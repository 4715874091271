import { callFirebaseFunction } from '../firebaseFunctions';
import { HelpScoutArticlePreview } from '../../features/Docs/pages/KnowledgeBase/service';

type GetArticlesInCategoryParams = {
  slug: string;
  spaceId: string;
  includeDrafts: boolean;
};

export const getArticlesInCategory = async ({
  slug,
  spaceId,
  includeDrafts
}: GetArticlesInCategoryParams) => {
  return callFirebaseFunction<{
    articles: HelpScoutArticlePreview[];
    category?: { name: string; slug: string; description: string };
    type: 'article' | 'category';
  }>('helpCenter-slugLookup', { spaceId, slug, includeDrafts });
};
