import React, { useContext, useState } from 'react';

interface IContextProps {
  open: boolean;
  setOpen: (open: boolean) => any;
}

const NotificationsDrawerContext = React.createContext<IContextProps>({
  open: false,
  setOpen: (o: boolean) => {}
});

export const NotificationsDrawerProvider: React.FC = props => {
  const [open, setOpen] = useState(false);
  const value = { open, setOpen };

  return (
    <NotificationsDrawerContext.Provider value={value}>
      {props.children}
    </NotificationsDrawerContext.Provider>
  );
};

export const useNotificationsDrawer = () => {
  const { open, setOpen } = useContext(NotificationsDrawerContext);
  return [open, setOpen] as [typeof open, typeof setOpen];
};
