import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
  Typography
} from '@material-ui/core';
import { useState } from 'react';
import { TagType } from '../../../domainTypes/tags';
import { useCurrentUser } from '../../../services/currentUser';
import { createTags } from '../../../services/tags';

export const CreateTagDialog = ({
  open,
  setDialogOpen
}: {
  open: boolean;
  setDialogOpen: (arg: boolean) => void;
}) => {
  const isGroup = true;
  const { space } = useCurrentUser();
  const [tagName, setTagName] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    const tagType: TagType = 'Page';
    const newTag = {
      id: '',
      space_id: '',
      name: tagName,
      color: '#444',
      parent_id: null,
      type: tagType
    };
    return createTags(space.id, [newTag]).then((r) => {
      console.log(r);
      setLoading(false);
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setDialogOpen(false);
      }}
    >
      <DialogTitle>Create a group</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Typography variant="body1" component="p" paragraph>
            Create a group for your tags, so you can benchmark different tags in
            the same group against one another.
            <br />
            <br />
            We recommend using a <strong>descriptive, two-word name</strong> for
            your group. For instance, use "Content type", "Product category", or
            "Site section" rather than the more generic "Type", "Category", or
            "Section".
          </Typography>
          <br />
          <InputLabel id="tagName">
            {isGroup ? 'Group name' : 'Tag name'}
          </InputLabel>
          <br />
          <TextField
            variant="outlined"
            value={tagName}
            autoFocus
            onChange={(e) => {
              setTagName(e.target.value);
            }}
            fullWidth
            id="tagName"
            name="tagName"
            required
            placeholder="E.g. Content type, Product category, Site section"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpen(false);
            }}
          >
            Done
          </Button>
          <Button
            disabled={tagName === '' || loading}
            type="submit"
            color="primary"
            variant="contained"
          >
            {loading ? 'Creating group...' : 'Create tag group'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
