import { styled } from '../../../../../../emotion';
import { identity, sortBy } from 'lodash';
import { TooltipProps } from 'recharts';
import {
  Channel,
  channelColor,
  channelLabel,
  getChannel
} from '../../../../../../services/channels/channels';
import { EarningMetric, TrafficMetric } from '../../service';
import { CurrencyCode } from '../../../../../../domainTypes/currency';
import {
  TooltipHeading,
  TooltipPaper
} from '../../../../../../components/Charts/CustomTooltip';
import {
  formatMetric,
  metricName,
  metricTitle
} from '../../../../../../services/analyticsV2/metrics';
import React, { ReactNode } from 'react';
import { WithShape } from '../../../../../../components/Charts/Util';
import { EARNINGS_COLOR } from './index';

const TooltipTable = styled<'div', { metricsCount: number }>('div')`
  display: grid;
  grid-template-columns: auto repeat(${(p) => p.metricsCount}, min-content);
  column-gap: ${(t) => t.theme.spacing(1)}px;
`;

const CurrentMetric = styled('div')`
  text-align: right;
  font-weight: bold;
`;

const PreviousMetric = styled('div')`
  text-align: right;
  opacity: 0.8;
`;

const RealtimeTooltip = ({
  data,
  metric,
  label,
  hasComparison,
  currency,
  subHeading
}: {
  data: Array<{
    key: string;
    label: ReactNode;
    currentValue: number;
    previousValue: number;
  }>;
  metric: TrafficMetric | EarningMetric;
  label: ReactNode;
  hasComparison: boolean;
  currency: CurrencyCode;
  subHeading?: ReactNode;
}) => {
  return (
    <TooltipPaper>
      <TooltipHeading>{label}</TooltipHeading>
      {subHeading ? <div>{subHeading}</div> : null}
      <TooltipTable metricsCount={hasComparison ? 2 : 1}>
        {data.map(({ key, label, currentValue, previousValue }) => {
          return (
            <React.Fragment key={key}>
              {label}
              <CurrentMetric>
                {formatMetric(currentValue, metric, currency)}
              </CurrentMetric>
              {hasComparison ? (
                <PreviousMetric>
                  ({formatMetric(previousValue, metric, currency)})
                </PreviousMetric>
              ) : null}
            </React.Fragment>
          );
        })}
      </TooltipTable>
    </TooltipPaper>
  );
};

export const RealtimeTrafficChartTooltip = ({
  payload,
  label = '',
  labelFormatter = identity,
  channels,
  metric,
  currency,
  hasComparison
}: TooltipProps & {
  channels: Channel[];
  metric: TrafficMetric;
  currency: CurrencyCode;
  hasComparison: boolean;
}) => {
  const data = (payload ?? [])
    .filter(({ name }) => channels.some((c) => c.id === name))
    .map(({ name, payload }) => {
      const channel = getChannel(name, channels);
      const color = channelColor(channel);
      const label = channelLabel(channel);
      const currentValue = payload.traffic[name] ?? 0;
      const previousValue = payload.previousTraffic[name] ?? 0;
      return {
        key: name,
        label: (
          <WithShape color={color} small>
            {label}
          </WithShape>
        ),
        currentValue,
        previousValue
      };
    })
    .filter(
      ({ currentValue, previousValue }) =>
        currentValue !== 0 || previousValue !== 0
    );

  const sortedData = sortBy(data, (d) => -d.currentValue);

  return (
    <RealtimeTooltip
      data={sortedData}
      metric={metric}
      label={labelFormatter(label)}
      subHeading={<strong>{metricTitle(metric)}</strong>}
      hasComparison={hasComparison}
      currency={currency}
    />
  );
};

export const RealtimeEarningsChartTooltip = ({
  payload,
  label = '',
  labelFormatter = identity,
  metric,
  currency,
  hasComparison
}: TooltipProps & {
  metric: EarningMetric;
  currency: CurrencyCode;
  hasComparison: boolean;
}) => {
  const activePayload = payload ?? [];
  const data = [
    {
      key: 'earnings',
      label: (
        <WithShape color={EARNINGS_COLOR}>
          All sites {metricName(metric)}
        </WithShape>
      ),
      currentValue:
        activePayload.find(({ name }) => name === 'current')?.payload
          .earnings ?? 0,
      previousValue:
        activePayload.find(({ name }) => name === 'previous')?.payload
          .previousEarnings ?? 0
    }
  ];

  return (
    <RealtimeTooltip
      data={data}
      metric={metric}
      label={labelFormatter(label)}
      hasComparison={hasComparison}
      currency={currency}
    />
  );
};
