import { useEffect } from 'react';

export const useErrorLogger = (err: any) => {
  useEffect(() => {
    if (err) {
      console.error(err);
    }
  }, [err]);
};

export const useErrorLoggerWithLabel = (label: string, err: any) => {
  useEffect(() => {
    if (err) {
      console.error(label, err);
    }
  }, [label, err]);
};
