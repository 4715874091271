import React from 'react';
import { useHasCurrentUserRequiredScopes } from '../../../../../services/currentUser';
import { Helmet } from 'react-helmet';
import { NoPermissions } from '../../../../../components/NoPermissions';
import { CustomDimensionsDetails } from './CustomDimensionsDetails';
import { Slot } from '../service/slot';
import { ContentPageBody } from '../../../components/ContentPageBody';

export const PageCustomDimensionsDetails = ({
  slot,
  value
}: {
  slot: Slot;
  value: string;
}) => {
  // FIX event name
  // useTrackMixpanelView('view_custom_dimensions');
  const [canView] = useHasCurrentUserRequiredScopes([
    'reports.custom_dimensions.view'
  ]);

  return (
    <>
      <Helmet>
        <title>{value} | Affilimate</title>
      </Helmet>
      <ContentPageBody>
        {canView ? (
          <CustomDimensionsDetails slot={slot} value={value} />
        ) : (
          <NoPermissions />
        )}
      </ContentPageBody>
    </>
  );
};
