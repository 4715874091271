import React from 'react';
import { Typography } from '@material-ui/core';
import { styled } from '../../../../../emotion';

const Image = styled('img')`
  display: block;
  filter: grayscale(80%);
  margin: ${p => p.theme.spacing(4)}px auto;
  width: 192px;
`;

const Container = styled('div')`
  margin: 0 auto;
  padding: ${p => p.theme.spacing(4)}px;
  text-align: center;
`;

const Message = styled('div')`
  font-size: 16px;
  margin: ${p => p.theme.spacing(2)}px 0;
  color: ${p => p.theme.palette.text.hint};
  font-weight: 700;
`;

export const Empty = () => {
  return (
    <Container>
      <Image src="/images/empty-states/unicorn.svg" />
      <Message>You've got this</Message>
      <Typography variant="body2" color="textSecondary">
        There are no unread alerts in your inbox
      </Typography>
    </Container>
  );
};
