import { DialogContent, DialogTitle } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Hidden from '@material-ui/core/Hidden';
import firebase from 'firebase/app';
import { compact, orderBy, truncate } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ChevronDown as IconChevronDown,
  Repeat as IconRepeat
} from 'react-feather';
import { Doc } from '../../domainTypes/document';
import { EMPTY_ARR } from '../../domainTypes/emptyConstants';
import { ISpace } from '../../domainTypes/space';
import { styled } from '../../emotion';
import { useLoadingValue } from '../../hooks/useLoadingValue';
import { SortDirection } from '../../hooks/useSort';
import { useImpersonatorClaim } from '../../services/auth';
import { getCurrentUser, useCurrentUser } from '../../services/currentUser';
import { LoadingValue, store } from '../../services/db';
import {
  CollectionListener,
  createSingleCollectionListenerStore
} from '../../services/firecache/collectionListener';
import { createDocumentListenerGetter } from '../../services/firecache/documentListener';
import { getActiveDomainUrls, toSpaceDoc } from '../../services/space';
import { formatDatePrecise } from '../../services/time';
import { FS } from '../../versions';
import { SearchInput } from '../SearchInput';
import { TableToolbar } from '../Table';
import { IColumn } from '../Table/Column';
import { VirtualizedSortableTable } from '../Table/VirtualizedSortable';
import { TopNavBarSection } from '../TopNavBarSection';

type Props = {
  onSwitch: (nextSpaceId: string) => void;
  labelMaxLength?: number;
};

type SpaceOption = {
  label: string;
  id: string;
  createdAt: firebase.firestore.Timestamp;
};

const Switcher = styled('div')`
  display: flex;
  align-items: center;

  color: inherit;
  font-size: ${(p) => p.theme.custom.fontSize.m}px;
  cursor: pointer;
  max-height: 54px;

  gap: ${(p) => p.theme.spacing(0.5)}px;
`;

const getCompleteActiveSpaceCache = createSingleCollectionListenerStore(
  () =>
    new CollectionListener(
      store().collection(FS.spaces).where('active', '==', true),
      toSpaceDoc,
      {
        ignoreInitialCachedResults: true
      }
    )
);

const getSpaceCache = createDocumentListenerGetter(
  (spaceId) => store().collection(FS.spaces).doc(spaceId),
  toSpaceDoc
);

const spaceToSpaceOption = (s: Doc<ISpace>): SpaceOption => ({
  id: s.id,
  label: getActiveDomainUrls(s.data, true).join(', ') || s.id,
  createdAt: s.data.createdAt
});

const useSpaceOptions = () => {
  const { spaces } = useCurrentUser();
  const { value, loading, error, setValue } = useLoadingValue<SpaceOption[]>();
  const [isImpersonator, loadingImpersonatorClaim] = useImpersonatorClaim();

  useEffect(() => {
    // return early.
    // If we don't do that, we would always trigger the !isAdmin path, followed by the isAdmin path.
    // The results might not come in in order and override each other as a result...
    if (loadingImpersonatorClaim) {
      console.log('impersonator claim loading');
      return;
    }

    if (!isImpersonator) {
      console.log('is not impersonator');
      Promise.all(
        spaces.map((spaceId) => getSpaceCache(spaceId).get())
      ).then((ss) => setValue(compact(ss).map(spaceToSpaceOption)));
    } else {
      console.log('getting active space cache');
      getCompleteActiveSpaceCache()
        .get()
        .then((ss) => {
          setValue(ss.map(spaceToSpaceOption));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isImpersonator, loadingImpersonatorClaim, spaces]);

  return [value, loading, error] as LoadingValue<typeof value>;
};

const getSelectedOption = (spaceId: string, options: SpaceOption[]) => {
  return options.find((o) => o.id === spaceId);
};

type SortKey = 'id' | 'label' | 'createdAt';

const sort = (
  os: SpaceOption[],
  sortBy: SortKey,
  direction: SortDirection
): SpaceOption[] => {
  if (sortBy === 'createdAt') {
    return orderBy(os, (o) => o[sortBy].toMillis(), direction);
  }
  return orderBy(os, (o) => o[sortBy], direction);
};

const COLUMNS: IColumn<SpaceOption, SortKey>[] = [
  {
    key: 'id',
    head: () => 'ID',
    cell: (d) => (
      <a href={`/dashboard?spaceId=${d.id}`} key={d.id}>
        {d.id}
      </a>
    ),
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'label',
    head: () => 'Domain',
    cell: (d) => d.label,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 4
  },
  {
    key: 'createdAt',
    head: () => 'Created',
    cell: (d) => <div>{formatDatePrecise(d.createdAt)}</div>,
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 100,
    flexGrow: 4
  }
];

export const SpaceSwitcher = ({ onSwitch, labelMaxLength }: Props) => {
  const [open, setOpen] = useState(false);
  const [spaceOptions = EMPTY_ARR, loading] = useSpaceOptions();
  const { space } = getCurrentUser();

  const [term, setTerm] = useState('');
  const options = useMemo(
    () =>
      term
        ? spaceOptions.filter(
            (o) => o.id.indexOf(term) !== -1 || o.label.indexOf(term) !== -1
          )
        : spaceOptions,
    [spaceOptions, term]
  );

  const selectedOption = getSelectedOption(space.id, spaceOptions);

  return (
    <>
      {!loading && spaceOptions.length > 1 && !!selectedOption && (
        <Switcher role="button" onClick={() => setOpen(true)}>
          <Hidden mdUp>
            <TopNavBarSection maxWidth={40}>
              <Box mt={1}>
                <IconRepeat size={20} />
              </Box>
            </TopNavBarSection>
          </Hidden>
          <Hidden smDown>
            <TopNavBarSection>
              {labelMaxLength
                ? truncate(selectedOption.label, { length: labelMaxLength })
                : selectedOption.label}
              <IconChevronDown size={12} />
            </TopNavBarSection>
          </Hidden>
        </Switcher>
      )}
      <Dialog onClose={() => setOpen(false)} open={open} maxWidth="md">
        <DialogTitle>Switch Space</DialogTitle>
        <DialogContent style={{ width: '900px' }}>
          <TableToolbar padding="dense">
            <SearchInput value={term} onChange={setTerm} autoFocus={true} />
          </TableToolbar>
          <VirtualizedSortableTable
            rows={options}
            columns={COLUMNS}
            cellProps={undefined}
            height={500}
            margin="dense"
            sortFn={sort}
            initialSortColumn={COLUMNS[1]}
            onRowClick={(o) => {
              onSwitch(o.id);
              setTerm('');
              setOpen(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
