import { WithShape } from '../../components/Charts/Util';
import { Truncated } from '../../components/Truncated';
import { EMPTY_ARR } from '../../domainTypes/emptyConstants';
import {
  Channel,
  channelColor,
  channelLabel,
  getChannel,
  useSpaceChannels
} from './channels';

export const ChannelIdLabel = ({ channelId }: { channelId: string }) => {
  const [channels = EMPTY_ARR] = useSpaceChannels();
  const channel = getChannel(channelId, channels);
  return <ChannelLabel channel={channel} />;
};

export const ChannelLabel = ({ channel }: { channel: Channel }) => {
  const name = channelLabel(channel);
  const color = channelColor(channel);
  return (
    <Truncated title={name}>
      <WithShape color={color}>{name}</WithShape>
    </Truncated>
  );
};
