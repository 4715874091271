import React from 'react';
import { Helmet } from 'react-helmet';
import { NoPermissions } from '../../../../components/NoPermissions';
import { useHasCurrentUserRequiredScopes } from '../../../../services/currentUser';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { PerformancePageBody } from '../../components/PerformancePageBody';
import { Rates } from './Rates';

export const PagePerformanceRates = () => {
  useTrackMixpanelView('view_rates');
  const [canView] = useHasCurrentUserRequiredScopes(['reports.rates.view']);

  return (
    <>
      <Helmet>
        <title>Rates | Affilimate</title>
      </Helmet>
      {canView ? (
        <Rates />
      ) : (
        <PerformancePageBody>
          <NoPermissions />
        </PerformancePageBody>
      )}
    </>
  );
};
