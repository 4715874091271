import { AnalyticsQuery } from '../../../domainTypes/analytics_v2';
import {
  useTimeframeDefinitionFromUrl,
  useTimeRanges
} from '../../../hooks/timeframe';
import { TimeframePickerDense } from '../../TimeframePicker/TimeframePickerDense';
import React, { useMemo } from 'react';
import {
  IComparisonOption,
  ITimeframeOption,
  useDefaultCompareOptions,
  useDefaultTimeframeOptions
} from '../../TimeframePicker/service/options';

export const useDefaultOptions = ({
  timeframeOptions,
  comparisonOptions
}: {
  timeframeOptions?: ITimeframeOption[];
  comparisonOptions?: IComparisonOption[];
}) => {
  const defaultTimeframeOptions = useDefaultTimeframeOptions();
  const defaultComparisonOptions = useDefaultCompareOptions();
  return useMemo(
    () => ({
      timeframeOptions: timeframeOptions ?? defaultTimeframeOptions,
      comparisonOptions: comparisonOptions ?? defaultComparisonOptions
    }),
    [
      comparisonOptions,
      defaultComparisonOptions,
      defaultTimeframeOptions,
      timeframeOptions
    ]
  );
};

interface TimeframeProps {
  timeframeOptions?: ITimeframeOption[];
  comparisonOptions?: IComparisonOption[];
}

export const TimeframePicker: React.FC<TimeframeProps> = ({
  timeframeOptions,
  comparisonOptions
}) => {
  const [timeframe, setTimeframe] = useTimeframeDefinitionFromUrl();
  const opts = useDefaultOptions({ timeframeOptions, comparisonOptions });

  return (
    <TimeframePickerDense
      value={timeframe}
      onChange={setTimeframe}
      timeframeOptions={opts.timeframeOptions}
      comparisonOptions={opts.comparisonOptions}
    />
  );
};

export type AnalyticsRanges = Pick<AnalyticsQuery, 'range' | 'compare'>;

export const useTimeframe = (): AnalyticsRanges => {
  return useTimeRanges();
};
