import { COLORS } from '../../domainTypes/colors';
import { useTheme } from '../../themes';
import { crc32 } from '../crc32';

export const COLOR_UNKNOWN = '#e3e7ea';

export const useColors = () => {
  return COLORS;
};

export const useColorsTrafficLight = () => {
  const theme = useTheme();
  return [
    theme.custom.colors.success.main,
    theme.custom.colors.pending.main,
    theme.custom.colors.error.main
  ];
};

export const useColorsSpectrumDanger = () => {
  return ['#f5222d', '#ff4d4f', '#ff7875', '#ffa39e', '#ffccc7', '#fff1f0'];
};

export const useColorsSpectrumWarning = () => {
  return ['#fa8c16', '#ffa940', '#ffc069', '#ffd591', '#ffe7ba', '#fff7e6'];
};

export const useColorsSpectrumSecondary = () => {
  return ['#eb2f96', '#f759ab', '#ff85c0', '#ffadd2', '#ffd6e7', '#fff0f6'];
};

export const useColorsSpectrumPrimary = () => {
  return ['#1890ff', '#40a9ff', '#69c0ff', '#91d5ff', '#bae7ff', '#e6f7ff'];
};

export const useColorsSpectrumUnknown = () => {
  return ['#722ed1', '#9254de', '#b37feb', '#d3adf7', '#efdbff', '#f9f0ff'];
};

export const useColorsSpectrumSuccess = () => {
  return ['#52c41a', '#73d13d', '#95de64', '#b7eb8f', '#d9f7be', '#f6ffed'];
};

export const useColorsSpectrumGrayscale = () => {
  return ['#595959', '#8c8c8c', '#bfbfbf', '#d9d9d9', '#e8e8e8', '#f5f5f5'];
};

export const useColors4 = () => {
  const theme = useTheme();
  return [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.custom.colors.pending.main,
    theme.custom.colors.success.main
  ];
};

export const useColorUnknown = () => {
  return COLOR_UNKNOWN;
};

// From: https://carto.com/carto-colors/
export const COLOR_SCALE = [
  '#ecda9a',
  '#efc47e',
  '#f3ad6a',
  '#f7945d',
  '#f97b57',
  '#f66356',
  '#ee4d5a',
  '#c4e6c3',
  '#96d2a4',
  '#6dbc90',
  '#4da284',
  '#36877a',
  '#266b6e',
  '#1d4f60',
  '#d1eeea',
  '#a8dbd9',
  '#85c4c9',
  '#4f90a6',
  '#3b738f',
  '#2a5674',
  '#f3e79b',
  '#fac484',
  '#f8a07e',
  '#eb7f86',
  '#ce6693',
  '#a059a0',
  '#5c53a5',
  '#b4d9cc',
  '#89c0b6',
  '#63a6a0',
  '#287274',
  '#0d585f',
  '#fcde9c',
  '#faa476',
  '#f0746e',
  '#e34f6f',
  '#dc3977',
  '#b9257a',
  '#7c1d6f',
  '#f6d2a9',
  '#f5b78e',
  '#f19c7c',
  '#ea8171',
  '#dd686c',
  '#ca5268',
  '#b13f64',
  '#009392',
  '#39b185',
  '#9ccb86',
  '#e9e29c',
  '#eeb479',
  '#e88471',
  '#cf597e',
  '#ffc6c4',
  '#f4a3a8',
  '#e38191',
  '#cc607d',
  '#ad466c',
  '#8b3058',
  '#672044'
];

export const getStableRandomColor = (x: string) => {
  if (
    x === 'Other' ||
    x === 'Unknown' ||
    x === 'other' ||
    x === 'unknown' ||
    x === '' ||
    x === '-' ||
    !x
  ) {
    return COLOR_UNKNOWN;
  }

  if (x.includes('applenews')) {
    return COLOR_SCALE[5];
  }

  if (x.includes('yahoo')) {
    return COLOR_SCALE[25];
  }

  if (x.includes('msn')) {
    return COLOR_SCALE[18];
  }

  if (x.includes('smartnews')) {
    return COLOR_SCALE[16];
  }

  const int = Math.abs(crc32(x));
  return COLOR_SCALE[int % COLOR_SCALE.length];
};
