import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import firebase from 'firebase/app';
import { compact, isNaN } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { useMemo } from 'react';
import { Check as IconCheck, Clock as IconClock } from 'react-feather';
import { AdditionalActionsMenu } from '../../../../../../components/AdditionalActionsMenu';
import { AlertBox } from '../../../../../../components/AlertBox';
import { Ctr } from '../../../../../../components/Ctr';
import { Loader } from '../../../../../../components/Loader';
import { Currency } from '../../../../../../components/Number';
import {
  BaseCountCell,
  CountCell,
  HideableTableCell,
  ITableHead,
  Paginator,
  SortableHead,
  TableLabelWithTooltip
} from '../../../../../../components/Table';
import { Dash } from '../../../../../../components/Table/CountCell';
import { Timeframe } from '../../../../../../domainTypes/analytics';
import { AnalyticsQuery } from '../../../../../../domainTypes/analytics_v2';
import { Doc } from '../../../../../../domainTypes/document';
import {
  IPageMetadata,
  IPageRevision
} from '../../../../../../domainTypes/page';
import { ISpace } from '../../../../../../domainTypes/space';
import { css, styled } from '../../../../../../emotion';
import { usePagination } from '../../../../../../hooks/usePagination';
import { usePromise } from '../../../../../../hooks/usePromise';
import { useRoutes } from '../../../../../../routes';
import { diffDays } from '../../../../../../services/analytics';
import { queryAnalyticsV2 } from '../../../../../../services/analyticsV2/query';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../../../services/db';
import { useFeatureEnabled } from '../../../../../../services/features';
import { updatePageRevision } from '../../../../../../services/page';
import {
  compareRevisions,
  getMatchingTimeframe,
  getTimeBoundaries,
  isLatestRevision
} from '../../../../../../services/page/revision';
import {
  formatDatePrecise,
  formatSmartRelativeDate,
  toMoment
} from '../../../../../../services/time';
import { removeTrailingSlash } from '../../../../../../services/url';
import { useSpaceCurrency } from '../../../../../../services/useSpaceCurrency';
import { useTheme } from '../../../../../../themes';
import { RevisionsEmptyState } from '../../../../components/EmptyStateRevision';
import { toUrlState } from '../../../../components/RevisionSelector';
import { RevisionNoteButton } from './RevisionNoteButton';

type Props = {
  url: string;
  metadata: Doc<IPageMetadata>;
};

export type TableRoutes = {
  toDetails: (revision: IPageRevision) => string;
  toHeatmap: (revision: IPageRevision) => string;
};

export type TableData = {
  revision: IPageRevision;
  start: firebase.firestore.Timestamp;
  end: firebase.firestore.Timestamp | null;
  index: number;
};

type TableKey =
  | 'lastModifiedAt'
  | 'activeDays'
  | 'pageViews'
  | 'dailyPageViews'
  | 'products'
  | 'clicks'
  | 'links'
  | 'viewRatio'
  | 'clickRatio'
  | 'cpm'
  | 'rpm'
  | 'epc'
  | 'actions';

const HEADS: ITableHead<TableKey>[] = [
  {
    key: 'lastModifiedAt',
    label: 'Revision',
    align: 'left',
    sortable: true,
    defaultDirection: 'desc'
  },
  {
    key: 'activeDays',
    label: 'Active days',
    align: 'right',
    sortable: false,
    defaultDirection: 'desc'
  },
  {
    key: 'dailyPageViews',
    label: 'Avg daily PVs',
    align: 'right',
    sortable: false,
    defaultDirection: 'desc'
  },
  {
    key: 'pageViews',
    label: 'Total Pageviews',
    align: 'right',
    sortable: false,
    defaultDirection: 'desc'
  },
  {
    key: 'clicks',
    label: 'Clicks',
    align: 'right',
    sortable: false,
    defaultDirection: 'desc'
  },
  {
    key: 'products',
    label: 'Products',
    align: 'right',
    sortable: false,
    defaultDirection: 'desc'
  },
  {
    key: 'links',
    label: 'Links',
    align: 'right',
    sortable: false,
    defaultDirection: 'desc'
  },
  {
    key: 'cpm',
    label: (
      <TableLabelWithTooltip
        align="right"
        label={<span>Page CTR</span>}
        tooltip={`
          Page-level click-through rate. The percentage of clicks through to your links per 100 pageviews.
        `}
        placement="top"
      />
    ),
    align: 'right',
    sortable: false,
    defaultDirection: 'desc',
    size: 'small'
  },
  {
    key: 'epc',
    label: (
      <TableLabelWithTooltip
        align="right"
        label={<span>EPC</span>}
        tooltip={`
      Earnings per click. How much you earn on average for every click through on an affiliate link on this page.
        `}
        placement="top"
      />
    ),
    align: 'right',
    sortable: false,
    defaultDirection: 'desc',
    size: 'small'
  },
  {
    key: 'rpm',
    label: (
      <TableLabelWithTooltip
        align="right"
        label={<span>RPM</span>}
        tooltip={`
          Revenue per one-thousand pageviews. If you drive 1,000 pageviews
          to this page, the amount of revenue to expect. Based on sales originating from clicks during this time period.
        `}
        placement="top"
      />
    ),
    align: 'right',
    sortable: false,
    defaultDirection: 'desc',
    size: 'small'
  },
  {
    key: 'actions',
    label: '',
    align: 'right',
    sortable: false,
    size: 'small'
  }
];

const PAGE_SIZES = [10];

const PaperWrapper = styled(Paper)`
  padding: ${(p) => p.theme.spacing(2)}px 0;
`;

const isRevisionReadyCH = (d: TableDataCH) => {
  return (
    d.activeDays !== null &&
    d.activeDays > 7 &&
    !isNaN(d.pageviews) &&
    d.pageviews > 2500
  );
};

const RevisionPendingWrapper = styled('div')`
  display: flex;
  background-color: ${(p) => p.theme.palette.grey.A700};
  color: white;
  margin-left: ${(p) => p.theme.spacing(1)}px;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  padding: 2px;
  border-radius: 100%;
`;

export const RevisionReadyWrapper = styled('div')`
  display: flex;
  background-color: ${(p) => p.theme.palette.primary.main};
  color: white;
  margin-left: ${(p) => p.theme.spacing(1)}px;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  padding: 2px;
  border-radius: 100%;
`;

const NoIgnore: React.FC<{ d: { revision: IPageRevision } }> = ({
  d,
  children
}) => {
  if (!!d.revision.ignore) {
    return null;
  }
  return <>{children}</>;
};

const AdditionalActions: React.FC<{
  spaceId: string;
  url: string;
  d: { revision: IPageRevision };
  routes: TableRoutes;
  isFirstVersion: boolean;
}> = ({ d, spaceId, url, routes, isFirstVersion }) => {
  return (
    <AdditionalActionsMenu
      options={compact([
        !d.revision.ignore && {
          key: 'heatmap',
          label: 'Open heatmap',
          href: routes.toHeatmap(d.revision)
        },
        (!isFirstVersion || d.revision.ignore) && {
          key: 'toggle',
          label: d.revision.ignore ? 'Reactivate revision' : 'Ignore revision',
          onClick: () =>
            updatePageRevision(spaceId, url, {
              ...d.revision,
              ignore: !d.revision.ignore
            })
        }
      ])}
    />
  );
};

const revisionQuery = (
  { end, start }: { end: Moment; start: Moment },
  url: string
): AnalyticsQuery => {
  return {
    range: {
      start: start.toISOString(),
      end: end.toISOString()
    },
    select: ['p', 'c', 'ctr', 'epc_net', 'rpm_net'],
    filters: [
      {
        field: 'page_url',
        condition: 'in',
        values: [url]
      }
    ]
  };
};

const toMoments = ({ end, start, ...rest }: TableData) => {
  const endMillis = end ? end.toMillis() : new Date();
  const endMoment = moment(endMillis);
  const startMoment = moment(start.toMillis());
  return {
    ...rest,
    end: endMoment,
    start: startMoment
  };
};

interface TableDataCH {
  index: number;
  revision: IPageRevision;
  start: Moment;
  end: Moment;
  activeDays: number;
  pageviews: number;
  ctr: number;
  epc: number;
  rpm: number;
  avgPageviews: number;
}

// TODO: Fix TableData type name
const useRevisionMetrics = (revisions: TableData[], url: string) => {
  const revisionsWithMoments = useMemo(() => revisions.map(toMoments), [
    revisions
  ]);
  const { space } = useCurrentUser();
  return useMappedLoadingValue(
    usePromise(
      () =>
        Promise.all(
          revisionsWithMoments.map(async (revision) => {
            const result = await queryAnalyticsV2(
              space.id,
              revisionQuery(revision, url)
            );
            return {
              ...revision,
              data: result.rows[0].data
            };
          })
        ),
      [revisionsWithMoments]
    ),
    (rows) => {
      return rows.map((row) => {
        const { revision, start, end, index, data } = row;
        const activeDays = diffDays(start, end);

        const pageviews = data.p?.curr ?? 0;
        return {
          index,
          revision,
          start,
          end,
          activeDays,
          pageviews,
          clicks: data.c?.curr ?? 0,
          ctr: data.ctr?.curr ?? 0,
          epc: data.epc_net?.curr ?? 0,
          rpm: data.rpm_net?.curr ?? 0,
          avgPageviews: pageviews / activeDays
        };
      });
    }
  );
};

const RevisionHistoryTableCh = ({
  spaceId,
  url,
  pagedRevisions,
  revisions,
  timeframe,
  routes
}: {
  spaceId: string;
  url: string;
  pagedRevisions: TableData[];
  revisions: IPageRevision[];
  timeframe: Timeframe;
  routes: TableRoutes;
}) => {
  const currency = useSpaceCurrency();

  const { tz } = timeframe;

  const [data, loading] = useRevisionMetrics(pagedRevisions, url);
  const hasRealtime = useFeatureEnabled('REFERRER_REPORTS_V1');

  const [canIgnoreRevisions] = useHasCurrentUserRequiredScopes([
    'revisions.toggle_ignore'
  ]);

  const visibleColumns = useMemo(
    () =>
      new Set(
        HEADS.filter((h) => {
          if (h.key === 'actions' && !canIgnoreRevisions) {
            return false;
          }
          return h.key !== 'products' && h.key !== 'links';
        }).map((h) => h.key)
      ),
    [canIgnoreRevisions]
  );

  const theme = useTheme();
  return (
    <Table>
      <TableHead>
        <TableRow>
          {HEADS.map((h) => (
            <SortableHead
              key={h.key}
              head={h}
              currentDirection={'desc'}
              currentSortBy={'lastModifiedAt'}
              setSort={() => {}}
              padding={h.padding}
              hide={!visibleColumns.has(h.key)}
              size={h.size}
            />
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {loading || !data ? (
          <TableCell
            colSpan={8}
            className={css((t) => ({ padding: t.spacing(8), height: 350 }))}
          >
            <Loader size={32} />
          </TableCell>
        ) : (
          data.map((d, i) => (
            <TableRow key={i} style={{ color: 'red' }}>
              <HideableTableCell
                align="left"
                hide={!visibleColumns.has('lastModifiedAt')}
              >
                <span
                  style={{
                    display: 'flex',
                    whiteSpace: 'nowrap',
                    alignItems: 'center',
                    color: d.revision.ignore
                      ? theme.palette.text.disabled
                      : 'inherit'
                  }}
                >
                  <div
                    title={formatDatePrecise(
                      toMoment(d.revision.lastModifiedAt).tz(tz),
                      'MMM D, YYYY'
                    )}
                  >
                    v{d.index + 1} ·{' '}
                    {d.revision.provider === 'user'
                      ? 'Created manually'
                      : 'Published'}{' '}
                    {formatSmartRelativeDate(
                      toMoment(d.revision.lastModifiedAt).tz(tz),
                      'MMM D, YYYY'
                    )}
                  </div>
                  <RevisionNoteButton spaceId={spaceId} url={url} d={d} />
                  {!d.revision.ignore && isRevisionReadyCH(d) && (
                    <Tooltip
                      title="This revision has enough data for analysis"
                      placement="top"
                    >
                      <RevisionReadyWrapper>
                        <IconCheck size={14} />
                      </RevisionReadyWrapper>
                    </Tooltip>
                  )}

                  {!d.revision.ignore &&
                    isLatestRevision(d.revision, revisions) &&
                    !isRevisionReadyCH(d) && (
                      <Tooltip
                        title="This revision is still collecting data"
                        placement="top"
                      >
                        <RevisionPendingWrapper>
                          <IconClock size={14} />
                        </RevisionPendingWrapper>
                      </Tooltip>
                    )}
                </span>
              </HideableTableCell>
              <HideableTableCell
                align="right"
                hide={!visibleColumns.has('activeDays')}
              >
                <NoIgnore d={d}>
                  {d.activeDays === 0 && <Dash />}
                  {d.activeDays > 0 && (
                    <CountCell
                      before={0}
                      after={d.activeDays}
                      format="decimal"
                      digits={0}
                      compare={false}
                    />
                  )}
                </NoIgnore>
              </HideableTableCell>
              <HideableTableCell
                align="right"
                hide={!visibleColumns.has('dailyPageViews')}
              >
                <NoIgnore d={d}>
                  {d.activeDays === 0 && <Dash />}
                  {d.activeDays > 0 && (
                    <CountCell
                      before={0}
                      after={Math.round(d.avgPageviews)}
                      format="decimal"
                      digits={0}
                      compare={false}
                    />
                  )}
                </NoIgnore>
              </HideableTableCell>
              <HideableTableCell
                align="right"
                hide={!visibleColumns.has('pageViews')}
              >
                <NoIgnore d={d}>
                  {!hasRealtime && d.activeDays === 0 && <Dash />}
                  {(hasRealtime || d.activeDays > 0) && (
                    <CountCell
                      before={0}
                      after={d.pageviews}
                      format="decimal"
                      digits={0}
                      compare={false}
                    />
                  )}
                </NoIgnore>
              </HideableTableCell>
              <HideableTableCell
                align="right"
                hide={!visibleColumns.has('clicks')}
              >
                {!hasRealtime && d.activeDays === 0 && <Dash />}
                {(hasRealtime || d.activeDays > 0) && (
                  <CountCell
                    before={0}
                    after={Math.round(d.clicks)}
                    digits={0}
                    compare={false}
                  />
                )}
              </HideableTableCell>
              <HideableTableCell
                align="right"
                hide={!visibleColumns.has('links')}
              >
                <NoIgnore d={d}>{0}</NoIgnore>
              </HideableTableCell>
              <HideableTableCell
                align="right"
                hide={!visibleColumns.has('cpm')}
                size="small"
              >
                <NoIgnore d={d}>
                  {!hasRealtime && d.activeDays === 0 && <Dash />}
                  {(hasRealtime || d.activeDays > 0) && (
                    <BaseCountCell
                      before={0}
                      after={d.ctr}
                      compare={false}
                      format="decimal"
                      digits={1}
                    >
                      <Ctr
                        steps={[0, 0.05, 0.15, 0.2, 0.3, 0.4, 0.5]}
                        rate={d.ctr}
                        format={(item) => `${(item * 100).toFixed(1)}%`}
                      />
                    </BaseCountCell>
                  )}
                </NoIgnore>
              </HideableTableCell>
              <HideableTableCell
                align="right"
                hide={!visibleColumns.has('epc')}
                size="small"
              >
                <NoIgnore d={d}>
                  <Currency
                    cents={d.epc}
                    currency={currency}
                    zeroState={<Dash />}
                  />
                </NoIgnore>
              </HideableTableCell>
              <HideableTableCell
                align="right"
                hide={!visibleColumns.has('rpm')}
                size="small"
              >
                <NoIgnore d={d}>
                  <Currency
                    cents={d.rpm}
                    currency={currency}
                    zeroState={<Dash />}
                  />
                </NoIgnore>
              </HideableTableCell>
              <HideableTableCell
                align="right"
                hide={!visibleColumns.has('actions')}
                size="small"
              >
                <AdditionalActions
                  routes={routes}
                  d={d}
                  spaceId={spaceId}
                  url={url}
                  isFirstVersion={d.index === 0}
                />
              </HideableTableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};

const RevisionHistory = ({
  space,
  url,
  routes,
  metadata
}: {
  space: ISpace;
  url: string;
  routes: TableRoutes;
  metadata: Doc<IPageMetadata>;
}) => {
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    getCurrentPageItems
  } = usePagination<TableData>(0, PAGE_SIZES[0], []);

  if (metadata.data.sitemapError === 'NOT_FOUND') {
    return (
      <AlertBox variant="error">
        We were unable to find this page in your sitemap and are therefore not
        able to track its revisions.
      </AlertBox>
    );
  }

  if (metadata.data.sitemapError === 'NO_LAST_MODIFIED') {
    return (
      <AlertBox variant="error">
        The sitemap entry for this page doesn't contain its last modification
        date. We're therefore not able to track its revisions.
      </AlertBox>
    );
  }

  if (!metadata.data.revisions.length) {
    return <RevisionsEmptyState />;
  }

  const revisions = [...metadata.data.revisions].sort(compareRevisions('asc'));

  if (!revisions.length) {
    return <RevisionsEmptyState />;
  }

  const framedRevisions = revisions.map((revision, index) => {
    const { start, end } = getTimeBoundaries(revision, revisions);
    return { revision, start, end, index };
  });

  const pagedRevisions = getCurrentPageItems([...framedRevisions].reverse());
  const timeframe = getMatchingTimeframe(
    space,
    pagedRevisions[pagedRevisions.length - 1].start,
    pagedRevisions[0].end
  );

  return (
    <PaperWrapper>
      <RevisionHistoryTableCh
        spaceId={space.id}
        url={url}
        pagedRevisions={pagedRevisions}
        revisions={revisions}
        timeframe={timeframe}
        routes={routes}
      />
      <Paginator
        totalCount={revisions.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={PAGE_SIZES}
        page={page}
        onChangePage={setPage}
        onChangeRowsPerPage={setRowsPerPage}
      />
    </PaperWrapper>
  );
};

export const Revisions: React.FC<Props> = ({ url: rawUrl, metadata }) => {
  const url = removeTrailingSlash(rawUrl);
  const { space } = useCurrentUser();
  const { ROUTES } = useRoutes();

  const routes = useMemo<TableRoutes>(() => {
    const defaultFieldNames = {
      revision: 'revision',
      syncTimeframe: 'syncTimeframe',
      timeframe: 'timeframe'
    };
    return {
      toDetails: (revision) =>
        ROUTES.content.details.trends.url(url, {
          ...toUrlState({ revision, syncTimeframe: true }, defaultFieldNames)
        }),
      toHeatmap: (revision) =>
        ROUTES.content.details.heatmap.url(url, {
          ...toUrlState({ revision, syncTimeframe: true }, defaultFieldNames)
        })
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return (
    <>
      <RevisionHistory
        space={space}
        url={url}
        routes={routes}
        metadata={metadata}
      />
    </>
  );
};
