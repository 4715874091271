import { FormControl, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import { CURRENCIES, CurrencyCode } from '../../domainTypes/currency';
import { useId } from '../../hooks/useId';

type Props = {
  value: CurrencyCode;
  onChange: (nextValue: CurrencyCode) => void;
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
};

export const SelectCurrency: React.FC<Props> = ({
  value,
  onChange,
  label = '',
  fullWidth = false,
  disabled = false,
  size
}) => {
  const id = useId();
  return (
    <FormControl style={{ width: fullWidth ? '100%' : 'auto' }}>
      <TextField
        select
        id={id}
        label={label}
        value={value}
        fullWidth={fullWidth}
        disabled={disabled}
        variant="outlined"
        size={size}
        onChange={(ev) => {
          onChange(ev.target.value as CurrencyCode);
        }}
      >
        {CURRENCIES.map((c) => (
          <MenuItem key={c} value={c}>
            {c}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};
