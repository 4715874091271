import React from 'react';
import {
  Monitor as IconDesktop,
  Smartphone as IconMobile,
  Tablet as IconTablet,
  HelpCircle as IconUnknown
} from 'react-feather';
import { Device, getDeviceLabel } from '../../domainTypes/tracking';
import { styled } from '../../emotion';

export const DeviceIcon = ({
  device,
  size = 18,
  ignoreUnknown = false
}: {
  device: Device;
  size?: number;
  ignoreUnknown?: boolean;
}) => {
  if (device === 'desktop') {
    return <IconDesktop size={size} />;
  }
  if (device === 'mobile') {
    return <IconMobile size={size} />;
  }

  if (device === 'tablet') {
    return <IconTablet size={size} />;
  }
  return ignoreUnknown ? null : <IconUnknown size={size} />;
};

const Wrapper = styled('div')`
  display: flex;
  align-items: center;

  > :first-of-type {
    margin-right: ${(p) => p.theme.spacing()}px;
  }
`;

export const DeviceIconWithLabel = ({
  device,
  size = 18
}: {
  device: Device;
  size?: number;
}) => (
  <Wrapper>
    <DeviceIcon device={device} size={size} />
    <div>{getDeviceLabel(device)}</div>
  </Wrapper>
);
