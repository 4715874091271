import React from 'react';
import { Cell, Label, Pie, PieChart } from 'recharts';
import { COMBINED_THEME } from '../../themes';
import { formatNumber, toPercent } from '../Number';

type ProgressRingProps = {
  done: number;
  pending: number;
  error: number;
  unknown?: number;
};

interface IData {
  key: string;
  name: string;
  value: number;
  color: string;
}

const WIDTH = 200;

export const ProgressRing = React.memo(
  ({ done, pending, error, unknown = 0 }: ProgressRingProps) => {
    const { colors } = COMBINED_THEME.custom;
    const data: IData[] = [
      { key: 'pending', name: 'Pending', value: pending, color: '#F6CC1B' },
      {
        key: 'unknown',
        name: 'Unknown',
        value: unknown,
        color: '#a4a4a4'
      },
      { key: 'error', name: 'Error', value: error, color: '#DB4D4D' },
      { key: 'done', name: 'Done', value: done, color: colors.success.main }
    ];

    const total = done + error + pending;

    return (
      <PieChart width={WIDTH} height={WIDTH}>
        <Pie
          data={data}
          dataKey="value"
          startAngle={90}
          endAngle={450}
          cx={WIDTH / 2 - 5}
          cy={WIDTH / 2 - 5}
          innerRadius={WIDTH / 2 - 30}
          outerRadius={WIDTH / 2 - 10}
          animationDuration={100}
          isAnimationActive={false}
        >
          <Label
            value={formatNumber({
              n: toPercent(done + error, total),
              format: 'percent'
            })}
            position="center"
          />
          {data.map((d) => (
            <Cell key={d.key} fill={d.color} />
          ))}
        </Pie>
      </PieChart>
    );
  }
);
