import { compact, flatten, memoize, round } from 'lodash';
import * as mp from 'mixpanel-browser';
import { useEffect, useMemo } from 'react';
import { AnalyticsRanges } from '../components/analytics_v2/Timeframe';
import {
  AnalyticsQuery,
  ClicksQuery,
  TransactionsQuery
} from '../domainTypes/analytics_v2';
import { EMPTY_OBJ } from '../domainTypes/emptyConstants';
import { MixpanelEventName } from '../domainTypes/mixpanel';
import * as ENVS from '../env.json';
import { useDeepEqual } from '../hooks/useDeepEqual';
import { isNonProductionEnv } from '../services/env';
import { useCurrentUser } from './currentUser';
import { isoRangeToMomentRange, ISOTimeRange, MomentRange } from './time';

export const mixpanel = memoize(() => {
  mp.init(ENVS.mixpanel.projectToken, {
    debug: true,
    ignore_dnt: true,
    api_host: 'https://api-eu.mixpanel.com'
  });
  return mp;
});

type MixpanelDict = {
  _tf?: AnalyticsRanges;
  _query?: Partial<AnalyticsQuery | TransactionsQuery | ClicksQuery>;
  [key: string]: any;
};

const isInternalUser = (email: string | null | undefined) => {
  if (!email) {
    return false;
  }
  return (
    email.indexOf('affilimate.com') !== -1 ||
    email.indexOf('affilimate.io') !== -1 ||
    email.indexOf('evergreensupport.co') !== -1
  );
};

const toDayDurationFromMoments = (range: MomentRange, precision = 4) => {
  // We round this, as our ranges might have a millisecond drift, e.g. 20
  // start:   2024-11-23T23:00:00.000Z
  // end:     2024-11-24T22:59:59.999Z
  // This is a technical detail, which we can nivellate in our output by rounding
  return round(range.end.diff(range.start, 'days', true), precision);
};

const toDayDurationFromIso = (range: ISOTimeRange, precision = 4) => {
  // We round this, as our ranges might have a millisecond drift, e.g. 20
  // start:   2024-11-23T23:00:00.000Z
  // end:     2024-11-24T22:59:59.999Z
  // This is a technical detail, which we can nivellate in our output by rounding
  const mRange = isoRangeToMomentRange(range);
  return toDayDurationFromMoments(mRange, precision);
};

const expandProperties = (_props: MixpanelDict) => {
  const { _tf, _query: _analyticsQuery, ...other } = _props;
  const props: { [key: string]: any } = {
    ...other,
    _page_pathname: window.location.pathname
  };
  const range = _tf?.range || _analyticsQuery?.range || null;
  if (range) {
    props._tf_range_start = range.start;
    props._tf_range_end = range.end;
    props._tf_range_duration_days = toDayDurationFromIso(range);
  }

  const compareRange =
    _tf?.compare?.range ||
    (_analyticsQuery as AnalyticsQuery)?.compare?.range ||
    null;
  if (compareRange) {
    props._tf_compare_range_start = compareRange.start;
    props._tf_compare_range_end = compareRange.end;
    props._tf_compare_range_duration_days = toDayDurationFromIso(compareRange);
  }

  if (_analyticsQuery) {
    if (_analyticsQuery.select) {
      props._query_select = _analyticsQuery.select;
    }
    if (_analyticsQuery.filters) {
      props._query_filter_fields = _analyticsQuery.filters.map((f) => f.field);
    }
    if (_analyticsQuery.search) {
      props._query_search_fields = flatten(
        _analyticsQuery.search.map((f) => f.in)
      );
    }
    if (_analyticsQuery.orderBy) {
      props._query_order_by_fields = compact(
        (_analyticsQuery.orderBy as {
          field: string;
        }[]).map((x) => x.field)
      );
    }
    if (_analyticsQuery.paginate) {
      props._query_paginate_limit = _analyticsQuery.paginate.limit;
      props._query_paginate_page = _analyticsQuery.paginate.page;
    }
  }

  return props;
};

export const useMixpanel = () => {
  const currentUser = useCurrentUser();
  const spaceId = currentUser?.space?.id || '';
  const email = currentUser?.email || '';

  return useMemo(() => {
    const m = mixpanel();
    const track = (name: MixpanelEventName, props: MixpanelDict = {}) => {
      if (!spaceId) {
        // We might have ended up being in the admin app - nothing to track here
        return;
      }
      try {
        const finalProps = { ...expandProperties(props), spaces: [spaceId] };

        if (isNonProductionEnv()) {
          console.debug('Mixpanel event:', name, finalProps);
        }
        if (isInternalUser(email)) {
          return;
        }

        m.track(name, finalProps);
      } catch (err) {
        console.debug('Error during Mixpanel track', err);
      }
    };
    const identify = m.identify;
    const register = m.register;
    return { track, identify, register };
  }, [spaceId, email]);
};

export const useTrackMixpanelView = (
  eventName: MixpanelEventName,
  properties?: MixpanelDict
) => {
  // Also uses the mixpanel queue via the hook above
  const mixpanel = useMixpanel();
  const props = useDeepEqual(properties || EMPTY_OBJ);
  return useEffect(() => {
    mixpanel.track(eventName, props);
  }, [mixpanel, eventName, props]);
};
