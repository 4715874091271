import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core';
import React from 'react';
import { ExternalLink } from 'react-feather';
import { Helmet } from 'react-helmet';
import { AlertBox } from '../../../../components/AlertBox';
import { CopyButton } from '../../../../components/CopyButton';
import * as ENV from '../../../../env.json';
import { CanvasBar } from '../../../../layout/Canvas';
import { LimitedWidth } from '../../../../layout/PageBody';
import { Section } from '../../../../layout/Section';
import { useCurrentUser } from '../../../../services/currentUser';
import { SettingsLayout } from '../../SettingsLayout';
import { toAffiliateUrl } from './service';
import { styled } from '../../../../emotion';

const DEFAULT_LINKS = [
  {
    label: 'Your affiliate link',
    url: ENV.domains.website.public
  }
];

const LinkExternal = styled('a')`
  border-bottom: 1px solid;
`;

export const PagesSettingsNewAffiliateProgram = () => {
  const {
    space: { affiliateId }
  } = useCurrentUser();

  return (
    <SettingsLayout>
      <Helmet>
        <title>Affiliate Program | Affilimate</title>
      </Helmet>
      <LimitedWidth width={800}>
        <Typography variant="h6" component="p" paragraph>
          <strong>Affiliate Program</strong>
        </Typography>
        <AlertBox
          variant="pending"
          style={{
            marginBottom: '36px'
          }}
        >
          <Typography variant="body2" component="p">
            <strong>Important:</strong> You must be accepted to the{' '}
            <LinkExternal
              href="https://affilimate.firstpromoter.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Affilimate affiliate program
            </LinkExternal>{' '}
            before the link below will work.
            <br />
            <br />
            All Affilimate customers who subscribed before May 28, 2023 have
            automatically generated accounts. Simply reset the password with
            your account email to access the affiliate program dashboard. Later
            customers can simply apply with the link above, and are highly
            likely to be approved.
            <br />
            <br />
            Earned commissions appear automatically in your Performance report
            once approved. Commissions earned before May 28, 2023 may be
            represented differently inside Affilimate vs. the external platform,
            FirstPromoter.
          </Typography>
        </AlertBox>
        <Section>
          <CanvasBar>Program summary</CanvasBar>
          <Card>
            <Typography variant="body2" component="p" paragraph>
              Here are the key details to know about our affiliate program:
            </Typography>
            <Typography variant="body2" component="ul" paragraph>
              <li>
                <strong>Commission rate:</strong> 20% recurring for the first
                year
              </li>
              <li>
                <strong>Qualifying plans:</strong> All self-service plans (Lite,
                Solo Creator, and Portfolio)
              </li>
              <li>
                <strong>Eligibility to participate:</strong> A legal business
                entity that we can self-invoice. Individuals are not permitted.
              </li>
              <li>
                <strong>Payout terms:</strong> Net 45, first week of the month
              </li>
              <li>
                <strong>Payout requirements:</strong> Wise account
              </li>
              <li>
                <strong>Payout threshold:</strong> $50 USD
              </li>
            </Typography>
            <Typography variant="body2" component="p" paragraph>
              For official requirements, refer to the{' '}
              <LinkExternal
                href="https://affilimate.com/qs/affiliate-terms-and-conditions/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Affiliate Terms and Conditions
              </LinkExternal>
              . Get in touch with Support with any questions!
            </Typography>
          </Card>
        </Section>
        <Section>
          <CanvasBar>Resources</CanvasBar>
          <Card>
            <Table>
              <TableBody>
                {DEFAULT_LINKS.map((l, i) => {
                  const affUrl = toAffiliateUrl(l.url, affiliateId);
                  return (
                    <TableRow key={i}>
                      <TableCell>{l.label}</TableCell>
                      <TableCell>
                        {affUrl}
                        <CopyButton text={affUrl} />
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow key="assets">
                  <TableCell>Marketing assets</TableCell>
                  <TableCell>
                    <LinkExternal
                      href="https://affilimate.firstpromoter.com/my-assets"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View assets <ExternalLink size={12} />
                    </LinkExternal>
                  </TableCell>
                </TableRow>
                <TableRow key="payout-settings1">
                  <TableCell>Payout settings</TableCell>
                  <TableCell>
                    <LinkExternal
                      href="https://affilimate.firstpromoter.com/my-payout-methods"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Set payout method <ExternalLink size={12} />
                    </LinkExternal>
                  </TableCell>
                </TableRow>
                <TableRow key="payout-settings2">
                  <TableCell>Payouts</TableCell>
                  <TableCell>
                    <LinkExternal
                      href="https://affilimate.firstpromoter.com/my-payouts"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View payouts <ExternalLink size={12} />
                    </LinkExternal>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        </Section>
      </LimitedWidth>
    </SettingsLayout>
  );
};
