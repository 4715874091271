import React from 'react';
import { ExternalLink, Search } from 'react-feather';
import { Link } from 'react-router-dom';
import { EmptySearchState } from '../../../../../components/EmptySearchState';
import { useRoutes } from '../../../../../routes';
import { styled } from '../../../../../emotion';
import { ARTICLES } from '../../../../../services/beacon';
import { COLORS } from '../../../../../domainTypes/colors';

const HelpLink = styled(Link)`
  color: ${(p) => p.theme.palette.primary.main};
`;

export const EmptyLinkSearchState = () => {
  const { ROUTES } = useRoutes();
  return (
    <EmptySearchState
      icon={Search}
      title="No links matched your search"
      supportMessage="Still missing links? Ask Support"
      message={
        <>
          Please double-check your search query, timeframe, and filter
          selection.
          <br />
          <br />
          If you've just added new links on your site,{' '}
          <Link
            to={ROUTES.productScans.overview.url()}
            style={{ borderBottom: '1px solid' }}
          >
            run a new Scan
          </Link>{' '}
          to import it immediately for tracking. You'll see the first clicks or
          impressions tomorrow.
          <br />
          <br />
          <strong>More from the docs:</strong>
          <br />
          <HelpLink
            to={ROUTES.docs.knowledgeBase.url(ARTICLES.links.linkManagement)}
          >
            How do I import new affiliate links? <ExternalLink size={14} />
          </HelpLink>
          <br />
          <HelpLink
            to={ROUTES.docs.knowledgeBase.url(ARTICLES.links.whenClicksAppear)}
          >
            When do clicks appear on links? <ExternalLink size={14} />
          </HelpLink>
          <br />
          <HelpLink to={ROUTES.docs.knowledgeBase.url(ARTICLES.links.editLink)}>
            How can I update existing links? <ExternalLink size={14} />
          </HelpLink>
        </>
      }
      color={COLORS.blue.blue6}
      bgColor={COLORS.blue.blue2}
    />
  );
};
