// @ts-ignore
import StackdriverErrorReporter from 'stackdriver-errors-js';

const REPORTER = new StackdriverErrorReporter();

export type StackdriverErrorReporterConfig = {
  key: string; // Stackdriver API key
  projectId: string;
  service?: string; // e.g. app or website
  version?: string; // git commit hash
  reportUncaughtExceptions?: boolean; // defaults to true
  reportUnhandledPromiseRejections?: boolean; // default to true
  disabled?: boolean; // defaults to false, use to disable in DEV environments
};

type StackdriverErrorReporterConfigWithDefaults = {
  key: string;
  projectId: string;
  service: string;
  version: string;
  reportUncaughtExceptions: boolean;
  reportUnhandledPromiseRejections: boolean;
  disabled: boolean;
};

const noop = () => undefined;

export const start = (config: StackdriverErrorReporterConfig) => {
  const configWithDefaults: StackdriverErrorReporterConfigWithDefaults = {
    service: 'UNKNOWN',
    version: 'UNKNOWN',
    reportUncaughtExceptions: true,
    reportUnhandledPromiseRejections: true,
    disabled: false,
    ...config
  };

  REPORTER.start(configWithDefaults);
  const exceptionHandler = window.onerror || noop;
  if (exceptionHandler) {
    window.onerror = function() {
      // @ts-ignore
      exceptionHandler(...arguments);
      // Make sure error is passed on, e.g. logged to the console
      return false;
    };
  }

  const promiseRejectionHandler = window.onunhandledrejection;
  if (promiseRejectionHandler) {
    window.onunhandledrejection = function() {
      // @ts-ignore
      exceptionHandler(...arguments);
      // Make sure error is passed on, e.g. logged to the console
      return false;
    };
  }
};

export const reportError = (error: string | Error) => {
  REPORTER.report(typeof error === 'string' ? new Error(error) : error, {
    skipLocalFrames: 2
  });
  console.error('STACKDRIVER ERROR REPORTER', error);
};

export const setUser = (userId: string) => {
  REPORTER.setUser(userId);
};
