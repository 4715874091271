import { Button, Card, Paper } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import {
  AdditionActionsMenuOption,
  AdditionalActionsMenu
} from '../../../../../../../components/AdditionalActionsMenu';
import { ButtonWithPromise } from '../../../../../../../components/ButtonWithPromise';
import {
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS
} from '../../../../../../../components/GroupableList';
import { Loader } from '../../../../../../../components/Loader';
import { IColumn } from '../../../../../../../components/Table/Column';
import { Doc } from '../../../../../../../domainTypes/document';
import { ILinkCheckAppContainer } from '../../../../../../../domainTypes/linkCheckApp';
import { CheckStatusChip } from '../../../../../../../features/LinkCheck/components/CheckStatusChip';
import { useErrorLogger } from '../../../../../../../hooks/useErrorLogger';
import { CanvasBar } from '../../../../../../../layout/Canvas';
import { Centered } from '../../../../../../../layout/Centered';
import { Section } from '../../../../../../../layout/Section';
import { removeDoc } from '../../../../../../../services/db';
import { formatDatePrecise } from '../../../../../../../services/time';
import { FS } from '../../../../../../../versions';
import { toFirestoreConsole } from '../../../../../../services/firebase';
import {
  createNewCheck,
  useAppChecksBySpaceId
} from '../../../../../LinkCheck/service';

type Props = {
  spaceId: string;
};

const HEIGHT = 300;

type Data = Doc<ILinkCheckAppContainer>;
type ColumnName =
  | 'status'
  | 'total'
  | 'unsupported'
  | 'ok'
  | 'warnings'
  | 'errors'
  | 'unknown'
  | 'blocked'
  | 'queuedAt'
  | 'finishedAt'
  | 'actions';

type Column = IColumn<Data, ColumnName>;

const Actions = ({ d }: { d: Data }) => {
  const options: AdditionActionsMenuOption[] = useMemo(() => {
    return [
      {
        key: 'details',
        label: 'Open Details',
        externalHref: `/linkCheck/details/${d.id}`
      },
      {
        key: 'openInFirestore',
        label: 'Open in Firestore console',
        externalHref: toFirestoreConsole(FS.linkCheckApp, d.id)
      },
      {
        key: 'remove',
        label: 'Remove',
        onClick: () => removeDoc(d)
      }
    ];
  }, [d]);
  return <AdditionalActionsMenu options={options} />;
};

const COLUMNS: Column[] = [
  {
    key: 'status',
    head: () => 'Status',
    cell: (d) => <CheckStatusChip status={d.data.status} />,
    sortable: true,
    align: 'center',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'total',
    head: () => 'Total',
    cell: (d) => d.data.counts.total,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'unsupported',
    head: () => 'Unsupported',
    cell: (d) => d.data.counts.unsupported,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'ok',
    head: () => 'OK',
    cell: (d) => d.data.counts.results.ok,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'warnings',
    head: () => 'Warnings',
    cell: (d) => d.data.counts.results.warnings,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'errors',
    head: () => 'Errors',
    cell: (d) => d.data.counts.results.errors,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'unknown',
    head: () => 'Unknown',
    cell: (d) => d.data.counts.results.unknown,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'blocked',
    head: () => 'Blocked',
    cell: (d) => d.data.counts.results.blocked,
    sortable: true,
    align: 'right',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'queuedAt',
    head: () => 'Queued At',
    cell: (d) => formatDatePrecise(d.data.queuedAt),
    sortable: true,
    align: 'right',
    width: 150,
    flexGrow: 0
  },
  {
    key: 'finishedAt',
    head: () => 'Finished At',
    cell: (d) => d.data.finishedAt && formatDatePrecise(d.data.finishedAt),
    sortable: true,
    align: 'right',
    width: 150,
    flexGrow: 0
  },
  {
    key: 'actions',
    head: () => '',
    alternateHead: () => 'Actions',
    cell: (d) => <Actions d={d} />,
    sortable: false,
    align: 'right',
    width: 50,
    flexGrow: 0
  }
];

const SORTERS: ItemSorters<Data> = {
  queuedAt: {
    key: 'queuedAt',
    items: { sort: (d) => d.data.queuedAt.toMillis(), dir: 'desc' }
  }
};

const DEFAULT_SORTER = SORTERS.queuedAt;
const LIMIT = 5;

const LinkChecksInner = ({ spaceId }: { spaceId: string }) => {
  const [checks, loading, error] = useAppChecksBySpaceId(spaceId, LIMIT);
  useErrorLogger(error);
  return (
    <Section>
      <CanvasBar>
        <div>Link Checks (last {LIMIT})</div>
        <div>
          <ButtonWithPromise
            size="small"
            variant="contained"
            color="primary"
            onClick={() => createNewCheck(spaceId)}
            pending="Creating..."
          >
            Create check
          </ButtonWithPromise>
        </div>
      </CanvasBar>
      <Paper>
        {loading && <Loader height={HEIGHT} />}
        {checks && (
          <RowsRenderer
            variant="contained"
            columns={COLUMNS}
            rows={checks}
            rowToKey={(d) => d.id}
            sorter={DEFAULT_SORTER}
            sortDirection={DEFAULT_SORTER.items.dir}
            renderHead={true}
            chunkSize={30}
            rootMargin="400px"
            otherProps={undefined}
            rowHeight={ROW_HEIGHTS.dense}
          />
        )}
      </Paper>
    </Section>
  );
};

export const LinkChecks: React.FC<Props> = ({ spaceId }) => {
  const [reveal, setReveal] = useState(false);
  return (
    <Section>
      <CanvasBar>
        <div>Link Checks (last {LIMIT})</div>
        <div>
          <ButtonWithPromise
            size="small"
            variant="contained"
            color="primary"
            onClick={() => createNewCheck(spaceId)}
            pending="Creating..."
          >
            Create check
          </ButtonWithPromise>
        </div>
      </CanvasBar>
      <Card>
        {reveal ? (
          <LinkChecksInner spaceId={spaceId} />
        ) : (
          <Centered>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setReveal(true)}
            >
              Load Data
            </Button>
          </Centered>
        )}
      </Card>
    </Section>
  );
};
