import { ButtonBase, Popover } from '@material-ui/core';
import React, { RefObject } from 'react';
import { FilterDefinition } from '../../../../domainTypes/filters';
import { Metric } from '../../../../services/analyticsV2/metrics';
import { PlaceholderChip } from '../Chip';
import { Dimension, initFilterValue } from '../filters';
import { useFilters } from '../hooks';
import { And } from './And';
import { FilterMenuBody } from './FilterMenuBody';
import {
  AnalyticsFilter,
  ISOTimeRange
} from '../../../../domainTypes/analytics_v2';
import { FilterDrawerRenderContext } from './types';

interface FilterPlaceholderProps {
  anchorRef: RefObject<HTMLDivElement>;
  dimension: Dimension;
  addFilter: (d: FilterDefinition) => void;
  clear: () => void;
  range: ISOTimeRange;
  orderBy: Metric;
  baseFilters: AnalyticsFilter[];
  renderContext?: FilterDrawerRenderContext;
}

export const FilterPlaceholder: React.FC<FilterPlaceholderProps> = ({
  dimension,
  addFilter,
  clear,
  anchorRef,
  orderBy,
  range,
  baseFilters,
  renderContext
}) => {
  const definition = initFilterValue(dimension);
  const filterDefinitions = useFilters();
  const isFirst = filterDefinitions.length === 0;
  return (
    <>
      {!isFirst ? <And /> : null}
      <ButtonBase>
        <PlaceholderChip dimension={dimension} />
      </ButtonBase>
      <Popover
        open={true}
        onClose={clear}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        style={{ marginTop: 10 }}
      >
        <FilterMenuBody
          initialDefinition={definition}
          onSave={addFilter}
          orderBy={orderBy}
          range={range}
          baseFilters={baseFilters}
          renderContext={renderContext}
        />
      </Popover>
    </>
  );
};
