import { isEmpty, keyBy } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';

type Hotkey = {
  keys: string;
  handler: () => void;
  label?: string;
  description?: string;
};

const HotkeysContext = React.createContext<{
  register: (hotkeys: Hotkey[]) => () => void;
  activeHotkeys: Hotkey[];
}>({ register: () => () => undefined, activeHotkeys: [] });

export const useHotKeys = (hotkeys: Hotkey[], deps: any[] = []) => {
  const { register } = useContext(HotkeysContext);
  // Let dependencies purely be determined from the outside
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => register(hotkeys), deps);
};

function isIllegalHotkeyEvent(event: Event) {
  const target = event.target || event.srcElement;
  if (!target) {
    return true;
  }
  const { tagName } = target as HTMLElement;
  if (
    (target as any).isContentEditable ||
    tagName === 'TEXTAREA' ||
    ((tagName === 'INPUT' || tagName === 'TEXTAREA' || tagName === 'SELECT') &&
      !(target as any).readOnly!)
  ) {
    return true;
  }
  return false;
}

const ALIASES: { [key: string]: string } = {
  ArrowUp: 'up',
  ArrowDown: 'down',
  ArrowLeft: 'left',
  ArrowRight: 'right'
};

const normalizeKey = (k: string) => {
  return ALIASES[k] || k.toLowerCase();
};

export const HotkeysProvider: React.FC = ({ children }) => {
  const [activeHotkeys, setActiveHotkeys] = useState<Hotkey[]>([]);
  const asMap = useMemo(() => keyBy(activeHotkeys, h => h.keys), [
    activeHotkeys
  ]);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (isEmpty(asMap) || isIllegalHotkeyEvent(e)) {
        return;
      }
      const keys: string[] = [];
      if (e.ctrlKey) {
        keys.push('ctrl');
      }
      if (e.metaKey) {
        keys.push('meta');
      }
      if (e.altKey) {
        keys.push('alt');
      }
      if (e.shiftKey) {
        keys.push('shift');
      }
      keys.push(normalizeKey(e.key));
      const hotkey = asMap[keys.join('+')];
      if (hotkey) {
        hotkey.handler();
      }
    };
    document.addEventListener('keyup', listener);
    return () => document.removeEventListener('keyup', listener);
  }, [asMap]);

  const register = (hotkeys: Hotkey[]) => {
    // check if we already have such a key combination registered
    // and print a warning if need be.
    setActiveHotkeys(active => [...active, ...hotkeys]);

    return () => {
      setActiveHotkeys(active => {
        hotkeys.forEach(h => {
          const i = active.indexOf(h);
          if (i !== -1) {
            active.splice(i, 1);
          }
        });
        return active;
      });
    };
  };

  return (
    <HotkeysContext.Provider value={{ register, activeHotkeys }}>
      {children}
    </HotkeysContext.Provider>
  );
};
