import React from 'react';
import { AlertTriangle } from 'react-feather';
import { Typography } from '@material-ui/core';
import { Doc } from '../../../../../domainTypes/document';
import { LinkCheckNewIssues } from '../../../../../domainTypes/notifications';
import { useRoutes } from '../../../../../routes';
import { pluralize } from '../../../../../services/pluralize';
import { Container } from '../../Item/Container';
import { Body, Frame } from '../../Item/Frame';
import { Heading } from '../../Item/Heading';
import * as Icon from '../../Item/Icon';
import { AlertStatus, IAlertStatus } from '../../AlertStatus';

const QUERY_PARAMS = {
  issues: 'LINK_CHECK',
  partners: 'amazon',
  sort: 'clicked---desc'
};

export const LinkCheck_NewIssues = ({ n }: { n: Doc<LinkCheckNewIssues> }) => {
  const { ROUTES } = useRoutes();
  return (
    <Frame n={n} href={ROUTES.links.amazonIssues.url(QUERY_PARAMS)}>
      <Container>
        <Icon.Container>
          <AlertTriangle size={Icon.SIZE} />
        </Icon.Container>
        <Body>
          <Heading>Amazon Link Check</Heading>{' '}
          <AlertStatus status={IAlertStatus.WARNING} />
          <Typography color="textSecondary" variant="body2" component="p">
            Our latest Amazon link check uncovered{' '}
            {pluralize('link', n.data.data.newIssues, true)} with new issues on
            your website.
          </Typography>
        </Body>
      </Container>
    </Frame>
  );
};
