import { Dialog, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { IPartner } from '../../../../domainTypes/partners';
import { styled } from '../../../../emotion';
import { RightPanel } from './RightPanel';
import { StyledContent } from './commons';
import { PartnerSelector } from './PartnerSelector';

const ImportDialogWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
`;

const ImportDialogLeftColumn = styled('div')`
  background-color: ${(p) => p.theme.palette.grey[200]};
  overflow-y: scroll;
  height: 650px;
  color: ${(p) => p.theme.palette.text.primary};
`;

const ImportDialogRightColumn = styled('div')`
  overflow-y: scroll;
  height: 650px;
`;

export function ImportDialog({
  open,
  onClose,
  preselectedPartnerKey,
  currency
}: {
  open: boolean;
  onClose: () => void;
  preselectedPartnerKey?: string;
  currency: CurrencyCode;
}) {
  const [selectedPartner, setSelectedPartner] = useState<
    IPartner['key'] | null
  >(preselectedPartnerKey || null);
  const [dialogStyle, setDialogStyle] = useState<'md' | 'lg'>('md');

  const close = () => {
    onClose();
    setSelectedPartner(null);
    setDialogStyle('md');
  };

  return (
    <Dialog open={open} onClose={close} maxWidth={dialogStyle} fullWidth>
      <ImportDialogWrapper>
        <ImportDialogLeftColumn>
          <DialogTitle>Connect or upload your reports</DialogTitle>
          <StyledContent>
            <PartnerSelector
              selectedPartner={selectedPartner}
              setSelectedPartner={setSelectedPartner}
            />
          </StyledContent>
        </ImportDialogLeftColumn>
        <ImportDialogRightColumn>
          <RightPanel
            close={close}
            currency={currency}
            selectedPartner={selectedPartner}
            selectPartner={setSelectedPartner}
            setDialogStyle={setDialogStyle}
          />
        </ImportDialogRightColumn>
      </ImportDialogWrapper>
    </Dialog>
  );
}
