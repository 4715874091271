import React from 'react';
import { styled } from '../emotion';

type BlobColor = 'green';

const Wrapper = styled<'span', { blobColor: BlobColor }>('span')`
  display: inline-block;
  .blob {
    display: inline-block;
    background: #73d13d;
    border-radius: 50%;
    margin: 10px 10px 2px 10px;
    height: 12px;
    width: 12px;

    box-shadow: 0 0 0 0 rgba(115, 209, 61, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(115, 209, 61, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(115, 209, 61, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(115, 209, 61, 0);
    }
  }
`;

export const PulsingCircle = ({ color }: { color: BlobColor }) => (
  <Wrapper blobColor={color}>
    <span className="blob" />
  </Wrapper>
);
