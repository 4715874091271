import React from 'react';
import {
  CampaignLink,
  CampaignOffsiteLink,
  CampaignOnsiteLink,
  ICampaign,
  isOffsiteLink,
  isOnsiteLink
} from '../../../../domainTypes/campaigns';
import { useCurrentUser } from '../../../../services/currentUser';
import { Caption, Header, Stack } from './form-components';
import { css } from '../../../../emotion';
import { ProductLinkCellWithPartnerLazy } from '../../../Links/pages/Overview/components/ProductLinkCell';
import { Button, Card, CardActions, CardContent } from '@material-ui/core';
import { RowsRenderer } from '../../../../components/GroupableList';
import { IColumn } from '../../../../components/Table/Column';
import { ChannelIdLabel } from '../../../../services/channels/ChannelLabel';

const nameColumn: IColumn<CampaignLink, 'name', { spaceId: string }> = {
  key: 'name',
  head: () => 'Link name or deep link',
  cell: (link: CampaignLink, { spaceId }) => (
    <ProductLinkCellWithPartnerLazy spaceId={spaceId} productId={link.id} />
  ),
  width: 200,
  align: 'left',
  sortable: false
};

const OnsiteLinkColumns: Array<IColumn<
  CampaignOnsiteLink,
  'name' | 'pageUrls',
  { spaceId: string }
>> = [
  nameColumn,
  {
    key: 'pageUrls',
    head: () => 'Page URL(s)',
    cell: (link: CampaignOnsiteLink) => (
      <Stack spacing={0.5}>
        {link.pageUrls.map((url) => (
          <Caption>{url}</Caption>
        ))}
      </Stack>
    ),
    width: 200,
    align: 'left',
    sortable: false
  }
];

const OffsiteLinkColumns: Array<IColumn<
  CampaignOffsiteLink,
  'name' | 'channels',
  { spaceId: string }
>> = [
  nameColumn,
  {
    key: 'channels',
    head: () => 'Channels',
    cell: (link: CampaignOffsiteLink) => (
      <Stack spacing={0.5}>
        {link.channels.map((channelId) => (
          <ChannelIdLabel channelId={channelId} />
        ))}
      </Stack>
    ),
    width: 200,
    align: 'left',
    sortable: false
  }
];

const OnsiteLinksTable = ({ links }: { links: Array<CampaignOnsiteLink> }) => {
  const { space } = useCurrentUser();
  return (
    <RowsRenderer
      rows={links}
      columns={OnsiteLinkColumns}
      sorter={{
        key: 'name',
        items: {
          sort: (link) => link.id,
          dir: 'asc'
        }
      }}
      otherProps={{ spaceId: space.id }}
      rowToKey={(link) => link.id}
      variant={'plain'}
      renderHead
    />
  );
};

const OffsiteLinksTable = ({
  links
}: {
  links: Array<CampaignOffsiteLink>;
}) => {
  const { space } = useCurrentUser();
  return (
    <RowsRenderer
      rows={links}
      columns={OffsiteLinkColumns}
      sorter={{
        key: 'name',
        items: {
          sort: (link) => link.id,
          dir: 'asc'
        }
      }}
      otherProps={{ spaceId: space.id }}
      rowToKey={(link) => link.id}
      variant={'plain'}
      renderHead
    />
  );
};

export const CampaignPreview: React.FC<{
  campaign: ICampaign;
  back: () => void;
  onSuccess: () => void;
}> = ({ campaign, back, onSuccess }) => {
  const campaignOnsiteLinks = campaign.links.filter(isOnsiteLink);
  const campaignOffsiteLinks = campaign.links.filter(isOffsiteLink);
  return (
    <Card>
      <CardContent
        className={css(() => ({
          height: 'calc(70vh)',
          overflowY: 'auto',
          marginBottom: 'auto'
        }))}
      >
        <Stack spacing={3}>
          <Header>CAMPAIGN CONTENT</Header>
          <Stack spacing={0.5}>
            {campaign.pageUrls.map((url) => (
              <Caption underline>{url}</Caption>
            ))}
          </Stack>

          <Header>CAMPAIGN LINKS</Header>
          {campaignOnsiteLinks.length > 0 && (
            <>
              <Caption>Onsite links</Caption>
              <OnsiteLinksTable links={campaignOnsiteLinks} />
            </>
          )}
          {campaignOffsiteLinks.length > 0 && (
            <>
              <Caption>Offsite links</Caption>
              <OffsiteLinksTable links={campaignOffsiteLinks} />
            </>
          )}
        </Stack>
      </CardContent>
      <CardActions>
        <Button variant="contained" color="primary" onClick={onSuccess}>
          Finalize campaign
        </Button>
        <Button onClick={back}>Back to links</Button>
      </CardActions>
    </Card>
  );
};
