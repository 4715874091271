import { groupBy, partition, sortBy } from 'lodash';
import { AssignTagsParams, IPostgresTags } from '../../domainTypes/tags';
import { usePromise } from '../../hooks/usePromise';
import { useCurrentUser } from '../currentUser';

import {
  LoadingValue,
  LoadingValueExtended,
  useMappedLoadingValue
} from '../db';
import { callFirebaseFunction } from '../firebaseFunctions';
import {
  deleteTagFromSpace,
  getTagsCacheforSpace,
  updateTagsForSpace,
  updateTagsPerPageCacheForSpace,
  useTagsCacheContext
} from './cache';
import { CF } from '../../versions';
import {
  isAuthorsTagGroup,
  isAutomaticTagGroup,
  isFromAutomaticGroup
} from '../../domainTypes/tag';
import { useAdminOrImpersonatorClaim } from '../auth';
import { useFeatureEnabled } from '../features';
import { useCallback } from 'react';

type TagsResponse = {
  time: number;
  spaceId: string;
  res: IPostgresTags[];
};

export const useTags = (): LoadingValueExtended<IPostgresTags[]> => {
  const { space } = useCurrentUser();
  const spaceId = space.id;
  const { version } = useTagsCacheContext();
  return usePromise(() => getTags(spaceId), [spaceId, version]);
};

export const useTagsForCurrentUser = (): LoadingValue<IPostgresTags[]> => {
  const { space } = useCurrentUser();
  const spaceId = space.id;
  const [canViewAutomaticTags] = useAdminOrImpersonatorClaim();
  const areAuthorTagsEnabled = useFeatureEnabled('AUTHOR_TAGS');
  const showAuthorsLink = canViewAutomaticTags || areAuthorTagsEnabled;
  const filterTags = useCallback(
    (tags: IPostgresTags[]) => {
      return showAuthorsLink
        ? tags
        : tags.filter(
            (tag) =>
              !isAutomaticTagGroup(tag, spaceId) &&
              !isFromAutomaticGroup(tag, spaceId)
          );
    },
    [showAuthorsLink, spaceId]
  );
  return useMappedLoadingValue(useTags(), filterTags);
};

export type IPostgresTagsParent = {
  parent: IPostgresTags;
  children: IPostgresTags[];
};

export const useTagHierarchy = (): LoadingValue<IPostgresTagsParent[]> => {
  return useMappedLoadingValue(useTagsForCurrentUser(), (tags) => {
    const sortedTags = sortBy(tags, (t) => t.name);
    const [parentTags, childTags] = partition(sortedTags, (t) => !t.parent_id);
    const childTagsByParentId = groupBy(childTags, (t) => t.parent_id);
    return parentTags.map((tag) => ({
      parent: tag,
      children: childTagsByParentId[tag.id] || []
    }));
  });
};

export const useUserTagGroups = (): LoadingValue<IPostgresTags[]> => {
  const {
    space: { id }
  } = useCurrentUser();
  return useMappedLoadingValue(useTagsForCurrentUser(), (tags) => {
    return tags.filter(
      (tag) =>
        // We exclude authors there. They have special component and link.
        tag.parent_id === null && !isAuthorsTagGroup(tag, id)
    );
  });
};

export const assignTags = async (data: AssignTagsParams) => {
  return callFirebaseFunction<{
    time: number;
    spaceId: string;
    changes: {
      [protocollessUrl: string]: {
        added: string[];
        removed: string[];
      };
    };
    res: IPostgresTags[];
  }>(CF.tags.assignTags, data).then((r) => {
    console.log('ASSIGN_SUCCESS', r);
    updateTagsPerPageCacheForSpace(data.spaceId, r.changes);
    return r;
  });
};

export const deleteTag = async (spaceId: string, tagId: string) => {
  return callFirebaseFunction<{
    time: number;
    spaceId: string;
    res: IPostgresTags[];
  }>(CF.tags.deleteTag, {
    spaceId,
    tagId
  }).then((r) => {
    deleteTagFromSpace(spaceId, tagId);
    return r;
  });
};

export const updateTag = async (spaceId: string, tag: IPostgresTags) => {
  return callFirebaseFunction<{
    time: number;
    spaceId: string;
    res: IPostgresTags[];
  }>(CF.tags.updateTag, {
    spaceId,
    tag
  }).then((r) => {
    updateTagsForSpace(spaceId, r.res);
    return r;
  });
};

export const createTags = async (spaceId: string, tags: IPostgresTags[]) => {
  return callFirebaseFunction<{
    time: number;
    spaceId: string;
    res: IPostgresTags[];
  }>(CF.tags.createTags, {
    spaceId,
    tags
  }).then((r) => {
    updateTagsForSpace(spaceId, r.res);
    return r;
  });
};

export const getTags = async (spaceId: string): Promise<IPostgresTags[]> => {
  const cache = getTagsCacheforSpace(spaceId);
  if (!cache.tags) {
    const tagsResp = await callFirebaseFunction<TagsResponse>(CF.tags.getTags, {
      spaceId
    });

    cache.tags = Object.fromEntries(tagsResp.res.map((tag) => [tag.id, tag]));
  }
  return Object.values(cache.tags);
};
