import { styled } from '../../emotion';
import { Button, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { AlertBox } from '../../components/AlertBox';

type IProps = {
  onSubmit: (email: string) => Promise<any>;
  onCancel?: () => void;
};

const FormRow = styled('div')`
  display: grid;
  grid-template-columns: 1fr 200px;
  grid-column-gap: ${(p) => p.theme.spacing(1)}px;
  margin-top: ${(p) => p.theme.spacing(3)}px;
  align-items: center;

  button {
    height: 56px;
  }
`;

export const ResetPassword = ({ onSubmit, onCancel }: IProps) => {
  const [email, setEmail] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [hasSuccess, setSuccess] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    return onSubmit(email)
      .then((resp) => {
        setSuccess(true);
        setErrorCode('');
      })
      .catch((resp) => {
        setSuccess(false);
        const { code } = resp;
        setErrorCode(code);
      });
  };

  return (
    <div>
      {errorCode && (
        <AlertBox variant="error">
          {errorCode === 'auth/user-not-found'
            ? `We did not find a user with that email in our system!
              Please try a different email address or create an account`
            : `Hm, for some reason we could not reset your password.
              Want to get in touch?`}
        </AlertBox>
      )}
      {hasSuccess && (
        <AlertBox variant="success">{`We've sent a link to reset your password to ${email}!`}</AlertBox>
      )}
      {hasSuccess ? (
        <Button
          onClick={() => onCancel && onCancel()}
          style={{ marginTop: '24px', float: 'right' }}
          variant="contained"
          color="default"
        >
          Done
        </Button>
      ) : (
        <div>
          <Typography>
            Just give us your email address and we'll send you an email to reset
            your password straight away.
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormRow>
              <TextField
                required
                label="Your email"
                name="email"
                type="email"
                autoComplete="email"
                variant="outlined"
                fullWidth={true}
                margin="none"
                onChange={(e) => {
                  e.stopPropagation();
                  setEmail(e.target.value);
                }}
              />
              <Button type="submit" variant="contained" color="primary">
                Reset password
              </Button>
            </FormRow>
          </form>
        </div>
      )}
    </div>
  );
};
