import { styled } from '../../../emotion';
import { Chip } from '@material-ui/core';
import React from 'react';
import { Campaign } from '../service';
import { Calendar, Feather, Maximize, Target } from 'react-feather';
import { CampaignGoal } from '../../../domainTypes/campaigns';
import pluralize from 'pluralize';
import { useSpaceCurrency } from '../../../services/useSpaceCurrency';
import {
  formatChartCurrency,
  formatChartNumber
} from '../../../components/Charts/Util';

const TagChip = styled(Chip)`
  border: ${(t) => `1px solid ${t.theme.palette.grey['200']}`} !important;
  color: ${(t) => t.theme.palette.grey['600']} !important;
  height: 30px !important;

  span {
    padding: 2px 6px !important;
  }

  svg {
    color: ${(t) => t.theme.palette.grey['400']} !important;
    margin-right: 0 !important;
    margin-left: 6px !important;
  }
`;

export const CampaignDateChip: React.FC<{ campaign: Campaign }> = ({
  campaign
}) => {
  if (campaign.timeframe === undefined) {
    return <TagChip label="Not set" icon={<Calendar size={16} />} />;
  }

  return (
    <TagChip
      label={`${campaign.timeframe.start.format(
        'MMM D'
      )} - ${campaign.timeframe.end.format('MMM D')}`}
      icon={<Calendar size={16} />}
    />
  );
};

export const CampaignGoalChip: React.FC<{ goal: CampaignGoal }> = ({
  goal
}) => {
  const currency = useSpaceCurrency();
  const icon = <Target size={16} />;
  switch (goal.type) {
    case 'roas':
      return <TagChip label={`${goal.amount}x ROAS`} icon={icon} />;
    case 'gmv':
      return (
        <TagChip
          label={`${formatChartCurrency(goal.amount / 100, currency, {
            excludeCents: true
          })} GMV`}
          icon={icon}
        />
      );
    case 'clicks':
      return (
        <TagChip
          label={`${formatChartNumber(goal.amount)} clicks`}
          icon={icon}
        />
      );
    case 'pageviews':
      return (
        <TagChip
          label={`${formatChartNumber(goal.amount)} pageviews`}
          icon={icon}
        />
      );
  }
};

export const CampaignArticlesChip: React.FC<{ campaign: Campaign }> = ({
  campaign
}) => {
  const count = campaign.pageUrls.length;
  if (count === 0) return null;
  return (
    <TagChip
      label={pluralize('article', count, true)}
      icon={<Feather size={16} />}
    />
  );
};

export const CampaignPlacementsChip: React.FC<{ campaign: Campaign }> = ({
  campaign
}) => {
  const count = campaign.links.length;
  if (count === 0) return null;
  return (
    <TagChip
      label={pluralize('placement', count, true)}
      icon={<Maximize size={16} />}
    />
  );
};
