import { FormControl, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import moment from 'moment-timezone';
import { useId } from '../../hooks/useId';

type Props = {
  value: string;
  onChange: (nextValue: string) => void;
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
};

const timezones = moment.tz.names();

export const SelectTimezone: React.FC<Props> = ({
  value,
  onChange,
  label = '',
  fullWidth = false,
  disabled = false
}) => {
  const id = useId();
  return (
    <FormControl style={{ width: fullWidth ? '100%' : 'auto' }}>
      <TextField
        select
        id={id}
        label={label}
        value={value}
        fullWidth={fullWidth}
        disabled={disabled}
        variant="outlined"
        onChange={(ev) => {
          onChange(ev.target.value as string);
        }}
      >
        {timezones.map((c) => (
          <MenuItem key={c} value={c}>
            {c}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};
