import { Button, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { ButtonWithPromise } from '../../../../../../components/ButtonWithPromise';
import { Doc } from '../../../../../../domainTypes/document';
import { IProduct } from '../../../../../../domainTypes/product';
import { styled } from '../../../../../../emotion';
import * as tracking from '../../../../../../tracking';
import { FS } from '../../../../../../versions';

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: ${(p) => p.theme.spacing() * 2}px;
`;

type Props = {
  product: Doc<IProduct>;
  onSubmit: (nextProduct: Doc<IProduct>) => Promise<any>;
  onDeleteSuccess: () => void;
  onCancel: () => void;
  noNameEdits?: boolean;
};

export const ProductEditForm: React.FC<Props> = ({
  product,
  onSubmit,
  onDeleteSuccess,
  onCancel,
  noNameEdits
}) => {
  const { id, data } = product;
  const [name, setName] = useState(data.name);
  const [url, setUrl] = useState(data.url);

  const submit = () => {
    tracking.sendEvent({
      category: tracking.toAppCategory(),
      action: 'Submit',
      label: 'Edit product form'
    });

    return onSubmit({
      id,
      collection: FS.products,
      data: {
        ...data,
        name,
        url
      }
    })
      .then(() => {
        tracking.sendEvent({
          category: tracking.toAppCategory(),
          action: 'Edit product',
          label: `${name} (${url})`
        });
      })
      .catch(() => {
        tracking.sendEvent({
          category: tracking.toAppCategory(),
          action: 'Edit product (error)',
          label: `${name} (${url})`
        });
      });
  };

  return (
    <div>
      {!noNameEdits && (
        <TextField
          required
          label="Name"
          margin="normal"
          fullWidth={true}
          value={name}
          variant="outlined"
          onChange={(e) => setName(e.target.value)}
        />
      )}
      <TextField
        required
        label="Affiliate Link URL"
        margin="normal"
        variant="outlined"
        fullWidth={true}
        multiline
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        helperText="This will not edit the affiliate link on your website. Change the URL only if you want to keep the performance history of this link and you have already updated the links on your website"
      />
      <ButtonContainer>
        <div>
          <Button onClick={onCancel}>Cancel</Button>
          <ButtonWithPromise
            type="submit"
            variant="contained"
            color="primary"
            disabled={(data.name === name && data.url === url) || !name || !url}
            onClick={submit}
            pending="Updating..."
          >
            Update
          </ButtonWithPromise>
        </div>
      </ButtonContainer>
    </div>
  );
};
