import { Card, CardContent, Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import {
  isInTrial,
  isInTrialGracePeriod,
  isInTrialIncludingGracePeriod,
  isUsingForFree
} from '../../../../domainTypes/billing';
import { ISpace } from '../../../../domainTypes/space';
import { css, styled } from '../../../../emotion';
import { CanvasBar } from '../../../../layout/Canvas';
import { Centered } from '../../../../layout/Centered';
import { LimitedWidth } from '../../../../layout/PageBody';
import { Section } from '../../../../layout/Section';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { CheckoutForm } from '../../../Billing/components/CheckoutForm';
import { Helmet } from 'react-helmet';
import { SettingsLayout } from '../../SettingsLayout';
import { NoPermissions } from '../../../../components/NoPermissions';
import { Subscription } from './Subscription';

type SpaceProps = {
  space: ISpace;
};

type TrialProps = SpaceProps & {
  onCheckoutSuccess: () => void;
};

const Img = styled('img')`
  width: 300px;
  display: block;
  margin: 0 auto;
`;

const Header: React.FC<{ imgSrc?: string }> = ({ imgSrc, children }) => {
  return (
    <>
      {imgSrc && (
        <Centered className={css((t) => ({ marginTop: t.spacing(3) }))}>
          <Img src={imgSrc} />
        </Centered>
      )}
      <Centered>
        <h2>{children}</h2>
      </Centered>
    </>
  );
};

const Free = () => {
  return (
    <Section>
      <Card>
        <CardContent>
          <Header imgSrc="/images/super_thankyou.svg">Free Plan</Header>
          <Centered>
            <p>
              As a thank you to our first users, you can use Affilimate for
              free!
            </p>
          </Centered>
        </CardContent>
      </Card>
    </Section>
  );
};

const TrialStatusSection = ({ space }: SpaceProps) => {
  const now = Date.now();
  if (!space.billing.trialUntil) {
    // We will reach this right after we checked out successfully.
    // We'll just show nothing, as a dialog will appear to indicate success.
    // Once the dialog is acknowledged, we move on to another state.
    return null;
  }

  const inTrial = isInTrial(space.billing, now);
  const inGracePeriod = isInTrialGracePeriod(space.billing, now);
  const lockedOut = !inTrial && !inGracePeriod;
  const { trialUntil, gracePeriod } = space.billing;
  return (
    <Section>
      {inGracePeriod && (
        <Card>
          <Typography variant="h6">
            <strong>Trial Expired</strong>
          </Typography>
          <CardContent>
            <Centered>
              <div>
                Your free Affilimate trial expired{' '}
                {moment(trialUntil).fromNow()}.{' '}
                <strong>
                  Your account will be inaccessible on{' '}
                  {moment(trialUntil + gracePeriod).format('YYYY/MM/DD HH:mm')}.
                </strong>
              </div>
            </Centered>
          </CardContent>
        </Card>
      )}
      {lockedOut && (
        <Card>
          <Typography variant="h6">
            <strong>Locked out</strong>
          </Typography>
          <CardContent>
            <Centered>{}</Centered>
          </CardContent>
        </Card>
      )}
    </Section>
  );
};

const Trial = ({ space, onCheckoutSuccess }: TrialProps) => {
  return (
    <>
      <TrialStatusSection space={space} />
      <Section>
        <CanvasBar>Checkout</CanvasBar>
        <Card>
          <CheckoutForm onSuccess={onCheckoutSuccess} />
        </Card>
      </Section>
    </>
  );
};

const Body = () => {
  const { space } = useCurrentUser();
  const { billing } = space;
  const now = Date.now();

  // This looks a bit non-sensical - why hold this state locally?
  // We want to be able to move away from this state MANUALLY, after
  // the user completed their checkout and us showing them a nice success state
  const [inTrial, setInTrial] = useState(
    isInTrialIncludingGracePeriod(billing, now)
  );

  if (isUsingForFree(billing)) {
    return <Free />;
  }

  if (inTrial) {
    return <Trial space={space} onCheckoutSuccess={() => setInTrial(false)} />;
  }

  return <Subscription space={space} />;
};

export const PagesSettingsNewBilling = () => {
  const [canViewBilling] = useHasCurrentUserRequiredScopes(['billing.view']);

  return (
    <SettingsLayout>
      <Helmet>
        <title>Billing Settings | Affilimate</title>
      </Helmet>
      <LimitedWidth width={750}>
        {canViewBilling ? <Body /> : <NoPermissions />}
      </LimitedWidth>
    </SettingsLayout>
  );
};
