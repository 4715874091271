import { IconButton, Tooltip } from '@material-ui/core';
import {
  ShoppingBag as IconProduct,
  CornerUpLeft as IconAccepted,
  X as IconRemove,
  FileText as IconPage,
  Link as IconLink
} from 'react-feather';
import React from 'react';
import { styled } from '../../../../../../emotion';
import { IRow } from '../../index';
import { LinkScore } from '../LinkScore';
import { ToolbarElements } from '../Toolbar';
import { useMediaQuery } from '../../../../../../hooks/useMediaQuery';

const Row = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${(p) => p.theme.spacing() * 3}px;
  font-size: ${(p) => p.theme.custom.fontSize.s}px;
  cursor: pointer;

  &:hover {
    background-color: ${(p) => p.theme.palette.grey.A200};
  }

  a {
    display: inline-block;
    color: ${(p) => p.theme.palette.text.primary};
    text-decoration: underline;
    margin-right: ${(p) => p.theme.spacing() * 2};
  }
`;

const RowCell = styled<'div', { flex?: number }>('div')`
  display: flex;
  align-items: center;
  flex: ${(p) => p.flex || 0};
`;

const CounterContainer = styled('span')`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.palette.text.secondary};
  min-width: 50px;
`;

const Counter = ({
  icon,
  count,
  tooltip
}: {
  icon: React.ReactNode;
  count: number;
  tooltip: string;
}) => {
  if (count === 0) {
    return null;
  }
  return (
    <Tooltip title={tooltip}>
      <CounterContainer>
        {icon} &nbsp; {count}
      </CounterContainer>
    </Tooltip>
  );
};

export const RowSummary = ({
  row,
  expanded,
  onExpand,
  onMove,
  currentView,
  disabled
}: {
  row: IRow;
  expanded: boolean;
  onExpand: () => void;
  onMove: (domainGroup: IRow) => void;
  disabled?: boolean;
  currentView: ToolbarElements;
}) => {
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <Row role="button" onClick={onExpand} title="Expand row">
      <RowCell flex={2}>{row.name}</RowCell>
      {!isMobile && (
        <RowCell flex={2}>
          <Counter
            icon={<IconProduct size={18} />}
            count={row.data.length}
            tooltip={`${row.data.length} unique products`}
          />

          <Counter
            icon={<IconLink size={18} />}
            count={row.timesFound}
            tooltip={`${row.timesFound} links found`}
          />
          <Counter
            icon={<IconPage size={18} />}
            count={row.pages.size}
            tooltip={`Found on ${row.pages.size} pages`}
          />
        </RowCell>
      )}
      <RowCell flex={2}>
        <LinkScore score={row.score} />
      </RowCell>
      {currentView !== 'knownProducts' && !disabled && (
        <RowCell style={{ textAlign: 'right' }}>
          <Tooltip
            title={currentView === 'list' ? 'Ignore link' : 'Restore link'}
            placement="top"
          >
            <IconButton
              color="default"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                onMove(row);
              }}
            >
              {currentView === 'list' ? <IconRemove /> : <IconAccepted />}
            </IconButton>
          </Tooltip>
        </RowCell>
      )}
    </Row>
  );
};
