import { styled } from '../emotion';

export const Spacer = styled('div')<{
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  marginBottom?: number;
}>((p) => ({
  width: '100%',
  marginTop: p.theme.spacing(p.marginTop || 0),
  marginLeft: p.theme.spacing(p.marginLeft || 0),
  marginRight: p.theme.spacing(p.marginRight || 0),
  marginBottom: p.theme.spacing(p.marginBottom || 0)
}));
