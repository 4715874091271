import {
  Chip,
  Button,
  capitalize,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton
} from '@material-ui/core';
import { compact } from 'lodash';
import React from 'react';
import { Plus, Trash } from 'react-feather';
import { IChannel, IChannelType } from '../../../domainTypes/channels';
import { Doc } from '../../../domainTypes/document';
import { styled } from '../../../emotion';
import {
  toChannelIcon,
  toChannelId,
  toChannelUrlLabel
} from '../../../services/channels';
import { updateDataInDoc } from '../../../services/db';
import { ConnectionId } from '../../ConnectionId';
import { useCurrentUserScopes } from '../../../services/currentUser';

const PREFIX_MAX_LENGTH = 6;

const ChannelTypeMenuItem = styled('span')`
  display: grid;
  grid-template-columns: 18px 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(2)}px;
  align-items: center;
`;

const CHANNEL_TYPES = [
  IChannelType.Email,
  IChannelType.Facebook,
  IChannelType.Instagram,
  IChannelType.Twitter,
  IChannelType.Youtube,
  IChannelType.Flipboard,
  IChannelType.Pinterest,
  IChannelType.Tiktok,
  IChannelType.App,
  IChannelType.Other
] as const;

const ChannelTypeSelector = ({
  channel,
  setChannel,
  mode
}: {
  channel: Doc<IChannel>;
  setChannel: (channelType: Doc<IChannel>) => void;
  mode?: 'create' | 'edit';
}) => {
  const onUpdateChannelType = (e: React.ChangeEvent<{ value: unknown }>) => {
    const type = e.target.value as IChannelType;
    const channelId =
      mode === 'create'
        ? toChannelId(type, channel.data.name)
        : channel.data.channelId;
    const nextChannel: IChannel =
      type === IChannelType.Email
        ? {
            ...channel.data,
            channelId,
            type
          }
        : {
            url: '',
            ...channel.data,
            channelId,
            type
          };

    setChannel(updateDataInDoc(channel, nextChannel));
  };

  return (
    <Select
      value={channel.data.type}
      variant="outlined"
      fullWidth
      onChange={onUpdateChannelType}
    >
      {CHANNEL_TYPES.map((type) => (
        <MenuItem key={type} value={type}>
          <ChannelTypeMenuItem>
            {toChannelIcon(type)}
            <span>{capitalize(type)}</span>
          </ChannelTypeMenuItem>
        </MenuItem>
      ))}
    </Select>
  );
};
export const OffsiteChannelForm = ({
  allChannels,
  channel,
  setChannel,
  onCancel,
  onSaveChannel,
  onDeleteChannel,
  mode
}: {
  allChannels: Doc<IChannel>[];
  channel: Doc<IChannel>;
  setChannel: (channel: Doc<IChannel>) => void;
  onCancel: () => void;
  onSaveChannel: (channel: Doc<IChannel>) => void;
  onDeleteChannel?: (channel: Doc<IChannel>) => void;
  mode: 'create' | 'edit';
}) => {
  const scopes = useCurrentUserScopes();
  const canDeleteChannels = scopes.has('channels.delete');
  const canCreateChannels = scopes.has('channels.create');
  const canEditChannels = scopes.has('channels.edit');
  const [newSubidMatch, setNewSubidMatch] = React.useState<string>('');
  const [subIdPrefix, setSubIdPrefix] = React.useState<string>(
    channel.data.subIdDefaultPrefix.slice(
      0,
      channel.data.subIdDefaultPrefix.length - 1
    )
  );
  const [hasSubIdPrefixError, setHasSubIdPrefixError] = React.useState<boolean>(
    false
  );
  const handleSubIdPrefixChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const subIdPrefixWithDash = value.endsWith('-') ? value : value + '-';
    setSubIdPrefix(value);
    setChannel({
      ...channel,
      data: {
        ...channel.data,
        subIdDefaultPrefix: subIdPrefixWithDash
      }
    });
    const isValid =
      value.length <= PREFIX_MAX_LENGTH && /^[a-z0-9-]$/.test(value);
    setHasSubIdPrefixError(isValid);
    setSubIdPrefix(value);
  };

  const addSubIdMatch = (subId: string) => {
    setChannel({
      ...channel,
      data: {
        ...channel.data,
        subIdMatches: compact([...channel.data.subIdMatches, subId])
      }
    });
    setNewSubidMatch('');
  };

  const hasDuplicateNameAndType = allChannels.some((c) => {
    return (
      c.data.name === channel.data.name &&
      c.data.type === channel.data.type &&
      c.id !== channel.id
    );
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSaveChannel(channel);
      }}
    >
      <FormControl fullWidth margin="normal">
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Channel type
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          What type of channel is this?
        </Typography>
        <ChannelTypeSelector
          mode={mode}
          channel={channel}
          setChannel={(channel) => {
            setChannel(channel);
          }}
        />
      </FormControl>
      <FormControl fullWidth margin="dense">
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Channel name
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          Name the channel without repeating the channel type, e.g. "Shopping
          Time" instead of "Shopping Time YouTube". Channel type will always be
          displayed next to the name.
        </Typography>
        <TextField
          label="Name"
          required
          value={channel.data.name}
          error={hasDuplicateNameAndType}
          helperText={
            hasDuplicateNameAndType
              ? 'A channel with this name and type already exists.'
              : ''
          }
          InputProps={{
            endAdornment: channel.data.name && (
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '12px'
                }}
              >
                <ConnectionId>{channel.data.channelId}</ConnectionId>
              </span>
            ),
            startAdornment: (
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '12px'
                }}
              >
                {toChannelIcon(channel.data.type)}
              </span>
            )
          }}
          variant="outlined"
          onChange={(e) => {
            setChannel({
              ...channel,
              data: {
                ...channel.data,
                name: e.target.value,
                channelId:
                  mode === 'create'
                    ? toChannelId(channel.data.type, e.target.value)
                    : channel.data.channelId
              }
            });
          }}
        />
      </FormControl>
      <FormControl fullWidth margin="dense">
        {channel.data.type !== IChannelType.Email && (
          <TextField
            label={toChannelUrlLabel(channel.data.type)}
            value={channel.data.url}
            required
            variant="outlined"
            onChange={(e) => {
              if (channel.data.type !== IChannelType.Email) {
                setChannel({
                  ...channel,
                  data: { ...channel.data, url: e.target.value }
                });
              }
            }}
          />
        )}
      </FormControl>
      <FormControl fullWidth margin="dense">
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Historical commission matching
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          How should we recognize this channel's historical commissions? Be as
          specific as possible to avoid incorrect matches. Matching is
          case-sensitive.
        </Typography>
        <TextField
          label="SubIDs matching..."
          value={newSubidMatch}
          placeholder="abc-"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <IconButton
                color="primary"
                disabled={!newSubidMatch}
                onClick={() => {
                  addSubIdMatch(newSubidMatch);
                }}
              >
                <Plus size={18} />
              </IconButton>
            )
          }}
          onBlur={() => {
            if (newSubidMatch) {
              addSubIdMatch(newSubidMatch);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              addSubIdMatch(newSubidMatch);
            }
          }}
          onChange={(e) => {
            setNewSubidMatch(e.target.value);
          }}
        />
        {channel.data.subIdMatches.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              marginTop: '6px',
              gap: '3px'
            }}
          >
            {channel.data.subIdMatches.map((subId) => (
              <Chip
                key={subId}
                color="default"
                label={subId}
                onDelete={() => {
                  setChannel({
                    ...channel,
                    data: {
                      ...channel.data,
                      subIdMatches: channel.data.subIdMatches.filter(
                        (s) => s !== subId
                      )
                    }
                  });
                }}
              />
            ))}
          </div>
        )}
      </FormControl>
      <FormControl fullWidth margin="dense">
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Link generation settings
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          Choose a short SubID prefix to be added in the link generator when
          this channel is selected. Usually an acronym or abbreviation of the
          channel name.
        </Typography>
        <TextField
          label="SubID default prefix"
          value={subIdPrefix}
          variant="outlined"
          placeholder="abc-"
          required
          InputProps={{
            endAdornment: channel.data.subIdDefaultPrefix && (
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '12px'
                }}
              >
                <ConnectionId>{channel.data.subIdDefaultPrefix}</ConnectionId>
              </span>
            )
          }}
          error={hasSubIdPrefixError}
          helperText={
            hasSubIdPrefixError
              ? `SubID prefix must be ${PREFIX_MAX_LENGTH} characters or less and contain only lowercase letters, numbers, and dashes.`
              : ''
          }
          inputProps={{
            maxLength: PREFIX_MAX_LENGTH
          }}
          onChange={handleSubIdPrefixChange}
        />
      </FormControl>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '18px'
        }}
      >
        {mode === 'edit' && onDeleteChannel && canDeleteChannels ? (
          <Button
            variant="text"
            color="default"
            startIcon={<Trash size={14} />}
            style={{ opacity: 0.5 }}
            onClick={() => {
              onDeleteChannel(channel);
            }}
          >
            Delete channel
          </Button>
        ) : (
          <div />
        )}
        <div>
          <Button variant="text" color="default" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={
              hasDuplicateNameAndType ||
              (mode === 'create' && !canCreateChannels) ||
              (mode === 'edit' && !canEditChannels)
            }
          >
            Save channel
          </Button>
        </div>
      </div>
    </form>
  );
};
