import isPropValid from '@emotion/is-prop-valid';
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography
} from '@material-ui/core';
import React, { createElement, useMemo, useState } from 'react';
import { Maximize as MaximizeIcon, X as CloseIcon } from 'react-feather';
import { styled } from '../../../emotion';

type Props = {
  heading?: string;
  subheading?: React.ReactNode;
  style?: React.CSSProperties;
  noMaximize?: boolean;
  centered?: boolean;
  maximizedContent?: React.ComponentType;
  topRight?: React.ReactNode;
  size?: 'small' | 'normal';
  padding?: 'dense' | 'normal';
};

const DialogContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 200px;
`;

const Body = styled('div')`
  margin-top: ${(p) => p.theme.spacing(4)}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCard = styled<typeof Card, { dense: boolean; center: boolean }>(
  Card,
  {
    shouldForwardProp: (props) => isPropValid(props)
  }
)`
  position: relative;
  text-align: ${(p) => (p.center ? 'center' : 'left')};
  ${(p) =>
    p.dense ? '' : `padding-bottom: ${p.theme.spacing(8)}px !important;`}

  .recharts-tooltip-wrapper {
    z-index: 2;
  }
`;

export const ChartCardFooter = styled('div')`
  position: absolute;
  background-color: ${(p) => p.theme.palette.common.white};
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid ${(p) => p.theme.palette.grey.A200};
  border-bottom-right-radius: ${(p) => p.theme.shape.borderRadius}px;
  border-bottom-left-radius: ${(p) => p.theme.shape.borderRadius}px;
  padding: ${(p) => p.theme.spacing(1)}px;
`;

export const ChartCardFooterBar = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ChartFooterBarItem = styled('div')`
  display: flex;
  align-items: center;
`;

export const ChartCardFooterBody = styled('div')`
  margin-top: ${(p) => p.theme.spacing()}px;
`;

type SelectOption<T> = { label: React.ReactNode; value: T };

type SelectProps<T> = {
  onChange: (nextValue: T) => any;
  value: T;
  options: SelectOption<T>[];
  color?: string;
};

const HEIGHT = 296;

const EmptyState = styled('div')`
  height: ${HEIGHT}px;
  display: flex;
  text-align: center;
  align-items: center;
  width: 100%;
`;

const EmptyStateInner = styled('div')`
  text-align: center;
  color: ${(p) => p.theme.palette.grey.A700};
`;

export const EmptyContent = () => (
  <EmptyState>
    <EmptyStateInner>
      <b>Nothing to see here</b>
      <p>
        You don't have any clicks for this time period. Try adjusting the date
        input to see clicks during a different time period.
      </p>
    </EmptyStateInner>
  </EmptyState>
);

const findValueIndex = <T extends {}>(options: SelectOption<T>[], value: T) => {
  const i = options.findIndex((o) => o.value === value);
  return i === -1 ? 0 : i;
};

const Legend = styled<'div', { color: string }>('div')`
  display: inline-block;
  border-radius: 100%;
  height: 18px;
  width: 18px;
  margin-right: 12px;
  background-color: ${(p) => p.color};
`;

const Form = styled('form')`
  margin-left: ${(p) => p.theme.spacing(1)}px;
  display: flex;
  align-items: center;
`;

export const ChartCardFooterSelect = <T extends {}>({
  onChange,
  value,
  color,
  options
}: SelectProps<T>) => (
  <Form autoComplete="off">
    {color && <Legend color={color} />}
    <FormControl>
      <Select
        value={findValueIndex(options, value)}
        disableUnderline
        onChange={(ev) => {
          onChange(options[Number(ev.target.value)].value);
        }}
        inputProps={{
          name: 'count',
          id: 'count'
        }}
      >
        {options.map((o, i) => (
          <MenuItem key={i} value={i}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Form>
);

export const ChartCardFooterSelectTopX = ({
  onChange,
  value,
  step = 5,
  max = 20,
  color
}: {
  value: number;
  onChange: (nextValue: number) => void;
  max?: number;
  step?: number;
  color?: string;
}) => {
  const options = useMemo(() => {
    let x = max;
    const res: { label: string; value: number }[] = [];
    while (x > 0) {
      res.unshift({ label: `Top ${x}`, value: x });
      x -= step;
    }
    return res;
  }, [step, max]);
  return (
    <ChartCardFooterSelect
      value={value}
      onChange={onChange}
      color={color}
      options={options}
    />
  );
};

const TopRightSection = styled<'div', { dense: boolean }>('div')`
  position: absolute !important;
  top: ${(p) => p.theme.spacing(p.dense ? 2 : 3)}px;
  right: ${(p) => p.theme.spacing(p.dense ? 2 : 3)}px;

  display: flex;
  align-items: center;
`;

const SmallHeading = styled('div')`
  font-size: ${(p) => p.theme.custom.fontSize.m}px;
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
  margin-bottom: ${(p) => p.theme.spacing()}px;
`;

export const ChartCard: React.FC<Props> = ({
  heading,
  subheading,
  children,
  style,
  noMaximize = false,
  centered = false,
  maximizedContent,
  topRight,
  size = 'normal',
  padding = 'normal'
}) => {
  const [open, setOpen] = useState(false);

  const body = (
    <>
      <TopRightSection dense={padding === 'dense'}>
        {topRight}
        {!noMaximize && (
          <Tooltip title="Expand" placement="top">
            <IconButton
              onClick={() => {
                setOpen(true);
              }}
            >
              <MaximizeIcon
                color="#47525dcc"
                size={size === 'small' ? 16 : undefined}
              />
            </IconButton>
          </Tooltip>
        )}
      </TopRightSection>
      {heading &&
        (size === 'small' ? (
          <SmallHeading>{heading}</SmallHeading>
        ) : (
          <Typography
            variant="body1"
            component="p"
            style={{ fontWeight: 'bold' }}
          >
            {heading}
          </Typography>
        ))}
      {subheading && (
        <Typography variant="body2" component="p" color="textSecondary">
          {subheading}
        </Typography>
      )}
      <Body>{children}</Body>
    </>
  );

  return (
    <StyledCard style={style} center={centered} dense={padding === 'dense'}>
      {padding === 'dense' ? (
        body
      ) : (
        <CardContent style={{ height: '100%' }}>{body}</CardContent>
      )}
      <Dialog
        open={open}
        fullScreen
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle
          disableTypography
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <div style={{ marginBottom: '48px' }}>
            <Typography
              variant="body1"
              component="p"
              style={{ fontWeight: 'bold' }}
            >
              {heading}
            </Typography>
          </div>
          <div>
            {topRight}
            <IconButton
              aria-label="close"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContainer>
            {maximizedContent ? createElement(maximizedContent) : children}
          </DialogContainer>
        </DialogContent>
      </Dialog>
    </StyledCard>
  );
};
