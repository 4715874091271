import React from 'react';
import { WithShape } from '../../../../../../components/Charts/Util';
import { COLORS } from '../services/groups';
import { ReferrerGroup } from '../../../../../../domainTypes/referrers';
import { toReferrerGroupTitle } from '../services/referrer-groups';

interface ReferrerGroupNameProps {
  group: ReferrerGroup;
}

export const ReferrerGroupName: React.FC<ReferrerGroupNameProps> = ({
  group
}) => (
  <WithShape color={COLORS[group]}>{toReferrerGroupTitle(group)}</WithShape>
);
