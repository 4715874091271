import { max } from 'lodash';
import { generateToDocFn } from '../../domainTypes/document';
import {
  RealtimeMonitorPageViews,
  RealtimeMonitorSales
} from '../../domainTypes/etl';
import { store, useCombineLoadingValues, useMappedLoadingValue } from '../db';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from '../firecache/documentListener';

const toRealtimeMonitorPageViewsDoc = generateToDocFn<
  RealtimeMonitorPageViews
>();
const pageViewsStore = createDocumentListenerGetter(
  (key) => store().collection('realtimeMonitorPageViewsV1').doc(key),
  toRealtimeMonitorPageViewsDoc,
  () => ({ lastUpdate: null, nextUpdateEstimated: null })
);

export const useRealtimeMonitorPageViews = () => {
  return useDocumentListener(pageViewsStore('GLOBAL'));
};

const toRealtimeMonitorSalesDoc = generateToDocFn<RealtimeMonitorSales>();
const salesStore = createDocumentListenerGetter(
  (spaceId) => store().collection('realtimeMonitorSalesV1').doc(spaceId),
  toRealtimeMonitorSalesDoc,
  (spaceId) => ({ spaceId, lastUpdate: null })
);

export const useRealtimeMonitorSales = (spaceId: string) => {
  return useDocumentListener(salesStore(spaceId));
};

export const useLastUpdate = (spaceId: string) => {
  return useMappedLoadingValue(
    useCombineLoadingValues(
      useRealtimeMonitorPageViews(),
      useRealtimeMonitorSales(spaceId)
    ),
    ([monitorPageViews, monitorSales]) => {
      const pv = (monitorPageViews || null)?.data.lastUpdate || '';
      const s = (monitorSales || null)?.data.lastUpdate || '';
      return max([pv, s]) || new Date().toISOString();
    }
  );
};

export const useNextUpdateEstimate = () => {
  const [monitorPageViews] = useRealtimeMonitorPageViews();
  return (
    (monitorPageViews && monitorPageViews.data.nextUpdateEstimated) || null
  );
};
