import { useCallback } from 'react';
import {
  ShortenLinkRequest,
  ShortenLinkResponse
} from '../../domainTypes/linkShortener';
import { callFirebaseFunction } from '../firebaseFunctions';

export const shortenLink = (
  args: ShortenLinkRequest
): Promise<ShortenLinkResponse> =>
  callFirebaseFunction<ShortenLinkResponse>('link_shortener-shorten', args);

export const useLinkShortener = () => {
  return useCallback(
    (args: ShortenLinkRequest) =>
      callFirebaseFunction<ShortenLinkResponse>('link_shortener-shorten', args),
    []
  );
};
