import { CampaignManagerFilterDefinition } from '../../../../../domainTypes/filters';
import { BASIC_MODES, FilterMenu } from '../FilterMenu';
import React, { useCallback, useMemo, useState } from 'react';
import { useCampaigns } from '../../../../../features/Campaigns/service';
import {
  useCombineLoadingValues,
  useMappedLoadingValue
} from '../../../../../services/db';
import { compact, isEqual, uniq } from 'lodash';
import {
  OptionsList,
  SelectorLoader,
  SelectorShell,
  toggle
} from '../Selector';
import {
  createCampaignManagerFilterDefinition,
  initFilterValue,
  validateFilterDefinition
} from '../../filters';
import pluralize from 'pluralize';
import { toSearchRegexp } from '../../../../SearchInput';
import { useUsersPerSpace } from '../../../../../services/user';
import { useCurrentUser } from '../../../../../services/currentUser';
import { IUser } from '../../../../../domainTypes/user';

const CampaignManagerPicker = ({
  definition,
  managers,
  onSave
}: {
  onSave: (definition: CampaignManagerFilterDefinition) => void;
  definition: CampaignManagerFilterDefinition;
  managers: Array<IUser & { id: string }>;
}) => {
  const [value, setValue] = useState(definition.v);
  const newDefinition = useMemo<CampaignManagerFilterDefinition>(
    () => createCampaignManagerFilterDefinition(value),
    [value]
  );
  const enableSave =
    validateFilterDefinition(newDefinition) &&
    !isEqual(definition, newDefinition);

  const [search, setSearch] = useState('');
  const managerOptions = useMemo(() => {
    return managers.map((m) => ({
      value: m.id,
      label: m.displayName ?? m.email,
      searchValue: m.displayName.toLowerCase()
    }));
  }, [managers]);
  const options = useMemo(() => {
    const searchRe = toSearchRegexp(search);
    if (!managerOptions) {
      return [];
    }
    if (!searchRe) {
      return managerOptions;
    }
    return managerOptions.filter((o) => o.searchValue.match(searchRe));
  }, [managerOptions, search]);

  const _toggle = useCallback(
    (manager: string) => setValue(toggle(value, manager)),
    [value]
  );

  const focus = useCallback(
    (manager: string) =>
      onSave(createCampaignManagerFilterDefinition([manager])),
    [onSave]
  );

  return (
    <>
      <FilterMenu.Body>
        <SelectorShell label="Managers" search={search} setSearch={setSearch}>
          <OptionsList
            options={options}
            selectedValues={value}
            toggle={_toggle}
            focus={focus}
          />
        </SelectorShell>
      </FilterMenu.Body>
      <FilterMenu.Footer definition={definition}>
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={`Filter by ${pluralize('manager', value.length, true)}`}
        />
      </FilterMenu.Footer>
    </>
  );
};

const InnerCampaignManagerMenu = ({
  definition,
  onSave
}: {
  onSave: (definition: CampaignManagerFilterDefinition) => void;
  definition: CampaignManagerFilterDefinition;
}) => {
  const { space } = useCurrentUser();

  const [managers, loading] = useMappedLoadingValue(
    useCombineLoadingValues(useCampaigns(), useUsersPerSpace(space.id)),
    ([campaigns, usersDoc]) => {
      const users = usersDoc ? usersDoc.data?.users ?? {} : {};
      return uniq(
        compact(
          campaigns.flatMap((campaign) =>
            campaign.managers.map((m) =>
              users[m]
                ? {
                    ...users[m]?.data,
                    id: m
                  }
                : null
            )
          )
        )
      ) as Array<IUser & { id: string }>;
    }
  );
  if (!managers || loading) {
    return (
      <>
        <FilterMenu.Body>
          <SelectorLoader />
        </FilterMenu.Body>
        <FilterMenu.Footer definition={initFilterValue('campaign_manager')}>
          <FilterMenu.SaveButton
            disabled={true}
            onSave={() => {}}
            label="Loading managers..."
          />
        </FilterMenu.Footer>
      </>
    );
  }
  return (
    <CampaignManagerPicker
      definition={definition}
      onSave={onSave}
      managers={managers}
    />
  );
};

export const CampaignManagerMenu: React.FC<{
  definition: CampaignManagerFilterDefinition;
  onSave: (definition: CampaignManagerFilterDefinition) => void;
}> = ({ definition, onSave }) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);

  return (
    <FilterMenu>
      <FilterMenu.Header dimension="campaign_manager">
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <InnerCampaignManagerMenu definition={definition} onSave={onSave} />
    </FilterMenu>
  );
};
