import { AnalyticsField } from '../../../../../../domainTypes/analytics_v2';

export const utmParameters = [
  'campaign',
  'medium',
  'source',
  'term',
  'content'
] as const;

export type UtmParameterType = typeof utmParameters[number];

export type UtmField = Extract<
  AnalyticsField,
  | 'utm_campaign'
  | 'utm_source'
  | 'utm_keyword'
  | 'utm_medium'
  | 'utm_content'
  | 'utm_term'
>;

export const utmParameterTitle = (parameter: UtmParameterType) => {
  switch (parameter) {
    case 'campaign':
      return 'UTM Campaigns';
    case 'medium':
      return 'UTM Medium';
    case 'source':
      return 'UTM Sources';
    case 'term':
      return 'UTM Terms';
    case 'content':
      return 'UTM Content';
  }
};

export const dbColumn = (parameter: UtmParameterType): UtmField => {
  switch (parameter) {
    case 'campaign':
      return 'utm_campaign';
    case 'medium':
      return 'utm_medium';
    case 'source':
      return 'utm_source';
    case 'term':
      return 'utm_term';
    case 'content':
      return 'utm_content';
  }
};

export const utmParameterTooltip = (parameter: UtmParameterType) => {
  switch (parameter) {
    case 'campaign':
      return "Unique values pulled from a URL's utm_campaign parameter. Example campaigns: weekly_newsletter_2023-10-07, youtube_alo_yoga_pants_review.";
    case 'medium':
      return "Unique values pulled from a URL's utm_medium parameter. Example mediums: social, email, syndication.";
    case 'source':
      return "Unique values pulled from a URL's utm_source parameter. Example sources: facebook, google, youtube, tiktok.";
    case 'content':
      return "Unique values pulled from a URL's utm_content parameter. Example content: sidebar, topnav, top-banner.";
    case 'term':
      return "Unique values pulled from a URL's utm_term parameter, usually via a PPC ad. Example terms: best shoes, yoga pants reviews, top meal delivery services.";
  }
};
