import { Typography } from '@material-ui/core';
import { capitalize } from 'lodash';
import React, { useMemo } from 'react';
import { Timeframe } from '../../../domainTypes/analytics';
import { CurrencyCode } from '../../../domainTypes/currency';
import { Centered } from '../../../layout/Centered';
import { FlexContainer } from '../../../layout/Flex';
import { useTypedStringQueryParam } from '../../../routes';
import { InlineLoader, Loader } from '../../Loader';
import { ChartCard, ChartCardFooter, ChartCardFooterBar } from '../ChartCard';
import { Data, EarningsBarChart } from '../EarningsChart';
import { Legend } from '../Util';
import { ChartMode, ChartModeSelector } from './ChartModeSelector';
import { GraphMode, GraphModeSelector } from './GraphModeSelector';

export const EarningsChartCard: React.FC<{
  data: void | Data[];
  loading: boolean;
  currency: CurrencyCode;
  style?: React.CSSProperties;
  graphMode: GraphMode;
  setGraphMode: (nextGraphMode: GraphMode) => void;
  chartMode: ChartMode;
  setChartMode: (nextGraphMode: ChartMode) => void;
  timeframe?: Timeframe;
}> = ({
  data,
  loading,
  currency,
  style,
  graphMode,
  setGraphMode,
  chartMode,
  setChartMode,
  timeframe
}) => {
  const [metric, setMetric] = useTypedStringQueryParam<
    'earnings' | 'saleValue'
  >('metric', 'earnings');

  const modeSelector = (
    <>
      <GraphModeSelector value={graphMode} onChange={setGraphMode} />
      <ChartModeSelector value={chartMode} onChange={setChartMode} />
    </>
  );

  // store reference to last timeframe
  // if loading serve last timeframe
  // if loading turns false -> expose new timeframe

  const heading = useMemo(() => {
    return metric === 'earnings' ? 'Daily earnings' : 'Gross sales by day';
  }, [metric]);

  if (loading) {
    return (
      <ChartCard
        heading={heading}
        topRight={modeSelector}
        style={style}
        size="small"
        padding="dense"
      >
        <Loader height={330} />
      </ChartCard>
    );
  }
  if (!data) {
    // TODO this should be an error state
    return (
      <ChartCard
        heading={heading}
        subheading={`By ${capitalize(graphMode)}`}
        topRight={modeSelector}
        style={style}
        size="small"
        padding="dense"
      >
        <Centered>
          <Typography
            variant="body1"
            component="p"
            paragraph
            style={{ textAlign: 'center', marginTop: '100px', maxWidth: '80%' }}
          >
            <strong>No data was loaded</strong>
            <br />
            <br />
            Try refreshing the page. If you expect to see data here and the
            error persists, please contact Support with the URL to this page.
          </Typography>
        </Centered>
        <ChartCardFooter>
          <ChartCardFooterBar>
            <Legend
              items={[
                {
                  color: '#444',
                  shape: 'circle',
                  active: metric === 'earnings',
                  onClick: () => setMetric('earnings'),
                  label: 'Earnings'
                },
                {
                  color: '#444',
                  shape: 'circle',
                  active: metric === 'saleValue',
                  onClick: () => setMetric('saleValue'),
                  label: 'Sales volume'
                }
              ]}
            />
          </ChartCardFooterBar>
        </ChartCardFooter>
      </ChartCard>
    );
  }

  return (
    <ChartCard
      heading={heading}
      topRight={
        loading ? (
          <FlexContainer>
            <InlineLoader color="inherit" />
            {modeSelector}
          </FlexContainer>
        ) : (
          modeSelector
        )
      }
      style={style}
      size="small"
      padding="dense"
      maximizedContent={() => (
        <EarningsBarChart
          height={'auto'}
          data={data}
          currency={currency}
          metric={metric}
          yAxisOrientation="right"
          timeframe={timeframe}
          chartMode={chartMode}
        />
      )}
    >
      <EarningsBarChart
        height={372}
        data={data}
        currency={currency}
        metric={metric}
        yAxisOrientation="right"
        timeframe={timeframe}
        chartMode={chartMode}
      />
      <ChartCardFooter>
        <ChartCardFooterBar>
          <Legend
            items={[
              {
                color: '#444',
                shape: 'circle',
                active: metric === 'earnings',
                onClick: () => setMetric('earnings'),
                label: 'Earnings'
              },
              {
                color: '#444',
                shape: 'circle',
                active: metric === 'saleValue',
                onClick: () => setMetric('saleValue'),
                label: 'Sales volume'
              }
            ]}
          />
        </ChartCardFooterBar>
      </ChartCardFooter>
    </ChartCard>
  );
};
