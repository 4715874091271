import React, { useCallback, useMemo, useState } from 'react';
import {
  createReferrersFilterDefinition,
  validateFilterDefinition
} from '../filters';
import { DimensionMenuComponent } from './Dimension';
import { FieldSelectorMenu, toggle } from './Selector';
import { FilterMenu } from './FilterMenu';
import { isEmpty, isEqual } from 'lodash';
import pluralize from 'pluralize';
import {
  AnalyticsFilter,
  AnalyticsQuery,
  ISOTimeRange
} from '../../../../domainTypes/analytics_v2';
import { css } from '../../../../emotion';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { useCurrentUser } from '../../../../services/currentUser';
import { useTimeframe } from '../../Timeframe';
import { useMappedLoadingValue } from '../../../../services/db';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import { Metric } from '../../../../services/analyticsV2/metrics';
import {
  ReferrerFilterDef,
  ReferrerFilterValue
} from '../../../../domainTypes/filters';
import { Group, groups } from '../../../../domainTypes/referrers';
import { useDomains } from '../../../../services/domains';
import {
  GOOGLE_DISCOVER_ORIGN,
  groupColumnTransformers
} from '../../../../services/referrers-query';
import Typography from '@material-ui/core/Typography';
import { Truncated } from '../../../Truncated';
import { toReferrerGroupTitle } from '../../../../features/Content/pages/Traffic/Referrers/services/referrer-groups';

const MODES: Array<{ label: string; value: Mode }> = [
  {
    value: 'group',
    label: 'from group'
  },
  {
    value: 'oneOf',
    label: 'one of'
  }
];

type Mode = 'oneOf' | 'group';

const toMode = (definition: ReferrerFilterDef): Mode => {
  switch (definition.v.mode) {
    case 'group':
      return 'group';
    case 'domains':
      return 'oneOf';
  }
};

interface ReferrerMenuBodyProps {
  value: ReferrerFilterValue;
  onChange: (v: ReferrerFilterValue) => void;
  onSave: (v: ReferrerFilterDef) => void;
  filters: AnalyticsFilter[];
  orderBy: Metric;
  range: ISOTimeRange;
}

const referrerGroupMenuOptions: { value: Group; label: string }[] = groups.map(
  (g) => ({
    value: g,
    label: toReferrerGroupTitle(g)
  })
);

const useContentReferrerGroups = (filters: AnalyticsFilter[]) => {
  const { space } = useCurrentUser();
  const allDomains = useDomains();

  const { range } = useTimeframe();

  const query = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      filters: filters,
      select: ['p'],
      groupBy: ['referrer_entry_origin'],
      columnTransformers: groupColumnTransformers(allDomains)
    };
  }, [allDomains, filters, range]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (response) => response.rows.map((row) => row.group.referrer_entry_origin)
  );
};

const ReferrerGroupMenuBody: React.FC<{
  onChange: (v: Group) => void;
  filters: AnalyticsFilter[];
  value: Group | null;
}> = ({ onChange, filters, value }) => {
  const [contentGroups = []] = useContentReferrerGroups(filters);

  return (
    <FormControl
      className={css((t) => ({
        padding: t.spacing(2)
      }))}
    >
      <RadioGroup
        value={value}
        onClick={(e) => onChange((e.target as HTMLInputElement).value as Group)}
      >
        {referrerGroupMenuOptions.map((o) => (
          <FormControlLabel
            disabled={!contentGroups.includes(o.value)}
            key={o.value}
            control={<Radio color="primary" />}
            label={o.label}
            value={o.value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const ReferrerDomainsMenuBody: React.FC<{
  onChange: (v: Array<string>) => void;
  onSave: (v: ReferrerFilterDef) => void;
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
  orderBy: Metric;
  value: Array<string>;
}> = ({ value, onSave, onChange, range, filters, orderBy }) => {
  const focusValue = useCallback(
    (domain: string) => onSave(createReferrersFilterDefinition([domain])),
    [onSave]
  );
  const toggleValue = useCallback((v: string) => onChange(toggle(value, v)), [
    onChange,
    value
  ]);
  return (
    <FieldSelectorMenu
      label="referrer"
      selectedValues={value}
      focusValue={focusValue}
      toggleValue={toggleValue}
      queryFilters={filters}
      analyticsField="referrer_entry_origin"
      orderBy={orderBy}
      range={range}
      includeEmpty
      renderOption={(domain) =>
        isEmpty(domain) ? (
          <Typography variant="body1">Direct</Typography>
        ) : domain === GOOGLE_DISCOVER_ORIGN ? (
          <Typography variant="body1">Google Discover</Typography>
        ) : (
          <Truncated title={domain} />
        )
      }
    />
  );
};

const ReferrerMenuBody: React.FC<ReferrerMenuBodyProps> = ({
  range,
  filters,
  onChange,
  onSave,
  value,
  orderBy
}) => {
  switch (value.mode) {
    case 'group':
      return (
        <ReferrerGroupMenuBody
          value={value.v}
          onChange={(v) => onChange({ mode: 'group', v })}
          filters={filters}
        />
      );
    case 'domains':
      return (
        <ReferrerDomainsMenuBody
          value={value.v}
          onChange={(v) => onChange({ mode: 'domains', v })}
          onSave={onSave}
          filters={filters}
          orderBy={orderBy}
          range={range}
        />
      );
  }
};

const coerceDefinition = (
  mode: Mode,
  definition: ReferrerFilterDef
): ReferrerFilterValue => {
  const modesMatch = mode === toMode(definition);
  switch (mode) {
    case 'oneOf':
      return modesMatch ? definition.v : { mode: 'domains', v: [] };
    case 'group':
      return modesMatch ? definition.v : { mode: 'group', v: null };
  }
};

interface ReferrerMenuModeProps {
  mode: Mode;
  initialDefinition: ReferrerFilterDef;
  onSave: (v: ReferrerFilterDef) => void;
  filters: AnalyticsFilter[];
  orderBy: Metric;
  range: ISOTimeRange;
}

const ReferrerMenuMode: React.FC<ReferrerMenuModeProps> = ({
  mode,
  initialDefinition,
  onSave,
  filters,
  range,
  orderBy
}) => {
  const [value, setValue] = useState(() =>
    coerceDefinition(mode, initialDefinition)
  );
  const newDefinition = useMemo<ReferrerFilterDef>(
    () => ({ k: 'referrer', v: value }),
    [value]
  );
  const enableSave =
    validateFilterDefinition(newDefinition) &&
    !isEqual(initialDefinition, newDefinition);

  return (
    <>
      <FilterMenu.Body>
        <ReferrerMenuBody
          filters={filters}
          orderBy={orderBy}
          range={range}
          onChange={setValue}
          onSave={onSave}
          value={value}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer definition={newDefinition}>
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={saveButtonLabel(value)}
        />
      </FilterMenu.Footer>
    </>
  );
};

const saveButtonLabel = (value: ReferrerFilterValue) => {
  switch (value.mode) {
    case 'group':
      return value.v
        ? `Filter by ${toReferrerGroupTitle(value.v)} traffic`
        : 'Filter';
    case 'domains':
      return `Filter by ${pluralize('domain', value.v.length, true)}`;
  }
};

export const ReferrersMenu: DimensionMenuComponent<ReferrerFilterDef> = ({
  range,
  filters,
  onSave,
  definition,
  orderBy
}) => {
  const [mode, setMode] = useState<Mode>(toMode(definition));

  return (
    <FilterMenu>
      <FilterMenu.Header dimension="referrer">
        <FilterMenu.ModeSelector<Mode>
          modes={MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <ReferrerMenuMode
        key={mode}
        filters={filters}
        range={range}
        orderBy={orderBy}
        mode={mode}
        onSave={onSave}
        initialDefinition={definition}
      />
    </FilterMenu>
  );
};
