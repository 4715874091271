import { useMemo, useState } from 'react';
import { Swatch } from '../../../../../../components/ColorPicker';
import { IPostgresTags } from '../../../../../../domainTypes/tags';
import { styled } from '../../../../../../emotion';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../../../services/currentUser';
import { updateTag } from '../../../../../../services/tags';
import { Color } from '../../../../../../domainTypes/colors';
import { TagInEditMode } from './TagInEditMode';

const NotEditableWrapper = styled('div')`
  display: flex;
  gap: ${(p) => p.theme.spacing(0.6)}px;
  font-size: ${(p) => p.theme.typography.body2.fontSize} !important;
  align-items: center;
`;

const Wrapper = styled('div')`
  display: flex;
  gap: ${(p) => p.theme.spacing(0.6)}px;
  font-size: ${(p) => p.theme.typography.body2.fontSize} !important;
  align-items: center;
  height: 40px !important;
  cursor: pointer;

  .edit-button {
    visibility: hidden;
  }

  &:hover {
    .edit-button {
      visibility: visible;
    }
  }
`;

type ITagOptions = {
  readonly?: boolean;
};

export const Tag = ({
  tag,
  options = {}
}: {
  tag: IPostgresTags;
  options?: ITagOptions;
}) => {
  const { space } = useCurrentUser();
  const scopes = useCurrentUserScopes();
  const canEditTags = useMemo(() => scopes.has('tags.edit'), [scopes]);
  const [editMode, setEditMode] = useState(false);
  const [localTag, setLocalTag] = useState<IPostgresTags>(tag);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    return updateTag(space.id, localTag).then(() => {
      setEditMode(false);
    });
  };

  if (!canEditTags || options.readonly) {
    return (
      <NotEditableWrapper>
        <Swatch colorName={tag.color as Color} size="small" />
        {tag.name}
      </NotEditableWrapper>
    );
  }

  return !editMode ? (
    <Wrapper
      onClick={(e) => {
        e.stopPropagation();
        setEditMode(true);
      }}
    >
      <Swatch colorName={tag.color as Color} size="small" />
      {tag.name}
    </Wrapper>
  ) : (
    <TagInEditMode
      tag={localTag}
      setTag={setLocalTag}
      onSubmit={handleSubmit}
      onCancel={() => {
        setEditMode(false);
      }}
    />
  );
};
