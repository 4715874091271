import {
  Feature,
  isFeatureEnabled as isFeatureEnabled_,
  setFeature as setFeature_
} from '../../domainTypes/features';
import { ISpace } from '../../domainTypes/space';
import { FS } from '../../versions';
import { getCurrentSpace, useCurrentUser } from '../currentUser';
import { store } from '../db';

export const isFeatureEnabled = (feature: Feature) => {
  const space = getCurrentSpace();
  return isFeatureEnabled_(space.features, feature);
};

export const useFeatureEnabled = (feature: Feature) => {
  const currentUser = useCurrentUser();
  // This sneaked into the admin app through some hooks that temporarily check for
  // postgres. This current user is not defined there - so let's ignore this for now
  return !!(
    currentUser && isFeatureEnabled_(currentUser.space.features, feature)
  );
};

export const setFeature = async (
  space: ISpace,
  feature: Feature,
  enabled: boolean,
  userId?: string
) => {
  const updatingUser = userId || 'AFFILIMATE';
  return store()
    .collection(FS.spaces)
    .doc(space.id)
    .update({
      features: setFeature_(space.features, feature, enabled, updatingUser)
    })
    .then(() => {
      console.log('Feature updated');
    })
    .catch((err) => {
      console.log(err);
    });
};
