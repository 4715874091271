import { styled } from '../../../emotion';
import React from 'react';
import {
  activeCampaignStatuses,
  Campaign,
  campaignStatusTitle,
  getFlatSpendAmount
} from '../service';
import { groupBy } from 'lodash';
import { useSpaceCurrency } from '../../../services/useSpaceCurrency';
import { FlexContainer } from '../../../layout/Flex';
import { CampaignStatusIcon } from './CampaignStatusIcon';
import Typography from '@material-ui/core/Typography';
import { formatChartCurrency } from '../../../components/Charts/Util';
import { CampaignCard } from './CampaignCard';

const Count = styled('span')`
  font-size: 12px;
  font-weight: bold;
  color: ${(t) => t.theme.palette.grey[800]};
  background-color: ${(t) => t.theme.palette.grey[300]};
  border-radius: 4px;
  padding: 2px 11px;
  font-family: monospace;
`;

const BoardGrid = styled('div')`
  margin: ${(p) => p.theme.spacing(2)}px auto;
  max-width: 1200px;
  padding-top: ${(t) => t.theme.spacing(2)}px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${(t) => t.theme.spacing(2)}px;
  column-gap: ${(t) => t.theme.spacing(4)}px;
`;

export const CampaignsBoard: React.FC<{ campaigns: Campaign[] }> = ({
  campaigns
}) => {
  const byStatus = groupBy(campaigns, 'status');
  const currency = useSpaceCurrency();
  return (
    <BoardGrid>
      {activeCampaignStatuses.map((status) => {
        const campaigns = byStatus[status] || [];
        const spend = campaigns.reduce(
          (acc, c) => acc + getFlatSpendAmount(c),
          0
        );
        return (
          <FlexContainer
            key={status}
            alignItems="center"
            justifyContent="space-between"
          >
            <FlexContainer alignItems="center">
              <CampaignStatusIcon status={status} />
              <Typography variant="body1">
                {campaignStatusTitle(status)}
              </Typography>
              <Count>{campaigns.length}</Count>
            </FlexContainer>
            <Typography variant="caption" color="textSecondary">
              {formatChartCurrency(spend, currency, { excludeCents: true })}
            </Typography>
          </FlexContainer>
        );
      })}
      {activeCampaignStatuses.map((status) => {
        const campaigns = byStatus[status] || [];
        return (
          <FlexContainer key={status} direction="column" spacing={2}>
            {campaigns.map((campaign) => (
              <CampaignCard key={campaign.id} campaign={campaign} />
            ))}
          </FlexContainer>
        );
      })}
    </BoardGrid>
  );
};
