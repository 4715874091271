import { sortBy } from 'lodash';
import React from 'react';
import { styled } from '../../../emotion';
import { useColorUnknown } from '../../../services/color';
import { PartnerListItemWithCountsAndTrends } from '../../../services/partner';
import { Loader } from '../../Loader';
import { IPieChartCell, PieChartWithLegend } from '../PieChartWithLegend';

type Props = {
  partners: PartnerListItemWithCountsAndTrends[] | void;
  loading: boolean;
  count: number;
  animate?: boolean;
};

const HEIGHT = 270;

const EmptyState = styled('div')`
  height: ${HEIGHT}px;
  display: flex;
  text-align: center;
  align-items: center;
  width: 100%;
`;

const EmptyStateInner = styled('div')`
  text-align: center;
  color: ${(p) => p.theme.palette.grey.A700};
`;

export const PartnerChart = React.memo(
  ({ partners, loading, count = 10, animate }: Props) => {
    const colorUnknown = useColorUnknown();

    if (loading || !partners) {
      return <Loader height={HEIGHT} />;
    }

    const hasAnyClicks = !!partners.find((p) => p.counts.clicked.count > 0);

    if (!hasAnyClicks) {
      return (
        <EmptyState>
          <EmptyStateInner>
            <b>Nothing to see here</b>
            <p>
              You don't have any clicks for this time period. Try adjusting the
              date input to see clicks during a different time period.
            </p>
          </EmptyStateInner>
        </EmptyState>
      );
    }

    const sorted = sortBy(partners, (p) => -p.counts.clicked.count);
    const shownIndividually = sorted.slice(0, count);
    const rest = sorted.slice(count);

    const data = shownIndividually.map<IPieChartCell>((d) => ({
      key: d.partner.name,
      name: d.partner.name,
      count: d.counts.clicked.count,
      color: d.partner.color
    }));

    if (rest.length) {
      data.push({
        key: 'other',
        name: 'Other',
        count: rest.reduce((m, p) => m + p.counts.clicked.count, 0),
        color: colorUnknown
      });
    }

    return <PieChartWithLegend aspect={2.2} data={data} animate={animate} />;
  }
);
