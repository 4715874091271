import { useTypedStringQueryParam } from '../../../../../../routes';

export type TopChartMetric =
  | 'gmv_sum_net'
  | 'rpm_net'
  | 'epc_net'
  | 'commission_sum_net';

export const useTopChartMetric = () =>
  useTypedStringQueryParam<TopChartMetric>('topMetric', 'commission_sum_net');

export type BarMetric = 'gmv_sum_net' | 'commission_sum_net';

export type LineMetric = 'p' | 'c' | 'v' | 'ctr';

export const useBarMetric = () => {
  return useTypedStringQueryParam<BarMetric>(
    'trendBarMetric',
    'commission_sum_net'
  );
};

export const useLineMetric = () => {
  return useTypedStringQueryParam<LineMetric>('trendLineMetric', 'p');
};
