import { styled } from '../../../emotion';

export const ButtonContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  > * {
    margin-right: ${p => p.theme.spacing()}px;
    margin-bottom: ${p => p.theme.spacing()}px;
  }
`;
