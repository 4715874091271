import React from 'react';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useCurrentUser } from '../../../services/currentUser';
import {
  EMPTY_CHANNEL,
  saveChannel,
  useChannels
} from '../../../services/channels';
import { Doc } from '../../../domainTypes/document';
import { IChannel } from '../../../domainTypes/channels';
import { OffsiteChannelForm } from './OffsiteChannelForm';
import { now } from '../../../services/time';

export const CreateChannelStep = ({
  onClose,
  onContinue
}: {
  onClose: () => void;
  onContinue: () => void;
}) => {
  const currentUser = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const { space } = currentUser;
  const [channels = []] = useChannels(space.id);
  const [newChannel, setNewChannel] = React.useState<Doc<IChannel>>(
    EMPTY_CHANNEL(space.id)
  );

  const onSaveChannel = async (channel: Doc<IChannel>) => {
    const newChannel: Doc<IChannel> = {
      ...channel,
      data: {
        ...channel.data,
        spaceId: space.id,
        updatedAt: now(),
        createdBy: currentUser.id,
        channelId: channel.data.channelId
      }
    };
    return saveChannel(newChannel).then(() => {
      enqueueSnackbar('Channel saved!', { variant: 'success' });
      onContinue();
    });
  };

  return (
    <OffsiteChannelForm
      allChannels={channels}
      mode="create"
      channel={newChannel}
      setChannel={setNewChannel}
      onSaveChannel={onSaveChannel}
      onCancel={onContinue}
    />
  );
};
