import { Tab, Tabs, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Loader } from '../../../../components/Loader';
import { NoPermissions } from '../../../../components/NoPermissions';
import { Doc } from '../../../../domainTypes/document';
import { Job } from '../../../../domainTypes/job';
import { SECRET_CATEGORY } from '../../../../domainTypes/reporting';
import { ISecretWithTs } from '../../../../domainTypes/secret';
import { Centered } from '../../../../layout/Centered';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import { useNumberQueryParam } from '../../../../routes';
import { useApiStatuses } from '../../../../services/apiStatus';
import { useAdminOrImpersonatorClaim } from '../../../../services/auth';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { useJobsForTable } from '../../../../services/jobs';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { useHasPayouts } from '../../../../services/payouts';
import { useSecrets } from '../../../../services/secret';
import { ApiStatusList } from '../../components/ApiStatusList';
import { ImportActivityTable } from '../../components/ImportActivityTable';
import { PerformancePageBody } from '../../components/PerformancePageBody';

const InnerJobTable = ({
  jobType,
  spaceId,
  secrets,
  description
}: {
  jobType: Job['type'];
  spaceId: string;
  secrets: Doc<ISecretWithTs>[];
  description: React.ReactNode;
}) => {
  const [jobs, loadingJobs] = useJobsForTable(spaceId, jobType);
  if (!jobs || loadingJobs) {
    return (
      <Centered height={500}>
        <Loader size={24} />
      </Centered>
    );
  }
  return (
    <div>
      <Typography variant="body2" component="p" color="textSecondary" paragraph>
        {description}
      </Typography>
      <ImportActivityTable jobs={jobs} secrets={secrets} />
    </div>
  );
};

const ApiStatusTable = ({ spaceId }: { spaceId: string }) => {
  const [apiStatuses, loadingApiStatuses] = useApiStatuses(spaceId);

  if (!apiStatuses || loadingApiStatuses) {
    return (
      <Centered height={500}>
        <Loader size={24} />
      </Centered>
    );
  }

  return <ApiStatusList apiStatuses={apiStatuses} />;
};

const ImportActivityContent = () => {
  const { space } = useCurrentUser();
  const [secrets, loadingSecrets] = useSecrets(space.id, SECRET_CATEGORY);
  const [selectedTab, setSelectedTab] = useNumberQueryParam('tab', 0);
  const handleChangeTab = (_: React.ChangeEvent<{}>, newValue: number) =>
    setSelectedTab(newValue);
  const hasPayoutReporting = useHasPayouts();
  const [
    isAdminOrImpersonator,
    loadingIsAdminOrImpersonator
  ] = useAdminOrImpersonatorClaim();

  const isPayoutsVisible = useMemo(() => {
    if (loadingIsAdminOrImpersonator) {
      return false;
    }
    return hasPayoutReporting || isAdminOrImpersonator;
  }, [hasPayoutReporting, isAdminOrImpersonator, loadingIsAdminOrImpersonator]);

  if (!secrets || loadingSecrets) {
    return (
      <Centered height={500}>
        <Loader size={24} />
      </Centered>
    );
  }

  return (
    <div>
      <PageToolbar sticky offset={DEFAULT_OFFSET}>
        <PageToolbarSection flex={2}>
          <Tabs value={selectedTab} onChange={handleChangeTab}>
            <Tab label="Transactions" value={0} />
            {isPayoutsVisible && (
              <Tab
                label="Payouts"
                value={2}
                style={{
                  opacity: hasPayoutReporting ? undefined : 0.5
                }}
              />
            )}
            <Tab label="Advertisers" value={3} />
            <Tab label="API status" value={1} />
          </Tabs>
        </PageToolbarSection>
      </PageToolbar>
      {selectedTab === 0 && (
        <InnerJobTable
          spaceId={space.id}
          secrets={secrets}
          jobType="reporting"
          description={
            <>
              <strong>Transaction reports</strong> &mdash; Update daily in
              batches minutes while respecting network API limits.
            </>
          }
        />
      )}
      {selectedTab === 2 && (
        <div
          style={{
            display: isPayoutsVisible ? 'block' : 'none',
            opacity: hasPayoutReporting ? undefined : 0.5
          }}
        >
          <InnerJobTable
            spaceId={space.id}
            secrets={secrets}
            jobType="payout"
            description={
              <>
                <strong>Payout reports</strong> &mdash; Update daily in batches
                while respecting network API limits.
              </>
            }
          />
        </div>
      )}
      {selectedTab === 3 && (
        <InnerJobTable
          spaceId={space.id}
          secrets={secrets}
          jobType="advertiser"
          description={
            <>
              <strong>Rate reports</strong> &mdash; Update daily for most
              networks, except Skimlinks and ShareASale which update weekly.
            </>
          }
        />
      )}
      {selectedTab === 1 && <ApiStatusTable spaceId={space.id} />}
    </div>
  );
};

export const PagePerformanceImportActivity = () => {
  const [canView] = useHasCurrentUserRequiredScopes(['integrations.view']);

  useTrackMixpanelView('view_integration_activity');

  return (
    <PerformancePageBody noTopPadding>
      <Helmet>
        <title>Import activity | Affilimate</title>
      </Helmet>
      {canView ? <ImportActivityContent /> : <NoPermissions />}
    </PerformancePageBody>
  );
};
