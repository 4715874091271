import React from 'react';
import { styled } from '../emotion';
import { IPartner } from '../domainTypes/partners';
import { Tooltip } from '@material-ui/core';
import { Info } from 'react-feather';
import { WithShape } from './Charts/Util';

const AlertWrapper = styled('span')`
  display: inline-block;
  position: relative;
  top: 1px;
  color: ${(p) => p.theme.palette.grey[600]};
  margin-left: ${(p) => p.theme.spacing(0.5)}px;
`;

export const PlatformWithColor = ({
  partner,
  info,
  style
}: {
  partner: Pick<IPartner, 'name' | 'color'>;
  info?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <WithShape shape="square" large color={partner.color} style={style}>
      {partner.name}
      {info && (
        <Tooltip title={info} placement="top">
          <AlertWrapper>
            <Info size={12} />
          </AlertWrapper>
        </Tooltip>
      )}
    </WithShape>
  );
};
