import React, { useCallback, useRef, useState } from 'react';
import { useDialogState } from '../../../../../hooks/useDialogState';
import { Button, CircularProgress, Menu, MenuItem } from '@material-ui/core';
import { useCurrentUser } from '../../../../../services/currentUser';
import { assignTags } from '../../../../../services/tags';
import { TagsDialog, TagsDialogProps } from './TagsDialog';
import pluralize from 'pluralize';
import { useFlushAnalyticsV2Cache } from '../../../../../services/analyticsV2/cache';
import { useBackgroundJobs } from '../../../../../hooks/useBackgroundJobs';

export const RemoveTagsDialog: React.FC<Pick<
  TagsDialogProps,
  'open' | 'onClose' | 'pages' | 'isAllPagesSelected'
>> = ({ open, onClose, pages, isAllPagesSelected }) => {
  const { space } = useCurrentUser();
  const flushCache = useFlushAnalyticsV2Cache();
  const { addJob } = useBackgroundJobs();
  return (
    <TagsDialog
      open={open}
      onClose={onClose}
      title="Remove tags from your pages"
      cta="Remove tags"
      description="Expand the tag groups and choose tags (left) to remove from all selected pages (right)."
      pages={pages}
      isAllPagesSelected={isAllPagesSelected}
      notice={(tags, pages) =>
        `You're about to remove ${pluralize(
          'tag',
          tags.size,
          true
        )} from ${pluralize('page', pages.length, true)}`
      }
      action={async (tags: string[], pages: string[]) => {
        addJob({
          job: () =>
            assignTags({
              spaceId: space.id,
              actions: pages.flatMap((href) =>
                tags.map((tagId) => ({ tagId, href, action: 'remove' }))
              )
            }),
          onStart: () => {
            console.time('removing tags');
            onClose();
            return {
              message: (
                <>
                  <CircularProgress color="inherit" size={16} /> &nbsp; Removing
                  tags...
                </>
              )
            };
          },
          onSuccess: () => {
            flushCache(space.id);
            console.timeEnd('removing tags');
            return {
              message: 'Tags removed!'
            };
          },
          onError: () => {
            console.timeEnd('removing tags');
            return {
              message: "Couldn't remove tags!"
            };
          }
        });
      }}
    />
  );
};

export const AddTagsDialog: React.FC<Pick<
  TagsDialogProps,
  'open' | 'onClose' | 'pages' | 'isAllPagesSelected'
>> = ({ open, onClose, pages, isAllPagesSelected }) => {
  const { space } = useCurrentUser();
  const flushCache = useFlushAnalyticsV2Cache();
  const { addJob } = useBackgroundJobs();
  return (
    <TagsDialog
      open={open}
      onClose={onClose}
      title="Add tags to your pages"
      cta="Add tags"
      description="Expand the tag groups and choose tags (left) to add to all selected pages (right)."
      pages={pages}
      isAllPagesSelected={isAllPagesSelected}
      notice={(tags, pages) =>
        `You're about to add ${pluralize(
          'tag',
          tags.size,
          true
        )} to ${pluralize('page', pages.length, true)}`
      }
      action={async (tags: string[], pages: string[]) => {
        addJob({
          job: () =>
            assignTags({
              spaceId: space.id,
              actions: pages.flatMap((href) =>
                tags.map((tagId) => ({ tagId, href, action: 'apply' }))
              )
            }),
          onStart: () => {
            console.time('adding tags');
            onClose();
            return {
              message: (
                <>
                  <CircularProgress color="inherit" size={16} /> &nbsp; Adding
                  tags...
                </>
              )
            };
          },
          onSuccess: () => {
            flushCache(space.id);
            console.timeEnd('adding tags');
            return {
              message: 'Tags added!'
            };
          },
          onError: () => {
            console.timeEnd('adding tags');
            return {
              message: "Couldn't add tags!"
            };
          }
        });
      }}
    />
  );
};

export const TagsMenu: React.FC<{
  pages: Set<string>;
  isAllPagesSelected: boolean;
}> = ({ pages, isAllPagesSelected }) => {
  const [open, setOpen] = useState(false);
  const anchor = useRef<HTMLButtonElement | null>(null);
  const {
    dialogOpen: addDialogOpen,
    openDialog: openAddDialog,
    closeDialog: closeAddDialog
  } = useDialogState();
  const {
    dialogOpen: removeDialogOpen,
    openDialog: openRemoveDialog,
    closeDialog: closeRemoveDialog
  } = useDialogState();
  const onCloseAddDialog = useCallback(() => {
    closeAddDialog();
    setOpen(false);
  }, [closeAddDialog]);
  const onCloseRemoveDialog = useCallback(() => {
    closeRemoveDialog();
    setOpen(false);
  }, [closeRemoveDialog]);

  return (
    <>
      <Button
        ref={anchor}
        onClick={() => setOpen((v) => !v)}
        variant="contained"
        color="primary"
      >
        Assign tags
      </Button>
      <Menu
        open={open}
        anchorEl={anchor.current}
        onClose={() => setOpen(false)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem onClick={openAddDialog}>Add tags</MenuItem>
        <MenuItem onClick={openRemoveDialog}>Remove tags</MenuItem>
      </Menu>
      <AddTagsDialog
        pages={pages}
        isAllPagesSelected={isAllPagesSelected}
        open={addDialogOpen}
        onClose={onCloseAddDialog}
      />
      <RemoveTagsDialog
        pages={pages}
        isAllPagesSelected={isAllPagesSelected}
        open={removeDialogOpen}
        onClose={onCloseRemoveDialog}
      />
    </>
  );
};
