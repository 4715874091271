import { Button } from '@material-ui/core';
import React from 'react';
import { TableToolbar } from '../../../../../../components/Table';
import { css, styled } from '../../../../../../emotion';

export type ToolbarElements = 'list' | 'trash' | 'knownProducts';

const Label = styled('span')`
  margin-left: ${(p) => p.theme.spacing() / 2}px;
`;

const StyledToolbar = styled(TableToolbar)`
  border-top-left-radius: ${(p) => p.theme.shape.borderRadius}px;
  border-top-right-radius: ${(p) => p.theme.shape.borderRadius}px;
  background-color: #111111;
  color: white;
`;

const PendingDot = styled('div')`
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 100%;
  margin-left: ${(p) => p.theme.spacing(1)}px;
  background-color: #ffa940;
`;

const ToolbarButton = ({
  variant,
  currentView,
  size,
  label,
  onClick
}: {
  variant: ToolbarElements;
  currentView: ToolbarElements;
  size: number;
  label?: string;
  onClick: (view: string) => void;
}) => (
  <Button
    onClick={() => onClick(variant)}
    variant={currentView === variant ? 'contained' : 'text'}
    className={css((t) => ({
      marginRight: t.spacing(),
      backgroundColor:
        currentView === variant ? t.palette.common.white : 'transaprent',
      color: currentView === variant ? 'black' : '#ffffffde'
    }))}
  >
    <Label>
      {size} {label || ''}&nbsp;
      {variant === 'trash' && <PendingDot />}
    </Label>
  </Button>
);

export const Toolbar = ({
  acceptedSize,
  onToggleTrashView,
  trashSize,
  alreadyAddedSize,
  currentView
}: {
  acceptedSize: number;
  onToggleTrashView: (view: ToolbarElements) => void;
  trashSize: number;
  alreadyAddedSize: number;
  currentView: ToolbarElements;
}) => {
  return (
    <StyledToolbar>
      <div style={{ width: '100%' }}>
        <ToolbarButton
          onClick={() => onToggleTrashView('list')}
          currentView={currentView}
          variant="list"
          label={'links to import'}
          size={acceptedSize}
        />
        <ToolbarButton
          onClick={() => onToggleTrashView('trash')}
          currentView={currentView}
          variant="trash"
          label={'ignored links'}
          size={trashSize}
        />
        {!!alreadyAddedSize && (
          <div style={{ float: 'right' }}>
            <ToolbarButton
              onClick={() => onToggleTrashView('knownProducts')}
              currentView={currentView}
              variant="knownProducts"
              label={'already imported'}
              size={alreadyAddedSize}
            />
          </div>
        )}
      </div>
    </StyledToolbar>
  );
};
