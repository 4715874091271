import React, { ReactNode } from 'react';
import {
  Bar,
  BarChart,
  TickFormatterFunction,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';
import { EarningMetric } from '../../service';
import { CHART_HEIGHT, EARNINGS_COLOR } from './index';
import { ChartData } from './chart-data';
import { CurrencyCode } from '../../../../../../domainTypes/currency';
import { formatMetric } from '../../../../../../services/analyticsV2/metrics';
import { RealtimeEarningsChartTooltip } from './RealtimeTooltip';

interface ChartProps {
  data: Array<ChartData>;
  labelFormatter: (label: TooltipProps['label']) => ReactNode;
  xAxisTickFormatter: TickFormatterFunction;
  currency: CurrencyCode;
  metric: EarningMetric;
  hasComparison: boolean;
}

export const realtimeEarningsChart = ({
  data,
  labelFormatter,
  xAxisTickFormatter,
  metric,
  currency,
  hasComparison
}: ChartProps) => (
  <BarChart
    width={730}
    syncId="realtimeChartId"
    height={CHART_HEIGHT}
    data={data}
    barGap={hasComparison ? 0 : undefined}
  >
    <Tooltip
      cursor={{ fill: 'rgba(0, 0, 0, 0.05)' }}
      content={
        <RealtimeEarningsChartTooltip
          metric={metric}
          currency={currency}
          hasComparison={hasComparison}
        />
      }
      labelFormatter={labelFormatter}
    />
    <XAxis
      dataKey="timestamp"
      minTickGap={120}
      tickLine={false}
      tickFormatter={xAxisTickFormatter}
      tick={{
        fill: '#9b9b9b',
        transform: 'translate(0, 8)',
        fontSize: 14
      }}
      textAnchor="end"
      tickSize={0}
      stroke="cwBBB"
    />
    <YAxis
      yAxisId="earnings"
      tickLine={false}
      tickFormatter={(d) => formatMetric(d, metric, currency)}
      tick={{
        fill: '#9b9b9b',
        transform: 'translate(8, 0)',
        fontSize: 14
      }}
      tickSize={0}
      orientation="right"
      stroke="cwBBB"
    />

    {hasComparison ? (
      <Bar
        name="previous"
        dataKey="previousEarnings"
        yAxisId="earnings"
        fill={EARNINGS_COLOR}
        fillOpacity={0.3}
        isAnimationActive={false}
        strokeWidth={1}
      />
    ) : null}

    <Bar
      name="current"
      dataKey="earnings"
      yAxisId="earnings"
      fill={EARNINGS_COLOR}
      isAnimationActive={false}
    />
  </BarChart>
);
