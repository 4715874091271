import { every, keyBy, sortBy } from 'lodash';
import md5 from 'md5';
import { toDocsFromRdbObject } from '../../../domainTypes/document';
import {
  IProductScanRescheduleParams,
  IProductSuggestionGroup,
  IProductSuggestionTrashItem
} from '../../../domainTypes/productScan';
import { getCurrentSpace, getCurrentUser } from '../../../services/currentUser';
import { rdb, toRef, useMappedLoadingValue } from '../../../services/db';
import {
  callFirebaseFunction,
  useFirebaseFunction
} from '../../../services/firebaseFunctions';
import { createScan as createScanServiceFn } from '../../../services/scan';
import { now } from '../../../services/time';
import { CF, RDB } from '../../../versions';
import { Compressed } from '../../../domainTypes/compression';
import { decompressType } from '../../../services/compression';
export { useScan, useScans } from '../../../services/scan';

export const useSuggestionGroups = (spaceId: string, scanId: string) => {
  return useMappedLoadingValue(
    useFirebaseFunction<Compressed<IProductSuggestionGroup[]>>(
      CF.scraping.getSuggestionGroupsForScan,
      {
        spaceId,
        scanId
      },
      [spaceId, scanId]
    ),
    decompressType
  );
};

export const createScan = createScanServiceFn;

export const rescanPages = (
  spaceId: string,
  scanId: string,
  pages: string[]
): Promise<{}> => {
  return callFirebaseFunction(CF.scraping.reschedulePages, {
    spaceId,
    scanId,
    pages
  } as IProductScanRescheduleParams);
};

const match = (regexp: RegExp) => (n: string) => regexp.test(n);
const equals = (x: string) => (n: string) => n === x;

const RELEVANCE_MATCHERS: {
  match: (n: string, ns?: string[]) => boolean;
  score: number;
}[] = [
  {
    match: match(/check/i),
    score: 2
  },
  {
    match: match(/click here/i),
    score: 2
  },
  {
    match: equals('this'),
    score: 2
  },
  {
    match: equals('here'),
    score: 2
  },
  {
    match: match(/here/i),
    score: 1
  },
  {
    match: match(/our|his|her/i),
    score: 1
  },
  {
    match: match(/(this|these)/i),
    score: 1
  },
  {
    match: match(/booking.com/i),
    score: 1
  },
  {
    match: match(/apply/i),
    score: 2
  },
  {
    match: match(/amazon/i),
    score: 1
  },
  {
    match: match(/shop/i),
    score: 1
  },
  {
    match: (n) => {
      const words = n.split(' ');
      if (words.length < 2) {
        return false;
      }
      // every words starts with a capital letter, or no letter at all (like an &)
      return every(words, (w) => /^[A-Z]/.test(w) || !/^[a-z]/i.test(w));
    },
    score: -1
  }
];

export const getScoreForName = (name: string) => {
  const score = RELEVANCE_MATCHERS.reduce(
    (m, matcher) => m + (matcher.match(name) ? matcher.score : 0),
    0
  );
  return score - name.length / 1000; // prefer longer names in a tiebreak
};

export const sortByNameScore = (names: string[]): string[] => {
  return sortBy(names, (n) => getScoreForName(n));
};

export const addToTrash = (href: string) => {
  return rdb()
    .ref(toRef(RDB.productTrash, getCurrentSpace().id, md5(href)))
    .set({
      href,
      trashedAt: now(),
      trashedBy: getCurrentUser().id
    } as IProductSuggestionTrashItem);
};

export const addSeveralToTrash = (
  spaceId: string,
  userId: string,
  hrefs: string[]
) => {
  const trashedAt = now();
  const data: IProductSuggestionTrashItem[] = hrefs.map((href) => ({
    href,
    trashedAt,
    trashedBy: userId
  }));

  return rdb()
    .ref(toRef(RDB.productTrash, spaceId))
    .update(keyBy(data, (d) => md5(d.href)));
};

export const removeFromTrash = (spaceId: string, href: string) => {
  return rdb()
    .ref(toRef(RDB.productTrash, spaceId, md5(href)))
    .set(null);
};

export const removeSeveralFromTrash = (spaceId: string, hrefs: string[]) => {
  const data = hrefs.reduce<{ [key: string]: null }>((m, href) => {
    m[md5(href)] = null;
    return m;
  }, {});
  return rdb().ref(toRef(RDB.productTrash, spaceId)).update(data);
};

export const getTrash = (
  spaceId: string
): Promise<{
  [href: string]: IProductSuggestionTrashItem;
}> => {
  return new Promise((resolve, reject) => {
    rdb()
      .ref(toRef(RDB.productTrash, spaceId))
      .once(
        'value',
        (snapshot) => {
          const docs = toDocsFromRdbObject<IProductSuggestionTrashItem>(
            snapshot.val()
          );
          resolve(
            keyBy(
              docs.map((d) => d.data),
              (d) => d.href
            )
          );
        },
        (err: any) => reject(err)
      );
  });
};

export const partitionSuggestions = (
  suggestions: IProductSuggestionGroup[],
  trash: { [href: string]: IProductSuggestionTrashItem }
): {
  new: IProductSuggestionGroup[];
  known: IProductSuggestionGroup[];
  trashed: IProductSuggestionGroup[];
} => {
  const newSuggs: IProductSuggestionGroup[] = [];
  const knownSuggs: IProductSuggestionGroup[] = [];
  const trashedSuggs: IProductSuggestionGroup[] = [];
  suggestions.forEach((s) => {
    if (s.productId) {
      knownSuggs.push(s);
    } else if (!!trash[s.href]) {
      trashedSuggs.push(s);
    } else {
      newSuggs.push(s);
    }
  });
  return {
    new: newSuggs,
    known: knownSuggs,
    trashed: trashedSuggs
  };
};
