import { InputAdornment, TextField } from '@material-ui/core';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Search as IconSearch } from 'react-feather';
import { css } from '../../emotion';
import * as tracking from '../../tracking';

// TODO update Icon position and color

interface Props {
  value: string;
  onChange: (nextValue: string) => void;
  autoFocus?: boolean;
  placeholder?: string;
  width?: number;
  size?: 'small' | 'normal';
  fullWidth?: boolean;
}

export const toSearchRegexp = (search: string) =>
  search ? new RegExp(search, 'i') : null;
export const useSearchRegexp = (search: string) =>
  useMemo(() => toSearchRegexp(search), [search]);

export const SearchInput = ({
  value,
  onChange,
  placeholder = 'Search by name or URL',
  autoFocus,
  width,
  fullWidth,
  size = 'normal'
}: Props) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const callback = useRef(onChange);
  const initialChange = useRef(true);

  useEffect(() => {
    callback.current = onChange;
  }, [onChange]);

  const debouncedOnChange = useRef(
    debounce((s: string) => callback.current(s), 400)
  );

  useEffect(() => {
    if (initialChange.current) {
      initialChange.current = false;
      return;
    }
    debouncedOnChange.current(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    if (value !== '') {
      tracking.sendEvent({
        category: tracking.toAppCategory(),
        action: 'Search',
        label: value
      });
    }
    return setDebouncedValue(value);
  }, [value]);

  return (
    <TextField
      autoFocus={autoFocus}
      type="search"
      value={debouncedValue}
      placeholder={placeholder}
      variant="outlined"
      size="small"
      fullWidth={fullWidth}
      InputProps={{
        fullWidth,
        style: size === 'small' ? { height: '36px', fontSize: 14 } : undefined,
        startAdornment: (
          <InputAdornment position="start">
            <IconSearch size={16} />
          </InputAdornment>
        )
      }}
      className={width ? css((_) => ({ width: `${width}px` })) : undefined}
      onChange={(ev) => {
        const val = ev.target.value;
        if (!val) {
          debouncedOnChange.current(val);
          debouncedOnChange.current.flush();
          initialChange.current = true;
        } else {
          setDebouncedValue(val);
        }
      }}
    />
  );
};
