import React, { useMemo, useState } from 'react';
import { CampaignStatusFilterDefinition } from '../../../../../domainTypes/filters';
import { BASIC_MODES, FilterMenu } from '../FilterMenu';
import {
  createCampaignStatusFilterDefinition,
  validateFilterDefinition
} from '../../filters';
import { OptionsList, useCollectionState } from '../Selector';
import {
  CampaignStatus,
  campaignStatuses,
  campaignStatusTitle
} from '../../../../../features/Campaigns/service';
import { FlexContainer } from '../../../../../layout/Flex';
import { CampaignStatusIcon } from '../../../../../features/Campaigns/components/CampaignStatusIcon';
import { isEqual } from 'lodash';
import pluralize from 'pluralize';
import { css } from '../../../../../emotion';

export const CampaignStatusMenu: React.FC<{
  definition: CampaignStatusFilterDefinition;
  onSave: (definition: CampaignStatusFilterDefinition) => void;
}> = ({ definition, onSave }) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const [value, toggleValue] = useCollectionState<CampaignStatus>(
    definition.v as CampaignStatus[]
  );

  const newDefinition = useMemo<CampaignStatusFilterDefinition>(
    () => createCampaignStatusFilterDefinition(value),
    [value]
  );
  const enableSave =
    validateFilterDefinition(newDefinition) &&
    !isEqual(definition, newDefinition);

  const focusValue = (status: CampaignStatus) => {
    onSave(createCampaignStatusFilterDefinition([status]));
  };

  const statuses = useMemo(() => {
    return campaignStatuses.map((status) => ({
      value: status,
      label: (
        <FlexContainer>
          <CampaignStatusIcon status={status} />
          <span>{campaignStatusTitle(status)}</span>
        </FlexContainer>
      )
    }));
  }, []);

  return (
    <FilterMenu>
      <FilterMenu.Header dimension="campaign_status">
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <div
          className={css(() => ({
            overflowY: 'scroll',
            maxHeight: 300,
            display: 'flex',
            flexDirection: 'column'
          }))}
        >
          <OptionsList
            options={statuses}
            selectedValues={value}
            toggle={toggleValue}
            focus={focusValue}
          />
        </div>
      </FilterMenu.Body>
      <FilterMenu.Footer definition={definition}>
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={`Filter by ${pluralize('status', value.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
