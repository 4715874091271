import React from 'react';
import { Helmet } from 'react-helmet';
import { useTrackMixpanelView } from '../../../../../../services/mixpanel';
import { useHasCurrentUserRequiredScopes } from '../../../../../../services/currentUser';
import { NoPermissions } from '../../../../../../components/NoPermissions';
import { PerformancePageBody } from '../../../../components/PerformancePageBody';
import { useHasPayouts } from '../../../../../../services/payouts';
import { PayoutDetails } from './PayoutDetails';

export const PagePerformancePayoutDetails = ({
  payoutId
}: {
  payoutId: string;
}) => {
  useTrackMixpanelView('view_payouts');

  const hasPayoutsAccess = useHasPayouts();
  const [canView] = useHasCurrentUserRequiredScopes([
    'payouts.view',
    'reports.payouts.view'
  ]);

  return (
    <>
      <Helmet>
        <title>Payouts | Affilimate</title>
      </Helmet>
      <PerformancePageBody noTopPadding>
        {canView && hasPayoutsAccess ? (
          <PayoutDetails payoutId={payoutId} />
        ) : (
          <NoPermissions />
        )}
      </PerformancePageBody>
    </>
  );
};
