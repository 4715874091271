import firebase from 'firebase/app';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Doc, toDoc } from '../../domainTypes/document';
import {
  IPageRevision,
  IPageScreenshot,
  IPageScreenshotDetails
} from '../../domainTypes/page';
import { FS } from '../../versions';
import { compress, decompress } from '../compression';
import { useMappedLoadingValue, store } from '../db';
import { compareTimestamps } from '../time';
import { removeTrailingSlash } from '../url';

export const screenshotCollection = () =>
  store().collection(FS.pageScreenshots);

const compressDetails = (d: IPageScreenshotDetails) => {
  return {
    ...d,
    links: compress(d.links)
  };
};

const decompressDetails = (d: IPageScreenshotDetails) => ({
  ...d,
  links: decompress(d.links as any)
});

export const screenshotV1ToV2 = (d: IPageScreenshot) => {
  const v1: any = d;
  const { links, storagePaths, dimensions, ...other } = v1;
  return {
    ...other,
    v: 2,
    byDevice: {
      desktop: {
        links,
        storagePaths,
        dimensions
      },
      mobile: undefined
    }
  };
};
export const toPageScreenshotDoc = (
  d:
    | firebase.firestore.QueryDocumentSnapshot
    | firebase.firestore.DocumentSnapshot
) => {
  return toDoc<IPageScreenshot>(d, (d) => {
    if ((d.v as number) === 1 || !d.v) {
      d = screenshotV1ToV2(d);
    }

    return {
      ...d,
      byDevice: {
        desktop: decompressDetails(d.byDevice.desktop),
        mobile: d.byDevice.mobile
          ? decompressDetails(d.byDevice.mobile)
          : undefined
      }
    };
  });
};

const withCompressedData = (
  data: Partial<IPageScreenshot> & { byDevice: IPageScreenshot['byDevice'] }
) => {
  return {
    ...data,
    byDevice: {
      desktop: compressDetails(data.byDevice.desktop),
      mobile: data.byDevice.mobile
        ? compressDetails(data.byDevice.mobile)
        : undefined
    }
  };
};

const EMPTY_DETAILS: IPageScreenshotDetails = {
  dimensions: { width: 0, height: 0 },
  storagePaths: [],
  links: []
};

export const recreateScreenshot = (doc: Doc<IPageScreenshot>) => {
  const updates = {
    createdAt: firebase.firestore.Timestamp.now(),
    hasError: false,
    finishedAt: null,
    pageModifiedAt: doc.data.pageModifiedAt,
    status: 'PENDING' as IPageScreenshot['status'],
    byDevice: {
      desktop: { ...EMPTY_DETAILS },
      mobile: { ...EMPTY_DETAILS }
    },
    v: 2 as 2
  };
  return screenshotCollection().doc(doc.id).update(withCompressedData(updates));
};

export const createScreenshotDoc = (
  spaceId: string,
  url: string,
  createdBy: string,
  pageModifiedAt: firebase.firestore.Timestamp | null = null
) => {
  const data: IPageScreenshot = {
    spaceId,
    url: removeTrailingSlash(url),
    createdBy,
    createdAt: firebase.firestore.Timestamp.now(),
    finishedAt: null,
    status: 'PENDING',
    hasError: false,
    pageModifiedAt,
    byDevice: {
      desktop: { ...EMPTY_DETAILS },
      mobile: { ...EMPTY_DETAILS }
    },
    v: 2
  };
  return screenshotCollection()
    .add(withCompressedData(data))
    .then(
      (ref) =>
        ({
          id: ref.id,
          data
        } as Doc<IPageScreenshot>)
    );
};

// all this might benefit from some caching down the road

const DATE_FIELD = 'pageModifiedAt';

const getScreenshotQuery = (
  spaceId: string,
  url: string,
  revision: IPageRevision
) => {
  return screenshotCollection()
    .where('spaceId', '==', spaceId)
    .where('url', '==', removeTrailingSlash(url))
    .where(DATE_FIELD, '==', revision.lastModifiedAt);
};

const extractScreenshotDocs = (s: firebase.firestore.QuerySnapshot) => {
  const docs = s.docs
    .map(toPageScreenshotDoc)
    .filter((d) => d.data.status !== 'ERROR');

  if (!docs.length) {
    return null;
  }

  return docs.sort((a, b) =>
    compareTimestamps(a.data.createdAt, b.data.createdAt)
  )[docs.length - 1];
};

export const getScreenshotsForRevision = (
  spaceId: string,
  url: string,
  revision: IPageRevision
) =>
  getScreenshotQuery(spaceId, url, revision).get().then(extractScreenshotDocs);

export const useScreenshotMetadata = (
  spaceId: string,
  url: string,
  revision: IPageRevision
) => {
  return useMappedLoadingValue(
    useCollection(getScreenshotQuery(spaceId, url, revision)),
    extractScreenshotDocs
  );
};
