import { toPairs } from 'lodash';
import { Dictionary } from '../../hooks/useDictionary';
import { getSelectorLabel } from '../../components/Table';

export const getSelectedItems = <T>(
  items: T[],
  selection: Dictionary<boolean>,
  toKey: (item: T) => string
): T[] => {
  return toPairs(selection).reduce<T[]>((m, [key, selected]) => {
    if (selected) {
      const item = items.find((d) => toKey(d) === key);
      if (item) {
        m.push(item);
      }
    }
    return m;
  }, []);
};

interface Selectable {
  selected: { [id: string]: boolean };
  setSelected: (nextSelected: { [id: string]: boolean }) => void;
}

export const toSelector = (ids: string[], o: Selectable) =>
  getSelectorLabel(
    ids,
    (id) => !!o.selected[id],
    (nextSelected) => o.setSelected({ ...o.selected, ...nextSelected })
  );
