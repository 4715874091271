import {
  DateInput,
  DateRangePicker,
  DateRangePickerProps,
  DateSegment,
  Group
} from 'react-aria-components';
import { ZonedDateTime } from '@internationalized/date';
import React from 'react';
import { styled, css } from '../../emotion';
import { isNil } from 'lodash';
import { Tooltip } from '@material-ui/core';
import { Granularity, GranularitySwitch } from './GranularitySwitch';

const DateRangeInputContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(p) => p.theme.spacing(2)}px;

  .react-aria-DateRangePicker {
    color: ${(p) => p.theme.palette.text.primary};

    .react-aria-Group {
      display: flex;
      gap: ${(p) => p.theme.spacing(1)}px;
      align-items: center;
      width: fit-content;
      max-width: 100%;
      box-sizing: border-box;
      overflow: auto;
      position: relative;
      padding: 8px 12px;
      border: 1px solid rgb(0 0 0 / 6%);
      border-radius: 8px;
      background: ${(p) => p.theme.palette.common.white};
      white-space: nowrap;

      &[data-pressed] {
        box-shadow: none;
        background: ${(p) => p.theme.palette.grey.A100};
      }

      &[data-invalid] {
        // YUCK!
        outline: 2px solid ${(p) => p.theme.palette.error.main} !important;
        outline-offset: -1px;
      }

      &[data-focus-within]:not([data-readonly='true']) {
        outline: 2px solid ${(p) => p.theme.palette.primary.main};
        outline-offset: -1px;
      }
    }

    .react-aria-DateInput {
      display: flex;
      width: unset;
      min-width: unset;
      padding: unset;
      border: unset;
      outline: unset;

      [data-focused]:not([aria-readonly='true']) {
        background-color: ${(p) => p.theme.custom.colors.primary.main};
        outline: 1px solid ${(p) => p.theme.custom.colors.primary.main};
        color: ${(p) => p.theme.custom.colors.primary.light};
        border-radius: 4px;
      }
    }
  }
`;

export const DateRangeInput = ({
  value,
  onChange,
  isReadOnly,
  minValue,
  maxValue,
  errorMessage,
  granularity,
  onGranularityChange,
  hideGranularitySwitch = false
}: Pick<
  DateRangePickerProps<ZonedDateTime>,
  'value' | 'onChange' | 'isReadOnly' | 'minValue' | 'maxValue'
> & {
  errorMessage?: string;
  granularity: Granularity;
  onGranularityChange: (granularity: Granularity) => void;
  hideGranularitySwitch?: boolean;
}) => {
  const isInvalid = !isNil(errorMessage);
  return (
    <Tooltip
      open={isInvalid}
      title={errorMessage || ''}
      placement="top"
      classes={{
        tooltip: css(() => ({
          backgroundColor: 'red',
          position: 'relative',
          top: '2px'
        })),
        popper: css(() => ({
          zIndex: 99999
        }))
      }}
    >
      <DateRangeInputContainer>
        <DateRangePicker
          isReadOnly={isReadOnly}
          value={value}
          onChange={onChange}
          minValue={minValue}
          maxValue={maxValue}
          isInvalid={isInvalid}
          granularity={granularity}
          hideTimeZone
        >
          <Group data-readonly={isReadOnly}>
            <DateInput slot="start">
              {(segment) => <DateSegment segment={segment} />}
            </DateInput>
            <span aria-hidden>&ndash;</span>
            <DateInput slot="end">
              {(segment) => <DateSegment segment={segment} />}
            </DateInput>
            {!hideGranularitySwitch ? (
              <GranularitySwitch
                value={granularity}
                onChange={onGranularityChange}
              />
            ) : null}
          </Group>
        </DateRangePicker>
      </DateRangeInputContainer>
    </Tooltip>
  );
};
