import {
  dbColumn,
  UtmParameterType
} from '../../../../features/Content/pages/Traffic/Utm/service/utm';
import {
  createUTMFilterDefinition,
  useDimensionName,
  validateFilterDefinition
} from '../filters';
import React, { useMemo, useState } from 'react';
import { FieldSelectorMenu, useCollectionState } from './Selector';
import {
  AnalyticsFilter,
  ISOTimeRange
} from '../../../../domainTypes/analytics_v2';
import { BASIC_MODES, FilterMenu } from './FilterMenu';
import pluralize from 'pluralize';
import { isEqual } from 'lodash';
import { Metric } from '../../../../services/analyticsV2/metrics';
import {
  FilterDefinition,
  UtmFilterDefinition
} from '../../../../domainTypes/filters';

export const UtmMenu = ({
  parameter,
  definition,
  onSave,
  filters,
  orderBy,
  range
}: {
  parameter: UtmParameterType;
  definition: UtmFilterDefinition;
  onSave: (v: FilterDefinition) => void;
  filters: AnalyticsFilter[];
  orderBy: Metric;
  range: ISOTimeRange;
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const [value, toggleValue] = useCollectionState(definition.v);
  const dimension = definition.k;
  const newDefinition = useMemo<UtmFilterDefinition>(
    () => createUTMFilterDefinition(parameter, value),
    [parameter, value]
  );
  const enableSave =
    validateFilterDefinition(newDefinition) &&
    !isEqual(definition, newDefinition);

  const focusValue = (utm: string) => {
    onSave(createUTMFilterDefinition(parameter, [utm]));
  };

  return (
    <FilterMenu>
      <FilterMenu.Header dimension={dimension}>
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <FieldSelectorMenu
          label={useDimensionName(dimension)}
          selectedValues={value}
          toggleValue={toggleValue}
          focusValue={focusValue}
          queryFilters={filters}
          analyticsField={dbColumn(parameter)}
          orderBy={orderBy}
          range={range}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer definition={definition}>
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={`Filter by ${pluralize(parameter, value.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
