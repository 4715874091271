import React from 'react';
import { css } from '../../../emotion';

type Props = {
  href: string;
};

export const LinkExternal: React.FC<Props> = ({ href, children }) => {
  return (
    <a
      className={css(() => ({ color: 'inherit', textDecoration: 'underline' }))}
      rel="noopener noreferrer"
      target="_blank"
      onClick={ev => ev.stopPropagation()}
      href={href}
    >
      {children || href}
    </a>
  );
};
