import { CampaignGoals } from '../../../../domainTypes/campaigns';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Header, Stack } from './form-components';
import {
  Button,
  ButtonBase,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import { PlusCircle, XCircle } from 'react-feather';
import { FlexContainer } from '../../../../layout/Flex';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import { MoneyInput } from '../../../../components/MoneyInput';
import { symbolForCurrencyCode } from '../../../../domainTypes/currency';
import { compact } from 'lodash';

const INPUT_WIDTH = 220;

type Goal = CampaignGoals[number];

type GoalType = CampaignGoals[number]['type'];

const TrafficGoalInput = ({
  value,
  onChange,
  label
}: {
  label: string;
  value: Goal;
  onChange: (goal: Goal) => void;
}) => {
  return (
    <TextField
      type="number"
      variant="outlined"
      style={{
        width: INPUT_WIDTH
      }}
      inputProps={{
        step: 1
      }}
      value={value.amount}
      onChange={(event) =>
        onChange({
          type: value.type,
          amount: Number(event.target.value)
        })
      }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{label}</InputAdornment>
        )
      }}
    />
  );
};

const GMVGoalInput = ({
  value,
  onChange
}: {
  value: Goal;
  onChange: (goal: Goal) => void;
}) => {
  const currency = useSpaceCurrency();
  return (
    <MoneyInput
      onChange={(amount) =>
        onChange({
          type: 'gmv',
          amount: amount * 100
        })
      }
      style={{
        width: INPUT_WIDTH
      }}
      value={value.amount / 100}
      variant="outlined"
      numericFormatProps={{
        prefix: symbolForCurrencyCode(currency)
      }}
      InputProps={{
        startAdornment: <InputAdornment position="start">GMV</InputAdornment>
      }}
    />
  );
};

const ROASGoalInput = ({
  value,
  onChange
}: {
  value: Goal;
  onChange: (goal: Goal) => void;
}) => {
  return (
    <TextField
      type="number"
      variant="outlined"
      style={{
        width: INPUT_WIDTH
      }}
      inputProps={{
        step: 1
      }}
      value={value.amount}
      onChange={(event) =>
        onChange({
          type: 'roas',
          amount: Number(event.target.value)
        })
      }
      InputProps={{
        startAdornment: <InputAdornment position="start">ROAS</InputAdornment>,
        endAdornment: <InputAdornment position="end">x</InputAdornment>
      }}
    />
  );
};

const CampaignGoalInput = ({
  value,
  onChange
}: {
  value: Goal;
  onChange: (goal: Goal) => void;
}) => {
  switch (value.type) {
    case 'roas':
      return <ROASGoalInput value={value} onChange={onChange} />;
    case 'gmv':
      return <GMVGoalInput value={value} onChange={onChange} />;
    case 'clicks':
      return (
        <TrafficGoalInput value={value} onChange={onChange} label="Clicks" />
      );
    case 'pageviews':
      return (
        <TrafficGoalInput value={value} onChange={onChange} label="Pageviews" />
      );
  }
};

interface CampaignGoalsSelectorProps {
  value: CampaignGoals;
  onChange: (goals: CampaignGoals) => void;
}

export const CampaignGoalsSelector: React.FC<CampaignGoalsSelectorProps> = ({
  value,
  onChange
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchor = useRef<HTMLButtonElement | null>(null);
  const options = useMemo(() => {
    const hasGoalOfType = (type: GoalType) =>
      value.some((goal) => goal.type === type);
    return compact<{ type: GoalType; label: string }>([
      !hasGoalOfType('clicks') && { type: 'clicks', label: 'Clicks' },
      !hasGoalOfType('pageviews') && { type: 'pageviews', label: 'Pageviews' },
      !hasGoalOfType('roas') &&
        !hasGoalOfType('gmv') && { type: 'roas', label: 'ROAS' },
      !hasGoalOfType('roas') &&
        !hasGoalOfType('gmv') && { type: 'gmv', label: 'GMV' }
    ]);
  }, [value]);
  const addGoal = useCallback(
    (type: GoalType) => {
      const amount = type === 'roas' ? 1 : 1000;
      onChange([...value, { type, amount }]);
    },
    [onChange, value]
  );
  const changeGoal = useCallback(
    (newGoal: Goal) => {
      onChange(
        value.map((goal) => (goal.type === newGoal.type ? newGoal : goal))
      );
    },
    [onChange, value]
  );
  return (
    <Stack>
      <Header>
        GOALS
        <Button
          ref={menuAnchor}
          color="primary"
          style={{ marginLeft: 12 }}
          onClick={() => setMenuOpen(true)}
          startIcon={<PlusCircle size={16} />}
          disabled={options.length === 0}
        >
          Add goal
        </Button>
        <Menu
          open={menuOpen}
          anchorEl={menuAnchor.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          onClose={() => setMenuOpen(false)}
        >
          {options.map((option) => (
            <MenuItem
              key={option.type}
              onClick={() => {
                addGoal(option.type);
                setMenuOpen(false);
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
        <FlexContainer wrap="wrap" spacing={4}>
          {value.map((goal) => (
            <FlexContainer spacing={1} key={goal.type}>
              <CampaignGoalInput
                key={goal.type}
                value={goal}
                onChange={changeGoal}
              />
              <ButtonBase
                onClick={() =>
                  onChange(value.filter((v) => v.type !== goal.type))
                }
              >
                <XCircle size={18} />
              </ButtonBase>
            </FlexContainer>
          ))}
          {value.length === 0 && (
            <Typography variant="body2" color="textSecondary">
              Choose at least one goal for your campaign
            </Typography>
          )}
        </FlexContainer>
      </Header>
    </Stack>
  );
};
