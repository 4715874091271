import { compact } from 'lodash';
import { useMemo } from 'react';
import {
  AnalyticsColumnTransformer,
  AnalyticsFilter
} from '../../../../domainTypes/analytics_v2';
import { useTypedStringQueryParam } from '../../../../routes';
import { usePageSearch } from './components/PageSearchBox';
import { useFilterClauses } from '../../../../components/analytics_v2/Filters/hooks';
import { useChannelIdGrouper } from '../../../../services/analyticsV2/groups';
import { useCurrentUser } from '../../../../services/currentUser';

export const useRealtimeFilters = (): {
  filters: AnalyticsFilter[];
  columnTransformers: AnalyticsColumnTransformer[];
} => {
  const { space } = useCurrentUser();
  const { columnTransformers } = useChannelIdGrouper();
  const filterClauses = useFilterClauses();
  const pageSearch = usePageSearch('page_url');
  return useMemo(() => {
    return {
      filters: compact([pageSearch, ...filterClauses]),
      columnTransformers: filterClauses.some((f) => f.field === 'channel_id')
        ? columnTransformers(space)
        : []
    };
  }, [pageSearch, filterClauses, columnTransformers, space]);
};

export const trafficMetrics = ['c', 'p'] as const;
export const earningsMetrics = [
  'commission_sum_net',
  'gmv_sum_net',
  'epc_net',
  'rpm_net'
] as const;

export type TrafficMetric = typeof trafficMetrics[number];
export type EarningMetric = typeof earningsMetrics[number];

export type RealtimeMetric = TrafficMetric | EarningMetric;

export const metricDescription = (m: RealtimeMetric): string => {
  switch (m) {
    case 'epc_net':
      return 'Earnings per click, for clicks and earnings during the selected timeframe.';
    case 'rpm_net':
      return 'Revenue per thousand pageviews. The average revenue you can expect to earn per thousand pageviews, based on pageviews and earnings during the selected timeframe.';
    case 'p':
      return 'Pageviews of affiliate content during the selected timeframe.';
    case 'c':
      return 'Clicks on affiliate links during the selected timeframe.';
    case 'gmv_sum_net':
      return 'Sales volume (GMV). This is the total value of sales made through affiliate links during the selected timeframe.';
    case 'commission_sum_net':
      return 'Earnings from affiliate links where a conversion occurred during the selected timeframe, and the network has reported that conversion.';
  }
};

export const useRealtimeMetric = () =>
  useTypedStringQueryParam<RealtimeMetric>('metric', 'c');

export const useSmallTableMetrics = (): RealtimeMetric[] => {
  const [metric] = useRealtimeMetric();
  return useMemo(() => {
    const lasMetric = trafficMetrics.includes(metric as TrafficMetric)
      ? 'commission_sum_net'
      : metric;
    return [...trafficMetrics, lasMetric];
  }, [metric]);
};
