import { Card } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Centered } from '../../../../layout/Centered';
import { LimitedWidth } from '../../../../layout/PageBody';
import { useRoutes } from '../../../../routes';
import { isChrome } from '../../../../services/browser';
import { useCurrentUser } from '../../../../services/currentUser';
import { ChromeExtension } from './components/Chrome';
import { SettingsLayout } from '../../SettingsLayout';
import { useFeatureEnabled } from '../../../../services/features';

const HEIGHT = 100;

export const PagesSettingsNewBrowserExtension = () => {
  const currentUser = useCurrentUser();
  const { getQuery } = useRoutes();
  const { install } = getQuery();
  const hasFeatureEnabled = useFeatureEnabled('CHROME_EXTENSION');

  return (
    <SettingsLayout>
      <Helmet>
        <title>Browser Extension | Affilimate</title>
      </Helmet>
      {hasFeatureEnabled ? (
        <LimitedWidth width={1160}>
          {!isChrome && (
            <Card>
              <Centered height={HEIGHT}>
                Only available for Chrome users.
              </Centered>
            </Card>
          )}
          {isChrome && (
            <ChromeExtension currentUser={currentUser} install={!!install} />
          )}
        </LimitedWidth>
      ) : (
        <LimitedWidth width={1160}>
          <Card>
            <Centered height={HEIGHT}>
              This feature is not currently available for account.
            </Centered>
          </Card>
        </LimitedWidth>
      )}
    </SettingsLayout>
  );
};
