import React from 'react';
import { Minus as IconStable } from 'react-feather';
import { styled } from '../../emotion';
import { ReactComponent as Arrow } from '../../icons/arrow.svg';
import { getTrend } from '../../services/analytics';
import { AffilimateTheme, useTheme } from '../../themes';
import { DEFAULT_COLOR_SCHEME } from '../../domainTypes/colors';
import { formatNumber } from '../Number';
import { COLOR_SCALE } from '../../services/color';

const DEFAULT_THRESHOLDS: [number, number] = [0.03, 0.1];

const COLORS = {
  DOWN: '#b64b4c',
  SLIGHT_DOWN: COLOR_SCALE[4],
  UP: DEFAULT_COLOR_SCHEME.green.bgColor,
  SLIGHT_UP: COLOR_SCALE[8]
};

const IconDown = (props: any) => <Arrow {...props} />;

const IconSlightDown = (props: any) => (
  <Arrow {...props} style={{ ...props.style, transform: 'rotate(45deg)' }} />
);

const IconUp = (props: any) => (
  <Arrow {...props} style={{ ...props.style, transform: 'rotate(180deg)' }} />
);

const IconSlightUp = (props: any) => (
  <Arrow {...props} style={{ ...props.style, transform: 'rotate(220deg)' }} />
);

export const getTrendRatio = (
  trend: number,
  thresholds: [number, number] = DEFAULT_THRESHOLDS
): -2 | -1 | 0 | 1 | 2 => {
  const [stable, steep] = thresholds;
  const abs = Math.abs(trend);
  const isStable = abs <= stable;
  const isSteep = abs > steep;

  if (isStable) {
    return 0;
  }
  if (trend < 0) {
    return isSteep ? -2 : -1;
  }
  return isSteep ? 2 : 1;
};

type Colors = AffilimateTheme['custom']['colors'];

const getIconConfigs = (invert: boolean) => {
  return [
    {
      icon: IconDown,
      color: () => (invert ? COLORS.UP : COLORS.DOWN)
    },
    {
      icon: IconSlightDown,
      color: () => (invert ? COLORS.SLIGHT_UP : COLORS.SLIGHT_DOWN),
      style: { transform: 'scale(-1, 1)' }
    },
    {
      icon: IconStable,
      color: (c: Colors) => c.pending.main
    },
    {
      icon: IconSlightUp,
      color: () => (invert ? COLORS.SLIGHT_DOWN : COLORS.SLIGHT_UP)
    },
    {
      icon: IconUp,
      color: () => (invert ? COLORS.DOWN : COLORS.UP)
    }
  ];
};

const getFontSize = (
  theme: AffilimateTheme,
  fontSize: number | string | undefined
) => {
  if (fontSize === undefined) {
    return `${theme.custom.fontSize.m}px`;
  }
  if (typeof fontSize === 'number') {
    return `${fontSize}px`;
  }
  return fontSize;
};

const Label = styled<
  'span',
  { color: string; fontSize: number | string | undefined }
>('span')`
  color: ${(p) => p.color};
  font-size: ${(p) => getFontSize(p.theme, p.fontSize)};
  font-weight: bold;
`;

const Container = styled<
  'div',
  { justifyContent: React.CSSProperties['justifyContent'] }
>('div')`
  display: flex;
  align-items: center;
  justify-content: ${(p) => p.justifyContent};
`;

export const bestDigits = (percent: number) => {
  const p = Math.abs(percent) * 100;
  if (p < 0) {
    return 2;
  }
  if (p > 10) {
    return 0;
  }
  return 1;
};

type Props = {
  values: [number, number];
  label?: 'none' | 'left' | 'right';
  thresholds?: [number, number];
  labelSize?: number | string;
  arrowSize?: number;
  justifyContent?: React.CSSProperties['justifyContent'];
  invert?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

export const formatTrend = (trend: number, plusMinus?: boolean) =>
  formatNumber({
    n: trend,
    format: 'percent',
    digits: bestDigits(trend),
    plusMinus
  });

export const Trend: React.FC<Props> = ({
  label,
  thresholds,
  values,
  labelSize = 12,
  arrowSize = 12,
  justifyContent = 'flex-start',
  className,
  invert = false,
  style
}) => {
  const theme = useTheme();
  label = label || 'none';
  thresholds = thresholds || DEFAULT_THRESHOLDS;

  const [before, after] = values;
  const trend = getTrend(before, after);
  const ratio = getTrendRatio(trend, thresholds);
  const config = getIconConfigs(invert)[ratio + 2];
  const Icon = config.icon;
  const color =
    style && style.color ? style.color : config.color(theme.custom.colors);

  const trendString = formatTrend(trend);
  const iconMargin: React.CSSProperties =
    label === 'none'
      ? {}
      : label === 'left'
      ? { marginLeft: '6px' }
      : { marginRight: '6px' };

  return (
    <Container
      className={className}
      style={style}
      justifyContent={justifyContent}
    >
      {label === 'left' && (
        <Label fontSize={labelSize} color={color}>
          {trendString}{' '}
        </Label>
      )}
      <Icon
        height={`${arrowSize}px`}
        width={`${arrowSize}px`}
        style={{ color, ...iconMargin, fill: color, ...config.style }}
      />
      {label === 'right' && (
        <Label fontSize={labelSize} color={color}>
          {' '}
          {trendString}
        </Label>
      )}
    </Container>
  );
};
