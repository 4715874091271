import { debounce } from 'lodash';
import React, { useMemo, useRef } from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List
} from 'react-virtualized';

type RowProps<T> = {
  row: T;
  index: number;
  isScrolling: boolean;
  key: string;
  resizeRow: () => void;
  style: React.CSSProperties;
};

export type ListVirtualizedRef = {
  resizeAll: () => void;
};

type Props<T> = {
  rows: T[];
  height?: number;
  width?: number;
  children: (props: RowProps<T>) => React.ReactNode;
  getRef?: React.MutableRefObject<ListVirtualizedRef | null> | null;
};

const useCellMeasuerCache = (defaultHeight?: number) => {
  return useMemo(
    () => new CellMeasurerCache({ defaultHeight, fixedWidth: true }),
    [defaultHeight]
  );
};

const recomputeRowHeights = (list: List | null, startingIndex?: number) => {
  if (list) {
    list.recomputeRowHeights(startingIndex);
  }
};

export const ListVirtualized = <T extends {}>({
  rows,
  height: h,
  width: w,
  children,
  getRef
}: Props<T>) => {
  const disableHeight = !!h;
  const disableWidth = !!w;

  const cache = useCellMeasuerCache();
  const listRef = useRef<List>(null);
  const lastKnownWidth = useRef<number | undefined>(w);

  const resize = (index: number) => {
    cache.clear(index, 0);
    recomputeRowHeights(listRef.current, index);
  };

  const resizeAll = debounce(() => {
    console.log('resizing all');
    cache.clearAll();
    recomputeRowHeights(listRef.current);
  }, 150);

  if (getRef) {
    getRef.current = { resizeAll };
  }

  const checkWidth = (nextWidth: number) => {
    const { current } = lastKnownWidth;
    if (current !== nextWidth) {
      lastKnownWidth.current = nextWidth;
      if (current) {
        // no need to do this when we didn't know the width before at all
        setTimeout(resizeAll);
      }
    }
  };

  return (
    <AutoSizer disableHeight={disableHeight} disableWidth={disableWidth}>
      {dimensions => {
        const height = h || dimensions.height;
        const width = w || dimensions.width;

        checkWidth(width);

        return (
          <List
            ref={listRef}
            deferredMeasurementCache={cache}
            width={width}
            height={height}
            rowCount={rows.length}
            rowHeight={cache.rowHeight}
            rowRenderer={({ index, key, parent, style, isScrolling }) => {
              const loading = index >= rows.length;
              return (
                <CellMeasurer
                  cache={cache}
                  columnIndex={0}
                  key={key}
                  parent={parent}
                  rowIndex={index}
                  width={width}
                >
                  {loading
                    ? null
                    : children({
                        row: rows[index],
                        index,
                        key,
                        isScrolling,
                        resizeRow: () => setTimeout(() => resize(index), 0),
                        style
                      })}
                </CellMeasurer>
              );
            }}
          />
        );
      }}
    </AutoSizer>
  );
};
