import { kebabCase } from 'lodash';
import { LinkGeneratorOption, LinkGeneratorOptionGroup } from './types';

export const LINK_GENERATOR_BASIC_OPTIONS: LinkGeneratorOption[] = [
  {
    value: 'subid',
    label: 'SubID',
    group: LinkGeneratorOptionGroup.Standard,
    inputType: 'text'
  }
];

const THE_SKIMM_CATEGORIES = [
  'Clothing',
  'Accessories',
  'Shoes ',
  'Jewelry',
  'Makeup',
  'Skincare',
  'Hair',
  'Kitchen & Dining',
  'Indoor Furniture',
  'Home Decor',
  'Outdoor Furniture',
  'Home Improvement & Organization',
  'Hobbies & Games',
  'Office & School Supplies',
  'Baby',
  'Kids',
  'Pet ',
  'Tech',
  'Travel',
  'Entertainment',
  'Food & Bev',
  'Subscriptions',
  'Book',
  'Health & Fitness',
  'Misc.'
];

export const LINK_GENERATOR_OPTIONS_PER_SPACE: Record<
  string,
  LinkGeneratorOption[]
> = {
  // theSkimm
  sKa82EYZ0: [
    {
      value: 'click_data_01',
      label: 'Section',
      group: LinkGeneratorOptionGroup.Offsite,
      inputType: 'select',
      options: [
        {
          value: 'skimm-picks',
          label: 'SkimmPicks'
        },
        {
          value: 'but-first',
          label: 'But First'
        },
        {
          value: 'whats-that-thing',
          label: `What's That Thing`
        },
        {
          value: 'splurge-vs-save',
          label: `Splurge vs Save`
        },
        {
          value: 'jwt',
          label: 'JWT'
        },
        {
          value: 'skimm-reads',
          label: 'Skimm Reads'
        },
        {
          value: 'daily-steal',
          label: 'The Daily Steal'
        },
        {
          value: 'shopping-spotlight',
          label: 'Shopping Spotlight'
        },
        {
          value: 'trt',
          label: 'TRT'
        },
        {
          value: 'strong-feelings',
          label: 'Strong Feelings'
        },
        {
          value: 'shl',
          label: 'SHL'
        },
        {
          value: 'deals',
          label: 'Deals'
        },
        {
          value: 'bookmark-this',
          label: 'Bookmark This'
        },
        {
          value: 'how-to',
          label: 'How To'
        },
        {
          value: 'not-so-silly-little-treat',
          label: '(Not So) Silly Little Treat'
        },
        {
          value: 'oor',
          label: 'OOR'
        },
        {
          value: 'take-note',
          label: 'Take Note'
        },
        {
          value: 'well-gifted',
          label: 'Well Gifted'
        },
        {
          value: 'the-resource',
          label: 'The Resource'
        },
        {
          value: 'misc',
          label: 'Misc'
        },
        {
          value: 'ask-an-expert',
          label: 'Ask An Expert'
        },
        {
          value: 'mom-picks',
          label: 'Mom Picks'
        },
        {
          value: 'ask-us-anything',
          label: 'Ask us anything'
        }
      ]
    },
    {
      value: 'click_data_02',
      label: 'Product Category',
      group: LinkGeneratorOptionGroup.Offsite,
      inputType: 'select',
      options: THE_SKIMM_CATEGORIES.map((category) => ({
        value: kebabCase(category),
        label: category
      }))
    },
    {
      value: 'utm_campaign',
      label: 'UTM Campaign',
      group: LinkGeneratorOptionGroup.Offsite,
      inputType: 'text'
    }
  ],
  '4oF26UAIvKcsv6YOxO7gtvrifrn2': [
    {
      value: 'click_data_01',
      label: 'Section',
      group: LinkGeneratorOptionGroup.Offsite,
      inputType: 'select',
      options: [
        {
          value: 'read-with-us',
          label: 'Read With Us'
        },
        {
          value: 'daily-deal',
          label: 'Daily Deal'
        },
        {
          value: 'product-spotlight',
          label: 'Product Spotlight'
        }
      ]
    },
    {
      value: 'utm_campaign',
      label: 'UTM Campaign',
      group: LinkGeneratorOptionGroup.Offsite,
      inputType: 'text'
    },
    {
      value: 'utm_source',
      label: 'UTM Source',
      group: LinkGeneratorOptionGroup.Offsite,
      inputType: 'text'
    },
    {
      value: 'utm_medium',
      label: 'UTM Medium',
      group: LinkGeneratorOptionGroup.Offsite,
      inputType: 'autocomplete',
      field: 'utm_medium'
    }
  ]
};

export const LINK_WRAPPER_ADVANCED_OPTIONS: LinkGeneratorOption[] = [
  {
    value: 'page_url',
    label: 'Page URL',
    group: LinkGeneratorOptionGroup.Offsite,
    inputType: 'autocomplete',
    field: 'page_url'
  },
  {
    value: 'utm_campaign',
    label: 'UTM Campaign',
    group: LinkGeneratorOptionGroup.Offsite,
    inputType: 'autocomplete',
    field: 'utm_campaign'
  },
  {
    value: 'utm_campaign',
    label: 'UTM Campaign',
    group: LinkGeneratorOptionGroup.Offsite,
    inputType: 'text'
  },
  {
    value: 'utm_source',
    label: 'UTM Source',
    group: LinkGeneratorOptionGroup.Offsite,
    inputType: 'text'
  },
  {
    value: 'utm_medium',
    label: 'UTM Medium',
    group: LinkGeneratorOptionGroup.Offsite,
    inputType: 'text'
  }
];
