import moment from 'moment-timezone';
import { TrackingStatsHourlyBySpaceResponse } from '../../domainTypes/monitoring';
import { CF } from '../../versions';
import { useMappedLoadingValue } from '../db';
import { useFirebaseFunction } from '../firebaseFunctions';
import { guessCurrentTimezone, ONE_MINUTE } from '../time';

export const formatHourKey = (
  hourKey: string,
  format?: string,
  addHours = 0
) => {
  const year = +hourKey.slice(0, 4);
  const dayOfYear = +hourKey.slice(4, 7);
  const hour = +hourKey.slice(7);

  const d = moment()
    .utc()
    .year(year)
    .dayOfYear(dayOfYear)
    .hour(hour + addHours)
    .minute(0)
    .tz(guessCurrentTimezone());

  const f = format || (d.hour() === 0 ? 'MMM DD' : 'HH:mm');
  return d.format(f);
};

const getStartAndEnd = (duration: moment.Duration, fullDaysOnly: boolean) => {
  const n = moment();
  if (fullDaysOnly) {
    return {
      start: n.clone().startOf('d').subtract(duration).valueOf(),
      end: n.clone().startOf('d').valueOf()
    };
  }

  if (n.minute() < 8) {
    // we need some time to fill the most recent hour in -< so we take one step back if we're not past this threshold
    n.subtract(1, 'h');
  }
  // Mind that it's important that start and end are stable numeric values, hence startOf('h'),
  // otherwise this is thrown into an endless request loop.
  // It just changes once an hour.
  return {
    start: n.clone().startOf('h').subtract(duration).valueOf(),
    end: n.clone().startOf('h').valueOf()
  };
};

export const useTrackingStatsHourlyBySpaceId = (
  spaceId: string,
  duration: moment.Duration, // currently always bound to take the most recent time
  fullDaysOnly?: boolean
) => {
  const { start, end } = getStartAndEnd(duration, !!fullDaysOnly);

  return useMappedLoadingValue(
    useFirebaseFunction<TrackingStatsHourlyBySpaceResponse>(
      CF.monitoring.getTrackingStatsHourlyBySpace,
      {
        spaceId,
        range: {
          start,
          end
        }
      },
      [spaceId, start, end],
      ONE_MINUTE * 60
    ),
    (res) => res.data
  );
};
