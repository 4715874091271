import React, { useMemo } from 'react';
import {
  ItemSorter,
  ItemSorters,
  ROW_HEIGHTS,
  RowsRenderer
} from '../GroupableList';
import { IColumn } from '../Table/Column';
import { WithHoverIndicator } from '../WithHoverIndicator';
import { AdvertiserWithColor } from '../AdvertiserWithColor';
import { CountCell } from '../Table';
import { getAov } from '../../services/analytics';
import { Dash } from '../Table/CountCell';
import { isNil } from 'lodash';
import { SortDirection } from '../../hooks/useSort';
import { AdvertiserEarnings } from '../../services/advertisers';

const ROW_TO_KEY = (d: AdvertiserEarnings) => {
  return `${d.advertiserName}--${d.advertiserId}--${d.partner.key}`;
};

export const COLUMNS: Column[] = [
  {
    key: 'name',
    head: () => 'Advertiser',
    cell: (p, o) => {
      const advertiser = (
        <AdvertiserWithColor
          advertiserName={p.advertiserName}
          partnerKey={p.partner.key}
        />
      );
      if (o.showLink) {
        return <WithHoverIndicator>{advertiser}</WithHoverIndicator>;
      }
      return advertiser;
    },
    align: 'left',
    sortable: true,
    width: 200,
    flexGrow: 2
  },
  {
    key: 'partner',
    head: () => 'Platform',
    cell: (p) => p.partner.name,
    align: 'left',
    sortable: true,
    width: 100,
    flexGrow: 1
  },

  {
    key: 'orderCount',
    head: () => 'Orders',
    cell: (p, o) => {
      // Similar to Items Sold and transaction count - this just counts EVERYTHING
      const before = p.prev.orderCount.total;
      const after = p.curr.orderCount.total;

      return (
        <CountCell
          before={before}
          after={after}
          compare={o.compare}
          dashWhenAllZero={true}
        />
      );
    },
    headInfo: () =>
      'How many net orders you drove for this advertiser from Platforms that report Order IDs, considering Final and Pending orders, and subtracting Refunded orders. Used to calculate AOV.',
    align: 'right',
    sortable: true,
    width: 75,
    flexGrow: 1
  },
  {
    key: 'transactionCount',
    head: () => 'Line items',
    cell: (p, o) => (
      <CountCell
        before={p.prev.transactionCount}
        after={p.curr.transactionCount}
        compare={o.compare}
      />
    ),
    headInfo: () =>
      'How many individual line items or orders were posted by this advertiser, during this timeframe. Same as order count for networks and advertisers that do not report SKU-level commissions.',
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 1
  },
  {
    key: 'orderCountGross',
    head: () => 'Orders Gross',
    cell: (p, o) => {
      // Similar to Items Sold and transaction count - this just counts EVERYTHING
      return (
        <CountCell
          before={p.prev.orderCount.all}
          after={p.curr.orderCount.all}
          compare={o.compare}
        />
      );
    },
    headInfo: () =>
      'Gross number of orders were tracked by this advertiser, including canceled, rejected, or refunded orders.',
    align: 'right',
    sortable: true,
    width: 75,
    flexGrow: 1
  },
  {
    key: 'aov',
    head: () => 'AOV',
    cell: (p, o) => {
      // Similar to Items Sold and transaction count - this just counts EVERYTHING
      const totalBefore = p.prev.saleValue.total;
      const totalAfter = p.curr.saleValue.total;
      const ordersBefore = p.prev.orderCount.total;
      const ordersAfter = p.curr.orderCount.total;
      const aovBefore = getAov(ordersBefore, totalBefore);
      const aovAfter = getAov(ordersAfter, totalAfter);

      return (
        <CountCell
          before={aovBefore}
          after={aovAfter}
          compare={o.compare}
          currency={p.curr.currency}
          dashWhenAllZero={true}
        />
      );
    },
    headInfo: () =>
      'Average order value (AOV). How much revenue you drove for the advertiser, on average, for every order generated. Based on Final, Pending, and Refunded commissions. Not all platforms or advertisers report this figure.',
    align: 'right',
    sortable: true,
    width: 75,
    flexGrow: 2
  },
  {
    key: 'lostCount',
    head: () => 'Lost',
    cell: (p, o) => (
      <CountCell
        before={p.prev.lostCount}
        after={p.curr.lostCount}
        invert={true}
        compare={o.compare}
      />
    ),
    headInfo: () =>
      'How many lost transactions (refunded, non-commissionable, or rejected) occurred during this timeframe.',
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 1
  },
  {
    key: 'avgCommissionPercent',
    head: () => 'Comm Rate',
    cell: (p, o) => {
      return p.curr.total ? (
        <CountCell
          format="percent"
          digits={2}
          before={p.prev.avgCommissionPercent}
          after={p.curr.avgCommissionPercent}
          compare={o.compare}
          dashWhenAllZero={true}
        />
      ) : (
        <Dash size={16} />
      );
    },
    headInfo: () =>
      `The average commission rate you're expected to earn through this advertiser during the selected timeframe. A dash may mean the advertiser does not provide order amounts needed to independently calculate the effective rate.`,
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 1
  },
  {
    key: 'avgCommissionNetPercent',
    head: () => 'Net Comm Rate',
    cell: (p, o) => {
      return p.curr.total ? (
        <CountCell
          format="percent"
          digits={2}
          before={p.prev.total / p.prev.saleValue.total}
          after={p.curr.total / p.curr.saleValue.total}
          compare={o.compare}
          dashWhenAllZero={true}
        />
      ) : (
        <Dash size={16} />
      );
    },
    headInfo: () =>
      `The effective commission rate you've earned during the current period. May be lower than the final commission rate if some pending commissions are currently $0.`,
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 1
  },
  {
    key: 'earnings',
    head: () => 'Earnings',
    cell: (p, o) => (
      <CountCell
        before={p.prev.total}
        after={p.curr.total}
        compare={o.compare}
        currency={p.curr.currency}
      />
    ),
    headInfo: () =>
      'How much you earned in Final or Pending commissions, minus Refunded commissions, posted during this timeframe.',
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  },
  {
    key: 'sales',
    head: () => 'Sales volume',
    cell: (p, o) => {
      return (
        <CountCell
          before={p.prev.saleValue.total}
          after={p.curr.saleValue.total}
          compare={o.compare}
          dashWhenAllZero={true}
          currency={p.curr.currency}
        />
      );
    },
    headInfo: () =>
      'How much revenue you drove for this advertiser during the selected time period, considering Final, Pending, and Refunded transactions. Not all platforms and advertisers report this figure.',
    align: 'right',
    sortable: true,
    width: 100,
    flexGrow: 2
  }
];

export const SORTERS: ItemSorters<AdvertiserEarnings> = {
  name: {
    key: 'name',
    items: { sort: (p) => p.advertiserName, dir: 'asc' }
  },
  partner: {
    key: 'partner',
    items: { sort: (p) => p.partner.name, dir: 'asc' }
  },
  transactionCount: {
    key: 'transactionCount',
    items: { sort: (p) => p.curr.transactionCount, dir: 'desc' }
  },
  lostCount: {
    key: 'lostCount',
    items: { sort: (p) => p.curr.lostCount, dir: 'desc' }
  },
  avgCommissionPercent: {
    key: 'avgCommissionPercent',
    items: { sort: (p) => p.curr.avgCommissionPercent, dir: 'desc' }
  },
  avgCommissionNetPercent: {
    key: 'avgCommissionNetPercent',
    items: { sort: (p) => p.curr.avgCommissionPercent, dir: 'desc' }
  },
  earnings: {
    key: 'earnings',
    items: { sort: (p) => p.curr.total, dir: 'desc' }
  },
  sales: {
    key: 'sales',
    items: { sort: (p) => p.curr.saleValue.total, dir: 'desc' }
  },
  aov: {
    key: 'aov',
    items: {
      sort: (p) => {
        const totalAfter = p.curr.saleValue.total;
        const ordersAfter = p.curr.orderCount.total;
        return getAov(ordersAfter, totalAfter);
      },
      dir: 'desc'
    }
  },
  orderCount: {
    key: 'orderCount',
    items: {
      sort: (p) => {
        return p.curr.orderCount.total;
      },
      dir: 'desc'
    }
  }
};

type ColumnName =
  | 'name'
  | 'partner'
  | 'transactionCount'
  | 'earnings'
  | 'sales'
  | 'orderCount'
  | 'orderCountGross'
  | 'aov'
  | 'avgCommissionPercent'
  | 'avgCommissionNetPercent'
  | 'lostCount';

type Column = IColumn<
  AdvertiserEarnings,
  ColumnName,
  { compare: boolean; showLink: boolean }
>;

interface AdvertisersTableProps {
  data: AdvertiserEarnings[];
  columns: Set<ColumnName>;
  compare?: boolean;
  rowToHref?: (d: AdvertiserEarnings) => string;
  sortDirection: SortDirection | undefined;
  sorter: ItemSorter<AdvertiserEarnings> | null;
  setSort: (
    value: [ItemSorter<AdvertiserEarnings>, SortDirection | undefined]
  ) => void;
  stickyHeadOffset: number;
}

export const AdvertisersTable = ({
  data,
  columns,
  compare = false,
  rowToHref,
  sorter,
  sortDirection,
  setSort,
  stickyHeadOffset
}: AdvertisersTableProps) => {
  const otherProps = useMemo(
    () => ({
      compare,
      showLink: !isNil(rowToHref)
    }),
    [compare, rowToHref]
  );
  return (
    <RowsRenderer
      columns={COLUMNS.filter((c) => columns.has(c.key))}
      sorter={sorter || SORTERS.earnings}
      sortDirection={sortDirection}
      onHeadClick={(c, dir) => setSort([SORTERS[c.key] || null, dir])}
      rows={data}
      otherProps={otherProps}
      variant="contained"
      renderHead={true}
      headProps={{
        sticky: true,
        offset: stickyHeadOffset
      }}
      rowToHref={rowToHref}
      rowToKey={ROW_TO_KEY}
      rowHeight={ROW_HEIGHTS.dense}
    />
  );
};
