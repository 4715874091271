import { ButtonBase, CircularProgress, TextField } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { MoreHorizontal, XCircle } from 'react-feather';
import { ColorPicker } from '../../../../../../components/ColorPicker';
import { IPostgresTags } from '../../../../../../domainTypes/tags';
import { useDialogState } from '../../../../../../hooks/useDialogState';
import { useCurrentUser } from '../../../../../../services/currentUser';
import { deleteTag, updateTag } from '../../../../../../services/tags';
import { Color } from '../../../../../../domainTypes/colors';
import { EditTagDialog } from './EditTagDialog';

export const TagInEditMode = ({
  tag,
  setTag,
  onSubmit,
  onCancel
}: {
  tag: IPostgresTags;
  setTag: (newTag: IPostgresTags) => void;
  onCancel: () => void;
  onSubmit: (e: React.SyntheticEvent) => Promise<any>;
}) => {
  const { dialogOpen, setDialogOpen } = useDialogState(false);
  const { space } = useCurrentUser();
  const [loading, setLoading] = useState(false);
  const focusRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: Event) => {
      const isColorSwatch = (event.target as any).getAttribute('colorname');
      const isExitingPopover =
        (event.target as any).getAttribute('class') === 'MuiPopover-root';

      const containsTarget = focusRef.current?.contains(event.target as any);

      // Do not count it as an off-click if the dialog is open
      if (dialogOpen) {
        return;
      }

      if (!containsTarget && !isColorSwatch && !isExitingPopover) {
        onCancel();
      }
    };
    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, [onCancel, dialogOpen]);

  const handleDelete = async () => {
    setLoading(true);
    return deleteTag(space.id, tag.id).then(() => {
      onCancel();
    });
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    return onSubmit(e);
  };

  const handleUpdate = async (newTag: IPostgresTags) => {
    setLoading(true);
    return updateTag(space.id, newTag).then(() => {
      setLoading(false);
    });
  };

  const handleColorEdit = async (newColor: Color) => {
    if (tag.id) {
      // Means the tag already exists, so we want to
      // immediately edit the color on the server
      setLoading(true);
      updateTag(space.id, { ...tag, color: newColor }).then(() => {
        setLoading(false);
      });
    }
  };

  return (
    <div ref={focusRef}>
      <form onSubmit={handleSubmit}>
        <TextField
          size="small"
          variant="outlined"
          autoFocus
          value={tag.name}
          onChange={(e) => setTag({ ...tag, name: e.target.value })}
          style={{ width: `${tag.name.length * 6 + 100}px`, minWidth: '100px' }}
          InputProps={{
            startAdornment: (
              <div style={{ marginRight: '6px' }}>
                <ColorPicker
                  value={tag.color}
                  onChange={(newColor) => {
                    setTag({ ...tag, color: newColor });
                    handleColorEdit(newColor);
                  }}
                />
              </div>
            ),
            endAdornment: loading ? (
              <CircularProgress size={14} />
            ) : tag.id ? (
              <ButtonBase
                onClick={() => {
                  setDialogOpen(true);
                }}
              >
                <MoreHorizontal size={14} />
              </ButtonBase>
            ) : (
              <ButtonBase
                onClick={() => {
                  onCancel();
                }}
              >
                <XCircle size={14} />
              </ButtonBase>
            )
          }}
        />
      </form>
      <EditTagDialog
        tag={tag}
        open={dialogOpen}
        onDelete={handleDelete}
        onUpdate={handleUpdate}
        onCancel={() => {
          setDialogOpen(false);
        }}
      />
    </div>
  );
};
