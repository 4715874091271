const URL_REGEXP = /https?:\/\/(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const isValidUrl = (url: string) => URL_REGEXP.test(url);
export const removeTrailingSlash = (t: string) => t.replace(/\/$/, '');
export const withoutTrailingSlashAtAnyPosition = (t: string) =>
  t.replace(/\/([?#$])/, '$1');

export const asHttps = (url: string) => {
  const u = url.replace('http://', 'https://');
  return u.indexOf('https://') === 0 ? u : `https://${u}`;
};

export const onlyHostname = (url: string) => {
  if (!url) {
    return url;
  }
  try {
    const u = new URL(url);
    return u.hostname;
  } catch {
    return url;
  }
};

export const getPathname = (url: string) => {
  try {
    const parsed = new URL(url);
    return `${parsed.pathname}${parsed.search}`;
  } catch {
    return url;
  }
};

// eslint-disable-next-line
const DOMAIN_REGEXP = /(^https?\:\/\/[^\/?#]+)(?:[\/?#]|$)/i;

export const toDomain = (url: string) => {
  // try {

  //   const parsed = new URL(url);
  //   return parsed.origin;
  // } catch {
  //   return url;
  // }

  // Regexp for MUCH faster handling
  // If we find no match, just give up.
  const m = url.match(DOMAIN_REGEXP);
  return m ? m[1] : url;
};

export const isSameDomain = (a: string, b: string) => {
  return toDomain(a) === toDomain(b);
};

export const pathnameWithoutQueryParams = (pathname: string) => {
  return pathname.match(/(.*)\?/)?.[1] || pathname;
};

export const withoutProtocol = (url: string) => {
  return url.replace(/^https?:\/\//, '');
};

export const withoutProtocolOrWww = (url: string) => {
  const noProtocol = withoutProtocol(url);
  const www = 'www.';
  if (noProtocol.startsWith('www.')) {
    return noProtocol.substr(www.length, noProtocol.length - 1);
  }
  return noProtocol;
};

export const isSameDomainWithoutWww = (a: string, b: string) => {
  // Normalize the domains in case we get hostname only
  const aUrl = a.startsWith('https://') ? a : `https://${a}`;
  const bUrl = b.startsWith('https://') ? b : `https://${b}`;

  // If neither URL starts with www, simply compare origins
  if (!aUrl.startsWith('https://www.') && !bUrl.startsWith('https://www.')) {
    return isSameDomain(a, b);
  }

  const { hostname: aHostname } = new URL(aUrl);
  const { hostname: bHostname } = new URL(bUrl);

  const sameDomain =
    aHostname === bHostname ||
    `www.${aHostname}` === bHostname ||
    aHostname === `www.${bHostname}`;

  return sameDomain;
};
