import { some, uniqBy } from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useMemo, useRef } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Timeframe } from '../../domainTypes/analytics';
import { generateToDocFn } from '../../domainTypes/document';
import { JobStatus, Job } from '../../domainTypes/job';
import { FS } from '../../versions';
import { useCurrentUser } from '../currentUser';
import { combineLoadingValues, store, useMappedLoadingValue } from '../db';
import { areOverlappingRanges, fromMoment, toMoment } from '../time';

type JobListener = {
  timeframe: Timeframe;
  onChange: () => void;
};

export const toJobDoc = generateToDocFn<Job>();
export const jobCollection = () => store().collection(FS.jobs);
export const getJobsQuery = (spaceId: string) =>
  jobCollection().where('spaceId', '==', spaceId);

export const useJobsForTable = (spaceId: string, type: Job['type']) => {
  const sevenDaysAgo = useMemo(
    () => fromMoment(moment().subtract(7, 'days')),
    []
  );

  return useMappedLoadingValue(
    combineLoadingValues(
      useCollection(
        jobCollection()
          .where('spaceId', '==', spaceId)
          .where('type', '==', type)
          .where('status', 'in', [JobStatus.queued, JobStatus.running])
      ),
      useCollection(
        jobCollection()
          .where('spaceId', '==', spaceId)
          .where('type', '==', type)
          .where('lastUpdated', '>=', sevenDaysAgo)
      )
    ),
    ([j1, j2]) => {
      const j1Docs = j1.docs.map((d) => toJobDoc(d));
      const j2Docs = j2.docs.map((d) => toJobDoc(d));
      return uniqBy([...j1Docs, ...j2Docs], (j) => j.id);
    }
  );
};

export const useJobs = (spaceId: string) => {
  return useMappedLoadingValue(
    useCollection(jobCollection().where('spaceId', '==', spaceId)),
    (s) => s.docs.map((d) => toJobDoc(d))
  );
};

const useJobsCollection = (spaceId: string) => {
  return useMappedLoadingValue(
    useCollection(
      jobCollection()
        .where('spaceId', '==', spaceId)
        .where('status', 'in', [JobStatus.queued, JobStatus.running])
    ),
    (s) => s.docs.map((d) => toJobDoc(d))
  );
};

export const useJobsListener = () => {
  const currentUser = useCurrentUser();
  const listeners = useRef<JobListener[]>([]);
  const [jobs] = useJobsCollection(currentUser.space.id);

  const initialCall = useRef(true);

  useEffect(() => {
    if (!jobs) {
      return;
    }
    // the initial call should never trigger this,
    // only when it changes we'd want to get notified;
    if (initialCall.current) {
      initialCall.current = false;
      return;
    }
    listeners.current.forEach((l) => {
      const tfRange = {
        start: moment(l.timeframe.start, 'YYYY-MM-DD'),
        end: moment(l.timeframe.end, 'YYYY-MM-DD')
      };
      const relevantChanges = some(
        jobs.map((j) => {
          const jobRange = {
            start: toMoment(j.data.range.start),
            end: toMoment(j.data.range.end)
          };
          return areOverlappingRanges(tfRange, jobRange);
        })
      );

      if (relevantChanges) {
        l.onChange();
      }
    });
  }, [jobs]);

  return useMemo(() => {
    return {
      listenForJobChanges: (listener: JobListener) => {
        listeners.current.push(listener);
        return () => {
          const i = listeners.current.indexOf(listener);
          if (i !== -1) {
            listeners.current.splice(i, 1);
          }
        };
      }
    };
  }, []);
};
