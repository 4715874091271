import { Paper, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Ctr } from '../../../../components/Ctr';
import {
  ItemSorter,
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS
} from '../../../../components/GroupableList';
import { Loader } from '../../../../components/Loader';
import { BaseCountCell, CountCell } from '../../../../components/Table';
import { IColumn } from '../../../../components/Table/Column';
import {
  toAbsoluteGrowth,
  toSingleSort,
  ValueGetter
} from '../../../../components/Table/ValueGetter';
import {
  getTrend,
  IPageCounter,
  mapCounter,
  Mode,
  Timeframe
} from '../../../../domainTypes/analytics';
import { css } from '../../../../emotion';
import { SortDirection } from '../../../../hooks/useSort';
import {
  DEFAULT_OFFSET,
  DEFAULT_TOOLBAR_HEIGHT
} from '../../../../layout/PageToolbar';
import {
  getCpm,
  getViewRatio,
  useCountsInTimeframePerPageForProductFs,
  useCountsInTimeframePerPageForProductPg
} from '../../../../services/analytics';
import { useCurrentUser } from '../../../../services/currentUser';
import { getDomainName, getPathName } from '../../../../services/pages';
import { usePostgres } from '../../../../services/sales/service';

type ColumnName =
  | 'url'
  | 'pageViews'
  | 'served'
  | 'viewed'
  | 'viewRatio'
  | 'clicked'
  | 'clickRatio';

type Data = IPageCounter;
type OtherProps = { compare: boolean; mode: Mode };

type Column = IColumn<Data, ColumnName, OtherProps>;

export const COLUMNS: Column[] = [
  // count occurrence here too
  {
    key: 'url',
    head: () => 'Page URL',
    cell: (d) => (
      <>
        <Typography
          className={css((t) => ({
            fontSize: '14px',
            color: t.palette.primary.main
          }))}
        >
          {getPathName(d.href)}
        </Typography>
        <Typography
          className={css((t) => ({
            fontSize: '11px',
            fontWeight: 700,
            color: '#acacb9'
          }))}
        >
          {getDomainName(d.href)}
        </Typography>
      </>
    ),
    sortable: true,
    defaultDirection: 'asc',
    align: 'left',
    width: 140,
    flexGrow: 8
  },
  {
    key: 'served',
    head: () => 'Served', // needs tooltip
    headInfo: () =>
      `How many affiliate links were served on this page to your users`,
    cell: (d, o) => (
      <CountCell
        variant={o.mode}
        before={d.total.served.lastCount}
        after={d.total.served.count}
        compare={o.compare}
      />
    ),
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 100,
    flexGrow: 4
  },
  {
    key: 'viewed',
    head: () => 'Viewed', // needs tooltip
    headInfo: () => `
      How many affiliate links were actually seen by visitors
      as a result of scrolling down your page
    `,
    cell: (d, o) => (
      <CountCell
        variant={o.mode}
        before={d.total.viewed.lastCount}
        after={d.total.viewed.count}
        compare={o.compare}
      />
    ),
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 100,
    flexGrow: 4
  },
  {
    key: 'viewRatio',
    head: () => <span>%&nbsp;Seen</span>, // needs tooltip
    headInfo: () => `
      Percentage of served affiliate links that were actually seen by visitors
      as a result of scrolling down your page
    `,
    cell: (d, o) => (
      <CountCell
        variant={o.mode}
        before={getViewRatio(mapCounter(d.total, (v) => v.lastCount))}
        after={getViewRatio(mapCounter(d.total, (v) => v.count))}
        compare={o.compare}
        format="percent"
      />
    ),
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 100,
    flexGrow: 4
  },
  {
    key: 'clicked',
    head: () => 'Clicks', // needs tooltip
    headInfo: () => `
      How many times this affiliate link has been clicked on this page
      during the given timeframe
    `,
    cell: (d, o) => (
      <CountCell
        variant={o.mode}
        before={d.total.clicked.lastCount}
        after={d.total.clicked.count}
        compare={o.compare}
      />
    ),
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 100,
    flexGrow: 4
  },
  {
    key: 'clickRatio',
    head: () => 'Page CTR', // needs tooltip
    headInfo: () =>
      `Page-level click-through rate. The percentage of clicks through to this link per 100 pageviews`,

    cell: (d, o) => {
      const before = getCpm(mapCounter(d.total, (v) => v.lastCount));
      const after = getCpm(mapCounter(d.total, (v) => v.count));
      if (o.mode === 'absolute-numbers') {
        return (
          <BaseCountCell
            before={before}
            after={after}
            compare={o.compare}
            format="percent"
            digits={1}
          >
            <Ctr
              steps={[0, 0.05, 0.15, 0.2, 0.3, 0.4, 0.5]}
              rate={getCpm(mapCounter(d.total, (v) => v.count))}
              format={(item) =>
                `${(Math.round(item * 10 * 100) / 10).toString()}%`
              }
            />
          </BaseCountCell>
        );
      }
      return (
        <CountCell
          variant={o.mode}
          before={before}
          after={after}
          compare={o.compare}
          format="percent"
        />
      );
    },
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 150,
    flexGrow: 4
  }
];

const VALUE_GETTERS: {
  [K in ColumnName]: ValueGetter<Data>;
} = {
  url: {
    current: (d) => d.href,
    prev: (d) => d.href,
    absoluteGrowth: (d) => d.href,
    relativeGrowth: (d) => d.href
  },
  pageViews: {
    current: (d) => d.total.pageViews.count,
    prev: (d) => d.total.pageViews.lastCount,
    absoluteGrowth: (d) => toAbsoluteGrowth(d.total.pageViews),
    relativeGrowth: (d) => d.total.pageViews.trend
  },
  served: {
    current: (p) => p.total.served.count,
    prev: (p) => p.total.served.lastCount,
    absoluteGrowth: (p) => toAbsoluteGrowth(p.total.served),
    relativeGrowth: (p) => p.total.served.trend
  },
  viewed: {
    current: (p) => p.total.viewed.count,
    prev: (p) => p.total.viewed.lastCount,
    absoluteGrowth: (p) => toAbsoluteGrowth(p.total.viewed),
    relativeGrowth: (p) => p.total.viewed.trend
  },
  viewRatio: {
    current: (p) => getViewRatio(mapCounter(p.total, (v) => v.count)),
    prev: (p) => getViewRatio(mapCounter(p.total, (v) => v.lastCount)),
    absoluteGrowth: (p) => {
      const current = getViewRatio(mapCounter(p.total, (v) => v.count));
      const prev = getViewRatio(mapCounter(p.total, (v) => v.lastCount));
      return current - prev;
    },
    relativeGrowth: (p) => {
      const current = getViewRatio(mapCounter(p.total, (v) => v.count));
      const prev = getViewRatio(mapCounter(p.total, (v) => v.lastCount));
      return getTrend(prev, current);
    }
  },
  clicked: {
    current: (p) => p.total.clicked.count,
    prev: (p) => p.total.clicked.lastCount,
    absoluteGrowth: (p) => toAbsoluteGrowth(p.total.clicked),
    relativeGrowth: (p) => p.total.clicked.trend
  },
  clickRatio: {
    current: (p) => getCpm(mapCounter(p.total, (v) => v.count)),
    prev: (p) => getCpm(mapCounter(p.total, (v) => v.lastCount)),
    absoluteGrowth: (p) => {
      const current = getCpm(mapCounter(p.total, (v) => v.count));
      const prev = getCpm(mapCounter(p.total, (v) => v.lastCount));
      return current - prev;
    },
    relativeGrowth: (p) => {
      const current = getCpm(mapCounter(p.total, (v) => v.count));
      const prev = getCpm(mapCounter(p.total, (v) => v.lastCount));
      return getTrend(prev, current);
    }
  }
};

const createSorters = (mode: Mode): ItemSorters<Data> => ({
  url: {
    key: 'url',
    items: { sort: toSingleSort(VALUE_GETTERS['url'], mode), dir: 'asc' }
  },
  pageViews: {
    key: 'pageViews',
    items: {
      sort: toSingleSort(VALUE_GETTERS['pageViews'], mode),
      dir: 'desc'
    }
  },
  served: {
    key: 'served',
    items: {
      sort: toSingleSort(VALUE_GETTERS['served'], mode),
      dir: 'desc'
    }
  },
  viewed: {
    key: 'viewed',
    items: {
      sort: toSingleSort(VALUE_GETTERS['viewed'], mode),
      dir: 'desc'
    }
  },
  viewRatio: {
    key: 'viewRatio',
    items: {
      sort: toSingleSort(VALUE_GETTERS['viewRatio'], mode),
      dir: 'desc'
    }
  },
  clicked: {
    key: 'clicked',
    items: {
      sort: toSingleSort(VALUE_GETTERS['clicked'], mode),
      dir: 'desc'
    }
  },
  clickRatio: {
    key: 'clickRatio',
    items: {
      sort: toSingleSort(VALUE_GETTERS['clickRatio'], mode),
      dir: 'desc'
    }
  }
});

export const SORTERS: {
  [K in Mode]: ItemSorters<Data>;
} = {
  'absolute-numbers': createSorters('absolute-numbers'),
  'absolute-growth': createSorters('absolute-growth'),
  'relative-growth': createSorters('relative-growth')
};

export const DEFAULT_COLUMNS: ColumnName[] = [
  'url',
  'viewRatio',
  'clicked',
  'clickRatio'
];

const ROW_TO_KEY = (d: Data) => d.href;

const PagesTableFs = ({
  productId,
  timeframe,
  compare,
  columns: columnNames,
  sorter,
  sortDirection,
  onSort,
  rowToHref,
  mode
}: {
  productId: string;
  timeframe: Timeframe;
  compare: boolean;
  columns: Set<ColumnName>;
  sorter: ItemSorter<Data>;
  sortDirection: SortDirection | undefined;
  onSort: (c: ColumnName, dir: SortDirection | undefined) => void;
  rowToHref: (d: Data) => string;
  mode: Mode;
}) => {
  const [pages, loading] = useCountsInTimeframePerPageForProductFs(
    productId,
    timeframe,
    compare
  );

  const columns = useMemo(
    () =>
      columnNames ? COLUMNS.filter((c) => columnNames.has(c.key)) : COLUMNS,
    [columnNames]
  );

  const otherProps = useMemo(
    () => ({
      compare,
      mode
    }),
    [compare, mode]
  );

  if (!pages || loading) {
    return (
      <Paper>
        <Loader height={400} />
      </Paper>
    );
  }

  const rows = pages;

  return (
    <RowsRenderer
      variant="contained"
      columns={columns}
      rows={rows}
      rowToKey={ROW_TO_KEY}
      sorter={sorter}
      sortDirection={sortDirection}
      renderHead={true}
      headProps={{
        sticky: true,
        offset: DEFAULT_OFFSET + DEFAULT_TOOLBAR_HEIGHT + 8
      }}
      onHeadClick={(c, dir) => onSort(c.key, dir)}
      chunkSize={30}
      rootMargin="400px"
      rowHeight={ROW_HEIGHTS.airy}
      rowToHref={rowToHref}
      otherProps={otherProps}
    />
  );
};

const PagesTablePg = ({
  productId,
  timeframe,
  compare,
  columns: columnNames,
  sorter,
  sortDirection,
  onSort,
  rowToHref,
  mode
}: {
  productId: string;
  timeframe: Timeframe;
  compare: boolean;
  columns: Set<ColumnName>;
  sorter: ItemSorter<Data>;
  sortDirection: SortDirection | undefined;
  onSort: (c: ColumnName, dir: SortDirection | undefined) => void;
  rowToHref: (d: Data) => string;
  mode: Mode;
}) => {
  const { space } = useCurrentUser();
  const [pages, loading] = useCountsInTimeframePerPageForProductPg(
    space.id,
    productId,
    timeframe,
    compare
  );

  const columns = useMemo(
    () =>
      columnNames ? COLUMNS.filter((c) => columnNames.has(c.key)) : COLUMNS,
    [columnNames]
  );

  const otherProps = useMemo(
    () => ({
      compare,
      mode
    }),
    [compare, mode]
  );

  if (!pages || loading) {
    return (
      <Paper>
        <Loader height={400} />
      </Paper>
    );
  }

  const rows = pages;

  return (
    <RowsRenderer
      variant="contained"
      columns={columns}
      rows={rows}
      rowToKey={ROW_TO_KEY}
      sorter={sorter}
      sortDirection={sortDirection}
      renderHead={true}
      headProps={{
        sticky: true,
        offset: DEFAULT_OFFSET + DEFAULT_TOOLBAR_HEIGHT + 8
      }}
      onHeadClick={(c, dir) => onSort(c.key, dir)}
      chunkSize={30}
      rootMargin="400px"
      rowHeight={ROW_HEIGHTS.airy}
      rowToHref={rowToHref}
      otherProps={otherProps}
    />
  );
};

export const PagesTable = (props: {
  productId: string;
  timeframe: Timeframe;
  compare: boolean;
  columns: Set<ColumnName>;
  sorter: ItemSorter<Data>;
  sortDirection: SortDirection | undefined;
  onSort: (c: ColumnName, dir: SortDirection | undefined) => void;
  rowToHref: (d: Data) => string;
  mode: Mode;
}) => {
  const pg = usePostgres();
  return pg ? <PagesTablePg {...props} /> : <PagesTableFs {...props} />;
};
