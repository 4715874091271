export const LineChartIcon = ({ size }: { size: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <g id="XMLID_2_">
        <polygon
          id="XMLID_4_"
          points="38.2,422.6 191.8,268.1 294.2,370.5 512,125.7 475.7,89.4 294.2,294.2 191.8,191.8    0,383.5  "
        />
      </g>
    </svg>
  );
};
