import { styled } from '../emotion';

export const TopNavBarSection = styled<
  'div',
  { minWidth?: number; maxWidth?: number }
>('div')`
  padding: ${(p) => `${p.theme.spacing(1)}px ${p.theme.spacing(2)}px`};
  ${(p) => (p.minWidth ? `min-width: ${p.minWidth}px;` : '')}
  ${(p) => (p.maxWidth ? `max-width: ${p.maxWidth}px;` : '')}
`;
