import { IconButton, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { styled } from '../emotion';
import { Spacer } from './Spacer';

export const DEFAULT_OFFSET = 56;
export const DEFAULT_TOOLBAR_HEIGHT = 72;
export const DEFAULT_TOOLBAR_HEIGHT_LARGE = 106;

// use padding instead of margin to make sure the background-color is applied
// to the spacing as well. important when in sticky mode.
// TODO would it also be fine to just default to wrap? I think so...
export const PageToolbar = styled<
  'div',
  { sticky?: boolean; offset?: number; wrap?: boolean }
>('div')`
  ${(p) => (p.offset ? `top: ${p.offset}px;` : '')}
  ${(p) => (p.sticky ? `position: sticky;` : '')}
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(p) => p.theme.custom.colors.background.main};
  padding: ${(p) => p.theme.spacing(3)}px 0 ${(p) => p.theme.spacing(3)}px;
  z-index: 2;
  flex-wrap: ${(p) => (p.wrap ? 'wrap' : 'nowrap')};
`;

const PageToolbarPaginationWrapper = styled('div')`
  display: inline-flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing(1)}px;
`;

export const PageToolbarPagination = ({
  page,
  setPage,
  pageCount
}: {
  page: number;
  setPage: (page: number) => void;
  pageCount: number;
}) => {
  return (
    <PageToolbarPaginationWrapper>
      {page > 1 && (
        <IconButton onClick={() => setPage(page - 1)}>
          <ChevronLeft size={16} />
        </IconButton>
      )}
      <Typography
        component="span"
        variant="body2"
        color="textSecondary"
        style={{ whiteSpace: 'nowrap' }}
      >
        Page{' '}
        <strong>
          {page} {pageCount ? `of ${pageCount}` : ''}
        </strong>
      </Typography>
      {pageCount > page ? (
        <IconButton onClick={() => setPage(page + 1)} size="small">
          <ChevronRight size={16} />
        </IconButton>
      ) : (
        <span style={{ width: 40 }} />
      )}
    </PageToolbarPaginationWrapper>
  );
};

export const PageToolbarSection = styled<
  'div',
  {
    flex: number;
    justifyContent?: React.CSSProperties['justifyContent'];
    spacing?: 'dense' | 'wide';
  }
>('div')`
  flex: ${(p) => p.flex};
  display: flex;
  align-items: center;
  ${(p) => (p.justifyContent ? `justify-content: ${p.justifyContent};` : '')}

  gap: ${(p) => p.theme.spacing(p.spacing === 'wide' ? 2 : 0.5)}px;
`;

export const PageToolbarTitle = styled<'div', { flex: number }>('div')`
  flex: ${(p) => p.flex};
  font-size: ${(p) => p.theme.custom.fontSize.l}px;
  font-weight: ${(p) => p.theme.typography.fontWeightBold};

  display: flex;
  flex-direction: column;

  gap: ${(p) => p.theme.spacing(2)}px;
`;

export const PageToolbarOtherRow = Spacer;
