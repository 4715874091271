import React from 'react';
import { Helmet } from 'react-helmet';
import { NoPermissions } from '../../../../components/NoPermissions';
import { useHasCurrentUserRequiredScopes } from '../../../../services/currentUser';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { DashboardRealtime } from './PageDashboardRealtime';

export const PageDashboardRealtime = () => {
  useTrackMixpanelView('view_realtime_report');
  const [canView] = useHasCurrentUserRequiredScopes(['realtime.view']);

  return (
    <>
      <Helmet>
        <title>Realtime | Affilimate</title>
      </Helmet>
      {canView ? <DashboardRealtime /> : <NoPermissions />}
    </>
  );
};
