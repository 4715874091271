import { LoadingValue, useMappedLoadingValue } from '../db';
import { useSites } from '../../components/analytics_v2/SiteSelector';
import { useCurrentUser } from '../currentUser';
import { useChannels } from './index';
import { COLOR_UNKNOWN, getStableRandomColor } from '../color';
import {
  IChannelType,
  OTHERS_CHANNEL_ID,
  UNKNOWN_CHANNEL_ID
} from '../../domainTypes/channels';
import { capitalize } from 'lodash';

export const getChannel = (
  channelId: string | undefined,
  channels: Channel[]
): Channel => {
  if (channelId === OTHERS_CHANNEL_ID) {
    return {
      kind: 'internal',
      id: OTHERS_CHANNEL_ID
    };
  }
  const channel = channels.find((c) => c.id === channelId);
  if (channel) {
    return channel;
  }
  return {
    kind: 'internal',
    id: UNKNOWN_CHANNEL_ID
  };
};

export const channelColor = (channel: Channel) => {
  switch (channel.kind) {
    case 'internal':
      return COLOR_UNKNOWN;
    case 'site':
      return getStableRandomColor(channel.name);
    case 'offsite':
      return getStableRandomColor(channel.id);
  }
};

export const channelLabel = (channel: Channel) => {
  switch (channel.kind) {
    case 'internal':
      return capitalize(channel.id);
    case 'site':
      return channel.name;
    case 'offsite':
      return `${channel.name} (${channel.type})`;
  }
};

export const channelKindLabel = (kind: Channel['kind']) => {
  switch (kind) {
    case 'internal':
      return 'Internal';
    case 'site':
      return 'Sites';
    case 'offsite':
      return 'Offsite channels';
  }
};

export type Channel =
  | {
      kind: 'internal';
      id: string;
    }
  | {
      kind: 'site';
      id: string;
      name: string;
    }
  | {
      kind: 'offsite';
      id: string;
      name: string;
      type: IChannelType;
    };

export const useSpaceChannels = (): LoadingValue<Channel[]> => {
  const { values: sites } = useSites();
  const { space } = useCurrentUser();
  const siteChannels: Channel[] = sites.map((site) => ({
    kind: 'site',
    id: site,
    name: site
  }));
  return useMappedLoadingValue(useChannels(space.id), (channels) => {
    const offsiteChannels: Channel[] = channels.map((channel) => ({
      kind: 'offsite',
      id: channel.data.channelId,
      name: channel.data.name,
      type: channel.data.type
    }));
    return [...siteChannels, ...offsiteChannels];
  });
};

export const channelContainer = (
  channelId: string | undefined,
  channels: Channel[]
) => {
  const channel = getChannel(channelId, channels);
  return {
    key: channel.id,
    color: channelColor(channel),
    label: channelLabel(channel)
  };
};
