import React, { useCallback, useMemo } from 'react';
import {
  allIntervalsForTimeframe,
  bestIntervalForTimeframe,
  DAILY_INTERVAL,
  DEFAULT_TIMEFRAME_URL_PARAM_NAME,
  FIFTEEN_MINUTES_INTERVAL,
  HOURLY_INTERVAL,
  MONTHLY_INTERVAL,
  QUARTERLY_INTERVAL,
  useTimeframeDefinitionFromUrl,
  WEEKLY_INTERVAL,
  YEARLY_INTERVAL
} from '../hooks/timeframe';
import { useCurrentUser } from '../services/currentUser';
import { TimeframeIntervalDefinition } from '../domainTypes/timeframe';
import { getTimeframeMoments } from '../hooks/timeframe/toMoments';
import { isEqual, isNil } from 'lodash';
import moment from 'moment-timezone';
import { InputBase, MenuItem, Select } from '@material-ui/core';

export type IntervalSelectorOption = {
  value: TimeframeIntervalDefinition;
  label: string;
};

export const ALL_INTERVAL_OPTIONS: Array<IntervalSelectorOption> = [
  {
    value: FIFTEEN_MINUTES_INTERVAL,
    label: '15 Minutes'
  },
  {
    value: HOURLY_INTERVAL,
    label: 'Hour'
  },
  {
    value: DAILY_INTERVAL,
    label: 'Day'
  },
  {
    value: WEEKLY_INTERVAL,
    label: 'Week'
  },
  {
    value: MONTHLY_INTERVAL,
    label: 'Month'
  },
  {
    value: QUARTERLY_INTERVAL,
    label: 'Quarter'
  },
  {
    value: YEARLY_INTERVAL,
    label: 'Year'
  }
];

export const useIntervalSelectorState = (
  paramName: string = DEFAULT_TIMEFRAME_URL_PARAM_NAME
) => {
  const { tz } = useCurrentUser();
  const [timeframe, setTimeframe] = useTimeframeDefinitionFromUrl(paramName);
  const setInterval = useCallback(
    (interval: TimeframeIntervalDefinition) => {
      setTimeframe({
        ...timeframe,
        interval
      });
    },
    [setTimeframe, timeframe]
  );

  const moments = useMemo(() => {
    const { start, end } = getTimeframeMoments(timeframe.range, tz);
    return { start, end };
  }, [timeframe.range, tz]);

  const interval = useMemo(() => {
    if (timeframe.interval) {
      return timeframe.interval;
    }
    return bestIntervalForTimeframe(moments.start, moments.end);
  }, [moments.end, moments.start, timeframe.interval]);

  const options = useMemo(() => {
    const validOptions = allIntervalsForTimeframe(moments.start, moments.end);
    return ALL_INTERVAL_OPTIONS.filter((o) =>
      validOptions.some((validOption) => isEqual(validOption, o.value))
    );
  }, [moments.end, moments.start]);

  return {
    interval,
    setInterval,
    options
  };
};

const printDefinition = (definition: TimeframeIntervalDefinition) => {
  return moment.duration(definition.value, definition.unit).toISOString();
};

const findOption = (value: string, options: IntervalSelectorOption[]) => {
  return options.find((o) => printDefinition(o.value) === value);
};

export const IntervalSelector = ({
  value,
  onChange,
  options = ALL_INTERVAL_OPTIONS
}: {
  value: TimeframeIntervalDefinition;
  onChange: (value: TimeframeIntervalDefinition) => void;
  options?: Array<IntervalSelectorOption>;
}) => {
  const selectedDuration = useMemo(() => printDefinition(value), [value]);
  const selectDuration = useCallback(
    (e) => {
      const option = findOption(e.target.value as string, options);
      if (!isNil(option)) {
        onChange(option.value);
      }
    },
    [onChange, options]
  );

  return (
    <Select
      value={selectedDuration}
      onChange={selectDuration}
      disableUnderline
      input={<InputBase style={{ fontSize: '0.85rem' }} />}
    >
      {options.map(({ value, label }) => {
        const duration = printDefinition(value);
        return (
          <MenuItem key={duration} value={duration}>
            {label}
          </MenuItem>
        );
      })}
    </Select>
  );
};
