import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import { assertNever } from 'assert-never';
import { chunk, compact, uniqBy } from 'lodash';
import { flatten } from 'lodash/fp';
import React, { useMemo, useState } from 'react';
import { X } from 'react-feather';
import { AlertBox } from '../../../../components/AlertBox';
import { Chip } from '../../../../components/Chip';
import { Dropzone } from '../../../../components/Dropzone';
import { HelpIcon } from '../../../../components/HelpIcon';
import { Loader } from '../../../../components/Loader';
import {
  AttributionRule,
  AttributionRuleApplication
} from '../../../../domainTypes/performanceLabelRules';
import { isRule } from '../../../../domainTypes/rules';
import { styled } from '../../../../emotion';
import { useBackgroundJobs } from '../../../../hooks/useBackgroundJobs';
import { Centered } from '../../../../layout/Centered';
import { useFlushAnalyticsV2Cache } from '../../../../services/analyticsV2/cache';
import { ARTICLES } from '../../../../services/beacon';
import { useColors } from '../../../../services/color';
import { readCsvRows } from '../../../../services/file';
import { callFirebaseFunction } from '../../../../services/firebaseFunctions';
import { flushSalesCacheForSpace } from '../../../../services/sales/cache';
import { now, secondsToHumanTime } from '../../../../services/time';
import { removeTrailingSlash, toDomain } from '../../../../services/url';
import { RuleRenderer } from '../../components/RuleCreateDialog/RuleBuilder';
import {
  config,
  createAttributionRules
} from '../../components/RuleCreateDialog/service';

const BATCH_IMPORT_LIMIT = 1000;

type ImportedRule = {
  trackingId: string;
  url: string;
};

const toRule = (row: ImportedRule, spaceId: string) => {
  let url: URL;

  if (row.url === 'Unknown Page' || !row.url) {
    return null;
  }

  if (!row.url.startsWith('https://')) {
    return null;
  }

  try {
    url = new URL(row.url);
  } catch (err) {
    return null;
  }

  const trackingId = row.trackingId.trim();
  const ruleType = url.pathname === '/' ? 'SITE' : 'PAGE';
  const ruleUrl = removeTrailingSlash(
    ruleType === 'SITE' ? toDomain(row.url) : row.url
  );
  const newRule: AttributionRule = {
    spaceId,
    createdAt: now(),
    match: {
      type: 'all',
      els: [
        {
          k: 'trackingLabel',
          op: '==',
          v: trackingId
        }
      ]
    },
    apply: [
      {
        type: ruleType,
        value: ruleUrl
      }
    ]
  };
  return newRule;
};

const RuleChip = ({ type }: { type: AttributionRuleApplication['type'] }) => {
  const width = 50;
  const COLORS = useColors();

  if (type === 'PRODUCT') {
    return null;
  }
  if (type === 'PAGE') {
    return (
      <Chip
        size="small"
        label="Page"
        style={{
          color: COLORS.blue.blue6,
          backgroundColor: COLORS.blue.blue1,
          width
        }}
      />
    );
  }
  if (type === 'SITE') {
    return (
      <Chip
        size="small"
        label="Site"
        style={{
          color: COLORS.purple.purple6,
          backgroundColor: COLORS.purple.purple1,
          width
        }}
      />
    );
  }

  return assertNever(type);
};

const ResultsContainer = styled('div')`
  height: 300px;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-row-gap: ${(p) => p.theme.spacing(1)}px;
  padding: ${(p) => p.theme.spacing(1)}px;
  margin: ${(p) => p.theme.spacing(1)}px auto;
  border: 1px solid ${(p) => p.theme.palette.grey[300]};
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
`;

const RuleUrl = styled('div')``;

const BatchRuleResults = ({ rules }: { rules: AttributionRule[] }) => {
  return (
    <ResultsContainer>
      {rules.map((rule, i) => {
        const applications = rule.apply;
        const applyPage = applications.find((a) => a.type === 'PAGE');
        const applySite = applications.find((a) => a.type === 'SITE');

        return (
          <React.Fragment key={i}>
            <div>
              <RuleRenderer
                variant="oneline"
                value={rule.match}
                config={config}
              />
            </div>
            <RuleUrl>
              {applyPage && <RuleChip type="PAGE" />}
              {applySite && <RuleChip type="SITE" />}
              &nbsp;
              <span>
                {applyPage && applyPage.value}
                {applySite && applySite.value}
              </span>
            </RuleUrl>
          </React.Fragment>
        );
      })}
    </ResultsContainer>
  );
};

const Table = styled('table')`
  border: 1px solid ${(p) => p.theme.palette.grey[200]};
  margin: ${(p) => p.theme.spacing(4)}px 0;
  width: 100%;

  th,
  td {
    padding: ${(p) => p.theme.spacing(0.5)}px;
    border: 1px solid ${(p) => p.theme.palette.grey[400]};
  }
`;

export const BatchImportDialog = ({
  spaceId,
  open,
  onClose,
  onRuleCreated
}: {
  spaceId: string;
  open: boolean;
  onClose: () => void;
  onRuleCreated?: () => void;
}) => {
  const { addJob } = useBackgroundJobs();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<AttributionRule[]>([]);
  const [applying, setApplying] = useState(false);
  const flushCache = useFlushAnalyticsV2Cache();
  const [noValidRules, setNoValidRules] = useState(false);

  const handleFiles = async (files: File[] | null) => {
    if (!files) {
      console.log('No files uploaded');
      return;
    }

    setLoading(true);

    const rules = await Promise.all(
      files.map(async (file) => {
        const rows = await readCsvRows(file, {});
        return rows
          .slice(1, rows.length)
          .filter((row) => {
            // Filter any empty rows from the CSV
            return row[0] && row[1];
          })
          .map((row) => {
            const [trackingId, url] = row;
            try {
              return toRule({ trackingId, url }, spaceId);
            } catch (err) {
              return null;
            }
          });
      })
    );

    const flat = compact(flatten(rules));
    const uniq = uniqBy(flat, (rule) => {
      const els = rule.match?.els;
      if (!els) {
        return null;
      }
      const firstEl = els[0];
      if (!isRule(firstEl)) {
        return firstEl.v;
      }
      return null;
    });
    const results = compact(uniq);
    setResults(results);
    setNoValidRules(results.length === 0);
    setLoading(false);
  };

  const insecureRules = useMemo(() => {
    if (results.length) {
      return results.filter((r) => {
        const insecureList = r.apply.filter(
          (v) => v.value.indexOf('http://') !== -1
        );
        return insecureList.length > 0;
      });
    }
    return [];
  }, [results]);

  const rulesWithoutProtocol = useMemo(() => {
    if (results.length) {
      return results.filter((r) => {
        const noProtocol = r.apply.filter(
          (v) => v.value.indexOf('http') === -1
        );
        return noProtocol.length > 0;
      });
    }
    return [];
  }, [results]);

  const onSubmit = async (rules: AttributionRule[]) => {
    setApplying(true);
    const newRuleDocs = await createAttributionRules(rules);
    const ruleCount = newRuleDocs.length;

    addJob({
      job: async () => {
        const batch = chunk(newRuleDocs, 5);
        for (const rules of batch) {
          console.log(
            `updating ${rules.length} rules:`,
            rules.map((r) => r.id)
          );
          await callFirebaseFunction(
            'labelRules-matchAndApplyAllRulesInSpaceWhichMatchTrackingLabels',
            {
              spaceId,
              rules
            }
          );
        }
      },
      onStart: () => {
        console.time('saving rules');
        onClose();
        return {
          message: (
            <>
              <CircularProgress color="inherit" size={16} /> &nbsp; Applying{' '}
              {ruleCount} rules. Est time: {secondsToHumanTime(ruleCount * 5)}.
              Do not close this tab...
            </>
          )
        };
      },
      onSuccess: () => {
        console.timeEnd('saving rules');
        flushSalesCacheForSpace(spaceId);
        flushCache(spaceId);
        if (onRuleCreated) {
          onRuleCreated();
        }
        return {
          message: 'Rules created and applied successfully'
        };
      },
      onError: (err) => {
        console.timeEnd('saving rules');
        if (err === 'FAILED_APPLY_RULES') {
          return {
            message:
              "Label rules were created, but couldn't be applied to your transactions. Please contact Support."
          };
        }
        return {
          message:
            'Your rules could not be created. Please try again or contact Support.'
        };
      }
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" scroll="body">
      <DialogTitle
        disableTypography
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography
          variant="h6"
          style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
        >
          <div>Batch Import Rules</div>
          <HelpIcon color="blue" articleId={ARTICLES.labelRules.fileImport}>
            How to use
          </HelpIcon>
        </Typography>
        <IconButton onClick={onClose}>
          <X size={24} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!results.length && !loading && (
          <>
            <Typography
              variant="body1"
              component="p"
              color="textSecondary"
              paragraph
            >
              To batch import a set of rules, please upload a CSV file
              containing your rules according to the format below. Malformed and
              insecure URLs must be fixed before importing.
            </Typography>
            <Table>
              <thead>
                <tr>
                  <th>SubID / Tracking Label</th>
                  <th>
                    Full Page or Site URL (starting with https:// and
                    including/excluding www as needed)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>yoursite-20</td>
                  <td>https://yoursite.com</td>
                </tr>
                <tr>
                  <td>ys-specific-page-20</td>
                  <td>https://yoursite.com/specific-page</td>
                </tr>
              </tbody>
            </Table>
            <AlertBox variant="pending">
              <strong>Important:</strong> Rules imported in batch{' '}
              <u>will not be deduplicated</u> against previously created rules.
              Only import new rules each time. The maximum number of rows you
              can upload from a single file is 1000. If you need to import more,
              please split your file into multiple files or use the API to
              automate rule creation on first publish. Carefully review the
              ruleset generated from your CSV file before applying it.
            </AlertBox>
            <br />
            {noValidRules && (
              <AlertBox variant="error">
                <strong>Error:</strong> No valid rules found in your file.
                Please check that your columns are in the correct order, and all
                URLs begin with https://.
              </AlertBox>
            )}
            <Dropzone
              onDrop={handleFiles}
              note="The CSV file must contain headers and the two columns listed above"
            />
          </>
        )}
        {loading && (
          <Centered height={200}>
            <Loader size={32} />
          </Centered>
        )}
        {results.length > BATCH_IMPORT_LIMIT && (
          <Typography variant="body1" color="error">
            You can import up to {BATCH_IMPORT_LIMIT} rules at a time. To import
            more, please split your file into multiple files or use the API.
          </Typography>
        )}
        {results.length > 0 && results.length <= BATCH_IMPORT_LIMIT && (
          <>
            <Typography
              variant="body1"
              component="p"
              color="textSecondary"
              paragraph
            >
              Review the ruleset generated from your CSV file below.
            </Typography>
            <BatchRuleResults rules={results} />
            {insecureRules && insecureRules.length > 0 && (
              <AlertBox variant="error">
                <strong>Warning:</strong> Your ruleset contains URLs with
                insecure protocols. You are highly recommended to only create
                rules to HTTPS pages.{' '}
                {insecureRules
                  .slice(0, 5)
                  .map((r) => {
                    return r.apply.find(
                      (u) => u.value.indexOf('http://') !== -1
                    );
                  })
                  .map((r) => r?.value)
                  .join(', ')}
                {insecureRules.length > 5 &&
                  `... (${insecureRules.length - 5} more)`}
              </AlertBox>
            )}
            {rulesWithoutProtocol && rulesWithoutProtocol.length > 0 && (
              <AlertBox variant="error">
                <strong>Error:</strong> Your ruleset contains URLs without a
                protocol. Rules for these pages will be skipped.{' '}
                {rulesWithoutProtocol
                  .slice(0, 5)
                  .map((r) => {
                    return r.apply.find((u) => !u.value.startsWith('http'));
                  })
                  .map((r) => r?.value)
                  .join(', ')}
                {rulesWithoutProtocol.length > 5 &&
                  `... (${rulesWithoutProtocol.length - 5} more)`}
              </AlertBox>
            )}
            <DialogActions style={{ paddingRight: 0 }}>
              <Button
                onClick={() => {
                  setResults([]);
                }}
              >
                Re-upload file
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  applying ||
                  !results.length ||
                  !!insecureRules.length ||
                  !!rulesWithoutProtocol.length
                }
                onClick={() => onSubmit(results)}
              >
                {applying
                  ? 'Creating rules...'
                  : `Create and apply ${results.length.toLocaleString(
                      'en-US'
                    )} rules`}
              </Button>
            </DialogActions>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
