import { Typography } from '@material-ui/core';
import { compact, isEqual } from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useMemo, useState } from 'react';
import {
  AnalyticsFilter,
  ISOTimeRange
} from '../../../../domainTypes/analytics_v2';
import { Doc } from '../../../../domainTypes/document';
import { IntegrationIdFilterDefinition } from '../../../../domainTypes/filters';
import { SECRET_CATEGORY } from '../../../../domainTypes/reporting';
import { ISecretWithTs } from '../../../../domainTypes/secret';
import { useApiReportHandlers } from '../../../../features/PerformanceNew/services/handlers';
import { IApiReportHandler } from '../../../../features/PerformanceNew/services/handlers/types';
import { FlexContainer } from '../../../../layout/Flex';
import { useCurrentUser } from '../../../../services/currentUser';
import { LoadingValue, useMappedLoadingValue } from '../../../../services/db';
import { getKnownPartnerForKey } from '../../../../services/partner';
import { useSecrets } from '../../../../services/secret';
import { InternalId } from '../../../ConnectionId';
import { PlatformWithColor } from '../../../PlatformWithColor';
import {
  createIntegrationIdFilterDefinition,
  initFilterValue,
  validateFilterDefinition
} from '../filters';
import { DimensionMenuComponent, DimensionMenuProps } from './Dimension';
import { BASIC_MODES, FilterMenu } from './FilterMenu';
import {
  OptionsList,
  SelectorLoader,
  SelectorShell,
  useCollectionState
} from './Selector';

type Integration = {
  secret: Doc<ISecretWithTs>;
  handler: IApiReportHandler;
};

interface IntegrationIdMenuBodyProps {
  integrations: Integration[];
}

// Maybe show items hierarchical - one for each PK, then whatever's inside

const IntegrationIdMenuBody: React.FC<
  IntegrationIdMenuBodyProps &
    Pick<
      DimensionMenuProps<IntegrationIdFilterDefinition>,
      'definition' | 'onSave'
    >
> = ({ integrations, definition, onSave }) => {
  const [search, setSearch] = useState('');
  const [value, toggleValue] = useCollectionState(definition.v);
  const newDefinition = useMemo<IntegrationIdFilterDefinition>(
    () => createIntegrationIdFilterDefinition(value),
    [value]
  );

  const enableSave =
    validateFilterDefinition(newDefinition) &&
    !isEqual(definition, newDefinition);

  const options = useMemo(() => {
    return compact(
      integrations
        .sort((a, b) =>
          a.handler.partnerKey.localeCompare(b.handler.partnerKey)
        )
        .filter((integration) => {
          const { secret, handler } = integration;
          const partner = getKnownPartnerForKey(handler.partnerKey);

          if (!partner) {
            return false;
          }

          // This one doesn't have any commissions come through
          if (handler.configName === 'amazonV2') {
            return false;
          }

          return (
            search === '' ||
            handler.partnerKey.toLowerCase().includes(search.toLowerCase()) ||
            secret.data.nickname
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            secret.data.instanceId.toLowerCase().includes(search.toLowerCase())
          );
        })
        .map((integration) => {
          const { secret, handler } = integration;
          const partner = getKnownPartnerForKey(handler.partnerKey);
          if (!partner) {
            return null;
          }
          return {
            value: secret.data.instanceId,
            label: (
              <div style={{ padding: '8px 4px' }}>
                <FlexContainer alignItems="center" justifyContent="flex-start">
                  <PlatformWithColor partner={partner} />
                  <InternalId>{secret.data.instanceId}</InternalId>
                </FlexContainer>
                <Typography variant="caption" color="textSecondary">
                  {secret.data.nickname
                    ? secret.data.nickname
                    : handler.additionalName
                    ? handler.additionalName(partner, secret)
                    : null}
                </Typography>
              </div>
            )
          };
        })
    );
  }, [integrations, search]);

  const focusValue = useCallback(
    (integration: string) =>
      onSave(createIntegrationIdFilterDefinition([integration])),
    [onSave]
  );

  return (
    <>
      <FilterMenu.Body>
        <SelectorShell
          label="Integrations"
          search={search}
          setSearch={setSearch}
        >
          <OptionsList
            options={options}
            selectedValues={value}
            toggle={toggleValue}
            focus={focusValue}
          />
        </SelectorShell>
      </FilterMenu.Body>
      <FilterMenu.Footer definition={definition}>
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={`Filter by ${pluralize('integration', value.length, true)}`}
        />
      </FilterMenu.Footer>
    </>
  );
};

const IntegrationIdMenuLoading = () => (
  <>
    <FilterMenu.Body>
      <SelectorLoader />
    </FilterMenu.Body>
    <FilterMenu.Footer definition={initFilterValue('integration_id')}>
      <FilterMenu.SaveButton
        disabled={true}
        onSave={() => {}}
        label="Loading integrations..."
      />
    </FilterMenu.Footer>
  </>
);

const useIntegrations = (
  filters: Array<AnalyticsFilter>,
  range: ISOTimeRange
): LoadingValue<
  {
    secret: Doc<ISecretWithTs>;
    handler: IApiReportHandler;
  }[]
> => {
  const { space } = useCurrentUser();
  const handlers = useApiReportHandlers();
  return useMappedLoadingValue(
    useSecrets(space.id, SECRET_CATEGORY),
    (secrets) => {
      return compact(
        secrets.map((s) => {
          const handler = handlers.find((h) => h.configName === s.data.name);
          return handler
            ? {
                secret: s,
                handler
              }
            : null;
        })
      );
    }
  );
};

const InnerIntegrationIdMenu: React.FC<{
  definition: IntegrationIdFilterDefinition;
  onSave: (d: IntegrationIdFilterDefinition) => void;
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
}> = ({ definition, onSave, filters, range }) => {
  const [integrations, loading] = useIntegrations(filters, range);
  if (!integrations || loading) {
    return <IntegrationIdMenuLoading />;
  }
  return (
    <IntegrationIdMenuBody
      integrations={integrations}
      definition={definition}
      onSave={onSave}
    />
  );
};

export const IntegrationIdMenu: DimensionMenuComponent<IntegrationIdFilterDefinition> = ({
  definition,
  onSave,
  filters,
  range
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);

  return (
    <FilterMenu>
      <FilterMenu.Header dimension="integration_id">
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <InnerIntegrationIdMenu
        filters={filters}
        range={range}
        definition={definition}
        onSave={onSave}
      />
    </FilterMenu>
  );
};
