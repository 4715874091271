import Tooltip from '@material-ui/core/Tooltip';
import React, { useCallback, useState } from 'react';
import { styled } from '../emotion';

const Truncate = styled('div')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Truncated: React.FC<{
  title: string;
  alwaysShowTooltip?: boolean;
}> = ({ title, children, alwaysShowTooltip }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const showTooltip = useCallback(
    (e: React.ChangeEvent<{}>) => {
      if (alwaysShowTooltip) {
        setIsTooltipOpen(true);
      }
      const { scrollWidth, offsetWidth } = e.target as HTMLElement;
      if (scrollWidth > offsetWidth) {
        setIsTooltipOpen(true);
      }
    },
    [alwaysShowTooltip]
  );
  const hideTooltip = useCallback(() => setIsTooltipOpen(false), []);
  return (
    <Tooltip
      title={title}
      placement="top-start"
      open={isTooltipOpen}
      onOpen={showTooltip}
      onClose={hideTooltip}
    >
      <Truncate>{children ?? title}</Truncate>
    </Tooltip>
  );
};
