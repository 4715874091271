import { Chip } from '@material-ui/core';
import { compact, keyBy } from 'lodash';
import React from 'react';
import { Doc } from '../../domainTypes/document';
import { IUser } from '../../domainTypes/user';
import { matchUser, useUsersPerSpace } from '../../services/user';
import { AutocompleteMulti } from '../Autocomplete';
import { TextFieldLoader } from '../TextFieldLoader';
import { UserAvatarWithNameNextToIt } from '../UserAvatars';

export const AutocompleteUserMultiById = ({
  spaceId,
  value,
  onChange,
  loadingLabel,
  ...autocompleteProps
}: {
  spaceId: string;
  value: string[];
  onChange: (nextValue: string[], nextUsers: Doc<IUser>[]) => void;
  loadingLabel?: string;
} & Omit<
  React.ComponentProps<typeof AutocompleteMulti>,
  'options' | 'value' | 'onChange' | 'filterOptions' | 'renderTags'
>) => {
  const [usersPerSpace] = useUsersPerSpace(spaceId);
  if (!usersPerSpace) {
    return <TextFieldLoader label={loadingLabel} />;
  }

  const options = compact(Object.values(usersPerSpace.data.users));
  const optionsById = keyBy(options, (o) => o.id);
  const selectedUsers = value.map(
    (userId) =>
      optionsById[userId] || {
        id: userId,
        collection: 'NOT_IMPLEMENTED',
        data: {
          displayName: '',
          email: '',
          photoURL: ''
        }
      }
  );

  return (
    <AutocompleteMulti
      value={selectedUsers}
      onChange={(nextUsers) =>
        onChange(
          nextUsers.map((u) => u.id),
          nextUsers
        )
      }
      options={options}
      filterOptions={(os, { inputValue }) => {
        const re = new RegExp(inputValue, 'i');
        return os.filter((user) => matchUser(user, re));
      }}
      renderTags={(os, getTagProps) =>
        os.map((o, index) => (
          <Chip
            label={<UserAvatarWithNameNextToIt user={o} />}
            {...getTagProps({ index })}
          />
        ))
      }
      renderOption={(o) => <UserAvatarWithNameNextToIt user={o} />}
      {...autocompleteProps}
    />
  );
};
