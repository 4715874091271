import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { ButtonContainer } from '../../admin/components/ButtonContainer';
import { Billing } from '../../admin/features/Spaces/pages/Details/components/Billing';
import { Features } from '../../admin/features/Spaces/pages/Details/components/Features';
import { LinkChecks } from '../../admin/features/Spaces/pages/Details/components/LinkChecks';
import { Monitoring } from '../../admin/features/Spaces/pages/Details/components/Monitoring';
import { PageQueue } from '../../admin/features/Spaces/pages/Details/components/PageQueue';
import { checkSpaceForModifications } from '../../admin/services/modifications';
import { ButtonWithPromise } from '../../components/ButtonWithPromise';
import { NoPermissions } from '../../components/NoPermissions';
import { CanvasBar } from '../../layout/Canvas';
import { LimitedWidth } from '../../layout/PageBody';
import { Section } from '../../layout/Section';
import { useAdminOrImpersonatorClaim } from '../../services/auth';
import { useCurrentUser } from '../../services/currentUser';
import { callFirebaseFunction } from '../../services/firebaseFunctions';

export const PageStaff = () => {
  const { space } = useCurrentUser();
  const [isAdminOrImpersonator] = useAdminOrImpersonatorClaim();

  if (!isAdminOrImpersonator) {
    return (
      <div style={{ marginTop: '50px' }}>
        <LimitedWidth width={1200}>
          <NoPermissions />
        </LimitedWidth>
      </div>
    );
  }

  return (
    <div style={{ marginTop: '50px' }}>
      <LimitedWidth width={1200}>
        <Typography variant="h6" component="h1" paragraph>
          <strong>Staff settings</strong>
        </Typography>
        <Billing space={space} />
        <Monitoring spaceId={space.id} />
        <PageQueue spaceId={space.id} />
        <Section>
          <CanvasBar>Actions</CanvasBar>
          <Card>
            <CardContent>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                paragraph
              >
                Page Queue
              </Typography>
              <ButtonContainer>
                <ButtonWithPromise
                  variant="outlined"
                  onClick={() => checkSpaceForModifications(space.id, false)}
                  pending="Checking..."
                >
                  Check for new revisions and queue screenshots
                </ButtonWithPromise>
                &nbsp;&nbsp;
                <ButtonWithPromise
                  variant="outlined"
                  onClick={() =>
                    callFirebaseFunction('pages-resumeQueue', {
                      spaceId: space.id
                    })
                  }
                  pending="Resuming..."
                >
                  Resume pending page queue
                </ButtonWithPromise>
                &nbsp;&nbsp;
                <ButtonWithPromise
                  variant="outlined"
                  onClick={() => checkSpaceForModifications(space.id, true)}
                  pending="Checking..."
                  color="secondary"
                >
                  Check for revisions and replace all screenshots
                </ButtonWithPromise>
              </ButtonContainer>
            </CardContent>
          </Card>
        </Section>
        <Features space={space} />
        <LinkChecks spaceId={space.id} />
      </LimitedWidth>
    </div>
  );
};
