import { Tooltip } from '@material-ui/core';
import React from 'react';
import {
  ITrackedSale,
  PayoutStatus,
  PAYOUT_UI_CONFIG,
  SALE_UI_CONFIG
} from '../../../domainTypes/performance';
import { styled } from '../../../emotion';

type SaleStatus = ITrackedSale['sale']['status'];

const Badge = styled('span')`
  display: inline-block;
  font-size: ${(p) => p.theme.typography.caption.fontSize};
  border-radius: 999px;
  padding: ${(p) => p.theme.spacing(0.5)}px ${(p) => p.theme.spacing(1.5)}px;
  font-weight: bold;
`;

export const SalesStatus: React.FC<{ status: SaleStatus; style?: any }> = ({
  status,
  style
}) => {
  const data = SALE_UI_CONFIG[status] || SALE_UI_CONFIG.Unknown;
  const css = {
    backgroundColor: data.color,
    color: data.text,
    fontWeight: 700,
    ...style
  };
  return <Badge style={css}>{data.label}</Badge>;
};

const StatusCircle = styled('div')`
  width: 12px;
  height: 12px;
  border-radius: 100%;
`;

const PayoutStatusCircle = styled('div')`
  width: 12px;
  height: 12px;
  border-radius: 100%;
  position: relative;
  margin-left: -3px;
`;

export const SalesStatusCompact: React.FC<{
  status: SaleStatus;
  payoutStatus?: PayoutStatus;
  title?: string;
}> = ({ status, payoutStatus }) => {
  const data = SALE_UI_CONFIG[status] || SALE_UI_CONFIG.Unknown;
  const css = { backgroundColor: data.color };

  const payoutStatusConfig =
    payoutStatus && payoutStatus !== 'unknown'
      ? PAYOUT_UI_CONFIG[payoutStatus]
      : null;

  return (
    <div style={{ display: 'flex' }}>
      <Tooltip title={data.label} placement="top">
        <StatusCircle style={css} />
      </Tooltip>
      {payoutStatusConfig && (
        <Tooltip title={payoutStatusConfig.label} placement="top">
          <PayoutStatusCircle
            style={{ backgroundColor: payoutStatusConfig.color }}
          />
        </Tooltip>
      )}
    </div>
  );
};
