import { Button, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { BookOpen, ExternalLink, Video as VideoIcon } from 'react-feather';
import { Platform } from '../..';
import { AlertBox } from '../../../../components/AlertBox';
import styled from '../../../../emotion/styled';
import { withStoppedPropagation } from '../../../../helpers';
import { Beacon, FIELDS, TOPIC_OPTIONS } from '../../../../services/beacon';
import { useCurrentUser } from '../../../../services/currentUser';
import {
  getCombinedSnippet,
  getMetaTag,
  getSnippet
} from '../../../../services/snippet';
import { addDomainUnverified } from '../../../../services/space';
import { isSameDomainWithoutWww } from '../../../../services/url';
import { testWebsite } from '../../../../services/validation';
import { PulsingCircle } from '../../../PulsingCircle';
import { Video } from '../../../Video';
import { usePlans } from '../../../../features/Billing/service';
import { useRoutes } from '../../../../routes';
import { Link } from 'react-router-dom';
import { useAdminClaim } from '../../../../services/auth';
import { SupportButton } from '../../../SupportButton';

const Code = styled('code')`
  font-family: monospace;
  background-color: #eee;
  font-size: 0.8em;
  padding: ${(p) => p.theme.spacing(0.5)}px;
  border-radius: ${(p) => p.theme.custom.border.radius()}px;
`;

const Body = styled('div')`
  margin: ${(p) => p.theme.spacing() * 2}px;

  a,
  a:visited,
  a:hover {
    color: ${(p) => p.theme.palette.primary.main};
  }
`;

const TextArea = styled('textarea')`
  margin: ${(p) => p.theme.spacing(1)}px auto;
  display: block;
  font-family: monospace;
  width: 100%;
  font-size: 0.8em;
  color: ${(p) => p.theme.palette.grey[900]};
  padding: ${(p) => p.theme.spacing(1)}px ${(p) => p.theme.spacing(2)}px;
  border: 1px solid ${(p) => p.theme.palette.grey[300]};
  background-color: ${(p) => p.theme.palette.grey[100]};
  border-radius: 8px;
`;

const Form = styled('form')`
  margin-bottom: ${(p) => p.theme.spacing() * 2}px;
`;

const INITIAL_DOMAIN = 'https://';

type IProps = {
  onVerify: (domain: string) => Promise<any>;
  platform: Platform | null;
  domain?: string;
};

interface Message {
  [key: string]: string;
}

const renderError = (errorCode: string) => {
  const messages: Message = {
    'failed-precondition': 'We could not find the script on your home page.',
    'already-exists': 'This domain is already associated with your account',
    'not-found': 'Your user does not exist. Weird huh?',
    'invalid-argument':
      'The domain you provided caused a redirect. Should "www" be in your domain?'
  };

  return messages[errorCode] ? (
    <p>
      <strong>{messages[errorCode]}</strong>
    </p>
  ) : null;
};

const checkDomainValidity = (
  newDomain: string,
  setFormErrorMsg: (str: string) => void
) => {
  const startsWithHttp =
    newDomain.startsWith('http://') || newDomain.startsWith('https://');

  if (!startsWithHttp) {
    const message =
      'Your domain name needs to begin with either "http://" or preferrably "https://".';
    setFormErrorMsg(message);
    return message;
  }

  const errorMessage = testWebsite(newDomain);
  setFormErrorMsg(errorMessage);

  console.log(errorMessage);
  return errorMessage;
};

const Steps = styled('ol')`
  li {
    list-style: none;
    counter-increment: steps-counter;
    position: relative;
    padding-top: 0.4em;
    padding-bottom: 1em;
    font-size: 16px;
  }

  li:before {
    content: counter(steps-counter);
    position: absolute;
    top: 0;
    left: -45px;
    background-color: ${(p) => p.theme.palette.grey[900]};
    color: #fff;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }
`;

export const WebflowInstructions = ({
  scriptSnippet,
  metaSnippet
}: {
  scriptSnippet: string;
  metaSnippet: string;
}) => {
  return (
    <Body>
      <Steps>
        <li>
          Go to <b>Project settings &gt; Custom code</b>. Paste in{' '}
          <b>Head Code</b>:
          <br />
          <TextArea rows={1} defaultValue={metaSnippet} />
        </li>
        <li>
          Then scroll to the <b>Body Code</b> and paste in:
          <br />
          <TextArea rows={2} defaultValue={scriptSnippet} />
        </li>
        <li>
          Finally, click the button to <b>Save Changes</b>.
        </li>
      </Steps>
    </Body>
  );
};

export const OtherInstructions = ({
  scriptSnippet,
  metaSnippet
}: {
  scriptSnippet: string;
  metaSnippet: string;
}) => {
  return (
    <Body>
      <Steps>
        <li>
          Inside the <Code>{'<head>'}</Code> on your site, copy and paste this
          code:
          <br />
          <TextArea rows={2} defaultValue={metaSnippet} />
        </li>
        <li>
          Then paste this code snippet before the closing{' '}
          <Code>{`</body>`}</Code> tag.
          <br />
          <TextArea rows={2} defaultValue={scriptSnippet} />
        </li>
      </Steps>
    </Body>
  );
};

export const WordPressInstructions = ({
  scriptSnippet,
  metaSnippet
}: {
  scriptSnippet: string;
  metaSnippet: string;
}) => {
  return (
    <Body>
      <Steps>
        <li>
          Inside the <Code>{'<head>'}</Code> on your site, copy and paste this
          code:
          <br />
          <TextArea rows={1} defaultValue={metaSnippet} />
        </li>
        <li>
          Then paste this code snippet before the closing{' '}
          <Code>{`</body>`}</Code> tag.
          <br />
          <TextArea rows={2} defaultValue={scriptSnippet} />
        </li>
      </Steps>
    </Body>
  );
};

export const SquarespaceInstructions = ({
  scriptSnippet,
  metaSnippet
}: {
  scriptSnippet: string;
  metaSnippet: string;
}) => {
  return (
    <Body>
      <Typography paragraph variant="body2" color="textSecondary" component="p">
        Note that you can only use Squarespace's{' '}
        <a
          href="https://support.squarespace.com/hc/en-us/articles/205815908"
          target="_blank"
          rel="noopener noreferrer"
        >
          code injection feature
        </a>{' '}
        if you're on a Business or Commerce plan.
      </Typography>
      <Steps>
        <li>
          In the <b>Home Menu</b> go to{' '}
          <b>Settings &gt; Advanced &gt; Code Injection</b>
        </li>
        <li>
          In the <b>Header</b>, paste this verification code:
          <br />
          <TextArea rows={1} defaultValue={metaSnippet} />
        </li>
        <li>
          In the <b>Footer</b>, paste your snippet and save:
          <br />
          <TextArea rows={2} defaultValue={scriptSnippet} />
        </li>
      </Steps>
    </Body>
  );
};

export const GtmInstructions = ({
  scriptSnippet
}: {
  scriptSnippet: string;
}) => {
  return (
    <Body>
      <Steps>
        <li>
          Go to <b>Tags &gt; New</b> and choose <b>Custom HTML Tag</b>.
        </li>
        <li>
          Use this code as this the HTML tag:
          <br />
          <TextArea rows={2} defaultValue={scriptSnippet} />
        </li>
        <li>
          For the Trigger Configuration, choose <b>All Page Views</b>.
        </li>
        <li>
          Optionally, use a{' '}
          <b>
            <a
              href="https://support.google.com/tagmanager/answer/7679316#filters"
              target="_blank"
              rel="nofollow noreferrer"
              style={{ borderBottom: '1px solid' }}
            >
              Trigger Filter
            </a>
          </b>{' '}
          if you only want to track specific URLs matching a pattern e.g.{' '}
          <em>Page URL contains /reviews/</em>.
        </li>
        <li>
          <b>Publish</b> the tag.
        </li>
      </Steps>
    </Body>
  );
};

export const WixInstructions = ({
  scriptSnippet,
  metaSnippet
}: {
  scriptSnippet: string;
  metaSnippet: string;
}) => {
  return (
    <Body>
      <Steps>
        <li>
          Go to <b>Settings</b> in your site's dashboard.
        </li>
        <li>
          Go to <b>Advanced Settings &gt; Custom Code &gt; + Add Custom Code</b>
          .
        </li>
        <li>
          Paste the code blow in the textbox and name it "Affilimate Tag":
          <br />
          <TextArea rows={1} defaultValue={metaSnippet} />
        </li>
        <li>
          Under <b>Add Code to Pages</b> choose <b>All pages</b>
        </li>
        <li>
          Choose <b>Head</b> for code placement. Click <b>Apply</b>
        </li>
        <li>
          Repeat the process and add the following code, named "Affilimate
          Snippet", to <b>All Pages</b> placed in <b>Body - end</b>.
          <br />
          <TextArea rows={2} defaultValue={scriptSnippet} />
        </li>
      </Steps>
    </Body>
  );
};

const UrlRow = styled('div')`
  display: grid;
  margin-top: ${(p) => p.theme.spacing(3)}px;
  grid-template-columns: 1fr 200px;
  grid-column-gap: ${(p) => p.theme.spacing(2)}px;
  align-items: center;

  button {
    height: 56px;
  }
`;

const TroubleshootingSection = ({ domain }: { domain: string }) => {
  const openChat = () => {
    Beacon('prefill', {
      subject: 'Manually verify a website',
      text: `Hi there, can you please manually verify the setup for ${domain}?`
    });
    Beacon('navigate', '/ask/message/');
  };

  return (
    <div>
      <AlertBox variant="pending">
        <strong>Make sure to clear all caching plugins and your CDN</strong>.
        Wait a few seconds for those changes to take effect, then reload your
        site.
        <br />
        <br />
        If you've installed the snippet but your site hasn't sent data after a
        few minutes, you can request manual verification
        <Button
          variant="text"
          style={{
            border: 0,
            margin: 0,
            position: 'relative',
            top: '1px',
            fontSize: '14px',
            minWidth: 'auto',
            padding: '1px 1px 4px 3px'
          }}
          size="small"
          onClick={openChat}
        >
          <u>here</u>.
        </Button>
      </AlertBox>
    </div>
  );
};

export function InstallStep({
  domain: prefilledDomain,
  onVerify,
  platform
}: IProps) {
  const [domain, setDomain] = useState(prefilledDomain || INITIAL_DOMAIN);
  const [loading, setLoading] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [formErrorMsg, setFormErrorMsg] = useState('');
  // prettier-ignore
  const [errorCode, setErrorCode] = useState<null| string>(null);
  const { ROUTES } = useRoutes();
  const { space } = useCurrentUser();
  const currentSpace = useMemo(() => space || { apiToken: 'DUMMY' }, [space]);
  const scriptSnippet = getSnippet();
  const combinedSnippet = getCombinedSnippet(currentSpace.apiToken);
  const metaSnippet = getMetaTag(currentSpace.apiToken);
  const hasFormError = formErrorMsg !== '';
  const plans = usePlans();
  const [adminClaim, loadingAdminClaim] = useAdminClaim();

  // Override available site for specific space
  const availableSites = (() => {
    if (space.id === 'b58v-c6NW') {
      return 25;
    }
    if (space.id === '9APWqMdrL') {
      return 15;
    }
    return plans.find((p) => p.name === space.billing.activePlan)?.sites;
  })();
  const activeSites = space.domains.filter((d) => d.active).length;
  const exceededSiteLimit = availableSites
    ? activeSites >= availableSites
    : false;

  const onSubmit = withStoppedPropagation(() => {
    const errorMessage = checkDomainValidity(domain, setFormErrorMsg);
    if (errorMessage) {
      return;
    }

    setLoading(true);
    return addDomainUnverified(currentSpace.id, domain).catch((error) => {
      const code = error.code;

      // Fine, because domain may be pre-filled from signup
      if (code === 'already-exists') {
        return;
      } else {
        setErrorCode(code);
        setLoading(false);
      }
    });
  });

  useEffect(() => {
    if (domain === 'https://') {
      return;
    }

    const domainObj = currentSpace.domains.find((d) => {
      return isSameDomainWithoutWww(d.url, domain);
    });

    if (!domainObj) {
      return;
    }

    if (domainObj.verified) {
      onVerify(domainObj.url);
    }
  }, [domain, currentSpace, onVerify]);

  if (loading) {
    return (
      <div>
        <Body>
          <Typography paragraph variant="h5" style={{ textAlign: 'center' }}>
            <PulsingCircle color="green" />
            <br />
            <br />
            Waiting for data
          </Typography>
          <Typography
            variant="body1"
            component="p"
            style={{ textAlign: 'center', marginBottom: '24px' }}
          >
            Visit any page on your site where the snippet is installed to send
            your first analytics to Affilimate:
            <br />
            <br />
            <Button
              href={domain}
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
              size="large"
              variant="text"
              style={{ fontWeight: 700, border: 0 }}
            >
              {domain} &nbsp;
              <ExternalLink size={16} />
            </Button>
            <br />
            <br />
            <Button
              size="large"
              variant="text"
              color="default"
              onClick={() => {
                setLoading(false);
              }}
              style={{ opacity: 0.7, border: 0 }}
            >
              Or, go back to setup instructions
            </Button>
          </Typography>
        </Body>
        <TroubleshootingSection domain={domain} />
      </div>
    );
  }

  return (
    <div>
      <Body>
        <Typography paragraph variant="h5" style={{ textAlign: 'center' }}>
          Install Affilimate ({platform})
        </Typography>
      </Body>
      {platform === Platform.WordPress && (
        <WordPressInstructions
          scriptSnippet={scriptSnippet}
          metaSnippet={metaSnippet}
        />
      )}
      {platform === Platform.GTM && (
        <GtmInstructions scriptSnippet={combinedSnippet} />
      )}
      {platform === Platform.Webflow && (
        <WebflowInstructions
          scriptSnippet={scriptSnippet}
          metaSnippet={metaSnippet}
        />
      )}
      {platform === Platform.Other && (
        <OtherInstructions
          scriptSnippet={scriptSnippet}
          metaSnippet={metaSnippet}
        />
      )}
      {platform === Platform.Squarespace && (
        <SquarespaceInstructions
          scriptSnippet={scriptSnippet}
          metaSnippet={metaSnippet}
        />
      )}
      {platform === Platform.Wix && (
        <WixInstructions
          scriptSnippet={scriptSnippet}
          metaSnippet={metaSnippet}
        />
      )}

      <Typography
        variant="body2"
        component="p"
        style={{
          textAlign: 'center',
          marginBottom: '24px',
          marginTop: '-24px'
        }}
      >
        Not sure how?
        <br />
        <Button
          variant="text"
          href={`https://app.affilimate.com/docs/install-snippet/#${platform?.toLowerCase()}`}
          target="_blank"
          size="small"
          color="primary"
          rel="noreferrer noopener"
        >
          <BookOpen size={16} /> &nbsp; Read our step-by-step guide
        </Button>
        {platform === 'WordPress' && (
          <Button
            variant="text"
            color="primary"
            size="small"
            onClick={() => {
              setShowVideo(!showVideo);
            }}
            style={{ marginLeft: '12px' }}
          >
            <VideoIcon size={16} /> &nbsp;{' '}
            {!showVideo ? 'Watch 1-minute tutorial' : 'Hide video tutorial'}
          </Button>
        )}
      </Typography>
      {showVideo && (
        <Video
          url="https://player.vimeo.com/video/690792337?h=49272439a2"
          width={555}
          height={290}
        />
      )}
      <Body style={{ borderTop: '1px solid #eee', paddingTop: '24px' }}>
        <Typography>
          After these changes, clear your cache and CDN. Then, request that
          Affilimate verify your setup and confirm the addition of your site.
        </Typography>
        <Form onSubmit={onSubmit}>
          <UrlRow>
            <TextField
              required
              variant="outlined"
              label="Your website"
              value={domain}
              fullWidth
              margin="none"
              error={hasFormError}
              onChange={(e) => {
                e.stopPropagation();
                setDomain(e.target.value);
              }}
            />
            {adminClaim && !loadingAdminClaim ? (
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                disabled={domain === INITIAL_DOMAIN || exceededSiteLimit}
              >
                Test Setup
              </Button>
            ) : (
              <SupportButton
                disabled={domain === INITIAL_DOMAIN || exceededSiteLimit}
                subject={`Request Site Verification: ${domain}`}
                text={`Please check the tech setup for the domain: ${domain}`}
                sessionData={{
                  Domain: domain,
                  'Has exceeded the site limit?': exceededSiteLimit,
                  'Install method': platform || 'Unknown'
                }}
                fields={[
                  {
                    id: FIELDS.TOPIC,
                    value: TOPIC_OPTIONS.REQUEST_SITE_VERIFICATION
                  }
                ]}
                label="Request verification"
              />
            )}
          </UrlRow>
          {formErrorMsg !== '' && (
            <AlertBox variant="error">{formErrorMsg}</AlertBox>
          )}
          {errorCode !== null && (
            <>
              <AlertBox variant="pending">{renderError(errorCode)}</AlertBox>
              <TroubleshootingSection domain={domain} />
            </>
          )}
        </Form>
        {exceededSiteLimit && (
          <AlertBox variant="pending" style={{ marginTop: '12px' }}>
            <strong>Head's up!</strong> You're already tracking {activeSites} of{' '}
            {availableSites} websites included in your plan. Please deactivate a
            site or{' '}
            <Link to={ROUTES.settings.billing.url()}>upgrade your plan</Link>.
          </AlertBox>
        )}
      </Body>
    </div>
  );
}
