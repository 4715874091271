import { Switch, Tooltip } from '@material-ui/core';
import React from 'react';
import { IPartner } from '../../../domainTypes/partners';
import { isFeatureEnabled } from '../../../services/features';

export const SmartLabelSwitch = ({
  value,
  partnerKey,
  disabled,
  onChange
}: {
  value: boolean;
  disabled?: boolean;
  partnerKey: IPartner['key'];
  onChange: (nextValue: boolean) => void;
}) => {
  const hasAmazonSubtags = isFeatureEnabled('AMAZON_SUBTAGS');

  if (partnerKey === 'amazon') {
    if (hasAmazonSubtags) {
      return (
        <Switch
          color="primary"
          checked={value}
          onChange={(ev) => onChange(ev.target.checked)}
        />
      );
    }
    return (
      <Tooltip
        placement="top"
        title="Please contact our Support team to enable subtag reporting, if you've already been granted access by your Amazon account manager."
      >
        <div>
          <Switch color="primary" checked={false} disabled />
        </div>
      </Tooltip>
    );
  }

  return (
    <Switch
      color="primary"
      checked={value}
      disabled={disabled}
      onChange={(ev) => onChange(ev.target.checked)}
    />
  );
};
