import React from 'react';
import { ExternalLink as IconOpenNewWindow } from 'react-feather';
import { LinkExternal } from '../../../../components/LinkExternal';
import { useCurrentUser } from '../../../../services/currentUser';
import { isSingleSiteSpace } from '../../../../services/space';
import { getPathname, withoutProtocol } from '../../../../services/url';

export const DetailsPageTitle = ({ url }: { url: string }) => {
  const { space } = useCurrentUser();
  const title = isSingleSiteSpace(space)
    ? getPathname(url)
    : withoutProtocol(url);
  return (
    <LinkExternal href={url}>
      <span>{title}</span>&nbsp;
      <IconOpenNewWindow
        size={20}
        style={{ position: 'relative', top: '2px' }}
      />
    </LinkExternal>
  );
};
