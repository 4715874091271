import { Chip as MuiChip } from '@material-ui/core';
import React from 'react';
import { css } from '../../emotion';
import { AffilimateTheme } from '../../themes';

export type ChipProps = {
  label: React.ReactNode;
  type?: 'SUCCESS' | 'PENDING' | 'ERROR' | 'NONE' | 'UNMODIFIED' | 'ABORTED';
  minWidth?: number | string;
  size?: 'small' | 'medium';
  style?: any;
  variant?: 'default' | 'outlined';
};

const getColor = (
  t: AffilimateTheme,
  type?: ChipProps['type']
): [string, string] => {
  const { colors } = t.custom;
  if (type === 'SUCCESS') {
    return [colors.success.contrastText, colors.success.main];
  } else if (type === 'PENDING') {
    return [colors.pending.contrastText, colors.pending.main];
  } else if (type === 'ERROR') {
    return [colors.error.contrastText, colors.error.main];
  } else if (type === 'ABORTED') {
    return [colors.error.contrastText, '#a4a4a4'];
  }
  return [t.palette.background.paper, t.palette.text.primary];
};

export const Chip = ({
  label,
  type,
  minWidth,
  style,
  size,
  variant
}: ChipProps) => {
  type = type || 'NONE';
  return (
    <MuiChip
      component="span"
      variant={variant}
      classes={{
        root: css((t) => {
          const [color, backgroundColor] = getColor(t, type);
          if (variant === 'outlined') {
            return {
              color: `${backgroundColor} !important`,
              borderColor: `${backgroundColor} !important`,
              fontWeight: t.typography.fontWeightMedium,
              minWidth: minWidth || 'auto',
              ...style
            };
          }
          return {
            color: `${color} !important`,
            backgroundColor: `${backgroundColor} !important`,
            fontWeight: t.typography.fontWeightMedium,
            minWidth: minWidth || 'auto',
            ...style
          };
        })
      }}
      label={label}
      size={size}
    />
  );
};
