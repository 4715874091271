import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';

export const useDeepEqual = <T>(x: T) => {
  const ref = useRef<T>(x);
  useEffect(() => {
    if (!isEqual(ref.current, x)) {
      ref.current = x;
    }
  }, [x]);
  return ref.current;
};
