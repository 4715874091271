import { scaleLinear, ScaleLinear } from 'd3-scale';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  TooltipHeading,
  TooltipRow
} from '../../../../components/Charts/CustomTooltip';
import { WithShape } from '../../../../components/Charts/Util';
import { Popover } from '../../../../components/Popover';
import { ICountWithTrend } from '../../../../domainTypes/analytics';
import { styled } from '../../../../emotion';
import { useHover } from '../../../../hooks/useHover';
import {
  useColorsSpectrumGrayscale,
  useColorsSpectrumPrimary
} from '../../../../services/color';

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: ${(p) => p.theme.spacing(1)}px;
  color: #9b9b9b;
  font-size: ${(p) => p.theme.custom.fontSize.m}px;
`;

const Pathname = styled('div')`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
`;

const BarsContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const Bar = styled<'div', { width: number; color: string }>('div')`
  width: ${(p) => p.width}%;
  background-color: ${(p) => p.color};
  height: 0.4rem;
`;

const useColors = () => {
  const colors = useColorsSpectrumPrimary();
  const colorsWarning = useColorsSpectrumGrayscale();
  const color = colors[0];
  const compareColor = colors[3];
  const dropColor = colorsWarning[1];
  const compareDropColor = colorsWarning[3];

  return {
    positive: {
      bef: compareColor,
      aft: color
    },
    negative: {
      bef: compareDropColor,
      aft: dropColor
    }
  };
};

const Bars = ({
  counter,
  scale
}: {
  counter: ICountWithTrend;
  scale: ScaleLinear<number, number>;
}) => {
  const colors = useColors();
  const cs = counter.trend > 0 ? colors.positive : colors.negative;

  const anchor = useRef<HTMLDivElement | null>(null);
  const popoverOpen = useHover(anchor);
  return (
    <BarsContainer ref={anchor}>
      <Bar width={scale(counter.count)} color={cs.aft} />
      <Bar width={scale(counter.lastCount)} color={cs.bef} />
      {popoverOpen && (
        <Popover variant="chart" widthMultiplier={24}>
          <TooltipHeading small>Clicks</TooltipHeading>
          <TooltipRow>
            <WithShape shape="square" color={cs.aft} small>
              Current
            </WithShape>
            {counter.count}
          </TooltipRow>
          <TooltipRow>
            <WithShape shape="square" color={cs.bef} small>
              Previous
            </WithShape>
            {counter.lastCount}
          </TooltipRow>
        </Popover>
      )}
    </BarsContainer>
  );
};

export type ClicksTableRow = {
  key: string;
  clicks: ICountWithTrend;
  title: React.ReactNode;
  linkTo?: string;
};

export const ClicksTable = ({ rows }: { rows: ClicksTableRow[] }) => {
  const maxClicks =
    Math.max(
      ...[
        ...rows.map((r) => r.clicks.count),
        ...rows.map((r) => r.clicks.lastCount)
      ]
    ) || 0;
  const scale = scaleLinear().range([0, 100]).domain([0, maxClicks]);

  if (!rows.length) {
    return null; // TODO empty state
  }

  return (
    <Grid>
      {rows.map((r) => {
        return (
          <React.Fragment key={r.key}>
            <Pathname>
              {r.linkTo ? <Link to={r.linkTo}>{r.title}</Link> : r.title}
            </Pathname>
            <Bars counter={r.clicks} scale={scale} />
          </React.Fragment>
        );
      })}
    </Grid>
  );
};
