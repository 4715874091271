import { css, styled } from '../../../../../../emotion';
import { Card } from '@material-ui/core';
import isPropValid from '@emotion/is-prop-valid';
import {
  utmParameterTitle,
  utmParameters,
  UtmParameterType,
  utmParameterTooltip
} from '../service/utm';
import { FlexContainer, FlexItem } from '../../../../../../layout/Flex';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useRoutes } from '../../../../../../routes';
import { Counts, useAllCountsQuery } from '../service/data';
import { Skeleton } from '@material-ui/lab';
import { LoadingValue } from '../../../../../../services/db';
import { formatChartNumber } from '../../../../../../components/Charts/Util';
import { Info } from 'react-feather';
import Tooltip from '@material-ui/core/Tooltip';

const ParameterCard = styled(Card, {
  shouldForwardProp: (props) => isPropValid(props)
})<{ isActive: boolean }>`
  height: 90px;
  outline: ${(p) =>
    p.isActive ? `1.5px solid ${p.theme.palette.grey[500]}` : 'none'};
`;

interface TotalsProps {
  selectedParameter: UtmParameterType;
}

const CountSkeleton = styled(Skeleton)`
  width: 60%;
  font-size: 1.5rem;
`;

interface CountProps {
  parameter: UtmParameterType;
  data: LoadingValue<Counts>;
}

const Count: React.FC<CountProps> = ({ parameter, data }) => {
  const [counts, loading, error] = data;
  if (error) {
    return <Typography variant="h5">-</Typography>;
  }
  if (!counts || loading) {
    return <CountSkeleton />;
  }
  return (
    <Typography variant="h5">
      {formatChartNumber(counts[parameter])}{' '}
    </Typography>
  );
};

export const UtmTotals: React.FC<TotalsProps> = ({ selectedParameter }) => {
  const { ROUTES } = useRoutes();
  const counts = useAllCountsQuery();
  return (
    <FlexContainer spacing={2} marginBottom={2}>
      {utmParameters.map((parameter) => {
        const link = ROUTES.content.utm.details.url(parameter);
        const title = utmParameterTitle(parameter);
        const tooltip = utmParameterTooltip(parameter);
        return (
          <FlexItem flex={1} key={parameter}>
            <Link to={link}>
              <ParameterCard isActive={parameter === selectedParameter}>
                <FlexContainer>
                  <Typography variant="body1">{title}</Typography>
                  <Tooltip title={tooltip} aria-label={tooltip} placement="top">
                    <Info
                      size={14}
                      className={css((t) => ({
                        color: t.palette.grey[500],
                        flexShrink: 0
                      }))}
                    />
                  </Tooltip>
                </FlexContainer>
                <Count parameter={parameter} data={counts} />
              </ParameterCard>
            </Link>
          </FlexItem>
        );
      })}
    </FlexContainer>
  );
};
