import { DBSchema, openDB, IDBPDatabase } from 'idb';
import {
  IDenomarlizationByPage,
  IDenormalizationByProduct,
  IDenormalizationByClick
} from '../../domainTypes/denormalization';
import { Doc } from '../../domainTypes/document';
import * as ENV from '../../env.json';

interface IDBSchema extends DBSchema {
  denormalizationByPage: {
    key: string;
    value: Doc<IDenomarlizationByPage>;
  };
  denormalizationByProduct: {
    key: string;
    value: Doc<IDenormalizationByProduct>;
  };
  denormalizationByClick: {
    key: string;
    value: Doc<IDenormalizationByClick>;
  };
}

const NAME = `affilimate-idb-1-${ENV.env.name.toLowerCase().replace(' ', '_')}`;
const VERSION = 5;

let openIdbConnection: Promise<IDBPDatabase<IDBSchema>> | null = null;

export const idb = async () => {
  if (!openIdbConnection) {
    openIdbConnection = openDB<IDBSchema>(NAME, VERSION, {
      upgrade: db => {
        // some where introduced later, so we just remove what we can
        try {
          db.deleteObjectStore('denormalizationByProduct');
        } catch {}
        try {
          db.deleteObjectStore('denormalizationByPage');
        } catch {}
        try {
          db.deleteObjectStore('denormalizationByClick');
        } catch {}

        db.createObjectStore('denormalizationByProduct', { keyPath: 'id' });
        db.createObjectStore('denormalizationByPage', { keyPath: 'id' });
        db.createObjectStore('denormalizationByClick', { keyPath: 'id' });
      }
    });
  }
  return openIdbConnection;
};
