import { IconButton, Tooltip } from '@material-ui/core';
import { SortDirection } from '@material-ui/core/TableCell';
import { orderBy } from 'lodash';
import React from 'react';
import { DownloadCloud as IconDownload } from 'react-feather';
import { Loader } from '../../../../components/Loader';
import { PartnerLogoWithName } from '../../../../components/PartnerLogo';
import { IColumn } from '../../../../components/Table/Column';
import { VirtualizedSortableTable } from '../../../../components/Table/VirtualizedSortable';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { Doc } from '../../../../domainTypes/document';
import { PARTNERS } from '../../../../domainTypes/partners';
import { IReport } from '../../../../domainTypes/performance';
import { useHasCurrentUserRequiredScopes } from '../../../../services/currentUser';
import { downloadFromCloudStorage } from '../../../../services/storage';
import {
  formatDatePrecise,
  formatDateRange,
  HUMAN_DATE
} from '../../../../services/time';
import { ReportDeleteButton } from '../ReportDeleteButton';
import { PreviewButton } from '../ReportPreviewButton';

type Props = {
  reports: void | Doc<IReport>[];
  loading: boolean;
  currency: CurrencyCode;
  tz: string;
  variant?: 'normal' | 'condensed';
  height?: number;
};

type SortKey =
  | 'partnerKey'
  | 'partnerName'
  | 'range'
  | 'salesCount'
  | 'uploadedAt'
  | 'actions';

type CellProps = {
  tz: string;
  currency: CurrencyCode;
};

const sortFn = (
  rows: Doc<IReport>[],
  sortBy: SortKey,
  direction: SortDirection
) => {
  return orderBy(
    rows,
    (r) => {
      if (sortBy === 'salesCount') {
        return r.data.salesCount;
      }
      if (sortBy === 'uploadedAt') {
        return r.data.createdAt.toMillis();
      }
      return r.data.partnerKey;
    },
    direction
  );
};

const Actions = ({
  d,
  tz,
  currency
}: {
  d: Doc<IReport>;
  tz: string;
  currency: CurrencyCode;
}) => {
  const [canDeleteReports] = useHasCurrentUserRequiredScopes([
    'integrations.delete_report'
  ]);

  return (
    <>
      {canDeleteReports && <ReportDeleteButton report={d} />}
      <Tooltip title="Download raw data" placement="top">
        <IconButton
          onClick={() => downloadFromCloudStorage(d.data.source.storagePath)}
        >
          <IconDownload size={20} />
        </IconButton>
      </Tooltip>
      <PreviewButton d={d} tz={tz} currency={currency} />
    </>
  );
};

const COLUMNS: IColumn<Doc<IReport>, SortKey, CellProps>[] = [
  {
    key: 'partnerKey',
    head: () => 'Partner',
    cell: (d) => {
      const partner = PARTNERS.find((p) => p.key === d.data.partnerKey);
      if (!partner) {
        return d.data.partnerKey;
      }
      return <PartnerLogoWithName partner={partner} maxLength={25} />;
    },
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'range',
    head: () => 'Report timeframe',
    cell: (d) => formatDateRange(d.data.start, d.data.end),
    align: 'left',
    sortable: false,
    defaultDirection: 'desc',
    width: 140,
    flexGrow: 2
  },
  {
    key: 'salesCount',
    head: () => '# of sales',
    cell: (d) => d.data.salesCount,
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'uploadedAt',
    head: () => 'Uploaded at',
    cell: (d) => formatDatePrecise(d.data.createdAt, HUMAN_DATE),
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'actions',
    head: () => '',
    cell: (d, p) => <Actions d={d} {...p} />,
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 50,
    flexGrow: 1
  }
];

const CONDENSED_COLUMNS: IColumn<Doc<IReport>, SortKey, CellProps>[] = [
  {
    key: 'uploadedAt',
    head: () => 'Uploaded at',
    cell: (d) => formatDatePrecise(d.data.createdAt, HUMAN_DATE),
    align: 'left',
    sortable: true,
    defaultDirection: 'desc',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'range',
    head: () => 'Report timeframe',
    cell: (d) => formatDateRange(d.data.start, d.data.end),
    align: 'left',
    sortable: false,
    defaultDirection: 'desc',
    width: 140,
    flexGrow: 2
  },
  {
    key: 'salesCount',
    head: () => '# of sales',
    cell: (d) => d.data.salesCount,
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 50,
    flexGrow: 1
  },
  {
    key: 'actions',
    head: () => '',
    cell: (d, p) => <Actions d={d} {...p} />,
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 50,
    flexGrow: 1
  }
];

export const ReportsTable: React.FC<Props> = ({
  reports,
  loading,
  variant = 'normal',
  height = 500,
  tz,
  currency
}) => {
  if (loading || !reports) {
    return <Loader height={height} />;
  }
  const columns = variant === 'condensed' ? CONDENSED_COLUMNS : COLUMNS;
  const startColumn = variant === 'condensed' ? columns[0] : columns[3];
  return (
    <VirtualizedSortableTable
      rows={reports}
      columns={columns}
      cellProps={{ tz, currency }}
      height={height}
      margin="normal"
      sortFn={sortFn}
      initialSortColumn={startColumn}
    />
  );
};
