import React from 'react';
import { getKnownPartnerForKey } from '../../../services/partner';
import { WithShape } from '../../../components/Charts/Util';
import { getAdvertiserColor } from '../../../components/AdvertiserWithColor';
import { ConnectionId } from '../../../components/ConnectionId';
import { Typography } from '@material-ui/core';
import { FlexContainer } from '../../../layout/Flex';

interface Advertiser {
  name: string;
  partnerKey: string;
  integrationId: string;
  nickname?: string;
}

interface AdvertiserLabelProps {
  advertiser: Advertiser;
}

export const AdvertiserLabel: React.FC<AdvertiserLabelProps> = ({
  advertiser
}) => {
  const platform = getKnownPartnerForKey(advertiser.partnerKey);
  return (
    <div style={{ padding: '8px 4px' }}>
      <WithShape
        large
        color={getAdvertiserColor(advertiser.name, advertiser.partnerKey)}
      >
        {advertiser.name}
      </WithShape>
      <FlexContainer alignItems="center" justifyContent="flex-start">
        <Typography variant="caption" color="textSecondary">
          {platform?.name}
        </Typography>

        <ConnectionId>
          {advertiser.nickname ? advertiser.nickname : advertiser.integrationId}
        </ConnectionId>
      </FlexContainer>
    </div>
  );
};
