import { Typography } from '@material-ui/core';
import { compact, groupBy, sortBy, sum } from 'lodash';
import React, { useMemo } from 'react';
import {
  AnalyticsColumnTransformer,
  AnalyticsFilter,
  AnalyticsQuery,
  AnalyticsSearch
} from '../../../domainTypes/analytics_v2';
import { CurrencyCode } from '../../../domainTypes/currency';
import { EMPTY_ARR } from '../../../domainTypes/emptyConstants';
import { ISpace } from '../../../domainTypes/space';
import { Centered } from '../../../layout/Centered';
import { FlexContainer } from '../../../layout/Flex';
import {
  ANALYTICS_GROUPS,
  AnalyticsGroup,
  useChannelIdGrouper
} from '../../../services/analyticsV2/groups';
import { useAnalyticsQueryV2 } from '../../../services/analyticsV2/query';
import { COLOR_UNKNOWN } from '../../../services/color';
import {
  useCombineLoadingValues,
  useMappedLoadingValue
} from '../../../services/db';
import { intervalToTk, ISOTimeRange } from '../../../services/time';
import { InlineLoader, Loader } from '../../Loader';
import { ChartCard, ChartCardFooter, ChartCardFooterBar } from '../ChartCard';
import { Data, EarningsBarChartV2, TimeseriesData } from '../EarningsChartV2';
import { ILegendItem, Legend } from '../Util';
import { ChartMode, ChartModeSelector } from './ChartModeSelector';
import {
  GraphMode,
  GraphModeSelector,
  graphModeToLabel
} from './GraphModeSelector';
import {
  IntervalSelector,
  IntervalSelectorOption
} from '../../IntervalSelector';
import { TimeframeIntervalDefinition } from '../../../domainTypes/timeframe';
import { useAnalyticsInterval } from '../../../hooks/timeframe';

export type EarningsBarChartCardMetricV2 =
  | 'commission_sum_net'
  | 'gmv_sum_net'
  | 'c';

const DEFAULT_SELECTABLE_METRICS: EarningsBarChartCardMetricV2[] = [
  'commission_sum_net',
  'gmv_sum_net'
];
export const EarningsChartCardV2: React.FC<{
  data: void | Data[];
  loading: boolean;
  currency: CurrencyCode;
  style?: React.CSSProperties;
  graphMode: GraphMode;
  setGraphMode?: (nextGraphMode: GraphMode) => void;
  chartMode: ChartMode;
  setChartMode?: (nextGraphMode: ChartMode) => void;
  metric: EarningsBarChartCardMetricV2;
  setMetric?: (nextMetric: EarningsBarChartCardMetricV2) => void;
  selectableMetrics?: EarningsBarChartCardMetricV2[];
  interval: TimeframeIntervalDefinition;
  setInterval?: (nextInterval: TimeframeIntervalDefinition) => void;
  intervalOptions?: IntervalSelectorOption[];
  range?: ISOTimeRange;
  tz: string;
  heading?: string;
  subHeading?: string;
}> = ({
  data,
  loading,
  currency,
  style,
  graphMode,
  setGraphMode,
  chartMode,
  setChartMode,
  metric,
  setMetric,
  interval,
  setInterval,
  intervalOptions,
  selectableMetrics = DEFAULT_SELECTABLE_METRICS,
  range,
  tz,
  heading,
  subHeading
}) => {
  const chartSelectors = (
    <>
      {setInterval && intervalOptions && (
        <IntervalSelector
          value={interval}
          onChange={setInterval}
          options={intervalOptions}
        />
      )}
      {setGraphMode && (
        <GraphModeSelector value={graphMode} onChange={setGraphMode} />
      )}
      {setChartMode && (
        <ChartModeSelector value={chartMode} onChange={setChartMode} />
      )}
    </>
  );

  // store reference to last timeframe
  // if loading serve last timeframe
  // if loading turns false -> expose new timeframe

  const _heading = useMemo(() => {
    return (
      heading ||
      (metric === 'commission_sum_net'
        ? 'Earnings'
        : metric === 'gmv_sum_net'
        ? 'Sales volume'
        : 'Outbound clicks')
    );
  }, [metric, heading]);

  const _subHeading = useMemo(() => {
    return subHeading || `By ${graphModeToLabel(graphMode)}`;
  }, [graphMode, subHeading]);

  const legendItems: ILegendItem[] = setMetric
    ? compact([
        selectableMetrics.includes('commission_sum_net') && {
          color: '#444',
          shape: 'circle',
          active: metric === 'commission_sum_net',
          onClick: () => setMetric('commission_sum_net'),
          label: 'Earnings'
        },
        selectableMetrics.includes('gmv_sum_net') && {
          color: '#444',
          shape: 'circle',
          active: metric === 'gmv_sum_net',
          onClick: () => setMetric('gmv_sum_net'),
          label: 'Sales volume'
        },
        selectableMetrics.includes('c') && {
          color: '#444',
          shape: 'circle',
          active: metric === 'c',
          onClick: () => setMetric('c'),
          label: 'Clicks'
        }
      ])
    : EMPTY_ARR;

  if (loading) {
    return (
      <ChartCard
        heading={_heading}
        subheading={_subHeading}
        topRight={chartSelectors}
        style={style}
        size="small"
        padding="dense"
      >
        <Loader height={330} />
      </ChartCard>
    );
  }
  if (!data) {
    // TODO this should be an error state
    return (
      <ChartCard
        heading={_heading}
        subheading={_subHeading}
        topRight={chartSelectors}
        style={style}
        size="small"
        padding="dense"
      >
        <Centered>
          <Typography
            variant="body1"
            component="p"
            paragraph
            style={{ textAlign: 'center', marginTop: '100px', maxWidth: '80%' }}
          >
            <strong>No data was loaded</strong>
            <br />
            <br />
            Try refreshing the page. If you expect to see data here and the
            error persists, please contact Support with the URL to this page.
          </Typography>
        </Centered>
        <ChartCardFooter>
          <ChartCardFooterBar>
            <Legend items={legendItems} />
          </ChartCardFooterBar>
        </ChartCardFooter>
      </ChartCard>
    );
  }

  return (
    <ChartCard
      heading={_heading}
      subheading={_subHeading}
      topRight={
        <FlexContainer>
          {loading && <InlineLoader color="inherit" />}
          {chartSelectors}
        </FlexContainer>
      }
      style={style}
      size="small"
      padding="dense"
      maximizedContent={() => (
        <EarningsBarChartV2
          height={'auto'}
          data={data}
          currency={currency}
          metric={metric}
          yAxisOrientation="right"
          range={range}
          chartMode={chartMode}
          intervalUnit={interval.unit}
          tz={tz}
        />
      )}
    >
      <EarningsBarChartV2
        height={372}
        data={data}
        currency={currency}
        metric={metric}
        yAxisOrientation="right"
        range={range}
        chartMode={chartMode}
        intervalUnit={interval.unit}
        tz={tz}
      />
      <ChartCardFooter>
        <ChartCardFooterBar>
          <Legend items={legendItems} />
        </ChartCardFooterBar>
      </ChartCardFooter>
    </ChartCard>
  );
};

interface EarningsChartCardWithoutDataV2Props {
  space: ISpace;
  range: ISOTimeRange;
  filters: AnalyticsFilter[];
  search: AnalyticsSearch[];
  columnTransformers?: AnalyticsColumnTransformer[];
  currency: CurrencyCode;
  metric: EarningsBarChartCardMetricV2;
  setMetric: (nextMetric: EarningsBarChartCardMetricV2) => void;
  selectableMetrics?: EarningsBarChartCardMetricV2[];
  style?: React.CSSProperties;
  graphMode: GraphMode;
  setGraphMode?: (nextGraphMode: GraphMode) => void;
  interval: TimeframeIntervalDefinition;
  setInterval?: (nextInterval: TimeframeIntervalDefinition) => void;
  intervalOptions?: IntervalSelectorOption[];
  chartMode: ChartMode;
  setChartMode?: (nextGraphMode: ChartMode) => void;
  heading?: string;
  subHeading?: string;
  limit?: number;
}

const EarningsBarChartCardAsyncInner = ({
  space,
  range,
  filters,
  baselineFilters,
  search,
  interval,
  columnTransformers,
  metric,
  grouper,
  logLabel,
  ...props
}: EarningsChartCardWithoutDataV2Props & {
  baselineFilters: AnalyticsFilter[];
  grouper: AnalyticsGroup;
  logLabel: string;
}) => {
  const analyticsInterval = useAnalyticsInterval(interval);
  const q1 = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      filters: [...filters, ...baselineFilters],
      search,
      groupBy: grouper.groupBy,
      columnTransformers,
      interval: analyticsInterval,
      select: [metric]
    };
  }, [
    range,
    filters,
    baselineFilters,
    search,
    grouper.groupBy,
    columnTransformers,
    analyticsInterval,
    metric
  ]);

  const q2 = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      filters,
      search,
      columnTransformers,
      interval: analyticsInterval,
      select: [metric]
    };
  }, [range, filters, search, columnTransformers, analyticsInterval, metric]);
  const [d, loading] = useMappedLoadingValue(
    useCombineLoadingValues(
      useAnalyticsQueryV2(space.id, q1, { logLabel: `${logLabel}-actual` }),
      useAnalyticsQueryV2(space.id, q2, { logLabel: `${logLabel}-totals` })
    ),
    ([actual, totals]) => {
      const actualsByInterval = groupBy(
        actual.rows,
        (r) => r.group['interval']
      );
      const grouped = groupBy(actual.rows, grouper.toKey);

      const sort = (data: Data[], metric: EarningsBarChartCardMetricV2) =>
        sortBy(
          data,
          (d) => -d.timeseries.reduce((m, e) => (e[metric] || 0) + m, 0)
        );

      const data: Data[] = Object.entries(grouped).map(([g, rows]) => {
        const container = grouper.toContainerFromKey(g);
        const timeseries: TimeseriesData[] = rows.map((r) => {
          return {
            // check res.q.interval?.unit to format the interval correct.
            tk: intervalToTk(
              r.group['interval'],
              space.config.tz,
              actual.q.interval?.unit
            ),
            [metric]: r.data[metric]?.curr || 0
          };
        });
        return { container, timeseries };
      });

      const sorted = sort(data, metric);

      return [
        ...sorted,
        {
          container: {
            key: 'OTHER',
            label: 'Others',
            color: COLOR_UNKNOWN
          },
          timeseries: totals.rows.map((r) => {
            const otherValues = sum(
              (actualsByInterval[r.group['interval']] || []).map(
                (x) => x.data[metric]?.curr || 0
              )
            );
            return {
              tk: intervalToTk(
                r.group['interval'],
                space.config.tz,
                actual.q.interval?.unit
              ),
              [metric]: (r.data[metric]?.curr || 0) - otherValues
            };
          })
        }
      ];
    }
  );

  return (
    <EarningsChartCardV2
      data={d}
      loading={loading}
      range={range}
      metric={metric}
      tz={space.config.tz}
      interval={interval}
      {...props}
    />
  );
};

const EarningsBarChartCardAsync = ({
  space,
  range,
  filters,
  search,
  interval,
  columnTransformers,
  grouper,
  logLabel,
  limit = 10,
  metric,
  ...props
}: EarningsChartCardWithoutDataV2Props & {
  grouper: AnalyticsGroup;
  logLabel: string;
}) => {
  const _columnTransformers = useMemo(
    () => [...grouper.columnTransformers(space), ...(columnTransformers || [])],
    [space, grouper, columnTransformers]
  );

  const q = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      filters,
      search,
      groupBy: grouper.groupBy,
      _columnTransformers,
      select: [metric],
      orderBy: [{ field: metric, direction: 'DESC' }],
      paginate: {
        page: 1,
        limit
      }
    };
  }, [range, filters, search, grouper, metric, _columnTransformers, limit]);

  const [baselineFilters] = useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, q, {
      logLabel: `${logLabel}-baseline`
    }),
    (res) =>
      compact(
        (grouper.groupBy || []).map<AnalyticsFilter | null>((g) => {
          const values = compact(res.rows.map((r) => r.group[g] ?? null));
          if (!values.length) {
            return null;
          }
          return {
            field: g,
            condition: 'in',
            values
          };
        })
      )
  );

  if (!baselineFilters) {
    return (
      <EarningsChartCardV2
        data={undefined}
        loading={true}
        range={range}
        metric={metric}
        tz={space.config.tz}
        interval={interval}
        {...props}
      />
    );
  } else {
    return (
      <EarningsBarChartCardAsyncInner
        space={space}
        range={range}
        filters={filters}
        baselineFilters={baselineFilters}
        search={search}
        interval={interval}
        columnTransformers={_columnTransformers}
        grouper={grouper}
        logLabel={logLabel}
        metric={metric}
        {...props}
      />
    );
  }
};

const EarningsBarChartCardChannel = (
  props: EarningsChartCardWithoutDataV2Props
) => {
  const grouper = useChannelIdGrouper();
  return (
    <EarningsBarChartCardAsync
      {...props}
      grouper={grouper}
      logLabel={'earnings_bar_chart_channel'}
    />
  );
};

export const EarningsChartCardWithoutDataV2 = ({
  ...props
}: EarningsChartCardWithoutDataV2Props) => {
  const { graphMode } = props;
  // We place a key on all of these components to avoid any weird
  // surprises when all of a sudden a grouper changes, finding previous
  // data. When the graphMode changes, just redo the whole component.
  if (graphMode === 'channel') {
    return <EarningsBarChartCardChannel key={graphMode} {...props} />;
  }
  if (graphMode === 'platform') {
    return (
      <EarningsBarChartCardAsync
        key={graphMode}
        grouper={ANALYTICS_GROUPS.pk}
        logLabel="earnings_bar_chart_platform"
        {...props}
      />
    );
  }
  if (graphMode === 'advertiser') {
    return (
      <EarningsBarChartCardAsync
        key={graphMode}
        grouper={ANALYTICS_GROUPS.advertiser}
        logLabel="earnings_bar_chart_advertiser"
        {...props}
      />
    );
  }
  if (graphMode === 'origin') {
    return (
      <EarningsBarChartCardAsync
        key={graphMode}
        grouper={ANALYTICS_GROUPS.page_url_origin}
        logLabel="earnings_bar_chart_page_url_origin"
        {...props}
      />
    );
  }
  if (graphMode === 'country') {
    return (
      <EarningsBarChartCardAsync
        key={graphMode}
        grouper={ANALYTICS_GROUPS.country}
        logLabel="earnings_bar_chart_country"
        {...props}
      />
    );
  }
  if (graphMode === 'device') {
    return (
      <EarningsBarChartCardAsync
        key={graphMode}
        grouper={ANALYTICS_GROUPS.device}
        logLabel="earnings_bar_chart_device"
        {...props}
      />
    );
  }
  if (graphMode === 'transactionStatus') {
    return (
      <EarningsBarChartCardAsync
        key={graphMode}
        grouper={ANALYTICS_GROUPS.sale_status}
        logLabel="earnings_bar_chart_sale_status"
        {...props}
      />
    );
  }
  if (graphMode === 'payoutStatus') {
    return (
      <EarningsBarChartCardAsync
        key={graphMode}
        grouper={ANALYTICS_GROUPS.payout_status}
        logLabel="earnings_bar_chart_payout_status"
        {...props}
      />
    );
  }
  if (graphMode === 'partner_product_name') {
    return (
      <EarningsBarChartCardAsync
        key={graphMode}
        grouper={ANALYTICS_GROUPS.partner_product_name}
        logLabel="earnings_bar_chart_partner_product_name"
        {...props}
      />
    );
  }
  if (graphMode === 'partner_product_id') {
    return (
      <EarningsBarChartCardAsync
        key={graphMode}
        grouper={ANALYTICS_GROUPS.partner_product_id}
        logLabel="earnings_bar_chart_partner_product_id"
        {...props}
      />
    );
  }
  if (graphMode === 'partner_product_category') {
    return (
      <EarningsBarChartCardAsync
        key={graphMode}
        grouper={ANALYTICS_GROUPS.partner_product_category}
        logLabel="earnings_bar_chart_partner_product_category"
        {...props}
      />
    );
  }
  if (graphMode === 'seller') {
    return (
      <EarningsBarChartCardAsync
        key={graphMode}
        grouper={ANALYTICS_GROUPS.seller}
        logLabel="earnings_bar_chart_seller"
        {...props}
      />
    );
  }
  if (graphMode === 'brand') {
    return (
      <EarningsBarChartCardAsync
        key={graphMode}
        grouper={ANALYTICS_GROUPS.brand}
        logLabel="earnings_bar_chart_brand"
        {...props}
      />
    );
  }
  return null;
};
