import { useCollection } from 'react-firebase-hooks/firestore';
import shortid from 'shortid';
import { Doc, generateToDocFn } from '../../domainTypes/document';
import { toReportingStatsId } from '../../domainTypes/reporting';
import {
  ISecret,
  ISecretConfigField,
  ISecretWithTs
} from '../../domainTypes/secret';
import { API_REPORT_HANDLERS } from '../../features/PerformanceNew/services/handlers';
import { FS } from '../../versions';
import { LoadingValue, store, useMappedLoadingValue } from '../db';

export const toSecretDoc = generateToDocFn<ISecretWithTs>((d) => {
  const h = API_REPORT_HANDLERS.find((x) => x.configName === d.name);

  if (!h) {
    throw new Error(`No handler for secret ${d.name}`);
  }

  if (!d.status) {
    d.status = 'active';
  }

  if (!d.instanceId) {
    d.instanceId = toReportingStatsId(d.spaceId, h.partnerKey);
  }

  h.configFields.forEach((field) => {
    if (!d.data.find((x) => x.key === field.key)) {
      const defaultValue = (() => {
        if (
          ['text', 'url', 'domain', 'currency', 'password', 'email'].indexOf(
            field.type
          ) !== -1
        ) {
          return '';
        }
        if (field.type === 'domainAndIdPairs') {
          return [{ key: '', value: '' }];
        }
      })();

      d.data.push({
        key: field.key,
        secret: field.secret,
        value: defaultValue
      });
    }
  });

  return d;
});

export const useSecrets = (
  spaceId: string,
  category: string
): LoadingValue<Doc<ISecretWithTs>[]> => {
  return useMappedLoadingValue(
    useCollection(
      store()
        .collection(FS.secretsPublic)
        .where('spaceId', '==', spaceId)
        .where('category', '==', category)
    ),
    (s) => s.docs.map(toSecretDoc)
  );
};

export const emptySecret = (
  spaceId: string,
  createdBy: string,
  category: string,
  name: string,
  fields: ISecretConfigField[]
): Doc<ISecretWithTs> => {
  return {
    id: shortid(),
    collection: FS.secretsPublic,
    data: {
      spaceId,
      category,
      name,
      createdBy,
      instanceId: shortid(),
      createdAt: null,
      nickname: '',
      status: 'active',
      data: fields.map((f) => ({
        key: f.key,
        value: '',
        secret: f.secret
      }))
    }
  };
};

export const getSecretValue = (
  secret: ISecret,
  key: string,
  defaultValue = ''
) => {
  const v = secret.data.find((f) => f.key === key);
  return v ? v.value : defaultValue;
};

export const setSecretValue = <T extends ISecret>(
  secret: T,
  key: string,
  value: any
) => {
  return {
    ...secret,
    data: secret.data.map((f) =>
      f.key === key
        ? {
            ...f,
            value
          }
        : f
    )
  };
};
