import { ButtonBase, MenuItem, Popover } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { styled } from '../../emotion';
import { Arrow } from '../Arrow';
import { MultiSelectorPopoverBody, MultiSelectorProps } from '../MultiSelector';
import {
  SingleSelectorPopoverBody,
  SingleSelectorProps
} from '../SingleSelector';

type CommonProps = {
  label: React.ReactNode;
  appliedLabel: React.ReactNode;
  isApplied: boolean;
};

export type IMultiSelectorFilter = CommonProps &
  Omit<MultiSelectorProps<any>, 'children'> & {
    key: string;
    type: 'multiSelector';
  };

export type ISingleSelectorFilter = CommonProps &
  SingleSelectorProps<any> & {
    key: string;
    type: 'singleSelector';
  };

type Filter = ISingleSelectorFilter | IMultiSelectorFilter;

type Props = {
  filters: Filter[];
};

const NestedMenuLabel = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${(p) => p.theme.custom.fontSize.m}px;
  padding: ${(p) => p.theme.spacing()}px;

  > :not(:last-child) {
    margin-right: ${(p) => p.theme.spacing(1)}px;
  }
`;

const NestedMenu: React.FC<CommonProps> = ({
  label,
  appliedLabel,
  isApplied,
  children
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLLIElement>(null);
  return (
    <>
      <MenuItem ref={ref} style={{ padding: 0 }}>
        <ButtonBase
          onClick={() => setOpen((x) => !x)}
          style={{ display: 'block', width: '100%', padding: '8px 10px' }}
        >
          <NestedMenuLabel>
            <div>{isApplied ? appliedLabel : label}</div>
            <Arrow dir="RIGHT" size={16} />
          </NestedMenuLabel>
        </ButtonBase>
      </MenuItem>

      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {children}
      </Popover>
    </>
  );
};

const MultiSelectorFilter = ({
  label,
  appliedLabel,
  isApplied,
  ...props
}: IMultiSelectorFilter) => {
  return (
    <NestedMenu label={label} appliedLabel={appliedLabel} isApplied={isApplied}>
      <MultiSelectorPopoverBody {...props} />
    </NestedMenu>
  );
};

const SingleSelectorFilter = ({
  label,
  appliedLabel,
  isApplied,
  ...props
}: ISingleSelectorFilter) => {
  return (
    <NestedMenu label={label} appliedLabel={appliedLabel} isApplied={isApplied}>
      <SingleSelectorPopoverBody {...props} onClose={() => undefined} />
    </NestedMenu>
  );
};

export const NestedFilterMenu: React.FC<Props> = ({ filters, children }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <>
      <ButtonBase
        onClick={() => setOpen((x) => !x)}
        ref={ref}
        style={{ display: 'block' }}
      >
        {children}
      </ButtonBase>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        {filters.map((f) => (
          <React.Fragment key={f.key}>
            {f.type === 'singleSelector' && <SingleSelectorFilter {...f} />}
            {f.type === 'multiSelector' && <MultiSelectorFilter {...f} />}
          </React.Fragment>
        ))}
      </Popover>
    </>
  );
};
