import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useState } from 'react';
import { Trash } from 'react-feather';
import { HelpIcon } from '../../../components/HelpIcon';
import { Loader } from '../../../components/Loader';
import { IAmazonApiKeys } from '../../../domainTypes/linkCheckV2/api/amazon';
import { AMAZON_MARKETPLACES } from '../../../domainTypes/reporting';
import { ISpace } from '../../../domainTypes/space';
import { styled } from '../../../emotion';
import { useBackgroundJobs } from '../../../hooks/useBackgroundJobs';
import { useErrorLogger } from '../../../hooks/useErrorLogger';
import { useApiConfigs } from '../../../services/amazonApiKeys';
import { ARTICLES } from '../../../services/beacon';
import { useHasCurrentUserRequiredScopes } from '../../../services/currentUser';
import { callFirebaseFunction } from '../../../services/firebaseFunctions';
import { useMixpanel } from '../../../services/mixpanel';
import { CF } from '../../../versions';

const RowWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 50px;
  grid-column-gap: ${(p) => p.theme.spacing(1)}px;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const EMPTY_CONFIG = (spaceId: string): IAmazonApiKeys => ({
  spaceId,
  Marketplace: 'www.amazon.com',
  AccessKey: '',
  SecretKey: '',
  PartnerTag: ''
});

const storeApiKeys = async (nextKeys: IAmazonApiKeys) =>
  callFirebaseFunction(CF.amazonApiKeys.store, nextKeys);
const removeApiKeys = async (id: string) =>
  callFirebaseFunction(CF.amazonApiKeys.remove, { id });

export const AmazonApiSettings = ({ space }: { space: ISpace }) => {
  const [apiConfigs, loading, error] = useApiConfigs(space.id);
  const [canEditSettings] = useHasCurrentUserRequiredScopes([
    'link_checker.amazon.api_keys.edit'
  ]);
  const mixpanel = useMixpanel();

  useErrorLogger(error);

  const { addJob } = useBackgroundJobs();
  const [newConfig, setNewConfig] = useState<IAmazonApiKeys>(
    EMPTY_CONFIG(space.id)
  );

  const handleSaveNewConfig = (newConfig: IAmazonApiKeys) => {
    addJob({
      job: async () => {
        return storeApiKeys(newConfig).then(() => {
          mixpanel.track('add_amazon_product_api_keys', {
            marketplace: newConfig.Marketplace
          });
        });
      },
      onStart: () => {
        return {
          message: (
            <>
              <CircularProgress color="inherit" size={16} /> &nbsp; Testing
              credentials...
            </>
          )
        };
      },
      onSuccess: () => {
        // Clear out the next one
        setNewConfig(EMPTY_CONFIG(space.id));

        return {
          message: `Your ${newConfig.Marketplace} connection was successfully added`
        };
      },
      onError: () => {
        return {
          message:
            'Your API connection was unsuccessful. Please check the credentials you supplied are correct.'
        };
      }
    });
  };

  if (loading) {
    return <Loader height={200} />;
  }

  return (
    <div>
      <Typography variant="body1" component="p" paragraph>
        <strong>Connect to the Amazon Product Advertising API</strong>
        &nbsp; &nbsp; &nbsp;
        <HelpIcon
          color="blue"
          articleId={ARTICLES.links.amazonProductAdvertisingApi}
        >
          Show setup guide
        </HelpIcon>
      </Typography>
      <Typography variant="body2" component="p" color="textSecondary" paragraph>
        We’ll use these API credentials to check your links for broken and
        out-of-stock Amazon products.
      </Typography>
      <br />
      {apiConfigs &&
        apiConfigs.map((config) => (
          <AmazonApiRow
            key={config.id}
            disabled={true}
            onSetApiConfig={() => null}
            onDelete={async () => {
              return removeApiKeys(config.id).then(() => {
                mixpanel.track('remove_amazon_product_api_keys', {
                  marketplace: config.data.Marketplace
                });
              });
            }}
            config={config.data}
          />
        ))}
      {canEditSettings && (
        <AmazonApiRow
          disabled={false}
          onSetApiConfig={setNewConfig}
          config={newConfig}
        />
      )}
      {canEditSettings && (
        <Button
          size="large"
          color="primary"
          variant="contained"
          onClick={() => {
            handleSaveNewConfig(newConfig);
          }}
        >
          Add connection
        </Button>
      )}
    </div>
  );
};

const AmazonApiRow = ({
  config,
  disabled,
  onSetApiConfig,
  onDelete
}: {
  config: IAmazonApiKeys;
  disabled: boolean;
  onSetApiConfig: (config: IAmazonApiKeys) => void;
  onDelete?: () => Promise<any>;
}) => {
  const { Marketplace, AccessKey, SecretKey, PartnerTag } = config;
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [canEditSettings] = useHasCurrentUserRequiredScopes([
    'link_checker.amazon.api_keys.edit'
  ]);

  return (
    <RowWrapper>
      <FormControl variant="outlined">
        <Select
          value={Marketplace}
          disabled={disabled}
          onChange={(e) => {
            onSetApiConfig({
              ...config,
              Marketplace: e.target.value as string
            });
          }}
        >
          <MenuItem value="">Choose an Amazon store</MenuItem>
          {AMAZON_MARKETPLACES.map((marketplace) => (
            <MenuItem value={marketplace.id} key={marketplace.id}>
              {marketplace.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <TextField
          type="text"
          value={PartnerTag}
          disabled={disabled}
          placeholder="Store ID"
          onChange={(e) => {
            onSetApiConfig({ ...config, PartnerTag: e.target.value as string });
          }}
          variant="outlined"
        />
      </FormControl>
      <FormControl>
        <TextField
          type="password"
          value={AccessKey}
          disabled={disabled}
          placeholder="Access Key"
          onChange={(e) => {
            onSetApiConfig({ ...config, AccessKey: e.target.value as string });
          }}
          variant="outlined"
        />
      </FormControl>
      <FormControl>
        <TextField
          type="password"
          value={SecretKey}
          disabled={disabled}
          placeholder="Secret key"
          onChange={(e) => {
            onSetApiConfig({ ...config, SecretKey: e.target.value as string });
          }}
          variant="outlined"
        />
      </FormControl>
      {canEditSettings && (
        <div>
          {onDelete && !deleteInProgress && (
            <Tooltip title="Delete connection" placement="top">
              <IconButton
                onClick={() => {
                  setDeleteInProgress(true);
                  return onDelete().then(
                    () => setDeleteInProgress(false),
                    () => setDeleteInProgress(false)
                  );
                }}
              >
                <Trash size={18} />
              </IconButton>
            </Tooltip>
          )}
          {deleteInProgress && <CircularProgress color="inherit" size={18} />}
        </div>
      )}
    </RowWrapper>
  );
};
