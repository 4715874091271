import {
  CircularProgress,
  Dialog,
  DialogProps,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core';
import React, { createElement, useRef, useState } from 'react';
import {
  MoreHorizontal as IconMoreHori,
  MoreVertical as IconMoreVert,
  Settings as IconSettings
} from 'react-feather';
import { Link } from 'react-router-dom';
import { styled } from '../../emotion';
import { useId } from '../../hooks/useId';
import { LinkExternal } from '../LinkExternal';

type DialogComponent<T = any> = React.ComponentType<{
  onClose: () => void;
  data?: T;
}>;

const Loader = styled(CircularProgress)`
  width: 20px !important;
  height: 20px !important;

  svg {
    color: ${(p) => p.theme.palette.primary.main};
  }
`;

export interface AdditionActionsMenuOption<T = any> {
  key: string;
  label: React.ReactNode;
  onClick?: (e: React.SyntheticEvent) => any;
  href?: string;
  externalHref?: string;
  dialog?: DialogComponent<T>;
  dialogMaxWidth?: DialogProps['maxWidth'];
  icon?: React.ComponentType<{
    size?: string | number;
    style?: React.CSSProperties;
  }>;
  divider?: boolean;
  disabled?: boolean;
}

export type AdditionalActionsMenuProps<T = any> = {
  label?: string;
  data?: T;
  options: AdditionActionsMenuOption<T>[];
  variant?: 'vertical' | 'horizontal' | 'cog';
};

const OptionContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OptionLabel = ({ o }: { o: AdditionActionsMenuOption }) => (
  <OptionContainer>
    {o.icon && createElement(o.icon, { size: 16, style: { marginRight: 8 } })}
    {o.label}
  </OptionContainer>
);

const ICON_SIZE = 20;

export const AdditionalActionsMenu = <T extends any>({
  options,
  label = '',
  variant = 'vertical',
  data
}: AdditionalActionsMenuProps<T>) => {
  const menuId = useId();
  const [dialogComp, setDialogComp] = useState<DialogComponent | null>(null);
  const [dialogMaxWidth, setDialogMaxWidth] = useState<DialogProps['maxWidth']>(
    'xl'
  );
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement | null>(null);
  const close = () => setOpen(false);
  const closeDialog = () => {
    setDialogComp(null);
    setDialogMaxWidth('xl');
  };

  if (!options.length) {
    return null;
  }

  return (
    <>
      <IconButton
        ref={ref}
        aria-label={label}
        aria-owns={open ? menuId : undefined}
        aria-haspopup="true"
        style={{ color: '#BBB' }}
        onClick={(e) => {
          e.stopPropagation();
          return setOpen((o) => !o);
        }}
      >
        {loading && <Loader />}
        {variant === 'vertical' && !loading && (
          <IconMoreVert size={ICON_SIZE} />
        )}
        {variant === 'horizontal' && !loading && (
          <IconMoreHori size={ICON_SIZE} />
        )}
        {variant === 'cog' && !loading && <IconSettings size={ICON_SIZE} />}
      </IconButton>

      <Menu id={menuId} open={open} onClose={close} anchorEl={ref.current}>
        {options.map((option) => {
          const { onClick, href, externalHref, dialog } = option;
          if (onClick) {
            return (
              <MenuItem
                key={option.key}
                onClick={(e) => {
                  const res = onClick(e);
                  if (res && res.then) {
                    setLoading(true);
                    res.then(() => {
                      setLoading(false);
                    });
                  }
                  setOpen(false);
                }}
                divider={option.divider}
                disabled={option.disabled}
              >
                <OptionLabel o={option} />
              </MenuItem>
            );
          }
          if (href) {
            return (
              <Link to={href} key={option.key}>
                <MenuItem divider={option.divider} disabled={option.disabled}>
                  <OptionLabel o={option} />
                </MenuItem>
              </Link>
            );
          }
          if (externalHref) {
            return (
              <LinkExternal href={externalHref} key={option.key}>
                <MenuItem divider={option.divider} disabled={option.disabled}>
                  <OptionLabel o={option} />
                </MenuItem>
              </LinkExternal>
            );
          }

          if (dialog) {
            return (
              <MenuItem
                key={option.key}
                onClick={() => {
                  setOpen(false);
                  setDialogMaxWidth(option.dialogMaxWidth || 'xl');
                  setDialogComp(() => dialog); // beware - diaog is a fn, we can't just pass it, othewise setState will invoke it
                }}
                divider={option.divider}
                disabled={option.disabled}
              >
                <OptionLabel o={option} />
              </MenuItem>
            );
          }
          return null;
        })}
      </Menu>
      <Dialog
        open={!!dialogComp}
        onClose={closeDialog}
        maxWidth={dialogMaxWidth}
      >
        {dialogComp ? (
          createElement(dialogComp, { onClose: closeDialog, data })
        ) : (
          <div />
        )}
      </Dialog>
    </>
  );
};
