import {
  AnalyticsResponse,
  AnalyticsResponseRowWithComparison
} from '../../../../../../domainTypes/analytics_v2';
import { IPostgresTags } from '../../../../../../domainTypes/tags';
import {
  LoadingValueExtended,
  useCombineLoadingValues,
  useMappedLoadingValue
} from '../../../../../../services/db';
import { useTagsForCurrentUser } from '../../../../../../services/tags';
import { keyBy } from 'lodash';

const mergeRowsWithTags = (
  rows: AnalyticsResponseRowWithComparison[],
  tags: Record<string, IPostgresTags>
) => {
  return rows.flatMap((r) => {
    const tag = tags[r.group.tags];
    if (!tag) {
      return [];
    }
    return [
      {
        ...r,
        group: {
          ...r.group,
          tagName: tag.name,
          tagGroupName: tags[tag.parent_id as string].name,
          tagColor: tag.color
        }
      }
    ];
  });
};

export const useTagsById = () => {
  return useMappedLoadingValue(useTagsForCurrentUser(), (tags) =>
    keyBy(tags, (t) => t.id)
  );
};

export const useResultsWithTags = (
  queryResult: LoadingValueExtended<AnalyticsResponse>
) => {
  const tagsById = useTagsById();
  return useMappedLoadingValue(
    useCombineLoadingValues(queryResult, tagsById),
    ([data, tagsById]) => {
      return {
        ...data,
        rows: mergeRowsWithTags(data.rows, tagsById)
      };
    }
  );
};
