import {
  TooltipHeading,
  TooltipPaper,
  TooltipRow,
  TooltipSection,
  TooltipText
} from '../../../../../../components/Charts/CustomTooltip';
import { WithShape } from '../../../../../../components/Charts/Util';
import React from 'react';
import {
  ChartData,
  earningsColor,
  formatters,
  LeftMode,
  REVISIONS_COLOR,
  SERIES_COLOR
} from './index2';
import { useSpaceCurrency } from '../../../../../../services/useSpaceCurrency';
import { CurrencyCode } from '../../../../../../domainTypes/currency';
import { isEmpty } from 'lodash';
import { tkToIntervalLabel } from '../../../../../../services/time';
import { AnalyticsIntervalUnit } from '../../../../../../domainTypes/analytics_v2';

interface ChartTooltipProps {
  startsInCurrentYear: boolean;
  data: ChartData;
  trafficMetric: LeftMode;
  unit: AnalyticsIntervalUnit;
}

const TrafficMetricRows: React.FC<{
  title: string;
  data: {
    prev: number;
    curr: number;
  };
  formatter: (n: number) => string;
}> = ({ formatter, title, data }) => (
  <>
    <TooltipRow>
      <WithShape color={SERIES_COLOR} outlineColor="white" shape="circle">
        {title}
      </WithShape>
      {formatter(data.curr)}
    </TooltipRow>
    <TooltipRow>
      <WithShape color="#555" outlineColor="white" shape="circle">
        Previous {title}
      </WithShape>
      {formatter(data.prev)}
    </TooltipRow>
  </>
);

const TrafficMetric: React.FC<{ metric: LeftMode; data: ChartData }> = ({
  metric,
  data
}) => {
  switch (metric) {
    case 'clicks':
      return (
        <TrafficMetricRows
          title="Clicks"
          data={data.clicks}
          formatter={formatters.clicks}
        />
      );
    case 'pageviews':
      return (
        <TrafficMetricRows
          title="Pageviews"
          data={data.pageviews}
          formatter={formatters.pageviews}
        />
      );
    case 'ctr':
      return (
        <TrafficMetricRows
          title="Page CTR"
          data={data.ctr}
          formatter={formatters.ctr}
        />
      );
  }
};

const EarningsSection: React.FC<{
  data: ChartData;
  currency: CurrencyCode;
}> = ({ data, currency }) => {
  const nonZeroEarnings = Object.entries(data.earnings).filter(
    ([, data]) => data.earnings !== 0
  );
  if (isEmpty(nonZeroEarnings)) {
    return null;
  }
  return (
    <TooltipSection>
      <TooltipText>Earnings</TooltipText>
      {nonZeroEarnings.map(([key, data]) => {
        return (
          <TooltipRow key={key}>
            <WithShape shape="square" color={earningsColor(key)}>
              {data.advertiserName}
            </WithShape>
            {formatters.earnings(data.earnings, currency)}
          </TooltipRow>
        );
      })}
    </TooltipSection>
  );
};

export const ChartTooltip: React.FC<ChartTooltipProps> = ({
  startsInCurrentYear,
  data,
  trafficMetric,
  unit
}) => {
  const currency = useSpaceCurrency();
  return (
    <TooltipPaper>
      <TooltipHeading>
        {tkToIntervalLabel(data.tk, unit, startsInCurrentYear)}
      </TooltipHeading>
      <TooltipSection>
        <TooltipText>Engagement</TooltipText>
        <TrafficMetric metric={trafficMetric} data={data} />
      </TooltipSection>
      <EarningsSection data={data} currency={currency} />
      {!!data.revisions.length && (
        <TooltipSection>
          <TooltipText>Revisions</TooltipText>
          {data.revisions.map((revision) => (
            <TooltipRow key={revision.index}>
              <WithShape shape="circle" color={REVISIONS_COLOR}>
                v{revision.index}
              </WithShape>
              {revision.timestamp.format('LT')}
            </TooltipRow>
          ))}
        </TooltipSection>
      )}
    </TooltipPaper>
  );
};
