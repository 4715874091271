import React from 'react';
import { IDeviceCounter } from '../../../domainTypes/analytics';
import { useColorsSpectrumPrimary } from '../../../services/color';
import { Loader } from '../../Loader';
import { IPieChartCell, PieChartWithLegend } from '../PieChartWithLegend';
import { EmptyContent } from '../ChartCard';

type Props = {
  counts?: IDeviceCounter | void;
  loading: boolean;
  loaderHeight?: string | number;
  animate?: boolean;
};

interface ICell {
  key: keyof IDeviceCounter;
  name: string;
}

const CELLS: ICell[] = [
  {
    key: 'desktop',
    name: 'Desktop'
  },
  {
    key: 'mobile',
    name: 'Mobile'
  },
  {
    key: 'tablet',
    name: 'Tablet'
  }
];

export const DeviceChart = React.memo(
  ({ counts, loading, loaderHeight, animate }: Props) => {
    const colors = useColorsSpectrumPrimary();

    if (loading || !counts) {
      return <Loader height={loaderHeight || 270} />;
    }

    const data: IPieChartCell[] = CELLS.map((c, i) => ({
      ...c,
      count: counts[c.key].clicked,
      color: colors[i]
    }));

    const hasAnyClicks = !!data.find(d => d.count > 0);

    if (!hasAnyClicks) {
      return <EmptyContent />;
    }

    return <PieChartWithLegend data={data} animate={animate} />;
  }
);
