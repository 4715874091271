export const ONBOARDING = {
  cancel: 'onboarding.cancel'
};

export const LOGIN = {
  firebaseUi: 'login.firebaseUi',
  emailLogin: {
    providerButton: 'login.emailLogin.providerButton'
  },
  signOutButton: 'login.signOutButton'
};

export const NAV = {
  title: 'nav.title',
  hero: 'nav.hero',
  userMenu: 'nav.userMenu'
};
