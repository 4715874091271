import React from 'react';
import { useCurrentUser } from '../../services/currentUser';
import { getSnippet, getMetaTag } from '../../services/snippet';
import { Code } from '../Code';

export const TrackingScriptTag = ({ className }: { className?: string }) => {
  return <Code className={className}>{getSnippet()}</Code>;
};

export const TrackingMetaTag = ({ className }: { className?: string }) => {
  const { space } = useCurrentUser();
  return <Code className={className}>{getMetaTag(space.apiToken)}</Code>;
};
