import { useCollection } from 'react-firebase-hooks/firestore';
import shortid from 'shortid';
import { Doc } from '../../domainTypes/document';
import {
  DataExportSchedule,
  ProductScanSchedule,
  Type
} from '../../domainTypes/schedule';
import { useMappedLoadingValue } from '../db';
import { now } from '../time';
import {
  getSchedulesForSpaceByTypeQuery,
  toScheduleDoc,
  updateOrCreateSchedule
} from './helper';

const TYPE: Type = 'DATA_EXPORT';

export const getDefaultDataExportSchedule = (
  spaceId: string,
  createdBy: string,
  tz: string
): DataExportSchedule => ({
  spaceId,
  type: TYPE,
  frequency: {
    type: 'DAILY',
    hour: 5,
    tz
  },
  active: false,
  config: {
    exportType: 'CLICKS',
    destination: 'HOSTED_GCS_BUCKET',
    bucket: '',
    path: ''
  },
  createdAt: now(),
  createdBy,
  lastRun: null,
  nextRun: null
});

export const createDataExportSchedule = (
  spaceId: string,
  createdBy: string,
  tz: string
) => {
  return updateOrCreateSchedule(
    shortid(),
    getDefaultDataExportSchedule(spaceId, createdBy, tz)
  );
};

export const useDataExportSchedules = (spaceId: string) => {
  const extractDoc = (
    v: firebase.firestore.QuerySnapshot
  ): Doc<DataExportSchedule>[] => {
    if (v.empty) {
      return [];
    }
    if (v.size > 1) {
      console.warn(`Found more than 1 product scan schedule for ${spaceId}`);
    }
    return v.docs.map((d: any) => toScheduleDoc(d) as Doc<ProductScanSchedule>);
  };

  return useMappedLoadingValue(
    useCollection(getSchedulesForSpaceByTypeQuery(spaceId, TYPE)),
    extractDoc
  );
};
