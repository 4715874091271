import { Doc } from '../../../../../domainTypes/document';
import { Payout } from '../../../../../domainTypes/payout';
import { useCurrentUser } from '../../../../../services/currentUser';
import { useNumberQueryParam } from '../../../../../routes';
import { usePayoutTransactions, TRANSACTIONS_PAGE_SIZE } from '../service';
import { Centered } from '../../../../../layout/Centered';
import { SalesList, ColumnName } from '../../../components/SalesList';
import { Loader } from '../../../../../components/Loader';

import { useLegacyTimeframe } from '../../../../../hooks/timeframe';

const TRANSACTION_COLUMNS = new Set<ColumnName>([
  'status',
  'advertiser',
  'amount'
]);

export const PayoutSpotCheck = ({ payout }: { payout: Doc<Payout> }) => {
  const { space } = useCurrentUser();
  const accountCurrency = space.config.currency;
  const [page, setPage] = useNumberQueryParam('page', 1);
  const [timeframe] = useLegacyTimeframe();
  const [transactions, loadingTransactions] = usePayoutTransactions(
    space.id,
    timeframe,
    accountCurrency,
    payout,
    page
  );

  if (!transactions || loadingTransactions) {
    return <Loader height={300} size={24} />;
  }
  if (!loadingTransactions && !transactions) {
    return (
      <Centered height={300}>Transactions not available for this data</Centered>
    );
  }
  if (!transactions) {
    return null;
  }

  return (
    <SalesList
      sales={transactions}
      page={page}
      setPage={setPage}
      pageSize={TRANSACTIONS_PAGE_SIZE}
      paginateExternally
      currency={accountCurrency}
      visibleColumns={TRANSACTION_COLUMNS}
    />
  );
};
