import React from 'react';
import { Chip, ChipProps } from '../../../../components/Chip';
import { LinkCheckStatus } from '../../../../domainTypes/linkCheckV2';

export type Props = {
  status: LinkCheckStatus;
  short?: boolean;
  size?: 'small' | 'medium';
};

const getChipType = (status: Props['status']): ChipProps['type'] => {
  switch (status) {
    case LinkCheckStatus.SUCCESS:
      return 'SUCCESS';
    case LinkCheckStatus.RUNNING:
      return 'PENDING';
    case LinkCheckStatus.ERROR:
      return 'ERROR';
    case LinkCheckStatus.ABORTED:
      return 'ABORTED';
    default:
      return 'NONE'; // QUEUEd
  }
};

export const CheckStatusChip = ({ status, short, size }: Props) => {
  const label = status.replace('_', ' ');
  return (
    <Chip
      label={short ? label.slice(0, 1) : label}
      type={getChipType(status)}
      size={size}
    />
  );
};
