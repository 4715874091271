import React, { useState } from 'react';
import { Timeframe } from '../../../domainTypes/analytics';
import { usePartnersWithDailyCountersPg } from '../../../services/partner';
import {
  ChartCard,
  ChartCardFooter,
  ChartCardFooterBar,
  ChartCardFooterSelectTopX
} from '../ChartCard';
import { PartnerBarChart } from '../PartnerBarChart';

export const PartnerBarChartCard: React.FC<{
  spaceId: string;
  timeframe: Timeframe;
  partners: Set<string>;
  style?: React.CSSProperties;
  linkToPartnerPage?: boolean;
  height?: number;
}> = ({ spaceId, timeframe, partners, style, height }) => {
  // const [counters, loading] = usePartnersWithDailyCounters(spaceId, timeframe);
  const [counters, loading] = usePartnersWithDailyCountersPg(
    spaceId,
    timeframe
  );

  const [count, setCount] = useState(10);

  return (
    <ChartCard
      heading="Outbound clicks"
      subheading={`By platform`}
      padding="dense"
      style={style}
    >
      <PartnerBarChart
        timeframe={timeframe}
        counters={
          counters
            ? counters.filter((c) =>
                partners.size > 0 ? partners.has(c.partner.key) : true
              )
            : undefined
        }
        count={count}
        loading={loading}
        animate={true}
        height={height}
      />
      <ChartCardFooter>
        <ChartCardFooterBar>
          <ChartCardFooterSelectTopX
            value={count}
            onChange={setCount}
            step={5}
            max={15}
          />
        </ChartCardFooterBar>
      </ChartCardFooter>
    </ChartCard>
  );
};
