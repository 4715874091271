import moment from 'moment-timezone';
import React from 'react';

export const Date = ({
  d,
  format
}: {
  d: string | number | null | undefined;
  format?: string;
}) => {
  if (!d) {
    return null;
  }
  const m = moment(d);
  return <span>{m.format(format || 'L')}</span>;
};
