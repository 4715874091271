import { CountDown } from '../../../../../components/CountDown';
import { useNextUpdateEstimate } from '../../../../../services/realtime/monitors';

export const UpdateCountDown = () => {
  const nextUpdateEstimate = useNextUpdateEstimate();
  if (!nextUpdateEstimate) {
    return null;
  }
  return <CountDown targetDate={nextUpdateEstimate} />;
};
