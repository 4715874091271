import { Tooltip } from '@material-ui/core';
import { orderBy } from 'lodash';
import React from 'react';
import { LinkTruncated } from '../../../../components/LinkTruncated';
import { IColumn } from '../../../../components/Table/Column';
import { VirtualizedSortableTable } from '../../../../components/Table/VirtualizedSortable';
import { IProductScanPage } from '../../../../domainTypes/productScan';
import { SortDirection } from '../../../../hooks/useSort';
import { ScanStatus } from '../ScanStatus';

type SortKey = keyof IProductScanPage;

const sort = (
  ds: IProductScanPage[],
  sortBy: SortKey,
  direction: SortDirection
): IProductScanPage[] => {
  return orderBy(
    ds,
    (d) => {
      if (sortBy === 'status') {
        const status = d[sortBy];
        if (status === 'SUCCESS') {
          return 3;
        }
        if (status === 'PENDING') {
          return 2;
        }
        if (status === 'ABORTED') {
          return 1;
        }
        if (status === 'ERROR') {
          return 0;
        }
      }
      return d[sortBy];
    },
    direction
  );
};

export const PageTable = ({
  pages,
  absoluteUrls = false
}: {
  pages: IProductScanPage[];
  absoluteUrls?: boolean;
}) => {
  const columns: IColumn<IProductScanPage, SortKey>[] = [
    {
      key: 'url',
      head: () => 'URL',
      cell: (d) => (
        <LinkTruncated
          url={d.url}
          length={100}
          usePathName={!absoluteUrls}
          copy={false}
        />
      ),
      align: 'left',
      sortable: true,
      defaultDirection: 'asc',
      width: 150,
      flexGrow: 4
    },
    {
      key: 'suggestionsFound',
      head: () => 'Links found',
      cell: (d) => d.suggestionsFound,
      align: 'right',
      sortable: true,
      defaultDirection: 'desc',
      width: 100,
      flexGrow: 1
    },
    {
      key: 'status',
      head: () => 'Status',
      cell: (d) => (
        <Tooltip title={d.message} placement="left">
          <span>
            <ScanStatus status={d.status} />
          </span>
        </Tooltip>
      ),
      align: 'right',
      sortable: true,
      defaultDirection: 'desc',
      width: 150,
      flexGrow: 1
    }
  ];

  return (
    <VirtualizedSortableTable
      rows={pages}
      columns={columns}
      cellProps={undefined}
      height={500}
      margin="normal"
      sortFn={sort}
      initialSortColumn={columns[1]}
    />
  );
};
