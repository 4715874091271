import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { TooltipRow } from '../../../../components/Charts/CustomTooltip';
import { WithShape } from '../../../../components/Charts/Util';
import { formatCurrency } from '../../../../components/Number';
import { Popover } from '../../../../components/Popover';
import { Trend } from '../../../../components/Trend';
import { ICountWithTrend } from '../../../../domainTypes/analytics';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { styled } from '../../../../emotion';
import { useHover } from '../../../../hooks/useHover';
import { useTheme } from '../../../../themes';

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: ${(p) => p.theme.spacing(1)}px;
  color: #9b9b9b;
  font-size: ${(p) => p.theme.custom.fontSize.m}px;
`;

const Title = styled('div')`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
`;

const ValueContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${(p) => p.theme.spacing(0.5)}px;
  position: relative;
`;

const Value = ({
  c,
  currency,
  showComparison
}: {
  c: ICountWithTrend;
  currency: CurrencyCode;
  showComparison: boolean;
}) => {
  const anchor = useRef<HTMLDivElement | null>(null);
  const popoverOpen = useHover(anchor);
  const t = useTheme();
  const posColor = t.custom.colors.success.main;
  const negColor = t.custom.colors.error.main;
  const neuColor = t.custom.colors.pending.main;
  const currColor =
    c.trend === 0 ? neuColor : c.trend > 0 ? posColor : negColor;
  const prevColor =
    c.trend === 0 ? neuColor : c.trend < 0 ? posColor : negColor;

  return (
    <ValueContainer ref={anchor}>
      <div>{formatCurrency(c.count, currency)}</div>
      {showComparison ? <Trend values={[c.lastCount, c.count]} /> : null}
      {showComparison && popoverOpen && (
        <Popover variant="chart" widthMultiplier={24}>
          <TooltipRow>
            <WithShape shape="square" color={currColor} small>
              Current
            </WithShape>
            {formatCurrency(c.count, currency)}
          </TooltipRow>
          <TooltipRow>
            <WithShape shape="square" color={prevColor} small>
              Previous
            </WithShape>
            {formatCurrency(c.lastCount, currency)}
          </TooltipRow>
        </Popover>
      )}
    </ValueContainer>
  );
};

export type EarningsTableRow = {
  key: string;
  earnings: ICountWithTrend;
  title: React.ReactNode;
  linkTo?: string;
};

export const EarningsTable = ({
  rows,
  currency,
  showComparison
}: {
  rows: EarningsTableRow[];
  currency: CurrencyCode;
  showComparison: boolean;
}) => {
  return (
    <Grid>
      {rows.map((d) => {
        const title = <span>{d.title}</span>;
        return (
          <React.Fragment key={d.key}>
            <Title>
              {d.linkTo ? <Link to={d.linkTo}>{title}</Link> : title}
            </Title>
            <Value
              c={d.earnings}
              currency={currency}
              showComparison={showComparison}
            />
          </React.Fragment>
        );
      })}
    </Grid>
  );
};
