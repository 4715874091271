import { Paper, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { CountCell } from '../../../components/Table';
import {
  EMPTY_COUNT_WITH_TREND,
  mergeCountWithTrend,
  reduceTrendCounters
} from '../../../domainTypes/analytics';
import { IPageWithCountsAndTrendsAndSales } from '../../../domainTypes/page';
import { styled } from '../../../emotion';
import { getEpc, getRpm } from '../../../services/analytics';
import { useSpaceCurrency } from '../../../services/useSpaceCurrency';

const HEIGHT = `87px`;

const SearchResultsPaper = styled(Paper)`
  height: 0;
  transition: height 0.3s ease-in-out !important;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const ResultsWrapper = styled('div')`
  padding: ${(p) => p.theme.spacing(2)}px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
`;

const ResultTitle = styled(Typography)`
  display: inline-block;
  margin-bottom: ${(p) => p.theme.spacing(1)}px !important;
`;

const Result = styled('div')`
  text-align: center;

  & > div {
    justify-content: center;
  }
`;

export const SearchResultsRibbon = ({
  pages,
  hidden
}: {
  pages: void | IPageWithCountsAndTrendsAndSales[];
  hidden: boolean;
}) => {
  const currency = useSpaceCurrency();

  const { analytics, earnings } = useMemo(() => {
    if (!pages) {
      return { analytics: null, earnings: null };
    }
    return {
      analytics: reduceTrendCounters(pages.map((p) => p.counts)),
      earnings: pages.reduce(
        (acc, p) => mergeCountWithTrend(acc, p.sales),
        EMPTY_COUNT_WITH_TREND()
      )
    };
  }, [pages]);

  return (
    <SearchResultsPaper style={{ height: hidden ? 0 : HEIGHT }}>
      {hidden || !analytics || !earnings || !pages || !pages.length ? null : (
        <ResultsWrapper>
          <Result>
            <ResultTitle variant="caption" color="textSecondary">
              Results
            </ResultTitle>
            <CountCell
              variant="absolute-numbers"
              before={pages.length}
              after={pages.length}
              compare={false}
            />
          </Result>
          <Result>
            <ResultTitle variant="caption" color="textSecondary">
              Pageviews
            </ResultTitle>
            <CountCell
              variant="absolute-numbers"
              before={analytics.pageViews.lastCount}
              after={analytics.pageViews.count}
              compare={true}
            />
          </Result>
          <Result>
            <ResultTitle variant="caption" color="textSecondary">
              Clicks
            </ResultTitle>
            <CountCell
              variant="absolute-numbers"
              before={analytics.clicked.lastCount}
              after={analytics.clicked.count}
              compare={true}
            />
          </Result>
          <Result>
            <ResultTitle variant="caption" color="textSecondary">
              Impressions
            </ResultTitle>
            <CountCell
              variant="absolute-numbers"
              before={analytics.viewed.lastCount}
              after={analytics.viewed.count}
              compare={true}
            />
          </Result>
          <Result>
            <ResultTitle variant="caption" color="textSecondary">
              Earnings
            </ResultTitle>
            <CountCell
              variant="absolute-numbers"
              before={earnings.lastCount}
              after={earnings.count}
              currency={currency}
              compare={true}
            />
          </Result>
          <Result>
            <ResultTitle variant="caption" color="textSecondary">
              RPM
            </ResultTitle>
            <CountCell
              variant="absolute-numbers"
              before={getRpm(analytics.pageViews.lastCount, earnings.lastCount)}
              after={getRpm(analytics.pageViews.count, earnings.count)}
              currency={currency}
              compare={true}
            />
          </Result>
          <Result>
            <ResultTitle variant="caption" color="textSecondary">
              EPC
            </ResultTitle>
            <CountCell
              variant="absolute-numbers"
              before={getEpc(analytics.clicked.lastCount, earnings.lastCount)}
              after={getEpc(analytics.clicked.count, earnings.count)}
              currency={currency}
              compare={true}
            />
          </Result>
        </ResultsWrapper>
      )}
    </SearchResultsPaper>
  );
};
