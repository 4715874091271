import {
  AnalyticsColumnTransformer,
  AnalyticsFilter,
  AnalyticsFilterIn,
  AnalyticsFilterLikeAny
} from '../domainTypes/analytics_v2';
import { Group } from '../domainTypes/referrers';

export const GOOGLE_DISCOVER_ORIGN = 'com.google.android.googlequicksearchbox';

const GOOGLE_DISCOVER_FILTER_CLAUSE: AnalyticsFilterIn = {
  field: 'referrer_entry_origin',
  condition: 'in',
  values: [GOOGLE_DISCOVER_ORIGN]
};

const DIRECT_FILTER_CLAUSE: AnalyticsFilterIn = {
  field: 'referrer_entry_origin',
  condition: 'in',
  values: ['']
};
const internalFilterClause = (domains: string[]): AnalyticsFilterIn => {
  return {
    field: 'referrer_entry_origin',
    condition: 'in',
    values: domains
  };
};
const SEARCH_FILTER_CLAUSE: AnalyticsFilterLikeAny = {
  field: 'referrer_entry_origin',
  condition: 'ilike any',
  patterns: [
    '%.google.%',
    'google.%',
    '%.yahoo.%',
    'yahoo.%',
    '%.yandex.%',
    'yandex.%',
    '%.duckduckgo.%',
    'duckduckgo.%',
    '%.bing.%',
    'bing.%',
    '%.ecosia.%',
    'ecosia.%',
    '%search.%',
    'msn.com'
  ]
};
const SOCIAL_FILTER_CLAUSE: AnalyticsFilterLikeAny = {
  field: 'referrer_entry_origin',
  condition: 'ilike any',
  patterns: [
    'likeshop.me',
    '%.linkedin.%',
    'linkedin.%',
    'com.pinterest',
    '%.pinterest.%',
    'pinterest.%',
    '%.flipboard.%',
    'flipboard.%',
    't.co',
    '%.youtube.%',
    'youtube.%',
    '%.reddit.%',
    'reddit.%',
    '%.facebook.%',
    'facebook.%',
    '%.instagram.%',
    'instagram.%'
  ]
};
export const groupFilterClause = (
  group: Group,
  domains: string[]
): Array<AnalyticsFilter> => {
  switch (group) {
    case 'google-discover':
      return [GOOGLE_DISCOVER_FILTER_CLAUSE];
    case 'internal':
      return [internalFilterClause(domains)];
    case 'other':
      return [
        {
          field: 'referrer_entry_origin',
          condition: 'not ilike any',
          patterns: [
            ...SOCIAL_FILTER_CLAUSE.patterns,
            ...SEARCH_FILTER_CLAUSE.patterns
          ]
        },
        {
          field: 'referrer_entry_origin',
          condition: 'not in',
          values: [...domains, '', ...GOOGLE_DISCOVER_FILTER_CLAUSE.values]
        }
      ];
    case 'search':
      return [
        SEARCH_FILTER_CLAUSE,
        {
          field: 'referrer_entry_origin',
          condition: 'not in',
          values: GOOGLE_DISCOVER_FILTER_CLAUSE.values
        }
      ];
    case 'social':
      return [SOCIAL_FILTER_CLAUSE];
    case 'direct':
      return [DIRECT_FILTER_CLAUSE];
  }
};
export const groupColumnTransformers = (
  allDomains: string[]
): AnalyticsColumnTransformer[] => {
  return [
    {
      // NOTE: Order is important! First google-discover, then search, because search has "%.google.%" pattern which matches google-discover value!
      ...GOOGLE_DISCOVER_FILTER_CLAUSE,
      mapTo: 'google-discover'
    },
    {
      ...SEARCH_FILTER_CLAUSE,
      mapTo: 'search'
    },
    {
      field: 'referrer_entry_origin',
      condition: 'in',
      values: allDomains,
      mapTo: 'internal'
    },
    {
      ...SOCIAL_FILTER_CLAUSE,
      mapTo: 'social'
    },
    {
      ...DIRECT_FILTER_CLAUSE,
      mapTo: 'direct'
    },
    {
      field: 'referrer_entry_origin',
      condition: 'ilike',
      pattern: '%',
      mapTo: 'other'
    }
  ];
};
