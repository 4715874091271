import { useEffect, useState } from 'react';

export const useMediaQuery = (query: string) => {
  const matcher = window.matchMedia(query);
  const [state, setState] = useState({
    matcher,
    match: matcher.matches
  });

  const listen = (event: MediaQueryListEvent) => {
    setState({ ...state, match: event.matches });
  };

  useEffect(() => {
    state.matcher.addListener(listen);
    return () => state.matcher.removeListener(listen);
    // eslint-disable-next-line
  }, [state.matcher]);

  useEffect(() => {
    const nextMatcher = window.matchMedia(query);
    setState({ matcher: nextMatcher, match: nextMatcher.matches });
  }, [query]);

  return state.match;
};
