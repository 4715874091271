import React from 'react';
import { Doc } from '../../../../domainTypes/document';
import {
  Notification,
  NotificationType,
  ReleaseNotification,
  ScanNewResults,
  TestDummy,
  LinkCheckNewIssues,
  DownloadExportNotification
} from '../../../../domainTypes/notifications';
import { Release_ExtensionKlook } from './Release_ExtensionKlook';
import { Release_Notifications } from './Release_Notifications';
import { Release_AffiliateProgram } from './Release_AffiliateProgram';
import { Scan_NewResults } from './Scan_NewResults';
import { Test_Dummy } from './Test_Dummy';
import { Release_AvantLinkIntegration } from './Release_AvantLinkIntegration';
import { Release_PepperjamIntegration } from './Release_PepperjamIntegration';
import { Release_LabelRules } from './Release_LabelRules';
import { LinkCheck_NewIssues } from './LinkCheck_NewIssues';
import { Download_Export } from './Download_Export';

const withType = <T extends Notification>(
  component: React.ComponentType<{ n: Doc<T> }>
) => ({ n }: { n: Doc<Notification> }) =>
  React.createElement(component, { n: n as Doc<T> });

export const RENDERERS: {
  [K in NotificationType]: React.ComponentType<{
    n: Doc<Notification>;
  }> | null;
} = {
  Download_Export: withType<DownloadExportNotification>(Download_Export),
  LinkCheck_NewIssues: withType<LinkCheckNewIssues>(LinkCheck_NewIssues),
  Release_AffiliateProgram: withType<ReleaseNotification>(
    Release_AffiliateProgram
  ),
  Release_AvantLinkIntegration: withType<ReleaseNotification>(
    Release_AvantLinkIntegration
  ),
  Release_PepperjamIntegration: withType<ReleaseNotification>(
    Release_PepperjamIntegration
  ),
  Release_ExtensionKlook: withType<ReleaseNotification>(Release_ExtensionKlook),
  Release_LabelRules: withType<ReleaseNotification>(Release_LabelRules),
  Release_Notifications: withType<ReleaseNotification>(Release_Notifications),
  Scan_NewResults: withType<ScanNewResults>(Scan_NewResults),
  Test_Dummy: withType<TestDummy>(Test_Dummy)
};
