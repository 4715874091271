import { ICampaign } from '../../../../domainTypes/campaigns';
import { CampaignDetails } from './form-types';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { toMoment } from '../../../../services/time';
import { css } from '../../../../emotion';
import { FieldContainer } from './form-components';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  InputLabel,
  TextField,
  Typography
} from '@material-ui/core';
import { required } from '../../../../components/Form/validators';
import { AdvertiserSelector } from '../../../../components/AdvertisersSelector';
import { AutocompleteUserMultiById } from '../../../../components/AutocompleteUserMulti';
import { CampaignGoalsSelector } from './CampaignGoalsSelector';
import { CampaignIncentivesSelector } from './CampaignIncentivesSelector';
import { DateRangePicker } from '../../../../components/DateRangePicker';

interface CampaignDetailsFormProps {
  campaign: ICampaign;
  proceed: (details: CampaignDetails, campaign: ICampaign) => void;
}

export const CampaignDetailsForm: React.FC<CampaignDetailsFormProps> = ({
  campaign,
  proceed
}) => {
  return (
    <Card>
      <Form
        onSubmit={(details) => proceed(details, campaign)}
        initialValues={{
          name: campaign.name,
          incentives: campaign.incentives ?? [],
          goals: campaign.goals ?? [],
          advertisers: campaign.advertisers,
          managers: campaign.managers,
          timeframe: campaign.timeframe
            ? {
                start: toMoment(campaign.timeframe.start),
                end: toMoment(campaign.timeframe.end)
              }
            : null
        }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <CardContent
                className={css(() => ({
                  height: 'calc(70vh)',
                  overflowY: 'auto',
                  marginBottom: 'auto'
                }))}
              >
                <div style={{ maxWidth: '80%', minWidth: '500px' }}>
                  <Typography variant="h6" component="p">
                    <strong>Define your campaign</strong>
                  </Typography>
                  <Typography variant="body2" component="p" paragraph>
                    Fill in the details of your campaign. You can always come
                    back and edit them later.
                  </Typography>
                  <div
                    className={css((t) => ({
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      columnGap: t.spacing(2),
                      rowGap: t.spacing(4)
                    }))}
                  >
                    <FieldContainer>
                      <InputLabel>Campaign name</InputLabel>
                      <Typography
                        variant="body2"
                        component="p"
                        paragraph
                        color="textSecondary"
                      >
                        Give your campaign a concise name that will help you and
                        your team identify it.
                      </Typography>
                      <Field name="name" validate={required}>
                        {({ input, meta }) => {
                          const hasError = meta.error && meta.touched;
                          return (
                            <TextField
                              name={input.name}
                              value={input.value}
                              onChange={input.onChange}
                              error={hasError}
                              helperText={
                                hasError &&
                                'Please provide a name for your campaign.'
                              }
                              onBlur={input.onBlur}
                              placeholder="Example Campaign Q4 2024"
                              onFocus={input.onFocus}
                              required
                              autoFocus
                              variant="outlined"
                              fullWidth
                            />
                          );
                        }}
                      </Field>
                    </FieldContainer>
                    <FieldContainer>
                      <InputLabel>Advertisers</InputLabel>
                      <Field name="advertisers">
                        {({ input }) => (
                          <AdvertiserSelector
                            value={input.value}
                            onChange={input.onChange}
                          />
                        )}
                      </Field>
                    </FieldContainer>
                    <FieldContainer>
                      <Field name="incentives">
                        {({ input }) => {
                          return (
                            <CampaignIncentivesSelector
                              value={input.value}
                              onChange={input.onChange}
                            />
                          );
                        }}
                      </Field>
                    </FieldContainer>
                    <FieldContainer>
                      <Field name="goals">
                        {({ input }) => {
                          return (
                            <CampaignGoalsSelector
                              value={input.value}
                              onChange={input.onChange}
                            />
                          );
                        }}
                      </Field>
                    </FieldContainer>
                    <FieldContainer>
                      <InputLabel>Campaign managers</InputLabel>
                      <Typography
                        variant="body2"
                        component="p"
                        paragraph
                        color="textSecondary"
                      >
                        Who is responsible for this campaign? Select one or more
                        users from your team.
                      </Typography>
                      <Field name="managers">
                        {({ input }) => (
                          <AutocompleteUserMultiById
                            spaceId={campaign.spaceId}
                            value={input.value}
                            onChange={input.onChange}
                            fullWidth
                          />
                        )}
                      </Field>
                    </FieldContainer>
                    <FieldContainer>
                      <InputLabel htmlFor="timeframe">Timeframe</InputLabel>
                      <Typography
                        variant="body2"
                        component="p"
                        paragraph
                        color="textSecondary"
                      >
                        Set the start and end date of your campaign. You can
                        skip this if you don't know yet.
                      </Typography>
                      <Field name="timeframe">
                        {({ input }) => (
                          <DateRangePicker
                            value={input.value}
                            onChange={input.onChange}
                          />
                        )}
                      </Field>
                    </FieldContainer>
                  </div>
                </div>
              </CardContent>
              <CardActions>
                <div
                  className={css(() => ({
                    gridColumn: '2 / 3',
                    textAlign: 'right'
                  }))}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={submitting}
                  >
                    Save and continue
                  </Button>
                </div>
              </CardActions>
            </form>
          );
        }}
      />
    </Card>
  );
};
