import { omit, sum } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Label, Text } from 'recharts';
import {
  AnalyticsFilter,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison,
  AnalyticsSearch
} from '../../../domainTypes/analytics_v2';
import { CurrencyCode } from '../../../domainTypes/currency';
import { EMPTY_ARR } from '../../../domainTypes/emptyConstants';
import { ISpace } from '../../../domainTypes/space';
import { styled } from '../../../emotion';
import {
  AnalyticsGroup,
  AnalyticsGroupContainer
} from '../../../services/analyticsV2/groups';
import {
  formatMetric,
  metricTitle
} from '../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../services/analyticsV2/query';
import { useColorUnknown } from '../../../services/color';
import {
  useCombineLoadingValues,
  useMappedLoadingValue
} from '../../../services/db';
import { ISOTimeRange } from '../../../services/time';
import { useTheme } from '../../../themes';
import { Loader } from '../../Loader';
import {
  ChartCard,
  ChartCardFooter,
  ChartCardFooterBar,
  ChartCardFooterSelectTopX
} from '../ChartCard';
import { TooltipRow } from '../CustomTooltip';
import { IPieChartCell, PieChartWithLegend } from '../PieChartWithLegend';

export type EarningsPieChartMetric =
  | 'c'
  | 'commission_sum_net'
  | 'quantity_net'
  | 'gmv_sum_net';

const METRICS: EarningsPieChartMetric[] = [
  'c',
  'commission_sum_net',
  'quantity_net',
  'gmv_sum_net'
];

export type EarningsPieChartValues = Partial<{
  c: number;
  commission_sum_net: number;
  quantity_net: number;
  gmv_sum_net: number;
}>;

export type EarningsPieChartData = {
  container: AnalyticsGroupContainer;
  values: EarningsPieChartValues;
};

const HEIGHT = 390;

const FormattedLabel = styled('div')`
  padding: 0;
  white-space: nowrap;
`;

export type EarningsPieChartV2Props = {
  data: EarningsPieChartData[] | void;
  // passing in totals will show the total number and also add an
  // Other pie.
  totals?: EarningsPieChartValues | void;
  loading: boolean;
  currency: CurrencyCode;
  animate?: boolean;
  height?: number;
  aspect?: number;
  metric?: EarningsPieChartMetric;
  hideLegend?: boolean;
  hideCenterLabel?: boolean;
  hideOther?: boolean;
};

export const EarningsPieChartV2 = React.memo(
  ({
    data,
    totals,
    loading,
    currency,
    animate,
    height = HEIGHT,
    aspect = 1.5,
    metric = 'commission_sum_net',
    hideLegend = false,
    hideCenterLabel = false,
    hideOther = false
  }: EarningsPieChartV2Props) => {
    const theme = useTheme();
    const colorUnknown = useColorUnknown();

    if (loading || !data) {
      return <Loader height={height} />;
    }

    const getVal = (x: EarningsPieChartValues) => x[metric] || 0;
    const formatter = (x: number) =>
      formatMetric(x, metric, currency, { verbose: true });
    const centerLabel = metricTitle(metric);

    const sortedData = data.map<IPieChartCell>((d) => ({
      key: d.container.key,
      name: d.container.label,
      count: getVal(d.values),
      color: d.container.color
    }));

    if (totals && !hideOther) {
      const count = getVal(totals) - sum(sortedData.map((d) => d.count));
      // Only push if there is something to show
      if (count > 0) {
        sortedData.push({
          key: 'other',
          name: 'Other',
          color: colorUnknown,
          count
        });
      }
    }

    return (
      <PieChartWithLegend
        data={sortedData}
        animate={animate}
        aspect={aspect}
        hideLegend={hideLegend}
        extra={
          !hideCenterLabel && (
            <Label
              content={(props) => {
                const {
                  viewBox: { cx, cy }
                } = props;
                return (
                  <>
                    <Text
                      x={cx}
                      y={cy - 20}
                      textAnchor="middle"
                      verticalAnchor="middle"
                      style={{
                        fontSize: theme.custom.fontSize.m,
                        fill: theme.palette.grey[700]
                      }}
                    >
                      {centerLabel}
                    </Text>
                    {totals && (
                      <Text
                        x={cx}
                        y={cy + 5}
                        textAnchor="middle"
                        verticalAnchor="middle"
                        style={{ fontSize: theme.custom.fontSize.l }}
                      >
                        {formatter(getVal(totals))}
                      </Text>
                    )}
                  </>
                );
              }}
            />
          )
        }
        formatter={(value, name) => {
          return (
            <FormattedLabel>
              <TooltipRow>
                <div>{name}</div>
                <div>{formatter(value as number)}</div>
              </TooltipRow>
            </FormattedLabel>
          );
        }}
      />
    );
  }
);

export const EarningsPieChartWithoutDataV2 = ({
  space,
  range,
  filters = EMPTY_ARR,
  search = EMPTY_ARR,
  metric,
  top,
  grouper,
  ...props
}: {
  space: ISpace;
  range: ISOTimeRange;
  filters?: AnalyticsFilter[];
  search?: AnalyticsSearch[];
  metric: EarningsPieChartMetric;
  top: number;
  grouper: AnalyticsGroup;
} & Omit<EarningsPieChartV2Props, 'data' | 'totals' | 'loading'>) => {
  const { groupBy, toContainer, columnTransformers } = grouper;
  const q: AnalyticsQuery = useMemo(
    () => ({
      range,
      select:
        metric === 'c'
          ? METRICS.filter((m) => m === 'c')
          : METRICS.filter((m) => m !== 'c'),
      filters,
      search,
      groupBy,
      columnTransformers: columnTransformers(space),
      orderBy: [{ field: metric, direction: 'DESC' }],
      paginate: {
        page: 1,
        limit: top
      }
    }),
    [range, filters, search, groupBy, top, metric, columnTransformers, space]
  );
  const totalsQ: AnalyticsQuery = useMemo(() => {
    return omit(q, ['groupBy', 'orderBy', 'paginate']);
  }, [q]);

  const getValues = (
    row: AnalyticsResponseRowWithComparison | undefined
  ): EarningsPieChartValues => ({
    c: row?.data.c?.curr || 0,
    commission_sum_net: row?.data.commission_sum_net?.curr || 0,
    quantity_net: row?.data.quantity_net?.curr || 0,
    gmv_sum_net: row?.data.gmv_sum_net?.curr || 0
  });

  const [data, loading] = useMappedLoadingValue(
    useCombineLoadingValues(
      useAnalyticsQueryV2(space.id, q),
      useAnalyticsQueryV2(space.id, totalsQ)
    ),
    ([rows, totals]): {
      rows: EarningsPieChartData[];
      totals: EarningsPieChartValues;
    } => {
      return {
        rows: rows.rows.map((row) => ({
          container: toContainer(row),
          values: getValues(row)
        })),
        totals: getValues(totals.rows[0])
      };
    }
  );

  return (
    <EarningsPieChartV2
      data={data && data.rows}
      totals={data && data.totals}
      loading={loading}
      metric={metric}
      {...props}
    />
  );
};

export const EarningsPieChartCardV2: React.FC<
  {
    space: ISpace;
    range: ISOTimeRange;
    currency: CurrencyCode;
    metric: EarningsPieChartMetric;
    filters?: AnalyticsFilter[];
    search?: AnalyticsSearch[];
    grouper: AnalyticsGroup;
    heading: string;
    subheading: string;
    style?: React.CSSProperties;
    //linkToPartnerOverview?: boolean;
  } & Omit<EarningsPieChartV2Props, 'data' | 'totals' | 'loading'>
> = ({
  space,
  range,
  currency,
  filters,
  search,
  grouper,
  metric = 'commission_sum_net',
  heading,
  subheading,
  style,
  ...otherProps
}) => {
  const [count, setCount] = useState(10);

  return (
    <ChartCard
      heading={heading}
      subheading={subheading}
      style={style}
      size="small"
      padding="dense"
    >
      <EarningsPieChartWithoutDataV2
        space={space}
        range={range}
        currency={currency}
        filters={filters}
        search={search}
        metric={metric}
        top={count}
        grouper={grouper}
        {...otherProps}
      />

      <ChartCardFooter>
        <ChartCardFooterBar>
          <ChartCardFooterSelectTopX
            value={count}
            onChange={setCount}
            step={5}
            max={20}
          />
        </ChartCardFooterBar>
      </ChartCardFooter>
    </ChartCard>
  );
};
