import { compact, trim } from 'lodash';
import moment from 'moment-timezone';
import { CurrencyCode } from '../../../../../domainTypes/currency';
import { getTrackingId, ISale } from '../../../../../domainTypes/performance';
import { getExchangeRates } from '../../../../../services/currency';
import { getKnownPartnerForKeyUnsafe } from '../../../../../services/partner';
import { getActiveDomainUrls } from '../../../../../services/space';
import { fromMoment } from '../../../../../services/time';
import { toDomain, withoutProtocolOrWww } from '../../../../../services/url';
import { addDevice, toCents } from '../helpers';
import { IFileReportHandler } from '../types';

const toStatus = (action: string) => {
  if (action === 'Payment') {
    return 'Final';
  }
  if (action === 'Refund') {
    return 'Refunded';
  }
  return 'Unknown';
};

type KlookTransaction = {
  'Order ID': string;
  'Ticket ID': string;
  'Booking Number': string;
  Action: string;
  'Action Date': string;
  'Action Time': string;
  'Participation Date': string;
  'Participation Time': string;
  Participants: string;
  'Sales Amount': string;
  'Commission Rate': string;
  'Commission Amount': string;
  AID: string;
  ADID: string;
  'Website Name(English)': string;
  'Partner Params': string;
  'Activity ID': string;
  'Activity Name': string;
  Destination: string;
  'Package Name': string;
  'Activity Category': string;
  Platform: string;
  'Promo Code Type': string;
  'Kreator Promo Code': undefined;
  'Supply Category 01': string;
  'Supply Category 02': string;
  'Supply Category 03': string;
};

type KlookKreatorTransaction = {
  'Order ID': string;
  'Ticket ID': string;
  'Booking Number': string;
  Action: string;
  'Action Date': string;
  'Action Time': string;
  'Participation Date': string;
  'Participation Time': string;
  Participants: string;
  'Sales Amount': string;
  'Commission Rate': string;
  'Commission Amount': string;
  'Tracking base': string;
  AID: string;
  ADID: string;
  'Website Name(English)': string;
  'Partner Params': string;
  'Activity ID': string;
  'Activity Name': string;
  Destination: string;
  'Package Name': string;
  'Activity Category': string;
  Platform: string;
  'Promo Code Type': string;
  'Kreator Promo Code': string;
  'Supply Category 01': string;
  'Supply Category 02': string;
  'Supply Category 03': string;
};

const exchangeDateFormat = 'YYYY-MM-DD';
const dateFormat = 'YYYYMMDD';
const dateTimeFormat = `${dateFormat} HH:mm:ss`;
const participationDateFormat = 'YYYY-MM-DD HH:mm:ss';

export const KLOOK: IFileReportHandler<string> = {
  type: 'CSV',
  partnerKey: 'klook',
  parser: {
    name: 'Klook report',
    csvHeaders:
      'Order ID,Ticket ID,Booking Number,Action,Action Date,Action Time,Participation Date,Participation Time,Participants,Sales Amount,Commission Rate,Commission Amount',
    matches: (text, expectedHeaders) => text.indexOf(expectedHeaders) === 0,
    processRows: async (
      rows,
      { space, partnerKey, reportId, integrationId }
    ) => {
      const tz = 'UTC';

      // Use papa parse to treat these rows as either a Klook or Klook Kreator report
      const [headerRow] = rows;
      const isKreator = headerRow.includes('Kreator Promo Code');

      // Because file contains a \n at the end
      const dataRows = rows.slice(1, rows.length).sort((a, b) => {
        const date = moment.tz(a[4], dateFormat, tz);
        const otherDate = moment.tz(b[4], dateFormat, tz);
        // The file is not sorted by date when there are refunds
        return date.isSameOrBefore(otherDate) ? 1 : -1;
      });

      const [, , , , endReportDate] = dataRows[0];
      const finalIndex = dataRows.length - 1 > 0 ? dataRows.length - 1 : 0;
      const [, , , , startReportDate] = dataRows[finalIndex];

      const TODAY = moment.tz(tz).format(exchangeDateFormat);
      const YESTERDAY = moment
        .tz(tz)
        .subtract(1, 'day')
        .format(exchangeDateFormat);

      const startDateMoment = moment.tz(startReportDate, dateFormat, tz);
      const endDateMoment = moment.tz(endReportDate, dateFormat, tz);
      const dateDiff = startDateMoment.diff(endDateMoment, 'days');

      // Assure we get at least 5 days of data in case there are gaps
      if (dateDiff < 5) {
        startDateMoment.subtract(5, 'days');
      }

      const startDate = startDateMoment.format(exchangeDateFormat);
      const endDate = endDateMoment.format(exchangeDateFormat);
      const [, , , , , , , , , , , firstCommission] = dataRows[0];
      const publisherCurrency: CurrencyCode = firstCommission.substr(0, 3);

      const exchangeRates = await getExchangeRates(
        publisherCurrency,
        startDate,
        endDate
      );

      return compact(
        dataRows.map((row) => {
          if (!row.length) {
            return undefined;
          }

          let [
            c1,
            c2,
            c3,
            c4,
            c5,
            c6,
            c7,
            c8,
            c9,
            c10,
            c11,
            c12,
            c13,
            c14,
            c15,
            c16,
            c17,
            c18,
            c19,
            c20,
            c21,
            c22,
            c23,
            c24,
            c25,
            c26,
            c27,
            c28
          ] = row;

          const r = isKreator
            ? ({
                'Order ID': c1,
                'Ticket ID': c2,
                'Booking Number': c3,
                Action: c4,
                'Action Date': c5,
                'Action Time': c6,
                'Participation Date': c7,
                'Participation Time': c8,
                Participants: c9,
                'Sales Amount': c10,
                'Commission Rate': c11,
                'Commission Amount': c12,
                'Tracking base': c13,
                AID: c14,
                ADID: c15,
                'Website Name(English)': c16,
                'Partner Params': c17,
                'Activity ID': c18,
                'Activity Name': c19,
                Destination: c20,
                'Package Name': c21,
                'Activity Category': c22,
                Platform: c23,
                'Promo Code Type': c24,
                'Kreator Promo Code': c25,
                'Supply Category 01': c26,
                'Supply Category 02': c27,
                'Supply Category 03': c28
              } as KlookKreatorTransaction)
            : ({
                'Order ID': c1,
                'Ticket ID': c2,
                'Booking Number': c3,
                Action: c4,
                'Action Date': c5,
                'Action Time': c6,
                'Participation Date': c7,
                'Participation Time': c8,
                Participants: c9,
                'Sales Amount': c10,
                'Commission Rate': c11,
                'Commission Amount': c12,
                AID: c13,
                ADID: c14,
                'Website Name(English)': c15,
                'Partner Params': c16,
                'Activity ID': c17,
                'Activity Name': c18,
                Destination: c19,
                'Package Name': c20,
                'Activity Category': c21,
                Platform: c22,
                'Promo Code Type': c23,
                'Supply Category 01': c24,
                'Supply Category 02': c25,
                'Supply Category 03': c26
              } as KlookTransaction);

          const trackingLabel = r['Partner Params'];
          const platform = r.Platform;
          const trackingId = getTrackingId(trackingLabel, space);
          const status = toStatus(r.Action);
          const saleDate = moment
            .tz(r['Action Date'], dateFormat, tz)
            .format(exchangeDateFormat);

          const saleDateTime = moment.tz(
            `${r['Action Date']} ${r['Action Time']}`,
            dateTimeFormat,
            tz
          );
          const completionDate = moment.tz(
            `${r['Participation Date']} ${r['Participation Time']}`,
            participationDateFormat,
            tz
          );

          const multiplier = status === 'Refunded' ? -1 : 1;
          const originalCurrency = r['Sales Amount'].substr(
            0,
            3
          ) as CurrencyCode;

          const exchangeDate = saleDate === TODAY ? YESTERDAY : saleDate;
          const dayRates = exchangeRates.rates[exchangeDate];

          if (!dayRates) {
            console.error(
              `No exchange rates for ${exchangeDate} in ${publisherCurrency}`,
              exchangeRates.rates
            );
          }

          const rate = dayRates[originalCurrency];

          if (!rate) {
            console.error(
              `No exchange rate for ${originalCurrency} in ${publisherCurrency} on ${exchangeDate}`,
              dayRates
            );
          }

          const amountOriginalCurrency = r['Sales Amount'].substr(3);
          const originalAmountForeignCurrency = toCents(
            trim(r['Sales Amount'].substr(3, amountOriginalCurrency.length))
          );

          const originalPriceInUsd = originalAmountForeignCurrency / rate;
          const price = originalPriceInUsd * multiplier;

          const websiteName = r['Website Name(English)'];
          const website = websiteName.match(/\((.+)\)/);
          const sites = getActiveDomainUrls(space, false);

          let origin = website
            ? toDomain(
                website[1].indexOf('http') === 0
                  ? website[1].replace('%28', '').replace('%29', '')
                  : `https://${website[1]
                      .replace('%28', '')
                      .replace('%29', '')}`
              )
            : null;

          // In case origin matches existing space but without www, use that
          sites.forEach((site) => {
            if (
              origin &&
              withoutProtocolOrWww(site) === withoutProtocolOrWww(origin)
            ) {
              origin = site;
            }
          });

          const shareAmountUsd = r['Commission Amount'];
          const _commissionPercent = r['Commission Rate'];
          let saleId = r['Ticket ID'];
          const commission =
            toCents(shareAmountUsd.replace(`${publisherCurrency} `, '')) *
            multiplier;

          const commissionPercent =
            parseFloat(_commissionPercent.replace('%', '')) / 100;

          if (status === 'Refunded') {
            saleId = `${saleId}-Refund`;
          }

          const sale: ISale = {
            saleId,
            orderId: r['Order ID'],
            trackingId,
            trackingLabel,
            reportId,
            integrationId,
            origin,
            saleDate: fromMoment(saleDateTime),
            completionDate: fromMoment(completionDate),
            status,
            partnerKey,
            partnerProductName: r['Activity Name'],
            partnerProductId: r['Activity ID'],
            amount: {
              currency: publisherCurrency,
              price,
              revenue: null,
              commission
            },
            payoutId: null,
            payoutDate: null,
            payoutStatus: null,
            lastModified: null,
            saleType: 'cpa',
            commissionPercent,
            advertiserId: partnerKey,
            advertiserName: getKnownPartnerForKeyUnsafe(partnerKey).name
          };

          if (r['Kreator Promo Code']) {
            sale.coupon = r['Kreator Promo Code'];
          }

          addDevice(sale, platform, {
            web: 'desktop',
            mobile: 'mobile',
            ios: 'mobile',
            android: 'mobile'
          });
          return sale;
        })
      );
    }
  }
};
