import { EMPTY_COUNTER, ICounter } from './analytics';
export const HOURKEY_FORMAT = 'YYYYDDDDHH';

export interface ITrackingStatsHourly {
  hourKey: string;
  total: ICounter;
  bySpace: { [spaceId: string]: ICounter };
}

export interface ITrackingStatsDaily {
  timeKey: string;
  total: ICounter;
  bySpace: { [spaceId: string]: ICounter };
}

export const hourKeyToTimeKey = (hourKey: string) => hourKey.slice(0, 7);

export type HourlyCounter = {
  hourKey: string;
  counts: ICounter;
};

export const EMPTY_HOURLY_COUNTER = (hourKey: string) => ({
  hourKey,
  counts: EMPTY_COUNTER()
});

export type TrackingStatsHourlyBySpaceResponse = {
  spaceId: string;
  range: { start: number; end: number };
  data: {
    hourKey: string;
    counts: ICounter;
  }[];
};
