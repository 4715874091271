import {
  CustomDimensionName,
  slotType
} from '../../../../../domainTypes/customDimensions';
import { InternalId } from '../../../../../components/ConnectionId';
import { FlexContainer } from '../../../../../layout/Flex';
import React from 'react';
import { MousePointer, Users } from 'react-feather';

const SlotIcon = ({ slotType }: { slotType: 'click' | 'page' }) => {
  switch (slotType) {
    case 'page':
      return <Users size={10} />;
    case 'click':
      return <MousePointer size={10} />;
  }
};

export const SlotBadge = ({ slot }: { slot: CustomDimensionName }) => {
  return (
    <InternalId>
      <FlexContainer alignItems="center">
        <SlotIcon slotType={slotType(slot)} />
        <span>{slot}</span>
      </FlexContainer>
    </InternalId>
  );
};
