import { Typography } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { COLORS } from '../../../domainTypes/colors';
import { styled } from '../../../emotion';
import { LimitedWidth } from '../../../layout/PageBody';
import { ContentPageBody } from '../../Content/components/ContentPageBody';
import { RecommendationsCard } from '../components/RecommendationsCard';
import { Recommendation } from '../types';

const RecommendationsGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing(2)}px;
  grid-row-gap: ${({ theme }) => theme.spacing(2)}px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  strong {
    color: ${({ theme }) => theme.palette.grey[800]};
  }
`;

const RECOMMENDATIONS: Recommendation[] = [
  {
    id: '5',
    status: 'active',
    category: 'ARTICLE_REFRESH',
    spaceId: '1',
    title: 'Best (And Affordable!) Places to Stay in Banff, Canada',
    description: (
      <>
        Last July, this was in your top 5 articles by earnings. Today it{' '}
        <strong>receives 20% of the pageviews it used to</strong>. Refresh the
        article to improve its ranking.
      </>
    ),
    estimatedEarnings: 522000,
    url:
      'https://app.affilimate.com/content/trends?spaceId=4oF26UAIvKcsv6YOxO7gtvrifrn2&url=https%3A%2F%2Fnotanomadblog.com%2Fwhere-to-stay-in-banff',
    createdAt: '2024-07-13',
    updatedAt: '2021-01-01',
    deletedAt: ''
  },
  {
    id: '3',
    status: 'active',
    category: 'MERCHANT_BOOST',
    spaceId: '1',
    title: 'GetYourGuide Content Partnerships',
    description: (
      <>
        This advertiser is responsible for 10% of your earnings. You are
        promoting them directly (6%) but they have a{' '}
        <strong>higher rate on Awin</strong> (7%).
      </>
    ),
    estimatedEarnings: 493000,
    url:
      'https://app.affilimate.com/performance-new/advertisers/?advertisers=GetYourGuide---GetYourGuide%20%28US%29%20%E2%80%93%20Content%20Partnerships&sale_type=bonus%2Ccpa%2Ccpc%2Ccpl%2Cunknown&spaceId=4oF26UAIvKcsv6YOxO7gtvrifrn2',
    createdAt: '2024-07-13',
    updatedAt: '2021-01-01',
    deletedAt: ''
  },
  {
    id: '1',
    status: 'active',
    category: 'ARTICLE_REFRESH',
    spaceId: '1',
    title: '50 Amazing Things to Do on the Big Island of Hawaii (2024)',
    description: (
      <>
        Last July, this was your top article by earnings. But today, it receives{' '}
        <strong>only 10% of the pageviews it used to</strong>. Refresh the
        article to improve its ranking.
      </>
    ),
    estimatedEarnings: 369000,
    url:
      'https://app.affilimate.com/content/trends?spaceId=4oF26UAIvKcsv6YOxO7gtvrifrn2&url=https%3A%2F%2Fnotanomadblog.com%2Fthings-to-do-big-island-activities',
    createdAt: '2024-07-13',
    updatedAt: '2021-01-01',
    deletedAt: ''
  },
  {
    id: '4',
    status: 'active',
    category: 'REPLACE_LINK',
    spaceId: '1',
    title: '50 Amazing Things to Do on the Big Island of Hawaii (2024)',
    description: (
      <>
        The first link in this article{' '}
        <strong>hasn't converted in the last 30 days</strong>. Consider updating
        the link to a newer version of this product or updating the retailer.
      </>
    ),
    estimatedEarnings: 132000,
    url:
      'https://app.affilimate.com/content/heatmap?spaceId=4oF26UAIvKcsv6YOxO7gtvrifrn2&url=https%3A%2F%2Fnotanomadblog.com%2Fthings-to-do-big-island-activities',
    createdAt: '2024-07-13',
    updatedAt: '2021-01-01',
    deletedAt: ''
  },
  {
    id: '2',
    status: 'active',
    category: 'AMPLIFY_PRODUCT',
    spaceId: '1',
    title: 'Nikon 7072 Lens Pen Cleaning System Black',
    description: (
      <>
        This is your most popular product by earnings. Consider{' '}
        <strong>adding it to these relevant articles</strong>:
        <ul style={{ margin: '3px 0 0', padding: '0 16px' }}>
          <li style={{ color: COLORS.blue.blue6 }}>
            /top-10-camera-accessories
          </li>
          <li style={{ color: COLORS.blue.blue6 }}>
            /best-cameras-for-travel-photography
          </li>
        </ul>
      </>
    ),
    estimatedEarnings: 54733,
    url: 'https://www.amazon.com/Nikon-7072-Lens-Cleaning-System/dp/B00006JN3G',
    createdAt: '2024-07-13',
    updatedAt: '2021-01-01',
    deletedAt: ''
  },
  {
    id: '5',
    status: 'active',
    category: 'IN_PAGE_OPTIMIZATION',
    spaceId: '1',
    title: 'Locals Guide: Where to Stay in Berlin, Germany',
    description: (
      <>
        Your first affiliate link in this article is{' '}
        <strong>driving conversions</strong>, but is only being seen by 43% of
        visitors. Increase visibility to boost CTR and potential earnings.
      </>
    ),
    estimatedEarnings: 36908,
    url:
      'https://app.affilimate.com/content/heatmap?spaceId=4oF26UAIvKcsv6YOxO7gtvrifrn2&url=https%3A%2F%2Fnotanomadblog.com%2Fwhere-to-stay-in-berlin-germany',
    createdAt: '2024-07-13',
    updatedAt: '2021-01-01',
    deletedAt: ''
  }
];

const RecommendationsBody = () => {
  return (
    <div>
      <LimitedWidth width={1000}>
        <Typography variant="h6" paragraph style={{ fontWeight: 'bold' }}>
          Top recommendations
        </Typography>
        <Typography variant="body1" paragraph color="textSecondary">
          These are the highest earning recommendations for your account. Click
          on each to view more details and take action.
        </Typography>
        <RecommendationsGrid>
          {RECOMMENDATIONS.map((recommendation) => (
            <RecommendationsCard
              key={recommendation.id}
              recommendation={recommendation}
            />
          ))}
        </RecommendationsGrid>
      </LimitedWidth>
    </div>
  );
};

export const PageRecommendations = () => {
  // TODO: Permissions check

  return (
    <>
      <Helmet>
        <title>Recommendations | Affilimate</title>
      </Helmet>
      <ContentPageBody>
        <RecommendationsBody />
      </ContentPageBody>
    </>
  );
};
