import { capitalize, sortBy } from 'lodash';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import {
  Bar,
  ComposedChart,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';
import { Timeframe } from '../../../domainTypes/analytics';
import { CurrencyCode } from '../../../domainTypes/currency';
import {
  addOneEarningToAnother,
  EMPTY_EARNING,
  IDailyEarning
} from '../../../domainTypes/performance';
import { formatTimeKey, getTimeKeyRange } from '../../../services/analytics';
import { COLOR_UNKNOWN } from '../../../services/color';
import { getRollingAverage } from '../../../services/math';
import { timeframeToMomentRange } from '../../../services/time';
import { CustomTooltip } from '../CustomTooltip';
import { ChartMode } from '../EarningsChartCard/ChartModeSelector';
import { ITimeBasedCounter } from '../TrafficBiaxialChart';
import { formatChartCurrency, formatChartNumber, WithShape } from '../Util';

const ROLLING_AVERAGE_COLOR = '#b3b3b3';
const CLICKS_COLOR = 'black';

type BarEl = {
  timeKey: string;
  total: number;
  rollingAverage30d: number;
  clicks?: number;
  containers: { [key: string]: number };
};

const formatter = (
  value: number,
  metric: 'earnings' | 'saleValue' | 'quantity' | 'orderCount',
  currency: CurrencyCode
) => {
  switch (metric) {
    case 'earnings':
    case 'saleValue':
      return formatChartCurrency(value, currency, {
        excludeCents: true
      });
    case 'quantity':
    case 'orderCount':
      return formatChartNumber(value);
  }
};

const tooltipFormatter = (
  value: number,
  metric: 'earnings' | 'saleValue' | 'quantity' | 'orderCount',
  name: string,
  containers: Container[],
  currency: CurrencyCode,
  isSmall: boolean
) => {
  const v = formatter(value, metric, currency);

  if (name === 'rollingAverage30d') {
    return [
      v,
      <WithShape color={ROLLING_AVERAGE_COLOR} small={isSmall}>
        Rolling Average
      </WithShape>
    ];
  }

  if (name === 'clicks') {
    return [
      value,
      <WithShape color={CLICKS_COLOR} small={isSmall}>
        Clicks
      </WithShape>,
      { marginTop: 8 }
    ];
  }

  if (name === 'total') {
    return [
      v,
      <WithShape color="none" small={isSmall}>
        <strong>Total</strong>
      </WithShape>,
      { marginTop: 8 }
    ];
  }

  const key = name.substr('containers.'.length, name.length - 1);
  const deserializedKey = deserializeKey(key);
  const container = containers.find((p) => p.key === deserializedKey);
  const containerName = container ? container.label : capitalize(key);

  return [
    v,
    <WithShape color={container?.color || 'none'} small={isSmall}>
      {containerName}
    </WithShape>
  ];
};

const addRollingAverages = (els: BarEl[]): void => {
  const averages = getRollingAverage(
    els.map((el) => el.total),
    30
  );
  averages.forEach((avg, i) => {
    const el = els[i];
    if (el) {
      el.rollingAverage30d = avg;
    }
  });
};

export type Container = {
  key: string;
  color: string;
  label: string;
};

export type Data = {
  container: Container;
  earnings: IDailyEarning[];
};

const sort = (data: Data[]) =>
  sortBy(data, (d) => -d.earnings.reduce((m, e) => e.total + m, 0));

export const limitAndSort = (data: Data[], limit?: number) => {
  if (!data.length) {
    return [];
  }
  const sorted = sort(data);
  if (!limit) {
    return sorted;
  }
  const named = sorted.slice(0, limit);
  const others = sorted.slice(limit);
  if (!others.length) {
    return named;
  }

  const otherEarnings = others.reduce<{
    [timeKey: string]: IDailyEarning;
  }>((m, s) => {
    s.earnings.forEach((e) => {
      const x: IDailyEarning = (m[e.timeKey] = m[e.timeKey] || {
        timeKey: e.timeKey,
        ...EMPTY_EARNING(e.currency)
      });
      addOneEarningToAnother(x, e);
      return m;
    });
    return m;
  }, {});

  return [
    ...named,
    {
      container: {
        key: 'OTHER',
        label: 'Others',
        color: COLOR_UNKNOWN
      },
      earnings: Object.values(otherEarnings)
    }
  ];
};

type Props = {
  data: Data[];
  clickData?: ITimeBasedCounter[];
  currency: CurrencyCode;
  size?: 'small' | 'normal';
  yAxisOrientation?: 'left' | 'right';
  metric?: 'earnings' | 'saleValue' | 'quantity' | 'orderCount';
  height?: number | string;
  timeframe?: Timeframe; // optionally pass this to guarantee a minimum range on the xAxis
  chartMode?: ChartMode;
};

const EMPTY_BAR_EL = (timeKey: string): BarEl => ({
  timeKey: timeKey,
  containers: {},
  total: 0,
  rollingAverage30d: 0
});

const serializeKey = (key: string) => {
  return key.replace(/\./g, '_');
};

const deserializeKey = (key: string) => {
  return key.replace(/_/g, '.');
};

export const EarningsBarChart = ({
  data,
  currency,
  size = 'normal',
  yAxisOrientation = 'left',
  height = 'auto',
  timeframe,
  metric = 'earnings',
  clickData,
  chartMode = 'barChart'
}: Props) => {
  const containers = data.map((d) => d.container);
  const isSmall = size === 'small';

  const tfStart = timeframe?.start;
  const tfEnd = timeframe?.end;
  const tfTz = timeframe?.tz;

  const startsInCurrentYear = useMemo(
    () => tfStart && moment(tfStart).format('YYYY') === moment().format('YYYY'),
    [tfStart]
  );

  const counts = useMemo(() => {
    const byTimeKey = data.reduce<{ [timeKey: string]: BarEl }>(
      (result, el) => {
        const { container, earnings } = el;
        earnings.forEach((e) => {
          const item: BarEl = (result[e.timeKey] =
            result[e.timeKey] || EMPTY_BAR_EL(e.timeKey));

          const count = (() => {
            switch (metric) {
              case 'earnings':
                return e.total;
              case 'saleValue':
                return e.saleValue.total;
              case 'quantity':
                return e.quantity.total;
              case 'orderCount':
                return e.orderCount.total;
            }
          })();

          // Handle keys with dots in them
          const serializedKey = serializeKey(container.key);
          item.containers[serializedKey] = count;
          item.total += count;
        });
        return result;
      },
      {}
    );
    if (tfStart && tfEnd && tfTz) {
      const moments = timeframeToMomentRange({
        start: tfStart,
        end: tfEnd,
        tz: tfTz
      });
      const tkRange = getTimeKeyRange(moments.start, moments.end);
      tkRange.forEach(
        (tk) => (byTimeKey[tk] = byTimeKey[tk] || EMPTY_BAR_EL(tk))
      );
    }

    if (clickData) {
      clickData.forEach((c) => {
        const tk = c.ts;
        const container = (byTimeKey[tk] = byTimeKey[tk] || EMPTY_BAR_EL(tk));
        container.clicks = c.clicked;
      });
    }

    const els = Object.values(byTimeKey);
    addRollingAverages(els);
    return els;
  }, [data, clickData, tfStart, tfEnd, tfTz, metric]);

  const axisFontSize = isSmall ? 12 : 14;

  return (
    <ResponsiveContainer
      height={height}
      width="99%"
      aspect={height === 'auto' ? 1.8 : undefined}
    >
      <ComposedChart
        data={counts}
        layout="horizontal"
        stackOffset="sign"
        maxBarSize={12}
      >
        <XAxis
          dataKey="timeKey"
          tickFormatter={(tk) =>
            formatTimeKey(
              tk || '',
              startsInCurrentYear ? 'MMM DD' : "MMM DD, 'YY"
            )
          }
          tick={{
            fill: '#9b9b9b',
            transform: 'translate(0, 8)',
            fontSize: axisFontSize
          }}
          textAnchor="end"
          tickSize={0}
          minTickGap={60}
          stroke="cwBBB"
          hide={isSmall}
        />
        <YAxis
          width={isSmall ? 40 : undefined}
          yAxisId="earnings"
          tick={{
            fill: '#9b9b9b',
            transform: `translate(${yAxisOrientation === 'left' ? -8 : 8}, 0)`,
            fontSize: axisFontSize
          }}
          domain={[
            (min) => {
              if (chartMode === 'barChart') {
                return Math.min(min, 0);
              }
              // getting smallest value from all containers to set yAxis length accordingly
              const minValue = Math.min(
                ...counts.map((count) =>
                  Math.min(...Object.values(count.containers))
                )
              );
              return minValue;
            },
            (max) => {
              if (chartMode === 'barChart') {
                return Math.max(max, 0);
              }
              // getting largest value from all containers to set yAxis length accordingly
              const maxValue = Math.max(
                ...counts.map((count) =>
                  Math.max(...Object.values(count.containers))
                )
              );
              return maxValue;
            }
          ]}
          allowDecimals={false}
          stroke="none"
          tickSize={0}
          orientation={yAxisOrientation}
          tickFormatter={(value: number) => formatter(value, metric, currency)}
        />

        {clickData && (
          <YAxis
            width={isSmall ? 40 : undefined}
            yAxisId="clicks"
            tick={{
              fill: '#9b9b9b',
              transform: `translate(${
                yAxisOrientation === 'left' ? 8 : -8
              }, 0)`,
              fontSize: axisFontSize
            }}
            allowDecimals={false}
            stroke="none"
            tickSize={0}
            tickFormatter={(v: number) => formatChartNumber(v)}
            orientation={yAxisOrientation === 'left' ? 'right' : 'left'}
          />
        )}
        {chartMode === 'barChart'
          ? data.map((d) => (
              <Bar
                key={serializeKey(d.container.key)}
                dataKey={`containers.${serializeKey(d.container.key)}`}
                stackId="a"
                yAxisId="earnings"
                fill={d.container.color}
                isAnimationActive={false}
              />
            ))
          : data.map((d) => (
              <Line
                key={serializeKey(d.container.key)}
                dataKey={`containers.${serializeKey(d.container.key)}`}
                yAxisId="earnings"
                stroke={d.container.color}
                isAnimationActive={false}
                dot={false}
                strokeWidth={1.3}
                type="monotone"
              />
            ))}
        <Line
          stroke="none"
          yAxisId="earnings"
          dataKey="total"
          dot={false}
          activeDot={false}
        />
        {clickData && (
          <Line
            type="monotone"
            dataKey="clicks"
            yAxisId="clicks"
            dot={false}
            stroke={CLICKS_COLOR}
            strokeWidth={isSmall ? 1 : 3}
            isAnimationActive={false}
          />
        )}
        <Tooltip
          cursor={false}
          separator=": "
          content={<CustomTooltip size={isSmall ? 'small' : 'normal'} />}
          labelFormatter={(label: TooltipProps['label']) => (
            <>
              {formatTimeKey(
                label as string,
                startsInCurrentYear ? 'ddd MMM DD' : "ddd MMM DD, 'YY"
              )}
            </>
          )}
          formatter={(value, name) =>
            tooltipFormatter(
              value as number,
              metric,
              name,
              containers,
              currency,
              isSmall
            )
          }
        />

        {!isSmall && <ReferenceLine y={0} yAxisId={'earnings'} stroke="#bbb" />}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
