import { IconButton } from '@material-ui/core';
import { Search as IconShowDetails } from 'react-feather';
import { truncate } from 'lodash';
import React from 'react';
import { IProductSuggestionGroup } from '../../../../../../domainTypes/productScan';
import { styled } from '../../../../../../emotion';
import { getPathName } from '../../../../../../services/pages';

// const Section = styled('div')`
//   margin-bottom: ${p => p.theme.spacing() * 3}px;
// `;

// const Title = styled('div')`
//   margin-bottom: ${p => p.theme.spacing() * 2}px;
//   font-weight: bold;
// `;

// const ImageListContainer = styled<'div', { width: number; cols: number }>(
//   'div'
// )`
//   display: flex;
//   max-width: ${p => p.width * p.cols + p.theme.spacing() * (p.cols - 2)}px;
//   flex-wrap: wrap;

//   img {
//     margin-left: ${p => p.theme.spacing()}px;

//     &:first-of-type {
//       margin-left: 0;
//     }
//   }
// `;

// const ImageList = ({
//   srcs,
//   onLoad
// }: {
//   srcs: string[];
//   onLoad: () => void;
// }) => {
//   const width = 300;
//   return (
//     <ImageListContainer width={300} cols={3}>
//       {srcs.map((src, i) => (
//         <img width={width} src={src} key={i} onLoad={onLoad} />
//       ))}
//     </ImageListContainer>
//   );
// };

const RowCell = styled<'div', { flex?: number }>('div')`
  display: flex;
  align-items: center;
  flex: ${p => p.flex || 0};
`;

const RowDetailsContainer = styled('div')`
  padding: 0 ${p => p.theme.spacing() * 3}px;
  padding-left: ${p => p.theme.spacing() * 5}px;
  background: ${p => p.theme.palette.grey.A100};
  margin-bottom: ${p => p.theme.spacing() * 2}px 0
    ${p => p.theme.spacing() * 2}px 0;
  font-size: ${p => p.theme.custom.fontSize.s}px;
`;

const ProductSuggestion = styled('div')`
  padding: ${p => p.theme.spacing()}px 0;
  border-top: 1px rgba(224, 224, 224, 1) solid;
  font-size: ${p => p.theme.custom.fontSize.s}px;
  display: flex;
`;

// const sortPages = (
//   pages: IProductSuggestionGroup['pages']
// ): IProductSuggestionGroup['pages'] => {
//   return orderBy(pages, [p => p.timesFound, p => p.url], ['desc', 'asc']);
// };

export type RowDetailsProps = {
  gs: IProductSuggestionGroup[];
  onShowDetails: (g: IProductSuggestionGroup) => void;
};

export const RowDetails = ({ gs, onShowDetails }: RowDetailsProps) => {
  return (
    <RowDetailsContainer>
      {gs.map(g => (
        <ProductSuggestion key={g.href}>
          <RowCell flex={2}>
            <a
              href={g.href}
              title={g.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {truncate(getPathName(g.href), { length: 60 })}
            </a>{' '}
          </RowCell>
          <RowCell flex={1} title={g.names[0]}>
            {truncate(g.names[0], { length: 50 })}
          </RowCell>
          <RowCell>
            <IconButton onClick={() => onShowDetails(g)}>
              <IconShowDetails />
            </IconButton>
          </RowCell>
        </ProductSuggestion>
      ))}
    </RowDetailsContainer>
  );
};
