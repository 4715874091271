import React from 'react';
import { styled } from '../emotion';

const Arrow = () => (
  <svg
    width="19"
    height="17"
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.03289 1.78459C3.15185 1.53539 3.45031 1.4298 3.69952 1.54876L7.76055 3.48733C8.00975 3.60629 8.11533 3.90474 7.99637 4.15395C7.87741 4.40315 7.57896 4.50874 7.32975 4.38978L3.71995 2.66661L1.99678 6.27641C1.87782 6.52562 1.57936 6.6312 1.33016 6.51224C1.08095 6.39328 0.975369 6.09483 1.09433 5.84562L3.03289 1.78459ZM17.128 12.9117C12.1277 13.5162 9.1713 12.6907 7.16542 10.6675C6.17973 9.67327 5.45473 8.41944 4.82208 6.99089C4.19113 5.5662 3.63617 3.92897 3.01275 2.16675L3.95549 1.83323C4.58716 3.61877 5.12543 5.20634 5.73642 6.58596C6.3457 7.96172 7.01135 9.09174 7.87556 9.96341C9.5695 11.672 12.1499 12.5063 17.008 11.9189L17.128 12.9117Z"
      fill="#1890ff"
    />
  </svg>
);

const StartHereWrapper = styled('div')`
  display: inline-block;
  position: relative;
`;

const ArrowWrapper = styled('div')`
  position: absolute;
  bottom: -18px;
  left: 24px;
  color: #1890ff;
  font-weight: bold;
`;

export const StartHere: React.FC = ({ children }) => {
  return (
    <StartHereWrapper>
      {children}
      <ArrowWrapper>
        <Arrow /> Click to start
      </ArrowWrapper>
    </StartHereWrapper>
  );
};
