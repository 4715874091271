import { LinkCheckStatus, ResultsCounter } from '../linkCheckV2';
import { IPubSubInstruction } from '../pubsub';
import { Timestamp } from '../time';

export const TOPICS = {
  onPackageStart: 'linkCheckApp-onPackageStart',
  onPackageDone: 'linkCheckApp-onPackageDone',
  notifyOnSuccessfulLinkCheck: 'linkCheckApp-notifyOnSuccessfulLinkCheck'
};

export interface ILinkCheckAppContainer {
  spaceId: string;
  status: LinkCheckStatus;
  packages: {
    [key: string]: {
      status: LinkCheckStatus;
      results: ResultsCounter;
    };
  };
  counts: {
    total: number;
    unsupported: number;
    pending: number;
    done: number;
    results: ResultsCounter;
  };

  createdBy: string;
  queuedAt: Timestamp;
  startedAt: Timestamp | null;
  finishedAt: Timestamp | null;

  publishOnDone?: IPubSubInstruction[];
}
