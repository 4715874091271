import { SortByKey } from './types';

export const toClickhouseOrderByField = (orderBy: SortByKey) =>
  orderBy === 'oct'
    ? 'order_count_net'
    : orderBy === 'qt'
    ? 'quantity_net'
    : orderBy === 'pt'
    ? 'gmv_sum_net'
    : 'commission_sum_net';
