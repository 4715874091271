import { X as IconX } from 'react-feather';
import { IconButton } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

export type BackgroundJobCallbackValue = {
  message: React.ReactNode;
  action?: (close: () => void) => React.ReactNode;
};
export type BackgroundJob<T = any> = {
  job: () => Promise<T>;
  onStart: () => BackgroundJobCallbackValue | null;
  onSuccess: (result: T) => BackgroundJobCallbackValue | null;
  onError: (err: any) => BackgroundJobCallbackValue | null;
};

export const BackgroundJobsContext = React.createContext<{
  addJob: (job: BackgroundJob) => void;
  jobs: BackgroundJob[];
}>({
  addJob: () => null,
  jobs: []
});

export const BackgroundJobsProvider: React.FC = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [jobs, setJobs] = useState<BackgroundJob[]>([]);
  const addJob = useCallback(
    (j: BackgroundJob) => {
      const promise = j.job();
      const start = j.onStart();
      let startKey: any = undefined;
      if (start) {
        startKey = enqueueSnackbar(start.message, {
          persist: true,
          action: start.action ? (
            start.action?.(() => closeSnackbar(startKey))
          ) : (
            <IconButton onClick={() => closeSnackbar(startKey || undefined)}>
              <IconX color="white" size={16} />
            </IconButton>
          )
        });
      }

      promise.then(
        (res) => {
          if (startKey) {
            closeSnackbar(startKey);
          }
          const success = j.onSuccess(res);
          if (success) {
            const successKey: any = enqueueSnackbar(success.message, {
              variant: 'success',
              action: success.action?.(() => closeSnackbar(successKey || ''))
            });
          }
          setJobs((js) => js.filter((o) => o !== j));
        },
        (err) => {
          if (startKey) {
            closeSnackbar(startKey);
          }
          const error = j.onError(err);
          if (error) {
            const errorKey: any = enqueueSnackbar(error.message, {
              variant: 'error',
              action: error.action?.(() => closeSnackbar(errorKey || ''))
            });
          }
          setJobs((js) => js.filter((o) => o !== j));
        }
      );
      return setJobs((js) => [...js, j]);
    },
    [enqueueSnackbar, closeSnackbar]
  );
  const value = useMemo(() => ({ jobs, addJob }), [jobs, addJob]);

  useEffect(() => {
    const unloader = (e: Event) => {
      const block = !!jobs.length;
      if (block) {
        e.preventDefault();
        e.returnValue = true;
      } else {
        // @ts-ignore
        delete e['returnValue'];
      }
    };
    window.addEventListener('beforeunload', unloader);
    return () => {
      window.removeEventListener('beforeunload', unloader);
    };
  }, [jobs]);

  return (
    <BackgroundJobsContext.Provider value={value}>
      {children}
    </BackgroundJobsContext.Provider>
  );
};

export const useBackgroundJobs = () => {
  return useContext(BackgroundJobsContext);
};
