import { CurrencyCode } from '../../../domainTypes/currency';
import { useSpaceCurrency } from '../../../services/useSpaceCurrency';
import { useTimeframe } from '../Timeframe';
import { useMemo } from 'react';
import moment from 'moment-timezone';

// TODO: Add interval unit there, when we get good convention when to store timeframe granularity so TimeseriesChart can get it from there.
export interface ChartContext {
  startsInCurrentYear: boolean;
  currency: CurrencyCode;
}

export const useChartContext = (): ChartContext => {
  const currency = useSpaceCurrency();
  const { range } = useTimeframe();
  const startsInCurrentYear = useMemo(
    () => moment(range.start).format('YYYY') === moment().format('YYYY'),
    [range.start]
  );

  return useMemo(() => ({ startsInCurrentYear, currency }), [
    currency,
    startsInCurrentYear
  ]);
};
