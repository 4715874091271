import { sortBy, sumBy } from 'lodash';
import React from 'react';
import { Label, Text } from 'recharts';
import { IEarning } from '../../../domainTypes/performance';
import { styled } from '../../../emotion';
import { useColorUnknown } from '../../../services/color';
import { useCurrentUser } from '../../../services/currentUser';
import { useTheme } from '../../../themes';
import { Loader } from '../../Loader';
import { formatCurrency } from '../../Number';
import { getSiteColor } from '../../SiteWithColor';
import { TooltipRow } from '../CustomTooltip';
import { IPieChartCell, PieChartWithLegend } from '../PieChartWithLegend';
import { formatChartCurrency } from '../Util';

type Props = {
  data: { origin: string; earnings: IEarning }[] | void;
  loading: boolean;
  count: number;
  animate?: boolean;
};

const HEIGHT = 390;

const FormattedLabel = styled('div')`
  padding: 0;
  white-space: nowrap;
`;

export const SitesChart = React.memo(
  ({ data, loading, count = 10, animate }: Props) => {
    const theme = useTheme();
    const colorUnknown = useColorUnknown();
    const currency = useCurrentUser().space.config.currency;

    if (loading || !data) {
      return <Loader height={HEIGHT} />;
    }

    const totalEarnings = sumBy(data, (d) => d.earnings.total);
    const sorted = sortBy(data, (p) => -p.earnings.total);
    const shownIndividually = sorted.slice(0, count);
    const rest = sorted.slice(count);
    const sortedData = shownIndividually.map<IPieChartCell>((d) => ({
      key: d.origin,
      name: d.origin,
      count: d.earnings.total,
      color: getSiteColor(d.origin)
    }));

    if (rest.length) {
      sortedData.push({
        key: 'other',
        name: 'Other',
        count: rest.reduce((m, p) => m + p.earnings.total, 0),
        color: colorUnknown
      });
    }

    return (
      <PieChartWithLegend
        data={sortedData}
        animate={animate}
        aspect={1.5}
        extra={
          <Label
            content={(props) => {
              const {
                viewBox: { cx, cy }
              } = props;
              return (
                <>
                  <Text
                    x={cx}
                    y={cy - 20}
                    textAnchor="middle"
                    verticalAnchor="middle"
                    style={{
                      fontSize: theme.custom.fontSize.m,
                      fill: theme.palette.grey[700]
                    }}
                  >
                    Earnings
                  </Text>
                  <Text
                    x={cx}
                    y={cy + 5}
                    textAnchor="middle"
                    verticalAnchor="middle"
                    style={{ fontSize: theme.custom.fontSize.l }}
                  >
                    {formatChartCurrency(totalEarnings, currency)}
                  </Text>
                </>
              );
            }}
          />
        }
        formatter={(value, name) => {
          return (
            <FormattedLabel>
              <TooltipRow>
                <div>{name}</div>
                <div>{formatCurrency(value as number, currency)}</div>
              </TooltipRow>
            </FormattedLabel>
          );
        }}
      />
    );
  }
);
