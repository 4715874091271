import React, { useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import {
  CustomDimensionName,
  ICustomDimensionsDoc,
  slotName
} from '../../../../../domainTypes/customDimensions';
import Typography from '@material-ui/core/Typography';
import { SlotBadge } from './SlotBadge';
import { updateDoc } from '../../../../../services/db';
import { omit } from 'lodash';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { Doc } from '../../../../../domainTypes/document';
import { getCustomDimensionsDetailsStore } from '../../../../../services/customDimensions';
import { useCurrentUser } from '../../../../../services/currentUser';

interface SlotDeleteDialogProps {
  customDimensionsDoc: Doc<ICustomDimensionsDoc>;
  slot: CustomDimensionName;
  open: boolean;
  onCancel: () => void;
}

export const SlotDeleteDialog: React.FC<SlotDeleteDialogProps> = ({
  customDimensionsDoc,
  slot,
  open,
  onCancel
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { space } = useCurrentUser();

  const deleteSlot = useCallback(async () => {
    try {
      onCancel();
      await updateDoc(customDimensionsDoc, (data) => ({
        dimensions: omit(data.dimensions, slot)
      }));
      const detailsStore = getCustomDimensionsDetailsStore(space.id, slot);
      await detailsStore.delete();
    } catch (e) {
      enqueueSnackbar(`Failed to delete ${slotName(slot)} slot`, {
        variant: 'error'
      });
    }
  }, [customDimensionsDoc, enqueueSnackbar, onCancel, slot, space.id]);

  return (
    <Dialog open={open} onClose={onCancel} scroll="body">
      <DialogTitle>Delete custom dimension</DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          Are you sure you want to delete <SlotBadge slot={slot} />
          dimension?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button variant="contained" color="secondary" onClick={deleteSlot}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const useDeleteDialog = () => {
  const [state, setState] = useState<
    { open: true; slot: CustomDimensionName } | { open: false }
  >({ open: false });
  const openDeleteDialog = useCallback((slot: CustomDimensionName) => {
    setState({ open: true, slot });
  }, []);
  const closeDeleteDialog = useCallback(() => {
    setState({ open: false });
  }, []);
  return { deleteDialogState: state, openDeleteDialog, closeDeleteDialog };
};
