import React, { useMemo, useState } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  TickFormatterFunction,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import {
  EMPTY_COUNTER,
  ICounter,
  IDailyCounter
} from '../../../domainTypes/analytics';
import { styled } from '../../../emotion';
import {
  formatTimeKey,
  getClickRatio,
  getCpm,
  getViewRatio
} from '../../../services/analytics';
import { splitInHalf } from '../../../services/list';
import { Loader } from '../../Loader';
import { formatNumber, INumberProps, Number, toPercent } from '../../Number';
import { Trend } from '../../Trend';
import { CustomTooltip } from '../CustomTooltip';
import { formatChartNumber, WithShape } from '../Util';

export interface ITrafficChartData {
  x: string;
  yAxisLeft: number;
  yAxisRight: number;
  linearRegression: number;
}

export type CountKey = keyof ICounter | 'viewRatio' | 'clickRatio' | 'cpm';

export interface ITimeBasedCounter extends ICounter {
  ts: string;
}

export const toTimebasedCounter = (d: IDailyCounter): ITimeBasedCounter => {
  return {
    ts: d.timeKey,
    pageViews: d.pageViews,
    served: d.served,
    viewed: d.viewed,
    clicked: d.clicked
  };
};

export const toTimebasedCounters = (ds: IDailyCounter[]) =>
  ds.map(toTimebasedCounter);

interface WithMargin {
  margin: 'normal' | 'dense';
}

export type Formatters = {
  xTick: TickFormatterFunction;
  tooltipLabel: (ts: string) => React.ReactNode;
};

interface Props {
  counters: ITimeBasedCounter[] | null | void;
  loading: boolean;
  compare: boolean;
  options?: CountKey[];
  yAxisLeft: CountKey;
  yAxisRight: CountKey;
  animate?: boolean;
  colors: string[];
  formatters: Formatters;
  variant?: 'normal' | 'minimal';
  height?: string | number;
}

export const FORMATTERS: {
  TIME_KEY: Formatters;
} = {
  TIME_KEY: {
    xTick: formatTimeKey,
    tooltipLabel: (ts) => (
      <span>{formatTimeKey(ts as string, 'ddd MMM DD')}</span>
    )
  }
};

const Options = styled('div')`
  display: flex;
  justify-content: center;
`;

const OptionItem = styled<'div', WithMargin>('div')((p) => {
  const dense = p.margin === 'dense';
  const { custom: c, palette, spacing } = p.theme;
  const fontSizeLabel = `${dense ? c.fontSize.s : c.fontSize.m}px`;
  const fontSize = `${dense ? c.fontSize.s : c.fontSize.l}px`;

  return `
      font-size: ${fontSize};
      min-width: ${dense ? '100px' : '200px'};
      color: ${palette.text.primary};
      text-align: center;

      > label {
        font-size: ${fontSizeLabel};
        color: ${palette.text.secondary};
      }

      > div {
        margin-top: ${spacing() / (dense ? 4 : 2)}px;
      }
  `;
});

const Container = styled('div')((p) => ({
  width: '100%'
}));

const Body = styled<'div', WithMargin>('div')`
  margin: ${(p) => p.theme.spacing() * (p.margin === 'dense' ? 0 : 2)}px;
`;

type OptionProps = {
  label: string;
  children?: any;
};

const Option = ({ label, children, margin }: OptionProps & WithMargin) => {
  return (
    <OptionItem margin={margin}>
      <label>{label}</label>
      <div>{children}</div>
    </OptionItem>
  );
};

const sumCounts = (counts: ITimeBasedCounter[]) => {
  return counts.reduce<ICounter>((m, r) => {
    m.served = m.served + r.served;
    m.viewed = m.viewed + r.viewed;
    m.clicked = m.clicked + r.clicked;
    m.pageViews = m.pageViews + r.pageViews;
    return m;
  }, EMPTY_COUNTER());
};

const sumCountsWithComparison = (
  counts: ITimeBasedCounter[],
  compare: boolean
): [ICounter, ICounter] => {
  const [a, b] = compare ? splitInHalf(counts) : [[], counts];
  return [sumCounts(a), sumCounts(b)];
};

const getValueFromCounter = (counter: ICounter, key: CountKey) => {
  if (!counter) {
    return 0;
  }
  if (key === 'viewRatio') {
    return getViewRatio(counter);
  }
  if (key === 'clickRatio') {
    return getClickRatio(counter);
  }
  if (key === 'cpm') {
    return getCpm(counter);
  }
  return counter[key];
};

const toChartData = (
  counts: ITimeBasedCounter[],
  keys: CountKey[],
  compare: boolean
): ITrafficChartData[] => {
  const [comparisonValues, values] = compare
    ? splitInHalf(counts)
    : [[], counts];
  const [yAxisLeft, yAxisRight] = keys;
  const data = values.map((c, i) => ({
    x: c.ts,
    yAxisLeft: getValueFromCounter(c, yAxisLeft),
    yAxisRight: getValueFromCounter(c, yAxisRight),
    linearRegression: 0,
    compareYAxisLeft: getValueFromCounter(comparisonValues[i], yAxisLeft),
    compareYAxisRight: getValueFromCounter(comparisonValues[i], yAxisRight)
  }));

  return data;
};

interface IOption {
  label: string;
  value: CountKey;
  format?: INumberProps['format'];
  digits?: INumberProps['digits'];
}

export const OPTIONS: IOption[] = [
  {
    label: 'Pageviews',
    value: 'pageViews'
  },
  {
    label: 'Seen',
    value: 'viewed'
  },
  {
    label: 'Clicked',
    value: 'clicked'
  },
  {
    label: '% Seen',
    value: 'viewRatio',
    format: 'percent'
  },
  {
    label: '% Clicked',
    value: 'clickRatio',
    format: 'percent',
    digits: 2
  },
  {
    label: 'Page CTR',
    value: 'cpm',
    format: 'percent',
    digits: 1
  }
];

const TICK_STYLES: {
  [K in WithMargin['margin']]: {
    fontSize?: number;
  };
} = {
  normal: { fontSize: 12 },
  dense: { fontSize: 10 }
};

const MARGINS: {
  [K in WithMargin['margin']]: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
} = {
  normal: { right: 16, top: 24 },
  dense: { right: 8, top: 16, left: -8 }
};

const ComparisonContainer = styled<
  'div',
  { positive: boolean; selected: boolean } & WithMargin
>('div')((p) => {
  const getColor = () => {
    if (p.selected) {
      return 'inherit';
    }
    return p.positive ? p.theme.custom.colors.success.main : 'red';
  };
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: p.theme.custom.fontSize.s,
    color: getColor(),
    marginTop: `${p.theme.spacing() / (p.margin === 'dense' ? 2 : 1)}px`
  };
});

const ComparisonPercentage = ({
  a,
  b,
  selected,
  margin
}: {
  a: number;
  b: number;
  selected: boolean;
} & WithMargin) => {
  const diff = a - b;
  const percentage = toPercent(diff, a);
  const positive = percentage > 0;

  return (
    <ComparisonContainer
      positive={positive}
      selected={selected}
      margin={margin}
    >
      <Trend values={[b, a]} label="left" />
    </ComparisonContainer>
  );
};

const OptionBody = ({
  option,
  sums,
  comparisonSums,
  compare,
  selected,
  margin
}: {
  option: IOption;
  sums: ICounter;
  comparisonSums: ICounter;
  compare: boolean;
  selected: boolean;
} & WithMargin) => {
  return (
    <>
      <div>
        <Number
          n={getValueFromCounter(sums, option.value)}
          format={option.format}
          digits={option.digits}
        />
      </div>
      {compare && (
        <ComparisonPercentage
          a={getValueFromCounter(sums, option.value)}
          b={getValueFromCounter(comparisonSums, option.value)}
          selected={selected}
          margin={margin}
        />
      )}
    </>
  );
};

export const ALL_OPTIONS = OPTIONS.map((t) => t.value);

export const ANALYTICS_OPTIONS: CountKey[] = [
  'pageViews',
  'clicked',
  'clickRatio',
  'cpm'
];

const toLineLabel = (toMatch: CountKey, compare: boolean) => {
  const config = OPTIONS.find((o) => o.value === toMatch);
  const label = config ? config.label : 'Value';
  return compare ? `Previous ${label.toLowerCase()}` : label;
};

export const TrafficBiaxialChart = React.memo(
  ({
    counters,
    loading,
    margin,
    compare,
    colors,
    yAxisLeft,
    yAxisRight,
    formatters,
    options = ALL_OPTIONS,
    animate = false,
    variant = 'normal',
    height = 'auto'
  }: Props & WithMargin) => {
    const displayedOptions = useMemo(
      () => options.map((t) => OPTIONS.find((x) => t === x.value)!),
      [options]
    );

    const [option] = useState<IOption>(
      displayedOptions.find((t) => t.value === 'clicked') || displayedOptions[0]
    );

    const [comparisonSums, sums] = sumCountsWithComparison(
      counters || [],
      compare
    );

    const keys = [yAxisLeft, yAxisRight];
    const [yAxisLeftColor, yAxisRightColor] = colors;

    return (
      <Container>
        {variant === 'normal' && (
          <Options>
            {displayedOptions.map((t) => (
              <Option key={t.value} label={t.label} margin={margin}>
                {!counters && loading ? (
                  ''
                ) : (
                  <OptionBody
                    option={t}
                    sums={sums}
                    comparisonSums={comparisonSums}
                    compare={compare && !loading}
                    selected={option === t}
                    margin={margin}
                  />
                )}
              </Option>
            ))}
          </Options>
        )}
        <Body margin={margin}>
          {counters && !loading ? (
            <ResponsiveContainer
              height={height}
              width="99%"
              aspect={height === 'auto' ? 2.5 : undefined}
            >
              <LineChart
                data={toChartData(counters, keys, compare)}
                margin={MARGINS[margin]}
              >
                <XAxis
                  dataKey="x"
                  tick={TICK_STYLES[margin]}
                  tickFormatter={formatters.xTick}
                  hide={variant === 'minimal'}
                />
                <YAxis
                  width={variant === 'minimal' ? 40 : undefined}
                  tick={{
                    ...TICK_STYLES[margin],
                    ...(variant === 'minimal'
                      ? {
                          fill: '#9b9b9b'
                        }
                      : {})
                  }}
                  yAxisId="left"
                  orientation="left"
                  tickFormatter={(t) => formatChartNumber(t)}
                  stroke={variant === 'minimal' ? 'none' : undefined}
                  allowDecimals={false}
                />
                <YAxis
                  width={variant === 'minimal' ? 40 : undefined}
                  tick={{
                    ...TICK_STYLES[margin],
                    ...(variant === 'minimal'
                      ? {
                          fill: '#9b9b9b'
                        }
                      : {})
                  }}
                  yAxisId="right"
                  orientation="right"
                  tickFormatter={(t) => formatChartNumber(t)}
                  stroke={variant === 'minimal' ? 'none' : undefined}
                  allowDecimals={false}
                />
                {variant === 'normal' && (
                  <CartesianGrid strokeDasharray="3 1" />
                )}
                <Tooltip
                  content={
                    <CustomTooltip
                      size={margin === 'dense' ? 'small' : 'normal'}
                      reversePayloadOrder
                    />
                  }
                  formatter={(value, name, p) => {
                    const color =
                      p.dataKey === 'yAxisLeft'
                        ? yAxisLeftColor
                        : p.dataKey === 'yAxisRight'
                        ? yAxisRightColor
                        : 'none';
                    return [
                      formatNumber({
                        n: value as number,
                        format: option.format,
                        digits: option.digits
                      }),
                      <WithShape color={color} small={margin === 'dense'}>
                        {name}
                      </WithShape>
                    ];
                  }}
                  labelFormatter={(l) => formatters.tooltipLabel(l as string)}
                />
                <Line
                  isAnimationActive={animate}
                  type="monotone"
                  dataKey="yAxisLeft"
                  yAxisId="left"
                  name={toLineLabel(yAxisLeft, false)}
                  stroke={yAxisLeftColor}
                  strokeWidth={2}
                  dot={false}
                />
                {compare && (
                  <Line
                    isAnimationActive={animate}
                    type="monotone"
                    dataKey="compareYAxisLeft"
                    name={toLineLabel(yAxisLeft, true)}
                    stroke={yAxisLeftColor}
                    yAxisId="left"
                    strokeDasharray="3 3"
                    dot={false}
                  />
                )}
                <Line
                  isAnimationActive={animate}
                  type="monotone"
                  dataKey="yAxisRight"
                  yAxisId="right"
                  name={toLineLabel(yAxisRight, false)}
                  stroke={yAxisRightColor}
                  strokeWidth={2}
                  dot={false}
                />
                {compare && (
                  <Line
                    isAnimationActive={animate}
                    type="monotone"
                    dataKey="compareYAxisRight"
                    name={toLineLabel(yAxisRight, true)}
                    stroke={yAxisRightColor}
                    yAxisId="right"
                    strokeDasharray="3 3"
                    dot={false}
                  />
                )}
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <Loader height={margin === 'dense' ? '215px' : '400px'} />
          )}
        </Body>
      </Container>
    );
  }
);
