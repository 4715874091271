import { Step, StepLabel, Stepper } from '@material-ui/core';
import React from 'react';

type Props = {
  steps: React.ReactNode[];
  activeStep: number | null;
};

export const ProgressStepper: React.FC<Props> = ({ steps, activeStep }) => {
  const mappedSteps = steps;
  return (
    <Stepper activeStep={activeStep || undefined} orientation="vertical">
      {mappedSteps.map((step, i) => {
        // const active = i === activeStep;
        const completed = activeStep === null || i < activeStep;
        return (
          <Step key={i} completed={completed}>
            <StepLabel>{step}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};
