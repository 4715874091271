import React from 'react';
import {
  PageBody,
  SideNavHowTo,
  SideNavProps
} from '../../../../layout/PageBody';
import { useRoutes, useTypedStringQueryParam } from '../../../../routes';

type Props = {
  productId: string;
  howTo?: SideNavHowTo;
  noTopPadding?: boolean;
};

export const PageBodyLinkDetails: React.FC<Props> = ({
  productId,
  howTo,
  noTopPadding,
  children
}) => {
  const { ROUTES } = useRoutes();
  const [back] = useTypedStringQueryParam<'amazonIssues' | 'overview'>(
    'back',
    'overview'
  );
  const backUrl =
    back === 'amazonIssues'
      ? ROUTES.links.amazonIssues.url()
      : ROUTES.links.overview.url();

  const sideNav: SideNavProps = {
    groups: [
      {
        label: 'Link Details',
        items: [
          {
            label: 'Overview',
            path: ROUTES.links.details.overview.url(productId, back)
          },
          {
            label: 'Page Performance',
            path: ROUTES.links.details.pages.url(productId, back)
          },
          {
            label: 'Audience',
            path: ROUTES.links.details.audience.url(productId, back)
          }
        ]
      }
    ],
    back: {
      path: backUrl
    },
    howTo: howTo
  };
  return (
    <>
      <PageBody sideNav={sideNav} noTopPadding={noTopPadding}>
        {children}
      </PageBody>
    </>
  );
};
