import React from 'react';
import { useCurrentUser } from '../../../../../services/currentUser';
import { useTagHierarchy } from '../../../../../services/tags';
import { isAutomaticTagGroup } from '../../../../../domainTypes/tag';
import {
  NestedMultiSelectorBody,
  toNestedMultiSelectorCheckboxGroup
} from '../../../../../components/NestedMultiSelector';
import { IPostgresTags } from '../../../../../domainTypes/tags';

interface TagsSelectorProps {
  value: Set<string>;
  onChange: (next: Set<string>) => void;
}

export const TagsMultiSelector: React.FC<TagsSelectorProps> = ({
  value,
  onChange
}) => {
  const { space } = useCurrentUser();
  const [tags] = useTagHierarchy();
  if (tags) {
    const items = tags
      .filter((g) => !isAutomaticTagGroup(g.parent, space.id))
      .map(({ parent, children }) => {
        return toNestedMultiSelectorCheckboxGroup<Set<string>, IPostgresTags>({
          key: parent.id,
          label: parent.name,
          options: children,
          isOptionSelected: (values, item) => values.has(item.id),
          onChangeOption: (prev, item) => {
            const nextValue = new Set(prev);
            if (nextValue.has(item.id)) {
              nextValue.delete(item.id);
            } else {
              nextValue.add(item.id);
            }
            return nextValue;
          }
        });
      });
    return (
      <NestedMultiSelectorBody
        values={value}
        onChange={onChange}
        items={items}
      />
    );
  }
  return null;
};
