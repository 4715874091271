import { kebabCase } from 'lodash';
import { IChannel, IChannelType } from '../../domainTypes/channels';
import { Doc, generateToDocFn } from '../../domainTypes/document';
import { FS } from '../../versions';
import { setDoc, store } from '../db';
import { now } from '../time';
import {
  Facebook,
  Grid,
  Instagram,
  Mail,
  Smartphone,
  Twitter,
  Youtube
} from 'react-feather';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../firecache/collectionListener';

const ICON_SIZE = 17;
// TODO: rename to OffsiteChannel!

export const toChannelDoc = generateToDocFn<IChannel>();

const channelStore = createCollectionListenerStore(
  (spaceId) =>
    new CollectionListener(
      store().collection(FS.channels).where('spaceId', '==', spaceId),
      toChannelDoc
    )
);

export const useChannels = (spaceId: string) =>
  useCollectionListener(channelStore(spaceId));

export const saveChannel = async (channel: Doc<IChannel>) => {
  const channelToSave = {
    ...channel,
    id: toChannelDocId(channel.data.spaceId, channel.data.channelId),
    data: {
      ...channel.data,
      spaceId: channel.data.spaceId,
      updatedAt: now(),
      createdBy: channel.data.createdBy,
      channelId: channel.data.channelId
    }
  };

  console.log('channelToSave', channelToSave);

  return setDoc(channelToSave);
};

export const toChannelDocId = (spaceId: string, channelId: string) => {
  return `${spaceId}_${channelId}`;
};

export const EMPTY_CHANNEL: (spaceId: string) => Doc<IChannel> = (spaceId) => ({
  id: '',
  collection: FS.channels,
  data: {
    name: '',
    channelId: '',
    spaceId: spaceId,
    status: 'active',
    createdAt: now(),
    updatedAt: now(),
    type: IChannelType.Email,
    subIdMatches: [],
    subIdDefaultPrefix: '',
    createdBy: ''
  }
});

export const toChannelId = (channelType: IChannelType, channelName: string) => {
  const shortname = kebabCase(channelName);
  switch (channelType) {
    case IChannelType.Email:
      return `email-${shortname}`;
    case IChannelType.Facebook:
      return `fb-${shortname}`;
    case IChannelType.Instagram:
      return `ig-${shortname}`;
    case IChannelType.Twitter:
      return `tw-${shortname}`;
    case IChannelType.Youtube:
      return `yt-${shortname}`;
    case IChannelType.Flipboard:
      return `fl-${shortname}`;
    case IChannelType.Pinterest:
      return `pi-${shortname}`;
    case IChannelType.Tiktok:
      return `tk-${shortname}`;
    case IChannelType.App:
      return `app-${shortname}`;
    case IChannelType.Other:
      return `other-${shortname}`;
  }
};

export const toChannelUrlLabel = (channelType: IChannelType) => {
  switch (channelType) {
    case IChannelType.Facebook:
      return 'Facebook Page';
    case IChannelType.Instagram:
      return 'Instagram Profile';
    case IChannelType.Twitter:
      return 'Twitter Profile';
    case IChannelType.Youtube:
      return 'YouTube Channel URL';
    case IChannelType.Flipboard:
      return 'Flipboard Magazine URL';
    case IChannelType.Pinterest:
      return 'Pinterest Profile URL';
    case IChannelType.Tiktok:
      return 'TikTok Profile URL';
    default:
      return 'URL';
  }
};

export const toChannelIcon = (channelType: IChannelType) => {
  switch (channelType) {
    case IChannelType.Email:
      return <Mail size={ICON_SIZE} />;
    case IChannelType.Facebook:
      return <Facebook size={ICON_SIZE} />;
    case IChannelType.Instagram:
      return <Instagram size={ICON_SIZE} />;
    case IChannelType.App:
      return <Smartphone size={ICON_SIZE} />;
    case IChannelType.Other:
      return <Grid size={ICON_SIZE} />;
    case IChannelType.Twitter:
      return <Twitter size={ICON_SIZE} />;
    case IChannelType.Youtube:
      return <Youtube size={ICON_SIZE} />;
    case IChannelType.Flipboard:
      return (
        <img
          src="/images/logos/flipboard.svg"
          alt="Flipboard"
          width={ICON_SIZE}
          height={ICON_SIZE}
        />
      );
    case IChannelType.Pinterest:
      return (
        <img
          src="/images/logos/pinterest.svg"
          alt="Pinterest"
          width={ICON_SIZE}
          height={ICON_SIZE}
        />
      );
    case IChannelType.Tiktok:
      return (
        <img
          src="/images/logos/tiktok.svg"
          alt="TikTok"
          width={ICON_SIZE}
          height={ICON_SIZE}
        />
      );
  }
};
