import React from 'react';
import { styled } from '../../../emotion';
import { COLORS } from '../../../domainTypes/colors';

export enum IAlertStatus {
  INFO,
  WARNING,
  ACTION_REQUIRED,
  SUCCESS
}

type IAlertStatusConfig = {
  bgColor: string;
  color: string;
  message: string;
};

const CONFIG: { [key in IAlertStatus]: IAlertStatusConfig } = {
  [IAlertStatus.SUCCESS]: {
    bgColor: COLORS.green.green2,
    color: COLORS.green.green6,
    message: 'Success'
  },
  [IAlertStatus.INFO]: {
    bgColor: COLORS.blue.blue2,
    color: COLORS.blue.blue8,
    message: 'FYI'
  },
  [IAlertStatus.WARNING]: {
    bgColor: COLORS.gold.gold2,
    color: COLORS.gold.gold8,
    message: 'Warning'
  },
  [IAlertStatus.ACTION_REQUIRED]: {
    bgColor: COLORS.volcano.volcano2,
    color: COLORS.volcano.volcano8,
    message: 'Action Required'
  }
};

const Badge = styled<'div', { status: IAlertStatus }>('div')`
  background-color: ${(p) => CONFIG[p.status].bgColor};
  color: ${(p) => CONFIG[p.status].color};
  padding: 0px 6px;
  font-weight: 600;
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
`;

export const AlertStatus = ({ status }: { status: IAlertStatus }) => {
  return <Badge status={status}>{CONFIG[status].message}</Badge>;
};
