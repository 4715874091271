import { omit, truncate } from 'lodash';
import React, { useState } from 'react';
import {
  Cell,
  Legend,
  LegendPayload,
  LegendProps,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
  TooltipFormatter
} from 'recharts';
import { formatNumber } from '../../Number';
import { CustomTooltip } from '../CustomTooltip';

type OrientationItem = {
  layout: LegendProps['layout'];
  align: LegendProps['align'];
};

type Orientation = {
  horizontal: OrientationItem;
  vertical: OrientationItem;
};

type Props = {
  data: IPieChartCell[];
  onClick?: (d: IPieChartCell, i: number) => void;
  orientation?: keyof Orientation;
  animate?: boolean;
  aspect?: number;
  formatter?: TooltipFormatter;
  extra?: React.ReactNode | null;
  hideLegend?: boolean;
};

export interface IPieChartCell {
  key: string;
  name: string;
  color: string;
  count: number;
}

const legendFormatter = (value: LegendPayload['value'], entry: any) => {
  if (!entry) {
    return null;
  }
  const { percent } = entry.payload;
  const p = formatNumber({ n: percent, format: 'percent' });
  return `${truncate(value, { length: 22 })} (${p})`;
};

const noop = () => {};

const ORIENTATION: Orientation = {
  horizontal: { layout: 'horizontal', align: 'center' },
  vertical: { layout: 'vertical', align: 'right' }
};

const renderActiveShape = (props: any) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill
  } = props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius}
        outerRadius={outerRadius + 12}
        opacity={0.6}
        fill={fill}
      />
    </g>
  );
};

export const PieChartWithLegend = ({
  data,
  onClick,
  orientation = 'vertical',
  animate = false,
  aspect = 2,
  extra = null,
  formatter,
  hideLegend = false
}: Props) => {
  const [activeIndex, setActiveIndex] = useState<
    number[] | number | undefined
  >();
  const { layout, align } = ORIENTATION[orientation];
  const handleClick = onClick
    ? (_: any, i: number) => onClick(data[i], i)
    : noop;

  return (
    <ResponsiveContainer height="99%" aspect={aspect}>
      <PieChart>
        <Pie
          data={data.map((d) => omit(d, 'key'))}
          height={550}
          startAngle={450}
          endAngle={90}
          dataKey="count"
          outerRadius="75%"
          innerRadius="50%"
          isAnimationActive={animate}
          activeShape={renderActiveShape}
          activeIndex={activeIndex}
          onMouseEnter={(_, i) => setActiveIndex(i)}
          onMouseLeave={() => setActiveIndex(undefined)}
        >
          {extra}
          {data.map((c, i) => (
            <Cell
              key={c.key}
              fill={c.color}
              opacity={activeIndex === i || activeIndex === undefined ? 1 : 0.5}
            />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} formatter={formatter} />
        {!hideLegend && (
          <Legend
            align={align}
            iconType="circle"
            layout={layout}
            verticalAlign="middle"
            formatter={legendFormatter}
            onMouseEnter={(_, i) => setActiveIndex(i)}
            onMouseLeave={() => setActiveIndex(undefined)}
            wrapperStyle={{ cursor: onClick ? 'pointer' : 'default' }}
            onClick={handleClick}
          />
        )}
      </PieChart>
    </ResponsiveContainer>
  );
};
