import { Card, Chip, Switch } from '@material-ui/core';
import { orderBy } from 'lodash';
import React from 'react';
import { IColumn } from '../../../../../../../components/Table/Column';
import { VirtualizedSortableTable } from '../../../../../../../components/Table/VirtualizedSortable';
import { COLORS } from '../../../../../../../domainTypes/colors';
import { FeatureCategory } from '../../../../../../../domainTypes/features';
import { ISpace } from '../../../../../../../domainTypes/space';
import { SortDirection } from '../../../../../../../hooks/useSort';
import { CanvasBar } from '../../../../../../../layout/Canvas';
import { Section } from '../../../../../../../layout/Section';
import {
  getFeatureList,
  IFeatureListItem,
  setFeature
} from '../../../../../../services/features';

type Props = {
  space: ISpace;
};

const HEIGHT = 500;

const Category = ({ category }: { category: FeatureCategory }) => {
  if (category === 'Deprecated') {
    return (
      <Chip
        style={{
          backgroundColor: COLORS.purple.purple1,
          color: COLORS.purple.purple8,
          fontWeight: 'bold'
        }}
        label="Deprecated"
      />
    );
  }

  if (category === 'In beta') {
    return (
      <Chip
        style={{
          backgroundColor: COLORS.gold.gold2,
          color: COLORS.gold.gold8,
          fontWeight: 'bold'
        }}
        label="In beta"
      />
    );
  }

  if (category === 'Add-ons') {
    return (
      <Chip
        style={{
          backgroundColor: COLORS.green.green2,
          color: COLORS.green.green8,
          fontWeight: 'bold'
        }}
        label="Paid Add-ons"
      />
    );
  }

  return (
    <Chip
      style={{
        backgroundColor: COLORS.blue.blue2,
        color: COLORS.blue.blue8,
        fontWeight: 'bold'
      }}
      label={category}
    />
  );
};

type SortKey =
  | 'displayName'
  | 'description'
  | 'category'
  | 'accessLevel'
  | 'enabled';

const sortFn = (
  rows: IFeatureListItem[],
  sortBy: SortKey,
  direction: SortDirection
) => {
  return orderBy(
    rows,
    (r: IFeatureListItem) => {
      if (sortBy === 'category') {
        return r.feature.category;
      }
      if (sortBy === 'accessLevel') {
        return r.feature.accessLevel;
      }
      if (sortBy === 'enabled') {
        return r.enabled;
      }
      return r.feature.displayName;
    },
    direction
  );
};

export const Features: React.FC<Props> = ({ space }) => {
  const features = getFeatureList(space.features);

  const COLUMNS: IColumn<IFeatureListItem, SortKey>[] = [
    {
      key: 'displayName',
      head: () => 'Feature',
      cell: (d) => <div>{d.feature.displayName}</div>,
      align: 'left',
      sortable: true,
      defaultDirection: 'asc',
      width: 350,
      flexGrow: 1
    },
    {
      key: 'description',
      head: () => '',
      cell: (d) => <div>{d.feature.description || ''}</div>,
      align: 'left',
      sortable: false,
      defaultDirection: 'asc',
      width: 100,
      flexGrow: 4
    },
    {
      key: 'category',
      head: () => 'Category',
      cell: (d) => <Category category={d.feature.category} />,
      align: 'left',
      sortable: true,
      defaultDirection: 'asc',
      width: 100,
      flexGrow: 1
    },
    {
      key: 'accessLevel',
      head: () => 'Access',
      cell: (d) => <div>{d.feature.accessLevel}</div>,
      align: 'left',
      sortable: true,
      defaultDirection: 'asc',
      width: 100,
      flexGrow: 1
    },
    {
      key: 'enabled',
      head: () => 'Status',
      cell: (d) => (
        <Switch
          checked={d.enabled}
          color="primary"
          onChange={(ev) => setFeature(space, d.name, ev.target.checked)}
        />
      ),
      align: 'right',
      sortable: true,
      defaultDirection: 'asc',
      width: 100,
      flexGrow: 1
    }
  ];
  return (
    <Section>
      <CanvasBar>Features</CanvasBar>
      <Card>
        <VirtualizedSortableTable
          rows={features}
          columns={COLUMNS}
          cellProps={undefined}
          height={HEIGHT}
          margin="normal"
          sortFn={sortFn}
          initialSortColumn={COLUMNS[1]}
        />
      </Card>
    </Section>
  );
};
