import firebase from 'firebase/app';
import moment from 'moment-timezone';
import { generateToDocFn, Doc } from '../../domainTypes/document';
import {
  Daily,
  Frequency,
  Schedule,
  Type,
  Weekly
} from '../../domainTypes/schedule';
import { FS } from '../../versions';
import { store } from '../db';
import { fromMoment } from '../time';

export const toScheduleDoc = generateToDocFn<Schedule>((p) => ({
  ...p,
  active: p.active === undefined ? true : p.active
}));

export const toScheduleDocs = (s: firebase.firestore.QuerySnapshot) =>
  s.docs.map(toScheduleDoc);

export const collection = () => store().collection(FS.schedules);

/*

============ All this code also exists in the backend ============

*/

const getUtcOffset = (now: moment.Moment, frequency: Daily | Weekly) => {
  const zone = moment.tz.zone(frequency.tz);
  return zone ? zone.utcOffset(now.valueOf()) / 60 : 0;
};

const getHour = (now: moment.Moment, frequency: Daily | Weekly) => {
  const offset = getUtcOffset(now, frequency);
  return frequency.hour + offset;
};

const getWeekday = (now: moment.Moment, frequency: Weekly) => {
  const hour = getHour(now, frequency);
  const weekday = frequency.weekDay;
  return hour < 0 ? weekday - 1 : weekday;
};

export const getNextRunDate = (now: moment.Moment, frequency: Frequency) => {
  const nowUtc = now.clone().utc();
  const currentHour = nowUtc.hour();
  if (frequency.type === 'DAILY') {
    const hour = getHour(nowUtc, frequency);
    const nextRun = moment.utc().startOf('d').hour(hour);
    if (hour <= currentHour) {
      nextRun.add(1, 'd');
    }
    return fromMoment(nextRun);
  }

  const currentWeekday = nowUtc.weekday();
  if (frequency.type === 'WEEKLY') {
    const hour = getHour(nowUtc, frequency);
    const weekday = getWeekday(nowUtc, frequency);
    const nextRun = moment.utc().startOf('d').hour(hour).weekday(weekday);
    if (weekday < currentWeekday) {
      nextRun.add(1, 'w');
    }
    if (weekday === currentWeekday) {
      if (hour <= currentHour) {
        nextRun.add(1, 'w');
      }
    }
    return fromMoment(nextRun);
  }

  return null;
};

export const updateOrCreateSchedule = (
  docId: string,
  data: Partial<Schedule> & { frequency: Frequency }
) => {
  return collection()
    .doc(docId)
    .set(
      {
        ...data,
        nextRun: getNextRunDate(moment.utc(), data.frequency)
      },
      { merge: true }
    );
};

export const changeScheduleActiveState = (
  schedule: Doc<Schedule>,
  active: boolean
) => {
  return updateOrCreateSchedule(schedule.id, {
    ...schedule.data,
    active
  });
};

export const getSchedulesForSpaceQuery = (spaceId: string) =>
  collection().where('spaceId', '==', spaceId);

export const getSchedulesForSpaceByTypeQuery = (spaceId: string, type: Type) =>
  collection().where('spaceId', '==', spaceId).where('type', '==', type);

export const getScheduleByIdQuery = (id: string) => collection().doc(id);
