import moment from 'moment-timezone';
import { isEmpty, isNil } from 'lodash';
import { isDayString } from '../../domainTypes/analytics';
import { getCurrentUser } from '../../services/currentUser';
import {
  readTimeframe,
  TimeframeDefinition
} from '../../domainTypes/timeframe';

export const parseTimeframe = (
  query: string | undefined | null
): TimeframeDefinition | null => {
  if (isNil(query) || isEmpty(query)) return null;
  try {
    return readTimeframe(query);
  } catch (e) {
    if (!isEmpty(query)) {
      const [startString = '', endString = ''] = query.split('---');
      if (isDayString(startString) && isDayString(endString)) {
        const tz = getCurrentUser().tz;
        const start = moment.tz(startString, tz).toISOString();
        const end = moment.tz(endString, tz).toISOString();
        return {
          range: { kind: 'custom', start, end },
          comparison: { kind: 'previous' }
        };
      }
    }
    return null;
  }
};
