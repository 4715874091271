import { compact, keyBy, mapValues, uniq } from 'lodash';
import { useMemo } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { CachedAdvertiser } from '../../domainTypes/advertiser';
import { Timeframe, UNKNOWN } from '../../domainTypes/analytics';
import { CurrencyCode } from '../../domainTypes/currency';
import { generateToDocFn } from '../../domainTypes/document';
import { IPartner } from '../../domainTypes/partners';
import {
  EarningsArgsGroupedInTimeframe,
  EarningsRespGroupedInTimeframe,
  EMPTY_EARNING,
  IEarning,
  IEarningMinimalField,
  SalesFilterArgs,
  toEarningFromMinimal
} from '../../domainTypes/performance';
import { FS } from '../../versions';
import { toComparableTimeframe } from '../analytics';
import { store, useMappedLoadingValue } from '../db';
import { getKnownPartnerForKeyUnsafe } from '../partner';
import { useEarnings } from '../sales/earnings';
import { timeframeToMs } from '../time';

export const toAdvertiserDoc = generateToDocFn<CachedAdvertiser>();
export const advertiserCollection = () => store().collection(FS.advertisers);

export const getAdvertisersQuery = (spaceId: string) =>
  advertiserCollection().where('spaceId', '==', spaceId);

export const getAdvertiserQuery = (spaceId: string, advertiserId: string) => {
  return getAdvertisersQuery(spaceId).where('id', '==', advertiserId);
};

export const getAdvertisersByIds = (spaceId: string, ids: string[]) => {
  return advertiserCollection()
    .where('spaceId', '==', spaceId)
    .where('advertiserId', 'in', ids);
};

export const searchAdvertisers = (spaceId: string, query: string) => {
  return advertiserCollection()
    .where('spaceId', '==', spaceId)
    .where('query', '>=', query)
    .where('query', '<=', query + '\uf8ff')
    .orderBy('query')
    .limit(50);
};

export const useAdvertisers = (spaceId: string) => {
  return useMappedLoadingValue(
    useCollection(
      advertiserCollection().where('spaceId', '==', spaceId)
      //.orderBy('name', 'asc')
    ),
    (s) => s.docs.map((d) => toAdvertiserDoc(d))
  );
};

export type AdvertiserEarnings = {
  advertiserName: string;
  advertiserId: string | null;
  partner: IPartner;
  curr: IEarning;
  prev: IEarning;
};

/**
 * @deprecated Unused, after app/src/features/PerformanceNew/pages/Advertisers/index.tsx is cleaned up
 */
export const useEarningsPerAdvertiser = (
  spaceId: string,
  timeframe: Timeframe,
  compare: boolean,
  currency: CurrencyCode,
  q: Omit<SalesFilterArgs, 'dates'>
) => {
  const queries: EarningsArgsGroupedInTimeframe[] = useMemo(() => {
    const RELEVANT_EARNINGS_FIELDS: IEarningMinimalField[] = [
      'ct',
      'oct',
      'ia',
      'ac',
      'pt',
      'oca',
      'nl',
      'cl',
      'qt'
    ];

    return compact([
      {
        type: 'groupedInTimeframe',
        d: {
          fields: RELEVANT_EARNINGS_FIELDS,
          ...q,
          groupBy: ['advertiser_name', 'advertiser_id', 'partner_key'],
          dates: { ...timeframeToMs(timeframe), column: 'sale_date' },
          currency
        }
      },
      compare && {
        type: 'groupedInTimeframe',
        d: {
          fields: RELEVANT_EARNINGS_FIELDS,
          ...q,
          groupBy: ['advertiser_name', 'advertiser_id', 'partner_key'],
          dateColumn: 'sale_date',
          dates: {
            ...timeframeToMs(toComparableTimeframe(timeframe)),
            column: 'sale_date'
          },
          currency
        }
      }
    ]);
  }, [timeframe, compare, currency, q]);

  return useMappedLoadingValue(
    useEarnings<EarningsRespGroupedInTimeframe[]>(spaceId, queries, currency),
    (r) => {
      // prev is empty if we are in non-compare mode
      console.log('useEarningsPerAdvertiser', r.time, r);
      const [curr, prev = {}] = r.res.map((x) => {
        return mapValues(
          keyBy(x.d, (t) => {
            const advertiserName =
              (t.group['advertiser_name'] as string) || UNKNOWN;
            const advertiserId =
              (t.group['advertiser_id'] as string) || UNKNOWN;

            const partnerKey = t.group['partner_key'];

            return `${advertiserName}---${advertiserId}---${partnerKey}`;
          }),
          (v) => toEarningFromMinimal(v.d)
        );
      });
      const allKeys = uniq([...Object.keys(curr), ...Object.keys(prev)]);
      return allKeys.map<AdvertiserEarnings>((key) => {
        const [advertiserName, advertiserId, partnerKey] = key.split('---');
        const adId = advertiserId === UNKNOWN ? null : advertiserId;
        const partner = getKnownPartnerForKeyUnsafe(partnerKey);
        return {
          advertiserName,
          advertiserId: adId,
          partner,
          curr: curr[key] || EMPTY_EARNING(currency),
          prev: prev[key] || EMPTY_EARNING(currency)
        };
      });
    }
  );
};
