import React, { ComponentProps } from 'react';
import { XAxis } from 'recharts';
import { ChartContext } from './chart-context';
import { tickFormatter } from './service/formatters';
import { AnalyticsIntervalUnit } from '../../../domainTypes/analytics_v2';

type CustomXAxisProps = Omit<ComponentProps<typeof XAxis>, 'tick'> & {
  tick?: object;
};

export const xAxis = (
  { startsInCurrentYear }: ChartContext,
  intervalUnit: AnalyticsIntervalUnit = 'day',
  { tick = {}, ...restXAxisProps }: Partial<CustomXAxisProps> = {}
) => (
  <XAxis
    key="timeseries-x-axis"
    dataKey="timestamp"
    xAxisId="timestamp"
    tickFormatter={tickFormatter(startsInCurrentYear, intervalUnit)}
    tick={{
      fill: '#9b9b9b',
      transform: 'translate(0, 8)',
      fontSize: 14,
      ...tick
    }}
    textAnchor="end"
    tickSize={0}
    minTickGap={60}
    stroke="cwBBB"
    {...restXAxisProps}
  />
);
