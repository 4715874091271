import {
  ButtonGroup,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import React, { useRef, useState } from 'react';
import { ChevronDown } from 'react-feather';

export type ButtonSplitProps = {
  otherActions: {
    label: React.ReactNode;
    onClick: () => void;
  }[];
} & ButtonProps;

export const ButtonSplit = ({ otherActions, ...props }: ButtonSplitProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { variant, color, ...buttonProps } = props;
  return (
    <>
      <ButtonGroup variant={variant} color={color} ref={anchorRef}>
        <Button {...buttonProps} />
        <Button
          onClick={() => setOpen((o) => !o)}
          size="small"
          style={{ minWidth: 0 }}
        >
          <ChevronDown size={14} />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <MenuList>
                {otherActions.map((action, i) => (
                  <MenuItem key={i} onClick={() => action.onClick()}>
                    {action.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
