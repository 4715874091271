import React from 'react';
import Typography from '@material-ui/core/Typography';
import { styled } from '../../../../emotion';
import { FlexContainer } from '../../../../layout/Flex';

export const FieldContainer = styled('div')`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  gap: ${(t) => t.theme.spacing(1)}px;
`;

export const Header: React.FC = ({ children }) => (
  <Typography
    variant="body2"
    style={{ color: '#6d6d6d', fontWeight: 'bold' }}
    paragraph
  >
    {children}
  </Typography>
);

export const Caption: React.FC<{ underline?: boolean }> = ({
  children,
  underline
}) => (
  <Typography
    variant="body2"
    paragraph
    style={{
      color: '#333',
      textDecorationLine: underline ? 'underline' : undefined
    }}
  >
    {children}
  </Typography>
);

export const Label: React.FC = ({ children }) => (
  <Typography variant="caption" style={{ color: '#828282', fontSize: '12px' }}>
    {children}
  </Typography>
);

export const Stack: React.FC<{ spacing?: number }> = ({
  children,
  spacing = 2
}) => (
  <FlexContainer direction="column" spacing={spacing} alignItems="flex-start">
    {children}
  </FlexContainer>
);
