import {
  compact,
  flatten,
  groupBy,
  mapValues,
  maxBy,
  minBy,
  omit,
  sortBy,
  uniq
} from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useMemo } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Timeframe } from '../../../domainTypes/analytics';
import { CurrencyCode } from '../../../domainTypes/currency';
import { Doc, generateToDocFn } from '../../../domainTypes/document';
import { IPartner } from '../../../domainTypes/partners';
import {
  aggregateSales,
  EarningsArgs,
  EarningsRespInTimeframe,
  IConvertedSale,
  IEarning,
  IEarningMinimalField,
  ISale,
  ISaleAmount,
  ITrackedConvertedSale,
  ITrackedSale,
  SalesFilterArgs,
  toEarningFromMinimal
} from '../../../domainTypes/performance';
import { useLoadingValue } from '../../../hooks/useLoadingValue';
import {
  applyConversionRate,
  getLatestExchangeRate
} from '../../../services/currency';
import { useCurrentUser } from '../../../services/currentUser';
import {
  LoadingValue,
  refreshTimestamp,
  store,
  useMappedLoadingValue
} from '../../../services/db';
import { callFirebaseFunction } from '../../../services/firebaseFunctions';
import {
  CollectionListener,
  listenToCollections
} from '../../../services/firecache/collectionListener';
import { getKnownPartnerForKey } from '../../../services/partner';
import { flushSalesCacheForSpace } from '../../../services/sales/cache';
import { useEarningsSingle } from '../../../services/sales/earnings';
import {
  areOverlappingRanges,
  fromMoment,
  getMomentInTz,
  isBetweenMs,
  MomentRange,
  timeframeToMomentRange,
  timeframeToTimestampRange,
  Timestamp,
  toEndOfPreviousDay,
  toStartOfNextDay
} from '../../../services/time';
import { toDomain } from '../../../services/url';
import { CF, FS } from '../../../versions';
import { toPercent } from './handlers/helpers';

export const refreshSaleTimestamps = <T extends ISale>(s: T): T => {
  s.saleDate = refreshTimestamp(s.saleDate);
  s.completionDate = refreshTimestamp(s.completionDate);
  if (s.clickDate) {
    s.clickDate = refreshTimestamp(s.clickDate);
  }
  return s;
};

export const toTrackedSaleDoc = generateToDocFn<ITrackedSale>((d) => {
  const x: any = d;
  if (!d.sale.origin && x.sale.domain) {
    d.sale.origin = x.sale.domain;
  }

  if (!d.sale.origin && d.pageUrl) {
    d.sale.origin = toDomain(d.pageUrl);
  }

  if (d.sale.origin && !d.origin) {
    d.origin = d.sale.origin;
  }

  const { click } = d;
  if (!d.sale.advertiserId) {
    d.sale.advertiserId = '';
  }
  if (!d.sale.advertiserName) {
    d.sale.advertiserName = '';
  }
  if (!click) {
    return d;
  }
  if (d.device && d.pageUrl) {
    return d;
  }
  d.device = d.device || click.device || 'unknown';
  d.pageUrl = d.pageUrl || click.href || null;
  return d;
});

const collection = () => store().collection(FS.sales);

/**
 * @deprecated unused
 */
export const importManualSales = async (
  sales: ISale[],
  spaceId: string
): Promise<void> => {
  return callFirebaseFunction<{ sales: ITrackedSale[] }>(
    CF.reporting.importSales,
    {
      sales,
      spaceId
    }
  ).then((resp) => {
    console.log('importManualSales', resp);
    return flushSalesCacheForSpace(spaceId);
  });
};

export const deleteSales = async (
  spaceId: string,
  docSaleIds: Array<Doc<ISale>['id']>
) => {
  return callFirebaseFunction(CF.reporting.deleteSales, {
    spaceId,
    docSaleIds
  }).then((resp) => {
    console.log('deleteSales', resp);
    return flushSalesCacheForSpace(spaceId);
  });
};

export const saveSalesFromReportsViaCf = (
  spaceId: string,
  reportIds: string[]
) => {
  return callFirebaseFunction<{ sales: { count: number } }>(
    CF.reporting.saveSalesFromReports,
    {
      spaceId,
      reportIds
    }
  ).then((res) => {
    flushSalesCacheForSpace(spaceId);
    return res;
  });
};

const convertSale = async (
  sale: ISale,
  currency: CurrencyCode,
  tz: string
): Promise<IConvertedSale> => {
  const originalAmount = sale.amount;
  const conversionRate = await getLatestExchangeRate(
    originalAmount.currency,
    currency
  );
  const convert = (n: number | null) =>
    n ? applyConversionRate(n, conversionRate) : null;
  const amount: ISaleAmount = {
    currency,
    price: convert(originalAmount.price),
    revenue: convert(originalAmount.revenue),
    commission: convert(originalAmount.commission)!
  };
  return {
    ...sale,
    amount,
    originalAmount,
    conversionRate
  };
};

export const convertTrackedSale = async (
  trackedSale: ITrackedSale,
  currency: CurrencyCode,
  tz: string
): Promise<ITrackedConvertedSale> => {
  const sale = await convertSale(trackedSale.sale, currency, tz);
  const clickDate = trackedSale.click?.createdAt || trackedSale.sale.clickDate;
  return {
    ...trackedSale,
    sale,
    dates: {
      sale: getMomentInTz(tz, sale.saleDate.toMillis()),
      completion: sale.completionDate
        ? getMomentInTz(tz, sale.completionDate.toMillis())
        : null,
      click: clickDate ? getMomentInTz(tz, clickDate.toMillis()) : null
    }
  };
};

export const convertTrackedSales = (
  sales: ITrackedSale[],
  currency: CurrencyCode,
  tz: string
) => Promise.all(sales.map((s) => convertTrackedSale(s, currency, tz)));

export const unconvertSale = (convertedSale: IConvertedSale): ISale => {
  const sale: ISale = omit(
    Object.assign({}, convertedSale, { amount: convertedSale.originalAmount }),
    ['originalAmount', 'conversionRate']
  );
  return sale;
};

export const unconvertTrackedSale = (
  convertedSale: ITrackedConvertedSale
): ITrackedSale => {
  const s: ITrackedSale = omit(
    Object.assign({}, convertedSale, {
      sale: unconvertSale(convertedSale.sale)
    }),
    'dates'
  );
  return s;
};

export const unconvertTrackedSales = (
  convertedSales: ITrackedConvertedSale[]
) => {
  return convertedSales.map(unconvertTrackedSale);
};

const _getSalesQueryByTimestampRangeForField = (
  spaceId: string,
  range: { start: Timestamp; end: Timestamp },
  field: 'sale.saleDate' | 'queryDate'
) => {
  return collection()
    .where('spaceId', '==', spaceId)
    .where(field, '>=', range.start)
    .where(field, '<', range.end)
    .orderBy(field, 'desc');
};

const _getSalesQueryByMomentRangeForField = (
  spaceId: string,
  range: { start: moment.Moment; end: moment.Moment },
  field: 'sale.saleDate' | 'queryDate',
  dir: 'asc' | 'desc' = 'desc'
) => {
  return collection()
    .where('spaceId', '==', spaceId)
    .where(field, '>=', fromMoment(range.start))
    .where(field, '<', fromMoment(range.end))
    .orderBy(field, dir);
};

const _getSalesInTimeframeQueryForField = (
  spaceId: string,
  tf: Timeframe,
  field: 'sale.saleDate' | 'queryDate'
) => {
  return _getSalesQueryByTimestampRangeForField(
    spaceId,
    timeframeToTimestampRange(tf),
    field
  );
};

const getSalesInTimeframeQuery = (spaceId: string, tf: Timeframe) => {
  console.log(tf, timeframeToMomentRange(tf));
  return _getSalesInTimeframeQueryForField(spaceId, tf, 'sale.saleDate');
};

const getSalesInClickTimeframeQuery = (spaceId: string, tf: Timeframe) => {
  return _getSalesInTimeframeQueryForField(spaceId, tf, 'queryDate');
};

export const getConvertedSalesInTimeframe = (
  spaceId: string,
  tf: Timeframe,
  currency: CurrencyCode
) => {
  return getSalesInTimeframeQuery(spaceId, tf)
    .get()
    .then((s) => {
      const docs = s.docs.map(toTrackedSaleDoc);
      return Promise.all(
        docs.map(async (d) => ({
          id: d.id,
          data: await convertTrackedSale(d.data, currency, tf.tz)
        }))
      );
    });
};

const useSalesConverter = (
  loadingValue: LoadingValue<Doc<ITrackedSale>[]>,
  currency: CurrencyCode,
  tz: string
): LoadingValue<Doc<ITrackedConvertedSale>[]> => {
  const [docs, loadingDocs, errorDocs] = loadingValue;
  const { value, loading, error, setValue, setError } = useLoadingValue<
    Doc<ITrackedConvertedSale>[]
  >(undefined, true);

  useEffect(() => {
    if (docs) {
      Promise.all(
        docs.map<Promise<Doc<ITrackedConvertedSale>>>((d) => {
          return convertTrackedSale(d.data, currency, tz).then((data) => ({
            id: d.id,
            collection: d.collection,
            data
          }));
        })
      ).then((xs) => setValue(xs));
    }
    if (errorDocs) {
      setError(errorDocs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docs, errorDocs, currency]);

  return [value, loading || loadingDocs, error];
};

type SalesListener = {
  start: moment.Moment;
  end: moment.Moment;
  listener: CollectionListener<ITrackedSale>;
};
const salesListeners: { [spaceId: string]: SalesListener[] } = {};

const createSalesListener = (spaceId: string, range: MomentRange) => {
  const query = _getSalesQueryByMomentRangeForField(
    spaceId,
    range,
    'sale.saleDate',
    'asc'
  );
  const listener = {
    ...range,
    listener: new CollectionListener(query, toTrackedSaleDoc)
  };
  const ss = salesListeners[spaceId] || [];
  ss.push(listener);
  ss.sort((a, b) => a.start.valueOf() - b.start.valueOf());
  salesListeners[spaceId] = ss;
  return listener;
};

/**
 * @deprecated
 */
export const useSalesInTimeframeBySpaceId = (
  spaceId: string,
  tf: Timeframe,
  currency: CurrencyCode
): LoadingValue<Doc<ITrackedConvertedSale>[]> => {
  const v = useLoadingValue<Doc<ITrackedSale>[]>(undefined, true);
  const { start, end, tz } = tf;
  const range = useMemo(() => timeframeToMomentRange({ start, end, tz }), [
    start,
    end,
    tz
  ]);

  useEffect(() => {
    if (!v.loading) {
      v.setLoading(true);
    }
    const listeners = (salesListeners[spaceId] = salesListeners[spaceId] || []);
    const matching = listeners.filter((l) => areOverlappingRanges(l, range));
    if (!matching.length) {
      // slightly wasteful - we could return early here, but better just to have one codepath
      matching.push(createSalesListener(spaceId, range));
    }
    // generally sorted, so we could also just look at the first and the last
    const min = minBy(matching, (l) => l.start.valueOf())!;
    const max = maxBy(matching, (l) => l.end.valueOf())!;

    // make sure that upper and lower boundary are covered
    if (range.start.isBefore(min.start)) {
      matching.unshift(
        createSalesListener(spaceId, {
          start: range.start,
          end: toEndOfPreviousDay(min.start)
        })
      );
    }
    if (range.end.isAfter(max.end)) {
      matching.push(
        createSalesListener(spaceId, {
          start: toStartOfNextDay(max.end),
          end: range.end
        })
      );
    }
    // After making sure that upper and lower boundary are present,
    // we need to make sure that the listeners cover a continous range.
    // Imagine a case like this
    //
    // requested:       10                           20
    // cached:        9        12       15   17   19     21
    //
    // 12-15 and 17-19 are missing. We can walk through the matching parts we have,
    // and fill in the gaps.
    const continuous = matching.reduce<SalesListener[]>((m, l, i) => {
      if (i !== 0) {
        const prev = matching[i - 1];
        if (!prev.end.isSame(toEndOfPreviousDay(l.start))) {
          m.push(
            createSalesListener(spaceId, {
              start: toStartOfNextDay(prev.end),
              end: toEndOfPreviousDay(l.start)
            })
          );
        }
      }
      m.push(l);
      return m;
    }, []);

    return listenToCollections(
      continuous.map((l) => l.listener),
      (docs) => {
        const s = range.start.valueOf();
        const e = range.end.valueOf();
        const filtered = docs.filter((d) =>
          isBetweenMs(d.data.sale.saleDate.toMillis(), s, e)
        );
        v.setValue(filtered);
      },
      v.setError
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId, range]);

  return useSalesConverter([v.value, v.loading, v.error], currency, tf.tz);
};

/**
 * @deprecated
 */
export const useSalesInTimefame = (
  timeframe: Timeframe,
  currency: CurrencyCode
) => {
  const { space } = useCurrentUser();
  return useSalesInTimeframeBySpaceId(space.id, timeframe, currency);
};

/**
 * @deprecated
 */
export const useSalesInTimeFrameForPageBySpaceId = (
  spaceId: string,
  href: string,
  tf: Timeframe,
  currency: CurrencyCode
) => {
  const value = useMappedLoadingValue(
    useCollection(
      getSalesInClickTimeframeQuery(spaceId, tf).where('pageUrl', '==', href)
    ),
    (s) => s.docs.map(toTrackedSaleDoc)
  );
  return useSalesConverter(value, currency, tf.tz);
};

export const aggregateSalesBy = <T extends ITrackedSale>(
  sales: T[],
  currency: CurrencyCode,
  keyFn: (sale: T) => string
) => {
  const grouped = groupBy(sales, keyFn);
  return mapValues(grouped, (sales) =>
    aggregateSales(
      sales.map((s) => s.sale),
      currency
    )
  );
};

export const getConversionRate = (earnings: IEarning, clicks: number) => {
  if (clicks === 0) {
    return 0;
  }
  return toPercent(earnings.totalCount, clicks);
};

export const getEarningsPerClick = (earnings: IEarning, clicks: number) => {
  if (clicks === 0) {
    return 0;
  }
  return earnings.total / clicks;
};

export const getPartnersFromSales = (sales: ITrackedSale[]): IPartner[] => {
  const partnerDict = sales.reduce<{ [partnerKey: string]: IPartner | null }>(
    (m, s) => {
      const { partnerKey } = s.sale;
      if (m[partnerKey] === undefined) {
        m[partnerKey] = getKnownPartnerForKey(partnerKey);
      }
      return m;
    },
    {}
  );
  return sortBy(compact(Object.values(partnerDict)), (p) => p.name);
};

export const useSalesByTrackingLabel = (
  spaceId: string,
  trackingLabel: string,
  currency: CurrencyCode,
  tz: string
) => {
  const v = useMappedLoadingValue(
    useCollection(
      collection()
        .where('spaceId', '==', spaceId)
        .where('sale.trackingLabel', '==', trackingLabel)
    ),
    (s) => s.docs.map(toTrackedSaleDoc)
  );
  return useSalesConverter(v, currency, tz);
};

const memoizedGetAllPresenetNonAffilimateTrackingLabels = (() => {
  let labels: Promise<string[]> | null;
  return (spaceId: string) => {
    if (!labels) {
      labels = callFirebaseFunction<string[]>(
        CF.reporting.getAllPresentNonAffilimateTrackingLabels,
        { spaceId }
      );
    }
    return labels;
  };
})();

export const getAllPresentNonAffilimateTrackingLabels = async (
  spaceId: string
) => {
  const listeners = salesListeners[spaceId] || [];
  const result: Set<string> = new Set();
  await Promise.all([
    Promise.all(listeners.map((l) => l.listener.get())).then((salesLists) => {
      uniq(
        compact(
          flatten(salesLists).map((s) => {
            if (!s.data.sale.trackingId && s.data.sale.trackingLabel) {
              return s.data.sale.trackingLabel;
            }
            return null;
          })
        )
      ).forEach((l) => result.add(l));
    }),
    memoizedGetAllPresenetNonAffilimateTrackingLabels(spaceId).then((labels) =>
      labels.forEach((l) => result.add(l))
    )
  ]);

  return [...result].sort();
};

export const useTotals = (
  spaceId: string,
  q: SalesFilterArgs,
  currency: CurrencyCode,
  fields?: IEarningMinimalField[]
) => {
  const query: EarningsArgs = useMemo(() => {
    const args: EarningsArgs = {
      type: 'inTimeframe',
      d: { ...q, currency }
    };

    if (fields) {
      args.d.fields = fields;
    }

    return args;
  }, [q, currency, fields]);
  return useMappedLoadingValue(
    useEarningsSingle<EarningsRespInTimeframe>(spaceId, query, currency),
    (r) => toEarningFromMinimal(r.res.d)
  );
};
