
import React, { useContext, useState } from 'react';
import { SwitchWithLabel } from '../../components/SwitchWithLabel';
import { useAdminClaim } from '../auth';


// See if this can be combined with the sales cache, so that there's only one provider
// for all these PG related caches
export const ExperimentalContext = React.createContext<[
  active: boolean,
  setActive: (nextActive: boolean) => void
]>([false, () => null]);

const isLocalhost = () => {
  try {
    return window.location.hostname === 'localhost';
  } catch {
    return false;
  }
}

const hasExperimentalQueryParam = () => {
  try {
    return window.location.search.includes('experimental=true');
  } catch {
    return false;
  }
}


export const ExperimentalProvider: React.FC = ({ children }) => {
  // While this could be handling a query param, we specifically do NOT want to do that,
  // because this would make this hook dependendant on route changes and potentially trigger
  // too many updates.
  // Let's just keep this simple. It's initial state looks at it, but then no more
  const state = useState(hasExperimentalQueryParam());
  return (
    <ExperimentalContext.Provider value={state}>
      {children}
    </ExperimentalContext.Provider>
  );
};

export const useExperimentalContext = () => {
  return useContext(ExperimentalContext);
};

export const ExperimentalSwitch = () => {
  const [active, setActive] = useExperimentalContext();
  const [isAdmin] = useAdminClaim();
  if (!isAdmin || !isLocalhost()) {
    return null;
  }
  return (
    <SwitchWithLabel
      checked={active}
      onChange={setActive}
      label={'Exp'}
    />
  )
}
