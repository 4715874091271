import { Metric } from '../../../../../services/analyticsV2/metrics';

export const availableMetrics: Metric[] = [
  'c',
  'epc_net',
  'order_count_net',
  'quantity_net',
  'aov_net',
  'avg_rate_net',
  'commission_sum_net',
  'gmv_sum_net'
];
