import { compact, isEqual, take } from 'lodash';
import pluralize from 'pluralize';
import React, { useMemo, useState } from 'react';
import {
  AnalyticsFilter,
  AnalyticsQuery,
  ISOTimeRange
} from '../../../../domainTypes/analytics_v2';
import { COUNTRY_ABBREVIATIONS } from '../../../../domainTypes/country';
import { useCurrentUser } from '../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../services/db';
import { CountryFilterDef } from '../../../../domainTypes/filters';
import { Metric, metricName } from '../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import { FlagWithLabel } from '../../../Flag';
import {
  createCountryFilterDefinition,
  validateFilterDefinition
} from '../filters';
import { DimensionMenuComponent } from './Dimension';
import { BASIC_MODES, FilterMenu } from './FilterMenu';
import {
  OptionsList,
  SelectorLoader,
  SelectorShell,
  TOP_N,
  useCollectionState
} from './Selector';

const CODES = COUNTRY_ABBREVIATIONS;

interface CountriesListProps {
  toggleValue: (country: string) => void;
  focusValue: (country: string) => void;
  orderBy: Metric;
  range: ISOTimeRange;
  filters: AnalyticsFilter[];
  value: Array<string>;
}

const CountriesList: React.FC<CountriesListProps> = ({
  range,
  filters,
  orderBy,
  value,
  toggleValue,
  focusValue
}) => {
  const { space } = useCurrentUser();
  const [search, setSearch] = useState('');

  const query = useMemo<AnalyticsQuery>(
    () => ({
      range,
      select: [orderBy],
      groupBy: ['country'],
      orderBy: [
        {
          field: orderBy,
          direction: 'DESC'
        }
      ],
      filters: compact([
        ...filters,
        {
          field: 'country',
          condition: 'not in',
          values: ['']
        }
      ])
    }),
    [range, orderBy, filters]
  );

  const [
    countryCodes = [],
    loading
  ] = useMappedLoadingValue(useAnalyticsQueryV2(space.id, query), (response) =>
    response.rows.map((row) => row.group.country)
  );

  const matchingCodes = useMemo(() => {
    const searchString = search.trim().toLowerCase();
    return countryCodes.filter((code) => {
      const name = CODES[code];
      return (
        (name && name.toLowerCase().includes(searchString)) ||
        code.toLowerCase().includes(searchString)
      );
    });
  }, [countryCodes, search]);

  const topCodes = useMemo(() => take(matchingCodes, TOP_N), [matchingCodes]);

  const hasMoreOptions = useMemo(() => matchingCodes.length > TOP_N, [
    matchingCodes
  ]);

  const options = useMemo(() => {
    const promoted = value.filter((code) => !topCodes.includes(code));
    return [...promoted, ...topCodes].map((countryCode) => ({
      value: countryCode,
      label: <FlagWithLabel code={countryCode} />
    }));
  }, [topCodes, value]);

  return (
    <SelectorShell label="Country" search={search} setSearch={setSearch}>
      {loading ? (
        <SelectorLoader />
      ) : (
        <OptionsList
          options={options}
          selectedValues={value}
          toggle={toggleValue}
          focus={focusValue}
          hasMore={hasMoreOptions}
          caption={`Top ${pluralize('country')} by ${metricName(orderBy)}`}
        />
      )}
    </SelectorShell>
  );
};

export const CountryMenu: DimensionMenuComponent<CountryFilterDef> = ({
  range,
  definition,
  filters,
  onSave,
  orderBy
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const [value, toggleValue] = useCollectionState(definition.v);
  const newDefinition = useMemo<CountryFilterDef>(
    () => createCountryFilterDefinition(value),
    [value]
  );
  const enableSave =
    validateFilterDefinition(newDefinition) &&
    !isEqual(definition, newDefinition);

  const focusValue = (country: string) => {
    onSave(createCountryFilterDefinition([country]));
  };

  return (
    <FilterMenu>
      <FilterMenu.Header dimension="country">
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <CountriesList
          filters={filters}
          orderBy={orderBy}
          range={range}
          toggleValue={toggleValue}
          focusValue={focusValue}
          value={value}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer definition={definition}>
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={`Filter by ${pluralize('country', value.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
