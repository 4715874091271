import { useTypedStringQueryParam } from '../../../../../../routes';

export type ReportMetric = typeof reportMetrics[number];

export const reportMetrics = [
  'p',
  'c',
  'v',
  'commission_sum_net',
  'epc_net',
  'rpm_net'
] as const;

export const useReportMetric = () =>
  useTypedStringQueryParam<ReportMetric>('metric', 'c');
