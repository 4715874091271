import { DialogActions } from '@material-ui/core';
import React from 'react';
import { css, styled } from '../../emotion';
import { useTheme } from '../../themes';

const Left = styled('div')`
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  gap: ${(p) => p.theme.spacing()}px;
`;

const Center = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  gap: ${(p) => p.theme.spacing()}px;
`;

const Right = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 1 auto;
  gap: ${(p) => p.theme.spacing()}px;
`;

export const DialogActionsWithSlots = ({
  left,
  center,
  right,
  variant
}: {
  left?: React.ReactNode;
  center?: React.ReactNode;
  right?: React.ReactNode;
  variant?: 'normal' | 'compactGray' | 'compact';
}) => {
  const th = useTheme();
  return (
    <DialogActions
      classes={{
        spacing: css(() => ({ justifyContent: 'space-between !important' })),
        root:
          variant === 'normal'
            ? undefined
            : css(() => ({
                '&&': {
                  borderTop: th.custom.border.standard,
                  background:
                    variant === 'compactGray' ? 'lightgray' : undefined,
                  paddingTop: th.spacing(2),
                  paddingBottom: th.spacing(2),

                  '@media (max-width: 600px)': {
                    flexDirection: 'column',
                    gap: th.spacing(),
                    alignItems: 'stretch'
                  }
                }
              }))
      }}
    >
      <Left>{left}</Left>
      <Center>{center}</Center>
      <Right>{right}</Right>
    </DialogActions>
  );
};
