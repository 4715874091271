import React, { useState } from 'react';
import { Trash2, X as IconDelete } from 'react-feather';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useDialogState } from '../../../../hooks/useDialogState';
import { pluralize } from '../../../../services/pluralize';
import { Doc } from '../../../../domainTypes/document';
import { IReport } from '../../../../domainTypes/performance';
import {
  deleteReports,
  getReportsQuery,
  toReportDoc
} from '../../services/report';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import { useCurrentUser } from '../../../../services/currentUser';
import { chunk } from 'lodash';
import { processParallelCapped } from '../../../../admin/services/denormalization';
import { wait } from '../../../../helpers';
import { AlertBox } from '../../../../components/AlertBox';
import { useFlushAnalyticsV2Cache } from '../../../../services/analyticsV2/cache';

export const DeleteAllReportsButton = ({
  integrationId
}: {
  integrationId: string;
}) => {
  const { space } = useCurrentUser();
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const { enqueueSnackbar } = useSnackbar();
  const [message, setMessage] = useState('');

  const onConfirmDelete = async () => {
    setMessage('Fetching all reports...');

    const reports = await getReportsQuery(space.id)
      .where('integrationId', '==', integrationId)
      .orderBy('createdAt', 'desc')
      .get()
      .then((s) => s.docs.map(toReportDoc));

    const batches = chunk(reports, 5);

    setMessage(
      `Deleting 1 of ${batches.length} ${pluralize(
        'batch',
        batches.length
      )} of reports...`
    );

    return processParallelCapped(
      batches.map((batch, i) => {
        return async () => {
          const count = i + 1;
          setMessage(
            `Deleting ${count} of ${batches.length} ${pluralize(
              'batch',
              batches.length
            )} of reports...`
          );
          return deleteReports(batch).then(() => wait(1000));
        };
      }),
      1
    )
      .then(() => {
        enqueueSnackbar('Your report deletion requests were successful.', {
          variant: 'success'
        });
        closeDialog();
      })
      .catch((err) => {
        enqueueSnackbar('Your report could not be deleted. Please try again.', {
          variant: 'error'
        });
        setMessage('');
        console.error('Error deleting reports', err);
      });
  };

  return (
    <>
      <Tooltip
        title="Delete all commissions ever imported through this integration"
        placement="top"
      >
        <Button onClick={() => openDialog()} style={{ opacity: 0.3 }}>
          <Trash2 size={20} /> &nbsp; Delete all reports
        </Button>
      </Tooltip>
      <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="sm">
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <AlertBox variant="error">
            <Typography variant="body1" component="p">
              <strong>Warning:</strong> This will delete all commissions
              imported with this integration's connection details.
            </Typography>
          </AlertBox>
          <br />
          <Typography variant="body1" component="p" paragraph>
            After beginning the deletion process, it can take several minutes
            after the requests finish for all reports and transactions to be
            cleared. Do not close your browser tab while this process is
            ongoing.
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonWithPromise
            pending={message || 'Starting deletion...'}
            onClick={onConfirmDelete}
          >
            Yes, delete all reports
          </ButtonWithPromise>
          <Button variant="contained" color="default" onClick={closeDialog}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const ReportDeleteButton = ({ report }: { report: Doc<IReport> }) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const { enqueueSnackbar } = useSnackbar();
  const { space } = useCurrentUser();
  const flushCache = useFlushAnalyticsV2Cache();

  const onConfirmDelete = () => {
    return deleteReports([report])
      .then(() => {
        enqueueSnackbar('Your report has been successfully deleted', {
          variant: 'success'
        });
        closeDialog();
        flushCache(space.id);
      })
      .catch((err: any) => {
        enqueueSnackbar('Your report could not be deleted. Please try again.', {
          variant: 'error'
        });
        console.error('Error deleting reports', err);
      });
  };

  return (
    <>
      <Tooltip title="Delete report" placement="top">
        <IconButton onClick={() => openDialog()}>
          <IconDelete size={20} />
        </IconButton>
      </Tooltip>
      <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="sm">
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this report containing{' '}
          <strong>{pluralize('sale', report.data.salesCount, true)}</strong>?
        </DialogContent>
        <DialogActions>
          <ButtonWithPromise pending="Deleting..." onClick={onConfirmDelete}>
            Yes, delete this report
          </ButtonWithPromise>
          <Button variant="contained" color="primary" onClick={closeDialog}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
