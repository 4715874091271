import React from 'react';
import { ButtonWithPromise } from '../../../../../../../components/ButtonWithPromise';
import { isQueueItemActive } from '../../../../../../../domainTypes/page';
import { styled } from '../../../../../../../emotion';
import { CanvasBar } from '../../../../../../../layout/Canvas';
import { Section } from '../../../../../../../layout/Section';
import {
  abortRunningAndQueuedItems,
  countItems,
  QueueCounts,
  requeueErroredItems,
  useQueue,
  requeueItems
} from '../../../../../../services/pageQueue';
import { PageQueueTable } from '../../../../../PageQueue/components/PageQueueTable';

type Props = {
  spaceId: string;
};

const CanvasActions = styled('div')`
  display: flex;
  align-items: center;

  > * {
    margin-left: ${(p) => p.theme.spacing()}px;
  }
`;

const Counts = ({ counts }: { counts: QueueCounts }) => {
  return (
    <div>
      R: {counts.running} | Q: {counts.queued} | D: {counts.done} | A:{' '}
      {counts.aborted} | E: {counts.error} | NS: {counts.noScript} | T:{' '}
      {counts.timedout}
    </div>
  );
};

export const PageQueue: React.FC<Props> = ({ spaceId }) => {
  const [items, loading] = useQueue(spaceId);

  return (
    <Section>
      <CanvasBar>
        <div>Page Queue</div>
        {items && (
          <CanvasActions>
            <Counts counts={countItems(items)} />
            <ButtonWithPromise
              variant="contained"
              color="primary"
              pending="Processing..."
              size="small"
              onClick={() => requeueItems(items)}
            >
              Requeue all
            </ButtonWithPromise>
            {!!items.find((d) => isQueueItemActive(d.data)) && (
              <ButtonWithPromise
                variant="outlined"
                color="secondary"
                pending="Aborting..."
                size="small"
                onClick={() => abortRunningAndQueuedItems(items)}
              >
                Abort all
              </ButtonWithPromise>
            )}
            {!!items.find((d) => d.data.errored) && (
              <ButtonWithPromise
                variant="contained"
                color="primary"
                pending="Requeuing..."
                size="small"
                onClick={() => requeueErroredItems(items)}
              >
                Requeue errored items
              </ButtonWithPromise>
            )}
          </CanvasActions>
        )}
      </CanvasBar>
      <PageQueueTable items={items} loading={loading} />
    </Section>
  );
};
