import { Paper } from '@material-ui/core';
import CircularProgress, {
  CircularProgressProps
} from '@material-ui/core/CircularProgress';
import React from 'react';
import { styled } from '../../emotion';

type Props = {
  label?: React.ReactNode;
  height?: string | number;
  size?: string | number;
  thickness?: number;
  style?: any;
};

const parseHeight = (height: string | number | undefined) => {
  if (typeof height === 'number') {
    return `${height}px`;
  }
  return height || '100%';
};

const LoaderContainer = styled<'div', { height: Props['height'] }>('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: ${(p) => parseHeight(p.height)};
  width: 100%;
`;

export const Loader = (props: Props) => (
  <LoaderContainer height={props.height} style={props.style}>
    <CircularProgress size={props.size} thickness={props.thickness} />
    {props.label && <div>{props.label}</div>}
  </LoaderContainer>
);

export const PaperLoader = (props: Props) => (
  <Paper>
    <Loader {...props} />
  </Paper>
);

export const InlineLoader = ({
  size,
  color
}: {
  size?: number;
  color?: CircularProgressProps['color'];
}) => <CircularProgress size={size || 12} color={color} />;
