import { Avatar, Button, ButtonBase, TextField } from '@material-ui/core';
import { isEqual, times } from 'lodash';
import React, { useEffect, useState } from 'react';
import shortid from 'shortid';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import { ImageUploadDialog } from '../../../../../components/ImageUpload';
import { ICurrentUser } from '../../../../../domainTypes/user';
import { css, styled } from '../../../../../emotion';
import { useDialogState } from '../../../../../hooks/useDialogState';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import {
  FlexContainer,
  FlexContainerVertical
} from '../../../../../layout/Flex';
import { storeFileAndGetDownloadUrl } from '../../../../../services/storage';
import { updateUser } from '../../../../../services/user';

const Grid = styled('div')((p) => ({
  display: 'grid',
  gridTemplateColumns: 'min-content 1fr',
  gridColumnGap: p.theme.spacing(4),
  alignItems: 'center'
}));

const AVATAR_SIZE = 120;

const HintText = styled('div')((p) => ({
  color: p.theme.palette.grey[500],
  fontSize: p.theme.custom.fontSize.s,
  width: AVATAR_SIZE,
  textAlign: 'center'
}));

export const UserProfileCardContent = ({
  currentUser
}: {
  currentUser: ICurrentUser;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const photoURL = currentUser.photoURL || '';
  const displayName = currentUser.displayName || '';

  const [state, setState] = useState({
    photoURL,
    displayName
  });

  const imageUpload = useDialogState();

  useEffect(() => {
    setState({ photoURL, displayName });
  }, [photoURL, displayName]);

  const revertChanges = () => setState({ photoURL, displayName });
  const saveChanges = async () => {
    try {
      await updateUser(currentUser.id, {
        displayName: state.displayName,
        photoURL: state.photoURL
      });
      enqueueSnackbar('User profile updated!', { variant: 'success' });
    } catch (err) {
      console.error('User profile update failed', err);
      enqueueSnackbar('Failed to update user profile', { variant: 'error' });
    }
  };

  const hasChanges = !isEqual({ photoURL, displayName }, state);
  return (
    <>
      <Grid>
        <ButtonBase onClick={() => imageUpload.openDialog()}>
          <FlexContainerVertical alignItems="center" justifyContent="center">
            <Avatar
              className={css((t) => ({
                backgroundColor: 'white',
                width: AVATAR_SIZE,
                height: AVATAR_SIZE,
                border: `1px solid ${t.palette.grey[300]}`,
                color: t.palette.grey[300]
              }))}
              src={state.photoURL}
            />
            <HintText>Click to change your profile picture</HintText>
          </FlexContainerVertical>
        </ButtonBase>

        <FlexContainerVertical fullWidth spacing={2}>
          <TextField
            variant="outlined"
            label="Display name"
            fullWidth
            value={state.displayName}
            onChange={(ev) =>
              setState((x) => ({ ...x, displayName: ev.target.value }))
            }
          />
          <FlexContainer fullWidth>
            <ButtonWithPromise
              variant="contained"
              color="primary"
              disabled={!hasChanges}
              pending="Saving..."
              onClick={() => saveChanges()}
            >
              Save profile
            </ButtonWithPromise>
            <Button
              variant="text"
              color="primary"
              disabled={!hasChanges}
              onClick={() => revertChanges()}
            >
              Revert changes
            </Button>
          </FlexContainer>
        </FlexContainerVertical>
      </Grid>
      <ImageUploadDialog
        title={'Upload profile photo'}
        open={imageUpload.dialogOpen}
        onClose={imageUpload.closeDialog}
        onUpload={async (blob, ext) => {
          const url = await storeFileAndGetDownloadUrl({
            name: `user-profile-photos/${times(4)
              .map(() => shortid())
              .join()}.${ext}`,
            data: blob,
            contentType: blob.type
          });
          setState((x) => ({ ...x, photoURL: url }));
        }}
        circularCrop
      />
    </>
  );
};
