import { BASIC_MODES, FilterMenu } from './FilterMenu';
import { FieldSelectorMenu, useCollectionState } from './Selector';
import React, { useMemo, useState } from 'react';
import {
  createCustomDimensionFilterDefinition,
  useDimensionName,
  validateFilterDefinition
} from '../filters';
import { isEqual } from 'lodash';
import pluralize from 'pluralize';
import {
  ClickDataFilterDefinition,
  ClickDataSlot,
  FilterDefinition
} from '../../../../domainTypes/filters';
import {
  AnalyticsFilter,
  ISOTimeRange
} from '../../../../domainTypes/analytics_v2';
import { Metric } from '../../../../services/analyticsV2/metrics';
import { CustomDimensionOptionLabel } from '../../../CustomDimensionOptionLabel';

interface ClickDataMenuProps {
  slot: ClickDataSlot;
  definition: ClickDataFilterDefinition;
  onSave: (v: FilterDefinition) => void;
  filters: AnalyticsFilter[];
  orderBy: Metric;
  range: ISOTimeRange;
}

export const ClickDataMenu: React.FC<ClickDataMenuProps> = ({
  slot,
  definition,
  onSave,
  filters,
  orderBy,
  range
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const [value, toggleValue] = useCollectionState(definition.v);
  const newDefinition = useMemo<ClickDataFilterDefinition>(
    () => createCustomDimensionFilterDefinition(slot, value),
    [slot, value]
  );
  const enableSave =
    validateFilterDefinition(newDefinition) &&
    !isEqual(definition, newDefinition);

  const focusValue = (value: string) => {
    onSave(createCustomDimensionFilterDefinition(slot, [value]));
  };

  const label = useDimensionName(slot);

  return (
    <FilterMenu>
      <FilterMenu.Header dimension={slot}>
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <FieldSelectorMenu
          label={label}
          selectedValues={value}
          toggleValue={toggleValue}
          focusValue={focusValue}
          queryFilters={filters}
          analyticsField={slot}
          orderBy={orderBy}
          range={range}
          renderOption={(value) => (
            <CustomDimensionOptionLabel slot={slot} value={value} large />
          )}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer definition={definition}>
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={`Filter by ${pluralize('value', value.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
