import { capitalize } from 'lodash';
import React from 'react';
import { IPermissions, RoleId } from '../../../../../domainTypes/permissions';
import { styled } from '../../../../../emotion';
import { COLOR_SCALE } from '../../../../../services/color';

type RoleConfig = {
  color: string;
  description: string;
};

const Color = styled('div')`
  width: 16px;
  height: 16px;
  border-radius: 99px;
`;

const RoleWithColor = styled('div')`
  display: flex;
  gap: ${(p) => p.theme.spacing(1)}px;
  align-items: center;
`;

const ROLE_CONFIG: { [key: string]: RoleConfig } = {
  OWNER: {
    color: COLOR_SCALE[5],
    description: 'Primary account owner with full rights'
  },
  SUPER_ADMIN: {
    color: COLOR_SCALE[59],
    description: 'Full access except removing account owner'
  },
  ADMIN: {
    color: COLOR_SCALE[21],
    description: 'Full access except managing users'
  },
  VIEWER: {
    color: COLOR_SCALE[19],
    description: 'View-only access to reports'
  },
  TOOLS_USER: {
    color: COLOR_SCALE[48],
    description: 'Use tools like the link generator'
  }
};

const toRoleName = (role: string) => capitalize(role).replace('_', ' ');

export const RolesAndPermissionsBadge = ({
  permissions
}: {
  permissions: IPermissions;
}) => {
  const firstRole = permissions.roles[0];

  return <RoleBadge role={firstRole} />;
};

export const RoleBadge = ({
  role,
  showDescription
}: {
  role: RoleId;
  showDescription?: boolean;
}) => {
  const color = ROLE_CONFIG[role] ? ROLE_CONFIG[role].color : '#DDD';

  return (
    <RoleWithColor>
      <Color style={{ backgroundColor: color }} />
      <span style={showDescription ? { fontWeight: 'bold' } : {}}>
        {toRoleName(role)}
      </span>
      {showDescription && ROLE_CONFIG[role] && (
        <span>&ndash;&nbsp; {ROLE_CONFIG[role].description}</span>
      )}
    </RoleWithColor>
  );
};
