import { CompletedCampaign, hasROASGoal } from '../service';
import { CampaignTotalsObject, hasGoal } from '../service/totals';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { formatCurrency, formatNumber } from '../../../components/Number';
import { useSpaceCurrency } from '../../../services/useSpaceCurrency';
import { formatChartNumber } from '../../../components/Charts/Util';
import pluralize from 'pluralize';
import { metricName } from '../../../services/analyticsV2/metrics';

const TrafficGoalSummary: React.FC<{
  totals: CampaignTotalsObject;
  field: 'p' | 'c';
}> = ({ totals, field }) => {
  const metric = totals[field];
  if (!hasGoal(metric)) {
    return null;
  }

  const metricStr = pluralize(metricName(field), 2);
  const valueStr = <strong>{formatChartNumber(metric.value)}</strong>;
  const goalStr = <strong>{formatChartNumber(metric.goal.value)}</strong>;
  if (metric.goal.percentage >= 1) {
    return (
      <Typography variant="body2" paragraph color="textSecondary">
        You've reached your planned goal of {goalStr} {metricStr} and tracked{' '}
        {valueStr} {metricStr}. Congratulations!
      </Typography>
    );
  }
  const progress = (
    <strong>
      {formatNumber({
        n: metric.goal.percentage,
        format: 'percent',
        digits: 1
      })}
    </strong>
  );
  return (
    <Typography variant="body2" paragraph color="textSecondary">
      You've reached {progress} of your planned goal and tracked {valueStr}{' '}
      {metricStr}. Keep up the good work!
    </Typography>
  );
};

const EarningsGoalSummary: React.FC<{
  campaign: CompletedCampaign;
  totals: CampaignTotalsObject;
}> = ({ campaign, totals }) => {
  const currency = useSpaceCurrency();

  const gmv = totals.gmv;
  if (!hasGoal(gmv)) {
    return null;
  }

  const earnings = <strong>{formatCurrency(gmv.value, currency)}</strong>;
  const isRoasGoal = hasROASGoal(campaign);
  const goalStr = isRoasGoal ? (
    <strong>{gmv.goal.value}x ROAS</strong>
  ) : (
    <strong>{formatCurrency(gmv.goal.value, currency)}</strong>
  );
  if (gmv.goal.percentage >= 1) {
    return (
      <Typography variant="body2" paragraph color="textSecondary">
        You've reached your planned goal of {goalStr} and earned {earnings}.
        Congratulations!
      </Typography>
    );
  }
  const progress = (
    <strong>
      {formatNumber({
        n: gmv.goal.percentage,
        format: 'percent',
        digits: 1
      })}
    </strong>
  );
  return (
    <Typography variant="body2" paragraph color="textSecondary">
      You've reached {progress} of your planned goal and earned {earnings}. Keep
      up the good work!
    </Typography>
  );
};

export const CompletedCampaignSummary: React.FC<{
  totalValues: CampaignTotalsObject;
  campaign: CompletedCampaign;
}> = ({ totalValues, campaign }) => {
  return (
    <>
      <EarningsGoalSummary campaign={campaign} totals={totalValues} />
      <TrafficGoalSummary totals={totalValues} field="p" />
      <TrafficGoalSummary totals={totalValues} field="c" />
    </>
  );
};
