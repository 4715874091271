import { SideNavHowTo, SideNavProps } from '../../../layout/PageBody';
import { useQueryParam, useRoutes } from '../../../routes';
import { useFiltersParam } from '../../../components/analytics_v2/Filters/hooks';

export const useSideNavProps = (url: string, howTo?: SideNavHowTo) => {
  const { ROUTES } = useRoutes();
  const [backUrl] = useQueryParam(
    'backUrl',
    (t) => (t ? decodeURIComponent(t) : ROUTES.content.overview_v2.url()),
    () => undefined // unnecessary
  );
  const [filters] = useFiltersParam();
  const sideNav: SideNavProps = {
    groups: [
      {
        label: 'Content',
        items: [
          {
            label: 'Trends',
            path: ROUTES.content.details.trends.url(url, { backUrl, filters })
          },
          {
            label: 'Heatmap',
            path: ROUTES.content.details.heatmap.url(url, { backUrl, filters })
          },
          {
            label: 'Link Performance',
            path: ROUTES.content.details.links.url(url, { backUrl, filters })
          },
          {
            label: 'Audience',
            path: ROUTES.content.details.audience.url(url, {
              backUrl,
              filters
            })
          },
          {
            label: 'Revisions',
            path: ROUTES.content.details.revisions.url(url, {
              backUrl,
              filters
            })
          }
        ]
      }
    ],
    back: {
      path: backUrl
    },
    howTo
  };

  return sideNav;
};
