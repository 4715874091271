import { groupBy, map, mapValues, sortBy, sumBy } from 'lodash';
import {
  AnalyticsResponseRowWithComparison,
  SelectableField
} from '../../../../../../domainTypes/analytics_v2';
import { EarningMetric, TrafficMetric } from '../../service';

export interface ChartData {
  timestamp: number;
  traffic: Record<string, number>;
  previousTraffic: Record<string, number>;
  earnings: number;
  previousEarnings: number;
}

const selectChannelId = (r: AnalyticsResponseRowWithComparison) =>
  r.group.channel_id;

const selectInterval = (r: AnalyticsResponseRowWithComparison) =>
  new Date(r.group.interval).getTime();

const aggregateInterval = (
  intervalTimestamp: string,
  rows: AnalyticsResponseRowWithComparison[],
  selectTrafficMetric: SelectableField,
  selectEarningsMetric: SelectableField
): ChartData => {
  const timestamp = parseInt(intervalTimestamp, 10);
  const rowsByChannel = groupBy(rows, selectChannelId);
  const traffic = mapValues(rowsByChannel, (originRows) =>
    sumBy(originRows, (d) => d.data[selectTrafficMetric]?.curr ?? 0)
  );
  const previousTraffic = mapValues(rowsByChannel, (originRows) =>
    sumBy(originRows, (d) => d.data[selectTrafficMetric]?.prev ?? 0)
  );
  const earnings = sumBy(rows, (d) => d.data[selectEarningsMetric]?.curr ?? 0);
  const previousEarnings = sumBy(
    rows,
    (d) => d.data[selectEarningsMetric]?.prev ?? 0
  );
  return {
    timestamp,
    traffic,
    previousTraffic,
    earnings,
    previousEarnings
  };
};

export const formatChartData = (
  rows: AnalyticsResponseRowWithComparison[],
  trafficMetric: TrafficMetric,
  earningsMetric: EarningMetric
) => {
  const rowsByInterval = groupBy(rows, selectInterval);

  const data = map(rowsByInterval, (rowsInInterval, intervalTimestamp) =>
    aggregateInterval(
      intervalTimestamp,
      rowsInInterval,
      trafficMetric,
      earningsMetric
    )
  );

  return sortBy(data, ['timestamp']);
};
