import React, { useMemo } from 'react';
import { IEarning } from '../../../domainTypes/performance';
import { useCurrentUser } from '../../../services/currentUser';
import { getAdvertiserColor } from '../../AdvertiserWithColor';
import { EarningsPieChart, EarningsPieChartData } from '../EarningsPieChart';

type Props = {
  data:
    | { advertiserName: string; partnerKey: string; earnings: IEarning }[]
    | void;
  loading: boolean;
  count: number;
  animate?: boolean;
};

const HEIGHT = 390;

export const AdvertiserChart = React.memo(
  ({ data, loading, count = 10, animate }: Props) => {
    const currency = useCurrentUser().space.config.currency;

    const chartData = useMemo(() => {
      if (!data) {
        return undefined;
      }
      return data.map<EarningsPieChartData>((d) => ({
        key: `${d.partnerKey}---${d.advertiserName}`,
        name: d.advertiserName,
        earnings: d.earnings,
        color: getAdvertiserColor(d.advertiserName, d.partnerKey)
      }));
    }, [data]);

    return (
      <EarningsPieChart
        data={chartData}
        loading={loading}
        count={count}
        animate={animate}
        currency={currency}
        height={HEIGHT}
      />
    );
  }
);
