import { isEmpty } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import {
  AnalyticsField,
  AnalyticsFilterLike
} from '../../../domainTypes/analytics_v2';
import { useStringQueryParam } from '../../../routes';
import { SearchInput } from '../../SearchInput';

type Props = Omit<
  React.ComponentProps<typeof SearchInput>,
  'value' | 'onChange'
> & { onSearch?: (term: string) => void };

const useSearchBoxState = (
  paramName: string
): [string, (v: string) => void] => {
  const [search, _setSearch] = useStringQueryParam(paramName, '');
  const setSearch = useCallback(
    (nextValue: string) => {
      if (search !== nextValue) {
        _setSearch(nextValue);
      }
    },
    [_setSearch, search]
  );
  return [search, setSearch];
};

export const createSearchBox = (
  queryParam: string
): [React.FC<Props>, (field: AnalyticsField) => AnalyticsFilterLike | null] => {
  const SearchBox = ({ onSearch, ...props }: Props) => {
    const [search, setSearch] = useSearchBoxState(queryParam);
    return (
      <SearchInput
        value={search}
        onChange={(e) => {
          setSearch(e);
          onSearch?.(e);
        }}
        {...props}
      />
    );
  };

  const useSearch = (field: AnalyticsField) => {
    const [search] = useSearchBoxState(queryParam);
    return useMemo(() => {
      if (isEmpty(search)) return null;
      return {
        field,
        condition: 'ilike',
        pattern: `%${search}%`
      } as const;
    }, [field, search]);
  };

  return [SearchBox, useSearch];
};
