import React from 'react';
import { Link } from 'react-router-dom';
import * as ENV from '../../env.json';
import { Typography } from '@material-ui/core';
import { styled } from '../../emotion';
import { CustomerIoEmailManager } from '../../components/CustomerIoEmailManager';
import { PATHS } from '../../domainTypes/routes';

const Wrapper = styled('div')`
  max-width: 600px;
  margin: 100px auto;
  padding: ${(p) => p.theme.spacing(3)}px;

  a {
    color: ${(p) => p.theme.palette.primary.main};
    border-bottom: 1px solid;
  }
`;

const Title = styled('h1')`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  line-height: 24px;
  margin: ${(p) => p.theme.spacing(4)}px 0 ${(p) => p.theme.spacing(8)}px;
  text-transform: uppercase;
`;

export const SubscriptionCenter = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  if (!id) {
    return (
      <div>
        You must provide an email address for us to retrieve your subscription
        preferences.
      </div>
    );
  }

  return (
    <Wrapper>
      <a href={ENV.domains.website.public}>
        <img
          src="https://storage.googleapis.com/affilimate-assets/logos/logo-black-full.png"
          width="200px"
          alt="Affilimate"
          style={{ display: 'block', margin: '0 auto' }}
        />
      </a>
      <Title>Email Preference Center</Title>
      <Typography
        variant="body1"
        component="p"
        color="textSecondary"
        style={{ marginBottom: '36px' }}
      >
        These are your preferences for our marketing communication, like product
        updates and blog digests. To change your email address or update your
        activity-based email preferences (like daily reports),{' '}
        <Link to={PATHS.settings.notifications}>please log in</Link>.
      </Typography>
      <CustomerIoEmailManager cioId={id} />
    </Wrapper>
  );
};
