import React from 'react';
import { styled } from '../emotion';

const Wrapper = styled('div')`
  position: relative;
  height: 0px;
  padding: 0px 0px 67.5%;
  overflow: hidden;
  padding-bottom: 56.25%;
`;

export const SIZE_SETTINGS = {
  MODAL: {
    width: 555,
    height: 290
  },
  MEDIUM: {
    width: 555,
    height: 290
  },
  LARGE: {
    width: 555,
    height: 290
  }
};

export const Video = ({
  url,
  width,
  height
}: {
  url: string;
  width: number;
  height: number;
}) => {
  return (
    <Wrapper>
      <iframe
        title="Video"
        src={url}
        width={width}
        height={height}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Wrapper>
  );
};
