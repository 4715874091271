import { Timestamp } from './time';

export enum JobStatus {
  done = 'done',
  error = 'error',
  running = 'running',
  queued = 'queued',
  cancelled = 'cancelled'
}

interface CommonJobParams {
  status: JobStatus;
  retries: number;
  retriesRemaining: number;
  createdAt: Timestamp | null;
  lastUpdated: Timestamp | null;
  message: string | null;
}

export interface AdvertiserJob extends CommonJobParams {
  spaceId: string;
  type: 'advertiser';
  handler: string;
  instanceId: string;
  range: {
    start: Timestamp;
    end: Timestamp;
  };
  progress: {
    percentage: number; // 0 - 100
  };
}

export type AwinAdvertiserJob = AdvertiserJob & {
  handler: 'awinV1';
  nextParams?: {
    startingIndex: number;
    advertiserIds: number[];
  };
};

export type SovrnAdvertiserJob = AdvertiserJob & {
  handler: 'sovrnV1';
  nextParams?: {
    startingIndex: number;
    totalPages: number;
  };
};

export interface PayoutJob extends CommonJobParams {
  spaceId: string;
  type: 'payout';
  range: {
    start: Timestamp;
    end: Timestamp;
  };
  handler: string;
  instanceId: string;
  progress: {
    percentage: number; // 0 - 100
  };
}

export interface ReportingJob extends CommonJobParams {
  spaceId: string;
  type: 'reporting';
  range: {
    start: Timestamp;
    end: Timestamp;
  };
  handler: string;
  instanceId: string;
  progress: {
    percentage: number; // 0 - 100
  };
}

export type Job = PayoutJob | ReportingJob | AdvertiserJob;

export type ReportingJobImpact = ReportingJob & {
  handler: 'impactV1';
  nextParams?: {
    startingPage: number;
    pageSize: number;
  };
};

export type ReportingJobSovrn = ReportingJob & {
  handler: 'sovrnV1';
  nextParams?: {
    startingIndex: number;
  };
};

export type ReportingJobDigidip = ReportingJob & {
  handler: 'digidipV1';
  cursors?: {
    projectId: number;
    status: 'pending' | 'done' | 'error' | null;
    cursor: string | null;
  }[];
};

export type AmazonReportingJob = ReportingJob & {
  handle: 'amazonFeedV1';
  nextParams?: {
    startDate: Timestamp;
    startingIndex: number;
  };
};

export type ReportingJobRedVentures = ReportingJob & {
  handler: 'redVenturesV1';
  nextParams?: {
    startingIndex: number;
  };
};
