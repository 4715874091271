import { Line, LineProps } from 'recharts';
import { isNil } from 'lodash';
import { getStableRandomColor } from '../../../services/color';
import React from 'react';
import { Metric } from '../../../services/analyticsV2/metrics';

type CustomLineProps = Omit<LineProps, 'name' | 'stroke'> & {
  name: LineProps['name'] | ((value: string) => LineProps['name']);
  stroke: LineProps['stroke'] | ((value: string) => LineProps['stroke']);
};

export const lineSeries = (
  values: string[],
  metric: Metric,
  { name, stroke, ...restLineProps }: Partial<CustomLineProps> = {}
) =>
  values.map((value) => {
    const lineName = isNil(name)
      ? value
      : typeof name === 'function'
      ? name(value)
      : name;
    const lineStroke = isNil(stroke)
      ? getStableRandomColor(value)
      : typeof stroke === 'function'
      ? stroke(value)
      : stroke;
    return (
      <Line
        key={`${metric}.${value}`}
        dataKey={`${metric}.${value}`}
        name={lineName}
        yAxisId={metric}
        xAxisId="timestamp"
        type="monotone"
        stroke={lineStroke}
        strokeWidth={2}
        dot={false}
        isAnimationActive={false}
        {...restLineProps}
      />
    );
  });
