import React from 'react';
import { Doc } from '../../../../../domainTypes/document';
import { ReleaseNotification } from '../../../../../domainTypes/notifications';
import { Body, Frame, ImageHeader } from '../../Item/Frame';

export const Release_Notifications = ({
  n
}: {
  n: Doc<ReleaseNotification>;
}) => {
  return (
    <Frame n={n}>
      <ImageHeader src="/images/alerts/release-notifications.svg">
        Introducing Alerts
      </ImageHeader>
      <Body>
        This drawer will inform you about new features in Affilimate,
        achievements and important changes on your blog, as well as other
        noteworthy events. Watch out for the red notification bell!
      </Body>
    </Frame>
  );
};
