import {
  Button,
  CircularProgress,
  DialogActions,
  Typography
} from '@material-ui/core';

import { AlertBox } from '../../../../../../../components/AlertBox';
import { Swatch } from '../../../../../../../components/ColorPicker';
import {
  AssignTagsParams,
  ImportedTag,
  ImportedTagAndIPostgresTags
} from '../../../../../../../domainTypes/tags';
import { styled } from '../../../../../../../emotion';
import { useBackgroundJobs } from '../../../../../../../hooks/useBackgroundJobs';
import {
  assignTags,
  useTagsForCurrentUser
} from '../../../../../../../services/tags';

import { Color } from '../../../../../../../domainTypes/colors';
import { useFlushAnalyticsV2Cache } from '../../../../../../../services/analyticsV2/cache';

const ResultsContainer = styled('div')`
  max-height: 300px;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-row-gap: ${(p) => p.theme.spacing(1)}px;
  padding: ${(p) => p.theme.spacing(1)}px;
  margin: ${(p) => p.theme.spacing(1)}px auto;
  border: 1px solid ${(p) => p.theme.palette.grey[300]};
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  div:nth-child(even) {
    align-items: center;
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-row-gap: 8px;
  }
`;

const applyTags = async (
  spaceId: string,
  tags: ImportedTagAndIPostgresTags[]
) => {
  const actions: AssignTagsParams['actions'] = [];
  tags.forEach((tag) => {
    actions.push({
      tagId: tag.id,
      href: tag.url,
      action: 'apply'
    });
  });

  await assignTags({ spaceId, actions });
};

export const BatchImportResults = ({
  importedTags,
  spaceId,
  onClose,
  setResults
}: {
  importedTags: ImportedTag[];
  spaceId: string;
  onClose: () => void;
  setResults: (commonTags: ImportedTagAndIPostgresTags[]) => void;
}) => {
  const flushCache = useFlushAnalyticsV2Cache();
  const { addJob } = useBackgroundJobs();
  const [tags, loadingTags] = useTagsForCurrentUser();

  if (loadingTags || !tags) {
    return null;
  }

  const sortedTags = importedTags.reduce<{
    commonTags: ImportedTagAndIPostgresTags[];
    missingTags: ImportedTag[];
  }>(
    (acc, importedTag) => {
      const foundTag = tags.find(
        (tag) => tag.name.toLowerCase() === importedTag.name.toLowerCase()
      );
      if (foundTag) {
        return {
          commonTags: [...acc.commonTags, { ...importedTag, ...foundTag }],
          missingTags: [...acc.missingTags]
        };
      }
      return {
        commonTags: [...acc.commonTags],
        missingTags: [...acc.missingTags, importedTag]
      };
    },
    { commonTags: [], missingTags: [] }
  );

  const { commonTags, missingTags } = sortedTags;

  const onSubmit = async (
    tags: ImportedTagAndIPostgresTags[],
    spaceId: string,
    onClose: () => void
  ) =>
    addJob({
      job: async () => await applyTags(spaceId, tags),
      onStart: () => {
        console.time('saving tags');
        onClose();
        return {
          message: (
            <>
              <CircularProgress color="inherit" size={16} /> &nbsp; Applying
              tags...
            </>
          )
        };
      },
      onSuccess: () => {
        console.timeEnd('saving tags');
        flushCache(spaceId);
        return {
          message: 'Applied tags successfully.'
        };
      },
      onError: (err) => {
        console.timeEnd('saving tags');
        return {
          message: 'Applying tags has failed. Please try again.'
        };
      }
    });

  return (
    <>
      <Typography variant="body1" component="p" color="textSecondary" paragraph>
        Review the results generated from your CSV file below.
      </Typography>
      <ResultsContainer>
        {commonTags.map((tag) => {
          return (
            <>
              <div>
                <span>{tag.url}</span>
              </div>
              <div>
                <Swatch colorName={tag.color as Color} size="small" />{' '}
                {tag.name}
              </div>
            </>
          );
        })}
      </ResultsContainer>
      {missingTags.length > 0 && (
        <AlertBox variant="pending">
          <strong>Warning:</strong> Your import contains tags you haven't
          created yet. Please create these tags first:{' '}
          {[...new Set(missingTags.map((tag) => tag.name))].join(', ')}
        </AlertBox>
      )}
      <DialogActions style={{ paddingRight: 0 }}>
        <Button
          onClick={() => {
            setResults([]);
          }}
        >
          Re-upload file
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSubmit(commonTags, spaceId, onClose)}
        >
          Apply {commonTags.length.toLocaleString('en-US')} tags
        </Button>
      </DialogActions>
    </>
  );
};
