import { Tooltip } from '@material-ui/core';
import { AlertTriangle } from 'react-feather';
import { Badge } from '../../../../components/Badge';
import {
  CachedAdvertiser,
  isAdvertiserStale
} from '../../../../domainTypes/advertiser';
import { COLORS } from '../../../../domainTypes/colors';

type AdvertiserStatusConfig = {
  bgColor: string;
  color: string;
  message: string;
};

const CONFIG: {
  [key in CachedAdvertiser['status']]: AdvertiserStatusConfig;
} = {
  Active: {
    bgColor: COLORS.green.green2,
    color: COLORS.green.green7,
    message: 'Active'
  },
  Inactive: {
    bgColor: COLORS.gold.gold2,
    color: COLORS.gold.gold8,
    message: 'Inactive'
  },
  Unknown: {
    bgColor: COLORS.gold.gold2,
    color: COLORS.gold.gold8,
    message: 'Unknown'
  }
};

export const AdvertiserStatus = ({
  advertiser
}: {
  advertiser: CachedAdvertiser;
}) => {
  // If advertiser was last updated over 1 week
  // ago, display it as Stale
  if (isAdvertiserStale(advertiser)) {
    return (
      <Tooltip
        title="This advertiser has not reported its rates or activity status in over a week, and may be inactive"
        placement="top"
      >
        <Badge bgColor="#EEE" color="#444">
          <AlertTriangle size={12} /> Stale
        </Badge>
      </Tooltip>
    );
  }

  const config = CONFIG[advertiser.status || 'Unknown'];
  return (
    <Badge bgColor={config.bgColor} color={config.color}>
      {config.message}
    </Badge>
  );
};
