import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  CircularProgress,
  FormHelperText,
  FormControlLabel,
  Button,
  Typography
} from '@material-ui/core';
import { Check } from 'react-feather';
import { usePromise } from '../../hooks/usePromise';
import {
  getEmailLists,
  Cio,
  unsubscribeByDeliveryId
} from '../../services/customerio';
import { css, styled } from '../../emotion';
import { Form } from '../Form';
import { FormField } from '../Form/FormField';
import { CioListItem, CioIdentify } from '../../domainTypes/customerio';
import { useSnackbar } from '../../hooks/useSnackbar';
import { Centered } from '../../layout/Centered';
import { AlertBox } from '../AlertBox';
import { useCurrentUser } from '../../services/currentUser';

const LIST_CONFIGS: {
  name: CioListItem;
  label: string;
  frequency: string;
  helperText: string;
}[] = [
  {
    name: 'product_updates',
    label: 'Product Updates',
    frequency: '1x per month',
    helperText:
      "Learn about Affilimate's latest features, tools, integrations, community news and announcements."
  },
  {
    name: 'blog',
    label: 'Blog Digest',
    frequency: '2x per month',
    helperText:
      'Receive a bi-weekly digest of new posts on our blog about affiliate marketing and analytics.'
  }
];

const Row = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ListForm = ({
  data,
  label,
  helperText,
  value,
  unsubscribed,
  onChange
}: {
  data: CioListItem[];
  label: React.ReactNode;
  helperText: string;
  value: CioListItem;
  unsubscribed: boolean;
  onChange: any;
}) => {
  return (
    <Form
      autoSave={true}
      onSubmit={(data) => onChange(data)}
      initialValues={{ lists: data }}
    >
      {({ submitting }) => (
        <>
          <Row>
            <FormField name="lists" type="checkbox" value={value}>
              {({ input }) => (
                <>
                  <FormControlLabel
                    label={label}
                    classes={{ root: css(() => ({ marginLeft: 0 })) }}
                    control={
                      <Checkbox
                        color="primary"
                        disabled={unsubscribed}
                        checked={input.checked && !unsubscribed}
                        onChange={input.onChange}
                      />
                    }
                  />
                </>
              )}
            </FormField>
            {submitting && <CircularProgress size={20} />}
          </Row>
          <FormHelperText
            classes={{
              root: css((t) => ({
                marginLeft: '12px',
                marginBottom: `${t.spacing(4)}px`
              }))
            }}
          >
            {helperText}
          </FormHelperText>
        </>
      )}
    </Form>
  );
};

const Frequency = styled('span')`
  display: inline-block;
  background-color: #eee;
  color: #999;
  font-size: 12px;
  border-radius: 4px;
  margin-left: 6px;
  padding: 4px 8px;
`;

export const CustomerIoEmailManager = ({
  cioId
}: {
  cioId: CioIdentify['id'];
}) => {
  const currentUser = useCurrentUser();
  const urlParams = new URLSearchParams(window.location.search);
  const deliveryId = urlParams.get('delivery_id');

  const [unsubscribed, setUnsubscribed] = useState<Boolean | undefined>(
    undefined
  );
  const [value, loading, error] = usePromise(() => getEmailLists(cioId), [
    unsubscribed
  ]);

  useEffect(() => {
    if (loading || error || currentUser || !cioId?.length) {
      return;
    }

    // Identify users with customerio who aren't logged in, assuming
    // they already exist as customers in the system
    Cio.identify({
      id: cioId
    });
  }, [currentUser, loading, error, cioId]);

  const { enqueueSnackbar } = useSnackbar();

  const onChangeSubscriptionStatus = () => {
    if (!value) {
      return;
    }

    let actuallyUnsubscribed: boolean;

    // Customer.io integration is slow to update subscription status,
    // so mirror state locally
    if (typeof unsubscribed === 'undefined') {
      actuallyUnsubscribed = !value.unsubscribed;
    } else {
      actuallyUnsubscribed = !unsubscribed;
    }

    const message = `You've been successfully ${
      actuallyUnsubscribed ? 'unsubscribed' : 're-subscribed'
    }`;

    setUnsubscribed(actuallyUnsubscribed);

    // Allows us to attribute the unsubscription to a specific message
    // that was sent to the user
    if (deliveryId && actuallyUnsubscribed) {
      return unsubscribeByDeliveryId(deliveryId)
        .then(() => {
          enqueueSnackbar(message, { variant: 'success' });
        })
        .catch(() => {
          enqueueSnackbar('An error occurred', { variant: 'success' });
          setUnsubscribed(!actuallyUnsubscribed);
        });
    }

    // Otherwise if they just unsubscribe out of the blue,
    // we don't need to attribute it. Or, if they want to re-subscribe by chance
    Cio.identify({
      id: cioId,
      unsubscribed: `${actuallyUnsubscribed}`
    });

    setTimeout(() => {
      enqueueSnackbar(message, { variant: 'success' });
    }, 250);
  };

  const onChange = ({ lists }: { lists: CioListItem[] }) => {
    Cio.identify({ id: cioId, lists });

    setTimeout(() => {
      const message = `Your subscription preferences have been updated`;
      enqueueSnackbar(message, { variant: 'success' });
    }, 250);

    return Promise.resolve();
  };

  if (!cioId) {
    return <div>Cannot provide subscription center for unknown account ID</div>;
  }

  const formData = !value ? [] : (value.lists as CioListItem[]);
  const showAsUnsubscribed =
    (value && value.unsubscribed && unsubscribed === undefined) ||
    unsubscribed === true;

  if (loading && !value) {
    return (
      <Centered height={270}>
        <CircularProgress size={20} />
      </Centered>
    );
  }

  if (error) {
    return (
      <AlertBox variant="pending">
        An error occurred processing your request. Please contact us at{' '}
        <a href="mailto:support@affilimate.com">support@affilimate.com</a> for
        assistance.
      </AlertBox>
    );
  }

  return (
    <div>
      {LIST_CONFIGS.map((listName) => (
        <ListForm
          key={listName.name}
          label={
            <>
              <span>{listName.label}</span>
              <Frequency>{listName.frequency}</Frequency>
            </>
          }
          data={formData}
          unsubscribed={showAsUnsubscribed}
          value={listName.name}
          helperText={listName.helperText}
          onChange={onChange}
        />
      ))}
      <Typography
        component="p"
        variant="body2"
        color="textSecondary"
        style={{
          opacity: showAsUnsubscribed ? 1 : 0,

          marginTop: !showAsUnsubscribed ? '12px' : 0
        }}
        paragraph
      >
        <Check
          size={16}
          style={{ position: 'relative', top: '3px', marginRight: '4px' }}
        />
        You've been unsubscribed from all email updates
      </Typography>
      <Button
        color={showAsUnsubscribed ? 'primary' : 'default'}
        variant="contained"
        style={{
          opacity: showAsUnsubscribed ? 1 : 0.5
        }}
        onClick={() => {
          onChangeSubscriptionStatus();
        }}
      >
        {showAsUnsubscribed ? 'Re-subscribe' : 'Unsubscribe from all email'}
      </Button>
    </div>
  );
};
