import { IMessageLogin, IMessageLogout, IMessageStatus } from '.';

export type AppToContentScript =
  | IMessageLogin
  | IMessageLogout
  | IMessageStatus;
export type ContentScriptToApp = IMessageStatus;

export const APP_TO_CONTENT_SCRIPT = 'APP_TO_CONTENT_SCRIPT';
export const CUSTOM_EVENT_TYPE = 'AM_CUSTOM_EVENT';
