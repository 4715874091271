import React, { useContext, useMemo, useState } from 'react';
import { AnalyticsResponse } from '../../domainTypes/analytics_v2';

type AnalyticsV2Cache = {
  analytics: {
    [checksum: string]: {
      expiresAt: null | number; // a millisecond value,
      promise: Promise<AnalyticsResponse>;
    };
  };
  transactions: {
    [checksum: string]: {
      expiresAt: null | number; // a millisecond value,
      promise: Promise<AnalyticsResponse>;
    };
  };
};

export const isExpired = (n: number, expiresAt: number | null) =>
  expiresAt !== null && n > expiresAt;

const CACHE_BY_SPACE: { [spaceId: string]: AnalyticsV2Cache } = {};
export const getCache = (spaceId: string, type: keyof AnalyticsV2Cache) =>
  (CACHE_BY_SPACE[spaceId] = CACHE_BY_SPACE[spaceId] || {
    analytics: {},
    transactions: {}
  })[type];

const resetCache = (spaceId: string) =>
  (CACHE_BY_SPACE[spaceId] = {
    analytics: {},
    transactions: {}
  });

export const useFlushAnalyticsV2Cache = () => {
  const { flushCache } = useContext(AnalyticsV2CacheContext);
  return flushCache;
};

export const useAnalyticsV2CacheRevision = (spaceId: string): number => {
  const { revisions } = useContext(AnalyticsV2CacheContext);
  return revisions[spaceId] ?? 0;
};

interface AnalyticsV2CacheContextValue {
  revisions: Readonly<Record<string, number>>;
  flushCache: (spaceId: string) => void;
}

const AnalyticsV2CacheContext = React.createContext<
  AnalyticsV2CacheContextValue
>({
  get revisions(): Record<string, number> {
    throw new Error('Using AnalyticsV2CacheContext without Provider!');
  },
  flushCache() {
    throw new Error('Using AnalyticsV2CacheContext without Provider!');
  }
});

export const AnalyticsV2CacheProvider: React.FC = ({ children }) => {
  const [revisions, updateRevisions] = useState<Record<string, number>>({});
  const value: AnalyticsV2CacheContextValue = useMemo(() => {
    const flushCache = (spaceId: string) => {
      resetCache(spaceId);
      updateRevisions((prevRevisions) => {
        return {
          ...prevRevisions,
          [spaceId]: (prevRevisions[spaceId] ?? 0) + 1
        };
      });
    };
    return {
      revisions,
      flushCache
    };
  }, [revisions]);
  return (
    <AnalyticsV2CacheContext.Provider value={value}>
      {children}
    </AnalyticsV2CacheContext.Provider>
  );
};
