import { Paper } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { HelpIcon } from '../../../../components/HelpIcon';
import { CanvasBar } from '../../../../layout/Canvas';
import { LimitedWidth } from '../../../../layout/PageBody';
import { Section } from '../../../../layout/Section';
import { ARTICLES } from '../../../../services/beacon';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../services/currentUser';
import { SettingsLayout } from '../../SettingsLayout';
import { useFeatureEnabled } from '../../../../services/features';
import { ScheduledExportNotAvailable } from '../../../../components/ApiNotAvailable';
import { AddScheduledExportButton, ScheduledExports } from './ScheduledExports';
import { Centered } from '../../../../layout/Centered';
import { NoPermissions } from '../../../../components/NoPermissions';
import { useTrackMixpanelView } from '../../../../services/mixpanel';

const PageSettingsExportsBody = () => {
  const currentUser = useCurrentUser();
  const scopes = useCurrentUserScopes();
  const hasCustomStorageExports = useFeatureEnabled('CUSTOM_STORAGE_EXPORT_V1');
  const canViewScheduledExports = scopes.has('scheduled_exports.view');
  const createScheduledExports = scopes.has('scheduled_exports.create');

  return (
    <LimitedWidth width={900}>
      <Section>
        <CanvasBar>
          <div>
            Scheduled exports <HelpIcon articleId={ARTICLES.api.dataExport} />
          </div>
          <AddScheduledExportButton
            variant="contained"
            color="primary"
            size="small"
            disabled={!hasCustomStorageExports || !createScheduledExports}
          >
            Add scheduled export
          </AddScheduledExportButton>
        </CanvasBar>
        {canViewScheduledExports ? (
          <Paper>
            {hasCustomStorageExports ? (
              <ScheduledExports space={currentUser.space} />
            ) : (
              <Centered style={{ paddingTop: 50, paddingBottom: 50 }}>
                <ScheduledExportNotAvailable />
              </Centered>
            )}
          </Paper>
        ) : (
          <NoPermissions />
        )}
      </Section>
    </LimitedWidth>
  );
};

export const PageSettingsExports = () => {
  useTrackMixpanelView('scheduled_exports_view');

  return (
    <SettingsLayout>
      <Helmet>
        <title>Scheduled Export Settings | Affilimate</title>
      </Helmet>
      <PageSettingsExportsBody />
    </SettingsLayout>
  );
};
