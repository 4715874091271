import React, { useMemo } from 'react';
import { ExportButton } from '../../../components/Exporter';
import { ButtonProps } from '@material-ui/core/Button';
import { Timeframe } from '../../../domainTypes/analytics';
import { CurrencyCode } from '../../../domainTypes/currency';
import { IExportColumn } from '../../../components/Exporter/service';
import { constant } from 'lodash';
import { getAov } from '../../../services/analytics';
import { AdvertiserEarnings } from '../../../services/advertisers';

type AdvertisersExportButtonProps = ButtonProps & {
  timeframe: Timeframe;
  rows: AdvertiserEarnings[];
  currency: CurrencyCode;
  columns: ExportColumnKey[];
};

interface OtherProps {
  currency: CurrencyCode;
}

type ExportColumnKey =
  | 'name'
  | 'partner'
  | 'transactionCount'
  | 'earnings'
  | 'sales'
  | 'orderCount'
  | 'orderCountGross'
  | 'aov'
  | 'avgCommissionPercent'
  | 'avgCommissionNetPercent'
  | 'lostCount';

type ExportColumn = IExportColumn<
  ExportColumnKey,
  AdvertiserEarnings,
  OtherProps
>;

const ALL_COLUMNS: ExportColumn[] = [
  {
    key: 'name',
    head: constant('Advertiser'),
    cell: (d) => d.advertiserName
  },
  {
    key: 'partner',
    head: constant('Platform'),
    cell: (d) => d.partner.name
  },
  {
    key: 'transactionCount',
    head: constant('Line items'),
    cell: (d) => d.curr.transactionCount
  },
  {
    key: 'earnings',
    head: ({ currency }) => `Earnings (in ${currency})`,
    cell: (d) => d.curr.total / 100
  },
  {
    key: 'sales',
    head: ({ currency }) => `Sales volume (in ${currency})`,
    cell: (d) => d.curr.saleValue.total / 100
  },
  {
    key: 'orderCount',
    head: constant('Orders'),
    cell: (d) => d.curr.orderCount.total
  },
  {
    key: 'orderCountGross',
    head: constant('Orders Gross'),
    cell: (d) => d.curr.orderCount.all
  },
  {
    key: 'aov',
    head: ({ currency }) => `AOV (in ${currency})`,
    cell: (d) => getAov(d.curr.orderCount.total, d.curr.saleValue.total) / 100
  },
  {
    key: 'avgCommissionPercent',
    head: constant('Comm Rate'),
    cell: (d) => d.curr.avgCommissionPercent
  },
  {
    key: 'avgCommissionNetPercent',
    head: constant('Net Comm Rate'),
    cell: (d) => d.curr.total / d.curr.saleValue.total
  },
  {
    key: 'lostCount',
    head: constant('Lost'),
    cell: (d) => d.curr.lostCount
  }
];

export const AdvertisersExportButton: React.FC<AdvertisersExportButtonProps> = ({
  timeframe,
  rows,
  currency,
  columns,
  ...other
}) => {
  const otherProps: OtherProps = useMemo(() => ({ currency }), [currency]);
  return (
    <ExportButton
      {...other}
      dialogTitle="Export Advertisers"
      fileName={`affilimate_advertisers_export_${timeframe.start}_${timeframe.end}`}
      rows={rows}
      otherProps={otherProps}
      columns={ALL_COLUMNS}
      preselectedColumns={columns}
    />
  );
};
