import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';

import { Archive } from 'react-feather';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import { IReportingStatusUpdateParams } from '../../../../domainTypes/reporting';
import { ISecret } from '../../../../domainTypes/secret';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { useCurrentUser } from '../../../../services/currentUser';
import { callFirebaseFunction } from '../../../../services/firebaseFunctions';

export const isArchivedIntegration = (status: ISecret['status'] | undefined) =>
  status === 'archived';

export const toggleIntegrationStatus = async ({
  spaceId,
  integrationId,
  status
}: IReportingStatusUpdateParams) => {
  try {
    await callFirebaseFunction<{ success: boolean }>(
      'reporting-updateSecretsStatus',
      {
        spaceId,
        integrationId,
        status: isArchivedIntegration(status) ? 'active' : 'archived'
      }
    );
  } catch (err) {
    console.log('error in archiveIntegrationDialog', err);
  }
};

export const ArchiveIntegrationDialog = ({
  integrationId,
  onClose,
  status
}: {
  integrationId: string;
  onClose: () => void;
  status: 'active' | 'archived';
}) => {
  const { space } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div style={{ maxWidth: '600px' }}>
      <DialogTitle>Archive integration</DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          color="textSecondary"
          component="p"
          paragraph
        >
          Archive integrations for programs that are no longer running, but you
          want to keep past commissions visible in your reports.
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          You can always reactivate this integration at the bottom of this page
          to start syncing again.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>

        <ButtonWithPromise
          onClick={async () => {
            await toggleIntegrationStatus({
              spaceId: space.id,
              integrationId,
              status
            });
            enqueueSnackbar('Integration archived!', {
              variant: 'success'
            });
            return onClose();
          }}
          pending="Archiving..."
        >
          <Archive size={20} /> &nbsp; Archive integration
        </ButtonWithPromise>
      </DialogActions>
    </div>
  );
};
