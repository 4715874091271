import { Bell as IconNotifications } from 'react-feather';
import React from 'react';
import * as tracking from '../../../../tracking';
import { useCurrentUser } from '../../../../services/currentUser';
import { Tooltip, IconButton } from '@material-ui/core';
import { useNotificationsDrawer } from '../../hooks/useNotificationsDrawer';
import { useNotificationCounter } from '../../services';
import { pluralize } from '../../../../services/pluralize';
import { styled } from '../../../../emotion';
import { COLORS } from '../../../../domainTypes/colors';

const Counter = styled('div')`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.red.red5};
  color: white;
  border-radius: 100%;
  font-size: 12px;
  line-height: 0;
  font-weight: 700;
  width: 18px;
  height: 18px;
  top: 16px;
  left: 21px;
  box-shadow: 0 0 0 0 rgba(255, 120, 117, 1);
  transform: scale(1);
  animation: red-pulse 10s infinite;

  @keyframes red-pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 120, 117, 0.7);
    }

    15% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 120, 117, 0);
    }

    30% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 120, 117, 0);
    }
  }
`;

export const NotificationBell = () => {
  const { id: userId } = useCurrentUser();
  const [notificationsOpen, setNotificationsOpen] = useNotificationsDrawer();
  const [counter] = useNotificationCounter(userId);
  const unseen = counter ? counter.data.counts.unseen : 0;

  return (
    <Tooltip
      title={
        counter ? `Alerts · ${pluralize('alert', unseen, true)}` : 'Alerts'
      }
    >
      <IconButton
        color="inherit"
        style={{ padding: '4px 6px' }}
        onClick={() => {
          tracking.sendEvent({
            category: 'TopNav',
            action: 'Click',
            label: 'Notifications icon'
          });
          setNotificationsOpen(!notificationsOpen);
        }}
      >
        <IconNotifications />
        {!!unseen && <Counter>{unseen}</Counter>}
      </IconButton>
    </Tooltip>
  );
};
