import { IApiReportHandler } from '../types';
import { HANDLER_CONFIGS } from '../../../../../domainTypes/reporting';

export const VOYAGIN: IApiReportHandler = {
  type: 'API',
  partnerKey: 'voyagin',
  status: 'deprecated',
  configName: 'voyaginV1',
  configFields: HANDLER_CONFIGS.voyaginV1
};
