import { Paper, Typography } from '@material-ui/core';
import { Centered } from '../layout/Centered';
import React from 'react';

interface MessageProps {
  message: string;
}

export const Message = ({ message }: MessageProps) => {
  return (
    <Paper style={{ width: '80%', margin: '0 auto' }}>
      <Centered height={300}>
        <Typography variant="body1" color="textSecondary">
          {message}
        </Typography>
      </Centered>
    </Paper>
  );
};
