import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { Calendar as IconCalendar } from 'react-feather';
import { isSameTimeframe, Timeframe } from '../../domainTypes/analytics';
import { css, styled } from '../../emotion';
import {
  allTime,
  lastDays,
  lastTimeframe,
  timeToDate
} from '../../services/analytics';
import { useCurrentUser } from '../../services/currentUser';
import { pluralize } from '../../services/pluralize';
import { getTzAbbr, timeframeToMoments } from '../../services/time';
import * as tracking from '../../tracking';
import { CustomDialog } from './CustomDialog';

const TimeframePickerContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export interface ITimeframeOption {
  value: Timeframe;
  label: string;
  customize?: boolean;
}

export const lastWeekMonthAndYear = (): ITimeframeOption[] => [
  {
    value: lastDays(1),
    label: 'Yesterday'
  },
  {
    value: lastDays(7),
    label: 'Last 7 days'
  },
  {
    value: lastDays(30),
    label: 'Last 30 days'
  },
  {
    value: lastDays(90),
    label: 'Last 90 days'
  },
  {
    value: lastDays(365),
    label: 'Last 365 days'
  },
  {
    value: lastTimeframe(1, 'month'),
    label: 'Last month'
  },
  {
    value: lastTimeframe(1, 'year'),
    label: 'Last year'
  },
  {
    value: timeToDate('month'),
    label: 'Month to date'
  },
  {
    value: timeToDate('year'),
    label: 'Year to date'
  }
];

export const getAllTimeOption = (): ITimeframeOption => {
  return {
    value: allTime(),
    label: 'Since account start'
  };
};

export const getStandardOptions = () => {
  const options = lastWeekMonthAndYear();
  return {
    options: [...options, getAllTimeOption()],
    defaultOption: options[2] // 30 days
  };
};

export const useStandardOptions = () => {
  const { tz = '' } = useCurrentUser();
  return useMemo(getStandardOptions, [tz]);
};

type TimeframePickerProps = {
  value: Timeframe;
  onChange: (nextValue: Timeframe) => void;
  options: ITimeframeOption[];
};

const CUSTOM_OPTION: ITimeframeOption = {
  value: { start: '', end: '', tz: '' }, // this should never be used for anything
  label: 'Custom',
  customize: true
};

const findSelectedOption = (options: ITimeframeOption[], value: Timeframe) => {
  const i = options.findIndex((o) => isSameTimeframe(o.value, value));
  return i === -1 ? options.indexOf(CUSTOM_OPTION) : i;
};

export const TimeframePicker = ({
  value,
  onChange: onSelect,
  options
}: TimeframePickerProps) => {
  const [open, setOpen] = useState(false);
  const [customDialogOpen, setCustomDialogOpen] = useState(false);

  options = [...options, CUSTOM_OPTION];

  const moments = timeframeToMoments(value);
  const start = moments.start.format('MMM DD');
  const end = moments.end.format('MMM DD, YYYY');

  const trackOptionClick = (option: ITimeframeOption) => {
    tracking.sendEvent({
      category: tracking.toAppCategory(),
      action: 'Filter timeframe',
      label: option.label
    });
  };

  return (
    <TimeframePickerContainer>
      <IconCalendar />
      <FormControl>
        <Select
          autoWidth={true}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={findSelectedOption(options, value)}
          renderValue={(v) => {
            const i = v as number;
            const option = options[i];
            if (!option) {
              return null;
            }
            if (option.customize) {
              return (
                <div>
                  Custom:{' '}
                  {pluralize('day', moments.end.diff(moments.start, 'd'), true)}
                </div>
              );
            }
            return <div>{option.label}</div>;
          }}
          onChange={(ev) => {
            const option = options[Number(ev.target.value)];
            if (option.customize) {
              // customize is handled via custom click handler on MenuItem, as otherwise
              // repeated clicks on the custom option will not trigger this change handler
              return;
            }

            onSelect(option.value);
            trackOptionClick(option);
          }}
          classes={{
            select: css((t) => ({
              fontSize: t.custom.fontSize.m,
              paddingTop: 0,
              paddingBottom: t.spacing() * 0.75
            }))
          }}
        >
          {options.map((o, i) => (
            <MenuItem key={i} value={i}>
              {o.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText
          margin="dense"
          classes={{ root: css(() => ({ lineHeight: '18px' })) }}
        >
          {start} - {end} ({getTzAbbr(value.tz)})
        </FormHelperText>
      </FormControl>
      <CustomDialog
        open={customDialogOpen}
        onClose={() => setCustomDialogOpen(false)}
        value={value}
        onSelect={(tf) => {
          setCustomDialogOpen(false);
          onSelect(tf);
        }}
      />
    </TimeframePickerContainer>
  );
};

export const TimeframePickerDense = ({
  value,
  onChange: onSelect,
  options
}: TimeframePickerProps) => {
  const [open, setOpen] = useState(false);
  const [customDialogOpen, setCustomDialogOpen] = useState(false);
  options = [...options, CUSTOM_OPTION];
  const moments = timeframeToMoments(value);

  const trackOptionClick = (option: ITimeframeOption) => {
    tracking.sendEvent({
      category: tracking.toAppCategory(),
      action: 'Filter timeframe',
      label: option.label
    });
  };
  return (
    <TimeframePickerContainer>
      <FormControl>
        <Select
          autoWidth={true}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          variant="outlined"
          value={findSelectedOption(options, value)}
          style={{ height: '36px' }}
          renderValue={(v) => {
            const i = v as number;
            const option = options[i];
            if (!option) {
              return null;
            }
            if (option.customize) {
              return (
                <div>
                  Custom: {moments.start.format('MMM DD')} -{' '}
                  {moments.end.format('MMM DD, YYYY')}
                </div>
              );
            }
            return <div>{option.label}</div>;
          }}
          onChange={(ev) => {
            console.log('on change');
            const option = options[Number(ev.target.value)];
            if (option.customize) {
              // customize is handled via custom click handler on MenuItem, as otherwise
              // repeated clicks on the custom option will not trigger this change handler
              return;
            }

            onSelect(option.value);
            trackOptionClick(option);
          }}
          classes={{
            select: css((t) => ({
              fontSize: t.custom.fontSize.m
            }))
          }}
        >
          {options.map((o, i) => (
            <MenuItem
              key={i}
              value={i}
              onClick={() => {
                if (o.customize) {
                  setCustomDialogOpen(true);
                  trackOptionClick(o);
                }
              }}
            >
              {o.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <CustomDialog
        open={customDialogOpen}
        onClose={() => setCustomDialogOpen(false)}
        value={value}
        onSelect={(tf) => {
          setCustomDialogOpen(false);
          onSelect(tf);
        }}
      />
    </TimeframePickerContainer>
  );
};
