import { IconButton, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { Info, Minus, X } from 'react-feather';
import { InternalId } from '../../../../components/ConnectionId';
import {
  ItemSorters,
  RowsRenderer
} from '../../../../components/GroupableList';
import { JobStatusBadge } from '../../../../components/JobStatus';
import { PlatformWithColor } from '../../../../components/PlatformWithColor';
import { IColumn } from '../../../../components/Table/Column';
import { Dash } from '../../../../components/Table/CountCell';
import { Doc } from '../../../../domainTypes/document';
import { Job, JobStatus } from '../../../../domainTypes/job';
import { ISecret, ISecretWithTs } from '../../../../domainTypes/secret';
import { useHasCurrentUserRequiredScopes } from '../../../../services/currentUser';
import { removeDoc } from '../../../../services/db';
import { getKnownPartnerForKeyUnsafe } from '../../../../services/partner';
import { HUMAN_DATE_PRECISE, toMoment } from '../../../../services/time';
import { useApiReportHandlers } from '../../services/handlers';
import { IApiReportHandler } from '../../services/handlers/types';

type ColumnName =
  | 'spaceId'
  | 'handler'
  | 'jobId'
  | 'dateRange'
  | 'status'
  | 'progress'
  | 'lastUpdated'
  | 'actions';

type OtherProps = {
  handlers: IApiReportHandler[];
  secrets: Doc<ISecret>[];
  permissions: {
    canCancelJobs: boolean;
  };
};

const COLUMNS: IColumn<Doc<Job>, ColumnName, OtherProps>[] = [
  {
    key: 'handler',
    head: () => 'Integration (ID)',
    headInfo: () =>
      "The platform and connection ID that's scheduled to update.",
    cell: (d, o) => {
      const handlerName = d.data.handler;
      const handler = o.handlers.find((h) => h.configName === handlerName);
      if (!handler) {
        return handlerName;
      }
      const partner = getKnownPartnerForKeyUnsafe(handler.partnerKey);
      const secret = o.secrets.find(
        (s) => s.data.instanceId === d.data.instanceId
      );

      return (
        <div style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
          <PlatformWithColor partner={partner} />
          {secret && secret.data.nickname ? (
            <Typography variant="caption" color="textSecondary">
              {secret.data.nickname}
            </Typography>
          ) : secret && handler.additionalName ? (
            <Typography variant="caption" color="textSecondary">
              {handler.additionalName(partner, secret)}
            </Typography>
          ) : null}
          <InternalId>{d.data.instanceId}</InternalId>
        </div>
      );
    },
    align: 'left',
    width: 380
  },
  {
    key: 'dateRange',
    head: () => 'Date range',
    headInfo: () =>
      'Dates of transactions to be imported in this batch. Daily imports pull a reporting period of up to 2 weeks, whereas weekly imports update all pending transactions.',
    cell: (d) => {
      return (
        <>
          {toMoment(d.data.range.start).format('MM/DD/YY')}&ndash;
          {toMoment(d.data.range.end).format('MM/DD/YY')}
        </>
      );
    },
    align: 'left',
    width: 170
  },
  {
    key: 'status',
    head: () => 'Status',
    cell: (d) => <JobStatusBadge job={d.data} />,
    sortable: true,
    align: 'left',
    width: 120
  },
  {
    key: 'lastUpdated',
    head: () => 'Created or updated',
    headInfo: () =>
      'When this import was either originally scheduled or last updated.',
    cell: (d) => {
      if (!d.data.lastUpdated) {
        return <Minus size={16} color="#DDD" />;
      }
      const m = toMoment(d.data.lastUpdated);
      return (
        <Tooltip title={m.format(HUMAN_DATE_PRECISE)} placement="top">
          <div>{m.fromNow()}</div>
        </Tooltip>
      );
    },
    align: 'left',
    width: 150
  },
  {
    key: 'progress',
    head: () => 'Progress',
    headInfo: () =>
      'Percentage of transactions imported from these dates. Depending on API limits and the number of transactions, it can take several hours to finish.',
    cell: (d) => `${d.data.progress.percentage}%`,
    align: 'left',
    width: 100
  },
  {
    key: 'jobId',
    head: () => 'Job ID',
    cell: (d) => (
      <InternalId>
        {d.id}{' '}
        {d.data.message && (
          <Tooltip title={d.data.message} placement="top">
            <span>
              <Info size={10} />
            </span>
          </Tooltip>
        )}{' '}
      </InternalId>
    ),
    sortable: true,
    align: 'left',
    width: 100
  },
  {
    key: 'actions',
    head: () => 'Actions',
    cell: (d, o) => {
      return o.permissions.canCancelJobs && d.data.status !== JobStatus.done ? (
        <Tooltip placement="top" title="Cancel or remove import job">
          <IconButton
            style={{ padding: 0 }}
            onClick={() => {
              removeDoc(d);
            }}
          >
            <X size={16} />
          </IconButton>
        </Tooltip>
      ) : (
        <Dash />
      );
    },
    align: 'center',
    width: 50
  }
];

const rowToKey = (j: Doc<Job>) => j.id;

const SORTERS: ItemSorters<Doc<Job>> = {
  handler: {
    key: 'status',
    items: {
      sort: [
        (d) => d.data.status,
        (d) => (d.data.lastUpdated === null ? 0 : -d.data.lastUpdated)
      ],
      dir: 'desc'
    }
  }
};

const DEFAULT_SORTER = SORTERS.handler;

export const ImportActivityTable = ({
  jobs,
  secrets
}: {
  jobs: Doc<Job>[];
  secrets: Doc<ISecretWithTs>[];
}) => {
  const handlers = useApiReportHandlers();
  const [canCancelJobs] = useHasCurrentUserRequiredScopes([
    'integrations.trigger_pull'
  ]);

  // TODO: If the user doesn't have access to payout reporting
  // and the job is a payout job, don't show it.
  // But continue to show these (but lower opacity) if the user
  // is an admin/impersonator.

  return (
    <RowsRenderer
      variant="contained"
      rows={jobs}
      otherProps={{ handlers, secrets, permissions: { canCancelJobs } }}
      rowToKey={rowToKey}
      renderHead={true}
      columns={COLUMNS}
      sorter={DEFAULT_SORTER}
      sortDirection={DEFAULT_SORTER.items.dir}
    />
  );
};
