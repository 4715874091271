import { flatten, groupBy, keyBy, mapValues } from 'lodash';
import {
  IPageScreenshotQueueItem,
  QueueStatus,
  isErroredQueueStatus,
  isQueueItemActive
} from '../../../domainTypes/page';
import {
  rdb,
  toRef,
  useRdbList,
  useMappedLoadingValue
} from '../../../services/db';
import { RDB } from '../../../versions';
import { useObjectVal } from 'react-firebase-hooks/database';
import { toDocsFromRdbObject, Doc } from '../../../domainTypes/document';
import { callFirebaseFunction } from '../../../services/firebaseFunctions';
import { removeTrailingSlash } from '../../../services/url';

export const useAllQueues = () => {
  return useMappedLoadingValue(
    useObjectVal<{
      [spaceId: string]: {
        [docId: string]: IPageScreenshotQueueItem;
      };
    }>(rdb().ref(toRef(RDB.pageQueue))),
    (v) => {
      return flatten(
        Object.values(v).map((perSpace) => toDocsFromRdbObject(perSpace))
      );
    }
  );
};

export const useQueue = (spaceId: string) => {
  return useRdbList<IPageScreenshotQueueItem>(
    rdb().ref(toRef(RDB.pageQueue, spaceId))
  );
};

export type QueueCounts = {
  running: number;
  error: number;
  done: number;
  aborted: number;
  queued: number;
  noScript: number;
  timedout: number;
};

export const countItems = (items: Doc<IPageScreenshotQueueItem>[]) => {
  return items.reduce<QueueCounts>(
    (m, d) => {
      const { status } = d.data;
      if (status === QueueStatus.DONE) {
        m.done++;
      }
      if (status === QueueStatus.ABORTED) {
        m.aborted++;
      }
      if (status === QueueStatus.ERROR) {
        m.error++;
      }
      if (status === QueueStatus.NO_SCRIPT) {
        m.noScript++;
      }
      if (status === QueueStatus.QUEUED) {
        m.queued++;
      }
      if (status === QueueStatus.RUNNING) {
        m.running++;
      }
      if (status === QueueStatus.TIMEOUT) {
        m.timedout++;
      }
      return m;
    },
    {
      running: 0,
      error: 0,
      done: 0,
      aborted: 0,
      queued: 0,
      noScript: 0,
      timedout: 0
    }
  );
};

const createQueueItem = (
  spaceId: string,
  url: string,
  queuedAt: number,
  lastRun: number | null,
  lastKnownModificationDate: number | null,
  status: QueueStatus,
  runningSince: number | null
): IPageScreenshotQueueItem => {
  return {
    spaceId,
    url: removeTrailingSlash(url),
    lastKnownModificationDate: lastKnownModificationDate || null,
    lastRun: lastRun || null,
    sortKey: `${status}-${queuedAt}`,
    status,
    running: status === QueueStatus.RUNNING,
    errored: isErroredQueueStatus(status),
    queuedAt,
    runningSince
  };
};

const abortItem = (
  q: Doc<IPageScreenshotQueueItem>
): Doc<IPageScreenshotQueueItem> => {
  const nextStatus = QueueStatus.ABORTED;
  return {
    ...q,
    data: {
      ...q.data,
      runningSince: null,
      running: false,
      errored: false,
      status: nextStatus,
      sortKey: `${nextStatus}-${q.data.queuedAt}`
    }
  };
};

const createRequeuedItem = (
  item: Doc<IPageScreenshotQueueItem>,
  now: number
): Doc<IPageScreenshotQueueItem> => {
  const d = item.data;
  const data = createQueueItem(
    d.spaceId,
    d.url,
    now,
    d.lastRun,
    d.lastKnownModificationDate,
    QueueStatus.QUEUED,
    null
  );

  return { id: item.id, collection: item.collection, data };
};

const saveItems = (items: Doc<IPageScreenshotQueueItem>[]) => {
  const bySpace = groupBy(items, (d) => d.data.spaceId);
  return Promise.all(
    Object.entries(bySpace).map(([spaceId, docs]) => {
      const updateMap = mapValues(
        keyBy(docs, (d) => d.id),
        (d) => d.data
      );
      return rdb().ref(toRef(RDB.pageQueue, spaceId)).update(updateMap);
    })
  );
};

export const requeueErroredItems = (items: Doc<IPageScreenshotQueueItem>[]) => {
  return requeueItems(items.filter((d) => d.data.errored));
};

export const abortItems = (items: Doc<IPageScreenshotQueueItem>[]) => {
  return saveItems(items.map(abortItem));
};

export const abortRunningAndQueuedItems = (
  items: Doc<IPageScreenshotQueueItem>[]
) => {
  return abortItems(items.filter((q) => isQueueItemActive(q.data)));
};

export const requeueItems = (items: Doc<IPageScreenshotQueueItem>[]) => {
  const now = Date.now();
  const requeuedItems = items.map((d) => createRequeuedItem(d, now));
  return saveItems(requeuedItems);
};

export const resumeAllQueues = (): Promise<any> =>
  callFirebaseFunction('pages-resumeAllQueues');

export const forceStartItem = (item: Doc<IPageScreenshotQueueItem>) => {
  return callFirebaseFunction('pages-forceStartItem', {
    spaceId: item.data.spaceId,
    itemId: item.id
  });
};
