import React, { useState } from 'react';
import { StripeProvider as SP } from 'react-stripe-elements';
import * as ENV from '../../../../env.json';
import { useAfterFirstRender } from '../../../../hooks/useAfterFirstRender';

const loadStripe = (): Promise<any> => {
  return new Promise(resolve => {
    const stripeJs = document.createElement('script');
    stripeJs.src = 'https://js.stripe.com/v3/';
    stripeJs.async = true;
    stripeJs.onload = () => {
      resolve((window as any).Stripe(ENV.stripe.publishableKey));
    };
    document.body && document.body.appendChild(stripeJs);
  });
};

export const StripeProvider: React.FC = ({ children }) => {
  const [stripe, setStripe] = useState(null);
  useAfterFirstRender(() => {
    loadStripe().then(setStripe);
  });
  return <SP stripe={stripe}>{children}</SP>;
};
