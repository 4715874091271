import React, { useMemo } from 'react';
import { SlotEditDialog, useEditDialog } from './SlotEditDialog';
import {
  CustomDimensionName,
  ICustomDimensionsDoc
} from '../../../../../domainTypes/customDimensions';
import { Card } from '@material-ui/core';
import { css, styled } from '../../../../../emotion';
import Typography from '@material-ui/core/Typography';
import { AddSlot } from './AddSlot';
import { Doc } from '../../../../../domainTypes/document';
import { BookOpen } from 'react-feather';
import { Section } from '../../../../../layout/Section';
import { useRoutes } from '../../../../../routes';
import { Link } from 'react-router-dom';
import { SlotDeleteDialog, useDeleteDialog } from './SlotDeleteDialog';
import { CustomDimensionsTable } from './CustomDimensionsTable';
import { useHasCurrentUserRequiredScopes } from '../../../../../services/currentUser';

interface CustomDimensionsManagerProps {
  doc: Doc<ICustomDimensionsDoc>;
}

const StyledDocsIcon = styled(Link)`
  position: relative;
  top: 4px;
  right: -2px;
  color: ${(p) => p.theme.palette.primary.main};
  display: inline-block;
  margin: ${(p) => p.theme.spacing(1)}px;
`;

export const CustomDimensionsManager: React.FC<CustomDimensionsManagerProps> = ({
  doc
}) => {
  const [canCreate] = useHasCurrentUserRequiredScopes([
    'custom_dimensions.create'
  ]);
  const { ROUTES } = useRoutes();
  const { editDialogState, openEditDialog, closeEditDialog } = useEditDialog();
  const {
    deleteDialogState,
    closeDeleteDialog,
    openDeleteDialog
  } = useDeleteDialog();
  const dimensions = useMemo(() => doc.data.dimensions, [doc.data]);
  const activeDimensions = useMemo<CustomDimensionName[]>(
    () => Object.keys(dimensions).sort() as CustomDimensionName[],
    [dimensions]
  );

  return (
    <>
      <Section
        className={css((t) => ({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: t.spacing(2)
        }))}
      >
        <Typography
          variant="h6"
          component="span"
          style={{
            marginRight: '9px',
            position: 'relative',
            fontWeight: 'bold',
            top: '-2px'
          }}
        >
          Manage custom dimensions
          {/*// FIXME: Update docs route param*/}
          <StyledDocsIcon
            to={ROUTES.docs.knowledgeBase.url('custom-dimensions')}
          >
            <BookOpen size={22} />
          </StyledDocsIcon>
        </Typography>
        {canCreate && <AddSlot activeDimensions={activeDimensions} />}
      </Section>
      <Card>
        {activeDimensions.length === 0 ? (
          <Typography variant="body1" component="h2" paragraph>
            Start adding your custom dimensions with the button above.
          </Typography>
        ) : (
          <CustomDimensionsTable
            activeDimensions={activeDimensions}
            dimensions={dimensions}
            editSlot={openEditDialog}
            deleteSlot={openDeleteDialog}
          />
        )}
        {deleteDialogState.open && (
          <SlotDeleteDialog
            customDimensionsDoc={doc}
            slot={deleteDialogState.slot}
            open={deleteDialogState.open}
            onCancel={closeDeleteDialog}
          />
        )}
        {editDialogState.open && (
          <SlotEditDialog
            key={editDialogState.slot}
            open={editDialogState.open}
            closeDialog={closeEditDialog}
            slot={editDialogState.slot}
            customDimensionsDoc={doc}
          />
        )}
      </Card>
    </>
  );
};
