import { flatten } from 'lodash';
import { ParseConfig, UnparseConfig, UnparseObject } from 'papaparse';
import { WorkSheet } from 'xlsx/types';

type FileTypeMetadata = {
  ext: string;
  mediaType: string;
};

export type FileType = 'csv' | 'tsv' | 'xlsx';
export type FileTypeOption = {
  value: FileType;
  label: string;
};

export const FILE_TYPE_OPTIONS: FileTypeOption[] = [
  {
    value: 'csv',
    label: 'Comma-separated Values (*.csv)'
  },
  {
    value: 'tsv',
    label: 'Tab-separated Values (*.tsv)'
  },
  {
    value: 'xlsx',
    label: 'Microsoft Excel (*.xlsx)'
  }
];

const FILE_TYPE_METADATA: { [K in FileType]: FileTypeMetadata } = {
  csv: {
    ext: 'csv',
    mediaType: 'data:text/csv;charset=utf-8'
  },
  tsv: {
    ext: 'tsv',
    mediaType: 'data:text/tab-separated-values;charset=utf-8'
  },
  xlsx: {
    ext: 'xlsx',
    mediaType:
      'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  }
};

export const readFileAsUint8Array = (file: File) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = function (e) {
      const data = new Uint8Array(this.result as ArrayBuffer);
      resolve(data);
    };
    reader.onerror = function (e) {
      reject(this.error);
    };
    reader.readAsArrayBuffer(file);
  });
};

export const readFileAsText = (file: File) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = function (e) {
      resolve(this.result as string);
    };
    reader.onerror = function (e) {
      reject(this.error);
    };
    reader.readAsText(file);
  });
};
export const readFileAsDataURL = (file: File): Promise<string> => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = function (e) {
      resolve(this.result as string);
    };
    reader.onerror = function (e) {
      reject(this.error);
    };
    reader.readAsDataURL(file);
  });
};

export const readCsvRows = async (
  file: File,
  options: ParseConfig
): Promise<any[][]> => {
  const papa = await import('papaparse');
  return new Promise((resolve, reject) => {
    papa.parse(file, {
      skipEmptyLines: true,
      error: reject,
      complete: (res) => resolve(res.data),
      ...options
    });
  });
};

export const toCsv = async (
  data: Array<Object> | Array<Array<any>> | UnparseObject,
  config?: UnparseConfig
) => {
  const papa = await import('papaparse');
  return papa.unparse(data, config);
};

export const toWorksheet = async (data: (string | number | boolean)[][]) => {
  const xlsx = await import('xlsx');
  return xlsx.utils.aoa_to_sheet(data);
};

export const downloadWorksheetAsXlsx = async (
  fileName: string,
  ws: WorkSheet
) => {
  const xlsx = await import('xlsx');
  const wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws);
  return xlsx.writeFile(wb, `${fileName}.xlsx`);
};

export const readXlsxRows = async (file: File): Promise<any[][]> => {
  const xlsx = await import('xlsx');
  const f = await readFileAsUint8Array(file);
  const wb = xlsx.read(f, { type: 'array' });
  const sheet = wb.Sheets[wb.SheetNames[0]];
  return xlsx.utils.sheet_to_json(sheet, {
    raw: false,
    defval: '',
    header: 1
  }); // tricking the export to create arrays of arrays
};

export const isZipFile = (fileName: string) => fileName.match(/zip$/);

export const unzipFile = async (file: File): Promise<File[]> => {
  if (!isZipFile(file.name)) {
    return [file];
  }
  const jszip = await import('jszip');
  const zip = await jszip.loadAsync(file);
  const toResolve: Promise<File>[] = [];
  zip.forEach((p, f) => {
    if (isZipFile(f.name)) {
      return; // currently only handling one layer
    }
    toResolve.push(f.async('blob').then((blob) => new File([blob], f.name)));
  });
  return Promise.all(toResolve);
};

export const unzipFiles = (files: File[]): Promise<File[]> => {
  return Promise.all(files.map(unzipFile)).then(flatten);
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const downloadFile = (
  fileName: string,
  fileType: FileType,
  data: string
) => {
  const ft = FILE_TYPE_METADATA[fileType];
  const a = document.createElement('a');
  a.href = `${ft.mediaType},${encodeURIComponent(data)}`;
  a.download = `${fileName}.${ft.ext}`;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const downloadFileFromBlob = (
  fileName: string,
  fileType: FileType,
  data: string
) => {
  const blob = new Blob([data], {
    type: FILE_TYPE_METADATA[fileType].mediaType
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
