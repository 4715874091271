import { ButtonBase, ButtonBaseProps } from '@material-ui/core';
import React from 'react';
import { styled } from '../../../emotion';

const SelectableButtonInner = styled<typeof ButtonBase, { selected: boolean }>(
  ButtonBase
)`
  display: block !important;
  border: 2px solid
    ${(p) =>
      p.selected
        ? p.theme.palette.primary.main
        : p.theme.palette.grey[200]} !important;
  border-radius: 8px !important;
  padding: ${(p) => p.theme.spacing(2)}px !important;
  text-align: left !important;

  span {
    display: block;
  }

  .heading {
    display: flex;
    align-items: center;
    font-weight: 700 !important;
    margin-bottom: ${(p) => p.theme.spacing(1)}px !important;
    color: ${(p) =>
      p.selected
        ? p.theme.palette.primary.main
        : p.theme.palette.common.black} !important;
  }

  .subheading {
    color: ${(p) => p.theme.palette.text.secondary} !important;
  }

  ul {
    padding-left: ${(p) => p.theme.spacing(2)}px;
  }
`;

export const SelectableChip = styled('span')`
  display: inline;
  margin-left: ${(p) => p.theme.spacing(1)}px;
  background-color: #eee;
  color: #666;
  padding: ${(p) => p.theme.spacing(0.3)}px ${(p) => p.theme.spacing(0.75)}px;
  border-radius: 4px;
  font-size: 11px;

  .selected & {
    background-color: ${(p) => p.theme.palette.primary.main};
    color: #fff;
  }
`;

export const SelectableButton: React.FC<
  ButtonBaseProps & {
    heading: React.ReactNode;
    subheading: React.ReactNode;
    selected: boolean;
  }
> = ({ heading, subheading, selected, ...rest }) => {
  return (
    <SelectableButtonInner
      {...rest}
      selected={selected}
      className={selected ? 'selected' : undefined}
    >
      <span className="heading">{heading}</span>
      <span className="subheading">{subheading}</span>
    </SelectableButtonInner>
  );
};
