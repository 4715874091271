import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@material-ui/core';
import firebase from 'firebase/app';
import { capitalize } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { AlertBox } from '../../../../components/AlertBox';
import { SectionSubheading } from '../../../../components/DetailsPage';
import { styled } from '../../../../emotion';
import { useDialogState } from '../../../../hooks/useDialogState';
import { CanvasBar } from '../../../../layout/Canvas';
import { LimitedWidth } from '../../../../layout/PageBody';
import { Section } from '../../../../layout/Section';
import { useCurrentUser } from '../../../../services/currentUser';
import { updateUser } from '../../../../services/user';
import { SettingsLayout } from '../../SettingsLayout';
import { UserProfileCardContent } from './UserProfileCardContent';

const auth = firebase.auth;

type Messages = {
  [key: string]: string;
};

const INITIAL_PW_STATE = {
  currentPassword: '',
  password: '',
  confirmPassword: '',
  errorCode: ''
};

const ChangePasswordDialog = ({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState(INITIAL_PW_STATE);

  const close = () => {
    setState(INITIAL_PW_STATE);
    onClose();
  };

  const handleSubmit = () => {
    if (!auth().currentUser) {
      return null;
    }

    const credential = auth.EmailAuthProvider.credential(
      auth().currentUser!.email as string,
      state.currentPassword
    );

    return auth()
      .currentUser!.reauthenticateWithCredential(credential)
      .then(() => auth().currentUser!.updatePassword(state.password))
      .then(() => {
        enqueueSnackbar('Password successfully updated!', {
          variant: 'success'
        });
        close();
      })
      .catch((resp) => {
        const messages = {
          'auth/weak-password':
            'Your password is too short! It needs to be at least 6 letters long.',
          'auth/wrong-password':
            'Your current password is not correct. If you forgot it, you can log out and use the reset password function to change your password instead.',
          default:
            'Not sure what went wrong! Contact us and we can help you sort it out.'
        } as Messages;

        setState((s) => ({
          ...s,
          errorCode: messages[resp.code] || messages.default
        }));
      });
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Change your password</DialogTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <DialogContent>
          {state.errorCode && (
            <AlertBox variant="error">{state.errorCode}</AlertBox>
          )}
          <Typography variant="body1" color="textSecondary" component="p">
            Choose a strong password please. Include letters, numbers, and
            symbols, and make it as long as possible. Your password needs to be
            at least 6 letters in length.
          </Typography>
          <br />
          <TextField
            required
            label="Current password"
            type="password"
            fullWidth={true}
            margin="normal"
            variant="outlined"
            value={state.currentPassword}
            onChange={(e) => {
              e.stopPropagation();
              const currentPassword = e.target.value;
              setState((s) => ({ ...s, currentPassword }));
            }}
          />
          <TextField
            required
            label="New password"
            type="password"
            variant="outlined"
            fullWidth={true}
            margin="normal"
            value={state.password}
            onChange={(e) => {
              e.stopPropagation();
              const password = e.target.value;
              setState((s) => ({ ...s, password }));
            }}
          />
          <TextField
            required
            label="New password (repeat)"
            type="password"
            fullWidth={true}
            variant="outlined"
            margin="normal"
            value={state.confirmPassword}
            onChange={(e) => {
              e.stopPropagation();
              const confirmPassword = e.target.value;
              setState((s) => ({ ...s, confirmPassword }));
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Close</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={
              state.password === '' || state.password !== state.confirmPassword
            }
          >
            Change password
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const INITIAL_EMAIL_STATE = (email: string) => ({
  email,
  confirmEmail: '',
  errorCode: ''
});

const ConfirmPasswordDialog = ({
  currentEmail,
  open,
  onClose
}: {
  currentEmail: string;
  open: boolean;
  onClose: (success?: boolean) => void;
}) => {
  const [state, setState] = useState({
    password: '',
    errored: false
  });
  const close = (success?: boolean) => {
    setState({ password: '', errored: false });
    onClose(success);
  };

  const submit = () => {
    const currentUser = auth().currentUser;
    if (!currentUser) {
      setState((s) => ({ ...s, errored: true }));
      return;
    }
    return currentUser
      .reauthenticateWithCredential(
        auth.EmailAuthProvider.credential(currentEmail, state.password)
      )
      .then(
        () => {
          close(true);
        },
        () => setState((s) => ({ ...s, errored: true }))
      );
  };

  return (
    <Dialog open={open} onClose={() => close()}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
      >
        <DialogContent>
          {state.errored && (
            <AlertBox variant="error">
              Password verification failed. Try again!
            </AlertBox>
          )}
          <p>Please re-enter your password.</p>

          <TextField
            required
            label="Password"
            type="password"
            fullWidth={true}
            margin="normal"
            value={state.password}
            onChange={(e) => {
              const password = e.target.value;
              setState((s) => ({ ...s, password }));
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => close()}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!state.password}
          >
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const ChangeEmailDialog = ({
  currentEmail,
  open,
  onClose
}: {
  currentEmail: string;
  open: boolean;
  onClose: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState(INITIAL_EMAIL_STATE(currentEmail));
  const {
    dialogOpen: pwDialogOpen,
    openDialog: openPwDialog,
    closeDialog: closePwDialog
  } = useDialogState(false);

  const close = () => {
    setState(INITIAL_EMAIL_STATE(currentEmail));
    onClose();
  };

  const changeEmail = () => {
    const currentUser = auth().currentUser;
    if (!currentUser) {
      return null;
    }

    currentUser
      .updateEmail(state.email)
      .then(() => updateUser(currentUser.uid, { email: state.email }))
      .then(() => {
        enqueueSnackbar('We successfully changed your email!', {
          variant: 'success'
        });
        close();
      })
      .catch((resp) => {
        if (resp?.code === 'auth/requires-recent-login') {
          openPwDialog();
        } else {
          const messages: Messages = {
            'auth/invalid-email':
              'This looks like an invalid email address. Please try again!',
            'auth/email-already-in-use':
              'This email address is already in use! Pleas use another one.',
            default:
              'Not sure what went wrong! Contact us and we can help you sort it out.'
          };

          setState((s) => ({
            ...s,
            errorCode: messages[resp?.code] || messages.default
          }));
        }
      });
  };

  return (
    <>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Change your email address</DialogTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            changeEmail();
          }}
        >
          <DialogContent>
            {state.errorCode && (
              <AlertBox variant="error">{state.errorCode}</AlertBox>
            )}
            <TextField
              required
              label="New email"
              type="text"
              fullWidth={true}
              margin="normal"
              variant="outlined"
              value={state.email}
              onChange={(e) => {
                e.stopPropagation();
                const email = e.target.value;
                setState((s) => ({ ...s, email }));
              }}
            />
            <TextField
              required
              label="New email (repeat)"
              type="text"
              variant="outlined"
              fullWidth={true}
              margin="normal"
              value={state.confirmEmail}
              onChange={(e) => {
                e.stopPropagation();
                const confirmEmail = e.target.value;
                setState((s) => ({ ...s, confirmEmail }));
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={close}>Close</Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={
                state.email === '' ||
                state.email !== state.confirmEmail ||
                state.email === currentEmail
              }
            >
              Change email
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmPasswordDialog
        currentEmail={currentEmail}
        open={pwDialogOpen}
        onClose={(success) => {
          closePwDialog();
          if (success) {
            changeEmail();
          }
        }}
      />
    </>
  );
};

const Actions = styled(CardActions)`
  justify-content: flex-end;
`;

export const PagesSettingsNewProfile = () => {
  const {
    dialogOpen: emailDialogOpen,
    openDialog: openEmailDialog,
    closeDialog: closeEmailDialog
  } = useDialogState(false);
  const {
    dialogOpen: pwDialogOpen,
    openDialog: openPwDialog,
    closeDialog: closePwDialog
  } = useDialogState(false);

  const currentUser = useCurrentUser();

  const hasPasswordLogin = !!currentUser.authUser?.providerData.find(
    (provider: any) => provider?.providerId === 'password'
  );

  const hasOnlyPasswordLogin =
    hasPasswordLogin && currentUser.authUser?.providerData.length === 1;

  return (
    <>
      <SettingsLayout>
        <Helmet>
          <title>Profile Settings | Affilimate</title>
        </Helmet>
        <LimitedWidth width={800}>
          <Section>
            <SectionSubheading>User profile</SectionSubheading>
            <Card>
              <CardContent>
                <UserProfileCardContent currentUser={currentUser} />{' '}
              </CardContent>
            </Card>
          </Section>
          <Section>
            <SectionSubheading>Login details</SectionSubheading>
            <Card>
              <CardContent>
                <Typography variant="body1" component="p" paragraph>
                  You're currently logged in with the following email address.
                </Typography>
                <br />
                <TextField
                  label="Email address"
                  variant="outlined"
                  value={currentUser.email || ''}
                  fullWidth={true}
                  disabled
                />
              </CardContent>
              {hasPasswordLogin && (
                <Actions>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={openEmailDialog}
                  >
                    Change email...
                  </Button>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={openPwDialog}
                  >
                    Change password...
                  </Button>
                </Actions>
              )}
            </Card>
          </Section>

          {!hasOnlyPasswordLogin && (
            <Section>
              <CanvasBar>Authentication providers</CanvasBar>
              <Card>
                <CardContent>
                  {!hasPasswordLogin && (
                    <AlertBox variant="pending">
                      You're currently logging in to Affilimate with a
                      third-party provider like Google or Facebook. If you'd
                      like to enable to email-based login, just logout and click
                      "Reset Password" to do so.
                    </AlertBox>
                  )}

                  <Typography variant="body2">
                    With Affilimate, you have authenticated at least once using:
                  </Typography>
                  <ul>
                    {currentUser.authUser?.providerData.map(
                      (provider: any) =>
                        provider && (
                          <li key={provider.providerId}>
                            {capitalize(provider.providerId)}
                          </li>
                        )
                    )}
                  </ul>
                  <Typography variant="body2">
                    To change passwords in any service besides Affilimate (such
                    as Google or Facebook), please change your password in that
                    service directly.
                  </Typography>
                </CardContent>
              </Card>
            </Section>
          )}
        </LimitedWidth>
      </SettingsLayout>
      <ChangePasswordDialog open={pwDialogOpen} onClose={closePwDialog} />
      <ChangeEmailDialog
        open={emailDialogOpen}
        onClose={closeEmailDialog}
        currentEmail={currentUser.email || ''}
      />
    </>
  );
};
