import { IconButton, IconButtonProps } from '@material-ui/core';
import React from 'react';
import {
  CheckSquare as IconCheckSquare,
  MinusSquare as IconMinusSquare,
  Square as IconSquare
} from 'react-feather';

type Props = Omit<IconButtonProps, 'value' | 'onChange' | 'disabled'> & {
  value: boolean;
  onChange: (nextValue: boolean) => void;
  indeterminate?: boolean;
  disabled?: boolean;
};

export const SelectionBox = React.forwardRef<HTMLButtonElement, Props>(
  ({ value, onChange, indeterminate, disabled, ...props }, ref) => {
    return (
      <IconButton
        onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          onChange(!value);
        }}
        disabled={disabled}
        {...props}
        ref={ref}
      >
        {indeterminate && <IconMinusSquare />}
        {value && !indeterminate && <IconCheckSquare />}
        {!value && !indeterminate && <IconSquare />}
      </IconButton>
    );
  }
);
