import React from 'react';
import { COLORS } from '../domainTypes/colors';
import { Job, JobStatus } from '../domainTypes/job';
import { Tooltip } from '@material-ui/core';
import { pluralize } from '../services/pluralize';
import { AlertTriangle, Clock } from 'react-feather';
import { Badge } from './Badge';

type IAlertStatusConfig = {
  bgColor: string;
  color: string;
  message: string;
};

const CONFIG: { [key in JobStatus]: IAlertStatusConfig } = {
  [JobStatus.done]: {
    bgColor: COLORS.blue.blue2,
    color: COLORS.blue.blue8,
    message: 'Done'
  },
  [JobStatus.running]: {
    bgColor: COLORS.green.green2,
    color: COLORS.green.green8,
    message: 'In progress'
  },
  [JobStatus.queued]: {
    bgColor: COLORS.gold.gold2,
    color: COLORS.gold.gold8,
    message: 'Waiting'
  },
  [JobStatus.error]: {
    bgColor: COLORS.volcano.volcano2,
    color: COLORS.volcano.volcano8,
    message: 'Error'
  },
  [JobStatus.cancelled]: {
    bgColor: COLORS.volcano.volcano2,
    color: COLORS.volcano.volcano8,
    message: 'Cancelled'
  }
};

const toTitle = (job: Job) => {
  const { status } = job;
  if (status === JobStatus.running) {
    return 'Import is in progress. Depending on transaction count and API limits, this can take several hours to finish.';
  }
  if (status === JobStatus.queued) {
    return 'Import is either waiting to be prioritized or waiting for available API quota from the platform.';
  }
  if (status === JobStatus.done) {
    return 'Import is finished pulling transactions during this date range';
  }
  if (status === JobStatus.error && job.retriesRemaining !== 0) {
    return `Import encountered an error, and has ${
      job.retriesRemaining
    } of ${pluralize(
      'retry',
      job.retries,
      true
    )} remaining. It will automatically attempt to pull this data again.`;
  }
  if (status === JobStatus.error && job.retriesRemaining === 0) {
    return `Import encountered an error and exhausted ${job.retries} retry attempts, it should be reported to Support.`;
  }

  return 'Unknown status';
};

export const JobStatusBadge = ({ job }: { job: Job }) => {
  return (
    <Tooltip title={toTitle(job)} placement="top">
      <Badge
        color={CONFIG[job.status].color}
        bgColor={CONFIG[job.status].bgColor}
      >
        {CONFIG[job.status].message}
        {job.retriesRemaining === 0 && (
          <>
            &nbsp;&nbsp;
            <AlertTriangle size={12} />
          </>
        )}
        {job.retriesRemaining !== 0 && job.status === JobStatus.error && (
          <>
            &nbsp;&nbsp;
            <Clock size={12} />
          </>
        )}
      </Badge>
    </Tooltip>
  );
};
