// These tags apply to our internal admin app which uses tags
// to segment websites we are crawling for links
// The tags used for segmentation for end users is in a file
// called tags.ts (as opposed to tag.ts)

import { Doc } from './document';
import { IPostgresTags } from './tags';
import { Timestamp } from './time';

export type ITagCategory = 'BLOG_TOPIC';

type Color =
  | 'red'
  | 'volcano'
  | 'orange'
  | 'gold'
  | 'yellow'
  | 'lime'
  | 'green'
  | 'cyan'
  | 'blue'
  | 'geekblue'
  | 'purple'
  | 'magenta';

export interface ITagPrototype {
  id: string;
  spaceId: string;
  color: Color;

  name: string;
  description: string;

  category: ITagCategory; // make certain labels only apply to certain things

  // future features to build label hierarchies? we shall see
  parents: string[];
  children: string[];

  createdAt: Timestamp;
  createdBy: string;

  editedAt: Timestamp;
  editedBy: string;
}

export interface ITag {
  id: string;
  createdAt: Timestamp;
  createdBy: string;
}

// to be used by other entities, which can be labelled
export interface ITaggable {
  tags: ITag[];
  tagIds: string[];
}

export const addTag = <T extends ITaggable>(
  entity: T,
  tagId: string,
  createdBy: string,
  createdAt: Timestamp
): T => {
  if (entity.tagIds.indexOf(tagId) !== -1) {
    return entity;
  }
  return {
    ...entity,
    tagIds: [...entity.tagIds, tagId],
    tags: [...entity.tags, { id: tagId, createdAt, createdBy }]
  };
};

export const addTags = <T extends ITaggable>(
  entity: T,
  tagIds: string[],
  createdBy: string,
  createdAt: Timestamp
): T => {
  return tagIds.reduce(
    (m, tId) => addTag(m, tId, createdBy, createdAt),
    entity
  );
};

export const addTagsOnDoc = <T extends ITaggable>(
  doc: Doc<T>,
  tagIds: string[],
  createdBy: string,
  createdAt: Timestamp
): Doc<T> => {
  return {
    ...doc,
    data: addTags(doc.data, tagIds, createdBy, createdAt)
  };
};

export const removeTag = <T extends ITaggable>(entity: T, tagId: string): T => {
  if (entity.tagIds.indexOf(tagId) === -1) {
    return entity;
  }
  return {
    ...entity,
    tagIds: entity.tagIds.filter((l) => l !== tagId),
    tags: entity.tags.filter((l) => l.id !== tagId)
  };
};

export const replaceTags = <T extends ITaggable>(
  entity: T,
  tagIds: string[],
  createdBy: string,
  createdAt: Timestamp
): T => {
  return {
    ...entity,
    tagIds: tagIds,
    tags: tagIds.map((id) => ({ id, createdAt, createdBy }))
  };
};

export const replaceTagsOnDoc = <T extends ITaggable>(
  doc: Doc<T>,
  tagIds: string[],
  createdBy: string,
  createdAt: Timestamp
): Doc<T> => {
  return {
    ...doc,
    data: replaceTags(doc.data, tagIds, createdBy, createdAt)
  };
};

export const createAutomaticTag = (name: string, spaceId: string) => {
  return `${spaceId}-${name}`;
};

export const isAutomaticTagGroup = (
  tag: { id: string },
  spaceId: string
): boolean => {
  return tag.id.startsWith(spaceId);
};

export const isFromAutomaticGroup = (
  tag: IPostgresTags,
  spaceId: string
): boolean => {
  return !!tag.parent_id?.startsWith(spaceId);
};

const AUTHORS_GROUP_PREFIX = 'authors';

export const createAuthorsTagGroup = (spaceId: string) =>
  createAutomaticTag(AUTHORS_GROUP_PREFIX, spaceId);

export const isAuthorsTagGroup = (tag: { id: string }, spaceId: string) =>
  tag.id === createAuthorsTagGroup(spaceId);
