import { CustomDimensionName } from '../domainTypes/customDimensions';
import { WithShape } from './Charts/Util';
import { getStableRandomColor } from '../services/color';
import React, { ComponentProps, useMemo } from 'react';
import { useCustomDimension } from '../services/customDimensions';

export const CustomDimensionOptionLabel = ({
  slot,
  value,
  ...withShapeProps
}: {
  slot: CustomDimensionName;
  value: string;
} & Omit<ComponentProps<typeof WithShape>, 'color'>) => {
  const label = useCustomDimensionOptionLabel(slot, value);
  return (
    <WithShape {...withShapeProps} color={getStableRandomColor(value)}>
      {label}
    </WithShape>
  );
};

const useCustomDimensionOptionLabel = (
  slot: CustomDimensionName,
  value: string
): string => {
  const [dimension = {}] = useCustomDimension(slot);
  return useMemo(() => {
    if (dimension.linkGeneratorSettings?.type !== 'dictionary') return value;
    return dimension.options?.find((o) => o.id === value)?.name ?? value;
  }, [dimension.linkGeneratorSettings?.type, dimension.options, value]);
};
