import { Typography } from '@material-ui/core';
import React from 'react';

export const TagErrorMessage = () => (
  <Typography
    variant="body1"
    component="p"
    paragraph
    style={{ textAlign: 'center', maxWidth: '80%' }}
  >
    <strong>No tag data was loaded</strong>
    <br />
    <br />
    Try refreshing the page. If you expect to see data here and the error
    persists, please contact Support with the URL to this page.
  </Typography>
);
