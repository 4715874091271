import { useTimeframe } from '../../analytics_v2/Timeframe';
import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import Tooltip from '@material-ui/core/Tooltip';
import { styled } from '../../../emotion';
import { FlexContainer } from '../../../layout/Flex';
import { Dash } from '../CountCell';
import { useCurrentUser } from '../../../services/currentUser';

const colorScale = (start: moment.Moment, end: moment.Moment) => {
  if (end.diff(start, 'months') > 6) {
    return '#FA8C16';
  }
  if (end.diff(start, 'months') > 3) {
    return '#FFD666';
  }
  if (end.diff(start, 'months') > 1) {
    return '#D3F261';
  }
  if (end.diff(start, 'weeks') > 1) {
    return '#77CF7B';
  }
  return '#39B185';
};

const Swatch = styled<'div', { color: string }>('div')`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: ${(p) => p.color};
`;

const ColorSwatch = ({ ts }: { ts: number }) => {
  const {
    range: { end }
  } = useTimeframe();
  return <Swatch color={colorScale(moment.unix(ts), moment(end))} />;
};

const RELATIVE_DATE_THRESHOLD = 10;

const useFormattedTimestamp = (ts: number) => {
  const {
    range: { end }
  } = useTimeframe();
  return useMemo(() => {
    const updated = moment.unix(ts);
    const endMoment = moment(end);
    const today = moment();
    const includesToday = endMoment.isSame(today, 'day');
    const belowThreshold =
      today.diff(updated, 'days') < RELATIVE_DATE_THRESHOLD;
    const sameYear = updated.isSame(today, 'year');
    return includesToday && belowThreshold
      ? updated.fromNow()
      : updated.format(`MMM D${sameYear ? '' : ', YYYY'}`);
  }, [end, ts]);
};

const useTooltipMessage = (ts: number) => {
  const {
    space: {
      config: { tz }
    }
  } = useCurrentUser();
  return useMemo(() => {
    const updated = moment.unix(ts).tz(tz);
    return updated.format('MMM D, YYYY [at] LT z');
  }, [ts, tz]);
};

const TimestampCell = ({ timestamp }: { timestamp: number }) => {
  const formatted = useFormattedTimestamp(timestamp);
  const tooltipTitle = useTooltipMessage(timestamp);
  return (
    <Tooltip title={tooltipTitle} placement="top">
      <FlexContainer spacing={1} alignItems="center" justifyContent="flex-end">
        {formatted}
        <ColorSwatch ts={timestamp} />
      </FlexContainer>
    </Tooltip>
  );
};

export const DateCell: React.FC<{ timestamp?: number }> = ({
  timestamp = 0
}) => {
  if (timestamp === 0) {
    return <Dash size={12} />;
  }
  return <TimestampCell timestamp={timestamp} />;
};
