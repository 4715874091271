import { Timestamp } from './time';
import { CachedAdvertiser } from './advertiser';

export type CampaignFlatSpendIncentive = {
  type: 'flatSpend';
  amount: number;
  description: string;
};

export type CampaignRateIncreaseIncentive = {
  type: 'rateIncrease';
  from: number;
  to: number;
  description: string;
};

export type CampaignIncentiveType = CampaignIncentive['type'];

export type CampaignIncentive =
  | CampaignFlatSpendIncentive
  | CampaignRateIncreaseIncentive;

export const defaultIncentive = (
  type: CampaignIncentiveType
): CampaignIncentive => {
  switch (type) {
    case 'flatSpend':
      return {
        type: 'flatSpend',
        amount: 0,
        description: ''
      };
    case 'rateIncrease':
      return {
        type: 'rateIncrease',
        from: 0,
        to: 0,
        description: ''
      };
  }
};

export type CampaignGoal =
  | {
      type: 'roas';
      amount: number;
    }
  | {
      type: 'gmv';
      amount: number;
    }
  | {
      type: 'clicks';
      amount: number;
    }
  | {
      type: 'pageviews';
      amount: number;
    };

export type CampaignGoalType = CampaignGoal['type'];

export type CampaignGoals = Array<CampaignGoal>;

export type CampaignAdvertiser = Pick<
  CachedAdvertiser,
  'advertiserId' | 'name' | 'partnerKey' | 'integrationId'
>;

export const isOffsiteLink = (
  link: CampaignLink
): link is CampaignOffsiteLink => link.type === 'offsite';

export const isOnsiteLink = (link: CampaignLink): link is CampaignOnsiteLink =>
  link.type === 'onsite';

export type CampaignOffsiteLink = {
  type: 'offsite';
  id: string;
  channels: string[];
};

export type CampaignOnsiteLink = {
  type: 'onsite';
  id: string;
  pageUrls: string[];
};

export type CampaignLink = CampaignOnsiteLink | CampaignOffsiteLink;

export interface ICampaign {
  spaceId: string;
  name: string;
  advertisers: Array<CampaignAdvertiser>;
  pageUrls: string[];
  id: string;
  managers: string[];
  timeframe: {
    start: Timestamp;
    end: Timestamp;
  } | null;
  incentives: CampaignIncentive[];
  goals: CampaignGoal[];
  links: CampaignLink[];
}
