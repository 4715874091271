import React from 'react';
import {
  PayoutStatus,
  PAYOUT_UI_CONFIG
} from '../../../domainTypes/performance';
import { styled } from '../../../emotion';

const Badge = styled('span')`
  display: inline-block;
  font-size: ${(p) => p.theme.typography.caption.fontSize};
  border-radius: 999px;
  padding: ${(p) => p.theme.spacing(0.5)}px ${(p) => p.theme.spacing(1.5)}px;
  font-weight: bold;
`;

export const PayoutStatusBadge: React.FC<{
  status: PayoutStatus;
  style?: any;
}> = ({ status, style }) => {
  const data = PAYOUT_UI_CONFIG[status] || PAYOUT_UI_CONFIG['unknown'];
  const css = {
    backgroundColor: data.color,
    color: data.text,
    fontWeight: 700,
    ...style
  };
  return <Badge style={css}>{data.label}</Badge>;
};
