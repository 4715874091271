import React from 'react';
import { styled } from '../../../../../emotion';
import { Lock } from 'react-feather';
import { SupportButton } from '../../../../../components/SupportButton';
import { Centered } from '../../../../../layout/Centered';
import { Typography } from '@material-ui/core';
import { HelpIcon } from '../../../../../components/HelpIcon';
import { ARTICLE_IDS, Beacon } from '../../../../../services/beacon';

const Image = styled('img')`
  width: 700px;
`;

const Container = styled('div')`
  text-align: center;
`;

const HelpWrapper = styled('div')`
  margin-top: 24px;
`;

const Hero = () => {
  return (
    <Image
      src="/images/empty-states/traffic-sources-empty-state.png"
      alt="Traffic source reporting"
    />
  );
};

export const TrafficSourcesUpgradePrompt = () => {
  return (
    <Centered height={680}>
      <Container>
        <Hero />
        <Typography variant="h5" style={{ marginTop: 20 }}>
          <Lock size={18} /> Upgrade to access Traffic Sources and Campaigns
        </Typography>
        <Typography
          variant="body1"
          style={{ margin: '20px auto', width: '80%', maxWidth: '500px' }}
        >
          Traffic Sources is a suite of analytics reports for tracking and
          optimizing performance from diverse traffic sources (like search and
          social) and campaigns (like newsletters or paid ads).
        </Typography>
        <SupportButton
          label="Upgrade your account"
          size="large"
          subject="Request account upgrade"
          text="Hi team, I'd like to upgrade my account to access Traffic Source reporting."
        />
        <HelpWrapper>
          <HelpIcon
            color="gray"
            onClick={() => {
              Beacon('article', ARTICLE_IDS.traffic.overview);
            }}
          >
            Learn more about traffic sources reporting
          </HelpIcon>
        </HelpWrapper>
      </Container>
    </Centered>
  );
};
