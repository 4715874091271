import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { capitalize } from 'lodash';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import {
  AlertVariant,
  CardAlertBox
} from '../../../../../../../components/AlertBox';
import { ButtonWithPromise } from '../../../../../../../components/ButtonWithPromise';
import { Form } from '../../../../../../../components/Form';
import { FormField } from '../../../../../../../components/Form/FormField';
import { FormSubmitButton } from '../../../../../../../components/Form/FormSubmitButton';
import { Loader } from '../../../../../../../components/Loader';
import {
  getPlanType,
  hasTrialExpired,
  isInTrialGracePeriod,
  isInTrialIncludingGracePeriod,
  ISubscriptionDetails,
  isUsingForFree
} from '../../../../../../../domainTypes/billing';
import { ISpace } from '../../../../../../../domainTypes/space';
import { styled } from '../../../../../../../emotion';
import { InvoiceTable } from '../../../../../../../features/Billing/components/Invoices/Table';
import {
  BillingFrequency,
  Cards,
  Coupon,
  Label,
  NextBilling,
  PricePerMonth
} from '../../../../../../../features/Billing/components/SubscriptionDetails/Items';
import {
  useInvoices,
  useSubscription
} from '../../../../../../../features/Billing/service';
import { useDialogState } from '../../../../../../../hooks/useDialogState';
import { useErrorLogger } from '../../../../../../../hooks/useErrorLogger';
import { useSnackbar } from '../../../../../../../hooks/useSnackbar';
import { CanvasBar } from '../../../../../../../layout/Canvas';
import { Centered } from '../../../../../../../layout/Centered';
import { Section } from '../../../../../../../layout/Section';
import { callFirebaseFunction } from '../../../../../../../services/firebaseFunctions';
import { humanizeDuration, ONE_DAY } from '../../../../../../../services/time';
import { CF } from '../../../../../../../versions';
import { ButtonContainer } from '../../../../../../components/ButtonContainer';
import { updateBillingData } from '../../../../../../services/space';

type Props = {
  space: ISpace;
};

type SpaceProps = {
  space: ISpace;
};

// show current plan
// how much it's worth
// add links to stripe data
// query for subscription details if need be
// show trial date
// allow to manipulate grace period and trial end

type TrialForm = {
  trialUntil: number;
  gracePeriod: number;
};

const FormRow = styled('div')`
  display: flex;

  > * {
    margin-right: ${(p) => p.theme.spacing(2)}px;
    :last-child {
      margin-right: 0;
    }
  }
`;

const FormActions = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const toDurationChangeVerb = (duration: number) =>
  duration > 0 ? 'extended' : 'shortened';
const toDurationChange = (object: string, duration: number) =>
  `${object} ${toDurationChangeVerb(duration)} by ${humanizeDuration(
    duration
  )}`;

const toChangeSet = (before: TrialForm, after: TrialForm) => {
  const changes: string[] = [];
  const trialPeriodChange = after.trialUntil - before.trialUntil;
  const gracePeriodChange = after.gracePeriod - before.gracePeriod;
  if (trialPeriodChange) {
    changes.push(toDurationChange('Trial', trialPeriodChange));
  }
  if (gracePeriodChange) {
    changes.push(toDurationChange('Grace period', gracePeriodChange));
  }

  return changes.join(' - ');
};

const AlertBoxHeading = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomPlanDialog = ({
  spaceId,
  open,
  onClose
}: {
  spaceId: string;
  open: boolean;
  onClose: () => void;
}) => {
  const [subscriptionId, setSubscriptionId] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const connect = () =>
    callFirebaseFunction(CF.billing.connectCustomSubscription, {
      spaceId,
      subscriptionId
    }).then(
      () => {
        enqueueSnackbar('Subscription connected!', { variant: 'success' });
        onClose();
      },
      (err) => {
        console.log(err);
        enqueueSnackbar(`Connection failed`, { variant: 'error' });
      }
    );
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Connect Custom Plan</DialogTitle>
      <DialogContent>
        <TextField
          label="Subscription ID"
          type="text"
          value={subscriptionId}
          fullWidth={true}
          onChange={(ev) => setSubscriptionId(ev.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <ButtonWithPromise
          pending="Connecting..."
          variant="contained"
          color="primary"
          disabled={!subscriptionId}
          onClick={connect}
        >
          Connect
        </ButtonWithPromise>
      </DialogActions>
    </Dialog>
  );
};

const ChangePlanDialog = ({
  spaceId,
  open,
  onClose
}: {
  spaceId: string;
  open: boolean;
  onClose: () => void;
}) => {
  const [planId, setPlanId] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const change = () =>
    callFirebaseFunction(CF.billing.changePlan, {
      spaceId,
      planId
    }).then(
      () => {
        enqueueSnackbar('Plan changed!', { variant: 'success' });
        onClose();
      },
      (err) => {
        console.log(err);
        enqueueSnackbar(`Connection failed`, { variant: 'error' });
      }
    );
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Change Plan</DialogTitle>
      <DialogContent>
        <TextField
          label="Plan ID"
          type="text"
          value={planId}
          fullWidth={true}
          onChange={(ev) => setPlanId(ev.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <ButtonWithPromise
          pending="Changing..."
          variant="contained"
          color="primary"
          disabled={!planId}
          onClick={change}
        >
          Change
        </ButtonWithPromise>
      </DialogActions>
    </Dialog>
  );
};

const FreeMonthsDialog = ({
  open,
  onClose,
  spaceId
}: {
  spaceId: string;
  open: boolean;
  onClose: () => void;
}) => {
  const [months, setMonths] = useState(1);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Award free months</DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          label="Number of months"
          value={months}
          onChange={(ev) => setMonths(parseInt(ev.target.value))}
          inputProps={{
            min: 1
          }}
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <ButtonWithPromise
          variant="contained"
          color="secondary"
          onClick={() =>
            callFirebaseFunction('billing-awardFreeMonths', {
              spaceId,
              noOfMonths: months
            }).then(onClose)
          }
          pending="Awarding..."
        >
          Award
        </ButtonWithPromise>
      </DialogActions>
    </Dialog>
  );
};

const Trial = ({ space, now }: SpaceProps & { now: number }) => {
  const {
    dialogOpen: customPlanDialogOpen,
    openDialog: openCustomPlanDialog,
    closeDialog: closeCustomPlanDialog
  } = useDialogState();
  const { trialUntil, gracePeriod } = space.billing;

  if (!trialUntil) {
    console.error('NO trial until date - this should never happen');
    return null;
  }

  const expired = hasTrialExpired(space.billing, now);
  const inGracePeriod = isInTrialGracePeriod(space.billing, now);

  const expiresAt = moment(trialUntil);
  const gracePeriodEnd = moment(trialUntil + gracePeriod);

  const title = inGracePeriod
    ? `TRIAL EXPIRED - lock out ${gracePeriodEnd.fromNow()}`
    : expired
    ? `TRIAL EXPIRED - locked out ${gracePeriodEnd.fromNow()}`
    : `TRIAL - ends in ${expiresAt.fromNow()}`;
  const variant: AlertVariant = inGracePeriod
    ? 'pending'
    : expired
    ? 'error'
    : 'primary';

  const submit = (values: TrialForm) => updateBillingData(space.id, values);

  const initialValues: TrialForm = { trialUntil, gracePeriod };

  return (
    <>
      <Form<TrialForm> initialValues={initialValues} onSubmit={submit}>
        {({ submitting, invalid, pristine, values }) => (
          <Card>
            <CardAlertBox variant={variant}>
              <AlertBoxHeading>
                <strong>{title}</strong>
                <div>
                  <Button variant="outlined" onClick={openCustomPlanDialog}>
                    Connect Custom Plan
                  </Button>
                </div>
              </AlertBoxHeading>
            </CardAlertBox>
            <CardContent>
              <FormRow>
                <FormField<TrialForm['trialUntil']> name="trialUntil">
                  {({ input }) => (
                    <DateTimePicker
                      value={input.value}
                      disablePast
                      clearable={false}
                      onChange={(m) => m && input.onChange(m.valueOf())}
                      label="Trial until"
                      showTodayButton
                      minDateMessage={`Expired ${expiresAt.fromNow()}`}
                      ampm={false}
                    />
                  )}
                </FormField>

                <FormField<TrialForm['gracePeriod']> name="gracePeriod">
                  {({ input }) => (
                    <TextField
                      label="Grace period in days"
                      type="number"
                      value={input.value / ONE_DAY}
                      onChange={(ev) =>
                        input.onChange(+ev.target.value * ONE_DAY)
                      }
                    />
                  )}
                </FormField>
              </FormRow>
            </CardContent>
            <CardActions>
              <FormActions>
                <div>{toChangeSet(initialValues, values)}</div>
                <FormSubmitButton
                  variant="contained"
                  color="secondary"
                  submitting={submitting}
                  submitComponent="Applying..."
                  disabled={invalid || pristine}
                >
                  Apply changes
                </FormSubmitButton>
              </FormActions>
            </CardActions>
          </Card>
        )}
      </Form>
      <CustomPlanDialog
        spaceId={space.id}
        open={customPlanDialogOpen}
        onClose={closeCustomPlanDialog}
      />
    </>
  );
};

const Free = () => {
  return (
    <Card>
      <CardAlertBox variant="success">
        <strong>Free Plan</strong>
      </CardAlertBox>
    </Card>
  );
};

const SubscriptionDetailsGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${(p) => p.theme.spacing(2)}px;
  padding: ${(p) => p.theme.spacing(2)}px;
  text-align: center;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const GracePeriod = ({ space }: { space: ISpace }) => {
  // Make this an auto saving form

  return (
    <div>
      <Label>Grace Period</Label>
      <div>{humanizeDuration(space.billing.gracePeriod)}</div>
    </div>
  );
};

const SubscriptionDetails = ({
  space,
  details
}: {
  space: ISpace;
  details: ISubscriptionDetails;
}) => {
  const { cards, subscription } = details;
  const { plan } = subscription.items.data[0];
  if (!plan) {
    return (
      <Centered>
        No plan associated with this subscription. This looks like an error.
      </Centered>
    );
  }

  const coupon = subscription.discount ? subscription.discount.coupon : null;

  return (
    <SubscriptionDetailsGrid>
      <PricePerMonth plan={plan} coupon={coupon} />
      <NextBilling subscription={subscription} />
      <Coupon coupon={coupon} />
      <BillingFrequency plan={plan} />
      <Cards cards={cards} />
      <GracePeriod space={space} />
    </SubscriptionDetailsGrid>
  );
};

const Invoices = ({ spaceId }: { spaceId: string }) => {
  const [invoices, loading, error] = useInvoices(spaceId);
  if (loading) {
    return <Loader height={200} />;
  }
  if (error) {
    return <Centered height={200}>Failed to load invoices</Centered>;
  }
  if (!invoices || !invoices.length) {
    return <Centered>No invoices found</Centered>;
  }
  return <InvoiceTable invoices={invoices} />;
};

const Subscription = ({ space }: SpaceProps) => {
  const { id, billing } = space;
  const planType = getPlanType(billing.activePlan);
  const changePlanDialog = useDialogState();
  const freeMonthsDialog = useDialogState();
  const [subscription, loading, error] = useSubscription(id, billing);
  useErrorLogger(error);

  return (
    <Card>
      <CardAlertBox variant="success">
        <AlertBoxHeading>
          <strong>{capitalize(planType)} Plan</strong>

          <ButtonContainer>
            <Button variant="outlined" onClick={changePlanDialog.openDialog}>
              Change Plan
            </Button>
            <Button variant="outlined" onClick={freeMonthsDialog.openDialog}>
              Award free months
            </Button>
          </ButtonContainer>
        </AlertBoxHeading>
      </CardAlertBox>
      <CardContent>
        {loading && <Loader height={136} />}
        {error && <Centered>Failed to load subscription.</Centered>}
        {!loading && !error && !subscription && (
          <Centered>Where is this subscription?</Centered>
        )}
        {subscription && (
          <SubscriptionDetails space={space} details={subscription} />
        )}
        <Invoices spaceId={space.id} key={billing.activePlan || 'none'} />
      </CardContent>
      <ChangePlanDialog
        spaceId={space.id}
        open={changePlanDialog.dialogOpen}
        onClose={changePlanDialog.closeDialog}
      />
      <FreeMonthsDialog
        spaceId={space.id}
        open={freeMonthsDialog.dialogOpen}
        onClose={freeMonthsDialog.closeDialog}
      />
    </Card>
  );
};

export const Billing: React.FC<Props> = ({ space }) => {
  const d = space.billing;
  const now = Date.now();

  const trial =
    isInTrialIncludingGracePeriod(d, now) || hasTrialExpired(d, now);
  const free = isUsingForFree(d);

  return (
    <Section>
      <CanvasBar>Billing</CanvasBar>
      {trial && <Trial space={space} now={now} />}
      {free && <Free />}
      {!trial && !free && <Subscription space={space} />}
    </Section>
  );
};
