import {
  formatChartCurrency,
  formatChartNumber
} from '../../components/Charts/Util';
import { CurrencyCode } from '../../domainTypes/currency';
import { formatDate } from '../time';
import moment from 'moment-timezone';

// TODO: It should be equal to SelectableField in the end!
export const metrics = [
  'c',
  'ctr',
  'p',
  'v',
  's',
  'view_ratio',
  'commission_sum_net',
  'commission_count_net',
  'gmv_sum_net',
  'epc_net',
  'rpm_net',
  'aov_net',
  'avg_rate_net',
  'order_count_net',
  'order_count_gross',
  'quantity_net',
  'page_last_modified',
  'count_uniq_page_url',
  'count_uniq_link_occ',
  'count_uniq_link_id'
] as const;

export type Metric = typeof metrics[number];

export const isMetric = (m: string): m is Metric =>
  metrics.includes(m as Metric);

export const formatMetric = (
  value: number,
  metric: Metric,
  currency: CurrencyCode,
  opts: {
    verbose?: boolean;
  } = {}
): string => {
  switch (metric) {
    case 'count_uniq_link_occ':
      return formatChartNumber(value);
    case 'count_uniq_page_url':
      return formatChartNumber(value);
    case 'page_last_modified':
      // NOTE: basic formatter!
      return formatDate(moment.unix(value), 'YYYY-MM-DD');
    case 'aov_net':
      return formatChartCurrency(value, currency, { excludeCents: false });
    case 'avg_rate_net':
      return formatChartNumber(value);
    case 'order_count_net':
      return formatChartNumber(value);
    case 'commission_count_net':
      return formatChartNumber(value);
    case 'order_count_gross':
      return formatChartNumber(value);
    case 'quantity_net':
      return formatChartNumber(value);
    case 'epc_net':
      return formatChartCurrency(value, currency, { excludeCents: false });
    case 'rpm_net':
      return formatChartCurrency(value, currency, { excludeCents: false });
    case 'v':
      return formatChartNumber(value);
    case 'ctr':
      return formatChartNumber(value);
    case 'view_ratio':
      return formatChartNumber(value);
    case 'p':
      return formatChartNumber(value);
    case 'c':
      return formatChartNumber(value);
    case 's':
      return formatChartNumber(value);
    case 'commission_sum_net':
      return formatChartCurrency(value, currency, {
        excludeCents: !opts.verbose
      });
    case 'gmv_sum_net':
      return formatChartCurrency(value, currency, {
        excludeCents: !opts.verbose
      });
    case 'count_uniq_link_id':
      return formatChartNumber(value);
  }
};

export const metricDescription = (m: Metric): string => {
  switch (m) {
    case 'count_uniq_link_occ':
      return 'Number of unique links matching your filters.';
    case 'count_uniq_page_url':
      return 'Number of unique pages matching your filters.';
    case 'page_last_modified':
      return 'The last time this page was modified.';
    case 'aov_net':
      return 'Average order value (AOV). How much revenue you drove on this page, on average, for every order generated. Based on Final, Pending, and Refunded commissions. Only counts commissions from networks that report unique Order IDs.';
    case 'avg_rate_net':
      return 'Your effective commission rate, factoring in zeros for orders that resulted in returns or cancellations.';
    case 'commission_count_net':
      return 'How many transactions were made through your affiliate links.';
    case 'order_count_net':
      return 'How many net orders you drove. Only counts commissions from networks that report unique Order IDs. Used to calculate AOV.';
    case 'order_count_gross':
      return 'How many gross orders you drove. Includes canceled, rejected, or refunded orders.';
    case 'quantity_net':
      return 'Net quantity of products sold during this timeframe. Not all networks report product count or basket size, in which case those are counted as a single item order.';
    case 'epc_net':
      return "Earnings per click (EPC). How much net earnings you've received for every click through on an affiliate link.";
    case 'rpm_net':
      return 'Revenue per thousand pageviews (RPM). The average revenue you can expect to earn per thousand pageviews, based on pageviews and earnings during the selected timeframe.';
    case 'v':
      return 'Impressions of affiliate content during the selected timeframe.';
    case 'ctr':
      return `Page-level click-through rate (CTR). The number of clicks divided by the number of pageviews. A Page CTR of over 100% can indicate your visitors tend to click multiple links per page.`;
    case 'view_ratio':
      return 'How often visitors scroll far enough on a given page to see this link.';
    case 'p':
      return "How many pageviews were received within the selected timeframe. You'll only see pageviews for content that contains tracked affiliate links.";
    case 'c':
      return 'The clicks your affiliate links have received within the given timeframe. Excludes duplicate clicks, bot clicks, and clicks you did not tracking using either the JS, API, or link wrapper.';
    case 's':
      return 'How many times affiliate links were served to one of your visitors, regardless of if they were seen or clicked.';
    case 'gmv_sum_net':
      return 'Gross Merchandise Volume (GMV). The total value of sales made through affiliate links during the selected timeframe, regardless of click date. Only factors in networks and advertisers that report this figure.';
    case 'commission_sum_net':
      return 'Net earnings from affiliate links where a conversion occurred during the selected timeframe, and the network has reported that conversion.';
    case 'count_uniq_link_id':
      return 'Number of unique links matching your filters.';
  }
};

export const metricTitle = (m: Metric): string => {
  switch (m) {
    case 'count_uniq_link_occ':
      return 'Links';
    case 'count_uniq_page_url':
      return 'Pages';
    case 'page_last_modified':
      return 'Updated';
    case 'aov_net':
      return 'AOV';
    case 'avg_rate_net':
      return 'Eff Rate';
    case 'commission_count_net':
      return 'Line items';
    case 'order_count_net':
      return 'Orders';
    case 'order_count_gross':
      return 'Orders gross';
    case 'quantity_net':
      return 'Items sold';
    case 'epc_net':
      return 'EPC';
    case 'rpm_net':
      return 'RPM';
    case 'v':
      return 'Impressions';
    case 'ctr':
      return 'CTR';
    case 'view_ratio':
      return 'Visibility';
    case 'p':
      return 'Pageviews';
    case 'c':
      return 'Clicks';
    case 's':
      return 'Served';
    case 'gmv_sum_net':
      return 'Sales volume';
    case 'commission_sum_net':
      return 'Earnings';
    case 'count_uniq_link_id':
      return 'Links';
  }
};

export const metricName = (m: Metric): string => {
  switch (m) {
    case 'count_uniq_link_occ':
      return 'links';
    case 'count_uniq_page_url':
      return 'pages';
    case 'page_last_modified':
      return 'updated';
    case 'aov_net':
      return 'AOV';
    case 'avg_rate_net':
      return 'eff Rate';
    case 'commission_count_net':
      return 'line items';
    case 'order_count_net':
      return 'orders';
    case 'order_count_gross':
      return 'orders gross';
    case 'quantity_net':
      return 'items sold';
    case 'epc_net':
      return 'EPC';
    case 'rpm_net':
      return 'RPM';
    case 'v':
      return 'impressions';
    case 'ctr':
      return 'CTR';
    case 'view_ratio':
      return 'visibility';
    case 'p':
      return 'pageviews';
    case 'c':
      return 'clicks';
    case 's':
      return 'served';
    case 'gmv_sum_net':
      return 'sales volume';
    case 'commission_sum_net':
      return 'earnings';
    case 'count_uniq_link_id':
      return 'links';
  }
};
