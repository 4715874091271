import Button, { ButtonProps } from '@material-ui/core/Button';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { withStoppedPropagation } from '../../helpers';
import { useSnackbar } from '../../hooks/useSnackbar';
import { Copy as IconCopy } from 'react-feather';
import * as tracking from '../../tracking';

type Props = {
  text?: string;
  notificationMessage?: string;
  label?: string | JSX.Element;
};

export const CopyButton = ({
  text = '',
  label = <IconCopy size={18} />,
  notificationMessage = 'Link copied',
  ...props
}: Props & ButtonProps) => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <CopyToClipboard text={text}>
      <Button
        size="small"
        color="inherit"
        onClick={withStoppedPropagation(() => {
          tracking.sendEvent({
            category: tracking.toAppCategory(),
            action: 'Copy',
            label: text
          });
          return enqueueSnackbar(notificationMessage, { variant: 'info' });
        })}
        title="Copy"
        {...props}
      >
        {label}
      </Button>
    </CopyToClipboard>
  );
};
