import { Typography } from '@material-ui/core';
import React from 'react';
import Stripe from 'stripe';
import { Date } from '../../../../../components/Dates';
import { ICard } from '../../../../../domainTypes/billing';
import { HUMAN_DATE } from '../../../../../services/time';
import {
  couponToString,
  formatAmount,
  getMonthlyPrice
} from '../../../service';
import { capitalize } from 'lodash';

export const Label: React.FC = ({ children }) => (
  <Typography component="label" color="textSecondary">
    {children}
  </Typography>
);

export const PricePerMonth = ({
  plan,
  coupon
}: {
  plan: Stripe.Plan;
  coupon: Stripe.Coupon | null;
}) => (
  <div>
    <Label>Price per month</Label>
    <div>{formatAmount(getMonthlyPrice(plan, coupon), plan.currency)}</div>
  </div>
);

export const NextBilling = ({
  subscription
}: {
  subscription: Stripe.Subscription;
}) => (
  <div>
    <Label>Next Invoice</Label>
    <div>
      <Date d={subscription.current_period_end * 1000} format={HUMAN_DATE} />
    </div>
  </div>
);

export const BillingFrequency = ({ plan }: { plan: Stripe.Plan }) => (
  <div>
    <Label>Billing Frequency</Label>
    <div>{capitalize(plan.interval)}ly</div>
  </div>
);

const formatToTwoDigits = (x: number) => ('0' + x).slice(-2);

const CreditCardView = ({ card }: { card: ICard }) => {
  return (
    <div>
      {card.brand} ••••{card.last4} {formatToTwoDigits(card.expMonth)}/
      {card.expYear}
    </div>
  );
};

export const Cards = ({ cards }: { cards: ICard[] }) => (
  <div>
    <Label>{cards.length > 1 ? 'Credit Cards' : 'Credit Card'}</Label>
    <div>
      {cards.map((card, i) => (
        <CreditCardView key={i} card={card} />
      ))}
    </div>
  </div>
);

export const Coupon = ({ coupon }: { coupon: Stripe.Coupon | null }) => (
  <div>
    <Label>Coupon</Label>
    <div>{coupon ? couponToString(coupon) : '-'}</div>
  </div>
);
