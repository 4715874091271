import { TextField } from '@material-ui/core';
import { padStart } from 'lodash';
import React from 'react';

const toHHmm = (value: number) => `${padStart(value + '', 2, '0')}:00`;
const fromHHmm = (value: string) => parseInt(value.split(':')[0], 10);

export const SelectHour: React.FC<{
  value: number;
  onChange: (nextValue: number) => void;
  disabled?: boolean;
}> = ({ value, onChange, disabled = false }) => {
  return (
    <TextField
      type="time"
      value={toHHmm(value)}
      variant="outlined"
      disabled={disabled}
      inputProps={{
        step: 60 * 60 // 60 min
      }}
      onChange={(ev) => onChange(fromHHmm(ev.target.value))}
    />
  );
};
