import React from 'react';
import {
  utmParameters,
  utmParameterTitle,
  UtmParameterType
} from '../../features/Content/pages/Traffic/Utm/service/utm';
import { InputBase, MenuItem, Select } from '@material-ui/core';

interface SelectUtmProps {
  value: UtmParameterType;
  onChange: (value: UtmParameterType) => void;
}

const options = utmParameters.map((p) => (
  <MenuItem key={p} value={p}>
    {utmParameterTitle(p)}
  </MenuItem>
));

export const SelectUtm: React.FC<SelectUtmProps> = ({ value, onChange }) => (
  <Select
    value={value}
    onChange={(e) => {
      onChange(e.target.value as UtmParameterType);
    }}
    disableUnderline
    input={<InputBase style={{ fontSize: '0.85rem' }} />}
  >
    {options}
  </Select>
);
