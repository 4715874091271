import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import React, { useRef } from 'react';
import { AlertBox } from '../../../../../../components/AlertBox';
import { LinkExternal } from '../../../../../../components/LinkExternal';
import { Popover } from '../../../../../../components/Popover';
import {
  AmazonAvailability,
  amazonAvailabilityToStatus,
  LinkCheckType,
  ProductDataStatus
} from '../../../../../../domainTypes/linkCheckV2';
import {
  IProductIssue,
  IProductLinkCheckIssueAmazon,
  ISmartLabelIssue,
  ProductIssueType,
  SmartLabelIssueType
} from '../../../../../../domainTypes/product';
import { styled } from '../../../../../../emotion';
import { withStoppedPropagation } from '../../../../../../helpers';
import { useDialogState } from '../../../../../../hooks/useDialogState';
import { useHover } from '../../../../../../hooks/useHover';
import { formatSmartRelativeDate } from '../../../../../../services/time';
import { COLORS } from '../../../../../../domainTypes/colors';

const StatusCircle = styled('div')`
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 100%;
`;

const StatusWrapper = styled('div')`
  position: relative;
`;

const StatusPopover = styled('div')`
  padding: ${(p) => p.theme.spacing(1)}px;
  width: 260px;
`;

const IssueDot = ({
  issue,
  explanation,
  circleStyle,
  onClick
}: {
  issue: IProductIssue;
  explanation: React.ReactNode;
  circleStyle: React.CSSProperties;
  onClick?: () => void;
}) => {
  const anchor = useRef<HTMLDivElement | null>(null);
  const popoverOpen = useHover(anchor);

  const firstDiscovered = formatSmartRelativeDate(issue.createdAt);
  const lastChecked = formatSmartRelativeDate(issue.updatedAt);
  const isSame = issue.createdAt.isEqual(issue.updatedAt);

  return (
    <StatusWrapper ref={anchor}>
      <StatusCircle
        style={circleStyle}
        onClick={onClick && withStoppedPropagation(onClick)}
        role={onClick && 'button'}
      />
      {popoverOpen && (
        <Popover>
          <StatusPopover>
            <Typography variant="body1" paragraph>
              {explanation}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {isSame
                ? `This issue was discovered ${firstDiscovered}.`
                : `This issue was first found ${firstDiscovered} and last confirmed ${lastChecked}.`}
            </Typography>
          </StatusPopover>
        </Popover>
      )}
    </StatusWrapper>
  );
};

const getAmazonExplanation = (status: AmazonAvailability): React.ReactNode => {
  const labels: { [K in AmazonAvailability]: React.ReactNode } = {
    [AmazonAvailability.AMAZON_SERVICE]: 'Amazon Service',
    [AmazonAvailability.ERROR]: (
      <>
        There was an <strong>error</strong> checking this link, we recommend
        checking its status manually.
      </>
    ),
    [AmazonAvailability.LIMITED]: 'Limited supply',
    [AmazonAvailability.MARKETPLACE_ONLY]: 'Only on marketplace',
    [AmazonAvailability.NON_BUYABLE_AUTHORITY]: 'Non-buyable authority',
    [AmazonAvailability.NOT_ACCESSIBLE]: 'Page not accessible',
    [AmazonAvailability.NOT_FOUND]: (
      <>
        This is a <strong>broken link</strong>, meaning the product couldn't be
        found on Amazon.
      </>
    ),
    [AmazonAvailability.OK]: 'Available',
    [AmazonAvailability.OUT_OF_STOCK]: 'Out of stock',
    [AmazonAvailability.PRE_ORDER]: 'Pre-order',
    [AmazonAvailability.ROBOT_CHECK]: 'Unknown (robot check)',
    [AmazonAvailability.SEARCH]: 'Search results',
    [AmazonAvailability.TEMP_UNAVAILABLE]: (
      <>
        Product is <strong>temporarily out of stock</strong>, meaning it will be
        re-stocked in the near future.
      </>
    ),
    [AmazonAvailability.UNAVAILABLE]: (
      <>
        Product is <strong>out of stock</strong>, meaning it cannot be purchased
        right now.
      </>
    ),
    [AmazonAvailability.UNKNOWN]: 'Unknown',
    [AmazonAvailability.UNSUPPORTED_MARKETPLACE]: (
      <>Please provide Product Advertising API keys under Settings.</>
    )
  };

  return labels[status] || 'Unknown';
};

const AmazonIssue = ({ issue }: { issue: IProductLinkCheckIssueAmazon }) => {
  const status = issue.subType;
  const productStatus = amazonAvailabilityToStatus(status);

  const css: {
    [key in ProductDataStatus]: {
      backgroundColor: string;
    };
  } = {
    OK: {
      backgroundColor: COLORS.green.green5
    },
    WARNING: {
      backgroundColor: COLORS.gold.gold5
    },
    ERROR: {
      backgroundColor: COLORS.red.red5
    },
    UNKNOWN: {
      backgroundColor: '#AAA'
    },
    BLOCKED: {
      backgroundColor: '#eee'
    }
  };

  return (
    <IssueDot
      issue={issue}
      explanation={getAmazonExplanation(status)}
      circleStyle={css[productStatus]}
    />
  );
};

const LinkEx = styled(LinkExternal)`
  text-decoration: underline;
`;

const SmartLabelIssue = ({ issue }: { issue: ISmartLabelIssue }) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const explanation = () => {
    if (issue.status === SmartLabelIssueType.NO_PASSTHROUGH_CONFIGURED) {
      return (
        <>Smart labels cannot be passed through. Click the dot to learn more.</>
      );
    }

    return '';
  };
  const css: {
    [key in SmartLabelIssueType]: {
      backgroundColor: string;
    };
  } = {
    NO_PASSTHROUGH_CONFIGURED: {
      backgroundColor: COLORS.purple.purple5
    },
    NO_PASSTHROUGH_POSSIBLE_BECAUSE_OF_SOURCE: {
      backgroundColor: COLORS.purple.purple4
    },
    NO_PASSTHROUGH_POSSIBLE_BECAUSE_OF_TARGET: {
      backgroundColor: COLORS.purple.purple6
    }
  };
  return (
    <>
      <IssueDot
        issue={issue}
        explanation={explanation()}
        circleStyle={css[issue.status]}
        onClick={openDialog}
      />
      <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="md">
        <DialogTitle>How to enable smart labels for cloaked links</DialogTitle>
        <DialogContent>
          <p>
            Luckily it's pretty easy to enable this option for both Pretty Links
            and Thirsty Affiliates. If you're using a different link cloaker and
            aren't sure how to enable it, feel free to email us at{' '}
            <a href="mailto:support@affilimate.io">support@affilimate.io</a>.
          </p>

          <h3>Thirsty Affiliates</h3>

          <p>Open your Thirsty Affiliates settings. You have two options:</p>

          <h4>Option 1. Enable "query string passing globally"</h4>

          <p>
            In general,{' '}
            <strong>
              there's almost no reason not to enable this for your links
              globally
            </strong>
            . All it does is combine whatever parameters you have when you
            create a link in a post to your cloaked link with the query.
          </p>

          <img
            alt=""
            style={{ maxWidth: '100%', marginBottom: 24 }}
            src="/images/smart-labels/thirsty-affiliates-global.png"
          />

          <h4>
            Option 2. Go into every link and choose "query string passing" for
            individual links
          </h4>

          <p>
            While we don't necessarily recommend this option, it is possible to
            do. You should use this option if you have a specific reason why you
            wouldn't want those parameters to get appended.
          </p>

          <img
            alt=""
            style={{ maxWidth: '100%', marginBottom: 24 }}
            src="/images/smart-labels/thirsty-affiliates-per-link.png"
          />

          <h3>Pretty Links</h3>

          <p>
            Similarly, Pretty Links has a per-link setting that does the exact
            same thing. It's called "parameter forwarding".
          </p>

          <p>
            Open your Pretty Links settings for a specific link, and enable
            Parameter Forwarding.
          </p>

          <img
            alt=""
            style={{ maxWidth: '100%', marginBottom: 24 }}
            src="/images/smart-labels/pretty-links-parameter-forwarding.png"
          />

          <AlertBox variant="pending">
            <LinkEx href="https://help.affilimate.com/articles/smart-labels">
              Check out our help center
            </LinkEx>{' '}
            for more information on smart labels.
          </AlertBox>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

const Issue = ({ issue }: { issue: IProductIssue }) => {
  if (issue.type === ProductIssueType.LINK_CHECK) {
    if (issue.partner === LinkCheckType.AMAZON) {
      return <AmazonIssue issue={issue} />;
    }
  }

  if (issue.type === ProductIssueType.SMART_LABEL) {
    return <SmartLabelIssue issue={issue} />;
  }

  return null;
};

export const ProductIssue = ({ issues }: { issues: IProductIssue[] }) => {
  return (
    <div>
      {issues.map((issue, i) => (
        <Issue key={i} issue={issue} />
      ))}
    </div>
  );
};
