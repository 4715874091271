import React from 'react';
import { SingleSelector } from '../../../../components/SingleSelector';
import Typography from '@material-ui/core/Typography';
import { ChevronDown, Grid } from 'react-feather';
import {
  CampaignGroupBy,
  campaignGroupBys,
  campaignsGroupLabel
} from '../../service/groups';

export const CampaignsGroupBySelector: React.FC<{
  value: CampaignGroupBy;
  onChange: (value: CampaignGroupBy) => void;
}> = ({ value, onChange }) => {
  return (
    <SingleSelector
      noSearch
      value={value}
      onChange={onChange}
      options={campaignGroupBys.map((g) => ({
        label: campaignsGroupLabel(g),
        value: g,
        searchValue: campaignsGroupLabel(g)
      }))}
    >
      <Typography
        variant="body2"
        color="textSecondary"
        component="span"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '6px'
        }}
      >
        <Grid size={18} /> Group <strong>{campaignsGroupLabel(value)}</strong>
        <ChevronDown size={18} />
      </Typography>
    </SingleSelector>
  );
};
