import { DimensionMenuComponent } from './Dimension';
import { TransactionTypeFilterDefinition } from '../../../../domainTypes/filters';
import { useMemo, useState } from 'react';
import { BASIC_MODES, FilterMenu } from './FilterMenu';
import { EnumSelectorMenu, useCollectionState } from './Selector';
import {
  createTransactionTypeFilterDefinition,
  useDimensionName,
  validateFilterDefinition
} from '../filters';
import { isEqual } from 'lodash';
import {
  SALE_TYPES,
  SaleType,
  saleTypeLabel
} from '../../../../domainTypes/performance';
import { pluralize } from '../../../../services/pluralize';

export const TransactionTypeMenu: DimensionMenuComponent<TransactionTypeFilterDefinition> = ({
  definition,
  onSave,
  filters,
  range
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const [value, toggleValue] = useCollectionState(definition.v);
  const newDefinition = useMemo<TransactionTypeFilterDefinition>(
    () => createTransactionTypeFilterDefinition(value),
    [value]
  );
  const enableSave =
    !isEqual(definition, newDefinition) &&
    validateFilterDefinition(newDefinition);

  const focusValue = (transactionType: SaleType) => {
    onSave(createTransactionTypeFilterDefinition([transactionType]));
  };

  return (
    <FilterMenu>
      <FilterMenu.Header dimension="transaction_type">
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <EnumSelectorMenu
          label={useDimensionName('transaction_type')}
          options={SALE_TYPES}
          selectedValues={value}
          toggleValue={toggleValue}
          focusValue={focusValue}
          toLabel={saleTypeLabel}
          analyticsField={'sale_type'}
          queryFilters={filters}
          range={range}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer definition={definition}>
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={`Filter by ${pluralize(
            'transaction status',
            newDefinition.v.length,
            true
          )}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
