import { CountCell, CountCellProps } from '../../Table/CountCell';
import { AnalyticsResponseRowWithComparison } from '../../../domainTypes/analytics_v2';
import React, { ReactElement } from 'react';
import { CurrencyCode } from '../../../domainTypes/currency';
import { Number } from '../../Number';
import { DateCell } from '../../Table/DateCell';
import { Metric } from '../../../services/analyticsV2/metrics';

type MetricCellProps = Omit<CountCellProps, 'before' | 'after'> & {
  data: AnalyticsResponseRowWithComparison;
  metric: Metric;
};

const MetricCell: React.FC<MetricCellProps> = ({
  data,
  metric,
  compare,
  ...rest
}) => {
  return (
    <CountCell
      before={data.data[metric]?.prev ?? 0}
      after={data.data[metric]?.curr ?? 0}
      compare={compare}
      dashWhenAllZero
      {...rest}
    />
  );
};

interface MetricCellOptions {
  currency: CurrencyCode;
  showComparison: boolean;
}

/*
 TODO
 1. Move this somewhere where it can depend on TableMetadata and convert parameters to single object of type TableMetadata
 2. End game: this function should dispatch <Currency> or <Number> and appropriate format/digits props,
 instead of relying on internal logic of CountCell (it dispatches based on presence of currency prop)
*/
export const metricCell = (
  metric: Metric,
  data: AnalyticsResponseRowWithComparison,
  { currency, showComparison }: MetricCellOptions
): ReactElement => {
  switch (metric) {
    case 'count_uniq_link_occ':
      return <Number n={data.data.count_uniq_link_occ?.curr ?? 0} />;
    case 'count_uniq_link_id':
      return <Number n={data.data.count_uniq_link_id?.curr ?? 0} />;
    case 'count_uniq_page_url':
      return <Number n={data.data.count_uniq_page_url?.curr ?? 0} />;
    case 'page_last_modified':
      return <DateCell timestamp={data.data.page_last_modified?.curr} />;
    case 'c':
      return (
        <MetricCell data={data} metric={metric} compare={showComparison} />
      );
    case 'ctr':
      return (
        <MetricCell
          data={data}
          metric={metric}
          compare={showComparison}
          format="percent"
        />
      );
    case 'view_ratio':
      return (
        <MetricCell
          data={data}
          metric={metric}
          compare={showComparison}
          format="percent"
          digits={1}
        />
      );
    case 'p':
      return (
        <MetricCell data={data} metric={metric} compare={showComparison} />
      );
    case 'v':
      return (
        <MetricCell data={data} metric={metric} compare={showComparison} />
      );
    case 's':
      return (
        <MetricCell data={data} metric={metric} compare={showComparison} />
      );
    case 'commission_sum_net':
      return (
        <MetricCell
          data={data}
          metric={metric}
          compare={showComparison}
          currency={currency}
        />
      );
    case 'gmv_sum_net':
      return (
        <MetricCell
          data={data}
          metric={metric}
          compare={showComparison}
          currency={currency}
        />
      );
    case 'epc_net':
      return (
        <MetricCell
          data={data}
          metric={metric}
          compare={showComparison}
          currency={currency}
        />
      );
    case 'rpm_net':
      return (
        <MetricCell
          data={data}
          metric={metric}
          compare={showComparison}
          currency={currency}
        />
      );
    case 'aov_net':
      return (
        <MetricCell
          data={data}
          metric={metric}
          compare={showComparison}
          currency={currency}
        />
      );
    case 'order_count_net':
      return (
        <MetricCell data={data} metric={metric} compare={showComparison} />
      );
    case 'order_count_gross':
      return (
        <MetricCell data={data} metric={metric} compare={showComparison} />
      );
    case 'commission_count_net':
      return (
        <MetricCell data={data} metric={metric} compare={showComparison} />
      );
    case 'quantity_net':
      return (
        <MetricCell data={data} metric={metric} compare={showComparison} />
      );
    case 'avg_rate_net':
      return (
        <MetricCell
          data={data}
          metric={metric}
          compare={showComparison}
          format="percent"
          digits={1}
        />
      );
  }
};
