import { FunctionComponent, useMemo, useState } from 'react';
import { useReportHandlers } from '../../services/handlers';
import { groupBy } from 'lodash';
import { IPartner } from '../../../../domainTypes/partners';
import { CurrencyCode } from '../../../../domainTypes/currency';
import {
  Button,
  ButtonGroup,
  DialogContent,
  Typography
} from '@material-ui/core';
import { AlertBox } from '../../../../components/AlertBox';
import {
  IAffiliateProgramReportHandler,
  IApiReportHandler,
  IFileReportHandler
} from '../../services/handlers/types';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { styled } from '../../../../emotion';
import { ApiWorkflow, FileWorkflow } from './workflows';
import { ManualImportForm } from './ManualImportForm';

const SelectHandlerInstructions = styled('div')`
  width: 50%;
  position: absolute;
  top: 33%;

  & > div {
    max-width: 300px;
    text-align: center;
    margin: 0 auto;
  }
`;

const API_V2 = `Link Generator & Rates`;

interface DialogPromptProps {
  selectManualImport: () => void;
}

const ManualImportCTA = styled('div')`
  margin-top: ${(p) => p.theme.spacing(8)}px;
`;

const DialogPrompt: FunctionComponent<DialogPromptProps> = ({
  selectManualImport
}) => {
  const [canImportSales] = useHasCurrentUserRequiredScopes([
    'transactions.create'
  ]);
  return (
    <SelectHandlerInstructions>
      <div>
        <Typography
          variant="body1"
          component="p"
          paragraph
          color="textSecondary"
        >
          Choose a data source to import your affiliate commissions
        </Typography>
        <svg
          width="59"
          height="56"
          viewBox="0 0 59 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.343821 47.2454C-0.0729358 47.6078 -0.117003 48.2394 0.245394 48.6562L6.151 55.4476C6.5134 55.8644 7.14503 55.9085 7.56179 55.5461C7.97854 55.1837 8.02261 54.552 7.66021 54.1353L2.41078 48.0984L8.44763 42.849C8.86439 42.4866 8.90845 41.855 8.54606 41.4382C8.18366 41.0215 7.55203 40.9774 7.13527 41.3398L0.343821 47.2454ZM57 0C57 20.0723 51.4063 32.2999 41.8118 39.2788C32.1553 46.3028 18.2056 48.198 1.0696 47.0024L0.930402 48.9976C18.1944 50.202 32.7447 48.3472 42.9882 40.8962C53.2937 33.4001 59 20.4277 59 0H57Z"
            fill="#828282"
          />
        </svg>

        {canImportSales && (
          <ManualImportCTA>
            <Button color="primary" onClick={selectManualImport}>
              Or, add a bonus manually
            </Button>
          </ManualImportCTA>
        )}
      </div>
    </SelectHandlerInstructions>
  );
};

interface IntegrationMethodPickerProps {
  selectedHandler: Handler | null;
  integrationMethods: string[];
  setIntegrationMethod: (method: string) => void;
  integrationMethod: string | null;
}

const IntegrationMethodPicker: FunctionComponent<IntegrationMethodPickerProps> = ({
  selectedHandler,
  integrationMethods,
  integrationMethod,
  setIntegrationMethod
}) => {
  const isRestrictedMethod = useMemo(() => {
    return (
      selectedHandler &&
      selectedHandler.partnerKey === 'amazon' &&
      selectedHandler.type === 'API' &&
      integrationMethod !== API_V2
    );
  }, [selectedHandler, integrationMethod]);

  const doesNotImportCommissions = useMemo(() => {
    const noAmazonCommissions =
      selectedHandler &&
      selectedHandler.partnerKey === 'amazon' &&
      selectedHandler.type === 'API' &&
      integrationMethod === API_V2;
    const noRakutenCommissions =
      selectedHandler &&
      selectedHandler.partnerKey === 'rakuten' &&
      selectedHandler.type === 'API' &&
      integrationMethod === API_V2;
    return noAmazonCommissions || noRakutenCommissions;
  }, [selectedHandler, integrationMethod]);

  return (
    <DialogContent style={{ paddingBottom: 0 }}>
      <Typography
        variant="body1"
        component="p"
        paragraph
        style={{ fontWeight: 'bold', padding: '20px 0 10px' }}
      >
        How do you want to integrate?
      </Typography>
      <ButtonGroup>
        {integrationMethods.map((method, i) => {
          const selected = method === integrationMethod;

          const style = selected
            ? {
                borderColor: 'black',
                backgroundColor: 'black',
                color: 'white'
              }
            : { color: 'black', borderColor: 'black' };
          return (
            <Button
              style={style}
              key={method}
              onClick={() => {
                setIntegrationMethod(method);
              }}
            >
              {method}{' '}
              {i === 0 && selectedHandler?.partnerKey === 'amazon'
                ? `(Recommended)`
                : ''}
            </Button>
          );
        })}
      </ButtonGroup>
      {isRestrictedMethod && (
        <AlertBox variant="error">
          This integration method is only available for Amazon Associates that
          have been granted S3 Access by a dedicated Account Manager. It is NOT
          the same as your Amazon login details, and will not work if those are
          provided.
        </AlertBox>
      )}
      {doesNotImportCommissions && (
        <AlertBox variant="pending">
          This integration method only imports Rates and enables the Link
          Generator. It does not handle commission import.
        </AlertBox>
      )}
    </DialogContent>
  );
};
export type Handler =
  | IApiReportHandler
  | IFileReportHandler<string>
  | IFileReportHandler<string[]>
  | IAffiliateProgramReportHandler;

interface SelectedHandlerProps {
  handler: Handler;
  currency: CurrencyCode;
  setDialogStyle: (nextStyle: () => 'md' | 'lg') => void;
  close: () => void;
}

const SelectedHandler: FunctionComponent<SelectedHandlerProps> = ({
  handler,
  currency,
  setDialogStyle,
  close
}) => {
  const { space } = useCurrentUser();
  return (
    <div>
      {['CSV', 'XLSX'].indexOf(handler.type) !== -1 && (
        <FileWorkflow
          handler={handler}
          onClose={close}
          currency={currency}
          space={space}
          setDialogStyle={setDialogStyle}
        />
      )}
      {['API'].indexOf(handler.type) !== -1 && (
        <ApiWorkflow handler={handler as IApiReportHandler} onClose={close} />
      )}
    </div>
  );
};

interface IntegrationPanelProps {
  selectedPartner: IPartner['key'];
  currency: CurrencyCode;
  setDialogStyle: (nextStyle: () => 'md' | 'lg') => void;
  close: () => void;
}

const IntegrationPanel: FunctionComponent<IntegrationPanelProps> = ({
  selectedPartner,
  currency,
  setDialogStyle,
  close
}) => {
  const reportHandlers = useReportHandlers();
  const [integrationMethod, setIntegrationMethod] = useState<string | null>(
    null
  );

  const integrationMethods = useMemo(() => {
    const reportGroups = groupBy(
      reportHandlers,
      (handler) => handler.partnerKey
    );

    const group = reportGroups[selectedPartner];
    return group.map((g) => {
      if (g.type === 'API' && g.configName.endsWith('V2')) {
        return API_V2;
      }
      return g.type;
    });
  }, [selectedPartner, reportHandlers]);

  const selectedHandler = useMemo(() => {
    if (selectedPartner === null) {
      return null;
    }
    const handlers = reportHandlers.filter(
      (h) => h.partnerKey === selectedPartner
    );
    if (handlers.length === 1) {
      return handlers[0];
    }
    const withChosenMethod = handlers.find((h) => {
      if (h.type === 'API') {
        if (h.configName.endsWith('V2')) {
          return integrationMethod === API_V2;
        }
      }
      return h.type === integrationMethod;
    });

    return withChosenMethod || handlers[0];
  }, [selectedPartner, integrationMethod, reportHandlers]);

  return (
    <>
      {integrationMethods.length > 1 && (
        <IntegrationMethodPicker
          integrationMethod={integrationMethod}
          integrationMethods={integrationMethods}
          setIntegrationMethod={setIntegrationMethod}
          selectedHandler={selectedHandler}
        />
      )}
      {selectedHandler && (
        <SelectedHandler
          handler={selectedHandler}
          currency={currency}
          setDialogStyle={setDialogStyle}
          close={close}
        />
      )}
    </>
  );
};

interface RightPanelProps {
  selectedPartner: IPartner['key'] | null;
  selectPartner: (partner: IPartner['key'] | null) => void;
  currency: CurrencyCode;
  setDialogStyle: (nextStyle: () => 'md' | 'lg') => void;
  close: () => void;
}

export const RightPanel: FunctionComponent<RightPanelProps> = ({
  selectedPartner,
  selectPartner,
  currency,
  setDialogStyle,
  close
}) => {
  if (selectedPartner === null) {
    return <DialogPrompt selectManualImport={() => selectPartner('direct')} />;
  }
  if (selectedPartner === 'direct') {
    return <ManualImportForm closeForm={() => selectPartner(null)} />;
  }
  return (
    <IntegrationPanel
      selectedPartner={selectedPartner}
      currency={currency}
      setDialogStyle={setDialogStyle}
      close={close}
    />
  );
};
