export const isSameSet = (as: Set<any>, bs: Set<any>) => {
  if (as.size !== bs.size) {
    return false;
  }
  for (const a of as) {
    if (!bs.has(a)) {
      return false;
    }
  }
  return true;
};

export const areSetPairsEqual = (pairs: [Set<any>, Set<any>][]) => {
  for (const pair of pairs) {
    if (!isSameSet(pair[0], pair[1])) {
      return false;
    }
  }
  return true;
};
