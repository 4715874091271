import { CalendarDate, ZonedDateTime } from '@internationalized/date';

export type CalendarDateRange = {
  start: CalendarDate;
  end: CalendarDate;
};

export type DateTimeRange = {
  start: ZonedDateTime;
  end: ZonedDateTime;
};

export const clampDateRange = (
  range: CalendarDateRange,
  min: CalendarDate,
  max: CalendarDate
): CalendarDateRange => {
  return {
    start: range.start.compare(min) < 0 ? min : range.start,
    end: range.end.compare(max) > 0 ? max : range.end
  };
};
