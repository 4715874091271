import { Metric } from '../../../services/analyticsV2/metrics';
import { isNil } from 'lodash';
import { Area, AreaProps } from 'recharts';
import { getStableRandomColor } from '../../../services/color';

type CustomAreaProps = Omit<AreaProps, 'name' | 'fill' | 'stroke'> & {
  name: AreaProps['name'] | ((value: string) => AreaProps['name']);
  fill: AreaProps['fill'] | ((value: string) => AreaProps['fill']);
  stroke: AreaProps['stroke'] | ((value: string) => AreaProps['stroke']);
};

export const areaSeries = (
  values: string[],
  metric: Metric,
  { name, fill, stroke, ...restAreaProps }: Partial<CustomAreaProps> = {}
) =>
  values.map((value) => {
    const areaName = isNil(name)
      ? value
      : typeof name === 'function'
      ? name(value)
      : name;
    const areaFill = isNil(fill)
      ? getStableRandomColor(value)
      : typeof fill === 'function'
      ? fill(value)
      : fill;
    const areaStroke = isNil(stroke)
      ? getStableRandomColor(value)
      : typeof stroke === 'function'
      ? stroke(value)
      : stroke;

    return (
      <Area
        xAxisId="timestamp"
        yAxisId={metric}
        key={`${metric}.${value}`}
        dataKey={`${metric}.${value}`}
        name={areaName}
        type="monotone"
        stackId="1"
        strokeOpacity={0.6}
        fillOpacity={0.75}
        fill={areaFill}
        stroke={areaStroke}
        dot={false}
        activeDot={false}
        isAnimationActive={false}
        {...restAreaProps}
      />
    );
  });
