import { compact } from 'lodash';
import { Doc } from '../../domainTypes/document';

export const createDeduplicationCache = () => {
  const cache: {
    [store: string]: { [key: string]: Promise<Doc<any> | null> | undefined };
  } = {};

  const get = (store: string, key: string): Promise<Doc<any>> => {
    return ((cache[store] || {})[key] as Promise<Doc<any>>) || null;
  };

  const getOr = async (
    store: string,
    key: string,
    getFn: () => Promise<any> | undefined
  ) => {
    const cached = get(store, key);
    if (cached !== null) {
      return cached;
    }
    const fresh = getFn();
    const c = (cache[store] = cache[store] || {});
    c[key] = fresh;
    return fresh;
  };

  const getAll = (store: string, keys: string[]) => {
    return compact(keys.map(key => get(store, key)));
  };

  const put = (store: string, doc: Doc<any>) => {
    const c = (cache[store] = cache[store] || {});
    c[doc.id] = Promise.resolve(doc);
  };

  const putAll = (store: string, docs: Doc<any>[]) => {
    docs.map(doc => put(store, doc));
  };

  return {
    get,
    getOr,
    getAll,
    put,
    putAll
  };
};

export const logIdbError = (msg: string, err: any) => {
  if (err) {
    console.log(msg, err, err.code, err.name, err.message);
  } else {
    console.log(msg, err);
  }
};
