import { ButtonBase, Typography } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { Edit, PlusCircle } from 'react-feather';
import { IPostgresTags, TagType } from '../../../../../../domainTypes/tags';
import { styled } from '../../../../../../emotion';
import { useDialogState } from '../../../../../../hooks/useDialogState';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../../../services/currentUser';
import {
  createTags,
  deleteTag,
  updateTag,
  useTagsForCurrentUser
} from '../../../../../../services/tags';
import { flushTagsCacheForSpace } from '../../../../../../services/tags/cache';
import { DEFAULT_COLORS } from '../../../../../../domainTypes/colors';
import { EditTagDialog } from './EditTagDialog';
import { Tag } from './Tag';
import { TagInEditMode } from './TagInEditMode';
import { isAutomaticTagGroup } from '../../../../../../domainTypes/tag';
import { toNestedTags } from '../service';

const TagListWrapper = styled('div')`
  margin-top: ${(p) => p.theme.spacing(1)}px;
  display: flex;
  flex-wrap: wrap;
  gap: ${(p) => p.theme.spacing(2)}px;

  & > button {
    height: 40px !important;
  }
`;

const TagButton = styled<typeof ButtonBase>(ButtonBase)`
  padding: 0;
  color: ${(p) => p.theme.palette.grey[600]} !important;
`;

const CREATE_EMPTY_TAG = (parentId: null | string, tagCount: number) => {
  const colorIndex = tagCount % DEFAULT_COLORS.length;
  const color = DEFAULT_COLORS[colorIndex];
  const tag: IPostgresTags = {
    space_id: '',
    id: '',
    name: '',
    color,
    parent_id: parentId,
    type: 'Page' as TagType
  };
  return tag;
};

const CreateTagButton = ({ parentTag }: { parentTag: IPostgresTags }) => {
  const [tags] = useTagsForCurrentUser();
  const { space } = useCurrentUser();
  const [clicked, setClicked] = useState(false);
  const [tag, setTag] = useState<IPostgresTags | null>(null);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!tag) {
      return;
    }
    createTags(space.id, [
      {
        id: '',
        space_id: '',
        parent_id: tag.parent_id,
        name: tag.name,
        type: tag.type,
        color: tag.color
      }
    ]).then(() => {
      setClicked(false);
    });
  };

  if (!clicked) {
    return (
      <TagButton
        onClick={(e) => {
          e.stopPropagation();
          setTag(CREATE_EMPTY_TAG(parentTag.id, tags ? tags.length : 0));
          setClicked(true);
        }}
      >
        <PlusCircle size={14} style={{ marginRight: '4px' }} /> Create{' '}
        {parentTag.name.toLowerCase()}
      </TagButton>
    );
  }

  if (tag) {
    return (
      <TagInEditMode
        tag={tag as IPostgresTags}
        setTag={setTag}
        onSubmit={handleSubmit}
        onCancel={() => {
          setClicked(false);
        }}
      />
    );
  }

  // Not existant case
  return null;
};

const NestedTagWrapper = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

export const TagList = ({ tags }: { tags: IPostgresTags[] }) => {
  const { space } = useCurrentUser();
  const scopes = useCurrentUserScopes();
  const canEditTags = useMemo(() => scopes.has('tags.edit'), [scopes]);
  const canCreateTags = useMemo(() => scopes.has('tags.create'), [scopes]);
  const { dialogOpen, setDialogOpen } = useDialogState();
  const [selectedTag, setSelectedTag] = useState<IPostgresTags | null>(null);
  const nestedTags = useMemo(() => {
    return toNestedTags(tags);
  }, [tags]);

  const handleDelete = async () => {
    if (!selectedTag) {
      return Promise.resolve();
    }
    return deleteTag(space.id, selectedTag.id).then(() => {
      setDialogOpen(false);
      flushTagsCacheForSpace(space.id);
    });
  };

  const handleUpdate = async (newTag: IPostgresTags) => {
    return updateTag(space.id, newTag).then(() => {
      setDialogOpen(false);
    });
  };

  return (
    <div>
      {nestedTags.map((nt) => {
        const isGroupEditable = !isAutomaticTagGroup(nt, space.id);
        return (
          <NestedTagWrapper key={nt.id}>
            <div>
              <Typography
                variant="body1"
                style={{ display: 'flex', alignItems: 'center' }}
                paragraph
              >
                <strong>{nt.name}</strong>
                {canEditTags && isGroupEditable && (
                  <TagButton
                    style={{ padding: '3px', marginLeft: '6px' }}
                    onClick={() => {
                      setSelectedTag(nt);
                      setDialogOpen(true);
                    }}
                  >
                    <Edit size={14} style={{ marginRight: '4px' }} /> Edit
                  </TagButton>
                )}
              </Typography>
            </div>
            <TagListWrapper>
              {nt.children.map((t) => (
                <Tag tag={t} key={t.id} />
              ))}
              {canCreateTags && isGroupEditable && (
                <CreateTagButton parentTag={nt} />
              )}
            </TagListWrapper>
          </NestedTagWrapper>
        );
      })}
      {selectedTag && (
        <EditTagDialog
          open={dialogOpen}
          tag={selectedTag}
          onCancel={() => {
            setDialogOpen(false);
            setSelectedTag(null);
          }}
          onDelete={handleDelete}
          onUpdate={handleUpdate}
        />
      )}
    </div>
  );
};
