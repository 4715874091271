import React from 'react';
import { ButtonWithPromise } from '../../../../../../components/ButtonWithPromise';
import { Doc } from '../../../../../../domainTypes/document';
import { IPageScreenshot } from '../../../../../../domainTypes/page';
import { styled } from '../../../../../../emotion';
import { useAdminClaim } from '../../../../../../services/auth';
import { recreateScreenshot } from '../../../../../../services/pageScreenshots';

type Props = {
  d: Doc<IPageScreenshot>;
};

const Container = styled('div')`
  display: flex;
  justify-content: center;
`;

// Should only be given to the end user to recreate a screenshot
// that errored out
export const RecreatorUnsafe: React.FC<Props> = ({ d }) => {
  return (
    <Container>
      <ButtonWithPromise
        onClick={() => recreateScreenshot(d)}
        color="default"
        variant="contained"
        pending="Starting..."
      >
        Try again
      </ButtonWithPromise>
    </Container>
  );
};

export const Recreator: React.FC<Props> = ({ d }) => {
  const [isAdmin] = useAdminClaim();
  if (!isAdmin) {
    return null;
  }
  return (
    <Container>
      <ButtonWithPromise
        onClick={() => recreateScreenshot(d)}
        color="secondary"
        variant="contained"
        pending="Starting..."
      >
        Recreate screenshot
      </ButtonWithPromise>
    </Container>
  );
};
