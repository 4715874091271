import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import shortid from 'shortid';
import { Doc, generateToDocFn } from '../../../domainTypes/document';
import {
  ILinkCheckAppContainer,
  TOPICS
} from '../../../domainTypes/linkCheckApp';
import {
  EMPTY_RESULTS_COUNTER,
  LinkCheckStatus
} from '../../../domainTypes/linkCheckV2';
import { setDoc, store, useMappedLoadingValue } from '../../../services/db';
import { now } from '../../../services/time';
import { FS } from '../../../versions';

export const toAppCheckDoc = generateToDocFn<ILinkCheckAppContainer>();

export const useAppChecksBySpaceId = (spaceId: string, limit: number) => {
  return useMappedLoadingValue(
    useCollection(
      store()
        .collection(FS.linkCheckApp)
        .where('spaceId', '==', spaceId)
        .orderBy('queuedAt', 'desc')
        .limit(limit)
    ),
    (s) => s.docs.map(toAppCheckDoc)
  );
};

export const useAppChecks = (limit: number) => {
  return useMappedLoadingValue(
    useCollection(
      store()
        .collection(FS.linkCheckApp)
        .orderBy('queuedAt', 'desc')
        .limit(limit)
    ),
    (s) => s.docs.map(toAppCheckDoc)
  );
};

export const useAppCheck = (id: string) => {
  return useMappedLoadingValue(
    useDocument(store().collection(FS.linkCheckApp).doc(id)),
    toAppCheckDoc
  );
};

export const createNewCheck = (
  spaceId: string,
  createNotifications?: boolean
) => {
  const createdBy = 'AFFILIMATE';
  const queuedAt = now();

  const doc: Doc<ILinkCheckAppContainer> = {
    id: shortid(),
    collection: FS.linkCheckApp,
    data: {
      spaceId,
      status: LinkCheckStatus.QUEUED,
      packages: {},
      counts: {
        total: 0,
        unsupported: 0,
        pending: 0,
        done: 0,
        results: EMPTY_RESULTS_COUNTER()
      },
      queuedAt,
      createdBy,
      startedAt: null,
      finishedAt: null
    }
  };
  if (createNotifications) {
    doc.data.publishOnDone = [{ topic: TOPICS.notifyOnSuccessfulLinkCheck }];
  }
  return setDoc(doc);
};
