import { Moment } from 'moment-timezone';
import { FS } from '../versions';
import { Timeframe } from './analytics';
import { Compressed } from './compression';
import { CurrencyCode } from './currency';
import { IDenormalizedClickEvent } from './denormalization';
import { Doc } from './document';
import { IPartner } from './partners';
import { ISpace } from './space';
import {
  toTrackingId,
  TRACKING_ID_DEFAULT_DELIMITER,
  TRACKING_ID_PREFIX,
  TRACKING_ID_PREFIX_ALT
} from './performance-minimal';
import { Timestamp } from './time';
import { Device } from './tracking';
import { uniq} from 'lodash';

export type SaleStatus =
  | 'Pending'
  | 'Final'
  | 'Cancelled'
  | 'Refunded'
  | 'Rejected'
  | 'Non-commissionable'
  | 'Unknown';

export const SALE_STATUSES: SaleStatus[] = [
  'Final',
  'Pending',
  'Cancelled',
  'Refunded',
  'Rejected',
  'Non-commissionable',
  'Unknown'
];

// NOTE: Transaction status is like Sale status but lowercase, because that's how it is encoded in ClickHouse
export const TRANSACTION_STATUSES = [
  'final',
  'pending',
  'cancelled',
  'refunded',
  'rejected',
  'non-commissionable',
  'unknown'
] as const;

export type TransactionStatus = typeof TRANSACTION_STATUSES[number];

export const TRANSACTION_UI_CONFIG: {
  [status in TransactionStatus]: { color: string; text: string; label: string };
} = {
  final: {
    color: '#b7eb8f',
    text: '#092b00',
    label: 'Complete'
  },
  pending: {
    color: '#ffd666',
    text: '#613400',
    label: 'Pending'
  },
  cancelled: {
    color: '#efdbff',
    text: '#531dab',
    label: 'Cancelled'
  },
  refunded: {
    color: '#fbb0ae',
    text: '#5c0011',
    label: 'Refunded'
  },
  rejected: {
    color: '#DDD',
    text: '#333',
    label: 'Rejected'
  },
  'non-commissionable': {
    color: '#DDD',
    text: '#333',
    label: 'Non-commissionable'
  },
  unknown: {
    color: '#DDD',
    text: '#333',
    label: 'Unknown'
  }
};


export type PayoutStatus = 'pending' | 'paid' | 'unknown';

export const PAYOUT_STATUSES: PayoutStatus[] = ['pending', 'paid', 'unknown'];

export const PAYOUT_UI_CONFIG: Record<string, { color: string; text: string; label: string }> = {
  pending: {
    color: '#fee7aa',
    text: '#613400',
    label: 'Payout pending'
  },
  paid: {
    color: '#39b185',
    text: '#fff',
    label: 'Paid out'
  },
  unknown: {
    color: '#AAA',
    text: '#333',
    label: 'Not provided'
  },
  Unknown: {
    color: '#AAA',
    text: '#333',
    label: 'Not provided'
  }
};

export type SaleType = 'cpa' | 'cpc' | 'cpl' | 'bonus' | 'unknown';

export const SALE_TYPES: SaleType[] = ['cpa', 'cpc', 'cpl', 'bonus', 'unknown'];
export const SALE_TYPES_WITHOUT_BONUS: SaleType[] = [
  'cpa',
  'cpc',
  'cpl',
  'unknown'
];

export const saleTypeLabel = (type: SaleType) => {
  switch (type) {
    case 'cpa':
      return 'CPA';
    case 'cpc':
      return 'CPC';
    case 'cpl':
      return 'CPL';
    case 'bonus':
      return 'Bonus';
    case 'unknown':
      return 'Unknown';
  }
}

export const SALE_UI_CONFIG: {
  [status: string]: { color: string; text: string; label: string };
} = {
  Cancelled: {
    color: '#efdbff',
    text: '#531dab',
    label: 'Cancelled'
  },
  Pending: {
    color: '#ffd666',
    text: '#613400',
    label: 'Pending'
  },
  Final: {
    color: '#b7eb8f',
    text: '#092b00',
    label: 'Complete'
  },
  Lost: {
    color: '#AAA',
    text: '#333',
    label: 'Lost'
  },
  Refunded: {
    color: '#fbb0ae',
    text: '#5c0011',
    label: 'Refunded'
  },
  Rejected: {
    color: '#DDD',
    text: '#333',
    label: 'Rejected'
  },
  'Non-commissionable': {
    color: '#DDD',
    text: '#333',
    label: 'Non-commissionable'
  },
  Unknown: {
    color: '#DDD',
    text: '#333',
    label: 'Unknown'
  }
};

export interface ISaleAmount {
  currency: CurrencyCode;
  price: number | null; // cent based
  revenue: number | null; // cent based
  commission: number; // cent based
}

export type ISaleMetadata = {
  data1?: string;
  data2?: string;
  data3?: string;
  data4?: string;
  data5?: string;
  channel?: string;
  advertiserSubId1?: string;
  advertiserSubId2?: string;
  advertiserSubId3?: string;
  advertiserSubId4?: string;
  advertiserSubId5?: string;
  brand?: string;
  category?: string;
  color?: string;
  customerCity?: string;
  customerCountry?: string;
  destinationCity?: string;
  destinationCountry?: string;
  gender?: string;
  guests?: string;
  marketplace?: string;
  productGroup?: string;
  productName?: string;
  propertyName?: string;
  reason?: string; // Correction reason
  seller?: string;
  size?: string;
  stayDuration?: string;
  subId1?: string;
  subId2?: string;
  subId3?: string;
  subId4?: string;
  subId5?: string;
  subId6?: string;
  subId7?: string;
  subId8?: string;
  transactionId?: string;
  trial?: string;
  variant?: string;
};

export type ISaleMetadataPath = `metadata.${keyof ISaleMetadata}`;

export interface ISale {
  orderId?: string;
  saleId: string;
  saleType: SaleType;

  trackingId: string | null; // Possible gaps in reports
  trackingLabel: string | null;
  reportId: string; // Store a reference to the report the data came from

  saleDate: Timestamp;
  completionDate: Timestamp | null;
  payoutDate: Timestamp | null;
  status: SaleStatus;
  payoutStatus: PayoutStatus | null;
  payoutId: string | null;
  lastModified: Timestamp | null;

  partnerKey: string;
  partnerProductName: string | null; // An internal name for the product
  partnerProductId: string | null; // If get an internal ID we can use to query the product APIs

  advertiserId: string;
  advertiserName: string;

  amount: ISaleAmount;

  commissionPercent: number | null;

  quantity?: number;
  device?: Device;
  referrerUrl?: string;
  origin: string | null;
  clickDate?: Timestamp;
  coupon?: string;
  subId1?: string;
  subId2?: string;
  subId3?: string;
  subId4?: string;
  subId5?: string;
  subId6?: string;

  metadata?: ISaleMetadata | null; // Additional data provided by an optional partner subtype

  integrationId: string;
}

export interface ITrackedSale {
  spaceId: string;

  queryDate: Timestamp;
  sale: ISale;
  click: IDenormalizedClickEvent | null;

  createdAt: Timestamp;
  createdBy: string;

  // The following are all provided either by the click or the sale;
  pageUrl: string | null;
  origin: string | null;
  device: Device;
  labelRuleId?: string;

  // The following is provided by either the sale or the user
  saleType: SaleType;

  channelId: string;
}

export interface IConvertedSale extends ISale {
  originalAmount: ISaleAmount;
  conversionRate: number;
}

export interface ITrackedConvertedSale extends ITrackedSale {
  sale: IConvertedSale;
  dates: {
    sale: Moment;
    completion: Moment | null;
    click: Moment | null;
  };
}

export const toTrackedSaleId = (d: ITrackedSale) => {
  return `${d.spaceId}-${d.sale.partnerKey}-${d.sale.saleId}`;
};

export interface IReportPreview {
  spaceId: string;
  reportId: string;
  partnerKey: string;
  start: Timestamp | null;
  end: Timestamp | null;
  sales: ISale[];
}

// Might or might not have sales attached already.
// If not present, they need to be resolved through the salesStoragePath
export type IReportPreviewMaybeAsync = Omit<IReportPreview, 'sales'> & {
  sales: ISale[] | null;
  salesStoragePath: string;
};

type ReportSourceApi = {
  type: 'API';
  url: string;
  storagePath: string; // stores the original API response
};

type ReportSourceFile = {
  type: 'FILE';
  storagePath: string;
};

type ReportSourceExtension = {
  type: 'EXTENSION';
  storagePath: string;
};

export type ReportSource =
  | ReportSourceApi
  | ReportSourceFile
  | ReportSourceExtension;

export interface IReport {
  spaceId: string;

  partnerKey: string;
  start: Timestamp;
  end: Timestamp;
  createdAt: Timestamp;
  createdBy: string;

  source: ReportSource;

  salesCount: number;
  salesStoragePath: string;
  /**
   * @deprecated Now stored in grive
   */
  sales: Compressed<ISale[]> | null; // null is for those who now use the new system. we'll not migrate old data and live with these remains
  integrationId: string;

  v: 1;
}

export { toTrackingId, TRACKING_ID_PREFIX, TRACKING_ID_PREFIX_ALT };

export const isAffilimateTrackingLabel = (label: string) => {
  return label.indexOf(TRACKING_ID_PREFIX) !== -1;
};

export const getTrackingId = (trackingLabel: string, space: ISpace ) => {
  const loc = trackingLabel.lastIndexOf(TRACKING_ID_PREFIX);
  const locAlt = trackingLabel.lastIndexOf(TRACKING_ID_PREFIX_ALT);
  const m = trackingLabel.match(/_[\d]{11}[a-z0-9]{4}$/g);

  const delimiters: string[] = uniq(space.domains.map(d => d.trackingIdFormat?.delimiter || TRACKING_ID_DEFAULT_DELIMITER));
  const prefixes: string[] = uniq(space.domains.flatMap(d => d.trackingIdFormat?.prefix || []));
  const combinations = prefixes.map(p => delimiters.map(d => ({ combo: `${d}${p}`, delimiter: d }))).flat();

  // Check if the subid contains any combination of the custom prefixes and delimiters
  for (const combo of combinations) {
    if (trackingLabel.includes(combo.combo)) {
      const lastIndex = trackingLabel.lastIndexOf(combo.combo) + combo.delimiter.length;
      return trackingLabel.slice(lastIndex);
    }
  }

  // Check if any of the prefixes are at the very beginning of the subid
  const prefix = prefixes.find(p => trackingLabel.startsWith(p));
  const matchesPrefixAtBeginning = prefix && trackingLabel.indexOf(prefix) === 0 && trackingLabel.length >= 15 && !trackingLabel.includes('-') && !trackingLabel.includes('_') && !trackingLabel.includes(' ');

  if (loc === -1 && locAlt === -1 && !m && !matchesPrefixAtBeginning) {
    return null;
  }

  if (locAlt !== -1) {
    const endIndex =
      trackingLabel.indexOf('/') !== -1 && trackingLabel.indexOf('/') > locAlt
        ? trackingLabel.indexOf('/')
        : trackingLabel.length;
    const altId = trackingLabel.slice(locAlt, endIndex);
    const replacedId = altId.replace(
      TRACKING_ID_PREFIX_ALT,
      TRACKING_ID_PREFIX
    );

    return replacedId || null;
  } else if (loc !== -1) {
    const endIndexLoc =
      trackingLabel.indexOf('/') !== -1 && trackingLabel.indexOf('/') > loc
        ? trackingLabel.indexOf('/')
        : trackingLabel.length;
    const idPart = trackingLabel.slice(loc, endIndexLoc);

    if (idPart) {
      return idPart;
    }
  } else if (m && m.length > 0) {
    const withoutUnderscore = m[0].slice(1);
    const shortAmcid = `${withoutUnderscore}`;

    if (space.id === 'YAyO7UsW3') {
      return `${TRACKING_ID_PREFIX}${shortAmcid}`;
    }

    return shortAmcid;
  } else if (matchesPrefixAtBeginning) {
    return trackingLabel;
  }

  return null;
};

export const getTrackingSlug = (trackingLabel: string) => {
  const loc = trackingLabel.lastIndexOf(TRACKING_ID_PREFIX);
  const [a, b] = trackingLabel.split(TRACKING_ID_PREFIX);

  const locAlt = trackingLabel.lastIndexOf(TRACKING_ID_PREFIX_ALT);
  const [aAlt, bAlt] = trackingLabel.split(TRACKING_ID_PREFIX_ALT);

  if ((!a || !b) && (!aAlt || !bAlt)) {
    return null;
  }

  if (loc === -1 && locAlt === -1) {
    return null;
  }

  // Note: What happens if the slug has other underscores
  // for example page_url?
  const m = trackingLabel.match(/(.+)?_/);
  return m ? m[1] : null;
};

export const getTrackingLabelWithoutAffilimateId = (trackingLabel: string) => {
  const i1 = trackingLabel.lastIndexOf(`_${TRACKING_ID_PREFIX}`);
  const i2 = trackingLabel.lastIndexOf(`_${TRACKING_ID_PREFIX_ALT}`);

  if (i1 !== -1) {
    return trackingLabel.slice(0, i1);
  }

  if (i2 !== -1) {
    return trackingLabel.slice(0, i2);
  }

  // Handle situation where SubID is so short there is no
  // underscore separator
  const i3 = trackingLabel.lastIndexOf(`${TRACKING_ID_PREFIX}`);
  const i4 = trackingLabel.lastIndexOf(`${TRACKING_ID_PREFIX_ALT}`);
  if (i3 !== -1) {
    return trackingLabel.slice(0, i3);
  }

  if (i4 !== -1) {
    return trackingLabel.slice(0, i4);
  }

  return trackingLabel;
};

export interface IEarning {
  items: number; // Database row count
  currency: CurrencyCode;
  total: number; // final + pending - refunded
  totalCount: number; // final + pending - refunded
  lost: number; // cancelled + refunded + rejected
  lostCount: number;
  salesCount: number; // final + pending
  refundCount: number;
  transactionCount: number; // number of all transactions regardless of status

  final: number;
  pending: number;
  cancelled: number;
  cancelledCount: number;
  refunded: number;
  avgCommissionPercent: number;
  unknown: number;
  nonCommissionable: number;

  quantity: {
    total: number; // Quantity of items sold
  };
  orderCount: {
    all: number; // final + pending + cancelled + refunded + unknown + rejected + unknown + nc
    total: number; // final + pending - refunded
    lost: number; // cancelled + refunded + rejected

    final: number;
    pending: number;
    cancelled: number;
    refunded: number;
    unknown: number;
    nonCommissionable: number;
  };

  saleValue: {
    total: number; // final + pending - refunded
    lost: number; // cancelled + refunded + rejected

    final: number;
    pending: number;
    cancelled: number;
    refunded: number;
    unknown: number;
    nonCommissionable: number;
  };
}

export interface IEarningMinimal {
  cur: CurrencyCode;
  ac: number; // Average commission percent

  ocf: number; // Order count final
  ocp: number; // Order count pending
  occ: number; // Order count cancelled
  ocrf: number; // Order count refunded
  ocrj: number; // Order count rejected
  ocnc: number; // Order count non-commissionable
  ocu: number; // Order count unknown

  pf: number; // Sales value final
  pp: number; // Sales value pending
  pc: number; // Sales value cancelled
  prf: number; // Sales value refunded
  prj: number; // Sales value rejected
  pnc: number; // Sales value non-commissionable
  pu: number; // Sales value unknown

  cf: number; // Commission amount final
  cp: number; // Commission amount pending
  cc: number; // Commission amount cancelled
  crf: number; // Commission amount refunded
  crj: number; // Commission amount rejected
  cnc: number; // Commission amount non-commissionable
  cu: number; // Commission amount unknown

  nf: number; // Number final
  np: number; // Number pending
  nc: number; // Number cancelled
  nrf: number; // Number refunded
  nrj: number; // Number rejected
  nnc: number; // Number non-commissionable
  nu: number; // Number unknown

  // New fields
  ct: number; // DONE: Commission total (final + pending - refunded)
  oct: number; // DONE: Order count total (final + pending - refunded)
  pt: number; // DONE: Sales value total (final + pending - refunded)
  nt: number; // DONE: Number total (final + pending - refunded)
  oca: number; // DONE: Order count (all statuses)
  qt: number; // DONE: Quantity total (final + pending - refunded)

  cl: number; // DONE: Commission lost (cancelled + refunded + rejected)
  ocl: number; // DONE: Order count lost (cancelled + refunded + rejected)
  pl: number; // DONE: Sales value lost (cancelled + refunded + rejected)
  nl: number; // DONE: Number lost (cancelled + refunded + rejected)
  ia: number; // DONE: Items (transaction count)

  aov_raw: number; // Raw AOV (sales value total / order count total)
  aov: number; // AOV (sales value total / order count total) for sales with non-null order_id
}

export interface IEarningMinimalDaily extends IEarningMinimal {
  tk: string;
}

export const EMPTY_EARNINGS_MINIMAL = (
  currency: CurrencyCode
): IEarningMinimal => {
  return {
    aov: 0,
    aov_raw: 0,
    cur: currency,
    ac: 0,
    cc: 0,
    cf: 0,
    cl: 0,
    cnc: 0,
    cp: 0,
    crf: 0,
    crj: 0,
    ct: 0,
    cu: 0,
    ia: 0,
    nc: 0,
    nf: 0,
    nl: 0,
    nnc: 0,
    np: 0,
    nrf: 0,
    nrj: 0,
    nt: 0,
    nu: 0,
    oca: 0,
    occ: 0,
    ocf: 0,
    ocl: 0,
    ocnc: 0,
    ocp: 0,
    ocrf: 0,
    ocrj: 0,
    oct: 0,
    ocu: 0,
    pc: 0,
    pf: 0,
    pl: 0,
    pnc: 0,
    pp: 0,
    prf: 0,
    prj: 0,
    pt: 0,
    pu: 0,
    qt: 0
  };
};

export const EMPTY_EARNINGS_MINIMAL_DAILY = (
  currency: CurrencyCode,
  tk: string
): IEarningMinimalDaily => {
  return {
    aov: 0,
    aov_raw: 0,
    cur: currency,
    tk,
    ac: 0,
    cc: 0,
    cf: 0,
    cl: 0,
    cnc: 0,
    cp: 0,
    crf: 0,
    crj: 0,
    ct: 0,
    cu: 0,
    ia: 0,
    nc: 0,
    nf: 0,
    nl: 0,
    nnc: 0,
    np: 0,
    nrf: 0,
    nrj: 0,
    nt: 0,
    nu: 0,
    oca: 0,
    occ: 0,
    ocf: 0,
    ocl: 0,
    ocnc: 0,
    ocp: 0,
    ocrf: 0,
    ocrj: 0,
    oct: 0,
    ocu: 0,
    pc: 0,
    pf: 0,
    pl: 0,
    pnc: 0,
    pp: 0,
    prf: 0,
    prj: 0,
    pt: 0,
    pu: 0,
    qt: 0
  };
};

export const EMPTY_EARNING = (currency: CurrencyCode): IEarning => ({
  currency,
  items: 0,
  total: 0,
  totalCount: 0,
  salesCount: 0,
  refundCount: 0,
  lost: 0,
  lostCount: 0,
  transactionCount: 0,
  final: 0,
  pending: 0,
  cancelled: 0,
  cancelledCount: 0,
  refunded: 0,
  unknown: 0,
  nonCommissionable: 0,
  avgCommissionPercent: 0,

  quantity: {
    total: 0
  },
  orderCount: {
    all: 0,
    total: 0,
    lost: 0,

    final: 0,
    pending: 0,
    cancelled: 0,
    refunded: 0,
    unknown: 0,
    nonCommissionable: 0
  },

  saleValue: {
    total: 0,
    lost: 0,

    final: 0,
    pending: 0,
    cancelled: 0,
    refunded: 0,
    unknown: 0,
    nonCommissionable: 0
  }
});

export const isEmptyEarning = (e: IEarning) => e.items === 0;
export interface IDailyEarning extends IEarning {
  timeKey: string;
}

export const EMPTY_DAILY_EARNING = (
  currency: CurrencyCode,
  timeKey: string
): IDailyEarning => ({
  currency,
  timeKey,
  items: 0,
  total: 0,
  totalCount: 0,
  salesCount: 0,
  refundCount: 0,
  lost: 0,
  lostCount: 0,
  transactionCount: 0,
  final: 0,
  pending: 0,
  cancelled: 0,
  cancelledCount: 0,
  refunded: 0,
  unknown: 0,
  nonCommissionable: 0,
  avgCommissionPercent: 0,
  quantity: {
    total: 0
  },
  orderCount: {
    all: 0,
    total: 0,
    lost: 0,

    final: 0,
    pending: 0,
    cancelled: 0,
    refunded: 0,
    unknown: 0,
    nonCommissionable: 0
  },

  saleValue: {
    total: 0,
    lost: 0,

    final: 0,
    pending: 0,
    cancelled: 0,
    refunded: 0,
    unknown: 0,
    nonCommissionable: 0
  }
});

export const addToEarning = <T extends IEarning>(
  earning: T,
  sale: ISale
): T => {
  const n = sale.amount.commission;
  earning.items += 1;

  if (sale.status === 'Final') {
    earning.final += n;
    earning.total += n;
    earning.totalCount += 1;
    earning.salesCount += 1;
    earning.transactionCount += 1;
  }

  if (sale.status === 'Pending') {
    earning.pending += n;
    earning.total += n;
    earning.totalCount += 1;
    earning.salesCount += 1;
    earning.transactionCount += 1;
  }

  if (sale.status === 'Cancelled') {
    earning.cancelled += n;
    earning.lost += n;
    earning.lostCount += 1;
    earning.cancelledCount += 1;
    earning.transactionCount += 1;
  }
  if (sale.status === 'Rejected') {
    earning.lost += n;
    earning.lostCount += 1;
    earning.transactionCount += 1;
  }
  if (sale.status === 'Refunded') {
    earning.refunded += n;
    earning.lost += n;
    earning.total -= n;
    earning.totalCount -= 1;
    earning.refundCount += 1;
    earning.lostCount += 1;
    earning.transactionCount += 1;
  }
  if (sale.status === 'Unknown') {
    earning.unknown += n;
    earning.totalCount += 1;
    earning.transactionCount += 1;
  }
  if (sale.status === 'Non-commissionable') {
    earning.nonCommissionable += n;
    earning.lost += n;
    earning.lostCount += 1;
    earning.transactionCount += 1;
  }
  return earning;
};

export const subtractOneEarningFromAnother = <T extends IEarning>(e: T, o: T): T => {
  e.items -= o.items;
  e.total -= o.total;
  e.totalCount -= o.totalCount;
  e.lost -= o.lost;
  e.salesCount -= o.salesCount;
  e.refundCount -= o.refundCount;

  e.final -= o.final;
  e.pending -= o.pending;
  e.cancelled -= o.cancelled;
  e.cancelledCount -= o.cancelledCount;
  e.refunded -= o.refunded;
  e.avgCommissionPercent = e.unknown += o.unknown; // makes no sense?
  e.nonCommissionable -= o.nonCommissionable;
  e.lostCount -= o.lostCount;
  e.transactionCount -= o.transactionCount;

  e.orderCount.all -= o.orderCount.all;
  e.orderCount.total -= o.orderCount.total;
  e.orderCount.lost -= o.orderCount.lost;
  e.orderCount.final -= o.orderCount.final;
  e.orderCount.pending -= o.orderCount.pending;
  e.orderCount.cancelled -= o.orderCount.cancelled;
  e.orderCount.refunded -= o.orderCount.refunded;
  e.orderCount.unknown -= o.orderCount.unknown;
  e.orderCount.nonCommissionable -= o.orderCount.nonCommissionable;

  e.saleValue.total -= o.saleValue.total;
  e.saleValue.lost -= o.saleValue.lost;
  e.saleValue.final -= o.saleValue.final;
  e.saleValue.pending -= o.saleValue.pending;
  e.saleValue.cancelled -= o.saleValue.cancelled;
  e.saleValue.refunded -= o.saleValue.refunded;
  e.saleValue.unknown -= o.saleValue.unknown;
  e.saleValue.nonCommissionable -= o.saleValue.nonCommissionable;
  return e;
};

export const addOneEarningToAnother = <T extends IEarning>(e: T, o: T): T => {
  e.items += o.items;
  e.total += o.total;
  e.totalCount += o.totalCount;
  e.lost += o.lost;
  e.salesCount += o.salesCount;
  e.refundCount += o.refundCount;

  e.final += o.final;
  e.pending += o.pending;
  e.cancelled += o.cancelled;
  e.cancelledCount += o.cancelledCount;
  e.refunded += o.refunded;
  e.avgCommissionPercent = e.unknown += o.unknown;
  e.nonCommissionable += o.nonCommissionable;
  e.lostCount += o.lostCount;
  e.transactionCount += o.transactionCount;

  e.orderCount.all += o.orderCount.all;
  e.orderCount.total += o.orderCount.total;
  e.orderCount.lost += o.orderCount.lost;
  e.orderCount.final += o.orderCount.final;
  e.orderCount.pending += o.orderCount.pending;
  e.orderCount.cancelled += o.orderCount.cancelled;
  e.orderCount.refunded += o.orderCount.refunded;
  e.orderCount.unknown += o.orderCount.unknown;
  e.orderCount.nonCommissionable += o.orderCount.nonCommissionable;

  e.saleValue.total += o.saleValue.total;
  e.saleValue.lost += o.saleValue.lost;
  e.saleValue.final += o.saleValue.final;
  e.saleValue.pending += o.saleValue.pending;
  e.saleValue.cancelled += o.saleValue.cancelled;
  e.saleValue.refunded += o.saleValue.refunded;
  e.saleValue.unknown += o.saleValue.unknown;
  e.saleValue.nonCommissionable += o.saleValue.nonCommissionable;
  return e;
};

export const mergeDailyEarnings = (a: IDailyEarning[], b: IDailyEarning[]) => {
  const bByTk: { [tk: string]: IDailyEarning } = {};
  b.forEach((x) => (bByTk[x.timeKey] = x));
  return a.map((x) => {
    const y = bByTk[x.timeKey];
    if (!y) {
      return x;
    }
    const earning = EMPTY_DAILY_EARNING(x.currency, x.timeKey);
    return addOneEarningToAnother(addOneEarningToAnother(earning, x), y);
  });
};

export const aggregateSales = (
  sales: ISale[],
  currency: CurrencyCode
): IEarning => {
  const aggregated = sales.reduce<IEarning>(
    addToEarning,
    EMPTY_EARNING(currency)
  );
  const avgCommissionPercent =
    sales.map((s) => s.commissionPercent || 0).reduce((m, s) => m + s, 0) /
      aggregated.totalCount || 0;
  return {
    ...aggregated,
    avgCommissionPercent
  };
};

export const aggregateDailyEarnings = (
  timeKey: string,
  sales: ISale[],
  currency: CurrencyCode
): IDailyEarning => {
  const earning = aggregateSales(sales, currency);
  return { timeKey, ...earning };
};

// It is possible to retrieve only a subset of fields when calling the
// getEarnings endpoint.
// SalesFilterArgs can optionally define which fields they are interesting in.
// Handle this with great care.
// The full IEarningMinimal object will be returned. All fields not requested
// will have 0 as their value.
// The consumer needs to be fully aware of what they need from this object, otherwise
// this can lead to nasty bugs, where we'll be surprised why all data is 0.
//
// It can be useful to drastically speed up certain queries.
// E.g. when we're only interested in total commissions, asking for COMMISSIONS_ONLY
// will speed up the query for large accounts by a factor of 3x or more.
export type IEarningMinimalField = keyof Omit<IEarningMinimal, 'cur'>;

export const EARNING_MINIMAL_FIELD_SET: {
  COMMISSIONS_ONLY: IEarningMinimalField[];
  COMMISSIONS_AND_SALES_VOLUME: IEarningMinimalField[];
  COMMISSIONS_VOLUME_AND_TX_COUNT: IEarningMinimalField[];
  COMMISSIONS_AND_TX_COUNT: IEarningMinimalField[];
} = {
  COMMISSIONS_ONLY: ['ct', 'cf', 'cp', 'cc', 'crf', 'crj', 'cnc', 'cu', 'cl'],
  COMMISSIONS_AND_SALES_VOLUME: [
    'ct',
    'cf',
    'cp',
    'cc',
    'crf',
    'crj',
    'cnc',
    'cu',
    'cl',
    'pt',
    'pf',
    'pp',
    'pc',
    'prf',
    'pnc',
    'pu'
  ],
  COMMISSIONS_VOLUME_AND_TX_COUNT: [
    'ia',
    'nt',
    'nf',
    'np',
    'nc',
    'nrf',
    'nnc',
    'nl',
    'ct',
    'cf',
    'cp',
    'cc',
    'crf',
    'crj',
    'cnc',
    'cu',
    'cl',
    'pf',
    'pt',
    'pp',
    'pc',
    'prf',
    'pnc',
    'pu'
  ],
  COMMISSIONS_AND_TX_COUNT: [
    'ia',
    'nt',
    'nf',
    'np',
    'nc',
    'nrf',
    'nnc',
    'ct',
    'cf',
    'cp',
    'cc',
    'crf',
    'crj',
    'cnc',
    'cu',
    'cl'
  ]
};

// Special handling of the string unattributed, which matches for NULL and empty string
export type SalesFilterArgs = {
  dates: {
    start: number;
    end: number;
    tz: string;
    column?: 'sale_date' | 'click_or_sale_date';
  };
  page?: number;
  limit?: number;
  orderBy?: {
    col: string;
    dir?: 'ASC' | 'DESC';
  }; // default to { col: 'sale_date', dir: 'DESC' }
  search?: { q: string };
  origin?: string[];
  page_url?: string[];
  partner_key?: string[];
  advertiser_id?: string[];
  advertiser_name?: string[];
  sale_status?: SaleStatus[];
  payout_status?: PayoutStatus[];
  sale_type?: SaleType[];
  tracking_label?: string[];
  label_rule_id?: string[];
  payout_id?: string[];
  product_id?: string[];
  partner_product_name?: string[];
  partner_product_id?: string[];
  device?: Device[];
  channel_id?: string[]
  fields?: IEarningMinimalField[];
} & {
  [K in ISaleMetadataPath]?: string[];
};

export type TransactionsArgs = SalesFilterArgs & {
  sale_date?: { d: string; tz: string }; // YYYY-MM-DD to ask for items of a SPECIFIC day.
  page?: number;
  limit?: number; // defaults to 2000, which is the maximum
  orderBy?: {
    col: string;
    dir?: 'ASC' | 'DESC';
  }; // default to { col: 'sale_date', dir: 'DESC' }
};

export type EarningsArgsInTimeframe = {
  type: 'inTimeframe';
  d: SalesFilterArgs & { currency: CurrencyCode };
};

export type EarningsArgsInTimeframeAsTimeseries = {
  type: 'inTimeframeAsTimeseries';
  d: SalesFilterArgs & { currency: CurrencyCode };
};

export type EarningsArgsTopPagesForOriginInTimeFrame = {
  type: 'topPagesForOriginInTimeframe';
  d: {
    tf: Timeframe;
    origin: string;
    limit: number;
    page?: number;
    orderBy?: {
      col: string;
      dir?: 'ASC' | 'DESC';
    }; // default to { col: 'sale_date', dir: 'DESC' }
    compare?: boolean;
    currency: CurrencyCode;
    fields?: IEarningMinimalField[];
  };
};

export type EarningsArgsGroupedInTimeframe = {
  type: 'groupedInTimeframe';
  d: SalesFilterArgs & { groupBy: string[]; currency: CurrencyCode };
};

export type EarningsArgsGroupedInTimeframeAsTimeseries = {
  type: 'groupedInTimeframeAsTimeseries';
  d: SalesFilterArgs & { groupBy: string[]; currency: CurrencyCode };
};

export type EarningsArgs =
  | EarningsArgsTopPagesForOriginInTimeFrame
  | EarningsArgsInTimeframe
  | EarningsArgsInTimeframeAsTimeseries
  | EarningsArgsGroupedInTimeframe
  | EarningsArgsGroupedInTimeframeAsTimeseries;

export type EarningsRespTopPagesForOriginInTimeFrame = {
  type: 'topPagesForOriginInTimeframe';
  d: {
    [pageUrl: string]: {
      curr: IEarningMinimal;
      prev: IEarningMinimal | null;
    };
  };
  q: EarningsArgsTopPagesForOriginInTimeFrame['d'];
};

export type EarningsRespGroupedInTimeframe = {
  type: 'groupedInTimeframe';
  d: {
    group: { [key: string]: any }; // contains the keys that were requested as grouping
    d: IEarningMinimal;
  }[];
  q: EarningsArgsGroupedInTimeframe['d'];
};

export type EarningsRespGroupedInTimeframeAsTimeseries = {
  type: 'groupedInTimeframeAsTimeseries';
  d: {
    group: { [key: string]: any }; // contains the keys that were requested as grouping
    ds: IEarningMinimalDaily[]; // not guaranteed to be continuous!
  }[];
  q: EarningsArgsGroupedInTimeframeAsTimeseries['d'];
};

export type EarningsRespInTimeframe = {
  type: 'inTimeframe';
  d: IEarningMinimal;
  q: EarningsArgsInTimeframe['d'];
};

export type EarningsRespInTimeframeAsTimeseries = {
  type: 'inTimeframeAsTimeseries';
  d: IEarningMinimalDaily[]; // not guaranteed to be continuous!
  q: EarningsArgsInTimeframeAsTimeseries['d'];
};

export type EarningsResp =
  | EarningsRespTopPagesForOriginInTimeFrame
  | EarningsRespInTimeframe
  | EarningsRespInTimeframeAsTimeseries
  | EarningsRespGroupedInTimeframe
  | EarningsRespGroupedInTimeframeAsTimeseries;

export const toEarningFromMinimal = (m: IEarningMinimal): IEarning => {
  // temporary until we redeploy
  const x = EMPTY_EARNINGS_MINIMAL(m.cur);
  Object.keys(m).forEach((k) => {
    if (k !== 'cur') {
      // for uknown reasons there can be null values returned by the backend. treat them as zeroes
      // parseInt cannot deal with null and produces NaN
      (x as any)[k] = parseInt((m as any)[k] || 0);
    }
  });
  return {
    currency: x.cur,
    items: x.ia,
    quantity: {
      total: x.qt
    },
    total: x.ct,
    totalCount: x.nt,
    lost: x.cl,
    lostCount: x.nl,
    salesCount: x.nf + x.np,
    refundCount: x.nrf,
    transactionCount: x.ia, // same as items

    final: x.cf,
    pending: x.cp,
    cancelled: x.cc,
    cancelledCount: x.nc,
    refunded: x.crf,
    avgCommissionPercent: x.ac / 100,
    unknown: x.cu,
    nonCommissionable: x.cnc,

    orderCount: {
      all: x.oca,
      total: x.oct,
      lost: x.ocl,

      final: x.ocf,
      pending: x.ocp,
      cancelled: x.occ,
      refunded: x.ocrf,
      unknown: x.ocu,
      nonCommissionable: x.ocnc
    },

    saleValue: {
      total: x.pt,
      lost: x.pl,

      final: x.pf,
      pending: x.pp,
      cancelled: x.pc,
      refunded: x.prf,
      unknown: x.pu,
      nonCommissionable: x.pnc
    }
  };
};

export const toDailyEarningFromMinimal = (
  m: IEarningMinimalDaily
): IDailyEarning => {
  const earning = toEarningFromMinimal(m) as IDailyEarning;
  earning.timeKey = m.tk;
  return earning;
};

export interface IPostgresSale {
  id: string;
  space_id: string;
  page_url: string | null;
  origin: string | null;
  product_id: string | null;
  device: Device;
  partner_key: string;
  partner_product_name: string | null;
  partner_product_id: string | null;
  advertiser_id: string | null;
  advertiser_name: string | null;
  order_id: string | null;
  sale_id: string;
  sale_type: SaleType;
  sale_date: Timestamp;
  click_or_sale_date: Timestamp;
  completion_date: Timestamp | null;
  payout_date: Timestamp | null;
  last_modified: Timestamp | null;
  sale_status: Lowercase<SaleStatus>;
  payout_status: PayoutStatus;
  payout_id: string | null;
  tracking_id: string | null;
  tracking_label: string | null;
  report_id: string;
  price: number | null;
  revenue: number | null;
  commission: number | null;
  o_currency: Lowercase<CurrencyCode>; // is this really lowercased?
  o_price: number | null;
  o_revenue: number | null;
  o_commission: number | null;
  r_currency: Lowercase<CurrencyCode>; // is this really lowercased?
  r_price: number | null;
  r_revenue: number | null;
  r_commission: number | null;
  comm_percent: number; // not a decimal!
  quantity: number;
  referrer_url: string | null;
  ext_referrer_url: string | null;
  ext_referrer_origin: string | null;
  click_pv_id: string | null;
  click_p_id: string | null;
  click_device: Device | null;
  click_country: string | null;
  click_href: string | null;
  click_t_id: string | null;
  click_occ: number | null;
  click_date: Timestamp | null;

  label_rule_id: string | null;
  metadata: object;
  coupon: string | null;
  orig_id: string;
  created_at: Timestamp;
  created_by: string;
  checksum: string;

  insertion_id: string;
  channel_id: string;
  integration_id: string;
}

export const postgresSaleToITrackedSale = (
  d: IPostgresSale,
  opts: { amountsInUsd?: boolean } = {}
): Doc<ITrackedSale> => {
  const status = (d.sale_status[0].toUpperCase() +
    d.sale_status.slice(1)) as SaleStatus;

  const trackingLabel = (function () {
    if (d.tracking_id && d.tracking_id !== d.tracking_label) {
      return [d.tracking_label, d.tracking_id].filter((t) => t).join('_');
    }
    return d.tracking_label;
  })();

  const sale: ISale = {
    orderId: d.order_id || undefined,
    saleId: d.sale_id,
    trackingId: d.tracking_id,
    trackingLabel,
    reportId: d.report_id,
    saleDate: d.sale_date,
    saleType: d.sale_type,
    completionDate: d.completion_date,
    payoutDate: d.payout_date,
    lastModified: d.last_modified,
    payoutStatus: d.payout_status,
    payoutId: d.payout_id,
    partnerKey: d.partner_key,
    partnerProductName: d.partner_product_name,
    partnerProductId: d.partner_product_id,
    advertiserId: d.advertiser_id || '',
    advertiserName: d.advertiser_name || '',
    amount:
      opts && opts.amountsInUsd
        ? {
            currency: 'USD',
            price: d.price,
            revenue: d.revenue,
            commission: d.commission || 0
          }
        : {
            currency: d.o_currency.toUpperCase() as CurrencyCode,
            price: d.o_price,
            revenue: d.o_revenue,
            commission: d.o_commission || 0
          },
    commissionPercent: d.comm_percent === null ? null : d.comm_percent / 100,
    quantity: d.quantity,
    device: d.device,
    referrerUrl: d.referrer_url || '',
    origin: d.origin,
    clickDate: d.click_date || undefined,
    metadata: d.metadata,
    status,
    integrationId: d.integration_id
  };
  const click: IDenormalizedClickEvent | null =
    d.click_pv_id !== null &&
    d.click_p_id !== null &&
    d.click_t_id !== null &&
    d.click_country !== null &&
    d.click_device !== null &&
    d.click_href !== null &&
    d.click_occ !== null &&
    d.click_date !== null
      ? {
          sId: d.space_id,
          pvId: d.click_pv_id,
          tId: d.click_t_id,
          device: d.click_device,
          country: d.click_country,
          href: d.click_href,
          pId: d.click_p_id,
          occ: d.click_occ,
          createdAt: d.click_date,
          cId: d.channel_id
        }
      : null;
  const data: ITrackedSale = {
    spaceId: d.space_id,
    queryDate: d.click_date || d.sale_date,
    sale,
    click,
    createdAt: d.created_at,
    createdBy: d.created_by,
    pageUrl: d.page_url,
    origin: d.origin,
    device: d.device,
    labelRuleId: d.label_rule_id || '',
    saleType: d.sale_type,
    channelId: d.channel_id
  };
  return {
    id: d.orig_id,
    collection: FS.sales,
    data: data
  };
};

export type IPostgresSaleFlat = [
  string, // id
  string, // space_id
  string | null, // page_url
  string | null, // origin
  string | null, // product_id
  Device, // device
  string, // partner_key
  string | null, // partner_product_name
  string | null, // partner_product_id
  string | null, // advertiser_id
  string | null, // advertiser_name
  string | null, // order_id
  string, // sale_id
  Timestamp, // sale_date
  Timestamp, // click_or_sale_date
  Timestamp | null, // completion_date
  Timestamp | null, // payout_date
  Timestamp | null, // last_modified
  Lowercase<SaleStatus>, // sale_status
  string | null, // tracking_id
  string | null, // tracking_label
  string, // report_id
  number | null, // price
  number | null, // revenue
  number | null, // commission
  Lowercase<CurrencyCode>, // o_currency
  number | null, // o_price -- original price
  number | null, // o_revenue
  number | null, // o_commission
  Lowercase<CurrencyCode>, // r_currency -- reporting currency
  number | null, // r_price
  number | null, // r_revenue
  number | null, // r_commission
  number, // comm_percent
  number, // quantity
  string | null, // referrer_url
  string | null, // ext_referrer_url
  string | null, // ext_referrer_origin
  string | null, // click_pv_id
  string | null, // click_p_id
  string | null, // click_device
  string | null, // click_country
  Device | null, // click_device
  string | null, // click_href
  number | null, // click_occ
  Timestamp | null, // click_date

  string | null, // label_rule_id
  object, // metadata
  string | null, // coupon
  string, // orig_id
  Timestamp, // created_at
  string, // created_by
  string, // checksum
  SaleType, // sale_type
  string | null, // payout_id
  PayoutStatus, // payout_status
  string, // insertion_id
  string, // channel_id
  string // integration_id
];

export const postgresSaleFlatToITrackedSale = (
  d: IPostgresSaleFlat,
  opts: { amountsInUsd?: boolean } = {}
): Doc<ITrackedSale> => {
  const [
    // @ts-ignore
    // eslint-disable-next-line
    id,
    space_id,
    page_url,
    origin,
    // @ts-ignore
    // eslint-disable-next-line
    product_id,
    device,
    partner_key,
    partner_product_name,
    partner_product_id,
    advertiser_id,
    advertiser_name,
    order_id,
    sale_id,
    sale_date,
    clikc_or_sale_date,
    completion_date,
    // @ts-ignore
    // eslint-disable-next-line
    payout_date,
    // @ts-ignore
    // eslint-disable-next-line
    last_modified,
    sale_status,
    tracking_id,
    tracking_label,
    report_id,
    price,
    revenue,
    commission,
    o_currency,
    o_price,
    o_revenue,
    o_commission,
    // @ts-ignore
    // eslint-disable-next-line
    r_currency,
    // @ts-ignore
    // eslint-disable-next-line
    r_price,
    // @ts-ignore
    // eslint-disable-next-line
    r_revenue,
    // @ts-ignore
    // eslint-disable-next-line
    r_commission,
    comm_percent,
    quantity,
    referrer_url,
    // @ts-ignore
    // eslint-disable-next-line
    ext_referrer_url,
    // @ts-ignore
    // eslint-disable-next-line
    ext_referrer_origin,
    click_pv_id,
    click_p_id,
    click_href,
    click_country,
    click_device,
    click_t_id,
    click_occ,
    click_date,

    label_rule_id,
    metadata,
    // @ts-ignore
    // eslint-disable-next-line
    coupon,
    orig_id,
    created_at,
    created_by,
    // @ts-ignore
    // eslint-disable-next-line
    checksum,
    sale_type,
    payout_id,
    payout_status,
    // @ts-ignore
    // eslint-disable-next-line
    insertion_id,
    channel_id,
    integration_id
  ] = d;
  const status = (sale_status[0].toUpperCase() +
    sale_status.slice(1)) as SaleStatus;
  const sale: ISale = {
    orderId: order_id || undefined,
    saleId: sale_id,
    trackingId: tracking_id,
    // PG stores the label WITHOUT the id. Restore if possible
    trackingLabel: tracking_id
      ? [tracking_id, tracking_label].filter((t) => t).join('_')
      : tracking_label,
    reportId: report_id,
    saleDate: sale_date,
    completionDate: completion_date,
    payoutDate: payout_date,
    payoutId: payout_id,
    payoutStatus: payout_status,
    saleType: sale_type,
    lastModified: last_modified,
    coupon: coupon || '',
    partnerKey: partner_key,
    partnerProductName: partner_product_name,
    partnerProductId: partner_product_id,
    advertiserId: advertiser_id || '',
    advertiserName: advertiser_name || '',
    amount:
      opts && opts.amountsInUsd
        ? {
            currency: 'USD',
            price: price,
            revenue: revenue,
            commission: commission || 0
          }
        : {
            currency: o_currency.toUpperCase() as CurrencyCode,
            price: o_price,
            revenue: o_revenue,
            commission: o_commission || 0
          },
    commissionPercent: comm_percent === null ? null : comm_percent / 100,
    quantity: quantity,
    device: device,
    referrerUrl: referrer_url || '',
    origin: origin,
    clickDate: click_date || undefined,
    metadata: metadata,
    status,
    integrationId : integration_id || ''
  };
  const click: IDenormalizedClickEvent | null =
    click_pv_id !== null &&
    click_p_id !== null &&
    click_t_id !== null &&
    click_country !== null &&
    click_device !== null &&
    click_href !== null &&
    click_occ !== null &&
    click_date !== null
      ? {
          sId: space_id,
          pvId: click_pv_id,
          tId: click_t_id,
          device: click_device,
          country: click_country,
          href: click_href,
          pId: click_p_id,
          occ: click_occ,
          createdAt: click_date,
          cId: channel_id
        }
      : null;
  const data: ITrackedSale = {
    spaceId: space_id,
    queryDate: clikc_or_sale_date,
    sale,
    click,
    createdAt: created_at,
    createdBy: created_by,
    pageUrl: page_url,
    origin: origin,
    device: device,
    labelRuleId: label_rule_id || '',
    saleType: sale_type,
    channelId: channel_id
  };
  return {
    id: orig_id,
    collection: FS.sales,
    data: data
  };
};

export const expandPostgresSaleFlat = (d: IPostgresSaleFlat): IPostgresSale => {
  const [
    id,
    space_id,
    page_url,
    origin,
    product_id,
    device,
    partner_key,
    partner_product_name,
    partner_product_id,
    advertiser_id,
    advertiser_name,
    order_id,
    sale_id,
    sale_date,
    click_or_sale_date,
    completion_date,
    payout_date,
    last_modified,
    sale_status,
    tracking_id,
    tracking_label,
    report_id,
    price,
    revenue,
    commission,
    o_currency,
    o_price,
    o_revenue,
    o_commission,
    r_currency,
    r_price,
    r_revenue,
    r_commission,
    comm_percent,
    quantity,
    referrer_url,
    ext_referrer_url,
    ext_referrer_origin,
    click_pv_id,
    click_p_id,
    click_country,
    click_href,
    click_device,
    click_t_id,
    click_occ,
    click_date,

    label_rule_id,
    metadata,
    coupon,
    orig_id,
    created_at,
    created_by,
    checksum,
    sale_type,
    payout_id,
    payout_status,
    insertion_id,
    channel_id,
    integration_id
  ] = d;
  return {
    id,
    space_id,
    page_url,
    origin,
    product_id,
    device,
    partner_key,
    partner_product_name,
    partner_product_id,
    advertiser_id,
    advertiser_name,
    order_id,
    sale_id,
    sale_date,
    click_or_sale_date,
    completion_date,
    payout_date,
    last_modified,
    sale_status,
    tracking_id,
    tracking_label,
    report_id,
    price,
    revenue,
    commission,
    o_currency,
    o_price,
    o_revenue,
    o_commission,
    r_currency,
    r_price,
    r_revenue,
    r_commission,
    comm_percent,
    quantity,
    referrer_url,
    ext_referrer_url,
    ext_referrer_origin,
    click_pv_id,
    click_p_id,
    click_device,
    click_country,
    click_href,
    click_t_id,
    click_occ,
    click_date,

    label_rule_id,
    metadata,
    coupon,
    orig_id,
    created_at,
    created_by,
    checksum,
    sale_type,
    payout_id,
    payout_status,
    insertion_id,
    channel_id,
    integration_id
  };
};

export type Advertiser = {
  name: string;
  id: string | null;
  partnerKey: IPartner['key'];
};

export type AdvertiserWithData = {
  clicks: number;
  orders: number;
  conversionRate: number;
  extra?: any; // Any extra data we want to report
  // that is provided by the network
} & Advertiser;
