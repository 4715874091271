import { Button, Tooltip, Typography } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { IPostgresTags } from '../../../../../../domainTypes/tags';
import { allVerticals, TAG_TEMPLATES } from '../constants/templates';
import { Tag } from './Tag';
import { styled } from '../../../../../../emotion';
import { createTags } from '../../../../../../services/tags';
import { useCurrentUser } from '../../../../../../services/currentUser';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import { partition } from 'lodash';

const ListItem = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(4)}px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ListWrapper = styled('div')`
  display: flex;
  gap: ${(p) => p.theme.spacing(2)}px;
  flex-wrap: wrap;
`;

const ListHeading = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

export const TagTemplateLibrary = ({
  tags,
  search,
  selectedVerticals
}: {
  tags: IPostgresTags[];
  search: string;
  selectedVerticals: Set<string>;
}) => {
  const [loading, setLoading] = useState<number>(-1);
  const { space } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const existingGroupNames = tags
    .filter((t) => t.parent_id === null)
    .map((t) => t.name);

  const groups = useMemo(() => {
    const allParents = TAG_TEMPLATES.filter((t) => t.parent_id === null);

    if (search === '' && selectedVerticals.size === allVerticals.length) {
      return allParents;
    }

    const filteredParents = allParents.filter((t) => {
      const children = TAG_TEMPLATES.filter((c) => c.parent_id === t.id);
      const names = [t.name, ...children.map((c) => c.name)];
      const matchesSearch =
        names.some((n) => n.toLowerCase().includes(search.toLowerCase())) ||
        t.vertical?.toLowerCase().includes(search.toLowerCase());
      const matchesVerticals = selectedVerticals.has(t.vertical as string);
      return matchesVerticals && matchesSearch;
    });

    return filteredParents;
  }, [search, selectedVerticals]);

  const applyTags = async (tagsToAdd: IPostgresTags[], index: number) => {
    setLoading(index);
    const finalTags = tagsToAdd.map((t) => ({ ...t, space_id: space.id }));

    const [parents, children] = partition(
      finalTags,
      (t) => t.parent_id === null
    );

    try {
      // Tag IDs are generated on the server, so the client-side
      // ones are only temporary for display. So we need to see
      // which tag id comes back for the parent before we can
      // properly nest the children tags under it
      const parentResp = await createTags(space.id, parents);
      const parentId = parentResp.res[0].id;
      const finalChildren = children.map((t) => ({
        ...t,
        parent_id: parentId
      }));
      await createTags(space.id, finalChildren);
      enqueueSnackbar('Tags created successfully!', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(
        'Could not create tags, please try again or contact Support',
        { variant: 'error' }
      );
    } finally {
      setLoading(-1);
    }
  };

  return (
    <div>
      {groups.map((g, i) => {
        const children = TAG_TEMPLATES.filter((t) => t.parent_id === g.id);
        const groupNameAlreadyExists = existingGroupNames.includes(g.name);
        return (
          <ListItem
            key={g.id}
            style={{ opacity: groupNameAlreadyExists ? 0.5 : 1 }}
          >
            <ListHeading>
              <Typography variant="body1" component="p">
                <strong>{g.name}</strong>{' '}
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="span"
                  style={{ display: 'inline-block', marginLeft: '6px' }}
                >
                  {g.vertical}
                </Typography>
              </Typography>
              <Tooltip
                title={
                  groupNameAlreadyExists
                    ? `You currently have a tag group with this name. Delete your existing group, or manually add this group's tags to your existing group.`
                    : ''
                }
                placement="top"
              >
                {/* Span tag exists so tooltip will render on disabled button */}
                <span>
                  <Button
                    variant="contained"
                    color="default"
                    size="small"
                    disabled={loading !== -1 || groupNameAlreadyExists}
                    onClick={() => applyTags([g, ...children], i)}
                  >
                    {loading === i && !groupNameAlreadyExists
                      ? 'Creating tags...'
                      : 'Use template'}
                  </Button>
                </span>
              </Tooltip>
            </ListHeading>
            <ListWrapper>
              {children.map((c) => {
                return <Tag tag={c} key={c.id} options={{ readonly: true }} />;
              })}
            </ListWrapper>
          </ListItem>
        );
      })}
    </div>
  );
};
