import { Card, Typography } from '@material-ui/core';
import { DatePicker, DateTimePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Rewind } from 'react-feather';
import { ButtonWithPromise } from '../../../components/ButtonWithPromise';
import { SupportButton } from '../../../components/SupportButton';
import { COLORS } from '../../../domainTypes/colors';
import { ISpace } from '../../../domainTypes/space';
import { styled } from '../../../emotion';
import { Centered } from '../../../layout/Centered';
import { FlexContainer } from '../../../layout/Flex';
import { useCurrentUser } from '../../../services/currentUser';
import { useFeatureEnabled } from '../../../services/features';
import { fromMoment, useTzAbbr } from '../../../services/time';
import {
  createManualRevision,
  useCanCreateManualRevisions
} from './RevisionCreateDialog';

const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  text-align: center;
  margin: 0 auto;
  border-radius: 100%;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

const RevisionsEmptyStateNotActionable = () => {
  return (
    <Card
      style={{
        maxWidth: 600,
        padding: '36px',
        margin: '0 auto'
      }}
    >
      <Centered height={500}>
        <div>
          <IconWrapper
            style={{
              color: COLORS.blue.blue6,
              backgroundColor: COLORS.blue.blue2
            }}
          >
            <Rewind size={42} />
          </IconWrapper>
          <Typography variant="h6" paragraph style={{ fontWeight: 700 }}>
            No revisions available yet for this page
          </Typography>
          <Typography variant="body1" paragraph>
            We don't have revisions for this particular page just yet.
            <br />
            <br />
            This can happen because:
          </Typography>
          <Typography variant="body1" component="ul" paragraph>
            <li>This page hasn't been processed yet in the queue.</li>
            <li>
              No modification data was available through your schema or sitemap.
            </li>
            <li>
              This page is not within your 2,000 most recently modified pages.
            </li>
            <li>
              This page has never sent us analytics data, or has only sent us
              analytics data starting today.
            </li>
          </Typography>
          <Typography variant="body1" paragraph>
            If this seems incorrect, you can always reach out to us.
          </Typography>
          <br />
          <div style={{ textAlign: 'center', margin: '0 auto' }}>
            <SupportButton
              label="Contact support"
              subject="No revisions available"
            />
          </div>
        </div>
      </Centered>
    </Card>
  );
};

const CardFooter = styled('div')`
  display: grid;
  grid-template-columns: 1fr 410px;
  align-items: center;
  grid-gap: ${(p) => p.theme.spacing(2)}px;
  background-color: ${(p) => p.theme.palette.grey[200]};
  padding: ${(p) => p.theme.spacing(2)}px;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  margin: 0 auto ${(p) => p.theme.spacing(2)}px;
`;

const RevisionsEmptyStateActionable = ({
  space,
  url
}: {
  space: ISpace;
  url: string;
}) => {
  const { tz } = space.config;
  const canSetTime = useFeatureEnabled('REFERRER_REPORTS_V1');
  const [nextRevisionDate, setNextRevisionDate] = useState<moment.Moment>(
    moment.tz(tz).startOf('day')
  );
  const tzString = useTzAbbr(tz);
  return (
    <>
      <CardFooter>
        <Typography variant="body2" color="textSecondary">
          <strong>Published today?</strong> If this page was published today,
          you can try to create a manual revision. Be precise to include
          existing, relevant data. Otherwise, please wait for the nightly
          process or report an issue with automatic capture to support.
        </Typography>
        <FlexContainer alignItems="center" justifyContent="flex-end">
          {canSetTime ? (
            <DateTimePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              value={nextRevisionDate}
              clearable={false}
              format={'LL HH:mm'}
              size="small"
              label={`Published at (${tzString} timezone)`}
              style={{ width: 220 }}
              onChange={(e) => setNextRevisionDate(e as moment.Moment)}
            />
          ) : (
            <DatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              value={nextRevisionDate}
              clearable={false}
              size="small"
              format={'LL HH:mm'}
              label="Published on"
              onChange={(e) =>
                setNextRevisionDate((e as moment.Moment).startOf('day'))
              }
            />
          )}
          <ButtonWithPromise
            color="default"
            variant="contained"
            onClick={async () => {
              await createManualRevision(
                space.id,
                url,
                fromMoment(nextRevisionDate),
                {
                  createMissingPageMetadataDocument: true
                }
              );
            }}
            pending="Creating..."
          >
            Create a revision
          </ButtonWithPromise>
        </FlexContainer>
      </CardFooter>
      <Card
        style={{
          maxWidth: 600,
          margin: '0 auto',
          padding: '36px'
        }}
      >
        <Centered height={550}>
          <div>
            <IconWrapper
              style={{
                color: COLORS.blue.blue6,
                backgroundColor: COLORS.blue.blue2
              }}
            >
              <Rewind size={42} />
            </IconWrapper>
            <Typography variant="h6" paragraph style={{ fontWeight: 700 }}>
              No revisions available yet for this page
            </Typography>
            <Typography variant="body1" paragraph>
              We don't have revisions for this particular page just yet.
              <br />
              <br />
              This can happen because:
            </Typography>
            <Typography variant="body1" component="ul" paragraph>
              <li>This page hasn't been processed yet in the queue.</li>
              <li>
                No modification data was available through your schema or
                sitemap.
              </li>
              <li>
                This page is not within your 2,000 most recently modified pages.
              </li>
              <li>
                This page has never sent us analytics data or the script is
                missing.
              </li>
              <li>This page was just published today.</li>
            </Typography>
            <Typography variant="body1" paragraph>
              If this seems incorrect, you can always reach out to us.
            </Typography>
            <br />
            <div style={{ textAlign: 'center', margin: '0 auto' }}>
              <SupportButton
                label="Contact support"
                subject="No revisions available"
              />
            </div>
          </div>
        </Centered>
      </Card>
    </>
  );
};

export const RevisionsEmptyState = ({ url }: { url: string }) => {
  const { space } = useCurrentUser();
  const canCreateManualRevisions = useCanCreateManualRevisions(space);
  return canCreateManualRevisions ? (
    <RevisionsEmptyStateActionable space={space} url={url} />
  ) : (
    <RevisionsEmptyStateNotActionable />
  );
};
