import { styled } from '../emotion';

export const DrawerHeader = styled('div')`
  background-color: ${(p) => p.theme.palette.grey[100]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  padding: ${(p) => p.theme.spacing(1)}px ${(p) => p.theme.spacing(1)}px
    ${(p) => p.theme.spacing(1)}px ${(p) => p.theme.spacing(3)}px;
  min-width: 400px;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;

  span {
    display: inline-block;
    color: black;
    font-weight: 700;
  }

  .pagination {
    font-weight: 500;
    color: ${(p) => p.theme.palette.grey[400]};

    .MuiIconButton-label {
      color: ${(p) => p.theme.palette.grey[400]};
    }
  }

  button {
    color: inherit;
  }
`;

export const SectionHeading = styled('div')`
  font-size: ${(p) => p.theme.typography.body2.fontSize}px;
  color: ${(p) => p.theme.palette.grey[600]};
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
  margin-bottom: ${(p) => p.theme.spacing(1)}px;
  text-transform: uppercase;
`;

export const CommissionWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing(1.5)}px;
`;

export const DrawerSection = styled('div')`
  padding: ${(p) => p.theme.spacing(2.5)}px ${(p) => p.theme.spacing(3)}px;
`;

export const ListWide = styled('dl')`
  display: grid;
  grid-template-columns: 250px 1fr;
  align-items: center;
  grid-column-gap: ${(p) => p.theme.spacing(2)}px;
  width: 100%;
  font-size: ${(p) => p.theme.typography.body2.fontSize};
  line-height: ${(p) => p.theme.typography.body1.lineHeight};
  margin: 0;

  dt {
    color: ${(p) => p.theme.palette.grey[600]};
    margin-bottom: ${(p) => p.theme.spacing(1)}px;
  }
  dd {
    margin: 0 0 ${(p) => p.theme.spacing(1)}px 0;
    word-break: break-all;
  }
`;

export const List = styled('dl')`
  display: grid;
  grid-template-columns: 110px 1fr;
  align-items: top;
  grid-column-gap: ${(p) => p.theme.spacing(2)}px;
  width: 100%;
  font-size: ${(p) => p.theme.typography.body2.fontSize};
  line-height: ${(p) => p.theme.typography.body1.lineHeight};
  margin: 0;

  dt {
    color: ${(p) => p.theme.palette.grey[600]};
    margin-bottom: ${(p) => p.theme.spacing(1)}px;
  }
  dd {
    margin: 0 0 ${(p) => p.theme.spacing(1)}px 0;
    word-break: break-all;
  }
`;
