import { Helmet } from 'react-helmet';
import { NoPermissions } from '../../../../components/NoPermissions';
import { useHasCurrentUserRequiredScopes } from '../../../../services/currentUser';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { AmazonIssuesReportContent } from './AmazonIssuesReportContent';

export const PageAmazonIssuesReport = () => {
  useTrackMixpanelView('view_amazon_issues');
  const [canView] = useHasCurrentUserRequiredScopes([
    'link_checker.amazon.view'
  ]);
  return (
    <>
      <Helmet>
        <title>Amazon Issues | Affilimate</title>
      </Helmet>

      {canView ? <AmazonIssuesReportContent /> : <NoPermissions />}
    </>
  );
};
