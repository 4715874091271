import moment from 'moment-timezone';

import {
  TimeframeComparisonDefinition,
  TimeframeDefinition,
  TimeframeRangeDefinition
} from '../../../domainTypes/timeframe';
import { useMemo } from 'react';
import { useFeatureEnabled } from '../../../services/features';
import { useCurrentUser } from '../../../services/currentUser';

const previous = (
  unit: moment.unitOfTime.DurationConstructor
): TimeframeRangeDefinition => ({
  kind: 'period',
  value: {
    type: 'previous',
    unit
  }
});

const current = (
  unit: moment.unitOfTime.StartOf
): TimeframeRangeDefinition => ({
  kind: 'period',
  value: {
    type: 'current',
    unit
  }
});

const last = (
  count: number,
  unit: moment.unitOfTime.DurationConstructor
): TimeframeRangeDefinition => ({
  kind: 'period',
  value: {
    type: 'last',
    duration: moment.duration(count, unit).toISOString()
  }
});

const latest = (
  count: number,
  unit: moment.unitOfTime.DurationConstructor
): TimeframeRangeDefinition => ({
  kind: 'period',
  value: {
    type: 'latest',
    duration: moment.duration(count, unit).toISOString()
  }
});

export interface ITimeframeOption {
  value: TimeframeRangeDefinition;
  label: string;
  customize?: boolean;
}

export const defaultTimeframe: TimeframeDefinition = {
  range: last(30, 'days'),
  comparison: {
    kind: 'previous'
  }
};

const realtimeTimeframeOptions: ITimeframeOption[] = [
  {
    label: 'Latest hour',
    value: latest(1, 'hour')
  },
  {
    label: 'Latest 24 hours',
    value: latest(24, 'hours')
  },
  {
    label: 'Today',
    value: current('day')
  }
];

const baseTimeframeOptions: ITimeframeOption[] = [
  {
    label: 'Yesterday',
    value: previous('day')
  },
  {
    value: last(7, 'days'),
    label: 'Last 7 days'
  },
  {
    value: last(14, 'days'),
    label: 'Last 14 days'
  },
  {
    value: last(28, 'days'),
    label: 'Last 28 days'
  },
  {
    value: last(30, 'days'),
    label: 'Last 30 days'
  },
  {
    value: last(365, 'days'),
    label: 'Last 365 days'
  },
  {
    value: current('isoWeek'),
    label: 'This week (Mon – Sun)'
  },
  {
    value: current('week'),
    label: 'This week (Sun – Sat)'
  },
  {
    value: current('month'),
    label: 'This month'
  },
  {
    value: current('quarter'),
    label: 'This quarter'
  },
  {
    value: current('year'),
    label: 'This year'
  },
  {
    value: previous('month'),
    label: 'Last month'
  },
  {
    value: previous('quarter'),
    label: 'Last quarter'
  },
  {
    value: previous('year'),
    label: 'Last year'
  }
];

const useHasRealtimeOptions = () => {
  return useFeatureEnabled('REFERRER_REPORTS_V1');
};

const useAllTimeOption = (): ITimeframeOption => {
  const hasRealtimeOptions = useHasRealtimeOptions();
  const { space, tz } = useCurrentUser();
  return useMemo(() => {
    const start = moment(space.createdAt.toDate())
      .tz(tz)
      .startOf('day')
      .toISOString();
    const end = hasRealtimeOptions
      ? moment().tz(tz).endOf('day').toISOString()
      : moment().tz(tz).startOf('day').subtract(1, 'millisecond').toISOString();
    return {
      value: {
        kind: 'custom',
        start,
        end
      },
      label: 'Since account start'
    };
  }, [hasRealtimeOptions, space.createdAt, tz]);
};

export const useDefaultTimeframeOptions = () => {
  const allTimeOption = useAllTimeOption();
  const hasRealtimeOptions = useHasRealtimeOptions();
  if (hasRealtimeOptions) {
    return [
      ...realtimeTimeframeOptions,
      ...baseTimeframeOptions,
      allTimeOption
    ];
  }
  return [...baseTimeframeOptions, allTimeOption];
};

export type IComparisonOption = {
  label: string;
  value: TimeframeComparisonDefinition;
};

const realtimeComparisons: Array<IComparisonOption> = [
  {
    label: 'Day over day',
    value: {
      kind: 'timeshift',
      duration: 'P1D'
    }
  }
];

const comparisons: Array<IComparisonOption> = [
  {
    label: 'Previous timeframe',
    value: {
      kind: 'previous'
    }
  },
  {
    label: 'Week over week',
    value: {
      kind: 'timeshift',
      duration: 'P1W'
    }
  },
  {
    label: 'Month over month',
    value: {
      kind: 'timeshift',
      duration: 'P1M'
    }
  },
  {
    label: 'Year over year',
    value: {
      kind: 'timeshift',
      duration: 'P1Y'
    }
  },
  {
    label: 'Disabled',
    value: {
      kind: 'disabled'
    }
  }
];

export const useDefaultCompareOptions = (): Array<IComparisonOption> => {
  const hasRealtimeOptions = useHasRealtimeOptions();
  if (hasRealtimeOptions) {
    const [first, ...rest] = comparisons;
    return [first, ...realtimeComparisons, ...rest];
  }
  return comparisons;
};
