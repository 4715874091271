import React from 'react';
import { Button, Divider, Paper } from '@material-ui/core';
import { css } from '../../emotion';
import Typography from '@material-ui/core/Typography';
import { I18nProvider } from 'react-aria-components';
import { IComparisonOption, ITimeframeOption } from './service/options';
import { DateRangeInput } from './DateRangeInput';
import { DateRangePicker } from './DateRangePicker';
import { useTimePickerMenuState } from './useTimePickerMenuState';
import { TimePresetSelector } from './TimePresetSelector';
import { FlexContainer } from '../../layout/Flex';
import { Info } from 'react-feather';
import { useCurrentUser } from '../../services/currentUser';
import { TimeframeDefinition } from '../../domainTypes/timeframe';

const Header: React.FC = ({ children }) => (
  <Typography variant="body1" style={{ fontWeight: 'bold', color: '#828282' }}>
    {children}
  </Typography>
);

interface TimeframePickerMenuProps {
  initialValue: TimeframeDefinition;
  onSave: (nextValue: TimeframeDefinition) => void;
  onCancel: () => void;
  timeframeOptions: ITimeframeOption[];
  comparisonOptions: IComparisonOption[];
}

export const TimeframePickerMenu: React.FC<TimeframePickerMenuProps> = ({
  initialValue,
  onSave,
  onCancel,
  timeframeOptions,
  comparisonOptions
}) => {
  const { tz } = useCurrentUser();
  const {
    timeframeDefinition,
    isMenuValid,
    comparisonPickerProps,
    timeframePickerProps,
    comparisonInputProps,
    timeframeInputProps,
    comparisonPresetSelectProps,
    timeframePresetSelectProps
  } = useTimePickerMenuState(initialValue, timeframeOptions, comparisonOptions);
  return (
    <Paper>
      <I18nProvider locale="en-US">
        <div
          className={css((t) => ({
            display: 'grid',
            gridTemplateColumns: '200px 1fr',
            columnGap: t.spacing(2),
            rowGap: t.spacing(1),
            padding: t.spacing(2),
            minWidth: 250
          }))}
        >
          <div>
            <Header>Timeframe</Header>
            <TimePresetSelector
              {...timeframePresetSelectProps}
              options={timeframeOptions}
            />
          </div>
          <div>
            <DateRangeInput {...timeframeInputProps} />
            <DateRangePicker {...timeframePickerProps} />
          </div>
        </div>
        <Divider />
        <div
          className={css((t) => ({
            display: 'grid',
            gridTemplateColumns: '200px 1fr',
            columnGap: t.spacing(2),
            rowGap: t.spacing(1),
            padding: t.spacing(2),
            minWidth: 250
          }))}
        >
          <div>
            <Header>Compared to</Header>
            <TimePresetSelector
              options={comparisonOptions}
              {...comparisonPresetSelectProps}
            />
          </div>
          <div>
            <DateRangeInput {...comparisonInputProps} />
            <DateRangePicker {...comparisonPickerProps} variant="secondary" />
          </div>
        </div>
        <FlexContainer
          className={css((t) => ({
            backgroundColor: t.palette.grey.A100,
            padding: t.spacing(2),
            justifyContent: 'space-between'
          }))}
        >
          <FlexContainer>
            <Info size={14} color="rgba(0, 0, 0, 0.54)" />
            <Typography variant="body2" color="textSecondary">
              Your account timezone is{' '}
              <span className={css(() => ({ fontWeight: 'bold' }))}>{tz}</span>.{' '}
            </Typography>
          </FlexContainer>

          <FlexContainer spacing={2}>
            <Button variant="text" onClick={onCancel}>
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              disabled={!isMenuValid}
              onClick={() => {
                if (!isMenuValid) return;
                onSave(timeframeDefinition);
              }}
            >
              Apply timeframe
            </Button>
          </FlexContainer>
        </FlexContainer>
      </I18nProvider>
    </Paper>
  );
};
