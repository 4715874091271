import { Paper, Tooltip, Typography } from '@material-ui/core';
import { compact, orderBy } from 'lodash';
import React, { useMemo } from 'react';
import { ExternalLink, Search } from 'react-feather';
import { Link } from 'react-router-dom';
import { Ctr } from '../../../../../components/Ctr';
import { EmptySearchState } from '../../../../../components/EmptySearchState';
import {
  RowsRenderer,
  ROW_HEIGHTS,
  Sorter,
  Sorters
} from '../../../../../components/GroupableList';
import { Loader } from '../../../../../components/Loader';
import { MiniTag } from '../../../../../components/MiniTag';
import { RankChip } from '../../../../../components/Rank';
import { IColumn } from '../../../../../components/Table/Column';
import {
  BaseCountCell,
  CountCell,
  Dash
} from '../../../../../components/Table/CountCell';
import {
  toAbsoluteGrowth,
  toMultiSort,
  toSingleSort,
  ValueGetter
} from '../../../../../components/Table/ValueGetter';
import {
  getTrend,
  mapCounter,
  Mode
} from '../../../../../domainTypes/analytics';
import { COLORS } from '../../../../../domainTypes/colors';
import { CurrencyCode } from '../../../../../domainTypes/currency';
import { EMPTY_OBJ } from '../../../../../domainTypes/emptyConstants';
import { IPageWithCountsAndTrendsAndSales } from '../../../../../domainTypes/page';
import { isFromAutomaticGroup } from '../../../../../domainTypes/tag';
import { IPostgresTags } from '../../../../../domainTypes/tags';
import { css, styled } from '../../../../../emotion';
import { SortDirection } from '../../../../../hooks/useSort';
import {
  DEFAULT_OFFSET,
  DEFAULT_TOOLBAR_HEIGHT
} from '../../../../../layout/PageToolbar';
import { useRoutes } from '../../../../../routes';
import {
  getAov,
  getCpm,
  getEpc,
  getRpm,
  getViewRatio
} from '../../../../../services/analytics';
import { ARTICLES } from '../../../../../services/beacon';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../../services/currentUser';
import { getDomainName, getPathName } from '../../../../../services/pages';
import { toSelector } from '../../../../../services/selection';

export type ColumnName =
  | 'href'
  | 'rank'
  | 'pageViews'
  | 'served'
  | 'viewed'
  | 'viewRatio'
  | 'clicked'
  | 'clickRatio'
  | 'rpm'
  | 'epc'
  | 'sales'
  | 'salesCount'
  | 'orderCount'
  | 'aov'
  | 'saleValue'
  | 'selector';

export type PageGroupSummary = {
  key: string;
};

export const VALUE_GETTERS: {
  [K in ColumnName]: ValueGetter<IPageWithCountsAndTrendsAndSales>;
} = {
  selector: {
    // placeholder to fulfill type constrained
    current: () => 0,
    prev: () => 0,
    absoluteGrowth: () => 0,
    relativeGrowth: () => 0
  },
  rank: {
    // placeholder to fulfill type constrained
    current: () => 0,
    prev: () => 0,
    absoluteGrowth: () => 0,
    relativeGrowth: () => 0
  },
  href: {
    current: (p) => p.href,
    prev: (p) => p.href,
    absoluteGrowth: (p) => p.href,
    relativeGrowth: (p) => p.href
  },
  pageViews: {
    current: (p) => p.counts.pageViews.count,
    prev: (p) => p.counts.pageViews.lastCount,
    absoluteGrowth: (p) => toAbsoluteGrowth(p.counts.pageViews),
    relativeGrowth: (p) => p.counts.pageViews.trend
  },
  served: {
    current: (p) => p.counts.served.count,
    prev: (p) => p.counts.served.lastCount,
    absoluteGrowth: (p) => toAbsoluteGrowth(p.counts.served),
    relativeGrowth: (p) => p.counts.served.trend
  },
  viewed: {
    current: (p) => p.counts.viewed.count,
    prev: (p) => p.counts.viewed.lastCount,
    absoluteGrowth: (p) => toAbsoluteGrowth(p.counts.viewed),
    relativeGrowth: (p) => p.counts.viewed.trend
  },
  viewRatio: {
    current: (p) => getViewRatio(mapCounter(p.counts, (v) => v.count)),
    prev: (p) => getViewRatio(mapCounter(p.counts, (v) => v.lastCount)),
    absoluteGrowth: (p) => {
      const current = getViewRatio(mapCounter(p.counts, (v) => v.count));
      const prev = getViewRatio(mapCounter(p.counts, (v) => v.lastCount));
      return current - prev;
    },
    relativeGrowth: (p) => {
      const current = getViewRatio(mapCounter(p.counts, (v) => v.count));
      const prev = getViewRatio(mapCounter(p.counts, (v) => v.lastCount));
      return getTrend(prev, current);
    }
  },
  clicked: {
    current: (p) => p.counts.clicked.count,
    prev: (p) => p.counts.clicked.lastCount,
    absoluteGrowth: (p) => toAbsoluteGrowth(p.counts.clicked),
    relativeGrowth: (p) => p.counts.clicked.trend
  },
  clickRatio: {
    current: (p) => getCpm(mapCounter(p.counts, (v) => v.count)),
    prev: (p) => getCpm(mapCounter(p.counts, (v) => v.lastCount)),
    absoluteGrowth: (p) => {
      const current = getCpm(mapCounter(p.counts, (v) => v.count));
      const prev = getCpm(mapCounter(p.counts, (v) => v.lastCount));
      return current - prev;
    },
    relativeGrowth: (p) => {
      const current = getCpm(mapCounter(p.counts, (v) => v.count));
      const prev = getCpm(mapCounter(p.counts, (v) => v.lastCount));
      return getTrend(prev, current);
    }
  },
  epc: {
    current: (p) => getEpc(p.counts.clicked.count, p.sales.count),
    prev: (p) => getEpc(p.counts.clicked.lastCount, p.sales.lastCount),
    absoluteGrowth: (p) => {
      const current = getEpc(p.counts.clicked.count, p.sales.count);
      const prev = getEpc(p.counts.clicked.lastCount, p.sales.lastCount);
      return current - prev;
    },
    relativeGrowth: (p) => {
      const current = getEpc(p.counts.clicked.count, p.sales.count);
      const prev = getEpc(p.counts.clicked.lastCount, p.sales.lastCount);
      return getTrend(prev, current);
    }
  },
  aov: {
    current: (p) => getAov(p.orderCount.count, p.saleValue.count),
    prev: (p) => getAov(p.orderCount.lastCount, p.saleValue.lastCount),
    absoluteGrowth: (p) => {
      const current = getAov(p.orderCount.count, p.saleValue.count);
      const prev = getAov(p.orderCount.lastCount, p.saleValue.lastCount);
      return current - prev;
    },
    relativeGrowth: (p) => {
      const current = getAov(p.orderCount.count, p.saleValue.count);
      const prev = getAov(p.orderCount.lastCount, p.saleValue.lastCount);
      return getTrend(prev, current);
    }
  },
  rpm: {
    current: (p) => getRpm(p.counts.pageViews.count, p.sales.count),
    prev: (p) => getRpm(p.counts.pageViews.lastCount, p.sales.lastCount),
    absoluteGrowth: (p) => {
      const current = getRpm(p.counts.pageViews.count, p.sales.count);
      const prev = getRpm(p.counts.pageViews.lastCount, p.sales.lastCount);
      return current - prev;
    },
    relativeGrowth: (p) => {
      const current = getRpm(p.counts.pageViews.count, p.sales.count);
      const prev = getRpm(p.counts.pageViews.lastCount, p.sales.lastCount);
      return getTrend(prev, current);
    }
  },
  sales: {
    current: (p) => p.sales.count,
    prev: (p) => p.sales.lastCount,
    absoluteGrowth: (p) => toAbsoluteGrowth(p.sales),
    relativeGrowth: (p) => p.sales.trend
  },
  salesCount: {
    current: (p) => p.salesCount.count,
    prev: (p) => p.salesCount.lastCount,
    absoluteGrowth: (p) => toAbsoluteGrowth(p.salesCount),
    relativeGrowth: (p) => p.salesCount.trend
  },
  orderCount: {
    current: (p) => p.orderCount.count,
    prev: (p) => p.orderCount.lastCount,
    absoluteGrowth: (p) => toAbsoluteGrowth(p.orderCount),
    relativeGrowth: (p) => p.orderCount.trend
  },
  saleValue: {
    current: (p) => p.saleValue.count,
    prev: (p) => p.saleValue.lastCount,
    absoluteGrowth: (p) => toAbsoluteGrowth(p.saleValue),
    relativeGrowth: (p) => p.saleValue.trend
  }
};

const createSorters = (
  mode: Mode
): Sorters<PageGroupSummary, IPageWithCountsAndTrendsAndSales> => ({
  href: {
    key: 'href',
    label: 'Page URL',
    groups: { sort: () => 0, dir: 'asc' },
    items: {
      sort: toSingleSort(VALUE_GETTERS['href'], mode),
      dir: 'asc'
    }
  },
  pageViews: {
    key: 'pageViews',
    label: 'Pageviews',
    groups: { sort: () => 0, dir: 'desc' },
    items: {
      sort: toSingleSort(VALUE_GETTERS['pageViews'], mode),
      dir: 'desc'
    }
  },
  served: {
    key: 'served',
    label: 'Served',
    groups: { sort: () => 0, dir: 'desc' },
    items: {
      sort: toSingleSort(VALUE_GETTERS['served'], mode),
      dir: 'desc'
    }
  },
  viewed: {
    key: 'viewed',
    label: 'Viewed',
    groups: { sort: () => 0, dir: 'desc' },
    items: {
      sort: toSingleSort(VALUE_GETTERS['viewed'], mode),
      dir: 'desc'
    }
  },
  viewRatio: {
    key: 'viewRatio',
    label: '% Seen',
    groups: { sort: () => 0, dir: 'desc' },
    items: {
      sort: toSingleSort(VALUE_GETTERS['viewRatio'], mode),
      dir: 'desc'
    }
  },
  clicked: {
    key: 'clicked',
    label: 'Clicked',
    groups: { sort: () => 0, dir: 'desc' },
    items: {
      sort: toSingleSort(VALUE_GETTERS['clicked'], mode),
      dir: 'desc'
    }
  },
  clickRatio: {
    key: 'clickRatio',
    label: 'Page CTR',
    groups: { sort: () => 0, dir: 'desc' },
    items: {
      sort: toSingleSort(VALUE_GETTERS['clickRatio'], mode),
      dir: 'desc'
    }
  },
  rpm: {
    key: 'rpm',
    label: 'RPM',
    groups: { sort: () => 0, dir: 'desc' },
    items: {
      sort: toMultiSort(VALUE_GETTERS['rpm'], mode),
      dir: 'desc'
    }
  },
  epc: {
    key: 'epc',
    label: 'EPC',
    groups: { sort: () => 0, dir: 'desc' },
    items: {
      sort: toMultiSort(VALUE_GETTERS['epc'], mode),
      dir: 'desc'
    }
  },
  aov: {
    key: 'aov',
    label: 'AOV',
    groups: { sort: () => 0, dir: 'desc' },
    items: {
      sort: toMultiSort(VALUE_GETTERS['aov'], mode),
      dir: 'desc'
    }
  },
  sales: {
    key: 'sales',
    label: 'Earnings',
    groups: { sort: () => 0, dir: 'desc' },
    items: {
      sort: toMultiSort(VALUE_GETTERS['sales'], mode),
      dir: 'desc'
    }
  },
  salesCount: {
    key: 'salesCount',
    label: 'Items sold',
    groups: { sort: () => 0, dir: 'desc' },
    items: {
      sort: toMultiSort(VALUE_GETTERS['salesCount'], mode),
      dir: 'desc'
    }
  },
  orderCount: {
    key: 'orderCount',
    label: 'Orders',
    groups: { sort: () => 0, dir: 'desc' },
    items: {
      sort: toMultiSort(VALUE_GETTERS['orderCount'], mode),
      dir: 'desc'
    }
  },
  saleValue: {
    key: 'saleValue',
    label: 'Sales volume',
    groups: { sort: () => 0, dir: 'desc' },
    items: {
      sort: toMultiSort(VALUE_GETTERS['saleValue'], mode),
      dir: 'desc'
    }
  }
});

export const SORTERS: {
  [K in Mode]: Sorters<PageGroupSummary, IPageWithCountsAndTrendsAndSales>;
} = {
  'absolute-numbers': createSorters('absolute-numbers'),
  'absolute-growth': createSorters('absolute-growth'),
  'relative-growth': createSorters('relative-growth')
};

type OtherProps = {
  spaceId: string;
  compare: boolean;
  currency: CurrencyCode;
  ranks: { [href: string]: { cur: number; prev: number } };
  mode: Mode;
  tagsById: { [tagId: string]: IPostgresTags };
  selected: { [href: string]: boolean };
  setSelected: (nextSelected: { [href: string]: boolean }) => void;
  selectedTags: null | Set<string>;
  setSelectedTags: (nextSelectedTags: null | Set<string>) => void;
  hrefs: string[];
};

type Column = IColumn<IPageWithCountsAndTrendsAndSales, ColumnName, OtherProps>;

type HrefCellProps = Pick<IPageWithCountsAndTrendsAndSales, 'href' | 'tagIds'> &
  Pick<OtherProps, 'spaceId' | 'tagsById' | 'selectedTags' | 'setSelectedTags'>;

const HrefCell: React.FC<HrefCellProps> = ({
  tagIds,
  spaceId,
  href,
  tagsById,
  selectedTags,
  setSelectedTags
}) => {
  const pathName = getPathName(href);
  return (
    <>
      <Tooltip placement="top-start" title={pathName}>
        <Typography
          className={css((t) => ({
            fontSize: '14px',
            color: t.palette.primary.main,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          }))}
        >
          {pathName}
        </Typography>
      </Tooltip>
      <Typography
        className={css((t) => ({
          fontSize: '11px',
          fontWeight: 700,
          color: '#acacb9'
        }))}
      >
        {getDomainName(href)}
      </Typography>
      {!!tagIds.length && (
        <div style={{ display: 'inline-block', marginTop: '2px' }}>
          {compact(
            tagIds.map((tagId) => {
              const tag = tagsById[tagId];
              if (!tag) return null;
              let isSelected = selectedTags?.has(tagId) || false;
              const showTag = isSelected || !isFromAutomaticGroup(tag, spaceId);
              return showTag ? (
                <MiniTag
                  key={tagId}
                  label={tag.name}
                  isSelected={isSelected}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (isSelected) {
                      const withoutTag = new Set(selectedTags);
                      withoutTag.delete(tagId);
                      setSelectedTags(withoutTag);
                    } else {
                      setSelectedTags(new Set([tagId]));
                    }
                    return false;
                  }}
                />
              ) : null;
            })
          )}
        </div>
      )}
    </>
  );
};

export const COLUMNS: Column[] = [
  {
    key: 'selector',
    head: (o) => (o ? toSelector(o.hrefs, o) : 'Selection'),
    cell: (d, o) => toSelector([d.href], o),
    align: 'center',
    width: 50,
    flexGrow: 0
  },
  {
    key: 'rank',
    head: () => 'Rank',
    headInfo: () =>
      'How this page performed, according to the selected sorting column, compared to its performance in the previous timeframe.',
    cell: (d, o) => {
      const r = o.ranks[d.href];
      if (!r) {
        return null;
      }
      return <RankChip current={r.cur} previous={r.prev} />;
    },
    align: 'center',
    width: 70,
    flexGrow: 1
  },
  {
    key: 'href',
    head: () => 'Page URL',
    cell: (d, o) => (
      <HrefCell
        href={d.href}
        tagIds={d.tagIds}
        spaceId={o.spaceId}
        tagsById={o.tagsById}
        selectedTags={o.selectedTags}
        setSelectedTags={o.setSelectedTags}
      />
    ),
    sortable: true,
    defaultDirection: 'asc',
    align: 'left',
    width: 200,
    flexGrow: 8
  },
  {
    key: 'pageViews',
    head: () => 'Pageviews',
    headInfo: () => `
      How many times this page has been viewed in the selected timeframe. You'll only see pageviews for content which contains tracked affiliate links.
    `,
    cell: (d, o) => (
      <CountCell
        variant={o.mode}
        before={d.counts.pageViews.lastCount}
        after={d.counts.pageViews.count}
        compare={o.compare}
      />
    ),
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 100,
    flexGrow: 4
  },
  {
    key: 'served',
    head: () => 'Served',
    headInfo: () =>
      `How many affiliate links were served on this page to your visitors in the selected timeframe.`,
    cell: (d, o) => (
      <CountCell
        variant={o.mode}
        before={d.counts.served.lastCount}
        after={d.counts.served.count}
        compare={o.compare}
      />
    ),
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 100,
    flexGrow: 4
  },
  {
    key: 'viewed',
    head: () => 'Impressions',
    headInfo: () => `
      How many affiliate links were actually seen by visitors
      as a result of scrolling down the page.
    `,
    cell: (d, o) => (
      <CountCell
        variant={o.mode}
        before={d.counts.viewed.lastCount}
        after={d.counts.viewed.count}
        compare={o.compare}
      />
    ),
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 100,
    flexGrow: 4
  },
  {
    key: 'viewRatio',
    head: () => <span>%&nbsp;Seen</span>,
    headInfo: () => `
      Percentage of served affiliate links that were actually seen by visitors
      as a result of scrolling down the page.
    `,
    cell: (d, o) => (
      <CountCell
        variant={o.mode}
        before={getViewRatio(mapCounter(d.counts, (v) => v.lastCount))}
        after={getViewRatio(mapCounter(d.counts, (v) => v.count))}
        compare={o.compare}
        format="percent"
      />
    ),
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 100,
    flexGrow: 4
  },
  {
    key: 'clicked',
    head: () => 'Clicks',
    headInfo: () => `
      The number of times affiliate links on this page have been clicked
      during the given timeframe. Excludes duplicate clicks and bot clicks.
    `,
    cell: (d, o) => (
      <CountCell
        variant={o.mode}
        before={d.counts.clicked.lastCount}
        after={d.counts.clicked.count}
        compare={o.compare}
      />
    ),
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 100,
    flexGrow: 4
  },
  {
    key: 'clickRatio',
    head: () => 'Page CTR',
    headInfo: () =>
      `Page-level click-through rate. The number of clicks divided by the number of pageviews. A Page CTR of over 100% can indicate your visitors tend to click multiple links per page.`,

    cell: (d, o) => {
      const before = getCpm(mapCounter(d.counts, (v) => v.lastCount));
      const after = getCpm(mapCounter(d.counts, (v) => v.count));
      if (o.mode === 'absolute-numbers') {
        return (
          <BaseCountCell
            before={before}
            after={after}
            compare={o.compare}
            format="percent"
            digits={1}
          >
            <Ctr
              steps={[0, 0.05, 0.15, 0.2, 0.3, 0.4, 0.5]}
              rate={getCpm(mapCounter(d.counts, (v) => v.count))}
              format={(item) =>
                `${(Math.round(item * 10 * 100) / 10).toString()}%`
              }
            />
          </BaseCountCell>
        );
      }
      return (
        <CountCell
          variant={o.mode}
          before={before}
          after={after}
          compare={o.compare}
          format="percent"
        />
      );
    },
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 150,
    flexGrow: 4
  },
  {
    key: 'rpm',
    head: () => 'RPM',
    headInfo: () => `
      Revenue per one-thousand pageviews. If you drive 1,000 pageviews
      to this page, the amount of revenue to expect. Based on sales originating from clicks during this time period.
    `,
    cell: (d, o) => {
      const before =
        d.counts.pageViews.lastCount !== 0
          ? getRpm(d.counts.pageViews.lastCount, d.sales.lastCount)
          : 0;
      const after = getRpm(d.counts.pageViews.count, d.sales.count);

      if (!before && !after && o.mode === 'absolute-numbers') {
        return <Dash />;
      }

      return (
        <CountCell
          variant={o.mode}
          before={before}
          after={after}
          compare={o.compare}
          currency={o.currency}
        />
      );
    },
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 100,
    flexGrow: 4
  },
  {
    key: 'epc',
    head: () => 'EPC',
    headInfo: () => `
      Earnings per click. How much you earn on average for every click through on an affiliate link on this page.
    `,
    cell: (d, o) => {
      const before =
        d.counts.clicked.lastCount !== 0
          ? getEpc(d.counts.clicked.lastCount, d.sales.lastCount)
          : 0;
      const after = getEpc(d.counts.clicked.count, d.sales.count);

      if (!before && !after && o.mode === 'absolute-numbers') {
        return <Dash />;
      }

      return (
        <CountCell
          variant={o.mode}
          before={before}
          after={after}
          compare={o.compare}
          currency={o.currency}
        />
      );
    },
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 100,
    flexGrow: 4
  },
  {
    key: 'sales',
    head: () => 'Earnings',
    headInfo: () => `
      Commissions earned from clicks that occurred during this time period.
      Commissions earned during this time period from earlier clicks can be found on the Performance page.
    `,
    cell: (d, o) => {
      const before = d.sales.lastCount;
      const after = d.sales.count;
      if (!before && !after && o.mode === 'absolute-numbers') {
        return <Dash />;
      }
      return (
        <CountCell
          variant={o.mode}
          before={before}
          after={after}
          compare={o.compare}
          currency={o.currency}
        />
      );
    },

    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 100,
    flexGrow: 4
  },
  {
    key: 'orderCount',
    head: () => 'Orders',
    headInfo: () =>
      'How many net orders you drove on this page from Platforms that report Order IDs, considering Final and Pending orders, and subtracting Refunded orders. Used to calculate AOV.',
    cell: (d, o) => {
      const before = d.orderCount.lastCount;
      const after = d.orderCount.count;
      if (!before && !after && o.mode === 'absolute-numbers') {
        return <Dash />;
      }
      return (
        <CountCell
          variant={o.mode}
          before={before}
          after={after}
          compare={o.compare}
        />
      );
    },
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 100,
    flexGrow: 4
  },
  {
    key: 'aov',
    head: () => 'AOV',
    headInfo: () =>
      'Average order value (AOV). How much revenue you drove on this page, on average, for every order generated. Based on Final, Pending, and Refunded commissions. Not all platforms or advertisers report this figure.',
    cell: (d, o) => {
      const before = getAov(d.orderCount.lastCount, d.saleValue.lastCount);
      const after = getAov(d.orderCount.count, d.saleValue.count);
      if (!before && !after && o.mode === 'absolute-numbers') {
        return <Dash />;
      }
      return (
        <CountCell
          variant={o.mode}
          before={before}
          after={after}
          compare={o.compare}
          currency={o.currency}
        />
      );
    },
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 100,
    flexGrow: 4
  },
  {
    key: 'saleValue',
    head: () => 'Sales Volume',
    headInfo: () =>
      'How much revenue you drove for advertisers through this page during the selected time period, considering Final, Pending, and Refunded transactions. Not all platforms and advertisers report this figure.',
    cell: (d, o) => {
      const before = d.saleValue.lastCount;
      const after = d.saleValue.count;
      if (!before && !after && o.mode === 'absolute-numbers') {
        return <Dash />;
      }
      return (
        <CountCell
          variant={o.mode}
          before={before}
          after={after}
          compare={o.compare}
          currency={o.currency}
        />
      );
    },
    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 100,
    flexGrow: 4
  },
  {
    key: 'salesCount',
    head: () => 'Items sold', // needs tooltip
    headInfo: () =>
      `Quantity of items purchased. Not all platforms report product quantities.`,
    cell: (d, o) => {
      const before = d.salesCount.lastCount;
      const after = d.salesCount.count;
      if (!before && !after && o.mode === 'absolute-numbers') {
        return <Dash />;
      }
      return (
        <CountCell
          variant={o.mode}
          before={before}
          after={after}
          compare={o.compare}
        />
      );
    },

    sortable: true,
    defaultDirection: 'desc',
    align: 'right',
    width: 100,
    flexGrow: 4
  }
];

export const DEFAULT_COLUMNS: ColumnName[] = [
  'selector',
  'rank',
  'href',
  'pageViews',
  'clicked',
  'clickRatio',
  'rpm',
  'epc',
  'sales',
  'salesCount'
];

const getRanks = (
  pages: IPageWithCountsAndTrendsAndSales[],
  columnName: ColumnName,
  direction: SortDirection
): { [href: string]: { cur: number; prev: number } } => {
  const toRankDict = (
    ps: IPageWithCountsAndTrendsAndSales[],
    sortBy: ColumnName,
    sortOver: 'current' | 'prev'
  ) => {
    const res: { [href: string]: number } = {};
    let lastVal: any = null;
    let lastRank: number = 1;
    for (let i = 0; i < ps.length; i++) {
      const p = ps[i];
      const val = VALUE_GETTERS[sortBy][sortOver](p);
      const tied = val === lastVal;
      const rank = tied ? lastRank : i + 1;
      if (!tied) {
        lastVal = val;
        lastRank = rank;
      }
      res[ps[i].href] = rank;
    }
    return res;
  };

  const sortPages = (
    ps: IPageWithCountsAndTrendsAndSales[],
    sortBy: ColumnName,
    sortOver: 'current' | 'prev',
    dir: SortDirection
  ) => {
    return orderBy(ps, (p) => VALUE_GETTERS[sortBy][sortOver](p), dir);
  };

  const cName: ColumnName =
    columnName === 'clicked' ||
    columnName === 'viewed' ||
    columnName === 'viewRatio' ||
    columnName === 'pageViews' ||
    columnName === 'clickRatio' ||
    columnName === 'rpm' ||
    columnName === 'salesCount' ||
    columnName === 'sales'
      ? columnName
      : 'clicked';

  const prev = toRankDict(
    sortPages(pages, cName, 'prev', direction),
    cName,
    'prev'
  );
  const current = toRankDict(
    sortPages(pages, cName, 'current', direction),
    cName,
    'current'
  );

  // This is the case when we didn't have any previous data,
  // then everyone will be listed as having rank 1
  // When true, return the special value of 0, which will indicate
  // to the UI components that this rank is supposed to be skipped
  const wereAllTiedBefore = new Set(Object.values(prev)).size === 1;
  return Object.fromEntries(
    pages.map((p) => [
      p.href,
      {
        cur: current[p.href],
        prev: wereAllTiedBefore ? 0 : prev[p.href]
      }
    ])
  );
};

const ROW_TO_KEY = (p: IPageWithCountsAndTrendsAndSales) => p.href;

const HelpLink = styled(Link)`
  color: ${(p) => p.theme.palette.primary.main};
`;

export const PagesTable = ({
  pages,
  loading,
  sorter,
  sortDirection,
  onSort,
  currency,
  columns: columnNames,
  compare,
  rowToHref,
  selected,
  setSelected,
  selectedTags,
  setSelectedTags,
  mode,
  tagsById
}: {
  sorter: Sorter<PageGroupSummary, IPageWithCountsAndTrendsAndSales>;
  sortDirection: SortDirection | undefined;
  onSort: (c: ColumnName, dir: SortDirection | undefined) => void;
  pages: IPageWithCountsAndTrendsAndSales[] | void;
  loading: boolean;
  currency: CurrencyCode;
  columns: Set<string>;
  compare: boolean;
  rowToHref?: (d: IPageWithCountsAndTrendsAndSales) => string;
  selected: { [href: string]: boolean };
  setSelected: (nextSelected: { [href: string]: boolean }) => void;
  selectedTags: null | Set<string>;
  setSelectedTags: (nextSelectedTags: null | Set<string>) => void;
  mode: Mode;
  tagsById: Record<string, IPostgresTags>;
}) => {
  const { space } = useCurrentUser();
  const { ROUTES } = useRoutes();
  const [canApplyTags] = useHasCurrentUserRequiredScopes([
    'tags.add_or_remove'
  ]);
  const [canExportContent] = useHasCurrentUserRequiredScopes([
    'reports.content.export'
  ]);
  const showSelector = canApplyTags || canExportContent;

  const ranks = useMemo(
    () =>
      pages
        ? getRanks(
            pages,
            sorter.key as ColumnName,
            sortDirection || sorter.items.dir
          )
        : EMPTY_OBJ,
    [pages, sorter, sortDirection]
  );

  const columns = useMemo(
    () =>
      columnNames
        ? COLUMNS.filter((c) => {
            if (!showSelector && c.key === 'selector') {
              return false;
            }
            return columnNames.has(c.key);
          })
        : COLUMNS.filter((c) => (showSelector ? true : c.key !== 'selector')),
    [columnNames, showSelector]
  );

  const otherProps: OtherProps = useMemo(() => {
    return {
      spaceId: space.id,
      compare,
      currency,
      ranks,
      mode,
      tagsById,
      selectedTags,
      setSelectedTags,
      selected,
      setSelected,
      hrefs: (pages || []).map((r) => r.href)
    };
  }, [
    space.id,
    compare,
    currency,
    ranks,
    mode,
    tagsById,
    selectedTags,
    setSelectedTags,
    selected,
    setSelected,
    pages
  ]);

  if (!pages) {
    return (
      <Paper>
        <Loader height={800} />
      </Paper>
    );
  }

  if (!loading && pages.length === 0) {
    return (
      <EmptySearchState
        icon={Search}
        supportMessage="Still missing content? Ask Support"
        title="No content matched your search"
        message={
          <>
            Please double-check your search query, timeframe, and filter
            selection.
            <br />
            <br />
            If you've just published content, it will appear the following day
            after we've tracked the first impression of an affiliate link on
            that page.
            <br />
            <br />
            <strong>More from the docs:</strong>
            <br />
            <HelpLink
              to={ROUTES.docs.knowledgeBase.url(
                ARTICLES.content.whenPagesAppear
              )}
            >
              When do pages appear under Content? <ExternalLink size={14} />
            </HelpLink>
            <br />
            <HelpLink
              to={ROUTES.docs.knowledgeBase.url(ARTICLES.links.linkManagement)}
            >
              How to import affiliate links per page <ExternalLink size={14} />
            </HelpLink>
          </>
        }
        color={COLORS.blue.blue6}
        bgColor={COLORS.blue.blue2}
      />
    );
  }

  return (
    <RowsRenderer
      variant="contained"
      columns={columns}
      rows={pages}
      rowToKey={ROW_TO_KEY}
      sorter={sorter}
      sortDirection={sortDirection}
      renderHead={true}
      headProps={{
        sticky: true,
        offset: DEFAULT_OFFSET + DEFAULT_TOOLBAR_HEIGHT + 8
      }}
      onHeadClick={(c, dir) => onSort(c.key, dir)}
      chunkSize={30}
      rootMargin="400px"
      rowHeight={ROW_HEIGHTS.airy}
      rowToHref={rowToHref}
      otherProps={otherProps}
    />
  );
};
