import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import React from 'react';
import { LinkTruncated } from '../../../../../../components/LinkTruncated';
import { IProductSuggestionGroup } from '../../../../../../domainTypes/productScan';
import { styled } from '../../../../../../emotion';

export type DetailsDialogProps = {
  g: IProductSuggestionGroup | null;
  onClose: () => void;
};

const Image = styled('img')`
  display: block;
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
  margin-right: ${p => p.theme.spacing()}px;
`;

const ImageGrid = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

const Images = ({ srcs }: { srcs: string[] }) => (
  <ImageGrid>
    {srcs.map((src, i) => (
      <Image key={i} src={src} />
    ))}
  </ImageGrid>
);

export const DetailsDialog = ({ g, onClose }: DetailsDialogProps) => {
  return (
    <Dialog open={!!g} onClose={onClose} maxWidth="lg">
      {g ? (
        <>
          <DialogTitle>{g.names[0]}</DialogTitle>
          <DialogContent>
            <p>
              <LinkTruncated url={g.href} length={70} />
            </p>

            <strong>Found on</strong>
            <ul>
              {g.pages.map((p, i) => (
                <li key={i}>
                  <Typography color="textSecondary">{p.timesFound}x</Typography>{' '}
                  {p.url}
                </li>
              ))}
            </ul>

            {!!g.images.length && <Images srcs={g.images} />}
          </DialogContent>
        </>
      ) : (
        <div />
      )}
    </Dialog>
  );
};
