import { Button, Card, Typography } from '@material-ui/core';
import compareVersions from 'compare-versions';
import React, { useEffect, useState } from 'react';
import { ArrowRight, Check, Chrome, LogIn } from 'react-feather';
import { AlertBox } from '../../../../../../components/AlertBox';
import { ButtonWithPromise } from '../../../../../../components/ButtonWithPromise';
import { Loader } from '../../../../../../components/Loader';
import { IMessageStatus } from '../../../../../../domainTypes/chromeExtension';
import { COLORS } from '../../../../../../domainTypes/colors';
import { ISpace } from '../../../../../../domainTypes/space';
import { ICurrentUser } from '../../../../../../domainTypes/user';
import { styled } from '../../../../../../emotion';
import * as ENV from '../../../../../../env.json';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import { Centered } from '../../../../../../layout/Centered';
import { Section } from '../../../../../../layout/Section';
import {
  getExtension,
  Listener,
  loginExtension,
  useExtensionMetadata
} from '../../../../../../services/chromeExtension';
import { Cio } from '../../../../../../services/customerio';
import {
  useMixpanel,
  useTrackMixpanelView
} from '../../../../../../services/mixpanel';

type Props = {
  currentUser: ICurrentUser;
  install: boolean;
};

const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  text-align: center;
  margin: 0 auto;
  border-radius: 100%;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

const IconOuterWrapper = styled('div')`
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
`;

const useStatus = () => {
  const [status, setStatus] = useState<IMessageStatus['data']>({
    status: 'PENDING'
  });
  const extension = getExtension();
  const listener: Listener = (msg) => {
    if (msg.type === 'STATUS') {
      setStatus(msg.data);
    }
  };
  useEffect(() => {
    extension.addListener(listener);
    setTimeout(() => {
      setStatus((prev) =>
        prev.status === 'PENDING' ? { status: 'UNAVAILABLE' } : prev
      );
    }, 4000);
    if (status.status === 'PENDING') {
      extension.postMessage({ type: 'STATUS', data: { status: 'PENDING' } });
    }
    return () => extension.removeListener(listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(status);
  return status;
};

const HEIGHT = 400;

const Login = () => {
  return (
    <Centered>
      <div
        style={{ textAlign: 'center', maxWidth: '500px', margin: '24px auto' }}
      >
        <IconOuterWrapper>
          <IconWrapper
            style={{
              backgroundColor: COLORS.blue.blue1,
              color: COLORS.blue.blue6
            }}
          >
            <LogIn size={50} />
          </IconWrapper>
        </IconOuterWrapper>
        <Typography variant="h6" paragraph>
          <strong>Start using the Chrome extension</strong>
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Step 1. Pin the extension</strong>
        </Typography>
        <Typography
          variant="body1"
          component="p"
          paragraph
          color="textSecondary"
        >
          Long press the Extensions icon, navigate to the Affilimate logo, and
          pin it.
        </Typography>
        <img
          style={{
            borderRadius: '8px',
            maxWidth: '300px',
            margin: '0 auto',
            display: 'block'
          }}
          alt="Pin an extension in Chrome"
          src="https://s3.amazonaws.com/helpscout.net/docs/assets/60438310c6b4255ec17839e7/images/65c101b0fbf3330265919f82/file-5mBAodCGL4.png"
        />
        <br />
        <br />
        <Typography variant="body1" gutterBottom>
          <strong>Step 2. Log in to the extension</strong>
        </Typography>
        <Typography
          variant="body1"
          component="p"
          paragraph
          color="textSecondary"
        >
          You're almost finished! Use the button below to log in to Affilimate's
          Chrome extension.
        </Typography>
        <ButtonWithPromise
          variant="contained"
          color="primary"
          pending="Logging in..."
          onClick={loginExtension}
        >
          Click to log in &nbsp; <ArrowRight size={16} />
        </ButtonWithPromise>
      </div>
    </Centered>
  );
};

const Install = () => {
  return (
    <Centered height={HEIGHT}>
      <div style={{ textAlign: 'center', maxWidth: '500px', margin: '0 auto' }}>
        <IconOuterWrapper>
          <IconWrapper
            style={{
              backgroundColor: COLORS.blue.blue1,
              color: COLORS.blue.blue6
            }}
          >
            <Chrome size={50} />
          </IconWrapper>
        </IconOuterWrapper>
        <Typography variant="h6" gutterBottom>
          <strong>Install the Affilimate Chrome Extension</strong>
        </Typography>
        <Typography
          variant="body1"
          component="p"
          paragraph
          color="textSecondary"
        >
          Affilimate's Chrome extension helps you generate affiliate links
          seamlessly while you browse the web.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          href={`https://chrome.google.com/webstore/detail/${ENV.chromeExtension.extensionId}`}
        >
          Open Chrome Webstore to install
        </Button>
      </div>
    </Centered>
  );
};

const VersionCheck = ({ version }: { version: string }) => {
  const [metadata] = useExtensionMetadata();
  if (
    !metadata ||
    compareVersions.compare(version, metadata.data.requiredVersion, '>=')
  ) {
    return null;
  }

  return (
    <Section>
      <AlertBox variant="error">
        A new release of the extension is available. Please restart your browser
        to update automatically.
      </AlertBox>
    </Section>
  );
};

const Settings = ({
  space,
  userId,
  version
}: {
  space: ISpace;
  userId: string;
  version: string;
}) => {
  return (
    <Centered height={HEIGHT}>
      <div style={{ textAlign: 'center', maxWidth: '500px', margin: '0 auto' }}>
        <IconOuterWrapper>
          <IconWrapper
            style={{
              backgroundColor: COLORS.lime.lime2,
              color: COLORS.green.green6
            }}
          >
            <Check size={50} />
          </IconWrapper>
        </IconOuterWrapper>
        <Typography variant="h6" gutterBottom>
          <strong>Your Chrome extension is active</strong>
        </Typography>
        <Typography
          variant="caption"
          component="p"
          paragraph
          color="textSecondary"
        >
          Version {version}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          paragraph
          color="textSecondary"
        >
          You can now use the Chrome extension to generate affiliate links.
          <br />
          <br />
          Visit one of your advertisers' websites and click the Affilimate icon
          in your browser to get started.
        </Typography>
      </div>
    </Centered>
  );
};

export const ChromeExtension: React.FC<Props> = ({ currentUser, install }) => {
  const { id, space } = currentUser;
  const [finishingInstallation, setFinishingInstallation] = useState(install);
  const { enqueueSnackbar } = useSnackbar();
  const mixpanel = useMixpanel();

  useTrackMixpanelView('extension_settings_view');

  const finishInstallation = (success: boolean) => {
    // give background script some time to respond
    setTimeout(() => {
      setFinishingInstallation(false);
      if (success) {
        mixpanel.track('extension_install');
        Cio.track({ name: 'extention_install', data: { space_id: space.id } });

        enqueueSnackbar('Extension successfully installed!', {
          variant: 'success'
        });
      }
    }, 2000);
  };

  useEffect(() => {
    if (install) {
      loginExtension().then(
        () => finishInstallation(true),
        () => finishInstallation(false)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const status = useStatus();

  if (!finishingInstallation) {
    if (status.status === 'UNAVAILABLE') {
      return (
        <Card>
          <Install />
        </Card>
      );
    }
    if (status.status === 'LOGGED_OUT') {
      return (
        <Card>
          <Login />
        </Card>
      );
    }
    if (status.status === 'LOGGED_IN') {
      if (!status.auth || id !== status.auth.uid) {
        return (
          <Card>
            <Login />
          </Card>
        );
      }
      if (status.auth && id !== status.auth.uid) {
        console.log('Somone else is logged in', status.auth.uid);
        return (
          <Card>
            <Login />
          </Card>
        );
      }
      return (
        <Card>
          <VersionCheck version={status.version || '0.0.0'} />
          <Settings
            version={status.version || '0.0.0.0'}
            space={space}
            userId={id}
          />
        </Card>
      );
    }
  }

  return <Loader height={HEIGHT} />;
};
