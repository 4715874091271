import React, { useMemo } from 'react';
import { ChartCard } from '../../../../../../../components/Charts/ChartCard';
import {
  EarningsVerticalBarChartDataV2,
  EarningsVerticalBarChartV2
} from '../../../../../../../components/Charts/EarningsVerticalBarChartV2';
import {
  AnalyticsQuery,
  ISOTimeRange
} from '../../../../../../../domainTypes/analytics_v2';
import { CurrencyCode } from '../../../../../../../domainTypes/currency';
import { metricTitle } from '../../../../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../../../../services/analyticsV2/query';
import { getStableRandomColor } from '../../../../../../../services/color';
import { useMappedLoadingValue } from '../../../../../../../services/db';
import {
  AdvertiserGrouper,
  productGrouperToEnglishHeading,
  productNameFieldToEnglish
} from '../../../../../services/advertisers';

type OrderField =
  | 'order_count_net'
  | 'quantity_net'
  | 'gmv_sum_net'
  | 'commission_sum_net';

const DEFAULT_LIMIT = 11;

export const TopAdvertiserGrouperChartCardV2 = ({
  spaceId,
  commonQ,
  compareRange,
  groupedBy,
  orderedBy,
  currency,
  limit = DEFAULT_LIMIT
}: {
  spaceId: string;
  commonQ: Pick<AnalyticsQuery, 'range' | 'filters' | 'search'>;
  compareRange: ISOTimeRange | null;
  groupedBy: AdvertiserGrouper;
  orderedBy: OrderField;
  currency: CurrencyCode;
  limit?: number;
}) => {
  const q = useMemo<AnalyticsQuery>(() => {
    return {
      ...commonQ,
      compare: compareRange ? { range: compareRange } : undefined,
      groupBy: [groupedBy],
      orderBy: [{ field: orderedBy, direction: 'DESC' }],
      paginate: {
        limit,
        page: 1
      },
      select: [orderedBy]
    };
  }, [commonQ, compareRange, groupedBy, orderedBy, limit]);
  const [data, loading] = useMappedLoadingValue(
    useAnalyticsQueryV2(spaceId, q),
    ({ rows }) => {
      return rows.map<EarningsVerticalBarChartDataV2>((r) => {
        const rawLabel = r.group[groupedBy] || 'Unknown';
        const label = productNameFieldToEnglish(rawLabel, groupedBy);
        return {
          key: rawLabel,
          name: label,
          data: r,
          color: getStableRandomColor(rawLabel)
        };
      });
    }
  );

  return (
    <ChartCard
      heading={productGrouperToEnglishHeading(groupedBy)}
      subheading={`By ${metricTitle(orderedBy)}`}
      size="small"
      padding="dense"
      centered
    >
      <EarningsVerticalBarChartV2
        data={data}
        loading={loading}
        currency={currency}
        count={DEFAULT_LIMIT}
        metric={orderedBy}
        aspect={1.2}
      />
    </ChartCard>
  );
};
