import { Button, Card, Dialog, DialogContent } from '@material-ui/core';
import { orderBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Chip, ChipProps } from '../../../../../components/Chip';
import { Loader } from '../../../../../components/Loader';
import { SearchInput } from '../../../../../components/SearchInput';
import { TableToolbar } from '../../../../../components/Table';
import { IColumn } from '../../../../../components/Table/Column';
import { VirtualizedSortableTable } from '../../../../../components/Table/VirtualizedSortable';
import { Doc } from '../../../../../domainTypes/document';
import {
  IPageScreenshotQueueItem,
  QueueStatus
} from '../../../../../domainTypes/page';
import { getInfinityNumber, SortDirection } from '../../../../../hooks/useSort';
import { CanvasBar } from '../../../../../layout/Canvas';
import { useStringQueryParam } from '../../../../../routes';
import { getPathName } from '../../../../../services/pages';
import { formatDatePrecise } from '../../../../../services/time';
import { Json } from '../../../../components/Json';
import {
  abortItems,
  forceStartItem,
  requeueItems
} from '../../../../services/pageQueue';

type Props = {
  items: void | Doc<IPageScreenshotQueueItem>[];
  loading: boolean;
  height?: number;
  useFullUrl?: boolean;
};

const HEIGHT = 400;

type SortKey = 'url' | 'queuedAt' | 'lastRun' | 'status';

const getChipType = (status: QueueStatus): ChipProps['type'] => {
  switch (status) {
    case QueueStatus.DONE:
      return 'SUCCESS';
    case QueueStatus.RUNNING:
      return 'PENDING';
    case QueueStatus.ERROR:
      return 'ERROR';
    case QueueStatus.NO_SCRIPT:
      return 'ERROR';
    case QueueStatus.TIMEOUT:
      return 'ERROR';
    case QueueStatus.ABORTED:
      return 'ABORTED';
    default:
      return 'NONE';
  }
};

const getChipLabel = (status: QueueStatus) => {
  switch (status) {
    case QueueStatus.TIMEOUT:
      return 'TIMEOUT';
    case QueueStatus.NO_SCRIPT:
      return 'NO SCRIPT';
    case QueueStatus.ERROR:
      return 'ERROR';
    case QueueStatus.ABORTED:
      return 'ABORTED';
    case QueueStatus.QUEUED:
      return 'QUEUED';
    case QueueStatus.DONE:
      return 'DONE';
    case QueueStatus.RUNNING:
      return 'RUNNING';
    default:
      return '';
  }
};

const QueueStatusChip = ({ status }: { status: QueueStatus }) => (
  <Chip label={getChipLabel(status)} type={getChipType(status)} />
);

const COLUMNS: IColumn<
  Doc<IPageScreenshotQueueItem>,
  SortKey,
  { useFullUrl?: boolean }
>[] = [
  {
    key: 'url',
    head: () => 'URL',
    cell: (d, { useFullUrl }) => (
      <div>{useFullUrl ? d.data.url : getPathName(d.data.url)}</div>
    ),
    align: 'left',
    sortable: true,
    defaultDirection: 'asc',
    width: 120,
    flexGrow: 2
  },
  {
    key: 'queuedAt',
    head: () => 'queued at',
    cell: (d) => formatDatePrecise(d.data.queuedAt),
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 80,
    flexGrow: 1
  },
  {
    key: 'lastRun',
    head: () => 'last run at',
    cell: (d) => d.data.lastRun && formatDatePrecise(d.data.lastRun),
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 80,
    flexGrow: 1
  },
  {
    key: 'status',
    head: () => 'Status',
    cell: (d) => <QueueStatusChip status={d.data.status} />,
    align: 'right',
    sortable: true,
    defaultDirection: 'desc',
    width: 80,
    flexGrow: 1
  }
];

const STATUS_INDICES: QueueStatus[] = [
  QueueStatus.NO_SCRIPT,
  QueueStatus.TIMEOUT,
  QueueStatus.ERROR,
  QueueStatus.DONE,
  QueueStatus.ABORTED,
  QueueStatus.QUEUED,
  QueueStatus.RUNNING
];

const sortFn = (
  ds: Doc<IPageScreenshotQueueItem>[],
  sortBy: SortKey,
  direction: SortDirection
) => {
  return orderBy(
    ds,
    (d) => {
      if (sortBy === 'queuedAt') {
        return d.data.queuedAt;
      }
      if (sortBy === 'lastRun') {
        return d.data.lastRun || getInfinityNumber(direction);
      }
      if (sortBy === 'status') {
        return STATUS_INDICES.indexOf(d.data.status);
      }
    },
    direction
  );
};

export const PageQueueTable: React.FC<Props> = ({
  items,
  loading,
  height,
  useFullUrl
}) => {
  const h = height || HEIGHT;
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const selected = useMemo(
    () =>
      (selectedId && items && items.find((i) => i.id === selectedId)) || null,
    [selectedId, items]
  );
  const [q, setQ] = useStringQueryParam('q', '');
  const filteredItems = useMemo(() => {
    if (!items || !q) {
      return items;
    }
    return items.filter((x) => x.data.url.includes(q));
  }, [items, q]);
  return (
    <Card>
      {loading && <Loader height={h} />}
      {!loading && filteredItems && (
        <>
          <TableToolbar>
            <SearchInput
              value={q}
              onChange={setQ}
              width={300}
              placeholder="Search by page URL"
            />
          </TableToolbar>
          <VirtualizedSortableTable
            rows={filteredItems}
            columns={COLUMNS}
            cellProps={{ useFullUrl }}
            height={h}
            margin="normal"
            sortFn={sortFn}
            initialSortColumn={COLUMNS[3]}
            onRowClick={(d) => setSelectedId(d.id)}
          />
        </>
      )}

      <Dialog open={!!selected} onClose={() => setSelectedId(null)}>
        <DialogContent>
          <CanvasBar>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                if (selected) abortItems([selected]);
              }}
            >
              Abort item
            </Button>
            {selected && (
              <Button
                disabled={selected.data.running}
                variant="contained"
                color="default"
                size="small"
                onClick={() => forceStartItem(selected)}
              >
                Force start
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                if (selected) requeueItems([selected]);
              }}
            >
              Requeue item
            </Button>
          </CanvasBar>
          <Json data={selected} />
        </DialogContent>
      </Dialog>
    </Card>
  );
};
