import { Chip } from '@material-ui/core';
import React from 'react';
import {
  Check as FilterAppliedIcon,
  ChevronDown as ArrowDown
} from 'react-feather';
import { css } from '../../emotion';
import Typography from '@material-ui/core/Typography';
import { Loader } from '../Loader';

export type SelectorChipProps = {
  isApplied: boolean;
  label: React.ReactNode;
  appliedLabel: string;
  onDelete?: () => void;
  noArrow?: boolean;
  color?: 'primary' | 'default';
};

// NOTE: Add BaseSelectorChip that handles basic classes and is not concerned with 'isApplied' stuff.
export const SelectorChip = ({
  isApplied,
  label,
  appliedLabel,
  onDelete,
  noArrow = false,
  color = 'default'
}: SelectorChipProps) => {
  return (
    <Chip
      color={isApplied ? 'primary' : color}
      classes={{
        label: css(() => ({
          display: 'inline-flex',
          alignItems: 'center'
        })),
        root: css(() => ({
          border: '1px solid rgb(0 0 0 / 6%)',
          '&:hover': {
            borderColor: 'rgba(0, 0, 0, 0.87) !important'
          }
        })),
        colorPrimary: !isApplied
          ? undefined
          : css((t) => ({
              backgroundColor: '#efefef',
              color: t.palette.common.black
            })),
        deleteIconColorPrimary: css((t) => ({
          color: 'rgba(0, 0, 0, 0.26)',
          '&:hover': {
            color: t.palette.common.black
          }
        }))
      }}
      onDelete={isApplied && onDelete ? onDelete : undefined}
      label={
        isApplied ? (
          <>
            <FilterAppliedIcon
              size={18}
              className={css((t) => ({
                marginRight: t.spacing(1),
                color: t.palette.primary.main
              }))}
            />{' '}
            {appliedLabel}
          </>
        ) : (
          <>
            {label}
            {!noArrow && ' '}
            {!noArrow && (
              <ArrowDown
                size={18}
                className={css((t) => ({
                  marginLeft: t.spacing(1),
                  color: t.palette.common.black
                }))}
              />
            )}
          </>
        )
      }
    />
  );
};

export const DisabledSelectorChip = ({ label }: { label: string }) => (
  <Chip
    classes={{
      label: css((t) => ({
        display: 'inline-flex',
        alignItems: 'center',
        gap: t.spacing(1)
      })),
      root: css(() => ({
        border: '1px solid rgb(0 0 0 / 6%)'
      }))
    }}
    label={
      <>
        <Typography variant="caption" color="textSecondary">
          {label}
        </Typography>
        <Loader size={10} />
      </>
    }
  />
);
