import React from 'react';
import { ButtonBase } from '@material-ui/core';
import { Info } from 'react-feather';
import { Beacon } from '../services/beacon';
import { styled } from '../emotion';
import { Link } from 'react-router-dom';
import { useRoutes } from '../routes';

const StyledButtonBase = styled<typeof ButtonBase, { color: 'blue' | 'gray' }>(
  ButtonBase
)`
  color: ${(p) =>
    p.color === 'blue'
      ? p.theme.palette.primary.main
      : p.theme.palette.grey[600]} !important;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-family: Lato;

  svg {
    color: inherit;
    margin-right: 3px;
  }
`;

const LinkBase = styled<typeof Link, { color: 'blue' | 'gray' }>(Link)`
  color: ${(p) => p.theme.palette.primary.main};
  font-weight: 500;
  display: inline-block;
  font-family: Lato;
  font-size: 14px;

  svg {
    position: relative;
    top: 3px;
    color: inherit;
    margin-right: 3px;
  }
`;

export const HelpIcon: React.FC<{
  color?: 'gray' | 'blue';
  articleId?: string;
  onClick?: () => void;
  type?: 'sidebar' | 'modal' | undefined;
  style?: React.CSSProperties;
}> = ({ articleId, color, onClick, type, children, style }) => {
  const args: any[] = ['article', articleId];
  const css = style || {};
  const { ROUTES } = useRoutes();

  if (typeof type !== 'undefined') {
    args.push({ type });
  }

  const iconColor = color || 'gray';

  if (articleId) {
    return (
      <LinkBase color={iconColor} to={ROUTES.docs.knowledgeBase.url(articleId)}>
        <Info size={16} />
        {children}
      </LinkBase>
    );
  }

  return (
    <StyledButtonBase
      color={iconColor}
      style={css}
      onClick={() => {
        if (!onClick) {
          Beacon(...args);
        } else {
          onClick();
        }
      }}
    >
      <Info size={16} />
      {children}
    </StyledButtonBase>
  );
};
