import { IconButton, Paper } from '@material-ui/core';
import { pickBy, sortBy } from 'lodash';
import React, { createElement, useMemo, useRef } from 'react';
import { ArrowDown as IconNext, ArrowUp as IconPrev } from 'react-feather';
import { Dimensions } from '../../domainTypes/puppeteer';
import { styled } from '../../emotion';
import { useHotKeys } from '../../hooks/useHotkeys';
import { useOnVisbibilityChange } from '../../hooks/useIsVisible';
import { useScrollIntoView } from '../../hooks/useIsVisible/useScrollIntoView';
import { useStateObject } from '../../hooks/useStateObject';
import { LazyProgressiveImage } from '../LazyProgressiveImage';

export interface IAnnotationProps {
  selected: boolean;
  onSelect: (nextState: boolean) => void;
  visible: boolean;
}

export interface IAnnotation {
  component: React.ComponentType<IAnnotationProps>;
  x: number;
  y: number;
}

export interface IImageInfo {
  src: string;
  placeholder?: string;
  height?: number;
}

const applyScale = (d: Dimensions, scale: number) => {
  return scale === 1
    ? d
    : {
        width: d.width * scale,
        height: d.height * scale
      };
};

const applyScaleToAnnotations = (annotations: IAnnotation[], scale: number) => {
  return scale === 1
    ? annotations
    : annotations.map((a) => ({
        ...a,
        x: a.x * scale,
        y: a.y * scale
      }));
};

const Container = styled('div')`
  display: flex;
  align-items: flex-start;
  position: relative;
`;

const ControlsContainer = styled<'div', { offset: number }>('div')`
  position: sticky;
  top: ${(p) => p.offset}px;
  display: flex;
  flex-direction: column;
  padding-left: ${(p) => p.theme.spacing(1)}px;
`;

const ImageContainer = styled('div')`
  position: relative;
`;

const AnnotationContainerOuter = styled('div')`
  position: absolute;
`;

const AnnotationContainerInner = styled('div')`
  transform: translate(-50%, -50%);
`;

const Annotation: React.FC<{
  a: IAnnotation;
  scale: number;
  selected: boolean;
  onSelect: (nextState: boolean) => void;
  visible: boolean;
  onVisibilityChange: (isVisible: boolean) => void;
}> = React.memo(
  ({ a, scale, selected, onSelect, visible, onVisibilityChange }) => {
    const visRef = useOnVisbibilityChange<HTMLDivElement>(onVisibilityChange);
    const scrollRef = useScrollIntoView<HTMLDivElement>(
      selected,
      {
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      },
      {
        top: window.innerHeight / 4,
        bottom: window.innerHeight / 4
      }
    );
    return (
      <AnnotationContainerOuter
        ref={(r) => {
          if (r) {
            visRef.current = r;
            scrollRef.current = r;
          }
        }}
        style={{
          top: a.y,
          left: a.x,
          transform: `scale(${scale})`
        }}
      >
        <AnnotationContainerInner>
          {createElement(a.component, { selected, onSelect, visible })}
        </AnnotationContainerInner>
      </AnnotationContainerOuter>
    );
  },
  (a, b) =>
    a.a === b.a &&
    a.scale === b.scale &&
    a.selected === b.selected &&
    a.visible === b.visible
);

const hasAnyNext = (
  selectedI: number | null,
  allVisibleIs: number[],
  bottomI: number,
  maxI: number
) => {
  if (selectedI !== null) {
    return selectedI !== maxI;
  }
  return true;
};

const hasAnyPrev = (
  selectedI: number | null,
  allVisibleIs: number[],
  topI: number
) => {
  if (selectedI !== null) {
    return selectedI !== 0;
  }
  return true;
};

const getScrollControls = (
  container: HTMLDivElement,
  unsortedAnnotations: IAnnotation[],
  sortedAnnotations: IAnnotation[],
  visibility: { [key: string]: boolean },
  selectedI: number | null,
  setSelectedI: (i: number | null) => void
) => {
  const getNextIInView = () => {
    const center = window.innerHeight / 2;
    const containerRect = container.getBoundingClientRect();
    return sortedAnnotations.findIndex((a) => containerRect.top + a.y > center);
  };

  const getPrevIInView = () => {
    const center = window.innerHeight / 2;
    const containerRect = container.getBoundingClientRect();
    return sortedAnnotations.findIndex((a) => containerRect.top + a.y < center);
  };

  const toNext = () => {
    if (selectedI !== null) {
      setSelectedI(selectedI + 1);
      if (visibility[selectedI]) {
        setSelectedI(selectedI + 1);
      } else {
        const nextI = getNextIInView();
        if (nextI !== -1) {
          setSelectedI(nextI);
        }
      }
    } else {
      setSelectedI(0);
    }
  };
  const toPrev = () => {
    if (selectedI !== null) {
      if (visibility[selectedI]) {
        setSelectedI(selectedI - 1);
      } else {
        const prevI = getPrevIInView();
        if (prevI !== -1) {
          setSelectedI(prevI);
        }
      }
    } else {
      setSelectedI(unsortedAnnotations.length - 1);
    }
  };
  const visibleIs = Object.keys(pickBy(visibility, (t) => t)).map(Number);
  const topI = unsortedAnnotations.indexOf(sortedAnnotations[0]);
  const bottomI = unsortedAnnotations.indexOf(
    sortedAnnotations[sortedAnnotations.length - 1]
  );

  const hasPrev = hasAnyPrev(selectedI, visibleIs, topI);
  const hasNext = hasAnyNext(
    selectedI,
    visibleIs,
    bottomI,
    unsortedAnnotations.length - 1
  );

  return { toNext, hasNext, toPrev, hasPrev };
};

export const ImageWithAnnotations: React.FC<{
  images: IImageInfo[];
  alt?: string;
  dimensions: Dimensions;
  scale?: number;
  scaleAnnotations?: boolean;
  annotations: IAnnotation[];
  showControls?: boolean;
  selectedI: number | null;
  setSelectedI: (i: number | null) => void;

  additionalControls?: React.ReactNode;
  controlsOffset?: number;
}> = ({
  images,
  alt = '',
  dimensions: originalDimensions,
  scale = 1,
  scaleAnnotations = false,
  annotations: originalAnnotations,
  selectedI,
  setSelectedI,
  controlsOffset = 100,
  additionalControls,
  showControls = true
}) => {
  const containerRef = useRef<HTMLDivElement>(null!);
  const dimensions = applyScale(originalDimensions, scale);
  const annotations = useMemo(
    () => applyScaleToAnnotations(originalAnnotations, scale),
    [originalAnnotations, scale]
  );
  const sortedAnnotations = useMemo(() => sortBy(annotations, (a) => a.y), [
    annotations
  ]);

  const [visibility, mergeVisibility] = useStateObject<{
    [key: string]: boolean;
  }>({});

  const { hasPrev, toPrev, hasNext, toNext } = getScrollControls(
    containerRef.current,
    annotations,
    sortedAnnotations,
    visibility,
    selectedI,
    setSelectedI
  );

  useHotKeys(
    [
      {
        keys: 'shift+up',
        handler: () => hasPrev && toPrev()
      },
      {
        keys: 'shift+down',
        handler: () => hasNext && toNext()
      }
    ],
    [selectedI]
  );

  return (
    <Container>
      <Paper elevation={3}>
        <ImageContainer ref={containerRef} style={dimensions}>
          {images.map((d, i) => (
            <LazyProgressiveImage
              key={i}
              src={d.src}
              placeholder={d.placeholder}
              alt={alt}
              height={d.height ? d.height * scale : d.height}
              width={dimensions.width}
              options={{ rootMargin: '4000px' }}
            />
          ))}
          {annotations.map((a, i) => (
            <Annotation
              key={i}
              a={a}
              scale={scaleAnnotations ? scale : 1}
              selected={selectedI === i}
              onSelect={(nextState) => setSelectedI(nextState ? i : null)}
              visible={!!visibility[i]}
              onVisibilityChange={(isVisible) => {
                if (isVisible !== !!visibility[i]) {
                  mergeVisibility({ [i]: isVisible });
                }
              }}
            />
          ))}
        </ImageContainer>
      </Paper>
      {showControls && (
        <ControlsContainer offset={controlsOffset}>
          <IconButton
            color="primary"
            onClick={() => {
              return toPrev();
            }}
            disabled={!hasPrev}
          >
            <IconPrev size={18} />
          </IconButton>
          <IconButton
            color="primary"
            style={{
              marginBottom: '24px'
            }}
            onClick={() => {
              return toNext();
            }}
            disabled={!hasNext}
          >
            <IconNext size={18} />
          </IconButton>
          {additionalControls}
        </ControlsContainer>
      )}
    </Container>
  );
};
