import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { CustomerIoEmailManager } from '../../../../components/CustomerIoEmailManager';
import { Loader } from '../../../../components/Loader';
import { ScheduleFormSimple } from '../../../../components/Schedule/SimpleForm';
import { Doc } from '../../../../domainTypes/document';
import { Schedule } from '../../../../domainTypes/schedule';
import { styled } from '../../../../emotion';
import { CanvasBar } from '../../../../layout/Canvas';
import { LimitedWidth } from '../../../../layout/PageBody';
import { Section } from '../../../../layout/Section';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { useDailyEarningsReportSchedule } from '../../../../services/schedules/dailyEarnings';
import { updateOrCreateSchedule } from '../../../../services/schedules/helper';
import { SettingsLayout } from '../../SettingsLayout';

const ScheduleForms = styled('div')`
  > :not(:first-of-type) {
    margin-top: ${(p) => p.theme.spacing(4)}px;
  }
`;

export const PagesSettingsNewNotifications = () => {
  const user = useCurrentUser();
  const cioId = user.cioId;
  const {
    space: { id: spaceId }
  } = user;
  const [dailyEarningsScheduleDoc] = useDailyEarningsReportSchedule(spaceId);
  const [canChangeEmailSettings] = useHasCurrentUserRequiredScopes([
    'emails.daily_earnings_report.edit'
  ]);
  const onChange = (schedule: Doc<Schedule>) =>
    updateOrCreateSchedule(schedule.id, schedule.data);

  return (
    <SettingsLayout>
      <Helmet>
        <title>Notification Settings | Affilimate</title>
      </Helmet>
      <LimitedWidth width={800}>
        <Section>
          <CanvasBar>Workspace emails</CanvasBar>
          <Card>
            <CardContent>
              {!dailyEarningsScheduleDoc ? (
                <Loader height={200} />
              ) : (
                <ScheduleForms>
                  <Typography
                    variant="body1"
                    component="p"
                    color="textSecondary"
                  >
                    Choose which emails will send to all members of your
                    workspace on a synchronized schedule. Members will only
                    receive the email if their role includes access to both
                    content and transaction data.
                  </Typography>
                  <ScheduleFormSimple
                    label="Daily Earnings Report email"
                    doc={dailyEarningsScheduleDoc}
                    disabled={!canChangeEmailSettings}
                    onChange={onChange}
                    readOnlyType={true}
                  />
                </ScheduleForms>
              )}
            </CardContent>
          </Card>
        </Section>
        <Section>
          <CanvasBar>Informational emails</CanvasBar>
          <Card>
            <CardContent>
              <Typography
                variant="body1"
                component="p"
                color="textSecondary"
                style={{ marginBottom: '24px' }}
              >
                Choose how and how often you'd like to hear from us.
              </Typography>
              <CustomerIoEmailManager cioId={cioId} />
            </CardContent>
          </Card>
        </Section>
      </LimitedWidth>
    </SettingsLayout>
  );
};
