import React from 'react';
import { FlexContainer } from '../../../../../../layout/Flex';
import Typography from '@material-ui/core/Typography';
import { utmParameterTitle, UtmParameterType } from '../service/utm';
import { SiteSelector } from '../../../../../../components/analytics_v2/SiteSelector';
import { TimeframePicker } from '../../../../../../components/analytics_v2/Timeframe';

interface TopBarProps {
  parameter: UtmParameterType;
}

export const UtmTopBar: React.FC<TopBarProps> = ({ parameter }) => {
  return (
    <FlexContainer marginBottom={1} justifyContent="space-between">
      <FlexContainer>
        <Typography
          variant="h6"
          color="textPrimary"
          style={{ fontWeight: 'bold' }}
        >
          {utmParameterTitle(parameter)}
        </Typography>
        <SiteSelector />
      </FlexContainer>
      <TimeframePicker />
    </FlexContainer>
  );
};
