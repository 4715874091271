import React from 'react';
import { Sorter, Sorters } from '../../GroupableList';
import { SingleSelector, SingleSelectorChip } from '../../SingleSelector';

export const SortSelector = <S extends object, T extends object>({
  value,
  onChange,
  defaultValue,
  sorters
}: {
  value: Sorter<S, T> | null;
  onChange: (nextValue: Sorter<S, T> | null) => void;
  defaultValue: Sorter<S, T>;
  sorters: Sorters<S, T>;
}) => {
  const allSorters = Object.values(sorters);
  const ss =
    allSorters.indexOf(defaultValue) === -1
      ? [defaultValue, ...allSorters]
      : allSorters;
  const activeValue = value || defaultValue;
  return (
    <SingleSelector
      value={value ? value.key : null}
      onChange={(key) =>
        onChange(key === defaultValue.key ? null : sorters[key])
      }
      legend="Sort by"
      options={ss.map((g) => ({
        value: g.key,
        label: g.label,
        searchValue: g.label
      }))}
    >
      <SingleSelectorChip
        isApplied={!!value}
        label={`Sort by ${activeValue.label}`}
        appliedLabel={`Sort by ${activeValue.label}`}
        onDelete={() => onChange(null)}
      />
    </SingleSelector>
  );
};
