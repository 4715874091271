import { Typography, Button } from '@material-ui/core';
import { styled } from '../../../../emotion';
import React from 'react';
import { useCurrentUser } from '../../../../services/currentUser';
import { AlertTriangle } from 'react-feather/dist';
import { Link } from 'react-router-dom';
import { useRoutes } from '../../../../routes';

const MissingAlert = styled('span')`
  position: absolute;
  top: 24px;
  right: 12px;
  background-color: #ffd666;
  color: #613400;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  font-weight: 700;
  padding: ${(p) => p.theme.spacing(1)}px ${(p) => p.theme.spacing(2)}px;
  display: flex;
  align-items: center;

  span {
    margin-left: ${(p) => p.theme.spacing(1)}px;
    display: inline-block;
  }
`;

export const SetupIncomplete = () => (
  <div style={{ textAlign: 'center', maxWidth: '500px', margin: '0 auto' }}>
    <div style={{ position: 'relative' }}>
      <MissingAlert>
        <AlertTriangle size={18} /> <span>Setup incomplete</span>
      </MissingAlert>
      <img
        src="/images/empty-states/content.png"
        alt="Empty Content Report"
        style={{ display: 'inline-block', maxWidth: '80%' }}
      />
    </div>
    <Typography
      variant="h6"
      component="p"
      style={{
        fontWeight: 700,
        maxWidth: '390px',
        margin: '0 auto 24px'
      }}
    >
      Without the tracking snippet, your Content Analytics will stay empty
    </Typography>
    <Typography variant="body1" component="p" paragraph>
      You'll need to install the Affilimate tracking snippet before we can track
      clicks, views, record heatmaps, or do affiliate revenue attribution.
    </Typography>
  </div>
);

export const EmptyState = () => {
  const currentUser = useCurrentUser();
  const { ROUTES } = useRoutes();
  const verifiedSites = currentUser.space.domains.find((d) => d.verified);

  if (!verifiedSites) {
    return (
      <div style={{ marginTop: '48px', textAlign: 'center' }}>
        <SetupIncomplete />
        <Link to={ROUTES.dashboard.overview.url()}>
          <Button variant="contained" color="primary" size="large">
            Finish tech setup
          </Button>
        </Link>
      </div>
    );
  }

  return (
    <div
      style={{
        width: '80%',
        maxWidth: '500px',
        margin: '0 auto',
        textAlign: 'center',
        position: 'relative'
      }}
    >
      <img
        src="/images/empty-states/content.png"
        width="100%"
        style={{ marginBottom: '16px', marginTop: '96px' }}
        alt="Empty Content Report"
      />
      <Typography variant="h5" component="p" paragraph>
        <strong>Your content analytics are being prepared</strong>
      </Typography>
      <Typography variant="body1" component="p">
        Once you've verified your website, your first set of content analytics
        will appear here within 24 hours.
      </Typography>
    </div>
  );
};
