import React from 'react';
import { TooltipPayload, TooltipProps } from 'recharts';
import { timeLabelFormatter } from '../../../../components/analytics_v2/Chart/service/formatters';
import {
  TooltipHeading,
  TooltipPaper,
  TooltipRow
} from '../../../../components/Charts/CustomTooltip';
import Typography from '@material-ui/core/Typography';
import {
  formatMetric,
  Metric,
  metricTitle
} from '../../../../services/analyticsV2/metrics';
import { WithShape } from '../../../../components/Charts/Util';
import { COLOR_UNKNOWN } from '../../../../services/color';
import { AnalyticsInterval } from '../../../../domainTypes/analytics_v2';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { Target } from 'react-feather';
import { css } from '../../../../emotion';

const All: React.FC<{
  payload: TooltipPayload;
  metric: Metric;
  currency: CurrencyCode;
}> = ({ payload: { color, value }, metric, currency }) => {
  if (value === 0) {
    return null;
  }
  return (
    <TooltipRow>
      <WithShape color={color ?? COLOR_UNKNOWN}>All</WithShape>
      <span>{formatMetric(value as number, metric, currency)}</span>
    </TooltipRow>
  );
};

const GoalLabel = () => (
  <div
    className={css((t) => ({
      display: 'grid',
      gridTemplateColumns: 'min-content 1fr',
      gridColumnGap: t.spacing(1),
      alignItems: 'center'
    }))}
  >
    <Target size={12} />
    <strong>Goal</strong>
  </div>
);

type CampaignTooltipProps = TooltipProps & {
  payload: NonNullable<TooltipProps['payload']>;
  metric: Metric;
  interval: AnalyticsInterval;
  context: {
    startsInCurrentYear: boolean;
    currency: CurrencyCode;
  };
  split: 'pk' | 'none';
  goal: number;
};

export const CampaignTooltip: React.FC<CampaignTooltipProps> = ({
  label,
  payload,
  metric,
  context,
  interval,
  split,
  goal
}) => {
  const formatLabel = timeLabelFormatter(
    context.startsInCurrentYear,
    interval.unit
  );
  const labelStr = formatLabel(label as string);

  return (
    <TooltipPaper>
      <TooltipHeading>
        <Typography variant="body1">{labelStr}</Typography>
      </TooltipHeading>
      <strong>{metricTitle(metric)}</strong>
      {split === 'none' ? (
        <All payload={payload[0]} metric={metric} currency={context.currency} />
      ) : (
        payload
          .filter((p) => p.value > 0)
          .map(({ color, name, value }) => {
            return (
              <TooltipRow>
                <WithShape color={color ?? COLOR_UNKNOWN}>{name}</WithShape>
                <span>
                  {formatMetric(value as number, metric, context.currency)}
                </span>
              </TooltipRow>
            );
          })
      )}
      <TooltipRow>
        <GoalLabel />
        <span>{formatMetric(goal, metric, context.currency)}</span>
      </TooltipRow>
    </TooltipPaper>
  );
};
