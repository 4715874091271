import { Typography } from '@material-ui/core';
import { truncate } from 'lodash';
import React, { useMemo } from 'react';
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';
import { AnalyticsResponseRowWithComparison } from '../../../domainTypes/analytics_v2';
import { CurrencyCode } from '../../../domainTypes/currency';
import { formatMetric, Metric } from '../../../services/analyticsV2/metrics';
import { Loader } from '../../Loader';
import { CustomTooltip } from '../CustomTooltip';
export type EarningsVerticalBarChartDataV2 = {
  key: string;
  name: string;
  data: AnalyticsResponseRowWithComparison;
  color: string;
};

type Props = {
  data: EarningsVerticalBarChartDataV2[] | void;
  loading: boolean;
  currency: CurrencyCode;
  count: number;
  // animate?: boolean;
  height?: number;
  aspect?: number;
  metric: Metric;
};

const HEIGHT = 390;

export const EarningsVerticalBarChartV2 = React.memo(
  ({
    data,
    loading,
    currency,
    height = HEIGHT,
    aspect = 1.5,
    metric
  }: Props) => {
    const barSize = useMemo(() => {
      const size = Math.round(200 / (data || []).length);
      return Math.min(size, 50);
    }, [data]);

    if (loading || !data) {
      return <Loader height={height} />;
    }

    const getVal = (x: EarningsVerticalBarChartDataV2) => {
      return x.data.data[metric]?.curr || 0;
    };

    const formatter = (x: number) => {
      return formatMetric(x, metric, currency);
    };

    return (
      <ResponsiveContainer width="99%" height="auto" aspect={aspect}>
        <BarChart
          width={150}
          height={40}
          data={data}
          layout="vertical"
          barCategoryGap={1}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 50
          }}
        >
          <Bar
            dataKey={(d) => getVal(d)}
            label={false}
            barSize={barSize}
            background={false}
          >
            {(data || []).map((v) => (
              <Cell key={`cell-${v.key}`} fill={v.color} />
            ))}
          </Bar>
          <YAxis
            type="category"
            tick={{
              fill: '#9b9b9b',
              fontSize: 14
            }}
            width={180}
            dataKey="name"
            tickFormatter={(name) => truncate(name, { length: 25 })}
          />
          <XAxis
            type="number"
            stroke="none"
            tick={{
              fill: '#9b9b9b',
              fontSize: 12
            }}
            tickFormatter={(value: number) => formatter(value)}
          />
          <Tooltip
            cursor={false}
            labelFormatter={(label: TooltipProps['label']) => (
              <Typography
                variant="body1"
                component="span"
                style={{ padding: 0, margin: 0 }}
              >
                {label}
              </Typography>
            )}
            content={<CustomTooltip size="small" />}
            formatter={(value) => formatter(value as number)}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
);
