import styled from '../../emotion/styled';

export const Code = styled('code')`
  display: inline-block;
  border-radius: 3px;
  margin: 12px auto;
  padding: 12px 24px;
  background-color: white;
  color: #f50057;
`;
