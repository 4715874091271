import { Typography } from '@material-ui/core';
import React from 'react';
import ReactDropzone, { DropzoneProps } from 'react-dropzone';
import { UploadCloud as IconUpload } from 'react-feather';
import { styled } from '../../emotion';

type Props = {
  label?: string;
  note?: string;
  onDrop: DropzoneProps['onDrop'];
  height?: React.CSSProperties['height'];
};

// const Container = styled('div')`
//   width: 100%;
//   height: 200px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border: 2px dashed ${(p) => p.theme.palette.primary.main};
//   background-color: #e6f7ff;
//   border-radius: ${(p) => p.theme.shape.borderRadius}px;
//   color: ${(p) => p.theme.palette.primary.main};
// `;
const Container = styled('div')<{
  error?: boolean;
  active?: boolean;
  height?: React.CSSProperties['height'];
}>((p) => {
  const strokeColor = p.active
    ? p.theme.custom.colors.success.dark
    : p.error
    ? p.theme.custom.colors.error.dark
    : p.theme.palette.grey[400];
  const strokeStyle = p.active ? 'dashed' : 'solid';
  const fgColor = p.active
    ? p.theme.custom.colors.success.main
    : p.error
    ? p.theme.custom.colors.success.main
    : p.theme.palette.grey[500];
  const bgColor = p.active
    ? p.theme.custom.colors.success.light
    : p.error
    ? p.theme.custom.colors.error.light
    : p.theme.palette.grey[100];
  return {
    width: '100%',
    height: p.height || '200px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px ${strokeStyle} ${strokeColor}`,
    backgroundColor: bgColor,
    borderRadius: p.theme.shape.borderRadius,
    color: fgColor,
    gap: p.theme.spacing(),
    padding: p.theme.spacing(2)
  };
});

const DropzoneLabel = styled(Typography)`
  display: block;
  text-align: center;
  font-weight: 700 !important;
`;

const DropzoneNote = styled(Typography)`
  margin: 12px auto !important;
  display: block;
  text-align: center;
  font-weight: 500 !important;
  max-width: 300px;
  font-size: 13px !important;
`;

export const Dropzone: React.FC<Props> = ({
  label = <>Drag and drop your files here, or click to select them</>,
  note = '',
  onDrop,
  height
}) => {
  return (
    <ReactDropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => (
        <Container {...getRootProps({ refKey: 'ref' })} height={height}>
          <input {...getInputProps()} />
          <DropzoneLabel>
            <IconUpload size={36} />
            <br />
            {label}
            {note && <DropzoneNote>{note}</DropzoneNote>}
          </DropzoneLabel>
        </Container>
      )}
    </ReactDropzone>
  );
};
