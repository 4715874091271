import React from 'react';
import { SaleType } from '../../../../domainTypes/performance';
import { styled } from '../../../../emotion';
import {
  Award,
  HelpCircle,
  Navigation,
  ShoppingBag,
  User
} from 'react-feather';

const TransactionTypeWrapper = styled('span')`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled('span')`
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 30px;

  svg {
    margin-right: ${(p) => p.theme.spacing(0.6)}px;
  }
`;

const TRANSACTION_TYPE_CONFIG: Record<
  SaleType,
  {
    icon: React.ReactNode;
    color: string;
    label: string;
  }
> = {
  bonus: {
    icon: <Award size={16} />,
    color: '#333',
    label: 'Bonus'
  },
  cpa: {
    icon: <ShoppingBag size={14} />,
    color: '#333',
    label: 'CPA'
  },
  cpc: {
    icon: <Navigation size={14} />,
    color: '#333',
    label: 'CPC'
  },
  cpl: {
    icon: <User size={16} />,
    color: '#333',
    label: 'CPL'
  },
  unknown: {
    icon: <HelpCircle size={16} />,
    color: '#333',
    label: 'Not provided'
  }
};

export const TransactionType = ({ saleType }: { saleType: SaleType }) => {
  const config = TRANSACTION_TYPE_CONFIG[saleType];

  return (
    <TransactionTypeWrapper>
      <IconWrapper
        style={{
          color: config.color
        }}
      >
        {config.icon}
      </IconWrapper>{' '}
      {config.label}
    </TransactionTypeWrapper>
  );
};
