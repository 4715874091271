import { Typography } from '@material-ui/core';
import { Centered } from '../../../../../../layout/Centered';
import React from 'react';
import { styled } from '../../../../../../emotion';
import { Link } from 'react-router-dom';
import { useRoutes } from '../../../../../../routes';

const StyledLink = styled(Link)`
  color: ${(p) => p.theme.palette.primary.main};
`;

interface TagManagerPromptProps {
  height: number;
}

export const TagManagerPrompt = ({ height }: TagManagerPromptProps) => {
  const { ROUTES } = useRoutes();
  return (
    <>
      <Centered height={height}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="body2" component="p" paragraph>
            Create and apply your first tags to Content to see their performance
            here.
          </Typography>
          <StyledLink to={ROUTES.content.tags.manage.url()}>
            Create your tags
          </StyledLink>
        </div>
      </Centered>
    </>
  );
};
