import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { Info } from 'react-feather';
import { DAY_FORMAT, Timeframe } from '../../domainTypes/analytics';
import { styled } from '../../emotion';

const DatepickerContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(p) => p.theme.palette.text.hint};
  width: 650px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 8px;
`;

const TimeframeLabel = styled('div')`
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.common.white};
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
  text-transform: uppercase;
  padding: ${(p) => p.theme.spacing(2)}px ${(p) => p.theme.spacing(3)}px 0;
`;

export const CustomDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  value: Timeframe;
  onSelect: (tf: Timeframe) => void;
}> = ({ open, onClose, value, onSelect }) => {
  const [tf, setTf] = useState(value);
  useEffect(() => {
    if (open) {
      setTf(value);
    }
  }, [open, value]);
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Select a custom timeframe</DialogTitle>
      <DialogContent style={{ width: '800px', margin: '0 auto' }}>
        <DatepickerContainer>
          <TimeframeLabel>From</TimeframeLabel>
          <TimeframeLabel>
            Until &ndash; exclusive{' '}
            <Tooltip
              title="The second date in your custom timeframe is exclusive. For example, to report on data from Jan 1-31, you would select the timeframe Jan 1-Feb 1."
              placement="top"
            >
              <span
                style={{
                  display: 'inline-block',
                  position: 'relative',
                  top: '2px',
                  left: '2px'
                }}
              >
                <Info size={14} />
              </span>
            </Tooltip>
          </TimeframeLabel>
          <DatePicker
            autoOk
            variant="static"
            openTo="date"
            value={tf.start}
            format={DAY_FORMAT}
            clearable={false}
            onChange={(m) =>
              setTf((p) => ({ ...p, start: m!.format(DAY_FORMAT) }))
            }
          />

          <DatePicker
            autoOk
            variant="static"
            openTo="date"
            value={tf.end}
            format={DAY_FORMAT}
            clearable={false}
            onChange={(m) =>
              setTf((p) => ({ ...p, end: m!.format(DAY_FORMAT) }))
            }
          />
        </DatepickerContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSelect(tf)}
        >
          Apply timeframe
        </Button>
      </DialogActions>
    </Dialog>
  );
};
