import { styled } from '../../../../emotion';
import {
  Button,
  ButtonBase,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import { IPartner } from '../../../../domainTypes/partners';
import { useReportHandlers } from '../../services/handlers';
import { groupBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { getKnownPartnerForKey } from '../../../../services/partner';
import { ArrowRight, Search } from 'react-feather';
import { PartnerLogo } from '../../../../components/PartnerLogo';
import { Beacon } from '../../../../services/beacon';
import { capitalize } from 'lodash/fp';

const SupportButton = styled(Button)`
  font-size: 16px;
`;

const ChipColumns = styled('div')`
  & > div {
    margin-right: ${(p) => p.theme.spacing(1)}px;
    margin-bottom: ${(p) => p.theme.spacing(1)}px;
  }
`;

const PartnerChip = styled<typeof ButtonBase, { selected: boolean }>(
  ButtonBase
)`
  background-color: ${(p) =>
    p.selected
      ? p.theme.palette.primary.main
      : p.theme.palette.grey[300]} !important;
  color: ${(p) =>
    p.selected
      ? p.theme.palette.common.white
      : p.theme.palette.grey[900]} !important;
  padding: 4px 8px 4px 4px !important;
  border-radius: 30px !important;
  margin-left: 2px !important;
  margin-bottom: 5px !important;
  &:hover {
    transition: background-color 0.2s;
    background-color: ${(p) =>
      p.selected
        ? p.theme.palette.primary.dark
        : p.theme.palette.grey[400]} !important;
  }
`;

export const PartnerSelector = ({
  selectedPartner,
  setSelectedPartner
}: {
  selectedPartner: IPartner['key'] | null;
  setSelectedPartner: (next: IPartner['key'] | null) => void;
}) => {
  const reportHandlers = useReportHandlers();
  const reportGroups = groupBy(reportHandlers, (handler) => handler.partnerKey);
  const [search, setSearch] = useState('');
  const partners = useMemo(() => {
    const allPartners = Object.keys(reportGroups).map(getKnownPartnerForKey);
    if (search === '') {
      return allPartners;
    }
    return allPartners.filter(
      (p) => p?.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  }, [search, reportGroups]);

  return (
    <>
      <div>
        <TextField
          type="search"
          variant="outlined"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          fullWidth
          placeholder="Search integrations"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search size={18} />
              </InputAdornment>
            )
          }}
        />
        <br />
        <br />
        <ChipColumns>
          {partners.map((partner) => {
            if (!partner) {
              return null;
            }
            return (
              <PartnerChip
                key={partner.key}
                selected={selectedPartner === partner.key}
                onClick={() => setSelectedPartner(partner.key)}
              >
                <PartnerLogo partner={partner} chipStyle />
                {partner.name}
              </PartnerChip>
            );
          })}
        </ChipColumns>
        {partners.length === 0 && (
          <div style={{ margin: '24px auto' }}>
            <Typography
              variant="body1"
              component="p"
              gutterBottom
              style={{
                color: '#DDD',
                fontWeight: 'bold',
                textAlign: 'center'
              }}
            >
              No results for "{search}"
            </Typography>
            <Typography
              variant="body2"
              component="p"
              style={{ color: '#AAA', textAlign: 'center' }}
            >
              Find out if this integration is on our roadmap, or create a new
              request. New integrations are added monthly.
              <br />
              <br />
              <SupportButton
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  Beacon('open');
                  Beacon('navigate', '/ask/message/');
                  Beacon('prefill', {
                    subject: `Can you integrate with ${capitalize(search)}?`,
                    text: `Please share more details about your integration request: `
                  });
                }}
              >
                Request an integration
                <ArrowRight size={14} style={{ marginLeft: '3px' }} />
              </SupportButton>
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};
