import {
  Button,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import React from 'react';
import { X } from 'react-feather';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../services/db';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import { DeleteAllReportsButton } from '../../components/ReportDeleteButton';
import { ReportsTable } from '../../components/ReportsTable';
import { getReportsQuery, toReportDoc } from '../../services/report';

const ReportsList = ({ integrationId }: { integrationId: string }) => {
  const { space } = useCurrentUser();
  const currency = useSpaceCurrency();
  const [reports, loading] = useMappedLoadingValue(
    useCollectionOnce(
      getReportsQuery(space.id)
        .where('integrationId', '==', integrationId)
        .orderBy('createdAt', 'desc')
        .limit(15)
    ),
    (s) => s.docs.map(toReportDoc)
  );

  return (
    <ReportsTable
      tz={space.config.tz || 'UTC'}
      currency={currency}
      variant="condensed"
      reports={reports}
      loading={loading}
      height={300}
    />
  );
};

export const ImportHistoryDialog = ({
  integrationId,
  onClose
}: {
  integrationId: string;
  onClose: () => void;
}) => {
  const [canDeleteReports] = useHasCurrentUserRequiredScopes([
    'integrations.delete_report'
  ]);

  return (
    <div>
      <DialogTitle>Import history</DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          color="textSecondary"
          component="p"
          style={{ marginBottom: '24px' }}
        >
          Each time a batch of transactions is pulled from this connection, the
          result is stored in a report.
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          component="p"
          style={{ marginBottom: '24px' }}
        >
          If you've imported something by mistake, you can use the{' '}
          <X size={16} style={{ position: 'relative', top: '4px' }} /> icon to
          delete the transactions associated with that import.
        </Typography>
        <div style={{ minWidth: '750px' }}>
          <ReportsList integrationId={integrationId} />
        </div>
        <div
          style={{
            padding: '24px 0',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {canDeleteReports ? (
            <DeleteAllReportsButton integrationId={integrationId} />
          ) : (
            <div />
          )}
          <Button onClick={onClose}>Done</Button>
        </div>
      </DialogContent>
    </div>
  );
};
