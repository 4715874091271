import { Paper } from '@material-ui/core';
import React from 'react';
import { styled } from '../../emotion';
import { TooltipPaper } from '../Charts/CustomTooltip';

type Props = {
  variant?: 'normal' | 'chart';
  widthMultiplier?: number;
};

const Wrapper = styled('div')<{
  widthMultiplier?: number;
}>`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: ${(p) => p.theme.spacing()}px;
  z-index: 5;
  ${(p) =>
    p.widthMultiplier ? `width: ${p.theme.spacing(p.widthMultiplier)}px;` : ''}
`;

export const Popover: React.FC<Props> = ({
  children,
  widthMultiplier,
  variant = 'normal'
}) => {
  return (
    <Wrapper widthMultiplier={widthMultiplier}>
      {variant === 'normal' && <Paper elevation={10}>{children}</Paper>}
      {variant === 'chart' && <TooltipPaper>{children}</TooltipPaper>}
    </Wrapper>
  );
};
