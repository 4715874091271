import { trim } from 'lodash';
import moment from 'moment-timezone';
import { CurrencyCode } from '../../../../../../domainTypes/currency';
import {
  getTrackingId,
  ISale,
  SaleStatus
} from '../../../../../../domainTypes/performance';
import { getKnownPartnerForKeyUnsafe } from '../../../../../../services/partner';
import { fromMoment, now, tsIsAfter } from '../../../../../../services/time';
import { toCents, toPercent } from '../../helpers';
import { IFileReportHandler } from '../../types';

export const RENTALCARS: IFileReportHandler<string> = {
  type: 'CSV',
  partnerKey: 'rentalcarscom',
  parser: {
    name: 'Bookings report',
    csvHeaders:
      'Reservation,Affiliate,"Booking Date","Pick Up Date","Drop Off Date","Rental Duration","Lead Time","Booking Status","Booking Type",Loyalty,Supplier,"Source Market","Pick Up Country","Pick Up Location","Drop Off Country","Drop Off Location",Language,"Advertising Platform","Advertising Campaign","Payment Currency (PC)","Car Price (PC)","Affiliate Currency (AC)","Car Price (AC)","Car Profit (AC)","Car Commission (AC)"',
    parseOptions: { quoteChar: '"' },
    matches: (text, expectedHeaders) => text.indexOf(expectedHeaders) === 0,
    processRows: async (
      rows,
      { space, partnerKey, reportId, integrationId }
    ) => {
      return rows.slice(1).map((row) => {
        const [
          // eslint-disable-next-line
          reservation,
          // eslint-disable-next-line
          affiliate,
          bookingDate,
          pickUpDate,
          // eslint-disable-next-line
          dropOffDate,
          // eslint-disable-next-line
          rentalDuration,
          // eslint-disable-next-line
          leadTime,
          // eslint-disable-next-line
          bookingStatus,
          // eslint-disable-next-line
          bookingType,
          // eslint-disable-next-line
          loyalty,
          // eslint-disable-next-line
          supplier,
          // eslint-disable-next-line
          sourceMarket,
          pickupCountry,
          pickupLocation,
          dropOffCountry,
          dropOffLocation,
          // eslint-disable-next-line
          language,
          // eslint-disable-next-line
          advertisingPlatform,
          advertisingCampaign,
          // eslint-disable-next-line
          currency, // payment currency
          // eslint-disable-next-line
          carPrice, // price in payment currency
          affiliateCurrency,
          carPriceAc, // car price affiliate currency
          carProfitAc, // car profit affiliate currency
          carCommission // car commission affiliate currency
        ] = row.map((cell) => trim(cell, '"'));

        const n = now();
        const saleDate = fromMoment(moment(bookingDate));
        const completionDate = fromMoment(moment(pickUpDate));
        const saleId = reservation;
        // Not clear atm what cancellations look like
        const status: SaleStatus = tsIsAfter(n, completionDate)
          ? 'Final'
          : 'Pending';
        const price = toCents(carPriceAc);
        const revenue = toCents(carProfitAc);
        const commission = toCents(carCommission);
        const commissionPercent = toPercent(commission, price);
        const quantity = parseInt(rentalDuration, 10);

        const trackingLabel =
          advertisingCampaign === 'Unknown' ? '' : advertisingCampaign;
        const trackingId = getTrackingId(trackingLabel, space);

        const partnerProductName = `${supplier} - ${pickupLocation} (${pickupCountry}) -> ${dropOffLocation} (${dropOffCountry})`;

        const sale: ISale = {
          saleId,
          saleDate,
          reportId,
          integrationId,
          trackingId,
          trackingLabel,
          origin: null,
          completionDate: completionDate,
          status,
          partnerKey,
          partnerProductName,
          partnerProductId: '',
          payoutId: null,
          payoutDate: null,
          payoutStatus: null,
          lastModified: n,
          coupon: '',
          saleType: 'unknown',
          amount: {
            currency: (affiliateCurrency as unknown) as CurrencyCode,
            revenue,
            price,
            commission
          },
          commissionPercent,
          quantity,
          advertiserId: partnerKey,
          advertiserName: getKnownPartnerForKeyUnsafe(partnerKey).name
        };

        return sale;
      });
    }
  }
};
