import { Typography } from '@material-ui/core';
import React from 'react';
import { ArrowDown } from 'react-feather';
import { SingleSelector } from '../../../../../components/SingleSelector';
import { metricTitle } from '../../../../../services/analyticsV2/metrics';
import { useMixpanel } from '../../../../../services/mixpanel';
import { earningsMetrics, trafficMetrics, useRealtimeMetric } from '../service';

export const RealtimeMetricSelector = () => {
  const mixpanel = useMixpanel();
  const [
    selectedRealtimeMetric,
    setSelectedRealtimeMetric
  ] = useRealtimeMetric();

  const options = [...earningsMetrics, ...trafficMetrics].map((m) => {
    const label = metricTitle(m);
    return {
      value: m,
      label,
      searchValue: label
    };
  });

  return (
    <SingleSelector
      value={selectedRealtimeMetric}
      onChange={(nextMetric) => {
        mixpanel.track('realtime_report_sort_metric_change', {
          metric: nextMetric
        });
        setSelectedRealtimeMetric(nextMetric);
      }}
      options={options}
    >
      <Typography
        variant="body2"
        color="textSecondary"
        component="span"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '6px'
        }}
      >
        <ArrowDown size={18} />
        Sort <strong>{metricTitle(selectedRealtimeMetric)}</strong>
      </Typography>
    </SingleSelector>
  );
};
