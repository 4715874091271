import { IPartner } from '../domainTypes/partners';

export const FIELDS = {
  TOPIC: 43586
};

export const TOPIC_OPTIONS = {
  ACCOUNT_SETUP: 226698,
  INTEGRATIONS: 226699,
  REQUEST_INTEGRATION: 226708,
  REQUEST_SITE_VERIFICATION: 290739,
  TRACKING: 226700,
  LINK_GENERATOR: 226701,
  PRICING: 226702,
  FEEDBACK: 226703,
  OTHER: 226704
};

export const ARTICLE_IDS = {
  performance: {
    pullCommissions: '6218fef4aca5bb2b753c67cf'
  },
  rates: {
    overview: '64d784c65fdc6369de547e96'
  },
  payouts: {
    overview: '649ad61d50579f0cf61a2394'
  },
  content: {
    filters: '65f832a79b1ce3620e7d48a2'
  },
  traffic: {
    overview: '6550a16d012ba63a184ed64a'
  },
  authors: {
    overview: '64edb9a541fa580fb8157418'
  },
  campaigns: {
    overview: '669230251dd568770420305c'
  }
};

export const ARTICLES = {
  account: {
    teams: 'teams'
  },
  api: {
    overview: 'api-overview',
    dataExport: 'data-sync'
  },
  setup: {
    install: 'install-snippet',
    quickstart: 'quickstart-guide',
    siteSettings: 'site-settings'
  },
  websites: {
    manageSites: 'add-or-remove-websites'
  },
  content: {
    whenPagesAppear: 'when-do-pages-appear',
    export: 'export-content-analytics',
    heatmapNotRendering: 'heatmap-not-rendering'
  },
  transactions: {
    overview: 'transaction-reports',
    statuses: 'affiliate-commission-statuses',
    export: 'export-transactions',
    manualImport: 'manual-import',
    formatNotRecognized: 'file-report-format-not-recognized'
  },
  smartLabels: {
    enable: 'enable-revenue-attribution',
    howRevenueAttributionWorks: 'how-revenue-attribution-works'
  },
  linkCloaker: {
    compatibility: 'working-with-link-cloakers',
    custom: 'custom-link-cloaker-domain'
  },
  labelRules: {
    overview: 'rules',
    rulesV2: 'rules',
    fileImport: 'import-rules-from-spreadsheet'
  },
  networks: {
    overview: 'network-report'
  },
  channels: {
    overview: 'channels-report'
  },
  advertisers: {
    overview: 'advertiser-report'
  },
  tools: {
    linkGenerator: 'affiliate-link-generator'
  },
  links: {
    whenClicksAppear: 'when-do-clicks-appear',
    export: 'export-links',
    editLink: 'edit-link',
    linkManagement: 'affiliate-link-management',
    troubleshootScan: 'troubleshoot-your-website-scan',
    customSitemap: 'custom-sitemap',
    crawlSpeed: 'adjust-crawl-speed',
    amazonLinkChecker: 'find-and-replace-broken-amazon-links',
    amazonProductAdvertisingApi: 'amazon-product-advertising-api'
  }
};

export const DOCS_LIST: { partnerKey: IPartner['key']; docId: string }[] = [
  {
    partnerKey: 'admitad',
    docId: '642d81b51b7ba105b02ef21a'
  },
  {
    partnerKey: 'adsbymoney',
    docId: '644a66e8fe00ba6380ad437d'
  },
  {
    partnerKey: 'amazon',
    docId: '6044f5c724d2d21e45edd772'
  },
  {
    partnerKey: 'affise',
    docId: '63131f3e90c29a3d732c4671'
  },
  {
    partnerKey: 'affilae',
    docId: '622876c72ce7ed0fb0913476'
  },
  {
    partnerKey: 'affiliateWp',
    docId: '62cfec8dc74a080359c89a79'
  },
  {
    partnerKey: 'booking',
    docId: '604500a9661b720174a7436c'
  },
  {
    partnerKey: 'belboon',
    docId: '642c3b05ebf74416afc77bc6'
  },
  {
    partnerKey: 'digidip',
    docId: '641309bb2fd9ce50bee608f2'
  },
  {
    partnerKey: 'gyg',
    docId: '605368de3f70ab34d9ba08d0'
  },
  {
    partnerKey: 'hotels_combined',
    docId: '6050f33d24ce107ab4d161d2'
  },
  {
    partnerKey: 'klook',
    docId: '6050e107207e3b1188e1cb5e'
  },
  {
    partnerKey: 'cake',
    docId: '611cf09fb55c2b04bf6df7b1'
  },
  {
    partnerKey: 'cannaffiliate',
    docId: '6283673c8c9b58508348a4ec'
  },
  {
    partnerKey: 'cbdAffs',
    docId: '628365e6c01fce37d9b13db6'
  },
  {
    partnerKey: 'civitatis',
    docId: '61bf0f47d172c11fdd325195'
  },
  {
    partnerKey: 'clickbank',
    docId: '6045052524d2d21e45edd797'
  },
  {
    partnerKey: 'cj',
    docId: '604502820a2dae5b58fb6bf6'
  },
  {
    partnerKey: 'viator',
    docId: '60ef068964a230081ba15268'
  },
  {
    partnerKey: 'voyagin',
    docId: '6061e1690ad05379b10d1bf9'
  },
  {
    partnerKey: 'awin',
    docId: '6044f3ac661b720174a74346'
  },
  {
    partnerKey: 'bol',
    docId: '60ec4feb05ff892e6bc300a4'
  },
  {
    partnerKey: 'shareasale',
    docId: '6044f14c0a2dae5b58fb6bc6'
  },
  {
    partnerKey: 'skimlinks',
    docId: '6050f13c24ce107ab4d161be'
  },
  {
    partnerKey: 'pepperjam',
    docId: '6050ea0c24ce107ab4d16168'
  },
  {
    partnerKey: 'pap',
    docId: '6050ec7d24ce107ab4d1617b'
  },
  {
    partnerKey: 'stacked_brands',
    docId: '6050ec7d24ce107ab4d1617b'
  },
  {
    partnerKey: 'refersion',
    docId: '6050ee71207e3b1188e1cbe1'
  },
  {
    partnerKey: 'rentalcarscom',
    docId: '6050f31424ce107ab4d161cf'
  },
  {
    partnerKey: 'partnerize',
    docId: '6050e8ee3f70ab34d9b9fbb2'
  },
  {
    partnerKey: 'avantlink',
    docId: '6044fec78502d1120e90e73f'
  },
  {
    partnerKey: 'rakuten',
    docId: '6044cb29661b720174a742e3'
  },
  {
    partnerKey: 'jvzoo',
    docId: '6050e7f424ce107ab4d1615a'
  },
  {
    partnerKey: 'impact',
    docId: '606c2c29f8c0ef2d98def5d4'
  },
  {
    partnerKey: 'flexoffers',
    docId: '606c3050f8c0ef2d98def5f0'
  },
  {
    partnerKey: 'sovrn',
    docId: '606c35b98af76a714bfd4364'
  },
  {
    partnerKey: 'linkconnector',
    docId: '60619d1e0ad05379b10d1a58'
  },
  {
    partnerKey: 'tradedoubler',
    docId: '607007d94466ce6ddc5f2e58'
  },
  {
    partnerKey: 'bluehost',
    docId: '611b82876ffe270af2a997b3'
  },
  {
    partnerKey: 'partnerstack',
    docId: '611b9edfb37d837a3d0e46c3'
  },
  {
    partnerKey: 'everflow',
    docId: '611ba681b37d837a3d0e46f7'
  },
  {
    partnerKey: 'idevaffiliate',
    docId: '61af51de8564e0276362a2ca'
  },
  {
    partnerKey: 'agoda',
    docId: '61af5f2b48287b457880999c'
  },
  {
    partnerKey: 'ltk',
    docId: '61af5eaa48287b4578809999'
  },
  {
    partnerKey: 'affiliatly',
    docId: '611ba7bef886c9486f8d9879'
  },
  {
    partnerKey: 'ebay',
    docId: '611b8f4eb37d837a3d0e4677'
  },
  {
    partnerKey: 'webgains',
    docId: '611b7256b37d837a3d0e45c4'
  },
  {
    partnerKey: 'financeads',
    docId: '614c74082b380503dfdf3fa3'
  },
  {
    partnerKey: 'commission-factory',
    docId: '614c702b2b380503dfdf3f96'
  },
  {
    partnerKey: 'optimise',
    docId: '614c772f0754e74465f130be'
  },
  {
    partnerKey: 'effiliation',
    docId: '614ef3900754e74465f13b78'
  },
  {
    partnerKey: 'tune',
    docId: '606c3ad3e0324b5fdfd07ea1'
  },
  {
    partnerKey: 'tradetracker',
    docId: '616e7b172b380503dfdfbb5b'
  },
  {
    partnerKey: 'expressvpn',
    docId: '6050ec7d24ce107ab4d1617b'
  },
  {
    partnerKey: 'daisycon',
    docId: '616e815aefc78d0553e50f46'
  },
  {
    partnerKey: 'odyssey',
    docId: '61813f529ccf62287e5f2190'
  },
  {
    partnerKey: 'revoffers',
    docId: '628366edc01fce37d9b13dba'
  },
  {
    partnerKey: 'maxbounty',
    docId: '629247a9573200079251f7e8'
  },
  {
    partnerKey: 'travelpayouts',
    docId: '62f77cc061ff5d5f24f9ba63'
  },
  {
    partnerKey: 'goaffpro',
    docId: '61af583a7a3b8c03913d4a45'
  },
  {
    partnerKey: 'leaddyno',
    docId: '63b968b835b84e435e77a466'
  },
  {
    partnerKey: 'tapfiliate',
    docId: '63e3a8bb2b14764c0619db5f'
  },
  {
    partnerKey: 'secomapp',
    docId: '63b43df2649bd03a4c35917b'
  },
  {
    partnerKey: 'kutoku',
    docId: '645ce8b2aeab6a235ef2cb68'
  },
  {
    partnerKey: 'mvf',
    docId: '6470ab7523940d28c9f21a60'
  },
  {
    partnerKey: 'firstpromoter',
    docId: '61eeb01439e5d05141b624c6'
  },
  {
    partnerKey: 'howl',
    docId: '64c24f792af2e15078a7fe28'
  },
  {
    partnerKey: 'redventures',
    docId: '64c252cbf7e57563daff8c39'
  },
  {
    partnerKey: 'levanta',
    docId: '650d93ca6a7bf14d5d3c50ce'
  },
  {
    partnerKey: 'stay22',
    docId: '6565e7163698b31822e868a9'
  },
  {
    partnerKey: 'engine',
    docId: '65d4b40c13abf5310a20b9fb'
  }
];

export const Beacon = (...args: any) => {
  if (typeof window !== 'undefined') {
    const b = (window as any).Beacon;
    b(...args);
  }
};
