import { Timestamp } from './time';

export enum IChannelType {
  Email = 'email',
  Facebook = 'facebook',
  Flipboard = 'flipboard',
  Instagram = 'instagram',
  Pinterest = 'pinterest',
  Tiktok = 'tiktok',
  Twitter = 'twitter',
  Youtube = 'youtube',
  App = 'app',
  Other = 'other'
}

interface IChannelCommon {
  spaceId: string;
  channelId: string;
  name: string;
  type: IChannelType;
  subIdMatches: string[];
  subIdDefaultPrefix: string;
  status: 'active'; // So we can add archived later
  createdAt: Timestamp;
  updatedAt: Timestamp;
  createdBy: string;
}

export interface IChannelWithUrl extends IChannelCommon {
  type: Exclude<IChannelType, IChannelType.Email>;
  url: string;
}

export interface IChannelWithoutUrl extends IChannelCommon {
  type: Extract<IChannelType, IChannelType.Email>;
}

export type IChannel = IChannelWithUrl | IChannelWithoutUrl;

export const UNKNOWN_CHANNEL_ID = 'unknown';
export const OTHERS_CHANNEL_ID = 'other';
