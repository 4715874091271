import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';
import { useSites } from '../../../../../../components/analytics_v2/SiteSelector';
import { useTimeframe } from '../../../../../../components/analytics_v2/Timeframe';
import {
  ChartCard,
  ChartCardFooter,
  ChartCardFooterBar
} from '../../../../../../components/Charts/ChartCard';
import { CustomTooltip } from '../../../../../../components/Charts/CustomTooltip';
import {
  formatChartCurrency,
  ILegendItem,
  Legend
} from '../../../../../../components/Charts/Util';
import { Loader } from '../../../../../../components/Loader';
import { formatCurrency } from '../../../../../../components/Number';
import {
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison
} from '../../../../../../domainTypes/analytics_v2';
import { Color, getColorDef } from '../../../../../../domainTypes/colors';
import { CurrencyCode } from '../../../../../../domainTypes/currency';
import { Centered } from '../../../../../../layout/Centered';
import { useAnalyticsQueryV2 } from '../../../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../../../services/currentUser';
import { IPostgresTagsParent } from '../../../../../../services/tags';
import { TopChartMetric, useTopChartMetric } from '../service/chart-metrics';
import { TagErrorMessage } from './TagErrorMessage';
import { useNonEmptyTagsFilter } from '../../../../../../components/analytics_v2/TagsSelector';
import { useResultsWithTags } from '../service/merge-with-tags';
import { topTagsQuery } from '../service/top-tags-query';

interface TagBarChartProps {
  tagGroup: IPostgresTagsParent;
}

const formatChartData = (
  data: AnalyticsResponseRowWithComparison[],
  metric: TopChartMetric
) => {
  return data.map((d) => ({
    curr: d.data[metric]?.curr ?? 0,
    prev: d.data[metric]?.prev ?? 0,
    name: d.group.tagName,
    color: d.group.tagColor as Color,
    id: d.group.tags
  }));
};

const Chart = ({
  data,
  currency,
  metric
}: {
  data: AnalyticsResponseRowWithComparison[];
  currency: CurrencyCode;
  metric: TopChartMetric;
}) => {
  const chartData = formatChartData(data, metric);
  const barSize = useMemo(() => {
    return Math.round(85 / chartData.length);
  }, [chartData.length]);

  return (
    <ResponsiveContainer width="99%" height="auto" aspect={1.5}>
      <BarChart
        width={150}
        height={40}
        data={chartData}
        layout="vertical"
        barCategoryGap={1}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 50
        }}
      >
        <Tooltip
          cursor={false}
          labelFormatter={(label: TooltipProps['label']) => (
            <Typography
              variant="h6"
              component="span"
              style={{ padding: 0, margin: 0 }}
            >
              {label}
            </Typography>
          )}
          content={<CustomTooltip size="small" />}
          formatter={(value, name) => [
            formatCurrency(value as number, currency),
            name.includes('prev') ? 'Previously' : 'Currently'
          ]}
        />
        <Bar dataKey="curr" label={false} barSize={barSize} background={false}>
          {chartData.map((v) => (
            <Cell key={`cell-${v.id}`} fill={getColorDef(v.color).bgColor} />
          ))}
        </Bar>
        <Bar dataKey="prev" label={false} barSize={barSize} background={false}>
          {chartData.map((v) => (
            <Cell
              key={`cell-${v.id}`}
              fill={getColorDef(v.color).bgColor}
              style={{ opacity: 0.5 }}
            />
          ))}
        </Bar>
        <XAxis
          type="number"
          stroke="none"
          tick={{
            fill: '#9b9b9b',
            fontSize: 12
          }}
          tickFormatter={(value: number) =>
            formatChartCurrency(value, currency, { excludeCents: true })
          }
        />
        <YAxis
          type="category"
          tick={{
            fill: '#9b9b9b',
            fontSize: 14
          }}
          width={150}
          dataKey="name"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

const HEIGHT = 422;

const useTopTags = (metric: TopChartMetric, tagGroup: IPostgresTagsParent) => {
  const { space } = useCurrentUser();
  const { range, compare } = useTimeframe();
  const sites = useSites();
  const tags = useNonEmptyTagsFilter(tagGroup);

  const query = useMemo<AnalyticsQuery>(
    () =>
      topTagsQuery([metric], range, tagGroup.parent.id, tags, sites, compare),
    [compare, metric, range, sites, tagGroup.parent.id, tags]
  );

  return useResultsWithTags(
    useAnalyticsQueryV2(space.id, query, { logLabel: 'top_tags' })
  );
};

interface ChartCardContentProps {
  tagGroup: IPostgresTagsParent;
}

const ChartCardContent: React.FC<ChartCardContentProps> = ({ tagGroup }) => {
  const { space } = useCurrentUser();
  const [metric] = useTopChartMetric();
  const [totals, loading, error] = useTopTags(metric, tagGroup);

  if (error) {
    return (
      <Centered height={HEIGHT}>
        <TagErrorMessage />
      </Centered>
    );
  }

  if (!totals || loading) {
    return (
      <Centered height={HEIGHT}>
        <Loader size={24} />
      </Centered>
    );
  }
  if (totals.rows.length === 0) {
    return (
      <Centered height={HEIGHT}>
        <Typography variant="body2" component="p" paragraph>
          No data found. Please change your selection or adjust filters.
        </Typography>
      </Centered>
    );
  }
  return (
    <Chart
      metric={metric}
      currency={space.config.currency}
      data={totals.rows}
    />
  );
};

const toHeading = (metric: TopChartMetric) => {
  const baseHeading = 'Tag performance';
  const qualifier: Record<TopChartMetric, string> = {
    commission_sum_net: 'by earnings',
    gmv_sum_net: 'by sales volume',
    rpm_net: 'by RPM',
    epc_net: 'by EPC'
  };
  return `${baseHeading} ${qualifier[metric]}`;
};

export const TagBarChart: React.FC<TagBarChartProps> = ({ tagGroup }) => {
  const [metric, setMetric] = useTopChartMetric();
  const items = useMemo<ILegendItem[]>(
    () => [
      {
        color: '#444',
        shape: 'circle',
        active: metric === 'commission_sum_net',
        onClick: () => setMetric('commission_sum_net'),
        label: 'Earnings'
      },
      {
        color: '#444',
        shape: 'circle',
        active: metric === 'gmv_sum_net',
        onClick: () => setMetric('gmv_sum_net'),
        label: 'Sale volume'
      },
      {
        color: '#444',
        shape: 'circle',
        active: metric === 'rpm_net',
        onClick: () => setMetric('rpm_net'),
        label: 'RPM'
      },
      {
        color: '#444',
        shape: 'circle',
        active: metric === 'epc_net',
        onClick: () => setMetric('epc_net'),
        label: 'EPC'
      }
    ],
    [metric, setMetric]
  );
  return (
    <ChartCard
      padding="dense"
      heading={toHeading(metric)}
      subheading="Your top tags by content performance"
    >
      <ChartCardContent tagGroup={tagGroup} />
      <ChartCardFooter>
        <ChartCardFooterBar>
          <Legend items={items} />
        </ChartCardFooterBar>
      </ChartCardFooter>
    </ChartCard>
  );
};
