import Cookies from 'js-cookie';
import { useMemo } from 'react';
import Stripe from 'stripe';
import {
  IBillingData,
  ICoupon,
  IInvoice,
  ISubscriptionCreateParams,
  ISubscriptionDetails,
  Plan,
  PLAN_TYPES
} from '../../domainTypes/billing';
import ENV from '../../env.json';
import {
  callFirebaseFunction,
  useFirebaseFunction
} from '../../services/firebaseFunctions';
import { CF } from '../../versions';

export const createCheckoutSession = ({
  spaceId,
  priceId
}: {
  spaceId: string;
  priceId: string;
}) => {
  const fprCookie = Cookies.get('_fprom_tid');
  return callFirebaseFunction<{ session: string }>(
    CF.billing.createCheckoutSession,
    { spaceId, priceId, fprCookie }
  );
};

export const useSubscription = (spaceId: string, billingData: IBillingData) => {
  const listeners = billingData
    ? [billingData.activePlan, billingData.subscriptionId]
    : ['', ''];
  return useFirebaseFunction<ISubscriptionDetails | null>(
    CF.billing.getSubscription,
    { spaceId },
    listeners
  );
};

export const hasOverdueInvoices = (
  invoices: IInvoice[],
  daysAllowed: number
) => {
  const now = Date.now();
  const allowedTime = daysAllowed * 24 * 60 * 60 * 1000;

  return invoices.some((invoice) => {
    return (
      invoice.status === 'open' &&
      invoice.due_date !== null &&
      invoice.due_date * 1000 < now - allowedTime
    );
  });
};

export const useInvoices = (spaceId: string) =>
  useFirebaseFunction<IInvoice[]>(CF.billing.getInvoices, { spaceId }, [
    spaceId
  ]);

const PLANS = (Object.keys(PLAN_TYPES) as Plan[]).map((plan) => {
  const planValue = PLAN_TYPES[plan];
  const planIds = ENV.stripeNew.planIds as any;
  const planId: string | null = planIds[plan] || null;

  return {
    ...planValue,
    name: plan,
    planId
  };
});

export const usePlans = () => {
  return useMemo(() => PLANS, []);
};

export type Coupons = { monthly: ICoupon | null; yearly: ICoupon | null };
export type CouponGetter = (code: string) => Promise<Coupons>;

export const getCoupon = (spaceId: string, code: string) =>
  callFirebaseFunction<Coupons>(CF.billing.getCoupon, {
    code,
    spaceId
  });

export const checkout = (
  spaceId: string,
  email: string,
  plan: string,
  coupon: string,
  token: stripe.Token
) => {
  return callFirebaseFunction(CF.billing.createSubscription, {
    spaceId,
    email,
    plan,
    coupon,
    token: token.id
  } as ISubscriptionCreateParams);
};

export const formatAmount = (amountInCents: number, currency: string) => {
  const isEvenPrice = amountInCents % 100 === 0;
  const amount = amountInCents / 100;
  const formattedPrice = amount.toFixed(isEvenPrice ? 0 : 2);
  const currencySymbol = currency === 'usd' ? '$' : 'EUR';
  return `${currencySymbol}${formattedPrice}`;
};

export const applyCoupon = (amount: number, coupon: ICoupon | null) => {
  if (coupon) {
    if (coupon.percent_off) {
      return (amount / 100) * (100 - coupon.percent_off);
    }
    if (coupon.amount_off) {
      return amount - coupon.amount_off;
    }
  }

  return amount;
};

export const getTotalPrice = (plan: Stripe.Plan, coupon: ICoupon | null) => {
  return applyCoupon(plan.amount || 0, coupon);
};

export const getMonthlyPrice = (plan: Stripe.Plan, coupon: ICoupon | null) => {
  // this should be much more precise
  const divisor = plan.interval === 'year' ? 12 : 1;
  return applyCoupon((plan.amount || 0) / divisor, coupon);
};

export const couponToString = (coupon: ICoupon) => {
  return `${coupon.name}${
    coupon.percent_off ? `: ${coupon.percent_off}% off` : ''
  }`;
};
