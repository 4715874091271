import React from 'react';
import { reportError } from './stackdriverErrorReporter';

export const some = <T>(predicate: (el: T) => boolean, list: T[]): boolean => {
  for (const el of list) {
    if (predicate(el)) {
      return true;
    }
  }
  return false;
};

export const withStoppedPropagation = <T>(
  fn: (e: React.SyntheticEvent) => T,
  preventDefault = true
): ((e: React.SyntheticEvent) => T) => {
  return (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (preventDefault) {
      e.preventDefault();
    }
    return fn(e);
  };
};

export const wait = (milliseconds: number): Promise<{}> =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));

export const fireAndForget = async (
  fn: () => Promise<any>,
  waitMs = 2000,
  options?: { ignoreError?: boolean }
) => {
  const opts = {
    ...options,
    ignoreError: false
  };
  // tslint:disable-next-line
  fn();
  try {
    await wait(waitMs);
  } catch (err) {
    reportError(err);
    if (!opts.ignoreError) {
      throw err;
    }
  }
};

export const reduceAsync = async <S, T>(
  xs: S[],
  aggregator: (m: T, x: S, i: number) => Promise<T>,
  startingValue: T
) => {
  let memo = startingValue;
  for (let i = 0; i < xs.length; i++) {
    const x = xs[i];
    memo = await aggregator(memo, x, i);
  }
  return memo;
};

export const mapValuesAsync = <T extends any, E extends any>(
  collection: { [key: string]: T },
  asyncFn: (
    value: T,
    key: string,
    index: number,
    all: { [key: string]: T }
  ) => Promise<E>
): Promise<{ [key: string]: E }> => {
  const promises = Object.entries(collection).map(([key, value], index) => {
    return asyncFn(value, key, index, collection).then((resolved) => [
      key,
      resolved
    ]);
  });
  return Promise.all(promises).then(Object.fromEntries);
};
