import { compact } from 'lodash';
import React, { useMemo } from 'react';
import { UploadCloud } from 'react-feather';
import { useDialogState } from '../../../../hooks/useDialogState';
import {
  PageBody,
  SideNavHowTo,
  SideNavProps
} from '../../../../layout/PageBody';
import { useRoutes } from '../../../../routes';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../services/currentUser';
import { isFeatureEnabled } from '../../../../services/features';
import * as tracking from '../../../../tracking';
import { DialogCreate } from '../../pages/Overview/components/DialogCreate';

type Props = {
  howTo?: SideNavHowTo;
  noTopPadding?: boolean;
};

export const PageBodyLinks: React.FC<Props> = ({
  howTo,
  noTopPadding,
  children
}) => {
  const { ROUTES } = useRoutes();
  const { space } = useCurrentUser();
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);
  const hasScanlessTracking = isFeatureEnabled('SCANLESS_TRACKING');
  const scopes = useCurrentUserScopes();
  const canCreateLinks = scopes.has('links.create');
  const canSeeLinks = scopes.has('reports.links.view');
  const canSeeLegacyLinks = canSeeLinks && space.id === '9fSYGsMqM'; // Disable for everyone but Mumsnet
  const canViewScans = scopes.has('scans.view');
  const canViewAmazonReport = scopes.has('link_checker.amazon.view');
  const canChangeLinkCheckerSettings = scopes.has('link_checker.settings.view');
  const canSeeScanSettings = scopes.has('scans.settings.view');

  const sideNav: SideNavProps = useMemo(() => {
    let nav = {
      groups: compact([
        canSeeLinks && {
          label: 'Links',
          items: compact([
            {
              label: 'Overview',
              path: ROUTES.links.overview_v2.url()
            }
          ])
        },
        (canViewAmazonReport || canChangeLinkCheckerSettings) && {
          label: 'Amazon',
          items: compact([
            canViewAmazonReport && {
              label: 'Links Issues',
              path: ROUTES.links.amazonIssues.url()
            },
            canSeeScanSettings && {
              label: 'Settings',
              path: ROUTES.links.amazonSettings.url()
            }
          ])
        },
        !hasScanlessTracking &&
          (canViewScans || canSeeScanSettings) && {
            label: 'Link Import',
            items: compact([
              canViewScans && {
                label: 'Scans',
                path: ROUTES.links.scans.history.url()
              },
              canSeeScanSettings && {
                label: 'Settings',
                path: ROUTES.links.settings.url()
              }
            ])
          },
        canSeeLegacyLinks && {
          label: 'Legacy',
          items: compact([
            {
              label: 'Overview (v1)',
              path: ROUTES.links.overview.url()
            }
          ])
        }
      ]),
      action:
        canCreateLinks && !hasScanlessTracking
          ? {
              icon: UploadCloud,
              label: 'Import',
              href: ROUTES.links.scans.history.url(),
              onClick: () => {
                tracking.sendEvent({
                  category: tracking.toAppCategory(),
                  action: 'Click',
                  label: 'Scan site'
                });
              },
              additionalActions: [
                {
                  label: 'Track link manually',
                  onClick: () => {
                    tracking.sendEvent({
                      category: tracking.toAppCategory(),
                      action: 'Open',
                      label: 'Add product modal'
                    });
                    openDialog();
                  }
                }
              ]
            }
          : undefined,
      howTo: howTo
    };
    return nav;
  }, [
    canSeeLinks,
    canSeeLegacyLinks,
    ROUTES.links.overview,
    ROUTES.links.overview_v2,
    ROUTES.links.amazonIssues,
    ROUTES.links.amazonSettings,
    ROUTES.links.scans.history,
    ROUTES.links.settings,
    canViewAmazonReport,
    canChangeLinkCheckerSettings,
    canSeeScanSettings,
    hasScanlessTracking,
    canViewScans,
    canCreateLinks,
    howTo,
    openDialog
  ]);

  return (
    <>
      <PageBody sideNav={sideNav} noTopPadding={noTopPadding}>
        {children}
      </PageBody>
      <DialogCreate open={dialogOpen} onClose={closeDialog} />
    </>
  );
};
