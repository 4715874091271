import { ReferrerGroup, groups } from '../../../../../../domainTypes/referrers';

export const isReferrerGroup = (g: unknown): g is ReferrerGroup =>
  groups.includes(g as ReferrerGroup);

export const COLORS: Record<ReferrerGroup, string> = {
  search: '#a8dbd9',
  social: '#1d4f60',
  internal: '#f3ad6a',
  direct: '#eb7f86',
  'google-discover': '#ad466c',
  other: '#36877a'
};

export const groupInfo = (g: ReferrerGroup): string => {
  switch (g) {
    case 'search':
      return 'Traffic from search engines, like Google, Bing, Yahoo, and DuckDuckGo.';
    case 'social':
      return 'Traffic from social media, like Facebook, Instagram, and Pinterest.';
    case 'internal':
      return 'Internal traffic within your websites, like when a user clicks on a link from one page to another.';
    case 'direct':
      return 'Direct traffic, either from typing the URL directly into the browser, bookmarks, or platforms that do not send referrer data.';
    case 'google-discover':
      return 'Traffic from Google Discover, a feed of personalized content on Android devices.';
    case 'other':
      return 'Traffic from other external domains.';
  }
};
