import { capitalize, Card } from '@material-ui/core';
import { sortBy } from 'lodash';
import { now } from 'moment-timezone';
import React from 'react';
import { ConnectionId } from '../../../components/ConnectionId';
import { PlatformWithColor } from '../../../components/PlatformWithColor';
import { ApiStatus } from '../../../domainTypes/apiStatus';
import { Doc } from '../../../domainTypes/document';
import { styled } from '../../../emotion';
import { getKnownPartnerForKeyUnsafe } from '../../../services/partner';
import { toMoment } from '../../../services/time';
import { COLORS } from '../../../domainTypes/colors';
import { useApiReportHandlers } from '../services/handlers';
import { IApiReportHandler } from '../services/handlers/types';

const ApiStatusGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(2)}px;
  grid-row-gap: ${(p) => p.theme.spacing(2)}px;
`;

const ApiStatusItemHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const StatusBadge = styled('div')`
  padding: 3px 12px;
  font-weight: 600;
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;

  svg {
    position: relative;
    top: 2px;
    left: -1px;
  }
`;

export const StatusDetailsList = styled('dl')`
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
  grid-column-gap: ${(p) => p.theme.spacing(2)}px;
  width: 100%;
  font-size: ${(p) => p.theme.typography.body2.fontSize};
  line-height: ${(p) => p.theme.typography.body1.lineHeight};
  margin: 0;

  dt {
    color: ${(p) => p.theme.palette.grey[600]};
    margin-top: ${(p) => p.theme.spacing(1)}px;
  }
  dd {
    margin: ${(p) => p.theme.spacing(1)}px 0 0 0;
    word-break: break-all;
  }
`;

const ApiStatusStatus = ({ status }: { status: ApiStatus['status'] }) => {
  const statusName = capitalize(status);
  const statusColor =
    status === 'available' ? COLORS.green.green8 : COLORS.gold.gold8;
  const statusBgColor =
    status === 'available' ? COLORS.green.green2 : COLORS.gold.gold2;

  return (
    <StatusBadge style={{ color: statusColor, backgroundColor: statusBgColor }}>
      {statusName}
    </StatusBadge>
  );
};

const ApiStatusItem = ({
  apiStatus,
  handlers
}: {
  apiStatus: Doc<ApiStatus>;
  handlers: IApiReportHandler[];
}) => {
  const handlerName = apiStatus.data.handler;
  const handler = handlers.find((h) => h.configName === handlerName);
  if (!handler) {
    return <>handlerName</>;
  }
  const partner = getKnownPartnerForKeyUnsafe(handler.partnerKey);
  const limitAsMoment = apiStatus.data.limitExpires
    ? toMoment(apiStatus.data.limitExpires)
    : null;

  const isLimitExpired = limitAsMoment && limitAsMoment.isBefore(now());

  return (
    <Card>
      <ApiStatusItemHeader>
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <PlatformWithColor partner={partner} />
          <ConnectionId>{apiStatus.data.instanceId}</ConnectionId>
        </div>
        <ApiStatusStatus status={apiStatus.data.status} />
      </ApiStatusItemHeader>
      <StatusDetailsList>
        <dt>Limit {isLimitExpired ? 'expired' : 'expires'}</dt>
        <dd>
          {limitAsMoment && limitAsMoment.isAfter(now()) ? (
            <span style={{ color: COLORS.gold.gold7 }}>
              {capitalize(limitAsMoment.fromNow())} (at{' '}
              {limitAsMoment.format('LT')})
            </span>
          ) : (
            'In the past'
          )}
        </dd>
      </StatusDetailsList>
    </Card>
  );
};

export const ApiStatusList = ({
  apiStatuses
}: {
  apiStatuses: Doc<ApiStatus>[];
}) => {
  const handlers = useApiReportHandlers();

  return (
    <ApiStatusGrid>
      {sortBy(apiStatuses, (a) => a.data.handler).map((a) => (
        <ApiStatusItem apiStatus={a} handlers={handlers} key={a.id} />
      ))}
    </ApiStatusGrid>
  );
};
