import React from 'react';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import { useAdminClaim } from '../../../../services/auth';
import { callFirebaseFunction } from '../../../../services/firebaseFunctions';
import { CF } from '../../../../versions';

type Props = {
  spaceId: string;
  scanId: string;
};

export const ScanAborter: React.FC<Props> = ({ spaceId, scanId }) => {
  const [isAdmin] = useAdminClaim();
  if (!isAdmin) {
    return null;
  }
  return (
    <ButtonWithPromise
      size="small"
      color="secondary"
      variant="outlined"
      pending="Aborting..."
      onClick={() =>
        callFirebaseFunction(CF.scraping.abortScan, { spaceId, scanId })
      }
    >
      Abort
    </ButtonWithPromise>
  );
};
