if (!Object.fromEntries) {
  Object.fromEntries = function fromEntries(iterable) {
    const entries = 'entries' in iterable ? iterable.entries() : iterable;
    const obj = {};
    let entry;
    while ((entry = entries.next()) && !entry.done) {
      const pair = entry.value[1];
      Object.defineProperty(obj, pair[0], {
        configurable: true,
        enumerable: true,
        writable: true,
        value: pair[1]
      });
    }
    return obj;
  };
}
