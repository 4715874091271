import shortid from 'shortid';
import { IPostgresTags } from '../../../../../../domainTypes/tags';
import { Color } from '../../../../../../domainTypes/colors';

export const allVerticals = ['Retail', 'Travel', 'Finance', 'Entertainment'];

export type TagVertical = 'Travel' | 'Retail' | 'Finance' | 'Entertainment';

type TagWithVertical = IPostgresTags & {
  vertical?: TagVertical;
  color: Color | '';
};

const PARENT_IDS = {
  GENERAL_CONTENT_TYPE: shortid(),
  FINANCE_CONTENT_TYPE: shortid(),
  MEDIA_CONTENT_TYPE: shortid(),
  TRAVEL_CONTENT_TYPE: shortid()
};

const GENERAL_TAGS: TagWithVertical[] = [
  {
    space_id: '',
    id: PARENT_IDS.GENERAL_CONTENT_TYPE,
    parent_id: null,
    name: 'Content type',
    vertical: 'Retail',
    color: '',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.GENERAL_CONTENT_TYPE,
    name: 'News',
    color: 'red',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.GENERAL_CONTENT_TYPE,
    name: 'Deals',
    color: 'volcano',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.GENERAL_CONTENT_TYPE,
    name: 'How to guide',
    color: 'orange',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.GENERAL_CONTENT_TYPE,
    name: 'Product review',
    color: 'gold',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.GENERAL_CONTENT_TYPE,
    name: 'Product roundup',
    color: 'yellow',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.GENERAL_CONTENT_TYPE,
    name: 'Product comparison',
    color: 'lime',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.GENERAL_CONTENT_TYPE,
    name: 'Buying guide',
    color: 'green',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.GENERAL_CONTENT_TYPE,
    name: 'Gift guide',
    color: 'cyan',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.GENERAL_CONTENT_TYPE,
    name: 'Product detail page',
    color: 'blue',
    type: 'Page'
  }
];

const TRAVEL_TAGS: TagWithVertical[] = [
  {
    space_id: '',
    id: PARENT_IDS.TRAVEL_CONTENT_TYPE,
    parent_id: null,
    name: 'Content type',
    vertical: 'Travel',
    color: '',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.TRAVEL_CONTENT_TYPE,
    name: 'Itinerary',
    color: 'red',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.TRAVEL_CONTENT_TYPE,
    name: 'Things to do',
    color: 'volcano',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.TRAVEL_CONTENT_TYPE,
    name: 'Where to stay',
    color: 'orange',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.TRAVEL_CONTENT_TYPE,
    name: 'Day trips',
    color: 'gold',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.TRAVEL_CONTENT_TYPE,
    name: 'Transportation',
    color: 'lime',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.TRAVEL_CONTENT_TYPE,
    name: 'Tickets',
    color: 'green',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.TRAVEL_CONTENT_TYPE,
    name: 'Tours and excursions',
    color: 'cyan',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.TRAVEL_CONTENT_TYPE,
    name: 'Packing list',
    color: 'blue',
    type: 'Page'
  }
];

const FINANCE_TAGS: TagWithVertical[] = [
  {
    space_id: '',
    id: PARENT_IDS.FINANCE_CONTENT_TYPE,
    parent_id: null,
    name: 'Content type',
    vertical: 'Finance',
    color: '',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.FINANCE_CONTENT_TYPE,
    name: 'Product review',
    color: 'lime',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.FINANCE_CONTENT_TYPE,
    name: 'Product comparisons',
    color: 'green',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.FINANCE_CONTENT_TYPE,
    name: 'Guides',
    color: 'cyan',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.FINANCE_CONTENT_TYPE,
    name: 'Calculators',
    color: 'blue',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.FINANCE_CONTENT_TYPE,
    name: 'Education',
    color: 'magenta',
    type: 'Page'
  }
];

const MEDIA_TAGS: TagWithVertical[] = [
  {
    space_id: '',
    id: PARENT_IDS.MEDIA_CONTENT_TYPE,
    parent_id: null,
    name: 'Content type',
    vertical: 'Entertainment',
    color: '',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.MEDIA_CONTENT_TYPE,
    name: 'News',
    color: 'red',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.MEDIA_CONTENT_TYPE,
    name: 'New releases',
    color: 'green',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.MEDIA_CONTENT_TYPE,
    name: 'Guides',
    color: 'cyan',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.MEDIA_CONTENT_TYPE,
    name: 'Opinion',
    color: 'blue',
    type: 'Page'
  },
  {
    space_id: '',
    id: shortid(),
    parent_id: PARENT_IDS.MEDIA_CONTENT_TYPE,
    name: 'How to guide',
    color: 'magenta',
    type: 'Page'
  }
];

export const TAG_TEMPLATES: TagWithVertical[] = [
  ...GENERAL_TAGS,
  ...FINANCE_TAGS,
  ...TRAVEL_TAGS,
  ...MEDIA_TAGS
];
