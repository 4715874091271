import { findIndex } from 'lodash';
import { useState } from 'react';

const _prepend = <T extends { key: string }>(
  list: T[],
  item: T,
  mutate: boolean
) => {
  if (mutate) {
    list.unshift(item);
    return list;
  }
  return [item, ...list];
};

const _append = <T extends { key: string }>(
  list: T[],
  item: T,
  mutate: boolean
) => {
  if (mutate) {
    list.push(item);
    return list;
  }
  return [...list, item];
};

const _remove = <T extends { key: string }>(
  list: T[],
  key: string,
  mutate: boolean
) => {
  const i = findIndex(list, el => el.key === key);
  if (i === -1) {
    return list;
  }
  if (mutate) {
    list.splice(i, 1);
    return list;
  }
  return [...list.slice(0, i), ...list.slice(i + 1)];
};

const _set = <T extends { key: string }>(
  list: T[],
  key: string,
  item: T,
  mutate: boolean
) => {
  const i = findIndex(list, el => el.key === key);
  if (i === -1) {
    return list;
  }
  if (mutate) {
    list[i] = item;
    return list;
  }
  return [...list.slice(0, i), item, ...list.slice(i + 1)];
};

const _update = <T extends { key: string }>(
  list: T[],
  key: string,
  item: Partial<T>,
  mutate: boolean
) => {
  const i = findIndex(list, el => el.key === key);
  if (i === -1) {
    return list;
  }
  const prevItem = list[i];
  const nextItem = { ...prevItem, ...item };
  return _set(list, key, nextItem, mutate);
};

export const useList = <T extends { key: string }>(
  initialList: T[],
  // WARNING: Hooks in post-alpha and don't work this way anymore. mutating will breank and not trigger a rerender!
  // See https://reactjs.org/docs/hooks-reference.html#bailing-out-of-a-state-update
  mutateList = false
) => {
  const [list, setList] = useState<T[]>(initialList);
  const prepend = (item: T) => setList(_prepend(list, item, mutateList));
  const append = (item: T) => setList(_append(list, item, mutateList));
  const remove = (key: string) => setList(_remove(list, key, mutateList));
  const set = (key: string, item: T) =>
    setList(_set(list, key, item, mutateList));
  const update = (key: string, item: Partial<T>) =>
    setList(_update(list, key, item, mutateList));
  const contains = (key: string) =>
    findIndex(list, el => el.key === key) !== -1;
  const reset = (nextList: T) => setList(list);
  return { list, prepend, append, remove, set, update, contains, reset };
};
