import { callFirebaseFunction } from '../../../services/firebaseFunctions';
import { getSpaceIds } from '../space';

export const checkSpaceForModifications = (
  spaceId: string,
  forceScreenshots: boolean
) => {
  return callFirebaseFunction<any>('pages-checkSpaceForModifications', {
    spaceId,
    forceScreenshots
  });
};

export const checkAllSpacesForModifications = async (
  forceScreenshots: boolean
) => {
  const spaceIds = await getSpaceIds();
  return Promise.all(
    spaceIds.map(spaceId =>
      checkSpaceForModifications(spaceId, forceScreenshots)
    )
  );
};
