import { compact } from 'lodash';
import { CurrencyCode, ICurrencyConverter } from '../../domainTypes/currency';
import { IDenormalizedClickEvent } from '../../domainTypes/denormalization';
import { Doc } from '../../domainTypes/document';
import {
  expandPostgresSaleFlat,
  IConvertedSale,
  IPostgresSale,
  IPostgresSaleFlat,
  ITrackedConvertedSale,
  SaleStatus,
  TransactionsArgs
} from '../../domainTypes/performance';
import { usePromise } from '../../hooks/usePromise';
import { CF, FS } from '../../versions';
import { toChecksum } from '../checksum';
import { CURRENCY_CONVERTER } from '../currency/converter';
import { refreshTimestamp } from '../db';
import { callFirebaseFunction } from '../firebaseFunctions';
import { getMomentInTz, MomentRange } from '../time';
import { getSalesCacheforSpace, useSalesCacheContext } from './cache';

const expandAndRefresh = (xs: IPostgresSaleFlat[]): IPostgresSale[] => {
  return xs.map((flatRow) => {
    const row = expandPostgresSaleFlat(flatRow);
    refreshRowTimestamps(row);
    return row;
  });
};

const toTrackedConvertedSales = async (
  xs: IPostgresSale[],
  currency: CurrencyCode,
  tz: string
): Promise<ITrackedConvertedSale[]> => {
  const saleDates = xs.map((r) => r.sale_date.toMillis()).sort();
  await CURRENCY_CONVERTER.preload(
    saleDates[0],
    saleDates[saleDates.length - 1]
  );

  const convertedSales = await Promise.all(
    xs.map((r) => {
      return postgresSaleToITrackedConvertedSale(
        r,
        currency,
        tz,
        CURRENCY_CONVERTER
      );
    })
  );

  return convertedSales.map((s) => s.data);
};

const _getSalesByIdRaw = (spaceId: string, ids: string[]) => {
  return callFirebaseFunction<{
    time: number;
    spaceId: string;
    ids: string[];
    res: IPostgresSaleFlat[];
  }>('sales-getTransactionsById', {
    spaceId,
    ids
  }).then((x) => ({
    rows: expandAndRefresh(x.res),
    time: x.time
  }));
};

export const getSalesConvertedById = async (
  spaceId: string,
  ids: string[],
  currency: CurrencyCode,
  tz: string
) => {
  const rawSales = await _getSalesByIdRaw(spaceId, ids);
  return toTrackedConvertedSales(rawSales.rows, currency, tz);
};

const _getSalesRaw = (spaceId: string, q: TransactionsArgs) => {
  return callFirebaseFunction<{
    time: number;
    spaceId: string;
    q: TransactionsArgs;
    res: IPostgresSaleFlat[];
  }>('sales-getTransactions', {
    spaceId,
    q
  });
};

const getTrackedConvertedSaleDates = (
  row: IPostgresSale,
  tz: string
): ITrackedConvertedSale['dates'] => {
  return {
    sale: getMomentInTz(tz, row.sale_date.toMillis()),
    completion: row.completion_date
      ? getMomentInTz(tz, row.completion_date.toMillis())
      : null,
    click: row.click_date ? getMomentInTz(tz, row.click_date.toMillis()) : null
  };
};

const USE_REPORTING_CURRENCY = true;

export const postgresSaleToITrackedConvertedSale = async (
  d: IPostgresSale,
  currency: CurrencyCode,
  tz: string,
  currencyConverter: ICurrencyConverter
): Promise<Doc<ITrackedConvertedSale>> => {
  const status = (d.sale_status[0].toUpperCase() +
    d.sale_status.slice(1)) as SaleStatus;

  const trackingLabel = (function () {
    if (d.tracking_id && d.tracking_id !== d.tracking_label) {
      return [d.tracking_label, d.tracking_id].filter((t) => t).join('_');
    }
    return d.tracking_label;
  })();

  const saleDate = d.sale_date;

  const reportingCurrency = d.r_currency?.toUpperCase() as CurrencyCode;
  const originalCurrency = d.o_currency.toUpperCase() as CurrencyCode;

  const sale: IConvertedSale = {
    orderId: d.order_id || undefined,
    saleId: d.sale_id,
    trackingId: d.tracking_id,
    trackingLabel,
    reportId: d.report_id,
    integrationId: d.integration_id,
    saleDate,
    completionDate: d.completion_date,
    partnerKey: d.partner_key,
    partnerProductName: d.partner_product_name,
    partnerProductId: d.partner_product_id,
    advertiserId: d.advertiser_id || '',
    advertiserName: d.advertiser_name || '',
    originalAmount: {
      currency: originalCurrency,
      price: d.o_price,
      revenue: d.o_revenue,
      commission: d.o_commission || 0
    },
    amount: USE_REPORTING_CURRENCY
      ? currency === reportingCurrency
        ? {
            currency: reportingCurrency,
            price: d.r_price,
            revenue: d.r_revenue,
            commission: d.r_commission || 0
          }
        : {
            currency,
            price: await currencyConverter.convertNullable(
              d.o_price,
              originalCurrency,
              currency,
              saleDate
            ),
            revenue: await currencyConverter.convertNullable(
              d.o_revenue,
              originalCurrency,
              currency,
              saleDate
            ),
            commission: await currencyConverter.convert(
              d.o_commission || 0,
              originalCurrency,
              currency,
              saleDate
            )
          }
      : currency === 'USD'
      ? {
          currency: 'USD',
          price: d.price,
          revenue: d.revenue,
          commission: d.commission || 0
        }
      : {
          currency,
          price: await currencyConverter.convertNullable(
            d.o_price,
            originalCurrency,
            currency,
            saleDate
          ),
          revenue: await currencyConverter.convertNullable(
            d.o_revenue,
            originalCurrency,
            currency,
            saleDate
          ),
          commission: await currencyConverter.convert(
            d.o_commission || 0,
            originalCurrency,
            currency,
            saleDate
          )
        },
    conversionRate: 0, // unused
    commissionPercent: d.comm_percent === null ? null : d.comm_percent / 100,
    quantity: d.quantity,
    device: d.device,
    referrerUrl: d.referrer_url || '',
    origin: d.origin,
    clickDate: d.click_date || undefined,
    metadata: d.metadata,
    status,
    payoutId: d.payout_id,
    payoutDate: d.payout_date,
    payoutStatus: d.payout_status,
    saleType: d.sale_type,
    lastModified: d.last_modified,
    coupon: d.coupon || undefined
  };
  const click: IDenormalizedClickEvent | null =
    d.click_pv_id !== null &&
    d.click_p_id !== null &&
    d.click_t_id !== null &&
    d.click_country !== null &&
    d.click_device !== null &&
    d.click_href !== null &&
    d.click_occ !== null &&
    d.click_date !== null
      ? {
          sId: d.space_id,
          pvId: d.click_pv_id,
          tId: d.click_t_id,
          device: d.click_device,
          country: d.click_country,
          href: d.click_href,
          pId: d.click_p_id,
          occ: d.click_occ,
          createdAt: d.click_date,
          cId: d.channel_id
        }
      : null;
  const data: ITrackedConvertedSale = {
    spaceId: d.space_id,
    queryDate: d.click_date || d.sale_date,
    sale,
    click,
    createdAt: d.created_at,
    createdBy: d.created_by,
    pageUrl: d.page_url,
    origin: d.origin,
    device: d.device,
    labelRuleId: d.label_rule_id || '',
    dates: getTrackedConvertedSaleDates(d, tz),
    saleType: d.sale_type,
    channelId: d.channel_id
  };
  return {
    id: d.orig_id,
    collection: FS.sales,
    data: data
  };
};

const refreshRowTimestamps = (row: IPostgresSale) => {
  row.sale_date = refreshTimestamp(row.sale_date);
  row.completion_date = refreshTimestamp(row.completion_date);
  row.click_date = refreshTimestamp(row.click_date);
  row.created_at = refreshTimestamp(row.created_at);
  row.payout_date = refreshTimestamp(row.payout_date);
  row.last_modified = refreshTimestamp(row.last_modified);
};

const getCacheForSpace = (spaceId: string) =>
  getSalesCacheforSpace(spaceId).sales;

// only call after you've made sure that the cache is filled!
const getFromCacheUnsafe = (
  spaceId: string,
  checksum: string
): Promise<IPostgresSale[]> => {
  const cache = getCacheForSpace(spaceId);
  return cache.queries[checksum].then((ids) =>
    compact(ids.map((id) => cache.entities[id]))
  );
};

const isCached = (spaceId: string, checksum: string) => {
  const cache = getCacheForSpace(spaceId);
  return !!cache.queries[checksum];
};

const getViaCache = async (
  spaceId: string,
  checksum: string,
  request: () => Promise<{ rows: IPostgresSale[]; time: number }>
) => {
  if (isCached(spaceId, checksum)) {
    return getFromCacheUnsafe(spaceId, checksum).then((rows) => ({
      rows,
      cached: true,
      time: 0
    }));
  }
  const cache = getCacheForSpace(spaceId);
  const req = request();
  cache.queries[checksum] = req.then((rs) => rs.rows.map((r) => r.id));
  const res = await req;
  res.rows.forEach((r) => (cache.entities[r.id] = r));
  return { rows: res.rows, time: res.time, cached: false };
};

const tryGetSalesCached = (spaceId: string, q: TransactionsArgs) => {
  const checksum = toChecksum({ spaceId, q });
  return getViaCache(spaceId, checksum, async () => {
    const r = await _getSalesRaw(spaceId, q);
    const rows = expandAndRefresh(r.res);
    return { rows, time: r.time };
  });
};

export const getSalesConverted = async (
  spaceId: string,
  q: TransactionsArgs,
  currency: CurrencyCode
) => {
  const requestStart = Date.now();
  const { rows, cached, time: dbTime } = await tryGetSalesCached(spaceId, q);
  const totalRequestTime = Date.now() - requestStart;
  if (!rows.length) {
    console.log('getSalesConverted', {
      cached,
      request: totalRequestTime,
      db: dbTime,
      conversion: 0,
      data: []
    });
    return [];
  }

  const convertedSales = await toTrackedConvertedSales(
    rows,
    currency,
    q.dates.tz
  );

  console.log('getSalesConverted', {
    cached,
    request: totalRequestTime,
    db: dbTime,
    data: convertedSales
  });
  return convertedSales;
};

export const useSalesConverted = (
  spaceId: string,
  q: TransactionsArgs,
  currency: CurrencyCode
) => {
  const { version } = useSalesCacheContext();
  return usePromise(() => {
    return getSalesConverted(
      spaceId,
      {
        ...q,
        limit: Math.min(500, q.limit ?? 500)
      },
      currency
    );
  }, [spaceId, q, currency, version]);
};

export const scheduleImportJobs = async ({
  spaceId,
  instanceId,
  handlerName,
  range
}: {
  spaceId: string;
  instanceId: string;
  handlerName: string;
  range: MomentRange;
}) => {
  return callFirebaseFunction(CF.reporting.scheduleReportingJobs, {
    spaceId: spaceId,
    instanceId: instanceId,
    handler: handlerName,
    type: 'reporting',
    range: {
      start: range.start.format('YYYY-MM-DD'),
      end: range.end.format('YYYY-MM-DD')
    }
  });
};
