import { AnyObject } from 'final-form';
import { Omit } from 'lodash';
import React from 'react';
import {
  Form as ReactFinalForm,
  FormProps,
  FormRenderProps,
  FormSpy,
  FormSpyProps
} from 'react-final-form';
import { FormAutoSave } from './FormAutoSave';
import { FormInterceptor } from './FormInterceptor';

type Props<T = AnyObject> = Omit<FormProps<T>, 'children'> &
  Pick<FormSpyProps, 'onChange' | 'subscription'> & {
    children: (props: FormRenderProps<T>) => React.ReactNode;
    autoSave?: boolean;
    interceptor?: boolean;
    className?: string;
  };

export const Form = <T extends {} = AnyObject>({
  onChange,
  children,
  subscription,
  autoSave,
  interceptor,
  className,
  ...otherProps
}: Props<T>) => (
  <ReactFinalForm<T> {...otherProps}>
    {formRenderProps => (
      <form
        onSubmit={formRenderProps.handleSubmit}
        className={className}
        noValidate
      >
        {onChange && (
          <FormSpy onChange={onChange} subscription={subscription} />
        )}
        {!!autoSave && <FormAutoSave />}
        {!!interceptor && <FormInterceptor />}
        {children && children(formRenderProps)}
      </form>
    )}
  </ReactFinalForm>
);

Form.defaultProps = {
  subscription: { values: true, submitting: true }
};
