import { Button, Paper, Popover } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { isEmpty, isEqual, isNil, isNumber } from 'lodash';
import moment, { Moment, unitOfTime } from 'moment-timezone';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ChevronDown, Info } from 'react-feather';
import { AnalyticsRanges } from '../../../../../components/analytics_v2/Timeframe';
import { TimePresetSelector } from '../../../../../components/TimeframePicker/TimePresetSelector';
import { TimeframeDefinition } from '../../../../../domainTypes/timeframe';
import { styled } from '../../../../../emotion';
import css from '../../../../../emotion/css';
import { getPreviousMoments } from '../../../../../hooks/timeframe/toMoments';
import { FlexContainer } from '../../../../../layout/Flex';
import { useQueryParam } from '../../../../../routes';
import { useMixpanel } from '../../../../../services/mixpanel';
import { guessCurrentTimezone } from '../../../../../services/time';

const PERIOD_PRESETS: PeriodOption[] = [
  {
    label: 'Today',
    value: {
      type: 'current',
      unit: 'day'
    }
  },
  {
    label: 'Yesterday',
    value: {
      type: 'previous',
      unit: 'day'
    }
  },
  {
    label: 'Last hour',
    value: {
      type: 'latest',
      duration: 'PT1H'
    }
  },
  {
    label: 'Last 6 hours',
    value: {
      type: 'latest',
      duration: 'PT6H'
    }
  },
  {
    label: 'Last 12 hours',
    value: {
      type: 'latest',
      duration: 'PT12H'
    }
  },
  {
    label: 'Last 24 hours',
    value: {
      type: 'latest',
      duration: 'PT24H'
    }
  },
  {
    label: 'Last 48 hours',
    value: {
      type: 'latest',
      duration: 'PT48H'
    }
  },
  {
    label: 'Last 7 days',
    value: {
      type: 'latest',
      duration: 'P7D'
    }
  }
];

const COMPARISON_PRESETS: ComparisonOption[] = [
  {
    label: 'No comparison',
    value: { kind: 'disabled' }
  },
  {
    label: 'Previous period',
    value: { kind: 'previous' }
  },
  {
    label: 'Day over day',
    value: { kind: 'timeshift', duration: 'P1D' }
  },
  {
    label: 'Week over week',
    value: { kind: 'timeshift', duration: 'P1W' }
  },
  {
    label: 'Month over month',
    value: { kind: 'timeshift', duration: 'P1M' }
  },
  {
    label: 'Year over year',
    value: { kind: 'timeshift', duration: 'P1Y' }
  },
  {
    label: 'Year over year (BFCM)',
    value: {
      kind: 'timeshift',
      duration: 'P53W'
    }
  },
  {
    label: 'Nov 24, 2023 (Black Friday)',
    value: {
      kind: 'day',
      start: '2023-11-24',
      end: '2023-11-24'
    }
  },
  {
    label: 'Nov 25, 2023 (Saturday)',
    value: {
      kind: 'day',
      start: '2023-11-25',
      end: '2023-11-25'
    }
  },
  {
    label: 'Nov 26, 2023 (Sunday)',
    value: {
      kind: 'day',
      start: '2023-11-26',
      end: '2023-11-26'
    }
  },
  {
    label: 'Nov 27, 2023 (Cyber Monday)',
    value: {
      kind: 'day',
      start: '2023-11-27',
      end: '2023-11-27'
    }
  },
  {
    label: 'Nov 28 2023 (Tuesday)',
    value: {
      kind: 'day',
      start: '2023-11-28',
      end: '2023-11-28'
    }
  }
];

const Date: React.FC<{ m: Moment }> = ({ m }) => {
  const isCurrentYear = m.year() === moment().year();
  const format = isCurrentYear ? 'MM/DD' : 'MM/DD/YY';

  return (
    <>
      <strong>{m.format(format)}</strong>, {m.format('hh:mmA')}
    </>
  );
};

const DateWrapper = styled('div')`
  min-width: 250px;
  text-align: center;
`;

const Range: React.FC<{ start: Moment; end: Moment }> = ({ start, end }) => (
  <DateWrapper>
    <Date m={start} /> &ndash; <Date m={end} />
  </DateWrapper>
);

interface PeriodMoments {
  start: Moment;
  end: Moment;
  compare?: {
    start: Moment;
    end: Moment;
  };
}

interface PeriodMenuState {
  isValid: boolean;
  moments: PeriodMoments;
  periodDefinition: PeriodDefinition;
  presetSelectorProps: {
    value: PeriodRangeDefinition;
    onChange: (value: PeriodRangeDefinition) => void;
    options: PeriodOption[];
  };
  comparisonPresetSelectProps: {
    value: PeriodCompareDefinition;
    onChange: (value: PeriodCompareDefinition) => void;
    options: ComparisonOption[];
  };
}

const getPeriodMoments = (
  range: PeriodRangeDefinition,
  now: moment.Moment
): { start: Moment; end: Moment } => {
  switch (range.type) {
    case 'latest': {
      const start = now.clone().subtract(moment.duration(range.duration));
      return { start, end: now };
    }
    case 'previous': {
      const ref = now.clone().subtract(1, range.unit);
      return {
        start: ref.clone().startOf(range.unit),
        end: ref.clone().endOf(range.unit)
      };
    }
    case 'current': {
      return {
        start: now.clone().startOf(range.unit),
        end: now.clone().endOf(range.unit)
      };
    }
  }
};

const getCompareMoments = (
  compare: PeriodCompareDefinition,
  { start, end }: { start: Moment; end: Moment },
  tz: string
): { start: Moment; end: Moment } | undefined => {
  switch (compare.kind) {
    case 'disabled':
      return undefined;
    case 'previous':
      return getPreviousMoments({ start, end });
    case 'timeshift': {
      return {
        start: start.clone().subtract(moment.duration(compare.duration)),
        end: end.clone().subtract(moment.duration(compare.duration))
      };
    }
    case 'day': {
      return {
        start: moment(compare.start).tz(tz).startOf('day'),
        end: moment(compare.end).tz(tz).endOf('day')
      };
    }
  }
};

const toMoments = (
  period: PeriodDefinition,
  {
    now = moment().tz(period.tz)
  }: {
    now?: Moment;
  } = {}
): PeriodMoments => {
  const { start, end } = getPeriodMoments(period.range, now);
  const compare = getCompareMoments(period.compare, { start, end }, period.tz);
  return { start, end, compare };
};

const toAnalyticsRanges = (
  period: PeriodDefinition,
  {
    now = moment().tz(period.tz)
  }: {
    now?: Moment;
  } = {}
) => {
  const { start, end, compare } = toMoments(period, { now });
  return {
    range: {
      start: start.toISOString(),
      end: end.toISOString()
    },
    compare: compare
      ? {
          range: {
            start: compare.start.toISOString(),
            end: compare.end.toISOString()
          }
        }
      : undefined
  };
};

const getPeriodPreset = (range: PeriodRangeDefinition) => {
  return PERIOD_PRESETS.find((option) => isEqual(option.value, range));
};

const getComparisonPreset = (compare: PeriodCompareDefinition) => {
  return COMPARISON_PRESETS.find((option) => isEqual(option.value, compare));
};

function validateRanges({ end, compare, start }: PeriodMoments) {
  if (!compare) return true;
  const diffInSeconds =
    end.diff(start, 's') - compare.end.diff(compare.start, 's');
  return Math.abs(diffInSeconds) < 1;
}

const periodRangeToTrackingProps = (definition: PeriodRangeDefinition) => {
  const preset = getPeriodPreset(definition);
  return {
    range_label: preset?.label || '',
    range_type: preset?.value.type || '',
    range_duration:
      preset?.value.type === 'latest'
        ? preset.value.duration
        : preset?.value.unit || ''
  };
};

const periodCompareToTrackingProps = (definition: PeriodCompareDefinition) => {
  const preset = getComparisonPreset(definition);
  return {
    compare_label: preset?.label || '',
    compare_kind: preset?.value.kind || '',
    compare_duration:
      preset?.value.kind === 'timeshift'
        ? preset.value.duration
        : preset?.value.kind === 'day'
        ? `${preset.value.start} - ${preset.value.end}`
        : ''
  };
};

const usePeriodMenuState = (
  value: PeriodDefinition,
  presets: PeriodOption[],
  comparisonPresets: ComparisonOption[]
): PeriodMenuState => {
  const mixpanel = useMixpanel();
  const [periodDefinition, setPeriodDefinition] = useState(value);

  return useMemo(() => {
    const moments = toMoments(periodDefinition);
    const isValid = validateRanges(moments);

    return {
      isValid,
      moments,
      periodDefinition: periodDefinition,
      presetSelectorProps: {
        value: periodDefinition.range,
        onChange: (range: PeriodRangeDefinition) => {
          setPeriodDefinition((state) => ({
            ...state,
            range
          }));
          mixpanel.track(
            'period_selector_timeframe_period_selected',
            periodRangeToTrackingProps(range)
          );
        },
        options: presets
      },
      comparisonPresetSelectProps: {
        value: periodDefinition.compare,
        onChange: (compare: PeriodCompareDefinition) => {
          setPeriodDefinition((state) => ({
            ...state,
            compare
          }));
          mixpanel.track(
            'period_selector_compare_period_selected',
            periodCompareToTrackingProps(compare)
          );
        },
        options: comparisonPresets
      }
    };
  }, [periodDefinition, presets, comparisonPresets, mixpanel]);
};

const PeriodMenuGrid = styled('div')((p) => ({
  padding: p.theme.spacing(2),
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: p.theme.spacing(2)
}));

const PeriodMenu: React.FC<{
  initialValue: PeriodDefinition;
  onSave: (period: PeriodDefinition) => void;
}> = ({ initialValue, onSave }) => {
  const {
    isValid,
    periodDefinition,
    presetSelectorProps,
    comparisonPresetSelectProps,
    moments
  } = usePeriodMenuState(initialValue, PERIOD_PRESETS, COMPARISON_PRESETS);
  const timeframeLabel = useMemo(
    () => <Range start={moments.start} end={moments.end} />,
    [moments]
  );
  const comparisonLabel = useMemo(
    () =>
      moments.compare ? (
        <Range start={moments.compare.start} end={moments.compare.end} />
      ) : (
        ' - '
      ),
    [moments]
  );
  return (
    <Paper>
      <PeriodMenuGrid>
        <Typography>Timeframe</Typography>
        <Typography>Compared to</Typography>
        <TimePresetSelector {...presetSelectorProps} />
        <TimePresetSelector {...comparisonPresetSelectProps} />
        <Typography variant="caption" color="textSecondary">
          {timeframeLabel}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {comparisonLabel}
        </Typography>
      </PeriodMenuGrid>
      <FlexContainer
        className={css((t) => ({
          backgroundColor: t.palette.grey.A100,
          padding: t.spacing(2),
          justifyContent: 'space-between'
        }))}
      >
        <FlexContainer>
          <Info size={14} color="rgba(0, 0, 0, 0.54)" />
          <Typography variant="body2" color="textSecondary">
            Your browser timezone is{' '}
            <span className={css(() => ({ fontWeight: 'bold' }))}>
              {periodDefinition.tz}
            </span>
            .{' '}
          </Typography>
        </FlexContainer>

        <Button
          variant="contained"
          color="primary"
          disabled={!isValid}
          onClick={() => {
            if (!isValid) return;
            onSave(periodDefinition);
          }}
        >
          Apply timeframe
        </Button>
      </FlexContainer>
    </Paper>
  );
};

const DEFAULT_PERIOD: PeriodDefinition = {
  tz: guessCurrentTimezone(),
  range: {
    type: 'latest',
    duration: 'PT48H'
  },
  compare: {
    kind: 'previous'
  }
};

type PeriodRangeDefinition =
  | {
      type: 'latest';
      duration: string; // ISODuration
    }
  | {
      type: 'previous';
      unit: unitOfTime.DurationConstructor;
    }
  | {
      type: 'current';
      unit: unitOfTime.StartOf;
    };

type PeriodCompareDefinition =
  | {
      kind: 'day';
      start: string; // YYYY-MM-DD
      end: string; // YYYY-MM-DD
    }
  | {
      kind: 'previous';
    }
  | {
      kind: 'disabled';
    }
  | {
      kind: 'timeshift';
      duration: string; // ISODuration
    };

type PeriodDefinition = {
  range: PeriodRangeDefinition;
  compare: PeriodCompareDefinition;
  tz: string;
};

interface PeriodOption {
  value: PeriodRangeDefinition;
  label: string;
}

interface ComparisonOption {
  value: PeriodCompareDefinition;
  label: string;
}

const parsePeriod = (p: string | undefined | null): PeriodDefinition | null => {
  if (isNil(p) || isEmpty(p)) return null;
  // Backward compatibility
  const possibleNumber = Number(p);
  if (isNumber(possibleNumber) && isFinite(possibleNumber)) {
    const duration = `PT${p}H`;
    const hasPreset = PERIOD_PRESETS.some(
      (p) => p.value.type === 'latest' && p.value.duration === duration
    );
    if (!hasPreset) return null;
    return {
      ...DEFAULT_PERIOD,
      range: {
        type: 'latest',
        duration
      }
    };
  }
  try {
    return JSON.parse(decodeURIComponent(p));
  } catch {
    return null;
  }
};

const printPeriod = (period: PeriodDefinition) =>
  encodeURIComponent(JSON.stringify(period));

const PERIOD_PARAM_NAME = 'period';
const usePeriodParam = () =>
  useQueryParam<PeriodDefinition>(
    PERIOD_PARAM_NAME,
    (p) => parsePeriod(p) || DEFAULT_PERIOD,
    printPeriod
  );

const PeriodLabel: React.FC<{ definition: PeriodDefinition }> = ({
  definition
}) => {
  const label = useMemo(() => {
    const { range, compare } = definition;

    const timeframeOption = getPeriodPreset(range);
    const timeFrameLabel = timeframeOption ? timeframeOption.label : '-';

    const comparisonLabel = (() => {
      if (compare.kind === 'disabled') return undefined;
      if (compare.kind === 'previous') return 'Previous period';
      if (compare.kind === 'timeshift' || compare.kind === 'day') {
        const comparisonOption = getComparisonPreset(compare);
        if (comparisonOption) {
          return comparisonOption.label;
        }
      }
      const { compare: compareMoments } = toMoments(definition);
      if (compareMoments) {
        return <Range start={compareMoments.start} end={compareMoments.end} />;
      }
      return undefined;
    })();

    return (
      <Typography variant="body2">
        {timeFrameLabel}{' '}
        {comparisonLabel ? (
          <>
            <span className={css((t) => ({ color: t.palette.text.secondary }))}>
              vs.
            </span>{' '}
            {comparisonLabel}
          </>
        ) : (
          ''
        )}
      </Typography>
    );
  }, [definition]);
  return <span>{label}</span>;
};

export const PeriodSelector: React.FC = () => {
  const mixpanel = useMixpanel();
  const [open, setOpen] = useState(false);
  const closeMenu = useCallback(() => {
    setOpen(false);
    mixpanel.track('period_selector_closed');
  }, [mixpanel]);
  const openMenu = useCallback(() => {
    setOpen(true);
    mixpanel.track('period_selector_opened');
  }, [mixpanel]);
  const ref = useRef<HTMLButtonElement>(null);

  const [definition, setDefinition] = usePeriodParam();

  const onSave = useCallback(
    (def: PeriodDefinition) => {
      setDefinition(def);
      mixpanel.track('period_selector_applied', {
        ...periodRangeToTrackingProps(def.range),
        ...periodCompareToTrackingProps(def.compare),
        _tf: toAnalyticsRanges(def)
      });
      closeMenu();
    },
    [closeMenu, setDefinition, mixpanel]
  );

  return (
    <>
      <Popover
        open={open}
        onClose={closeMenu}
        anchorEl={ref.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        style={{ zIndex: 99998 }}
      >
        <PeriodMenu initialValue={definition} onSave={onSave} />
      </Popover>
      <Button ref={ref} onClick={openMenu}>
        <FlexContainer style={{ flexShrink: 0 }}>
          <PeriodLabel definition={definition} />
          <ChevronDown
            size={16}
            className={css((t) => ({
              marginLeft: t.spacing(1),
              color: 'rgba(0, 0, 0, 0.54)'
            }))}
          />
        </FlexContainer>
      </Button>
    </>
  );
};

export const usePeriodRange = (endDate: number): AnalyticsRanges => {
  const [definition] = usePeriodParam();
  return useMemo(
    () => toAnalyticsRanges(definition, { now: moment(endDate) }),
    [definition, endDate]
  );
};

export const usePeriodRangeToTimeframe = (): TimeframeDefinition => {
  const [definition] = usePeriodParam();
  return useMemo(
    () => ({
      range: {
        kind: 'period',
        value: definition.range
      },
      comparison:
        definition.compare.kind === 'day'
          ? { kind: 'disabled' }
          : definition.compare
    }),
    [definition.compare, definition.range]
  );
};
