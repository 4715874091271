import React from 'react';
import { styled } from '../../emotion';

export const InfoTabs = styled('div')`
  display: flex;
`;

const InfoTabStyled = styled('div')(p => {
  const { custom: c, palette, spacing } = p.theme;
  return `
    cursor: ${p.role === 'button' ? 'pointer' : 'default'};
    font-size: ${c.fontSize.l}px;
    padding: ${spacing() * 2}px ${spacing() * 2}px;
    min-width: 200px;
    color: ${palette.text.primary};
    background-color: ${palette.background.paper};
    text-align: center;
  `;
});

export const InfoTab: React.FC<{ onClick?: () => void }> = ({
  onClick,
  children
}) => {
  return (
    <InfoTabStyled onClick={onClick} role={onClick && 'button'}>
      {children}
    </InfoTabStyled>
  );
};

export const InfoTabLabel = styled('label')(p => {
  const { custom: c, palette } = p.theme;
  return `
    font-size: ${c.fontSize.s}px;
    color: ${palette.text.secondary};
    text-transform: uppercase;
    text-align: center;
  `;
});

export const InfoTabBody = styled('div')``;
