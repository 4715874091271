import { Button, Popover } from '@material-ui/core';
import React, { useCallback, useRef, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { css } from '../../../../emotion';
import { SearchInput } from '../../../SearchInput';
import { Dimension } from '../filters';
import { DimensionList } from './DimensionList';
import { FilterDrawerRenderContext } from './types';

interface MenuBodyProps {
  selectDimension: (d: Dimension) => void;
  availableDimensions: Dimension[];
  renderContext?: FilterDrawerRenderContext;
}

const MenuBody: React.FC<MenuBodyProps> = ({
  selectDimension,
  availableDimensions,
  renderContext
}) => {
  const [search, onSearchChange] = useState('');

  return (
    <div>
      <div
        className={css((t) => ({
          padding: t.spacing(2),
          paddingBottom: t.spacing(1)
        }))}
      >
        <SearchInput
          size="small"
          placeholder="Find a filter"
          value={search}
          onChange={onSearchChange}
          fullWidth
          autoFocus
        />
      </div>
      <div className={css(() => ({ maxHeight: 360, overflow: 'scroll' }))}>
        <DimensionList
          search={search}
          availableDimensions={availableDimensions}
          selectDimension={selectDimension}
          renderContext={renderContext}
        />
      </div>
    </div>
  );
};

interface AddFilterMenuProps {
  availableDimensions: Dimension[];
  selectDimension: (d: Dimension) => void;
  renderContext?: FilterDrawerRenderContext;
}

export const AddFilterMenu: React.FC<AddFilterMenuProps> = ({
  selectDimension,
  availableDimensions,
  renderContext
}) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  const toggle = useCallback(() => setOpen((x) => !x), []);
  const close = useCallback(() => setOpen(false), []);
  const _selectDimension = useCallback(
    (d: Dimension) => {
      close();
      selectDimension(d);
    },
    [close, selectDimension]
  );
  return (
    <>
      <Button
        onClick={toggle}
        ref={ref}
        color="primary"
        startIcon={<PlusCircle size={18} />}
      >
        Add a filter
      </Button>
      <Popover
        PaperProps={{ square: false }}
        open={isOpen}
        onClose={close}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <MenuBody
          availableDimensions={availableDimensions}
          selectDimension={_selectDimension}
          renderContext={renderContext}
        />
      </Popover>
    </>
  );
};
