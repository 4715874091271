import { TableCell, TableSortLabel } from '@material-ui/core';
import { Padding } from '@material-ui/core/TableCell';
import cN from 'classnames';
import { Interpolation } from 'emotion';
import React, { useEffect, useMemo, useState } from 'react';
import { AutoSizer, Column, Index, Table } from 'react-virtualized';
import { css } from '../../../emotion';
import {
  getNextDirection,
  SortDirection,
  SortFn,
  useSort
} from '../../../hooks/useSort';
import { AffilimateTheme } from '../../../themes';
import { IColumn } from '../Column';

export type VirtualizedSortableTableProps<
  RowData,
  SortKey,
  CellProps = undefined
> = {
  columns: IColumn<RowData, SortKey, CellProps>[];
  rows: RowData[];
  height: number; // or null to calculate it automatically?
  rowHeight?: number;
  margin: 'dense' | 'normal';
  initialSortColumn: IColumn<RowData, SortKey, CellProps>;
  initialSortDirection?: SortDirection;
  padding?: Padding;
  cellProps: CellProps;
  rowClassNameFn?: (
    item: RowData | null,
    theme: AffilimateTheme
  ) => Interpolation; // null is the header row
  sortFn: (
    rows: RowData[],
    sortBy: SortKey,
    direction: SortDirection
  ) => RowData[];
  onSortChange?: (sortBy: SortKey, direction: SortDirection) => void;

  onRowClick?: (row: RowData) => void;

  visibleColumns?: Set<SortKey>;
};

const convertSortDirection = (
  dir: SortDirection
): 'ASC' | 'DESC' | undefined => {
  if (dir === 'asc') {
    return 'ASC';
  }
  if (dir === 'desc') {
    return 'DESC';
  }
  return undefined;
};

const SortableHead = <SortKey extends {}, CellProps = undefined>({
  column,
  currentDirection,
  currentSortBy,
  setSort,
  className,
  padding,
  cellProps
}: {
  column: IColumn<any, SortKey, CellProps>;
  setSort: SortFn<SortKey>;
  currentSortBy: SortKey;
  currentDirection: SortDirection;
  className: string;
  padding?: Padding;
  cellProps: CellProps;
}) => {
  const onSort = (cell: IColumn<any, SortKey, CellProps>) => {
    const nextDir =
      column.key === currentSortBy
        ? getNextDirection(currentDirection)
        : cell.defaultDirection || getNextDirection();
    setSort(cell.key, nextDir);
  };
  return (
    <TableCell
      component="div"
      align={column.align}
      variant="head"
      className={className}
      padding={padding}
    >
      {column.sortable ? (
        <TableSortLabel
          active={column.key === currentSortBy}
          direction={currentDirection}
          onClick={() => onSort(column)}
        >
          {column.head(cellProps)}
        </TableSortLabel>
      ) : (
        column.head(cellProps)
      )}
    </TableCell>
  );
};

const STANDARD_ROW_HEIGHT = 48;

const getStandardHeaderHeight = (padding?: Padding) => {
  return 56;
};

const getClassNames = (clickable: boolean) => {
  const classNames = {
    table: css((t) => ({ fontFamily: t.typography.fontFamily })),
    cell: css(() => ({ flex: 1, height: 'inherit' })),
    cellContainer: css(() => ({ height: 'inherit' })),
    row: css(() => ({ cursor: clickable ? 'pointer' : 'default' })),
    flex: css(() => ({
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box'
    }))
  };

  return {
    table: cN(classNames.table),
    row: cN(classNames.row, classNames.flex),
    cell: cN(classNames.cell, classNames.flex),
    cellContainer: cN(classNames.cellContainer)
  };
};

const useRowSort = <T extends {}>(sortFn: () => T[], listeners: any[]) => {
  const [sortedRows, setSortedRows] = useState(sortFn());
  useEffect(() => {
    setSortedRows(sortFn());
    // eslint-disable-next-line
  }, listeners);

  return sortedRows;
};

export const VirtualizedSortableTable = <
  RowData extends {},
  SortKey extends string,
  CellProps = undefined
>({
  columns,
  rows,
  height,
  rowHeight = STANDARD_ROW_HEIGHT,
  initialSortColumn,
  initialSortDirection,
  sortFn,
  onSortChange,
  onRowClick,
  padding,
  cellProps,
  rowClassNameFn,
  visibleColumns
}: VirtualizedSortableTableProps<RowData, SortKey, CellProps>) => {
  const { sortBy, direction, setSort } = useSort(
    initialSortColumn.key,
    initialSortDirection || initialSortColumn.defaultDirection
  );
  const onSort = (k: SortKey, d: SortDirection) => {
    setSort(k, d);
    onSortChange && onSortChange(k, d);
  };
  const sortedRows = useRowSort(() => sortFn(rows, sortBy, direction), [
    rows,
    sortBy,
    direction
  ]);

  const isClickable = !!onRowClick;
  const classes = useMemo(() => getClassNames(isClickable), [isClickable]);
  const headerHeight = getStandardHeaderHeight(padding);

  const rowClassName = rowClassNameFn
    ? ({ index }: Index) => {
        const item = sortedRows[index] || null;
        return cN(
          classes.row,
          css((t) => rowClassNameFn(item, t))
        );
      }
    : classes.row;

  const renderedColumns = visibleColumns
    ? columns.filter((c) => visibleColumns.has(c.key))
    : columns;

  return (
    <AutoSizer disableHeight>
      {({ width }) => {
        return (
          <Table
            width={width}
            height={height}
            headerHeight={headerHeight}
            rowHeight={rowHeight}
            rowCount={sortedRows.length}
            rowGetter={({ index }) => sortedRows[index]}
            sortBy={sortBy}
            sortDirection={convertSortDirection(direction)}
            className={classes.table}
            rowClassName={rowClassName}
            onRowClick={({ index }) =>
              (onRowClick || (() => {}))(sortedRows[index])
            }
          >
            {renderedColumns.map((column) => (
              <Column
                key={column.key}
                className={classes.cellContainer}
                headerClassName={classes.cellContainer}
                dataKey={column.key}
                disableSort={!column.sortable}
                headerRenderer={() => (
                  <SortableHead<SortKey, CellProps>
                    column={column}
                    currentSortBy={sortBy}
                    currentDirection={direction}
                    setSort={onSort}
                    className={classes.cell}
                    padding={padding}
                    cellProps={cellProps}
                  />
                )}
                cellRenderer={({ rowData }) => (
                  <TableCell
                    component="div"
                    align={column.align}
                    variant="body"
                    className={classes.cell}
                  >
                    {column.cell(rowData, cellProps)}
                  </TableCell>
                )}
                width={column.width}
                maxWidth={column.maxWidth}
                flexGrow={column.flexGrow}
                flexShrink={column.flexShrink}
              />
            ))}
          </Table>
        );
      }}
    </AutoSizer>
  );
};
