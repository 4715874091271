import { Omit } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import React from 'react';

type Props = {
  submitComponent: React.ReactNode;
  submitting: boolean;
  disabled: boolean;
};

export const FormSubmitButton = (
  props: Props & Omit<ButtonProps, 'onClick'>
) => {
  const { disabled, submitting, submitComponent, children, ...other } = props;

  return (
    <Button
      variant="contained"
      color="primary"
      {...other}
      disabled={disabled || submitting}
      type="submit"
    >
      {submitting ? submitComponent : children}
    </Button>
  );
};
