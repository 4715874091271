import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useBoolean } from '../../../hooks/useBoolean';
import {
  numberToWeekday,
  WEEKDAYS,
  weekdayToNumber
} from '../../../services/time';

type Props = {
  value: number; // 0 === Sunday,
  onChange: (nextValue: number) => void;
  disabled?: boolean;
};

export const SelectWeekDay: React.FC<Props> = ({
  value,
  onChange,
  disabled = false
}) => {
  const [isOpen, open, close] = useBoolean(false);
  return (
    <Select
      open={isOpen}
      onOpen={open}
      onClose={close}
      disabled={disabled}
      variant="outlined"
      value={numberToWeekday(value)}
      onChange={(ev) => onChange(weekdayToNumber(ev.target.value as string))}
    >
      {WEEKDAYS.map((o) => (
        <MenuItem key={o} value={o}>
          {o}
        </MenuItem>
      ))}
    </Select>
  );
};
