import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Tooltip,
  Typography
} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { TrackingMetaTag, TrackingScriptTag } from '../../components/Snippet';
import { SpaceSwitcher } from '../../components/SpaceSwitcher';
import { TopNavBarSection } from '../../components/TopNavBarSection';
import { css, styled } from '../../emotion';
import { NotificationBell } from '../../features/Notifications/components/Bell';
import { useSpaceSwitcher } from '../../hooks/useSpaceSwitcher';
import { useRoutes } from '../../routes';
import { signOut, useAdminOrImpersonatorClaim } from '../../services/auth';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../services/currentUser';
import { ExperimentalSwitch } from '../../services/experimental';
import { LOGIN, NAV } from '../../testIds';
import * as tracking from '../../tracking';
import { BillingStatus } from './BillingStatus';

type Props = {
  noBorder?: boolean;
};

const StyledTopNavRight = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-self: end;
`;

const StyledTopNavBarSection = styled(TopNavBarSection)`
  justify-self: end;
`;

const AdminItemsWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const TrackingSetupStep = styled('div')((p) => ({
  marginBottom: p.theme.spacing(2)
}));

export const TopNavRight: React.FC<Props> = ({ noBorder }) => {
  const { ROUTES } = useRoutes();
  const currentUser = useCurrentUser();
  const scopes = useCurrentUserScopes();
  const canSeeBilling = scopes.has('billing.view');
  const canSeeAmazonIssues = scopes.has('link_checker.amazon.view');
  const canSeeTrackingSetup = scopes.has('domains.view');
  const [isImpersonatorOrAdmin] = useAdminOrImpersonatorClaim();

  const { switchSpace } = useSpaceSwitcher();
  // prettier-ignore
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // prettier-ignore
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const onClickMenu = (e: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(e.currentTarget);
  const onCloseMenu = () => setAnchorEl(null);
  const menuOpen = anchorEl !== null;

  return (
    <StyledTopNavRight>
      <AdminItemsWrapper>
        <ExperimentalSwitch />
        <SpaceSwitcher onSwitch={switchSpace} labelMaxLength={30} />
        <Hidden mdDown>
          <BillingStatus />
        </Hidden>
      </AdminItemsWrapper>
      <StyledTopNavBarSection minWidth={150}>
        {canSeeAmazonIssues && <NotificationBell />}
        <Popover
          open={menuOpen}
          anchorEl={anchorEl}
          elevation={3}
          onClose={onCloseMenu}
          classes={{
            paper: css(() => ({
              width: '190px',
              position: 'relative',
              border: '1px solid #efefef'
            }))
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <List>
            <NavLink to={ROUTES.settings.profile.url()}>
              <ListItem button>
                <ListItemText primary="Settings" />
              </ListItem>
            </NavLink>
            <NavLink to={ROUTES.settings.notifications.url()}>
              <ListItem button>
                <ListItemText primary="Notifications" />
              </ListItem>
            </NavLink>
            {canSeeTrackingSetup && (
              <ListItem
                button
                onClick={() => {
                  setDialogOpen(true);
                  tracking.sendEvent({
                    category: 'TopNav',
                    action: 'Click',
                    label: 'Code snippet icon'
                  });
                }}
              >
                <ListItemText primary="Tracking Code" />
              </ListItem>
            )}
            {canSeeBilling && (
              <NavLink to={ROUTES.settings.affiliateProgram.url()}>
                <ListItem button>
                  <ListItemText primary="Partner Program" />
                </ListItem>
              </NavLink>
            )}
            {isImpersonatorOrAdmin && (
              <NavLink to={ROUTES.staff.overview.url()}>
                <ListItem button>
                  <ListItemText primary="Staff settings" />
                </ListItem>
              </NavLink>
            )}
            <ListItem button onClick={signOut} data-cy={LOGIN.signOutButton}>
              <ListItemText primary="Sign out" />
            </ListItem>
          </List>
        </Popover>
        <Tooltip title="Your account">
          <IconButton
            data-cy={NAV.userMenu}
            color="inherit"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              tracking.sendEvent({
                category: 'TopNav',
                action: 'Click',
                label: 'User menu'
              });
              return onClickMenu(e);
            }}
            className={css((t) => ({
              padding: '3px',
              border: noBorder ? 'none' : `2px solid ${t.palette.primary.main}`,
              marginLeft: `${t.spacing(1)}px`
            }))}
          >
            <Avatar src={currentUser.photoURL || ''} />
          </IconButton>
        </Tooltip>
        <Dialog
          open={dialogOpen}
          maxWidth="md"
          onClose={() => {
            setDialogOpen(false);
          }}
        >
          <DialogTitle>Tracking Setup</DialogTitle>
          <DialogContent>
            <TrackingSetupStep>
              <TrackingSetupStep>
                <Typography variant="body1">
                  <strong>Step 1.</strong> Inside the {'<head>'}, copy and paste
                  this code to verify ownership of your site (do not remove it
                  after you're verified):
                </Typography>
                <TrackingMetaTag />
                <Typography variant="body1">
                  <strong>Step 1.</strong> Before the closing {'</body>'} tag,
                  copy and paste this code to start tracking:
                </Typography>
                <TrackingScriptTag />
              </TrackingSetupStep>
            </TrackingSetupStep>

            <Typography variant="body1" color="textSecondary" component="p">
              Not sure how to set this up?{' '}
              <a
                href="https://app.affilimate.com/docs/install-snippet/"
                target="_blank"
                style={{ color: '#1890ff', borderBottom: '1px solid' }}
                rel="noreferrer noopener"
              >
                Read our step-by-step guide
              </a>
              .
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="default"
              variant="contained"
              onClick={() => {
                setDialogOpen(false);
              }}
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </StyledTopNavBarSection>
    </StyledTopNavRight>
  );
};
