import { COLORS } from '../../../domainTypes/colors';
import { Badge } from '../../../components/Badge';
import { Recommendation, RecommendationCategory } from '../types';
import { capitalize } from 'lodash';

const BADGE_CONFIG: Record<
  RecommendationCategory,
  { color: string; bgColor: string }
> = {
  ARTICLE_REFRESH: {
    color: COLORS.blue.blue6,
    bgColor: COLORS.blue.blue1
  },
  MERCHANT_BOOST: {
    color: COLORS.green.green8,
    bgColor: COLORS.green.green2
  },
  AMPLIFY_PRODUCT: {
    color: COLORS.gold.gold8,
    bgColor: COLORS.gold.gold2
  },
  REPLACE_LINK: {
    color: COLORS.volcano.volcano8,
    bgColor: COLORS.volcano.volcano2
  },
  IN_PAGE_OPTIMIZATION: {
    color: COLORS.purple.purple8,
    bgColor: COLORS.purple.purple2
  }
};

export const RecommendationsBadge = ({
  recommendation
}: {
  recommendation: Recommendation;
}) => {
  return (
    <Badge
      color={BADGE_CONFIG[recommendation.category].color}
      bgColor={BADGE_CONFIG[recommendation.category].bgColor}
    >
      {capitalize(recommendation.category.replace(/_/g, ' ').toLowerCase())}
    </Badge>
  );
};
