import { sortBy } from 'lodash';
import React from 'react';
import { ICountryCounter } from '../../../domainTypes/analytics';
import { styled } from '../../../emotion';
import { FlagWithLabel } from '../../Flag';
import { Loader } from '../../Loader';
import { EmptyContent } from '../ChartCard';

type Props = {
  counts: ICountryCounter[] | void | undefined;
  loading: boolean;
  maxShown?: number;
};

const HEIGHT = 296;

const RowContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 250px;
  margin-bottom: ${(p) => p.theme.spacing(1)}px;
`;

const Row: React.FC<{ counter: ICountryCounter }> = ({ counter }) => {
  return (
    <RowContainer>
      <FlagWithLabel code={counter.code} />{' '}
      <div>{counter.total.clicked.count.toLocaleString()}</div>
    </RowContainer>
  );
};

export const CountryChart: React.FC<Props> = ({
  counts,
  loading,
  maxShown = 10
}) => {
  if (loading || !counts) {
    return <Loader height={`${HEIGHT}px`} />;
  }

  const toDisplay = sortBy(counts, (c) => -c.total.clicked.count).slice(
    0,
    maxShown
  );

  const hasAnyClicks = !!toDisplay.find((d) => d.total.clicked.count > 0);

  if (!hasAnyClicks) {
    return <EmptyContent />;
  }

  return (
    <div style={{ width: '100%' }}>
      {toDisplay.map((c) => (
        <Row key={c.code} counter={c} />
      ))}
    </div>
  );
};
