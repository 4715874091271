import React from 'react';
import { Redirect } from 'react-router-dom';
import { Routes } from '../../domainTypes/routes';
import { useRoutes } from '../../routes';

export const Redirector = ({
  toPath
}: {
  toPath: (routes: Routes) => string;
}) => {
  const { ROUTES } = useRoutes();
  return <Redirect to={toPath(ROUTES)} />;
};
