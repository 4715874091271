import React from 'react';
import { Helmet } from 'react-helmet';
import { ContentPageBody } from '../../../components/ContentPageBody';
import { utmParameterTitle, UtmParameterType } from './service/utm';
import { UtmTable } from './components/UtmTable';
import { UtmTotals } from './components/UtmTotals';
import { UtmTopBar } from './components/UtmTopBar';
import { useTrackMixpanelView } from '../../../../../services/mixpanel';
import { useHasCurrentUserRequiredScopes } from '../../../../../services/currentUser';
import { useFeatureEnabled } from '../../../../../services/features';
import { NoPermissions } from '../../../../../components/NoPermissions';
import { TrafficSourcesUpgradePrompt } from '../components/TrafficSourcesUpgradePrompt';

interface UtmParameterDetailsProps {
  parameter: UtmParameterType;
}

const Body: React.FC<UtmParameterDetailsProps> = ({ parameter }) => {
  useTrackMixpanelView('view_campaigns', { section: parameter });
  return (
    <>
      <UtmTotals selectedParameter={parameter} />
      <UtmTable selectedParameter={parameter} />
    </>
  );
};

export const UtmParameterDetails: React.FC<UtmParameterDetailsProps> = ({
  parameter
}) => {
  const [canView] = useHasCurrentUserRequiredScopes(['reports.utms.view']);
  const hasTrafficSourcesEnabled = useFeatureEnabled('REFERRER_REPORTS_V1');
  return (
    <>
      <Helmet>
        <title>{utmParameterTitle(parameter)} | Affilimate</title>
      </Helmet>
      <ContentPageBody>
        {canView && hasTrafficSourcesEnabled ? (
          <>
            <UtmTopBar parameter={parameter} />
            <Body parameter={parameter} />
          </>
        ) : !hasTrafficSourcesEnabled ? (
          <TrafficSourcesUpgradePrompt />
        ) : (
          <NoPermissions />
        )}
      </ContentPageBody>
    </>
  );
};
