import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { ArrowRight, Icon } from 'react-feather';
import { styled } from '../emotion';
import { Beacon } from '../services/beacon';

const OuterWrapper = styled('div')`
  margin: ${(p) => p.theme.spacing(4)}px auto;
`;

const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  text-align: center;
  margin: 0 auto;
  border-radius: 100%;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

const ErrorWrapper = styled('div')`
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
`;

export const EmptySearchState = ({
  icon,
  title,
  message,
  color,
  bgColor,
  supportMessage
}: {
  icon: Icon;
  title: React.ReactNode;
  message: React.ReactNode;
  color: string;
  bgColor: string;
  supportMessage?: string;
}) => {
  const EmptyStateIcon = icon;

  return (
    <OuterWrapper>
      <ErrorWrapper>
        <IconWrapper style={{ color, backgroundColor: bgColor }}>
          <EmptyStateIcon size={42} />
        </IconWrapper>
        <Typography
          variant="h6"
          component="p"
          gutterBottom
          style={{ fontWeight: 700 }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          style={{ marginBottom: '48px' }}
        >
          {message}
        </Typography>
        {supportMessage && (
          <Button
            color="default"
            variant="text"
            size="small"
            style={{ margin: '0 auto', opacity: 0.5 }}
            onClick={(e) => {
              e.preventDefault();
              Beacon('open');
              Beacon('navigate', '/ask/message/');
            }}
          >
            {supportMessage} <ArrowRight size={12} />
          </Button>
        )}
      </ErrorWrapper>
    </OuterWrapper>
  );
};
