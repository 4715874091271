import React, { ReactNode } from 'react';
import {
  Line,
  LineChart,
  TickFormatterFunction,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';
import { CurrencyCode } from '../../../../../../domainTypes/currency';
import { formatMetric } from '../../../../../../services/analyticsV2/metrics';
import { TrafficMetric } from '../../service';
import { ChartData } from './chart-data';
import { CHART_HEIGHT } from './index';
import {
  Channel,
  channelColor
} from '../../../../../../services/channels/channels';
import { RealtimeTrafficChartTooltip } from './RealtimeTooltip';

interface ChartProps {
  data: Array<ChartData>;
  channels: Channel[];
  labelFormatter: (label: TooltipProps['label']) => ReactNode;
  xAxisTickFormatter: TickFormatterFunction;
  currency: CurrencyCode;
  metric: TrafficMetric;
  hasComparison: boolean;
}

export const realtimeTrafficChart = ({
  labelFormatter,
  data,
  xAxisTickFormatter,
  channels,
  currency,
  metric,
  hasComparison
}: ChartProps) => (
  <LineChart
    width={730}
    height={CHART_HEIGHT}
    data={data}
    syncId="realtimeChartId"
  >
    <Tooltip
      cursor={false}
      content={
        <RealtimeTrafficChartTooltip
          channels={channels}
          currency={currency}
          metric={metric}
          hasComparison={hasComparison}
          labelFormatter={labelFormatter}
        />
      }
    />
    <XAxis
      dataKey="timestamp"
      minTickGap={120}
      tickLine={false}
      tickFormatter={xAxisTickFormatter}
      tick={{
        fill: '#9b9b9b',
        transform: 'translate(0, 8)',
        fontSize: 14
      }}
      textAnchor="end"
      tickSize={0}
      stroke="cwBBB"
    />
    <YAxis
      yAxisId="traffic"
      tickLine={false}
      tickFormatter={(d) => formatMetric(d, metric, currency)}
      tick={{
        fill: '#9b9b9b',
        transform: 'translate(8, 0)',
        fontSize: 14
      }}
      tickSize={0}
      orientation="right"
      stroke="cwBBB"
    />
    {channels.map((channel) => (
      <Line
        key={`current-${channel.id}`}
        name={channel.id}
        type="monotone"
        dataKey={(d) => d.traffic[channel.id]}
        yAxisId="traffic"
        dot={false}
        stroke={channelColor(channel)}
        strokeWidth={2.5}
        isAnimationActive={false}
      />
    ))}
    {hasComparison
      ? channels.map((channel) => (
          <Line
            key={`previous-${channel.id}`}
            name={`previous___${channel.id}`}
            type="monotone"
            dataKey={(d) => d.previousTraffic[channel.id]}
            yAxisId="traffic"
            dot={false}
            stroke={channelColor(channel)}
            strokeWidth={1.5}
            strokeDasharray="3 3"
            isAnimationActive={false}
          />
        ))
      : null}
  </LineChart>
);
