import { compact } from 'lodash';
import React, { useMemo } from 'react';
import { UploadCloud } from 'react-feather';
import { DEFAULT_CURRENCY } from '../../../../domainTypes/space';
import { useDialogState } from '../../../../hooks/useDialogState';
import {
  PageBody,
  SideNavHowTo,
  SideNavProps
} from '../../../../layout/PageBody';
import { useRoutes } from '../../../../routes';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../services/currentUser';
import { ImportDialog } from '../ImportDialog';

type Props = {
  howTo?: SideNavHowTo;
  noTopPadding?: boolean;
};

export const PerformancePageBody: React.FC<Props> = ({
  howTo,
  noTopPadding,
  children
}) => {
  const { space } = useCurrentUser();
  const { ROUTES } = useRoutes();
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);
  const scopes = useCurrentUserScopes();
  const isFmtcSubAffiliate = /^fr\d{5}/.test(space.id);
  const canViewTransactions = scopes.has('reports.transactions.view');
  const canViewAdvertisers = scopes.has('reports.advertisers.view');
  const canViewPayouts = scopes.has('reports.payouts.view');
  const canViewChannels = scopes.has('reports.channels.view');
  const canViewPlatforms = scopes.has('reports.platforms.view');
  const canCreateIntegrations = scopes.has('integrations.create');
  const canEditIntegrations = scopes.has('integrations.edit');
  const canUploadReports = scopes.has('integrations.upload_report');
  const canViewRules = scopes.has('rules.view');
  const canViewIntegrations = scopes.has('integrations.view');
  const canViewRates = scopes.has('reports.rates.view');

  const sideNav: SideNavProps = useMemo(() => {
    return {
      groups: compact([
        {
          label: 'Reports',
          items: compact([
            canViewTransactions && {
              label: 'Transactions',
              path: ROUTES.performanceNew.transactions.url()
            },
            canViewAdvertisers && {
              label: 'Advertisers',
              path: ROUTES.performanceNew.advertisers.overview.url()
            },
            canViewRates &&
              !isFmtcSubAffiliate && {
                label: 'Rates',
                path: ROUTES.performanceNew.rates.overview.url()
              },
            canViewPlatforms &&
              !isFmtcSubAffiliate && {
                label: 'Platforms',
                path: ROUTES.performanceNew.networks.overview.url()
              },
            canViewChannels &&
              !isFmtcSubAffiliate && {
                label: 'Channels',
                path: ROUTES.performanceNew.channels.url()
              },
            canViewPayouts &&
              !isFmtcSubAffiliate && {
                label: 'Payouts',
                path: ROUTES.performanceNew.payouts.overview.url()
              }
          ])
        },
        (canViewIntegrations || canViewRules) && {
          label: 'Settings',
          items: compact([
            canViewIntegrations &&
              !isFmtcSubAffiliate && {
                label: 'Integrations',
                path: ROUTES.performanceNew.integrations.url()
              },
            canViewIntegrations && {
              label: 'Activity',
              path: ROUTES.performanceNew.activity.url()
            },
            canViewRules &&
              !isFmtcSubAffiliate && {
                label: 'Rules',
                path: ROUTES.performanceNew.rulesV2.url()
              }
          ])
        }
      ]),
      action:
        (canCreateIntegrations || canEditIntegrations || canUploadReports) &&
        !isFmtcSubAffiliate
          ? {
              icon: UploadCloud,
              label: 'Import',
              onClick: openDialog
            }
          : undefined,
      howTo
    };
  }, [
    ROUTES,
    canViewRates,
    canViewTransactions,
    canViewAdvertisers,
    canViewPayouts,
    canViewPlatforms,
    canViewIntegrations,
    canViewRules,
    canViewChannels,
    canCreateIntegrations,
    canEditIntegrations,
    canUploadReports,
    openDialog,
    howTo,
    isFmtcSubAffiliate
  ]);

  return (
    <>
      <PageBody sideNav={sideNav} noTopPadding={noTopPadding}>
        {children}
      </PageBody>
      <ImportDialog
        open={dialogOpen}
        onClose={closeDialog}
        currency={space.config.currency || DEFAULT_CURRENCY}
      />
    </>
  );
};
