import { generateToDocFn } from '../../../domainTypes/document';
import { IReportingStats } from '../../../domainTypes/reporting';
import { store } from '../../../services/db';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import { FS } from '../../../versions';

export const toReportingStatsDoc = generateToDocFn<IReportingStats>();

const reportingStatsListener = createCollectionListenerStore(
  (spaceId) =>
    new CollectionListener(
      store().collection(FS.reportingStats).where('spaceId', '==', spaceId),
      toReportingStatsDoc
    )
);

export const useReportingStats = (spaceId: string) => {
  return useCollectionListener(reportingStatsListener(spaceId));
};
