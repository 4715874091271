import React from 'react';
import { styled } from '../../../../emotion';
import { Helmet } from 'react-helmet';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { NoPermissions } from '../../../../components/NoPermissions';
import { PerformancePageBody } from '../../components/PerformancePageBody';
import { PagePerformancePayoutsContent } from './PagePerformancePayouts';
import { useHasPayouts } from '../../../../services/payouts';
import { Centered } from '../../../../layout/Centered';
import { Button, Typography } from '@material-ui/core';
import { Lock } from 'react-feather';
import { SupportButton } from '../../../../components/SupportButton';
import { useRoutes } from '../../../../routes';
import { Link } from 'react-router-dom';
import { HelpIcon } from '../../../../components/HelpIcon';
import { ARTICLE_IDS, Beacon } from '../../../../services/beacon';

const EmptyStateImage = styled('img')`
  width: 700px;
`;

const UpgradeWrapperInner = styled('div')`
  text-align: center;
`;

const PayoutReportsUpgradePrompt = () => {
  const user = useCurrentUser();
  const isInTrial = user.space.billing.activePlan === null;
  const { ROUTES } = useRoutes();

  const UI_CONFIG = [
    {
      active: isInTrial,
      heading: (
        <>
          <Lock size={18} /> Subscribe to access payout reporting
        </>
      ),
      description:
        'Track your payouts, monitor for unpaid commissions, and simplify your accounting and finances — all in one place.',
      cta: {
        text: 'Subscribe now'
      }
    },
    {
      active: !isInTrial,
      heading: (
        <>
          <Lock size={18} /> Add access to payout reporting
        </>
      ),
      description:
        'Track your payouts, monitor for unpaid commissions, and simplify your accounting and finances — all in one place.',
      cta: {
        text: 'Add payouts add-on'
      },
      contact: {
        subject: 'Request access to payouts add-on',
        body: `Hi team, I'd like to upgrade my account to access Payout reporting.`
      }
    }
  ];

  const activeUI = UI_CONFIG.find((ui) => ui.active);

  if (!activeUI) {
    return <div>Something went wrong</div>;
  }

  return (
    <Centered height={680}>
      <UpgradeWrapperInner>
        <EmptyStateImage
          src="/images/empty-states/payout-reporting-empty-state.png"
          alt="Payout reporting"
        />
        <Typography variant="h5" style={{ marginTop: 20 }}>
          {activeUI.heading}
        </Typography>
        <Typography
          variant="body1"
          style={{ margin: '20px auto', width: '80%', maxWidth: '500px' }}
        >
          {activeUI.description}
        </Typography>
        {!isInTrial && (
          <SupportButton
            label={activeUI.cta.text}
            size="large"
            subject={activeUI.contact?.subject}
            text={activeUI.contact?.body}
          />
        )}
        {isInTrial && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to={ROUTES.settings.billing.url()}
          >
            Subscribe now
          </Button>
        )}
        <div style={{ marginTop: '24px' }}>
          <HelpIcon
            color="gray"
            onClick={() => {
              Beacon('article', ARTICLE_IDS.payouts.overview);
            }}
          >
            Learn more about payout reporting
          </HelpIcon>
        </div>
      </UpgradeWrapperInner>
    </Centered>
  );
};

export const PagePerformancePayouts = () => {
  useTrackMixpanelView('view_payouts');

  const hasPayoutsAccess = useHasPayouts();
  const [canView] = useHasCurrentUserRequiredScopes([
    'payouts.view',
    'reports.payouts.view'
  ]);

  return (
    <>
      <Helmet>
        <title>Payouts | Affilimate</title>
      </Helmet>
      <PerformancePageBody noTopPadding>
        {canView ? (
          hasPayoutsAccess ? (
            <PagePerformancePayoutsContent />
          ) : (
            <PayoutReportsUpgradePrompt />
          )
        ) : (
          <NoPermissions />
        )}
      </PerformancePageBody>
    </>
  );
};
