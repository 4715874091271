export const groups = [
  'search',
  'social',
  'internal',
  'direct',
  'google-discover',
  'other'
] as const;

export type ReferrerGroup = typeof groups[number];
