import React, { useMemo, useState } from 'react';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import * as regression from 'regression';
import {
  EMPTY_COUNTER,
  ICounter,
  IDailyCounter
} from '../../../domainTypes/analytics';
import { styled } from '../../../emotion';
import {
  formatTimeKey,
  getClickRatio,
  getCpm,
  getViewRatio
} from '../../../services/analytics';
import {
  useColorsSpectrumPrimary,
  useColorsSpectrumSecondary
} from '../../../services/color';
import { splitInHalf } from '../../../services/list';
import { Loader } from '../../Loader';
import { formatNumber, INumberProps, Number, toPercent } from '../../Number';
import { Trend } from '../../Trend';
import { CustomTooltip } from '../CustomTooltip';

export interface ITrafficChartData {
  timeKey: string;
  value: number;
  linearRegression: number;
}

type CountKey = keyof ICounter | 'viewRatio' | 'clickRatio' | 'cpm';

interface WithMargin {
  margin: 'normal' | 'dense';
}

interface Props {
  counters: IDailyCounter[] | null | void;
  loading: boolean;
  compare: boolean;
  tabs?: CountKey[];
  animate?: boolean;
  height?: number | string;
}

const SHOW_LINEAR_REGRESSION = false;

const Tabs = styled<'div', WithMargin>('div')`
  display: flex;
  justify-content: stretch;
  padding: ${(p) => p.theme.spacing(3)}px;
  padding-bottom: 0;
  min-height: ${(p) => p.theme.spacing() * (p.margin === 'dense' ? 8 : 16)}px;
`;

const TabItem = styled<'div', { selected: boolean } & WithMargin>('div')(
  (p) => {
    const dense = p.margin === 'dense';
    const { custom: c, palette, spacing } = p.theme;
    const fontSizeLabel = `${dense ? c.fontSize.s : c.fontSize.m}px`;
    const fontSize = `${dense ? c.fontSize.s : c.fontSize.l}px`;
    return `
      cursor: pointer;
      font-size: ${fontSize};
      margin: ${spacing(1)}px;
      padding: ${spacing(2)}px ${spacing(3)}px;
      min-width: ${dense ? '100px' : '200px'};
      border-radius: 6px;
      background-color: ${p.selected ? palette.grey.A100 : 'transparent'};
      opacity:  ${p.selected ? 1 : 0.5};
      flex-grow: 1;

      > label {
        font-size: ${fontSizeLabel};
      }

      > div {
        margin-top: ${spacing() / (dense ? 4 : 2)}px;
      }
  `;
  }
);

const Body = styled<'div', WithMargin>('div')`
  margin: ${(p) => p.theme.spacing() * (p.margin === 'dense' ? 1 : 2)}px;
`;

type TabProps<T> = {
  label: string;
  children?: any;
  value: T;
  selected: boolean;
  onSelect: (nextValue: T) => void;
};

const Label = styled('label')`
  display: block;
  margin-bottom: ${(p) => p.theme.spacing()}px;
`;

const Tab = <T extends {}>({
  label,
  children,
  value,
  selected,
  onSelect,
  margin
}: TabProps<T> & WithMargin) => {
  return (
    <TabItem
      role="button"
      onClick={() => onSelect(value)}
      selected={selected}
      margin={margin}
    >
      <Label>{label}</Label> {children}
    </TabItem>
  );
};

// TODO - replace with aggregateDailyCounters
const sumCounts = (counts: IDailyCounter[]) => {
  return counts.reduce<ICounter>((m, r) => {
    m.served = m.served + r.served;
    m.viewed = m.viewed + r.viewed;
    m.clicked = m.clicked + r.clicked;
    m.pageViews = m.pageViews + r.pageViews;
    return m;
  }, EMPTY_COUNTER());
};

const sumCountsWithComparison = (
  counts: IDailyCounter[],
  compare: boolean
): [ICounter, ICounter] => {
  const [a, b] = compare ? splitInHalf(counts) : [[], counts];
  return [sumCounts(a), sumCounts(b)];
};

const getValueFromCounter = (counter: ICounter, key: CountKey) => {
  if (!counter) {
    return 0;
  }
  if (key === 'viewRatio') {
    return getViewRatio(counter);
  }
  if (key === 'clickRatio') {
    return getClickRatio(counter);
  }
  if (key === 'cpm') {
    return getCpm(counter);
  }
  return counter[key];
};

const toChartData = (
  counts: IDailyCounter[],
  key: CountKey,
  compare: boolean
): ITrafficChartData[] => {
  const [comparisonValues, values] = compare
    ? splitInHalf(counts)
    : [[], counts];
  const data = values.map((c, i) => ({
    timeKey: c.timeKey,
    value: getValueFromCounter(c, key),
    linearRegression: 0,
    comparisonValue: getValueFromCounter(comparisonValues[i], key)
  }));

  regression
    .linear(data.map((d) => [+d.timeKey, d.value] as [number, number]))
    .points.forEach((point, i) => {
      data[i].linearRegression = point[1];
    });

  return data;
};

interface ITab {
  label: string;
  value: CountKey;
  format?: INumberProps['format'];
  digits?: INumberProps['digits'];
}

const TABS: ITab[] = [
  {
    label: 'Pageviews',
    value: 'pageViews'
  },
  {
    label: 'Seen',
    value: 'viewed'
  },
  {
    label: 'Clicks',
    value: 'clicked'
  },
  {
    label: '% Seen',
    value: 'viewRatio',
    format: 'percent'
  },
  {
    label: 'Avg. CTR',
    value: 'clickRatio',
    format: 'percent',
    digits: 2
  },
  {
    label: 'Page CTR',
    value: 'cpm',
    format: 'percent',
    digits: 1
  }
];

const TICK_STYLES: {
  [K in WithMargin['margin']]: {
    fontSize?: number;
    fill?: string;
  };
} = {
  normal: { fontSize: 12, fill: '#333' },
  dense: { fontSize: 10, fill: '#333' }
};

const MARGINS: {
  [K in WithMargin['margin']]: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
} = {
  normal: { right: 16, top: 24 },
  dense: { right: 8, top: 16, left: -16 }
};

const ComparisonContainer = styled<
  'div',
  { positive: boolean; selected: boolean } & WithMargin
>('div')((p) => {
  const getColor = () => {
    if (p.selected) {
      return 'inherit';
    }
    return p.positive ? p.theme.custom.colors.success.main : 'red';
  };
  return {
    display: 'flex',
    alignItems: 'center',
    fontSize: p.theme.custom.fontSize.s,
    color: getColor(),
    marginTop: `${p.theme.spacing() / (p.margin === 'dense' ? 2 : 1)}px`
  };
});

const ComparisonPercentage = ({
  a,
  b,
  selected,
  margin
}: {
  a: number;
  b: number;
  selected: boolean;
} & WithMargin) => {
  const diff = a - b;
  const percentage = toPercent(diff, a);
  const positive = percentage > 0;
  const style = {};

  return (
    <ComparisonContainer
      positive={positive}
      selected={selected}
      margin={margin}
    >
      <Trend values={[b, a]} label="left" style={style} />
    </ComparisonContainer>
  );
};

const TabBody = ({
  tab,
  sums,
  comparisonSums,
  compare,
  selected,
  margin
}: {
  tab: ITab;
  sums: ICounter;
  comparisonSums: ICounter;
  compare: boolean;
  selected: boolean;
} & WithMargin) => {
  return (
    <>
      <div style={{ fontSize: '20px' }}>
        <Number
          n={getValueFromCounter(sums, tab.value)}
          format={tab.format}
          digits={tab.digits}
        />
      </div>
      {compare && (
        <ComparisonPercentage
          a={getValueFromCounter(sums, tab.value)}
          b={getValueFromCounter(comparisonSums, tab.value)}
          selected={selected}
          margin={margin}
        />
      )}
    </>
  );
};

export const ALL_TABS = TABS.map((t) => t.value);
export const PAGE_TABS: CountKey[] = [
  'pageViews',
  'clicked',
  'clickRatio',
  'cpm'
];
export const PRODUCT_TABS: CountKey[] = [
  'clicked',
  'viewed',
  'viewRatio',
  'clickRatio'
];

export const ANALYTICS_TABS: CountKey[] = [
  'pageViews',
  'clicked',
  'clickRatio',
  'cpm'
];

export const TrafficChart = React.memo(
  ({
    counters,
    loading,
    margin,
    compare,
    tabs = ALL_TABS,
    animate = false,
    height = 'auto'
  }: Props & WithMargin) => {
    const displayedTabs = useMemo(
      () => tabs.map((t) => TABS.find((x) => t === x.value)!),
      [tabs]
    );
    const [tab, setTab] = useState<ITab>(
      displayedTabs.find((t) => t.value === 'clicked') || displayedTabs[0]
    );
    const [comparisonSums, sums] = sumCountsWithComparison(
      counters || [],
      compare
    );
    const [primaryColor] = useColorsSpectrumPrimary();
    const [secondaryColor] = useColorsSpectrumSecondary();

    return (
      <div>
        <Body margin={margin}>
          {counters && !loading ? (
            <ResponsiveContainer
              width="99%"
              height={height}
              aspect={height === 'auto' ? 2.5 : undefined}
            >
              <LineChart
                data={toChartData(counters, tab.value, compare)}
                margin={MARGINS[margin]}
              >
                <XAxis
                  dataKey="timeKey"
                  tick={{
                    ...TICK_STYLES[margin],
                    transform: 'translate(0, 8)'
                  }}
                  textAnchor="end"
                  tickSize={0}
                  minTickGap={8}
                  stroke="#BBB"
                  tickFormatter={formatTimeKey}
                />
                <YAxis
                  tick={{
                    ...TICK_STYLES[margin],
                    transform: 'translate(-8, 0)'
                  }}
                  stroke="#BBB"
                  tickSize={0}
                  tickFormatter={(t) =>
                    formatNumber({
                      n: t,
                      format: tab.format,
                      digits: tab.digits
                    })
                  }
                />
                <Tooltip
                  content={<CustomTooltip />}
                  formatter={(value, name) => [
                    formatNumber({
                      n: value as number,
                      format: tab.format,
                      digits: tab.digits
                    }),
                    name
                  ]}
                  labelFormatter={(l) => (
                    <span>{formatTimeKey(l as string, 'ddd MMM DD')}</span>
                  )}
                />
                <Line
                  isAnimationActive={animate}
                  type="monotone"
                  dataKey="value"
                  name={
                    tab.format && tab.format === 'percent' ? 'Ratio' : 'Volume'
                  }
                  stroke={primaryColor}
                  strokeWidth={2}
                  dot={false}
                />
                {SHOW_LINEAR_REGRESSION && (
                  <Line
                    isAnimationActive={animate}
                    type="linear"
                    dataKey="linearRegression"
                    stroke={secondaryColor}
                    strokeWidth={1}
                    dot={false}
                  />
                )}
                {compare && (
                  <Line
                    isAnimationActive={animate}
                    type="monotone"
                    dataKey="comparisonValue"
                    name="Comparison"
                    stroke={primaryColor}
                    strokeDasharray="3 3"
                    dot={false}
                  />
                )}
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <Loader
              height={
                height === 'auto'
                  ? margin === 'dense'
                    ? '215px'
                    : '400px'
                  : height
              }
            />
          )}
        </Body>
        <Tabs margin={margin}>
          {displayedTabs.map((t) => (
            <Tab
              key={t.value}
              selected={tab === t}
              onSelect={setTab}
              label={t.label}
              value={t}
              margin={margin}
            >
              {!counters && loading ? (
                ''
              ) : (
                <TabBody
                  tab={t}
                  sums={sums}
                  comparisonSums={comparisonSums}
                  compare={compare && !loading}
                  selected={tab === t}
                  margin={margin}
                />
              )}
            </Tab>
          ))}
        </Tabs>
      </div>
    );
  }
);
