import { Typography } from '@material-ui/core';
import { difference } from 'lodash';
import React, { useMemo } from 'react';
import { ChevronDown, Columns as IconColumns } from 'react-feather/dist';
import { EMPTY_OBJ } from '../../../domainTypes/emptyConstants';
import { useMixpanel } from '../../../services/mixpanel';
import { MultiSelector } from '../../MultiSelector';
import { IColumn } from '../Column';

export interface ColumnSelectorProps<Key extends string> {
  value: Set<Key>;
  onChange: (nextValue: Set<Key>) => void;
  columns: Array<
    | IColumn<any, Key, any>
    | { label: string; columns: Array<IColumn<any, Key, any>> }
  >;
  short?: boolean;
  additionalTrackingProperties?: { [key: string]: any };
}

export const ColumnSelector = <Key extends string>({
  value,
  onChange,
  columns,
  additionalTrackingProperties = EMPTY_OBJ
}: ColumnSelectorProps<Key>) => {
  const mixpanel = useMixpanel();
  const options = useMemo(
    () =>
      columns.map((c) => {
        if ('columns' in c) {
          return {
            label: c.label,
            options: c.columns.map((c) => ({
              label: c.alternateHead ? c.alternateHead() : c.head(),
              value: c.key
            }))
          };
        }
        return {
          label: c.alternateHead ? c.alternateHead() : c.head(),
          value: c.key
        };
      }),
    [columns]
  );
  const flatColumns = useMemo(
    () => columns.flatMap((c) => ('columns' in c ? c.columns : c)),
    [columns]
  );
  const columnsCount = flatColumns.length;
  return (
    <MultiSelector<Key>
      value={value}
      onChange={(nextValue) => {
        onChange(nextValue);

        const columns_all = flatColumns.map((c) => c.key).sort();
        const columns_selected = [...nextValue].sort();
        const columns_selected_prev = [...value].sort();
        mixpanel.track('column_selector_change', {
          columns_all,
          columns_selected,
          columns_all_count: columns_all.length,
          columns_selected_count: columns_selected.length,
          columns_added: difference(
            columns_selected,
            columns_selected_prev
          ).sort(),
          columns_removed: difference(
            columns_selected_prev,
            columns_selected
          ).sort(),
          ...additionalTrackingProperties
        });
      }}
      options={options}
      legend={`Columns (${value.size} of ${columnsCount} selected)`}
      allowSearch
      onSearch={({ term, filteredOptions }) =>
        mixpanel.track('column_selector_search', {
          term,
          columns_all: flatColumns.map((c) => c.key).sort(),
          columns_filtered: [...filteredOptions].sort(),
          columns_all_count: flatColumns.length,
          columns_filtered_count: filteredOptions.size,
          ...additionalTrackingProperties
        })
      }
      onOpen={(nextOpen) =>
        mixpanel.track(
          nextOpen ? 'column_selector_opened' : 'column_selector_closed',
          additionalTrackingProperties
        )
      }
    >
      <Typography
        variant="body2"
        color="textSecondary"
        component="span"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '6px'
        }}
      >
        <IconColumns size={18} /> Columns{' '}
        <strong>
          {value.size} of {columnsCount}
        </strong>
        <ChevronDown size={18} />
      </Typography>
    </MultiSelector>
  );
};
