import { Typography, ButtonBase, Dialog, Button } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useCurrentUser } from '../services/currentUser';
import { Video } from './Video';
import { styled } from '../emotion';
import { Calendar, ChevronRight, ExternalLink, Send } from 'react-feather/dist';
import { Beacon } from '../services/beacon';

const STORAGE_KEY = 'quickstartDismissed';

const DialogFooter = styled('div')`
  text-align: center;
  background-color: ${(p) => p.theme.palette.grey[100]};
  padding: ${(p) => p.theme.spacing(4)}px ${(p) => p.theme.spacing(2)}px;
`;

const DialogLink = styled('a')`
  color: ${(p) => p.theme.palette.primary.main};

  svg {
    position: relative;
    top: 2px;
  }
`;

const SupportButton = styled(ButtonBase)`
  font-size: 16px;
  color: ${(p) => p.theme.palette.primary.main} !important;
  position: relative;
  top: -2px;
`;

const Title = styled('h1')`
  font-weight: bold;
  margin-top: ${(p) => p.theme.spacing(4)}px;
  text-align: center;
  font-size: 24px;
`;

export const QuickstartDialog = ({
  otherModalsOpen
}: {
  otherModalsOpen: boolean;
}) => {
  const currentUser = useCurrentUser();
  const [closedDialog, setClosedDialog] = useState(false);
  const hasDismissedQuickstart = window.localStorage.getItem(STORAGE_KEY);

  const open = useMemo(() => {
    if (otherModalsOpen || closedDialog || hasDismissedQuickstart) {
      return false;
    }
    if (currentUser.space.billing.activePlan) {
      return false;
    }
    return true;
  }, [currentUser, otherModalsOpen, closedDialog, hasDismissedQuickstart]);

  const onClose = () => {
    setClosedDialog(true);
    window.localStorage.setItem(STORAGE_KEY, 'true');
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" scroll="body">
      <Title>Welcome to Affilimate!</Title>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{ textAlign: 'center', marginBottom: '18px' }}
      >
        Here's a demo recap of the platform. Click the button below the video to
        get started.
      </Typography>
      <Video
        url="https://player.vimeo.com/video/880102867?h=1f474b2f19"
        width={800}
        height={418}
      />
      <DialogFooter>
        <Typography
          variant="body1"
          style={{ textAlign: 'center', maxWidth: '600px', margin: '0 auto' }}
        >
          <strong>Questions along the way?</strong> Start with our{' '}
          <DialogLink
            href="https://app.affilimate.com/docs/quickstart-guide/?utm_source=app"
            target="_blank"
          >
            Quickstart Guide <ExternalLink size={16} />
          </DialogLink>{' '}
          and{' '}
          <DialogLink
            href="https://app.affilimate.com/docs/?utm_source=app"
            target="_blank"
          >
            Knowledge Base
            <ExternalLink size={16} />
          </DialogLink>
        </Typography>
        <Typography
          variant="body1"
          style={{
            marginTop: '9px',
            textAlign: 'center',
            maxWidth: '600px',
            margin: '0 auto'
          }}
        >
          You can also{' '}
          <SupportButton
            onClick={(e) => {
              e.preventDefault();
              Beacon('open');
              Beacon('navigate', '/ask/message/');
            }}
          >
            <Send size={16} style={{ marginRight: '3px' }} /> talk to Customer
            Success
          </SupportButton>{' '}
          or{' '}
          <DialogLink
            href="https://savvycal.com/affilimate/intro"
            style={{ marginLeft: '3px' }}
            target="_blank"
          >
            <Calendar
              size={16}
              style={{
                marginRight: '1px'
              }}
            />{' '}
            request a demo
          </DialogLink>
        </Typography>
        <Button
          style={{ margin: '24px auto 12px' }}
          onClick={onClose}
          variant="contained"
          size="large"
          color="primary"
        >
          Set up account now{' '}
          <ChevronRight
            style={{ position: 'relative', top: '1px', left: '6px' }}
            size={18}
          />
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
