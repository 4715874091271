import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { ButtonWithPromise } from '../../../components/ButtonWithPromise';
import { Loader } from '../../../components/Loader';
import { CurrencyCode } from '../../../domainTypes/currency';
import { ReportingApiResult } from '../../../domainTypes/reporting';
import { ISpace } from '../../../domainTypes/space';
import { useBackgroundJobs } from '../../../hooks/useBackgroundJobs';
import {
  saveApiResult,
  useTrackedSalesEventsForReports
} from '../services/report';
import { saveSalesFromReportsViaCf } from '../services/sale';
import { DEFAULT_SALESLIST_PAGE_SIZE, SalesList } from './SalesList';
import { styled } from '../../../emotion';
import { Beacon } from '../../../services/beacon';
import { AlertTriangle, Send } from 'react-feather';
import { COLORS } from '../../../domainTypes/colors';
import { useFlushAnalyticsV2Cache } from '../../../services/analyticsV2/cache';

const WarningIcon = styled('div')`
  background-color: ${COLORS.gold.gold2};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.palette.warning.main};
  width: 100px;
  height: 100px;
  text-align: center;
  margin: 0 auto;
  border-radius: 100%;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

const ErrorWrapper = styled('div')`
  max-width: 400px;
  margin: 0 48px;
  text-align: center;
`;

export const ApiError = ({ onClose }: { onClose: () => void }) => (
  <>
    <DialogContent>
      <ErrorWrapper>
        <WarningIcon>
          <AlertTriangle size={42} />
        </WarningIcon>
        <Typography
          variant="h6"
          component="p"
          gutterBottom
          style={{ fontWeight: 700 }}
        >
          Your API pull was unsuccessful
        </Typography>
        <Typography
          variant="body1"
          component="p"
          style={{ marginBottom: '48px' }}
        >
          This usually happens if you're trying to backfill a large number of
          commissions and exceed your API limits with the network, or the
          network itself has an error.
        </Typography>
        <Button
          color="primary"
          variant="contained"
          style={{ marginBottom: '12px' }}
          onClick={(e) => {
            e.preventDefault();
            Beacon('prefill', {
              subject: 'Issue importing commissions via API',
              text: `Hi there, I'm having an issue importing commissions from Integration (X).`
            });
            Beacon('open');
            Beacon('navigate', '/ask/message/');
          }}
        >
          <Send size={18} /> &nbsp; Report issue to Support
        </Button>
        <Typography
          variant="caption"
          component="p"
          color="textSecondary"
          style={{ maxWidth: '250px', textAlign: 'center', margin: '0 auto' }}
          paragraph
        >
          We'll check your setup and report back, usually within a few hours.
        </Typography>
      </ErrorWrapper>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </>
);

type Props = {
  userId: string;
  space: ISpace;
  result: ReportingApiResult;
  currency: CurrencyCode;
  onDone: () => void;
  onClose: () => void;
  height: number;
};

export const ApiImportResults = ({
  userId,
  space,
  result,
  currency,
  onDone,
  onClose,
  height
}: Props) => {
  console.log(result.response, result.report && result.report.sales);

  const [page, setPage] = useState(1);
  const flushCache = useFlushAnalyticsV2Cache();
  const [sales, loading] = useTrackedSalesEventsForReports(
    space.id,
    userId,
    result.report ? [result.report] : [],
    space.config.tz || 'UTC',
    currency
  );

  const { addJob } = useBackgroundJobs();

  const onSave = () => {
    const request = saveApiResult(result, userId).then(async (res) => {
      await saveSalesFromReportsViaCf(space.id, [res.report.id]);
      flushCache(space.id);
    });
    addJob({
      job: () => request,
      onStart: () => {
        console.time('saving sales');
        return {
          message: (
            <>
              <CircularProgress color="inherit" size={16} /> &nbsp; Importing
              your data...
            </>
          )
        };
      },
      onSuccess: () => {
        console.timeEnd('saving sales');
        return {
          message:
            'Import successful! Future imports will happen automatically.'
        };
      },
      onError: () => {
        console.timeEnd('saving sales');
        return {
          message:
            'Your import was not successful. Please try again or contact Support.'
        };
      }
    });
    onDone();
    return Promise.resolve();
  };

  if (result.errorType || !result.report) {
    return <ApiError onClose={onClose} />;
  }

  if (loading || !sales) {
    return (
      <DialogContent>
        <Loader height={height} />
      </DialogContent>
    );
  }

  return (
    <>
      <DialogContent>
        <SalesList
          page={page}
          setPage={setPage}
          pageSize={DEFAULT_SALESLIST_PAGE_SIZE}
          sales={sales}
          height={height}
          currency={currency}
        />
      </DialogContent>
      <DialogActions
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          width: '50%',
          backgroundColor: '#FFF',
          boxShadow: '-1px 0 10px rgb(0 0 0 / 10%)',
          zIndex: 1
        }}
      >
        <Button onClick={onClose}>Cancel</Button>
        <ButtonWithPromise
          color="primary"
          variant="contained"
          pending="Uploading..."
          onClick={onSave}
        >
          Connect and import {pluralize('sale', sales.length, true)}
        </ButtonWithPromise>
      </DialogActions>
    </>
  );
};
