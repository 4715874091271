import { capitalize } from '@material-ui/core';
import { every } from 'lodash';
import React from 'react';
import { NestedFilterMenu } from '../../../../../components/NestedFilterMenu';
import { SelectorChip } from '../../../../../components/SelectorChip';
import { EMPTY_SET } from '../../../../../domainTypes/emptyConstants';
import { PARTNERS } from '../../../../../domainTypes/partners';
import {
  PayoutStatus,
  PAYOUT_STATUSES
} from '../../../../../domainTypes/performance';

export const PayoutsFilter = ({
  payoutStatus,
  platforms
}: {
  payoutStatus: {
    value: PayoutStatus;
    onChange: (value: PayoutStatus) => void;
  };
  platforms: {
    availablePlatforms: string[];
    value: Set<string> | null;
    onChange: (value: Set<string> | null) => void;
  };
}) => {
  const payoutStatusApplied = true;
  const isApplied = payoutStatusApplied;

  return (
    <NestedFilterMenu
      filters={[
        {
          key: 'payoutStatuses',
          type: 'singleSelector',
          label: 'Payout Status',
          appliedLabel: (
            <strong>Status selected: {capitalize(payoutStatus.value)}</strong>
          ),
          isApplied: payoutStatusApplied,
          legend: 'Payout Status',
          options: PAYOUT_STATUSES.map((s) => ({
            label: capitalize(s),
            value: s
          })),
          value: payoutStatus.value,
          onChange: payoutStatus.onChange
        },
        {
          key: 'platforms',
          type: 'multiSelector',
          label: 'Platform',
          allowFocusing: true,
          allOption: <strong>All platforms</strong>,
          appliedLabel: (
            <strong>
              Platforms selected:{' '}
              {platforms.value ? platforms.value.size : 'All'}
            </strong>
          ),
          isApplied: payoutStatusApplied,
          legend: 'Platform',
          options: platforms.availablePlatforms.map((s) => {
            const partner = PARTNERS.find((p) => p.key === s);
            return {
              label: partner ? partner.name : capitalize(s),
              value: s
            };
          }),
          value: platforms.value || EMPTY_SET,
          onChange: (nextValue) => {
            /// when everything's selected, just remove the fitler
            const trueNextValue =
              nextValue.size === platforms.availablePlatforms.length &&
              every(platforms.availablePlatforms, (p) => nextValue.has(p))
                ? null
                : nextValue;
            platforms.onChange(trueNextValue);
          }
        }
      ]}
    >
      <SelectorChip
        isApplied={isApplied}
        label="Filters"
        appliedLabel="Filters applied"
      />
    </NestedFilterMenu>
  );
};
