import { Typography, CircularProgress } from '@material-ui/core';
import React from 'react';
import { styled } from '../../emotion';

const Wrapper = styled('div')`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f9;

  > img {
    margin-bottom: ${(p) => p.theme.spacing()}px;
    margin-right: ${(p) => p.theme.spacing()}px;
  }
`;

const Loader = styled(CircularProgress)`
  width: 24px !important;
  height: 24px !important;
  margin-right: ${(p) => p.theme.spacing(1)}px;
  display: block;

  svg {
    color: ${(p) => p.theme.palette.primary.main};
  }
`;

export const InitializingAccount = () => {
  return (
    <Wrapper>
      <Loader />
      <br />
      <Typography component="div" color="textSecondary">
        Preparing your account, hang tight
      </Typography>
    </Wrapper>
  );
};

export const CreatingNewAccount = () => {
  return (
    <Wrapper>
      <img src="/favicon.png" width="30px" alt="" />
      <Typography color="textSecondary">
        Creating a new account for you...
      </Typography>
    </Wrapper>
  );
};
