import {
  Checkbox,
  CircularProgress,
  FormControlLabel
} from '@material-ui/core';
import React from 'react';
import { Doc } from '../../../domainTypes/document';
import { Schedule } from '../../../domainTypes/schedule';
import { css, styled } from '../../../emotion';
import { Form } from '../../Form';
import { FormField } from '../../Form/FormField';
import { FrequencyEdit } from '../FrequencyEdit';

type Props = {
  label: string | React.ReactNode;
  doc: Doc<Schedule>;
  onChange: (next: Doc<Schedule>) => void | Promise<void>;
  readOnlyType?: boolean;
  disabled?: boolean;
};

const Row = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Overlay = styled('div')`
  opacity: 0.5;
  background: ${(p) => p.theme.palette.background.paper};
  pointer-events: none;
`;

const DisableWhenInactive: React.FC = ({ children }) => (
  <FormField name="active">
    {({ input }) => (input.value ? children : <Overlay>{children}</Overlay>)}
  </FormField>
);

export const ScheduleFormSimple: React.FC<Props> = ({
  label,
  doc,
  onChange,
  readOnlyType = false,
  disabled = false
}) => {
  return (
    <Form
      autoSave={true}
      interceptor={true}
      onSubmit={(data) => onChange({ ...doc, data: data as Schedule })}
      initialValues={doc.data}
    >
      {({ submitting }) => (
        <>
          <Row>
            <FormField name="active" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  label={label}
                  classes={{ root: css(() => ({ marginLeft: 0 })) }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={input.checked}
                      disabled={disabled}
                      onChange={input.onChange}
                    />
                  }
                />
              )}
            </FormField>
            {submitting && <CircularProgress size={20} />}
          </Row>
          <DisableWhenInactive>
            <FormField name="frequency">
              {({ input }) => (
                <FrequencyEdit
                  value={input.value}
                  onChange={input.onChange as any}
                  readOnlyType={readOnlyType}
                  disabled={disabled}
                  style={{ marginLeft: '28px' }}
                />
              )}
            </FormField>
          </DisableWhenInactive>
        </>
      )}
    </Form>
  );
};
