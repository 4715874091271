import { Avatar, Tooltip } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { compact } from 'lodash';
import React, { useMemo } from 'react';
import { Doc } from '../../domainTypes/document';
import { IUser } from '../../domainTypes/user';
import { css } from '../../emotion';
import { FlexContainer } from '../../layout/Flex';
import { getStableRandomColor } from '../../services/color';
import {
  toUserLabel,
  useUserInSpaceById,
  useUsersInSpaceByIds
} from '../../services/user';

const UNKNOWN_USER = 'Unknown user';
export const DEFAULT_USER_AVATAR_SIZE = 32;

const useUserLabels = (user: Doc<IUser> | null) => {
  return useMemo(() => {
    const fullLabel = toUserLabel(user);
    const labelParts = fullLabel.split(' ').filter((x) => x); // Excessive whitespace we don't care about
    // When someone is named Donald J. Trump, then only take DT as initials
    const smallLabel = (labelParts.length > 2
      ? [labelParts[0], labelParts[labelParts.length - 1]]
      : labelParts
    )
      .map((x) => (x[0] || '').toLocaleUpperCase())
      .join('');
    return {
      fullLabel,
      smallLabel,
      fullLabelWithFallback: fullLabel || UNKNOWN_USER
    };
  }, [user]);
};

export const UserAvatar = ({
  user,
  size,
  hideTooltip,
  className
}: {
  user: Doc<IUser> | null;
  size?: number;
  hideTooltip?: boolean; // e.g. so that groups can define their own tooltip is so desired
  className?: string;
}) => {
  const data = useUserLabels(user);
  return (
    <Tooltip
      title={hideTooltip ? '' : data.fullLabelWithFallback}
      placement="top"
    >
      <Avatar
        src={user?.data.photoURL || ''}
        className={compact([
          css(() => ({
            height: size,
            width: size,
            backgroundColor:
              data.fullLabelWithFallback === UNKNOWN_USER
                ? 'lightGrey'
                : getStableRandomColor(data.fullLabelWithFallback)
          })),
          className
        ]).join(' ')}
      ></Avatar>
    </Tooltip>
  );
};

export const UserAvatarWithNameNextToIt = ({
  user,
  size,
  className
}: {
  user: Doc<IUser> | null;
  size?: number;
  className?: string;
}) => {
  const data = useUserLabels(user);
  return (
    <FlexContainer>
      <Avatar
        src={user?.data.photoURL || ''}
        className={compact([
          css(() => ({
            height: size,
            width: size,
            backgroundColor:
              data.fullLabelWithFallback === UNKNOWN_USER
                ? 'lightGrey'
                : getStableRandomColor(data.fullLabelWithFallback)
          })),
          className
        ]).join(' ')}
      >
        {data.smallLabel || null}
      </Avatar>
      <div>{data.fullLabelWithFallback}</div>
    </FlexContainer>
  );
};

export const UserAvatarById = ({
  spaceId,
  userId,
  size,
  className
}: {
  spaceId: string;
  userId: string;
  size?: number;
  className?: string;
}) => {
  const [user] = useUserInSpaceById(spaceId, userId);
  return <UserAvatar user={user || null} size={size} className={className} />;
};

export const UserAvatarGroup = ({
  spaceId,
  userIds,
  max,
  size
}: {
  spaceId: string;
  userIds: string[];
  max: number;
  size?: number;
}) => {
  const [users] = useUsersInSpaceByIds(spaceId, userIds);
  const usersWithFallback: (Doc<IUser> | null)[] =
    users || userIds.map(() => null);
  const userLabels = useMemo(
    () => usersWithFallback.map((u) => toUserLabel(u) || UNKNOWN_USER),
    [usersWithFallback]
  );
  return (
    <Tooltip placement="top" title={userLabels.join(', ')}>
      <AvatarGroup max={max}>
        {usersWithFallback.map((u, i) => (
          <UserAvatar key={u?.id || i} user={u} size={size} hideTooltip />
        ))}
      </AvatarGroup>
    </Tooltip>
  );
};
