import React, { useState } from 'react';
import { IEarning } from '../../../domainTypes/performance';
import {
  ChartCard,
  ChartCardFooter,
  ChartCardFooterBar,
  ChartCardFooterSelectTopX
} from '../ChartCard';
import { SitesChart } from '../SitesChart';

export const SitesChartCard: React.FC<{
  data: { origin: string; earnings: IEarning }[];
  loading: boolean;
  style?: React.CSSProperties;
  heading?: string;
  subheading?: string;
  linkToPartnerOverview?: boolean;
}> = ({ data, loading, style, heading, subheading }) => {
  const [count, setCount] = useState(10);

  return (
    <ChartCard
      heading="All Sites"
      subheading="How each site contributes to your total revenue"
      style={style}
      size="small"
      padding="dense"
      centered
    >
      <SitesChart data={data} count={count} loading={loading} animate={true} />
      <ChartCardFooter>
        <ChartCardFooterBar>
          <ChartCardFooterSelectTopX
            value={count}
            onChange={setCount}
            step={5}
            max={20}
          />
        </ChartCardFooterBar>
      </ChartCardFooter>
    </ChartCard>
  );
};
