import { useCurrentUser } from './currentUser';
import { useMemo } from 'react';
import { getActiveDomainUrls } from './space';

const adjustDomainList = (domains: string[]): string[] => {
  return domains.flatMap((domain) => {
    if (domain.startsWith('www.')) {
      return [domain, domain.substr(4)];
    }
    return [domain];
  });
};
export const useDomains = (): string[] => {
  const { space } = useCurrentUser();
  return useMemo(() => {
    return adjustDomainList(getActiveDomainUrls(space, true));
  }, [space]);
};