import React from 'react';
import { Loader } from '../../../../components/Loader';
import { ISubscriptionDetails } from '../../../../domainTypes/billing';
import { ISpace } from '../../../../domainTypes/space';
import { styled } from '../../../../emotion';
import { Centered } from '../../../../layout/Centered';
import { useSubscription } from '../../service';
import { BillingFrequency, NextBilling } from './Items';

type SpaceProps = {
  space: ISpace;
};

type Props = {
  subscription: ISubscriptionDetails;
};

const SubscriptionDetailsGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${(p) => p.theme.spacing(2)}px;
  padding: ${(p) => p.theme.spacing(2)}px;
  text-align: center;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const SubscriptionDetailsWithoutData = ({
  subscription: subscriptionDetails
}: Props) => {
  const { subscription } = subscriptionDetails;
  const { plan } = subscription.items.data[0];

  if (!plan) {
    return (
      <Centered>
        No plan associated with this subscription. Please contact support.
      </Centered>
    );
  }

  return (
    <SubscriptionDetailsGrid>
      <BillingFrequency plan={plan} />
      <NextBilling subscription={subscription} />
    </SubscriptionDetailsGrid>
  );
};

export const SubscriptionDetails = ({ space }: SpaceProps) => {
  const [subscription, loading, error] = useSubscription(
    space.id,
    space.billing
  );

  if (loading) {
    return <Loader height={76} />;
  }

  if (error) {
    return <Centered>Failed to load subscription details.</Centered>;
  }

  if (!subscription) {
    console.error('no subscription found?');
    return null;
  }

  return <SubscriptionDetailsWithoutData subscription={subscription} />;
};
