import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTimeframe } from '../../../../components/analytics_v2/Timeframe';
import { NoPermissions } from '../../../../components/NoPermissions';
import { AnalyticsQuery } from '../../../../domainTypes/analytics_v2';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../services/db';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { ContentPageBody } from '../../components/ContentPageBody';
import { ContentOverviewBody } from './ContentOverviewBody';
import { EmptyAccountAnalytics, NoVerifiedSites } from './Messages';

const useHasEmptyData = () => {
  const { space } = useCurrentUser();
  const { range } = useTimeframe();
  const query = useMemo<AnalyticsQuery>(
    () => ({
      range,
      select: ['count_uniq_page_url']
    }),
    [range]
  );
  const [count, loading] = useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (response) => {
      return response.rows[0]?.data.count_uniq_page_url?.curr ?? 0;
    }
  );
  if (loading) return false;
  return count === 0;
};

const Body = () => {
  const currentUser = useCurrentUser();
  const verifiedSites = currentUser.space.domains.find((d) => d.verified);
  const [canView] = useHasCurrentUserRequiredScopes([
    'reports.content.view',
    'tags.view'
  ]);
  const hasEmptyData = useHasEmptyData();

  if (!canView) return <NoPermissions />;
  if (!verifiedSites) return <NoVerifiedSites />;
  if (hasEmptyData) return <EmptyAccountAnalytics />;
  return <ContentOverviewBody />;
};

export const PageContentOverviewV2 = () => {
  useTrackMixpanelView('view_content_v2');
  return (
    <>
      <Helmet>
        <title>Content | Affilimate</title>
      </Helmet>
      <ContentPageBody>
        <Body />
      </ContentPageBody>
    </>
  );
};
