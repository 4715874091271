import React from 'react';
import { Doc } from '../../../../../domainTypes/document';
import { TestDummy } from '../../../../../domainTypes/notifications';
import { useRoutes } from '../../../../../routes';
import { ModuleIcons } from '../../../../../services/moduleIcons';
import { Container } from '../../Item/Container';
import { Frame } from '../../Item/Frame';
import { Heading } from '../../Item/Heading';
import * as Icon from '../../Item/Icon';

export const Test_Dummy = ({ n }: { n: Doc<TestDummy> }) => {
  const { ROUTES } = useRoutes();
  return (
    <Frame n={n} href={ROUTES.base.url()}>
      <Container>
        <Icon.Container>
          <ModuleIcons.Recommendations size={Icon.SIZE} />
        </Icon.Container>
        <div>
          <Heading>This is a call to action</Heading>
          <div>Testing out notifications</div>
        </div>
      </Container>
    </Frame>
  );
};
