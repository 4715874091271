import React from 'react';
import { Chip, ChipProps } from '../../../../components/Chip';
import {
  ProductScanPageStatus,
  ProductScanStatus
} from '../../../../domainTypes/productScan';

export type Props = {
  status: ProductScanStatus | ProductScanPageStatus;
  short?: boolean;
};

const getChipType = (status: Props['status']): ChipProps['type'] => {
  if (status === 'DONE' || status === 'SUCCESS') {
    return 'SUCCESS';
  } else if (status === 'PENDING') {
    return 'PENDING';
  } else if (status === 'ERROR') {
    return 'ERROR';
  } else if (status === 'ABORTED') {
    return 'ABORTED';
  } else if (status === 'UNMODIFIED') {
    return 'UNMODIFIED';
  }
  return 'NONE';
};

export const ScanStatus = ({ status, short }: Props) => {
  const label = status.replace('_', ' ');
  return (
    <Chip
      label={short ? label.slice(0, 1) : label}
      type={getChipType(status)}
    />
  );
};
