import { isEqual } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import {
  AnalyticsFilter,
  ISOTimeRange
} from '../../../../domainTypes/analytics_v2';
import {
  coerceFilterMode,
  FilterMode,
  PlatformFilterDef
} from '../../../../domainTypes/filters';
import { useMappedLoadingValue } from '../../../../services/db';
import { PlatformWithColor } from '../../../PlatformWithColor';
import { toSearchRegexp } from '../../../SearchInput';
import {
  usePlatformsWithCommission,
  usePlatformsWithLinkCounts
} from '../../service/platform';
import {
  createPlatformFilterDefinition,
  validateFilterDefinition
} from '../filters';
import { DimensionMenuComponent } from './Dimension';
import { ADVANCED_MODES, FilterMenu, saveButtonLabel } from './FilterMenu';
import {
  OptionsList,
  SelectorLoader,
  SelectorShell,
  useCollectionState
} from './Selector';

interface PlatformMenuBodyProps {
  toggleValue: (value: string) => void;
  focusValue: (value: string) => void;
  filters: AnalyticsFilter[];
  value: Array<string>;
  range: ISOTimeRange;
}

const PlatformMenuBody: React.FC<PlatformMenuBodyProps> = ({
  toggleValue,
  filters,
  range,
  focusValue,
  value
}) => {
  const [search, setSearch] = useState('');

  const [options, loading] = useMappedLoadingValue(
    usePlatformsWithLinkCounts(filters, range),
    (platforms) =>
      platforms.map((platform) => ({
        label: <PlatformWithColor partner={platform} />,
        value: platform.key,
        searchValue: platform.name
      }))
  );

  const filteredOptions = useMemo(() => {
    const searchRe = toSearchRegexp(search);
    if (!options) {
      return [];
    }
    if (!searchRe) {
      return options;
    }
    return options.filter((o) => o.searchValue.match(searchRe));
  }, [options, search]);

  return (
    <SelectorShell label="Platforms" search={search} setSearch={setSearch}>
      {!options || loading ? (
        <SelectorLoader />
      ) : (
        <OptionsList
          options={filteredOptions}
          selectedValues={value}
          toggle={toggleValue}
          focus={focusValue}
        />
      )}
    </SelectorShell>
  );
};

const PlatformMenuBodyForTransactions: React.FC<PlatformMenuBodyProps> = ({
  toggleValue,
  focusValue,
  filters,
  value
}) => {
  const [search, setSearch] = useState('');

  const [options, loading] = useMappedLoadingValue(
    usePlatformsWithCommission(filters),
    (platforms) =>
      platforms.map((platform) => ({
        label: <PlatformWithColor partner={platform} />,
        value: platform.key,
        searchValue: platform.name
      }))
  );

  const filteredOptions = useMemo(() => {
    const searchRe = toSearchRegexp(search);
    if (!options) {
      return [];
    }
    if (!searchRe) {
      return options;
    }
    return options.filter((o) => o.searchValue.match(searchRe));
  }, [options, search]);

  return (
    <SelectorShell label="Platforms" search={search} setSearch={setSearch}>
      {!options || loading ? (
        <SelectorLoader />
      ) : (
        <OptionsList
          options={filteredOptions}
          selectedValues={value}
          toggle={toggleValue}
          focus={focusValue}
          caption={`Top platforms by earnings`}
        />
      )}
    </SelectorShell>
  );
};

const PlatformMenuInner: React.FC<{
  definition: PlatformFilterDef;
  onSave: (v: PlatformFilterDef) => void;
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
  isTransactionsPage: boolean;
  mode: FilterMode;
}> = ({ definition, onSave, filters, range, isTransactionsPage, mode }) => {
  const [value, toggleValue] = useCollectionState(
    coerceFilterMode(definition.mode) === mode ? definition.v : []
  );

  const focus = useCallback(
    (platform: string) =>
      onSave(createPlatformFilterDefinition([platform], mode)),
    [mode, onSave]
  );

  const newDefinition = useMemo<PlatformFilterDef>(
    () => createPlatformFilterDefinition(value, mode),
    [mode, value]
  );
  const enableSave =
    validateFilterDefinition(newDefinition) &&
    !isEqual(definition, newDefinition);

  return (
    <>
      <FilterMenu.Body>
        {isTransactionsPage ? (
          <PlatformMenuBodyForTransactions
            filters={filters}
            toggleValue={toggleValue}
            value={value}
            range={range}
            focusValue={focus}
          />
        ) : (
          <PlatformMenuBody
            filters={filters}
            toggleValue={toggleValue}
            value={value}
            range={range}
            focusValue={focus}
          />
        )}
      </FilterMenu.Body>
      <FilterMenu.Footer definition={definition}>
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={saveButtonLabel('platform', value.length, mode)}
        />
      </FilterMenu.Footer>
    </>
  );
};

export const PlatformMenu: DimensionMenuComponent<PlatformFilterDef> = ({
  definition,
  onSave,
  filters,
  range,
  renderContext
}) => {
  const [mode, setMode] = useState<FilterMode>(
    coerceFilterMode(definition.mode)
  );

  return (
    <FilterMenu>
      <FilterMenu.Header dimension="platform">
        <FilterMenu.ModeSelector
          modes={ADVANCED_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <PlatformMenuInner
        key={mode}
        definition={definition}
        onSave={onSave}
        filters={filters}
        range={range}
        isTransactionsPage={renderContext?.page === 'TRANSACTIONS'}
        mode={mode}
      />
    </FilterMenu>
  );
};
