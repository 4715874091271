import assertNever from 'assert-never';
import { sortBy, sumBy } from 'lodash';
import React from 'react';
import { Label, Text } from 'recharts';
import { UNKNOWN } from '../../../domainTypes/analytics';
import { CurrencyCode } from '../../../domainTypes/currency';
import { IEarning } from '../../../domainTypes/performance';
import { styled } from '../../../emotion';
import { useColorUnknown } from '../../../services/color';
import { useTheme } from '../../../themes';
import { Loader } from '../../Loader';
import { TooltipRow } from '../CustomTooltip';
import { IPieChartCell, PieChartWithLegend } from '../PieChartWithLegend';
import { formatChartCurrency, formatChartNumber } from '../Util';

export type EarningsPieChartData = {
  key: string;
  name: string;
  earnings: IEarning;
  color: string;
};

type Props = {
  data: EarningsPieChartData[] | void;
  loading: boolean;
  currency: CurrencyCode;
  count: number;
  animate?: boolean;
  height?: number;
  aspect?: number;
  metric?: 'earnings' | 'salesCount' | 'saleValue';
  putUnknownIntoOther?: boolean;
  hideLegend?: boolean;
  hideCenterLabel?: boolean;
};

const HEIGHT = 390;

const FormattedLabel = styled('div')`
  padding: 0;
  white-space: nowrap;
`;

export const EarningsPieChart = React.memo(
  ({
    data,
    loading,
    count = 10,
    currency,
    animate,
    height = HEIGHT,
    aspect = 1.5,
    metric = 'earnings',
    putUnknownIntoOther = false,
    hideLegend = false,
    hideCenterLabel = false
  }: Props) => {
    const theme = useTheme();
    const colorUnknown = useColorUnknown();

    if (loading || !data) {
      return <Loader height={height} />;
    }

    const getVal = (x: EarningsPieChartData) =>
      metric === 'earnings'
        ? x.earnings.total
        : metric === 'salesCount'
        ? x.earnings.salesCount
        : metric === 'saleValue'
        ? x.earnings.saleValue.total
        : assertNever(metric);

    const formatter = (x: number) =>
      metric === 'earnings'
        ? formatChartCurrency(x, currency)
        : metric === 'salesCount'
        ? formatChartNumber(x)
        : metric === 'saleValue'
        ? formatChartCurrency(x, currency)
        : assertNever(metric);

    const centerLabel =
      metric === 'earnings'
        ? 'Earnings'
        : metric === 'salesCount'
        ? 'Items sold'
        : metric === 'saleValue'
        ? 'Gross sales'
        : assertNever(metric);

    const totalSum = sumBy(data, (d) => getVal(d));
    const sorted = sortBy(data, (p) => {
      const sortValue = -getVal(p);
      return putUnknownIntoOther && p.key.toLowerCase() === UNKNOWN
        ? Infinity
        : sortValue;
    });
    const shownIndividually = sorted.slice(0, count);
    console.log('SH', shownIndividually);
    const rest = sorted.slice(count);

    const sortedData = shownIndividually.map<IPieChartCell>((d) => ({
      key: d.key,
      name: d.name,
      count: getVal(d),
      color: d.color
    }));

    if (rest.length) {
      sortedData.push({
        key: 'other',
        name: 'Other',
        count: rest.reduce((m, p) => m + getVal(p), 0),
        color: colorUnknown
      });
    }

    return (
      <PieChartWithLegend
        data={sortedData}
        animate={animate}
        aspect={aspect}
        hideLegend={hideLegend}
        extra={
          !hideCenterLabel && (
            <Label
              content={(props) => {
                const {
                  viewBox: { cx, cy }
                } = props;
                return (
                  <>
                    <Text
                      x={cx}
                      y={cy - 20}
                      textAnchor="middle"
                      verticalAnchor="middle"
                      style={{
                        fontSize: theme.custom.fontSize.m,
                        fill: theme.palette.grey[700]
                      }}
                    >
                      {centerLabel}
                    </Text>
                    <Text
                      x={cx}
                      y={cy + 5}
                      textAnchor="middle"
                      verticalAnchor="middle"
                      style={{ fontSize: theme.custom.fontSize.l }}
                    >
                      {formatter(totalSum)}
                    </Text>
                  </>
                );
              }}
            />
          )
        }
        formatter={(value, name) => {
          return (
            <FormattedLabel>
              <TooltipRow>
                <div>{name}</div>
                <div>{formatter(value as number)}</div>
              </TooltipRow>
            </FormattedLabel>
          );
        }}
      />
    );
  }
);
