import {
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography
} from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { AlertBox } from '../../../../components/AlertBox';
import { Form } from '../../../../components/Form';
import { FormField } from '../../../../components/Form/FormField';
import { HelpIcon } from '../../../../components/HelpIcon';
import { Loader } from '../../../../components/Loader';
import { NoPermissions } from '../../../../components/NoPermissions';
import { RecommendedBadge } from '../../../../components/RecommendedBadge';
import { ScheduleFormSimple } from '../../../../components/Schedule/SimpleForm';
import { Doc } from '../../../../domainTypes/document';
import { Schedule } from '../../../../domainTypes/schedule';
import { IDomain, ISpace } from '../../../../domainTypes/space';
import { css, styled } from '../../../../emotion';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { CanvasBar } from '../../../../layout/Canvas';
import { LimitedWidth } from '../../../../layout/PageBody';
import { Section } from '../../../../layout/Section';
import { ARTICLES } from '../../../../services/beacon';
import {
  useCurrentUser,
  useCurrentUserScopes,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { useFeatureEnabled } from '../../../../services/features';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { updateOrCreateSchedule } from '../../../../services/schedules/helper';
import { useProductScanSchedule } from '../../../../services/schedules/productScan';
import { updateAutoImportSpace } from '../../../../services/space';
import { PageBodyLinks } from '../../components/PageBodyLinks';

const Row = styled('div')`
  display: flex;
  align-items: center;
`;

const AutoImportByDomainSettings = ({ space }: { space: ISpace }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [canEditDomains] = useHasCurrentUserRequiredScopes(['domains.edit']);

  const onSubmit = (domain: IDomain) => {
    const { autoImport } = domain;
    return updateAutoImportSpace(space, domain, autoImport).catch((err) => {
      if (err.message === 'Cannot autoImport an unverified domain') {
        enqueueSnackbar('Verify the domain before enabling auto-import', {
          variant: 'error'
        });
      } else {
        enqueueSnackbar(
          'Could not enable auto-import. Please try again or contact Support.',
          { variant: 'error' }
        );
      }
    });
  };

  const activeDomains = space.domains.filter((d) => d.active);

  return (
    <div>
      {activeDomains.map((domain) => (
        <Form
          autoSave={true}
          onSubmit={onSubmit}
          initialValues={domain}
          key={domain.url}
        >
          {({ submitting }) => (
            <Row>
              <FormField name="autoImport" type="checkbox">
                {({ input }) => (
                  <FormControlLabel
                    label={domain.url}
                    classes={{ root: css(() => ({ marginLeft: 0 })) }}
                    control={
                      <Checkbox
                        color="primary"
                        checked={input.checked}
                        disabled={!canEditDomains}
                        onChange={input.onChange}
                      />
                    }
                  />
                )}
              </FormField>
              {submitting && <CircularProgress size={20} />}
            </Row>
          )}
        </Form>
      ))}
    </div>
  );
};

export const PageLinksSettings = () => {
  const { space } = useCurrentUser();
  const [productScanScheduleDoc] = useProductScanSchedule(space.id);
  const onChange = (schedule: Doc<Schedule>) =>
    updateOrCreateSchedule(schedule.id, schedule.data);
  const scopes = useCurrentUserScopes();
  const canEditSchedule = scopes.has('scans.settings.edit');
  const canSeeSettings = scopes.has('scans.settings.view');
  const hasScanlessTracking = useFeatureEnabled('SCANLESS_TRACKING');

  useTrackMixpanelView('view_scan_settings');

  return (
    <PageBodyLinks>
      <Helmet>
        <title>Link Import Settings | Affilimate</title>
      </Helmet>
      <LimitedWidth width={800}>
        {canSeeSettings && hasScanlessTracking && (
          <AlertBox variant="pending">
            <strong>Your account is set to use scanless tracking.</strong> This
            means your links are tracked automatically without needing to run
            scans of your site or sitemap. Do not enable link import or run it,
            or you may end up with duplicate links.
          </AlertBox>
        )}
        {canSeeSettings ? (
          <div>
            <Section>
              <CanvasBar>
                <div>Link Scan Settings</div>
              </CanvasBar>
              {!productScanScheduleDoc ? (
                <Loader height={140} />
              ) : (
                <Card>
                  <CardContent>
                    <Typography variant="body1" component="p" paragraph>
                      <strong>
                        How often should we scan your site for new affiliate
                        links?
                      </strong>
                      &nbsp; &nbsp;
                      <HelpIcon
                        color="blue"
                        articleId={ARTICLES.links.linkManagement}
                      >
                        How it works
                      </HelpIcon>
                    </Typography>
                    <Typography
                      variant="body2"
                      component="p"
                      color="textSecondary"
                      paragraph
                    >
                      Automatic scans will run shortly after the time they're
                      scheduled and check updated pages for new links.
                    </Typography>
                    <ScheduleFormSimple
                      disabled={!canEditSchedule}
                      label={
                        <>
                          Automatically scan your site for affiliate links
                          <RecommendedBadge label="Recommended" />
                        </>
                      }
                      doc={productScanScheduleDoc}
                      onChange={onChange}
                    />
                    <Typography
                      variant="body2"
                      component="p"
                      color="textSecondary"
                      style={{ marginTop: '24px' }}
                      paragraph
                    >
                      For most sites, choose Daily and set the time to the end
                      of your workday.
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Section>
            <Section>
              <Card>
                <CardContent>
                  <Typography variant="body1" component="p" paragraph>
                    <strong>Allow automatic import of new links</strong>

                    <RecommendedBadge label="Recommended" />
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    color="textSecondary"
                    paragraph
                  >
                    When we find new links during the scan, enable automatic
                    import for these domains:
                  </Typography>
                  <AutoImportByDomainSettings space={space} />
                  <AlertBox variant="pending" style={{ marginTop: '12px' }}>
                    For best results, clear all caching plugins and your CDN
                    after publishing, for both the page itself and sitemap.
                  </AlertBox>
                </CardContent>
              </Card>
            </Section>
            <Section>
              <Card>
                <CardContent>
                  <Typography variant="body1" component="p" paragraph>
                    <strong>Sitemap and Scan Speed Settings</strong>
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    color="textSecondary"
                    paragraph
                  >
                    Looking for more scan-related settings? Here's how to:
                  </Typography>
                  <Typography
                    variant="body2"
                    component="ul"
                    color="textSecondary"
                    style={{ listStyle: 'none', paddingLeft: 0 }}
                    paragraph
                  >
                    <li>
                      <HelpIcon
                        color="blue"
                        articleId={ARTICLES.links.customSitemap}
                      >
                        Set a custom sitemap location
                      </HelpIcon>
                    </li>
                    <li>
                      <HelpIcon
                        color="blue"
                        articleId={ARTICLES.linkCloaker.custom}
                      >
                        Add a custom redirect / link cloaker domain
                      </HelpIcon>
                    </li>
                    <li>
                      <HelpIcon
                        color="blue"
                        articleId={ARTICLES.links.crawlSpeed}
                      >
                        Adjust the crawl speed
                      </HelpIcon>
                    </li>
                  </Typography>
                </CardContent>
              </Card>
            </Section>
          </div>
        ) : (
          <NoPermissions />
        )}
      </LimitedWidth>
    </PageBodyLinks>
  );
};
