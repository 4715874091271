import { groupBy, keyBy, mapValues } from 'lodash';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import { UNKNOWN } from '../../components/GroupableList';
import {
  DAY_FORMAT,
  Timeframe,
  TIMEKEY_FORMAT
} from '../../domainTypes/analytics';
import { CurrencyCode } from '../../domainTypes/currency';
import {
  EarningsArgsGroupedInTimeframeAsTimeseries,
  EarningsRespGroupedInTimeframeAsTimeseries,
  EMPTY_DAILY_EARNING,
  IDailyEarning,
  SalesFilterArgs,
  toDailyEarningFromMinimal
} from '../../domainTypes/performance';
import { useStringQueryParam } from '../../routes';
import { LoadingValue, useMappedLoadingValue } from '../db';
import { useFeatureEnabled } from '../features';
import { msToTimeframe } from '../time';
import { useEarningsSingle } from './earnings';

const OTHER = 'OTHER';

export const usePostgres = () => {
  const [dataProvider] = useStringQueryParam('dataProvider', 'pg');
  const pgEnabled = useFeatureEnabled('POSTGRES_V1');
  // allow to override the feature flag by providing dataProvider=fs
  return dataProvider === 'fs' ? false : pgEnabled || dataProvider === 'pg';
};

const getTimeKeyRangeFromTimeframe = (timeframe: Timeframe): string[] => {
  const start = moment.tz(timeframe.start, DAY_FORMAT, timeframe.tz);
  const end = moment.tz(timeframe.end, DAY_FORMAT, timeframe.tz);
  const range = Math.abs(start.diff(end, 'days'));
  const result: string[] = [];
  for (let i = 0; i < range; i++) {
    const x = start.clone().add(i, 'days').format(TIMEKEY_FORMAT);
    result.push(x);
  }
  return result;
};

export const useEarningsAsTimeseriesForNetworks = (
  spaceId: string,
  q: SalesFilterArgs,
  currency: CurrencyCode
): LoadingValue<{
  [origin: string]: {
    [partnerKey: string]: IDailyEarning[];
  };
}> => {
  const query: EarningsArgsGroupedInTimeframeAsTimeseries = useMemo(() => {
    return {
      type: 'groupedInTimeframeAsTimeseries',
      d: {
        groupBy: ['origin', 'partner_key'],
        ...q,
        currency
      }
    };
  }, [q, currency]);
  return useMappedLoadingValue(
    useEarningsSingle<EarningsRespGroupedInTimeframeAsTimeseries>(
      spaceId,
      query,
      currency
    ),
    ({ time, res: data }) => {
      console.log('timeseries', time, data);
      const tkRange = getTimeKeyRangeFromTimeframe(msToTimeframe(data.q.dates));
      const byOrigin = groupBy(data.d, (x) => {
        const k = `${x.group['origin'] || UNKNOWN}`;
        return k === UNKNOWN ? OTHER : k;
      });
      return mapValues(byOrigin, (xs) => {
        const byPartnerKey = keyBy(xs, (x) => {
          const k = `${x.group['partner_key'] || UNKNOWN}`;
          return k === UNKNOWN ? OTHER : k;
        });
        return mapValues(byPartnerKey, (x) => {
          const series = x.ds;
          if (tkRange.length === series.length) {
            return series.map(toDailyEarningFromMinimal);
          }
          const dict = keyBy(series, (t) => t.tk);
          return tkRange.map((tk) =>
            dict[tk]
              ? toDailyEarningFromMinimal(dict[tk])
              : EMPTY_DAILY_EARNING(currency, tk)
          );
        });
      });
    }
  );
};
