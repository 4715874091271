import { compact, keyBy } from 'lodash';
import { useMemo } from 'react';
import { useFilterClauses } from '../../../../components/analytics_v2/Filters/hooks';
import { createSearchBox } from '../../../../components/analytics_v2/SearchBox';
import { useTimeframe } from '../../../../components/analytics_v2/Timeframe';
import {
  AnalyticsFilter,
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponse,
  SelectableField
} from '../../../../domainTypes/analytics_v2';
import { IPostgresTags } from '../../../../domainTypes/tags';
import { Metric } from '../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import {
  LoadingValue,
  useCombineLoadingValues,
  useMappedLoadingValue
} from '../../../../services/db';
import { useTagsForCurrentUser } from '../../../../services/tags';
import { useBestIntervalForTimeframe } from '../../../../hooks/timeframe';

const mergeRowsWithTags = ([result, tagsById]: [
  AnalyticsResponse,
  Record<string, IPostgresTags>
]) =>
  result.rows.map((row) => ({
    ...row,
    extra: {
      tags: (row.data.agg_uniq_tags?.curr ?? []).map((tagId) => tagsById[tagId])
    }
  }));

export const usePagesMetrics = (
  metrics: Metric[],
  paginate: AnalyticsQuery['paginate'],
  orderBy: AnalyticsOrderBy
) => {
  const { space } = useCurrentUser();
  const tf = useTimeframe();
  const filters = useContentFilters();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      groupBy: ['page_url'],
      select: [...metrics, 'agg_uniq_tags'],
      ...tf,
      filters: [
        ...filters,
        {
          field: 'page_url',
          condition: 'not in',
          values: ['']
        }
      ],
      paginate,
      orderBy: [orderBy]
    };
  }, [metrics, tf, filters, paginate, orderBy]);

  return useMappedLoadingValue(
    useCombineLoadingValues(
      useAnalyticsQueryV2(space.id, query),
      useTagsById()
    ),
    mergeRowsWithTags
  );
};

export const PAGE_SIZE = 100;

export const usePagesCount = () => {
  const { space } = useCurrentUser();
  const filters = useContentFilters();
  const { range } = useTimeframe();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: ['count_uniq_page_url'],
      range,
      filters
    };
  }, [range, filters]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (data) => data.rows[0]?.data.count_uniq_page_url?.curr ?? 0
  );
};

export const useExportQuery = (
  metrics: Metric[],
  orderBy: AnalyticsOrderBy
): AnalyticsQuery => {
  const tf = useTimeframe();
  const filters = useContentFilters();
  return useMemo<AnalyticsQuery>(
    () => ({
      groupBy: ['page_url'],
      select: [...metrics, 'agg_uniq_tags'],
      ...tf,
      filters: [
        ...filters,
        {
          field: 'page_url',
          condition: 'not in',
          values: ['']
        }
      ],
      orderBy: [orderBy]
    }),
    [filters, metrics, orderBy, tf]
  );
};

export const [SearchBox, useSearch] = createSearchBox('q');

export const useContentFilters = (): AnalyticsFilter[] => {
  const filters = useFilterClauses();
  const search = useSearch('page_url');

  return useMemo(() => compact([search, ...filters]), [search, filters]);
};

export const useTagsById = (): LoadingValue<Record<string, IPostgresTags>> => {
  return useMappedLoadingValue(useTagsForCurrentUser(), (tags) =>
    keyBy(tags, (t) => t.id)
  );
};

export const useSearchTrend = (metric: SelectableField) => {
  const { space } = useCurrentUser();
  const { range } = useTimeframe();
  const interval = useBestIntervalForTimeframe();
  const filters = useContentFilters();

  const query = useMemo<AnalyticsQuery>(
    () => ({
      range,
      filters,
      select: [metric],
      interval
    }),
    [filters, interval, metric, range]
  );

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (response) =>
      response.rows.map((row) => ({
        timestamp: row.group.interval,
        value: row.data[metric]?.curr ?? 0
      }))
  );
};

export const useSearchSummary = (metrics: SelectableField[]) => {
  const { space } = useCurrentUser();
  const { range, compare } = useTimeframe();
  const filters = useContentFilters();

  const query = useMemo<AnalyticsQuery>(
    () => ({
      range,
      compare,
      filters,
      select: [...metrics, 'count_uniq_page_url', 'agg_uniq_page_url']
    }),
    [compare, filters, metrics, range]
  );

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (response) => response.rows[0]
  );
};

export const useAllPagesQuery = () => {
  const filters = useContentFilters();
  const { space } = useCurrentUser();
  const { range } = useTimeframe();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: ['agg_uniq_page_url'],
      range,
      filters
    };
  }, [range, filters]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (data) => data.rows[0]?.data.agg_uniq_page_url?.curr ?? []
  );
};

export const useCanAssignTags = (): boolean => {
  const [canAssignTags] = useHasCurrentUserRequiredScopes([
    'tags.add_or_remove'
  ]);

  return canAssignTags;
};
