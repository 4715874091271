import { sumBy } from 'lodash';
import { Campaign, getFlatSpendAmount } from './index';
import { CampaignData } from '../components/list/useCampaignsData';

export const campaignGroupBys = [
  'status',
  'manager',
  'start-month',
  'end-month',
  'start-year',
  'end-year',
  'start-quarter',
  'end-quarter'
] as const;

export type CampaignGroupBy = typeof campaignGroupBys[number];

export const campaignsGroupLabel = (group: CampaignGroupBy): string => {
  switch (group) {
    case 'manager':
      return 'Manager';
    case 'status':
      return 'Status';
    case 'start-month':
      return 'Start month';
    case 'end-month':
      return 'End month';
    case 'start-year':
      return 'Start year';
    case 'end-year':
      return 'End year';
    case 'start-quarter':
      return 'Start quarter';
    case 'end-quarter':
      return 'End quarter';
  }
};

const keyFn = (groupBy: CampaignGroupBy): ((c: Campaign) => string[]) => {
  switch (groupBy) {
    case 'status':
      return (c) => [c.status];
    case 'manager':
      return (c) => c.managers;
    case 'start-month':
      return (c) => [c.timeframe?.start.format('MM/YYYY') ?? 'Not set'];
    case 'end-month':
      return (c) => [c.timeframe?.end.format('MM/YYYY') ?? 'Not set'];
    case 'start-year':
      return (c) => [c.timeframe?.start.format('YYYY') ?? 'Not set'];
    case 'end-year':
      return (c) => [c.timeframe?.end.format('YYYY') ?? 'Not set'];
    case 'start-quarter':
      return (c) => [c.timeframe?.start.format('[Q]Q YYYY') ?? 'Not set'];
    case 'end-quarter':
      return (c) => [c.timeframe?.end.format('[Q]Q YYYY') ?? 'Not set'];
  }
};

export const groupCampaigns = (
  campaigns: CampaignData[],
  groupBy: CampaignGroupBy
) => {
  const key = keyFn(groupBy);
  return campaigns.reduce((groups, campaignData) => {
    const ids = key(campaignData.campaign);
    return ids.reduce((groups, id) => {
      return {
        ...groups,
        [id]: [...(groups[id] || []), campaignData]
      };
    }, groups);
  }, {} as Record<string, CampaignData[]>);
};

export interface CampaignsGroupMetrics {
  count: number;
  flatSpend: number;
  clicks: number;
  pageviews: number;
  impressions: number;
  gmv: number;
  orders: number;
}

export const summarizeCampaigns = (
  campaigns: CampaignData[]
): CampaignsGroupMetrics => ({
  flatSpend: sumBy(campaigns, (c) => getFlatSpendAmount(c.campaign)),
  count: campaigns.length,
  clicks: sumBy(campaigns, (c) => c.totals?.clicks ?? 0),
  pageviews: sumBy(campaigns, (c) => c.totals?.pageviews ?? 0),
  impressions: sumBy(campaigns, (c) => c.totals?.impressions ?? 0),
  gmv: sumBy(campaigns, (c) => c.totals?.gmv ?? 0),
  orders: sumBy(campaigns, (c) => c.totals?.orders ?? 0)
});
