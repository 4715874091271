import { IApiReportHandler } from '../types';
import { HANDLER_CONFIGS } from '../../../../../domainTypes/reporting';
import { getSecretValue } from '../../../../../services/secret';

export const LEADDYNO: IApiReportHandler = {
  type: 'API',
  partnerKey: 'leaddyno',
  configName: 'leaddynoV1',
  configFields: HANDLER_CONFIGS.leaddynoV1,
  additionalName: (_, secret) => {
    if (!secret) {
      return null;
    }
    return getSecretValue(secret.data, 'advertiserName', '') || null;
  }
};
