// NOTE: Polymorphic declaration to make TS satisfied
import {
  CalendarDate,
  parseDate,
  parseZonedDateTime,
  ZonedDateTime
} from '@internationalized/date';
import { ISOTimeRange } from '../../../domainTypes/analytics_v2';
import moment, { Moment } from 'moment-timezone';

import { CalendarDateRange, DateTimeRange } from './range';

export function toCalendarDate(m: Moment): CalendarDate;
export function toCalendarDate(m: undefined): undefined;
export function toCalendarDate(m: Moment | undefined): CalendarDate | undefined;
export function toCalendarDate(
  m: Moment | undefined
): CalendarDate | undefined {
  try {
    return m ? parseDate(m.format('YYYY-MM-DD')) : undefined;
  } catch (e) {
    return undefined;
  }
}

export function toZonedDateTime(m: Moment): ZonedDateTime {
  const {
    date,
    minutes,
    months,
    milliseconds,
    seconds,
    years,
    hours
  } = m.toObject();
  const offsetInMs = m.utcOffset() * 60 * 1000;
  const tz = m.tz() ?? 'UTC';
  return new ZonedDateTime(
    years,
    months + 1,
    date,
    tz,
    offsetInMs,
    hours,
    minutes,
    seconds,
    milliseconds
  );
}

export const toEndOfDay = (date: CalendarDate, tz: string): ZonedDateTime => {
  const zdt = parseZonedDateTime(`${date.toString()}T23:59[${tz}]`);
  return zdt.set({
    second: 59,
    millisecond: 999
  });
};

export const endOfDay = (date: ZonedDateTime): ZonedDateTime => {
  return date.set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 999
  });
};

export const toStartOfDay = (date: CalendarDate, tz: string): ZonedDateTime => {
  return parseZonedDateTime(`${date.toString()}T00:00[${tz}]`);
};

export const momentsToRange = (range: {
  start: Moment;
  end: Moment;
}): CalendarDateRange => {
  return {
    start: toCalendarDate(range.start),
    end: toCalendarDate(range.end)
  };
};

export const rangeToMoments = (
  range: DateTimeRange,
  tz: string
): { start: moment.Moment; end: moment.Moment } => {
  return {
    start: moment(range.start.toAbsoluteString()).tz(tz),
    end: moment(range.end.toAbsoluteString()).tz(tz)
  };
};

export const rangeToISOStrings = (
  range: DateTimeRange,
  tz: string
): ISOTimeRange => {
  const { start, end } = rangeToMoments(range, tz);
  return {
    start: start.toISOString(),
    end: end.toISOString()
  };
};
