import { groupBy, kebabCase } from 'lodash';
import moment from 'moment-timezone';
import { CurrencyCode } from '../../../../../domainTypes/currency';
import { ISale, SaleStatus } from '../../../../../domainTypes/performance';
import { detectCurrency } from '../../../../../services/currency';
import { fromMoment } from '../../../../../services/time';
import { addDevice, toCents, toPercent } from '../helpers';
import { IFileReportHandler } from '../types';

const fromAmazonNumberToCents = (str: string) => {
  const commaForCents = str.indexOf(',') === str.length - 3;
  const withCorrectCommas = commaForCents
    ? toCents(str.replace('.', '').replace(',', '.'))
    : toCents(str.replace(',', ''));
  return Math.abs(withCorrectCommas);
};

const detectCurrencyFromRow = (
  priceHeader: string,
  trackingId: string
): CurrencyCode | null => {
  if (priceHeader === '価格') {
    return 'JPY';
  }

  if (priceHeader === 'cena') {
    return 'PLN';
  }

  if (priceHeader === 'Pris' && trackingId.endsWith('-21')) {
    return 'SEK';
  }

  const match = priceHeader.match(/.+\((.+)\)/);
  // GBP has no currency info in the header!

  if (priceHeader === 'Price' && trackingId.endsWith('-20')) {
    return 'USD';
  }

  if (!match || !match[1]) {
    return 'GBP';
  }

  const currency = detectCurrency(match[1]);

  if (currency === 'USD' && trackingId.endsWith('-22')) {
    return 'CAD';
  }

  return currency;
};

const parseDate = (date: string) => {
  // Parse a date like November 23, 2023 to YYYY-MM-DD
  return moment(date, 'MMMM DD, YYYY').format('YYYY-MM-DD');
};

type StandardAmazonRow = {
  type: 'Standard';
  category: string;
  productName: string;
  productId: string;
  seller: string;
  trackingLabel: string;
  shippedDate: string;
  price: string;
  quantity: string;
  isReturn: string;
  revenue: string;
  commission: string;
  device: string;
};

type SubtagAmazonRow = {
  type: 'Subtag';
  category: string;
  seller: string;
  trackingLabel: string;
  shippedDate: string;
  price: string;
  rate: string;
  quantity: string;
  revenue: string;
  commission: string;
  subtag: string;
};

const convertStandardRow = ({
  row,
  i,
  reportId,
  currency,
  integrationId
}: {
  row: StandardAmazonRow;
  i: number;
  reportId: string;
  integrationId: string;
  currency: CurrencyCode;
}) => {
  const saleDate = moment.tz(row.shippedDate, 'YYYY-MM-DD HH:mm:ss', 'UTC');
  const rowAddon = i === 0 ? `` : `-${i + 1}`;
  const saleId = saleDate.valueOf() + row.productId + rowAddon;
  const completionDate = saleDate;
  const status = parseInt(row.isReturn, 10) === 1 ? 'Refunded' : 'Final';
  const price = toCents(row.price);
  const revenueInCents = toCents(row.revenue);
  const multiplier = status === 'Refunded' ? -1 : 1;
  const revenue = revenueInCents * multiplier;
  const commission = toCents(row.commission) * multiplier;
  const commissionPercent = toPercent(commission, revenue);
  const quantity = parseInt(row.quantity, 10);

  const sale: ISale = {
    saleId,
    saleDate: fromMoment(saleDate),
    reportId,
    integrationId,
    trackingId: '',
    trackingLabel: row.trackingLabel || '',
    origin: null,
    completionDate: fromMoment(completionDate),
    status,
    partnerKey: 'amazon',
    partnerProductName: row.productName,
    partnerProductId: row.productId,
    payoutId: null,
    payoutDate: null,
    payoutStatus: null,
    lastModified: null,
    coupon: '',
    saleType: 'cpa',
    amount: {
      currency,
      revenue,
      price,
      commission
    },
    commissionPercent,
    quantity,
    advertiserId: 'amazon',
    advertiserName: 'Amazon',
    metadata: {
      category: row.category,
      seller: row.seller,
      subId1: row.trackingLabel || ''
    }
  };

  addDevice(sale, row.device, {
    DESKTOP: 'desktop',
    PHONE: 'mobile',
    TABLET: 'tablet'
  });
  return sale;
};

const convertSubtagRow = ({
  row,
  i,
  reportId,
  currency,
  integrationId,
  storeId
}: {
  row: SubtagAmazonRow;
  i: number;
  reportId: string;
  integrationId: string;
  currency: CurrencyCode;
  storeId: string;
}): ISale => {
  const tz = currency === 'CAD' ? 'America/Vancouver' : 'UTC';
  const saleDate = moment.tz(row.shippedDate, 'MMMM DD, YYYY', tz).valueOf();
  const kebabCategory = kebabCase(row.category);
  const saleId = `${storeId}-${saleDate}-${kebabCategory}-${i}`;
  const isRefund = row.revenue.startsWith('-');
  const status: SaleStatus = isRefund ? 'Refunded' : 'Final';

  const sale: ISale = {
    saleId,
    saleDate: fromMoment(moment.tz(row.shippedDate, 'MMMM DD, YYYY', tz)),
    reportId,
    integrationId,
    trackingId: '',
    trackingLabel: row.subtag || '',
    origin: null,
    completionDate: null,
    status,
    partnerKey: 'amazon',
    partnerProductName: row.category,
    partnerProductId: '',
    payoutId: null,
    payoutDate: null,
    payoutStatus: null,
    lastModified: null,
    coupon: '',
    saleType: 'cpa',
    amount: {
      currency,
      revenue: fromAmazonNumberToCents(row.revenue),
      price: fromAmazonNumberToCents(row.price),
      commission: fromAmazonNumberToCents(row.commission)
    },
    commissionPercent: toPercent(
      fromAmazonNumberToCents(row.commission),
      fromAmazonNumberToCents(row.revenue)
    ),
    quantity: Math.abs(parseInt(row.quantity, 10)),
    advertiserId: 'amazon',
    advertiserName: 'Amazon',
    metadata: {
      category: row.category,
      seller: row.seller.replace(/"/g, '') || '',
      subId1: row.trackingLabel,
      subId2: row.subtag,
      subId3: storeId
    }
  };

  return sale;
};

export const AMAZON: IFileReportHandler<string> = {
  type: 'CSV',
  partnerKey: 'amazon',
  parser: {
    name: 'Fee earnings report',
    csvHeaders: '',
    parseOptions: { quoteChar: '' },
    matches: (text) => {
      return (
        text.indexOf('Fee-Earnings reports from') === 0 ||
        text.indexOf('earnings report for') === 0
      );
    },
    processRows: async (rows, { reportId, integrationId }) => {
      // First detect if it's a subtag file
      const headers = rows[1];

      const isSubtagFile = headers.indexOf('Sub Tag') !== -1;
      const priceHeader = !isSubtagFile ? headers[6] : headers[4];
      const trackingId = !isSubtagFile ? rows[2][4] : rows[2][2];
      const currency = detectCurrencyFromRow(priceHeader, trackingId);

      if (!currency) {
        throw new Error(`UNKNOWN CURRENCY - ${priceHeader}`);
      }

      const groupedRows = groupBy(rows.slice(2), (row) => {
        const [
          // eslint-disable-next-line
          c1,
          // eslint-disable-next-line
          c2,
          c3,
          // eslint-disable-next-line
          c4,
          // eslint-disable-next-line
          c5,
          c6
        ] = row;

        const uniqId = isSubtagFile ? kebabCase(c1) : c3;
        const date = isSubtagFile ? parseDate(c4) : c6;

        return `${date}-${uniqId}`;
      });
      const keys = Object.keys(groupedRows);

      return keys.reduce((memo, key) => {
        const asinGroup = groupedRows[key];
        const sales = asinGroup.map((row, i) => {
          const [c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c12] = row;

          if (!isSubtagFile) {
            const standardRow: StandardAmazonRow = {
              type: 'Standard',
              category: c1,
              productName: c2,
              productId: c3,
              seller: c4,
              trackingLabel: c5,
              shippedDate: c6,
              price: c7,
              quantity: c8,
              isReturn: c9,
              revenue: c10,
              commission: c11,
              device: c12
            };
            return convertStandardRow({
              row: standardRow,
              i,
              reportId,
              currency,
              integrationId
            });
          }

          const subtagRow: SubtagAmazonRow = {
            type: 'Subtag',
            category: c1,
            seller: c2,
            trackingLabel: c3,
            shippedDate: c4,
            price: c5,
            rate: c6,
            quantity: c7,
            revenue: c8,
            commission: c9,
            subtag: c10
          };

          // Extract store id "mysimon" from text like:
          // earnings report for mysimon 2023-11-10 to 2023-11-10

          const match = rows[0][0].match(/\bearnings report for\s+([^\s]+)/);
          const storeId = match ? match[1] : null;

          return convertSubtagRow({
            row: subtagRow,
            i,
            reportId,
            currency,
            integrationId,
            storeId
          });
        });
        return [...memo, ...sales];
      }, [] as ISale[]);
    }
  },
  splitFile: (f) => {
    return [f];
  }
};
