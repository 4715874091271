import React, { useMemo } from 'react';
import { FilterDefinition } from '../../../../domainTypes/filters';
import { Metric } from '../../../../services/analyticsV2/metrics';
import { useFilters } from '../hooks';
import { DimensionMenu } from '../Menus/Dimension';
import {
  AnalyticsFilter,
  ISOTimeRange
} from '../../../../domainTypes/analytics_v2';
import { FilterDrawerRenderContext } from './types';

interface FilterMenuBodyProps {
  initialDefinition: FilterDefinition;
  onSave: (value: FilterDefinition) => void;
  orderBy: Metric;
  range: ISOTimeRange;
  renderContext?: FilterDrawerRenderContext;
  baseFilters: AnalyticsFilter[];
}

export const FilterMenuBody: React.FC<FilterMenuBodyProps> = ({
  initialDefinition,
  onSave,
  orderBy,
  range,
  renderContext,
  baseFilters
}) => {
  const filterDefinitions = useFilters();
  const filterClauses = useMemo(
    () => [
      ...baseFilters,
      ...filterDefinitions
        .filter((f) => f.dimension !== initialDefinition.k)
        .map((f) => f.clause)
    ],
    [filterDefinitions, baseFilters, initialDefinition.k]
  );

  return (
    <DimensionMenu
      filters={filterClauses}
      orderBy={orderBy}
      range={range}
      definition={initialDefinition}
      onSave={onSave}
      renderContext={renderContext}
    />
  );
};
