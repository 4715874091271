import {
  AnalyticsField,
  AnalyticsResponseRowWithComparison
} from '../../../domainTypes/analytics_v2';
import moment from 'moment-timezone';
import { find, last, sum, values } from 'lodash';
import { Metric } from '../../../services/analyticsV2/metrics';

export type SummedSeries = {
  series: AnalyticsResponseRowWithComparison[];
  sum: number;
  lastInterval?: string;
  intervalAfterNow?: string;
};

const findIntervals = (
  rows: AnalyticsResponseRowWithComparison[]
): Pick<SummedSeries, 'lastInterval' | 'intervalAfterNow'> => {
  const lastInterval = last(rows)?.group.interval;
  const intervalAfterNow = find(rows, (row) =>
    moment().isBefore(row.group.interval)
  )?.group.interval;

  return {
    lastInterval,
    intervalAfterNow
  };
};

export const cumulativeSumGrouped = (
  rows: AnalyticsResponseRowWithComparison[],
  metric: Metric,
  field: AnalyticsField
): SummedSeries => {
  const now = moment();
  let currSums: Record<string, number> = {};

  const series = rows.map((row) => {
    if (now.isBefore(row.group.interval)) {
      return {
        ...row,
        data: {
          ...row.data,
          [metric]: undefined
        }
      };
    }
    const currentMetricValue = row.data[metric]?.curr ?? 0;
    const fieldValue = row.group[field];
    currSums[fieldValue] = (currSums[fieldValue] ?? 0) + currentMetricValue;

    return {
      ...row,
      data: {
        ...row.data,
        [metric]: {
          curr: currSums[fieldValue]
        }
      }
    };
  });

  return {
    series,
    sum: sum(values(currSums)),
    ...findIntervals(series)
  };
};

export const cumulativeSum = (
  rows: AnalyticsResponseRowWithComparison[],
  metric: Metric
): SummedSeries => {
  const now = moment();
  let currSum = 0;

  const series = rows.map((row) => {
    if (now.isBefore(row.group.interval)) {
      return {
        ...row,
        data: {
          ...row.data,
          [metric]: undefined
        }
      };
    }
    const currentMetricValue = row.data[metric]?.curr ?? 0;
    currSum += currentMetricValue;

    return {
      ...row,
      data: {
        ...row.data,
        [metric]: {
          curr: currSum
        }
      }
    };
  });

  return {
    series,
    sum: currSum,
    ...findIntervals(series)
  };
};
