import { AutoSizer, List } from 'react-virtualized';
import { getDomainName, getPathName } from '../../../../../services/pages';
import { Truncated } from '../../../../../components/Truncated';
import { Typography } from '@material-ui/core';
import { css } from '../../../../../emotion';
import React from 'react';

export const PagesList = ({ pages }: { pages: string[] }) => (
  <AutoSizer>
    {({ height, width }) => (
      <List
        width={width}
        height={height}
        rowHeight={40}
        rowCount={pages.length}
        rowRenderer={({ key, index, style }) => {
          const href = pages[index];
          const pathName = getPathName(href);
          const domain = getDomainName(href);

          return (
            <div key={key} style={style}>
              <Truncated title={href}>
                <Typography
                  className={css((t) => ({
                    fontSize: '14px',
                    color: t.palette.primary.main,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                  }))}
                >
                  {pathName}
                </Typography>
              </Truncated>
              <Typography
                className={css(() => ({
                  fontSize: '11px',
                  fontWeight: 700,
                  color: '#acacb9'
                }))}
              >
                {domain}
              </Typography>
            </div>
          );
        }}
      />
    )}
  </AutoSizer>
);