import React from 'react';
import { css } from '../../../../../../emotion';
import { Chip } from '@material-ui/core';
import { useTheme } from '../../../../../../themes';

interface Details {
  isActive: boolean;
  color: string;
  label: string;
  text: string;
  key: string;
}

export const LinkScore = ({ score }: { score: number }) => {
  const theme = useTheme();
  const configs: Details[] = [
    {
      key: 'high',
      isActive: score === 1,
      color: theme.custom.colors.success.main,
      text: theme.custom.colors.success.contrastText,
      label: 'High'
    },
    {
      key: 'medium',
      isActive: score > 0.5,
      color: theme.custom.colors.primary.main,
      text: theme.custom.colors.primary.contrastText,
      label: 'Medium'
    },
    {
      key: 'low',
      isActive: score <= 0.5,
      color: theme.custom.colors.pending.main,
      text: '#874d00',
      label: 'Needs review'
    }
  ];

  const config = configs.find((c) => c.isActive);

  if (!config) {
    return null;
  }

  return (
    <Chip
      label={config.label}
      classes={{
        root: css((t) => ({
          color: config.text,
          fontWeight: 'bold',
          backgroundColor: config.color
        }))
      }}
    />
  );
};
