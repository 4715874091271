import { Component } from 'react';
import { block } from '../../services/history';

export class Interceptor extends Component<{ if: boolean }> {
  unblock: null | (() => void) = null;

  componentDidMount() {
    this.unblock = block(() => this.props.if);
  }

  componentWillUnmount() {
    if (this.unblock) {
      this.unblock();
    }
    this.unblock = null;
  }

  render() {
    return null;
  }
}
