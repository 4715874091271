import { difference, isEqual } from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useMemo, useState } from 'react';
import {
  AnalyticsFilter,
  AnalyticsQuery,
  ISOTimeRange
} from '../../../../domainTypes/analytics_v2';
import { useMappedLoadingValue } from '../../../../services/db';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import { OptionsList, SelectorLoader, SelectorShell, toggle } from './Selector';
import { BASIC_MODES, FilterMenu } from './FilterMenu';
import { Metric, metricName } from '../../../../services/analyticsV2/metrics';
import { SiteFilterDef } from '../../../../domainTypes/filters';
import { useCurrentUser } from '../../../../services/currentUser';
import { getActiveDomainUrls } from '../../../../services/space';
import {
  createSiteFilterDefinition,
  validateFilterDefinition
} from '../filters';
import { DimensionMenuComponent } from './Dimension';

interface SiteMenuBodyProps {
  filters: AnalyticsFilter[];
  orderBy: Metric;
  range: ISOTimeRange;
  value: SiteFilterDef['v'];
  onChange: (v: SiteFilterDef['v']) => void;
  onSave: (v: SiteFilterDef) => void;
}

const SiteMenuBody: React.FC<SiteMenuBodyProps> = ({
  range,
  filters,
  orderBy,
  onChange,
  value,
  onSave
}) => {
  const [search, setSearch] = useState('');
  const { space } = useCurrentUser();
  const spaceSites = useMemo(() => {
    return getActiveDomainUrls(space, true);
  }, [space]);

  const query = useMemo<AnalyticsQuery>(
    () => ({
      range,
      select: [orderBy],
      groupBy: ['page_url_origin'],
      orderBy: [
        {
          field: orderBy,
          direction: 'DESC'
        }
      ],
      filters: [
        ...filters,
        {
          field: 'page_url_origin',
          condition: 'in',
          values: spaceSites
        }
      ]
    }),
    [range, orderBy, filters, spaceSites]
  );

  const [sites, loading] = useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (response) => response.rows.map((row) => row.group.page_url_origin)
  );

  const _toggle = useCallback((v: string) => onChange(toggle(value, v)), [
    onChange,
    value
  ]);

  const focus = useCallback(
    (site: string) => onSave(createSiteFilterDefinition([site])),
    [onSave]
  );

  const allSites = [...difference(value, sites || []), ...(sites || [])];
  const options = allSites.map((site) => ({
    label: site,
    value: site
  }));

  return (
    <SelectorShell label="Sites" search={search} setSearch={setSearch}>
      {loading || !sites ? (
        <SelectorLoader />
      ) : (
        <OptionsList
          caption={`Top sites by ${metricName(orderBy)}`}
          options={options}
          selectedValues={value}
          toggle={_toggle}
          focus={focus}
        />
      )}
    </SelectorShell>
  );
};

export const SiteMenu: DimensionMenuComponent<SiteFilterDef> = ({
  filters,
  range,
  onSave,
  definition,
  orderBy
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const [value, setValue] = useState(definition.v);
  const newDefinition = useMemo<SiteFilterDef>(
    () => createSiteFilterDefinition(value),
    [value]
  );
  const enableSave =
    validateFilterDefinition(newDefinition) &&
    !isEqual(definition, newDefinition);

  return (
    <FilterMenu>
      <FilterMenu.Header dimension="site">
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <SiteMenuBody
          filters={filters}
          orderBy={orderBy}
          range={range}
          onChange={setValue}
          onSave={onSave}
          value={value}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer definition={definition}>
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={`Filter by ${pluralize('site', value.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
