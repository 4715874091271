import { CF } from '../../../../versions';
import { IPermissions } from '../../../../domainTypes/permissions';
import { CreateInvitationResponse } from '../../../../domainTypes/user';
import { callFirebaseFunction } from '../../../../services/firebaseFunctions';

export const updatePermissions = (
  userId: string,
  spaceId: string,
  permissions: IPermissions
) => {
  return callFirebaseFunction(CF.user.editPermissions, {
    userId,
    spaceId,
    permissions
  });
};

export const createInvitation = (
  spaceId: string,
  email: string,
  permissions: IPermissions
): Promise<CreateInvitationResponse> => {
  return callFirebaseFunction<CreateInvitationResponse>(
    CF.user.createInvitation,
    {
      spaceId,
      email,
      permissions,
      triggerMail: true
    }
  );
};

export const resendAndUpdateInvitation = (
  invitationId: string
): Promise<{}> => {
  return callFirebaseFunction<{}>(
    CF.user.resendInvitationMailAndUpdateExpirationDate,
    {
      invitationId
    }
  );
};

export const revokeInvitation = (invitationId: string): Promise<{}> => {
  return callFirebaseFunction<{}>(CF.user.revokeInvitation, {
    invitationId
  });
};

export const removeUser = (userId: string, spaceId: string): Promise<{}> => {
  return callFirebaseFunction<{}>(CF.user.removeUserFromSpace, {
    userId,
    spaceId
  });
};
