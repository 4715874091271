import { Dialog, DialogContent } from '@material-ui/core';
import React from 'react';
import { CheckoutForm, CHECKOUT_FORM_MIN_WIDTH } from '../CheckoutForm';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const CheckoutDialog: React.FC<Props> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" scroll="body">
      <DialogContent style={{ width: CHECKOUT_FORM_MIN_WIDTH }}>
        <CheckoutForm onSuccess={onClose} />
      </DialogContent>
    </Dialog>
  );
};
