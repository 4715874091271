import {
  MOMENT_TO_INTERVAL_LABEL,
  MOMENT_TO_INTERVAL_TICK
} from '../../../../services/time';
import { AnalyticsIntervalUnit } from '../../../../domainTypes/analytics_v2';
import moment from 'moment-timezone';

export const tickFormatter = (
  startsInCurrentYear: boolean,
  intervalUnit: AnalyticsIntervalUnit
) => (timestamp: string) => {
  const m = moment(timestamp);
  const year = startsInCurrentYear
    ? 'startsInCurrentYear'
    : 'startsInOtherYear';
  return MOMENT_TO_INTERVAL_TICK[intervalUnit][year](m);
};

export const timeLabelFormatter = (
  startsInCurrentYear: boolean,
  intervalUnit: AnalyticsIntervalUnit
) => (timestamp: string) => {
  const m = moment(timestamp);
  const year = startsInCurrentYear
    ? 'startsInCurrentYear'
    : 'startsInOtherYear';
  return MOMENT_TO_INTERVAL_LABEL[intervalUnit][year](m);
};
