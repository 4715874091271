import React from 'react';
import { Helmet } from 'react-helmet';
import { NoPermissions } from '../../../../components/NoPermissions';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { useExperimentalContext } from '../../../../services/experimental';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { PerformancePageBody } from '../../components/PerformancePageBody';
import { PagePerformanceNewTransactionsPg } from './index2';
import { PagePerformanceNewTransactionsPgAndClickhouse } from './index3';

export const PagePerformanceNewTransactions = () => {
  useTrackMixpanelView('view_transactions');
  const [canView] = useHasCurrentUserRequiredScopes([
    'reports.transactions.view'
  ]);
  const [experimental] = useExperimentalContext();
  const { space } = useCurrentUser();

  return (
    <>
      <Helmet>
        <title>Transactions | Affilimate</title>
      </Helmet>
      {canView ? (
        experimental || space.id === '9fSYGsMqM' ? (
          <PagePerformanceNewTransactionsPg />
        ) : (
          <PagePerformanceNewTransactionsPgAndClickhouse />
        )
      ) : (
        <PerformancePageBody>
          <NoPermissions />
        </PerformancePageBody>
      )}
    </>
  );
};
