import { IApiReportHandler } from '../types';
import { HANDLER_CONFIGS } from '../../../../../domainTypes/reporting';
import { getSecretValue } from '../../../../../services/secret';
import { truncate } from 'lodash';

export const IMPACT: IApiReportHandler = {
  type: 'API',
  partnerKey: 'impact',
  configName: 'impactV1',
  configFields: HANDLER_CONFIGS.impactV1,
  additionalName: (_, secret) => {
    if (!secret) {
      return null;
    }

    const accountSid = getSecretValue(secret.data, 'accountSid', '');

    return accountSid ? `${truncate(accountSid, { length: 10 })}` : null;
  }
};
