import { Device } from '../../../../domainTypes/tracking';
import { ISale } from '../../../../domainTypes/performance';

export const toCents = (m: string) => parseFloat(m) * 100;

export const toPercent = (part?: number, total?: number) => {
  return part && total ? part / total : 0;
};

export const addDevice = (
  sale: ISale,
  device: string | undefined | null,
  deviceMapper: { [key: string]: Device }
) => {
  if (device) {
    const d = deviceMapper[device];
    if (d) {
      sale.device = d;
    }
  }
};
