import { Button } from '@material-ui/core';
import { sum } from 'lodash';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import { AlertOctagon, Trash as IconTrash } from 'react-feather';
import { Link } from 'react-router-dom';
import { AlertBox } from '../../../../components/AlertBox';
import { EmptySearchState } from '../../../../components/EmptySearchState';
import {
  useColumnsQueryParam,
  useSortQueryParam
} from '../../../../components/GroupableList';
import { IconContainer } from '../../../../components/IconContainer';
import { SearchInput } from '../../../../components/SearchInput';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import { Doc } from '../../../../domainTypes/document';
import {
  INarrowProductWithCountsAndTrends,
  ProductIssueType
} from '../../../../domainTypes/product';
import { useDictionary } from '../../../../hooks/useDictionary';
import { useErrorLogger } from '../../../../hooks/useErrorLogger';
import { usePromise } from '../../../../hooks/usePromise';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarPagination,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import {
  queryParamToList,
  setToQueryParam,
  useNumberQueryParam,
  useQueryParam,
  useRoutes,
  useStringQueryParam
} from '../../../../routes';
import { useApiConfigs } from '../../../../services/amazonApiKeys';
import { useProductCountsInTimeframePg } from '../../../../services/analytics';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import {
  constructPartnerForKey,
  getKnownPartnerForKey
} from '../../../../services/partner';
import { pluralize } from '../../../../services/pluralize';
import { getPartnersWithProductCounts } from '../../../../services/products';
import { getSelectedItems } from '../../../../services/selection';
import { toMoment } from '../../../../services/time';
import { COLORS } from '../../../../domainTypes/colors';
import { FS } from '../../../../versions';
import {
  BROKEN_AMAZON_AVAILABILITIES,
  IssueSelector,
  useAmazonIssuesQueryParam
} from '../../components/IssueSelector';
import { PageBodyLinks } from '../../components/PageBodyLinks';
import { EmptyState } from './components/EmptyState';
import { ProductDeleteButton } from './components/ProductDeleteButton';
import {
  ColumnName,
  COLUMNS as COLUMNS_PG,
  ProductsTablePg,
  SORTERS as SORTERS_PG
} from './components/ProductsTablePg';
import { usePgProductsCacheContext } from '../../../../services/products/cache';

import { useHasComparison } from '../../../../hooks/timeframe';
import {
  TimeframePicker,
  useTimeframe
} from '../../../../components/analytics_v2/Timeframe';

const SelectionActionsPg = ({
  products,
  deselect
}: {
  products: Doc<INarrowProductWithCountsAndTrends>[];
  deselect?: () => void;
}) => {
  const productIds = products.map((p) => p.id);
  return (
    <ProductDeleteButton
      variant="contained"
      color="secondary"
      productIds={productIds}
      onSuccess={deselect}
    >
      <IconContainer>
        <IconTrash size={18} />
        <div>Delete {pluralize('link', products.length)}</div>
      </IconContainer>
    </ProductDeleteButton>
  );
};

export const useIssuesQueryParam = (param: string) => {
  return useQueryParam(
    param,
    (p) => (p ? new Set(queryParamToList<ProductIssueType>(p)) : null),
    (cs) => (cs?.size ? setToQueryParam(cs) : undefined)
  );
};

const PAGE_SIZE = 200;
const AMAZON_ISSUE_COLUMNS: ColumnName[] = [
  'selector',
  'rank',
  'partner',
  'name',
  'clicked',
  'clickRatio',
  'issues'
];

const PARTNERS = new Set(['amazon']);

export const AmazonIssuesReportContent = () => {
  const { space } = useCurrentUser();

  const spaceId = space.id;
  const { ROUTES } = useRoutes();
  const tf = useTimeframe();
  const showComparison = useHasComparison();
  const [search, setSearch] = useStringQueryParam('q');
  const sorters = SORTERS_PG['absolute-numbers'];
  const [[sorter, direction], setSort] = useSortQueryParam('sort', sorters);
  const defaultedSorter = sorter || sorters.clicked;
  const [canDeleteLinks] = useHasCurrentUserRequiredScopes(['links.delete']);
  const [apiConfigs] = useApiConfigs(space?.id);

  const [columns, setColumns] = useColumnsQueryParam(
    'columns',
    AMAZON_ISSUE_COLUMNS
  );
  const [amazonIssues, setAmazonIssues] = useAmazonIssuesQueryParam(
    'amazonIssues',
    BROKEN_AMAZON_AVAILABILITIES
  );

  const {
    dictionary: selected,
    merge: mergeSelected,
    replace: replaceSelection
  } = useDictionary<boolean>({});

  // This is for the filter
  const partners = PARTNERS;
  const [allPartners] = usePromise(async () => {
    const cs = await getPartnersWithProductCounts(spaceId);
    return cs.map((c) => {
      const knownPartner = getKnownPartnerForKey(c.partner_key);
      return {
        partner: knownPartner || constructPartnerForKey(c.partner_key),
        counts: { links: c.count }
      };
    });
  }, [spaceId]);

  const { totalPages } = useMemo(() => {
    if (!allPartners) {
      return { totalPages: null };
    }
    const count = sum(
      allPartners
        .filter((p) =>
          partners && partners.size > 0 ? partners.has(p.partner.key) : true
        )
        .map((p) => p.counts.links)
    );
    const pages = Math.ceil(count / PAGE_SIZE);
    return { totalPages: pages };
  }, [allPartners, partners]);

  const { version: pgProductsCacheVersion } = usePgProductsCacheContext();
  const [page, setPage] = useNumberQueryParam('p', 1);
  const [
    analytics,
    loadingAnalytics,
    errorAnalytics
  ] = useProductCountsInTimeframePg(
    {
      spaceId: space.id,
      groupBy: 'product_id',
      page,
      q: search,
      limit: PAGE_SIZE,
      partner_key: partners ? Array.from(partners) : undefined,
      issue_type: ['LINK_CHECK'],
      orderBy: {
        column: sorter?.key ? [sorter.key] : [sorters.clicked.key],
        dir: direction === 'asc' ? 'ASC' : 'DESC'
      }
    },
    tf,
    pgProductsCacheVersion
  );

  const data = useMemo(() => {
    if (!analytics) {
      return null;
    }

    const result: Doc<INarrowProductWithCountsAndTrends>[] = Object.keys(
      analytics
    ).map((pid) => {
      const row = analytics[pid];
      const p: Doc<INarrowProductWithCountsAndTrends> = {
        id: pid,
        collection: FS.products,
        data: {
          space_id: spaceId,
          id: pid,
          name: row.name,
          url: row.url,
          partner_key: row.partnerKey,
          created_at: row.createdAt,
          issues: row.issues,
          sales: row.earnings,
          epc: row.epc,
          ctr: row.ctr,
          counts: {
            ...analytics[pid]
          }
        }
      };
      return p;
    });

    return result;
  }, [analytics, spaceId]);

  const loading = loadingAnalytics;
  useErrorLogger(errorAnalytics);

  const selectedProducts: Doc<
    INarrowProductWithCountsAndTrends
  >[] = useMemo(() => getSelectedItems(data || [], selected, (d) => d.id), [
    data,
    selected
  ]);

  // Use this to show a message to new users about their product
  // analytics data
  const user = useCurrentUser();
  const isNewSpace =
    moment().diff(toMoment(user.space.createdAt), 'hours') < 48;
  const hasProducts = useMemo(
    () => analytics && !!Object.keys(analytics).length, // ignoring filters
    [analytics]
  );

  const showEmptyState = useMemo(
    () =>
      !loading &&
      data &&
      data.length === 0 &&
      !hasProducts &&
      apiConfigs &&
      apiConfigs.length > 0,
    [loading, data, hasProducts, apiConfigs]
  );

  const askForApiKeys = useMemo(() => apiConfigs && apiConfigs.length === 0, [
    apiConfigs
  ]);

  return (
    <PageBodyLinks noTopPadding>
      {!showEmptyState && !askForApiKeys && (
        <PageToolbar sticky offset={DEFAULT_OFFSET}>
          {selectedProducts.length && canDeleteLinks ? (
            <>
              <PageToolbarSection flex={1}>
                {pluralize('links', selectedProducts.length, true)} selected
              </PageToolbarSection>
              <PageToolbarSection flex={1} justifyContent="flex-end">
                <SelectionActionsPg
                  products={selectedProducts}
                  deselect={() => {
                    replaceSelection({});
                  }}
                />
              </PageToolbarSection>
            </>
          ) : (
            <>
              <PageToolbarSection flex={4}>
                <SearchInput
                  placeholder="Search by link name or (cloaked) URL"
                  value={search}
                  onChange={setSearch}
                  width={280}
                  size="small"
                />
                {false && (
                  <IssueSelector
                    value={amazonIssues}
                    onChange={setAmazonIssues}
                  />
                )}
              </PageToolbarSection>
              <PageToolbarSection
                flex={4}
                justifyContent="flex-end"
                spacing="wide"
              >
                <ColumnSelector
                  value={columns}
                  onChange={setColumns}
                  columns={COLUMNS_PG}
                />
                <PageToolbarPagination
                  page={page}
                  setPage={setPage}
                  pageCount={totalPages || 0}
                />
                <TimeframePicker />
              </PageToolbarSection>
            </>
          )}
        </PageToolbar>
      )}

      {isNewSpace && hasProducts && (
        <AlertBox variant="success" style={{ marginBottom: '36px' }}>
          Congratulations on importing your first links. We'll start collecting
          analytics for these links on your website.
          <br />
          <br />
          In the mean time, try{' '}
          <Link
            style={{ borderBottom: '1px solid black' }}
            to={ROUTES.performanceNew.transactions.url()}
          >
            setting up reporting
          </Link>{' '}
          with your affiliate networks and programs.
        </AlertBox>
      )}

      {askForApiKeys && (
        <EmptySearchState
          icon={AlertOctagon}
          title="Please provide your Amazon Product Advertising API keys"
          message={
            <>
              To check your Amazon links for being broken or out of stock, you
              need to provide your Amazon Product Advertising API keys first.
              <br />
              <br />
              <Link to={ROUTES.links.amazonSettings.url()}>
                <Button variant="contained" color="primary">
                  Update Amazon Settings
                </Button>
              </Link>
            </>
          }
          color={COLORS.blue.blue6}
          bgColor={COLORS.blue.blue2}
        />
      )}

      {showEmptyState && <EmptyState />}

      {(loading || (data && !!data.length)) && !askForApiKeys && (
        <>
          <ProductsTablePg
            products={data || []}
            loading={loading}
            sorter={defaultedSorter}
            sortDirection={direction}
            onSort={(k, dir) => setSort([sorters[k] || null, dir])}
            columns={columns}
            compare={showComparison}
            rowToHref={(d) =>
              ROUTES.links.details.overview.url(d.id, 'amazonIssues')
            }
            selected={selected}
            setSelected={mergeSelected}
            mode={'absolute-numbers'}
          />
        </>
      )}
    </PageBodyLinks>
  );
};
