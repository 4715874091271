import React from 'react';
import { Drawer, IconButton, Tooltip, Typography } from '@material-ui/core';
import * as moment from 'moment-timezone';
import { AlertTriangle, ExternalLink, X } from 'react-feather';
import {
  DrawerHeader,
  DrawerSection,
  List,
  SectionHeading
} from '../../../../components/DrawerHeader';
import { Currency, Number } from '../../../../components/Number';
import { PlatformWithColor } from '../../../../components/PlatformWithColor';
import {
  AdvertiserRate,
  CachedAdvertiser
} from '../../../../domainTypes/advertiser';
import { COLORS } from '../../../../domainTypes/colors';
import { styled } from '../../../../emotion';
import { getKnownPartnerForKey } from '../../../../services/partner';
import { toMoment } from '../../../../services/time';
import { PlatformNameAndIdWrapper } from '../PlatformNameAndIdWrapper';
import { AdvertiserStatus } from './AdvertiserStatus';
import { Doc } from '../../../../domainTypes/document';

const OutboundLink = styled('span')`
  color: ${(p) => p.theme.palette.primary.main};
`;

const AsinGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  color: ${(p) => p.theme.palette.text.secondary};
  grid-column-gap: ${(props) => props.theme.spacing(1)}px;
  grid-row-gap: ${(props) => props.theme.spacing(1)}px;
`;

const InnerWrapper = styled('div')`
  max-width: 400px;
`;

export const cookieDurationString = (seconds: number) => {
  const duration = moment.duration(seconds, 'seconds');

  if (duration.asSeconds() < 60) {
    return `${Math.floor(duration.asSeconds())}-second`;
  } else if (duration.asMinutes() < 60) {
    return `${Math.floor(duration.asMinutes())}-minute`;
  } else if (duration.asHours() < 25) {
    return `${Math.floor(duration.asHours())}-hour`;
  } else {
    return `${Math.floor(duration.asDays())}-day`;
  }
};

export const CookieDuration = ({ seconds }: { seconds: number }) => {
  const str = cookieDurationString(seconds);
  return (
    <Typography variant="body2" color="textSecondary" component="span">
      &nbsp;&mdash; {str} cookie
    </Typography>
  );
};

const AdvertiserRateText = ({ rate }: { rate: AdvertiserRate }) => {
  return (
    <div style={{ fontWeight: 'bold' }}>
      {rate.rateType === 'Percent' &&
        rate.actualRatePercent === null &&
        rate.baseRatePercent !== null && (
          <>
            <Typography variant="h6" component="div" gutterBottom>
              <Number n={rate.baseRatePercent / 100} format="percent" />{' '}
              <Typography
                variant="body2"
                component="span"
                gutterBottom
                color="textSecondary"
              >
                {rate.payoutType}
                <Tooltip
                  title="This network does not directly report custom rates. Refer to your effective rate in the Advertisers report."
                  placement="top"
                >
                  <span style={{ color: COLORS.gold.gold7 }}>
                    <AlertTriangle
                      size={14}
                      style={{
                        marginLeft: 4,
                        position: 'relative',
                        top: '2px'
                      }}
                    />
                  </span>
                </Tooltip>
              </Typography>
              {rate.cookieDurationSeconds && (
                <CookieDuration seconds={rate.cookieDurationSeconds} />
              )}
            </Typography>
          </>
        )}
      {rate.rateType === 'Percent' && rate.actualRatePercent !== null && (
        <Typography variant="h6" component="div" gutterBottom>
          <Number n={rate.actualRatePercent / 100} format="percent" />{' '}
          <Typography
            variant="body2"
            component="span"
            gutterBottom
            color="textSecondary"
          >
            {rate.payoutType}
          </Typography>
          {rate.cookieDurationSeconds && (
            <CookieDuration seconds={rate.cookieDurationSeconds} />
          )}
        </Typography>
      )}
      {rate.rateType === 'Flat' &&
        rate.actualRateFlat !== null &&
        rate.currency && (
          <Typography variant="h6" component="div" gutterBottom>
            <Currency cents={rate.actualRateFlat} currency={rate.currency} />{' '}
            <Typography
              variant="body2"
              component="span"
              gutterBottom
              color="textSecondary"
            >
              {rate.payoutType}
            </Typography>
            {rate.cookieDurationSeconds !== null && (
              <CookieDuration seconds={rate.cookieDurationSeconds} />
            )}
          </Typography>
        )}
      {rate.description && (
        <Typography
          variant="body2"
          component="div"
          color="textSecondary"
          style={{ marginBottom: 8 }}
        >
          {rate.description}
        </Typography>
      )}
      {rate.metadata?.publisherDomains && (
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
          style={{ marginBottom: 8 }}
        >
          <strong>Applies to:</strong>{' '}
          {rate.metadata.publisherDomains
            .map((d) => {
              try {
                const u = new URL(d);
                return u.hostname;
              } catch {
                return d;
              }
            })
            .join(',')}
        </Typography>
      )}
    </div>
  );
};

const DrawerInner = ({ advertiser }: { advertiser: Doc<CachedAdvertiser> }) => {
  const partner = getKnownPartnerForKey(advertiser.data.partnerKey);
  if (!partner) {
    return null;
  }

  return (
    <InnerWrapper>
      <DrawerSection>
        <SectionHeading>
          Advertiser&nbsp;&nbsp;&nbsp;&nbsp;
          <AdvertiserStatus advertiser={advertiser.data} />
        </SectionHeading>
        <PlatformNameAndIdWrapper variant="h4" gutterBottom>
          {advertiser.data.name}
        </PlatformNameAndIdWrapper>
        <PlatformWithColor partner={partner} />
      </DrawerSection>
      <DrawerSection>
        <SectionHeading>Rates</SectionHeading>
        {(advertiser.data.rates || []).map((rate, i) => (
          <AdvertiserRateText rate={rate} key={i} />
        ))}
        {!advertiser.data.rates || advertiser.data.rates.length === 0 ? (
          <Typography variant="body2" component="p">
            No rates were provided by the platform.
          </Typography>
        ) : null}
      </DrawerSection>
      <DrawerSection>
        <SectionHeading>
          Domains{' '}
          {advertiser.data.metadata?.allowsDeeplinking !== undefined
            ? advertiser.data.metadata.allowsDeeplinking
              ? '— Deeplinking allowed'
              : '— Deeplinking not allowed'
            : null}
        </SectionHeading>
        {(advertiser.data.domains || []).map((domain, i) => {
          return (
            <Typography key={i} variant="body2" component="div" gutterBottom>
              {domain}
            </Typography>
          );
        })}
      </DrawerSection>
      {advertiser.data.partnerKey === 'levanta' && (
        <DrawerSection>
          <SectionHeading>Available ASINs</SectionHeading>
          <AsinGrid>
            {advertiser.data.asins &&
              advertiser.data.asins.map((asin) => (
                <div key={asin}>
                  <a
                    href={`https://${advertiser.data.domains[0]}/dp/${asin}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {asin}{' '}
                    <OutboundLink>
                      <ExternalLink size={12} />
                    </OutboundLink>
                  </a>
                </div>
              ))}
          </AsinGrid>
        </DrawerSection>
      )}
      <DrawerSection>
        <SectionHeading>Details</SectionHeading>
        <List>
          <dt>Advertiser ID</dt>
          <dd>{advertiser.data.advertiserId}</dd>
          <dt>Last updated</dt>
          <dd>{toMoment(advertiser.data.lastUpdated).format('LLL')}</dd>
        </List>
      </DrawerSection>
    </InnerWrapper>
  );
};

export const RatesDrawer = ({
  selectedAdvertiser,
  setSelectedAdvertiser
}: {
  selectedAdvertiser: Doc<CachedAdvertiser> | null;
  setSelectedAdvertiser: (a: Doc<CachedAdvertiser> | null) => void;
}) => {
  const onClose = () => {
    setSelectedAdvertiser(null);
  };

  return (
    <Drawer anchor="right" onClose={onClose} open={selectedAdvertiser !== null}>
      <DrawerHeader>
        <Typography variant="body1" component="span">
          Advertiser campaign rates
        </Typography>
        <IconButton onClick={onClose}>
          <X size={16} />
        </IconButton>
      </DrawerHeader>
      {selectedAdvertiser && <DrawerInner advertiser={selectedAdvertiser} />}
    </Drawer>
  );
};
