import React from 'react';
import { CachedAdvertiser } from '../domainTypes/advertiser';
import { styled } from '../emotion';
import { Currency, Number } from './Number';

export const RateWrapper = styled('div')`
  color: #616161;
  background-color: #eee;
  font-size: 10px;
  line-height: 18px;
  margin-left: 10px;
  padding: 0 5px;
  border-radius: 5px;
  overflow: hidden;
  white-space: nowrap;
  height: 19px;
  max-width: 80px;
  text-overflow: ellipsis;
  font-family: Menlo, Consolas, Courier;
`;

const formatRateRange = (minRate: number, maxRate: number) => {
  if (minRate === maxRate) {
    return <Number n={minRate / 100} format="percent" />;
  }
  return (
    <>
      <Number n={minRate} />
      &ndash;
      <Number n={maxRate / 100} format="percent" />
    </>
  );
};

export const MiniRateSummary = ({
  advertiser
}: {
  advertiser: CachedAdvertiser;
}) => {
  const d = advertiser;
  if (d.maxRateFlat || d.maxRatePercent) {
    const showSlash = d.maxRateFlat && d.maxRatePercent;

    const [minRatePercent, maxRatePercent] = d.rates.reduce<[number, number]>(
      ([curMin, curMax], r) => {
        if (r.rateType === 'Percent' && r.actualRatePercent) {
          return [
            Math.min(curMin, r.actualRatePercent),
            Math.max(curMax, r.actualRatePercent)
          ];
        }
        return [curMin, curMax];
      },
      [Infinity, -Infinity]
    );

    return (
      <>
        {d.maxRatePercent !== null &&
          formatRateRange(minRatePercent, maxRatePercent)}
        {showSlash && ' / '}
        {d.maxRateFlat && d.maxRateCurrency !== null && (
          <Currency cents={d.maxRateFlat} currency={d.maxRateCurrency} />
        )}
      </>
    );
  }
  return null;
};
