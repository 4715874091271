import { useState } from 'react';

export type Dictionary<T> = { [key: string]: T };

export const useDictionary = <T>(initialDictionary?: Dictionary<T>) => {
  const [dict, setDict] = useState<Dictionary<T>>(initialDictionary || {});
  const get = (key: string) => dict[key];
  const set = (key: string, value: T) => setDict({ ...dict, [key]: value });
  const has = (key: string) => key in dict;
  const rm = (key: string) => {
    const next = { ...dict };
    delete next[key];
    setDict(next);
  };
  const merge = (otherDict: Dictionary<T>) =>
    setDict({ ...dict, ...otherDict });
  const replace = (otherDict: Dictionary<T>) => setDict(otherDict);
  return {
    dictionary: dict,
    get,
    set,
    has,
    rm,
    merge,
    replace
  };
};
