import { ILegendItem, Legend } from '../../../../../../components/Charts/Util';
import React, { useMemo } from 'react';
import { metricTitle } from '../../../../../../services/analyticsV2/metrics';
import { ReportMetric } from '../services/report-metric';

const activityMetrics = ['p', 'c', 'v'] as const;
const earningMetrics = ['commission_sum_net', 'epc_net', 'rpm_net'] as const;

interface FooterProps {
  metric: ReportMetric;
  setMetric: (metric: ReportMetric) => void;
}

export const ReferrersChartFooter: React.FC<FooterProps> = ({
  metric,
  setMetric
}) => {
  const activityLegend = useMemo<ILegendItem[]>(() => {
    return activityMetrics.map((m) => ({
      color: '#444',
      shape: 'circle',
      active: metric === m,
      onClick: () => setMetric(m),
      label: metricTitle(m)
    }));
  }, [metric, setMetric]);
  const earningsLegend = useMemo<ILegendItem[]>(() => {
    return earningMetrics.map((m) => ({
      color: '#444',
      shape: 'circle',
      active: metric === m,
      onClick: () => setMetric(m),
      label: metricTitle(m)
    }));
  }, [metric, setMetric]);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Legend items={activityLegend} />
      <Legend items={earningsLegend} />
    </div>
  );
};
