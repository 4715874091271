import Cookies from 'js-cookie';
import qS from 'query-string';
import { useEffect } from 'react';
import {
  AffiliateCookie,
  AFFILIATE_COOKIE_EXPIRATION_DAYS,
  AFFILIATE_COOKIE_NAME,
  AFFILIATE_ID_PARAM,
  TRACKING_ID_PARAM
} from '../../../../domainTypes/affiliate/cookie';
import * as ENV from '../../../../env.json';

const getSingleParamOrEmpty = (attr: any) =>
  typeof attr === 'string' && attr ? attr : '';
const getAffiliateParamsFromUrl = () => {
  const query = qS.parse(window.location.search);
  return {
    affiliateId: getSingleParamOrEmpty(query[AFFILIATE_ID_PARAM]),
    trackingId: getSingleParamOrEmpty(query[TRACKING_ID_PARAM])
  };
};

export const useAffiliateCookieObserver = () => {
  const { affiliateId, trackingId } = getAffiliateParamsFromUrl();
  const referrer = document.referrer || '';
  useEffect(() => {
    if (!affiliateId) {
      return;
    }
    // maybe check if it's already there and do something special?
    const v: AffiliateCookie = {
      [AFFILIATE_ID_PARAM]: affiliateId,
      [TRACKING_ID_PARAM]: trackingId,
      ts: Date.now(),
      ua: window.navigator.userAgent,
      ref: referrer,
      v: 1
    };
    Cookies.set(AFFILIATE_COOKIE_NAME, v, {
      expires: AFFILIATE_COOKIE_EXPIRATION_DAYS,
      domain: ENV.domains.cookies.public,
      sameSite: 'strict'
    });
  }, [affiliateId, trackingId, referrer]);
};

export const getAffiliateCookie = (): AffiliateCookie | null => {
  const c = Cookies.get(AFFILIATE_COOKIE_NAME);
  if (!c) {
    return null;
  }
  const value: AffiliateCookie = JSON.parse(c);
  // check version if need be
  return value;
};

export const toAffiliateUrl = (url: string, affiliateId: string) => {
  const u = new URL(url);
  u.searchParams.set(AFFILIATE_ID_PARAM, affiliateId);
  return u.toString();
};
