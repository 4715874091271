import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import React from 'react';
import { Form } from '../../../../../../components/Form';
import { FormField } from '../../../../../../components/Form/FormField';
import { FormSubmitButton } from '../../../../../../components/Form/FormSubmitButton';
import { css } from '../../../../../../emotion';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import { createProduct } from '../../../../../../services/products';
import * as tracking from '../../../../../../tracking';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const DialogCreate = ({ open, onClose }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const onSuccess = (name: string, url: string) => {
    enqueueSnackbar('Product created', { variant: 'success' });
    tracking.sendEvent({
      category: tracking.toAppCategory(),
      action: 'Add product',
      label: `${name} (${url})`
    });
    onClose();
  };
  const onError = (name: string, url: string) => {
    enqueueSnackbar('Failed to create product', { variant: 'error' });
    tracking.sendEvent({
      category: tracking.toAppCategory(),
      action: 'Add product (error)',
      label: `${name} (${url})`
    });
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth="lg">
      <DialogTitle>Add a new product</DialogTitle>
      <DialogContent>
        <Form
          initialValues={{ name: '', url: '' }}
          onSubmit={({ name, url }) =>
            createProduct(name, url).then(
              () => onSuccess(name, url),
              () => onError(name, url)
            )
          }
          className={css((t) => ({
            minWidth: 500,
            width: '100%'
          }))}
        >
          {({ submitting, values }) => (
            <>
              <FormField name="name" type="input">
                {({ input }) => (
                  <TextField
                    {...input}
                    required
                    label="Name"
                    margin="normal"
                    fullWidth={true}
                  />
                )}
              </FormField>

              <FormField name="url" type="input">
                {({ input }) => (
                  <TextField
                    {...input}
                    required
                    label="Affiliate Link URL"
                    margin="normal"
                    fullWidth={true}
                  />
                )}
              </FormField>
              <div
                className={css((t) => ({
                  marginTop: t.spacing() * 6,
                  display: 'flex',
                  justifyContent: 'center'
                }))}
              >
                <FormSubmitButton
                  disabled={!values.name || !values.url}
                  className={css((t) => ({ width: '100%' }))}
                  submitting={submitting}
                  submitComponent="Creating..."
                >
                  Create
                </FormSubmitButton>
              </div>
            </>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  );
};
