import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Edit, ExternalLink as IconOpenNewWindow } from 'react-feather';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import { PartnerLogo } from '../../../../components/PartnerLogo';
import { Doc } from '../../../../domainTypes/document';
import { IProduct } from '../../../../domainTypes/product';
import { styled } from '../../../../emotion';
import { useDialogState } from '../../../../hooks/useDialogState';
import { getCurrentPartnerForProduct } from '../../../../services/partner';
import { updateProductName } from '../../../../services/products';

const Container = styled('div')`
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing(1.5)}px;
`;

const Name = styled('div')`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 550px;
`;

const Actions = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const EditNameDialog = ({
  open,
  onClose,
  p
}: {
  open: boolean;
  onClose: () => void;
  p: Doc<IProduct>;
}) => {
  const originalName = p.data.name;
  const [name, setName] = useState(originalName);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Rename your link</DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          component="p"
          color="textSecondary"
          paragraph
        >
          Choose a name for your link that'll help you recognize it, such as the
          product or advertiser name.
        </Typography>
        <TextField
          required
          fullWidth
          value={name}
          onChange={(ev) => setName(ev.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <ButtonWithPromise
          variant="contained"
          color="primary"
          onClick={() =>
            updateProductName(p, name).then(
              () => {
                enqueueSnackbar('Name updated!', { variant: 'success' });
                onClose();
                setName(p.data.name);
              },
              () => {
                enqueueSnackbar('Name could not be updated.', {
                  variant: 'error'
                });
              }
            )
          }
          pending={'Updating...'}
          disabled={!name || name === originalName}
        >
          Update
        </ButtonWithPromise>
      </DialogActions>
    </Dialog>
  );
};

export const DetailsTitle = ({ p }: { p: Doc<IProduct> }) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);

  return (
    <>
      <Container>
        <PartnerLogo
          partner={getCurrentPartnerForProduct(p.data)}
          style={{ opacity: 1, display: 'flex' }}
        />
        <Name>{p.data.name}</Name>
        <Actions>
          <Button startIcon={<Edit size={14} />} onClick={openDialog}>
            Rename link
          </Button>
          <Button
            href={p.data.url}
            rel="noopener noreferrer"
            target="_blank"
            startIcon={<IconOpenNewWindow size={14} />}
          >
            Visit
          </Button>
        </Actions>
      </Container>
      <EditNameDialog open={dialogOpen} onClose={closeDialog} p={p} />
    </>
  );
};
