import { GetTagsForPagesParams } from '../../../../domainTypes/tags';
import { usePromise } from '../../../../hooks/usePromise';
import { callFirebaseFunction } from '../../../../services/firebaseFunctions';
import {
  getTagsCacheforSpace,
  useTagsCacheContext
} from '../../../../services/tags/cache';
import { CF } from '../../../../versions';

const getTagsPerPage = (spaceId: string) => {
  return callFirebaseFunction<
    {
      time: number;
      res: {
        url: string;
        tag_ids: string[];
      }[];
    } & GetTagsForPagesParams
  >(CF.tags.getTagsForPages, { spaceId });
};

// NOTE: it's used in app/src/services/pages/index.ts !
export const useTagsPerPage = (spaceId: string) => {
  const { version } = useTagsCacheContext();
  return usePromise(async () => {
    const cache = getTagsCacheforSpace(spaceId);
    if (cache.tagsPerPage) {
      return cache.tagsPerPage;
    }
    const tagsPerPage = await getTagsPerPage(spaceId).then(({ res }) => {
      return Object.fromEntries(res.map((x) => [x.url, x.tag_ids]));
    });
    cache.tagsPerPage = tagsPerPage;
    return tagsPerPage;
  }, [spaceId, version]);
};
