import { AnalyticsField } from './analytics_v2';

export const CLICK_CUSTOM_DIMENSION_NAMES = [
  'click_data_01',
  'click_data_02',
  'click_data_03',
  'click_data_04',
  'click_data_05',
  'click_data_06',
  'click_data_07',
  'click_data_08',
  'click_data_09',
  'click_data_10'
] as const;

export type ClickDimensionName = typeof CLICK_CUSTOM_DIMENSION_NAMES[number];

export const isClickDimension = (value: unknown): value is ClickDimensionName =>
  CLICK_CUSTOM_DIMENSION_NAMES.includes(value as ClickDimensionName);

export const PAGE_CUSTOM_DIMENSION_NAMES = [
  'page_data_01',
  'page_data_02',
  'page_data_03',
  'page_data_04',
  'page_data_05',
  'page_data_06',
  'page_data_07',
  'page_data_08',
  'page_data_09',
  'page_data_10',
  'page_data_11',
  'page_data_12',
  'page_data_13',
  'page_data_14',
  'page_data_15'
] as const;

export type PageDimensionName = typeof PAGE_CUSTOM_DIMENSION_NAMES[number];

export type CustomDimensionName = Extract<
  AnalyticsField,
  ClickDimensionName | PageDimensionName
>;

export interface LinkGeneratorOption {
  id: string;
  name: string;
}

export type LinkGeneratorSettings =
  | {
      type: 'disabled';
    }
  | {
      type: 'dictionary';
    }
  | {
      type: 'freeform';
    };

export interface ICustomDimensionDefinition {
  name: string;
  description: string;
  linkGeneratorSettings: LinkGeneratorSettings;
}

export interface ICustomDimensionsDoc {
  dimensions: Partial<Record<CustomDimensionName, ICustomDimensionDefinition>>;
  spaceId: string;
}

export interface ICustomDimensionDetailsDoc {
  spaceId: string;
  slot: CustomDimensionName;
  options: LinkGeneratorOption[];
}

export const slotTitle = (name: CustomDimensionName): string => {
  switch (name) {
    case 'click_data_01':
      return 'Click Data 01';
    case 'click_data_02':
      return 'Click Data 02';
    case 'click_data_03':
      return 'Click Data 03';
    case 'click_data_04':
      return 'Click Data 04';
    case 'click_data_05':
      return 'Click Data 05';
    case 'click_data_06':
      return 'Click Data 06';
    case 'click_data_07':
      return 'Click Data 07';
    case 'click_data_08':
      return 'Click Data 08';
    case 'click_data_09':
      return 'Click Data 09';
    case 'click_data_10':
      return 'Click Data 10';
    case 'page_data_01':
      return 'Page Data 01';
    case 'page_data_02':
      return 'Page Data 02';
    case 'page_data_03':
      return 'Page Data 03';
    case 'page_data_04':
      return 'Page Data 04';
    case 'page_data_05':
      return 'Page Data 05';
    case 'page_data_06':
      return 'Page Data 06';
    case 'page_data_07':
      return 'Page Data 07';
    case 'page_data_08':
      return 'Page Data 08';
    case 'page_data_09':
      return 'Page Data 09';
    case 'page_data_10':
      return 'Page Data 10';
    case 'page_data_11':
      return 'Page Data 11';
    case 'page_data_12':
      return 'Page Data 12';
    case 'page_data_13':
      return 'Page Data 13';
    case 'page_data_14':
      return 'Page Data 14';
    case 'page_data_15':
      return 'Page Data 15';
  }
};

export const slotName = (name: CustomDimensionName): string => {
  switch (name) {
    case 'click_data_01':
      return 'click data 01';
    case 'click_data_02':
      return 'click data 02';
    case 'click_data_03':
      return 'click data 03';
    case 'click_data_04':
      return 'click data 04';
    case 'click_data_05':
      return 'click data 05';
    case 'click_data_06':
      return 'click data 06';
    case 'click_data_07':
      return 'click data 07';
    case 'click_data_08':
      return 'click data 08';
    case 'click_data_09':
      return 'click data 09';
    case 'click_data_10':
      return 'click data 10';
    case 'page_data_01':
      return 'page data 01';
    case 'page_data_02':
      return 'page data 02';
    case 'page_data_03':
      return 'page data 03';
    case 'page_data_04':
      return 'page data 04';
    case 'page_data_05':
      return 'page data 05';
    case 'page_data_06':
      return 'page data 06';
    case 'page_data_07':
      return 'page data 07';
    case 'page_data_08':
      return 'page data 08';
    case 'page_data_09':
      return 'page data 09';
    case 'page_data_10':
      return 'page data 10';
    case 'page_data_11':
      return 'page data 11';
    case 'page_data_12':
      return 'page data 12';
    case 'page_data_13':
      return 'page data 13';
    case 'page_data_14':
      return 'page data 14';
    case 'page_data_15':
      return 'page data 15';
  }
};

export const slotType = (name: CustomDimensionName): 'click' | 'page' => {
  if (CLICK_CUSTOM_DIMENSION_NAMES.includes(name as ClickDimensionName)) {
    return 'click';
  }
  if (PAGE_CUSTOM_DIMENSION_NAMES.includes(name as PageDimensionName)) {
    return 'page';
  }
  throw new Error(`Unknown slot type for ${name}`);
};
