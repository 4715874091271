import {
  Button,
  Card,
  CardContent,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Loader } from '../../../../components/Loader';
import { Doc } from '../../../../domainTypes/document';
import {
  IProductScanResult,
  ProductScanType
} from '../../../../domainTypes/productScan';
import { styled } from '../../../../emotion';
import { CanvasBar } from '../../../../layout/Canvas';
import { Page } from '../../../../layout/Page';
import { Section } from '../../../../layout/Section';
import { useRoutes } from '../../../../routes';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../services/db';
import { createScan, useScans } from '../../../../services/scan';
import { getActiveDomainUrls } from '../../../../services/space';
import { formatDate, HUMAN_DATE, toMoment } from '../../../../services/time';
import * as tracking from '../../../../tracking';
import { ScanStatus } from '../../../Scan/components/ScanStatus';
import { ChevronRight } from 'react-feather';
import { Helmet } from 'react-helmet';
import { PageBodyLinks } from '../../components/PageBodyLinks';
import { HelpIcon } from '../../../../components/HelpIcon';
import { ARTICLES } from '../../../../services/beacon';
import { compact, orderBy } from 'lodash';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { NoPermissions } from '../../../../components/NoPermissions';
import { useFeatureEnabled } from '../../../../services/features';
import { AlertBox } from '../../../../components/AlertBox';

const LIMIT = 15;

const Form = styled('form')`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  margin-top: ${(p) => p.theme.spacing(4)}px;
`;

const LoadMoreContainer = styled('div')((p) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  marginTop: p.theme.spacing(2)
}));

const PastScans = () => {
  const { ROUTES } = useRoutes();
  const [limit, setLimit] = useState(LIMIT);

  // when loading more what's loaded in the past is briefly
  // void again, which causes the screen to flash.
  // Hold on to these in a ref.
  // The ref is only updated when a new state change happens,
  // so this approach is safe to have up-to-date renders.
  const results = useRef<{
    scans: Doc<IProductScanResult>[];
    hasMore: boolean;
  } | null>(null);

  const mapFn = useCallback(
    (scans: Doc<IProductScanResult>[]) => {
      results.current = {
        scans: scans.length > limit ? scans.slice(0, scans.length) : scans,
        hasMore: scans.length > limit
      };
      return null;
    },
    [limit]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, loading, error] = useMappedLoadingValue(
    useScans(limit + 1),
    mapFn,
    true
  );
  const docs = results.current?.scans || null;
  const hasMore = results.current?.hasMore || false;

  if (error) {
    return null;
  }

  const sortedDocs = docs
    ? docs.sort(
        (a, b) => b.data.createdAt.toMillis() - a.data.createdAt.toMillis()
      )
    : null;

  return (
    <Page>
      <Section>
        <CanvasBar>Past {limit} scans</CanvasBar>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '45%' }}>Domain or Page</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Date</TableCell>
                <TableCell align="left">Results</TableCell>
              </TableRow>
            </TableHead>
            {sortedDocs && (
              <TableBody>
                {sortedDocs.map((doc) => {
                  return (
                    <TableRow key={doc.id}>
                      <TableCell style={{ width: '45%' }}>
                        {doc.data.targets.map((t) => t.url).join(', ')}
                      </TableCell>
                      <TableCell>
                        <ScanStatus status={doc.data.status} />
                      </TableCell>
                      <TableCell>
                        {formatDate(toMoment(doc.data.createdAt), HUMAN_DATE)}
                      </TableCell>
                      <TableCell align="left">
                        <Link
                          to={ROUTES.links.scans.details.url(doc.id)}
                          onClick={() => {
                            tracking.sendEvent({
                              category: tracking.toAppCategory(),
                              action: 'Click',
                              label: 'View results'
                            });
                          }}
                        >
                          <Button>
                            View
                            <ChevronRight
                              style={{
                                marginLeft: '6px',
                                position: 'relative',
                                top: '1px'
                              }}
                              size={16}
                            />
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
          {loading && <Loader height="200px" />}
        </Paper>
        {hasMore && (
          <LoadMoreContainer>
            <Button
              variant="outlined"
              color="default"
              onClick={() => setLimit((x) => x + LIMIT)}
            >
              Load more
            </Button>
          </LoadMoreContainer>
        )}
      </Section>
    </Page>
  );
};

type ScanMode = ProductScanType | 'PAGE';

export const PageLinksScanHistory = () => {
  const currentUser = useCurrentUser();
  const { ROUTES, goTo } = useRoutes();
  const domains = getActiveDomainUrls(currentUser.space);
  const [scanTargets, setScanTargets] = useState(domains);
  const [scanMode, setScanMode] = useState<ScanMode>('PARTIAL');
  const [canRunScans] = useHasCurrentUserRequiredScopes(['scans.run']);
  const [canViewScans] = useHasCurrentUserRequiredScopes(['scans.view']);
  const hasScanlessTracking = useFeatureEnabled('SCANLESS_TRACKING');

  const sortedDomains = useMemo(() => {
    return orderBy(domains);
  }, [domains]);

  useTrackMixpanelView('view_scans');

  const onScan = (e: React.SyntheticEvent) => {
    e.preventDefault();

    tracking.sendEvent({
      category: tracking.toAppCategory(),
      action: 'Click',
      label: 'Scan website'
    });

    const mode = scanMode === 'PARTIAL' ? 'PARTIAL' : 'FULL';
    const scanType = scanMode === 'PAGE' ? 'PAGE' : 'DOMAIN';

    return createScan(scanTargets, scanType, mode)
      .then((doc) => {
        tracking.sendEvent({
          category: tracking.toAppCategory(),
          action: 'Scan',
          label: scanTargets.join(', ')
        });

        goTo(ROUTES.links.scans.details.url(doc.id));
      })
      .catch(() => {
        tracking.sendEvent({
          category: tracking.toAppCategory(),
          action: 'Scan (error)',
          label: scanTargets.join(', ')
        });
      });
  };

  return (
    <PageBodyLinks>
      <Helmet>
        <title>Site Scans | Affilimate</title>
      </Helmet>
      <Page width={800}>
        {canRunScans && hasScanlessTracking && (
          <AlertBox variant="pending">
            <strong>Your account is set to use scanless tracking.</strong> This
            means your links are tracked automatically without needing to run
            scans of your site or sitemap. Do not run scans or import links from
            scans or you may end up with duplicate links.
          </AlertBox>
        )}
        {canRunScans && (
          <Section>
            <Card>
              <CardContent>
                <Typography variant="body1" component="p" paragraph>
                  <strong>Import affiliate links for tracking</strong>
                  &nbsp; &nbsp;
                  <HelpIcon
                    color="blue"
                    articleId={ARTICLES.links.linkManagement}
                  >
                    How it works
                  </HelpIcon>
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  color="textSecondary"
                  paragraph
                >
                  Scan your website for affiliate links and import them for
                  tracking. Clear all caching plugins and CDN first.
                </Typography>
                <Form onSubmit={onScan}>
                  <div>
                    <TextField
                      value={scanMode}
                      onChange={(e) => {
                        setScanMode(e.target.value as ProductScanType);
                      }}
                      label="Scan mode"
                      select
                      id="scan-mode"
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem value="PARTIAL">Only changed</MenuItem>
                      <MenuItem value="FULL">All pages</MenuItem>
                      <MenuItem value="PAGE">Single page</MenuItem>
                    </TextField>
                  </div>
                  <div>
                    <TextField
                      value={scanTargets.join(', ')}
                      label="URL"
                      variant="outlined"
                      select={scanMode !== 'PAGE'}
                      fullWidth
                      onChange={(e) => {
                        setScanTargets(
                          compact((e.target.value as string).split(', '))
                        );
                      }}
                    >
                      {domains.length > 1 && (
                        <MenuItem value={domains.join(', ')}>
                          All sites
                        </MenuItem>
                      )}
                      {sortedDomains.map((d) => (
                        <MenuItem value={d} key={d}>
                          {d}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={scanTargets.length === 0}
                  >
                    Start scan
                  </Button>
                </Form>
              </CardContent>
            </Card>
          </Section>
        )}
        <Section>{canViewScans ? <PastScans /> : <NoPermissions />}</Section>
      </Page>
    </PageBodyLinks>
  );
};
