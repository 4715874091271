import React from 'react';
import { TableCellProps } from '@material-ui/core/TableCell';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { css } from '../../../../../emotion';
import { SlotBadge } from './SlotBadge';
import { AdditionalActionsMenu } from '../../../../../components/AdditionalActionsMenu';
import {
  CustomDimensionName,
  ICustomDimensionDefinition
} from '../../../../../domainTypes/customDimensions';

const RowCell: React.FC<TableCellProps> = ({ children, ...props }) => (
  <TableCell
    {...props}
    className={css(() => ({
      borderBottom: 'none'
    }))}
  >
    {children}
  </TableCell>
);

const HeaderCell: React.FC<TableCellProps> = ({ children, ...props }) => (
  <TableCell
    {...props}
    className={css((t) => ({
      borderBottom: 'none',
      fontSize: t.typography.caption.fontSize,
      color: t.palette.text.secondary
    }))}
  >
    {children}
  </TableCell>
);

interface CustomDimensionsTableProps {
  activeDimensions: CustomDimensionName[];
  dimensions: Partial<Record<CustomDimensionName, ICustomDimensionDefinition>>;
  editSlot: (slot: CustomDimensionName) => void;
  deleteSlot: (slot: CustomDimensionName) => void;
}

export const CustomDimensionsTable: React.FC<CustomDimensionsTableProps> = ({
  activeDimensions,
  dimensions,
  editSlot,
  deleteSlot
}) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <HeaderCell>Name</HeaderCell>
          <HeaderCell>Slot</HeaderCell>
          <HeaderCell>Description</HeaderCell>
          <HeaderCell align="right">Actions</HeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {activeDimensions.map((slot) => {
          const settings = dimensions[slot];
          return (
            <TableRow key={slot}>
              <RowCell>{settings?.name}</RowCell>
              <RowCell>
                <SlotBadge slot={slot} />
              </RowCell>
              <RowCell>{settings?.description}</RowCell>
              <RowCell align="right">
                <AdditionalActionsMenu
                  variant="horizontal"
                  options={[
                    {
                      key: 'edit',
                      label: 'Edit dimension...',
                      onClick: () => editSlot(slot)
                    },
                    {
                      key: 'delete',
                      label: 'Delete dimension...',
                      onClick: () => deleteSlot(slot)
                    }
                  ]}
                />
              </RowCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
