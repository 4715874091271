import { Paper, Tooltip } from '@material-ui/core';
import firebase from 'firebase/app';
import { groupBy, keyBy, mapValues, sortBy, uniq } from 'lodash';
import { useMemo } from 'react';
import { Info } from 'react-feather';
import { Loader } from '../../../../components/Loader';
import { Doc } from '../../../../domainTypes/document';
import { IPartner } from '../../../../domainTypes/partners';
import {
  emptyReportingStats,
  IReportingStats,
  SECRET_CATEGORY,
  toReportingStatsId
} from '../../../../domainTypes/reporting';
import { SalesApiFetchSchedule } from '../../../../domainTypes/schedule';
import { ISecret, ISecretWithTs } from '../../../../domainTypes/secret';
import { ISpace } from '../../../../domainTypes/space';
import { IAutoLabelConfig } from '../../../../domainTypes/tracking';
import { styled } from '../../../../emotion';
import {
  saveAutoLabelConfig,
  useAutoLabelConfig
} from '../../../../services/autoLabelConfig';
import { getKnownPartnerForKey } from '../../../../services/partner';
import { useSalesApiFetchScheduleForHandlers } from '../../../../services/schedules/salesApiFetch';
import { emptySecret, useSecrets } from '../../../../services/secret';
import { useReportingExtensionStatuses } from '../../../Settings/pages/BrowserExtension/service';
import { ReportHandler } from '../../services/handlers/types';
import { useReportingStats } from '../../services/reportingStats';
import { isArchivedIntegration } from './ArchiveIntegrationDialog';
import { HandlerListRow } from './HandlerListRow';

const HandlerGrid = styled('div')`
  display: grid;
  grid-template-columns: 4fr 110px max-content 2fr 110px 3fr max-content;
  align-items: stretch;
  justify-items: stretch;
  padding: 0 ${(p) => p.theme.spacing(1)}px;

  > * {
    padding: ${(p) => p.theme.spacing(1)}px;
  }
`;

const Cell = styled<'div', { head?: boolean }>('div')`
  ${(p) => (p.head ? `color: ${p.theme.palette.text.hint};` : '')}
  ${(p) =>
    p.head
      ? `font-size: ${p.theme.custom.fontSize.s}px;`
      : ''}
  min-height: 25px;
`;

const LAlign = styled(Cell)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CAlign = styled(Cell)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RAlign = styled(Cell)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const HeadWithInfo = styled('span')`
  svg {
    margin-left: 4px;
    position: relative;
    top: 2px;
  }
`;

const Heads = () => {
  return (
    <>
      <LAlign head>
        Platform
        <Tooltip
          placement="top"
          title="Platforms include affiliate networks, white-label software, and some indendent programs."
        >
          <HeadWithInfo>
            <Info size={13} />
          </HeadWithInfo>
        </Tooltip>
      </LAlign>
      <RAlign head>
        ID
        <Tooltip
          placement="top"
          title="Use this ID to match any connection to the corresponding connection in the Link Generator."
        >
          <HeadWithInfo>
            <Info size={13} />
          </HeadWithInfo>
        </Tooltip>
      </RAlign>
      <LAlign head>
        Type
        <Tooltip
          placement="top"
          title="API integrations pull automatically according to a schedule, whereas File Upload require you to upload reports."
        >
          <HeadWithInfo>
            <Info size={13} />
          </HeadWithInfo>
        </Tooltip>
      </LAlign>
      <LAlign head>
        Import schedule
        <Tooltip
          placement="top"
          title="When and how often to pull your affiliate transaction reports. Defaults to Daily at midnight."
        >
          <HeadWithInfo>
            <Info size={13} />
          </HeadWithInfo>
        </Tooltip>
      </LAlign>
      <CAlign head>
        Smart Label
        <Tooltip
          placement="top"
          title="Ask Affilimate to automatically label your affiliate links on your site with dynamic SubIDs for page and link-level revenue attribution."
        >
          <HeadWithInfo>
            <Info size={13} />
          </HeadWithInfo>
        </Tooltip>
      </CAlign>
      <LAlign head>
        ▼ Last Import
        <Tooltip
          placement="top"
          title="Date of your last successful import with this integration."
        >
          <HeadWithInfo>
            <Info size={13} />
          </HeadWithInfo>
        </Tooltip>
      </LAlign>
      <RAlign head>Actions</RAlign>
    </>
  );
};

export const changeAutoLabelConfig = (
  doc: Doc<IAutoLabelConfig>,
  partnerKey: string,
  enabled: boolean
) => {
  const nextItems = enabled
    ? uniq([...doc.data.partners, partnerKey])
    : doc.data.partners.filter((p) => p !== partnerKey);
  return saveAutoLabelConfig({
    ...doc,
    data: {
      ...doc.data,
      partners: nextItems
    }
  });
};

const useHandlerListProps = (
  spaceId: string,
  userId: string,
  handlers: ReportHandler[],
  archivedOnly?: boolean
) => {
  const [stats, loadingStats, errorStats] = useReportingStats(spaceId);
  const [secrets, loadingSecrets, errorSecrets] = useSecrets(
    spaceId,
    SECRET_CATEGORY
  );
  const [
    autoLabelConfig,
    loadingAutoLabelConfig,
    errorAutoLabelConfig
  ] = useAutoLabelConfig(spaceId);

  const [
    schedules,
    loadingSchedules,
    errorSchedules
  ] = useSalesApiFetchScheduleForHandlers(
    spaceId,
    handlers.reduce<string[]>((m, h) => {
      if (h.type === 'API') {
        m.push(h.configName);
      }
      return m;
    }, [])
  );

  const [
    extensionStatuses,
    loadingExtensionStatuses,
    errorExtensionStatuses
  ] = useReportingExtensionStatuses(spaceId, userId);

  const statsByIntegrationId = useMemo(
    () =>
      mapValues(
        keyBy(stats || [], (s) => s.id),
        (s) => s.data
      ),
    [stats]
  );

  const secretsByName = useMemo(() => {
    let secretsTemp = secrets;
    // filter out archived secrets if needed
    if (archivedOnly && secrets) {
      secretsTemp = secrets.filter((s: Doc<ISecret>) =>
        isArchivedIntegration(s.data.status)
      );
    }
    const returnValue = groupBy(secretsTemp || [], (s) => s.data.name);
    return returnValue;
  }, [secrets, archivedOnly]);

  const schedulesByHandlerName = useMemo(
    () => keyBy(schedules || [], (s) => s.data.config.handler),
    [schedules]
  );

  const extensionStatusByPartnerKey = useMemo(
    () =>
      mapValues(
        keyBy(extensionStatuses || [], (e) => e.data.partnerKey),
        (s) => s.data
      ),
    [extensionStatuses]
  );

  const error =
    errorStats ||
    errorSecrets ||
    errorAutoLabelConfig ||
    errorSchedules ||
    errorExtensionStatuses;

  if (error) {
    return [undefined, false, error] as [undefined, boolean, any];
  }

  const loading =
    loadingAutoLabelConfig ||
    loadingStats ||
    loadingSecrets ||
    loadingSchedules ||
    loadingExtensionStatuses;

  const data =
    stats && secrets && autoLabelConfig && schedules && extensionStatuses
      ? {
          statsByIntegrationId,
          secretsByName,
          autoLabelConfig,
          schedulesByHandlerName,
          extensionStatusByPartnerKey
        }
      : undefined;

  return [data, loading, null] as [typeof data, boolean, any];
};

type EnrichedHandler = {
  key: string;
  handler: ReportHandler;
  autoRunSchedule: Doc<SalesApiFetchSchedule> | null;
  smartLabels: boolean;
  onChangeSmartLabels: (enabled: boolean) => Promise<void>;
  partner: IPartner;
  secret: Doc<ISecretWithTs> | null;
  stats: IReportingStats;
  extensionActive: boolean;
};

export const HandlerListLoader = () => <Loader height={500} />;

export type HandlerListTypes = {
  space: ISpace;
  userId: string;
  handlers: ReportHandler[];
  ts: firebase.firestore.Timestamp;
  archivedOnly?: boolean;
};

export const HandlerList = ({
  space,
  userId,
  handlers,
  ts,
  archivedOnly
}: HandlerListTypes) => {
  const [p] = useHandlerListProps(space.id, userId, handlers, archivedOnly);
  if (!p) {
    return <HandlerListLoader />;
  }
  const mappedHandlers = handlers.reduce<EnrichedHandler[]>((memo, h) => {
    const partner = getKnownPartnerForKey(h.partnerKey);
    if (!partner) {
      return memo;
    }

    // API handlers can have multiple rows, represented through multiple secrets
    // Non-API handlers don't have a secret - we just pass a single null,
    // so that we are later still able to iterate over this list and render
    // one row per entry.
    const secrets: (Doc<ISecretWithTs> | null)[] =
      h.type === 'API'
        ? p.secretsByName[h.configName] || [
            emptySecret(
              space.id,
              userId,
              SECRET_CATEGORY,
              h.configName,
              h.configFields
            )
          ]
        : [null];

    const smartLabels =
      p.autoLabelConfig.data.partners.indexOf(partner.key) !== -1;

    const onChangeSmartLabels = (enabled: boolean) => {
      return changeAutoLabelConfig(p.autoLabelConfig, partner.key, enabled);
    };

    const autoRunSchedule =
      h.type === 'API' ? p.schedulesByHandlerName[h.configName] || null : null;

    const rows = secrets.map((secret, i) => {
      const integrationId =
        secret?.data.instanceId || toReportingStatsId(space.id, h.partnerKey);
      const stats =
        p.statsByIntegrationId[integrationId] ||
        emptyReportingStats(space.id, h.partnerKey);
      const extensionActive =
        !!p.extensionStatusByPartnerKey[h.partnerKey] &&
        !p.extensionStatusByPartnerKey[h.partnerKey].disabled;

      const key =
        h.type === 'API' ? `${h.configName}-${i}` : `${h.partnerKey}-${i}`;

      return {
        key,
        handler: h,
        autoRunSchedule,
        smartLabels,
        onChangeSmartLabels,
        secret,
        partner,
        stats,
        extensionActive
      };
    });

    return [...memo, ...rows];
  }, []);

  // additional reduce to deal with archive filter
  const archivedModelFilteredHandlers = mappedHandlers.reduce<
    EnrichedHandler[]
  >((memo, h) => {
    if (archivedOnly && isArchivedIntegration(h.secret?.data.status)) {
      return [...memo, h];
    }
    if (!archivedOnly && !isArchivedIntegration(h.secret?.data.status)) {
      return [...memo, h];
    }
    return memo;
  }, []);

  const sortedHandlers = sortBy(archivedModelFilteredHandlers, (h) =>
    h.stats.lastRun ? -h.stats.lastRun.ts : 0
  );

  if (sortedHandlers.length === 0) {
    const message = archivedOnly
      ? 'No archived integrations. Once you archive an integration, it will appear here.'
      : 'No integrations';
    return <Paper style={{ padding: 20 }}>{message}</Paper>;
  }
  return (
    <Paper>
      <HandlerGrid>
        <Heads />
        {sortedHandlers.map((h) => (
          <HandlerListRow
            key={h.key}
            h={h.handler}
            partner={h.partner}
            stats={h.stats}
            secret={h.secret}
            spaceId={space.id}
            smartLabels={h.smartLabels}
            onChangeSmartLabels={h.onChangeSmartLabels}
            autoRunSchedule={h.autoRunSchedule}
            extensionActive={h.extensionActive}
            archivedOnly={archivedOnly}
          />
        ))}
      </HandlerGrid>
    </Paper>
  );
};
