import { SortDirection, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { ConnectionId } from '../../../../components/ConnectionId';
import {
  ItemSorter,
  ItemSorters,
  RowsRenderer
} from '../../../../components/GroupableList';
import { PlatformWithColor } from '../../../../components/PlatformWithColor';
import { IColumn } from '../../../../components/Table/Column';
import { Dash } from '../../../../components/Table/CountCell';
import { CachedAdvertiser } from '../../../../domainTypes/advertiser';
import { Doc } from '../../../../domainTypes/document';
import { getKnownPartnerForKeyUnsafe } from '../../../../services/partner';
import { toMoment } from '../../../../services/time';
import { RatesDrawer } from './RatesDrawer';
import { TransactionType } from '../SalesList/TransactionType';
import { AdvertiserStatus } from './AdvertiserStatus';
import { PlatformNameAndIdWrapper } from '../PlatformNameAndIdWrapper';
import { MiniRateSummary } from '../../../../components/MiniRateSummary';

type ColumnName =
  | 'name'
  | 'id'
  | 'partnerKey'
  | 'status'
  | 'payoutType'
  | 'rateCount'
  | 'lastUpdated'
  | 'maxRatePercent';

const COLUMNS: IColumn<Doc<CachedAdvertiser>, ColumnName, undefined>[] = [
  {
    key: 'name',
    head: () => 'Advertiser name',
    cell: (d) => {
      return <>{d.data.name}</>;
    },
    sortable: true,
    align: 'left',
    width: 180
  },
  {
    key: 'partnerKey',
    head: () => 'Integration (ID)',
    headInfo: () =>
      "The platform and connection ID where this rate is available. You'll see multiple rows per advertiser if you've joined their program through multiple network accounts.",
    cell: (d) => {
      const partner = getKnownPartnerForKeyUnsafe(d.data.partnerKey);
      return (
        <PlatformNameAndIdWrapper>
          <Typography variant="body2">
            <PlatformWithColor partner={partner} />
          </Typography>
          <ConnectionId>{d.data.integrationId}</ConnectionId>
        </PlatformNameAndIdWrapper>
      );
    },
    align: 'left',
    width: 250
  },
  {
    key: 'maxRatePercent',
    head: () => 'Max rates',
    headInfo: () =>
      "The highest available rates for this advertiser's connection.",
    cell: (d) => {
      return d.data.maxRateFlat || d.data.maxRatePercent ? (
        <MiniRateSummary advertiser={d.data} />
      ) : (
        <Dash />
      );
    },
    sortable: true,
    align: 'left',
    width: 110
  },
  {
    key: 'status',
    head: () => 'Status',
    headInfo: () =>
      'The status of the advertiser. If the advertiser is not active, it may not be updated in the future.',
    cell: (d) => {
      return <AdvertiserStatus advertiser={d.data} />;
    },
    align: 'left',
    width: 85
  },
  {
    key: 'payoutType',
    head: () => 'Types',
    headInfo: () =>
      'Payout models available for this advertiser via this integration.',
    cell: (d) => {
      if (d.data.payoutTypes && d.data.payoutTypes.length > 0) {
        const saleTypes = d.data.payoutTypes.map((pt) => {
          switch (pt) {
            case 'CPA':
              return 'cpa';
            case 'CPL':
              return 'cpl';
            case 'CPC':
              return 'cpc';
            default:
              return 'unknown';
          }
        });
        return (
          <React.Fragment>
            {saleTypes.map((t) => (
              <TransactionType key={t} saleType={t} />
            ))}
          </React.Fragment>
        );
      }
      return <Dash />;
    },
    align: 'left',
    width: 120
  },
  {
    key: 'rateCount',
    head: () => 'Rates',
    headInfo: () =>
      'How many different rates are available for this advertiser.',
    cell: (d) => {
      if (!d.data.rates || d.data.rates.length === 0) {
        return <Dash />;
      }
      return <>{d.data.rates.length}x</>;
    },
    align: 'left',
    width: 70
  },
  {
    key: 'lastUpdated',
    head: () => 'Updated',
    sortable: true,
    headInfo: () =>
      'The last time the rates for this advertiser were updated. Different networks update on different cadences, depending on API limits. Most networks update daily.',
    cell: (d) => {
      return <>{toMoment(d.data.lastUpdated).format('L')}</>;
    },
    align: 'left',
    width: 95
  }
];

const rowToKey = (a: Doc<CachedAdvertiser>) => a.id;

export const SORTERS: ItemSorters<Doc<CachedAdvertiser>> = {
  lastUpdated: {
    key: 'lastUpdated',
    items: {
      sort: (d) =>
        d.data.lastUpdated === null ? 0 : d.data.lastUpdated.toMillis(),
      dir: 'desc'
    }
  },
  maxRatePercent: {
    key: 'maxRatePercent',
    items: {
      sort: (d) => d.data.maxRateFlat || d.data.maxRatePercent,
      dir: 'desc'
    }
  },
  name: {
    key: 'name',
    items: {
      sort: (d) => d.data.query,
      dir: 'asc'
    }
  }
};

export const RatesTable = ({
  advertisers,
  sorter,
  setSort,
  direction
}: {
  advertisers: Doc<CachedAdvertiser>[];
  sorter: ItemSorter<Doc<CachedAdvertiser>> | null;
  setSort: (
    nextValue: [ItemSorter<Doc<CachedAdvertiser>> | null, 'asc' | 'desc']
  ) => void;
  direction: SortDirection;
}) => {
  const [selectedAdvertiser, setSelectedAdvertiser] = useState<Doc<
    CachedAdvertiser
  > | null>(null);

  return (
    <div>
      <RowsRenderer
        variant="contained"
        rows={advertisers}
        otherProps={undefined}
        rowToKey={rowToKey}
        renderHead={true}
        columns={COLUMNS}
        sorter={sorter || SORTERS.name}
        sortDirection={direction || SORTERS.name.items.dir}
        onHeadClick={(c, dir) =>
          setSort([SORTERS[c.key] || null, dir || 'desc'])
        }
        onRowClick={(row) => {
          setSelectedAdvertiser(row);
        }}
      />
      <RatesDrawer
        selectedAdvertiser={selectedAdvertiser}
        setSelectedAdvertiser={setSelectedAdvertiser}
      />
    </div>
  );
};
