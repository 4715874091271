import { useMemo } from 'react';
import { AnalyticsFilterIn } from '../../../domainTypes/analytics_v2';
import {
  queryParamToList,
  setToQueryParam,
  useQueryParam
} from '../../../routes';
import { useCurrentUser } from '../../../services/currentUser';
import { getActiveDomainUrls } from '../../../services/space';
import {
  SiteSelector as BasicSiteSelector,
  UNATTRIBUTED
} from '../../SiteSelector';

const useSitesState = () => {
  const { space } = useCurrentUser();
  const allSites = useMemo(() => {
    return getActiveDomainUrls(space, true);
  }, [space]);
  const [sites, setSites] = useQueryParam(
    'site',
    (p) => new Set(p && p !== UNATTRIBUTED ? queryParamToList(p) : allSites),
    (ss) => (ss.size === allSites.length ? undefined : setToQueryParam(ss))
  );
  return {
    sites,
    setSites,
    allSites
  };
};

export const SiteSelector = () => {
  const { sites, setSites, allSites } = useSitesState();
  return (
    <BasicSiteSelector onChange={setSites} sites={allSites} value={sites} />
  );
};

export const useSites = (): AnalyticsFilterIn => {
  const { sites } = useSitesState();
  return useMemo(
    () => ({
      field: 'page_url_origin',
      condition: 'in',
      values: [...sites]
    }),
    [sites]
  );
};

export const useSitesComplex = (): {
  allSelected: boolean;
  filter: AnalyticsFilterIn;
} => {
  const { sites, allSites } = useSitesState();
  return useMemo(() => {
    return {
      allSelected: sites.size === allSites.length,
      filter: {
        field: 'page_url_origin',
        condition: 'in',
        values: [...sites]
      }
    };
  }, [sites, allSites]);
};
