import { useState } from 'react';

interface ILoadingValue<T> {
  loading: boolean;
  error?: any;
  value?: T;
}
export const useLoadingValue = <T>(initialValue?: T, initialLoading = true) => {
  const toInitialState = () => ({
    loading: initialLoading,
    error: undefined,
    value: initialValue
  });
  const [{ loading, error, value }, setState] = useState<ILoadingValue<T>>(
    toInitialState()
  );

  const setLoading = (nextLoading: boolean) =>
    setState({ loading: nextLoading, value, error });
  const setValue = (nextValue: T) =>
    setState({ loading: false, value: nextValue, error: undefined });
  const setError = (nextError: any) =>
    setState({ loading: false, value: undefined, error: nextError });
  const reset = () => setState(toInitialState());

  return { loading, error, value, setLoading, setValue, setError, reset };
};
