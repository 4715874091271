import { compact } from 'lodash';
import { useMemo } from 'react';
import { useSites } from '../../../../../../components/analytics_v2/SiteSelector';
import { useTimeframe } from '../../../../../../components/analytics_v2/Timeframe';
import {
  AnalyticsFilter,
  AnalyticsFilterLike,
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponse,
  CountUniqOfAnalyticsField
} from '../../../../../../domainTypes/analytics_v2';
import { Metric } from '../../../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../../../services/db';
import { UTM_PARAM_COLUMN_NAME } from '../components/UtmTable';
import { dbColumn, utmParameters, UtmParameterType } from './utm';

const notEmptyParam = (param: UtmParameterType): AnalyticsFilter => {
  return {
    field: dbColumn(param),
    condition: 'not in',
    values: ['']
  };
};

const normalizeOrderByClause = (
  orderBy: AnalyticsOrderBy,
  utmParam: UtmParameterType
): AnalyticsOrderBy => {
  if ((orderBy.field as unknown) === UTM_PARAM_COLUMN_NAME) {
    return {
      ...orderBy,
      field: dbColumn(utmParam)
    };
  }
  return orderBy;
};

const useUtmQuery = (
  utmParam: UtmParameterType,
  metrics: Metric[],
  search: AnalyticsFilterLike | null,
  orderBy: AnalyticsOrderBy,
  paginate?: AnalyticsQuery['paginate'],
  includePrevious = false
): AnalyticsQuery => {
  const sitesFilterClause = useSites();
  const { range, compare } = useTimeframe();
  return useMemo<AnalyticsQuery>(() => {
    return {
      groupBy: [dbColumn(utmParam)],
      select: metrics,
      range,
      compare: includePrevious ? compare : undefined,
      filters: compact([notEmptyParam(utmParam), sitesFilterClause, search]),
      paginate,
      orderBy: [normalizeOrderByClause(orderBy, utmParam)]
    };
  }, [
    utmParam,
    metrics,
    range,
    includePrevious,
    compare,
    sitesFilterClause,
    search,
    paginate,
    orderBy
  ]);
};

export const useUtmExportQuery = (
  utmParam: UtmParameterType,
  metrics: Metric[],
  search: AnalyticsFilterLike | null,
  orderBy: AnalyticsOrderBy
): AnalyticsQuery => {
  return useUtmQuery(utmParam, metrics, search, orderBy);
};

export const useUtmMetricsQuery = (
  utmParam: UtmParameterType,
  metrics: Metric[],
  search: AnalyticsFilterLike | null,
  orderBy: AnalyticsOrderBy,
  paginate: AnalyticsQuery['paginate']
) => {
  const { space } = useCurrentUser();
  const query = useUtmQuery(utmParam, metrics, search, orderBy, paginate, true);
  return useAnalyticsQueryV2(space.id, query);
};

export const useUtmCountQuery = (
  utmParam: UtmParameterType,
  search: AnalyticsFilterLike | null
) => {
  const { space } = useCurrentUser();
  const sitesFilterClause = useSites();
  const { range } = useTimeframe();
  const metric = paramToCountMetric(utmParam);
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: [metric],
      range,
      filters: compact([notEmptyParam(utmParam), sitesFilterClause, search])
    };
  }, [metric, range, utmParam, sitesFilterClause, search]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    // TODO: again, common selector
    (data) => data.rows[0]?.data[metric]?.curr || 0
  );
};

const paramToCountMetric = (param: UtmParameterType) =>
  // TODO: why typescript doesn't understand that?
  `count_uniq_${dbColumn(param)}` as CountUniqOfAnalyticsField;

export type Counts = Record<UtmParameterType, number>;

export const useAllCountsQuery = () => {
  const { space } = useCurrentUser();
  const sitesFilterClause = useSites();
  const { range } = useTimeframe();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: utmParameters.map(paramToCountMetric),
      range,
      filters: [sitesFilterClause]
    };
  }, [range, sitesFilterClause]);

  return useMappedLoadingValue<AnalyticsResponse, Counts, any>(
    useAnalyticsQueryV2(space.id, query),
    // TODO: again, common selector
    (data) => {
      const rowData = data.rows[0].data;
      return {
        campaign: rowData?.[paramToCountMetric('campaign')]?.curr ?? 0,
        medium: rowData?.[paramToCountMetric('medium')]?.curr ?? 0,
        content: rowData?.[paramToCountMetric('content')]?.curr ?? 0,
        term: rowData?.[paramToCountMetric('term')]?.curr ?? 0,
        source: rowData?.[paramToCountMetric('source')]?.curr ?? 0
      };
    }
  );
};

export const PAGE_SIZE = 10;
