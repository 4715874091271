import { styled } from '../../../../../emotion';

export const Container = styled('div')`
  text-align: center;
  width: 36px;
  height: 36px;
  background-color: #000;
  color: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SIZE = 18;
