import {
  Button,
  ButtonBase,
  Chip,
  Paper,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { BookOpen, Check } from 'react-feather';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Loader } from '../../../../../components/Loader';
import { NoPermissions } from '../../../../../components/NoPermissions';
import { SearchInput } from '../../../../../components/SearchInput';
import { styled } from '../../../../../emotion';
import { useDialogState } from '../../../../../hooks/useDialogState';
import { Centered } from '../../../../../layout/Centered';
import { LimitedWidth } from '../../../../../layout/PageBody';
import { Section } from '../../../../../layout/Section';
import {
  queryParamToList,
  setToQueryParam,
  useNumberQueryParam,
  useQueryParam,
  useRoutes,
  useStringQueryParam
} from '../../../../../routes';
import { useCurrentUserScopes } from '../../../../../services/currentUser';
import { useTrackMixpanelView } from '../../../../../services/mixpanel';
import { useTagsForCurrentUser } from '../../../../../services/tags';
import { COLORS } from '../../../../../domainTypes/colors';
import { ContentPageBody } from '../../../components/ContentPageBody';
import { CreateTagDialog } from '../../../components/CreateTagDialog';
import { AutoTaggingDialog } from './components/AutoTaggingDialog';
import { BatchImportDialog } from './components/BatchImportTags/BatchImportDialog';
import { TagList } from './components/TagList';
import { TagTemplateLibrary } from './components/TagTemplateLibrary';
import { VerticalSelector } from './components/VerticalSelector';
import { allVerticals } from './constants/templates';

import { useCurrentUser } from '../../../../../services/currentUser';

const PaperInnerWrapper = styled('div')`
  padding: ${(p) => p.theme.spacing(2)}px;
`;

const OuterWrapper = styled('div')`
  margin-top: ${(p) => p.theme.spacing(2)}px;
`;

const TemplateLibraryToolbar = styled('div')`
  display: flex;
  gap: ${(p) => p.theme.spacing(2)}px;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const ArrowSvg = () => (
  <svg
    width="75"
    height="57"
    viewBox="0 0 75 57"
    fill="none"
    style={{ position: 'absolute', top: '-30px', right: '-85px' }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_11327_7469)">
      <path
        d="M65.9346 11.6442C65.738 11.128 65.1603 10.8689 64.6442 11.0654L56.2332 14.2679C55.7171 14.4644 55.458 15.0421 55.6545 15.5582C55.851 16.0744 56.4287 16.3335 56.9448 16.137L64.4213 13.2904L67.2679 20.7668C67.4644 21.2829 68.0421 21.542 68.5582 21.3455C69.0744 21.149 69.3335 20.5713 69.137 20.0552L65.9346 11.6442ZM-0.158172 43.2251C7.97089 44.5273 20.9822 44.5521 33.7057 40.4082C46.4513 36.2571 58.9654 27.8988 65.9124 12.4092L64.0876 11.5908C57.4271 26.4414 45.4397 34.4832 33.0864 38.5065C20.711 42.5371 8.026 42.5106 0.158172 41.2503L-0.158172 43.2251Z"
        fill="#BDBDBD"
      />
    </g>
    <defs>
      <clipPath id="clip0_11327_7469">
        <rect width="75" height="57" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const TopBarRight = styled('div')`
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing(2)}px;
`;

const StyledDocsIcon = styled(Link)`
  position: relative;
  top: 4px;
  right: -2px;
  color: ${(p) => p.theme.palette.primary.main};
  display: inline-block;
  margin: ${(p) => p.theme.spacing(1)}px;
`;

const TagTopBar = () => {
  const { ROUTES } = useRoutes();
  const {
    dialogOpen: groupDialogOpen,
    setDialogOpen: setGroupDialogOpen
  } = useDialogState();
  const {
    dialogOpen: autoTaggingDialogOpen,
    setDialogOpen: setAutoTaggingDialogOpen
  } = useDialogState();

  const {
    dialogOpen: batchDialogOpen,
    closeDialog: batchDialogClose,
    openDialog: batchOpenDialog
  } = useDialogState();

  const { space } = useCurrentUser();

  const scopes = useCurrentUserScopes();
  const canCreateTags = useMemo(() => {
    return scopes.has('tags.create');
  }, [scopes]);

  return (
    <Section
      style={{
        marginBottom: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Typography
        variant="h6"
        component="span"
        style={{
          marginRight: '9px',
          position: 'relative',
          fontWeight: 'bold',
          top: '-2px'
        }}
      >
        Manage tags
        <StyledDocsIcon to={ROUTES.docs.knowledgeBase.url('tags')}>
          <BookOpen size={22} />
        </StyledDocsIcon>
      </Typography>
      <TopBarRight>
        {false && (
          <ButtonBase
            onClick={() => {
              setAutoTaggingDialogOpen(true);
            }}
          >
            <Check size={14} />
            &nbsp; Auto-apply tags with AI &nbsp;
            <Chip
              size="small"
              label="Beta"
              style={{
                color: COLORS.orange.orange9,
                backgroundColor: COLORS.orange.orange3,
                alignSelf: 'flex-start'
              }}
            />
          </ButtonBase>
        )}
        {canCreateTags && (
          <>
            <Button variant="text" onClick={batchOpenDialog}>
              Apply tags from file
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setGroupDialogOpen(true);
              }}
            >
              Create group
            </Button>
          </>
        )}
      </TopBarRight>
      <AutoTaggingDialog
        open={autoTaggingDialogOpen}
        onClose={() => {
          setAutoTaggingDialogOpen(false);
        }}
      />
      <CreateTagDialog
        open={groupDialogOpen}
        setDialogOpen={setGroupDialogOpen}
      />
      {batchDialogOpen && (
        <BatchImportDialog
          spaceId={space.id}
          open={batchDialogOpen}
          onClose={batchDialogClose}
        />
      )}
    </Section>
  );
};

const ManageTagsEmptyState = () => (
  <Centered height={300}>
    <div style={{ textAlign: 'center' }}>
      <Typography
        variant="body1"
        component="h2"
        style={{ fontWeight: 'bold', position: 'relative' }}
        paragraph
      >
        Segment your content with tags and groups <ArrowSvg />
      </Typography>
      <Typography
        variant="body2"
        component="p"
        paragraph
        style={{ maxWidth: '300px' }}
      >
        Report on performance of your content by tags and groups. To get
        started, click the button to create a group for your tags.
      </Typography>
    </div>
  </Centered>
);

const TagBody = () => {
  const [tags, loadingTags] = useTagsForCurrentUser();
  const [selectedTab, setSelectedTab] = useNumberQueryParam('tab', 0);
  const [search, setSearch] = useStringQueryParam('tagQ', '');
  const [verticals, setVerticals] = useQueryParam(
    'vertical',
    (p) => new Set(p ? queryParamToList(p) : allVerticals),
    (ss) => (ss.size === allVerticals.length ? undefined : setToQueryParam(ss))
  );

  const handleChangeTab = (_: React.ChangeEvent<{}>, newValue: number) =>
    setSelectedTab(newValue);

  if (loadingTags || !tags) {
    return (
      <ContentPageBody>
        <Centered height={300}>
          <Loader size={32} />
        </Centered>
      </ContentPageBody>
    );
  }

  return (
    <ContentPageBody>
      <LimitedWidth width={800}>
        <TagTopBar />
        <Tabs value={selectedTab} onChange={handleChangeTab}>
          <Tab label="Your tags" />
          <Tab label="Templates" />
        </Tabs>
        {selectedTab === 0 && (
          <OuterWrapper>
            <Paper>
              {tags.length ? (
                <PaperInnerWrapper>
                  <TagList tags={tags} />
                </PaperInnerWrapper>
              ) : (
                <ManageTagsEmptyState />
              )}
            </Paper>
          </OuterWrapper>
        )}
        {selectedTab === 1 && (
          <OuterWrapper>
            <Typography variant="body2" component="p" paragraph>
              Start your tagging strategy with a pre-made template. You’ll be
              able to add, edit, or remove tags from a group later.
            </Typography>
            <TemplateLibraryToolbar>
              <SearchInput
                value={search}
                onChange={setSearch}
                placeholder="Search by tag or group name"
                width={350}
              />
              <VerticalSelector
                value={verticals}
                verticals={allVerticals}
                onChange={setVerticals}
              />
            </TemplateLibraryToolbar>
            <Paper>
              <PaperInnerWrapper>
                <TagTemplateLibrary
                  tags={tags}
                  search={search}
                  selectedVerticals={verticals}
                />
              </PaperInnerWrapper>
            </Paper>
          </OuterWrapper>
        )}
      </LimitedWidth>
    </ContentPageBody>
  );
};

export const PageContentTagsManager = () => {
  useTrackMixpanelView('view_tag_manager');

  const scopes = useCurrentUserScopes();
  const canView = useMemo(() => {
    return (
      scopes.has('tags.view') &&
      (scopes.has('tags.create') ||
        scopes.has('tags.delete') ||
        scopes.has('tags.edit'))
    );
  }, [scopes]);

  return (
    <>
      <Helmet>
        <title>Tags | Affilimate</title>
      </Helmet>
      {canView ? (
        <TagBody />
      ) : (
        <ContentPageBody>
          <NoPermissions />
        </ContentPageBody>
      )}
    </>
  );
};
