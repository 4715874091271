import { compact } from 'lodash';
import moment from 'moment-timezone';
import { getTrackingId, ISale } from '../../../../../domainTypes/performance';
import { getKnownPartnerForKeyUnsafe } from '../../../../../services/partner';
import { fromMoment, now } from '../../../../../services/time';
import { addDevice, toCents } from '../helpers';
import { IFileReportHandler } from '../types';

export const HOTELS_COMBINED: IFileReportHandler<string> = {
  type: 'CSV',
  partnerKey: 'hotels_combined',
  status: 'deprecated',
  parser: {
    name: 'Transaction report',
    csvHeaders:
      '"LeadDate","LeadId","LeadValue","LanguageCode","DeviceCategory","ClientCountryCode","BrandID","Label","HotelID","HotelCountry","HotelCity","HotelName"',
    matches: (text, expectedHeaders) => text.indexOf(expectedHeaders) === 0,
    processRows: async (
      rows,
      { space, partnerKey, reportId, integrationId }
    ) => {
      return compact(
        rows.slice(1).map((row) => {
          const tz = space.config.tz || 'UTC';
          const dateFormat = 'MM/DD/YYYY HH:mm:ss';
          const [
            _saleDate,
            saleId,
            _commission,
            // eslint-disable-next-line
            __langCode,
            // eslint-disable-next-line
            __deviceCategory,
            // eslint-disable-next-line
            __customerCountry,
            // eslint-disable-next-line
            __brandId,
            trackingLabel,
            partnerProductId,
            // eslint-disable-next-line
            __hotelCountry,
            // eslint-disable-next-line
            __hotelCity,
            partnerProductName
          ] = row;

          const formattedSaleDate = _saleDate.replace(/[\n\r"]+/g, '');

          // HotelsCombined report contains blank rows in between each sale
          // so throw those out
          if (!formattedSaleDate || formattedSaleDate.length === 0) {
            return null;
          }

          const status = 'Final';
          const saleDate = moment.tz(formattedSaleDate, dateFormat, tz);
          const commission = toCents(_commission);
          const price = null;

          const sale: ISale = {
            saleId,
            trackingId: getTrackingId(trackingLabel || ''),
            trackingLabel,
            origin: null,
            reportId,
            integrationId,
            saleDate: fromMoment(saleDate),
            completionDate: null,
            status,
            partnerKey,
            partnerProductName,
            partnerProductId,
            payoutId: null,
            payoutDate: null,
            payoutStatus: null,
            lastModified: now(),
            coupon: '',
            saleType: 'unknown',
            amount: {
              // NOTE: Non-Australians will be paid in USD
              currency: 'AUD',
              price,
              revenue: null,
              commission
            },
            commissionPercent: null,
            quantity: 1,
            advertiserId: partnerKey,
            advertiserName: getKnownPartnerForKeyUnsafe(partnerKey).name
          };

          addDevice(sale, __deviceCategory, {
            0: 'desktop',
            1: 'mobile',
            2: 'tablet'
          });

          return sale;
        })
      );
    }
  }
};
