import React from 'react';
import { Doc } from '../../../../../domainTypes/document';
import { ReleaseNotification } from '../../../../../domainTypes/notifications';
import { useRoutes } from '../../../../../routes';
import { Body, Frame, Header } from '../../Item/Frame';

export const Release_LabelRules = ({ n }: { n: Doc<ReleaseNotification> }) => {
  const { ROUTES } = useRoutes();
  return (
    <Frame n={n} href={ROUTES.performanceNew.rules.url()}>
      <Header>Introducing label rules!</Header>
      <Body>
        Do you have sales that can't be or aren't using Smart Labels? Now you
        can use Label Rules to associate sales with the pages on your site, and
        comprehensively track Sales, RPMs, and Items Sold. Click this
        notification to create a new label rule!
      </Body>
    </Frame>
  );
};
