import { IPostgresTags } from '../../../../../domainTypes/tags';

export const toNestedTags = (tags: IPostgresTags[]) => {
  const roots = tags.filter((t) => t.parent_id === null);
  return roots.map((r) => {
    const children = tags.filter((t) => t.parent_id === r.id);
    return {
      ...r,
      children
    };
  });
};