import React from 'react';
import { PageComingSoon } from '../../../../components/ComingSoon';
import { PageBody } from '../../../../layout/PageBody';
export const PageRevisionsOverview = () => {
  return (
    <PageBody>
      <PageComingSoon />
    </PageBody>
  );
};
