import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export const useBoolean = (
  initialValue: boolean = false
): [boolean, () => void, () => void, Dispatch<SetStateAction<boolean>>] => {
  const [value, setValue] = useState(initialValue);
  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  return [value, setTrue, setFalse, setValue];
};
