import React from 'react';
import { Loader } from '../../../../components/Loader';
import { PageBody } from '../../../../layout/PageBody';
import { PageToolbar, PageToolbarTitle } from '../../../../layout/PageToolbar';
import { useRoutes } from '../../../../routes';
import { useCurrentUser } from '../../../../services/currentUser';
import { callFirebaseFunction } from '../../../../services/firebaseFunctions';
import { createProducts } from '../../../../services/products';
import { useScan } from '../../../../services/scan';
import { Timestamp } from '../../../../services/time';
import * as tracking from '../../../../tracking';
import { CF } from '../../../../versions';
import { ScanObserver } from './ScanObserver';
import { CreateProductArgs } from '../../../../domainTypes/product';

type Props = {
  scanId: string;
};

export const PageLinksScanDetails: React.FC<Props> = ({ scanId }) => {
  const { id: userId, space } = useCurrentUser();
  const { goTo, ROUTES } = useRoutes();
  const [doc, loading] = useScan(space.id, scanId);

  if (loading) {
    return <Loader height="100%" />;
  }

  if (!doc || !doc.data || !doc.data.status) {
    return null;
  }

  const onImport = (ps: CreateProductArgs[], ts: Timestamp) => {
    return createProducts(userId, space, ps, ts)
      .then(async () => {
        await callFirebaseFunction(CF.products.updateProductImportDate, {
          spaceId: space.id
        });
        tracking.sendEvent({
          category: tracking.toAppCategory(),
          action: 'Import',
          label: 'Products',
          value: ps.length
        });
        goTo(ROUTES.products.overview.url());
      })
      .catch(() => {
        tracking.sendEvent({
          category: tracking.toAppCategory(),
          action: 'Import (error)',
          label: 'Products',
          value: ps.length
        });
      });
  };

  return (
    <PageBody
      sideNav={{
        back: { path: ROUTES.links.scans.history.url() }
      }}
      noTopPadding
    >
      <PageToolbar>
        <PageToolbarTitle flex={1}>
          {doc.data.targets.map((t) => t.url).join(', ')}
        </PageToolbarTitle>
      </PageToolbar>
      <ScanObserver userId={userId} scan={doc} onImport={onImport} />
    </PageBody>
  );
};
