import { uniq } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IPostgresTags } from '../../domainTypes/tags';

type TagsCache = {
  tags: {
    [id: string]: IPostgresTags;
  } | null;
  tagsPerPage: {
    [protocollessUrl: string]: string[];
  } | null;
};

let CACHE: {
  [spaceId: string]: TagsCache;
} = {};

const createCache = (): TagsCache => ({
  tags: null,
  tagsPerPage: null
});

export const getTagsCacheforSpace = (spaceId: string) => {
  return (CACHE[spaceId] = CACHE[spaceId] || createCache());
};

const listeners: (() => void)[] = [];
const listenToCacheFlushes = (listener: () => void) => {
  listeners.push(listener);

  return () => {
    const index = listeners.indexOf(listener);
    if (index !== -1) {
      listeners.splice(index, 1);
    }
  };
};

export const flushTagsCacheForSpace = (spaceId: string) => {
  delete CACHE[spaceId];
  listeners.forEach((l) => l());
};

export const updateTagsForSpace = (
  spaceId: string,
  cacheChanges: IPostgresTags[]
) => {
  const cache = getTagsCacheforSpace(spaceId).tags;
  if (!cache) {
    console.log('Tags cache not prefilled yet');
    return;
  }
  cacheChanges.forEach((cacheChange) => {
    cache[cacheChange.id] = cacheChange;
  });
  listeners.forEach((l) => l());
};

export const setTagsPerPageCacheForSpace = (
  spaceId: string,
  tagsPerPage: {
    [url: string]: string[];
  }
) => {
  getTagsCacheforSpace(spaceId).tagsPerPage = tagsPerPage;
  listeners.forEach((l) => l());
};

export const updateTagsPerPageCacheForSpace = (
  spaceId: string,
  updates: {
    [url: string]: {
      added: string[];
      removed: string[];
    };
  }
) => {
  const cache = getTagsCacheforSpace(spaceId).tagsPerPage;
  if (!cache) {
    console.log('Tags per page cache not prefilled yet');
    return;
  }
  Object.entries(updates).forEach(([url, changes]) => {
    const prevEntry = cache[url];
    if (!prevEntry) {
      console.log(
        'Update tagsPerPage cache - entry not yet present',
        url,
        changes
      );
      cache[url] = changes.added;
    } else {
      const nextEntry = uniq([...prevEntry, ...changes.added]).filter(
        (x) => !changes.removed.includes(x)
      );
      cache[url] = nextEntry;
    }
  });
  listeners.forEach((l) => l());
};

export const deleteTagFromSpace = (spaceId: string, tagId: string) => {
  const cache = getTagsCacheforSpace(spaceId).tags;
  if (cache) {
    delete cache[tagId];
    listeners.forEach((l) => l());
  }
};

export const TagsCacheContext = React.createContext<{
  version: number;
  flush: (spaceId: string) => void;
}>({
  version: Date.now(),
  flush: () => null
});

export const TagsCacheProvider: React.FC = ({ children }) => {
  const [version, setVersion] = useState(Date.now());
  useEffect(() => listenToCacheFlushes(() => setVersion(Date.now())));
  const value = useMemo(() => ({ version, flush: flushTagsCacheForSpace }), [
    version
  ]);
  return (
    <TagsCacheContext.Provider value={value}>
      {children}
    </TagsCacheContext.Provider>
  );
};

export const useTagsCacheContext = () => {
  return useContext(TagsCacheContext);
};
