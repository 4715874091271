import { FormControlLabel, MenuItem, Select } from '@material-ui/core';
import { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import React from 'react';
import { Daily, Weekly } from '../../../domainTypes/schedule';
import { css } from '../../../emotion';
import { useBoolean } from '../../../hooks/useBoolean';
import { getTzAbbr } from '../../../services/time';
import { SelectHour } from '../../Time/SelectHour';
import { SelectWeekDay } from '../../Time/SelectWeekDay';

type Freq = Daily | Weekly;

type Props = {
  value: Freq;
  onChange: (nextValue: Daily | Weekly) => void;
  readOnlyType?: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
};

interface IOption {
  label: string;
  value: Freq['type'];
}

const OPTIONS: IOption[] = [
  {
    label: 'daily',
    value: 'DAILY'
  },
  {
    label: 'weekly',
    value: 'WEEKLY'
  }
];

const TypeEdit: React.FC<{
  value: Freq['type'];
  onChange: (nextType: Freq['type']) => void;
  readOnly: boolean;
  disabled?: boolean;
}> = ({ value, onChange, readOnly, disabled = false }) => {
  const [isOpen, open, close] = useBoolean(false);
  return (
    <Select
      open={isOpen}
      onOpen={open}
      onClose={close}
      value={value}
      disabled={disabled}
      variant="outlined"
      onChange={(ev) => onChange(ev.target.value as Freq['type'])}
      readOnly={readOnly}
    >
      {OPTIONS.map((o, i) => (
        <MenuItem key={i} value={o.value}>
          {o.label}
        </MenuItem>
      ))}
    </Select>
  );
};

const changeType = (value: Freq, onChange: (nextValue: Freq) => void) => (
  type: Freq['type']
) => {
  if (type === 'WEEKLY') {
    onChange({
      ...value,
      type,
      weekDay: (value as Weekly).weekDay || 1
    } as Weekly);
  } else {
    onChange({
      ...value,
      type
    } as Freq);
  }
};

const changeHour = (value: Freq, onChange: (nextValue: Freq) => void) => (
  hour: number
) => {
  onChange({
    ...value,
    hour
  } as Freq);
};

const changeWeekday = (value: Freq, onChange: (nextValue: Freq) => void) => (
  weekDay: number
) => {
  onChange({
    ...value,
    weekDay
  } as Weekly);
};

export const Label: React.FC<{
  label: string;
  control: FormControlLabelProps['control'];
}> = ({ label, control }) => (
  <FormControlLabel
    label={label}
    labelPlacement="start"
    control={control}
    classes={{
      root: css((t) => ({
        '&:first-of-type': {
          span: {
            marginLeft: '0 !important'
          }
        },
        span: {
          margin: `0 ${t.spacing()}px !important`,
          fontSize: '1rem !important'
        }
      }))
    }}
  />
);

export const FrequencyEdit: React.FC<Props> = ({
  value,
  onChange,
  readOnlyType = false,
  style = {},
  disabled = false
}) => {
  return (
    <div style={style}>
      <Label
        label="Run"
        control={
          <TypeEdit
            readOnly={readOnlyType}
            disabled={disabled}
            value={value.type}
            onChange={changeType(value, onChange)}
          />
        }
      />

      {value.type === 'WEEKLY' && (
        <Label
          label="on"
          control={
            <SelectWeekDay
              value={value.weekDay}
              disabled={disabled}
              onChange={changeWeekday(value, onChange)}
            />
          }
        />
      )}
      <Label
        label="at"
        control={
          <SelectHour
            value={value.hour}
            disabled={disabled}
            onChange={changeHour(value, onChange)}
          />
        }
      />
      <Label label={getTzAbbr(value.tz)} control={<div />} />
    </div>
  );
};
