import { Typography } from '@material-ui/core';
import { padStart } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { ISOString } from '../../domainTypes/etl';
import { ONE_MINUTE } from '../../services/time';

export const CountDown = ({
  targetDate,
  label = 'Next update',
  overdueLabel = 'New data incoming'
}: {
  targetDate: ISOString;
  label?: string;
  overdueLabel?: string;
}) => {
  const ms = useMemo(() => new Date(targetDate).valueOf(), [targetDate]);
  const [countDown, setCountDown] = useState(Date.now() - ms);
  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(Date.now() - ms);
    }, 1000);
    return () => clearInterval(interval);
  }, [ms]);
  const abs = Math.abs(countDown);
  // const hours = Math.floor((countDown % ONE_DAY) / (ONE_MINUTE * 60));
  const minutes = Math.floor((abs % (ONE_MINUTE * 60)) / ONE_MINUTE);
  const seconds = Math.floor((abs % ONE_MINUTE) / 1000);
  if (countDown < 0) {
    return (
      <Typography variant="caption">
        {label}:{' '}
        {[minutes, seconds]
          .map((x) => padStart(x.toString(), 2, '0'))
          .join(':')}
      </Typography>
    );
  }
  return <Typography variant="caption">{overdueLabel}</Typography>;
};
