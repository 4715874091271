import { Button } from '@material-ui/core';
import { css } from '../../../../emotion';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useFiltersState } from './filters-state';

export const ClearAll = () => {
  const { clearFilters, filters } = useFiltersState();
  if (filters.length === 0) return null;
  return (
    <Button
      onClick={clearFilters}
      className={css(() => ({
        marginLeft: 'auto'
      }))}
    >
      <Typography variant="caption">Clear all</Typography>
    </Button>
  );
};
