import React, { useCallback, useMemo, useState } from 'react';
import {
  CustomDimensionName,
  ICustomDimensionDetailsDoc,
  ICustomDimensionsDoc,
  LinkGeneratorOption,
  LinkGeneratorSettings,
  slotName
} from '../../../../../domainTypes/customDimensions';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { SlotSettingsControl } from './SlotDialog/SlotSettingsControl';
import { LinkGeneratorSettingsControl } from './SlotDialog/LinkGeneratorSettingsControl';
import { Doc } from '../../../../../domainTypes/document';
import {
  getCustomDimensionsDetailsStore,
  useCustomDimensionDetailsDoc
} from '../../../../../services/customDimensions';
import { useCurrentUser } from '../../../../../services/currentUser';
import { Loader } from '../../../../../components/Loader';
import { updateDoc } from '../../../../../services/db';
import { css } from '../../../../../emotion';
import { FlexContainer } from '../../../../../layout/Flex';
import { isEqual } from 'lodash';
import { useSnackbar } from '../../../../../hooks/useSnackbar';
import { EMPTY_ARR } from '../../../../../domainTypes/emptyConstants';

interface EditDialogBodyProps {
  customDimensionsDoc: Doc<ICustomDimensionsDoc>;
  detailsDoc?: Doc<ICustomDimensionDetailsDoc>;
  slot: CustomDimensionName;
  closeDialog: () => void;
  updateSlot: (
    name: string,
    description: string,
    linkGeneratorSettings: LinkGeneratorSettings,
    options: LinkGeneratorOption[]
  ) => Promise<void>;
}

const EditDialogBody: React.FC<EditDialogBodyProps> = ({
  customDimensionsDoc,
  detailsDoc,
  slot,
  closeDialog,
  updateSlot
}) => {
  const definition = customDimensionsDoc.data.dimensions[slot];

  const initialName = definition?.name ?? '';
  const initialDescription = definition?.description ?? '';
  const initialShowIn =
    definition?.linkGeneratorSettings?.type !== 'disabled' ?? false;
  const initialMode: 'dictionary' | 'freeform' =
    definition?.linkGeneratorSettings?.type === 'freeform'
      ? 'freeform'
      : 'dictionary';
  const initialOptions = useMemo(() => detailsDoc?.data.options ?? EMPTY_ARR, [
    detailsDoc?.data.options
  ]);

  const [name, setName] = useState(initialName);
  const [description, setDescription] = useState(initialDescription);
  const [showInLinkGenerator, setShowInLinkGenerator] = useState(initialShowIn);
  const [linkGeneratorMode, setLinkGeneratorMode] = useState<
    'dictionary' | 'freeform'
  >(initialMode);
  const [options, setOptions] = useState<LinkGeneratorOption[]>(initialOptions);

  const submit = useCallback(
    async (e) => {
      const createGeneratorSettings = (): LinkGeneratorSettings => {
        if (!showInLinkGenerator) return { type: 'disabled' };
        if (linkGeneratorMode === 'dictionary') {
          return { type: 'dictionary' };
        }
        return { type: 'freeform' };
      };
      e.preventDefault();
      closeDialog();
      await updateSlot(name, description, createGeneratorSettings(), options);
    },
    [
      closeDialog,
      description,
      linkGeneratorMode,
      name,
      options,
      showInLinkGenerator,
      updateSlot
    ]
  );

  const valid = useMemo(() => {
    return (
      name !== initialName ||
      description !== initialDescription ||
      showInLinkGenerator !== initialShowIn ||
      linkGeneratorMode !== initialMode ||
      !isEqual(options, initialOptions)
    );
  }, [
    description,
    initialDescription,
    initialMode,
    initialName,
    initialOptions,
    initialShowIn,
    linkGeneratorMode,
    name,
    options,
    showInLinkGenerator
  ]);

  return (
    <form onSubmit={submit}>
      <DialogContent
        className={css((t) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: t.spacing(3),
          minWidth: 600
        }))}
      >
        <SlotSettingsControl
          name={name}
          setName={setName}
          description={description}
          setDescription={setDescription}
        />
        <LinkGeneratorSettingsControl
          name={name}
          showInLinkGenerator={showInLinkGenerator}
          setShowInLinkGenerator={setShowInLinkGenerator}
          linkGeneratorMode={linkGeneratorMode}
          setLinkGeneratorMode={setLinkGeneratorMode}
          options={options}
          setOptions={setOptions}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button
          type="submit"
          disabled={!valid}
          color="primary"
          variant="contained"
          onClick={submit}
        >
          Save
        </Button>
      </DialogActions>
    </form>
  );
};

interface SlotEditDialogProps {
  customDimensionsDoc: Doc<ICustomDimensionsDoc>;
  slot: CustomDimensionName;
  closeDialog: () => void;
  open: boolean;
}

export const SlotEditDialog: React.FC<SlotEditDialogProps> = ({
  open,
  customDimensionsDoc,
  slot,
  closeDialog
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { space } = useCurrentUser();

  const [_detailsDoc, loading] = useCustomDimensionDetailsDoc(space.id, slot);
  // NOTE: Trick to get rid of the null and void, because they don't differ from undefined but pollute the type
  const detailsDoc = !_detailsDoc ? undefined : _detailsDoc;

  const updateSlot = useCallback(
    async (
      name: string,
      description: string,
      linkGeneratorSettings: LinkGeneratorSettings,
      options: LinkGeneratorOption[]
    ) => {
      try {
        const definition = {
          name,
          description,
          linkGeneratorSettings
        };
        await updateDoc(customDimensionsDoc, (data) => ({
          dimensions: {
            ...data.dimensions,
            [slot]: definition
          }
        }));
        const detailsStore = getCustomDimensionsDetailsStore(space.id, slot);
        // Set will result in an upsert. It is possible to have slot in custom dimensions collection but not in details collection
        await detailsStore.set(
          {
            options: linkGeneratorSettings.type === 'dictionary' ? options : [],
            spaceId: space.id,
            slot
          },
          { merge: true }
        );
      } catch (e) {
        enqueueSnackbar(`Failed to update "${name}" slot (${slotName(slot)})`, {
          variant: 'error'
        });
      }
    },
    [customDimensionsDoc, enqueueSnackbar, slot, space.id]
  );

  return (
    <Dialog open={open} onClose={closeDialog} scroll="body" maxWidth="md">
      <DialogTitle>Update custom dimension</DialogTitle>
      {loading ? (
        <FlexContainer
          alignItems="center"
          className={css(() => ({
            minHeight: 400,
            minWidth: 600
          }))}
        >
          <Loader size={32} />
        </FlexContainer>
      ) : (
        <EditDialogBody
          customDimensionsDoc={customDimensionsDoc}
          detailsDoc={detailsDoc}
          slot={slot}
          closeDialog={closeDialog}
          updateSlot={updateSlot}
        />
      )}
    </Dialog>
  );
};

export const useEditDialog = () => {
  const [state, setState] = useState<
    { open: true; slot: CustomDimensionName } | { open: false }
  >({ open: false });
  const openEditDialog = useCallback((slot: CustomDimensionName) => {
    setState({ open: true, slot });
  }, []);
  const closeEditDialog = useCallback(() => {
    setState({ open: false });
  }, []);
  return { editDialogState: state, openEditDialog, closeEditDialog };
};
