import { Chip } from '@material-ui/core';
import React, { useRef } from 'react';
import { Minus } from 'react-feather';
import { styled } from '../../emotion';
import { useHover } from '../../hooks/useHover';
import { useTheme } from '../../themes';
import { formatChartNumber } from '../Charts/Util';
import { Popover } from '../Popover';

const RankContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// colored, based on whether we moved up or down
const RankCurrent = styled('div')`
  font-weight: bold;
  color: ${(p) => p.theme.palette.text.secondary};
  font-size: ${(p) => p.theme.custom.fontSize.m}px;
`;

const RankPrevious = styled('div')`
  color: ${(p) => p.theme.palette.text.secondary};
  font-size: ${(p) => p.theme.custom.fontSize.s}px;
`;

export const Rank: React.FC<{ current: number; previous: number }> = ({
  current,
  previous
}) => {
  return (
    <RankContainer>
      <RankCurrent>{current}</RankCurrent>
      <RankPrevious>{previous}</RankPrevious>
    </RankContainer>
  );
};

const RankChipContainer = styled('div')`
  position: relative;
`;

const RankComparisonTable = styled('table')`
  border-collapse: collapse;
  margin: ${(p) => p.theme.spacing() / 2}px;
  width: calc(100% - ${(p) => p.theme.spacing()}px);

  td {
    padding: ${(p) => p.theme.spacing() / 2}px;
  }
`;

// Passing 0 as a previous value is practically similar to a happy disabled style of this component
// It will not show a diff, but just the current rank in a friendly green.
export const RankChip: React.FC<{ current: number; previous: number }> = ({
  current,
  previous
}) => {
  const anchor = useRef<HTMLDivElement | null>(null);
  const popoverOpen = useHover(anchor);
  const theme = useTheme();

  const diff = previous - current;
  const minWidth = 40;
  const fontWeight = 700;
  const chip = (() => {
    if (previous === 0) {
      return (
        <Chip
          style={{
            minWidth,
            fontWeight,
            backgroundColor: theme.palette.grey.A200,
            color: theme.palette.grey.A700
          }}
          label={current}
        />
      );
    }
    if (diff === 0) {
      return (
        <Chip
          style={{
            minWidth,
            fontWeight,
            backgroundColor: theme.palette.grey.A200,
            color: theme.palette.grey.A700
          }}
          label={
            <Minus size={16} style={{ position: 'relative', top: '3px' }} />
          }
        />
      );
    }
    if (diff > 0) {
      return (
        <Chip
          style={{
            minWidth,
            fontWeight,
            backgroundColor: theme.palette.grey[800],
            color: theme.palette.grey.A100
          }}
          label={`+${formatChartNumber(diff)}`}
        />
      );
    }
    if (diff < 0) {
      return (
        <Chip
          style={{
            minWidth,
            fontWeight,
            color: '#999',
            backgroundColor: '#EEE'
          }}
          label={diff}
        />
      );
    }

    // can never happen
    return null;
  })();

  return (
    <RankChipContainer ref={anchor}>
      {chip}
      {popoverOpen && (
        <Popover>
          <RankComparisonTable>
            <tbody>
              <tr>
                <td align="left">From</td>
                <td align="right">{previous}</td>
              </tr>
              <tr>
                <td align="left">To</td>
                <td align="right">{current}</td>
              </tr>
            </tbody>
          </RankComparisonTable>
        </Popover>
      )}
    </RankChipContainer>
  );
};
