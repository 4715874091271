import isPropValid from '@emotion/is-prop-valid';
import { Card } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../../../../../components/Number';
import { getTrend } from '../../../../../../domainTypes/analytics';
import { css, styled } from '../../../../../../emotion';
import { FlexContainer, FlexItem } from '../../../../../../layout/Flex';
import { useRoutes } from '../../../../../../routes';
import { LoadingValue } from '../../../../../../services/db';
import { COLORS, groupInfo } from '../services/groups';
import { useSpaceCurrency } from '../../../../../../services/useSpaceCurrency';
import { Trend } from '../../../../../../components/Trend';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from 'react-feather';
import { formatMetric } from '../../../../../../services/analyticsV2/metrics';
import {
  ReferrerGroupTotals,
  toReferrerGroupTitle
} from '../services/referrer-groups';
import { useReportMetric } from '../services/report-metric';
import { ReferrerGroup, groups } from '../../../../../../domainTypes/referrers';

const TotalCard = styled(Card, {
  shouldForwardProp: (props) => isPropValid(props)
})<{ isActive: boolean; color: string }>`
  height: 90px;
  outline: ${(p) => (p.isActive ? `1.5px solid ${p.color}` : 'none')};
`;

const TotalSkeleton = styled(Skeleton)`
  width: 60%;
  font-size: 1.5rem;
`;

interface TotalProps {
  data: LoadingValue<ReferrerGroupTotals>;
  group: ReferrerGroup;
  isActive: boolean;
}

const TotalBody: React.FC<{ group: ReferrerGroup; isActive: boolean }> = ({
  children,
  group,
  isActive
}) => {
  const { ROUTES } = useRoutes();
  const infoText = groupInfo(group);
  return (
    <FlexItem flex={1}>
      <Link
        to={
          isActive
            ? ROUTES.content.referrers.overview.url()
            : ROUTES.content.referrers.groupDetails.url(group)
        }
      >
        <TotalCard isActive={isActive} color={COLORS[group]}>
          <FlexContainer>
            <Typography variant="body1">
              {toReferrerGroupTitle(group)}
            </Typography>
            <Tooltip title={infoText} aria-label={infoText} placement="top">
              <Info
                size={14}
                className={css((t) => ({
                  color: t.palette.grey[500],
                  flexShrink: 0
                }))}
              />
            </Tooltip>
          </FlexContainer>
          <FlexContainer alignItems="baseline" spacing={0.5}>
            {children}
          </FlexContainer>
        </TotalCard>
      </Link>
    </FlexItem>
  );
};

const Total: React.FC<TotalProps> = ({ data, group, isActive }) => {
  const [metric] = useReportMetric();
  const currency = useSpaceCurrency();

  const [totals, loading, error] = data;
  if (error) {
    return (
      <TotalBody group={group} isActive={isActive}>
        <Typography variant="h5">-</Typography>
      </TotalBody>
    );
  }
  if (!totals || loading) {
    return (
      <TotalBody group={group} isActive={isActive}>
        <TotalSkeleton />
      </TotalBody>
    );
  }

  const groupTotals = totals[group];
  const curr = groupTotals?.curr ?? 0;
  const prev = groupTotals?.prev ?? 0;
  const currentString = formatMetric(curr, metric, currency);
  const percentString = formatNumber({
    n: getTrend(prev, curr),
    format: 'percent',
    digits: 1,
    plusMinus: true
  });
  return (
    <TotalBody group={group} isActive={isActive}>
      <Typography
        variant="h5"
        className={css((t) => ({
          marginRight: t.spacing(1.5),
          color: COLORS[group],
          filter: 'brightness(0.9) saturate(1.5)'
        }))}
      >
        {currentString}{' '}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {percentString}
      </Typography>
      <Trend values={[prev, curr]} />
    </TotalBody>
  );
};

interface TotalNumbersProps {
  totals: LoadingValue<ReferrerGroupTotals>;
  active?: ReferrerGroup;
}

export const TotalNumbers: React.FC<TotalNumbersProps> = ({
  active,
  totals
}) => (
  <FlexContainer spacing={2}>
    {groups.map((group) => (
      <Total
        key={group}
        data={totals}
        group={group}
        isActive={group === active}
      />
    ))}
  </FlexContainer>
);
