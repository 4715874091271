import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import { ButtonWithPromise } from '../../../components/ButtonWithPromise';
import { ConnectionId } from '../../../components/ConnectionId';
import { PlatformWithColor } from '../../../components/PlatformWithColor';
import { Job } from '../../../domainTypes/job';
import { SECRET_CATEGORY } from '../../../domainTypes/reporting';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useCurrentUser } from '../../../services/currentUser';
import { callFirebaseFunction } from '../../../services/firebaseFunctions';
import { getKnownPartnerForKey } from '../../../services/partner';
import { useSecrets } from '../../../services/secret';
import { MomentRange } from '../../../services/time';
import { CF } from '../../../versions';
import { API_REPORT_HANDLERS } from '../services/handlers';

export const TriggerJobDialog = ({
  open,
  onClose,
  jobType,
  availableHandlers,
  dialogTitle,
  dialogContent,
  showDates = false
}: {
  open: boolean;
  onClose: () => void;
  jobType: Job['type'];
  availableHandlers: string[];
  dialogTitle: React.ReactNode;
  dialogContent: React.ReactNode;
  showDates?: boolean;
}) => {
  const { space } = useCurrentUser();
  const [secrets, loadingSecrets] = useSecrets(space.id, SECRET_CATEGORY);
  const [selectedSecretId, setSelectedSecretId] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const options = useMemo(() => {
    return [
      {
        label: 'Last 12 months',
        range: {
          start: moment().subtract(12, 'months').startOf('day'),
          end: moment().subtract(1, 'day').endOf('day')
        }
      },
      {
        label: 'Last 24 months',
        range: {
          start: moment().subtract(12, 'months').startOf('day'),
          end: moment().subtract(1, 'day').endOf('day')
        }
      },
      {
        label: 'January 2022 – Today',
        range: {
          start: moment('2022-01-01', 'YYYY-MM-DD').startOf('day'),
          end: moment().subtract(1, 'day').endOf('day')
        }
      },
      {
        label: 'January 2021 – Today',
        range: {
          start: moment('2021-01-01', 'YYYY-MM-DD').startOf('day'),
          end: moment().subtract(1, 'day').endOf('day')
        }
      },
      {
        label: 'January 2020 – Today',
        range: {
          start: moment('2020-01-01', 'YYYY-MM-DD').startOf('day'),
          end: moment().subtract(1, 'day').endOf('day')
        }
      },
      {
        label: 'January 2019 – Today',
        range: {
          start: moment('2019-01-01', 'YYYY-MM-DD').startOf('day'),
          end: moment().subtract(1, 'day').endOf('day')
        }
      }
    ];
  }, []);
  const [importRange, setImportRange] = useState<{
    label: string;
    range: MomentRange;
  }>(options[0]);

  const scheduleImports = async () => {
    if (!secrets || !selectedSecretId) {
      return;
    }

    try {
      const selectedSecret = secrets.find((s) => s.id === selectedSecretId);

      if (!selectedSecret) {
        enqueueSnackbar(
          'Could not find the API integration for your selection.',
          { variant: 'error' }
        );
        return;
      }

      await callFirebaseFunction(CF.reporting.scheduleReportingJobs, {
        spaceId: space.id,
        instanceId: selectedSecret.data.instanceId,
        handler: selectedSecret.data.name,
        type: jobType,
        range: {
          start: importRange.range.start.format('YYYY-MM-DD'),
          end: importRange.range.end.format('YYYY-MM-DD')
        }
      });

      enqueueSnackbar('Imports scheduled! View progress under Activity.', {
        variant: 'success'
      });
    } catch (err) {
      enqueueSnackbar(
        'Imports could not be scheduled. Try again or contact support.',
        { variant: 'error' }
      );
      console.log(err);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} scroll="body">
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        {dialogContent}
        <br />
        {secrets && !loadingSecrets ? (
          <Select
            variant="outlined"
            value={selectedSecretId}
            onChange={(e) => setSelectedSecretId(e.target.value as string)}
            fullWidth
          >
            {secrets
              .filter(
                (s) =>
                  s.data.status === 'active' &&
                  availableHandlers.includes(s.data.name)
              )
              .map((s) => {
                const partnerKey = API_REPORT_HANDLERS.find(
                  (h) => h.configName === s.data.name
                );
                const partner = getKnownPartnerForKey(partnerKey!.partnerKey);

                return (
                  <MenuItem key={s.id} value={s.id}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '6px'
                        }}
                      >
                        <PlatformWithColor partner={partner!} />
                        <Typography variant="caption" color="textSecondary">
                          {s.data.nickname}
                        </Typography>
                      </span>
                      <ConnectionId>{s.data.instanceId}</ConnectionId>
                    </div>
                  </MenuItem>
                );
              })}
          </Select>
        ) : (
          <div>Loading...</div>
        )}
        <br />
        <br />
        {showDates && (
          <>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Select a date range to import:
            </Typography>
            <Select
              variant="outlined"
              value={options.indexOf(importRange)}
              onChange={(ev) =>
                setImportRange(options[Number(ev.target.value)])
              }
              fullWidth
            >
              {options.map((o, i) => {
                return (
                  <MenuItem value={i} key={o.label}>
                    {o.label}
                  </MenuItem>
                );
              })}
            </Select>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <ButtonWithPromise
          size="large"
          variant="contained"
          color="primary"
          fullWidth
          onClick={scheduleImports}
          disabled={!selectedSecretId}
          pending="Scheduling imports..."
        >
          Start importing
        </ButtonWithPromise>
      </DialogActions>
    </Dialog>
  );
};
