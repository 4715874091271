import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  Tooltip
} from '@material-ui/core';
import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import {
  CheckSquare as IconCheckSquare,
  Layers as IconLayers,
  Square as IconSquare
} from 'react-feather';
import { Timeframe } from '../../../../domainTypes/analytics';
import { IPageRevision } from '../../../../domainTypes/page';
import { Query } from '../../../../domainTypes/routes';
import { css, styled } from '../../../../emotion';
import { useRoutes } from '../../../../routes';
import { useCurrentUser } from '../../../../services/currentUser';
import {
  compareRevisions,
  getRevisionTimeframe
} from '../../../../services/page/revision';
import {
  formatSmartRelativeDate,
  toMoment,
  tsFromMillis
} from '../../../../services/time';
import { COLORS } from '../../../../domainTypes/colors';
import { FieldNames } from '../Selectors';

export type RevisionSelectorValue = {
  revision: IPageRevision | null;
  syncTimeframe: boolean;
  timeframe?: Timeframe;
};

type Props = {
  value: RevisionSelectorValue;
  onChange: (nextValue: RevisionSelectorValue) => void;
  options: IPageRevision[];
  tz: string;
};

const DataMismatch = styled('span')`
  background-color: ${COLORS.red.red5} !important;
  width: 14px;
  height: 14px;
  font-size: 12px;
  line-height: 13px;
  font-weight: 700;
  border-radius: 100% !important;
  color: white !important;
  display: inline-block;
  text-align: center;
`;

const Container = styled('div')`
  display: flex;
  align-items: flex-start;

  > :last-child {
    margin-left: ${(p) => p.theme.spacing() * 2}px;
  }
`;

export const toUrlState = (
  value: RevisionSelectorValue,
  fieldNames: FieldNames
) => ({
  [fieldNames.revision]: value.revision
    ? `${value.revision.lastModifiedAt.toMillis()}`
    : undefined,
  [fieldNames.syncTimeframe]: value.syncTimeframe ? 'true' : 'false'
});

export const useRevisionFromUrl = (
  revisions: IPageRevision[],
  fieldNames: FieldNames
): {
  value: RevisionSelectorValue;
  onChange: (nextValue: RevisionSelectorValue) => void;
  toQueryParams: (nextValue: RevisionSelectorValue) => Query;
  options: IPageRevision[];
} => {
  const { getQuery, changeQuery } = useRoutes();
  const query = getQuery();
  const revQuery = +((query[fieldNames.revision] as string) || '');
  const syncQuery = (query[fieldNames.syncTimeframe] as string) || '';
  const ts = tsFromMillis(revQuery);
  const sortedRevisions = [...revisions].sort(compareRevisions('desc'));
  const revision =
    sortedRevisions.find((r) => r.lastModifiedAt.isEqual(ts)) ||
    sortedRevisions.find((r) => !r.ignore) ||
    null;

  return {
    value: {
      revision,
      syncTimeframe: syncQuery === 'true'
    },
    onChange: (v) => changeQuery(toUrlState(v, fieldNames)),
    toQueryParams: (v) => toUrlState(v, fieldNames),
    options: sortedRevisions
  };
};

export const RevisionSelector: React.FC<Props> = ({
  value,
  onChange,
  options,
  tz
}) => {
  const [open, setOpen] = useState(false);
  const { revision, syncTimeframe } = value;

  const changeRevision = (nextRevision: IPageRevision) =>
    onChange({ ...value, revision: nextRevision });
  const changeSync = (nextSync: boolean) =>
    onChange({ ...value, syncTimeframe: nextSync });

  return (
    <Container>
      <Tooltip
        title="Select which revision of your page for which you'd like to see data."
        placement="top"
      >
        <span>
          <IconLayers />
        </span>
      </Tooltip>
      <FormControl>
        <Select
          autoWidth={true}
          open={open}
          onOpen={() => setOpen(true)}
          variant="outlined"
          onClose={() => setOpen(false)}
          value={revision ? options.indexOf(revision) : null}
          onChange={(ev) => changeRevision(options[Number(ev.target.value)])}
          classes={{
            select: css((t) => ({
              fontSize: t.custom.fontSize.m,
              paddingTop: 0,
              paddingBottom: t.spacing() * 0.75
            }))
          }}
        >
          {options.map((_, i) =>
            options[i].ignore ? null : (
              <MenuItem key={i} value={i}>
                v{options.length - i} ·{' '}
                {options[i].provider === 'user'
                  ? 'Created manually'
                  : 'Published'}{' '}
                {formatSmartRelativeDate(
                  toMoment(options[i].lastModifiedAt).tz(tz),
                  'MMM D, YYYY'
                )}
              </MenuItem>
            )
          )}
        </Select>
        <FormHelperText margin="dense">
          <FormControlLabel
            classes={{
              label: css((t) => ({
                '&&': {
                  fontSize: 'inherit',
                  color: syncTimeframe
                    ? t.custom.colors.primary.main
                    : 'inherit'
                }
              }))
            }}
            control={
              <Checkbox
                color="primary"
                classes={{
                  root: css(() => ({
                    '&&': { paddingTop: 0, paddingBottom: 0, paddingRight: 4 }
                  }))
                }}
                icon={<IconSquare size="1rem" />}
                checkedIcon={<IconCheckSquare size="1rem" />}
                checked={syncTimeframe}
                onChange={(ev) => changeSync(ev.target.checked)}
              />
            }
            label="Align dates with a single revision"
          />
        </FormHelperText>
      </FormControl>
    </Container>
  );
};

export const RevisionSelectorDense: React.FC<Props> = ({
  value,
  onChange,
  options,
  tz
}) => {
  const [open, setOpen] = useState(false);
  const { revision, syncTimeframe } = value;

  const changeRevision = (nextRevision: IPageRevision) =>
    onChange({ ...value, revision: nextRevision });

  const changeSync = (nextSync: boolean) =>
    onChange({ ...value, syncTimeframe: nextSync });

  const { space } = useCurrentUser();
  const hasMismatch = useMemo(() => {
    if (
      !value.revision ||
      !options.length ||
      syncTimeframe ||
      !value.timeframe
    ) {
      return false;
    }
    const revisionTimeframe = getRevisionTimeframe(
      space,
      value.revision,
      options
    );
    const revisionStart = moment(revisionTimeframe.start);
    const revisionEnd = moment(revisionTimeframe.end);

    const timeframeStart = moment(value.timeframe.start);
    const timeframeEnd = moment(value.timeframe.end);

    if (
      (options.length > 1 && !timeframeStart.isSameOrAfter(revisionStart)) ||
      !timeframeEnd.isSameOrBefore(revisionEnd)
    ) {
      return 'A revision was published during the timeframe you are viewing. Check the box to "Align dates with a single revision", and ensure only data from a specific revision is shown, especially if viewing heatmaps.';
    }
  }, [space, value, options, syncTimeframe]);

  return (
    <Container>
      <FormControl>
        <Select
          autoWidth={true}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          variant="outlined"
          value={revision ? options.indexOf(revision) : null}
          onChange={(ev) => changeRevision(options[Number(ev.target.value)])}
          classes={{
            select: css((t) => ({
              fontSize: t.custom.fontSize.m,
              padding: `${t.spacing(1)}px ${t.spacing(2)}px`
            }))
          }}
        >
          {options.map((_, i) =>
            options[i].ignore ? null : (
              <MenuItem key={i} value={i}>
                v{options.length - i} ·{' '}
                {options[i].provider === 'user'
                  ? 'Created manually'
                  : 'Published'}{' '}
                {formatSmartRelativeDate(
                  toMoment(options[i].lastModifiedAt).tz(tz),
                  'MMM D, YYYY'
                )}
              </MenuItem>
            )
          )}
        </Select>
        <FormHelperText margin="dense">
          <FormControlLabel
            classes={{
              root: css((t) => ({
                '&&': {
                  position: 'relative',
                  left: '4px'
                }
              })),
              label: css((t) => ({
                '&&': {
                  fontSize: t.custom.fontSize.s,
                  color: syncTimeframe
                    ? t.custom.colors.primary.main
                    : 'inherit'
                }
              }))
            }}
            control={
              <Checkbox
                color="primary"
                classes={{
                  root: css(() => ({
                    '&&': { paddingTop: 0, paddingBottom: 0, paddingRight: 4 }
                  }))
                }}
                icon={<IconSquare size="1rem" />}
                checkedIcon={<IconCheckSquare size="1rem" />}
                checked={syncTimeframe}
                onChange={(ev) => changeSync(ev.target.checked)}
              />
            }
            label={
              <span>
                Align dates with a single revision&nbsp;
                {hasMismatch && (
                  <Tooltip title={hasMismatch} placement="bottom">
                    <DataMismatch>!</DataMismatch>
                  </Tooltip>
                )}
              </span>
            }
          />
        </FormHelperText>
      </FormControl>
    </Container>
  );
};
