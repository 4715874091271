import { Card, Typography } from '@material-ui/core';
import { compact } from 'lodash';
import React, { useMemo } from 'react';
import {
  ColumnDefinitions,
  TableMetadata,
  useTable
} from '../../../../components/analytics_v2/Table';
import {
  TimeframePickerWithRevisions,
  useTimeframeWithRevisions
} from '../../../../components/analytics_v2/Timeframe/revisions';
import { ExportQueryButton } from '../../../../components/ExportQuery';
import { RowsRenderer } from '../../../../components/GroupableList';
import { GroupSelector } from '../../../../components/GroupSelector';
import { Loader } from '../../../../components/Loader';
import { NoPermissions } from '../../../../components/NoPermissions';
import { SingleSelectorOption } from '../../../../components/SingleSelector';
import { ColumnSelector } from '../../../../components/Table/ColumnSelector';
import {
  AnalyticsField,
  AnalyticsFilter,
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison,
  ISOTimeRange
} from '../../../../domainTypes/analytics_v2';
import { useHasComparison } from '../../../../hooks/timeframe';
import { useErrorLogger } from '../../../../hooks/useErrorLogger';
import { Centered } from '../../../../layout/Centered';
import { PageBody } from '../../../../layout/PageBody';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarOtherRow,
  PageToolbarSection,
  PageToolbarTitle
} from '../../../../layout/PageToolbar';
import { useRoutes, useTypedStringQueryParam } from '../../../../routes';
import { Metric } from '../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../services/currentUser';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { usePage } from '../../../../services/page';
import { ProductLinkCellWithPartnerLazy } from '../../../Links/pages/Overview/components/ProductLinkCell';
import { DetailsPageTitle } from '../../components/DetailsPageTitle';
import { useSideNavProps } from '../../services/detailsSideNav';
import {
  FILTER_STATE_LOCAL_STORAGE_KEYS,
  FiltersDrawer,
  FiltersIcon,
  useFilterDrawerState
} from '../../../../components/analytics_v2/Filters/Drawer';
import { useFilterClauses } from '../../../../components/analytics_v2/Filters/hooks';
import { useContentPagesFilterDimensions } from '../../services/filters';
import { LazyRenderWrapper } from '../../../../components/LazyRenderWrapper';

const CUSTOM_COLUMNS = ['link'] as const;
const HARD_LIMIT = 500;

type CustomColumns = typeof CUSTOM_COLUMNS[number];
type Column = CustomColumns | Metric;

const AVAILABLE_COLUMNS: Column[] = [
  ...CUSTOM_COLUMNS,
  'count_uniq_link_occ',
  's',
  'v',
  'view_ratio',
  'c',
  'ctr',
  'epc_net',
  'quantity_net',
  'commission_sum_net',
  'gmv_sum_net',
  'avg_rate_net'
];
const DEFAULT_VISIBLE_COLUMNS: Column[] = [
  ...CUSTOM_COLUMNS,
  'count_uniq_link_occ',
  'view_ratio',
  'c',
  'ctr',
  'epc_net',
  'commission_sum_net',
  'gmv_sum_net',
  'avg_rate_net'
];

const columnDefinitions: ColumnDefinitions<CustomColumns> = {
  link: {
    column: {
      key: 'link',
      head: () => 'Link URL',
      headInfo: () =>
        `Combined metrics for all instances of this product link on the page. The number (like 3x) indicates how many times this link appears in the content.`,
      cell: (p: AnalyticsResponseRowWithComparison, o: TableMetadata) => {
        return (
          <LazyRenderWrapper height={40}>
            <ProductLinkCellWithPartnerLazy
              spaceId={o.spaceId}
              productId={p.group.link_id}
            />
          </LazyRenderWrapper>
        );
      },
      align: 'left',
      sortable: false,
      width: 400,
      flexGrow: 4
    },
    sorter: {
      key: 'link',
      items: {
        sort: (p: AnalyticsResponseRowWithComparison) => p.group.link_id,
        dir: 'asc'
      }
    }
  }
};

const getGroupByColumnsForGroupType = (
  g: GroupType,
  includeUrl: boolean
): AnalyticsField[] => {
  return compact(
    g === 'link'
      ? ['link_id', includeUrl && 'link_url']
      : ['link_id', includeUrl && 'link_url', 'link_occ']
  );
};

const useLinksMetrics = (
  range: ISOTimeRange,
  compare: AnalyticsQuery['compare'],
  filters: AnalyticsFilter[],
  metrics: Metric[],
  orderBy: AnalyticsOrderBy,
  paginate: AnalyticsQuery['paginate'],
  groupType: GroupType
) => {
  const { space } = useCurrentUser();

  const query = useMemo<AnalyticsQuery>(
    () => ({
      range,
      compare,
      select: metrics,
      orderBy: [orderBy],
      paginate,
      groupBy: getGroupByColumnsForGroupType(groupType, false),
      filters
    }),
    [paginate, compare, filters, metrics, orderBy, range, groupType]
  );

  return useAnalyticsQueryV2(space.id, query, { logMode: 'full' });
};

const useLinksExportQuery = (
  range: ISOTimeRange,
  compare: AnalyticsQuery['compare'],
  filters: AnalyticsFilter[],
  metrics: Metric[],
  orderBy: AnalyticsOrderBy,
  groupType: GroupType
): AnalyticsQuery => {
  return useMemo(
    () => ({
      range,
      compare,
      select: metrics,
      orderBy: [orderBy],
      groupBy: getGroupByColumnsForGroupType(groupType, true),
      filters
    }),
    [compare, filters, metrics, orderBy, range, groupType]
  );
};

const headProps = { sticky: true, offset: DEFAULT_OFFSET };
const rowToKey = (d: AnalyticsResponseRowWithComparison) => d.group.link_id;

type GroupType = 'link' | 'occ';
const GROUPS: SingleSelectorOption<GroupType>[] = [
  {
    value: 'link',
    label: 'Link',
    searchValue: 'Link'
  },
  {
    value: 'occ',
    label: 'No group',
    searchValue: 'No group'
  }
];

export const PageContentDetailsLinksBody: React.FC<{ url: string }> = ({
  url
}) => {
  const { ROUTES } = useRoutes();
  const sideNav = useSideNavProps(url);

  const filterDimensions = useContentPagesFilterDimensions();
  const { isOpen, toggle } = useFilterDrawerState(false, {
    localStorageKey: FILTER_STATE_LOCAL_STORAGE_KEYS.content
  });

  const [group, setGroup] = useTypedStringQueryParam<GroupType>(
    'group',
    'link'
  );

  const baseFilters = useMemo<AnalyticsFilter[]>(
    () => [
      { field: 'page_url', condition: 'in', values: [url] },
      {
        condition: 'not in',
        field: 'link_id',
        values: ['']
      }
    ],
    [url]
  );
  const filterClauses = useFilterClauses();
  const allFilters = useMemo<AnalyticsFilter[]>(() => {
    return [...baseFilters, ...filterClauses];
  }, [baseFilters, filterClauses]);

  const showComparison = useHasComparison();

  const { availableColumns, defaultVisibleColumns } = useMemo(() => {
    return {
      availableColumns:
        group === 'link'
          ? AVAILABLE_COLUMNS
          : AVAILABLE_COLUMNS.filter((a) => a !== 'count_uniq_link_occ'),
      defaultVisibleColumns:
        group === 'link'
          ? DEFAULT_VISIBLE_COLUMNS
          : DEFAULT_VISIBLE_COLUMNS.filter((a) => a !== 'count_uniq_link_occ')
    };
  }, [group]);

  const {
    tableProps,
    columnSelectorProps,
    metrics,
    orderBy,
    pagination
  } = useTable(availableColumns, columnDefinitions, {
    pageSize: HARD_LIMIT,
    defaultSortColumn: 'c',
    defaultVisibleColumns,
    showComparison
  });

  const { space } = useCurrentUser();
  const [pageMetadata] = usePage(space.id, url);
  const {
    ranges: { range, compare },
    pickerProps
  } = useTimeframeWithRevisions(
    pageMetadata ? pageMetadata.data.revisions : []
  );

  const exportQuery = useLinksExportQuery(
    range,
    compare,
    allFilters,
    metrics,
    orderBy,
    group
  );

  const [data, loading, error] = useLinksMetrics(
    range,
    compare,
    allFilters,
    metrics,
    orderBy,
    pagination,
    group
  );

  useErrorLogger(error);

  return (
    <PageBody sideNav={sideNav} noTopPadding>
      <PageToolbar sticky offset={DEFAULT_OFFSET} wrap>
        <PageToolbarTitle flex={2}>
          <DetailsPageTitle url={url} />
          <PageToolbarSection flex={1}>
            <GroupSelector value={group} onChange={setGroup} options={GROUPS} />
            <ColumnSelector {...columnSelectorProps} />
            <FiltersIcon isOpen={isOpen} toggle={toggle} />
            <ExportQueryButton
              title="Export links to CSV"
              reportType="links"
              query={exportQuery}
            />
          </PageToolbarSection>
        </PageToolbarTitle>
        <PageToolbarSection flex={2} justifyContent="flex-end">
          <TimeframePickerWithRevisions {...pickerProps} />
        </PageToolbarSection>
        <PageToolbarOtherRow>
          <FiltersDrawer
            isOpen={isOpen}
            marginTop={2}
            availableDimensions={filterDimensions}
            baseFilters={baseFilters}
          />
        </PageToolbarOtherRow>
      </PageToolbar>
      {loading || !data ? (
        <Card>
          <Centered height={350}>
            <Loader size={32} />
          </Centered>
        </Card>
      ) : (
        <>
          <RowsRenderer
            {...tableProps}
            renderHead
            headProps={headProps}
            rows={data.rows}
            rowToKey={rowToKey}
            rowToHref={(d) => ROUTES.links.overview_v2.url({}, d.group.link_id)}
          />
          {data.rows.length === HARD_LIMIT && (
            <Centered style={{ marginTop: 16 }}>
              <Typography variant="body2" color="textSecondary">
                Showing the first {HARD_LIMIT} rows. Please refine your query to
                see more.
              </Typography>
            </Centered>
          )}
        </>
      )}
    </PageBody>
  );
};

export const PageContentDetailsLinks = ({ url }: { url: string }) => {
  const scopes = useCurrentUserScopes();
  const canViewContentReports = scopes.has('reports.content.view');

  useTrackMixpanelView('view_content_details_links', { url });

  if (!canViewContentReports) {
    return <NoPermissions />;
  }

  return <PageContentDetailsLinksBody url={url} />;
};
