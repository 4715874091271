import React from 'react';
import { FormSpy, FormSpyRenderProps } from 'react-final-form';
import { Interceptor } from '../../Interceptor';

const DirtyInterceptor: React.FC<FormSpyRenderProps> = ({ dirty }) => (
  <Interceptor if={dirty} />
);

export const FormInterceptor = () => (
  <FormSpy subscription={{ dirty: true }} component={DirtyInterceptor} />
);
