import { memoize } from 'lodash';
import React from 'react';
import { getStableRandomColor } from '../services/color';
import { WithShape } from './Charts/Util';

export const getSiteColor = memoize((origin: string) => {
  return getStableRandomColor(origin);
});

export const SiteWithColor = ({ origin }: { origin: string }) => {
  return (
    <WithShape large color={getStableRandomColor(origin)}>
      {origin}
    </WithShape>
  );
};
