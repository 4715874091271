import { useCurrentUser } from '../../../../services/currentUser';
import {
  UserAvatarGroup,
  UserAvatarWithNameNextToIt
} from '../../../../components/UserAvatars';
import React from 'react';
import { useUserInSpaceById } from '../../../../services/user';

export const Managers = ({ userIds }: { userIds: string[] }) => {
  const { space } = useCurrentUser();
  return <UserAvatarGroup spaceId={space.id} userIds={userIds} max={3} />;
};

export const Manager = ({
  userId,
  size
}: {
  userId: string;
  size?: number;
}) => {
  const { space } = useCurrentUser();
  const [user] = useUserInSpaceById(space.id, userId);
  return <UserAvatarWithNameNextToIt user={user || null} size={size} />;
};
