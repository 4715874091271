import { Bar, BarProps } from 'recharts';
import { isNil } from 'lodash';
import { Metric } from '../../../services/analyticsV2/metrics';
import { getStableRandomColor } from '../../../services/color';

type CustomBarProps = Omit<BarProps, 'name' | 'fill'> & {
  name: BarProps['name'] | ((value: string) => BarProps['name']);
  fill: BarProps['fill'] | ((value: string) => BarProps['fill']);
};

export const barSeries = (
  values: string[],
  metric: Metric,
  { name, fill, ...restBarProps }: Partial<CustomBarProps> = {}
) =>
  values.map((value) => {
    const barName = isNil(name)
      ? value
      : typeof name === 'function'
      ? name(value)
      : name;
    const barFill = isNil(fill)
      ? getStableRandomColor(value)
      : typeof fill === 'function'
      ? fill(value)
      : fill;
    return (
      <Bar
        key={`${metric}.${value}`}
        dataKey={`${metric}.${value}`}
        name={barName}
        yAxisId={metric}
        xAxisId="timestamp"
        stackId="a"
        fill={barFill}
        isAnimationActive={false}
        {...restBarProps}
      />
    );
  });
