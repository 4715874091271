import {
  Avatar,
  ButtonBase,
  Card,
  Divider,
  Tooltip,
  Typography
} from '@material-ui/core';
import { formatChartCurrency } from '../../../components/Charts/Util';
import { styled } from '../../../emotion';
import { Recommendation } from '../types';
import { ChevronRight, Info } from 'react-feather';
import { RecommendationsBadge } from './RecommendationsBadge';

const RecommendationsHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const StyledCard = styled(Card)`
  transform: scale(1);

  &:hover {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    transform: scale(1.03);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`;

export const RecommendationsCard = ({
  recommendation
}: {
  recommendation: Recommendation;
}) => {
  return (
    <ButtonBase href={recommendation.url} target="_blank">
      <StyledCard>
        <Typography variant="body1" paragraph style={{ height: '50px' }}>
          <strong>{recommendation.title}</strong>{' '}
          <ChevronRight
            size={16}
            style={{ position: 'relative', top: '2px' }}
          />
        </Typography>
        <RecommendationsHeader>
          <div>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Opportunity
            </Typography>
            <RecommendationsBadge recommendation={recommendation} />
          </div>
          <div style={{ textAlign: 'right' }}>
            <Typography variant="body2" color="textSecondary">
              Estimated lift{' '}
              <Tooltip
                title="This is an estimate of how much more you could earn by following this recommendation."
                placement="top"
              >
                <span>
                  <Info
                    size={14}
                    style={{ position: 'relative', top: '2px' }}
                  />
                </span>
              </Tooltip>
            </Typography>
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
              +
              {formatChartCurrency(recommendation.estimatedEarnings, 'USD', {
                excludeCents: true
              })}
            </Typography>
          </div>
        </RecommendationsHeader>
        <Divider />
        <br />
        <Typography variant="body2" color="textSecondary" paragraph>
          {recommendation.description}
        </Typography>
        <br />
        <Avatar>
          <Typography variant="body2" color="textSecondary">
            M
          </Typography>
        </Avatar>
      </StyledCard>
    </ButtonBase>
  );
};
