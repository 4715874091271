import React from 'react';
import { Typography } from '@material-ui/core';
import { Doc } from '../../../../../domainTypes/document';
import { ScanNewResults } from '../../../../../domainTypes/notifications';
import { useRoutes } from '../../../../../routes';
import { pluralize } from '../../../../../services/pluralize';
import { Container } from '../../Item/Container';
import { Frame } from '../../Item/Frame';
import { Heading } from '../../Item/Heading';
import * as Icon from '../../Item/Icon';
import { AlertStatus, IAlertStatus } from '../../AlertStatus';
import { Link } from 'react-feather';

export const Scan_NewResults = ({ n }: { n: Doc<ScanNewResults> }) => {
  const { ROUTES } = useRoutes();
  const { scanId, counts } = n.data.data;
  return (
    <Frame n={n} href={ROUTES.links.scans.details.url(scanId)}>
      <Container>
        <Icon.Container>
          <Link size={Icon.SIZE} />
        </Icon.Container>
        <div>
          <Heading>New links detected</Heading>
          <AlertStatus status={IAlertStatus.ACTION_REQUIRED} />
          <Typography color="textSecondary" variant="body2" component="p">
            We found new affiliate links on your website. Please review and
            import {pluralize('new link', counts.links, true)} found on{' '}
            {pluralize('page', counts.pages, true)}.
          </Typography>
        </div>
      </Container>
    </Frame>
  );
};
