import { Paper, Typography } from '@material-ui/core';
import { orderBy } from 'lodash';
import React, { FC, useRef, useState } from 'react';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import {
  ListVirtualized,
  ListVirtualizedRef
} from '../../../../components/List/Virtualized';
import { IProductSuggestionGroup } from '../../../../domainTypes/productScan';
import { css, styled } from '../../../../emotion';
import { useList } from '../../../../hooks/useList';
import { SortDirection } from '../../../../hooks/useSort';
import { now, Timestamp } from '../../../../services/time';
import { removeTrailingSlash } from '../../../../services/url';
import * as tracking from '../../../../tracking';
import {
  addSeveralToTrash,
  removeSeveralFromTrash,
  sortByNameScore
} from '../../services';
import { DetailsDialog } from './components/DetailsDialog';
import { RowDetails } from './components/RowDetails';
import { RowSummary } from './components/RowSummary';
import { Toolbar, ToolbarElements } from './components/Toolbar';
import { X, CornerUpLeft, Check } from 'react-feather';
import { HelpIcon } from '../../../../components/HelpIcon';
import { ARTICLES } from '../../../../services/beacon';
import { CreateProductArgs } from '../../../../domainTypes/product';
import { Link } from 'react-router-dom';
import { useRoutes } from '../../../../routes';
import { useHasCurrentUserRequiredScopes } from '../../../../services/currentUser';

type Props = {
  spaceId: string;
  userId: string;
  onImport?: (args: CreateProductArgs[], ts: Timestamp) => Promise<any>;
  new: IProductSuggestionGroup[];
  known: IProductSuggestionGroup[];
  trashed: IProductSuggestionGroup[];
};

export interface IRow {
  key: string;
  name: string;
  selected: boolean;
  expanded: boolean;
  timesFound: number;
  pages: Set<string>;
  score: number;
  data: IProductSuggestionGroup[];
}

type RowInfo = {
  index: number;
  totalRows: number;
  expanded: boolean;
};

// zIndex in expanded state should always be higher than the next elements zIndex,
// so that we don't have strange overlaps while the list recomputes its row heights.
const RowWrapper = styled<'div', RowInfo>('div')`
  z-index: ${(p) => (p.expanded ? 1 + p.totalRows - p.index : 0)};
  border-bottom: 1px rgba(224, 224, 224, 1) solid;
`;

const LIST_HEIGHT = 500;

const EmptyContainer = styled('div')`
  height: ${LIST_HEIGHT}px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > img {
    width: 300px;
  }

  > div {
    margin: ${(p) => p.theme.spacing() * 2}px;
    text-align: center;
  }
`;

const EmptyState = ({
  label,
  imgSrc
}: {
  label?: React.ReactNode;
  imgSrc?: string;
}) => {
  return (
    <EmptyContainer>
      {!!imgSrc && <img src={imgSrc} alt="" />}
      {!!label && <div>{label}</div>}
    </EmptyContainer>
  );
};

const Header = styled('div')`
  display: grid;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(4)}px;
  grid-template-columns: 3fr 1fr;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
  }
`;

const PaperStyled: FC = ({ children }) => (
  <Paper
    className={css(() => ({
      height: '580px',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '24px'
    }))}
  >
    {children}
  </Paper>
);

type SortKey = 'score';

const sort = (rows: IRow[], sortBy: SortKey, direction: SortDirection) => {
  return orderBy(
    rows,
    [
      (r) => {
        // only score implemented so far
        return r.score;
      },
      (r) => r.timesFound,
      (r) => r.name
    ],
    [direction, 'desc', 'desc', 'asc']
  );
};

// TODO: Move this to be shared with PartnerChart?
const extractDomain = (domain: string): string => {
  const matches = new RegExp(
    // eslint-disable-next-line
    /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/
  ).exec(domain);

  if (!matches || !matches.length) {
    return 'unknown';
  }

  return matches[1];
};

interface Memo {
  [key: string]: IRow;
}

const combineDomains = (memo: Memo, g: IProductSuggestionGroup) => {
  const domain = extractDomain(g.href);
  const entry: IRow = memo[domain] || {
    key: domain,
    name: domain,
    selected: false,
    expanded: false,
    pages: new Set(),
    timesFound: 0,
    data: [],
    score: 0
  };

  g.pages.forEach((p) => entry.pages.add(removeTrailingSlash(p.url)));
  entry.timesFound += g.timesFound;
  entry.score = g.score; // Refactor to be average of all links?
  entry.data.push(g);
  memo[domain] = entry;
  return memo;
};

const sumLinks = (list: IRow[]) =>
  list.reduce((memo, row) => memo + row.data.length, 0);

const rowsToProducts = (rows: IRow[]) => {
  console.log(rows);
  return rows.reduce<CreateProductArgs[]>((memo, r) => {
    return memo.concat(
      r.data.map((d) => {
        const sortedByNameScore = sortByNameScore(d.names);
        return {
          name: sortedByNameScore[0] || d.href,
          url: d.href,
          destinationUrl: d.destHref,
          pageUrls: d.pages.map((p) => removeTrailingSlash(p.url))
        };
      })
    );
  }, []);
};

const toDomainRows = (suggestions: IProductSuggestionGroup[]) =>
  Object.values(suggestions.reduce(combineDomains, {} as Memo));

const NoNewProducts = () => {
  const { ROUTES } = useRoutes();

  return (
    <EmptyState
      label={
        <div style={{ maxWidth: '500px', margin: '0 auto' }}>
          <Typography variant="body1" component="p" gutterBottom>
            <strong>All the links on your site have been imported.</strong>
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            The links found during this scan have already been imported for
            tracking. Either because you imported them already, all recently
            updated pages don't contain new links, or because you have Automatic
            Import enabled in your{' '}
            <Link
              to={ROUTES.links.settings.url()}
              style={{ borderBottom: '1px solid' }}
            >
              Link Settings
            </Link>
            .
          </Typography>
        </div>
      }
      imgSrc="/images/empty-states/scan_no_new_products.svg"
    />
  );
};
const NoProductsAtAll = () => {
  return (
    <EmptyState
      label={
        <div style={{ maxWidth: '500px', margin: '0 auto' }}>
          <Typography variant="body1" component="p" gutterBottom>
            <strong>No new links found in this scan</strong>
          </Typography>
          <Typography variant="body1" component="p" color="textSecondary">
            Either none of the pages we checked this time contained affiliate
            links, or there was an error scanning your sitemap.
          </Typography>
          <br />
          <br />
          <HelpIcon color="blue" articleId={ARTICLES.links.troubleshootScan}>
            Read for help troubleshooting your site scan
          </HelpIcon>
        </div>
      }
      imgSrc="/images/empty-states/scan_nothing_found.svg"
    />
  );
};

export const Importer = ({
  spaceId,
  userId,
  new: newSuggestions,
  known: knownSuggestions,
  trashed: trashedSuggestions,
  onImport
}: Props) => {
  console.log(newSuggestions);
  const [
    selectedSuggestion,
    setSelectedSuggestion
  ] = useState<IProductSuggestionGroup | null>(null);
  const [currentView, setCurrentView] = useState<ToolbarElements>('list');
  const listRef = useRef<ListVirtualizedRef | null>(null);
  const { ROUTES } = useRoutes();
  const [canCreateLinks] = useHasCurrentUserRequiredScopes(['links.create']);

  // Known products
  const knownDomains = useList<IRow>(toDomainRows(knownSuggestions));

  // Products to import
  const newDomains = useList<IRow>(toDomainRows(newSuggestions));

  // Products in trash
  const trash = useList<IRow>(toDomainRows(trashedSuggestions));

  const handleTrashDomain = (domainGroup: IRow) => {
    trash.append({ ...domainGroup, expanded: false });
    newDomains.remove(domainGroup.key);
    addSeveralToTrash(
      spaceId,
      userId,
      domainGroup.data.map((d) => d.href)
    );
    tracking.sendEvent({
      category: tracking.toAppCategory(),
      action: 'Click',
      label: 'Move to trash'
    });
  };

  const handleRestoreDomain = (domainGroup: IRow) => {
    newDomains.append({ ...domainGroup, expanded: false });
    trash.remove(domainGroup.key);
    removeSeveralFromTrash(
      spaceId,
      domainGroup.data.map((d) => d.href)
    );
    tracking.sendEvent({
      category: tracking.toAppCategory(),
      action: 'Click',
      label: 'Restore from trash'
    });
  };

  const linksToImportCount = sumLinks(newDomains.list);
  const linksToTrashCount = sumLinks(trash.list);
  const linksKnownCount = sumLinks(knownDomains.list);

  const handleImport = (rows: IRow[]) => {
    if (!onImport) {
      return Promise.resolve();
    }
    tracking.sendEvent({
      category: tracking.toAppCategory(),
      action: 'Click',
      label: 'Import all'
    });

    const productRows = rowsToProducts(rows);
    return onImport(productRows, now());
  };

  const getCurrent = () => {
    if (currentView === 'trash') {
      return { d: trash, empty: <EmptyState /> };
    }
    if (currentView === 'knownProducts') {
      return { d: knownDomains, empty: <EmptyState /> };
    }
    return {
      d: newDomains,
      empty: knownDomains.list.length ? <NoNewProducts /> : <NoProductsAtAll />
    };
  };

  const current = getCurrent();

  return (
    <>
      <Header>
        <div>
          <Typography
            variant="h6"
            component="p"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <strong style={{ marginRight: '12px' }}>
              Import your affiliate links
            </strong>
            <HelpIcon articleId={ARTICLES.links.linkManagement}>
              How it works
            </HelpIcon>
          </Typography>
          {currentView === 'trash' && (
            <Typography variant="body1" component="p">
              Restore any links that should NOT be ignored with the{' '}
              <CornerUpLeft
                size={24}
                style={{ position: 'relative', top: '6px' }}
              />{' '}
              button.
            </Typography>
          )}
          {currentView === 'list' && (
            <Typography variant="body1" component="p">
              Use the{' '}
              <X size={24} style={{ position: 'relative', top: '6px' }} />{' '}
              button to remove anything that's not an affiliate link.
            </Typography>
          )}
          {currentView === 'knownProducts' && (
            <Typography variant="body1" component="p">
              <Check size={24} style={{ position: 'relative', top: '6px' }} />{' '}
              These links have already been imported for tracking. To remove
              links you want to ignore, go to{' '}
              <Link
                style={{ borderBottom: '1px solid' }}
                to={ROUTES.links.overview.url()}
              >
                Links
              </Link>
              .
            </Typography>
          )}
        </div>
        {onImport && canCreateLinks && (
          <ButtonWithPromise
            variant="contained"
            color="primary"
            size="large"
            pending="Importing..."
            disabled={!newDomains.list.length}
            onClick={() => handleImport(newDomains.list)}
          >
            Import all links ({linksToImportCount})
          </ButtonWithPromise>
        )}
      </Header>

      <PaperStyled>
        <Toolbar
          acceptedSize={linksToImportCount}
          trashSize={linksToTrashCount}
          alreadyAddedSize={linksKnownCount}
          onToggleTrashView={(view) => {
            setCurrentView(view);
            if (listRef.current) {
              listRef.current.resizeAll();
            }
          }}
          currentView={currentView}
        />
        {current.d.list.length ? (
          <ListVirtualized
            rows={sort(current.d.list, 'score', 'desc')}
            height={LIST_HEIGHT}
            getRef={listRef}
          >
            {({ row, index, resizeRow, style }) => {
              const onExpand = () => {
                current.d.update(row.key, { expanded: !row.expanded });
                resizeRow();
              };
              return (
                <RowWrapper
                  style={style}
                  expanded={row.expanded}
                  index={index}
                  key={index}
                  totalRows={current.d.list.length}
                >
                  <RowSummary
                    row={row}
                    expanded={row.expanded}
                    onExpand={onExpand}
                    disabled={!canCreateLinks}
                    onMove={(domain) => {
                      const fn =
                        currentView === 'trash'
                          ? handleRestoreDomain
                          : handleTrashDomain;
                      fn(domain);
                      resizeRow();
                    }}
                    currentView={currentView}
                  />
                  {row.expanded && (
                    <RowDetails
                      gs={row.data}
                      onShowDetails={(g) => {
                        tracking.sendEvent({
                          category: tracking.toAppCategory(),
                          action: 'Open',
                          label: 'Product details modal'
                        });
                        return setSelectedSuggestion(g);
                      }}
                    />
                  )}
                </RowWrapper>
              );
            }}
          </ListVirtualized>
        ) : (
          current.empty
        )}
      </PaperStyled>
      <DetailsDialog
        g={selectedSuggestion}
        onClose={() => {
          tracking.sendEvent({
            category: tracking.toAppCategory(),
            action: 'Close',
            label: 'Product details modal'
          });
          return setSelectedSuggestion(null);
        }}
      />
    </>
  );
};
