import firebase from 'firebase/app';
import { trim } from 'lodash';
import { useObject } from 'react-firebase-hooks/database';
import { Doc, toDocD } from '../../domainTypes/document';
import {
  IProductScan,
  IProductScanResult,
  ProductScanType,
  IProductScanTracker,
  IProductScanTarget
} from '../../domainTypes/productScan';
import { getCurrentSpace, getCurrentUser } from '../../services/currentUser';
import { RDB } from '../../versions';
import { useMappedLoadingValue, rdb, toRef, useRdbList } from '../db';

export const suggestionsQuery = (spaceId: string, scanId: string) => {
  return rdb().ref(toRef(RDB.productSuggestions, spaceId, scanId));
};

export const scanQuery = (spaceId: string, scanId: string) => {
  return rdb().ref(toRef(RDB.productScans, spaceId, scanId));
};

export const createScan = (
  urls: string[],
  type: IProductScanTarget['type'],
  mode: ProductScanType = 'FULL'
): Promise<Doc<IProductScan>> => {
  const space = getCurrentSpace();

  const urlsToScan = urls.reduce<string[]>((result, url) => {
    const { origin } = new URL(url);
    const spaceDomain = space.domains.find((d) => d.url === origin);

    if (!spaceDomain || spaceDomain.sitemaps === null) {
      return [...result, url];
    }
    return [...result, ...spaceDomain.sitemaps];
  }, []);

  return createProductScanInSpace(
    space.id,
    urlsToScan,
    type,
    mode,
    getCurrentUser().id
  );
};

export const createProductScanInSpace = (
  spaceId: string,
  urls: string[],
  type: 'DOMAIN' | 'PAGE',
  scanType: ProductScanType,
  createdBy: string
) => {
  const scansPath = toRef(RDB.productScans, spaceId);
  const scansRef = rdb().ref(scansPath);
  const scanRef = scansRef.push();
  const id = scanRef.key;
  if (id === null) {
    throw new Error('Failed to create id for new scan');
  }
  if (!urls.length) {
    throw new Error('No urls given!');
  }

  const scan: IProductScan = {
    spaceId,
    status: 'INITIALISING',
    pages: {},
    targets: urls.map((url) => ({
      type,
      url: trim(url)
    })),
    createdBy,
    createdAt: Date.now(),
    finishedAt: null,
    scanType
  };
  return scanRef
    .set(scan)
    .then<Doc<IProductScan>>(() => ({ id, collection: scansPath, data: scan }));
};

export const useScansBySpaceId = (spaceId: string, limit: number) => {
  return useMappedLoadingValue(
    useRdbList<IProductScan>(
      rdb()
        .ref(toRef(RDB.productScans, spaceId))
        .orderByKey()
        .limitToLast(limit),
      normalizeProductScan
    ),
    (docs) => docs.map(mapProductScan)
  );
};

export const useScans = (limit: number) =>
  useScansBySpaceId(getCurrentSpace().id, limit);

export const normalizeProductScan = (d: IProductScan) => {
  d.pages = d.pages || {};
  d.scanType = d.scanType || 'FULL';

  const x = d as any;
  if (!d.targets && x.target) {
    d.targets = [x.target];
  }
  return d;
};

export const useScan = (spaceId: string, scanId: string) => {
  return useMappedLoadingValue(useObject(scanQuery(spaceId, scanId)), (value) =>
    mapProductScan(toDocD<IProductScan>(value, normalizeProductScan))
  );
};

// we changed the format of this timestamp over time and therefore need to
// be backwards compatible
export const parseTimestamp = (
  ts: number | { _seconds: number; _nanoseconds: number }
) => {
  if (typeof ts === 'number') {
    return firebase.firestore.Timestamp.fromMillis(ts);
  }
  if (!ts) {
    return new firebase.firestore.Timestamp(0, 0);
  }
  return new firebase.firestore.Timestamp(ts._seconds, ts._nanoseconds);
};

export const mapProductScan = (
  doc: Doc<IProductScan>
): Doc<IProductScanResult> => {
  const {
    spaceId,
    status,
    targets,
    createdAt,
    createdBy,
    finishedAt,
    pages,
    speed,
    notes
  } = doc.data;
  return {
    id: doc.id,
    collection: '',
    data: {
      spaceId,
      status,
      targets,
      createdAt: parseTimestamp(createdAt),
      createdBy: createdBy || '',
      finishedAt: finishedAt ? parseTimestamp(finishedAt) : null,
      pages: Object.values(pages),
      speed,
      notes: notes || []
    }
  };
};

const scanTrackerQuery = (spaceId: string, scanId: string) => {
  return rdb().ref(toRef(RDB.productScanTrackers, spaceId, scanId));
};

export const useScanTracker = (spaceId: string, scanId: string) => {
  return useMappedLoadingValue(
    useObject(scanTrackerQuery(spaceId, scanId)),
    (value) => toDocD<IProductScanTracker>(value)
  );
};
