import { CioIdentify, ICioEvent, CioListItem } from '../domainTypes/customerio';
import { CF } from '../versions';
import * as ENV from '../env.json';
import { callFirebaseFunction } from './firebaseFunctions';

type ICustomerIo = {
  identify: (attrs: CioIdentify) => void;
  track: (event: ICioEvent) => void;
};

export const Cio: ICustomerIo = {
  identify: (args) => {
    console.log('calling identify');
    if (typeof window !== 'undefined') {
      const _cio = (window as any)._cio;
      _cio.identify(args);
    }
  },
  track: (event) => {
    if (typeof window !== 'undefined') {
      const _cio = (window as any)._cio;
      _cio.track(event.name, event.data);
    }
  }
};

export const unsubscribeByDeliveryId = async (deliveryId: string) => {
  return callFirebaseFunction(CF.mail.unsubscribeByDeliveryId, { deliveryId });
};

export const getEmailLists = async (id: string) => {
  return callFirebaseFunction<{ lists: string; unsubscribed: boolean }>(
    CF.mail.getEmailLists,
    { id }
  ).then((resp) => {
    if (!resp.lists || !resp.lists.length) {
      return {
        lists: [],
        unsubscribed: resp.unsubscribed
      };
    }

    return {
      lists: Object.values(JSON.parse(resp.lists)) as CioListItem[],
      unsubscribed: resp.unsubscribed
    };
  });
};

export const CUSTOMER_IO_INIT_SCRIPT = `
  var _cio = _cio || [];
  (function() {
    var a,b,c;a=function(f){return function(){_cio.push([f].
    concat(Array.prototype.slice.call(arguments,0)))}};b=["load","identify",
    "sidentify","track","page"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])};
    var t = document.createElement('script'),
        s = document.getElementsByTagName('script')[0];
    t.async = true;
    t.id    = 'cio-tracker';
    t.setAttribute('data-site-id', '${ENV.customerio.siteId}');
    t.src = 'https://assets.customer.io/assets/track.js';
    s.parentNode.insertBefore(t, s);
  })();
`;
