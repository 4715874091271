import { ChangeEvent, FunctionComponent } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

type ForcedHandlerType = (
  e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => void;

interface NumberFormatEvent {
  target: { name: string; value: string };
  details: {
    floatValue: number;
  };
}

type NumberFormatCustomProps = {
  inputRef: (instance: typeof NumericFormat | null) => void;
  onChange: (event: NumberFormatEvent) => void;
} & Omit<NumericFormatProps, 'onChange'>;

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;
  // NOTE: We use a thousand and decimal separator from en-US

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name!,
            value: values.value
          },
          details: {
            floatValue: values.floatValue ?? 0
          }
        });
      }}
      thousandSeparator=","
      decimalScale={2}
      fixedDecimalScale
      decimalSeparator="."
    />
  );
}

type MoneyInputProps = {
  onChange: (value: number) => void;
  value: number;
  numericFormatProps?: Omit<NumericFormatProps, 'onChange' | 'defaultValue'>;
} & Omit<TextFieldProps, 'onChange' | 'value'>;

export const MoneyInput: FunctionComponent<MoneyInputProps> = ({
  value,
  onChange,
  numericFormatProps = {},
  InputProps = {},
  ...textFieldProps
}) => {
  return (
    <TextField
      {...textFieldProps}
      value={value}
      onChange={
        (((e: NumberFormatEvent) =>
          onChange(e.details.floatValue)) as unknown) as ForcedHandlerType
      }
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        inputProps: numericFormatProps,
        ...InputProps
      }}
    />
  );
};
