import React, { useState } from 'react';
import { IEarning } from '../../../domainTypes/performance';
import { AdvertiserChart } from '../AdvertiserChart';
import {
  ChartCard,
  ChartCardFooter,
  ChartCardFooterBar,
  ChartCardFooterSelectTopX
} from '../ChartCard';

export const AdvertiserChartCard: React.FC<{
  data: { advertiserName: string; partnerKey: string; earnings: IEarning }[];
  loading: boolean;
  style?: React.CSSProperties;
  heading?: string;
  subheading?: string;
  linkToPartnerOverview?: boolean;
}> = ({ data, loading, style, heading, subheading }) => {
  const [count, setCount] = useState(10);

  return (
    <ChartCard
      heading="Top Advertisers"
      subheading="By share of total earnings"
      style={style}
      size="small"
      padding="dense"
      centered
    >
      <AdvertiserChart
        data={data}
        count={count}
        loading={loading}
        animate={true}
      />
      <ChartCardFooter>
        <ChartCardFooterBar>
          <ChartCardFooterSelectTopX
            value={count}
            onChange={setCount}
            step={5}
            max={20}
          />
        </ChartCardFooterBar>
      </ChartCardFooter>
    </ChartCard>
  );
};
