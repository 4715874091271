import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import { useRoutes } from '../../../routes';
import { PartnerListItemWithCountsAndTrends } from '../../../services/partner';
import {
  ChartCard,
  ChartCardFooter,
  ChartCardFooterBar,
  ChartCardFooterSelectTopX
} from '../ChartCard';
import { PartnerChart } from '../PartnerChart';

export const PartnerChartCard: React.FC<{
  partners: PartnerListItemWithCountsAndTrends[] | void;
  loading: boolean;
  style?: React.CSSProperties;
  linkToPartnerOverview?: boolean;
}> = ({ partners, loading, style, linkToPartnerOverview }) => {
  const { ROUTES } = useRoutes();
  const [count, setCount] = useState(10);

  return (
    <ChartCard
      heading="Platforms"
      subheading="By share of outbound clicks"
      padding="dense"
      style={style}
    >
      <PartnerChart
        partners={partners}
        count={count}
        loading={loading}
        animate={true}
      />
      <ChartCardFooter>
        <ChartCardFooterBar>
          <ChartCardFooterSelectTopX
            value={count}
            onChange={setCount}
            step={5}
            max={20}
          />
          {linkToPartnerOverview && (
            <Link to={ROUTES.partners.overview.url()}>
              <Button color="primary">
                Network Overview <ChevronRight />
              </Button>
            </Link>
          )}
        </ChartCardFooterBar>
      </ChartCardFooter>
    </ChartCard>
  );
};
