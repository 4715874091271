import firebase from 'firebase/app';
import { useEffect, useRef, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Doc, toDoc } from '../../domainTypes/document';
import { ISpace } from '../../domainTypes/space';
import { ICurrentUser, IUser } from '../../domainTypes/user';
import { getAffiliateCookie } from '../../features/Settings/pages/AffiliateProgram/service';
import { useLoadingValue } from '../../hooks/useLoadingValue';
import { usePromise } from '../../hooks/usePromise';
import { useStateObject } from '../../hooks/useStateObject';
import {
  reportError,
  setUser as setStackdriverUser
} from '../../stackdriverErrorReporter';
import { setUserDimension } from '../../tracking';
import { CF, FS } from '../../versions';
import { Beacon } from '../beacon';
import {
  getCurrentUser,
  setCurrentUser as setCurrentUserExternal
} from '../currentUser';
import { combineLoadingValues, store, useMappedLoadingValue } from '../db';
import { callFirebaseFunction } from '../firebaseFunctions';
import { localJsonStorage } from '../localJsonStorage';
import { mixpanel as mp } from '../mixpanel';
import { createSpace, toSpaceDoc } from '../space';
import { guessCurrentTimezone } from '../time';
import { createCurrentUser } from '../user';

const toKey = (type: string, id: string) => `${type}-${id}`;

const USER_CACHE_PREFIX = 'user-v2';

const refreshCustomClaims = (authUser: firebase.User) => {
  return authUser.getIdToken(true);
};

const REFRESH_ALL_TOKENS = true;

export const signOut = async () => {
  const currentUser = getCurrentUser();
  if (currentUser) {
    // users might be trapped in an in between state where there is no current user - we still want to be able to force a full logout
    localJsonStorage.removeItem(toKey(USER_CACHE_PREFIX, currentUser.id));
  }
  await firebase.auth().signOut();
  Beacon('logout');
  const mixpanel = mp();
  mixpanel.reset();
  window.location.assign('/');
};

export const signOutWithoutRedirect = () => {
  const currentUser = getCurrentUser();
  localJsonStorage.removeItem(toKey(USER_CACHE_PREFIX, currentUser.id));
  firebase.auth().signOut();
  Beacon('logout');
};

const createUser = (args: {
  email: string | null;
  displayName: string | null | undefined;
  photoURL: string | null | undefined;
}) => {
  return callFirebaseFunction<Doc<IUser>>(CF.user.createUser, {
    email: args.email || '',
    displayName: args.displayName || '',
    photoURL: args.photoURL || ''
  });
};

const useUser = (authUser: firebase.User | void) => {
  const [creatingNewUser, setCreatingNewUser] = useState(false);
  const {
    value,
    loading,
    error,
    setValue,
    setError,
    reset
  } = useLoadingValue<IUser | null>(null);
  useEffect(() => {
    if (!authUser) {
      reset();
      setCreatingNewUser(false);
      return;
    }

    const key = toKey(USER_CACHE_PREFIX, authUser.uid);
    const storedUser = localJsonStorage.getItem<IUser>(key);
    if (storedUser) {
      setValue(storedUser);
    }

    return store()
      .collection(FS.users)
      .doc(authUser.uid)
      .onSnapshot((snapshot) => {
        if (!snapshot.exists) {
          setCreatingNewUser(true);
          createUser(authUser)
            .then((u) => {
              // update through snapshot listener

              // user create makes it known to the auth token
              // to which space the user belongs - needs a token refresh
              return refreshCustomClaims(authUser);
            })
            .catch((err) => {
              setCreatingNewUser(false);
              setError(err);
            });
          return;
        }
        const user = toDoc<IUser>(snapshot).data;
        localJsonStorage.setItem(key, user);
        if (creatingNewUser) {
          setCreatingNewUser(false);
        }
        setValue(user);
      }, setError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);
  if (authUser) {
    return {
      value,
      loading,
      creatingNewUser: !value && creatingNewUser,
      error
    };
  } else {
    return { value: null, loading, creatingNewUser, error: undefined };
  }
};

const useSpace = (spaceId: string | null) => {
  const [creatingNewSpace, setCreatingNewSpace] = useState(false);
  const {
    value,
    loading,
    error,
    setValue,
    setError,
    reset
  } = useLoadingValue<ISpace | null>(null);
  useEffect(() => {
    if (!spaceId) {
      reset();
      return;
    }

    return store()
      .collection(FS.spaces)
      .doc(spaceId)
      .onSnapshot((snapshot) => {
        if (!snapshot.exists) {
          setCreatingNewSpace(true);
          createSpace(spaceId, guessCurrentTimezone(), getAffiliateCookie())
            .then((u) => {
              // setError(undefined);
              // setLoading(false);
              // setUser(u);
              // no need to anything here, we can just wait for our snapshot listener
              // to update
            })
            .catch((err) => {
              setCreatingNewSpace(false);
              setError(err);
            });
          return;
        }
        const space = toSpaceDoc(snapshot).data;
        if (creatingNewSpace) {
          setCreatingNewSpace(false);
        }
        setValue(space);
      }, setError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId]);

  if (spaceId) {
    return {
      value,
      loading,
      creatingNewspace: !value && creatingNewSpace,
      error
    };
  } else {
    return { value: null, loading, creatingNewSpace, error: undefined };
  }
};

type State = {
  currentUser: ICurrentUser | null;
  spaceId: string | null;
  init: boolean;
};

export const useLogin = (forcedSpaceId?: string) => {
  const tokenRefreshRef = useRef<{ [userId: string]: boolean }>({});
  const [state, mergeState] = useStateObject<State>({
    currentUser: null,
    spaceId: null,
    init: true
  });
  const { currentUser, init, spaceId: originalSpaceId } = state;
  const spaceId = forcedSpaceId || originalSpaceId;
  const [authUser, initialising] = useAuthState(firebase.auth());
  setStackdriverUser(authUser ? authUser.uid : 'NOT_LOGGED_IN');

  const {
    value: user,
    loading: loadingUser,
    creatingNewUser,
    error: errorUser
  } = useUser(authUser);
  const {
    value: space,
    loading: loadingSpace,
    creatingNewSpace,
    error: errorSpace
  } = useSpace(spaceId);

  useEffect(() => {
    if (initialising) {
      return;
    }
    if (!authUser) {
      mergeState({ currentUser: null, init: false, spaceId: null });
      setCurrentUserExternal(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, initialising]);

  useEffect(() => {
    if (!spaceId && user) {
      mergeState({ spaceId: user.spaces[0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (initialising || loadingUser || loadingSpace) {
      return;
    }
    if (authUser && user && space) {
      const nextCurrentUser = createCurrentUser(authUser, user, space);
      setCurrentUserExternal(nextCurrentUser);
      mergeState({ currentUser: nextCurrentUser, init: false });
      setUserDimension(
        nextCurrentUser.id,
        nextCurrentUser.displayName || '',
        nextCurrentUser.email || '',
        nextCurrentUser.space.id
      );
    } else {
      setCurrentUserExternal(null);
      mergeState({ currentUser: null, init: false, spaceId: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, space, authUser, initialising, loadingUser, loadingSpace]);

  const firebaseLoaders = initialising || init;
  const allLoaders = firebaseLoaders || loadingUser || loadingSpace;

  useEffect(() => {
    if (
      REFRESH_ALL_TOKENS &&
      currentUser?.authUser &&
      !tokenRefreshRef.current[currentUser.id]
    ) {
      refreshCustomClaims(currentUser.authUser);
      tokenRefreshRef.current[currentUser.id] = true;
    }
  }, [currentUser]);

  const errorUserOrNull = errorUser || null;
  useEffect(() => {
    if (errorUserOrNull) {
      reportError('ERR U');
      reportError(errorUserOrNull);
    }
  }, [errorUserOrNull]);

  const errorSpaceOrNull = errorSpace || null;
  useEffect(() => {
    if (errorSpaceOrNull) {
      reportError('ERR S');
      reportError(errorSpaceOrNull);
    }
  }, [errorSpaceOrNull]);

  return {
    currentUser,
    initialising: authUser ? allLoaders : firebaseLoaders,
    creatingNewAccount: creatingNewUser || creatingNewSpace,
    error: authUser ? errorUser || errorSpace : undefined
  };
};

export const checkAdminClaim = (user: firebase.User) => {
  return user
    .getIdTokenResult()
    .then((result) => !!result.claims.admin)
    .catch(() => false);
};

// As these can never change during a session,
// we can retrieve them once and just store
// them to avoid unnecessary chatter.
const CLAIMS: {
  impersonator: undefined | boolean;
  admin: undefined | boolean;
} = {
  impersonator: undefined,
  admin: undefined
};

export const useAdminClaim = () => {
  return usePromise(async () => {
    const authUser = firebase.auth().currentUser;
    if (!authUser) {
      return false;
    }
    if (CLAIMS.admin === undefined) {
      CLAIMS.admin = await checkAdminClaim(authUser);
    }
    return CLAIMS.admin;
  }, []);
};

export const checkImpersonatorClaim = (user: firebase.User) => {
  return user
    .getIdTokenResult()
    .then((result) => !!result.claims.impersonator)
    .catch(() => false);
};

export const useImpersonatorClaim = () => {
  return usePromise(async () => {
    const authUser = firebase.auth().currentUser;
    if (!authUser) {
      return false;
    }
    if (CLAIMS.impersonator === undefined) {
      CLAIMS.impersonator = await checkImpersonatorClaim(authUser);
    }
    return CLAIMS.impersonator;
  }, []);
};

export const useAdminOrImpersonatorClaim = (): [boolean, boolean] => {
  const [result, loading] = useMappedLoadingValue(
    combineLoadingValues(useAdminClaim(), useImpersonatorClaim()),
    ([isAdmin, isImpersonator]) => {
      return isAdmin || isImpersonator;
    }
  );
  // Even during loading, return false.
  // This produces a stable value, as this will be always false for regular
  // users anyway!
  return [result || false, loading];
};
