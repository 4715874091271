import { generateToDocFn } from '../../domainTypes/document';
import { IMaintenanceSettings } from '../../domainTypes/maintenance';
import { store } from '../../services/db';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from '../../services/firecache/documentListener';

const DEFAULT_ID = 'maintenanceSettingsV1';

const toMaintenanceSettingsDoc = generateToDocFn<IMaintenanceSettings>();
const maintenanceSettingsStore = createDocumentListenerGetter(
  (key) => store().collection('maintenanceSettingsV1').doc(key),
  toMaintenanceSettingsDoc,
  () => ({ enabled: false })
);

export const useMaintenanceSettings = () => {
  return useDocumentListener(maintenanceSettingsStore(DEFAULT_ID));
};
