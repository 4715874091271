import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { last, without } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Info } from 'react-feather';
import { Doc } from '../../domainTypes/document';
import { Schedule } from '../../domainTypes/schedule';
import {
  IDomain,
  IDomainUpdateSettingsParams,
  ISpace
} from '../../domainTypes/space';
import { css, styled } from '../../emotion';
import {
  SelectableButton,
  SelectableChip
} from '../../features/Settings/components/SelectableButton';
import { useBackgroundJobs } from '../../hooks/useBackgroundJobs';
import { useSnackbar } from '../../hooks/useSnackbar';
import { FlexContainer } from '../../layout/Flex';
import { useAdminOrImpersonatorClaim } from '../../services/auth';
import { ARTICLES } from '../../services/beacon';
import { useCurrentUser } from '../../services/currentUser';
import { setFeature, useFeatureEnabled } from '../../services/features';
import { updateOrCreateSchedule } from '../../services/schedules/helper';
import { useProductScanSchedule } from '../../services/schedules/productScan';
import {
  updateAutoImportSpace,
  updateSiteSettings
} from '../../services/space';
import { AlertBox } from '../AlertBox';
import { Form } from '../Form';
import { FormField } from '../Form/FormField';
import { HelpIcon } from '../HelpIcon';
import { Loader } from '../Loader';
import { ScheduleFormSimple } from '../Schedule/SimpleForm';

const toEventIdPreview = ({
  prefix,
  format,
  delimiter,
  maxLabelLength,
  noPrefix
}: {
  prefix: string[];
  format: 'alphanumeric' | 'timestamp';
  delimiter: string;
  maxLabelLength: number;
  noPrefix: boolean;
}) => {
  const prefixStr = !noPrefix ? last(prefix) || 'amcid-' : '';
  const exampleFormat =
    format === 'alphanumeric' ? 'jnzqogqqibyujiynm7o7m' : '18397454820jp2v';
  const truncatedFormat = exampleFormat.slice(
    0,
    maxLabelLength - prefixStr.length
  );
  return `${delimiter}${prefixStr}${truncatedFormat}`;
};

const InsetSection = styled('div')`
  border: 1px solid ${(p) => p.theme.palette.divider};
  padding: ${(p) => p.theme.spacing(2)}px;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
`;

const InfoWrapper = styled('span')`
  display: inline-block;
  position: relative;
  top: 2px;
  right: -6px;
`;

const Row = styled('div')`
  display: flex;
  align-items: center;
`;

const Code = styled('span')`
  display: inline-block;
  background-color: ${(p) => p.theme.palette.grey[100]};
  color: ${(p) => p.theme.palette.grey[700]};
  font-family: monospace;
  padding: 2px 6px;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
`;

type UpdateSettingsFn = (
  domain: IDomain,
  settings: Partial<IDomainUpdateSettingsParams>
) => Promise<void>;

const SelectableButtonWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
`;

const TrackingSettings = ({
  domain,
  onUpdateSettings
}: {
  domain: IDomain;
  onUpdateSettings: UpdateSettingsFn;
}) => {
  const currentUser = useCurrentUser();
  const hasScanlessTracking = useFeatureEnabled('SCANLESS_TRACKING');

  const [noPrefix, setNoPrefix] = React.useState<boolean>(
    domain.trackingIdFormat.prefix[0] === ''
  );
  const [newTrackingIdPrefix, setNewTrackingIdPrefix] = React.useState<string>(
    last(domain.trackingIdFormat.prefix) || ''
  );
  const [trackingIdFormat, setTrackingIdFormat] = React.useState<
    'alphanumeric' | 'timestamp'
  >(domain.trackingIdFormat.format);
  const [maxLabelLength, setMaxLabelLength] = React.useState(
    domain.trackingIdFormat.maxLabelLength
  );
  const [delimiter, setDelimiter] = React.useState(
    domain.trackingIdFormat.delimiter
  );
  const [amazonSubTagFormat, setAmazonSubTagFormat] = React.useState(
    domain.trackingIdFormat.amazonSubTagFormat
  );
  const [adminClaim] = useAdminOrImpersonatorClaim();

  return (
    <FormControl>
      <div
        style={{
          opacity: 0.5,
          pointerEvents: 'none'
        }}
      >
        <Typography variant="h6" component="p" gutterBottom>
          <strong>Global tracking settings</strong>
        </Typography>
        <Typography variant="body2" component="p" paragraph>
          These settings apply to all your sites in this workspace. Contact
          Support if you believe this setting should be changed.
        </Typography>
        <SelectableButtonWrapper>
          <SelectableButton
            selected={hasScanlessTracking}
            disabled={true}
            heading={
              <>
                Instant tracking <SelectableChip>Best</SelectableChip>
              </>
            }
            onClick={() => {
              setFeature(
                currentUser.space,
                'SCANLESS_TRACKING',
                true,
                currentUser.id
              );
            }}
            subheading={
              <ul>
                <li>Instantly track new links when they appear onsite.</li>
                <li>Simplified setup for sites without cloaked links.</li>
                <li>
                  Or, custom cloakers using our universal SubIDs (see below).
                </li>
              </ul>
            }
          />
          <SelectableButton
            selected={!hasScanlessTracking}
            disabled={true}
            onClick={() => {
              setFeature(
                currentUser.space,
                'SCANLESS_TRACKING',
                false,
                currentUser.id
              );
            }}
            heading={
              <>
                Daily link import <SelectableChip>Deprecated</SelectableChip>
              </>
            }
            subheading={
              <ul>
                <li>Tracks new links after a nightly site crawl.</li>
                <li>Required for sites with cloaked links on WordPress.</li>
                <li>Not recommended for newly created workspaces.</li>
              </ul>
            }
          />
        </SelectableButtonWrapper>
      </div>
      <br />
      <Divider />
      <br />
      <Typography variant="h6" component="p" gutterBottom>
        <strong>Site-specific tracking settings (Advanced)</strong>
      </Typography>
      <Typography variant="body2" component="p" paragraph>
        Customize how Affilimate tracks your affiliate links and builds your
        SubIDs with event data. These settings apply to this website only.
      </Typography>
      <br />
      <Typography variant="body1" component="p" style={{ fontWeight: 'bold' }}>
        SubID update method
        <Tooltip
          placement="top"
          title="Replaced SubIDs will include a human-friendly page slug at the beginning. Appended SubIDs will respect any existing SubID value, and only add our dynamic SubID to the end, separated by an underscore."
        >
          <InfoWrapper>
            <Info size={16} />
          </InfoWrapper>
        </Tooltip>
      </Typography>
      <Typography variant="body2" component="p" color="textSecondary" paragraph>
        Should Affilimate replace or append to any existing SubIDs?
      </Typography>
      <SelectableButtonWrapper>
        <SelectableButton
          selected={!domain.updateMethod || domain.updateMethod === 'replace'}
          heading="Replace existing SubIDs"
          onClick={() => {
            onUpdateSettings(domain, { updateMethod: 'replace' });
          }}
          subheading="Default setting. SubIDs placed in the format of post-slug_amcid-XXX."
        />
        <SelectableButton
          selected={domain.updateMethod === 'append'}
          heading="Append to existing SubIDs"
          onClick={() => {
            onUpdateSettings(domain, { updateMethod: 'append' });
          }}
          subheading="Recommended if you have existing, meaningful SubIDs on your links."
        />
      </SelectableButtonWrapper>
      <br />
      {adminClaim && (
        <InsetSection>
          <Typography
            variant="body1"
            component="p"
            style={{ fontWeight: 'bold' }}
          >
            Event ID format
          </Typography>
          <Typography
            variant="body2"
            component="p"
            color="textSecondary"
            paragraph
          >
            How should the event IDs added to your SubIDs be formatted?
          </Typography>
          <AlertBox variant="error">
            These settings are visible to admins only.
          </AlertBox>
          <br />
          <Typography variant="body2" component="p" paragraph>
            <strong>Set a custom event ID prefix</strong>
            <Tooltip
              placement="top"
              title="Event ID prefixes are used to distinguish the Affilimate event ID within your SubIDs. Choose a short, unique prefix that will not conflict with your existing SubIDs."
            >
              <InfoWrapper>
                <Info size={16} />
              </InfoWrapper>
            </Tooltip>
            <br />
            For example if your company is named "Media Corp", good examples of
            prefixes might include: <Code>mc</Code>, <Code>co</Code>, or{' '}
            <Code>af</Code>. Ideally use the same event format for all sites.
          </Typography>
          <FlexContainer alignItems="center">
            <TextField
              value={newTrackingIdPrefix}
              onChange={(e) => setNewTrackingIdPrefix(e.target.value)}
              variant="outlined"
              placeholder="amcid-"
              style={{ width: '200px' }}
              disabled={noPrefix}
            />
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={noPrefix}
                  onChange={(e) => {
                    setNoPrefix(e.target.checked);
                  }}
                />
              }
              label="Use empty prefix"
            />
          </FlexContainer>
          <br />
          <Typography variant="body2" component="p">
            <strong>Choose the event ID format</strong>
            <Tooltip
              placement="top"
              title="This is a matter of preference. Alphanumeric IDs are shorter and easier to read, but timestamp-based IDs are better at preventing collisions with small maximum lengths on high volume sites."
            >
              <InfoWrapper>
                <Info size={16} />
              </InfoWrapper>
            </Tooltip>
          </Typography>
          <RadioGroup
            name="trackingIdFormat"
            value={trackingIdFormat}
            style={{ flexDirection: 'row' }}
            onChange={(e) => setTrackingIdFormat(e.target.value as any)}
          >
            <FormControlLabel
              value="alphanumeric"
              control={<Radio color="primary" />}
              label="Alphanumeric"
            />
            <FormControlLabel
              value="timestamp"
              control={<Radio color="primary" />}
              label="Timestamp-based"
            />
          </RadioGroup>
          <br />
          <Typography variant="body2" component="p" paragraph>
            <strong>Maximum length</strong>
            <br />
            Keep as long as possible considering network limits and existing
            SubIDs.
          </Typography>
          <TextField
            type="number"
            value={maxLabelLength}
            onChange={(e) => setMaxLabelLength(parseInt(e.target.value))}
            variant="outlined"
            placeholder="32"
            style={{ width: '230px' }}
            InputProps={{
              endAdornment: (
                <Typography variant="body2" component="span">
                  characters
                </Typography>
              )
            }}
            error={maxLabelLength < 24}
            helperText={
              maxLabelLength < 24 ? 'Must be at least 24 characters' : ''
            }
          />
          <br />
          <br />
          <Typography variant="body2" component="p" paragraph>
            <strong>Delimiter</strong>
            <br />
            Separates the event ID from the rest of the SubID. Recommended:{' '}
            <Code>_</Code> or <Code>-</Code>.
          </Typography>
          <TextField
            value={delimiter}
            onChange={(e) => setDelimiter(e.target.value)}
            variant="outlined"
            placeholder="_"
            style={{ width: '100px' }}
          />
          <br />
          <br />
          <Typography variant="body2" component="p">
            <strong>Amazon Subtag format</strong>
            <br />
            Whether to use approximate or precise event IDs for Amazon Subtags.
          </Typography>
          <RadioGroup
            name="amazonSubTagFormat"
            value={amazonSubTagFormat}
            style={{ flexDirection: 'row' }}
            onChange={(e) => {
              setAmazonSubTagFormat(e.target.value as any);
            }}
          >
            <FormControlLabel
              value="approx"
              control={<Radio color="primary" />}
              label="Approximate"
            />
            <FormControlLabel
              value="exact"
              control={<Radio color="primary" />}
              label="Exact"
            />
            <FormControlLabel
              value=""
              control={<Radio color="primary" />}
              label="None"
            />
          </RadioGroup>
          <br />
          <Typography variant="body2" component="p" paragraph>
            Your event IDs will be added like:{' '}
            <Code>
              {toEventIdPreview({
                format: trackingIdFormat,
                delimiter,
                maxLabelLength,
                noPrefix,
                prefix: newTrackingIdPrefix
                  ? [newTrackingIdPrefix]
                  : domain.trackingIdFormat.prefix
              })}
            </Code>
          </Typography>
          <Typography
            variant="body2"
            component="p"
            color="textSecondary"
            paragraph
          >
            Previous custom prefixes preserved to map historical data:{' '}
            {domain.trackingIdFormat.prefix.length ? (
              <Code>{domain.trackingIdFormat.prefix.join(', ')}</Code>
            ) : (
              'None'
            )}
          </Typography>
          <Button
            variant="contained"
            color="default"
            onClick={() => {
              const prefix = newTrackingIdPrefix
                ? without(
                    domain.trackingIdFormat.prefix,
                    newTrackingIdPrefix
                  ).concat(newTrackingIdPrefix)
                : domain.trackingIdFormat.prefix;

              onUpdateSettings(domain, {
                trackingIdFormat: {
                  prefix,
                  format: trackingIdFormat,
                  delimiter,
                  maxLabelLength,
                  amazonSubTagFormat
                }
              });
            }}
          >
            Apply new event ID format
          </Button>
        </InsetSection>
      )}
      <br />
      <Typography variant="body1" component="p" style={{ fontWeight: 'bold' }}>
        Auto-linking scripts
      </Typography>
      <Typography variant="body2" component="p" color="textSecondary">
        Enable compatibility with Skimlinks and other dynamic link insertion
        scripts.
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={domain.skimlinksDynamicImport}
            onChange={(e) => {
              onUpdateSettings(domain, {
                skimlinksDynamicImport: e.target.checked
              });
            }}
          />
        }
        label="Skimlinks JS"
      />
      <br />
      <Typography variant="body1" component="p" style={{ fontWeight: 'bold' }}>
        Smart labels for cloaked links
        <Tooltip
          placement="top"
          title="Non-cloaked links and WordPress plugin users should use Network-specific SubIDs. Custom link cloaker users with their own backend integration can use Universal SubIDs to control how Affilimate's SubIDs are merged with your internal tracking setup."
        >
          <InfoWrapper>
            <Info size={16} />
          </InfoWrapper>
        </Tooltip>
      </Typography>
      <Typography variant="body2" component="p" color="textSecondary" paragraph>
        How should Affilimate add SubIDs to your cloaked links?
      </Typography>
      <SelectableButtonWrapper>
        <SelectableButton
          selected={!domain.subidStrategy || domain.subidStrategy === 'network'}
          onClick={() => {
            onUpdateSettings(domain, { subidStrategy: 'network' });
          }}
          heading={
            <>
              Network-specific SubIDs <SelectableChip>Default</SelectableChip>
            </>
          }
          subheading="Automatically add SubIDs for each network based on our default parameters."
        />
        <SelectableButton
          selected={domain.subidStrategy === 'universal'}
          onClick={() => {
            onUpdateSettings(domain, { subidStrategy: 'universal' });
          }}
          heading={
            <>
              Universal SubIDs <SelectableChip>Custom setup</SelectableChip>
            </>
          }
          subheading={`Always use the "amcid" query parameter for SubIDs on cloaked links regardless of network.`}
        />
      </SelectableButtonWrapper>
      <br />
      <br />
      {false && (
        <div>
          <Typography
            variant="body1"
            component="p"
            style={{ fontWeight: 'bold' }}
          >
            Clean SubIDs from analytics data
            <Tooltip
              placement="top"
              title="Not ignoring dynamic parameters causes a new link to be generated in your account for each unique value. Provide dynamic parameters to ignore, separated by commas."
            >
              <InfoWrapper>
                <Info size={16} />
              </InfoWrapper>
            </Tooltip>
          </Typography>
          <Typography
            variant="body2"
            component="p"
            color="textSecondary"
            paragraph
          >
            Clean SubIDs record analytics events for links without SubIDs on
            them, but does not strip them from the actual URL. Beneficial for
            consolidating the same product's analytics across articles.{' '}
            <strong>Required if you have your own dynamic SubIDs.</strong>
          </Typography>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={false}
                onChange={(e) => {
                  onUpdateSettings(domain, {
                    urlFormat: e.target.checked ? 'canonical' : 'url'
                  });
                }}
              />
            }
            label="Yes, clean SubIDs from link analytics data"
          />
          <br />
          <br />
        </div>
      )}
      <Typography variant="body1" component="p" style={{ fontWeight: 'bold' }}>
        Canonical URLs
        <Tooltip
          placement="top"
          title="Use this only if your canonical URLs have query parameters in them. Otherwise, this prevents you from using unique URLs and the same canonical for A/B testing."
        >
          <InfoWrapper>
            <Info size={16} />
          </InfoWrapper>
        </Tooltip>
      </Typography>
      <Typography variant="body2" component="p" color="textSecondary" paragraph>
        Should Affilimate track canonical URLs instead of the browser URL?
      </Typography>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={domain.urlFormat === 'canonical'}
            onChange={(e) => {
              onUpdateSettings(domain, {
                urlFormat: e.target.checked ? 'canonical' : 'url'
              });
            }}
          />
        }
        label="Yes, use canonical URLs instead"
      />
      <br />
      {/*
      <div>
      <Typography variant="body1" component="p" gutterBottom>
        <strong>SubID format settings</strong>
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        Customize the format used for the events stored in your SubIDs.
      </Typography>
      </div>
      */}
    </FormControl>
  );
};

const SiteScheduleSettings = ({
  space,
  domain
}: {
  space: ISpace;
  domain: IDomain;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [productScanScheduleDoc] = useProductScanSchedule(space.id);
  const onChange = (schedule: Doc<Schedule>) =>
    updateOrCreateSchedule(schedule.id, schedule.data);

  const onUpdateAutoImport = (d: IDomain) => {
    const { autoImport } = d;
    return updateAutoImportSpace(space, d, autoImport).catch((err) => {
      if (err.message === 'Cannot autoImport an unverified domain') {
        enqueueSnackbar('Verify the domain before enabling auto-import', {
          variant: 'error'
        });
      } else {
        enqueueSnackbar(
          'Could not enable auto-import. Please try again or contact Support.',
          { variant: 'error' }
        );
      }
    });
  };

  return (
    <div>
      <Typography variant="body1" component="p" style={{ fontWeight: 'bold' }}>
        Scan schedule
        <Tooltip
          placement="top"
          title="This setting determines when and how often we'll scan your site for affiliate links and check for version of your pages."
        >
          <span
            style={{
              display: 'inline-block',
              position: 'relative',
              top: '2px',
              right: '-6px'
            }}
          >
            <Info size={16} />
          </span>
        </Tooltip>
      </Typography>
      <Typography variant="body1" component="p" color="textSecondary" paragraph>
        This setting applies to <strong>all domains</strong> in this workspace.
      </Typography>
      {!productScanScheduleDoc ? (
        <Loader size={24} height={98} />
      ) : (
        <ScheduleFormSimple
          label={<>Automatically scan your sites for affiliate links</>}
          doc={productScanScheduleDoc}
          onChange={onChange}
        />
      )}
      <Typography
        variant="body1"
        component="p"
        style={{ fontWeight: 'bold', marginTop: '48px' }}
      >
        Auto-import affiliate links
        <Tooltip
          placement="top"
          title={
            <>
              With this setting, you can allow us to automatically import the
              affiliate links our algorithm finds on your site. Otherwise,
              you'll need to review and improve each set of links before import.
            </>
          }
        >
          <span
            style={{
              display: 'inline-block',
              position: 'relative',
              top: '2px',
              right: '-3px'
            }}
          >
            <Info size={16} />
          </span>
        </Tooltip>
      </Typography>
      <Typography variant="body1" component="p" color="textSecondary" paragraph>
        Automatically import new links found during site scans.
      </Typography>
      <Form
        autoSave={true}
        onSubmit={onUpdateAutoImport}
        initialValues={domain}
      >
        {({ submitting }) => (
          <Row>
            <FormField name="autoImport" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  label={
                    <>
                      Enable auto-import for this website{' '}
                      <strong>(Recommended)</strong>
                    </>
                  }
                  classes={{ root: css(() => ({ marginLeft: 0 })) }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={input.checked}
                      onChange={input.onChange}
                    />
                  }
                />
              )}
            </FormField>
            {submitting && <CircularProgress size={20} />}
          </Row>
        )}
      </Form>
    </div>
  );
};

const UrlSourceSettings = ({
  domain,
  onUpdateSettings
}: {
  domain: IDomain;
  onUpdateSettings: UpdateSettingsFn;
}) => {
  const [loading, setLoading] = useState(false);
  const [sitemaps, setSitemaps] = useState<string>(
    domain.sitemaps !== null ? domain.sitemaps.join('\r\n') : ''
  );
  const [isDefault, setIsDefault] = useState(domain.sitemaps === null);

  const onSaveSitemaps = async (defaultSettings?: boolean) => {
    setLoading(true);
    const sitemapArr = sitemaps.split(/\r?\n/);
    const sitemapVal = isDefault || defaultSettings ? null : sitemapArr;

    try {
      await onUpdateSettings(domain, { sitemaps: sitemapVal });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return Promise.reject(err);
    }
  };

  return (
    <div>
      <Typography variant="body1" component="p" style={{ fontWeight: 'bold' }}>
        Sitemap URLs
      </Typography>
      <Typography variant="body1" component="p" color="textSecondary" paragraph>
        We'll use these sitemaps to efficiently crawl your site for affiliate
        links and track changes to your content.
      </Typography>
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={isDefault}
              onChange={(_) => {
                if (isDefault) {
                  setIsDefault(false);
                } else {
                  setIsDefault(true);
                  onSaveSitemaps(true);
                }
              }}
            />
          }
          label={
            <>
              Use auto-detected sitemaps
              <Tooltip
                placement="top"
                title={
                  <>
                    Leave this switched on to let us auto-detect your sitemap
                    based on your robots.txt file and check sitemaps located at
                    /sitemap.xml.
                    <br />
                    <br />
                    Switch it off to provide one or more custom Sitemap URLs.
                  </>
                }
              >
                <span
                  style={{
                    display: 'inline-block',
                    position: 'relative',
                    top: '2px',
                    right: '-6px'
                  }}
                >
                  <Info size={16} />
                </span>
              </Tooltip>
            </>
          }
        />
      </FormGroup>
      {!isDefault && (
        <div>
          <Typography
            variant="body1"
            component="p"
            style={{ fontWeight: 'bold', marginTop: '24px' }}
          >
            Custom Sitemaps
          </Typography>
          <Typography
            variant="body1"
            component="p"
            color="textSecondary"
            paragraph
          >
            Provide the full URL (including protocol) to your sitemap URLs.
          </Typography>
          <FormGroup row>
            <TextField
              multiline
              rows={5}
              name="sitemap"
              variant="outlined"
              value={sitemaps}
              onChange={(e) => {
                setSitemaps(e.target.value);
              }}
              fullWidth
              placeholder="One sitemap URL per line, including protocol"
            />
          </FormGroup>
          <Button
            variant="contained"
            color="default"
            style={{ marginTop: '12px' }}
            onClick={() => onSaveSitemaps()}
            disabled={sitemaps.length === 0 || loading}
          >
            {loading ? 'Saving...' : 'Save custom sitemaps'}
          </Button>
          <AlertBox variant="background" style={{ marginTop: '24px' }}>
            <HelpIcon articleId="sitemap-compatibility">
              View docs on sitemap formatting
            </HelpIcon>{' '}
            for our compatibility guidelines.
          </AlertBox>
        </div>
      )}
    </div>
  );
};

const LinkSettings = ({
  domain,
  onUpdateSettings
}: {
  domain: IDomain;
  onUpdateSettings: UpdateSettingsFn;
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [redirectUrls, setCustomRedirectUrls] = useState(
    domain.redirectUrls !== null ? domain.redirectUrls.join('\r\n') : ''
  );

  const onSaveRedirectUrls = async () => {
    const arr = redirectUrls.split(/\r?\n/);
    const isUsingMainDomain = arr.some((url) => url === domain.url);

    if (isUsingMainDomain) {
      setError(
        'You cannot redirect to the main domain. Set a redirect format that includes either a custom subdomain or path prefix.'
      );
      return;
    } else {
      setError('');
    }

    setLoading(true);

    try {
      await onUpdateSettings(domain, { redirectUrls: arr });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(
        'An error occurred updating your link settings. Try again our contact support.'
      );
      return Promise.reject(err);
    }
  };

  return (
    <div>
      <AlertBox variant="primary">
        Skip Cloaking Settings if you do not cloak or redirect your affiliate
        links.
      </AlertBox>
      <br />
      <Typography variant="body1" component="p" style={{ fontWeight: 'bold' }}>
        Cloaked link format&nbsp;&nbsp;
        <HelpIcon articleId={ARTICLES.linkCloaker.compatibility} color="blue">
          See required settings
        </HelpIcon>
      </Typography>
      <Typography variant="body1" component="p" color="textSecondary" paragraph>
        We'll use this to detect cloaked links on your site. For example,{' '}
        <u>https://go.yoursite.com</u> or{' '}
        <u>https://yoursite.com/recommends/</u>.<br />
        <br />
        Provide one per line, starting with <strong>https://</strong>.
      </Typography>
      <FormGroup row>
        <TextField
          multiline
          rows={3}
          name="customer-cloaker"
          variant="outlined"
          value={redirectUrls}
          onChange={(e) => {
            setCustomRedirectUrls(e.target.value);
          }}
          fullWidth
          placeholder="One URL per line, including protocol (e.g. https://)"
        />
      </FormGroup>
      <Button
        variant="contained"
        color="default"
        style={{ marginTop: '12px' }}
        disabled={loading}
        onClick={onSaveRedirectUrls}
      >
        {loading ? 'Saving...' : 'Save changes'}
      </Button>
      {error && (
        <AlertBox variant="pending" style={{ marginTop: '24px' }}>
          {error}
        </AlertBox>
      )}
    </div>
  );
};

export const SiteSettings = ({ domain }: { domain: string }) => {
  const currentUser = useCurrentUser();
  const space = currentUser.space;
  const hasScanlessTracking = useFeatureEnabled('SCANLESS_TRACKING');
  const [selectedTab, setSelectedTab] = useState(hasScanlessTracking ? 0 : 1);
  const { addJob } = useBackgroundJobs();
  const [loading, setLoading] = useState(false);

  const spaceDomain = useMemo(() => {
    return currentUser.space.domains.find((d) => d.url === domain);
  }, [currentUser, domain]);

  const onUpdateSiteSettings = async (
    d: IDomain,
    settings: Partial<IDomainUpdateSettingsParams>
  ) => {
    const updatedSettings = {
      autoImport: d.autoImport,
      sitemaps: d.sitemaps,
      redirectUrls: d.redirectUrls,
      urlFormat: d.urlFormat,
      updateMethod: d.updateMethod,
      subidStrategy: d.subidStrategy,
      subIdDefaultPrefix: d.subIdDefaultPrefix,
      subIdMatches: d.subIdMatches,
      ignoreParameters: d.ignoreParameters,
      cleanUrl: d.cleanUrl,
      skimlinksDynamicImport: d.skimlinksDynamicImport,
      selectors: d.selectors,
      trackingIdFormat: d.trackingIdFormat,
      ...settings
    };

    setLoading(true);

    addJob({
      job: async () =>
        await updateSiteSettings(space, d, { ...updatedSettings }),
      onStart: () => {
        return {
          message: (
            <>
              <CircularProgress color="inherit" size={16} /> &nbsp; Updating
              your settings...
            </>
          )
        };
      },
      onSuccess: () => {
        setLoading(false);
        return {
          message: 'Updated your settings successfully.'
        };
      },
      onError: () => {
        setLoading(false);
        return {
          message:
            'There was an error updating your settings. Please try again or contact Support.'
        };
      }
    });
  };

  const onTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  if (!spaceDomain) {
    return <div>Could not find space domain</div>;
  }

  return (
    <div>
      <div>
        <Tabs
          value={selectedTab}
          onChange={onTabChange}
          style={{ marginBottom: '24px' }}
          variant="fullWidth"
        >
          <Tab label="Tracking" />
          <Tab label="Cloaking" />
          <Tab
            style={{ display: hasScanlessTracking ? 'none' : 'block' }}
            label="Schedule"
          />
          <Tab
            style={{ display: hasScanlessTracking ? 'none' : 'block' }}
            label="URL sources"
          />
        </Tabs>
        <div
          style={{
            opacity: !loading ? 1 : 0.5,
            pointerEvents: !loading ? 'initial' : 'none'
          }}
        >
          {selectedTab === 0 && (
            <TrackingSettings
              domain={spaceDomain}
              onUpdateSettings={onUpdateSiteSettings}
            />
          )}
          {selectedTab === 1 && (
            <LinkSettings
              domain={spaceDomain}
              onUpdateSettings={onUpdateSiteSettings}
            />
          )}
          {selectedTab === 2 && (
            <SiteScheduleSettings domain={spaceDomain} space={space} />
          )}
          {selectedTab === 3 && (
            <UrlSourceSettings
              domain={spaceDomain}
              onUpdateSettings={onUpdateSiteSettings}
            />
          )}
        </div>
      </div>
    </div>
  );
};
