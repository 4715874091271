import { MenuItem, Select, SelectProps } from '@material-ui/core';
import React from 'react';
import { ALL_ROLES, RoleId } from '../../../../../domainTypes/permissions';
import { RoleBadge } from './RoleBadge';

type RoleSelectorProps = {
  role: RoleId;
  setRole: React.Dispatch<React.SetStateAction<RoleId>>;
  availableRoles: RoleId[];
} & SelectProps;

export const RoleSelector = ({
  role,
  setRole,
  availableRoles,
  ...otherProps
}: RoleSelectorProps) => {
  return (
    <Select
      id="role"
      name="role"
      value={role}
      onChange={(e) => setRole(e.target.value as RoleId)}
      required
      variant="outlined"
      fullWidth
      {...otherProps}
    >
      {ALL_ROLES.map((r) => (
        <MenuItem value={r} key={r} disabled={!availableRoles.includes(r)}>
          <RoleBadge role={r} showDescription />
        </MenuItem>
      ))}
    </Select>
  );
};
