import styled from '@emotion/styled';
import { Tooltip } from '@material-ui/core';
import { Edit, FileText as IconFileText } from 'react-feather';
import { useDialogState } from '../../../../../../hooks/useDialogState';
import { useHasCurrentUserRequiredScopes } from '../../../../../../services/currentUser';
import { RevisionNoteDialog } from './RevisionNoteDialog';
import { IPageRevision } from '../../../../../../domainTypes/page';

const StyledIconWrapper = styled('span')`
  cursor: pointer;
  position: relative;
  top: 2px;

  svg {
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
  }

  &:hover svg {
    opacity: 1 !important;
  }
`;

export const RevisionNoteButton: React.FC<{
  spaceId: string;
  url: string;
  d: { revision: IPageRevision };
}> = ({ d, spaceId, url }) => {
  const [canEditNotes] = useHasCurrentUserRequiredScopes([
    'revisions.notes.edit'
  ]);

  const { dialogOpen, setDialogOpen } = useDialogState();
  const hasNote = d.revision.note && d.revision.note.length > 0;

  const IconWihTooltip = () => {
    if (hasNote) {
      return (
        <Tooltip title={`Note: ${d.revision.note}`} placement="top">
          <IconFileText
            size={16}
            style={{ marginLeft: 6, opacity: 0.8 }}
            onClick={canEditNotes ? () => setDialogOpen(true) : () => false}
          />
        </Tooltip>
      );
    }
    if (canEditNotes) {
      return (
        <Tooltip title="Add a note to this revision" placement="top">
          <Edit
            size={14}
            style={{ marginLeft: 6, opacity: 0.5 }}
            onClick={() => setDialogOpen(true)}
          />
        </Tooltip>
      );
    }
    return <></>;
  };

  return (
    <span>
      <StyledIconWrapper>
        <IconWihTooltip />
      </StyledIconWrapper>

      <RevisionNoteDialog
        dialogProps={{
          open: dialogOpen,
          onClose: () => {
            setDialogOpen(false);
          }
        }}
        onClose={() => {
          setDialogOpen(false);
        }}
        spaceId={spaceId}
        url={url}
        d={d}
      />
    </span>
  );
};
