import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { getTimeElapsed } from '../../services/time';

export const Stopwatch = ({ start }: { start: moment.Moment }) => {
  const [now, setNow] = useState(moment());
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(moment());
    }, 1000);
    return () => clearInterval(interval);
  });
  return <span>{getTimeElapsed(start, now)}</span>;
};
