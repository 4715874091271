import { compact, min, trim } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import { COUNTRY_NAMES } from '../../../../../domainTypes/country';
import { CurrencyCode } from '../../../../../domainTypes/currency';
import { getTrackingId, ISale } from '../../../../../domainTypes/performance';
import { getExchangeRates } from '../../../../../services/currency';
import { getKnownPartnerForKeyUnsafe } from '../../../../../services/partner';
import { fromMoment } from '../../../../../services/time';
import { toCents } from '../helpers';
import { IFileReportHandler } from '../types';

const toStatus = (status: string, completionDate: Moment, tz: string) => {
  const now = moment.tz(tz);
  const travelDatePassed = completionDate.isSameOrBefore(now);
  if (status === 'Confirmed' && !travelDatePassed) {
    return 'Pending';
  }
  if (['Confirmed', 'Amended'].indexOf(status) !== -1 && travelDatePassed) {
    return 'Final';
  }
  if (status === 'Pending' || status === 'Amended') {
    return 'Pending';
  }
  if (status === 'Canceled') {
    return 'Cancelled';
  }
  if (status === 'Rejected') {
    return 'Rejected';
  }
  return 'Unknown';
};

const dateFormat = 'MMM D, YYYY';
const exchangeDateFormat = 'YYYY-MM-DD';

export const VIATOR: IFileReportHandler<string> = {
  type: 'CSV',
  partnerKey: 'viator',
  parser: {
    name: 'Viator Report',
    parseOptions: {
      delimiter: ',',
      quoteChar: '"'
    },
    csvHeaders:
      '"Booking reference","Booking date","Travel date","Booking/Travel date interval (Days)","Product code","Product name","Status","Payment status","Customer country","Total travellers","Medium","Campaign","Total amount (Customer currency)","Commission (Payout currency)"',
    matches: (text, expectedHeaders) => text.indexOf(expectedHeaders) === 0,
    processRows: async (
      rows,
      { space, partnerKey, reportId, integrationId }
    ) => {
      const tz = 'UTC';
      const dataRows = rows.slice(1);
      const [, startReportDate] = dataRows[0];
      const [, endReportDate] = dataRows[dataRows.length - 1];
      const TODAY = moment.tz(tz).format(exchangeDateFormat);
      const YESTERDAY = moment
        .tz(tz)
        .subtract(1, 'day')
        .format(exchangeDateFormat);

      // Need to do manual currency conversion on the original sale
      // in case the sale currency and commission currency are different
      const startDateMoment = moment.tz(startReportDate, dateFormat, tz);
      const endDateMoment = moment.tz(endReportDate, dateFormat, tz);
      const dateDiff = startDateMoment.diff(endDateMoment, 'days');

      // Assure we get at least 5 days of data in case there are gaps
      if (dateDiff < 5) {
        startDateMoment.subtract(5, 'days');
      }

      const startDate = startDateMoment.format('YYYY-MM-DD');
      const endDate = moment
        .tz(endReportDate, dateFormat, tz)
        .format('YYYY-MM-DD');

      const publisherCurrency: CurrencyCode = (() => {
        for (const row of dataRows) {
          const [, , , , , , , , , , , , , commission] = row;
          if (new RegExp(/([A-Z]){3} /).test(commission)) {
            return commission.substr(0, 3);
          }
        }
      })();

      if (!publisherCurrency) {
        throw Error(
          'Could not get publisher currency as all rows are Pending. Please upload a longer report'
        );
      }

      // Use the publisher's payout currency as base currency
      const exchangeRates = await getExchangeRates(
        publisherCurrency,
        startDate,
        endDate
      );

      const result = compact(
        dataRows.map((row) => {
          if (!row.length) {
            return undefined;
          }

          const [
            saleId,
            transactionDate,
            travelDate,
            // eslint-disable-next-line
            bookingTravelDateInterval,
            partnerProductId,
            partnerProductName,
            status,
            // eslint-disable-next-line
            paymentStatus,
            // eslint-disable-next-line
            customerCountry,
            quantity,
            // eslint-disable-next-line
            medium,
            campaign,
            saleAmount,
            commissionAmount
          ] = (() => {
            if (row.length !== 13) {
              if (row[4].indexOf(',"') !== -1) {
                const [prodName, prodStatus] = row[4].split(',"');
                const r = [...row];
                r[4] = prodName;
                r.splice(5, 0, prodStatus);
                return r;
              }
            }

            return row;
          })();

          const saleDate = moment.tz(transactionDate, dateFormat, tz);
          const formattedSaleDate = saleDate.format(exchangeDateFormat);
          const completionDate = moment.tz(travelDate, dateFormat, tz);
          const exchangeDate =
            formattedSaleDate === TODAY ? YESTERDAY : saleDate;

          // Find the closest available date to gather exchange rates from
          const allDates = Object.keys(exchangeRates.rates).sort((a, b) => {
            return (
              moment.tz(a, exchangeDateFormat, tz).unix() -
              moment.tz(b, exchangeDateFormat, tz).unix()
            );
          });

          const distances = allDates.map((d) =>
            Math.abs(
              moment.tz(d, exchangeDateFormat, tz).diff(exchangeDate, 'days')
            )
          );
          const shortestDistance = min(distances);
          const smallestDistanceIndex = distances.find(
            (d) => d === shortestDistance
          )!;
          const dateToUse = allDates[smallestDistanceIndex];
          const dayRates = exchangeRates.rates[dateToUse];
          const saleCurrency: CurrencyCode = saleAmount.substr(0, 3);
          const rate = dayRates[saleCurrency];

          const originalAmountForeignCurrency = toCents(
            trim(saleAmount.substr(3, saleAmount.length))
          );

          const salesStatus = toStatus(status, completionDate, tz);
          const multiplier = status === 'Refunded' ? -1 : 1;
          const originalPriceInCustomerCurrency =
            originalAmountForeignCurrency / rate;
          const price = Math.round(
            originalPriceInCustomerCurrency * multiplier
          );
          let commission = 0;
          try {
            commission = toCents(
              commissionAmount.replace(`${publisherCurrency} `, '')
            );
          } catch (err) {
            console.log(row);
            throw err;
          }

          const commissionPercent = commission / price;
          const label = campaign || '';

          const sale: ISale = {
            saleId,
            orderId: saleId,
            trackingId: getTrackingId(label, space),
            trackingLabel: label,
            reportId,
            integrationId,
            origin: null,
            saleDate: fromMoment(saleDate),
            completionDate: fromMoment(completionDate),
            status: salesStatus,
            partnerKey,
            partnerProductName,
            partnerProductId,
            quantity: parseInt(quantity),
            payoutId: null,
            payoutDate: null,
            payoutStatus: null,
            lastModified: null,
            coupon: '',
            saleType: 'cpa',
            amount: {
              currency: publisherCurrency,
              price,
              revenue: null,
              commission
            },
            commissionPercent,
            advertiserId: partnerKey,
            advertiserName: getKnownPartnerForKeyUnsafe(partnerKey).name,
            metadata: {
              customerCountry: COUNTRY_NAMES[customerCountry]
            }
          };

          return sale;
        })
      );
      console.log(result);
      return result;
    }
  }
};
