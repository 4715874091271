import React from 'react';
import { getPathName } from '../../services/pages';
import { CopyButton } from '../CopyButton';
import { LinkExternal } from '../../admin/components/LinkExternal';

export const LinkTruncated = ({
  url,
  length = 50,
  copy = true,
  usePathName = false
}: {
  url: string;
  length?: number;
  copy?: boolean;
  usePathName?: boolean;
}) => {
  const u = usePathName ? getPathName(url) : url;
  return (
    <>
      <LinkExternal href={url}>
        {u.substr(0, length)}
        {u.length > length && `...`}
      </LinkExternal>
      {copy && <CopyButton text={url} />}
    </>
  );
};
