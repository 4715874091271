import React, { useContext, useEffect, useMemo, useState } from 'react';
import { EarningsResp, IPostgresSale } from '../../domainTypes/performance';

type SalesCache = {
  distinctValues: {
    [checksum: string]: Promise<string[]>;
  };
  earnings: {
    [checksum: string]: Promise<EarningsResp>;
  };
  sales: {
    entities: { [id: string]: IPostgresSale };
    queries: { [checksum: string]: Promise<string[]> };
  };
};
let CACHE: {
  [spaceId: string]: SalesCache;
} = {};

const createCache = (): SalesCache => ({
  distinctValues: {},
  earnings: {},
  sales: {
    entities: {},
    queries: {}
  }
});

export const getSalesCacheforSpace = (spaceId: string) => {
  return (CACHE[spaceId] = CACHE[spaceId] || createCache());
};

const listeners: (() => void)[] = [];
const listenToCacheFlushes = (listener: () => void) => {
  listeners.push(listener);
  return () => {
    const index = listeners.indexOf(listener);
    if (index !== -1) {
      listeners.splice(index, 1);
    }
  };
};

export const flushSalesCacheForSpace = (spaceId: string) => {
  delete CACHE[spaceId];
  listeners.forEach((l) => l());
};

// There's a case to be made for this to be more general
// than just sales - especially once other data is going
// to live in PG, but let's see.
export const SalesCacheContext = React.createContext<{
  version: number;
  flush: (spaceId: string) => void;
}>({
  version: Date.now(),
  flush: () => null
});

export const SalesCacheProvider: React.FC = ({ children }) => {
  const [version, setVersion] = useState(Date.now());
  useEffect(() => listenToCacheFlushes(() => setVersion(Date.now())));
  const value = useMemo(() => ({ version, flush: flushSalesCacheForSpace }), [
    version
  ]);
  return (
    <SalesCacheContext.Provider value={value}>
      {children}
    </SalesCacheContext.Provider>
  );
};

export const useSalesCacheContext = () => {
  return useContext(SalesCacheContext);
};
