import { Tooltip } from '@material-ui/core';
import React from 'react';
import { InlineLoader } from '../../../../components/Loader';
import { styled } from '../../../../emotion';
import { COLORS } from '../../../../domainTypes/colors';

const Container = styled<'div', { bgColor: string }>('div')`
  padding: ${(p) => p.theme.spacing(0.5)}px ${(p) => p.theme.spacing(1.5)}px;
  border-radius: ${(p) => p.theme.custom.border.radius(3)}px;
  font-size: ${(p) => p.theme.custom.fontSize.m}px;

  background-color: ${(p) => p.bgColor};

  display: flex;
  justify-content: space-between;
  align-items: baseline;

  gap: ${(p) => p.theme.spacing()}px;
  margin-top: ${(p) => p.theme.spacing(0.5)}px;
`;

const Label = styled('div')``;
const Value = styled('div')`
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
  min-width: 30px;
  text-align: center;
`;
const Diff = styled<'div', { color: string }>('div')`
  color: ${(p) => p.color};
  font-size: ${(p) => p.theme.custom.fontSize.s}px;
`;
const Loading = styled('div')`
  min-width: 80px;
  text-align: center;
`;

const COLOR_SET = {
  green: {
    color: COLORS.green.green8,
    bgColor: COLORS.green.green2
  },
  yellow: {
    color: COLORS.gold.gold8,
    bgColor: COLORS.gold.gold2
  },
  gray: {
    color: 'inherit',
    bgColor: '#ebebeb'
  }
};

export const StatChip = ({
  label,
  tooltip,
  value,
  diff,
  color,
  loading
}: {
  label: React.ReactNode;
  tooltip: string;
  value: React.ReactNode;
  diff: React.ReactNode;
  color: 'green' | 'yellow' | 'gray';
  loading: boolean;
}) => {
  const colors = COLOR_SET[color];
  return (
    <Tooltip title={tooltip}>
      <Container bgColor={loading ? COLOR_SET.gray.bgColor : colors.bgColor}>
        <Label>{label}</Label>
        {!loading && <Value>{value}</Value>}
        {!loading && <Diff color={colors.color}>{diff}</Diff>}
        {loading && (
          <Loading>
            <InlineLoader size={10} color="inherit" />
          </Loading>
        )}
      </Container>
    </Tooltip>
  );
};
