import {
  queryParamToList,
  setToQueryParam,
  useQueryParam
} from '../../../routes';
import { AnalyticsFilterIn } from '../../../domainTypes/analytics_v2';
import React, { useCallback, useMemo, useState } from 'react';
import {
  IPostgresTagsParent,
  useTagsForCurrentUser
} from '../../../services/tags';
import { IPostgresTags } from '../../../domainTypes/tags';
import { isEmpty, isNil, keyBy, sortBy, take, trim } from 'lodash';
import { ButtonBase } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { LoadingValue } from '../../../services/db';
import { Truncated } from '../../Truncated';
import { Selector, SelectorChip, SelectorMenu } from '../Selector';
import { FlexContainer } from '../../../layout/Flex';
import { DisabledSelectorChip } from '../../SelectorChip';

const useTagsState = () =>
  useQueryParam(
    'tags',
    (p) => new Set(p ? queryParamToList(p) : []),
    setToQueryParam
  );

const TOP_N = 20;

function createLoadedValue<T>(value: T): LoadingValue<T> {
  return [value, false, null];
}

interface InnerTagsSelectorProps {
  allTags: Array<IPostgresTags>;
  tagGroup?: IPostgresTags;
}

const InnerTagsSelector: React.FC<InnerTagsSelectorProps> = ({
  allTags,
  tagGroup
}) => {
  const [selectedTagIds, selectTagIds] = useTagsState();
  const [search, setSearch] = useState('');

  const tags = useMemo(() => {
    if (isNil(tagGroup)) return allTags.filter((t) => t.parent_id !== null);
    const { id } = tagGroup;
    return allTags.filter((t) => t.parent_id === id);
  }, [allTags, tagGroup]);
  const tagsById = useMemo(() => keyBy(allTags, (t) => t.id), [allTags]);

  const filtered = useMemo(() => {
    return isEmpty(search)
      ? tags
      : tags.filter((t) =>
          t.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        );
  }, [search, tags]);

  const hasMoreOptions = filtered.length > TOP_N;

  const data = useMemo<LoadingValue<IPostgresTags[]>>(() => {
    return createLoadedValue(
      take(
        sortBy(filtered, (t) => trim(t.name)),
        TOP_N
      )
    );
  }, [filtered]);

  const renderOption = useCallback(
    (t: IPostgresTags) => {
      if (!isNil(tagGroup)) return <Truncated title={t.name} />;
      return (
        <FlexContainer direction="column" alignItems="flex-start" spacing={0}>
          <Truncated title={t.name} />
          <Typography variant="caption" color="textSecondary">
            {tagsById[t.parent_id!].name}
          </Typography>
        </FlexContainer>
      );
    },
    [tagGroup, tagsById]
  );

  return (
    <Selector
      onClose={() => setSearch('')}
      trigger={
        <SelectorChip
          selectedValues={selectedTagIds}
          label="Tags"
          getValue={(id) => tagsById[id].name}
          onDelete={() => selectTagIds(new Set())}
        />
      }
      menu={
        <SelectorMenu
          data={data}
          getId={(t) => t.id}
          selectedValues={selectedTagIds}
          label="Tags"
          renderOption={renderOption}
          selectValues={selectTagIds}
          setSearch={setSearch}
          search={search}
          hasMoreOptions={hasMoreOptions}
        />
      }
    />
  );
};

interface TagsSelectorProps {
  tagGroup?: IPostgresTags;
}

export const TagsSelector: React.FC<TagsSelectorProps> = ({ tagGroup }) => {
  const [allTags, loadingTags] = useTagsForCurrentUser();

  if (!allTags || loadingTags) {
    return (
      <ButtonBase disabled={true}>
        <DisabledSelectorChip label="Tags" />
      </ButtonBase>
    );
  }

  return (
    <InnerTagsSelector
      key={
        /* NOTE: force to rerender everything after changing tagGroup */
        tagGroup?.id ?? '__ALL_TAGS__'
      }
      allTags={allTags}
      tagGroup={tagGroup}
    />
  );
};

const filterTagsByGroup = (
  tagIds: Set<string>,
  tagGroup?: IPostgresTagsParent
): string[] => {
  if (isNil(tagGroup)) return [...tagIds];
  const groupIds = tagGroup.children.map((t) => t.id);
  return [...tagIds].filter((t) => groupIds.includes(t));
};

/*
  NOTE: Without any tags in filter selection, this just skips filter by tags clause.
 */
export const useTagsFilter = (
  tagGroup?: IPostgresTagsParent
): AnalyticsFilterIn | null => {
  const [tags] = useTagsState();
  return useMemo(() => {
    if (tags.size === 0) return null;
    return {
      field: 'tags',
      condition: 'in',
      values: filterTagsByGroup(tags, tagGroup)
    };
  }, [tagGroup, tags]);
};

/*
  NOTE: This filter omits rows without any tags. 
  Useful if you want to groupBy tags in your query.
 */
export const useNonEmptyTagsFilter = (
  tagGroup?: IPostgresTagsParent
): AnalyticsFilterIn => {
  const [tags] = useTagsState();
  return useMemo(() => {
    const values = filterTagsByGroup(tags, tagGroup);
    if (values.length === 0) {
      // If we don't have any tags in filter, add non-empty clause.
      return {
        field: 'tags',
        condition: 'not in',
        values: ['']
      };
    } else {
      return {
        field: 'tags',
        condition: 'in',
        values
      };
    }
  }, [tagGroup, tags]);
};
