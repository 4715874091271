import React from 'react';
import { InlineLoader } from '../../../../../components/Loader';
import { formatCurrency } from '../../../../../components/Number';
import { RAlign } from '../../../../../components/Typography';
import { CurrencyCode } from '../../../../../domainTypes/currency';
import { styled } from '../../../../../emotion';

const ColoredDiff = styled<'div', { n: number }>('div')`
  color: ${(p) => {
    if (p.n === 0) {
      return p.theme.custom.colors.pending.main;
    }
    if (p.n > 0) {
      return p.theme.custom.colors.success.main;
    }
    return p.theme.custom.colors.error.main;
  }};
`;

const EarningsSummaryHeaderContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: ${(p) => p.theme.spacing(1)}px;
`;

const EarningsSummaryHeaderTotal = styled('div')`
  margin-top: ${(p) => p.theme.spacing(1)}px;
  font-size: ${(p) => p.theme.custom.fontSize.xl}px;
`;

const EarningsSummaryHeaderDiff = styled(ColoredDiff)`
  font-size: ${(p) => p.theme.custom.fontSize.m}px;
`;

const EarningsSummaryRowValue = styled(RAlign)`
  font-weight: 600;
`;

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: ${(p) => p.theme.spacing(1)}px;
  color: #9b9b9b;
  font-size: ${(p) => p.theme.custom.fontSize.m}px;
`;

const EarningsSummaryRow = ({
  curr,
  currency,
  loading,
  label
}: {
  curr: number | void;
  currency: CurrencyCode;
  loading: boolean;
  label: string;
}) => {
  return (
    <>
      <div>{label}</div>
      <EarningsSummaryRowValue>
        {loading ? <InlineLoader /> : formatCurrency(curr || 0, currency)}
      </EarningsSummaryRowValue>
    </>
  );
};

const EarningsSummaryHeader = ({
  prev,
  curr,
  currency,
  loading
}: {
  prev: number | null;
  curr: number;
  currency: CurrencyCode;
  loading: boolean;
}) => {
  return (
    <EarningsSummaryHeaderContainer>
      <EarningsSummaryHeaderTotal>
        {loading ? <InlineLoader /> : formatCurrency(curr, currency)}
      </EarningsSummaryHeaderTotal>
      {prev !== null && (
        <EarningsSummaryHeaderDiff n={curr - prev}>
          {!loading && formatCurrency(curr - prev, currency, true)}
        </EarningsSummaryHeaderDiff>
      )}
    </EarningsSummaryHeaderContainer>
  );
};

export type EarningsSummaryData = {
  tf: {
    curr: number;
    prev: number | null;
    loading: boolean;
  };
  yesterday: {
    curr: number;
    loading: boolean;
  };
  monthToDate: {
    curr: number;
    loading: boolean;
  };
  lastMonth: {
    curr: number;
    loading: boolean;
  };
};

export const EarningsSummary = ({
  data,
  currency
}: {
  data: EarningsSummaryData;
  currency: CurrencyCode;
}) => {
  const rows: React.ComponentProps<typeof EarningsSummaryRow>[] = [
    {
      label: 'Yesterday',
      currency,
      ...data.yesterday
    },
    {
      label: 'Month to date',
      currency,
      ...data.monthToDate
    },
    {
      label: 'Last month',
      currency,
      ...data.lastMonth
    }
  ];

  return (
    <>
      <EarningsSummaryHeader
        prev={data.tf.prev}
        curr={data.tf.curr}
        loading={data.tf.loading}
        currency={currency}
      />
      <Grid>
        {rows.map((r) => (
          <EarningsSummaryRow key={r.label} {...r} />
        ))}
      </Grid>
    </>
  );
};
