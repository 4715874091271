import { Card, Tooltip } from '@material-ui/core';
import { truncate } from 'lodash';
import React, { createElement } from 'react';
import {
  Award,
  DollarSign,
  ExternalLink,
  Icon,
  Percent,
  ShoppingBag
} from 'react-feather';
import { Link } from 'react-router-dom';
import {
  formatChartCurrency,
  formatChartNumber
} from '../../../../../../../components/Charts/Util';
import { Loader } from '../../../../../../../components/Loader';
import {
  Currency,
  formatCurrency,
  Number
} from '../../../../../../../components/Number';
import { CurrencyCode } from '../../../../../../../domainTypes/currency';
import { styled } from '../../../../../../../emotion';
import { Centered } from '../../../../../../../layout/Centered';
import { useRoutes } from '../../../../../../../routes';
import { LoadingValueLike } from '../../../../../../../services/db';
import { getPathname } from '../../../../../../../services/url';
import {
  AdvertiserGrouper,
  earningsMinimalFieldtoEnglish,
  productGrouperToEnglish
} from '../../../../../services/advertisers';
import { SortByKey } from '../types';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

const StyledA = styled('a')`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const Grid = styled('div')`
  display: grid;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing(2)}px;
`;

const Paragraph = styled('p')`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.grey[600]};

  strong {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  text-align: center;
  margin: 0 auto;
  border-radius: 100%;
`;

const GridCard = styled(Card)`
  display: grid;
  grid-template-columns: 36px 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing(2)}px;
  align-items: center;
`;

export const AdvertiserSummaryCardLoader = () => {
  return (
    <Centered>
      <Loader height={12 * 8} size={24} />
    </Centered>
  );
};

export const AdvertiserSummaryCardLoaderCard = () => {
  return (
    <Card>
      <AdvertiserSummaryCardLoader />
    </Card>
  );
};

export const AdvertiserSummaryCardIcon = ({ icon }: { icon: Icon }) => {
  return (
    <IconWrapper style={{ color: '#888', backgroundColor: '#EEE' }}>
      {createElement(icon, { size: 16 })}
    </IconWrapper>
  );
};

export const AdvertiserSummaryCardTotalsContent = ({
  commission_sum_net,
  gmv_sum_net,
  order_count_net,
  quantity_net,
  currency,
  advertiserName
}: {
  commission_sum_net: number;
  gmv_sum_net: number;
  order_count_net: number;
  quantity_net: number;
  currency: CurrencyCode;
  advertiserName: string;
}) => {
  return (
    <Paragraph>
      You earned{' '}
      <Tooltip
        title={formatCurrency(commission_sum_net, currency)}
        placement="top"
      >
        <strong>{formatChartCurrency(commission_sum_net, currency)}</strong>
      </Tooltip>{' '}
      {gmv_sum_net > 0 && (
        <>
          and drove{' '}
          <Tooltip
            title={formatCurrency(gmv_sum_net, currency)}
            placement="top"
          >
            <strong>{formatChartCurrency(gmv_sum_net, currency)}</strong>
          </Tooltip>{' '}
          in sales volume{' '}
        </>
      )}
      from{' '}
      {order_count_net > 0 && (
        <>
          <Tooltip title={`${order_count_net} orders`} placement="top">
            <strong>{formatChartNumber(order_count_net)} orders</strong>
          </Tooltip>{' '}
          and{' '}
        </>
      )}
      <Tooltip title={`${quantity_net} quantity sold`} placement="top">
        <strong>{formatChartNumber(quantity_net)} quantity sold</strong>
      </Tooltip>{' '}
      for {advertiserName} during this timeframe.
    </Paragraph>
  );
};

export const AdvertiserSummaryCardEffectiveCommissionRateContent = ({
  commission_sum_net,
  gmv_sum_net,
  order_count_net,
  currency,
  advertiserName
}: {
  commission_sum_net: number;
  gmv_sum_net: number;
  order_count_net: number;
  currency: CurrencyCode;
  advertiserName: string;
}) => {
  return (
    <Paragraph>
      Your <strong>effective commission rate</strong> with {advertiserName} in
      this timeframe is{' '}
      <strong>
        <Number
          n={commission_sum_net / gmv_sum_net}
          format="percent"
          digits={1}
        />
      </strong>
      {commission_sum_net !== 0 && order_count_net === 0 ? (
        // This is for amazon, for which we don't have order counts
        '.'
      ) : (
        <>
          {' '}
          with an average commission of{' '}
          <strong>
            <Currency
              cents={commission_sum_net / order_count_net}
              currency={currency}
            />
          </strong>{' '}
          per order.
        </>
      )}
    </Paragraph>
  );
};

export const AdvertiserSummaryCardAverageCommissionRateContent = ({
  commission_sum_net,
  order_count_net,
  currency,
  advertiserName
}: {
  commission_sum_net: number;
  order_count_net: number;
  currency: CurrencyCode;
  advertiserName: string;
}) => {
  return (
    <Paragraph>
      Your <strong>average commission</strong> per order from {advertiserName}{' '}
      in this timeframe is{' '}
      <strong>
        <Currency
          cents={commission_sum_net / order_count_net}
          currency={currency}
        />
      </strong>
      .
    </Paragraph>
  );
};

export const AdvertiserSummaryCardBestProducAndPageContent = ({
  topProduct,
  topPage,
  groupBy,
  orderBy,
  currency
}: {
  topProduct: {
    name: string;
    commission_sum_net: number;
  } | null;
  topPage: {
    url: string;
    commission_sum_net: number;
  } | null;
  groupBy: AdvertiserGrouper;
  orderBy: SortByKey;
  currency: CurrencyCode;
}) => {
  const { ROUTES } = useRoutes();
  return (
    <Paragraph>
      {topProduct && (
        <>
          <Tooltip title={topProduct.name} placement="top">
            <strong>{truncate(topProduct.name, { length: 50 })}</strong>
          </Tooltip>{' '}
          was your top{' '}
          <strong>{productGrouperToEnglish(groupBy).toLowerCase()}</strong>{' '}
          {orderBy !== 'ct' && (
            <>
              by{' '}
              <strong>
                {earningsMinimalFieldtoEnglish(orderBy).toLowerCase()}
              </strong>{' '}
            </>
          )}
          resulting in earnings of{' '}
          <Tooltip
            title={formatCurrency(topProduct.commission_sum_net, currency)}
            placement="top"
          >
            <strong>
              {formatChartCurrency(topProduct.commission_sum_net, currency)}
            </strong>
          </Tooltip>
          .
        </>
      )}{' '}
      {topPage && (
        <>
          Your best page by{' '}
          {earningsMinimalFieldtoEnglish(orderBy).toLowerCase()} is{' '}
          <Tooltip title={topPage.url} placement="top">
            <StyledLink to={ROUTES.content.details.trends.url(topPage.url)}>
              {truncate(getPathname(topPage.url), { length: 50 })}
            </StyledLink>
          </Tooltip>{' '}
          <Tooltip title="Open in a new tab" placement="bottom">
            <StyledA
              href={topPage.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ExternalLink size={14} />
            </StyledA>
          </Tooltip>
        </>
      )}
    </Paragraph>
  );
};

export const AdvertiserSummaryCardsV2 = ({
  totalsLv,
  bestPageLv,
  bestGroupLv,
  groupBy,
  orderBy,
  currency,
  advertiserNames
}: {
  totalsLv: LoadingValueLike<{
    commission_sum_net: number;
    gmv_sum_net: number;
    order_count_net: number;
    quantity_net: number;
  }>;
  bestPageLv: LoadingValueLike<{
    url: string;
    commission_sum_net: number;
  } | null>;
  bestGroupLv: LoadingValueLike<{
    name: string;
    commission_sum_net: number;
  } | null>;
  groupBy: AdvertiserGrouper;
  orderBy: SortByKey;
  currency: CurrencyCode;
  advertiserNames: string[];
}) => {
  const advertiserName = truncate(advertiserNames.join(', '), { length: 100 });
  const totalsD = totalsLv[0];
  const bestPageD = bestPageLv[0];
  const bestGroupD = bestGroupLv[0];

  return (
    <Grid>
      <GridCard>
        <AdvertiserSummaryCardIcon icon={DollarSign} />
        {totalsD ? (
          <AdvertiserSummaryCardTotalsContent
            advertiserName={advertiserName}
            currency={currency}
            commission_sum_net={totalsD.commission_sum_net || 0}
            gmv_sum_net={totalsD.gmv_sum_net || 0}
            order_count_net={totalsD.order_count_net || 0}
            quantity_net={totalsD.quantity_net || 0}
          />
        ) : (
          <AdvertiserSummaryCardLoader />
        )}
      </GridCard>
      <GridCard>
        <AdvertiserSummaryCardIcon icon={ShoppingBag} />
        {bestPageD && bestGroupD ? (
          <AdvertiserSummaryCardBestProducAndPageContent
            topProduct={bestGroupD}
            topPage={bestPageD}
            groupBy={groupBy}
            orderBy={orderBy}
            currency={currency}
          />
        ) : (
          <AdvertiserSummaryCardLoader />
        )}
      </GridCard>
      <GridCard>
        <AdvertiserSummaryCardIcon
          icon={totalsD && totalsD.gmv_sum_net > 0 ? Percent : Award}
        />
        {totalsD ? (
          totalsD.gmv_sum_net > 0 ? (
            <AdvertiserSummaryCardEffectiveCommissionRateContent
              advertiserName={advertiserName}
              commission_sum_net={totalsD.commission_sum_net}
              gmv_sum_net={totalsD.gmv_sum_net}
              order_count_net={totalsD.order_count_net}
              currency={currency}
            />
          ) : (
            <AdvertiserSummaryCardAverageCommissionRateContent
              advertiserName={advertiserName}
              commission_sum_net={totalsD.commission_sum_net}
              order_count_net={totalsD.order_count_net}
              currency={currency}
            />
          )
        ) : (
          <AdvertiserSummaryCardLoader />
        )}
      </GridCard>
    </Grid>
  );
};
