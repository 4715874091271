import { ListSubheader } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { isEmpty, mapValues, pickBy } from 'lodash';
import React, { useMemo } from 'react';
import { css } from '../../../../emotion';
import { Dimension, useDimensionNameFn, useDimensionTitle } from '../filters';
import { dimensionsTree, DimensionsTree, FilterTreeCategory } from '../tree';
import { FilterDrawerRenderContext } from './types';

const matches = (name: string, terms: string[], search: string) => {
  if (search === '') return true;
  const normalizedSearch = search.toLocaleLowerCase();
  return (
    name.includes(normalizedSearch) ||
    terms.some((term) => term.toLocaleLowerCase().includes(normalizedSearch))
  );
};

export const filterDimensionsTree = (
  tree: DimensionsTree,
  availableDimensions: Dimension[],
  search: string,
  dimensionName: (d: Dimension) => string
): DimensionsTree => {
  // NOTE: dunno why we need so many type assertions there
  return pickBy(
    mapValues<DimensionsTree, FilterTreeCategory>(
      tree,
      (category: FilterTreeCategory) => ({
        ...category,
        options: category.options.filter((def) => {
          if (!availableDimensions.includes(def.dimension)) return false;
          return matches(
            dimensionName(def.dimension).toLocaleLowerCase(),
            def.terms,
            search
          );
        })
      })
    ),
    (c) => c.options.length > 0
  ) as DimensionsTree;
};

interface DimensionListProps {
  availableDimensions: Dimension[];
  selectDimension: (f: Dimension) => void;
  search: string;
  renderContext?: FilterDrawerRenderContext;
}

const DimensionTitle = ({ dimension }: { dimension: Dimension }) => {
  const title = useDimensionTitle(dimension);

  return <ListItemText>{title}</ListItemText>;
};

export const DimensionList: React.FC<DimensionListProps> = ({
  selectDimension,
  availableDimensions,
  search
  // renderContext
}) => {
  const dimensionName = useDimensionNameFn();
  const tree = useMemo<DimensionsTree>(() => {
    return filterDimensionsTree(
      dimensionsTree,
      availableDimensions,
      search,
      dimensionName
    );
  }, [availableDimensions, dimensionName, search]);

  const filters = Object.values(tree);

  if (filters.length === 0) {
    return (
      <Typography
        variant="caption"
        color="textSecondary"
        style={{ padding: 10, marginBottom: 12 }}
        component="p"
      >
        No filters found.
        {isEmpty(search) ? null : ' Try a different search term.'}
      </Typography>
    );
  }
  return (
    <List>
      {filters.map(({ options, label }) => {
        return (
          <React.Fragment key={label}>
            <ListSubheader
              className={css((t) => ({
                backgroundColor: t.palette.background.paper,
                fontWeight: 'bold',
                color: '#AAA'
              }))}
            >
              {label}
            </ListSubheader>
            {options.map((filter) => {
              return (
                <ListItem
                  key={filter.dimension}
                  button
                  onClick={() => selectDimension(filter.dimension)}
                >
                  <DimensionTitle dimension={filter.dimension} />
                </ListItem>
              );
            })}
          </React.Fragment>
        );
      })}
    </List>
  );
};
