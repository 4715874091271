import React from 'react';
import { Typography, ButtonBase, Button } from '@material-ui/core';
import { styled } from '../../../emotion';
import { Platform } from '..';
import { onlyHostname } from '../../../services/url';
import { ChevronRight } from 'react-feather';
import { useFeatureEnabled } from '../../../services/features';

const PlatformContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(2)}px;
  grid-row-gap: ${(p) => p.theme.spacing(2)}px;
  margin: ${(p) => p.theme.spacing(3)}px auto ${(p) => p.theme.spacing(6)}px;
`;

const PlatformButton = styled(ButtonBase)`
  position: relative;
  display: grid !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
  border-radius: ${(p) => p.theme.shape.borderRadius}px !important;
  padding: ${(p) => p.theme.spacing(3)}px !important;
  border: 1px solid ${(p) => p.theme.palette.grey[200]} !important;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.04);
    border: 1px solid ${(p) => p.theme.palette.primary.main} !important;
  }

  img {
    max-height: 28px !important;
    width: 100%;
  }
`;

const Body = styled('div')`
  text-align: center;
  margin: ${(p) => p.theme.spacing() * 2}px;

  a,
  a:visited,
  a:hover {
    color: ${(p) => p.theme.palette.primary.main};
    text-decoration: underline;
  }
`;

export const ChoosePlatformStep = ({
  domain,
  onClick
}: {
  domain?: string;
  onClick: (platform: Platform) => void;
}) => {
  const url = domain ? onlyHostname(domain) : '';
  const headline = domain ? `Start tracking ${url}` : `Start tracking now`;
  const hasChannels = useFeatureEnabled('CHANNELS');

  return (
    <Body>
      <Typography paragraph variant="h5" style={{ margin: '0 auto 12px' }}>
        {headline}
      </Typography>
      <Typography
        variant="body1"
        component="p"
        style={{ maxWidth: '400px', margin: '0 auto 12px' }}
      >
        Choose your platform to start tracking your website with Affilimate in
        under 5 minutes:
      </Typography>
      <PlatformContainer>
        <PlatformButton
          onClick={() => {
            onClick(Platform.WordPress);
          }}
        >
          <img src="/images/logos/wordpress.svg" alt="WordPress" />
        </PlatformButton>
        <PlatformButton
          onClick={() => {
            onClick(Platform.GTM);
          }}
        >
          <img
            src="/images/logos/google-tag-manager.svg"
            alt="Google Tag Manager"
          />
        </PlatformButton>
        <PlatformButton
          onClick={() => {
            onClick(Platform.Squarespace);
          }}
        >
          <img
            src="/images/logos/squarespace.svg"
            alt="Squarespace"
            style={{ width: '75%', margin: '0 auto' }}
          />
        </PlatformButton>
        <PlatformButton
          onClick={() => {
            onClick(Platform.Other);
          }}
        >
          <Typography variant="body1" component="p">
            Other
          </Typography>
        </PlatformButton>
      </PlatformContainer>
      {hasChannels && (
        <Button
          variant="text"
          style={{ width: '80%', margin: '0 auto' }}
          onClick={() => {
            onClick(Platform.Offsite);
          }}
        >
          Track an offsite channel <ChevronRight size={16} />
        </Button>
      )}
    </Body>
  );
};
