import { compact, difference, groupBy, isEqual, keys } from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useMemo, useState } from 'react';
import {
  AnalyticsFilter,
  AnalyticsQuery,
  ISOTimeRange
} from '../../../../domainTypes/analytics_v2';
import { ChannelFilterDef } from '../../../../domainTypes/filters';
import { useChannelIdGrouper } from '../../../../services/analyticsV2/groups';
import { Metric, metricName } from '../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import {
  Channel,
  channelKindLabel,
  channelLabel,
  getChannel,
  useSpaceChannels
} from '../../../../services/channels/channels';
import { useCurrentUser } from '../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../services/db';
import { Loader } from '../../../Loader';
import { toSearchRegexp } from '../../../SearchInput';
import { Truncated } from '../../../Truncated';
import {
  createChannelFilterDefinition,
  validateFilterDefinition
} from '../filters';
import { DimensionMenuComponent } from './Dimension';
import { BASIC_MODES, FilterMenu } from './FilterMenu';
import {
  GroupedOptionList,
  Option,
  SelectorLoader,
  SelectorShell,
  toggle
} from './Selector';
import {
  OTHERS_CHANNEL_ID,
  UNKNOWN_CHANNEL_ID
} from '../../../../domainTypes/channels';

interface ChannelMenuBodyProps {
  channels: Channel[];
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
  orderBy: Metric;
  value: ChannelFilterDef['v'];
  onChange: (v: ChannelFilterDef['v']) => void;
  onSave: (v: ChannelFilterDef) => void;
}

const ChannelMenuBody: React.FC<ChannelMenuBodyProps> = ({
  range,
  filters,
  orderBy,
  onChange,
  onSave,
  value,
  channels
}) => {
  const grouper = useChannelIdGrouper();
  const { space } = useCurrentUser();
  const [search, setSearch] = useState('');

  // Return null if there is no search, so that the "Unknown" channel is also included here
  const channelIdFilter = useMemo(() => {
    const searchRe = toSearchRegexp(search);
    if (!searchRe) {
      return null;
    }
    return channels
      .filter((c) => c.id.match(searchRe) || channelLabel(c).match(searchRe))
      .map((c) => c.id);
  }, [channels, search]);

  const query = useMemo<AnalyticsQuery>(
    () => ({
      range,
      select: [orderBy],
      groupBy: grouper.groupBy,
      orderBy: [
        {
          field: orderBy,
          direction: 'DESC'
        }
      ],
      filters: compact([
        ...filters,
        channelIdFilter && {
          field: 'channel_id',
          condition: 'in',
          values: channelIdFilter
        }
      ]),
      columnTransformers: grouper.columnTransformers(space)
    }),
    [range, orderBy, grouper, filters, channelIdFilter, space]
  );

  const [channelsData, loading] = useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (response) => response.rows.map((row) => row.group.channel_id)
  );

  const _toggle = useCallback((v: string) => onChange(toggle(value, v)), [
    onChange,
    value
  ]);

  const focus = useCallback(
    (channel: string) => onSave(createChannelFilterDefinition([channel])),
    [onSave]
  );

  const options = useMemo<Option[] | null>(() => {
    if (!channelsData) return null;

    const byKind = groupBy(channels, (channel) => channel.kind);
    const kinds = keys(byKind).filter(
      (kind): kind is Channel['kind'] => kind === 'site' || kind === 'offsite'
    );

    const knownKinds = kinds.map((kind) => {
      const channels = byKind[kind] ?? [];
      // NOTE: matchingChannels will not include channels like 'Unknown' or 'Others'.
      const matchingChannels = [
        ...difference(value, channelsData),
        ...channelsData
      ]
        .filter((channelId) => channels.some((ch) => ch.id === channelId))
        .map((channelId) => getChannel(channelId, channels));

      return {
        key: kind,
        label: `${channelKindLabel(kind)} (${matchingChannels.length})`,
        expanded: true,
        items: matchingChannels.map((channel) => ({
          label: <Truncated title={channelLabel(channel)} />,
          id: channel.id
        }))
      };
    });

    return [
      ...knownKinds,
      {
        key: 'Other',
        label: 'Other',
        expanded: true,
        items: compact([
          channelsData.includes(UNKNOWN_CHANNEL_ID) && {
            label: 'Unknown',
            id: UNKNOWN_CHANNEL_ID
          },
          channelsData.includes(OTHERS_CHANNEL_ID) && {
            label: 'Other',
            id: OTHERS_CHANNEL_ID
          }
        ])
      }
    ].filter((group) => group.items.length > 0);
  }, [channelsData, value, channels]);

  return (
    <SelectorShell label="Channels" search={search} setSearch={setSearch}>
      {loading || !options ? (
        <SelectorLoader />
      ) : (
        <GroupedOptionList
          caption={`Top channels by ${metricName(orderBy)}`}
          options={options}
          selectedValues={value}
          toggleValue={_toggle}
          focusValue={focus}
        />
      )}
    </SelectorShell>
  );
};

const InnerChannelMenu: React.FC<Omit<ChannelMenuBodyProps, 'channels'>> = (
  props
) => {
  const [channels, loading] = useSpaceChannels();
  if (loading || !channels) {
    return <Loader size={24} height={80} />;
  }
  return <ChannelMenuBody {...props} channels={channels} />;
};

export const ChannelMenu: DimensionMenuComponent<ChannelFilterDef> = ({
  range,
  definition,
  filters,
  orderBy,
  onSave
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const [value, setValue] = useState(definition.v);
  const newDefinition = useMemo<ChannelFilterDef>(
    () => createChannelFilterDefinition(value),
    [value]
  );
  const enableSave =
    validateFilterDefinition(newDefinition) &&
    !isEqual(definition, newDefinition);

  return (
    <FilterMenu>
      <FilterMenu.Header dimension="channel">
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <InnerChannelMenu
          filters={filters}
          orderBy={orderBy}
          range={range}
          value={value}
          onChange={setValue}
          onSave={onSave}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer definition={definition}>
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={`Filter by ${pluralize('channel', value.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
