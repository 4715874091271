import { Typography } from '@material-ui/core';
import React from 'react';
import { IDomain } from '../../../../domainTypes/space';
import { styled } from '../../../../emotion';
import { useCurrentUser } from '../../../../services/currentUser';
import { useSettings } from '../../../../services/settings';
import { SelectableButton } from '../../components/SelectableButton';

const Columns = styled('div')`
  display: grid;
  grid-template-columns: 1.3fr 2fr;
  align-items: center;
  grid-gap: ${(p) => p.theme.spacing(4)}px;
  row-gap: ${(p) => p.theme.spacing(4)}px;
  padding: ${(p) => p.theme.spacing(2)}px;
`;

const SelectableButtonWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
`;

export const RevisionSettings = ({ site }: { site: IDomain }) => {
  const { space } = useCurrentUser();
  const settings = useSettings(space.id, site.channelId);
  console.log(settings);

  return (
    <Columns>
      <div>
        <Typography variant="body1" component="h3" paragraph>
          <strong>Date to use</strong>
        </Typography>
        <Typography variant="body2" component="p" color="textSecondary">
          How should we determine your revision dates?
        </Typography>
      </div>
      <SelectableButtonWrapper>
        <SelectableButton
          selected
          heading="Sitemap"
          subheading="Use sitemap's lastMod dates"
        />
        <SelectableButton
          selected={false}
          heading="Schema"
          subheading="Use schema's dateModified dates"
        />
      </SelectableButtonWrapper>
    </Columns>
  );
};
