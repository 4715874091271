import { Helmet } from 'react-helmet';
import { NoPermissions } from '../../../../components/NoPermissions';
import { useHasCurrentUserRequiredScopes } from '../../../../services/currentUser';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { PerformancePageBody } from '../../components/PerformancePageBody';
import { CompleteHandlerListCH } from './IntegrationsV2';

export const PagePerformanceNewIntegrations = () => {
  const [canView] = useHasCurrentUserRequiredScopes(['integrations.view']);

  useTrackMixpanelView('view_integration_settings');

  return (
    <PerformancePageBody>
      <Helmet>
        <title>Integrations | Affilimate</title>
      </Helmet>
      {canView ? <CompleteHandlerListCH /> : <NoPermissions />}
    </PerformancePageBody>
  );
};
