import React, { ComponentProps, useCallback } from 'react';
import { Chip } from '@material-ui/core';
import { AutocompleteMultiAsync } from './Autocomplete';
import { searchAdvertisers, toAdvertiserDoc } from '../services/advertisers';
import { useCurrentUser } from '../services/currentUser';
import { CampaignAdvertiser } from '../domainTypes/campaigns';
import { AdvertiserLabel } from '../features/Campaigns/service/advertiser';
import { WithShape } from './Charts/Util';
import { getAdvertiserColor } from './AdvertiserWithColor';

type AdvertiserSelectorProps = {
  value: CampaignAdvertiser[];
  onChange: (value: CampaignAdvertiser[]) => void;
} & Omit<
  ComponentProps<typeof AutocompleteMultiAsync>,
  'options' | 'onChange' | 'value'
>;

export const AdvertiserSelector: React.FC<AdvertiserSelectorProps> = ({
  value,
  onChange,
  ...autocompleteProps
}) => {
  const { space } = useCurrentUser();
  const getOptions = useCallback(
    async (search: string): Promise<CampaignAdvertiser[]> => {
      const snapshot = await searchAdvertisers(
        space.id,
        search.toLocaleLowerCase()
      ).get();
      return snapshot.docs.map((d) => {
        const { id, data } = toAdvertiserDoc(d);
        return {
          id: data.advertiserId,
          name: data.name,
          partnerKey: data.partnerKey,
          integrationId: data.integrationId,
          docId: id
        };
      });
    },
    [space.id]
  );

  return (
    <AutocompleteMultiAsync
      fullWidth
      value={value}
      onChange={onChange}
      options={getOptions}
      renderOption={(a) => <AdvertiserLabel advertiser={a} />}
      renderTags={(advertisers, getTagProps) =>
        advertisers.map((advertiser, index) => (
          <Chip
            label={
              <WithShape
                large
                color={getAdvertiserColor(
                  advertiser.name,
                  advertiser.partnerKey
                )}
              >
                {advertiser.name}
              </WithShape>
            }
            {...getTagProps({ index })}
          />
        ))
      }
      {...autocompleteProps}
    />
  );
};
