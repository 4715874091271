import { styled } from '../../../../../../emotion';
import React from 'react';
import { scaleLinear } from 'd3-scale';
import { LoadingValue } from '../../../../../../services/db';
import { Skeleton } from '@material-ui/lab';
import { sumBy } from 'lodash';
import { COLORS } from '../services/groups';
import { ReferrerGroupTotals } from '../services/referrer-groups';
import { groups } from '../../../../../../domainTypes/referrers';

const TotalsContainer = styled('div')`
  display: flex;
  border-radius: 4px;
  overflow: hidden;
`;
const TotalsSegment = styled('div')`
  height: 16px;
`;

interface TotalsBarProps {
  data: LoadingValue<ReferrerGroupTotals>;
}

export const TotalsBar: React.FC<TotalsBarProps> = ({ data }) => {
  const [totals, loading, error] = data;
  if (error) {
    return (
      <TotalsContainer>
        <Skeleton height={16} width="100%" variant="rect" animation={false} />
      </TotalsContainer>
    );
  }
  if (!totals || loading) {
    return (
      <TotalsContainer>
        <Skeleton height={16} width="100%" variant="rect" />
      </TotalsContainer>
    );
  }
  const sum = sumBy(groups, (g) => totals[g]?.curr ?? 0);
  const scale = scaleLinear().domain([0, sum]).range([0, 100]);
  return (
    <TotalsContainer>
      {groups.map((g) => (
        <TotalsSegment
          key={g}
          style={{
            width: `${scale(totals[g]?.curr ?? 0)}%`,
            backgroundColor: COLORS[g]
          }}
        />
      ))}
    </TotalsContainer>
  );
};
