import {
  Chip,
  TableCell,
  TablePagination,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';
import { Padding, TableCellProps } from '@material-ui/core/TableCell';
import { keyBy, mapValues } from 'lodash';
import React from 'react';
import { Info as IconInfo } from 'react-feather';
import { ChevronDown } from 'react-feather/dist';
import { NOOP } from '../../domainTypes/emptyConstants';
import { css, styled } from '../../emotion';
import { Dictionary } from '../../hooks/useDictionary';
import { getNextDirection, SortDirection, SortFn } from '../../hooks/useSort';
import * as tracking from '../../tracking';
import { MultiSelector, MultiSelectorValueOption } from '../MultiSelector';
import { SelectionBox } from '../SelectionBox';
import { BaseCountCell, CountCell } from './CountCell';

type Align = 'left' | 'center' | 'right';

const Label = styled<'div', { align?: Align }>('div')`
  display: flex;
  align-items: center;
  ${(p) =>
    p.align && p.align === 'left' ? 'justify-content: flex-start;' : ''}
  ${(p) => (p.align && p.align === 'center' ? 'justify-content: center;' : '')}
  ${(p) => (p.align && p.align === 'right' ? 'justify-content: flex-end;' : '')}
`;

const LabelWrapper = styled('span')`
  display: block;
  margin-right: ${(p) => p.theme.spacing()}px;
`;

export const TableLabelWithTooltip = ({
  label,
  tooltip,
  placement,
  align
}: {
  label: string | React.ReactNode;
  tooltip: string;
  placement: 'top' | 'top-start' | 'top-end';
  align?: Align;
}) => (
  <Tooltip title={tooltip} aria-label={tooltip} placement={placement}>
    <Label align={align}>
      <LabelWrapper>{label}</LabelWrapper> <IconInfo size={14} />
    </Label>
  </Tooltip>
);

export const TableToolbar = styled<
  'div',
  { padding?: 'dense'; sticky?: boolean; offset?: number; isOnCanvas?: boolean }
>('div')`
  position: ${(p) => (p.sticky ? 'sticky' : 'relative')};
  ${(p) => (p.sticky && p.offset ? `top: ${p.offset}px;` : '')}
  ${(p) => (p.sticky ? 'z-index: 5;' : '')}
  ${(p) =>
    p.sticky
      ? `background-color: ${
          p.isOnCanvas ? '#f7f7f9' : p.theme.palette.background.paper
        };`
      : ''}
  padding: ${(p) => (p.padding === 'dense' ? 14 : p.theme.spacing() * 3)}px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(p) => p.theme.breakpoints.down('md')} {
    display: block;

    & > div {
      margin-bottom: ${(p) =>
        p.padding === 'dense' ? 14 : p.theme.spacing() * 3}px;
    }
  }
`;

export const TableToolbarSection = styled('div')`
  display: flex;
  align-items: center;
  padding-top: ${(p) => p.theme.spacing(0.5)}px;
  padding-bottom: ${(p) => p.theme.spacing(0.5)}px;

  > :not(:last-child) {
    margin-right: ${(p) => p.theme.spacing(0.25)}px;
  }
`;

export const TableTitle = styled('div')`
  font-size: ${(p) => p.theme.custom.fontSize.l}px;
`;

export interface ITableHead<SortKey> {
  key: SortKey;
  label: string | React.ReactNode;
  visibilityLabel?: string | React.ReactNode;
  align: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  sortable: boolean;
  defaultDirection?: SortDirection;
  padding?: Padding;
  size?: 'small' | 'medium';
}

export const HideableTableCell = ({
  hide,
  ...props
}: TableCellProps & { hide?: boolean }) => {
  return hide ? null : <TableCell {...props} />;
};

export const SortableHead = <SortKey extends string>({
  head,
  currentDirection,
  currentSortBy,
  setSort,
  padding,
  hide,
  size,
  top = 0
}: {
  head: ITableHead<SortKey>;
  setSort: SortFn<SortKey>;
  currentSortBy: SortKey;
  currentDirection: SortDirection;
  padding?: Padding;
  hide?: boolean;
  size?: 'small' | 'medium';
  top?: number;
}) => {
  const onSort = (cell: ITableHead<SortKey>) => {
    const nextDir =
      head.key === currentSortBy
        ? getNextDirection(currentDirection)
        : cell.defaultDirection || getNextDirection();
    setSort(cell.key, nextDir);
  };
  return (
    <HideableTableCell
      padding={padding}
      align={head.align}
      hide={hide}
      style={{ top: `${top}px` }}
      size={size}
    >
      {head.sortable ? (
        <TableSortLabel
          active={head.key === currentSortBy}
          direction={currentDirection}
          onClick={() => {
            tracking.sendEvent({
              category: tracking.toAppCategory(),
              action: 'Sort',
              label: head.key
            });
            return onSort(head);
          }}
        >
          {head.label}
        </TableSortLabel>
      ) : (
        <span>{head.label}</span>
      )}
    </HideableTableCell>
  );
};

export const Paginator = ({
  dense = false,
  page,
  totalCount,
  rowsPerPage,
  rowsPerPageOptions = [rowsPerPage],
  onChangePage,
  onChangeRowsPerPage = NOOP
}: {
  dense?: boolean;
  page: number;
  totalCount: number;
  rowsPerPage: number;
  rowsPerPageOptions?: number[];
  onChangePage: (nextPage: number) => void;
  onChangeRowsPerPage?: (nextRowsPerPage: number) => void;
}) => {
  return (
    <TablePagination
      component="div"
      classes={
        dense
          ? {
              root: css(() => ({
                padding: 0,
                height: '36px',
                display: 'flex',
                alignItems: 'center'
              })),
              toolbar: css(() => ({
                padding: 0
              }))
            }
          : {}
      }
      count={totalCount}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      page={page}
      backIconButtonProps={{
        'aria-label': 'Previous Page'
      }}
      nextIconButtonProps={{
        'aria-label': 'Next Page'
      }}
      onPageChange={(_, page) => onChangePage(page)}
      onChangeRowsPerPage={(ev) => ev && onChangeRowsPerPage(+ev.target.value)}
      labelRowsPerPage={dense ? '' : 'Rows per page:'}
    />
  );
};

export const visibilityOptionsFromHeads = <Key extends string>(
  heads: ITableHead<Key>[],
  exclude: Key[] = []
): MultiSelectorValueOption<Key>[] => {
  return heads
    .filter((h) => h.key !== 'selector' && exclude.indexOf(h.key) === -1)
    .map((h) => ({
      label: h.visibilityLabel || h.label,
      value: h.key
    }));
};

export const VisibilitySelector = <Key extends string>({
  value,
  options,
  onChange
}: {
  value: Set<Key>;
  onChange: (nextValue: Set<Key>) => void;
  options: MultiSelectorValueOption<Key>[];
}) => {
  return (
    <MultiSelector
      value={value}
      onChange={onChange}
      options={options}
      legend="Filter columns"
    >
      <Chip
        label={
          <>
            Filter columns
            <ChevronDown
              size={18}
              className={css((t) => ({ marginLeft: t.spacing(1) }))}
            />
          </>
        }
      />
    </MultiSelector>
  );
};

export const autoHeightRow = () => ({
  root: css((t) => ({ height: 'auto ' }))
});

export const getSelectorLabel = (
  itemIds: string[],
  isSelected: (productId: string) => boolean,
  mergeSelected: (nextSelection: Dictionary<boolean>) => void
) => {
  if (!itemIds.length) {
    return <SelectionBox value={false} onChange={() => {}} />;
  }
  const selected = itemIds.filter((id) => isSelected(id)).length;
  const allSelected = selected === itemIds.length;
  const noneSelected = selected === 0;

  const onChange = () => {
    const dict = mapValues(
      keyBy(itemIds, (id) => id),
      (v) => !allSelected
    );
    mergeSelected(dict);
  };

  return (
    <SelectionBox
      onChange={onChange}
      indeterminate={!allSelected && !noneSelected}
      value={allSelected}
    />
  );
};

export const SELECTOR_HEAD = (
  itemIds: string[],
  isSelected: (href: string) => boolean,
  mergeSelected: (href: Dictionary<boolean>) => void
): ITableHead<'selector'> => ({
  key: 'selector',
  label: getSelectorLabel(itemIds, isSelected, mergeSelected),
  align: 'center',
  sortable: false,
  padding: 'checkbox'
});

export { BaseCountCell, CountCell };
