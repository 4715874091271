import { Chip } from '@material-ui/core';
import { styled } from '../../../../../emotion';

const DraftStatusWrapper = styled(Chip)`
  font-weight: 700;
  margin-left: ${(p) => p.theme.spacing(0.5)}px;
  color: ${(p) => p.theme.palette.common.white} !important;
  background-color: ${(p) => p.theme.palette.primary.main} !important;
`;

export const DraftStatus = () => (
  <DraftStatusWrapper label="Draft" size="small" />
);

const UnpublishedStatusWrapper = styled(Chip)`
  font-weight: 700;
  margin-left: ${(p) => p.theme.spacing(1)}px;
  color: ${(p) => p.theme.palette.grey[600]} !important;
  background-color: ${(p) => p.theme.palette.grey[300]} !important;
`;

export const UnpublishedStatus = () => (
  <UnpublishedStatusWrapper label="Unpublished" size="small" />
);
