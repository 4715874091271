import {
  Connector,
  IChromeExtensionMetadata,
  CHROME_EXTENSION_METADATA_ID,
  EMPTY_CHROME_EXTENSION_METADATA
} from '../../domainTypes/chromeExtension';
import {
  AppToContentScript,
  APP_TO_CONTENT_SCRIPT,
  ContentScriptToApp,
  CUSTOM_EVENT_TYPE
} from '../../domainTypes/chromeExtension/appToContentScript';
import { callFirebaseFunction } from '../firebaseFunctions';
import { generateToDocFn } from '../../domainTypes/document';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useMappedLoadingValue, store } from '../db';
import { FS } from '../../versions';

export type Listener = (msg: ContentScriptToApp) => void;
const listeners: Listener[] = [];

const connector = new Connector<ContentScriptToApp, AppToContentScript>(
  APP_TO_CONTENT_SCRIPT,
  msg => {
    listeners.forEach(listener => listener(msg));

    if (msg.type === 'STATUS') {
      console.log('Extension status: ', msg.data);
    }
  }
);

const connect = () => {
  // check out chrome/extension/src/connections/dom.ts - this is the same code
  connector.connect(
    msg => {
      const event = new CustomEvent(CUSTOM_EVENT_TYPE, { detail: msg });
      document.dispatchEvent(event);
    },
    handleMessage => {
      document.addEventListener(CUSTOM_EVENT_TYPE, event => {
        const { detail } = event as CustomEvent;
        if (detail && detail.type) {
          const msg: any = { type: detail.type, data: detail.data };
          handleMessage(msg);
        }
      });
    }
  );
};

let isConnected = false;

export const getExtension = () => {
  if (!isConnected) {
    connect();
    isConnected = true;
  }

  return {
    postMessage: connector.postMessage,
    addListener: (listener: Listener) => listeners.push(listener),
    removeListener: (listener: Listener) => {
      const i = listeners.indexOf(listener);
      if (i !== -1) {
        listeners.splice(i, 1);
      }
    }
  };
};

export const loginExtension = () => {
  return callFirebaseFunction<string>('user-getCustomToken').then(token =>
    getExtension().postMessage({ type: 'LOGIN', data: { token } })
  );
};

const toMetadataDoc = generateToDocFn<IChromeExtensionMetadata>();
export const useExtensionMetadata = () => {
  return useMappedLoadingValue(
    useDocument(
      store()
        .collection(FS.chromeExtensionMetadata)
        .doc(CHROME_EXTENSION_METADATA_ID)
    ),
    s => (s.exists ? toMetadataDoc(s) : EMPTY_CHROME_EXTENSION_METADATA())
  );
};
