import { Button, Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import React from 'react';
import { ISpace } from '../../../../domainTypes/space';
import {
  SplashPage,
  SplashPageActions,
  SplashPageContent,
  SplashPageHeader
} from '../../../../layout/SplashPage';
import { signOut } from '../../../../services/auth';

type Props = {
  space: ISpace;
  onIgnore: () => void;
  onGoToCheckout: () => void;
};

export const PageSubscriptionExpired: React.FC<Props> = ({
  space,
  onIgnore,
  onGoToCheckout
}) => {
  const { billing } = space;
  const expiredAt = moment.tz(billing.subscribedUntil, space.config.tz);
  const graceEnd = expiredAt.clone().add(billing.gracePeriod, 'ms');
  const isOutsideOfGracePeriod = moment().isAfter(graceEnd);
  return (
    <SplashPage>
      <SplashPageHeader>Your subscription has expired</SplashPageHeader>
      <SplashPageContent style={{ textAlign: 'center' }}>
        <Typography component="p">
          Your subscription ended {expiredAt.fromNow()}.{' '}
          {!isOutsideOfGracePeriod && (
            <span>
              Your account will be inaccessbile on {graceEnd.format('MMM Do')}.
            </span>
          )}
        </Typography>
        {isOutsideOfGracePeriod ? (
          <Typography component="p">
            Reactive your subscription now to access your data again.
          </Typography>
        ) : (
          <Typography component="p">
            Reactive your subscription now to avoid getting locked out.
          </Typography>
        )}
      </SplashPageContent>
      <SplashPageActions
        leftSlot={<Button onClick={() => signOut()}>Log out</Button>}
      >
        {!isOutsideOfGracePeriod && (
          <Button onClick={onIgnore}>Continue to app</Button>
        )}
        <Button variant="contained" color="primary" onClick={onGoToCheckout}>
          Reactivate
        </Button>
      </SplashPageActions>
    </SplashPage>
  );
};
