import React, { useContext, useState } from 'react';

interface IContextProps {
  open: boolean;
  setOpen: (open: boolean) => any;
}

const SideNavContext = React.createContext<IContextProps>({
  open: false,
  setOpen: (o: boolean) => {}
});

export const SideNavProvider: React.FC = props => {
  const [open, setOpen] = useState(false);
  const value = { open, setOpen };

  return (
    <SideNavContext.Provider value={value}>
      {props.children}
    </SideNavContext.Provider>
  );
};

export const useSideNav = () => {
  const { open, setOpen } = useContext(SideNavContext);
  return { open, setOpen };
};
