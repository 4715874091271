import { Card } from '@material-ui/core';
import { every } from 'lodash';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import {
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Text,
  Tooltip
} from 'recharts';
import {
  FreeFormTooltip,
  TooltipPaper,
  TooltipRow
} from '../../../../../../components/Charts/CustomTooltip';
import { WithShape } from '../../../../../../components/Charts/Util';
import { Loader } from '../../../../../../components/Loader';
import {
  formatCurrency,
  formatNumber
} from '../../../../../../components/Number';
import { CurrencyCode } from '../../../../../../domainTypes/currency';
import { IPageRevision } from '../../../../../../domainTypes/page';
import { styled } from '../../../../../../emotion';
import { Centered } from '../../../../../../layout/Centered';
import { toMoment } from '../../../../../../services/time';
import { useTheme } from '../../../../../../themes';
import { PIE_HEIGHT } from '../constants';
import { getAdvertiserColor } from '../../../../../../components/AdvertiserWithColor';
import { AnalyticsResponseRowWithComparison } from '../../../../../../domainTypes/analytics_v2';
import { useSpaceCurrency } from '../../../../../../services/useSpaceCurrency';
import { useCurrentUser } from '../../../../../../services/currentUser';

const Category = styled('div')`
  color: gray;
`;

const Improvement = styled('div')`
  color: ${(p) => p.theme.custom.colors.success.main};
`;

const Decline = styled('div')`
  color: ${(p) => p.theme.custom.colors.error.main};
`;

const Table = styled('table')`
  width: 100%;

  tr td:nth-of-type(2) {
    padding-right: ${(p) => p.theme.spacing(2)}px;
  }
`;

const Colored: React.FC<{ n: number }> = ({ n, children }) => {
  if (n > 0) {
    return <Improvement>{children}</Improvement>;
  }
  if (n < 0) {
    return <Decline>{children}</Decline>;
  }
  return <div>{children}</div>;
};

const Header = styled('div')`
  text-align: center;
`;

const Title = styled('div')`
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
  margin-bottom: ${(p) => p.theme.spacing()}px;
`;

type RingData = {
  key: string;
  name: string;
  value: number;
  color: string;
};

const SalesRing = ({
  prev,
  current,
  advertisers
}: {
  advertisers: AnalyticsResponseRowWithComparison[];
  prev: number;
  current: number;
}) => {
  const currency = useSpaceCurrency();
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = useState<
    number[] | number | undefined
  >();
  const data: RingData[] = advertisers
    .map((row) => ({
      key: row.group.pk,
      name: row.group.advertiser_name,
      color: getAdvertiserColor(row.group.advertiser_name, row.group.pk),
      value: row.data.commission_sum_net?.curr ?? 0
    }))
    .sort((a, b) => b.value - a.value);

  const totalFormatted = formatCurrency(current, currency);
  const totalDiff = current - prev;
  const totalDiffFormatted = formatCurrency(totalDiff, currency, true);

  const isEmpty = every(data, (d) => d.value === 0);

  if (isEmpty) {
    data.push({
      key: 'EMPTY',
      name: '',
      value: 1,
      color: theme.custom.colors.background.main
    });
  }

  return (
    <ResponsiveContainer width="99%" height={PIE_HEIGHT}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          startAngle={450}
          endAngle={90}
          innerRadius={88}
          outerRadius={120}
          isAnimationActive={false}
          activeIndex={activeIndex}
          onMouseEnter={(_, i) => setActiveIndex(i)}
          onMouseLeave={() => setActiveIndex(undefined)}
        >
          <Label
            content={(props) => {
              const {
                viewBox: { cx, cy }
              } = props;
              return (
                <>
                  <Text
                    x={cx}
                    y={cy - 10}
                    textAnchor="middle"
                    verticalAnchor="middle"
                    style={{ fontSize: theme.custom.fontSize.xl }}
                  >
                    {totalFormatted}
                  </Text>
                  <Text
                    x={cx}
                    y={cy + 18}
                    textAnchor="middle"
                    verticalAnchor="middle"
                    fill={
                      totalDiff === 0
                        ? theme.custom.colors.pending.main
                        : totalDiff > 0
                        ? theme.custom.colors.success.main
                        : theme.custom.colors.error.main
                    }
                    style={{ fontSize: theme.custom.fontSize.s }}
                  >
                    {totalDiffFormatted}
                  </Text>
                </>
              );
            }}
          />
          {data.map((d, i) => (
            <Cell
              key={d.key}
              fill={d.color}
              opacity={activeIndex === i || activeIndex === undefined ? 1 : 0.5}
            />
          ))}
        </Pie>
        <Tooltip
          cursor={false}
          content={
            <FreeFormTooltip>
              {() =>
                isEmpty ? null : (
                  <TooltipPaper>
                    {data
                      .filter((_, i) => i === activeIndex)
                      .map(
                        (d) =>
                          d.value !== 0 && (
                            <TooltipRow key={d.key}>
                              <WithShape color={d.color}>{d.name}</WithShape>
                              {formatCurrency(d.value, currency)}
                            </TooltipRow>
                          )
                      )}
                  </TooltipPaper>
                )
              }
            </FreeFormTooltip>
          }
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

const toRows = (
  data: void | AnalyticsResponseRowWithComparison['data'],
  rev: IPageRevision | null,
  tz: string,
  currency: CurrencyCode
): [React.ReactNode, React.ReactNode, React.ReactNode][] => {
  if (!data) {
    return [
      [<Category>RPM</Category>, '', ''],
      [<Category>AOV</Category>, '', ''],
      [<Category>Items sold</Category>, '', ''],
      [<Category>Pageviews</Category>, '', ''],
      [<Category>Clicks</Category>, '', ''],
      [<Category>Page CTR</Category>, '', ''],
      [
        <Category>Last revision</Category>,
        rev ? toMoment(rev.lastModifiedAt).tz(tz).format('D MMM YYYY') : '',
        rev ? (
          <Improvement>
            {moment
              .duration(toMoment(rev.lastModifiedAt).diff(moment()))
              .humanize(true)}
          </Improvement>
        ) : (
          ''
        )
      ]
    ];
  }

  const rpm = data.rpm_net?.curr ?? 0;
  const rpmDiff = rpm - (data.rpm_net?.prev ?? 0);

  const pV = data.p?.curr ?? 0;
  const pVDiff = pV - (data.p?.prev ?? 0);

  const clicks = data.c?.curr ?? 0;
  const clicksDiff = clicks - (data.c?.prev ?? 0);

  const ctr = data.ctr?.curr ?? 0;
  const ctrDiff = ctr - (data.ctr?.prev ?? 0);

  const itemsSold = data.commission_count_net?.curr ?? 0;
  const itemsSoldDiff = itemsSold - (data.commission_count_net?.prev ?? 0);

  const aov = data.aov_net?.curr ?? 0;
  const aovDiff = aov - (data.aov_net?.prev ?? 0);

  return [
    [
      <Category>RPM</Category>,
      formatCurrency(rpm, currency),
      <Colored n={rpmDiff}>{formatCurrency(rpmDiff, currency, true)}</Colored>
    ],
    [
      <Category>AOV</Category>,
      formatCurrency(aov, currency),
      <Colored n={aovDiff}>{formatCurrency(aovDiff, currency, true)}</Colored>
    ],
    [
      <Category>Items sold</Category>,
      formatNumber({ n: itemsSold }),
      <Colored n={itemsSoldDiff}>
        {formatNumber({ n: itemsSoldDiff, plusMinus: true })}
      </Colored>
    ],
    [
      <Category>Pageviews</Category>,
      formatNumber({ n: pV }),
      <Colored n={pVDiff}>
        {formatNumber({ n: pVDiff, plusMinus: true })}
      </Colored>
    ],
    [
      <Category>Clicks</Category>,
      formatNumber({ n: clicks }),
      <Colored n={clicksDiff}>
        {formatNumber({ n: clicksDiff, plusMinus: true })}
      </Colored>
    ],
    [
      <Category>Page CTR</Category>,
      formatNumber({ n: ctr, format: 'percent', digits: 1 }),
      <Colored n={ctrDiff}>
        {formatNumber({
          n: ctrDiff,
          format: 'percent',
          digits: 1,
          plusMinus: true
        })}
      </Colored>
    ],
    [
      <Category>Last revision</Category>,
      rev ? toMoment(rev.lastModifiedAt).tz(tz).format('D MMM YYYY') : '',
      rev ? (
        <Improvement>
          {moment
            .duration(toMoment(rev.lastModifiedAt).diff(moment()))
            .humanize(true)}
        </Improvement>
      ) : (
        ''
      )
    ]
  ];
};

export const Summary = ({
  summary,
  rev,
  loading,
  advertisers
}: {
  summary: void | AnalyticsResponseRowWithComparison['data'];
  advertisers: void | AnalyticsResponseRowWithComparison[];
  rev: IPageRevision | null;
  loading: boolean;
}) => {
  const currency = useSpaceCurrency();
  const { tz } = useCurrentUser();
  const rows = toRows(summary, rev, tz, currency);
  return (
    <Card>
      <Header>
        <Title>Content summary</Title>
      </Header>
      {summary && advertisers && (
        <SalesRing
          advertisers={advertisers}
          prev={summary.commission_sum_net?.prev ?? 0}
          current={summary.commission_sum_net?.curr ?? 0}
        />
      )}
      {loading && !summary && <Loader height={PIE_HEIGHT} />}
      {!loading && !summary && (
        <Centered height={PIE_HEIGHT}>Something went wrong.</Centered>
      )}
      <Table>
        <tbody>
          {rows.map((r, i) => (
            <tr key={i}>
              <td>{r[0]}</td>
              <td align="right">{r[1]}</td>
              <td>{r[2]}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};
