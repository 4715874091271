export const joinWithCommaAndAnd = (xs: string[]) => {
  let els = xs.slice();
  if (els.length > 2) {
    const lastEl = els.pop() || ''; // just to satisfy the type ssytem
    els = [els.join(', '), lastEl];
  }
  return els.join(` and `);
};

export const joinWithCommaAndOr = (xs: string[]) => {
  let els = xs.slice();
  if (els.length > 2) {
    const lastEl = els.pop() || ''; // just to satisfy the type ssytem
    els = [els.join(', '), lastEl];
  }
  return els.join(` or `);
};
