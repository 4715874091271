import { ButtonProps } from '@material-ui/core';
import React, { useMemo } from 'react';
import { ExportButton } from '../../../../components/Exporter';
import { IExportColumn } from '../../../../components/Exporter/service';
import { Timeframe } from '../../../../domainTypes/analytics';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { IPageWithCountsAndTrendsAndSales } from '../../../../domainTypes/page';
import { VALUE_GETTERS } from '../../pages/Overview/PagesTable';
import { IPostgresTags } from '../../../../domainTypes/tags';
import { compact } from 'lodash';
import { SortValue } from '../../../../components/GroupableList';

type ExportColumnKey =
  | 'url'
  | 'pageViewsAbsolute'
  | 'pageViewsAbsoluteGrowth'
  | 'pageViewsRelativeGrowth'
  | 'servedAbsolute'
  | 'servedAbsoluteGrowth'
  | 'servedRelativeGrowth'
  | 'viewedAbsolute'
  | 'viewedAbsoluteGrowth'
  | 'viewedRelativeGrowth'
  | 'viewRatioAbsolute'
  | 'viewRatioAbsoluteGrowth'
  | 'viewRatioRelativeGrowth'
  | 'clickedAbsolute'
  | 'clickedAbsoluteGrowth'
  | 'clickedRelativeGrowth'
  | 'clickRatioAbsolute'
  | 'clickRatioAbsoluteGrowth'
  | 'clickRatioRelativeGrowth'
  | 'rpmAbsolute'
  | 'rpmAbsoluteGrowth'
  | 'rpmRelativeGrowth'
  | 'epcAbsolute'
  | 'epcAbsoluteGrowth'
  | 'epcRelativeGrowth'
  | 'salesAbsolute'
  | 'salesAbsoluteGrowth'
  | 'salesRelativeGrowth'
  | 'salesCountAbsolute'
  | 'salesCountAbsoluteGrowth'
  | 'salesCountRelativeGrowth'
  | 'aov'
  | 'saleValue'
  | 'orderCount'
  | 'tags';

type OtherProps = {
  currency: CurrencyCode;
  tagsById: Record<string, IPostgresTags>;
};
type ExportColumn = IExportColumn<
  ExportColumnKey,
  IPageWithCountsAndTrendsAndSales,
  OtherProps
>;

const toCurrency = (fn: (d: IPageWithCountsAndTrendsAndSales) => SortValue) => (
  d: IPageWithCountsAndTrendsAndSales
) => Number(fn(d)) / 100;

const EXPORT_COLUMNS: ExportColumn[] = [
  {
    key: 'url',
    head: () => 'URL',
    cell: (d) => d.href
  },
  {
    key: 'pageViewsAbsolute',
    head: () => 'Pageviews',
    cell: VALUE_GETTERS.pageViews.current
  },
  {
    key: 'clickedAbsolute',
    head: () => 'Clicks',
    cell: VALUE_GETTERS.clicked.current
  },
  {
    key: 'viewedAbsolute',
    head: () => 'Impressions',
    cell: VALUE_GETTERS.viewed.current
  },
  {
    key: 'servedAbsolute',
    head: () => 'Served',
    cell: VALUE_GETTERS.served.current
  },
  {
    key: 'viewRatioAbsolute',
    head: () => 'Visibility',
    cell: VALUE_GETTERS.viewRatio.current
  },
  {
    key: 'clickRatioAbsolute',
    head: () => 'Page CTR',
    cell: VALUE_GETTERS.clickRatio.current
  },
  {
    key: 'rpmAbsolute',
    head: ({ currency }) => `RPM (in ${currency})`,
    cell: toCurrency(VALUE_GETTERS.rpm.current)
  },
  {
    key: 'epcAbsolute',
    head: ({ currency }) => `EPC (in ${currency})`,
    cell: toCurrency(VALUE_GETTERS.epc.current)
  },
  {
    key: 'salesAbsolute',
    head: ({ currency }) => `Earnings (in ${currency})`,
    cell: toCurrency(VALUE_GETTERS.sales.current)
  },
  {
    key: 'orderCount',
    head: () => 'Orders',
    cell: VALUE_GETTERS.orderCount.current
  },
  {
    key: 'aov',
    head: ({ currency }) => `AOV (in ${currency})`,
    cell: toCurrency(VALUE_GETTERS.aov.current)
  },
  {
    key: 'saleValue',
    head: ({ currency }) => `Sale Volume (in ${currency})`,
    cell: toCurrency(VALUE_GETTERS.saleValue.current)
  },
  {
    key: 'pageViewsAbsoluteGrowth',
    head: () => 'Pageviews (absolute growth)',
    cell: VALUE_GETTERS.pageViews.absoluteGrowth
  },
  {
    key: 'pageViewsRelativeGrowth',
    head: () => 'Pageviews (relative growth)',
    cell: VALUE_GETTERS.pageViews.relativeGrowth
  },
  {
    key: 'servedAbsoluteGrowth',
    head: () => 'Served Links (absolute growth)',
    cell: VALUE_GETTERS.served.absoluteGrowth
  },
  {
    key: 'servedRelativeGrowth',
    head: () => 'Served Links (relative growth)',
    cell: VALUE_GETTERS.served.relativeGrowth
  },
  {
    key: 'viewedAbsoluteGrowth',
    head: () => 'Impressions (absolute growth)',
    cell: VALUE_GETTERS.viewed.absoluteGrowth
  },
  {
    key: 'viewedRelativeGrowth',
    head: () => 'Impressions (relative growth)',
    cell: VALUE_GETTERS.viewed.relativeGrowth
  },
  {
    key: 'viewRatioAbsoluteGrowth',
    head: () => 'Visibility (absolute growth)',
    cell: VALUE_GETTERS.viewRatio.absoluteGrowth
  },
  {
    key: 'viewRatioRelativeGrowth',
    head: () => 'Visibility (relative growth)',
    cell: VALUE_GETTERS.viewRatio.relativeGrowth
  },
  {
    key: 'clickedAbsoluteGrowth',
    head: () => 'Clicks (absolute growth)',
    cell: VALUE_GETTERS.clicked.absoluteGrowth
  },
  {
    key: 'clickedRelativeGrowth',
    head: () => 'Clicks (relative growth)',
    cell: VALUE_GETTERS.clicked.relativeGrowth
  },
  {
    key: 'clickRatioAbsoluteGrowth',
    head: () => 'Page CTR (absolute growth)',
    cell: VALUE_GETTERS.clickRatio.absoluteGrowth
  },
  {
    key: 'clickRatioRelativeGrowth',
    head: () => 'Page CTR (relative growth)',
    cell: VALUE_GETTERS.clickRatio.relativeGrowth
  },
  {
    key: 'rpmAbsoluteGrowth',
    head: ({ currency }) => `RPM (absolute growth in ${currency})`,
    cell: toCurrency(VALUE_GETTERS.rpm.absoluteGrowth)
  },
  {
    key: 'rpmRelativeGrowth',
    head: () => 'RPM (relative growth)',
    cell: VALUE_GETTERS.rpm.relativeGrowth
  },
  {
    key: 'epcAbsoluteGrowth',
    head: ({ currency }) => `EPC (absolute growth in ${currency})`,
    cell: toCurrency(VALUE_GETTERS.epc.absoluteGrowth)
  },
  {
    key: 'epcRelativeGrowth',
    head: () => 'EPC (relative growth)',
    cell: VALUE_GETTERS.epc.relativeGrowth
  },
  {
    key: 'salesAbsoluteGrowth',
    head: ({ currency }) => `Earnings (absolute growth in ${currency})`,
    cell: toCurrency(VALUE_GETTERS.sales.absoluteGrowth)
  },
  {
    key: 'salesRelativeGrowth',
    head: () => 'Earnings (relative growth)',
    cell: VALUE_GETTERS.sales.relativeGrowth
  },
  {
    key: 'salesCountAbsolute',
    head: () => 'Items sold (absolute)',
    cell: VALUE_GETTERS.salesCount.current
  },
  {
    key: 'salesCountAbsoluteGrowth',
    head: () => 'Items sold (absolute growth)',
    cell: VALUE_GETTERS.salesCount.absoluteGrowth
  },
  {
    key: 'salesCountRelativeGrowth',
    head: () => 'Items sold (relative growth)',
    cell: VALUE_GETTERS.salesCount.relativeGrowth
  },
  {
    key: 'tags',
    head: () => 'Tag names',
    cell: (d, o) => {
      return compact(d.tagIds.map((id) => o.tagsById[id]?.name)).join(', ');
    }
  }
];

const toFileName = (tf?: Timeframe) => {
  let n = 'affilimate_content_export';
  if (tf) {
    n = `${n}_${tf.start}_${tf.end}`;
  }
  return n;
};

export const ContentExportButton = ({
  pages,
  timeframe,
  currency,
  tagsById,
  ...other
}: {
  pages: IPageWithCountsAndTrendsAndSales[];
  currency: CurrencyCode;
  timeframe?: Timeframe;
  tagsById: Record<string, IPostgresTags>;
} & ButtonProps) => {
  const otherProps: OtherProps = useMemo(() => ({ currency, tagsById }), [
    currency,
    tagsById
  ]);
  const preselectedColumns = [
    'url',
    'pageViewsAbsolute',
    'clickedAbsolute',
    'viewedAbsolute',
    'servedAbsolute',
    'clickRatioAbsolute',
    'viewRatioAbsolute',
    'rpmAbsolute',
    'epcAbsolute',
    'salesAbsolute'
  ];

  return (
    <ExportButton
      {...other}
      dialogTitle="Export Content"
      preselectedColumns={preselectedColumns}
      rows={pages}
      columns={EXPORT_COLUMNS}
      otherProps={otherProps}
      fileName={toFileName(timeframe)}
    />
  );
};
