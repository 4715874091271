import { IconButton, IconButtonProps } from '@material-ui/core';
import React, { useState } from 'react';
import useIsMounted from '../../hooks/useIsMounted';
import { InlineLoader } from '../Loader';

export type IconButtonWithPromiseProps<T> = {
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => Promise<T | void>;
  pendingSize?: number;
} & Omit<IconButtonProps, 'onClick'>;

export const IconButtonWithPromise = <T extends any>(
  props: IconButtonWithPromiseProps<T>
) => {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const { onClick, disabled, pendingSize, children, ...other } = props;
  const done = () => isMounted.current && setLoading(false);
  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setLoading(true);
    return onClick(e).then(done, done);
  };

  return (
    <IconButton {...other} disabled={disabled || loading} onClick={handleClick}>
      {loading ? (
        <>
          <InlineLoader size={pendingSize} />
        </>
      ) : (
        children
      )}
    </IconButton>
  );
};
