import { Card, CardContent, Paper } from '@material-ui/core';
import React from 'react';
import { Loader } from '../../../../components/Loader';
import { ISpace } from '../../../../domainTypes/space';
import { useInvoices } from '../../service';
import { InvoiceTable } from './Table';

type SpaceProps = {
  space: ISpace;
};

export const Invoices = ({ space }: SpaceProps) => {
  const [invoices, loading, error] = useInvoices(space.id);
  if (loading) {
    return (
      <Card>
        <CardContent>
          <Loader height={200} />
        </CardContent>
      </Card>
    );
  }
  if (error) {
    return (
      <Card>
        <CardContent>Error</CardContent>
      </Card>
    );
  }

  if (!invoices || !invoices.length) {
    return (
      <Card>
        <CardContent>No invoices yet</CardContent>
      </Card>
    );
  }

  return (
    <Paper>
      <InvoiceTable invoices={invoices} />
    </Paper>
  );
};
