import React, { useMemo } from 'react';
import { Section } from '../../../../../../../layout/Section';
import { useMappedLoadingValue } from '../../../../../../../services/db';
import moment from 'moment-timezone';
import { FORMATTERS } from '../../../../../../../components/Charts/TrafficBiaxialChart';
import {
  AnalyticsQuery,
  ISOTimeRange
} from '../../../../../../../domainTypes/analytics_v2';
import { useAnalyticsQueryV2 } from '../../../../../../../services/analyticsV2/query';
import { HOURKEY_FORMAT } from '../../../../../../../domainTypes/monitoring';
import { MonitoringChartCard } from '../../../../../Monitoring/components/Chart';
import { HOUR_KEY_FORMATTERS } from '../../../../../../../features/Dashboard/components/DomainSummary/RealtimeReporting';
import { TIMEKEY_FORMAT } from '../../../../../../../domainTypes/analytics';

type Props = {
  spaceId: string;
};

const CurrentDay = ({ spaceId }: Props) => {
  const query = useMemo<AnalyticsQuery>(() => {
    const tz = moment.tz.guess();
    const range: ISOTimeRange = {
      start: moment().startOf('h').subtract(2, 'day').toISOString(),
      end: moment().startOf('h').toISOString()
    };

    return {
      range,
      select: ['p', 's', 'v', 'c'],
      interval: {
        unit: 'hour',
        value: 1,
        tz
      },
      orderBy: [
        {
          field: 'interval',
          direction: 'ASC'
        }
      ]
    };
  }, []);

  const [stats, loading] = useMappedLoadingValue(
    useAnalyticsQueryV2(spaceId, query),
    (result) => {
      return result.rows.map((row) => ({
        ts: moment(row.group.interval).format(HOURKEY_FORMAT),
        pageViews: row.data.p?.curr ?? 0,
        served: row.data.s?.curr ?? 0,
        viewed: row.data.v?.curr ?? 0,
        clicked: row.data.c?.curr ?? 0
      }));
    }
  );

  return (
    <MonitoringChartCard
      heading="Last 48 hours"
      data={stats}
      loading={loading}
      formatters={HOUR_KEY_FORMATTERS}
      animate={false}
    />
  );
};

const CurrentTwoWeeks = ({ spaceId }: Props) => {
  const query = useMemo<AnalyticsQuery>(() => {
    const tz = moment.tz.guess();
    const range: ISOTimeRange = {
      start: moment().startOf('day').subtract(2, 'week').toISOString(),
      end: moment().startOf('day').toISOString()
    };
    return {
      range,
      select: ['p', 's', 'v', 'c'],
      interval: {
        unit: 'day',
        value: 1,
        tz
      },
      orderBy: [
        {
          field: 'interval',
          direction: 'ASC'
        }
      ]
    };
  }, []);
  const [stats, loading] = useMappedLoadingValue(
    useAnalyticsQueryV2(spaceId, query),
    (result) => {
      return result.rows.map((row) => ({
        ts: moment(row.group.interval).format(TIMEKEY_FORMAT),
        pageViews: row.data.p?.curr ?? 0,
        served: row.data.s?.curr ?? 0,
        viewed: row.data.v?.curr ?? 0,
        clicked: row.data.c?.curr ?? 0
      }));
    }
  );

  return (
    <MonitoringChartCard
      heading="Last 14 days"
      data={stats}
      loading={loading}
      formatters={FORMATTERS.TIME_KEY}
      animate={false}
    />
  );
};

export const Monitoring = ({ spaceId }: Props) => {
  return (
    <>
      <Section>
        <CurrentDay spaceId={spaceId} />
      </Section>
      <Section>
        <CurrentTwoWeeks spaceId={spaceId} />
      </Section>
    </>
  );
};
