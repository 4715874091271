import {
  Zap as Performance,
  BarChart as Analytics,
  Briefcase as Partners,
  FileText as Pages,
  Link as Products,
  RefreshCw as Checks,
  Search as Scans,
  Sun as Recommendations
} from 'react-feather';

export const ModuleIcons = {
  Performance,
  Analytics,
  Partners,
  Pages,
  Products,
  Checks,
  Scans,
  Recommendations
};
