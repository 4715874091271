import { FC } from 'react';
import { Calendar, Clock } from 'react-feather';
import Tooltip from '@material-ui/core/Tooltip';
import { css } from '../../emotion';
import { ButtonBase } from '@material-ui/core';

export type Granularity = 'minute' | 'day';

interface GranularitySwitchProps {
  value: Granularity;
  onChange: (value: Granularity) => void;
}

export const GranularitySwitch: FC<GranularitySwitchProps> = ({
  value,
  onChange
}) => {
  const title = value === 'day' ? 'Customize times' : 'Show only dates';

  return (
    <Tooltip
      title={title}
      placement="top"
      classes={{
        popper: css(() => ({
          zIndex: 99999
        }))
      }}
    >
      <ButtonBase
        onClick={() => {
          onChange(value === 'minute' ? 'day' : 'minute');
        }}
      >
        {value === 'day' ? <Clock size={14} /> : <Calendar size={14} />}
      </ButtonBase>
    </Tooltip>
  );
};
