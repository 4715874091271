import { ExportReportType } from './export';
import { Timestamp } from './time';

export interface INotificationCounts {
  unseen: number;
}

export interface INotificationCounter {
  userId: string;
  counts: INotificationCounts;
  bySpace: {
    [spaceId: string]: INotificationCounts;
  };
  lastUpdate: Timestamp | null;
}

export const emptyNotificationCounter = (
  userId: string
): INotificationCounter => {
  return {
    userId,
    counts: {
      unseen: 0
    },
    bySpace: {},
    lastUpdate: null
  };
};

interface IAbstractNotification {
  uid: string | 'XXX';
  spaceId: string | 'GENERAL';
  userId: string;
  createdAt: Timestamp;
  seenAt: Timestamp | null;

  category: NotificationCategory;
  type: NotificationType;
}

export type NotificationPrototype<T extends Notification> = Omit<
  T,
  'spaceId' | 'userId' | 'createdAt' | 'seenAt' | 'uid'
>;

export type NotificationDistributionParams = {
  prototype: NotificationPrototype<Notification>;
  createdAt: number;
  distributePerSpace: boolean;
  userIds: string[] | 'EVERYONE';
};

export type NotificationCategory =
  | 'LinkCheck'
  | 'Release'
  | 'Scan'
  | 'Test'
  | 'Download';

export type NotificationType =
  | 'LinkCheck_NewIssues'
  | 'Download_Export'
  | 'Release_ExtensionKlook'
  | 'Release_Notifications'
  | 'Release_AffiliateProgram'
  | 'Release_AvantLinkIntegration'
  | 'Release_PepperjamIntegration'
  | 'Release_LabelRules'
  | 'Scan_NewResults'
  | 'Test_Dummy';

export type Notification =
  | LinkCheckNewIssues
  | ReleaseNotification
  | ScanNewResults
  | TestDummy
  | DownloadExportNotification;

export interface TestDummy extends IAbstractNotification {
  category: 'Test';
  type: 'Test_Dummy';
  data: any;
}

export interface ReleaseNotification extends IAbstractNotification {
  category: 'Release';
  type:
    | 'Release_Notifications'
    | 'Release_ExtensionKlook'
    | 'Release_AvantLinkIntegration'
    | 'Release_AffiliateProgram'
    | 'Release_PepperjamIntegration'
    | 'Release_LabelRules';
}

export interface DownloadExportNotification extends IAbstractNotification {
  category: 'Download';
  type: 'Download_Export';
  data: {
    reportType: ExportReportType;
    downloadPath: string;
    start: string;
    end: string;
  };
}

export interface ScanNewResults extends IAbstractNotification {
  category: 'Scan';
  type: 'Scan_NewResults';
  data: {
    scanId: string;
    counts: {
      products: number;
      links: number;
      pages: number;
    };
  };
}

export interface LinkCheckNewIssues extends IAbstractNotification {
  category: 'LinkCheck';
  type: 'LinkCheck_NewIssues';
  data: {
    containerId: string;
    newIssues: number;
  };
}
