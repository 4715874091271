import { useEffect } from 'react';
import { Beacon } from '../services/beacon';
import { Cio } from '../services/customerio';
import { useAdminClaim, useImpersonatorClaim } from '../services/auth';
import { ICurrentUser } from '../domainTypes/user';
import { mixpanel as mp } from '../services/mixpanel';

export const useIdentifyUser = ({
  currentUser,
  ready
}: {
  currentUser: ICurrentUser | null;
  ready: boolean;
}) => {
  const [isAdmin, loadingAdminClaim] = useAdminClaim();
  const [isImpersonator, loadingImpersonatorClaim] = useImpersonatorClaim();

  return useEffect(() => {
    if (
      !ready ||
      !currentUser ||
      !currentUser.space.id ||
      loadingAdminClaim ||
      loadingImpersonatorClaim
    ) {
      return;
    }

    const { space } = currentUser;
    const { billing } = space;

    Beacon('init', 'e052e3dd-19f2-4eae-bbb3-fcfb9be1a3d2');

    Cio.identify({
      id: currentUser.cioId
    });

    if (
      isAdmin ||
      isImpersonator ||
      currentUser.email?.indexOf('affilimate.com') !== -1 ||
      currentUser.email?.indexOf('affilimate.io') !== -1
    ) {
      // Want to avoid identifying admins as users inside Helpscout Beacon
      return;
    }

    const mixpanel = mp();
    mixpanel.identify(currentUser.cioId);
    mixpanel.set_group('spaces', currentUser.space.id);
    mixpanel.people.set('$email', currentUser.email);
    if (currentUser.displayName) {
      mixpanel.people.set('$name', currentUser.displayName);
    }
    if (currentUser.photoURL) {
      mixpanel.people.set('$avatar', currentUser.photoURL);
    }
    mixpanel.register({
      spaces: [space.id]
    });

    const helpScoutIdentify: any = {
      email: currentUser.email,
      avatar: currentUser.photoURL,
      'space-id': currentUser.space.id,
      plan: billing.activePlan,
      'stripe-customer-id': billing.customerId,
      domains: currentUser.space.domains.map((d) => d.url).join(', ')
    };

    if (currentUser.displayName) {
      helpScoutIdentify.name = currentUser.displayName;
    }

    Beacon('identify', helpScoutIdentify);
    Beacon('prefill', {
      email: currentUser.email
    });
  }, [
    isAdmin,
    loadingAdminClaim,
    isImpersonator,
    loadingImpersonatorClaim,
    currentUser,
    ready
  ]);
};
