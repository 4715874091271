import {
  Dialog,
  DialogContent,
  Step,
  StepLabel,
  StepButton,
  Stepper,
  Button
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { ImportStep } from './components/ImportStep';
import { InstallStep } from './components/InstallStep';
import { ChoosePlatformStep } from './components/ChoosePlatform';
import { useRoutes } from '../../routes';
import { SetupIncomplete } from '../../features/Content/pages/Overview/EmptyState';
import { useCurrentUser } from '../../services/currentUser';
import * as tracking from '../../tracking';
import { SettingsStep } from './components/SettingsStep';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useFeatureEnabled } from '../../services/features';
import { IntegrationsStep } from './components/IntegrationsStep';
import { OffsiteChannelStep } from './components/OffsiteChannelStep';
import { CreateChannelStep } from './components/CreateChannelStep';

type IProps = {
  onClose: () => void;
  domain?: string;
  onVerifyDomain: (domain: string) => Promise<any>;
  onScan: (domains: string[]) => Promise<void>;
  open: boolean;
};

export enum Platform {
  WordPress = 'WordPress',
  Squarespace = 'Squarespace',
  Wix = 'Wix',
  GTM = 'GTM',
  Webflow = 'Webflow',
  Drupal = 'Drupal',
  Other = 'Other CMS',
  Offsite = 'Offsite'
}

type IStep = { key: number; label: string };

const WELCOME_STEPS = [
  { key: 0, label: 'Platform' },
  { key: 1, label: 'Install' },
  { key: 2, label: 'Settings' },
  { key: 3, label: 'Scan' }
];

const ADD_ADDITIONAL_DOMAIN_STEPS = [
  { key: 0, label: 'Platform' },
  { key: 1, label: 'Install' },
  { key: 2, label: 'Settings' },
  { key: 3, label: 'Track links' }
];

const SCANLESS_TRACKING_STEPS = [
  { key: 0, label: 'Platform' },
  { key: 1, label: 'Install' },
  { key: 2, label: 'Settings' },
  { key: 3, label: 'Integrations' }
];

const OFFSITE_CHANNEL_STEPS = [
  { key: 0, label: 'Platform' },
  { key: 1, label: 'Manage channels' },
  { key: 2, label: 'Add new channel' }
];

const toSteps = (
  currentStep: number,
  steps: IStep[],
  setStep: (step: number) => void
) => {
  return (
    <Stepper activeStep={currentStep}>
      {steps.map((step) => (
        <Step key={step.key} completed={step.key < currentStep}>
          <StepButton
            onClick={() => {
              setStep(step.key);
            }}
          >
            <StepLabel>{step.label}</StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
};

export function AddDomainModal({
  onVerifyDomain,
  onScan,
  onClose,
  domain,
  open
}: IProps) {
  const [step, setStep] = useState(0);
  const { ROUTES, goTo } = useRoutes();
  const currentUser = useCurrentUser();
  const firstDomain =
    currentUser.space.domains.length && !currentUser.space.domains[0].verified
      ? currentUser.space.domains[0].url
      : '';
  const [newDomain, setNewDomain] = useState(domain || firstDomain || '');
  const [platform, setPlatform] = useState<null | Platform>(null);
  const [setupLater, setSetupLater] = useState(false);

  const onVerify = async (domain: string) => {
    return Promise.resolve().then(() => {
      setNewDomain(domain);
      tracking.sendOnboardingEvent({
        event: 'Onboarding: Verify first website',
        value: 0
      });
      setStep(2);
    });
  };

  const onScanFn = () => {
    onScan([newDomain]).then(() => onClose());
  };

  const onSetPlatform = (platform: Platform) => {
    setPlatform(platform);
    setStep(1);
  };

  return (
    <Dialog open={open} maxWidth="sm">
      {setupLater && (
        <DialogContent style={{ textAlign: 'center' }}>
          <SetupIncomplete />
          <Button
            color="primary"
            variant="contained"
            size="large"
            style={{ marginBottom: '12px' }}
            onClick={() => {
              setSetupLater(false);
            }}
          >
            Go back to tech setup
          </Button>
          <br />
          <Button
            onClick={() => {
              goTo(ROUTES.performanceNew.transactions.url());
              onClose();
            }}
          >
            Or, add affiliate integrations first
          </Button>
        </DialogContent>
      )}

      {!setupLater && (
        <DialogContent>
          {toSteps(step, WELCOME_STEPS, setStep)}
          {step === 0 && (
            <ChoosePlatformStep domain={newDomain} onClick={onSetPlatform} />
          )}
          {step === 1 && (
            <InstallStep
              domain={newDomain}
              platform={platform}
              onVerify={onVerify}
            />
          )}
          {step === 2 && (
            <ImportStep
              onClose={onClose}
              domain={newDomain}
              onScan={onScanFn}
            />
          )}
          {step === 0 && (
            <div
              style={{ textAlign: 'center', opacity: 0.5, margin: '0 auto' }}
            >
              <Button
                onClick={() => {
                  setSetupLater(true);
                }}
              >
                Or, set up tracking later
              </Button>
            </div>
          )}
        </DialogContent>
      )}
    </Dialog>
  );
}

export function AddAdditionalDomainModal({
  onVerifyDomain,
  onScan,
  onClose,
  domain,
  open
}: IProps) {
  const [step, setStep] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [newDomain, setNewDomain] = useState(domain || '');
  const [platform, setPlatform] = useState<null | Platform>(null);
  const hasScanlessTracking = useFeatureEnabled('SCANLESS_TRACKING');

  const onVerify = (domain: string) => {
    return Promise.resolve().then(() => {
      setNewDomain(domain);
      tracking.sendOnboardingEvent({
        event: 'Onboarding: Verify first website',
        value: 0
      });
      enqueueSnackbar('Your site has been verified!', { variant: 'success' });
      setStep(2);
    });
  };

  const onSetPlatform = (platform: Platform) => {
    setPlatform(platform);
    setStep(1);
  };

  const onScanFn = () => {
    onScan([newDomain]).then(() => onClose());
  };

  const stepsToUse = useMemo(() => {
    const websiteSteps = hasScanlessTracking
      ? SCANLESS_TRACKING_STEPS
      : ADD_ADDITIONAL_DOMAIN_STEPS;
    if (platform === Platform.Offsite) {
      return OFFSITE_CHANNEL_STEPS;
    }
    return websiteSteps;
  }, [hasScanlessTracking, platform]);

  return (
    <Dialog open={open} maxWidth="sm" onClose={onClose} scroll="body">
      <DialogContent>
        {toSteps(step, stepsToUse, setStep)}
        {step === 0 && (
          <ChoosePlatformStep domain={newDomain} onClick={onSetPlatform} />
        )}
        {step === 1 && platform !== Platform.Offsite && (
          <InstallStep
            domain={newDomain}
            platform={platform}
            onVerify={onVerify}
          />
        )}
        {step === 1 && platform === Platform.Offsite && (
          <OffsiteChannelStep
            onContinue={() => {
              setStep(2);
            }}
          />
        )}
        {step === 2 && platform === Platform.Offsite && (
          <CreateChannelStep
            onClose={onClose}
            onContinue={() => {
              setStep(1);
            }}
          />
        )}
        {step === 2 && platform !== Platform.Offsite && (
          <SettingsStep
            domain={newDomain}
            onContinue={() => {
              setStep(3);
            }}
          />
        )}
        {step === 3 &&
          (hasScanlessTracking ? (
            <IntegrationsStep />
          ) : (
            <ImportStep
              onClose={onClose}
              domain={newDomain}
              onScan={onScanFn}
            />
          ))}
      </DialogContent>
    </Dialog>
  );
}
