import {
  ButtonBase,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Popover
} from '@material-ui/core';
import React, {
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import pluralize from 'pluralize';
import { capitalize, isEmpty } from 'lodash';
import { getAppliedLabel } from '../../MultiSelector';
import {
  SelectorChipProps as BaseSelectorChipProps,
  SelectorChip as BaseSelectorChip
} from '../../SelectorChip';
import { css } from '../../../emotion';
import { SearchInput } from '../../SearchInput';
import { LoadingValue } from '../../../services/db';
import { Loader } from '../../Loader';
import Typography from '@material-ui/core/Typography';

interface SelectorProps {
  trigger: ReactNode;
  menu: ReactNode;
  onClose?: () => void;
}

// NOTE: menu should be a render prop, and it should get setOpen('false') as an argument so after clicking anything in menu we can close the popover.
export const Selector: React.FC<SelectorProps> = ({
  trigger,
  menu,
  onClose
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <>
      <ButtonBase onClick={() => setOpen((x) => !x)} ref={ref}>
        {trigger}
      </ButtonBase>
      <Popover
        open={open}
        onClose={() => {
          setOpen(false);
          onClose && onClose();
        }}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        {menu}
      </Popover>
    </>
  );
};

type SelectorChipProps = {
  selectedValues: Set<string>;
  label: string;
  getValue: (v: string) => string;
} & Omit<BaseSelectorChipProps, 'isApplied' | 'appliedLabel' | 'label'>;

export const SelectorChip = ({
  selectedValues,
  label,
  getValue,
  ...props
}: SelectorChipProps) => {
  const valueLabels = useMemo(() => [...selectedValues].map(getValue), [
    getValue,
    selectedValues
  ]);
  return (
    <BaseSelectorChip
      isApplied={selectedValues.size > 0}
      label={pluralize(capitalize(label))}
      appliedLabel={getAppliedLabel(capitalize(label), valueLabels)}
      {...props}
    />
  );
};

interface SelectorMenuProps<T> {
  label: string;
  search: string;
  setSearch: (search: string) => void;
  selectedValues: Set<string>;
  selectValues: (value: Set<string>) => void;
  data: LoadingValue<Array<T>>;
  getId: (value: T) => string;
  renderOption: (value: T) => ReactNode;
  hasMoreOptions?: boolean;
}

export function SelectorMenu<T>({
  label,
  search,
  setSearch,
  selectedValues,
  selectValues,
  data,
  renderOption,
  getId,
  hasMoreOptions
}: SelectorMenuProps<T>) {
  const toggleValue = useCallback(
    (key: string) => {
      const nextValue = new Set(selectedValues);
      if (nextValue.has(key)) {
        nextValue.delete(key);
      } else {
        nextValue.add(key);
      }
      selectValues(nextValue);
    },
    [selectValues, selectedValues]
  );

  return (
    <FormControl
      component="fieldset"
      className={css((t) => ({
        margin: t.spacing(2),
        minWidth: 150,
        maxHeight: 540
      }))}
    >
      <FormLabel
        classes={{
          root: css((t) => ({
            padding: t.spacing(1)
          }))
        }}
        component="legend"
      >
        Filter by {label}
      </FormLabel>
      <div className={css((t) => ({ paddingBottom: t.spacing(1) }))}>
        <SearchInput
          placeholder={`Search ${pluralize(label)}`}
          fullWidth
          value={search}
          onChange={setSearch}
        />
      </div>
      <FormGroup>
        <OptionsList
          data={data}
          selectedValues={selectedValues}
          toggle={toggleValue}
          renderOption={renderOption}
          getId={getId}
          hasMore={hasMoreOptions}
        />
      </FormGroup>
    </FormControl>
  );
}

interface OptionsListProps<T> {
  data: LoadingValue<Array<T>>;
  getId: (value: T) => string;
  renderOption: (value: T) => ReactNode;
  selectedValues: Set<string>;
  toggle: (value: string) => void;
  hasMore?: boolean;
}

function OptionsList<T>({
  data,
  selectedValues,
  toggle,
  renderOption,
  getId,
  hasMore
}: OptionsListProps<T>) {
  const [options, loading] = data;

  if (!options || loading) {
    return <Loader size={24} style={{ marginTop: 20 }} />;
  }

  if (isEmpty(options)) {
    return (
      <Typography
        variant="body2"
        color="textSecondary"
        className={css((t) => ({
          marginTop: t.spacing(2),
          padding: t.spacing(1),
          textAlign: 'center'
        }))}
      >
        Couldn't find any results. Try different filters.
      </Typography>
    );
  }

  const queryOptions = options.map((d) => ({
    label: renderOption(d),
    value: getId(d)
  }));

  return (
    <>
      <FormGroup>
        {queryOptions.map((o) => {
          return (
            <FormControlLabel
              key={o.value}
              control={
                <Checkbox
                  checked={selectedValues.has(o.value)}
                  color="primary"
                  value={o.value}
                  onChange={() => toggle(o.value)}
                />
              }
              classes={{
                label: css(() => ({
                  maxWidth: 350
                })),
                root: css(() => ({
                  marginLeft: 0,
                  marginRight: 0
                }))
              }}
              label={o.label}
            />
          );
        })}
      </FormGroup>
      {hasMore && (
        <Typography
          variant="caption"
          component="span"
          color="textSecondary"
          className={css((t) => ({
            textAlign: 'center',
            marginTop: t.spacing(1.5)
          }))}
          paragraph
        >
          Search to reveal more results
        </Typography>
      )}
    </>
  );
}
