import { useAllPagesQuery } from '../service';

export const FetchAllPages = ({
  children
}: {
  children: (props: { pages: string[] }) => JSX.Element;
}) => {
  const [pages = []] = useAllPagesQuery();
  return children({ pages });
};
