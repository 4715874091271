import { compact, sortBy } from 'lodash';
import { noop } from 'lodash/fp';
import React from 'react';
import {
  PageBody,
  SideNavBack,
  SideNavGroup,
  SideNavHowTo,
  SideNavItem,
  SideNavProps
} from '../../../../layout/PageBody';
import { useRoutes } from '../../../../routes';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../services/currentUser';
import { useFeatureEnabled } from '../../../../services/features';
import { useUserTagGroups } from '../../../../services/tags';
import { useCustomDimensions } from '../../../../services/customDimensions';

type Props = {
  howTo?: SideNavHowTo;
  back?: SideNavBack;
  noTopPadding?: boolean;
};

const useUserGroupLinks = (): SideNavItem[] => {
  const { ROUTES } = useRoutes();
  const [groups, loadingGroups] = useUserTagGroups();
  if (loadingGroups)
    return [
      // TODO: convert SideNavItem to Discriminated union and add case for info/notification
      {
        label: 'Loading your tags...',
        disabled: true,
        onClick: noop
      }
    ];
  if (!groups) return [];

  return sortBy(groups, (t) => t.name).map(({ id, name }) => {
    const path = ROUTES.content.tags.group.url(id);
    return {
      label: name,
      path
    };
  });
};

const useTagGroupLinks = (): SideNavItem[] => {
  const userGroupLinks = useUserGroupLinks();
  const { ROUTES } = useRoutes();
  return [
    ...userGroupLinks,
    {
      label: 'Authors',
      path: ROUTES.content.tags.authors.url()
    }
  ];
};

const useCustomDimensionLinks = (): SideNavGroup | null => {
  const { ROUTES } = useRoutes();
  const scopes = useCurrentUserScopes();
  const canViewCustomDimensions = scopes.has('custom_dimensions.view');
  const hasCustomDimensions = useFeatureEnabled('CUSTOM_DIMENSIONS');
  const [dimensions, loadingDimensions] = useCustomDimensions();
  if (!canViewCustomDimensions || !hasCustomDimensions) return null;
  const manager = {
    label: 'Manage',
    path: ROUTES.content.customDimensions.manage.url()
  };
  if (loadingDimensions || !dimensions) {
    return {
      label: 'Custom',
      items: [
        {
          label: 'Loading custom dimensions...',
          disabled: true,
          onClick: noop
        },
        manager
      ]
    };
  }
  const dimensionRoutes = Object.entries(dimensions).map(
    ([slot, dimension]) => {
      if (!dimension) return null;
      return {
        label: dimension.name,
        path: ROUTES.content.customDimensions.slot.url(slot)
      };
    }
  );
  const dimensionItems = sortBy(compact(dimensionRoutes), (d) =>
    d.label.toLocaleLowerCase()
  );
  return {
    label: 'Custom',
    items: [...dimensionItems, manager]
  };
};

export const ContentPageBody: React.FC<Props> = ({
  howTo,
  back,
  noTopPadding,
  children
}) => {
  const { ROUTES } = useRoutes();
  const scopes = useCurrentUserScopes();
  const groupLinks = useTagGroupLinks();
  const { space } = useCurrentUser();
  const customDimensionsGroup = useCustomDimensionLinks();
  const canViewContent = scopes.has('reports.content.view');
  const canViewTags = scopes.has('reports.tags.view');
  const canViewTagList = scopes.has('tags.view');
  const canViewTrafficSources = scopes.has('reports.traffic_sources.view');
  const canViewUtms = scopes.has('reports.utms.view');
  const canViewRecommendations = space.id === '4oF26UAIvKcsv6YOxO7gtvrifrn2';

  const sideNav: SideNavProps = {
    groups: compact([
      canViewContent && {
        label: 'Content',
        items: compact([
          {
            label: 'Trends',
            path: ROUTES.content.overview_v2.url()
          },
          canViewTrafficSources && {
            label: 'Referrers',
            path: ROUTES.content.referrers.overview.url()
          },
          canViewUtms && {
            label: 'UTMs',
            path: ROUTES.content.utm.details.url('campaign')
          }
        ])
      },
      canViewRecommendations && {
        label: 'Insights',
        items: [
          {
            label: 'Recommendations',
            path: ROUTES.recommendations.overview.url()
          }
        ]
      },
      (canViewTags || canViewTagList) && {
        label: 'Tags',
        items: compact([
          ...(canViewTags ? groupLinks : []),
          canViewTagList && {
            label: 'Manage',
            path: ROUTES.content.tags.manage.url()
          }
        ])
      },
      customDimensionsGroup,
      canViewContent && {
        label: 'Legacy',
        items: [
          {
            label: 'Trends (v1)',
            path: ROUTES.content.overview.url()
          }
        ]
      }
    ]),
    back,
    howTo
  };
  return (
    <PageBody sideNav={sideNav} noTopPadding={noTopPadding}>
      {children}
    </PageBody>
  );
};
