import React from 'react';
import { SingleSelector } from '../../../../components/SingleSelector';
import { metricTitle } from '../../../../services/analyticsV2/metrics';
import { ChevronDown, TrendingUp } from 'react-feather';
import { Typography } from '@material-ui/core';
import { CampaignGoalType } from '../../../../domainTypes/campaigns';
import { useQueryParam } from '../../../../routes';
import { ReadyCampaign } from '../../service';

const metrics = ['gmv_sum_net', 'c', 'p'] as const;

export type CampaignMetric = typeof metrics[number];

const isCampaignMetric = (value: unknown): value is CampaignMetric =>
  metrics.includes(value as CampaignMetric);

const options = metrics.map((metric) => ({
  value: metric,
  label: metricTitle(metric),
  searchValue: metricTitle(metric)
}));

const goalTypeToMetric = (
  type: CampaignGoalType | undefined
): CampaignMetric => {
  switch (type) {
    case 'clicks':
      return 'c';
    case 'pageviews':
      return 'p';
    case 'gmv':
    case 'roas':
      return 'gmv_sum_net';
    default:
      return 'gmv_sum_net';
  }
};

export const useCampaignMetricSelectorState = (campaign: ReadyCampaign) => {
  const firstGoalMetric = goalTypeToMetric(campaign.goals[0]?.type);
  return useQueryParam<CampaignMetric>(
    'metric',
    (metric) => (isCampaignMetric(metric) ? metric : firstGoalMetric),
    (metric) => metric
  );
};

export const CampaignMetricSelector: React.FC<{
  metric: CampaignMetric;
  setMetric: (metric: CampaignMetric) => void;
}> = ({ setMetric, metric }) => {
  return (
    <SingleSelector value={metric} onChange={setMetric} options={options}>
      <Typography
        variant="body2"
        color="textSecondary"
        component="span"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '6px'
        }}
      >
        <TrendingUp size={18} /> Show <strong>{metricTitle(metric)}</strong>
        <ChevronDown size={18} />
      </Typography>
    </SingleSelector>
  );
};
