import React from 'react';
import { ChevronDown as IconExpandMore } from 'react-feather';
import { styled } from '../../emotion';

export type ArrowDirection = 'UP' | 'RIGHT' | 'DOWN' | 'LEFT';
type ArrowProps = {
  dir: ArrowDirection;
};

type DEGRESS = { [key in ArrowDirection]: number };
const degrees: DEGRESS = {
  UP: 180,
  RIGHT: -90,
  DOWN: 0,
  LEFT: 90
};

const Icon = styled<typeof IconExpandMore, ArrowProps>(IconExpandMore)`
  transition: ${(p) =>
    p.theme.transitions.create('transform', {
      duration: p.theme.transitions.duration.shortest
    })};
  transform: rotate(${(p) => degrees[p.dir as ArrowDirection]}deg);
`;

export type Props = React.ComponentProps<typeof Icon>;

export const Arrow = (props: Props) => {
  // @ts-ignore
  return <Icon {...props} />;
};
