import { styled } from '../../../../../emotion';

export const PayoutId = styled('span')`
  display: inline-block;
  color: #616161;
  background-color: #eee;
  font-size: 10px;
  line-height: 18px;
  padding: 0 5px;
  border-radius: 5px;
  height: 19px;
  font-family: Menlo, Consolas, Courier;
`;
