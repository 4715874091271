import Tooltip from '@material-ui/core/Tooltip';
import pluralize from 'pluralize';
import { SelectionBox } from '../../../../../components/SelectionBox';
import React from 'react';

interface SelectorProps {
  pageUrl: string;
  isAllPagesSelected: boolean;
  pages: Set<string>;
  togglePage: (page: string) => void;
}

export const Selector: React.FC<SelectorProps> = ({
  pageUrl,
  isAllPagesSelected,
  togglePage,
  pages
}) => {
  return (
    <SelectionBox
      disabled={isAllPagesSelected}
      value={isAllPagesSelected || pages.has(pageUrl)}
      onChange={() => togglePage(pageUrl)}
    />
  );
};

interface HeadSelectorProps {
  toggleAllPages: () => void;
  isAllPagesSelected: boolean;
  allPagesCount: number;
}

export const HeadSelector: React.FC<HeadSelectorProps> = ({
  toggleAllPages,
  isAllPagesSelected,
  allPagesCount
}) => {
  return (
    <Tooltip
      title={`Select all ${pluralize('pages', allPagesCount, true)}`}
      placement="top"
    >
      <SelectionBox value={isAllPagesSelected} onChange={toggleAllPages} />
    </Tooltip>
  );
};
