import React from 'react';
import { styled } from '../../emotion';
import { TopNavBarSection } from '../../components/TopNavBarSection';
import { CheckoutDialog } from '../../features/Billing/components/CheckoutDialog';

import { useDialogState } from '../../hooks/useDialogState';
import { humanizeDuration } from '../../services/time';

import {
  hasTrialExpired,
  isInTrialIncludingGracePeriod
} from '../../domainTypes/billing';

import { useCurrentUser } from '../../services/currentUser';

const BillingNavBarSection = styled<
  typeof TopNavBarSection,
  { expired: boolean }
>(TopNavBarSection)`
  color: ${(p) =>
    p.expired
      ? p.theme.custom.colors.error.light
      : p.theme.custom.colors.pending.dark};
  background-color: ${(p) => p.theme.custom.colors.pending.light};
`;

const BillingInfoPlan = styled('div')`
  font-size: ${(p) => p.theme.custom.fontSize.m}px;
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
`;
const BillingInfoPlanSubscript = styled('div')`
  font-size: ${(p) => p.theme.custom.fontSize.s}px;
`;

export const BillingStatus = () => {
  const { space } = useCurrentUser();
  const { dialogOpen, closeDialog } = useDialogState(false);
  const now = Date.now();
  const { trialUntil, gracePeriod } = space.billing;
  if (!trialUntil || !isInTrialIncludingGracePeriod(space.billing, now)) {
    return null;
  }
  const expired = hasTrialExpired(space.billing, now);

  const title = expired ? 'Trial expired' : 'Trial';
  const subscript = expired
    ? `Lock out in ${humanizeDuration(trialUntil + gracePeriod - now)}`
    : `Ends in ${humanizeDuration(trialUntil - now)}`;

  return (
    <>
      <BillingNavBarSection expired={expired} minWidth={175} maxWidth={360}>
        <BillingInfoPlan>{title}</BillingInfoPlan>
        <BillingInfoPlanSubscript>{subscript}</BillingInfoPlanSubscript>
      </BillingNavBarSection>
      <CheckoutDialog open={dialogOpen} onClose={closeDialog} />
    </>
  );
};
