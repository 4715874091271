import { LoadingValue, useMappedLoadingValue } from '../../../services/db';
import {
  calculateEarningsGoal,
  campaignQueryBase,
  getClicksGoal,
  getFlatSpendAmount,
  getPageviewsGoal,
  ReadyCampaign
} from './index';
import { useCurrentUser } from '../../../services/currentUser';
import { useMemo } from 'react';
import { AnalyticsQuery } from '../../../domainTypes/analytics_v2';
import { useAnalyticsQueryV2 } from '../../../services/analyticsV2/query';

export type CampaignMetricWithGoal = {
  value: number;
  goal?: {
    value: number;
    percentage: number;
  };
};

export const hasGoal = (
  metric: CampaignMetricWithGoal
): metric is Required<CampaignMetricWithGoal> => {
  return !!metric.goal;
};

export type CampaignTotalWithGoal = 'c' | 'p' | 'gmv';
export type CampaignTotal =
  | 'v'
  | 'commission'
  | 'flatSpend'
  | 'aov'
  | 'orderCount';

export type CampaignTotalsObject = {
  [key in CampaignTotalWithGoal]: CampaignMetricWithGoal;
} &
  {
    [key in CampaignTotal]: number;
  };

export type CampaignTotals = LoadingValue<CampaignTotalsObject>;

export const useCampaignTotals = (campaign: ReadyCampaign): CampaignTotals => {
  const { space } = useCurrentUser();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      ...campaignQueryBase(campaign),
      select: [
        'c',
        'p',
        'v',
        'commission_sum_net',
        'gmv_sum_net',
        'aov_net',
        'order_count_net'
      ]
    };
  }, [campaign]);
  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (response) => {
      const data = response.rows[0].data;
      const gmvGoal = calculateEarningsGoal(campaign);
      const clickGoal = getClicksGoal(campaign);
      const pvGoal = getPageviewsGoal(campaign);
      const gmv = data.gmv_sum_net?.curr ?? 0;
      const clicks = data.c?.curr ?? 0;
      const pageviews = data.p?.curr ?? 0;
      const flatSpend = getFlatSpendAmount(campaign);
      return {
        commission: data.commission_sum_net?.curr ?? 0,
        gmv: {
          value: gmv,
          goal: gmvGoal
            ? {
                value: gmvGoal,
                percentage: gmv / gmvGoal
              }
            : undefined
        },
        flatSpend,
        aov: data.aov_net?.curr ?? 0,
        orderCount: data.order_count_net?.curr ?? 0,
        c: {
          value: clicks,
          goal: clickGoal
            ? {
                value: clickGoal,
                percentage: clicks / clickGoal
              }
            : undefined
        },
        p: {
          value: pageviews,
          goal: pvGoal
            ? {
                value: pvGoal,
                percentage: pageviews / pvGoal
              }
            : undefined
        },
        v: data.v?.curr ?? 0
      };
    }
  );
};
