import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  TooltipProps,
  Typography
} from '@material-ui/core';
import { DatePicker, DateTimePicker } from '@material-ui/pickers';
import { actions } from '@storybook/addon-actions';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Camera } from 'react-feather';
import { Link } from 'react-router-dom';
import { AlertBox } from '../../../../components/AlertBox';
import { BlurredOutOverlay } from '../../../../components/BlurredOutOverlay';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import {
  getPlanType2024ForSpace,
  PlanType2024,
  planType2024ToString
} from '../../../../domainTypes/billing';
import { CreateRevisionManuallyArgs } from '../../../../domainTypes/page';
import { ISpace } from '../../../../domainTypes/space';
import { Timestamp } from '../../../../domainTypes/time';
import { useDialogState } from '../../../../hooks/useDialogState';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { FlexContainer, FlexContainerVertical } from '../../../../layout/Flex';
import { useRoutes } from '../../../../routes';
import { useImpersonatorClaim } from '../../../../services/auth';
import { useFeatureEnabled } from '../../../../services/features';
import { callFirebaseFunction } from '../../../../services/firebaseFunctions';
import { joinWithCommaAndOr } from '../../../../services/lists';
import { fromMoment, toMoment, useTzAbbr } from '../../../../services/time';
import { CF } from '../../../../versions';

export const createManualRevision = (
  spaceId: string,
  pageUrl: string,
  modificationDate: Timestamp,
  opts: { createMissingPageMetadataDocument?: boolean } = {}
) => {
  return callFirebaseFunction<
    {
      screenshotState: 'STARTED' | 'QUEUED';
    },
    CreateRevisionManuallyArgs
  >(CF.pages.createRevisionManually, {
    spaceId,
    pageUrl,
    modificationDate: modificationDate.toMillis(),
    immediatelyCollectScreenshot: true,
    createMissingPageMetadataDocument: !!opts.createMissingPageMetadataDocument
  });
};

const REQUIRED_PLAN_TYPE: PlanType2024[] = [
  PlanType2024.PORTFOLIO,
  PlanType2024.ENTERPRISE
];

export const useCanCreateManualRevisions = (space: ISpace) => {
  const planType = getPlanType2024ForSpace(space);
  const [isImpersonator] = useImpersonatorClaim();
  return REQUIRED_PLAN_TYPE.includes(planType) || isImpersonator;
};

export const RevisionCreateDialog = ({
  space,
  pageUrl,
  minDate,
  open,
  onClose
}: {
  space: ISpace;
  pageUrl: string;
  open: boolean;
  onClose: () => void;
  minDate: Timestamp;
}) => {
  const { tz } = space.config;
  const { enqueueSnackbar } = useSnackbar();
  const canSetTime = useFeatureEnabled('REFERRER_REPORTS_V1');
  const [
    nextRevisionDate,
    setNextRevisionDate
  ] = useState<moment.Moment | null>(null);
  const tzString = useTzAbbr(tz);
  const canCreateManualRevisions = useCanCreateManualRevisions(space);
  const { ROUTES } = useRoutes();
  return (
    <Dialog open={open} onClose={onClose}>
      {!canCreateManualRevisions && (
        <BlurredOutOverlay>
          <FlexContainerVertical fullWidth alignItems="center" spacing={2}>
            <div>
              This feature is only available on a{' '}
              <strong>
                {joinWithCommaAndOr(
                  REQUIRED_PLAN_TYPE.map(planType2024ToString)
                )}
              </strong>{' '}
              plan.
            </div>
            <Link to={ROUTES.settings.billing.url()}>
              <Button variant="contained" color="primary">
                Go to your Billing settings
              </Button>
            </Link>
          </FlexContainerVertical>
        </BlurredOutOverlay>
      )}
      <DialogTitle>Create a manual revision and heatmap</DialogTitle>
      <DialogContent>
        <FlexContainerVertical fullWidth>
          <Typography variant="body1" paragraph>
            Revisions and heatmaps are automatically created every night at
            midnight in your account timezone, based on your page's schema data.
          </Typography>
          <Typography variant="body1" paragraph>
            But you can create a manual revision if you've updated your page,
            but intentionally did not change the schema data. For example, if it
            was a minor change like reordering existing links or adjusting copy.
          </Typography>

          <Typography variant="body1" paragraph>
            <strong>
              What {canSetTime ? `time` : `date`} did the revision occur?
            </strong>
          </Typography>

          <FlexContainer alignItems="center">
            {canSetTime ? (
              <DateTimePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                value={nextRevisionDate}
                clearable={false}
                format={'LL HH:mm'}
                minDate={toMoment(minDate).tz(tz)}
                label="Revision Date"
                onChange={(e) => setNextRevisionDate(e as moment.Moment)}
              />
            ) : (
              <DatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                value={nextRevisionDate}
                clearable={false}
                format={'LL HH:mm'}
                minDate={toMoment(minDate).tz(tz)}
                label="Revision Date"
                onChange={(e) =>
                  setNextRevisionDate((e as moment.Moment).startOf('day'))
                }
              />
            )}
            <Typography variant="body2" color="textSecondary">
              {tzString}
            </Typography>
          </FlexContainer>

          <AlertBox variant="primary">
            <Typography variant="body2">
              <strong>Keep in mind:</strong> Regardless of the date you provide,
              the heatmap will reflect how the page looks right now.
            </Typography>
          </AlertBox>
        </FlexContainerVertical>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <ButtonWithPromise
          variant="contained"
          color="primary"
          pending={'Creating...'}
          disabled={!nextRevisionDate}
          onClick={async () => {
            if (!canCreateManualRevisions || !nextRevisionDate) {
              return;
            }
            try {
              await createManualRevision(
                space.id,
                pageUrl,
                fromMoment(nextRevisionDate)
              );
              enqueueSnackbar('New revision created!', { variant: 'success' });
              onClose();
            } catch (err) {
              console.error(err);
              enqueueSnackbar('Failed to create a new revision', {
                variant: 'error'
              });
            }
          }}
        >
          Create manual revision
        </ButtonWithPromise>
        {actions}
      </DialogActions>
    </Dialog>
  );
};

export const RevisionCreateIconButton = ({
  space,
  pageUrl,
  minDate,
  size,
  tooltipTitle,
  tooltipPlacement,
  disabled
}: {
  space: ISpace;
  pageUrl: string;
  minDate: Timestamp;
  size?: number;
  tooltipTitle?: TooltipProps['title'];
  tooltipPlacement?: TooltipProps['placement'];
  disabled?: boolean;
}) => {
  const dialog = useDialogState();
  return (
    <>
      <Tooltip title={tooltipTitle || ''} placement={tooltipPlacement}>
        <IconButton onClick={dialog.openDialog} disabled={disabled}>
          <Camera size={size} />
        </IconButton>
      </Tooltip>
      <RevisionCreateDialog
        space={space}
        pageUrl={pageUrl}
        minDate={minDate}
        open={dialog.dialogOpen}
        onClose={dialog.closeDialog}
      />
    </>
  );
};

export const RevisionCreateButton = ({
  space,
  pageUrl,
  minDate,
  disabled
}: {
  space: ISpace;
  pageUrl: string;
  minDate: Timestamp;
  disabled?: boolean;
}) => {
  const dialog = useDialogState();
  return (
    <>
      <Button
        variant="contained"
        color="default"
        onClick={() => dialog.openDialog()}
        disabled={disabled}
      >
        Create manual revision...
      </Button>
      <RevisionCreateDialog
        space={space}
        pageUrl={pageUrl}
        open={dialog.dialogOpen}
        onClose={dialog.closeDialog}
        minDate={minDate}
      />
    </>
  );
};
