import { AppBar, Drawer, IconButton, Button } from '@material-ui/core';
import React, { useState } from 'react';
import { ArrowLeft as IconArrowLeft, X as IconX } from 'react-feather';
import { Loader } from '../../../../components/Loader';
import { styled } from '../../../../emotion';
import { useNotificationsDrawer } from '../../hooks/useNotificationsDrawer';
import {
  useAllNotifications,
  useRecentNotifications as useUnseenNotifications,
  markAllNotificationsAsSeen
} from '../../services';
import { Empty } from './Empty';
import { NotificationList } from '../List';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';

const PAPER_PROPS = {
  style: { width: 500, maxWidth: '100%', backgroundColor: '#f9f9f9' }
};

const Container = styled('div')`
  position: relative;
  height: 100%;
  padding: ${(p) => p.theme.spacing(2)}px;
`;

const BarContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(p) => p.theme.spacing(2)}px;
`;

const Title = styled('div')`
  font-size: 18px;
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
  display: flex;
  align-items: center;
`;

const ActionBar = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing(1)}px;
`;

const RecentNotifications = ({
  userId,
  onClose,
  onShowHistory
}: {
  userId: string;
  onClose: () => void;
  onShowHistory: () => void;
}) => {
  const [notifications, loading] = useUnseenNotifications(userId);
  return (
    <>
      <AppBar
        position="sticky"
        elevation={1}
        style={{ backgroundColor: 'white', color: 'black' }}
      >
        <BarContainer>
          <TitleWrapper>
            <Title>Alerts</Title>
            {!loading && (
              <Button
                size="small"
                variant="text"
                onClick={onShowHistory}
                style={{ opacity: 0.6 }}
              >
                Show history
              </Button>
            )}
          </TitleWrapper>
          <IconButton onClick={onClose} size="small" color="inherit">
            <IconX />
          </IconButton>
        </BarContainer>
      </AppBar>
      <Container>
        {loading && <Loader height={300} />}
        {notifications && !notifications.length && <Empty />}
        {notifications && !!notifications.length && (
          <>
            <ActionBar>
              <div />
              <ButtonWithPromise
                size="small"
                color="primary"
                variant="outlined"
                onClick={() => markAllNotificationsAsSeen(userId)}
                pending="Mark all as read"
              >
                Mark all as read
              </ButtonWithPromise>
            </ActionBar>
            <NotificationList notifications={notifications} />
          </>
        )}
      </Container>
    </>
  );
};

const EmptyHistory = styled('div')`
  padding: ${(p) => p.theme.spacing(5)}px 0;
  color: ${(p) => p.theme.palette.text.hint};
  font-size: ${(p) => p.theme.custom.fontSize.s}px;
  text-align: center;
`;

const History = ({
  userId,
  onClose,
  onBack
}: {
  userId: string;
  onClose: () => void;
  onBack: () => void;
}) => {
  const [notifications, loading] = useAllNotifications(userId, 200);
  return (
    <>
      <AppBar
        position="sticky"
        elevation={1}
        style={{ backgroundColor: 'white', color: 'black' }}
      >
        <BarContainer>
          <Title>
            <IconButton
              onClick={onBack}
              size="small"
              color="inherit"
              style={{ marginRight: '12px' }}
            >
              <IconArrowLeft />
            </IconButton>
            Alert History
          </Title>
          <IconButton onClick={onClose} size="small" color="inherit">
            <IconX />
          </IconButton>
        </BarContainer>
      </AppBar>
      <Container>
        {loading && <Loader height={300} />}
        {notifications && !notifications.length && (
          <EmptyHistory>No alerts have made their way here yet</EmptyHistory>
        )}
        {notifications && !!notifications.length && (
          <NotificationList notifications={notifications} />
        )}
      </Container>
    </>
  );
};

const Body = ({ userId, onClose }: { userId: string; onClose: () => void }) => {
  const [showHistory, setShowHistory] = useState(false);
  return showHistory ? (
    <History
      userId={userId}
      onClose={onClose}
      onBack={() => setShowHistory(false)}
    />
  ) : (
    <RecentNotifications
      userId={userId}
      onClose={onClose}
      onShowHistory={() => setShowHistory(true)}
    />
  );
};

export const NotificationsDrawer = ({ userId }: { userId: string }) => {
  const [open, setOpen] = useNotificationsDrawer();
  const close = () => setOpen(false);
  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      onClose={close}
      PaperProps={PAPER_PROPS}
    >
      <Body userId={userId} onClose={close} />
    </Drawer>
  );
};
