import { Button, Typography } from '@material-ui/core';
import React from 'react';
import styled from '../../../../emotion/styled';
//import * as tracking from '../../../../tracking';
import { useScans } from '../../../../services/scan';
import { toDomain } from '../../../../services/url';
import { useRoutes } from '../../../../routes';

const Body = styled('div')`
  text-align: center;
  margin: ${(p) => p.theme.spacing() * 2}px;
`;

const Img = styled('img')`
  width: 250px;
  display: block;
  margin: 0 auto ${(p) => p.theme.spacing() * 2}px;
`;

type IProps = {
  onScan: () => void;
  domain: string;
  onClose: () => void;
};

export function ImportStep({ onScan, domain, onClose }: IProps) {
  const [scans, loading] = useScans(5);
  const { ROUTES, goTo } = useRoutes();
  const recentScan = scans
    ? scans.find((s) =>
        s.data.targets.find((t) => toDomain(t.url) === toDomain(domain))
      )
    : null;

  return (
    <div>
      <Body>
        <Img src="/images/empty-states/scan.svg" />
        <Typography paragraph variant="h5">
          Import your affiliate links
        </Typography>
        <Typography paragraph>
          We will scan your website for links that we believe are affiliate
          links. You will then be able to accept or reject our suggestions.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={() => {
            if (recentScan) {
              goTo(ROUTES.productScans.details.url(recentScan.id));
              onClose();
            } else {
              return onScan();
            }
          }}
        >
          Begin site scan
        </Button>
      </Body>
    </div>
  );
}
