import { YAxis, YAxisProps } from 'recharts';
import { formatMetric, Metric } from '../../../services/analyticsV2/metrics';
import { ChartContext } from './chart-context';
import React from 'react';

type CustomYAxisProps = Omit<YAxisProps, 'tick'> & {
  tick?: object;
};

export const yAxis = (
  metric: Metric,
  orientation: 'left' | 'right',
  { currency }: ChartContext,
  { tick = {}, ...restYAxisProps }: Partial<CustomYAxisProps> = {}
) => {
  const tickTransform =
    orientation === 'left' ? `translate(-8, 0)` : `translate(8, 0)`;
  return (
    <YAxis
      key="timeseries-y-axis"
      yAxisId={metric}
      tick={{
        fill: '#9b9b9b',
        transform: tickTransform,
        fontSize: 14,
        ...tick
      }}
      tickFormatter={(value: number) => formatMetric(value, metric, currency)}
      // FIXME
      allowDecimals={metric === 'epc_net'}
      stroke="none"
      tickSize={0}
      orientation={orientation}
      {...restYAxisProps}
    />
  );
};
