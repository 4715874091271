import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import {
  LoadingValue,
  setDoc,
  store,
  useMappedLoadingValue
} from '../../../services/db';
import { FS } from '../../../versions';
import { Doc, generateToDocFn } from '../../../domainTypes/document';
import {
  CampaignFlatSpendIncentive,
  CampaignGoal,
  CampaignGoalType,
  CampaignIncentive,
  CampaignRateIncreaseIncentive,
  ICampaign,
  isOffsiteLink
} from '../../../domainTypes/campaigns';
import { useCurrentUser } from '../../../services/currentUser';
import { compact, isEmpty, Omit } from 'lodash';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from '../../../services/firecache/documentListener';
import {
  AnalyticsFilter,
  ISOTimeRange
} from '../../../domainTypes/analytics_v2';
import { toMoment } from '../../../services/time';
import { Moment } from 'moment-timezone';
import { removeTrailingSlash } from '../../../services/url';

const toCampaignDoc = generateToDocFn<ICampaign>();

const campaignsStore = (spaceId: string) =>
  store().collection(FS.campaigns).where('spaceId', '==', spaceId);

const campaignsCollectionListener = createCollectionListenerStore(
  (spaceId) => new CollectionListener(campaignsStore(spaceId), toCampaignDoc)
);

export const useCampaignsCollection = (spaceId: string) =>
  useCollectionListener(campaignsCollectionListener(spaceId));

export const campaignStatuses = [
  'planning',
  'scheduled',
  'running',
  'completed'
] as const;

export const activeCampaignStatuses = [
  'planning',
  'scheduled',
  'running'
] as const;

export const campaignStatusTitle = (status: CampaignStatus) => {
  switch (status) {
    case 'planning':
      return 'Planning';
    case 'scheduled':
      return 'Scheduled';
    case 'running':
      return 'Running';
    case 'completed':
      return 'Completed';
  }
};

export type CampaignStatus = typeof campaignStatuses[number];

interface BaseCampaign extends Omit<ICampaign, 'timeframe'> {}

type PlannedCampaign = BaseCampaign & {
  status: 'planning';
  timeframe?: {
    start: Moment;
    end: Moment;
  };
};

export type ScheduledCampaign = BaseCampaign & {
  status: 'scheduled';
  timeframe: {
    start: Moment;
    end: Moment;
  };
};

export type RunningCampaign = BaseCampaign & {
  status: 'running';
  timeframe: {
    start: Moment;
    end: Moment;
  };
};

export type CompletedCampaign = BaseCampaign & {
  status: 'completed';
  timeframe: {
    start: Moment;
    end: Moment;
  };
};

export type Campaign =
  | PlannedCampaign
  | ScheduledCampaign
  | RunningCampaign
  | CompletedCampaign;

export type ReadyCampaign = RunningCampaign | CompletedCampaign;

export const isCampaignRunning = (
  campaign: Campaign
): campaign is RunningCampaign => campaign.status === 'running';

export const isCampaignComplete = (
  campaign: Campaign
): campaign is CompletedCampaign => campaign.status === 'completed';

// Bad name
export const isCampaignReady = (
  campaign: Campaign
): campaign is ReadyCampaign =>
  campaign.status === 'running' || campaign.status === 'completed';

export const isCampaignActive = (
  campaign: Campaign
): campaign is ScheduledCampaign | RunningCampaign | PlannedCampaign => {
  return (
    campaign.status === 'planning' ||
    campaign.status === 'scheduled' ||
    campaign.status === 'running'
  );
};

export const isCampaignAtLeastScheduled = (
  campaign: Campaign
): campaign is ScheduledCampaign | RunningCampaign | CompletedCampaign =>
  campaign.status === 'scheduled' ||
  campaign.status === 'running' ||
  campaign.status === 'completed';

export const campaignQueryBase = ({
  timeframe,
  links,
  pageUrls
}: ReadyCampaign): {
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
} => ({
  range: {
    start: timeframe.start.toISOString(),
    end: timeframe.end.toISOString()
  },
  filters: compact([
    links.length > 0 && {
      field: 'link_id',
      condition: 'in',
      values: links.map((l) => l.id)
    },
    pageUrls.length > 0 && {
      field: 'page_url',
      condition: 'in',
      values: [
        ...pageUrls.map(removeTrailingSlash),
        // can't use lodash.compact, because "''" is falsy
        ...(links.some(isOffsiteLink) ? [''] : [])
      ]
    }
  ])
});

const isFlatSpend = (
  incentive: CampaignIncentive
): incentive is CampaignFlatSpendIncentive => incentive.type === 'flatSpend';

export const isRateIncreaseCampaignIncentive = (
  incentive: CampaignIncentive
): incentive is CampaignRateIncreaseIncentive =>
  incentive.type === 'rateIncrease';

export const getFlatSpend = (campaign: Campaign) =>
  campaign.incentives.find(isFlatSpend);

export const getFlatSpendAmount = (campaign: Campaign) =>
  getFlatSpend(campaign)?.amount ?? 0;

const getCampaignGoal = (
  campaign: Campaign,
  type: CampaignGoalType
): CampaignGoal | undefined => {
  return campaign.goals.find((g) => g.type === type);
};

export const hasROASGoal = (campaign: Campaign) => {
  return !!getCampaignGoal(campaign, 'roas');
};

export const hasGMVGoal = (campaign: Campaign) => {
  return !!getCampaignGoal(campaign, 'gmv');
};

export const getCampaignGoalForMetric = (
  campaign: Campaign,
  metric: 'gmv_sum_net' | 'c' | 'p'
) => {
  switch (metric) {
    case 'gmv_sum_net':
      return calculateEarningsGoal(campaign);
    case 'c':
      return getClicksGoal(campaign);
    case 'p':
      return getPageviewsGoal(campaign);
  }
};

export const getClicksGoal = (campaign: Campaign): number | null => {
  const clicks = getCampaignGoal(campaign, 'clicks');
  return clicks ? clicks.amount : null;
};

export const getPageviewsGoal = (campaign: Campaign): number | null => {
  const pageviews = getCampaignGoal(campaign, 'pageviews');
  return pageviews ? pageviews.amount : null;
};

export const calculateEarningsGoal = (campaign: Campaign): number | null => {
  const roas = getCampaignGoal(campaign, 'roas');
  if (roas) {
    const flatSpend = getFlatSpendAmount(campaign);
    return roas.amount * flatSpend;
  }
  const gmv = getCampaignGoal(campaign, 'gmv');
  if (gmv) {
    return gmv.amount;
  }
  return null;
};

// Type is used to model changing schema of ICampaign
type PartialICampaign = Pick<ICampaign, 'spaceId' | 'id'> &
  Partial<Omit<ICampaign, 'spaceId' | 'id'>>;

export const readCampaign = (partialCampaign: PartialICampaign): Campaign => {
  const campaign: ICampaign = {
    ...DEFAULT_CAMPAIGN,
    ...partialCampaign
  };

  const { timeframe: rawTimeframe, pageUrls, links } = campaign;
  const timeframe = rawTimeframe
    ? {
        start: toMoment(rawTimeframe.start),
        end: toMoment(rawTimeframe.end)
      }
    : undefined;

  if (!rawTimeframe || !timeframe || isEmpty(pageUrls) || isEmpty(links)) {
    return {
      ...campaign,
      timeframe,
      status: 'planning'
    };
  }
  const now = Date.now();
  if (now < rawTimeframe.start.toMillis()) {
    return {
      ...campaign,
      status: 'scheduled',
      timeframe
    };
  }
  if (now < rawTimeframe.end.toMillis()) {
    return {
      ...campaign,
      status: 'running',
      timeframe
    };
  }
  return {
    ...campaign,
    status: 'completed',
    timeframe
  };
};

export const useCampaigns = (): LoadingValue<Campaign[]> => {
  const { space } = useCurrentUser();
  return useMappedLoadingValue(useCampaignsCollection(space.id), (campaigns) =>
    campaigns.map(({ data }) => readCampaign(data))
  );
};

const DEFAULT_CAMPAIGN: Omit<ICampaign, 'spaceId' | 'id'> = {
  name: '',
  advertisers: [],
  managers: [],
  incentives: [],
  goals: [],
  timeframe: null,
  pageUrls: [],
  links: []
};

export const EMPTY_CAMPAIGN = (spaceId: string, id: string): ICampaign => ({
  spaceId,
  id,
  ...DEFAULT_CAMPAIGN
});

const toCampaignDocId = (spaceId: string, campaignId: string) =>
  `${spaceId}_${campaignId}`;

export const removeCampaign = async (spaceId: string, id: string) =>
  store().collection(FS.campaigns).doc(toCampaignDocId(spaceId, id)).delete();

export const saveCampaign = async (
  spaceId: string,
  id: string,
  campaign: Omit<ICampaign, 'id' | 'spaceId'>
) => {
  const campaignToSave: Doc<ICampaign> = {
    collection: FS.campaigns,
    id: toCampaignDocId(spaceId, id),
    data: {
      ...campaign,
      id,
      spaceId
    }
  };
  return setDoc(campaignToSave);
};

const campaignStore = createDocumentListenerGetter(
  (docId: string) => store().collection(FS.campaigns).doc(docId),
  toCampaignDoc
);

export const useCampaign = (slug: string): LoadingValue<Campaign | null> => {
  const { space } = useCurrentUser();
  return useMappedLoadingValue(
    useDocumentListener(campaignStore(toCampaignDocId(space.id, slug))),
    (campaign) => {
      if (!campaign) {
        return null;
      }
      return readCampaign(campaign.data);
    }
  );
};

export const useCampaignWithDefault = (id: string) => {
  const { space } = useCurrentUser();
  return useMappedLoadingValue(
    useDocumentListener(campaignStore(toCampaignDocId(space.id, id))),
    (campaign) => {
      if (!campaign) {
        return EMPTY_CAMPAIGN(space.id, id);
      }
      return campaign.data;
    }
  );
};
