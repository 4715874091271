import Typography from '@material-ui/core/Typography';
import React from 'react';

export const ErrorMessage = () => (
  <Typography
    variant="body1"
    component="p"
    paragraph
    style={{ textAlign: 'center', maxWidth: '80%' }}
  >
    <strong>Couldn't load referrers data</strong>
    <br />
    <br />
    Try refreshing the page. If you expect to see data here and the error
    persists, please contact Support with the URL to this page.
  </Typography>
);
