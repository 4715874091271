import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography
} from '@material-ui/core';
import { compact } from 'lodash';
import moment from 'moment-timezone';
import { SnackbarProvider } from 'notistack';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AdditionalActionsMenu } from '../../../../components/AdditionalActionsMenu';
import {
  ItemSorters,
  RowsRenderer,
  ROW_HEIGHTS,
  useSortQueryParam
} from '../../../../components/GroupableList';
import { HelpIcon } from '../../../../components/HelpIcon';
import { Loader } from '../../../../components/Loader';
import { NoPermissions } from '../../../../components/NoPermissions';
import { IColumn } from '../../../../components/Table/Column';
import {
  DEFAULT_USER_AVATAR_SIZE,
  UserAvatar
} from '../../../../components/UserAvatars';
import { PLANS_WITH_TEAMS } from '../../../../domainTypes/permissions';
import { ICurrentUser } from '../../../../domainTypes/user';
import { useDialogState } from '../../../../hooks/useDialogState';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { Centered } from '../../../../layout/Centered';
import {
  DEFAULT_OFFSET,
  PageToolbar,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import { useAdminOrImpersonatorClaim } from '../../../../services/auth';
import { ARTICLES } from '../../../../services/beacon';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../services/currentUser';
import { useFeatureEnabled } from '../../../../services/features';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { toMoment } from '../../../../services/time';
import {
  RealOrInvitedUser,
  RealUser,
  useUsersAndTheirPermissionLevels
} from '../../../../services/user';
import { SettingsLayout } from '../../SettingsLayout';
import { EditPermissionsDialog } from './components/EditPermissionsDialog';
import { InviteUserDialog } from './components/InviteUserDialog';
import { RolesAndPermissionsBadge } from './components/RoleBadge';
import { removeUser, revokeInvitation } from './service';
import { styled } from '../../../../emotion';
import { AlertTriangle } from 'react-feather';
import { SearchInput } from '../../../../components/SearchInput';

type UserRow = {
  user: RealOrInvitedUser;
};

const nowMoment = moment();

type ColumnName = 'email' | 'role' | 'status' | 'actions';

type Column = IColumn<
  UserRow,
  ColumnName,
  {
    currentUser: ICurrentUser;
    enqueueSnackbar: SnackbarProvider['enqueueSnackbar'];
    canRemoveUsers: boolean;
    canEditUsers: boolean;
  }
>;

const UserInfoGrid = styled('div')`
  display: grid;
  grid-template-columns: ${DEFAULT_USER_AVATAR_SIZE}px 1fr;
  grid-gap: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
`;

const COLUMNS: Column[] = [
  {
    key: 'email',
    head: () => 'Email',
    cell: (u) => {
      if (u.user.type === 'USER') {
        const email = u.user.user.data.email;
        const name = u.user.user.data.displayName;

        return (
          <UserInfoGrid>
            <UserAvatar user={u.user.user} hideTooltip />
            <div>
              {name && (
                <Typography variant="body2" component="p">
                  <strong>{name}</strong>
                </Typography>
              )}
              <Typography
                variant="body2"
                component="p"
                color={name ? 'textSecondary' : 'textPrimary'}
              >
                {email}
              </Typography>
            </div>
          </UserInfoGrid>
        );
      }

      return (
        <UserInfoGrid>
          <UserAvatar user={null} hideTooltip />
          <div>
            <Typography variant="body2" component="p">
              {u.user.invitation.data.email}
            </Typography>
          </div>
        </UserInfoGrid>
      );
    },
    align: 'left',
    sortable: true,
    flexGrow: 2,
    width: 200
  },
  {
    key: 'role',
    head: () => 'Role',
    cell: (u) => {
      const permissions =
        u.user.type === 'USER'
          ? u.user.permissions
          : u.user.invitation.data.permissions;
      return <RolesAndPermissionsBadge permissions={permissions} />;
    },

    align: 'left',
    sortable: true,
    flexGrow: 2,
    width: 100
  },
  {
    key: 'status',
    head: () => 'Status',
    cell: (u) => {
      if (u.user.type === 'USER') {
        return 'Active';
      }

      const expirationTs = u.user.invitation.data.expiresAt;
      const expirationDate = toMoment(expirationTs);

      if (expirationDate.isBefore(nowMoment)) {
        return (
          <Typography
            variant="body2"
            component="div"
            color="textSecondary"
            style={{ display: 'flex', alignItems: 'center', gap: 4 }}
          >
            <AlertTriangle size={16} /> <span>Invite expired</span>
          </Typography>
        );
      }

      return `Invited`;
    },
    align: 'left',
    sortable: true,
    flexGrow: 1,
    width: 100
  },
  {
    key: 'actions',
    head: () => 'Actions',
    cell: (u, o) => {
      if (u.user.type === 'INVITATION') {
        const inviteId = u.user.invitation.id;
        const options = [
          {
            key: 'revoke-invite',
            label: 'Revoke invitation',
            onClick: async () => {
              try {
                await revokeInvitation(inviteId);
                o.enqueueSnackbar('Invitation revoked', {
                  variant: 'success'
                });
              } catch (err) {
                o.enqueueSnackbar('Invitation could not be revoked', {
                  variant: 'error'
                });
              }
            }
          }
        ];

        return <AdditionalActionsMenu variant="horizontal" options={options} />;
      }

      const userId = u.user.user.id;
      const isCurrentUser = userId === o.currentUser.id;

      // Users cannot edit or remove themselves
      if (isCurrentUser) {
        return null;
      }

      const options = compact([
        o.canEditUsers && {
          key: 'edit-permissions',
          label: 'Edit permissions',
          dialog: ({ onClose }: { onClose: any }) => {
            const onSuccess = () => {
              o.enqueueSnackbar('Permissions updated successfully', {
                variant: 'success'
              });
            };
            return (
              <EditPermissionsDialog
                user={u.user as RealUser}
                permissions={u.user.permissions}
                onSuccess={onSuccess}
                onClose={onClose}
              />
            );
          }
        },
        o.canRemoveUsers && {
          key: 'remove-user',
          label: 'Remove user',
          dialog: ({ onClose }: { onClose: any }) => {
            const handleRemove = async () => {
              try {
                await removeUser(userId, o.currentUser.space.id);
                o.enqueueSnackbar('User removed', {
                  variant: 'success'
                });
                onClose();
              } catch (err) {
                o.enqueueSnackbar('User could not be removed', {
                  variant: 'error'
                });
              }
            };

            return (
              <>
                <DialogTitle>Remove user</DialogTitle>
                <DialogContent>
                  <Typography variant="body1" component="p">
                    Are you sure you want to remove this user?
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleRemove}
                  >
                    Remove user
                  </Button>
                </DialogActions>
              </>
            );
          }
        }
      ]);

      return <AdditionalActionsMenu variant="horizontal" options={options} />;
    },
    align: 'right',
    sortable: false,
    flexGrow: 1,
    width: 30
  }
];

const SORTERS: ItemSorters<UserRow> = {
  email: {
    key: 'email',
    items: {
      sort: (u) =>
        u.user.type === 'USER'
          ? u.user.user.data.email || null
          : u.user.invitation.data.email,
      dir: 'asc'
    }
  },
  role: {
    key: 'role',
    items: {
      sort: (u) =>
        u.user.type === 'USER'
          ? u.user.permissions.roles[0]
          : u.user.invitation.data.permissions.roles[0],
      dir: 'asc'
    }
  }
};

const rowToKey = (u: UserRow) => u.user.id;

const UserList = ({
  users,
  search
}: {
  users: RealOrInvitedUser[];
  search: string;
}) => {
  const currentUser = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const [[sorter, direction], setSort] = useSortQueryParam('sort', SORTERS);
  const scopes = useCurrentUserScopes();
  const canRemoveUsers = scopes.has('users.delete');
  const canEditUsers = scopes.has('users.edit');
  const [isAdminOrImpersonator] = useAdminOrImpersonatorClaim();

  const otherProps = {
    enqueueSnackbar,
    currentUser,
    canRemoveUsers,
    canEditUsers
  };

  const rows = useMemo(() => {
    return users
      .filter((u) => {
        const isAffilimateEmail =
          u.type === 'USER'
            ? u.user.data.email?.includes('@affilimate.com')
            : u.invitation.data.email.includes('@affilimate.com');

        if (search) {
          const emailIncludesSearch =
            u.type === 'USER'
              ? u.user.data.email?.includes(search)
              : u.invitation.data.email.includes(search);

          if (!emailIncludesSearch) {
            return false;
          }

          return isAdminOrImpersonator || !isAffilimateEmail;
        }

        if (isAdminOrImpersonator) {
          return true;
        }
        return !isAffilimateEmail;
      })
      .map((u) => ({ user: u }));
  }, [users, isAdminOrImpersonator, search]);

  return (
    <div>
      <RowsRenderer
        columns={COLUMNS}
        sorter={sorter || SORTERS.email}
        sortDirection={direction}
        onHeadClick={(u, dir) => setSort([SORTERS[u.key] || null, dir])}
        rows={rows}
        otherProps={otherProps}
        variant="contained"
        renderHead={true}
        rowToKey={rowToKey}
        rowHeight={ROW_HEIGHTS.airy}
      />
    </div>
  );
};

const TeamsUpsell = () => {
  return (
    <Paper>
      <Centered height={300}>
        <Typography variant="body1" color="textSecondary">
          Please contact our Support team to upgrade your account and add team
          members.
        </Typography>
      </Centered>
    </Paper>
  );
};

const UsersContent = () => {
  const currentUser = useCurrentUser();
  const [users, loading] = useUsersAndTheirPermissionLevels();
  const { dialogOpen, setDialogOpen, closeDialog } = useDialogState();
  const scopes = useCurrentUserScopes();
  const canViewUsers = scopes.has('users.view');
  const canCreateUsers = scopes.has('users.create');
  const hasTeamsFeatureToggleOn = useFeatureEnabled('TEAMS');
  const [search, setSearch] = useState('');

  const hasTeamsAccess = useMemo(() => {
    const plan = currentUser.space.billing.activePlan;

    if (hasTeamsFeatureToggleOn) {
      return true;
    }

    if (!plan) {
      return false;
    }

    return PLANS_WITH_TEAMS.indexOf(plan) !== -1;
  }, [currentUser, hasTeamsFeatureToggleOn]);

  return (
    <SettingsLayout noTopPadding>
      <Helmet>
        <title>Team | Affilimate</title>
      </Helmet>
      <PageToolbar sticky offset={DEFAULT_OFFSET}>
        <PageToolbarSection flex={2}>
          <Typography
            variant="h6"
            component="span"
            style={{
              fontWeight: 'bold'
            }}
          >
            Team &nbsp;
          </Typography>
          <SearchInput
            value={search}
            onChange={setSearch}
            width={300}
            placeholder="Search users by email"
          />
        </PageToolbarSection>
        <div>
          <HelpIcon articleId={ARTICLES.account.teams}>How teams work</HelpIcon>

          {canCreateUsers && (
            <Button
              variant="contained"
              color="primary"
              disabled={!users || !hasTeamsAccess}
              style={{ marginLeft: 16 }}
              onClick={() => {
                setDialogOpen(true);
              }}
            >
              Invite teammates
            </Button>
          )}
        </div>
      </PageToolbar>
      <Paper>
        {loading && <Loader height={300} />}
        {users && hasTeamsAccess && canViewUsers && (
          <UserList users={users} search={search} />
        )}
        {users && !hasTeamsAccess && canViewUsers && <TeamsUpsell />}
        {!loading && !canViewUsers && <NoPermissions />}
      </Paper>
      {dialogOpen && (
        <InviteUserDialog open={dialogOpen} onClose={closeDialog} />
      )}
    </SettingsLayout>
  );
};

export const PageSettingsNewUserManagement = () => {
  useTrackMixpanelView('view_users');
  return <UsersContent />;
};
