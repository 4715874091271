import {
  CLICK_CUSTOM_DIMENSION_NAMES,
  CustomDimensionName,
  ICustomDimensionsDoc,
  LinkGeneratorOption,
  LinkGeneratorSettings,
  PAGE_CUSTOM_DIMENSION_NAMES,
  slotName,
  slotType
} from '../../../../../domainTypes/customDimensions';
import React, { useCallback, useState } from 'react';
import { useDialogState } from '../../../../../hooks/useDialogState';
import { without } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { css, styled } from '../../../../../emotion';
import { useCurrentUser } from '../../../../../services/currentUser';
import { CardAlertBox } from '../../../../../components/AlertBox';
import { SelectableButton } from '../../../../Settings/components/SelectableButton';
import { MousePointer, Users } from 'react-feather';
import { FlexContainer } from '../../../../../layout/Flex';
import { interpose } from '../../../../../services/interpose';
import { HelpIcon } from '../../../../../components/HelpIcon';
import {
  defaultCustomDimensionsDoc,
  getCustomDimensionsDetailsStore,
  getCustomDimensionsStore
} from '../../../../../services/customDimensions';
import { LinkGeneratorSettingsControl } from './SlotDialog/LinkGeneratorSettingsControl';
import { SlotSettingsControl } from './SlotDialog/SlotSettingsControl';
import { useSnackbar } from '../../../../../hooks/useSnackbar';

interface AddSlotDialogProps {
  availableDimensions: CustomDimensionName[];
  addSlot: (
    slot: CustomDimensionName,
    name: string,
    description: string,
    linkGeneratorSettings: LinkGeneratorSettings,
    options: LinkGeneratorOption[]
  ) => Promise<void>;
  closeDialog: () => void;
}

const SelectionContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(2)}px;
  grid-row-gap: ${(p) => p.theme.spacing(2)}px;
`;

const TEMPLATES = [
  'syndicationSource',
  'googleAdsID',
  'placementType',
  'custom'
] as const;

type Template = typeof TEMPLATES[number];

interface TemplateDef {
  heading: string;
  subheading: string;
  types: Array<'click' | 'page'>;
  options?: Array<LinkGeneratorOption>;
}

const allTemplates: Record<Template, TemplateDef> = {
  custom: {
    heading: 'Custom',
    subheading: 'Set up a custom dimension type based on your own preferences.',
    types: ['click', 'page']
  },
  googleAdsID: {
    heading: 'Google Ads ID',
    subheading:
      'Record the gclid query parameter into a custom dimension and sync conversions back via API',
    types: ['page']
  },
  placementType: {
    heading: 'Placement type',
    subheading:
      'Track clicks and EPC by placement types like buttons, text links, and product cards.',
    types: ['click'],
    options: [
      { id: 'button', name: 'Button' },
      { id: 'text-link', name: 'Text link' }
    ]
  },
  syndicationSource: {
    heading: 'Syndication source',
    subheading:
      'Track clicks and earnings back to a syndication source like Yahoo, Apple News, or MSN.',
    types: ['click'],
    options: [
      { id: 'apple-news', name: 'Apple News' },
      { id: 'msn-reviews', name: 'MSN reviews' },
      { id: 'yahoo', name: 'Yahoo' }
    ]
  }
};

const Heading = ({
  types,
  heading
}: {
  types: Array<'click' | 'page'>;
  heading: string;
}) => (
  <FlexContainer alignItems="center" spacing={2}>
    {heading}
    <Typography variant="caption" color="textSecondary">
      <FlexContainer alignItems="center" spacing={1}>
        {interpose(
          types.map((type) => (
            <FlexContainer spacing={0.5} key={type}>
              <TypeIcon key={type} type={type} />
            </FlexContainer>
          )),
          <span>or</span>
        )}
      </FlexContainer>
    </Typography>
  </FlexContainer>
);

const TypeIcon = ({ type }: { type: 'click' | 'page' }) => {
  switch (type) {
    case 'click':
      return (
        <>
          <MousePointer size={12} />
          Click
        </>
      );
    case 'page':
      // Note: internally we have "page" custom dimensions, but we display them with "user".
      return (
        <>
          <Users size={12} />
          User
        </>
      );
  }
};

const firstSlot = (slots: CustomDimensionName[], type: 'click' | 'page') =>
  slots.filter((slot) => slotType(slot) === type)[0];

const AddSlotDialog: React.FC<AddSlotDialogProps> = ({
  availableDimensions,
  addSlot,
  closeDialog
}) => {
  const [template, _setTemplate] = useState<Template>('custom');

  const [type, setType] = useState<'click' | 'page'>('click');

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [showInLinkGenerator, setShowInLinkGenerator] = useState(false);
  const [linkGeneratorMode, setLinkGeneratorMode] = useState<
    Exclude<LinkGeneratorSettings['type'], 'disabled'>
  >('dictionary');
  const [options, setOptions] = useState<LinkGeneratorOption[]>([]);

  const [slot, setSlot] = useState<CustomDimensionName>(() =>
    firstSlot(availableDimensions, type)
  );

  const setTemplate = useCallback(
    (template: Template) => {
      _setTemplate(template);
      const isCustom = template === 'custom';
      const { types, heading, subheading, options } = allTemplates[template];
      const name = isCustom ? '' : heading;
      const description = isCustom ? '' : subheading;
      setName(name);
      setDescription(description);
      setType(types[0]);
      setSlot(firstSlot(availableDimensions, types[0]));
      setShowInLinkGenerator(true);
      const mode = options ? 'dictionary' : 'freeform';
      setLinkGeneratorMode(mode);
      setOptions(options ?? []);
    },
    [availableDimensions]
  );

  const submit = useCallback(
    async (e) => {
      const createGeneratorSettings = (): LinkGeneratorSettings => {
        if (!showInLinkGenerator) return { type: 'disabled' };
        if (linkGeneratorMode === 'dictionary') {
          return { type: 'dictionary' };
        }
        return { type: 'freeform' };
      };
      e.preventDefault();
      closeDialog();
      await addSlot(
        slot,
        name,
        description,
        createGeneratorSettings(),
        options
      );
    },
    [
      addSlot,
      closeDialog,
      description,
      linkGeneratorMode,
      name,
      options,
      showInLinkGenerator,
      slot
    ]
  );
  return (
    <form onSubmit={submit}>
      <DialogContent
        className={css((t) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: t.spacing(3)
        }))}
      >
        <Typography variant="body1" color="textSecondary">
          Custom dimensions are customizable attributes you can connect to
          clicks or users. Choose one of the preset options below, or create
          your dimension from scratch.
        </Typography>
        <CardAlertBox variant="primary">
          Tip: For categorizing content, use Tags instead.
        </CardAlertBox>
        <Typography
          variant="body1"
          className={css(() => ({
            fontWeight: 'bold'
          }))}
        >
          Examples and templates
        </Typography>
        <SelectionContainer>
          {TEMPLATES.map((templateName) => {
            const { types, heading, subheading } = allTemplates[templateName];
            return (
              <SelectableButton
                key={templateName}
                selected={template === templateName}
                onClick={() => {
                  setTemplate(templateName);
                }}
                heading={<Heading heading={heading} types={types} />}
                subheading={subheading}
              />
            );
          })}
        </SelectionContainer>

        <Typography
          variant="body1"
          className={css(() => ({
            fontWeight: 'bold'
          }))}
        >
          Configure your custom dimension
        </Typography>

        <SlotSettingsControl
          name={name}
          setName={setName}
          description={description}
          setDescription={setDescription}
        />
        <FlexContainer>
          <Typography variant="body2">
            What type of dimension is it?{' '}
          </Typography>
          <HelpIcon>Learn about dimension types</HelpIcon>
        </FlexContainer>

        <SelectionContainer>
          <SelectableButton
            key="click"
            onClick={() => {
              setType('click');
              setSlot(firstSlot(availableDimensions, 'click'));
            }}
            heading={
              <FlexContainer>
                <MousePointer size={12} />
                Click dimension
              </FlexContainer>
            }
            subheading={
              <ul>
                <li>
                  Used for tracking custom properties on click- or link-level.
                </li>
                <li>
                  Optional: Surface this dimension as dropdown within the link
                  generator for offsite links.
                </li>
              </ul>
            }
            selected={type === 'click'}
          />
          <SelectableButton
            key="page"
            onClick={() => {
              setType('page');
              setSlot(firstSlot(availableDimensions, 'page'));
            }}
            heading={
              <FlexContainer>
                <Users size={12} />
                User dimension
              </FlexContainer>
            }
            subheading={
              <ul>
                <li>
                  Used for tracking custom properties related to your audience.
                </li>
                <li>
                  Note that any kind of unique IDs may require user consent
                  before passing in.
                </li>
              </ul>
            }
            selected={type === 'page'}
          />
        </SelectionContainer>

        <FormControl>
          <InputLabel id="slot">
            Which slot should be used? The next available slot is selected by
            default.
          </InputLabel>
          <Select
            id="slot"
            fullWidth
            variant="outlined"
            value={slot}
            onChange={(e) => setSlot(e.target.value as CustomDimensionName)}
          >
            {availableDimensions
              .filter((slot) => slotType(slot) === type)
              .map((slot) => (
                <MenuItem key={slot} value={slot}>
                  {slotName(slot)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <LinkGeneratorSettingsControl
          name={name}
          showInLinkGenerator={showInLinkGenerator}
          setShowInLinkGenerator={setShowInLinkGenerator}
          linkGeneratorMode={linkGeneratorMode}
          setLinkGeneratorMode={setLinkGeneratorMode}
          options={options}
          setOptions={setOptions}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button
          onClick={submit}
          type="submit"
          color="primary"
          variant="contained"
        >
          Assign slot
        </Button>
      </DialogActions>
    </form>
  );
};

interface AddSlotProps {
  activeDimensions: CustomDimensionName[];
}

export const AddSlot: React.FC<AddSlotProps> = ({ activeDimensions }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { space } = useCurrentUser();
  const { dialogOpen, openDialog, closeDialog } = useDialogState(false);
  const availableDimensions = without(
    [...CLICK_CUSTOM_DIMENSION_NAMES, ...PAGE_CUSTOM_DIMENSION_NAMES],
    ...activeDimensions
  );
  const noSlotsAvailable = availableDimensions.length === 0;

  const addSlot = useCallback(
    async (
      slot: CustomDimensionName,
      name: string,
      description: string,
      linkGeneratorSettings: LinkGeneratorSettings,
      options: LinkGeneratorOption[]
    ) => {
      try {
        const definition = {
          name,
          description,
          linkGeneratorSettings
        };

        const dimensionsStore = getCustomDimensionsStore(space.id);
        const dimensionsStoreDoc = await dimensionsStore.get();
        const data =
          (dimensionsStoreDoc.data() as ICustomDimensionsDoc | undefined) ??
          defaultCustomDimensionsDoc(space.id);

        await dimensionsStore.set({
          ...data,
          dimensions: {
            ...data.dimensions,
            [slot]: definition
          }
        });

        const detailsStore = getCustomDimensionsDetailsStore(space.id, slot);
        // Set will result in an upsert. Merge ensures that the existing fields are not lost.
        await detailsStore.set(
          {
            options: linkGeneratorSettings.type === 'dictionary' ? options : [],
            spaceId: space.id,
            slot: slot
          },
          { merge: true }
        );
      } catch (e) {
        enqueueSnackbar('Failed to add slot', { variant: 'error' });
      }
    },
    [enqueueSnackbar, space.id]
  );

  return (
    <>
      <Tooltip title={noSlotsAvailable ? 'No more slots available' : ''}>
        <Button
          variant="contained"
          color="primary"
          onClick={openDialog}
          disabled={noSlotsAvailable}
        >
          Assign dimension slot
        </Button>
      </Tooltip>

      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        scroll="body"
        maxWidth="md"
      >
        <DialogTitle>Assign new custom dimension to a slot</DialogTitle>
        <AddSlotDialog
          availableDimensions={availableDimensions}
          addSlot={addSlot}
          closeDialog={closeDialog}
        />
      </Dialog>
    </>
  );
};
