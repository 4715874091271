import {
  PAYOUT_STATUSES,
  SALE_TYPES,
  TRANSACTION_STATUSES
} from '../../../domainTypes/performance';
import { Dimension } from './filters';

export interface FilterTreeOption {
  dimension: Dimension;
  terms: string[];
}

export interface FilterTreeCategory {
  label: string;
  options: Array<FilterTreeOption>;
}

export type DimensionsTree = Record<CategoryName, FilterTreeCategory>;

export type CategoryName =
  | 'popular'
  | 'other'
  | 'custom'
  | 'audience'
  | 'campaign'
  | 'traffic';

export const dimensionsTree: DimensionsTree = {
  popular: {
    label: 'Popular',
    options: [
      { dimension: 'site', terms: ['website'] },
      { dimension: 'platform', terms: ['partner', 'network'] },
      {
        dimension: 'channel',
        terms: ['offsite', 'site', 'newsletter', 'social', 'media', 'email']
      },
      {
        dimension: 'advertiser',
        terms: ['advertisers', 'partners', 'affiliates']
      }
    ]
  },
  custom: {
    label: 'Custom',
    options: [
      { dimension: 'tag', terms: ['author', 'authors', 'editor', 'writer'] },
      {
        dimension: 'click_data_01',
        terms: ['click', 'dimension', 'custom', 'data']
      },
      {
        dimension: 'click_data_02',
        terms: ['click', 'dimension', 'custom', 'data']
      },
      {
        dimension: 'click_data_03',
        terms: ['click', 'dimension', 'custom', 'data']
      },
      {
        dimension: 'click_data_04',
        terms: ['click', 'dimension', 'custom', 'data']
      },
      {
        dimension: 'click_data_05',
        terms: ['click', 'dimension', 'custom', 'data']
      },
      {
        dimension: 'click_data_06',
        terms: ['click', 'dimension', 'custom', 'data']
      },
      {
        dimension: 'click_data_07',
        terms: ['click', 'dimension', 'custom', 'data']
      },
      {
        dimension: 'click_data_08',
        terms: ['click', 'dimension', 'custom', 'data']
      },
      {
        dimension: 'click_data_09',
        terms: ['click', 'dimension', 'custom', 'data']
      },
      {
        dimension: 'click_data_10',
        terms: ['click', 'dimension', 'custom', 'data']
      }
    ]
  },
  audience: {
    label: 'Audience',
    options: [
      { dimension: 'device', terms: ['desktop', 'mobile'] },
      { dimension: 'country', terms: ['geography', 'location'] }
    ]
  },
  campaign: {
    label: 'Campaigns',
    options: [
      { dimension: 'campaign_manager', terms: ['campaign', 'manager'] },
      { dimension: 'campaign_advertiser', terms: ['campaign', 'advertiser'] },
      { dimension: 'campaign_status', terms: ['campaign', 'status'] }
    ]
  },
  traffic: {
    label: 'Traffic sources',
    options: [
      { dimension: 'referrer', terms: ['domain', 'traffic'] },
      { dimension: 'utm_campaign', terms: [] },
      { dimension: 'utm_medium', terms: [] },
      { dimension: 'utm_source', terms: [] },
      { dimension: 'utm_term', terms: [] },
      { dimension: 'utm_content', terms: [] }
    ]
  },
  other: {
    label: 'Other filters',
    options: [
      {
        dimension: 'payout_id',
        terms: ['invoices', 'payments', 'payouts']
      },
      {
        dimension: 'payout_status',
        terms: PAYOUT_STATUSES
      },
      {
        dimension: 'transaction_status',
        terms: [...TRANSACTION_STATUSES, 'sale']
      },
      {
        dimension: 'transaction_type',
        terms: [...SALE_TYPES, 'sale']
      },
      {
        dimension: 'integration_id',
        terms: ['integration', 'api', 'import']
      }
    ]
  }
};
