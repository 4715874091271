import { CircularProgress, TextField, TextFieldProps } from '@material-ui/core';
import React from 'react';

export const TextFieldLoader = ({
  fullWidth,
  variant = 'outlined',
  label,
  placeholder
}: Pick<TextFieldProps, 'label' | 'placeholder' | 'fullWidth' | 'variant'>) => {
  return (
    <TextField
      disabled
      fullWidth={fullWidth}
      variant={variant}
      label={label}
      placeholder={placeholder}
      InputProps={{
        endAdornment: <CircularProgress size={20} />
      }}
    />
  );
};
