import React from 'react';
import { PageToolbarSection } from '../../../../layout/PageToolbar';
import pluralize from 'pluralize';
import IconButton from '@material-ui/core/IconButton';
import { XCircle } from 'react-feather';
import { FlexContainer } from '../../../../layout/Flex';
import { TagsMenu } from './Tags/TagsMenu';
import { useCanAssignTags } from './service';
import { css } from '../../../../emotion';

interface SelectionToolbarProps {
  pages: Set<string>;
  isAllPagesSelected: boolean;
  resetPages: () => void;
  allPagesCount: number;
}

export const SelectionToolbar: React.FC<SelectionToolbarProps> = ({
  pages,
  isAllPagesSelected,
  resetPages,
  allPagesCount
}) => {
  const canAssignTags = useCanAssignTags();
  return (
    <>
      <PageToolbarSection
        flex={1}
        className={css((t) => ({ marginBottom: t.spacing(2) }))}
      >
        <FlexContainer justifyContent="flex-start" spacing={0.5}>
          <div>
            {pluralize(
              'page',
              isAllPagesSelected ? allPagesCount : pages.size,
              true
            )}{' '}
            selected
          </div>
          <IconButton
            onClick={resetPages}
            className={css((t) => ({ padding: t.spacing(1) }))}
          >
            <XCircle size={14} />
          </IconButton>
        </FlexContainer>
      </PageToolbarSection>
      <PageToolbarSection
        flex={1}
        justifyContent="flex-end"
        className={css((t) => ({ marginBottom: t.spacing(2) }))}
      >
        {canAssignTags ? (
          <TagsMenu isAllPagesSelected={isAllPagesSelected} pages={pages} />
        ) : null}
      </PageToolbarSection>
    </>
  );
};
