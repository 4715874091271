import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { SiteSettings } from '../../../SiteSettings';
import { styled } from '../../../../emotion';

const ButtonContainer = styled('div')`
  text-align: right;
  margin-top: ${(p) => p.theme.spacing(2)}px;
`;

const SettingsContainer = styled('div')``;

export const SettingsStep = ({
  domain,
  onContinue
}: {
  domain: string;
  onContinue: () => void;
}) => {
  return (
    <div>
      <Typography paragraph variant="h5" style={{ textAlign: 'center' }}>
        Choose your tracking settings
      </Typography>
      <Typography
        variant="body1"
        component="p"
        style={{ textAlign: 'center', marginBottom: '24px' }}
      >
        If you're not sure, you can leave the default settings and change these
        later.
      </Typography>
      <SettingsContainer>
        <SiteSettings domain={domain} />
      </SettingsContainer>
      <ButtonContainer>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={onContinue}
        >
          Continue
        </Button>
      </ButtonContainer>
    </div>
  );
};
