import { Theme } from '@material-ui/core';
import { EMOTION_THEME, IEMotionTheme } from './emotionTheme';
import MUI_THEME from './muiTheme';

export type AffilimateTheme = Theme & IEMotionTheme;

export const COMBINED_THEME: AffilimateTheme = {
  ...MUI_THEME,
  ...EMOTION_THEME
};

export { EMOTION_THEME, MUI_THEME };

// this doesn't really do anything atm, as we also don't
// have changing themes atm. If we ever so, this needs
// to become a useState/useEffect pair which listens to changes
export const useTheme = () => COMBINED_THEME;
