import React, { useCallback } from 'react';
import { RowsRenderer } from '../../../../../../components/GroupableList';
import { Loader } from '../../../../../../components/Loader';
import { Centered } from '../../../../../../layout/Centered';
import { Card } from '@material-ui/core';
import { Truncated } from '../../../../../../components/Truncated';
import {
  PAGE_SIZE,
  useReferrerOriginCount,
  useReferrerOriginExportQuery,
  useReferrerOriginTotals
} from '../services/referrer-origins';
import { CustomPagination } from '../../../../../../components/CustomPagination';
import { useRoutes } from '../../../../../../routes';
import { isNil } from 'lodash';
import { WithHoverIndicator } from '../../../../../../components/WithHoverIndicator';
import { createSearchBox } from '../../../../../../components/analytics_v2/SearchBox';
import {
  ALL_COLUMN_NAMES,
  ColumnDefinitions,
  DEFAULT_VISIBLE_COLUMN_NAMES,
  useTable
} from '../../../../../../components/analytics_v2/Table';
import { Metric } from '../../../../../../services/analyticsV2/metrics';
import { ColumnSelector } from '../../../../../../components/Table/ColumnSelector';
import { AnalyticsResponseRowWithComparison } from '../../../../../../domainTypes/analytics_v2';
import { FlexContainer } from '../../../../../../layout/Flex';
import { ExportQueryButton } from '../../../../../../components/ExportQuery';
import { ReferrerGroup } from '../../../../../../domainTypes/referrers';
import {
  createReferrerGroupFilterDefinition,
  createReferrersFilterDefinition
} from '../../../../../../components/analytics_v2/Filters/filters';
import { GOOGLE_DISCOVER_ORIGN } from '../../../../../../services/referrers-query';
import { useHasComparison } from '../../../../../../hooks/timeframe';

const customColumns = ['origin'] as const;
type CustomColumns = typeof customColumns[number];
type Column = CustomColumns | Metric;

const availableColumns: Column[] = [...customColumns, ...ALL_COLUMN_NAMES];
const defaultVisibleColumns: Column[] = [
  ...customColumns,
  ...DEFAULT_VISIBLE_COLUMN_NAMES
];

const normalizeOrigin = (origin: string): string => {
  if (origin === GOOGLE_DISCOVER_ORIGN) {
    return 'Google Discover';
  }
  return isNil(origin) ? 'Direct' : origin;
};

const columnDefinitions: ColumnDefinitions<CustomColumns> = {
  origin: {
    column: {
      key: 'origin',
      head: () => 'Domain',
      cell: (p) => (
        <WithHoverIndicator>
          <Truncated title={normalizeOrigin(p.group.referrer_entry_origin)} />
        </WithHoverIndicator>
      ),
      align: 'left',
      sortable: true,
      width: 200,
      flexGrow: 4
    },
    sorter: {
      key: 'origin',
      items: { sort: (p) => p.group.referrer_entry_origin, dir: 'asc' }
    }
  }
};

const rowToKey = (p: AnalyticsResponseRowWithComparison) =>
  p.group.referrer_entry_origin;

const [SearchBox, useSearch] = createSearchBox('q');

interface OriginsTableProps {
  group: ReferrerGroup;
}

export const OriginsTable: React.FC<OriginsTableProps> = ({ group }) => {
  const { ROUTES } = useRoutes();
  const rowToHref = useCallback(
    (d: AnalyticsResponseRowWithComparison) => {
      if (group === 'direct' || group === 'google-discover') {
        return ROUTES.content.overview_v2.url([
          createReferrerGroupFilterDefinition(group)
        ]);
      }
      return ROUTES.content.overview_v2.url([
        createReferrersFilterDefinition([d.group.referrer_entry_origin])
      ]);
    },
    [ROUTES.content.overview_v2, group]
  );

  const showComparison = useHasComparison();
  const {
    tableProps,
    columnSelectorProps,
    paginationSelectorProps,
    pagination,
    orderBy,
    metrics
  } = useTable(availableColumns, columnDefinitions, {
    pageSize: PAGE_SIZE,
    defaultSortColumn: 'c',
    defaultVisibleColumns,
    showComparison
  });

  const search = useSearch('referrer_entry_origin');

  const exportQuery = useReferrerOriginExportQuery(
    group,
    metrics,
    search,
    orderBy
  );
  const [data, loading] = useReferrerOriginTotals(
    group,
    metrics,
    search,
    pagination,
    orderBy
  );
  const [count = 1] = useReferrerOriginCount(group, search);

  return (
    <>
      <FlexContainer justifyContent="space-between">
        <SearchBox
          placeholder="Search by referrer domain"
          width={300}
          size="small"
        />
        <FlexContainer>
          <ColumnSelector {...columnSelectorProps} />
          <CustomPagination
            {...paginationSelectorProps}
            style={{ marginLeft: 'auto' }}
            count={Math.ceil(count / PAGE_SIZE)}
            siblingCount={0}
          />
          <ExportQueryButton
            title="Referrers"
            reportType="referrers"
            query={exportQuery}
          />
        </FlexContainer>
      </FlexContainer>
      {!data || loading ? (
        <Card>
          <Centered height={300}>
            <Loader size={24} />
          </Centered>
        </Card>
      ) : (
        <RowsRenderer
          {...tableProps}
          renderHead={true}
          headProps={{
            sticky: true,
            offset: 56
          }}
          rows={data.rows}
          rowToKey={rowToKey}
          rowToHref={rowToHref}
        />
      )}
    </>
  );
};
