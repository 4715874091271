import { Paper } from '@material-ui/core';
import React from 'react';
import { TooltipPayload, TooltipProps } from 'recharts';
import { styled } from '../../../emotion';
import { FlexContainer } from '../../../layout/Flex';

const DARK_BG = '#111';

export const TooltipPaper = styled(Paper)`
  background-color: ${DARK_BG} !important;
  color: white !important;
  z-index: 1000;
  padding: ${(p) => p.theme.spacing(2)}px;
`;

export const TooltipText = styled<'div', { small?: boolean }>('div')`
  font-size: ${(p) =>
    p.small ? p.theme.custom.fontSize.s : p.theme.custom.fontSize.m}px;
`;

export const TooltipRow = styled(TooltipText)`
  display: flex;
  justify-content: space-between;

  span:first-of-type {
    display: inline-block;
  }

  > :first-of-type {
    padding-right: ${(p) => p.theme.spacing(4)}px;
  }
`;

const renderTooltipCounts = (
  formattedPayload: (string | number)[] | string
) => {
  if (Array.isArray(formattedPayload)) {
    return [formattedPayload[1], formattedPayload[0]].map((p, i) => (
      <span key={i} style={formattedPayload[2] as any}>
        {p}
      </span>
    ));
  }
  return <span>{formattedPayload}</span>;
};

const extractPayload = (payload: readonly TooltipPayload[]) => {
  return payload && payload.length ? payload[0].payload : null;
};

export const TooltipHeading = styled<'div', { small?: boolean }>('div')`
  font-size: ${(p) =>
    p.small ? p.theme.custom.fontSize.m : p.theme.custom.fontSize.l}px;
  font-weight: 500;
  margin-bottom: ${(p) => p.theme.spacing()}px;
`;

export const TooltipSection = styled('div')`
  margin-top: ${(p) => p.theme.spacing()}px;
`;

export const CustomTooltip: React.FC<
  Omit<TooltipProps, 'labelFormatter'> & {
    labelFormatter?: (label: TooltipProps['label']) => string;
    customLabel?: (payload: any) => string;
    grouper?: (
      items: TooltipPayload[]
    ) => {
      key: string;
      label: string;
      items: TooltipPayload[];
    }[];
    size?: 'normal' | 'small';
    reversePayloadOrder?: boolean;
  }
> = ({
  label = '',
  active = false,
  payload = [],
  labelFormatter = (l) => l,
  formatter = (v, n) => [v, n],
  customLabel,
  grouper,
  size = 'normal',
  reversePayloadOrder
}) => {
  const activePayload = (payload || []).filter(({ value }) => value !== 0);
  const isSmall = size === 'small';

  const grouped = grouper?.(activePayload);
  return (
    <TooltipPaper>
      {customLabel ? (
        <TooltipHeading small={isSmall}>
          {customLabel(extractPayload(payload))}
        </TooltipHeading>
      ) : labelFormatter(label) ? (
        <TooltipHeading small={isSmall}>{labelFormatter(label)}</TooltipHeading>
      ) : null}
      {activePayload.length === 0 && (
        <TooltipText small={isSmall}>
          Nothing to see here{' '}
          <span role="img" aria-label="Eyes">
            👀
          </span>
        </TooltipText>
      )}
      {grouped ? (
        <FlexContainer
          spacing={1}
          justifyContent="flex-start"
          alignItems="flexSart"
          direction="column"
        >
          {grouped.map((g) => (
            <div key={g.key}>
              <strong>{g.label}</strong>
              {g.items.map((p, i) => (
                <TooltipRow key={i} small={isSmall}>
                  {renderTooltipCounts(
                    (formatter as any)(p.value, p.name, p, i)
                  )}
                </TooltipRow>
              ))}
            </div>
          ))}
        </FlexContainer>
      ) : (
        <>
          {(reversePayloadOrder
            ? [...activePayload].reverse()
            : activePayload
          ).map((p, i) => (
            <TooltipRow key={i} small={isSmall}>
              {renderTooltipCounts((formatter as any)(p.value, p.name, p, i))}
            </TooltipRow>
          ))}
        </>
      )}
    </TooltipPaper>
  );
};

export const FreeFormTooltip = ({
  label,
  payload,
  children
}: {
  children: (props: {
    x: string | number | undefined;
    hasAnyData: boolean;
  }) => JSX.Element | null; // that's what recharts wants
} & Pick<TooltipProps, 'label' | 'payload'>) => {
  const activePayload = (payload || []).filter(({ value }) => value !== 0);
  return children({ x: label, hasAnyData: !!activePayload.length });
};
