import { CheckCircle as IconCheckCircle, ArrowLeft } from 'react-feather';
import { Notification } from '../../../../../domainTypes/notifications';
import React from 'react';
import { Doc } from '../../../../../domainTypes/document';
import { Paper, IconButton, Tooltip, Button } from '@material-ui/core';
import { styled } from '../../../../../emotion';
import {
  markNotificationAsUnseen,
  markNotificationAsSeen
} from '../../../services';
import { useColorsTrafficLight } from '../../../../../services/color';
import { Link } from 'react-router-dom';
import { useNotificationsDrawer } from '../../../hooks/useNotificationsDrawer';
import { COLORS } from '../../../../../domainTypes/colors';

type Props = {
  n: Doc<Notification>;
  href?: string;
  onClick?: () => void;
};

const Container = styled('div')`
  padding: ${(p) => p.theme.spacing(2)}px;
  position: relative;
  width: 100%;
`;

const Actions = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  padding: ${(p) => p.theme.spacing(0.5)}px;
`;

const ICON_SIZE = 18;
const BUTTON_STYLE: any = {
  padding: 0,
  width: '100%',
  textAlign: 'left'
};

const Wrapper: React.FC<Props> = ({ n, href, onClick, children }) => {
  const nD = useNotificationsDrawer();
  const click = () => {
    const exec = () => {
      nD[1](false);
      onClick && onClick();
    };
    n.data.seenAt ? exec() : markNotificationAsSeen(n.id).then(exec);
  };
  if (href) {
    return (
      <Button onClick={click} style={BUTTON_STYLE}>
        <Link to={href} style={BUTTON_STYLE}>
          {children}
        </Link>
      </Button>
    );
  }
  if (onClick) {
    return (
      <Button onClick={click} style={BUTTON_STYLE}>
        {children}
      </Button>
    );
  }
  return <div>{children}</div>;
};

const FrameWrapper = styled(Paper)`
  border: 1px solid ${(p) => p.theme.palette.grey[100]};

  &:hover {
    border: 1px solid ${COLORS.blue.blue3};
    background-color: ${COLORS.blue.blue1};
  }
`;

export const Frame: React.FC<Props> = (props) => {
  const { n, children } = props;
  const seen = !!n.data.seenAt;
  const toggle = () =>
    seen ? markNotificationAsUnseen(n.id) : markNotificationAsSeen(n.id);
  const [green] = useColorsTrafficLight();

  return (
    <FrameWrapper>
      <Wrapper {...props}>
        <Container>
          <Actions>
            <Tooltip
              title={seen ? `Mark as unread` : `Mark as read`}
              placement="top"
            >
              <IconButton
                onClick={(ev) => {
                  ev.stopPropagation();
                  ev.preventDefault();
                  toggle();
                }}
              >
                {seen ? (
                  <ArrowLeft size={ICON_SIZE} />
                ) : (
                  <IconCheckCircle size={ICON_SIZE} color={green} />
                )}
              </IconButton>
            </Tooltip>
          </Actions>
          {children}
        </Container>
      </Wrapper>
    </FrameWrapper>
  );
};

export const Header = styled<'div', { center?: true }>('div')`
  font-size: ${(p) => p.theme.custom.fontSize.l}px;
  margin-bottom: ${(p) => p.theme.spacing(1)}px;
  ${(p) => (p.center ? 'text-align: center;' : '')}
`;

export const Image = styled('img')`
  max-width: 256px;
  margin: 0 auto;
  display: block;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

export const ImageHeader: React.FC<{ src: string; alt?: string }> = ({
  src,
  alt = '',
  children
}) => {
  return (
    <>
      <Image src={src} alt={alt} />
      <Header center={true}>{children}</Header>
    </>
  );
};

export const Body = styled('div')``;
