export type CurrencyCode =
  | 'AUD'
  | 'BGN'
  | 'BRL'
  | 'CAD'
  | 'CHF'
  | 'CNY'
  | 'CZK'
  | 'DKK'
  | 'EUR'
  | 'GBP'
  | 'HKD'
  | 'HRK'
  | 'HUF'
  | 'IDR'
  | 'ILS'
  | 'INR'
  | 'ISK'
  | 'JPY'
  | 'KRW'
  | 'MXN'
  | 'MYR'
  | 'NOK'
  | 'NZD'
  | 'PHP'
  | 'PLN'
  | 'RON'
  | 'RUB'
  | 'SEK'
  | 'SGD'
  | 'THB'
  | 'TRY'
  | 'USD'
  | 'ZAR';

// Beware when changing this.
// The exchangeRate document relies on the order of these!
export const CURRENCIES: CurrencyCode[] = [
  'AUD',
  'BGN',
  'BRL',
  'CAD',
  'CHF',
  'CNY',
  'CZK',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'HRK',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'ISK',
  'JPY',
  'KRW',
  'MXN',
  'MYR',
  'NOK',
  'NZD',
  'PHP',
  'PLN',
  'RON',
  'RUB',
  'SEK',
  'SGD',
  'THB',
  'TRY',
  'USD',
  'ZAR'
];

// Todo fill out wih real names, if we ever need that
export const CURRENCY_NAMES: { [K in CurrencyCode]: string } = {
  AUD: 'AUD',
  BGN: 'BGN',
  BRL: 'BRL',
  CAD: 'CAD',
  CHF: 'CHF',
  CNY: 'CNY',
  CZK: 'CZK',
  DKK: 'DKK',
  EUR: 'EUR',
  GBP: 'GBP',
  HKD: 'HKD',
  HRK: 'HRK',
  HUF: 'HUF',
  IDR: 'IDR',
  ILS: 'ILS',
  INR: 'INR',
  ISK: 'ISK',
  JPY: 'JPY',
  KRW: 'KRW',
  MXN: 'MXN',
  MYR: 'MYR',
  NOK: 'NOK',
  NZD: 'NZD',
  PHP: 'PHP',
  PLN: 'PLN',
  RON: 'RON',
  RUB: 'RUB',
  SEK: 'SEK',
  SGD: 'SGD',
  THB: 'THB',
  TRY: 'TRY',
  USD: 'USD',
  ZAR: 'ZAR'
};

export const COMMON_CURRENCY_SYMBOLS: { [symbol: string]: CurrencyCode } = {
  '€': 'EUR',
  '£': 'GBP',
  '¥': 'JPY',
  $: 'USD'
};

export const symbolForCurrencyCode = (
  currencyCode: CurrencyCode
): string | undefined => {
  return Object.keys(COMMON_CURRENCY_SYMBOLS).find(
    (symbol) => currencyCode === COMMON_CURRENCY_SYMBOLS[symbol]
  );
};

export type ExchangeRates = {
  date: string; // YYYY-MM-DD
  base: CurrencyCode;
  rates: { [K in CurrencyCode]: number };
};

export type HistoricalExchangeRates = {
  rates: {
    [date: string]: { [K in CurrencyCode]: number };
  };
};

export interface IExchangeRate {
  date: string;
  rates: number[];
}

export interface ICurrencyConverter {
  convert(
    amount: number,
    from: CurrencyCode,
    to: CurrencyCode,
    date?: any // Actually is DateLike, but we don't have that as a shared type
  ): Promise<number>;

  convertNullable(
    amount: number | null,
    from: CurrencyCode,
    to: CurrencyCode,
    date?: any // Actually is DateLike, but we don't have that as a shared type
  ): Promise<number | null>;
}
