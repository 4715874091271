import { useCollection } from 'react-firebase-hooks/firestore';
import { ApiStatus } from '../../domainTypes/apiStatus';
import { generateToDocFn } from '../../domainTypes/document';
import { FS } from '../../versions';
import { store, useMappedLoadingValue } from '../db';

export const toApiStatusDoc = generateToDocFn<ApiStatus>();
export const apiStatusCollection = () => store().collection(FS.apiStatus);
export const getApiStatusQuery = (spaceId: string) =>
  apiStatusCollection().where('spaceId', '==', spaceId);

export const useApiStatuses = (spaceId: string) => {
  return useMappedLoadingValue(
    useCollection(apiStatusCollection().where('spaceId', '==', spaceId)),
    (s) => s.docs.map((d) => toApiStatusDoc(d))
  );
};
