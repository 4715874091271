import { Doc } from '../document';
import { IUser } from '../user';
import { ISpace } from '../space';
import { FS } from '../../versions';

export const CHROME_EXTENSION_METADATA_ID = 'm';
export interface IChromeExtensionMetadata {
  requiredVersion: string; // version number like x.x.x
}
export const EMPTY_CHROME_EXTENSION_METADATA = (
  requiredVersion: string = '0.0.0'
): Doc<IChromeExtensionMetadata> => ({
  id: CHROME_EXTENSION_METADATA_ID,
  collection: FS.chromeExtensionMetadata,
  data: {
    requiredVersion
  }
});

export interface IAuthState {
  uid: string | null;
  user: Doc<IUser> | null | undefined;
  spaces: Doc<ISpace>[] | undefined;
}

export interface IMessage {
  type: string;
  messageId?: string;
}

const NOOP_POST = (name: string) => (msg: any) =>
  console.log(`${name} not connected - post: `, msg);

export interface IMessageLogin extends IMessage {
  type: 'LOGIN';
  data: {
    token: string;
  };
}

export interface IMessageLogout extends IMessage {
  type: 'LOGOUT';
}

export interface IMessageStatus extends IMessage {
  type: 'STATUS';
  data: {
    status: 'PENDING' | 'LOGGED_IN' | 'LOGGED_OUT' | 'UNAVAILABLE';
    version?: string;
    auth?: IAuthState | null;
  };
}

export class Connector<Incoming extends IMessage, Outgoing extends IMessage> {
  public name: string;
  private _postMessage: (msg: Outgoing) => void;
  private _onConnect: (respond: (msg: Outgoing) => void) => void;
  // we'd also want disconnect events eventually
  private _messageHandler: (
    msg: Incoming,
    respond: (msg: Outgoing) => void
  ) => void;

  constructor(
    name: string,
    messageHandler: (msg: Incoming, respond: (msg: Outgoing) => void) => void,
    onConnect?: (respond: (msg: Outgoing) => void) => void
  ) {
    this.name = name;
    this._postMessage = NOOP_POST(name);
    this._onConnect = onConnect || (() => undefined);
    this._messageHandler = messageHandler;
  }

  postMessage = (msg: Outgoing) => this._postMessage(msg);

  // postAndAwait = (msg: Outgoing, incomingMessageType: MessageType) => Promise<Incoming>

  handleMessage = (msg: Incoming) => {
    this._messageHandler(msg, response => this.postMessage(response));
  };

  connect = (
    postMessage: (msg: any) => void,
    listener: (handleMessage: (msg: Incoming) => void) => void
  ) => {
    this._postMessage = postMessage;
    listener(msg =>
      this._messageHandler(msg, response => this.postMessage(response))
    );
    this._onConnect(postMessage);
  };

  disconnect = () => {
    this._postMessage = NOOP_POST(this.name);
  };
}

export type DetachFn = () => void;
