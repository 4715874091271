import { useEffect, useState } from 'react';
import * as tracking from '../tracking';

export const usePagination = <T>(
  initialPage: number,
  initialRowsPerPage: number,
  pageResetListeners: any[]
) => {
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [page, setPage] = useState(initialPage);

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, pageResetListeners);

  const getCurrentPageItems = (list: T[]) => {
    const currentPos = page * rowsPerPage;
    return list.slice(currentPos, currentPos + rowsPerPage);
  };

  return {
    page,
    setPage: (p: number) => {
      tracking.sendEvent({
        category: tracking.toAppCategory(),
        action: 'Click',
        label: 'Change page',
        value: p
      });
      return setPage(p);
    },
    rowsPerPage,
    setRowsPerPage: (rpp: number) => {
      tracking.sendEvent({
        category: tracking.toAppCategory(),
        action: 'Click',
        label: 'Change rows per page',
        value: rpp
      });
      return setRowsPerPage(rpp);
    },
    getCurrentPageItems
  };
};
