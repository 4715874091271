import React from 'react';
import { MenuItem, MenuList } from '@material-ui/core';
import { isEqual } from 'lodash';
import Typography from '@material-ui/core/Typography';
import { css } from '../../emotion';

type Option<T> = { value: T; label: string };

interface TimePresetSelectorProps<T> {
  options: Array<Option<T>>;
  value?: T;
  onChange: (value: T) => void;
}

export function TimePresetSelector<T>({
  options,
  value,
  onChange
}: TimePresetSelectorProps<T>) {
  return (
    <MenuList style={{ maxHeight: 265, overflowY: 'auto' }}>
      {options.map((o, i) => {
        const selected = isEqual(o.value, value);
        return (
          <MenuItem
            key={i}
            value={i}
            selected={selected}
            onClick={() => {
              onChange(o.value);
            }}
            className={css((t) => ({
              borderRadius: t.shape.borderRadius
            }))}
          >
            <Typography
              variant="body2"
              className={css((t) => ({
                fontWeight: selected ? 'bold' : 'normal'
              }))}
            >
              {o.label}
            </Typography>
          </MenuItem>
        );
      })}
    </MenuList>
  );
}
