import { Chip } from '@material-ui/core';
import { styled } from '../emotion';

export const RecommendedBadge = styled(Chip)`
  background-color: ${(p) => p.theme.palette.grey[900]} !important;
  text-transform: uppercase;
  font-size: 13px;
  position: relative;
  top: -2px;
  font-weight: bold;
  margin-left: ${(p) => p.theme.spacing()}px;
  color: white !important;
  height: 26px !important;
`;
