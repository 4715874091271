import { CurrencyCode } from '../currency';
import { DocReference } from '../document';
import { IPubSubInstruction } from '../pubsub';
import { Timestamp } from '../time';

export type LinkCheckTarget = {
  // an arbitrary outside identifier, which will be passed onto the result, so
  // that consumers can identify results independently
  id: string;
  url: string;
  // optional - all consumers need to be able to resolve a url themselves,
  // but a caller can bypass (and therefore gain performance wins) this process
  // by providing an already known destination.
  knownDestinationUrl?: string;
};

export enum LinkCheckStatus {
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  ABORTED = 'ABORTED'
}

export const isLinkCheckPackageDone = (s: LinkCheckStatus) =>
  s === LinkCheckStatus.SUCCESS ||
  s === LinkCheckStatus.ERROR ||
  s === LinkCheckStatus.ABORTED;

export type ResultsCounter = {
  ok: number;
  warnings: number;
  errors: number;
  unknown: number;
  blocked: number;
};

export const EMPTY_RESULTS_COUNTER = () => ({
  ok: 0,
  warnings: 0,
  errors: 0,
  unknown: 0,
  blocked: 0
});

export enum ProductDataStatus {
  OK = 'OK',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  UNKNOWN = 'UNKNOWN',
  BLOCKED = 'BLOCKED'
}

export const getSortValueForProductDataStatus = (status: ProductDataStatus) => {
  const sortValues: { [K in ProductDataStatus]: number } = {
    OK: 0,
    WARNING: 1,
    UNKNOWN: 2,
    BLOCKED: 3,
    ERROR: 4
  };
  return sortValues[status];
};

export const collectResultCounts = (ds: IProductData[]) => {
  return ds.reduce<ResultsCounter>((m, r) => {
    if (r.status === ProductDataStatus.OK) {
      m.ok++;
    }
    if (r.status === ProductDataStatus.WARNING) {
      m.warnings++;
    }
    if (r.status === ProductDataStatus.ERROR) {
      m.errors++;
    }
    if (r.status === ProductDataStatus.UNKNOWN) {
      m.unknown++;
    }
    if (r.status === ProductDataStatus.BLOCKED) {
      m.blocked++;
    }
    return m;
  }, EMPTY_RESULTS_COUNTER());
};

export const countPackageResults = (pkgs: { results: ResultsCounter }[]) => {
  return pkgs.reduce<ResultsCounter>((m, s) => {
    // we control the object. save to mutate.
    m.ok += s.results.ok;
    m.warnings += s.results.warnings;
    m.errors += s.results.errors;
    m.unknown += s.results.unknown;
    m.blocked += s.results.blocked;
    return m;
  }, EMPTY_RESULTS_COUNTER());
};

interface IProductDataCommon {
  id: string;
  url: string;
  destUrl: string;
  pureUrl: string;

  status: ProductDataStatus;
  blocked: boolean;

  type: LinkCheckType;
}

export enum LinkCheckType {
  AMAZON = 'amazon',
  BOOKING = 'booking.com'
}

export const LINK_CHECK_PRIORITY = {
  LOWEST: 0,
  LOW: 250,
  MEDIUM: 500,
  HIGH: 750,
  HIGHEST: 999
};

export interface ILinkCheckPackageCustomConfigInstructions {
  collection: string;
  queries: {
    key: string;
    op: '<' | '<=' | '==' | '>=' | '>' | 'array-contains';
    value: any;
  }[];
}

export interface ILinkCheckPackage<T extends IProductData = IProductData> {
  parent: DocReference;
  targets: LinkCheckTarget[];
  status: LinkCheckStatus;
  priority: number; // higher is better
  queuedAt: Timestamp;
  startedAt: Timestamp | null;
  finishedAt: Timestamp | null;
  results: T[];
  type: LinkCheckType;

  customConfigs: null | ILinkCheckPackageCustomConfigInstructions[];

  publishOnStart?: IPubSubInstruction[];
  publishOnDone?: IPubSubInstruction[];
}

export enum AmazonAvailability {
  OK = 'OK',
  LIMITED = 'LIMITED',
  MARKETPLACE_ONLY = 'MARKETPLACE_ONLY',
  PRE_ORDER = 'PRE_ORDER',
  TEMP_UNAVAILABLE = 'TEMP_UNAVAILABLE',
  SEARCH = 'SEARCH',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  UNAVAILABLE = 'UNAVAILABLE',
  NOT_FOUND = 'NOT_FOUND',
  ERROR = 'ERROR',
  UNKNOWN = 'UNKNOWN',
  ROBOT_CHECK = 'ROBOT_CHECK',
  NON_BUYABLE_AUTHORITY = 'NON_BUYABLE_AUTHORITY',
  AMAZON_SERVICE = 'AMAZON_SERVICE',
  UNSUPPORTED_MARKETPLACE = 'UNSUPPORTED_MARKETPLACE',
  NOT_ACCESSIBLE = 'NOT_ACCESSIBLE'
}

export const AMAZON_AVAILABILITIES = [
  AmazonAvailability.OK,
  AmazonAvailability.LIMITED,
  AmazonAvailability.MARKETPLACE_ONLY,
  AmazonAvailability.PRE_ORDER,
  AmazonAvailability.TEMP_UNAVAILABLE,
  AmazonAvailability.SEARCH,
  AmazonAvailability.OUT_OF_STOCK,
  AmazonAvailability.UNAVAILABLE,
  AmazonAvailability.NOT_FOUND,
  AmazonAvailability.ERROR,
  AmazonAvailability.UNKNOWN,
  AmazonAvailability.ROBOT_CHECK,
  AmazonAvailability.NON_BUYABLE_AUTHORITY,
  AmazonAvailability.AMAZON_SERVICE,
  AmazonAvailability.UNSUPPORTED_MARKETPLACE,
  AmazonAvailability.NOT_ACCESSIBLE
];

export const getAmazonLabel = (status: AmazonAvailability): string => {
  const labels: { [K in AmazonAvailability]: string } = {
    [AmazonAvailability.AMAZON_SERVICE]: 'Amazon Service',
    [AmazonAvailability.ERROR]: 'Error',
    [AmazonAvailability.LIMITED]: 'Limited supply',
    [AmazonAvailability.MARKETPLACE_ONLY]: 'Only on marketplace',
    [AmazonAvailability.NON_BUYABLE_AUTHORITY]: 'Non-buyable authority',
    [AmazonAvailability.NOT_ACCESSIBLE]: 'Page not accessible',
    [AmazonAvailability.NOT_FOUND]: 'Not found',
    [AmazonAvailability.OK]: 'Available',
    [AmazonAvailability.OUT_OF_STOCK]: 'Out of stock',
    [AmazonAvailability.PRE_ORDER]: 'Pre-order',
    [AmazonAvailability.ROBOT_CHECK]: 'Unknown (robot check)',
    [AmazonAvailability.SEARCH]: 'Search results',
    [AmazonAvailability.TEMP_UNAVAILABLE]: 'Temporarily unavailable',
    [AmazonAvailability.UNAVAILABLE]: 'Unavailable',
    [AmazonAvailability.UNKNOWN]: 'Unknown',
    [AmazonAvailability.UNSUPPORTED_MARKETPLACE]: 'Unsupported Marketplace'
  };

  return labels[status] || 'Unknown';
};

// TODO - Make this an explicit dictionary, so that we get exhaustive type checks
export const amazonAvailabilityToStatus = (
  a: AmazonAvailability
): ProductDataStatus => {
  if (
    a === AmazonAvailability.OK ||
    a === AmazonAvailability.LIMITED ||
    a === AmazonAvailability.SEARCH ||
    a === AmazonAvailability.MARKETPLACE_ONLY ||
    a === AmazonAvailability.PRE_ORDER ||
    a === AmazonAvailability.NON_BUYABLE_AUTHORITY ||
    a === AmazonAvailability.AMAZON_SERVICE
  ) {
    return ProductDataStatus.OK;
  }
  if (
    a === AmazonAvailability.OUT_OF_STOCK ||
    a === AmazonAvailability.UNAVAILABLE ||
    a === AmazonAvailability.NOT_FOUND ||
    a === AmazonAvailability.ERROR
  ) {
    return ProductDataStatus.ERROR;
  }
  if (
    a === AmazonAvailability.UNKNOWN ||
    a === AmazonAvailability.UNSUPPORTED_MARKETPLACE
  ) {
    return ProductDataStatus.UNKNOWN;
  }
  if (a === AmazonAvailability.ROBOT_CHECK) {
    return ProductDataStatus.BLOCKED;
  }
  return ProductDataStatus.WARNING;
};

export interface IAmazonProductData extends IProductDataCommon {
  asin: string;
  title: string;
  affiliateId: string;
  imgSrc: string;
  availability: AmazonAvailability;
  rating: {
    avg: number;
    count: number;
  } | null;
  price: {
    amount: number; // in cents!
    currency: CurrencyCode | null;
  }[];
  amazonChoice: boolean;
}

export type IProductData = IAmazonProductData;

export interface IAmazonLinkCheckPackage
  extends ILinkCheckPackage<IAmazonProductData> {
  type: LinkCheckType.AMAZON;
}
