import { difference, sortBy } from 'lodash';
import moment from 'moment-timezone';
import { DAY_FORMAT } from '../../domainTypes/analytics';
import {
  CURRENCIES,
  CurrencyCode,
  ICurrencyConverter,
  IExchangeRate
} from '../../domainTypes/currency';
import { Doc, generateToDocFn } from '../../domainTypes/document';
import { DateLike, dateLikeToMoment } from '../../services/time';
import { FS } from '../../versions';
import { store } from '../db';

export const toExchangeRateDoc = generateToDocFn<IExchangeRate>();

const DATE_FORMAT = 'YYYY-MM-DD';

export type DailyRate = { date: string; rates: { [currency: string]: number } };
export const toDailyRateFromExchangeRateDoc = (
  d: Doc<IExchangeRate>
): DailyRate => {
  return {
    date: d.data.date,
    rates: CURRENCIES.reduce<DailyRate['rates']>((m, cur, i) => {
      m[cur] = d.data.rates[i];
      return m;
    }, {})
  };
};

export const getLatestUsdExchangeRates = () => {
  return store()
    .collection(FS.exchangeRates)
    .orderBy('date', 'desc')
    .limit(1)
    .get()
    .then(
      (s) => s.docs.map(toExchangeRateDoc)[0] || Promise.reject('NOT_FOUND')
    )
    .then((d) => toDailyRateFromExchangeRateDoc(d));
};

export const getHistoricalUsdExchangeRates = (
  start: string, // YYYY-MM-DD
  end: string // YYYY-MM-DD
) => {
  return store()
    .collection(FS.exchangeRates)
    .where('date', '>=', start)
    .where('date', '<=', end)
    .get()
    .then((s) =>
      s.docs.map(toExchangeRateDoc).map(toDailyRateFromExchangeRateDoc)
    );
};

const getConversionRate = (
  r: DailyRate,
  from: CurrencyCode,
  to: CurrencyCode
) => {
  if (from === to) {
    return 1;
  }
  if (from === 'USD') {
    return r.rates[to];
  }

  const fromRate = r.rates[from];
  const toRate = r.rates[to];
  return toRate / fromRate; // double check
};

const MIN_DATE = '1999-01-04';
const parseDate = (d: DateLike) => {
  const date = dateLikeToMoment(d).format(DATE_FORMAT);
  const underThreshold = date < MIN_DATE;
  if (underThreshold) {
    console.log(
      `Date too early: ${date} is earlier than min date ${MIN_DATE} - fall back to min date`
    );
  }
  return underThreshold ? MIN_DATE : date;
};

const getDayFormatRange = (start: string, end: string) => {
  const s = moment(start);
  const e = moment(end);
  const range: string[] = [];
  while (s.isSameOrBefore(e)) {
    range.push(s.format(DAY_FORMAT));
    s.add(1, 'd');
  }
  return range;
};

export class CurrencyConverter implements ICurrencyConverter {
  cache: { [date: string]: Promise<DailyRate> } = {};

  // @ts-ignore - optimize preloading
  private getPreloadableRange(
    start: string,
    end: string
  ): {
    start: string;
    end: string;
  } | null {
    const cached = Object.keys(this.cache).sort();
    if (!cached.length) {
      return { start, end };
    }
    const requestedRange = getDayFormatRange(start, end);
    const remainingKeys = difference(requestedRange, cached);
    if (!remainingKeys.length) {
      return null;
    }
    return {
      start: remainingKeys[0],
      end: remainingKeys[remainingKeys.length - 1]
    };
  }

  async preload(start: DateLike, end: DateLike) {
    const [s, e] = sortBy(
      [dateLikeToMoment(start), dateLikeToMoment(end)],
      (m) => m.valueOf()
    ).map((m) => m.format(DATE_FORMAT));
    const range = this.getPreloadableRange(s, e);
    if (range) {
      const rates = await getHistoricalUsdExchangeRates(range.start, range.end);
      rates.forEach((r) => (this.cache[r.date] = Promise.resolve(r)));
    }
  }

  private async getLatest() {
    return (this.cache['LATEST'] =
      this.cache['LATEST'] || getLatestUsdExchangeRates());
  }

  private async get(d: DateLike) {
    // add upper and lower boundary
    // 1999-01-04 and yesterday/today
    const date = parseDate(d);
    // naively assume that the date exists!!
    if (!this.cache[date]) {
      const r = store()
        .collection(FS.exchangeRates)
        .doc(date)
        .get()
        .then((s) => {
          if (!s.exists) {
            console.log(`Date not found: ${date} - fallback to latest`);
            return this.getLatest();
          }
          return toDailyRateFromExchangeRateDoc(toExchangeRateDoc(s));
        });
      this.cache[date] = r;
    }

    return this.cache[date];
  }

  async convert(
    amount: number,
    from: CurrencyCode,
    to: CurrencyCode,
    date?: DateLike
  ): Promise<number> {
    if (amount === 0) {
      return 0;
    }
    if (from === to) {
      return amount;
    }
    const m = dateLikeToMoment(date ?? Date.now());
    const r = await this.get(m.format(DATE_FORMAT));
    const rate = getConversionRate(r, from, to);
    return amount * rate;
  }

  async convertNullable(
    amount: number | null,
    from: CurrencyCode,
    to: CurrencyCode,
    date?: DateLike
  ): Promise<number | null> {
    return amount === null ? null : this.convert(amount, from, to, date);
  }
}

export const CURRENCY_CONVERTER = new CurrencyConverter();
