import { InputBase, MenuItem, Select } from '@material-ui/core';
import { useMixpanel } from '../../../services/mixpanel';
import { useHasPayouts } from '../../../services/payouts';

export type GraphMode =
  | 'platform'
  | 'advertiser'
  | 'channel'
  | 'origin'
  | 'device'
  | 'country'
  | 'transactionStatus'
  | 'payoutStatus'
  | 'seller'
  | 'brand'
  | 'partner_product_category'
  | 'partner_product_name'
  | 'partner_product_id';

export const graphModeToLabel = (graphMode: GraphMode) => {
  const labels: { [key in GraphMode]: string } = {
    brand: 'Brand',
    seller: 'Seller',
    platform: 'Platform',
    advertiser: 'Advertiser',
    channel: 'Channel',
    origin: 'Origin',
    device: 'Device',
    country: 'Country',
    transactionStatus: 'Transaction Status',
    partner_product_category: 'Product category',
    partner_product_name: 'Product name',
    partner_product_id: 'Product ID',
    payoutStatus: 'Payout Status'
  };
  return labels[graphMode] || '';
};

const STANDARD_MODES: Exclude<GraphMode, 'payoutStatus'>[] = [
  'platform',
  'advertiser',
  'channel',
  'origin',
  'device',
  'country',
  'transactionStatus',
  'partner_product_name',
  'partner_product_id'
];

export const GraphModeSelector = ({
  value,
  onChange
}: {
  value: GraphMode;
  onChange: (nextValue: GraphMode) => void;
}) => {
  const mixpanel = useMixpanel();
  const hasPayouts = useHasPayouts();

  let options: {
    value: GraphMode;
    label: string;
  }[] = STANDARD_MODES.map((value) => ({
    value,
    label: graphModeToLabel(value)
  }));

  if (hasPayouts) {
    options = [
      ...options,
      { value: 'payoutStatus', label: graphModeToLabel('payoutStatus') }
    ];
  }

  return (
    <Select
      value={value}
      onChange={(ev) => {
        const gm = ev.target.value as GraphMode;
        mixpanel.track('set_graph_mode', {
          graphMode: gm
        });
        return onChange(gm);
      }}
      disableUnderline
      input={<InputBase style={{ fontSize: '0.85rem' }} />}
    >
      {options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          By {label}
        </MenuItem>
      ))}
    </Select>
  );
};
