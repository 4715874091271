import {
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import { AlertTriangle } from 'react-feather';
import { CardAlertBox, AlertBox } from '../../../../components/AlertBox';
import { Arrow } from '../../../../components/Arrow';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import { InfoTabBody, InfoTabLabel } from '../../../../components/InfoTabs';
import { ProgressRing } from '../../../../components/ProgressRing';
import { Stopwatch } from '../../../../components/Stopwatch';
import { Doc } from '../../../../domainTypes/document';
import {
  hasPageErrored,
  IProductScanResult,
  isPageDone,
  isScanDone,
  wasPageAborted
} from '../../../../domainTypes/productScan';
import { css, styled } from '../../../../emotion';
import { CanvasBar } from '../../../../layout/Canvas';
import { Centered } from '../../../../layout/Centered';
import { Section } from '../../../../layout/Section';
import {
  formatDate,
  getTimeElapsed,
  toMoment
} from '../../../../services/time';
import { getProgress } from '../../pages/ProductScanDetail/service';
import { ScanAborter } from '../ScanAborter';
import { ScanStatus } from '../ScanStatus';
import { PageTable } from './PageTable';
import { ARTICLES } from '../../../../services/beacon';
import { HelpIcon } from '../../../../components/HelpIcon';

type Props = {
  scanDoc: Doc<IProductScanResult>;
  onRescan: (spaceId: string, scanId: string, pages: string[]) => Promise<{}>;
};

const getAllUnfinishedPageUrls = (scan: IProductScanResult): string[] => {
  return scan.pages
    .filter((p) => !isPageDone(p) || hasPageErrored(p) || wasPageAborted(p))
    .map((p) => p.url);
};

const Grid = styled('div')`
  display: grid;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(4)}px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
  }
`;

const NumberGrid = styled('div')`
  display: grid;
  margin-top: ${(p) => p.theme.spacing(3)}px;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(4)}px;
  grid-template-columns: 1fr 1fr;

  div {
    text-align: center;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${(p) => p.theme.spacing(1)}px;
    grid-row-gap: ${(p) => p.theme.spacing(2)}px;
  }
`;

const AlertHeader = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing()}px;
  > * {
    margin-right: ${(p) => p.theme.spacing()}px;
  }
`;

const Alerts: React.FC<{ scan: IProductScanResult }> = ({ scan }) => {
  const isDone = isScanDone(scan);

  return (
    <>
      {scan.notes.map((note) => {
        if (
          (!isDone && note === 'SITEGROUND_SLOW_DOWN') ||
          note === 'GODADDY_SLOW_DOWN'
        ) {
          return (
            <CardAlertBox variant="pending" key={note}>
              <AlertHeader>
                <AlertTriangle />
                <strong>This is going to take a while</strong>
              </AlertHeader>
              <div>
                Your site's hosting limits how quickly we can crawl your website
                for affiliate links. We'll email you when your scan is finished.
              </div>
            </CardAlertBox>
          );
        }

        if (note === 'SITEMAP_EMPTY') {
          return (
            <AlertBox variant="pending" key={note}>
              <AlertHeader>
                <Typography
                  variant="body1"
                  component="p"
                  gutterBottom
                  style={{ fontWeight: 'bold' }}
                >
                  Could not scan sitemap
                </Typography>
              </AlertHeader>
              <Typography
                variant="body1"
                component="p"
                style={{ marginBottom: '18px' }}
              >
                Affilimate was not able to access your sitemap and crawl for
                affiliate links. Try the recommended solutions and re-scan your
                site.
              </Typography>
              <HelpIcon
                color="blue"
                articleId={ARTICLES.links.troubleshootScan}
              >
                See recommended solutions
              </HelpIcon>
            </AlertBox>
          );
        }

        return null;
      })}
    </>
  );
};

export const ScanTracker = ({ scanDoc, onRescan }: Props) => {
  const { id: scanId, data: scan } = scanDoc;
  const [showPageDetails, setShowPageDetails] = useState(false);
  const start = toMoment(scan.createdAt);
  const { completed, total, aborted, errors, linksFound } = getProgress(scan);
  const pending = total - completed;
  return (
    <Section>
      <CanvasBar>
        Scan status <ScanStatus status={scan.status} />
      </CanvasBar>
      <Card>
        <Alerts scan={scan} />
        <CardContent>
          <Grid style={{ textAlign: 'center' }}>
            <Typography variant="body2">
              {scan.finishedAt ? (
                `Finished in ${getTimeElapsed(
                  start,
                  toMoment(scan.finishedAt)
                )} seconds`
              ) : (
                <span>
                  Running for <Stopwatch start={start} /> seconds...
                </span>
              )}
            </Typography>
            <Typography variant="body2">
              Started at {formatDate(start)}
            </Typography>
          </Grid>
          <Grid>
            <Centered height={200}>
              {scan.status === 'INITIALISING' ? (
                <Typography paragraph>Scanning sitemap...</Typography>
              ) : scan.status === 'SPEED_TEST' ? (
                <Typography paragraph>Testing page speed...</Typography>
              ) : (
                <ProgressRing
                  done={completed - errors - aborted}
                  pending={pending}
                  unknown={aborted}
                  error={errors}
                />
              )}
            </Centered>
            <NumberGrid>
              <div role="button" onClick={() => setShowPageDetails(true)}>
                <InfoTabLabel>Total pages</InfoTabLabel>
                <InfoTabBody>{total}</InfoTabBody>
              </div>
              <div role="button" onClick={() => setShowPageDetails(true)}>
                <InfoTabLabel>Scanned pages</InfoTabLabel>
                <InfoTabBody>{completed - errors - aborted}</InfoTabBody>
              </div>
              <div role="button" onClick={() => setShowPageDetails(true)}>
                <InfoTabLabel>Failed scans</InfoTabLabel>
                <InfoTabBody>{errors}</InfoTabBody>
              </div>
              <div>
                <InfoTabLabel>Links found</InfoTabLabel>
                <InfoTabBody>{linksFound}</InfoTabBody>
              </div>
            </NumberGrid>
          </Grid>
        </CardContent>
        <CardActions
          className={css(() => ({ justifyContent: 'space-between' }))}
        >
          <div>
            {!isScanDone(scan) && (
              <ScanAborter spaceId={scan.spaceId} scanId={scanId} />
            )}
            {isScanDone(scan) && (errors > 0 || aborted > 0 || pending > 0) && (
              <ButtonWithPromise
                size="small"
                color="primary"
                variant="contained"
                pending="Restarting scan..."
                onClick={() =>
                  onRescan(scan.spaceId, scanId, getAllUnfinishedPageUrls(scan))
                }
              >
                Re-scan unfinished pages
              </ButtonWithPromise>
            )}
          </div>
          <Button
            size="small"
            onClick={() => setShowPageDetails(!showPageDetails)}
            aria-expanded={showPageDetails}
          >
            {showPageDetails ? 'Hide' : 'Show'} details{' '}
            <Arrow dir={showPageDetails ? 'UP' : 'DOWN'} />
          </Button>
        </CardActions>
        <Collapse in={showPageDetails}>
          <PageTable
            pages={scan.pages}
            absoluteUrls={scan.targets.length > 1}
          />
        </Collapse>
      </Card>
    </Section>
  );
};
