import { InputLabel, TextField } from '@material-ui/core';
import React from 'react';

interface SlotSettingsProps {
  name: string;
  setName: (name: string) => void;
  description: string;
  setDescription: (description: string) => void;
}

export const SlotSettingsControl: React.FC<SlotSettingsProps> = ({
  name,
  setName,
  description,
  setDescription
}) => (
  <>
    <InputLabel id="name">Dimension name</InputLabel>
    <TextField
      id="name"
      value={name}
      autoFocus
      required
      onChange={(e) => setName(e.target.value)}
      variant="outlined"
      fullWidth
    />
    <InputLabel id="description">Dimension description</InputLabel>
    <TextField
      id="description"
      value={description}
      required
      onChange={(e) => setDescription(e.target.value)}
      variant="outlined"
      fullWidth
    />
  </>
);
