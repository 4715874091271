import { CampaignStatus } from '../service';
import { CheckCircle, Circle, Clock, PieChart } from 'react-feather';
import React from 'react';

export const CampaignStatusIcon = ({ status }: { status: CampaignStatus }) => {
  switch (status) {
    case 'planning':
      return <Circle size={18} color="#828282" />;
    case 'scheduled':
      return <Clock size={18} color="#FAAD14" />;
    case 'running':
      return <PieChart size={18} color="#39B185" />;
    case 'completed':
      return <CheckCircle size={18} />;
  }
};
