import React from 'react';
import { Helmet } from 'react-helmet';
import { useHasCurrentUserRequiredScopes } from '../../../../../../services/currentUser';
import { PerformancePageBody } from '../../../../components/PerformancePageBody';
import { NoPermissions } from '../../../../../../components/NoPermissions';
import { PagePerformanceAdvertiserDetailsInner } from './AdvertiserDetails';

export const PagePerformanceAdvertiserDetails = () => {
  const [canView] = useHasCurrentUserRequiredScopes([
    'reports.advertisers.view'
  ]);

  return (
    <>
      <Helmet>
        <title>Details | Affilimate</title>
      </Helmet>
      {canView ? (
        <PerformancePageBody noTopPadding>
          <PagePerformanceAdvertiserDetailsInner />
        </PerformancePageBody>
      ) : (
        <PerformancePageBody>
          <NoPermissions />
        </PerformancePageBody>
      )}
    </>
  );
};
