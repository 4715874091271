import { compact } from 'lodash';
import { useMemo } from 'react';
import {
  AnalyticsFilter,
  AnalyticsQuery,
  ISOTimeRange
} from '../../../domainTypes/analytics_v2';
import { EMPTY_ARR } from '../../../domainTypes/emptyConstants';
import { useAnalyticsQueryV2 } from '../../../services/analyticsV2/query';
import { COLOR_UNKNOWN } from '../../../services/color';
import { useCurrentUser } from '../../../services/currentUser';
import { useMappedLoadingValue } from '../../../services/db';
import { getKnownPartnerForKey } from '../../../services/partner';
import { formatCurrency } from '../../Number';
import { useTimeframe } from '../Timeframe';

export const usePlatformsWithLinkCounts = (
  filters: AnalyticsFilter[],
  range: ISOTimeRange
) => {
  const { space } = useCurrentUser();
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      select: ['count_uniq_link_id'],
      groupBy: ['pk'],
      filters
    };
  }, [filters, range]);

  return useMappedLoadingValue(useAnalyticsQueryV2(space.id, query), (res) => {
    return compact(
      res.rows.map((row) => {
        const pk = row.group.pk;
        const linksCount = row.data.count_uniq_link_id?.curr ?? 0;
        if (pk === null)
          return {
            key: '',
            name: `Unknown (${linksCount})`,
            color: COLOR_UNKNOWN
          };
        const partner = getKnownPartnerForKey(pk);
        if (!partner) return null;
        return {
          ...partner,
          name: `${partner.name} (${linksCount})`
        };
      })
    ).sort((a, b) => a.name.localeCompare(b.name));
  });
};

export const usePlatformsWithCommission = (
  filters: AnalyticsFilter[] = EMPTY_ARR
) => {
  const { space } = useCurrentUser();
  const { range } = useTimeframe();
  const { currency } = space.config;
  const query = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      select: ['commission_sum_net'],
      groupBy: ['pk'],
      filters
    };
  }, [filters, range]);

  return useMappedLoadingValue(useAnalyticsQueryV2(space.id, query), (res) => {
    return compact(
      res.rows.map((row) => {
        const pk = row.group.pk;
        const commission = row.data.commission_sum_net?.curr ?? 0;
        const commStr = formatCurrency(commission, currency);
        if (pk === null)
          return {
            key: '',
            name: `Unknown (${commStr})`,
            color: COLOR_UNKNOWN
          };
        const partner = getKnownPartnerForKey(pk);
        if (!partner) return null;
        return {
          ...partner,
          name: `${partner.name} (${commStr})`
        };
      })
    ).sort((a, b) => a.name.localeCompare(b.name));
  });
};
