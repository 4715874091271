import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  FormControl,
  TextField,
  Typography
} from '@material-ui/core';
import { capitalize } from 'lodash';
import React, { FormEvent, useState } from 'react';
import { AlertBox } from '../../../../../components/AlertBox';
import { IPermissions, RoleId } from '../../../../../domainTypes/permissions';
import { useCurrentUser } from '../../../../../services/currentUser';
import { updatePermissions } from '../service';
import { RoleSelector } from './RoleSelector';
import { first } from 'lodash/fp';
import { RealUser } from '../../../../../services/user';
import { useAvailableRoles } from '../../../../../services/roles';

export const EditPermissionsDialog = ({
  user,
  permissions,
  onClose,
  onSuccess
}: {
  user: RealUser;
  permissions: IPermissions;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const currentUser = useCurrentUser();
  const availableRoles = useAvailableRoles();
  const initialRole = first(permissions.roles);
  const [role, setRole] = useState<RoleId>(initialRole || 'ADMIN');
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const email = user.user.data.email;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const userId = user.id;

    try {
      await updatePermissions(userId, currentUser.space.id, {
        roles: [role],
        additionalScopes: []
      });
      setErrorMsg(null);
      onSuccess();
      onClose();
    } catch (err) {
      setErrorMsg(capitalize(err.message).replaceAll('_', ' '));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <DialogTitle>Update permissions</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Typography variant="body1" component="p" paragraph>
            Change the role assigned to this user. Learn about roles
          </Typography>
          <br />
          <InputLabel id="emails">User email</InputLabel>
          <br />
          <TextField
            variant="outlined"
            value={email}
            fullWidth
            disabled
            id="email"
            name="email"
          />

          <br />
          <br />
          <InputLabel id="role">New Role</InputLabel>
          <FormControl
            style={{
              width: '100%',
              marginTop: '13px',
              marginBottom: '8px'
            }}
          >
            <RoleSelector
              role={role}
              setRole={setRole}
              availableRoles={availableRoles}
            />
          </FormControl>
          {errorMsg && (
            <AlertBox variant="error">
              <strong>An error occurred.</strong> {errorMsg}.
            </AlertBox>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onClose}>
            Done
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? `Updating permissions` : `Update permissions`}
          </Button>
        </DialogActions>
      </form>
    </div>
  );
};
