import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { ChevronDown, Grid } from 'react-feather';
import { SingleSelector, SingleSelectorOption } from '../SingleSelector';

export const GroupSelector = <T extends unknown>({
  value,
  onChange,
  options
}: {
  value: T | null | undefined;
  onChange: (nextValue: T) => void;
  options: SingleSelectorOption<T>[];
}) => {
  const selectedOption = useMemo(() => {
    return options.find((o) => o.value === value);
  }, [value, options]);
  return (
    <SingleSelector
      value={value}
      onChange={onChange}
      legend="Group by..."
      options={options}
    >
      <Typography
        variant="body2"
        color="textSecondary"
        component="span"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '6px'
        }}
      >
        <Grid size={18} />{' '}
        {selectedOption ? (
          <>
            Group <strong>{selectedOption.label}</strong>
          </>
        ) : (
          'No group'
        )}
        <ChevronDown size={18} />
      </Typography>
    </SingleSelector>
  );
};
