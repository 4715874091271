import { CurrencyCode } from './currency';
import { Timestamp } from './time';

export const toAdvertiserDocId = (advertiser: CachedAdvertiser) => {
  return `${advertiser.spaceId}-${advertiser.partnerKey}-${advertiser.integrationId}-${advertiser.apiId}`;
};

/*
type AdvertiserChannel = {
  name: string;
  id: string;
  url: string;
};
*/

export type AdvertiserRateType = 'Percent' | 'Flat' | 'Unknown';
export type AdvertiserPayoutType = 'CPA' | 'CPC' | 'CPL' | 'Unknown';
export type AdvertiserMeta = {
  allowsDeeplinking?: boolean;
};

export type AdvertiserRate = {
  payoutType: AdvertiserPayoutType;
  rateType: AdvertiserRateType;
  currency: CurrencyCode | null;
  baseRatePercent: number | null;
  actualRatePercent: number | null;
  baseRateFlat: number | null;
  actualRateFlat: number | null;
  // Number of seconds in the cookie duration (e.g. 30 days = 2592000)
  // Session-based cookies will be expressed as zero
  // Unknown cookie periods will be expressed as null
  cookieDurationSeconds: number | null;
  description: string | null;
  metadata?: {
    domains?: string[]; // Advertiser rates per site
    publisherDomains?: string[]; // Publisher websites
  };
};

interface CachedAdvertiserCommon {
  spaceId: string;
  integrationId: string;
  lastUpdated: Timestamp;
  name: string;
  advertiserId: string;
  apiId: string;
  domains: string[];

  status: 'Active' | 'Inactive' | 'Unknown';
  query: string; // Lowercase advertiser name used for querying
  rates: AdvertiserRate[];
  payoutTypes: AdvertiserPayoutType[];
  rateTypes: AdvertiserRateType[];
  maxRatePercent: number | null;
  maxRateFlat: number | null;
  maxRateCurrency: CurrencyCode | null;
  hasSpecialRates: boolean;
  metadata?: AdvertiserMeta;
}

export interface CachedAdvertiserAdmitAd extends CachedAdvertiserCommon {
  partnerKey: 'admitad';
  adSpaceId: string;
  adSpaceUrl: string;
}

export interface CachedAdvertiserCj extends CachedAdvertiserCommon {
  partnerKey: 'cj';
}

export interface CachedAdvertiserAvantLink extends CachedAdvertiserCommon {
  partnerKey: 'avantlink';
  merchantId: string; // Merchant ID refers to old merchant ID, vs. the new UUID to be stored in the advertiserId
  defaultTrackingLink: string;
}

export interface CachedAdvertiserImpact extends CachedAdvertiserCommon {
  partnerKey: 'impact';
  adId: string;
  trackingLink: string | null;
}

interface CachedAdvertiserSkimlinks extends CachedAdvertiserCommon {
  partnerKey: 'skimlinks';
}

interface CachedAdvertiserAmazon extends CachedAdvertiserCommon {
  partnerKey: 'amazon';
}

export interface CachedAdvertiserShareASale extends CachedAdvertiserCommon {
  partnerKey: 'shareasale';
  trackingLink: string;
  stores: {
    id: string;
    name: string;
    url: string[];
    trackingLink: string;
  }[];
}

interface CachedAdvertiserPepperjam extends CachedAdvertiserCommon {
  partnerKey: 'pepperjam';
  trackingLink: string | null;
}

interface CachedAdvertiserAwin extends CachedAdvertiserCommon {
  partnerKey: 'awin';
  trackingLink: string | null;
}

export interface CachedAdvertiserSovrn extends CachedAdvertiserCommon {
  partnerKey: 'sovrn';
}

export interface CachedAdvertiserRefersion extends CachedAdvertiserCommon {
  partnerKey: 'refersion';
  parameter: string;
}

export interface CachedAdvertiserRakuten extends CachedAdvertiserCommon {
  partnerKey: 'rakuten';
}

export interface CachedAdvertiserHowl extends CachedAdvertiserCommon {
  partnerKey: 'howl';
}

export interface CachedAdvertiserBelboon extends CachedAdvertiserCommon {
  partnerKey: 'belboon';
  adSpaceId: string;
  adSpaceName: string;
  trackingLink: string;
  internalAdvertiserId: string;
}

export interface CachedAdvertiserDigidip extends CachedAdvertiserCommon {
  partnerKey: 'digidip';
  blockedProjects: number[];
}

export interface CachedAdvertiserLevanta extends CachedAdvertiserCommon {
  partnerKey: 'levanta';
  asins: string[];
}

export interface CachedAdvertiserPartnerize extends CachedAdvertiserCommon {
  partnerKey: 'partnerize';
  networkId: string | null;
  campaignNames: string[];
  campaignIds: string[];
  camrefs: string[];
}

export type CachedAdvertiser =
  | CachedAdvertiserAdmitAd
  | CachedAdvertiserAmazon
  | CachedAdvertiserAvantLink
  | CachedAdvertiserAwin
  | CachedAdvertiserBelboon
  | CachedAdvertiserCj
  | CachedAdvertiserDigidip
  | CachedAdvertiserHowl
  | CachedAdvertiserImpact
  | CachedAdvertiserPartnerize
  | CachedAdvertiserLevanta
  | CachedAdvertiserPepperjam
  | CachedAdvertiserRakuten
  | CachedAdvertiserRefersion
  | CachedAdvertiserShareASale
  | CachedAdvertiserSkimlinks
  | CachedAdvertiserSovrn;

const ONE_WEEK_IN_MS = 60 * 60 * 24 * 7 * 1000;

export const isAdvertiserStale = (advertiser: CachedAdvertiser): boolean => {
  return advertiser.lastUpdated.toMillis() < Date.now() - ONE_WEEK_IN_MS;
};
