import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  FormControl,
  TextField,
  Typography
} from '@material-ui/core';
import { capitalize } from 'lodash';
import React, { FormEvent, useMemo, useState } from 'react';
import { AlertBox } from '../../../../../components/AlertBox';
import { RoleId } from '../../../../../domainTypes/permissions';
import { styled } from '../../../../../emotion';
import { useRoutes } from '../../../../../routes';
import { useCurrentUser } from '../../../../../services/currentUser';
import { createInvitation } from '../service';
import * as ENV from '../../../../../env.json';
import { RoleSelector } from './RoleSelector';
import { HelpIcon } from '../../../../../components/HelpIcon';
import { ARTICLES } from '../../../../../services/beacon';
import { useAvailableRoles } from '../../../../../services/roles';

const A = styled('a')`
  color: ${(p) => p.theme.palette.primary.main};
  word-break: break-all;
`;

export const InviteUserDialog = ({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { ROUTES } = useRoutes();
  const currentUser = useCurrentUser();
  const availableRoles = useAvailableRoles();
  const [email, setEmail] = useState('');
  const [role, setRole] = useState<RoleId>('ADMIN');
  const [loading, setLoading] = useState(false);
  const [inviteId, setInviteId] = useState<string | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const result = await createInvitation(currentUser.space.id, email, {
        roles: [role],
        additionalScopes: []
      });
      setErrorMsg(null);
      setInviteId(result.status === 'OK' ? result.invitationId : '');
    } catch (err) {
      setErrorMsg(capitalize(err.message).replaceAll('_', ' '));
    } finally {
      setLoading(false);
    }
  };

  const inviteLink = useMemo(() => {
    if (!inviteId) {
      return null;
    }

    const appUrl = ENV.domains.app.public;
    const appUrlWithoutSlash = appUrl.endsWith('/')
      ? appUrl.substr(0, appUrl.length - 1)
      : appUrl;

    const link = new URL(
      `${appUrlWithoutSlash}${ROUTES.invite.accept.url(inviteId)}`
    );

    // Remove the spaceId param so that multi-space users don't
    // accidentally trigger sending people to an error page
    link.searchParams.delete('spaceId');

    return link.href;
  }, [inviteId, ROUTES]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" scroll="body">
      <DialogTitle>Create invite link</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Typography variant="body1" component="p" paragraph>
            Add additional users to your team by creating and sharing
            individualized team invite links.
          </Typography>
          <br />
          <InputLabel id="emails">Email address</InputLabel>
          <br />
          <TextField
            variant="outlined"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            fullWidth
            id="email"
            name="email"
            required
            placeholder="name@yourcompany.com"
          />

          <br />
          <br />
          <InputLabel id="role">
            Role &nbsp;
            <HelpIcon color="blue" articleId={ARTICLES.account.teams}>
              Learn about roles
            </HelpIcon>
          </InputLabel>
          <FormControl
            style={{
              width: '100%',
              marginTop: '13px',
              marginBottom: '8px'
            }}
          >
            <RoleSelector
              role={role}
              setRole={setRole}
              availableRoles={availableRoles}
            />
          </FormControl>
          {inviteLink && (
            <AlertBox variant="success">
              <strong>Invitation created!</strong> Send this link to your team
              member:
              <br />
              <br />
              <A href={inviteLink} target="_blank">
                {inviteLink}
              </A>
              <br />
              <br />
              For security reasons, this link will expire in 36 hours. At that
              point you can revoke and regenerate a new link.
            </AlertBox>
          )}
          {errorMsg && (
            <AlertBox variant="error">
              <strong>An error occurred.</strong> {errorMsg}.
            </AlertBox>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onClose}>
            Done
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading || !email}
          >
            {loading ? `Creating invite...` : `Create invite link`}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
