import { ButtonBase } from '@material-ui/core';
import React from 'react';
import { CurrencyCode } from '../../../domainTypes/currency';
import { styled } from '../../../emotion';

export const formatChartNumber = (value: number) => {
  const digits = value < 999 ? 0 : 1;
  const res = value.toLocaleString('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  });
  if (res.endsWith('.0K')) {
    return res.replace('.0K', 'K');
  }
  return res;
};

export const formatChartCurrency = (
  amountInCents: number,
  currency: CurrencyCode,
  options?: {
    excludeCents: boolean;
  }
) => {
  const value = amountInCents / 100;

  if (Math.abs(value) < 1000 || value === 0) {
    const maxDigits = options?.excludeCents ? 0 : 2;
    const res = value.toLocaleString('en-US', {
      style: 'currency',
      currency,
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: maxDigits,
      maximumFractionDigits: maxDigits
    });
    return res.includes('.0K') ? res.replace('.0K', 'K') : res;
  }

  const maxDigits = options?.excludeCents ? 1 : 2;

  const res = value.toLocaleString('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    style: 'currency',
    currency,
    minimumFractionDigits: maxDigits,
    maximumFractionDigits: maxDigits
  });
  return res.includes('.0K') ? res.replace('.0K', 'K') : res;
};

export const LegendLabel = styled<'span', { size?: 'small' | 'normal' }>(
  'span'
)`
  color: ${({ theme }) => theme.palette.grey[600]};
  font-size: ${(p) =>
    p.size === 'small'
      ? p.theme.custom.fontSize.s
      : p.theme.custom.fontSize.m}px;
`;

const ShapeContainer = styled('div')((p) => ({
  display: 'grid',
  gridTemplateColumns: 'min-content 1fr',
  gridColumnGap: p.theme.spacing(1),
  alignItems: 'center'
}));

const SquareShape = styled<
  'div',
  { color: string; small: boolean; large: boolean; outlineColor?: string }
>('div')`
  background-color: ${(p) => p.color};
  height: ${(p) => (p.large ? 16 : p.small ? 10 : 12)}px;
  width: ${(p) => (p.large ? 16 : p.small ? 10 : 12)}px;
  border-radius: 2px;
  border: ${(p) =>
    p.outlineColor ? `2px solid ${p.outlineColor}` : 'initial'};
`;

const CircleShape = styled(SquareShape)`
  border-radius: 50%;
`;

const Square = styled<'div', { color: string; active: boolean }>('div')`
  width: 12px;
  height: 12px;
  border: 3px solid ${(p) => p.color};
  background: ${(p) => (p.active ? p.color : 'none')};
  margin-right: ${(p) => p.theme.spacing(1)}px;
`;

const Circle = styled<typeof Square, { color: string; active: boolean }>(
  Square
)`
  border-radius: 50%;
`;

export type ILegendItem = {
  shape: 'circle' | 'square';
  color: string;
  active: boolean;
  onClick: () => void;
  label: string;
};
export const Legend = ({ items }: { items: ILegendItem[] }) => {
  return (
    <LegendElementWrapper>
      {items.map((i) => (
        <LegendElement
          key={i.label}
          shape={i.shape}
          color={i.color}
          active={i.active}
          onClick={i.onClick}
          label={i.label}
        />
      ))}
    </LegendElementWrapper>
  );
};

const LegendElementWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  gap: ${(p) => p.theme.spacing()}px;
  padding: ${(p) => p.theme.spacing()}px;
`;

const LegendElement = ({
  color,
  active,
  onClick,
  label,
  shape = 'circle'
}: {
  shape?: 'square' | 'circle';
  color: string;
  active: boolean;
  onClick?: () => void;
  label: string;
}) => {
  return (
    <ButtonBase onClick={onClick} disabled={!onClick}>
      {shape === 'circle' && <Circle color={color} active={active} />}
      {shape === 'square' && <Square color={color} active={active} />}
      <LegendLabel>{label}</LegendLabel>
    </ButtonBase>
  );
};

const InnerContainer = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const WithShape: React.FC<{
  color: string;
  outlineColor?: string;
  small?: boolean;
  large?: boolean;
  shape?: 'circle' | 'square';
  style?: React.CSSProperties;
}> = ({
  color,
  small,
  large,
  shape = 'circle',
  outlineColor,
  children,
  style
}) => {
  return (
    <ShapeContainer>
      {shape === 'circle' ? (
        <CircleShape
          color={color}
          outlineColor={outlineColor}
          small={!!small}
          large={!!large}
          style={style}
        />
      ) : (
        <SquareShape
          outlineColor={outlineColor}
          color={color}
          small={!!small}
          large={!!large}
          style={style}
        />
      )}
      <InnerContainer>{children}</InnerContainer>
    </ShapeContainer>
  );
};
