import React from 'react';
import { useRoutes } from '../../../../routes';
import { usePromise } from '../../../../hooks/usePromise';
import { getProductByIdPg } from '../../../../services/products';
import { Tooltip, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { css, styled } from '../../../../emotion';
import { truncate } from 'lodash';
import { ExternalLink } from 'react-feather';
import { Skeleton } from '@material-ui/lab';

const StyledExternalLink = styled('a')`
  color: ${(p) => p.theme.palette.primary.main};
`;

const StyledInternalLink = styled(Link)`
  color: ${(p) => p.theme.palette.common.black};
`;

export const ReferringLink = ({
  spaceId,
  productId
}: {
  spaceId: string;
  productId: string;
}) => {
  const { ROUTES } = useRoutes();
  const [product, loading] = usePromise(() =>
    getProductByIdPg(spaceId, productId)
  );

  return (
    <Typography variant="body2" component="p" color="textSecondary">
      {product ? (
        <div>
          <StyledInternalLink to={ROUTES.links.overview_v2.url({}, productId)}>
            {truncate(product.name, { length: 40 })}
          </StyledInternalLink>
          <Tooltip title="Open in new tab">
            <StyledExternalLink
              href={product.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
              style={{ position: 'relative', top: '2px', marginLeft: '6px' }}
            >
              <ExternalLink size={14} />
            </StyledExternalLink>
          </Tooltip>
        </div>
      ) : loading ? (
        <StyledInternalLink
          to={ROUTES.links.overview_v2.url({}, productId)}
          className={css((t) => ({ color: t.palette.primary.main }))}
        >
          <Skeleton variant="text" width={150} />
        </StyledInternalLink>
      ) : (
        <StyledInternalLink
          to={ROUTES.links.overview_v2.url({}, productId)}
          className={css((t) => ({ color: t.palette.primary.main }))}
        >
          {productId}
        </StyledInternalLink>
      )}
    </Typography>
  );
};
