import { Card, IconButton, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import {
  ChevronRight as NavigateNextIcon,
  Info as IconInfo
} from 'react-feather';
import { Link } from 'react-router-dom';
import { css, styled } from '../../emotion';
import {
  Breadcrumbs,
  TabLinkProps,
  Tabs,
  TopNavOld
} from '../../layout/TopNav';
import * as tracking from '../../tracking';
import { IPage, NavigationArrows } from '../NavigationArrows';

export type IRank = {
  current: number;
  total: number;
};

export type IProps = {
  title?: React.ReactNode;
  prevPage: IPage | null;
  nextPage: IPage | null;
  rootPage: IPage;
  rank: IRank | null;
  toNextPage: (page: IPage) => void;
  breadcrumb: React.ReactNode;
  tabs?: TabLinkProps[];
};

export const SectionHeading: React.FC = ({ children }) => (
  <Typography
    variant="body1"
    component="p"
    style={{
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'space-between'
    }}
  >
    {children}
  </Typography>
);

export const SectionSubheading: React.FC = ({ children }) => (
  <Typography variant="body2" color="textSecondary" component="p" paragraph>
    {children}
  </Typography>
);

const CardPadding = styled('div')`
  padding: ${(p) => p.theme.spacing(1)}px ${(p) => p.theme.spacing(2)}px;
`;

export const DetailCard: React.FC<{ style?: any }> = ({
  children,
  ...rest
}) => (
  <Card {...rest}>
    <CardPadding>{children}</CardPadding>
  </Card>
);

export const DetailCardHeadline = ({
  title,
  onInfoClick
}: {
  title: string;
  onInfoClick?: () => void;
}) => (
  <SectionHeading>
    {title}
    {onInfoClick && (
      <Tooltip title="What does this tell me?" placement="top">
        <IconButton
          className={css((t) => ({
            marginLeft: `${t.spacing()}px`
          }))}
          onClick={() => {
            tracking.sendEvent({
              category: tracking.toAppCategory(),
              action: 'Open',
              label: `${title} modal`
            });
            return onInfoClick();
          }}
        >
          <IconInfo style={{ fontSize: '18' }} />
        </IconButton>
      </Tooltip>
    )}
  </SectionHeading>
);

export const DetailsPageSubtitle = styled('div')`
  display: flex;
  align-items: flex-end;
  font-size: ${(p) => p.theme.custom.fontSize.m}px;
`;

const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderRight = styled('div')`
  display: flex;
  align-items: center;
  margin-right: ${(p) => p.theme.spacing(2)}px;

  > * {
    margin-left: ${(p) => p.theme.spacing() * 2}px;
  }
`;

export const ControlsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f9;
  padding: ${(p) => p.theme.spacing(3)}px ${(p) => p.theme.spacing(10)}px 0;
`;

export const DetailsPageHeader = ({
  title,
  prevPage,
  nextPage,
  rank,
  toNextPage,
  rootPage,
  breadcrumb,
  tabs
}: IProps) => {
  return (
    <TopNavOld title={title} stickyIndex={2}>
      <Header>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="Breadcrumb"
        >
          <Link
            color="inherit"
            to={rootPage.url}
            title="Go back to overview"
            onClick={() => {
              tracking.sendEvent({
                category: tracking.toAppCategory(),
                action: 'Click',
                label: `${rootPage.label}`
              });
            }}
          >
            <Typography color="inherit" style={{ fontWeight: 700 }}>
              {rootPage.label}
            </Typography>
          </Link>
          <Typography color="inherit">{breadcrumb}</Typography>
        </Breadcrumbs>
        <HeaderRight>
          {rank && (
            <div>
              {rank.current} of {rank.total}
            </div>
          )}
          <NavigationArrows
            toNextPage={toNextPage}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        </HeaderRight>
      </Header>
      {tabs && <Tabs tabs={tabs} />}
    </TopNavOld>
  );
};
