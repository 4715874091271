import React from 'react';
import { Doc } from '../../../../../domainTypes/document';
import { ReleaseNotification } from '../../../../../domainTypes/notifications';
import { useRoutes } from '../../../../../routes';
import { Body, Frame, Header } from '../../Item/Frame';

export const Release_AvantLinkIntegration = ({
  n
}: {
  n: Doc<ReleaseNotification>;
}) => {
  const { ROUTES } = useRoutes();
  return (
    <Frame n={n} href={ROUTES.performanceNew.integrations.url()}>
      <Header>AvantLink integration is now available!</Header>
      <Body>
        You can now track revenue reports from AvantLink and apply Smart Labels
        from Affilimate. If you're using AvantLink, click this notification to
        set up your integration.
      </Body>
    </Frame>
  );
};
