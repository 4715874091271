import styled from '@emotion/styled';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
  Typography
} from '@material-ui/core';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { Trash2 as IconTrash2 } from 'react-feather';
import { ButtonWithPromise } from '../../../../../../components/ButtonWithPromise';
import { useMixpanel } from '../../../../../../services/mixpanel';
import { updatePageRevision } from '../../../../../../services/page';
import { IPageRevision } from '../../../../../../domainTypes/page';

const StyledTextareaAutosize = styled(TextField)`
  width: 100%;
`;

export const RevisionNoteDialog = ({
  dialogProps,
  spaceId,
  url,
  d,
  onClose
}: {
  spaceId: string;
  url: string;
  d: { revision: IPageRevision };
  onClose: () => void;
  dialogProps: DialogProps;
}) => {
  const [note, setNote] = useState('');
  const hasOldNote = d.revision.note && d.revision.note.length > 0;
  const mixpanel = useMixpanel();

  const handleSubmit = async (
    _: React.SyntheticEvent,
    shouldDelete?: boolean
  ) => {
    if (!hasOldNote && !note) {
      return false;
    }
    const newNote = shouldDelete ? '' : note;
    await updatePageRevision(spaceId, url, {
      ...d.revision,
      note: newNote
    });

    if (!hasOldNote && note) {
      mixpanel.track('add_note', {
        url
      });
    }

    const successMessage = shouldDelete ? 'Note deleted!' : 'Note saved!';
    enqueueSnackbar(successMessage, {
      variant: 'success'
    });
    return onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" {...dialogProps}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Add a note for this revision</DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ marginBottom: '24px' }}
          >
            Use notes to keep track of what changed in this revision.
          </Typography>
          <StyledTextareaAutosize
            onChange={(e) => setNote(e.target.value)}
            minRows="4"
            variant="outlined"
            multiline
            placeholder="Changed CTA text, updated article layout, swapped out featured advertiser in comparison table..."
            defaultValue={d.revision.note}
          />
        </DialogContent>
        <DialogActions>
          {hasOldNote ? (
            <ButtonWithPromise
              onClick={(e) => handleSubmit(e, true)}
              pending="Deleting"
            >
              <IconTrash2 size={20} /> &nbsp; Delete note
            </ButtonWithPromise>
          ) : (
            <Button onClick={onClose}>Close</Button>
          )}

          <ButtonWithPromise
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            pending="Saving note..."
          >
            Save note
          </ButtonWithPromise>
        </DialogActions>
      </form>
    </Dialog>
  );
};
