import { Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import React from 'react';
import { AffilimateLogo } from '../components/AffilimateLogo';
import { styled } from '../emotion';

export const Container = styled('div')`
  max-width: 800px;
  margin: ${(p) => p.theme.spacing(6)}px auto;
`;

const Title = styled('div')`
  font-size: ${(p) => p.theme.custom.fontSize.l}px;
  font-weight: ${(p) => p.theme.typography.fontWeightBold};
`;

const Actions = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  > * {
    margin-left: ${(p) => p.theme.spacing()}px !important;
  }
`;

type Props = {};

export const SplashPageHeader: React.FC = ({ children }) => (
  <CardHeader
    avatar={<AffilimateLogo />}
    title={<Title>{children}</Title>}
    style={{ textAlign: 'center' }}
  />
);

export const SplashPageContent = CardContent;
export const SplashPageActions: React.FC<{ leftSlot?: React.ReactNode }> = ({
  children,
  leftSlot
}) => (
  <CardActions>
    {leftSlot}
    <Actions>{children}</Actions>
  </CardActions>
);

export const SplashPage: React.FC<Props> = ({ children }) => {
  return (
    <Container>
      <Card>{children}</Card>
    </Container>
  );
};
