import firebase from 'firebase/app';
import { compact, intersection, mapValues } from 'lodash';
import { useCallback } from 'react';
import { Plan } from '../../domainTypes/billing';
import { Doc, generateToDocFn } from '../../domainTypes/document';
import { IPermissions } from '../../domainTypes/permissions';
import { Routes } from '../../domainTypes/routes';
import { ISpace } from '../../domainTypes/space';
import {
  ICapabilites,
  ICurrentUser,
  IUser,
  IUserInvitation,
  IUsersPerSpace,
  IUserUpdateParams
} from '../../domainTypes/user';
import { CF, FS } from '../../versions';
import { useCurrentUser } from '../currentUser';
import {
  combineLoadingValues,
  LoadingValue,
  store,
  useMappedLoadingValue
} from '../db';
import { callFirebaseFunction } from '../firebaseFunctions';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../firecache/collectionListener';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from '../firecache/documentListener';
import { getUserIds } from '../space';

export const toUserDoc = generateToDocFn<IUser>((d) => {
  d.displayName = d.displayName || '';
  d.photoURL = d.photoURL || '';
  return d;
});

export type RealOrInvitedUser = RealUser | InvitedUser;

export type RealUser = {
  id: string;
  type: 'USER';
  user: Doc<IUser>;
  permissions: IPermissions;
};

type InvitedUser = {
  id: string;
  type: 'INVITATION';
  invitation: Doc<IUserInvitation>;
  permissions: IPermissions;
};

export const toUserInvitationDoc = generateToDocFn<IUserInvitation>();
export const toUsersPerSpaceDoc = generateToDocFn<IUsersPerSpace>((d) => {
  d.users = mapValues(d.users, (v) => {
    if (!v) {
      return v;
    }
    v.data.displayName = v.data.displayName || '';
    v.data.photoURL = v.data.photoURL || '';
    return v;
  });
  return d;
});

export const invitationStore = createCollectionListenerStore(
  (spaceId) =>
    new CollectionListener(
      store().collection(FS.userInvitations).where('spaceId', '==', spaceId),
      toUserInvitationDoc
    )
);

export const usersPerSpaceStore = createDocumentListenerGetter(
  (spaceId) => store().collection(FS.usersPerSpace).doc(spaceId),
  toUsersPerSpaceDoc
);

export const useUsersPerSpace = (spaceId: string) =>
  useDocumentListener(usersPerSpaceStore(spaceId));

export const getUser = (userId: string) => {
  return store()
    .collection(FS.users)
    .doc(userId)
    .get()
    .then((s) => {
      if (!s.exists) {
        return {
          id: s.id,
          data: null
        } as Doc<null>;
      }
      return toUserDoc(s);
    });
};

export const useUserInSpaceById = (spaceId: string, userId: string) => {
  const mapFn = useCallback(
    (usersPerSpace: Doc<IUsersPerSpace> | null) => {
      if (!usersPerSpace) {
        return null;
      }
      return usersPerSpace.data.users[userId] || null;
    },
    [userId]
  );
  return useMappedLoadingValue(useUsersPerSpace(spaceId), mapFn, true);
};

export const useUsersInSpaceByIds = (spaceId: string, userIds: string[]) => {
  const mapFn = useCallback(
    (usersPerSpace: Doc<IUsersPerSpace> | null) => {
      if (!usersPerSpace) {
        return null;
      }
      return userIds.map((userId) => usersPerSpace.data.users[userId] || null);
    },
    [userIds]
  );
  return useMappedLoadingValue(useUsersPerSpace(spaceId), mapFn, true);
};

export const useUserInSpace = (spaceId: string, userId: string) => {
  const mapFn = useCallback(
    (usersPerSpace: Doc<IUsersPerSpace> | null) => {
      if (!usersPerSpace) {
        return null;
      }
      return usersPerSpace.data.users[userId] || null;
    },
    [userId]
  );
  return useMappedLoadingValue(useUsersPerSpace(spaceId), mapFn, true);
};

export const updateUser = (userId: string, update: IUserUpdateParams) => {
  const request: IUserUpdateParams = {
    email: update.email,
    displayName: update.displayName,
    photoURL: update.photoURL
  };
  return callFirebaseFunction(CF.user.updateUser, { userId, ...request });
};

export const getUsersInSpace = async (space: Doc<ISpace>) => {
  return getUsers(getUserIds(space));
};

export const getUsers = (userIds: string[]) => {
  return Promise.all(userIds.map(getUser));
};

export const toUserLabel = (user: Doc<IUser> | null) =>
  (user?.data.displayName || user?.data.email || '').trim();

export const matchUser = (user: Doc<IUser> | null, re: RegExp) => {
  const label = toUserLabel(user);
  return label.match(re);
};

export const getPostLoginRedirect = (
  ROUTES: Routes,
  currentUser: ICurrentUser
) => {
  const users = currentUser.space.permissionsV2.users;
  const currentUserRoles = users[currentUser.id]?.roles || [];

  if (
    intersection(['OWNER', 'ADMIN', 'VIEWER'], currentUserRoles).length !== 0
  ) {
    return ROUTES.dashboard.overview.url();
  }
  if (['TOOLS_USER'].includes(currentUserRoles[0])) {
    return ROUTES.tools.linkGenerator.url();
  }
  return ROUTES.dashboard.overview.url();
};

const getCapabilitiesForPlan = (plan: Plan | null): ICapabilites => {
  if (!plan) {
    return {
      allowedScans: 5
    };
  }
  return {
    allowedScans: Infinity
  };
};

export const createCurrentUser = (
  authUser: firebase.User,
  user: IUser,
  space: ISpace
): ICurrentUser => {
  const billing = space.billing || {
    activePlan: null,
    customerId: '',
    subscriptionId: ''
  };

  return {
    id: authUser.uid,
    ...user,
    email: user.email || authUser.email,
    photoURL: user.photoURL,
    displayName: user.displayName,
    capabilities: getCapabilitiesForPlan(billing.activePlan),
    authUser,
    space,
    tz: space.config.tz || 'UTC'
  };
};

export const useUsersAndTheirPermissionLevels = (): LoadingValue<
  RealOrInvitedUser[]
> => {
  const currentUser = useCurrentUser();
  const spaceId = currentUser.space.id;
  const userPermissions = currentUser.space.permissionsV2.users;
  // get users in space
  // get invitations
  return useMappedLoadingValue(
    combineLoadingValues(
      useDocumentListener(usersPerSpaceStore(spaceId)),
      useCollectionListener(invitationStore(spaceId))
    ),
    ([usersPerSpace, invitations]) => {
      const users = usersPerSpace
        ? compact(Object.values(usersPerSpace.data.users || {}))
        : [];

      return [
        ...compact(
          users.map((user) => {
            const permissions = userPermissions[user.id];
            if (!permissions) {
              return null;
            }
            return {
              id: user.id,
              type: 'USER' as const,
              user,
              permissions
            };
          })
        ),
        ...invitations
          .filter((invitation) => invitation.data.status === 'PENDING')
          .map((invitation) => ({
            id: invitation.id,
            type: 'INVITATION' as const,
            invitation,
            permissions: invitation.data.permissions
          }))
      ];
    }
  );
};
