/* eslint-disable */

import { useCallback } from 'react';
import { Timeframe } from '../../domainTypes/analytics';
import { useCurrentUser } from '../currentUser';
import { useDenormalizedDeviceClickCountsForProductsInTimeframe } from './denormalization';

export const useDeviceClickCountsForProductInTimeframeFs = (
  productId: string,
  timeframe: Timeframe
) => {
  const { space } = useCurrentUser();
  const filter = useCallback(() => [productId], [productId]);
  return useDenormalizedDeviceClickCountsForProductsInTimeframe(
    space.id,
    filter,
    timeframe
  );
};
