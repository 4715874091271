import { now, ZonedDateTime } from '@internationalized/date';
import { useCurrentUser } from '../../../services/currentUser';
import { useFeatureEnabled } from '../../../services/features';
import { useMemo } from 'react';
import { endOfDay } from './conversion';

export const useMaxDateTime = (): ZonedDateTime => {
  const { tz } = useCurrentUser();
  const hasRealtimeFeatures = useFeatureEnabled('REFERRER_REPORTS_V1');
  return useMemo(() => {
    const todayDate = endOfDay(now(tz));
    return hasRealtimeFeatures ? todayDate : todayDate.subtract({ days: 1 });
  }, [hasRealtimeFeatures, tz]);
};

export const useMinDateTime = (): ZonedDateTime => {
  const { tz } = useCurrentUser();
  return useMemo(() => new ZonedDateTime(1900, 1, 1, tz, 0), [tz]);
};
