import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import React from 'react';
import { AlertBox } from '../../../../../../components/AlertBox';
import { Doc } from '../../../../../../domainTypes/document';
import { IPageScreenshot } from '../../../../../../domainTypes/page';
import { recreateScreenshot } from '../../../../../../services/pageScreenshots';

export const RegenerateScreenshotDialog = ({
  open,
  onClose,
  screenshot
}: {
  open: boolean;
  onClose: () => void;
  screenshot: Doc<IPageScreenshot>;
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete and recreate this screenshot?</DialogTitle>
      <DialogContent>
        <AlertBox variant="pending">
          <Typography variant="body1" component="p">
            <strong>If you recently published</strong> a new version of this
            page, please wait for the nightly revision check which automatically
            creates a new screenshot. Deleting and re-creating the screenshot
            ahead of time will cause you to lose the current version's
            screenshot.
          </Typography>
        </AlertBox>
        <br />
        <br />
        <Typography variant="body1" component="p" paragraph>
          If you need to delete the current screenshot and replace it with
          whatever is currently live on your site, you can push the button below
          to do that.
        </Typography>
        <Typography variant="body1" component="p">
          <strong>
            Deleting and re-rendering a screenshot is not reversible.
          </strong>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            recreateScreenshot(screenshot);
            onClose();
          }}
        >
          Delete and re-render screenshot
        </Button>
      </DialogActions>
    </Dialog>
  );
};
