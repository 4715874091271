import { useCollection } from 'react-firebase-hooks/firestore';
import shortid from 'shortid';
import { Doc } from '../../domainTypes/document';
import { LinkCheckSchedule, Type } from '../../domainTypes/schedule';
import { FS } from '../../versions';
import { getCurrentUser } from '../currentUser';
import { useMappedLoadingValue } from '../db';
import { now } from '../time';
import {
  getSchedulesForSpaceByTypeQuery,
  toScheduleDoc,
  updateOrCreateSchedule
} from './helper';

const TYPE: Type = 'LINK_CHECK';

export const getDefaultLinkCheckSchedule = (
  spaceId: string,
  createdBy: string,
  tz: string
): LinkCheckSchedule => ({
  spaceId,
  type: TYPE,
  frequency: {
    type: 'WEEKLY',
    weekDay: 1,
    hour: 7,
    tz
  },
  active: false,
  config: {},
  createdAt: now(),
  createdBy,
  lastRun: null,
  nextRun: null
});

export const createLinKCheckSchedule = (
  spaceId: string,
  createdBy: string,
  tz: string
) => {
  return updateOrCreateSchedule(
    shortid(),
    getDefaultLinkCheckSchedule(spaceId, createdBy, tz)
  );
};

export const useLinkCheckSchedule = (spaceId: string) => {
  const extractDoc = (
    v: firebase.firestore.QuerySnapshot
  ): Doc<LinkCheckSchedule> => {
    if (v.empty) {
      return {
        id: shortid(),
        collection: FS.schedules,
        data: getDefaultLinkCheckSchedule(
          spaceId,
          getCurrentUser().id,
          getCurrentUser().tz
        )
      };
    }
    if (v.size > 1) {
      console.warn(`Found more than 1 report schedule for ${spaceId}`);
    }
    return toScheduleDoc(v.docs[0]) as Doc<LinkCheckSchedule>;
  };

  return useMappedLoadingValue(
    useCollection(getSchedulesForSpaceByTypeQuery(spaceId, TYPE)),
    extractDoc
  );
};
