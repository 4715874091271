import { Pagination } from '@material-ui/lab';
import { styled } from '../../emotion';

// siblingCount 0 doesn't work, so we override the styles to hide all siblings
export const CustomPagination = styled(Pagination)((p) => ({
  'li:not(:first-of-type):not(:last-of-type)': {
    '.MuiPaginationItem-root:not(.Mui-selected)': {
      display: 'none'
    },
    '.Mui-selected': {
      backgroundColor: 'transparent !important'
    }
  }
}));
