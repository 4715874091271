import { Button, Paper, Tooltip, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Loader } from '../../../../components/Loader';
import { Centered } from '../../../../layout/Centered';
import { useCurrentUser } from '../../../../services/currentUser';
import { usePayouts } from '../../../../services/payouts';
import { PayoutsList } from './components/PayoutsList';
import {
  PageToolbar,
  DEFAULT_OFFSET,
  PageToolbarSection
} from '../../../../layout/PageToolbar';
import {
  useNullableStringSetQueryParam,
  useStringQueryParam,
  useTypedStringQueryParam
} from '../../../../routes';
import { SearchInput } from '../../../../components/SearchInput';
import { Payout } from '../../../../domainTypes/payout';
import { Doc } from '../../../../domainTypes/document';
import { styled } from '../../../../emotion';
import moment from 'moment-timezone';
import { toMoment } from '../../../../services/time';
import {
  CommissionWrapper,
  SectionHeading
} from '../../../../components/DrawerHeader';
import { Currency } from '../../../../components/Number';
import { Info } from 'react-feather';
import { PayoutStatusBadge } from '../../components/PayoutStatus';
import { useDialogState } from '../../../../hooks/useDialogState';
import { TriggerJobDialog } from '../../components/TriggerJobDialog';
import { HANDLERS_WITH_PAYOUTS } from '../../../../domainTypes/reporting';
import { PayoutStatus } from '../../../../domainTypes/performance';
import { PayoutsFilter } from './components/PayoutsFilter';
import { uniq } from 'lodash';

const PayoutSummaryGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;

  & > div {
    padding: ${({ theme }) => theme.spacing(2)}px;
  }
`;

const InfoIcon = styled(Info)`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  position: relative;
  top: 1px;
`;

const LOADER_SIZE = 24;
const lastMonthStart = moment().subtract(1, 'month').startOf('month');
const lastMonthEnd = lastMonthStart.clone().endOf('month');
const thisYear = moment().format('YYYY');

const PayoutSummary = ({
  allPayouts,
  payouts,
  loadingPayouts
}: {
  loadingPayouts: boolean;
  payouts: Doc<Payout>[];
  allPayouts: Doc<Payout>[];
}) => {
  const { space } = useCurrentUser();
  const currency = space.config.currency;

  const lastMonthPayouts = React.useMemo(() => {
    return allPayouts.filter((payout) => {
      return payout.data.payoutDate
        ? toMoment(payout.data.payoutDate).isBetween(
            lastMonthStart,
            lastMonthEnd
          )
        : false;
    });
  }, [allPayouts]);

  const pendingPayouts = React.useMemo(() => {
    return allPayouts.filter((payout) => {
      return payout.data.payoutStatus === 'pending';
    });
  }, [allPayouts]);

  return (
    <PayoutSummaryGrid>
      <Paper>
        <SectionHeading>
          Last month payouts
          <Tooltip
            title="Includes payouts with payout dates listed as last month. May differ from bank deposits. Payouts from currencies besides your account currency are automatically converted and may differ from network conversion rates if no conversion rate is provided by the network."
            placement="top"
          >
            <span style={{ display: 'inline-block' }}>
              <InfoIcon size={13} />
            </span>
          </Tooltip>
        </SectionHeading>

        {loadingPayouts ? (
          <div>
            <Loader size={LOADER_SIZE} />
          </div>
        ) : (
          <CommissionWrapper>
            <Typography variant="h5" component="p">
              <Currency
                cents={lastMonthPayouts.reduce(
                  (acc, payout) => acc + payout.data.netAmount.convertedValue,
                  0
                )}
                currency={currency}
              />
            </Typography>
            <PayoutStatusBadge status="paid" />
          </CommissionWrapper>
        )}
      </Paper>
      <Paper>
        <SectionHeading>
          Pending payouts
          <Tooltip
            title="Refers to pending payouts listed below, not all transactions with pending payout statuses."
            placement="top"
          >
            <span style={{ display: 'inline-block' }}>
              <InfoIcon size={13} />
            </span>
          </Tooltip>
        </SectionHeading>
        {loadingPayouts ? (
          <div>
            <Loader size={LOADER_SIZE} />
          </div>
        ) : (
          <CommissionWrapper>
            <Typography variant="h5" component="p">
              <Currency
                cents={pendingPayouts.reduce(
                  (acc, payout) => acc + payout.data.netAmount.convertedValue,
                  0
                )}
                currency={currency}
              />
            </Typography>
            <PayoutStatusBadge status="pending" />
          </CommissionWrapper>
        )}
      </Paper>
      <Paper>
        <SectionHeading>Year-to-date payouts</SectionHeading>
        {loadingPayouts ? (
          <div>
            <Loader size={LOADER_SIZE} />
          </div>
        ) : (
          <CommissionWrapper>
            <Typography variant="h5" component="p">
              <Currency
                cents={payouts
                  .filter((p) => {
                    const payoutYear = p.data.payoutDate
                      ? toMoment(p.data.payoutDate).format('YYYY')
                      : null;
                    return p.data.payoutDate && payoutYear === thisYear;
                  })
                  .reduce(
                    (acc, payout) => acc + payout.data.netAmount.convertedValue,
                    0
                  )}
                currency={currency}
              />
            </Typography>
          </CommissionWrapper>
        )}
      </Paper>
    </PayoutSummaryGrid>
  );
};

export const PagePerformancePayoutsContent = () => {
  const { space } = useCurrentUser();
  const [payouts = [], loadingPayouts] = usePayouts(space.id);
  const [search, setSearch] = useStringQueryParam('q', '');
  const [
    selectedPayoutStatus,
    setSelectedPayoutStatus
  ] = useTypedStringQueryParam<PayoutStatus>('status', 'paid');
  const [
    selectedPlatforms,
    setSelectedPlatforms
  ] = useNullableStringSetQueryParam('platforms');

  const { dialogOpen, setDialogOpen } = useDialogState();

  const availablePlatforms = useMemo(() => {
    if (!payouts.length) {
      return [];
    }
    return uniq(payouts.map((p) => p.data.partnerKey));
  }, [payouts]);

  const filteredPayouts = payouts.filter((p) => {
    const matchesFilters =
      (!selectedPayoutStatus || p.data.payoutStatus === selectedPayoutStatus) &&
      (!selectedPlatforms || selectedPlatforms.has(p.data.partnerKey));

    const matchesSearch =
      p.data.payoutId.includes(search) ||
      p.data.payoutReference?.includes(search) ||
      p.data.partnerKey.toLowerCase().includes(search) ||
      p.data.advertiserName?.toLowerCase().includes(search.toLowerCase());

    return !search ? matchesFilters : matchesSearch && matchesSearch;
  });

  return (
    <div>
      <PageToolbar sticky offset={DEFAULT_OFFSET}>
        <PageToolbarSection flex={2}>
          <Typography
            variant="h6"
            component="span"
            style={{
              marginRight: '9px',
              position: 'relative',
              fontWeight: 'bold',
              top: '-2px'
            }}
          >
            Payouts
          </Typography>
          <SearchInput
            value={search}
            onChange={setSearch}
            width={400}
            placeholder="Search by advertiser, payout or invoice ID"
          />
          <PayoutsFilter
            platforms={{
              availablePlatforms: availablePlatforms,
              value: selectedPlatforms,
              onChange: setSelectedPlatforms
            }}
            payoutStatus={{
              value: selectedPayoutStatus,
              onChange: setSelectedPayoutStatus
            }}
          />
        </PageToolbarSection>
        <PageToolbarSection flex={1} justifyContent="flex-end">
          <Button
            variant="contained"
            color="default"
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            Sync payouts
          </Button>
        </PageToolbarSection>
      </PageToolbar>
      <PayoutSummary
        allPayouts={payouts}
        payouts={filteredPayouts}
        loadingPayouts={loadingPayouts}
      />
      {loadingPayouts ? (
        <Centered height={500}>
          <div>
            <Loader size={LOADER_SIZE} />
            <br />
            <Typography variant="body2" component="p">
              Loading your payouts...
            </Typography>
          </div>
        </Centered>
      ) : (
        <PayoutsList payouts={filteredPayouts} />
      )}
      {dialogOpen && (
        <TriggerJobDialog
          jobType="payout"
          availableHandlers={HANDLERS_WITH_PAYOUTS}
          dialogTitle="Sync your payouts"
          showDates={true}
          dialogContent={
            <>
              <Typography variant="body1" gutterBottom>
                Once you've performed an initial import of your payouts, they'll
                update automatically on a daily basis. You can also manually
                import your payouts using the form below:
              </Typography>
              <br />
              <br />
              <Typography variant="body1" color="textSecondary" gutterBottom>
                Which platform do you want to sync payouts for?
              </Typography>
            </>
          }
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
        />
      )}
    </div>
  );
};
