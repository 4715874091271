import React from 'react';
import { PageBody, SideNavHowTo, SideNavProps } from '../../../layout/PageBody';
import { useRoutes } from '../../../routes';

type Props = {
  howTo?: SideNavHowTo;
  noTopPadding?: boolean;
};

export const ToolsPageBody: React.FC<Props> = ({
  howTo,
  noTopPadding,
  children
}) => {
  const { ROUTES } = useRoutes();

  const sideNav: SideNavProps = {
    groups: [
      {
        label: 'Tools',
        items: [
          {
            label: 'Link Generator',
            path: ROUTES.tools.linkGenerator.url()
          }
        ]
      }
    ],
    howTo
  };

  return (
    <>
      <PageBody sideNav={sideNav} noTopPadding={noTopPadding}>
        {children}
      </PageBody>
    </>
  );
};
