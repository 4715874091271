import styled from '../../emotion/styled';
import { IEMotionTheme } from '../../themes/emotionTheme';

export type AlertVariant = keyof IEMotionTheme['custom']['colors'];

type Props = {
  variant: AlertVariant;
};

export const AlertBox = styled<'div', Props>('div')`
  text-align: left;
  border-left: 5px solid ${(p) => p.theme.custom.colors[p.variant].main};
  margin: ${(p) => p.theme.spacing()}px 0;
  padding: ${(p) => p.theme.spacing(2)}px;
  border-radius: 0px ${(p) => p.theme.shape.borderRadius}px
    ${(p) => p.theme.shape.borderRadius}px 0px;
  background-color: ${(p) => p.theme.custom.colors[p.variant].light};
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: ${(p) => p.theme.typography.body2.fontSize};
  line-height: ${(p) => p.theme.typography.body2.lineHeight};
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.1),
    2px 5px 4px -1px rgba(0, 0, 0, 0.01);
`;

export const CardAlertBox = styled(AlertBox)`
  border-left: none;
  margin: 0;
  border-radius: 0;
`;
