import { DimensionMenuComponent } from './Dimension';
import { DeviceFilterDef } from '../../../../domainTypes/filters';
import { BASIC_MODES, FilterMenu } from './FilterMenu';
import React, { useMemo, useState } from 'react';
import pluralize from 'pluralize';
import {
  createDeviceFilterDefinition,
  useDimensionName,
  validateFilterDefinition
} from '../filters';
import { isEqual } from 'lodash';
import { FieldSelectorMenu, useCollectionState } from './Selector';
import { Device } from '../../../../domainTypes/tracking';
import { DeviceIconWithLabel } from '../../../DeviceIcon';

export const DeviceMenu: DimensionMenuComponent<DeviceFilterDef> = ({
  range,
  definition,
  filters,
  onSave,
  orderBy
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const [value, toggleValue] = useCollectionState(definition.v);
  const newDefinition = useMemo<DeviceFilterDef>(
    () => createDeviceFilterDefinition(value),
    [value]
  );
  const enableSave =
    validateFilterDefinition(newDefinition) &&
    !isEqual(definition, newDefinition);

  const focusValue = (device: string) => {
    onSave(createDeviceFilterDefinition([device]));
  };

  return (
    <FilterMenu>
      <FilterMenu.Header dimension="device">
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <FieldSelectorMenu
          label={useDimensionName('device')}
          selectedValues={value}
          toggleValue={toggleValue}
          focusValue={focusValue}
          queryFilters={filters}
          analyticsField={'device'}
          orderBy={orderBy}
          range={range}
          renderOption={(device) => (
            <DeviceIconWithLabel device={device as Device} />
          )}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer definition={definition}>
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={`Filter by ${pluralize('device', value.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
