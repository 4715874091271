import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { RotateCw } from 'react-feather';
import { styled } from '../../../../../emotion';

const RefreshIcon = styled(RotateCw)`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const RefreshButton = ({
  onClick,
  lastEndDate
}: {
  onClick: () => void;
  lastEndDate: number;
}) => {
  const [refreshing, setRefreshing] = useState(false);
  const prev = useRef<number>(0);
  useEffect(() => {
    if (prev.current) {
      console.log(prev.current, lastEndDate);
      if (prev.current !== lastEndDate) {
        prev.current = lastEndDate;
        console.log('CHANGE');
        setRefreshing(true);
        const timeout = setTimeout(() => {
          setRefreshing(false);
        }, 1500);
        return () => clearTimeout(timeout);
      }
    } else {
      prev.current = lastEndDate;
    }
  }, [lastEndDate]);
  return (
    <Tooltip title="Refresh">
      <IconButton onClick={onClick} size="small">
        <RefreshIcon
          size={14}
          style={{
            animation: refreshing ? 'spin 0.4s ease-out infinite' : 'none'
          }}
        />
      </IconButton>
    </Tooltip>
  );
};
