import { chunk, every, flatten, last } from 'lodash';
import moment from 'moment-timezone';
import { DAY_FORMAT, Timeframe } from '../../domainTypes/analytics';
import {
  IDenomarlizationByPage,
  IDenormalizationByProduct,
  IDenormalizationParams
} from '../../domainTypes/denormalization';
import { Doc, toDoc } from '../../domainTypes/document';
import { ISpace } from '../../domainTypes/space';
import { allTime_ } from '../../services/analytics';
import {
  normalizeDenormalizationByPage,
  normalizeDenormalizationByProduct
} from '../../services/analytics/denormalization';
import { compress } from '../../services/compression';
import { batchSet, paginatedForEach, store } from '../../services/db';
import { callFirebaseFunction } from '../../services/firebaseFunctions';
import { CF, FS } from '../../versions';
import { getSpaces } from './space';

const MAX_PARALLEL_REQUESTS = 15;
const MAX_DAYS_PER_DENORMALIZATION = 30;
const VOID = () => {};

type QueueItem<T> = {
  fn: () => Promise<T>;
  started: boolean;
  done: boolean;
};

export const processParallelCapped = async <T>(
  fns: (() => Promise<T>)[],
  capSize: number
): Promise<T[]> => {
  if (!fns.length) {
    return [];
  }
  return new Promise((resolve, reject) => {
    const results: T[] = [];
    const errors: any[] = [];
    const queue: QueueItem<T>[] = fns.map((fn) => ({
      fn,
      started: false,
      done: false
    }));
    const processNext = () => {
      const nextItem = queue.find((item) => !item.started);
      if (nextItem) {
        processItem(nextItem);
      } else {
        if (every(queue, (item) => item.done)) {
          if (errors.length) {
            reject(errors);
          } else {
            resolve(results);
          }
        }
      }
    };
    const processItem = (item: QueueItem<T>) => {
      item.started = true;
      item.fn().then(
        (res) => {
          results[queue.indexOf(item)] = res;
          item.done = true;
          processNext();
        },
        (err) => {
          errors.push(err);
          item.done = true;
          processNext();
        }
      );
    };

    queue.slice(0, capSize).forEach(processItem);
  });
};

const toMoment = (d: string) => moment(d, DAY_FORMAT);

const getRange = (start: string, end: string): string[] => {
  const s = toMoment(start);
  const e = toMoment(end);
  const result: string[] = [];
  while (s.isSameOrBefore(e)) {
    result.push(s.format('YYYY-MM-DD'));
    s.add(1, 'day');
  }
  return result;
};

const chunkTimeframe = (
  timeframe: Timeframe,
  chunkSize: number
): Timeframe[] => {
  const range = getRange(timeframe.start, timeframe.end);
  const chunks = chunk(range, chunkSize);
  return chunk(range, 30).map((days) => {
    const start = days[0];
    const end = days[days.length - 1];
    // remember that the end is exlusive. this means that chunks in between need
    // to add a day
    const calibratedEnd =
      last(chunks) === days
        ? end
        : toMoment(end).add(1, 'd').format(DAY_FORMAT);

    return {
      start,
      end: calibratedEnd,
      tz: timeframe.tz
    };
  });
};

const toDenormalizationParams = (space: ISpace): IDenormalizationParams[] => {
  return chunkTimeframe(
    allTime_(space, space.config.tz || 'UTC'),
    MAX_DAYS_PER_DENORMALIZATION
  ).map((timeframe) => ({ spaceId: space.id, timeframe }));
};

export const denornmalizeAnalytics = async (
  params: IDenormalizationParams
): Promise<void> => {
  return callFirebaseFunction(CF.denormalization.denormalizeSpace, params).then(
    VOID
  );
};

export const denormalizeAnalyticsForSpace = (space: ISpace): Promise<void> => {
  const params = toDenormalizationParams(space);
  return Promise.all(params.map(denornmalizeAnalytics)).then(VOID);
};

export const denormalizeAnalyticsForSpaceInTimeframe = (
  spaceId: string,
  timeframe: Timeframe
) => {
  return denornmalizeAnalytics({
    spaceId,
    timeframe,
    goToCloudStorageIfNeedBe: true
  });
};

export const denormalizeAllAnalytics = async (): Promise<void> => {
  const spaces = await getSpaces();
  const params = flatten(
    spaces.map((doc) => toDenormalizationParams(doc.data))
  );
  return processParallelCapped(
    params.map((p) => () => denornmalizeAnalytics(p)),
    MAX_PARALLEL_REQUESTS
  ).then(VOID);
};

const migratePageDenormalizations = async (
  execute: boolean,
  migrateFn: (
    docs: Doc<IDenomarlizationByPage>[]
  ) => Doc<IDenomarlizationByPage>[]
) => {
  await paginatedForEach(
    store().collection(FS.denormalizationByPage),
    2,
    async (s) => {
      const docs = s.docs.map((x) =>
        toDoc<IDenomarlizationByPage>(x, normalizeDenormalizationByPage)
      );

      const toUpdate = migrateFn(docs);

      const docCount = docs.length;
      const updateCount = toUpdate.length;

      if (execute && updateCount) {
        await batchSet(
          FS.denormalizationByPage,
          toUpdate.map((d) => ({
            id: d.id,
            collection: d.collection,
            data: {
              ...d.data,
              pages: compress(d.data.pages)
            }
          }))
        );
      }
      console.log(
        `IDenormalizationByPage: Updated ${updateCount} of ${docCount} - ${docs
          .map((d) => d.id)
          .join(', ')}`
      );
      return;
    },
    null
  );
};

const migrateProductDenormalizations = async (
  execute: boolean,
  migrateFn: (
    docs: Doc<IDenormalizationByProduct>[]
  ) => Doc<IDenormalizationByProduct>[]
) => {
  await paginatedForEach(
    store().collection(FS.denormalizationByProduct),
    2,

    async (s) => {
      const docs = s.docs.map((x) =>
        toDoc<IDenormalizationByProduct>(x, normalizeDenormalizationByProduct)
      );

      const toUpdate = migrateFn(docs);

      const docCount = docs.length;
      const updateCount = toUpdate.length;

      if (execute && updateCount) {
        await batchSet(
          FS.denormalizationByProduct,
          toUpdate.map((d) => ({
            id: d.id,
            collection: d.collection,
            data: {
              ...d.data,
              products: compress(d.data.products)
            }
          }))
        );
      }
      console.log(
        `IDenormalizationByProduct: Updated ${updateCount} of ${docCount} - ${docs
          .map((d) => d.id)
          .join(', ')}`
      );
      return;
    },
    null
  );
};

export const migrateDenormalizations = async (execute: boolean) => {
  return Promise.all([
    migratePageDenormalizations(execute, (docs) => docs),
    migrateProductDenormalizations(execute, (docs) => docs)
  ]);
};
