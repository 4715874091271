import { cloneElement, ReactElement, ReactNode } from 'react';

export const interpose = (
  nodes: ReactNode[],
  separator: ReactElement
): ReactNode[] => {
  return nodes.flatMap((node, i) => {
    if (i === nodes.length - 1) {
      return [node];
    }
    return [node, cloneElement(separator, { key: i })];
  });
};
