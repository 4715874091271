import React from 'react';

export interface INumberProps {
  n?: number;
  format?: 'decimal' | 'percent';
  digits?: number;
  plusMinus?: boolean;
}

export const toPercent = (part?: number, total?: number) => {
  return part && total ? part / total : 0;
};

const formatters: { [key: string]: Intl.NumberFormat } = {};
const getFormatter = (options: Intl.NumberFormatOptions) => {
  const k = JSON.stringify(options);
  if (!formatters[k]) {
    formatters[k] = new Intl.NumberFormat(undefined, options);
  }
  return formatters[k];
};

export const formatNumber = ({
  n,
  format,
  digits,
  plusMinus
}: INumberProps): string => {
  const c = n || 0;
  const x = plusMinus ? Math.abs(c) : c;
  const f = format || 'decimal';
  const d = digits || 0;
  const withPlusMinus = (n: string) =>
    plusMinus ? `${getPlusMinus(c)}${n}` : n;
  if (Intl && Intl.NumberFormat) {
    return withPlusMinus(
      getFormatter({
        style: f,
        maximumFractionDigits: d
      }).format(x)
    );
  }

  if (f === 'percent') {
    return withPlusMinus(`${(x * 100).toFixed(digits)}%`);
  }
  return withPlusMinus(x.toFixed(digits));
};

export const formatNumberNullable = ({
  n,
  format,
  digits
}: Omit<INumberProps, 'n'> & { n: number | null | undefined }):
  | string
  | null => {
  if (n === null || n === undefined) {
    return null;
  }
  const c = n || 0;
  const f = format || 'decimal';
  const d = digits || 0;
  if (Intl && Intl.NumberFormat) {
    return getFormatter({
      style: f,
      maximumFractionDigits: d
    }).format(c);
  }

  if (f === 'percent') {
    return `${(c * 100).toFixed(digits)}%`;
  }
  return c.toFixed(digits);
};

export const getPlusMinus = (n: number) => {
  if (n === 0) {
    return '±';
  }
  return n > 0 ? '+' : '-';
};

// Here we can also do some more formatting to deal better with large numbers
export const Number = (props: INumberProps) => (
  <span>{formatNumber(props)}</span>
);

export const formatCurrency = (
  cents: number,
  currency: string,
  plusMinus?: boolean
) => {
  const c = plusMinus ? Math.abs(cents) : cents;
  const str = (c / 100).toLocaleString('en-US', {
    style: 'currency',
    currency
  });
  return plusMinus ? getPlusMinus(cents) + str : str;
};

export const Currency: React.FC<{
  cents: number;
  currency: string;
  zeroState?: React.ReactNode;
}> = ({ cents, currency, zeroState }) => (
  <>{cents === 0 && zeroState ? zeroState : formatCurrency(cents, currency)}</>
);
