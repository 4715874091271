import React from 'react';
import { Tooltip } from '@material-ui/core';
import { styled } from '../emotion';

export const ConnectionId: React.FC = ({ children }) => {
  return (
    <Tooltip title={children as string} placement="top">
      <ConnectionIdInner>{children}</ConnectionIdInner>
    </Tooltip>
  );
};

const ConnectionIdInner = styled('div')`
  color: #616161;
  background-color: #eee;
  font-size: 10px;
  line-height: 18px;
  margin-left: 10px;
  padding: 0 5px;
  border-radius: 5px;
  overflow: hidden;
  white-space: nowrap;
  height: 19px;
  max-width: 80px;
  min-width: auto;
  text-overflow: ellipsis;
  font-family: Menlo, Consolas, Courier;
`;

export const InternalId = styled('div')`
  display: inline-block;
  color: #616161;
  background-color: #eee;
  font-size: 10px;
  line-height: 18px;
  padding: 0 5px;
  border-radius: 5px;
  white-space: nowrap;
  font-family: Menlo, Consolas, Courier;
`;
