import { Lock, Navigation, X, Zap } from 'react-feather';
import {
  Timeline,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
  TimelineDot as OriginalTimelineDot,
  TimelineConnector,
  TimelineOppositeContent
} from '@material-ui/lab';
import { styled } from '../../../../emotion';
import { ITrackedConvertedSaleWithProduct } from './types';
import { Typography } from '@material-ui/core';
import moment, { Moment } from 'moment-timezone';

const TimelineDot = styled(OriginalTimelineDot)`
  background-color: #000 !important;
  box-shadow: none !important;
`;

const FINAL_STATUSES = [
  'Cancelled',
  'Rejected',
  'Non-commissionable',
  'Refunded'
];

const renderDate = (date: Moment) => {
  const dateTime = date.clone().utc().format('HH:mm');

  // If something happens at midnight precisely,
  // treat is as just being day-precise instead of
  // minute-precise
  if (dateTime === '00:00') {
    return date.format('MMM DD, YYYY');
  }

  const d = date.format('MMM DD, YYYY');
  const t = date.format('HH:mm z');
  return `${d} at ${t}`;
};

const ClickItem = ({ sale }: { sale: ITrackedConvertedSaleWithProduct }) => {
  if (!sale.dates.click) {
    return null;
  }

  return (
    <TimelineItem>
      <TimelineOppositeContent style={{ flex: 0, padding: 0 }} />
      <TimelineSeparator>
        <TimelineDot>
          <Navigation size={11} />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant="body2" component="p" style={{ fontWeight: 700 }}>
          Click
        </Typography>
        <Typography variant="caption" color="textSecondary" component="p">
          {renderDate(sale.dates.click)}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

const SaleItem = ({ sale }: { sale: ITrackedConvertedSaleWithProduct }) => {
  // Amazon sale dates are technically shipping dates
  if (sale.sale.partnerKey === 'amazon') {
    return null;
  }

  return (
    <TimelineItem>
      <TimelineOppositeContent style={{ flex: 0, padding: 0 }} />
      <TimelineSeparator>
        <TimelineDot>
          <Zap size={12} />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant="body2" component="p" style={{ fontWeight: 700 }}>
          Conversion
        </Typography>
        <Typography variant="caption" color="textSecondary" component="p">
          {renderDate(sale.dates.sale)}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

const CompletionItem = ({
  sale
}: {
  sale: ITrackedConvertedSaleWithProduct;
}) => {
  if (FINAL_STATUSES.indexOf(sale.sale.status) !== -1) {
    return null;
  }

  const isFuture =
    (sale.dates.completion && moment().isBefore(sale.dates.completion)) ||
    sale.sale.status !== 'Final';

  return (
    <TimelineItem style={{ opacity: isFuture ? 0.5 : 1 }}>
      <TimelineOppositeContent style={{ flex: 0, padding: 0 }} />
      <TimelineSeparator>
        <TimelineDot>
          <Lock size={11} />
        </TimelineDot>
      </TimelineSeparator>
      <TimelineContent>
        <strong>
          {sale.sale.status === 'Final' ? 'Complete' : 'Completion'}
        </strong>
        <br />
        <Typography variant="caption" color="textSecondary" component="p">
          {sale.dates.completion
            ? renderDate(sale.dates.completion)
            : sale.sale.status !== 'Final'
            ? 'Not provided'
            : null}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

const FinalStatusItem = ({
  sale
}: {
  sale: ITrackedConvertedSaleWithProduct;
}) => {
  if (FINAL_STATUSES.indexOf(sale.sale.status) !== -1) {
    return (
      <TimelineItem>
        <TimelineOppositeContent style={{ flex: 0, padding: 0 }} />
        <TimelineSeparator>
          <TimelineDot>
            <X size={11} />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <strong>{sale.sale.status}</strong>
        </TimelineContent>
      </TimelineItem>
    );
  }
  return null;
};

export const SalesTimeline = ({
  sale
}: {
  sale: ITrackedConvertedSaleWithProduct;
}) => {
  console.log(sale);
  return (
    <Timeline style={{ padding: 0 }}>
      <ClickItem sale={sale} />
      <SaleItem sale={sale} />
      <CompletionItem sale={sale} />
      <FinalStatusItem sale={sale} />
    </Timeline>
  );
};
