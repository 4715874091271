import React from 'react';
import { SupportFormProps, SupportForm } from '.';
import { useCurrentUser } from '../../services/currentUser';

type Props = {
  onDone: SupportFormProps['onDone'];
  title?: string;
  description?: React.ReactNode;
};

const DEFAULT_DESCRIPTION = () => (
  <div>Please try again or use the form below to report the issue.</div>
);

export const ErrorSupportForm: React.FC<Props> = ({
  title = 'Something went wrong',
  description = DEFAULT_DESCRIPTION(),
  onDone
}) => {
  const user = useCurrentUser();
  const email = (user && user.email) || null;
  return (
    <SupportForm
      title={title}
      description={description}
      email={email}
      disclaimer={!email}
      onDone={onDone}
    />
  );
};
