import React, { FC, useCallback, useMemo, useState } from 'react';
import { useCurrentUser } from '../../../services/currentUser';
import {
  toTimeRanges,
  useTimeframeDefinitionFromUrl
} from '../../../hooks/timeframe';
import { TimeframePickerDense } from '../../TimeframePicker/TimeframePickerDense';
import { TimeframeDefinition } from '../../../domainTypes/timeframe';
import {
  IComparisonOption,
  ITimeframeOption
} from '../../TimeframePicker/service/options';
import { css, styled } from '../../../emotion';
import { IPageRevision } from '../../../domainTypes/page';
import {
  CheckSquare as IconCheckSquare,
  Square as IconSquare
} from 'react-feather';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  formatSmartRelativeDate,
  isBefore,
  toMoment,
  tsFromMillis
} from '../../../services/time';
import { COLORS } from '../../../domainTypes/colors';
import { useRoutes } from '../../../routes';
import {
  compareRevisions,
  getTimeBoundaries
} from '../../../services/page/revision';
import { toMoments } from '../../../hooks/timeframe/toMoments';
import { ISpace } from '../../../domainTypes/space';
import moment from 'moment-timezone';
import { AnalyticsRanges, useDefaultOptions } from './index';
import { head } from 'lodash';
import { isFeatureEnabled } from '../../../domainTypes/features';
import { useFeatureEnabled } from '../../../services/features';

const useRevisionUrlParams = (revisions: IPageRevision[]) => {
  const { getQuery, changeQuery } = useRoutes();
  const query = getQuery();
  const revision = parseInt((query.revision as string) || '', 10);
  const syncTimeframe = query.syncTimeframe === 'true';
  const ts = tsFromMillis(revision);
  const selectedRevision =
    revisions.find((r) => r.lastModifiedAt.isEqual(ts)) ||
    head(revisions) ||
    null;

  return {
    selectedRevision,
    syncTimeframe,
    changeSync: (syncTimeframe: boolean) => {
      changeQuery({ syncTimeframe: syncTimeframe ? 'true' : undefined });
    },
    toSyncTimeframeQueryParam: (syncTimeframe: boolean) => ({
      syncTimeframe: syncTimeframe ? 'true' : undefined
    }),
    selectRevision: (revision: IPageRevision | null) => {
      changeQuery({
        revision: revision
          ? revision.lastModifiedAt.toMillis().toString()
          : undefined
      });
    }
  };
};

const getRevisionMoments = (
  revision: IPageRevision,
  revisions: IPageRevision[],
  space: ISpace
) => {
  const {
    createdAt,
    config: { tz }
  } = space;
  const hasRealtime = isFeatureEnabled(space.features, 'REFERRER_REPORTS_V1');
  const { start, end } = getTimeBoundaries(revision, revisions);
  const clampedStart = isBefore(start, createdAt) ? createdAt : start;
  const endOfYesterday = moment().tz(tz).startOf('day').subtract(1, 'ms');
  const today = moment().tz(tz);
  const upperBound = hasRealtime ? today : endOfYesterday;

  return {
    start: moment(clampedStart.toMillis()).tz(tz),
    end: end ? moment(end.toMillis()).tz(tz) : upperBound
  };
};

interface TimeframeWithRevisionsState {
  ranges: AnalyticsRanges;
  pickerProps: TimeframePickerWithRevisionsProps;
}

export const useTimeframeWithRevisions = (
  revisions: IPageRevision[],
  options: {
    timeframeOptions?: ITimeframeOption[];
    comparisonOptions?: IComparisonOption[];
  } = {}
): TimeframeWithRevisionsState => {
  const { changeQuery } = useRoutes();
  const { tz, space } = useCurrentUser();
  const normalizedRevisions = useMemo(
    () => revisions.filter((r) => !r.ignore).sort(compareRevisions('desc')),
    [revisions]
  );
  const opts = useDefaultOptions(options);
  const [
    timeframe,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _setTimeframe,
    toTimeframeQuery
  ] = useTimeframeDefinitionFromUrl();
  const {
    selectedRevision,
    syncTimeframe,
    changeSync,
    selectRevision,
    toSyncTimeframeQueryParam
  } = useRevisionUrlParams(normalizedRevisions);

  const timeRanges = useMemo(() => {
    return toTimeRanges(timeframe, tz);
  }, [timeframe, tz]);

  const revisionMoments = useMemo(() => {
    if (selectedRevision && normalizedRevisions.length && syncTimeframe) {
      return getRevisionMoments(selectedRevision, normalizedRevisions, space);
    }
    return null;
  }, [normalizedRevisions, selectedRevision, space, syncTimeframe]);

  const ranges = useMemo(() => {
    if (revisionMoments) {
      return {
        range: {
          start: revisionMoments.start.toISOString(),
          end: revisionMoments.end.toISOString()
        }
      };
    }
    return timeRanges;
  }, [revisionMoments, timeRanges]);

  const value = useMemo<TimeframeDefinition>(() => {
    if (revisionMoments) {
      return {
        range: {
          kind: 'custom',
          start: revisionMoments.start.toISOString(),
          end: revisionMoments.end.toISOString()
        },
        comparison: {
          kind: 'previous'
        }
      };
    }
    return timeframe;
  }, [revisionMoments, timeframe]);

  const onChange = useCallback(
    (tf: TimeframeDefinition) => {
      changeQuery({
        ...toTimeframeQuery(tf),
        ...toSyncTimeframeQueryParam(false)
      });
    },
    [changeQuery, toSyncTimeframeQueryParam, toTimeframeQuery]
  );

  const hasMismatch = useMemo(() => {
    if (!selectedRevision || !normalizedRevisions.length || syncTimeframe)
      return false;
    const moments = toMoments(timeframe, tz);
    const { start, end } = getRevisionMoments(
      selectedRevision,
      normalizedRevisions,
      space
    );

    return (
      normalizedRevisions.length > 1 &&
      !moments.start.isSameOrAfter(start) &&
      !moments.end.isSameOrBefore(end)
    );
  }, [
    normalizedRevisions,
    selectedRevision,
    space,
    syncTimeframe,
    timeframe,
    tz
  ]);

  return useMemo(() => {
    return {
      ranges,
      pickerProps: {
        value,
        onChange,
        timeframeOptions: opts.timeframeOptions,
        comparisonOptions: opts.comparisonOptions,
        revisions: normalizedRevisions,
        selectedRevision,
        syncTimeframe,
        hasMismatch,
        selectRevision,
        setSyncTimeframe: changeSync
      }
    };
  }, [
    changeSync,
    hasMismatch,
    onChange,
    opts.comparisonOptions,
    opts.timeframeOptions,
    ranges,
    normalizedRevisions,
    selectRevision,
    selectedRevision,
    syncTimeframe,
    value
  ]);
};

const Container = styled('div')`
  display: flex;
  align-items: flex-start;

  > :last-child {
    margin-left: ${(p) => p.theme.spacing() * 2}px;
  }
`;

const DataMismatch = styled('span')`
  background-color: ${COLORS.red.red5} !important;
  width: 14px;
  height: 14px;
  font-size: 12px;
  line-height: 13px;
  font-weight: 700;
  border-radius: 100% !important;
  color: white !important;
  display: inline-block;
  text-align: center;
`;

interface RevisionSelectorProps {
  revisions: IPageRevision[];
  selectedRevision: IPageRevision | null;
  selectRevision: (revision: IPageRevision | null) => void;
  syncTimeframe: boolean;
  setSyncTimeframe: (syncTimeframe: boolean) => void;
  hasMismatch: boolean;
}

const RevisionSelector: FC<RevisionSelectorProps> = ({
  revisions,
  selectedRevision,
  selectRevision,
  setSyncTimeframe,
  syncTimeframe,
  hasMismatch
}) => {
  const { tz } = useCurrentUser();
  const [open, setOpen] = useState(false);
  const hasRealtime = useFeatureEnabled('REFERRER_REPORTS_V1');

  return (
    <FormControl>
      <Select
        autoWidth
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        variant="outlined"
        value={selectedRevision ? revisions.indexOf(selectedRevision) : null}
        onChange={(e) => selectRevision(revisions[Number(e.target.value)])}
        classes={{
          select: css((t) => ({
            fontSize: t.custom.fontSize.m,
            padding: `${t.spacing(1)}px ${t.spacing(2)}px`
          }))
        }}
      >
        {revisions.map((revision, i) => {
          if (revision.ignore) return null;
          return (
            <MenuItem key={i} value={i}>
              v{revisions.length - i} ·{' '}
              {revisions[i].provider === 'user'
                ? 'Created manually '
                : 'Published'}{' '}
              {formatSmartRelativeDate(
                toMoment(revisions[i].lastModifiedAt).tz(tz),
                'MMM D, YYYY'
              )}
              <Typography
                component="span"
                variant="caption"
                color="textSecondary"
                style={{ marginLeft: `8px` }}
              >
                {hasRealtime &&
                  toMoment(revisions[i].lastModifiedAt)
                    .tz(tz)
                    .format('hh:mm A')}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText margin="dense">
        <FormControlLabel
          classes={{
            root: css(() => ({
              '&&': {
                position: 'relative',
                left: '4px'
              }
            })),
            label: css((t) => ({
              '&&': {
                fontSize: t.custom.fontSize.s,
                color: syncTimeframe ? t.custom.colors.primary.main : 'inherit'
              }
            }))
          }}
          control={
            <Checkbox
              color="primary"
              classes={{
                root: css(() => ({
                  '&&': { paddingTop: 0, paddingBottom: 0, paddingRight: 4 }
                }))
              }}
              icon={<IconSquare size="1rem" />}
              checkedIcon={<IconCheckSquare size="1rem" />}
              checked={syncTimeframe}
              onChange={(ev) => setSyncTimeframe(ev.target.checked)}
            />
          }
          label={
            <span>
              Align dates with a single revision&nbsp;
              {hasMismatch && (
                <Tooltip
                  title='A revision was published during the timeframe you are viewing. Check the box to "Align dates with a single revision", and ensure only data from a specific revision is shown, especially if viewing heatmaps.'
                  placement="bottom"
                >
                  <DataMismatch>!</DataMismatch>
                </Tooltip>
              )}
            </span>
          }
        />
      </FormHelperText>
    </FormControl>
  );
};

interface TimeframePickerWithRevisionsProps {
  value: TimeframeDefinition;
  onChange: (nextValue: TimeframeDefinition) => void;
  timeframeOptions: ITimeframeOption[];
  comparisonOptions: IComparisonOption[];
  revisions: IPageRevision[];
  selectedRevision: IPageRevision | null;
  selectRevision: (revision: IPageRevision | null) => void;
  setSyncTimeframe: (syncTimeframe: boolean) => void;
  syncTimeframe: boolean;
  hasMismatch: boolean;
}

export const TimeframePickerWithRevisions: FC<TimeframePickerWithRevisionsProps> = ({
  value,
  onChange,
  timeframeOptions,
  comparisonOptions,
  revisions,
  selectedRevision,
  selectRevision,
  syncTimeframe,
  setSyncTimeframe,
  hasMismatch
}) => {
  return (
    <Container>
      {selectedRevision && (
        <RevisionSelector
          revisions={revisions}
          selectedRevision={selectedRevision}
          syncTimeframe={syncTimeframe}
          hasMismatch={hasMismatch}
          selectRevision={selectRevision}
          setSyncTimeframe={setSyncTimeframe}
        />
      )}
      <TimeframePickerDense
        value={value}
        onChange={onChange}
        timeframeOptions={timeframeOptions}
        comparisonOptions={comparisonOptions}
      />
    </Container>
  );
};
