import { partition } from 'lodash';
import {
  Feature,
  FeatureMap,
  FEATURES,
  IFeature,
  IFeatureStatus,
  isFeatureEnabled,
  setFeature as setFeature_
} from '../../../domainTypes/features';
import { ISpace } from '../../../domainTypes/space';
import { executeInBatch, store } from '../../../services/db';
import { FS } from '../../../versions';

export interface IFeatureListItem {
  name: Feature;
  feature: IFeature;
  enabled: boolean;
  status: IFeatureStatus | null;
}

export interface IFeatureDashboardListItem {
  name: Feature;
  feature: IFeature;
  spaces: {
    enabled: ISpace[];
    disabled: ISpace[];
  };
  counts: {
    total: number;
    enabled: number;
  };
}

export const getFeatureList = (featureMap: FeatureMap): IFeatureListItem[] => {
  return Object.keys(FEATURES).map((key) => {
    const name = key as Feature;
    const feature = FEATURES[name];
    return {
      name,
      feature,
      enabled: isFeatureEnabled(featureMap, name),
      status: featureMap[feature.dbKey] || null
    };
  });
};

export const getDashboardData = (
  spaces: ISpace[]
): IFeatureDashboardListItem[] => {
  const total = spaces.length;
  return Object.keys(FEATURES).map((key) => {
    const name = key as Feature;
    const feature = FEATURES[name];
    const [enabled, disabled] = partition(spaces, (s) =>
      isFeatureEnabled(s.features, name)
    );
    return {
      name,
      feature,
      spaces: {
        enabled,
        disabled
      },
      counts: {
        total,
        enabled: enabled.length
      }
    };
  });
};

export const setFeature = (
  space: ISpace,
  feature: Feature,
  enabled: boolean
) => {
  return store()
    .collection(FS.spaces)
    .doc(space.id)
    .update({
      features: setFeature_(space.features, feature, enabled, 'AFFILIMATE')
    });
};

export const setFeatureForSpaces = (
  spaces: ISpace[],
  feature: Feature,
  enabled: boolean
) => {
  return executeInBatch(spaces, FS.spaces, (batch, collection, space) => {
    batch.update(collection.doc(space.id), {
      features: setFeature_(space.features, feature, enabled, 'AFFILIMATE')
    });
  });
};
