import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Loader } from '../../../../components/Loader';
import { ISpace } from '../../../../domainTypes/space';
import { ITrackingConfigV4 } from '../../../../domainTypes/tracking';
import { useErrorLogger } from '../../../../hooks/useErrorLogger';
import { useCurrentUser } from '../../../../services/currentUser';
import { LoadingValue } from '../../../../services/db';
import { SettingsLayout } from '../../SettingsLayout';

const MOCK_CONFIG: ITrackingConfigV4 = {
  spaceId: 'xxx',
  channelId: 'aaa',
  inactive: false,
  url: 'https://notanomadblog.comj',
  verified: true,
  enabled: true,
  source: 'scan',
  trackEvents: [],
  smartLabels: [],
  redirectUrls: [],
  customLinkTracking: [],
  ignoreParameters: [],
  forwardUrlParameters: [],
  forwardLinkParameters: [],
  urlFormat: 'url',
  observeLinks: false,
  delay: 0,
  dynamicLinks: [],
  newTab: true,
  v: 4
};

const useTrackingConfigForChannel = (
  spaceId: string,
  channelId: string
): LoadingValue<ITrackingConfigV4> => {
  return [MOCK_CONFIG, false, null];
};

const ChannelSettings = ({
  space,
  channelId
}: {
  space: ISpace;
  channelId: string;
}) => {
  const [config, loading, error] = useTrackingConfigForChannel(
    space.id,
    channelId
  );
  useErrorLogger(error);

  if (loading) {
    return <Loader height={500} />;
  }
  if (error || !config) {
    return null;
  }

  return null;
};

const Header = ({
  space,
  channelId,
  setChannelId
}: {
  space: ISpace;
  channelId: string;
  setChannelId: (nextChannelId: string) => void;
}) => {
  return <div>Tracking Settings - Todo header</div>;
};

export const PageSettingsTracking = () => {
  const { space } = useCurrentUser();
  const [channelId, setChannelId] = useState(space.domains[0].channelId || '');
  return (
    <SettingsLayout>
      <Helmet>
        <title>Tracking settings | Affilimate</title>
      </Helmet>
      <Header space={space} channelId={channelId} setChannelId={setChannelId} />
      <ChannelSettings space={space} channelId={channelId} />
    </SettingsLayout>
  );
};
