import { Typography } from '@material-ui/core';
import React from 'react';
import { Clock } from 'react-feather';
import { COLORS } from '../../../domainTypes/colors';
import { IMaintenanceSettings } from '../../../domainTypes/maintenance';
import { styled } from '../../../emotion';
import { SplashPage, SplashPageContent } from '../../../layout/SplashPage';

const OuterWrapper = styled('div')`
  margin: ${(p) => p.theme.spacing(4)}px auto;
`;

const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  text-align: center;
  margin: 0 auto;
  border-radius: 100%;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

export const PageMaintenance = ({
  settings
}: {
  settings: IMaintenanceSettings;
}) => {
  return (
    <SplashPage>
      <OuterWrapper>
        <IconWrapper
          style={{
            color: COLORS.blue.blue6,
            backgroundColor: COLORS.blue.blue1
          }}
        >
          <Clock size={42} />
        </IconWrapper>
      </OuterWrapper>
      <SplashPageContent>
        <Typography
          variant="h6"
          component="p"
          gutterBottom
          style={{ fontWeight: 700, textAlign: 'center' }}
        >
          We'll be back soon!
        </Typography>
        <Typography
          variant="body1"
          component="p"
          gutterBottom
          style={{ textAlign: 'center', maxWidth: 400, margin: '0 auto' }}
        >
          Affilimate is currently undergoing database maintenance. In the
          meantime, all data is still being tracked and will be available once
          we're back online in about an hour.
          <br />
          <br />
          Thank you for your patience.
        </Typography>
      </SplashPageContent>
    </SplashPage>
  );
};
