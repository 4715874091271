import React, { CSSProperties, useMemo, useState } from 'react';
import { styled } from '../../emotion';
import { useOnVisbibilityChange } from '../../hooks/useIsVisible';

type Props = {
  src: string;
  placeholder?: string;
  alt?: string;
  width?: number | string;
  height?: number | string;
  options?: IntersectionObserverInit;
};

// img are typically inline elements and thus adhere
// to font-size and line-height. If we don't set it
// to block, subsequent images will have small gaps
// between them.
const Image = styled('img')`
  display: block;
  position: absolute;
  transition: opacity 300ms linear;
`;

// needed so that the blur effect doesn't bleed over
const PlaceholderWrapper = styled('div')`
  overflow: hidden;
  position: absolute;
`;

// position relave needed, so that the blur effect doesn't bleed over
const PlaceholderImage = styled(Image)`
  filter: blur(10px);
  position: relative;
`;

const STYLES = {
  loaded: { opacity: 1, zIndex: 0 },
  loading: { opacity: 0, zIndex: -1 }
};

export const LazyProgressiveImage: React.FC<Props> = ({
  src,
  placeholder,
  alt,
  width,
  height,
  options
}) => {
  const [visibleOnce, setVisibleOnce] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const ref = useOnVisbibilityChange<HTMLDivElement>(
    visible => visible && !visibleOnce && setVisibleOnce(true),
    options
  );

  const outerStyle = useMemo<CSSProperties>(
    () => ({ width, height, position: 'relative' }),
    [width, height]
  );

  return (
    <div style={outerStyle} ref={ref}>
      {placeholder && (
        <>
          <PlaceholderWrapper>
            <PlaceholderImage
              src={placeholder}
              alt={alt}
              height={height}
              width={width}
            />
          </PlaceholderWrapper>
          {visibleOnce && (
            <Image
              src={src}
              alt={alt}
              height={height}
              width={width}
              style={imageLoaded ? STYLES.loaded : STYLES.loading}
              onLoad={() => setImageLoaded(true)}
            />
          )}
        </>
      )}
      {visibleOnce && !placeholder && (
        <Image src={src} alt={alt} width={width} height={height} />
      )}
    </div>
  );
};
