import React from 'react';
import { CampaignsPageBody } from '../components/CampaignsPageBody';
import { useCampaigns } from '../service';
import { Loader } from '../../../components/Loader';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import { Paper } from '@material-ui/core';
import { styled } from '../../../emotion';
import { useCurrentUserScopes } from '../../../services/currentUser';
import { NoPermissions } from '../../../components/NoPermissions';

const Wrapper = styled('div')`
  margin: 0 auto;
  max-width: 1200px;
`;

export const PageCampaignsSettings: React.FC = () => {
  const scopes = useCurrentUserScopes();
  const canView = scopes.has('campaigns.view');
  const [campaigns, loading] = useCampaigns();
  return (
    <>
      <Helmet>
        <title>Campaigns Settings | Affilimate</title>
      </Helmet>
      <CampaignsPageBody>
        <Wrapper>
          <Typography component="h1" variant="h6">
            <strong>Campaign Settings</strong>
          </Typography>
          {!canView ? (
            <NoPermissions />
          ) : loading || !campaigns ? (
            <Loader size={36} height={500} />
          ) : (
            <Paper>hi</Paper>
          )}
        </Wrapper>
      </CampaignsPageBody>
    </>
  );
};
