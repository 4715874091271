import { useCallback, useState } from 'react';
import { Dimension } from '../filters';
import { useFiltersParam } from '../hooks';
import { FilterDefinition } from '../../../../domainTypes/filters';
import { useMixpanel } from '../../../../services/mixpanel';

export const useFiltersState = () => {
  const mixpanel = useMixpanel();
  const [
    placeholderDimension,
    setPlaceholderDimension
  ] = useState<Dimension | null>(null);
  const [filters, setFilters] = useFiltersParam();

  const clearPlaceholder = useCallback(() => setPlaceholderDimension(null), []);

  const addFilter = useCallback(
    (def: FilterDefinition) => {
      setFilters([...filters, def]);
      mixpanel.track('filters_apply', { name: def.k, value: def.v });
      clearPlaceholder();
    },
    [clearPlaceholder, filters, mixpanel, setFilters]
  );

  const updateFilter = useCallback(
    (def: FilterDefinition) => {
      setFilters(
        filters.map((d) => {
          if (d.k === def.k) {
            return def;
          }
          return d;
        })
      );
      mixpanel.track('filters_apply', { name: def.k, value: def.v });
    },
    [filters, mixpanel, setFilters]
  );

  const removeFilter = useCallback(
    (dimension: Dimension) => {
      setFilters(filters.filter((f) => f.k !== dimension));
      mixpanel.track('filters_remove', { name: dimension });
    },
    [filters, mixpanel, setFilters]
  );

  const clearFilters = useCallback(() => {
    setFilters([]);
    mixpanel.track('filters_clear');
  }, [mixpanel, setFilters]);

  return {
    filters,
    placeholderDimension,
    setPlaceholderDimension,
    clearPlaceholder,
    addFilter,
    updateFilter,
    removeFilter,
    clearFilters
  };
};
