import {
  Button,
  ButtonBase,
  Dialog,
  DialogContent,
  Typography
} from '@material-ui/core';
import React from 'react';
import {
  saveChannel,
  toChannelIcon,
  useChannels
} from '../../../services/channels';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../services/currentUser';
import { IChannel } from '../../../domainTypes/channels';
import { Doc } from '../../../domainTypes/document';
import { OffsiteChannelForm } from './OffsiteChannelForm';
import { now } from '../../../services/time';
import { ConnectionId } from '../../ConnectionId';
import { FlexContainer } from '../../../layout/Flex';
import { WithShape } from '../../Charts/Util';
import { getStableRandomColor } from '../../../services/color';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { Centered } from '../../../layout/Centered';
import { Loader } from '../../Loader';
import { ChevronRight, X } from 'react-feather';
import { removeDoc } from '../../../services/db';

export const OffsiteChannelStep = ({
  onContinue
}: {
  onContinue: () => void;
}) => {
  const currentUser = useCurrentUser();
  const scopes = useCurrentUserScopes();
  const canEditChannels = scopes.has('channels.edit');
  const canCreateChannels = scopes.has('channels.create');
  const { enqueueSnackbar } = useSnackbar();
  const { space } = currentUser;
  const [channels = [], loadingChannels] = useChannels(space.id);
  const [selectedChannel, setSelectedChannel] = React.useState<Doc<
    IChannel
  > | null>(null);

  const onDeleteChannel = async (channel: Doc<IChannel>) => {
    setSelectedChannel(null);
    await removeDoc(channel);
    enqueueSnackbar('Channel deleted!', { variant: 'success' });
  };

  const onSaveChannel = async (channel: Doc<IChannel>) => {
    const newChannel: Doc<IChannel> = {
      ...channel,
      data: {
        ...channel.data,
        updatedAt: now()
      }
    };
    return saveChannel(newChannel).then(() => {
      enqueueSnackbar('Channel saved!', { variant: 'success' });
      setSelectedChannel(null);
    });
  };

  if (loadingChannels) {
    return (
      <Centered>
        <Loader height={250} size={24} />
      </Centered>
    );
  }

  return (
    <div>
      <Typography
        paragraph
        variant="h5"
        style={{ margin: '12px auto', textAlign: 'center' }}
      >
        Manage offsite channels
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        paragraph
        style={{ textAlign: 'center' }}
      >
        Offsite channels represent commissions and engagement that happens off
        your owned and operated website.
      </Typography>
      <br />
      {channels.map((channel: Doc<IChannel>) => (
        <FlexContainer
          justifyContent="space-between"
          style={{ marginBottom: '12px' }}
          key={channel.data.channelId}
        >
          <Typography variant="body1">
            <span style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
              <WithShape
                large
                color={getStableRandomColor(channel.data.channelId)}
              >
                <FlexContainer alignItems="center">
                  {toChannelIcon(channel.data.type)}
                  <span>{channel.data.name}</span>
                </FlexContainer>
              </WithShape>
              <ConnectionId>{channel.data.subIdDefaultPrefix}</ConnectionId>
            </span>
          </Typography>
          {canEditChannels && (
            <Button onClick={() => setSelectedChannel(channel)}>Edit</Button>
          )}
        </FlexContainer>
      ))}
      {selectedChannel && (
        <Dialog
          open={true}
          onClose={() => setSelectedChannel(null)}
          scroll="body"
        >
          <DialogContent>
            <FlexContainer justifyContent="space-between">
              <Typography
                variant="h6"
                component="h2"
                style={{ fontWeight: 'bold' }}
              >
                Edit channel
              </Typography>
              <ButtonBase onClick={() => setSelectedChannel(null)}>
                <X />
              </ButtonBase>
            </FlexContainer>
            <OffsiteChannelForm
              allChannels={channels}
              mode="edit"
              channel={selectedChannel}
              setChannel={setSelectedChannel}
              onSaveChannel={onSaveChannel}
              onDeleteChannel={() => {
                onDeleteChannel(selectedChannel);
              }}
              onCancel={() => setSelectedChannel(null)}
            />
          </DialogContent>
        </Dialog>
      )}
      {canCreateChannels && (
        <FlexContainer alignItems="center" justifyContent="center">
          <Button onClick={onContinue} size="large">
            Add a channel <ChevronRight size={14} />{' '}
          </Button>
        </FlexContainer>
      )}
    </div>
  );
};
