import firebase from 'firebase/app';
import React, { Fragment } from 'react';
import { Doc } from '../../../../domainTypes/document';
import { Notification } from '../../../../domainTypes/notifications';
import { styled } from '../../../../emotion';
import { HUMAN_DATE_SHORT, toMoment } from '../../../../services/time';
import { RENDERERS } from '../renderers';

type Props = {
  notifications: Doc<Notification>[];
};

const Item = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const Day = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const isSameDay = (
  a: firebase.firestore.Timestamp,
  b: firebase.firestore.Timestamp
) => {
  const format = 'YYYYDDDD';
  return toMoment(a).format(format) === toMoment(b).format(format);
};

export const NotificationList: React.FC<Props> = ({ notifications }) => {
  const renderers = RENDERERS;

  return (
    <div>
      {notifications.map((n, i) => {
        const renderer = renderers[n.data.type];
        if (!renderer) {
          console.log('No renderer for: ', n.data.type);
          return null;
        }

        const showDate =
          i === 0 ||
          !isSameDay(n.data.createdAt, notifications[i - 1].data.createdAt);

        return (
          <Fragment key={n.id}>
            {showDate && (
              <Day>{toMoment(n.data.createdAt).format(HUMAN_DATE_SHORT)}</Day>
            )}
            <Item>{React.createElement(renderer, { n: n })}</Item>
          </Fragment>
        );
      })}
    </div>
  );
};
