import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { AlertTriangle } from 'react-feather';
import { Link } from 'react-router-dom';
import { AlertBox } from '../../../../../../components/AlertBox';
import { styled } from '../../../../../../emotion';
import { useRoutes } from '../../../../../../routes';
import { useCurrentUser } from '../../../../../../services/currentUser';
import { useFeatureEnabled } from '../../../../../../services/features';
import { useScans } from '../../../../../../services/scan';

const AlertInner = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    position: relative;
    top: 4px;
    margin-right: 6px;
  }

  strong {
    margin-right: 6px;
  }
`;

export const EmptyState = () => {
  const [scans, error] = useScans(1);
  const { ROUTES } = useRoutes();
  const hasScanlessTracking = useFeatureEnabled('SCANLESS_TRACKING');
  const { space } = useCurrentUser();

  if (error) {
    return null;
  }

  if (hasScanlessTracking && space.domains.find((d) => d.verified)) {
    return (
      <div
        style={{
          width: '80%',
          maxWidth: '500px',
          padding: '0 36px',
          margin: '0 auto',
          textAlign: 'center'
        }}
      >
        <img src="/images/empty-states/links.png" width="100%" alt="" />
        <Typography variant="h6" component="h3">
          <strong>Your links are tracking</strong>
        </Typography>
        <br />
        <Typography variant="body1" component="p" paragraph>
          We are currently tracking your links. Your first links report will
          appear here within 24 hours.
        </Typography>
      </div>
    );
  }

  if (hasScanlessTracking && !space.domains.find((d) => d.verified)) {
    return (
      <div
        style={{
          width: '80%',
          maxWidth: '500px',
          padding: '0 36px',
          margin: '0 auto',
          textAlign: 'center'
        }}
      >
        <img src="/images/empty-states/links.png" width="100%" alt="" />
        <Typography variant="h6" component="h3">
          <strong>Finish your tech setup</strong>
        </Typography>
        <br />
        <Typography variant="body1" component="p" paragraph>
          Finish setting up your site to start tracking your content
          performance.
        </Typography>
        <Link to={ROUTES.dashboard.overview.url()}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ whiteSpace: 'nowrap' }}
          >
            Finish your tech setup
          </Button>
        </Link>
      </div>
    );
  }

  return (
    <>
      {scans && scans.length > 0 && (
        <AlertBox variant="pending" style={{ marginBottom: '36px' }}>
          <AlertInner>
            <Typography variant="body2" component="p">
              <AlertTriangle size={18} />
              &nbsp;
              <strong>Links not imported yet.</strong> Site was scanned for
              affiliate links, but no links were imported for tracking. Import
              links now to begin collecting analytics.
            </Typography>
            <Link to={ROUTES.productScans.details.url(scans[0].id)}>
              <Button variant="outlined" style={{ whiteSpace: 'nowrap' }}>
                Finish importing links
              </Button>
            </Link>
          </AlertInner>
        </AlertBox>
      )}

      <div
        style={{
          width: '80%',
          maxWidth: '500px',
          padding: '0 36px',
          margin: '0 auto',
          textAlign: 'center'
        }}
      >
        <img src="/images/empty-states/links.png" width="100%" alt="" />
        <h3>Import your affiliate links with the website scan</h3>
        <p>
          Let's scan your website for affiliate links to get started tracking
          your content performance.
        </p>
        <Link to={ROUTES.productScans.overview.url()}>
          <Button color="primary" variant="contained">
            Scan your website
          </Button>
        </Link>
      </div>
    </>
  );
};
