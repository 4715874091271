import { useCollection } from 'react-firebase-hooks/firestore';
import { generateToDocFn } from '../../../domainTypes/document';
import {
  emptyNotificationCounter,
  INotificationCounter,
  Notification
} from '../../../domainTypes/notifications';
import {
  batchUpdate,
  store,
  useMappedLoadingValue
} from '../../../services/db';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import {
  createDocumentListenerGetter,
  useDocumentListener
} from '../../../services/firecache/documentListener';
import { now } from '../../../services/time';
import { FS } from '../../../versions';

export const toNotificationDoc = generateToDocFn<Notification>();
export const toNotificationCounterDoc = generateToDocFn<INotificationCounter>();

export const notificationCollection = () =>
  store().collection(FS.notifications);
export const notificationCounterCollection = () =>
  store().collection(FS.notificationCounters);

const getNotificationCounterListener = createDocumentListenerGetter(
  userId => notificationCounterCollection().doc(userId),
  toNotificationCounterDoc,
  emptyNotificationCounter
);

export const useNotificationCounter = (userId: string) => {
  return useDocumentListener(getNotificationCounterListener(userId));
};

const updateNotification = (id: string, update: Partial<Notification>) => {
  return notificationCollection()
    .doc(id)
    .update(update);
};

export const markNotificationAsSeen = (
  notificationId: string,
  seenAt = now()
) => updateNotification(notificationId, { seenAt });

export const markNotificationAsUnseen = (notificationId: string) =>
  updateNotification(notificationId, { seenAt: null });

export const markAllNotificationsAsSeen = async (
  userId: string,
  seenAt = now()
) => {
  const ids = await notificationCollection()
    .where('userId', '==', userId)
    .where('seenAt', '==', null)
    .get()
    .then(s => s.docs.map(d => d.id));

  return batchUpdate(
    FS.notifications,
    ids.map(id => ({ id, collection: FS.notifications, data: { seenAt } }))
  );
};

const getRecentNotificationsCacheForUser = createCollectionListenerStore(
  userId =>
    new CollectionListener(
      notificationCollection()
        .where('userId', '==', userId)
        .where('seenAt', '==', null)
        .orderBy('createdAt', 'desc'),
      toNotificationDoc
    )
);

export const useRecentNotifications = (userId: string) =>
  useCollectionListener(getRecentNotificationsCacheForUser(userId));

export const useAllNotifications = (userId: string, limit: number) => {
  return useMappedLoadingValue(
    useCollection(
      notificationCollection()
        .where('userId', '==', userId)
        .orderBy('createdAt', 'desc')
        .limit(limit)
    ),
    s => s.docs.map(toNotificationDoc)
  );
};
