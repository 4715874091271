import { compact, omit } from 'lodash';
import { useMemo } from 'react';
import {
  AnalyticsFilter,
  AnalyticsQuery
} from '../../../domainTypes/analytics_v2';
import { SalesFilterArgs } from '../../../domainTypes/performance';
import { usePromise } from '../../../hooks/usePromise';
import { useAnalyticsQueryV2 } from '../../../services/analyticsV2/query';
import { useMappedLoadingValue } from '../../../services/db';
import { getKnownPartnerForKey } from '../../../services/partner';
import { useSalesCacheContext } from '../../../services/sales/cache';
import { getDistinctValues } from '../../../services/sales/distinctValues';
import { ISOTimeRange } from '../../../services/time';

export const useKnownPartnersFromSalesV2 = (
  spaceId: string,
  range: ISOTimeRange,
  filters: AnalyticsFilter[]
) => {
  const q = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      select: ['commission_count_gross'],
      groupBy: ['pk'],
      filters
    };
  }, [range, filters]);

  return useMappedLoadingValue(useAnalyticsQueryV2(spaceId, q), (res) => {
    const results = compact(
      res.rows
        .filter((r) => r.data.commission_count_gross?.curr !== 0)
        .map((r) => r.group.pk)
        .map(getKnownPartnerForKey)
    );
    return results;
  });
};

export const useKnownPartnersV2 = (
  spaceId: string,
  range: ISOTimeRange,
  filters: AnalyticsFilter[]
) => {
  const q = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      select: ['agg_uniq_pk'],
      filters
    };
  }, [range, filters]);

  return useMappedLoadingValue(useAnalyticsQueryV2(spaceId, q), (res) =>
    compact(
      (res.rows[0]?.data.agg_uniq_pk?.curr || []).map(getKnownPartnerForKey)
    )
  );
};

export const useKnownAdvertisersV2 = (
  spaceId: string,
  range: ISOTimeRange,
  filters: AnalyticsFilter[]
) => {
  const q = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      select: ['agg_uniq_advertiser_name'],
      filters
    };
  }, [range, filters]);

  return useMappedLoadingValue(useAnalyticsQueryV2(spaceId, q), (res) =>
    compact(
      (res.rows[0]?.data.agg_uniq_advertiser_name?.curr || []).map((x) => x)
    )
  );
};

export const useKnownPayoutIdsV2 = (spaceId: string, q: SalesFilterArgs) => {
  const { version } = useSalesCacheContext();
  return useMappedLoadingValue(
    usePromise(
      () => getDistinctValues(spaceId, 'payout_id', omit(q, ['payout_id'])),
      [spaceId, q, version]
    ),
    compact
  );
};
