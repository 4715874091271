import React, { ComponentProps } from 'react';
import { CustomTooltip } from '../../Charts/CustomTooltip';
import { ChartContext } from './chart-context';
import { Tooltip, TooltipProps } from 'recharts';
import { entries, groupBy, orderBy } from 'lodash';
import {
  formatMetric,
  Metric,
  metricTitle
} from '../../../services/analyticsV2/metrics';
import Typography from '@material-ui/core/Typography';
import { WithShape } from '../../Charts/Util';
import { COLOR_UNKNOWN } from '../../../services/color';
import { readMetric } from './service/keys';
import { Granularity } from '../../../features/Content/pages/Traffic/Referrers/services/referrer-groups';
import { timeLabelFormatter } from './service/formatters';

type CustomTooltipProps = ComponentProps<typeof CustomTooltip>;

export const tooltip = (
  { startsInCurrentYear, currency }: ChartContext,
  intervalUnit: Granularity['unit'] = 'day',
  TooltipProps: Partial<TooltipProps> = {},
  CustomTooltipProps: Partial<CustomTooltipProps> = {}
) => (
  <Tooltip
    key="timeseries-tooltip"
    cursor={false}
    wrapperStyle={{ zIndex: 1000 }}
    separator=": "
    content={
      <CustomTooltip
        size="normal"
        grouper={(items) => {
          const byMetric = groupBy(items, (item) => readMetric(item.dataKey));
          return entries(byMetric)
            .reverse()
            .map(([metric, items]) => {
              return {
                key: metric,
                label: metricTitle(metric as Metric),
                items: orderBy(items, 'value', 'desc')
              };
            });
        }}
        {...CustomTooltipProps}
      />
    }
    labelFormatter={(label) => {
      let formatDate = timeLabelFormatter(startsInCurrentYear, intervalUnit);
      return (
        <Typography variant="body1">{formatDate(label as string)}</Typography>
      );
    }}
    formatter={(value, name, { color, dataKey }) => {
      const metric = readMetric(dataKey);
      return [
        formatMetric(value as number, metric, currency),
        <WithShape color={color ?? COLOR_UNKNOWN}>{name}</WithShape>
      ];
    }}
    {...TooltipProps}
  />
);
