import { AnalyticsFilter } from '../../../../../../domainTypes/analytics_v2';
import { useSites } from '../../../../../../components/analytics_v2/SiteSelector';
import { useMemo } from 'react';

/*
 NOTE:
   We skip server-side clicks on traffic report.
   Server-side clicks has "external" substring inside their page_view_id.
*/
const excludeExternalPV: AnalyticsFilter = {
  field: 'page_view_id',
  condition: 'not ilike',
  pattern: '%external%'
};

/*
 NOTE:
   We skip sales without traffic data.
   Sales rows have no value in page_view_id column.
*/
const excludeSalesWithoutPV: AnalyticsFilter = {
  field: 'page_view_id',
  condition: 'not in',
  values: ['']
};

export const useFilters = (): AnalyticsFilter[] => {
  const sites = useSites();
  return useMemo(() => [sites, excludeExternalPV, excludeSalesWithoutPV], [
    sites
  ]);
};
