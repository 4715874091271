import React, { ReactNode, useState } from 'react';
import { FetchAllPages } from './pages';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { ButtonWithPromise } from '../../../../../components/ButtonWithPromise';
import { TagsMultiSelector } from './TagsMultiSelector';
import { PagesList } from './PagesList';
import { styled } from '../../../../../emotion';

interface TagsDialogContentProps {
  pages: string[];
  tags: Set<string>;
  setTags: (nextTags: Set<string>) => void;
  description: ReactNode;
}

const TagsContentWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(2)}px;
  grid-row-gap: ${(p) => p.theme.spacing(1)}px;
  border-bottom: ${(p) => p.theme.custom.border.standard};
`;

const TagsDialogDescription = styled('div')`
  grid-column: 1/-1;
  padding-bottom: ${(p) => p.theme.spacing(2)}px;
  border-bottom: ${(p) => p.theme.custom.border.standard};
`;

const ContentMask = styled('div')`
  position: relative;

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    pointer-events: none;
    box-shadow: inset 0 -20px 10px -10px #fff;
    border-radius: 2px;
  }
`;

const TagsDialogContent: React.FC<TagsDialogContentProps> = ({
  pages,
  tags,
  setTags,
  description
}) => (
  <DialogContent>
    <TagsContentWrapper>
      <TagsDialogDescription>
        <Typography variant="body1">{description}</Typography>
      </TagsDialogDescription>
      <Typography
        variant="caption"
        color="textPrimary"
        style={{ fontWeight: 'bold' }}
      >
        Tags
      </Typography>
      <Typography
        variant="caption"
        color="textPrimary"
        style={{ fontWeight: 'bold' }}
      >
        Pages
      </Typography>
      <ContentMask>
        <TagsMultiSelector value={tags} onChange={setTags} />
      </ContentMask>
      <ContentMask>
        <PagesList pages={pages} />
      </ContentMask>
    </TagsContentWrapper>
  </DialogContent>
);

export interface TagsDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  cta: string;
  action: (tags: string[], pages: string[]) => Promise<void>;
  notice: (tags: Set<string>, pages: string[]) => ReactNode;
  description: ReactNode;
  isAllPagesSelected: boolean;
  pages: Set<string>;
}

const InnerDialog = ({
  action,
  cta,
  description,
  notice,
  onClose,
  open,
  pages,
  title
}: {
  open: boolean;
  onClose: () => void;
  title: string;
  cta: string;
  action: (tags: string[], pages: string[]) => Promise<void>;
  notice: (tags: Set<string>, pages: string[]) => React.ReactNode;
  description: React.ReactNode;
  pages: string[];
}) => (
  <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth scroll="body">
    <DialogBody
      onClose={onClose}
      title={title}
      cta={cta}
      action={action}
      notice={notice}
      description={description}
      pages={pages}
    />
  </Dialog>
);

export const TagsDialog: React.FC<TagsDialogProps> = ({
  pages,
  isAllPagesSelected,
  ...props
}) => {
  if (isAllPagesSelected) {
    return (
      <FetchAllPages>
        {({ pages }) => <InnerDialog {...props} pages={pages} />}
      </FetchAllPages>
    );
  }
  return <InnerDialog {...props} pages={[...pages]} />;
};

type DialogBodyProps = {
  onClose: () => void;
  title: string;
  cta: string;
  action: (tags: string[], pages: string[]) => Promise<void>;
  notice: (tags: Set<string>, pages: string[]) => ReactNode;
  description: ReactNode;
  pages: string[];
};

const DialogBody: React.FC<DialogBodyProps> = ({
  onClose,
  pages,
  title,
  cta,
  notice,
  action,
  description
}) => {
  const [tags, setTags] = useState<Set<string>>(new Set());
  const disabled = tags.size === 0;
  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <TagsDialogContent
        pages={pages}
        tags={tags}
        setTags={setTags}
        description={description}
      />
      <DialogActions>
        {!disabled && (
          <Typography
            variant="caption"
            color="textSecondary"
            style={{ marginRight: 'auto' }}
          >
            {notice(tags, pages)}
          </Typography>
        )}
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <ButtonWithPromise
          onClick={async () => {
            await action([...tags], pages);
          }}
          pending="Applying ..."
          variant="contained"
          color="primary"
          disabled={disabled}
        >
          {cta}
        </ButtonWithPromise>
      </DialogActions>
    </>
  );
};
