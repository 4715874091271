import { capitalize, isEqual } from 'lodash';
import pluralize from 'pluralize';
import React, { useMemo, useState } from 'react';
import { PayoutStatusFilterDefinition } from '../../../../domainTypes/filters';
import {
  createPayoutStatusFilterDefinition,
  useDimensionName,
  validateFilterDefinition
} from '../filters';
import { DimensionMenuComponent } from './Dimension';
import { BASIC_MODES, FilterMenu } from './FilterMenu';
import { EnumSelectorMenu, useCollectionState } from './Selector';
import {
  PAYOUT_STATUSES,
  PayoutStatus
} from '../../../../domainTypes/performance';

export const PayoutStatusMenu: DimensionMenuComponent<PayoutStatusFilterDefinition> = ({
  definition,
  filters,
  range,
  onSave
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const [value, toggleValue] = useCollectionState(definition.v);
  const newDefinition = useMemo<PayoutStatusFilterDefinition>(
    () => createPayoutStatusFilterDefinition(value),
    [value]
  );
  const enableSave =
    validateFilterDefinition(newDefinition) &&
    !isEqual(definition, newDefinition);

  const focusValue = (payoutStatus: PayoutStatus) => {
    onSave(createPayoutStatusFilterDefinition([payoutStatus]));
  };

  return (
    <FilterMenu>
      <FilterMenu.Header dimension="payout_status">
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <EnumSelectorMenu
          label={useDimensionName('payout_status')}
          options={PAYOUT_STATUSES}
          selectedValues={value}
          toggleValue={toggleValue}
          focusValue={focusValue}
          toLabel={capitalize}
          analyticsField={'payout_status'}
          range={range}
          queryFilters={filters}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer definition={definition}>
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={`Filter by ${pluralize('payout status', value.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
