import React, { useState } from 'react';
import { Typography, TextField, Button } from '@material-ui/core';
import { styled } from '../../emotion';
import { AnimatedCheckmark } from '../AnimatedCheckmark';

const ButtonContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  > * {
    margin-right: ${p => p.theme.spacing()}px;
    margin-bottom: ${p => p.theme.spacing()}px;
  }
`;

const Container = styled('div')`
  position: relative;
  text-align: center;
  width: 100%;
  padding: 0;
`;

const Link = styled('a')`
  color: ${p => p.theme.palette.primary.main};
  text-decoration: none;
  border-bottom: 2px solid ${p => p.theme.palette.primary.main};
`;

const ContainerForSuccess = styled(Container)`
  padding: ${p => p.theme.spacing(5)}px;
`;

const CheckmarkWrapper = styled('div')`
  padding-bottom: ${p => p.theme.spacing(2)}px;
`;

export type SupportFormProps = {
  title: string;
  description: React.ReactNode;
  email?: string | null | undefined;
  onDone?: () => void;
  disclaimer?: boolean;
};

export const SupportForm = ({
  title,
  description,
  email,
  onDone,
  disclaimer = true
}: SupportFormProps) => {
  const [status, setStatus] = useState('');

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus('SUCCESS');
      } else {
        setStatus('ERROR');
      }
    };
    xhr.send(data);
  };

  if (status === 'SUCCESS') {
    return (
      <ContainerForSuccess>
        <CheckmarkWrapper>
          <AnimatedCheckmark style={{ margin: '0 auto' }} />
        </CheckmarkWrapper>
        <Typography paragraph style={{ fontSize: '24px' }}>
          Your message is on its way{' '}
        </Typography>
        <Typography paragraph>
          Thanks for reaching out! You should be hearing from us shortly.
        </Typography>
        <ButtonContainer
          style={{ margin: '0 auto', textAlign: 'center', display: 'block' }}
        >
          {onDone && (
            <>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => onDone()}
              >
                Close
              </Button>{' '}
            </>
          )}
          <Button
            size="large"
            color={onDone ? 'default' : 'primary'}
            variant={!onDone ? 'contained' : undefined}
            onClick={() => {
              setStatus('');
            }}
          >
            Send another message
          </Button>
        </ButtonContainer>
      </ContainerForSuccess>
    );
  }

  return (
    <form
      onSubmit={submitForm}
      action="https://formspree.io/xayyvppe"
      method="POST"
    >
      <Typography variant="h6" component="p" paragraph>
        {title}
      </Typography>
      <Typography variant="body1" component="p">
        {description}
      </Typography>
      <TextField
        fullWidth
        margin={email ? 'dense' : 'normal'}
        variant="outlined"
        label={!email ? 'Your email address' : undefined}
        type={email ? 'hidden' : 'email'}
        name="email"
        value={email}
      />
      <TextField
        fullWidth
        multiline
        margin="normal"
        variant="outlined"
        rows="6"
        label="Your message"
        type="textarea"
        name="message"
      />
      {disclaimer && (
        <Typography variant="caption" component="p" paragraph>
          Your email address will be stored so we can respond to you, in
          accordance with our{' '}
          <Link
            href="https://affilimate.io/privacy-policy"
            target="_blank"
            rel="nofollow noreferrer"
          >
            privacy policy
          </Link>
          .
        </Typography>
      )}
      <Button size="large" variant="contained" color="primary" type="submit">
        Send message
      </Button>{' '}
      {onDone && (
        <Button size="large" onClick={() => onDone()}>
          Cancel
        </Button>
      )}
    </form>
  );
};
