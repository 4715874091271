import assertNever from 'assert-never';
import { ICountWithTrend, Mode } from '../../../domainTypes/analytics';
import { SortValue } from '../../GroupableList';

export type ValueGetter<Data> = {
  current: (d: Data) => SortValue;
  prev: (d: Data) => SortValue;
  absoluteGrowth: (d: Data) => SortValue;
  relativeGrowth: (d: Data) => SortValue;
};

export const toSingleSort = <Data>(
  v: ValueGetter<Data>,
  mode: Mode
): ((p: Data) => SortValue) => {
  if (mode === 'absolute-numbers') {
    return v.current;
  }
  if (mode === 'absolute-growth') {
    return v.absoluteGrowth;
  }
  if (mode === 'relative-growth') {
    return v.relativeGrowth;
  }

  return assertNever(mode);
};

export const toMultiSort = <Data>(
  v: ValueGetter<Data>,
  mode: Mode
): ((p: Data) => SortValue) | ((p: Data) => SortValue)[] => {
  if (mode === 'absolute-numbers') {
    return [v.current, v.prev];
  }
  if (mode === 'absolute-growth') {
    return v.absoluteGrowth;
  }
  if (mode === 'relative-growth') {
    return v.relativeGrowth;
  }

  return assertNever(mode);
};

export const toAbsoluteGrowth = (c: ICountWithTrend) => c.count - c.lastCount;
