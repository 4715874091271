import { delay, throttle } from 'lodash/fp';
import React, { Component } from 'react';
import { FormSpy } from 'react-final-form';

const INITIAL_BACKOFF_MS = 100;

const promiseDelay = delayMs => new Promise(delay(delayMs));

class AutoSaver extends Component {
  backoff = INITIAL_BACKOFF_MS;

  promise = Promise.resolve();

  componentDidUpdate = prevProps => {
    if (prevProps.values !== this.props.values || this.props.submitFailed) {
      this.save();
    }
  };

  save = throttle(500, async () => {
    await this.promise;
    const { dirty, submitFailed, form } = this.props;
    if (dirty && submitFailed) {
      this.promise = promiseDelay(this.backoff).then(form.submit);
      this.backoff = this.backoff * 2;
      return;
    }
    this.backoff = INITIAL_BACKOFF_MS;
    this.promise = dirty ? form.submit() : Promise.resolve();
  });

  render = () => null;
}

export const FormAutoSave = () => (
  <FormSpy
    subscription={{ dirty: true, submitFailed: true, values: true }}
    component={AutoSaver}
  />
);
