import isPropValid from '@emotion/is-prop-valid';
import { Box, ButtonBase, Popover } from '@material-ui/core';
import React from 'react';
import { styled } from '../../emotion';
import { Color, DEFAULT_COLORS, getColorDef } from '../../domainTypes/colors';

type Size = 'small' | 'medium' | 'input';

type Props = {
  value: string;
  onChange: (nextValue: Color) => void;
  size?: Size;
  colorSet?: Color[];
  noMargin?: boolean;
};

const DEFAULT_SIZE = 'medium';

export const Swatch = styled<
  typeof ButtonBase,
  { colorName: Color; size: Size; noMargin?: boolean }
>(ButtonBase, {
  shouldForwardProp: (props) => isPropValid(props)
})`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 100px !important;
  margin: ${(p) => (p.noMargin ? 0 : p.theme.spacing(1))}px;
  background-color: ${(p) => getColorDef(p.colorName).bgColor} !important;
  color: ${(p) => getColorDef(p.colorName).color};

  &:hover {
    box-shadow: 0 1px 5px 2px ${(p) => p.theme.palette.grey[200]};
  }
`;

export const ColorPicker = ({
  value = 'red',
  onChange,
  size = DEFAULT_SIZE,
  colorSet = DEFAULT_COLORS
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Swatch
        type="button"
        size={size}
        colorName={value as Color}
        onClick={handleClick}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        elevation={8}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box p={2} style={{ maxWidth: '280px' }}>
          {colorSet.map((cs) => (
            <Swatch
              type="button"
              size="medium"
              key={cs}
              style={{ marginRight: '2px' }}
              colorName={cs}
              onClick={() => {
                onChange(cs);
                handleClose();
              }}
            />
          ))}
        </Box>
      </Popover>
    </div>
  );
};
