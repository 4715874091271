import {
  Button,
  ButtonBase,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { SelectProps } from '@material-ui/core/Select/Select';
import Confetti from 'canvas-confetti';
import { compact, first, trim, truncate, uniq, uniqBy } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  Check,
  Copy,
  HelpCircle,
  Layout as Table,
  Maximize2,
  Minimize2
} from 'react-feather';
import {
  GeneratedLink,
  LinkGeneratorMessage,
  ICreateLinkOptions,
  LinkGeneratorWarning,
  LinkParameter
} from '../../../../domainTypes/linkGenerator';
import { ISpace } from '../../../../domainTypes/space';
import { PlatformWithColor } from './components';
import { LinkParametersForm } from './components/LinkParametersForm';
import { RatesBox } from './components/RatesBox';
import {
  PARTNERS,
  refreshTimestamp,
  styled,
  useLinkShortener,
  useChannels,
  useFeatureEnabled,
  useLinkCreator,
  useLocalJsonStorage,
  withStoppedPropagation
} from './service';

const COPY_TRANSITION = 2500;
const LOCAL_STORAGE_MEMORY_DURATION = 1000 * 60 * 30; // 30 minutes

const OuterWrapper = styled('div')`
  position: relative;
  padding: ${({ theme }) => theme.spacing(2)}px 0 0
    ${({ theme }) => theme.spacing(2)}px;
`;

const FlexContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

const Step = ({
  count,
  label,
  isActive,
  isComplete
}: {
  count: number;
  label: string;
  isActive: boolean;
  isComplete: boolean;
}) => (
  <Typography
    variant="caption"
    color={isActive ? 'textPrimary' : 'textSecondary'}
    style={{
      display: 'flex',
      alignItems: 'center',
      marginTop: '12px',
      marginBottom: '24px'
    }}
  >
    <StepNumber isActive={isActive}>
      {isComplete ? (
        <Check
          size={16}
          style={{ position: 'relative', top: '3px', left: '-4px' }}
        />
      ) : (
        count
      )}
    </StepNumber>
    <span style={{ whiteSpace: 'nowrap', marginRight: '8px' }}>{label}</span>
    <Divider />
  </Typography>
);

const StepNumber = styled<'span', { isActive: boolean }>('span')`
  display: inline-block;
  border-radius: 100%;
  padding: 1px 8px;
  height: 24px;
  width: 24px;
  align-items: center;
  background-color: ${(p) =>
    p.isActive ? p.theme.palette.primary.main : p.theme.palette.grey[600]};
  color: white;
  margin-right: 8px;
  font-weight: bold;
`;

const Divider = styled('hr')`
  display: inline-block;
  width: 100%;
  height: 1px;
  color: ${({ theme }) => theme.palette.grey[200]};
  border: 1px solid;
`;

const SelectDense = styled(Select)<SelectProps>(({ theme }) => ({
  padding: 0
}));

const LinearLoader = styled(LinearProgress)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
`;

const InnerWrapper = styled('div')`
  overflow-y: scroll;
  position: relative;
  padding-top: 6px;
  padding-right: ${({ theme }) => theme.spacing(2)}px;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const ConnectionId = styled('div')`
  color: #616161;
  background-color: #eee;
  font-size: 10px;
  line-height: 18px;
  padding: 0 5px;
  border-radius: 5px;
  overflow: hidden;
  white-space: nowrap;
  height: 19px;
  max-width: 100px;
  text-overflow: ellipsis;
  font-family: Menlo, Consolas, Courier;
`;

const ConnectionMenuItem = styled('div')`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

const LinkConfiguration = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  grid-column-gap: ${(p) => p.theme.spacing(1)}px;
  grid-row-gap: ${(p) => p.theme.spacing(1)}px;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const LinkResults = styled('div')`
  padding-top: ${(p) => p.theme.spacing(3)}px;
`;

const SubmitButtonRow = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(p) => p.theme.spacing(1)}px;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: ${(p) => p.theme.spacing(2)}px;
  background-color: #fff;
`;

const SubmitButtonRowGradient = styled('div')`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: calc(100% - 16px); /* Adjust based on the size you need */
  height: 24px; /* Adjust based on the size you need */
  background: linear-gradient(to top, #eee, transparent);
`;

const Canvas = styled('canvas')`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

type LinkGeneratorCache = Record<string, LinkGeneratorCacheItem>;

type LinkGeneratorCacheItem = {
  links: GeneratedLink[];
  parameters: Array<LinkParameter>;
  selectedLink: GeneratedLink | null;
  generatedAt: string;
};

const DEFAULT_PARAMETERS: Array<LinkParameter> = [
  {
    key: 'channel',
    value: ''
  },
  {
    key: 'subid',
    value: ''
  }
];

export const LinkGenerator = ({
  prefilledUrl,
  height,
  space
}: {
  prefilledUrl: string;
  height?: number;
  space: ISpace;
}) => {
  const createLink = useLinkCreator();
  const [url, setUrl] = useState(prefilledUrl);
  const [loading, setLoading] = useState(false);
  const [showRates, setShowRates] = useState(false);
  const [errors, setErrors] = useState<LinkGeneratorMessage[]>([]);
  const [links, setLinks] = useState<GeneratedLink[] | null>(null);
  const [selectedLink, setSelectedLink] = useState<GeneratedLink | null>(null);
  const [channels = []] = useChannels(space.id);
  const [loadingShortenedLink, setLoadingShortenedLink] = useState(false);
  const [shortenedLink, setShortenedLink] = useState<string | null>(null);
  const shortenLink = useLinkShortener();
  const hasLinkWrapper = useFeatureEnabled('LINK_WRAPPER');

  // We store a cache of link parameters in local storage,
  // so the chrome extension can re-open on a page where a link
  // was previously generated and preserve that info.
  // If you change the structure of the local storage, be sure
  // to increment the name of the key, so it doesn't break
  const [storage, setLocalStorage] = useLocalJsonStorage<LinkGeneratorCache>(
    'affilimate:linkgenerator:v1',
    {}
  );

  const setSelectedLinkAndUpdateCache = (link: GeneratedLink | null) => {
    setSelectedLink(link);
    setLocalStorage({
      ...storage,
      [url]: {
        ...storage[url],
        selectedLink: link
      }
    });
  };

  useEffect(() => {
    // If we have something in local storage for this, and it's
    // from within the last 30 minutes, use it.
    if (!url) {
      return;
    }
    const generatedAt = storage[url]?.generatedAt;
    if (
      generatedAt &&
      new Date().getTime() - new Date(generatedAt).getTime() <
        LOCAL_STORAGE_MEMORY_DURATION
    ) {
      setLinks(storage[url].links);
      setSelectedLink(storage[url].selectedLink);
      setParameters(storage[url].parameters || DEFAULT_PARAMETERS);

      setTimeout(() => {
        // And even scroll to the generated link automatically
        if (scrollTargetRef.current) {
          scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const canvasEl = useRef<HTMLCanvasElement>(null);
  const scrollTargetRef = useRef<HTMLDivElement>(null);
  const [useWrappedLink, setUseWrappedLink] = useState(false);
  const [wrappedLinkRequired, setWrappedLinkRequired] = useState(false);
  const [parameters, setParameters] = useState<Array<LinkParameter>>(
    DEFAULT_PARAMETERS
  );

  const isGenerateLinksButtonDisabled = useMemo(() => {
    // Eventually add validation that if they have
    // channels enabled, they need to pick one before generating the link
    return loading || url.length < 'https://'.length;
  }, [loading, url]);

  useEffect(() => {
    // key? needed to handle errant clicks on option groups
    if (
      parameters.find(
        (p) => p.key?.includes('utm_') || p.key?.startsWith('click_data')
      )
    ) {
      setWrappedLinkRequired(true);
      setUseWrappedLink(true);
    } else {
      setWrappedLinkRequired(false);
    }
  }, [parameters]);

  const affiliateLink = useMemo(() => {
    if (!selectedLink) {
      return '';
    }
    if (!useWrappedLink || !selectedLink?.wrappedLink) {
      return selectedLink?.link;
    }

    const u = new URL(selectedLink.wrappedLink);

    const utmSource = parameters.find((p) => p.key === 'utm_source');
    const utmMedium = parameters.find((p) => p.key === 'utm_medium');
    const utmCampaign = parameters.find((p) => p.key === 'utm_campaign');
    const clickData01 = parameters.find((p) => p.key === 'click_data_01');
    const clickData02 = parameters.find((p) => p.key === 'click_data_02');
    const channel = parameters.find((p) => p.key === 'channel');

    if (channel) {
      const channelData = channels.find((c) => c.id === channel.value);
      if (channelData) {
        u.searchParams.set('channel', channelData.data.channelId);
      }
    }

    if (clickData01?.value) {
      u.searchParams.set('click_data_01', clickData01.value);
    }
    if (clickData02?.value) {
      u.searchParams.set('click_data_02', clickData02.value);
    }
    if (utmSource?.value) {
      u.searchParams.set('utm_source', utmSource.value);
    }
    if (utmMedium?.value) {
      u.searchParams.set('utm_medium', utmMedium.value);
    }
    if (utmCampaign?.value) {
      u.searchParams.set('utm_campaign', utmCampaign.value);
    }

    return u.href;
  }, [selectedLink, parameters, useWrappedLink, channels]);

  const [availableConnections, setAvailableConnections] = useState<
    {
      connection: string;
      connectionId: string;
      connectionNickname: string;
      partnerKey: string;
    }[]
  >([]);

  const handleShortenLink = async () => {
    setLoadingShortenedLink(true);
    await shortenLink({
      spaceId: space.id,
      url: affiliateLink
    })
      .then((response) => {
        setShortenedLink(response.shortUrl);
      })
      .finally(() => {
        setLoadingShortenedLink(false);
      });
  };

  const availableAdvertisers = useMemo(() => {
    if (!links) {
      return [];
    }
    return uniqBy(
      links
        .filter(
          (l) => selectedLink && l.connectionId === selectedLink.connectionId
        )
        .map((l) => ({
          name: l.advertiser,
          id: l.advertiserId,
          data: l.advertiserDetails
        })),
      (l) => l.id
    );
  }, [links, selectedLink]);

  const availableChannels = useMemo(() => {
    if (!links) {
      return [];
    }
    return compact(
      uniq(
        links
          .filter(
            (l) => selectedLink && l.connectionId === selectedLink.connectionId
          )
          .map((l) => l.channel)
      )
    );
  }, [links, selectedLink]);

  useEffect(() => {
    if (!links) {
      return;
    }
    const connections = uniqBy(
      links.map((l) => ({
        connection: l.connection,
        partnerKey: l.partnerKey,
        connectionId: l.connectionId,
        connectionNickname: l.connectionNickname
      })),
      (c) => c.connectionId
    );
    setAvailableConnections(connections);
  }, [links]);

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    return generateLink();
  };

  const generateLink = async (options?: ICreateLinkOptions) => {
    const u = trim(url);

    try {
      new URL(u);
      if (u.indexOf('.') === -1 || u.endsWith('.')) {
        throw Error('TLD wrong');
      }
    } catch (err) {
      setErrors([
        {
          type: LinkGeneratorWarning.LinkFormatError,
          message: ''
        }
      ]);
      return;
    }

    setLoading(true);
    setErrors([]);

    try {
      const subid = parameters.find((p) => p.key === 'subid')?.value || '';

      // If necessary, append the channel prefix to the subid
      const channelId =
        parameters.find((p) => p.key === 'channel')?.value || '';
      const subidDefaultPrefix =
        (channels || []).find((c) => c.id === channelId)?.data
          .subIdDefaultPrefix ||
        space.domains.find((d) => d.channelId === channelId)
          ?.subIdDefaultPrefix;
      const subidWithChannel = subidDefaultPrefix
        ? subidDefaultPrefix + subid
        : subid;

      const { links: newLinks, messages } = await createLink(
        u,
        subidWithChannel,
        space.id,
        options
      );

      if (newLinks && newLinks[0]) {
        const newLinksMapped = newLinks.map((l) => {
          if (!l.advertiserDetails) {
            return l;
          }
          return {
            ...l,
            advertiserDetails: {
              ...l.advertiserDetails,
              lastUpdated: refreshTimestamp(l.advertiserDetails.lastUpdated)
            }
          };
        });
        const newLinksSorted = newLinksMapped.sort((a, b) => {
          // If no rates provided, then fall back to alphabetical
          if (!a.advertiserDetails || !b.advertiserDetails) {
            return a.advertiser.localeCompare(b.advertiser);
          }
          // Handle if the maxRate is zero, sort it to the bottom
          const aMaxRate = a.advertiserDetails.maxRatePercent || 0;
          const bMaxRate = b.advertiserDetails.maxRatePercent || 0;
          return bMaxRate - aMaxRate;
        });
        setLinks(newLinksSorted);
        setSelectedLink(first(newLinksSorted) || null);

        // Get the current storage, and filter out anything older
        const blankStorage: LinkGeneratorCache = {};
        const newStorage: LinkGeneratorCache = Object.keys(storage).reduce(
          (acc, key) => {
            const generatedAt = storage[key].generatedAt;
            if (
              generatedAt &&
              new Date().getTime() - new Date(generatedAt).getTime() <
                LOCAL_STORAGE_MEMORY_DURATION
            ) {
              acc[key] = storage[key];
            }
            return acc;
          },
          blankStorage
        );

        newStorage[u] = {
          links: newLinksSorted,
          selectedLink: first(newLinksSorted) || null,
          generatedAt: new Date().toISOString(),
          parameters: parameters
        };
        setLocalStorage(newStorage);

        if (scrollTargetRef.current) {
          scrollTargetRef.current.scrollIntoView({ behavior: 'smooth' });
        } else {
          console.log('no ref');
        }
      } else {
        setLinks([]);
        setSelectedLinkAndUpdateCache(null);
        setErrors(messages);
      }
    } catch (err) {
      console.log('ERROR', err);
    }
    setLoading(false);
  };

  const [isShowingConfetti, setIsShowingConfetti] = useState(false);

  const triggerConfetti = () => {
    if (!canvasEl.current) {
      return;
    }

    const canvas = canvasEl.current;
    canvas.setAttribute('style', 'z-index: 1');

    const confetti = Confetti.create(canvas, {
      resize: true,
      useWorker: true
    });

    confetti({
      spread: 110,
      particleCount: prefilledUrl ? 60 : 30,
      startVelocity: 50,
      gravity: 0.4
    });

    setIsShowingConfetti(true);

    setTimeout(() => {
      canvas.setAttribute('style', 'z-index: -1');
      setIsShowingConfetti(false);
    }, COPY_TRANSITION);
  };

  return (
    <div>
      {loading && <LinearLoader />}
      <OuterWrapper>
        <form onSubmit={submitForm}>
          {selectedLink !== null &&
            links !== null &&
            url.startsWith('https://') && <SubmitButtonRowGradient />}
          <InnerWrapper
            style={{
              maxHeight: height ?? `${height}px`,
              height: height ?? `${height}px`
            }}
          >
            <Step
              isActive={url.length < 5 || !url.startsWith('https://')}
              isComplete={url.startsWith('https://') && url.includes('.')}
              count={1}
              label="Start with a clean landing page URL"
            />
            <FormControl style={{ width: '100%' }}>
              <TextField
                type="url"
                value={url}
                multiline
                placeholder="https://www.website.com/product/landing-page"
                label="Deep link"
                disabled={loading}
                fullWidth
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !loading && !e.shiftKey) {
                    e.preventDefault();
                    generateLink();
                  }
                }}
                autoFocus={!prefilledUrl}
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
                variant="outlined"
                helperText={
                  url.length && !url.startsWith('https')
                    ? 'Your deep link must start with https://'
                    : undefined
                }
              />
            </FormControl>
            {url.includes('?') && (
              <Button
                size="small"
                style={{
                  padding: 0,
                  marginTop: '2px',
                  opacity: 0.6,
                  float: 'right'
                }}
                onClick={() => {
                  let cleanLink = url.split('?')[0];
                  if (url.includes('amazon')) {
                    // Also clean off the /ref part of the link
                    cleanLink = cleanLink.split('/ref')[0];
                  }
                  setUrl(cleanLink);
                }}
              >
                Clean out link parameters?
              </Button>
            )}
            <br />
            <br />
            <Step
              isActive={url.startsWith('https://') && !selectedLink}
              isComplete={selectedLink !== null}
              count={2}
              label="Then, customize your link"
            />
            <LinkParametersForm
              loading={loading}
              parameters={parameters}
              setParameters={setParameters}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '16px'
              }}
            >
              {hasLinkWrapper ? (
                <FormControlLabel
                  label={
                    <Tooltip
                      title={
                        wrappedLinkRequired
                          ? 'Only offsite links can capture custom custom dimensions or UTM parameters directly within the link.'
                          : 'Offsite links let you track clicks and earnings from offsite channels, like email and social.'
                      }
                      placement="top"
                    >
                      <Typography variant="caption">
                        Create offsite links{' '}
                        <ButtonBase
                          style={{
                            position: 'relative',
                            top: '-2px',
                            left: '2px'
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <HelpCircle size={16} />
                        </ButtonBase>
                      </Typography>
                    </Tooltip>
                  }
                  control={
                    <Checkbox
                      checked={useWrappedLink}
                      disabled={wrappedLinkRequired}
                      onChange={(e) => {
                        setUseWrappedLink(e.target.checked);
                      }}
                      color="primary"
                    />
                  }
                />
              ) : (
                <div />
              )}
              <Button
                type="submit"
                color={selectedLink ? 'default' : 'primary'}
                variant="contained"
                disabled={isGenerateLinksButtonDisabled}
              >
                {selectedLink ? 'Regenerate links' : 'Generate links'}
              </Button>
            </div>
            <div style={{ maxWidth: '500px' }}>
              {errors.find(
                (e) => e.type === LinkGeneratorWarning.NoMatchingAdvertisers
              ) && (
                <div>
                  <strong>No matching advertiser found.</strong>
                  Please double-check this advertiser is active and allows
                  deeplinking.{' '}
                </div>
              )}
              {errors.find(
                (e) => e.type === LinkGeneratorWarning.LinkFormatError
              ) && (
                <div>
                  <strong>Check your link format.</strong> Make sure your link
                  is correctly formatted and starts with "https://"
                </div>
              )}
              {links?.length === 0 &&
                errors.find(
                  (e) =>
                    e.type === LinkGeneratorWarning.ErrorFetchingAdvertisers
                ) && (
                  <div>
                    <strong>
                      An error occurred pulling your advertiser data.{' '}
                    </strong>
                  </div>
                )}
              {links?.length === 0 &&
                errors.find(
                  (e) => e.type === LinkGeneratorWarning.ErrorCreatingLink
                ) && (
                  <div>
                    <strong>An unexpected error happened. </strong>
                  </div>
                )}
            </div>
            {selectedLink !== null &&
              links !== null &&
              url.startsWith('https://') && (
                <div
                  ref={scrollTargetRef}
                  style={{
                    opacity: loading ? 0.5 : 1,
                    pointerEvents: loading ? 'none' : 'initial'
                  }}
                >
                  <LinkResults>
                    <Step
                      isActive={!!selectedLink && url.length > 5}
                      isComplete={false}
                      count={3}
                      label="Now, pick the best link"
                    />
                    <LinkConfiguration>
                      <div>
                        <div>
                          <Typography
                            variant="body2"
                            component="p"
                            gutterBottom
                            color="textSecondary"
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <span>Integrations</span>
                            <Button
                              size="small"
                              variant="text"
                              style={{ padding: 0, opacity: 0.6 }}
                              onClick={() => {
                                setShowRates(!showRates);
                              }}
                            >
                              <Table size={14} style={{ marginRight: '6px' }} />{' '}
                              {showRates ? 'Hide rates' : 'Compare rates'}
                            </Button>
                          </Typography>
                          <Collapse in={showRates}>
                            <RatesBox
                              links={links}
                              onSelect={({ connectionId, advertiserId }) => {
                                const newSelectedLink =
                                  links.find(
                                    (l) =>
                                      l.connectionId === connectionId &&
                                      l.advertiserId === advertiserId
                                  ) || null;
                                setSelectedLinkAndUpdateCache(newSelectedLink);
                                setShowRates(false);
                              }}
                            />
                          </Collapse>

                          {!loading && links && (
                            <div style={{ opacity: 0.6 }}></div>
                          )}
                        </div>
                        <div>
                          <FormControl
                            variant="outlined"
                            style={{ width: '100%' }}
                          >
                            <SelectDense
                              value={selectedLink.connectionId}
                              onChange={(e) => {
                                const newSelectedLink =
                                  links.find(
                                    (l) =>
                                      l.connectionId ===
                                      (e.target.value as string)
                                  ) || null;
                                setSelectedLinkAndUpdateCache(newSelectedLink);
                              }}
                            >
                              {availableConnections.map((c) => {
                                const partner = PARTNERS.find(
                                  (p) => p.key === c.partnerKey
                                );

                                if (partner) {
                                  return (
                                    <MenuItem
                                      value={c.connectionId}
                                      key={c.connectionId}
                                    >
                                      <ConnectionMenuItem>
                                        <PlatformWithColor
                                          partner={partner}
                                          key={c.partnerKey}
                                        />
                                        {c.connectionNickname && (
                                          <Typography
                                            variant="caption"
                                            component="span"
                                            color="textSecondary"
                                          >
                                            {c.connectionNickname}
                                          </Typography>
                                        )}
                                      </ConnectionMenuItem>
                                    </MenuItem>
                                  );
                                } else {
                                  return (
                                    <MenuItem
                                      value={c.connectionId}
                                      key={c.connectionId}
                                    >
                                      {c.connection}({c.connectionId})
                                    </MenuItem>
                                  );
                                }
                              })}
                            </SelectDense>
                          </FormControl>
                        </div>
                      </div>
                      <div style={{ marginTop: '12px' }}>
                        <Typography
                          variant="body2"
                          component="p"
                          gutterBottom
                          color="textSecondary"
                        >
                          Advertisers
                        </Typography>
                        <FormControl
                          variant="outlined"
                          style={{ width: '100%' }}
                        >
                          <Select
                            value={selectedLink.advertiserId}
                            MenuProps={{ disableScrollLock: true }}
                            onChange={(e) => {
                              const newSelectedLink =
                                links.find(
                                  (l) =>
                                    l.advertiserId ===
                                      (e.target.value as string) &&
                                    l.connectionId ===
                                      selectedLink?.connectionId
                                ) || null;
                              setSelectedLinkAndUpdateCache(newSelectedLink);
                            }}
                          >
                            {availableAdvertisers.map((a) => (
                              <MenuItem value={a.id} key={a.id}>
                                <ConnectionMenuItem>
                                  <span>
                                    {truncate(a.name, { length: 35 })}
                                  </span>
                                  <ConnectionId>{a.id}</ConnectionId>
                                </ConnectionMenuItem>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div
                        style={{
                          marginTop: '12px',
                          display:
                            availableChannels.length === 0 ? 'none' : 'block'
                        }}
                      >
                        <Typography
                          variant="body2"
                          component="p"
                          gutterBottom
                          color="textSecondary"
                        >
                          Channels
                        </Typography>
                        <FormControl
                          component="fieldset"
                          style={{
                            width: '100%',
                            maxHeight: '150px',
                            overflowY: 'auto',
                            overflowX: 'hidden'
                          }}
                        >
                          <RadioGroup
                            aria-label="channel"
                            name="channel"
                            value={selectedLink.channel}
                            onChange={(e) => {
                              const newSelectedLink =
                                links.find(
                                  (l) =>
                                    l.channel === (e.target.value as string) &&
                                    l.connectionId ===
                                      selectedLink?.connectionId
                                ) || null;

                              setSelectedLinkAndUpdateCache(newSelectedLink);
                            }}
                          >
                            {availableChannels.map((c) => {
                              const channelId = links.find(
                                (l) =>
                                  l.channel === c &&
                                  l.connectionId === selectedLink.connectionId
                              )?.channelId;

                              const channelName =
                                channels.find((ch) => c === ch.data.channelId)
                                  ?.data.name || c;

                              return (
                                <FormControlLabel
                                  value={c}
                                  control={<Radio color="primary" />}
                                  label={
                                    c !== channelId ? (
                                      <FlexContainer>
                                        <>
                                          {channelName}{' '}
                                          <ConnectionId>
                                            {channelId}
                                          </ConnectionId>
                                        </>
                                      </FlexContainer>
                                    ) : (
                                      c
                                    )
                                  }
                                  key={c}
                                />
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </LinkConfiguration>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '6px'
                      }}
                    >
                      <Typography
                        variant="body2"
                        component="p"
                        color="textSecondary"
                        gutterBottom
                      >
                        Your affiliate link
                      </Typography>
                      {hasLinkWrapper && useWrappedLink && (
                        <Button
                          size="small"
                          variant="text"
                          style={{ padding: 0, opacity: 0.6 }}
                          disabled={loadingShortenedLink}
                          onClick={() => {
                            if (shortenedLink) {
                              setShortenedLink(null);
                            } else {
                              handleShortenLink();
                            }
                          }}
                        >
                          {loadingShortenedLink ? (
                            <>
                              <CircularProgress size={14} />
                              &nbsp; Shortening...
                            </>
                          ) : shortenedLink ? (
                            <>
                              <Maximize2 size={14} />
                              &nbsp; Use long link
                            </>
                          ) : (
                            <>
                              <Minimize2 size={14} />
                              &nbsp; Shorten link
                            </>
                          )}
                        </Button>
                      )}
                    </div>
                    <TextField
                      value={shortenedLink || affiliateLink}
                      variant="outlined"
                      multiline
                      placeholder="https://"
                      fullWidth
                    />
                  </LinkResults>
                </div>
              )}
          </InnerWrapper>
        </form>
      </OuterWrapper>
      {selectedLink !== null && links !== null && url.startsWith('https://') && (
        <div
          style={{
            pointerEvents: loading ? 'none' : 'initial'
          }}
        >
          <SubmitButtonRow>
            <div>
              <Typography
                variant="caption"
                color="textSecondary"
                style={{
                  fontSize: '11px',
                  lineHeight: '17px'
                }}
              >
                Always use the auto-copied link, not the tested URL from your
                browser bar &nbsp;
                <Tooltip
                  title="Already clicked affiliate links may not be credited again when a visitor clicks them. Always use the original, auto-copied link and do not re-copy the tested URL from your browser bar."
                  placement="top"
                >
                  <HelpCircle
                    style={{ position: 'relative', top: '1px' }}
                    size={11}
                  />
                </Tooltip>
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                opacity: loading ? 0.5 : 1
              }}
            >
              <div style={{ position: 'relative' }}>
                <CopyToClipboard text={shortenedLink || affiliateLink}>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{
                      width: '160px',
                      paddingLeft: 0,
                      paddingRight: 0
                    }}
                    disabled={isShowingConfetti}
                    onClick={withStoppedPropagation(() => {
                      triggerConfetti();
                      setTimeout(() => {
                        const width = 800; // Define the width of the window
                        const height = 300; // Define the height of the window
                        // Calculate the position to open the window at the center of the screen
                        const left = window.screen.width / 2 - width / 2;
                        const top = window.screen.height / 2 - height / 2;

                        // Features of the window to be opened
                        const features = `width=${width},height=${height},left=${left},top=${top}`;

                        // Open the new window
                        window.open(
                          shortenedLink || affiliateLink,
                          '_blank',
                          features
                        );
                      }, COPY_TRANSITION);
                    })}
                  >
                    {isShowingConfetti ? (
                      'Copied! Now testing...'
                    ) : (
                      <>
                        <Copy size={16} />
                        &nbsp; Copy & test link
                      </>
                    )}
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
            <Canvas ref={canvasEl} />
          </SubmitButtonRow>
        </div>
      )}
    </div>
  );
};
