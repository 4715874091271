import { Dimension } from '../../../components/analytics_v2/Filters/filters';
import { useFeatureEnabled } from '../../../services/features';
import { useMemo } from 'react';
import { useClickDimensionNames } from '../../../services/customDimensions';

const baseDimensions: Dimension[] = ['tag', 'channel', 'device', 'platform'];
const referrersDimensions: Dimension[] = [
  'referrer',
  'utm_content',
  'utm_campaign',
  'utm_medium',
  'utm_source',
  'utm_term'
];

export const useContentPagesFilterDimensions = (): Dimension[] => {
  const hasReferrerReports = useFeatureEnabled('REFERRER_REPORTS_V1');
  // TODO support page dimensions once we have filters for these
  const clickDimensions = useClickDimensionNames();
  return useMemo(
    () =>
      hasReferrerReports
        ? [...baseDimensions, ...referrersDimensions, ...clickDimensions]
        : baseDimensions,
    [clickDimensions, hasReferrerReports]
  );
};
