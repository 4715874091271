import { compact } from 'lodash';
import { useMemo } from 'react';
import { useTimeframe } from '../../../../../../components/analytics_v2/Timeframe';
import {
  AnalyticsFilter,
  AnalyticsOrderBy,
  AnalyticsQuery
} from '../../../../../../domainTypes/analytics_v2';
import { ReferrerGroup } from '../../../../../../domainTypes/referrers';
import { Metric } from '../../../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../../../services/currentUser';
import { useMappedLoadingValue } from '../../../../../../services/db';
import { useDomains } from '../../../../../../services/domains';
import { groupFilterClause } from '../../../../../../services/referrers-query';
import { useFilters } from './filters';

export const PAGE_SIZE = 10;

export const useReferrerOriginExportQuery = (
  group: ReferrerGroup,
  metrics: Metric[],
  search: AnalyticsFilter | null,
  orderBy: AnalyticsOrderBy
): AnalyticsQuery => {
  const domains = useDomains();
  const { range } = useTimeframe();
  const filters = useFilters();
  return useMemo<AnalyticsQuery>(
    () => ({
      select: metrics,
      range,
      groupBy: ['referrer_entry_origin'],
      orderBy: [orderBy],
      filters: compact([
        ...filters,
        search,
        ...groupFilterClause(group, domains)
      ])
    }),
    [domains, filters, group, metrics, orderBy, range, search]
  );
};

export const useReferrerOriginTotals = (
  group: ReferrerGroup,
  metrics: Metric[],
  search: AnalyticsFilter | null,
  paginate: AnalyticsQuery['paginate'],
  orderBy: AnalyticsOrderBy
) => {
  const { space } = useCurrentUser();
  const domains = useDomains();
  const { range, compare } = useTimeframe();
  const filters = useFilters();

  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: metrics,
      range,
      compare,
      groupBy: ['referrer_entry_origin'],
      orderBy: [orderBy],
      paginate,
      filters: compact([
        ...filters,
        search,
        ...groupFilterClause(group, domains)
      ])
    };
  }, [
    compare,
    domains,
    group,
    metrics,
    orderBy,
    paginate,
    range,
    search,
    filters
  ]);

  return useAnalyticsQueryV2(space.id, query);
};

export const useReferrerOriginCount = (
  group: ReferrerGroup,
  search: AnalyticsFilter | null
) => {
  const { space } = useCurrentUser();
  const domains = useDomains();
  const { range } = useTimeframe();
  const filters = useFilters();

  const query = useMemo<AnalyticsQuery>(() => {
    return {
      select: ['count_uniq_referrer_entry_origin'],
      range,
      filters: compact([
        ...filters,
        {
          field: 'page_view_id',
          condition: 'not ilike',
          pattern: '%external%'
        },
        search,
        ...groupFilterClause(group, domains)
      ])
    };
  }, [domains, group, range, search, filters]);

  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (data) => data.rows[0]?.data.count_uniq_referrer_entry_origin?.curr ?? 0
  );
};
