import { Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { LimitedWidth } from '../../../../layout/PageBody';
import { Section } from '../../../../layout/Section';
import { useRoutes } from '../../../../routes';
import { SettingsLayout } from '../../SettingsLayout';
import { styled } from '../../../../emotion';
import { BookOpen } from 'react-feather';
import { SectionSubheading } from '../../../../components/DetailsPage';
import { HeatmapSettings } from './HeatmapSettings';
import { RevisionSettings } from './RevisionSettings';
import { useCurrentUser } from '../../../../services/currentUser';
import { IDomain } from '../../../../domainTypes/space';

const StyledDocsIcon = styled(Link)`
  position: relative;
  top: 4px;
  right: -2px;
  color: ${(p) => p.theme.palette.primary.main};
  display: inline-block;
  margin: ${(p) => p.theme.spacing(1)}px;
`;

const PageSettingsContentContainer = () => {
  const { ROUTES } = useRoutes();
  const { space } = useCurrentUser();
  const activeDomains = space?.domains.filter((d) => d.active);
  const [currentSite] = useState<IDomain | null>(activeDomains?.[0] || null);

  if (!currentSite) {
    return <div>No site selected</div>;
  }

  return (
    <LimitedWidth width={900}>
      <Section
        style={{
          marginBottom: '12px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography
          variant="h6"
          component="span"
          style={{
            marginRight: '9px',
            position: 'relative',
            fontWeight: 'bold',
            top: '-2px'
          }}
        >
          Content tracking settings for <code>{currentSite?.channelId}</code>
          <StyledDocsIcon to={ROUTES.docs.knowledgeBase.url('tags')}>
            <BookOpen size={22} />
          </StyledDocsIcon>
        </Typography>
      </Section>
      <Section>
        <SectionSubheading>Revisions</SectionSubheading>
        <Paper>
          <RevisionSettings site={currentSite} />
        </Paper>
      </Section>
      <Section>
        <SectionSubheading>Heatmaps</SectionSubheading>
        <Paper>
          <HeatmapSettings />
        </Paper>
      </Section>
    </LimitedWidth>
  );
};

export const PageSettingsContent = () => {
  return (
    <SettingsLayout>
      <Helmet>
        <title>Content settings | Affilimate</title>
      </Helmet>
      <PageSettingsContentContainer />
    </SettingsLayout>
  );
};
