import { styled } from '../../emotion';

export const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  > * {
    margin-right: ${p => p.theme.spacing()}px;
  }

  > :last-child {
    margin-right: 0;
  }
`;
