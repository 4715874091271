import React from 'react';
import {
  Button as AriaButton,
  CalendarCell,
  CalendarGrid,
  Heading,
  RangeCalendar,
  RangeCalendarProps,
  SlotProps
} from 'react-aria-components';
import { CalendarDate, isToday } from '@internationalized/date';
import { FlexContainer } from '../../layout/Flex';
import { css, styled } from '../../emotion';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useCurrentUser } from '../../services/currentUser';

// NOTE: MUI has clicks, react-aria has presses, so we fake integration via classes :shrug:
const AriaIconButton: React.FC<SlotProps> = ({ slot, children }) => (
  <AriaButton slot={slot} className="MuiButtonBase-root MuiIconButton-root">
    <span className="MuiIconButton-label">{children}</span>
  </AriaButton>
);

type Variant = 'primary' | 'secondary';

const DateRangePickerContainer = styled('div')<{ variant: Variant }>`
  .react-aria-RangeCalendar {
    width: fit-content;
    max-width: 100%;
    color: ${(p) => p.theme.palette.text.primary};

    .react-aria-Heading {
      flex: 1;
      margin: 0;
      text-align: center;
      font-size: ${(p) => p.theme.typography.body1.fontSize};
    }

    & table {
      border-collapse: collapse;

      & td {
        padding: 2px 0;
      }
    }

    .react-aria-CalendarCell {
      width: 1.8rem;
      line-height: 1.72rem;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
      outline: none;
      forced-color-adjust: none;

      &[data-today] {
        font-weight: bold;
      }

      &:hover {
        background: ${(p) => p.theme.palette.grey[200]};
      }

      &[data-disabled] {
        color: ${(p) => p.theme.palette.grey[400]};
        pointer-events: none;
      }

      &[data-outside-month] {
        display: none;
      }

      &[data-pressed] {
        background: ${(p) => p.theme.palette.grey.A100};
      }

      &[data-focus-visible] {
        outline: 2px solid
          ${(p) =>
            p.variant === 'secondary'
              ? p.theme.custom.colors.pending.main
              : p.theme.palette.primary.main};
        outline-offset: -2px;
        color: ${(p) => p.theme.palette.grey[800]};
      }

      &[data-selected] {
        background: ${(p) =>
          p.variant === 'secondary'
            ? p.theme.custom.colors.pending.light
            : p.theme.custom.colors.primary.light};
        border-radius: 0;
        color: ${(p) => p.theme.palette.grey[800]};

        &[data-focus-visible] {
          outline-color: ${(p) => p.theme.palette.primary.contrastText};
          outline-offset: -3px;
        }
      }

      &[data-selection-start] {
        color: ${(p) => p.theme.palette.primary.contrastText};
        background: ${(p) =>
          p.variant === 'secondary'
            ? p.theme.custom.colors.pending.dark
            : p.theme.palette.primary.main};
        border-start-start-radius: 4px;
        border-end-start-radius: 4px;
      }

      &[data-selection-end] {
        color: ${(p) => p.theme.palette.primary.contrastText};
        background: ${(p) =>
          p.variant === 'secondary'
            ? p.theme.custom.colors.pending.dark
            : p.theme.palette.primary.main};
        border-start-end-radius: 4px;
        border-end-end-radius: 4px;
      }
    }
  }
`;

export const DateRangePicker: React.FC<
  Pick<
    RangeCalendarProps<CalendarDate>,
    | 'value'
    | 'onChange'
    | 'isReadOnly'
    | 'focusedValue'
    | 'onFocusChange'
    | 'minValue'
    | 'maxValue'
  > & { variant?: Variant }
> = ({
  value,
  onChange,
  isReadOnly,
  focusedValue,
  onFocusChange,
  minValue,
  maxValue,
  variant = 'primary'
}) => {
  const { tz } = useCurrentUser();
  return (
    <DateRangePickerContainer variant={variant}>
      <RangeCalendar
        value={value}
        onChange={onChange}
        isReadOnly={isReadOnly}
        visibleDuration={{ months: 2 }}
        pageBehavior="single"
        focusedValue={focusedValue}
        onFocusChange={onFocusChange}
        minValue={minValue}
        maxValue={maxValue}
      >
        <FlexContainer
          alignItems="center"
          className={css((t) => ({
            marginBottom: t.spacing(2)
          }))}
        >
          <AriaIconButton slot="previous">
            <ChevronLeft size={18} />
          </AriaIconButton>
          <Heading />
          <AriaIconButton slot="next">
            <ChevronRight size={18} />
          </AriaIconButton>
        </FlexContainer>
        <FlexContainer alignItems="flex-start" spacing={3}>
          <CalendarGrid>
            {(date) => (
              <CalendarCell
                date={date}
                data-today={isToday(date, tz) ? '' : undefined}
              />
            )}
          </CalendarGrid>
          <CalendarGrid offset={{ months: 1 }}>
            {(date) => (
              <CalendarCell
                date={date}
                data-today={isToday(date, tz) ? '' : undefined}
              />
            )}
          </CalendarGrid>
        </FlexContainer>
      </RangeCalendar>
    </DateRangePickerContainer>
  );
};
