import { toPercent } from '../../../../components/Number';
import {
  hasPageErrored,
  IProductScanResult,
  isPageDone,
  wasPageAborted,
  IProductScanTracker
} from '../../../../domainTypes/productScan';

interface ProgressSmall {
  percentCompleted: number;
  completed: number;
  errors: number;
  aborted: number;
  total: number;
}

interface Progress extends ProgressSmall {
  linksFound: number;
}

export const getProgress = (scan: IProductScanResult): Progress => {
  if (scan.status === 'INITIALISING') {
    return {
      percentCompleted: 0,
      completed: 0,
      errors: 0,
      aborted: 0,
      total: 0,
      linksFound: 0
    };
  }

  const total = scan.pages.length;
  const completed = scan.pages.filter(isPageDone).length;
  const errors = scan.pages.filter(hasPageErrored).length;
  const aborted = scan.pages.filter(wasPageAborted).length;
  const linksFound = scan.pages.reduce((m, p) => m + p.suggestionsFound, 0);

  return {
    percentCompleted: toPercent(completed, total),
    completed,
    errors,
    aborted,
    total,
    linksFound
  };
};

export const getProgressFromTracker = (
  tracker: IProductScanTracker
): ProgressSmall => {
  if (!tracker) {
    return {
      percentCompleted: 0,
      completed: 0,
      errors: 0,
      aborted: 0,
      total: 0
    };
  }

  if (tracker.status === 'INITIALISING') {
    return {
      percentCompleted: 0,
      completed: 0,
      errors: 0,
      aborted: 0,
      total: 0
    };
  }

  const statuses = Object.values(tracker.pages || {}).map(([x]) => x);

  const total = statuses.length;
  const completed = statuses.filter(x => x !== 'P').length;
  const errors = statuses.filter(x => x === 'E').length;
  const aborted = statuses.filter(x => x === 'A').length;

  return {
    percentCompleted: toPercent(completed, total),
    completed,
    errors,
    aborted,
    total
  };
};
