import React, { useState, useRef, useEffect } from 'react';
import { styled } from '../../../../emotion';
import Confetti from 'canvas-confetti';
import { Typography, Button } from '@material-ui/core';
import { Check } from 'react-feather';
import { useRoutes } from '../../../../routes';
import * as tracking from '../../../../tracking';
import { useCurrentUser } from '../../../../services/currentUser';
import { usePlans } from '../../service';

const SuccessCircle = styled('div')`
  margin: 0 auto ${(p) => p.theme.spacing(3)}px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #77cf7b;
  color: #ffffff;

  svg {
    position: relative;
    top: 2px;
    left: 4px;
  }
`;

const Wrapper = styled('div')`
  width: 600px;
  padding: ${(p) => p.theme.spacing(4)}px;
  text-align: center;

  a {
    border-bottom: 1px dashed;
  }
`;

const Canvas = styled('canvas')`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const CheckoutSuccess = ({ onClose }: { onClose: () => void }) => {
  const { goTo, ROUTES } = useRoutes();
  const [hideConfetti, setHideConfetti] = useState(false);
  const canvasEl = useRef<HTMLCanvasElement>(null);
  const currentUser = useCurrentUser();
  const plans = usePlans();

  const openBilling = () => {
    goTo(ROUTES.settings.billing.url());
    onClose();
  };

  useEffect(() => {
    if (
      !currentUser ||
      !plans ||
      !plans.length ||
      !currentUser.space.billing.activePlan
    ) {
      return;
    }

    const planName = currentUser.space.billing.activePlan;
    const plan = plans.find((p) => p.name === planName);

    tracking.sendOnboardingEvent({
      event: 'Onboarding: Payment',
      value: plan !== undefined ? plan.price * 100 : 0
    });
  }, [currentUser, plans]);

  useEffect(() => {
    if (!canvasEl.current) {
      return;
    }

    const confetti = Confetti.create(canvasEl.current, {
      resize: true,
      useWorker: true
    });

    confetti({
      spread: 180,
      particleCount: 150,
      startVelocity: 30,
      gravity: 0.2
    });

    setTimeout(() => {
      setHideConfetti(true);
    }, 4000);
  }, []);

  return (
    <Wrapper>
      <SuccessCircle>
        <Check size={66} />
      </SuccessCircle>
      <Typography
        variant="h5"
        component="h1"
        style={{ textAlign: 'center', marginBottom: '24px' }}
      >
        Welcome aboard!
      </Typography>
      <Typography variant="body1" component="p" color="textSecondary">
        Your payment was successful, and your Affilimate subscription is now
        active. We're glad you're here.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        style={{ marginTop: '18px', marginBottom: '18px' }}
        onClick={onClose}
      >
        Continue to Affilimate
      </Button>
      <br />
      <Button
        onClick={openBilling}
        color="default"
        size="small"
        style={{ opacity: 0.8 }}
      >
        Need an invoice? Go to Billing settings
      </Button>
      {!hideConfetti && <Canvas ref={canvasEl} />}
    </Wrapper>
  );
};
