import { ResponsiveContainer } from 'recharts';
import { Paper } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { CHART_HEIGHT } from './index';

interface RealtimeChartCardProps {
  chart: ReactNode;
  details: ReactNode;
}

export const RealtimeChartCard: React.FC<RealtimeChartCardProps> = ({
  chart,
  details
}) => (
  <Paper
    style={{
      padding: '24px 12px',
      display: 'grid',
      gridTemplateColumns: '4fr 1fr'
    }}
  >
    <ResponsiveContainer width="99%" height={CHART_HEIGHT}>
      {chart}
    </ResponsiveContainer>
    {details}
  </Paper>
);
