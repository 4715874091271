import { createAuthorsTagGroup } from '../../../../../domainTypes/tag';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../../services/currentUser';
import { Centered } from '../../../../../layout/Centered';
import { styled } from '../../../../../emotion';
import { Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { Lock } from 'react-feather';
import { SupportButton } from '../../../../../components/SupportButton';
import { HelpIcon } from '../../../../../components/HelpIcon';
import { useAdminOrImpersonatorClaim } from '../../../../../services/auth';
import { useFeatureEnabled } from '../../../../../services/features';
import { ContentPageBody } from '../../../components/ContentPageBody';
import { Helmet } from 'react-helmet';
import { ARTICLE_IDS, Beacon } from '../../../../../services/beacon';
import { NoPermissions } from '../../../../../components/NoPermissions';
import { PageContentTagGroupOverview } from './GroupOverview';

const Image = styled('img')`
  width: 700px;
`;

const Container = styled('div')`
  text-align: center;
`;

const HelpWrapper = styled('div')`
  margin-top: 24px;
`;

const Hero = () => {
  return (
    <Image
      src="/images/empty-states/author-reporting.png"
      alt="Author reporting"
    />
  );
};

const EmptyState = () => {
  return (
    <Centered height={680}>
      <Container>
        <Hero />
        <Typography variant="h5" style={{ marginTop: 20 }}>
          <Lock size={18} /> Upgrade to access author reporting
        </Typography>
        <Typography
          variant="body1"
          style={{ margin: '20px auto', width: '80%', maxWidth: '500px' }}
        >
          Benchmark performance between authors. See which authors are producing
          the highest revenue and EPC articles, and learn what sets them and
          their content apart.
        </Typography>
        <SupportButton
          label="Upgrade your account"
          size="large"
          subject="Request account upgrade"
          text="Hi team, I'd like to upgrade my account to access Authors reporting."
        />
        <HelpWrapper>
          <HelpIcon
            color="gray"
            onClick={() => {
              Beacon('article', ARTICLE_IDS.authors.overview);
            }}
          >
            Lear more about author reporting
          </HelpIcon>
        </HelpWrapper>
      </Container>
    </Centered>
  );
};

const AuthorsWrapper: FunctionComponent = ({ children }) => (
  <>
    <Helmet>
      <title>Authors | Affilimate</title>
    </Helmet>
    <ContentPageBody>{children}</ContentPageBody>
  </>
);

export const PageContentAuthorsOverview = () => {
  const { space } = useCurrentUser();
  const scopes = useCurrentUserScopes();
  const [canViewAutomaticTags] = useAdminOrImpersonatorClaim();
  const areAuthorTagsEnabled = useFeatureEnabled('AUTHOR_TAGS');
  const hasAccessToAuthorsReporting =
    canViewAutomaticTags || areAuthorTagsEnabled;
  const canViewTags = scopes.has('reports.tags.view');
  if (!canViewTags) {
    return (
      <AuthorsWrapper>
        <NoPermissions />
      </AuthorsWrapper>
    );
  }
  if (!hasAccessToAuthorsReporting) {
    return (
      <AuthorsWrapper>
        <EmptyState />
      </AuthorsWrapper>
    );
  }
  const authorsGroupId = createAuthorsTagGroup(space.id);
  return <PageContentTagGroupOverview groupId={authorsGroupId} />;
};
