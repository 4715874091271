import React, { useEffect } from 'react';
import { Loader } from '../../../../components/Loader';
import { Doc } from '../../../../domainTypes/document';
import {
  IProductScanResult,
  isScanDone
} from '../../../../domainTypes/productScan';
import { Typography } from '@material-ui/core';
import { useErrorLogger } from '../../../../hooks/useErrorLogger';
import { usePromise } from '../../../../hooks/usePromise';
import { Timestamp } from '../../../../services/time';
import { Importer } from '../../../Scan/components/Importer';
import { ScanTracker } from '../../../Scan/components/ScanTracker';
import {
  getTrash,
  partitionSuggestions,
  rescanPages,
  useSuggestionGroups
} from '../../../Scan/services';
import { CreateProductArgs } from '../../../../domainTypes/product';

type ObserverProps = {
  userId: string;
  scan: Doc<IProductScanResult>;
  onImport?: (args: CreateProductArgs[], ts: Timestamp) => Promise<any>;
};

export const ScanObserver = ({ userId, scan, onImport }: ObserverProps) => {
  useEffect(
    () => {
      if (scan.data.speed) {
        console.log('Scan speed: ', scan.data.speed, scan.data.notes);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [scan.data.speed]
  );

  return (
    <>
      <ScanTracker scanDoc={scan} onRescan={rescanPages} />
      {isScanDone(scan.data) && (
        <Results
          spaceId={scan.data.spaceId}
          userId={userId}
          scanId={scan.id}
          status={scan.data.status}
          onImport={onImport}
        />
      )}
    </>
  );
};

const Results = ({
  spaceId,
  userId,
  scanId,
  status,
  onImport
}: {
  spaceId: string;
  userId: string;
  scanId: string;
  status: IProductScanResult['status'];
  onImport?: (args: CreateProductArgs[], ts: Timestamp) => Promise<any>;
}) => {
  const [suggestionGroups, loading, error] = useSuggestionGroups(
    spaceId,
    scanId
  );
  const [trash, loadingTrash] = usePromise(() => getTrash(spaceId), [spaceId]);

  useErrorLogger(error);

  if (loading || loadingTrash) {
    return <Loader height="300px" />;
  }

  const groups = suggestionGroups || [];

  if (!groups.length) {
    if (status === 'ERROR') {
      <Typography variant="body1" component="p">
        <strong>Couldn't find your affiliate links.</strong> We weren't able to
        find affiliate links on your site due to an error. Please check the
        error message above for details.
      </Typography>;
    } else {
      return (
        <Typography variant="body1" component="p">
          <strong>No new affiliate links found during this scan.</strong> Either
          you've already imported all your links, or your website or sitemap is
          currently cached at the plugin or CDN level.
        </Typography>
      );
    }
  }

  const partitioned = partitionSuggestions(groups, trash || {});
  return (
    <Importer
      {...partitioned}
      spaceId={spaceId}
      userId={userId}
      onImport={onImport}
    />
  );
};
