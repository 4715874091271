import firebase from 'firebase/app';
import { last } from 'lodash';
import moment from 'moment-timezone';
import { DAY_FORMAT, Timeframe } from '../../domainTypes/analytics';
import { IPageRevision } from '../../domainTypes/page';
import { ISpace } from '../../domainTypes/space';
import { compareTimestamps, isBefore, toMoment } from '../time';

export const compareRevisions = (order: 'asc' | 'desc') => (
  a: IPageRevision,
  b: IPageRevision
) => {
  const first = order === 'asc' ? a : b;
  const second = order === 'asc' ? b : a;
  return compareTimestamps(first.lastModifiedAt, second.lastModifiedAt);
};

export const getLatestRevision = (revisions: IPageRevision[]) => {
  return last([...revisions].sort(compareRevisions('asc'))) || null;
};

export const isLatestRevision = (
  revision: IPageRevision | null,
  otherRevisions: IPageRevision[]
) => {
  const latestRevision = getLatestRevision(otherRevisions);
  return latestRevision === revision;
};

export const isSameRevision = (
  a: IPageRevision | null,
  b: IPageRevision | null
) => {
  if (a === null && b === null) {
    return true;
  }
  if (a === null || b === null) {
    return false;
  }
  return a.lastModifiedAt.isEqual(b.lastModifiedAt);
};

const isAfterNoon = (ts: firebase.firestore.Timestamp, tz: string) => {
  return toMoment(ts).tz(tz).hour() >= 12;
};

export const toStart = (
  d: firebase.firestore.Timestamp,
  tz: string,
  format: string
) => {
  return toMoment(d)
    .tz(tz)
    .startOf('day')
    .add(isAfterNoon(d, tz) ? 1 : 0, 'd')
    .format(format);
};

export const toEnd = (
  d: firebase.firestore.Timestamp | null,
  tz: string,
  format: string
) => {
  return d
    ? toMoment(d)
        .tz(tz)
        .endOf('day')
        .add(isAfterNoon(d, tz) ? 0 : -1, 'd')
        .format(format)
    : moment().tz(tz).startOf('day').format(format);
};

export const getTimeBoundaries = (
  revision: IPageRevision,
  otherRevisions: IPageRevision[]
) => {
  // handle ignored
  const sortedRevisions = [...otherRevisions].sort(compareRevisions('asc'));
  const revisionsToLookAt = revision.ignore
    ? sortedRevisions
    : sortedRevisions.filter((r) => !r.ignore);
  const index = revisionsToLookAt.indexOf(revision);
  const isLast = index === revisionsToLookAt.length - 1;
  const start = revision.lastModifiedAt;
  const end = isLast ? null : revisionsToLookAt[index + 1].lastModifiedAt;

  return { start, end };
};

export const getTimeBoundariesWithComparison = (
  revision: IPageRevision,
  revisions: IPageRevision[]
) => {
  const sortedRevisions = [...revisions].sort(compareRevisions('asc'));
  const revisionsToLookAt = revision.ignore
    ? sortedRevisions
    : sortedRevisions.filter((r) => !r.ignore);

  const currentIndex = revisionsToLookAt.indexOf(revision);
  const isLast = currentIndex === revisionsToLookAt.length - 1;
  const curr = {
    start: revision.lastModifiedAt,
    end: isLast ? null : revisionsToLookAt[currentIndex + 1].lastModifiedAt
  };
  const previousIndex = currentIndex - 1;
  const previousRevision = previousIndex >= 0 ? revisions[previousIndex] : null;
  const prev = !previousRevision
    ? null
    : {
        start: previousRevision.lastModifiedAt,
        end: revision.lastModifiedAt
      };

  return { curr, prev };
};

export const getRevisionTimeframe = (
  space: ISpace,
  revision: IPageRevision,
  otherRevisions: IPageRevision[]
) => {
  const {
    createdAt,
    config: { tz }
  } = space;
  const { start, end } = getTimeBoundaries(revision, otherRevisions);

  return {
    start: toStart(
      isBefore(start, createdAt) ? createdAt : start,
      tz,
      DAY_FORMAT
    ),
    end: toEnd(end, tz, DAY_FORMAT),
    tz
  };
};

export const getMatchingTimeframe = (
  space: ISpace,
  start: firebase.firestore.Timestamp,
  end: firebase.firestore.Timestamp | null
): Timeframe => {
  const {
    createdAt,
    config: { tz }
  } = space;

  const s = toMoment(isBefore(start, createdAt) ? createdAt : start)
    .tz(tz)
    .startOf('day')
    .format(DAY_FORMAT);

  const e = end
    ? toMoment(end).tz(tz).endOf('day').format(DAY_FORMAT)
    : moment().tz(tz).startOf('day').format(DAY_FORMAT);

  return { start: s, end: e, tz };
};
