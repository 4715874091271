import {
  downloadFile,
  downloadWorksheetAsXlsx,
  FileType,
  toCsv,
  toWorksheet
} from '../../services/file';

export interface IExportColumn<ColumnKey, RowData, OtherProps = undefined> {
  key: ColumnKey;
  head: (props: OtherProps) => string;
  cell: (
    data: RowData,
    props: OtherProps
  ) => string | number | boolean | null | undefined;

  ui?: (
    props: OtherProps
  ) => {
    label?: string; // fall back to head if not defined
    description?: string;
    optional?: boolean;
  };
}

export interface IExportGroup<ColumnKey> {
  label: string;
  description: string;
  columns: ColumnKey[];
}

export const renderHeads = <ColumnKey, RowData, OtherProps = undefined>(
  otherProps: OtherProps,
  columns: IExportColumn<ColumnKey, RowData, OtherProps>[]
): string[] => columns.map((c) => c.head(otherProps));

export const renderRows = <ColumnKey, RowData, OtherProps = undefined>(
  rows: RowData[],
  otherProps: OtherProps,
  columns: IExportColumn<ColumnKey, RowData, OtherProps>[]
): (string | number | boolean)[][] =>
  rows.map((r) => columns.map((c) => c.cell(r, otherProps) ?? ''));

export const exportAsCsv = async <ColumnKey, RowData, OtherProps = undefined>(
  fileName: string,
  rows: RowData[],
  otherProps: OtherProps,
  columns: IExportColumn<ColumnKey, RowData, OtherProps>[]
) => {
  const heads = renderHeads(otherProps, columns);
  const rs = renderRows(rows, otherProps, columns);
  console.log('ROWS', rs, rs.length);
  const data = await toCsv({ fields: heads, data: rs });
  return downloadFile(fileName, 'csv', data);
};

export const exportAsXlsx = async <ColumnKey, RowData, OtherProps = undefined>(
  fileName: string,
  rows: RowData[],
  otherProps: OtherProps,
  columns: IExportColumn<ColumnKey, RowData, OtherProps>[]
) => {
  const heads = renderHeads(otherProps, columns);
  const rs = renderRows(rows, otherProps, columns);

  const sheet = await toWorksheet([heads, ...rs]);
  return downloadWorksheetAsXlsx(fileName, sheet);
};

export const exportRowsToFile = async <
  ColumnKey,
  RowData,
  OtherProps = undefined
>(
  fileName: string,
  rows: RowData[],
  otherProps: OtherProps,
  columns: IExportColumn<ColumnKey, RowData, OtherProps>[],
  fileType: FileType
) => {
  if (fileType === 'csv') {
    return exportAsCsv(fileName, rows, otherProps, columns);
  }
  if (fileType === 'xlsx') {
    return exportAsXlsx(fileName, rows, otherProps, columns);
  }

  throw new Error(`Unknown filetype: ${fileType}`);
};
