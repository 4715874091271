import { Typography } from '@material-ui/core';
import React from 'react';
import { LimitedWidth } from '../layout/PageBody';
import { SupportButton } from './SupportButton';

export const ScheduledExportNotAvailable = () => {
  return (
    <LimitedWidth width={600}>
      <img
        src="/images/empty-states/scheduled-export.svg"
        style={{ width: '100%' }}
        alt="Scheduled Cloud Exports"
      />
      <Typography variant="h6" component="h2" paragraph>
        <strong>Access Scheduled Cloud Exports</strong>
      </Typography>
      <Typography variant="body1" component="h2" paragraph>
        Scheduled Cloud Exports are an advanced feature which allows you to
        periodically export your data to a cloud storage provider.
      </Typography>
      <Typography variant="body1" component="h2" paragraph>
        Reach out to us for more details on features and pricing.
      </Typography>
      <SupportButton
        subject="Storage Exports"
        label="Request Scheduled Exports information"
      />
    </LimitedWidth>
  );
};

export const ApiNotAvailable = () => {
  return (
    <LimitedWidth width={600}>
      <img
        src="/images/empty-states/api-key-empty-state.svg"
        style={{ width: '100%' }}
        alt="Publisher API"
      />
      <Typography variant="h6" component="h2" paragraph>
        <strong>Access the Publisher API</strong>
      </Typography>
      <Typography variant="body1" component="h2" paragraph>
        The Affilimate Publisher API is an advanced feature which includes a
        unified transaction and payouts API, programmatic retrieval of analytics
        data, API-driven link generation, and more.
      </Typography>
      <Typography variant="body1" component="h2" paragraph>
        Reach out to us for more details on features and pricing.
      </Typography>
      <SupportButton subject="Publisher API" label="Request API information" />
    </LimitedWidth>
  );
};
