import { Typography } from '@material-ui/core';
import React from 'react';
import styled from '../../emotion/styled';
import { Centered } from '../../layout/Centered';

const Layout = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(3)}px;
  margin-top: ${(p) => p.theme.spacing(3)}px;
  text-align: center;
`;

const DESCRIPTION = `We're working on creating something amazing here.`;

export const PageComingSoon = ({
  description = DESCRIPTION
}: {
  description?: string;
}) => {
  return (
    <Centered height={400}>
      <Layout>
        <div>
          <img
            alt="Coming soon"
            src="/images/empty-states/coming_soon.svg"
            style={{ width: '300px' }}
          />
        </div>
        <div>
          <Typography variant="h5" component="p" paragraph>
            Coming Soon
          </Typography>
          <Typography variant="body1" component="p">
            {description}
          </Typography>
        </div>
      </Layout>
    </Centered>
  );
};
