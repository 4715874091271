import { useCollection } from 'react-firebase-hooks/firestore';
import { IReportingExtensionStatus } from '../../../../domainTypes/chromeExtension/reporting';
import { toDoc } from '../../../../domainTypes/document';
import { store, useMappedLoadingValue } from '../../../../services/db';
import { FS } from '../../../../versions';

export const useReportingExtensionStatuses = (
  spaceId: string,
  userId: string
) => {
  return useMappedLoadingValue(
    useCollection(
      store()
        .collection(FS.reportingExtensionStatus)
        .where('spaceId', '==', spaceId)
        .where('userId', '==', userId)
    ),
    (s) => s.docs.map((d) => toDoc<IReportingExtensionStatus>(d))
  );
};
