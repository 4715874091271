import { groupBy, isEqual, isNil, partition, sortBy, take } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import {
  AnalyticsFilter,
  AnalyticsQuery,
  ISOTimeRange
} from '../../../../domainTypes/analytics_v2';
import {
  coerceFilterMode,
  FilterMode,
  TagFilterDef
} from '../../../../domainTypes/filters';
import { IPostgresTags } from '../../../../domainTypes/tags';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../services/currentUser';
import {
  LoadingValue,
  useCombineLoadingValues,
  useMappedLoadingValue
} from '../../../../services/db';
import { ADVANCED_MODES, FilterMenu, saveButtonLabel } from './FilterMenu';
import { useTagsForCurrentUser } from '../../../../services/tags';
import { Truncated } from '../../../Truncated';
import {
  createTagsFilterDefinition,
  initFilterValue,
  validateFilterDefinition
} from '../filters';
import { DimensionMenuComponent, DimensionMenuProps } from './Dimension';
import {
  GroupedOptionList,
  SelectorLoader,
  SelectorShell,
  useCollectionState
} from './Selector';

const TOP_TAGS = 10;

interface TagMenuBodyProps {
  mode: FilterMode;
  tags: TagsContext;
}

const useTagMenuHierarchy = (
  { allTags, contentTags }: TagsContext,
  selectedTags: Array<string>,
  search: string
) => {
  return useMemo(() => {
    const sortedTags = sortBy(allTags, (t) => t.name.trim());
    const [parentTags, childTags] = partition(sortedTags, (t) => !t.parent_id);
    const childTagsByParentId = groupBy(childTags, (t) => t.parent_id);
    const hierarchy = parentTags.map((tag) => {
      const allChildren = childTagsByParentId[tag.id] || [];
      const childrenMatchingSearch = allChildren.filter(
        (t) =>
          contentTags.includes(t.id) &&
          t.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      );

      const topChildren = take(childrenMatchingSearch, TOP_TAGS);
      const topChildrenIds = topChildren.map((t) => t.id);

      const promoted = selectedTags
        .filter((id) => !topChildrenIds.includes(id))
        .map((id) => allChildren.find((t) => t.id === id))
        .filter((t) => !isNil(t)) as Array<IPostgresTags>;
      const children = [...promoted, ...topChildren];

      return {
        parent: {
          ...tag,
          count: childrenMatchingSearch.length,
          hasMore: topChildren.length < childrenMatchingSearch.length
        },
        children
      };
    });
    return hierarchy.filter((h) => h.children.length > 0);
  }, [allTags, contentTags, search, selectedTags]);
};

const TagMenuBody: React.FC<
  TagMenuBodyProps &
    Pick<DimensionMenuProps<TagFilterDef>, 'definition' | 'onSave'>
> = ({ tags, definition, onSave, mode }) => {
  const [search, setSearch] = useState('');
  const [value, toggleValue] = useCollectionState(
    coerceFilterMode(definition.mode) === mode ? definition.v : []
  );
  const newDefinition = useMemo<TagFilterDef>(
    () => createTagsFilterDefinition(value, mode),
    [mode, value]
  );

  const enableSave =
    validateFilterDefinition(newDefinition) &&
    !isEqual(definition, newDefinition);

  const hierarchy = useTagMenuHierarchy(tags, value, search);

  const options = useMemo(() => {
    return hierarchy.map(({ parent, children }) => {
      return {
        key: parent.id,
        label: `${parent.name} (${parent.count})`,
        hasMore: parent.hasMore,
        items: children.map((child) => ({
          id: child.id,
          label: <Truncated title={child.name} />
        }))
      };
    });
  }, [hierarchy]);

  const focusValue = useCallback(
    (tag: string) => onSave(createTagsFilterDefinition([tag], mode)),
    [mode, onSave]
  );

  return (
    <>
      <FilterMenu.Body>
        <SelectorShell label="Tags" search={search} setSearch={setSearch}>
          <GroupedOptionList
            options={options}
            selectedValues={value}
            toggleValue={toggleValue}
            focusValue={focusValue}
          />
        </SelectorShell>
      </FilterMenu.Body>
      <FilterMenu.Footer definition={definition}>
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={saveButtonLabel('tag', value.length, mode)}
        />
      </FilterMenu.Footer>
    </>
  );
};

const useContentTags = (filters: AnalyticsFilter[], range: ISOTimeRange) => {
  const { space } = useCurrentUser();

  const query = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      filters: [
        ...filters,
        { field: 'tags', condition: 'not in', values: [''] }
      ],
      select: ['p'],
      groupBy: ['tags']
    };
  }, [filters, range]);
  return useMappedLoadingValue(
    useAnalyticsQueryV2(space.id, query),
    (response) => response.rows.map((row) => row.group.tags)
  );
};

const TagMenuLoading = () => (
  <>
    <FilterMenu.Body>
      <SelectorLoader />
    </FilterMenu.Body>
    <FilterMenu.Footer definition={initFilterValue('tag')}>
      <FilterMenu.SaveButton
        disabled={true}
        onSave={() => {}}
        label="Loading tags..."
      />
    </FilterMenu.Footer>
  </>
);

type TagsContext = {
  allTags: IPostgresTags[];
  contentTags: Array<string>;
};

const useTags = (
  filters: Array<AnalyticsFilter>,
  range: ISOTimeRange
): LoadingValue<TagsContext> => {
  return useMappedLoadingValue(
    useCombineLoadingValues(
      useContentTags(filters, range),
      useTagsForCurrentUser()
    ),
    ([contentTagIds, allTags]) => {
      return {
        allTags,
        contentTags: contentTagIds
      };
    }
  );
};

const InnerTagMenu: React.FC<{
  definition: TagFilterDef;
  onSave: (d: TagFilterDef) => void;
  filters: AnalyticsFilter[];
  range: ISOTimeRange;
  mode: FilterMode;
}> = ({ definition, onSave, filters, range, mode }) => {
  const [tags, loading] = useTags(filters, range);
  if (!tags || loading) {
    return <TagMenuLoading />;
  }
  return (
    <TagMenuBody
      key={mode}
      tags={tags}
      definition={definition}
      onSave={onSave}
      mode={mode}
    />
  );
};

export const TagMenu: DimensionMenuComponent<TagFilterDef> = ({
  definition,
  onSave,
  filters,
  range
}) => {
  const [mode, setMode] = useState<FilterMode>(
    coerceFilterMode(definition.mode)
  );

  return (
    <FilterMenu>
      <FilterMenu.Header dimension="tag">
        <FilterMenu.ModeSelector
          modes={ADVANCED_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <InnerTagMenu
        mode={mode}
        filters={filters}
        range={range}
        definition={definition}
        onSave={onSave}
      />
    </FilterMenu>
  );
};
