import {
  AnalyticsFilter,
  AnalyticsQuery,
  ISOTimeRange,
  SelectableField
} from '../../../../../../domainTypes/analytics_v2';

const TOP_N = 7;

export const topTagsQuery = (
  metrics: SelectableField[],
  range: ISOTimeRange,
  tagGroupId: string,
  tagsFilter: AnalyticsFilter,
  sitesFilter: AnalyticsFilter,
  compare?: AnalyticsQuery['compare']
): AnalyticsQuery => ({
  range,
  compare,
  select: metrics,
  groupBy: ['tags'],
  orderBy: metrics.map((m) => ({ field: m, direction: 'DESC' })),
  filters: [
    sitesFilter,
    tagsFilter,
    {
      field: 'parent_tags',
      condition: 'in',
      values: [tagGroupId]
    }
  ],
  paginate: {
    page: 1,
    limit: TOP_N
  }
});
