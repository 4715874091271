import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { HelpIcon } from '../../../components/HelpIcon';
import { Loader } from '../../../components/Loader';
import { Currency, Number, toPercent } from '../../../components/Number';
import {
  EMPTY_EARNING,
  IEarning,
  SALE_UI_CONFIG
} from '../../../domainTypes/performance';
import { css, styled } from '../../../emotion';
import { useDialogState } from '../../../hooks/useDialogState';
import { ARTICLES } from '../../../services/beacon';
import { SalesStatus } from './SalesStatus';

const StatusWrapper = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

const TotalsWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: ${(p) => p.theme.spacing(2)}px;
  padding-top: ${(p) => p.theme.spacing(3)}px;
  text-align: center;
`;

const ProgressSectionWrapper = styled('div')`
  margin-top: ${(p) => p.theme.spacing(3)}px;
`;

const StatusLabelAndAmount = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const ProgressSection = ({
  status,
  percentage,
  value,
  currency,
  asPercent,
  noColor
}: {
  status: string;
  percentage: number;
  value: number;
  currency: string;
  asPercent: boolean;
  noColor?: boolean;
}) => {
  const config = SALE_UI_CONFIG[status] || SALE_UI_CONFIG.Unknown;

  return (
    <ProgressSectionWrapper>
      <StatusLabelAndAmount>
        <Typography
          variant="body1"
          component="p"
          paragraph
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {config.label} &nbsp;{' '}
          <HelpIcon articleId={ARTICLES.transactions.statuses} />
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          {asPercent ? (
            <Number n={value || 0} format="percent" digits={2} />
          ) : (
            <Currency cents={value || 0} currency={currency} />
          )}
        </Typography>
      </StatusLabelAndAmount>
      <LinearProgress
        classes={{
          root: css((t) => ({
            height: 10,
            borderRadius: t.shape.borderRadius,
            backgroundColor: '#eee',
            marginBottom: t.spacing(2)
          })),
          bar: css((t) => ({
            borderRadius: t.shape.borderRadius,
            backgroundColor: noColor ? '#6d6d6d' : config.color
          }))
        }}
        variant="determinate"
        value={percentage || 0}
      />
    </ProgressSectionWrapper>
  );
};

type IVariant = 'progress' | 'summary';

const EmptySalesDialogContent = () => (
  <DialogContent>
    <Typography paragraph style={{ marginBottom: '24px' }}>
      There are a few reasons why you might not be able to see sales during this
      time period. If you think it's a mistake, you can always contact us using
      the little bubble in the bottom right corner of the screen.
    </Typography>
    <ol>
      <li>
        <b>Sales were made with a network that doesn't support Smart Labels </b>
        (such as Klook or Voyagin).
        <br />
        <br />
      </li>
      <li>
        <b>Sales were made with Amazon</b>, for which we're still building
        page-level support! Be sure to follow the tutorial on{' '}
        <a
          href="https://affilimate.io/blog/how-to-track-affiliate-sales-on-amazon?utm_source=app"
          style={{ textDecoration: 'underline' }}
          rel="noopener noreferrer"
          target="_blank"
        >
          adding tracking IDs to Amazon links
        </a>{' '}
        to be prepared for that feature.
        <br />
        <br />
      </li>
      <li>
        <b>Sales were made with a network we haven't integrated yet</b> (
        <a
          href="https://help.affilimate.io/articles/reporting-integrations/?utm_source=app"
          rel="noopener noreferrer"
          target="_blank"
          style={{ textDecoration: 'underline' }}
        >
          See our list of integrations
        </a>
        ).
        <br />
        <br />
      </li>
      <li>
        <b>
          Smart Labels aren't turned on in your Performance integration settings
        </b>
        . (
        <a
          href="https://help.affilimate.io/articles/smart-labels/##how-to-enable-smart-labels-for-cloaked-links?utm_source=app"
          rel="noopener noreferrer"
          target="_blank"
          style={{ textDecoration: 'underline' }}
        >
          Learn how to do that!
        </a>
        )<br />
        <br />
      </li>
      <li>
        <b>You're using Thirsty Affiliates or Pretty Links</b>, and haven't set
        up parameter pass-through (
        <a
          href="https://help.affilimate.io/articles/smart-labels/##how-to-enable-smart-labels-for-cloaked-links?utm_source=app"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline' }}
        >
          Learn how to do that!
        </a>
        )<br />
        <br />
      </li>
      <li>
        <b>Links on this page use a URL shortener</b> that doesn't support
        parameter pass-through , like <u>fave.co</u> or <u>shrsl.com</u>. You
        can fix this by gradually creating new "long links" for Skimlinks and
        Shareasale, respectively.
        <br />
        <br />
      </li>
      <li>
        <b>You didn't make any sales :(</b> Sad, but always a possibility.
      </li>
    </ol>
  </DialogContent>
);

const Overlay = styled('div')((p) => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
}));

export const TotalsCard = ({
  totals: totalsOrig,
  loading,
  variant = 'progress'
}: {
  totals: void | IEarning;
  loading?: boolean;
  variant?: IVariant;
}) => {
  // Currency for the empty earning doesn't matter - data here is just a placeholder
  const totals = useMemo(() => totalsOrig || EMPTY_EARNING('USD'), [
    totalsOrig
  ]);
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const { currency } = totals;
  const salesTotal = totals.final + totals.pending;
  const baseValue = Math.max(salesTotal, totals.refunded);
  const hasNoSales = totals.transactionCount === 0;
  const wrapperStyles = hasNoSales
    ? {
        filter: 'blur(8px)',
        zIndex: -1,
        opacity: 0.3
      }
    : {};

  const NO_COLOR = true;

  return (
    <>
      <div style={{ width: '100%', position: 'relative' }}>
        {loading && !totalsOrig && (
          <Overlay>
            <Loader />
          </Overlay>
        )}
        {hasNoSales && !loading && (
          <Overlay>
            <Typography
              component="p"
              style={{ textAlign: 'center', marginBottom: '36px' }}
            >
              You don't have any earnings tracked during this time frame.
              <br />
              <br />
              <Button
                style={{ position: 'relative', zIndex: 2 }}
                onClick={openDialog}
                variant="outlined"
              >
                Learn more
              </Button>
            </Typography>
          </Overlay>
        )}
        <div style={wrapperStyles}>
          <Typography
            variant="h4"
            component="p"
            style={{ textAlign: 'center', marginBottom: '36px' }}
          >
            <Currency cents={totals.total} currency={currency} />
          </Typography>
          {variant === 'progress' && (
            <div>
              <ProgressSection
                status="Final"
                percentage={(totals.final / baseValue) * 100}
                value={totals.final}
                currency={currency}
                asPercent={false}
                noColor={NO_COLOR}
              />
              <ProgressSection
                status="Pending"
                percentage={(totals.pending / baseValue) * 100}
                value={totals.pending}
                currency={currency}
                asPercent={false}
                noColor={NO_COLOR}
              />
              <ProgressSection
                status="Refunded"
                percentage={(totals.refunded / baseValue) * 100}
                value={-totals.refunded}
                currency={currency}
                asPercent={false}
                noColor={NO_COLOR}
              />
              <ProgressSection
                status="Lost"
                percentage={(totals.lostCount / totals.transactionCount) * 100}
                value={totals.lostCount / totals.transactionCount}
                currency={currency}
                asPercent={true}
                noColor={NO_COLOR}
              />
            </div>
          )}
          {variant === 'summary' && (
            <TotalsWrapper>
              <div>
                <StatusWrapper>
                  <SalesStatus status="Final" />
                </StatusWrapper>
                <Typography variant="body1" component="p" paragraph>
                  <Currency cents={totals.final} currency={currency} />
                </Typography>
              </div>
              <div>
                <StatusWrapper>
                  <SalesStatus status="Pending" />
                </StatusWrapper>
                <Typography variant="body1" component="p" paragraph>
                  <Currency cents={totals.pending} currency={currency} />
                </Typography>
              </div>
              <div>
                <StatusWrapper>
                  <SalesStatus status="Refunded" />
                </StatusWrapper>
                <Typography variant="body1" component="p">
                  -<Currency cents={totals.refunded} currency={currency} />
                </Typography>
              </div>
              <div>
                <StatusWrapper>
                  <SalesStatus status="Cancelled" />
                </StatusWrapper>
                <Typography variant="body1" component="p">
                  <Number
                    n={toPercent(totals.cancelledCount, totals.totalCount)}
                    format="percent"
                    digits={2}
                  />
                </Typography>
              </div>
            </TotalsWrapper>
          )}
        </div>
      </div>
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle>Why are there no commissions?</DialogTitle>
        <EmptySalesDialogContent />
        <DialogActions>
          <Button variant="contained" onClick={closeDialog}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
