import moment from 'moment-timezone';
import React from 'react';
import {
  CountKey,
  Formatters,
  ITimeBasedCounter,
  TrafficBiaxialChart
} from '../../../../components/Charts/TrafficBiaxialChart';
import { Loader } from '../../../../components/Loader';
import {
  useColorsSpectrumGrayscale,
  useColorsSpectrumPrimary
} from '../../../../services/color';
import { LoadingValue, useMappedLoadingValue } from '../../../../services/db';
import {
  formatHourKey,
  useTrackingStatsHourlyBySpaceId
} from '../../../../services/monitoring';
import { CHART_HEIGHT } from './constants';

export const HOUR_KEY_FORMATTERS: Formatters = {
  xTick: (x) => formatHourKey(x),
  tooltipLabel: (x) => {
    const date = formatHourKey(x, 'ddd MMM DD');
    const startTime = formatHourKey(x, 'LT').replace(':00', '');
    const endTime = formatHourKey(x, 'LT', 1).replace(':00', '');

    if (startTime.indexOf('AM') !== -1 && endTime.indexOf('AM') !== -1) {
      return (
        <span>
          {date}, {startTime.replace(' AM', '')}–{endTime}
        </span>
      );
    } else if (startTime.indexOf('PM') !== -1 && endTime.indexOf('PM') !== -1) {
      return (
        <span>
          {date}, {startTime.replace(' PM', '')}—{endTime}
        </span>
      );
    } else {
      return (
        <span>
          {date}, {startTime}—{endTime}
        </span>
      );
    }
  }
};

const OPTIONS: CountKey[] = ['clicked', 'pageViews'];

export const RealTimeReportingMiniChart = ({
  data
}: {
  data: LoadingValue<ITimeBasedCounter[]>;
}) => {
  const [stats, loading] = data;
  const [secondaryColor] = useColorsSpectrumPrimary();
  const [primaryColor] = useColorsSpectrumGrayscale();

  if (loading) {
    return <Loader height={CHART_HEIGHT} />;
  }

  return (
    <TrafficBiaxialChart
      yAxisRight={'clicked'}
      yAxisLeft={'pageViews'}
      loading={loading}
      counters={stats}
      compare={false}
      animate={false}
      colors={[primaryColor, secondaryColor]}
      options={OPTIONS}
      formatters={HOUR_KEY_FORMATTERS}
      variant="minimal"
      margin="dense"
      height={CHART_HEIGHT}
    />
  );
};

export const RealtimeReporting = ({ spaceId }: { spaceId: string }) => {
  const data = useMappedLoadingValue(
    useTrackingStatsHourlyBySpaceId(spaceId, moment.duration(2, 'd')),
    (ds) =>
      ds.map<ITimeBasedCounter>((d) => ({
        ts: d.hourKey,
        pageViews: d.counts.pageViews,
        served: d.counts.served,
        viewed: d.counts.viewed,
        clicked: d.counts.clicked
      }))
  );
  return <RealTimeReportingMiniChart data={data} />;
};
