import React from 'react';
import { Helmet } from 'react-helmet';
import { NoPermissions } from '../../../../components/NoPermissions';
import { useCurrentUserScopes } from '../../../../services/currentUser';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { getPathname } from '../../../../services/url';
import { DetailsTrendsBody } from './components/DetailsTrendsBody';

export const PageContentDetailsTrends = ({ url }: { url: string }) => {
  useTrackMixpanelView('view_content_details');
  const scopes = useCurrentUserScopes();
  const canViewContentReports = scopes.has('reports.content.view');

  if (!canViewContentReports) {
    return <NoPermissions />;
  }

  return (
    <div>
      <Helmet>
        <title>{getPathname(url)} | Affilimate</title>
      </Helmet>
      <DetailsTrendsBody url={url} />
    </div>
  );
};
