import React, { useMemo } from 'react';
import {
  ColumnDefinition,
  useTable
} from '../../../../../../../components/analytics_v2/Table';
import { CustomPagination } from '../../../../../../../components/CustomPagination';
import { RowsRenderer } from '../../../../../../../components/GroupableList';
import { PaperLoader } from '../../../../../../../components/Loader';
import {
  AnalyticsQuery,
  ISOTimeRange
} from '../../../../../../../domainTypes/analytics_v2';
import { CanvasBar } from '../../../../../../../layout/Canvas';
import { Section } from '../../../../../../../layout/Section';
import { Metric } from '../../../../../../../services/analyticsV2/metrics';
import { useAnalyticsQueryV2 } from '../../../../../../../services/analyticsV2/query';
import { PageHref } from '../../../../../../Content/pages/Overview_v2/Table/Href';
import {
  AVERAGE_COMMISSION_COLUMN,
  AVERAGE_PRICE_COLUMN
} from './customColumns';
import { ExportQueryButton } from '../../../../../../../components/ExportQuery';
import { FlexContainer } from '../../../../../../../layout/Flex';

type CustomColumns = 'page_url' | 'average_price' | 'average_commission';

const PAGE_SIZE = 15;

const TOP_PAGES_COLUMNS: (Metric | CustomColumns)[] = [
  'page_url',
  'order_count_net',
  'quantity_net',
  'average_price',
  'average_commission',
  'aov_net',
  'commission_sum_net',
  'gmv_sum_net'
];
const TOP_PAGES_CUSTOM_COLUMNS: {
  [key in CustomColumns]: ColumnDefinition<CustomColumns>;
} = {
  page_url: {
    column: {
      key: 'page_url',
      head: () => 'Page URL',
      cell: (p) => <PageHref href={p.group.page_url} />,
      align: 'left',
      sortable: true,
      defaultDirection: 'asc',
      width: 300,
      flexGrow: 2
    },
    sorter: {
      key: 'page_url',
      items: {
        sort: (p) => p.group.page_url,
        dir: 'asc'
      }
    }
  },
  average_price: AVERAGE_PRICE_COLUMN,
  average_commission: AVERAGE_COMMISSION_COLUMN
};

export const TopPagesSection = ({
  spaceId,
  commonQ,
  compareRange
}: {
  spaceId: string;
  commonQ: Pick<AnalyticsQuery, 'range' | 'filters' | 'search'>;
  compareRange: ISOTimeRange | null;
}) => {
  const { pagination, orderBy, tableProps, paginationSelectorProps } = useTable(
    TOP_PAGES_COLUMNS,
    TOP_PAGES_CUSTOM_COLUMNS,
    {
      pageSize: PAGE_SIZE,
      defaultSortColumn: 'commission_sum_net',
      sortQueryParamName: 'pagesSort',
      paginationParamName: 'pagesPage',
      showComparison: !!compareRange
    }
  );

  const baseQ = useMemo<AnalyticsQuery>(
    () => ({
      ...commonQ,
      filters: [
        ...(commonQ.filters || []),
        {
          field: 'page_url',
          condition: 'not in',
          values: ['']
        }
      ],
      groupBy: ['page_url'],
      orderBy: [orderBy],
      select: [
        'quantity_net',
        'aov_net',
        'commission_sum_net',
        'gmv_sum_net',
        'order_count_net'

        // avg price - don't have that yet --> current table divides total by quantity
        // avg commision --> current table divides total by quantity
        // avg rate
      ]
    }),
    [commonQ, orderBy]
  );

  const topPagesQ = useMemo<AnalyticsQuery>(
    () => ({
      ...baseQ,
      compare: compareRange ? { range: compareRange } : undefined,
      paginate: pagination
    }),
    [baseQ, compareRange, pagination]
  );
  const [ds] = useAnalyticsQueryV2(spaceId, topPagesQ, { logMode: 'full' });
  const currentRowsCount = ds ? ds.rows.length : 0;
  return (
    <Section>
      <CanvasBar>
        <div>Top pages</div>
        <FlexContainer>
          <CustomPagination
            {...paginationSelectorProps}
            siblingCount={0}
            count={
              (paginationSelectorProps.page || 1) +
              (currentRowsCount < PAGE_SIZE ? 0 : 1)
            }
          />
          <ExportQueryButton
            title="Export content"
            reportType="advertisers"
            query={baseQ}
          />
        </FlexContainer>
      </CanvasBar>
      {!ds && <PaperLoader height={300} />}
      {ds && (
        <RowsRenderer
          {...tableProps}
          renderHead
          rows={ds.rows}
          rowToKey={(d) => d.group.page_url}
        />
      )}
    </Section>
  );
};
