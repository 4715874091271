import { createBrowserHistory } from 'history';
import { pull } from 'lodash';

function getConfirmation(message, callback) {
  callback(window.confirm(message)); // eslint-disable-line no-alert
}

export const history = createBrowserHistory({
  basePath: '',
  getUserConfirmation: getConfirmation
});

function historyBlock(...args) {
  if (history) {
    return history.block.apply(history, args);
  } else {
    return () => undefined;
  }
}

const blockers = [];
let unblockHistory;

export function block(shouldBlock) {
  blockers.push(shouldBlock);
  if (!unblockHistory) {
    unblockHistory = historyBlock(() => (areAnyBlocking() ? '' : undefined));
    window.addEventListener('beforeunload', handleUnload);
  }
  return function unblock() {
    pull(blockers, shouldBlock);
    shouldBlock = null;
    if (blockers.length === 0) {
      unblockHistory();
      unblockHistory = null;
      window.removeEventListener('beforeunload', handleUnload);
    }
  };
}

function areAnyBlocking() {
  return blockers.some(shouldBlock => shouldBlock());
}

function handleUnload(event) {
  if (areAnyBlocking()) {
    // Note: The actual text here doesn't matter. Browsers will render a fixed text
    event.returnValue =
      'Any unsaved changes will be lost. Are you sure you want to leave this page?';
    return 'Any unsaved changes will be lost. Are you sure you want to leave this page?';
  }
}
