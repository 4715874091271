import { ISettings } from '../../domainTypes/settings';
import { generateToDocFn } from '../../domainTypes/document';
import { FS } from '../../versions';
import { store } from '../db';
import {
  CollectionListener,
  createCollectionListenerStore,
  useCollectionListener
} from '../firecache/collectionListener';

const toSettingsDocId = (spaceId: string, channelId: string) => {
  return `${spaceId}|${channelId}`;
};

export const toSettingsDoc = generateToDocFn<ISettings>();
export const settingsCollection = () => store().collection(FS.settings);
export const getSettingsQuery = (spaceId: string) =>
  settingsCollection().where('spaceId', '==', spaceId);

const settingsStore = createCollectionListenerStore((spaceAndChannel) => {
  const [spaceId, channelId] = spaceAndChannel.split('|');
  return new CollectionListener(
    store()
      .collection(FS.settings)
      .where('spaceId', '==', spaceId)
      .where('channelId', '==', channelId),
    toSettingsDoc
  );
});

export const useSettings = (spaceId: string, channelId: string) => {
  useCollectionListener(settingsStore(toSettingsDocId(spaceId, channelId)));
};

export const updateSiteSettings = async (
  spaceId: string,
  channelId: string,
  settings: Partial<ISettings>
) => {
  const docId = toSettingsDocId(spaceId, channelId);
  const settingsRef = settingsCollection().doc(docId);
  const settingsDoc = await settingsRef.get();

  // TODO: They can only set this if the space ID belongs
  // to them.
  if (!settingsDoc.exists) {
    await settingsRef.set({
      ...settings,
      spaceId,
      channelId
    });
  } else {
    await settingsRef.update(settings);
  }
};
