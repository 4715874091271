import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import React from 'react';
import { DownloadCloud as IconDownload } from 'react-feather';
import Stripe from 'stripe';
import { Chip, ChipProps } from '../../../../components/Chip';
import { Date } from '../../../../components/Dates';
import { IInvoice } from '../../../../domainTypes/billing';
import { formatAmount } from '../../service';

type Props = {
  invoices: IInvoice[];
};

const getChipType = (status: Stripe.Invoice['status']): ChipProps['type'] => {
  if (status === 'paid') {
    return 'SUCCESS';
  }
  if (status === 'open') {
    return 'PENDING';
  }
  if (status === 'uncollectible') {
    return 'ERROR';
  }
  return 'ABORTED';
};

const Status = ({ status }: { status: Stripe.Invoice['status'] }) => {
  return (
    <Chip label={(status || '').toUpperCase()} type={getChipType(status)} />
  );
};

export const InvoiceTable: React.FC<Props> = ({ invoices }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Invoice Number</TableCell>
          <TableCell align="center">Status</TableCell>
          <TableCell>Amount</TableCell>
          <TableCell>Date</TableCell>
          <TableCell align="center">Download</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {invoices.map((invoice) => (
          <TableRow key={invoice.id}>
            <TableCell>{invoice.number}</TableCell>
            <TableCell align="center">
              <Status status={invoice.status} />
            </TableCell>
            <TableCell>
              {formatAmount(invoice.total, invoice.currency)}
            </TableCell>
            <TableCell>
              <Date d={invoice.created * 1000} />
            </TableCell>
            <TableCell align="center">
              {invoice.invoice_pdf && (
                <a href={invoice.invoice_pdf}>
                  <IconDownload size={22} />
                </a>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
