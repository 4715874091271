import { useEffect, useRef } from 'react';

export const useWhatChanged = (
  args: { [key: string]: any },
  label?: string,
  opts: { disable?: boolean } = {}
) => {
  const ref = useRef<any>(args);
  const { disable } = opts;
  useEffect(() => {
    if (disable) {
      return;
    }

    const prev = ref.current;
    const changes: { key: string; prev: any; curr: any }[] = [];
    Object.entries(args).forEach(([k, v]) => {
      if (prev[k] !== v) {
        changes.push({ key: k, prev: prev[k], curr: v });
      }
    });
    if (changes.length) {
      console.log(
        `CHANGES${label ? ` for ${label}` : ''} - ${changes.length} of ${
          Object.keys(args).length
        }`,
        changes
      );
    }
  }, [args, label, disable]);
};
