import { useMemo } from 'react';
import { PLANS_WITH_TEAMS, RoleId } from '../domainTypes/permissions';
import { useCurrentUser } from './currentUser';
import { useFeatureEnabled } from './features';

export const useAvailableRoles = () => {
  const currentUser = useCurrentUser();
  const hasTeamsToggleOn = useFeatureEnabled('TEAMS');

  return useMemo(() => {
    const plan = currentUser.space.billing.activePlan;
    const roles: RoleId[] = [];
    const hasTeamsAccess = plan && PLANS_WITH_TEAMS.indexOf(plan) !== -1;

    if (hasTeamsToggleOn) {
      roles.push('ADMIN');
      roles.push('SUPER_ADMIN');
      roles.push('VIEWER');
      roles.push('TOOLS_USER');
      return roles;
    }

    if (!plan || !hasTeamsAccess) {
      return roles;
    }

    if (hasTeamsAccess) {
      roles.push('ADMIN');
    }

    if (
      plan.indexOf('PORTFOLIO') !== -1 ||
      plan.indexOf('MEDIA_COMPANY') !== -1 ||
      plan.indexOf('2MIL') !== -1 ||
      plan.indexOf('FREE') !== -1 ||
      plan.indexOf('CUSTOM') !== -1
    ) {
      roles.push('SUPER_ADMIN');
      roles.push('VIEWER');
      roles.push('TOOLS_USER');
    }

    return roles;
  }, [currentUser.space.billing.activePlan, hasTeamsToggleOn]);
};
