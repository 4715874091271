import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IPostgresProduct } from '../../domainTypes/product';

type PgProductsCache = {
  analytics: {
    [checksum: string]: Promise<any>;
  };
  products: {
    [productId: string]: Promise<IPostgresProduct | null>;
  };
  productIds: {
    [checksum: string]: Promise<string[]>;
  };
};

let PG_CACHE: {
  [spaceId: string]: PgProductsCache;
} = {};

export const getPgProductsCacheForSpace = (spaceId: string) => {
  return (PG_CACHE[spaceId] = PG_CACHE[spaceId] || {
    analytics: {},
    products: {},
    productIds: {}
  });
};

const listeners: (() => void)[] = [];
const listenToCacheFlushes = (listener: () => void) => {
  listeners.push(listener);
  return () => {
    const index = listeners.indexOf(listener);
    if (index !== -1) {
      listeners.splice(index, 1);
    }
  };
};

export const flushPgProductsCacheForSpace = (spaceId: string) => {
  delete PG_CACHE[spaceId];
  listeners.forEach((l) => l());
};

// See if this can be combined with the sales cache, so that there's only one provider
// for all these PG related caches
export const PgProductsCacheContext = React.createContext<{
  version: number;
  flush: (spaceId: string) => void;
}>({
  version: Date.now(),
  flush: () => null
});

export const PgProductsCacheProvider: React.FC = ({ children }) => {
  const [version, setVersion] = useState(Date.now());
  useEffect(() => listenToCacheFlushes(() => setVersion(Date.now())));
  const value = useMemo(
    () => ({ version, flush: flushPgProductsCacheForSpace }),
    [version]
  );
  return (
    <PgProductsCacheContext.Provider value={value}>
      {children}
    </PgProductsCacheContext.Provider>
  );
};

export const usePgProductsCacheContext = () => {
  return useContext(PgProductsCacheContext);
};
