import { SalesFilterArgs } from '../../domainTypes/performance';
import { toChecksum } from '../checksum';
import { callFirebaseFunction } from '../firebaseFunctions';
import { getSalesCacheforSpace } from './cache';

export const getDistinctValues = async (
  spaceId: string,
  column: string,
  q: SalesFilterArgs
) => {
  const cache = getSalesCacheforSpace(spaceId).distinctValues;
  const checksum = toChecksum({ spaceId, q, column });
  const start = Date.now();
  let dbTime = 0;
  const req = (cache[checksum] =
    cache[checksum] ||
    callFirebaseFunction<{
      spaceId: string;
      q: SalesFilterArgs & { column: string };
      time: number;
      res: string[];
    }>('sales-getDistinctValuesInTimeframe', {
      spaceId,
      q: {
        ...q,
        column
      }
    }).then((r) => {
      dbTime = r.time;
      return r.res;
    }));
  const res = await req;
  const totalTime = Date.now() - start;
  console.log('getDistinctValues', { total: totalTime, db: dbTime, res });
  return res;
};
