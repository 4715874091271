import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { useMemo, useState } from 'react';
import { Trash2 } from 'react-feather';
import { IPostgresTags } from '../../../../../../domainTypes/tags';
import { useTagsForCurrentUser } from '../../../../../../services/tags';

const DeleteTagContent = ({
  tag,
  onDelete,
  onCancel
}: {
  tag: IPostgresTags;
  onCancel: () => void;
  onDelete: () => Promise<any>;
}) => {
  return (
    <>
      <DialogTitle>
        Are you sure you want to delete this {tag.parent_id ? 'tag' : 'group'}?
      </DialogTitle>
      <DialogContent>
        {tag.parent_id ? (
          <Typography variant="body1">
            Deleting this tag will also permanently remove it from all content
            it's been applied to, and cannot be reversed.
          </Typography>
        ) : (
          <Typography variant="body1">
            Deleting this group will{' '}
            <strong>also delete all the nested tags</strong> that belong to it,
            and remove those tags from any content they've been applied to.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button color="secondary" onClick={onDelete} variant="contained">
          Delete {tag.parent_id ? 'tag' : 'group'}
        </Button>
      </DialogActions>
    </>
  );
};

const UpdateTagGroupContent = ({
  tag,
  onUpdate,
  onUpdateMode
}: {
  tag: IPostgresTags;
  onUpdateMode: (mode: 'edit' | 'delete') => void;
  onUpdate: (newTag: IPostgresTags) => Promise<any>;
}) => {
  const [loading, setLoading] = useState(false);
  const [tagName, setTagName] = useState<string>(tag.name);

  const handleUpdate = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    return onUpdate({ ...tag, name: tagName }).then(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <DialogTitle>Rename this group</DialogTitle>
      <form onSubmit={handleUpdate}>
        <DialogContent>
          <Typography variant="body1" paragraph>
            We recommend the singular version of a name for your group, such as
            "Content type", "Silo", or "Topic".
          </Typography>
          <br />
          <TextField
            id="tagName"
            name="tagName"
            autoFocus
            value={tagName}
            onChange={(e) => {
              setTagName(e.target.value as string);
            }}
            required
            variant="outlined"
            fullWidth
          />
        </DialogContent>
        <DialogActions
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button
            color="secondary"
            onClick={() => {
              onUpdateMode('delete');
            }}
          >
            <Trash2 size={16} /> &nbsp; Delete this group
          </Button>
          <Button
            type="submit"
            color="primary"
            onClick={handleUpdate}
            variant="contained"
            disabled={tag.name === tagName || loading}
          >
            {loading ? 'Updating group...' : 'Update group'}
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

const UpdateTagContent = ({
  tag,
  onUpdate,
  onDelete
}: {
  tag: IPostgresTags;
  onDelete: () => Promise<any>;
  onUpdate: (newTag: IPostgresTags) => Promise<any>;
}) => {
  const [tags, loadingTags] = useTagsForCurrentUser();
  const [loading, setLoading] = useState(false);
  const [parentTagId, setParentTagId] = useState<string | null>(tag.parent_id);
  const parentTags = useMemo(() => {
    if (loadingTags || !tags) {
      return [];
    }
    return tags.filter((t) => t.parent_id === null);
  }, [tags, loadingTags]);

  const handleUpdate = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    return onUpdate({ ...tag, parent_id: parentTagId }).then(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <DialogTitle>Update this tag</DialogTitle>
      <form onSubmit={handleUpdate}>
        <DialogContent>
          <Typography variant="body1" paragraph>
            You can change which group this tag belongs to.
          </Typography>
          <Select
            id="parentId"
            name="parentId"
            value={parentTagId}
            onChange={(e) => {
              setParentTagId(e.target.value as string);
            }}
            required
            variant="outlined"
            fullWidth
          >
            {parentTags.map((t) => (
              <MenuItem value={t.id} key={t.id}>
                {t.name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Button
            color="secondary"
            onClick={() => {
              setLoading(true);
              onDelete().then(() => {
                setLoading(false);
              });
            }}
          >
            <Trash2 size={16} /> &nbsp; Delete this tag
          </Button>
          <Button
            type="submit"
            color="primary"
            onClick={handleUpdate}
            variant="contained"
            disabled={tag.parent_id === parentTagId || loading}
          >
            Update tag
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

export const EditTagDialog = ({
  open,
  tag,
  onCancel,
  onUpdate,
  onDelete
}: {
  open: boolean;
  tag: IPostgresTags;
  onCancel: () => void;
  onDelete: () => Promise<any>;
  onUpdate: (newTag: IPostgresTags) => Promise<any>;
}) => {
  const [mode, setMode] = useState<'edit' | 'delete'>('edit');

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      onClick={(e) => e.stopPropagation()}
      scroll="body"
    >
      {mode === 'delete' ? (
        <DeleteTagContent tag={tag} onDelete={onDelete} onCancel={onCancel} />
      ) : tag.parent_id ? (
        <UpdateTagContent tag={tag} onUpdate={onUpdate} onDelete={onDelete} />
      ) : (
        <UpdateTagGroupContent
          tag={tag}
          onUpdate={onUpdate}
          onUpdateMode={setMode}
        />
      )}
    </Dialog>
  );
};
