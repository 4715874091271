import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import { LegacyDateRangePicker } from '../../../../components/DateRangePicker/LegacyDateRangePicker';
import { Loader } from '../../../../components/Loader';
import { PartnerLogoWithName } from '../../../../components/PartnerLogo';
import { Doc } from '../../../../domainTypes/document';
import { IPartner } from '../../../../domainTypes/partners';
import { ReportingApiResult } from '../../../../domainTypes/reporting';
import { ISecretWithTs } from '../../../../domainTypes/secret';
import { css, styled } from '../../../../emotion';
import { useLoadingValue } from '../../../../hooks/useLoadingValue';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { useCurrentUser } from '../../../../services/currentUser';
import { scheduleImportJobs } from '../../../../services/sales/sales';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import { IApiReportHandler } from '../../services/handlers/types';
import { getApiPreview } from '../../services/report';
import { ApiError, ApiImportResults } from '../ApiImportResults';

type Props = {
  open: boolean;
  onClose: () => void;
  handler: IApiReportHandler;
  partner: IPartner;
  secret: Doc<ISecretWithTs>;
};

const GetReport = ({
  onRun,
  onCancel,
  secret
}: {
  onRun: (d: { start: number; end: number }) => Promise<any>;
  onCancel: () => void;
  secret: Doc<ISecretWithTs>;
}) => {
  const n = moment();
  const { space } = useCurrentUser();
  const [range, setRange] = useState({
    start: n.clone().subtract(7, 'd'),
    end: n
  });

  const { enqueueSnackbar } = useSnackbar();

  const onScheduleImport = async () => {
    await scheduleImportJobs({
      spaceId: space.id,
      instanceId: secret.data.instanceId,
      handlerName: secret.data.name,
      range
    })
      .then(() => {
        enqueueSnackbar(
          'Import scheduled successfully! Wait a few minutes then refresh to see results.',
          {
            variant: 'success'
          }
        );
      })
      .catch(() => {
        enqueueSnackbar('Error occurred scheduling import!', {
          variant: 'error'
        });
      });
  };

  return (
    <>
      <DialogContent>
        <Typography
          variant="body1"
          component="p"
          color="textSecondary"
          style={{ maxWidth: '650px' }}
          paragraph
        >
          Schedule a commission import for a specific time period. The second
          date is <strong>inclusive</strong>. To pull commissions from today for
          networks that report same-day commissions, include just today and
          yesterday in your selection.
        </Typography>
        <LegacyDateRangePicker value={range} onChange={setRange} />
      </DialogContent>
      <DialogActions
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div>
          <Button
            variant="text"
            color="default"
            style={{ opacity: 0.5 }}
            onClick={() =>
              onRun({ start: range.start.valueOf(), end: range.end.valueOf() })
            }
          >
            Try live pull
          </Button>
        </div>
        <div>
          <Button onClick={onCancel}>Cancel</Button>
          <ButtonWithPromise
            variant="contained"
            color="primary"
            pending="Scheduling imports..."
            onClick={() => onScheduleImport()}
          >
            Schedule batch import
          </ButtonWithPromise>
        </div>
      </DialogActions>
    </>
  );
};

const StyledDialog = styled(Dialog)`
  .MuiDialogActions-root {
    width: 100% !important;
  }
`;

const ImportingSalesWrapper = styled('div')`
  max-width: 400px;
  margin: 25px auto 125px;
  text-align: center;
`;

export const ApiRunDialog: React.FC<Props> = ({
  open,
  onClose,
  handler,
  partner,
  secret
}) => {
  const { space, id: userId } = useCurrentUser();
  const currency = useSpaceCurrency();
  const {
    value: result,
    error,
    loading,
    setLoading,
    setValue,
    setError
  } = useLoadingValue<ReportingApiResult | undefined>(undefined, false);

  const close = () => {
    onClose();
    setTimeout(() => {
      setValue(undefined);
    });
  };

  const onRun = ({ start, end }: { start: number; end: number }) =>
    getApiPreview({
      spaceId: secret.data.spaceId,
      integrationId: secret.data.instanceId,
      handler: handler.configName,
      start,
      end
    }).then(
      (r) => {
        console.log(r);
        return r;
      },
      (err) => {
        console.log('err', err);
        return Promise.reject(err);
      }
    );

  return (
    <StyledDialog
      open={open}
      onClose={close}
      maxWidth="xl"
      classes={{
        paper: css(() => ({
          width: result || loading ? '80%' : 'auto',
          maxHeight: '90%',
          minHeight: '300px'
        }))
      }}
    >
      <DialogTitle>
        <PartnerLogoWithName
          partner={{ ...partner, name: `${partner.name} Reporting API` }}
        />
      </DialogTitle>
      {loading && (
        <DialogContent>
          <ImportingSalesWrapper>
            <Loader height={100} />
            <Typography
              variant="h6"
              component="div"
              style={{ fontWeight: '700' }}
              paragraph
            >
              Importing your transactions...
            </Typography>
            <Typography variant="body1" color="textSecondary" component="div">
              This can take anywhere from a few seconds to a couple minutes
              depending on your transaction count, API limits, and the network
              response time.
            </Typography>
          </ImportingSalesWrapper>
        </DialogContent>
      )}
      {error && <ApiError onClose={close} />}
      {!loading && !result && !error && (
        <GetReport
          secret={secret}
          onRun={(d) => {
            setLoading(true);
            return onRun(d).then(setValue, setError);
          }}
          onCancel={close}
        />
      )}
      {result && (
        <ApiImportResults
          userId={userId}
          space={space}
          result={result}
          currency={currency}
          onDone={close}
          onClose={close}
          height={600}
        />
      )}
    </StyledDialog>
  );
};
