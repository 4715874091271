import React from 'react';
import { styled } from '../emotion';

const MiniTagWrapper = styled<
  'span',
  { isSelected: boolean; clickable: boolean }
>('span')`
  display: inline-block;
  font-size: 10px;
  padding: ${(p) => p.theme.spacing(0.25)}px ${(p) => p.theme.spacing(0.5)}px;
  margin-right: 4px;
  border: 1px solid
    ${(p) =>
      p.isSelected ? p.theme.palette.grey[500] : p.theme.palette.grey[300]};
  color: ${(p) =>
    p.isSelected ? p.theme.palette.grey[900] : p.theme.palette.grey[700]};
  background-color: ${(p) =>
    p.isSelected ? p.theme.palette.grey[100] : 'transparent'};
  border-radius: 4px;

  ${(p) =>
    p.clickable
      ? `
      &:hover {
        background-color: ${p.theme.palette.grey[100]};
        color: ${p.theme.palette.grey[900]};
        border: 1px solid ${p.theme.palette.grey[500]};
      `
      : ''}
  }
`;

export const MiniTag = ({
  label,
  isSelected,
  onClick
}: {
  label: React.ReactNode;
  isSelected?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
}) => {
  return (
    <MiniTagWrapper
      onClick={onClick}
      role={onClick ? 'button' : undefined}
      isSelected={!!isSelected}
      clickable={!!onClick}
    >
      {label}
    </MiniTagWrapper>
  );
};
