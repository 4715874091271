import React, { useState, useMemo } from 'react';
import {
  IExportColumn,
  IExportGroup,
  exportRowsToFile,
  renderHeads,
  renderRows
} from './service';
import {
  ButtonProps,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { useDialogState } from '../../hooks/useDialogState';
import { ButtonWithPromise } from '../ButtonWithPromise';
import { styled } from '../../emotion';
import { truncate } from 'lodash';
import { FileType, FILE_TYPE_OPTIONS } from '../../services/file';
import { ChevronUp, ChevronDown } from 'react-feather';

type ColumnSelectorProps<ColumnKey, RowData, OtherProps = undefined> = {
  value: ColumnKey[];
  onChange: (nextValue: ColumnKey[]) => void;
  columns: IExportColumn<ColumnKey, RowData, OtherProps>[];
  otherProps: OtherProps;
  groups?: IExportGroup<ColumnKey>;
};

const ColumnSelectorGrid = styled('div')`
  display: grid;
  grid-template-rows: repeat(11, 1fr);
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: 0;
  grid-auto-flow: column;
`;

export const ColumnSelector = <ColumnKey, RowData, OtherProps = undefined>({
  value,
  onChange,
  columns,
  otherProps,
  groups
}: ColumnSelectorProps<ColumnKey, RowData, OtherProps>) => {
  const set = useMemo(() => new Set(value), [value]);
  const add = (k: ColumnKey) => onChange([...value, k]);
  const remove = (k: ColumnKey) => onChange(value.filter((x) => x !== k));

  return (
    <ColumnSelectorGrid>
      {columns.map((c) => {
        const ui = c.ui ? c.ui(otherProps) : null;
        return (
          <FormControlLabel
            key={`${c.key}`}
            label={ui?.label || c.head(otherProps)}
            control={
              <Checkbox
                color="primary"
                checked={set.has(c.key)}
                onChange={(ev) =>
                  ev.target.checked ? add(c.key) : remove(c.key)
                }
              />
            }
          />
        );
      })}
    </ColumnSelectorGrid>
  );
};

type ExportPreviewProps<ColumnKey, RowData, OtherProps = undefined> = {
  rows: RowData[];
  otherProps: OtherProps;
  columns: IExportColumn<ColumnKey, RowData, OtherProps>[];
  maxShown?: number;
};

const PreviewTableContainer = styled('div')`
  max-width: 896px;
  overflow: auto;
  font-size: ${(p) => p.theme.custom.fontSize.s}px;

  margin-bottom: ${(p) => p.theme.spacing(3)}px;

  table,
  th,
  td {
    border: ${(p) => p.theme.custom.border.standard};
    border-collapse: collapse;
  }

  th,
  td {
    padding: ${(p) => p.theme.spacing()}px;
    white-space: nowrap;
  }
`;

export const ExportPreview = <ColumnKey, RowData, OtherProps = undefined>({
  rows,
  otherProps,
  columns,
  maxShown = 5
}: ExportPreviewProps<ColumnKey, RowData, OtherProps>) => {
  const heads = renderHeads(otherProps, columns);
  const rs = renderRows(rows.slice(0, maxShown), otherProps, columns);
  return (
    <PreviewTableContainer>
      <table>
        <thead>
          <tr>
            {heads.map((h, i) => (
              <th key={i}>{h}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rs.map((r, i) => (
            <tr key={i}>
              {r.map((c, ii) => (
                <td key={ii}>{truncate(c, { length: 60, omission: '…' })}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </PreviewTableContainer>
  );
};

export const ExportFormatSelector = ({
  value,
  onChange,
  label
}: {
  value: FileType;
  onChange: (nextValue: FileType) => void;
  label?: React.ReactNode;
}) => {
  const opts = FILE_TYPE_OPTIONS;
  return (
    <FormControl variant="outlined" fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        fullWidth={true}
        value={opts.findIndex((o) => o.value === value)}
        onChange={(ev) => onChange(opts[Number(ev.target.value)].value)}
      >
        {opts.map((o, i) => (
          <MenuItem key={i} value={i}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const TitleRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(p) => (p.onClick ? 'cursor: pointer;' : '')}
`;

export const ExportDialogInner = <ColumnKey, RowData, OtherProps = undefined>({
  onClose,
  title,
  description,
  fileName,
  rows,
  getAllRows,
  otherProps,
  columns,
  groups,
  maxPreviewed = 5,
  preselectedColumns
}: {
  onClose: () => void;
  title: React.ReactNode;
  description?: React.ReactNode;
  fileName: string;
  rows: RowData[];
  getAllRows?: () => Promise<RowData[]>;
  otherProps: OtherProps;
  columns: IExportColumn<ColumnKey, RowData, OtherProps>[];
  groups?: IExportGroup<ColumnKey>;
  maxPreviewed?: number;
  preselectedColumns?: ColumnKey[];
}) => {
  const [format, setFormat] = useState(FILE_TYPE_OPTIONS[0].value);
  const [fN, setFn] = useState(fileName);
  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<ColumnKey[]>(
    preselectedColumns || columns.map((c) => c.key)
  );

  const renderedColumns = useMemo(() => {
    const set = new Set(selectedColumns);
    return columns.filter((c) => set.has(c.key));
  }, [columns, selectedColumns]);

  const columnTitle =
    renderedColumns.length === columns.length
      ? 'All columns'
      : `${renderedColumns.length} of ${columns.length} columns`;

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {description}
        <h4>File Name</h4>
        <TextField
          fullWidth
          variant="outlined"
          value={fN}
          onChange={(ev) => setFn(ev.target.value)}
          required
        />
        <h4>Preview (First {maxPreviewed} rows)</h4>
        <ExportPreview
          rows={rows}
          otherProps={otherProps}
          columns={columns}
          maxShown={maxPreviewed}
        />
        <TitleRow onClick={() => setShowColumnSelector((x) => !x)}>
          <h4>{columnTitle}</h4>
          {showColumnSelector ? (
            <ChevronUp size={18} />
          ) : (
            <ChevronDown size={18} />
          )}
        </TitleRow>
        {showColumnSelector && (
          <ColumnSelector
            value={selectedColumns}
            onChange={setSelectedColumns}
            columns={columns}
            otherProps={otherProps}
            groups={groups}
          />
        )}
        <h4>Format</h4>
        <ExportFormatSelector value={format} onChange={setFormat} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <ButtonWithPromise
          variant="contained"
          color="primary"
          disabled={!fN}
          onClick={async () => {
            const rs = getAllRows ? await getAllRows() : rows;
            const columnsToExport = columns.filter((c) =>
              selectedColumns.includes(c.key)
            );
            return exportRowsToFile(
              fN,
              rs,
              otherProps,
              columnsToExport,
              format
            );
          }}
          pending="Exporting..."
        >
          Export
        </ButtonWithPromise>
      </DialogActions>
    </>
  );
};

export const ExportDialog = <ColumnKey, RowData, OtherProps = undefined>({
  open,
  onClose,
  ...other
}: {
  open: boolean;
  onClose: () => void;
  title: React.ReactNode;
  fileName: string;
  rows: RowData[];
  getAllRows?: () => Promise<RowData[]>;
  otherProps: OtherProps;
  columns: IExportColumn<ColumnKey, RowData, OtherProps>[];
  groups?: IExportGroup<ColumnKey>;
  maxPreviewed?: number;
  preselectedColumns?: ColumnKey[];
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <ExportDialogInner {...other} onClose={onClose} />
    </Dialog>
  );
};

// Pass some or all rows into this component.
// If you didn't pass all you want to export, provide getAllRows.
// If you already passed everything you want to export, there's no need for getAllRows
export const ExportButton = <ColumnKey, RowData, OtherProps = undefined>({
  dialogTitle,
  fileName,
  rows,
  getAllRows,
  otherProps,
  columns,
  groups,
  maxPreviewed,
  preselectedColumns,
  ...buttonProps
}: {
  dialogTitle: React.ReactNode;
  fileName: string;
  rows: RowData[];
  getAllRows?: () => Promise<RowData[]>;
  otherProps: OtherProps;
  columns: IExportColumn<ColumnKey, RowData, OtherProps>[];
  preselectedColumns?: ColumnKey[];
  groups?: IExportGroup<ColumnKey>;
  maxPreviewed?: number;
} & ButtonProps) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  return (
    <>
      <Button {...buttonProps} onClick={openDialog} />
      <ExportDialog
        open={dialogOpen}
        onClose={closeDialog}
        title={dialogTitle}
        fileName={fileName}
        preselectedColumns={preselectedColumns}
        rows={rows}
        getAllRows={getAllRows}
        otherProps={otherProps}
        columns={columns}
        groups={groups}
        maxPreviewed={maxPreviewed}
      />
    </>
  );
};
