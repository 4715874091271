import { capitalize, kebabCase } from 'lodash';
import React from 'react';
import { SelectSimple } from '../../SelectSimple';
import { Mode } from '../../../domainTypes/analytics';

export const ALL_MODES: Mode[] = [
  'absolute-numbers',
  'absolute-growth',
  'relative-growth'
];

export const ModeSelector = ({
  value,
  onChange
}: {
  value: Mode;
  onChange: (nextMode: Mode) => void;
}) => {
  return (
    <SelectSimple
      options={ALL_MODES}
      selected={value}
      setSelected={onChange}
      toLabel={(o) => kebabCase(o).split('-').map(capitalize).join(' ')}
      variant="chiplike"
      autoWidth
    />
  );
};
