import {
  queryParamToList,
  setToQueryParam,
  useComboQueryParam,
  useQueryParam
} from '../../../routes';
import { SortDirection } from '../../../hooks/useSort';
import { isSameSet } from '../../../services/set';
import { identity } from 'lodash';

export const useSortQueryParam = <ColumnName extends string>(
  paramName: string,
  columns: ColumnName[],
  defaultSort: {
    column: ColumnName;
    direction: SortDirection;
  }
) => {
  return useComboQueryParam<[ColumnName, SortDirection]>(
    paramName,
    ([column, direction]) => {
      const isValidColumn = column && columns.includes(column as ColumnName);
      const newColumn = isValidColumn
        ? (column as ColumnName)
        : defaultSort.column;
      const newDirection = direction
        ? (direction as SortDirection)
        : defaultSort.direction;

      return [newColumn, newDirection];
    },
    identity,
    '---',
    [columns]
  );
};

export const useColumnsQueryParam = <ColumnName extends string>(
  param: string,
  defaultColumns: ColumnName[]
) => {
  return useQueryParam(
    param,
    (p) => new Set(p ? queryParamToList<ColumnName>(p) : defaultColumns),
    (cs) =>
      isSameSet(cs, new Set(defaultColumns)) ? undefined : setToQueryParam(cs),
    [defaultColumns]
  );
};
