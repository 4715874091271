import { Chip } from '@material-ui/core';
import moment from 'moment-timezone';
import React from 'react';
import { DAY_FORMAT, Timeframe } from '../../domainTypes/analytics';
import { pluralize } from '../../services/pluralize';
import { timeframeToMoments } from '../../services/time';
import { ISOTimeRange } from '../../domainTypes/analytics_v2';
import { useCurrentUser } from '../../services/currentUser';

export const TimeframeDurationChip = ({ tf }: { tf: Timeframe }) => {
  const moments = timeframeToMoments(tf);
  const duration = pluralize('day', moments.end.diff(moments.start, 'd'), true);
  const isLastDays = tf.end === moment.tz(tf.tz).format(DAY_FORMAT);

  return (
    <Chip
      component="span"
      style={{ backgroundColor: '#ededed' }}
      label={`${isLastDays ? 'Last ' : ''}${duration}`}
    />
  );
};

export const RangeDurationChip = ({ range }: { range: ISOTimeRange }) => {
  const { tz } = useCurrentUser();
  const end = moment(range.end).add(1, 'ms');
  const duration = pluralize('day', end.diff(range.start, 'd'), true);
  const isLastDays = end.isSame(moment.tz(tz), 'day');
  return (
    <Chip
      component="span"
      style={{ backgroundColor: '#ededed' }}
      label={`${isLastDays ? 'Last ' : ''}${duration}`}
    />
  );
};
