import React from 'react';
import { Link } from 'react-router-dom';
import { AlertBox } from '../../../../../components/AlertBox';
import { useRoutes } from '../../../../../routes';

export const LinksEmptyState = ({
  hasVerifiedSites
}: {
  hasVerifiedSites: boolean;
}) => {
  const { ROUTES } = useRoutes();
  return (
    <AlertBox
      variant={hasVerifiedSites ? 'success' : 'pending'}
      style={{ marginBottom: '36px' }}
    >
      <strong>Your links have been imported for tracking!</strong>
      {hasVerifiedSites && (
        <p>
          We'll start collecting analytics for these links on your website.
          <br />
          <br />
          In the mean time, try{' '}
          <Link
            style={{ borderBottom: '1px solid black' }}
            to={ROUTES.performanceNew.transactions.url()}
          >
            setting up reporting
          </Link>{' '}
          with your affiliate networks and programs.
        </p>
      )}
      {!hasVerifiedSites && (
        <p>
          However, you haven't finished setting up the tracking on your website.{' '}
          <Link
            style={{ borderBottom: '1px solid black' }}
            to={ROUTES.dashboard.overview.url()}
          >
            Click here
          </Link>{' '}
          and click "Waiting for data" to enable tracking.
        </p>
      )}
    </AlertBox>
  );
};
