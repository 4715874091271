import { IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import {
  ChevronDown as IconExpand,
  ChevronUp as IconCollapse
} from 'react-feather';
import {
  ChartCard,
  ChartCardFooter,
  ChartCardFooterBar,
  ChartCardFooterBody,
  ChartCardFooterSelect,
  ChartFooterBarItem
} from '../../../../../components/Charts/ChartCard';
import {
  ALL_OPTIONS,
  Formatters,
  ITimeBasedCounter,
  OPTIONS,
  TrafficBiaxialChart
} from '../../../../../components/Charts/TrafficBiaxialChart';
import { styled } from '../../../../../emotion';
import {
  useColorsSpectrumPrimary,
  useColorsSpectrumSuccess
} from '../../../../../services/color';

const Vs = styled('div')`
  margin-left: ${(p) => p.theme.spacing(1)}px;
  margin-right: ${(p) => p.theme.spacing(1)}px;
`;

export const MonitoringChartCard = ({
  heading,
  loading,
  data,
  formatters,
  style,
  expandable,
  animate = false
}: {
  heading: string;
  loading: boolean;
  data: void | ITimeBasedCounter[];
  formatters: Formatters;
  style?: React.CSSProperties;
  expandable?: () => React.ReactNode | void;
  animate?: boolean;
}) => {
  const [showList, setShowList] = useState(false);
  const [yAxisLeft, setYAxisLeft] = useState(OPTIONS[0].value);
  const [yAxisRight, setYAxisRight] = useState(OPTIONS[2].value);
  const [primaryColor] = useColorsSpectrumPrimary();
  const [secondaryColor] = useColorsSpectrumSuccess();

  return (
    <ChartCard heading={heading} style={style}>
      <TrafficBiaxialChart
        yAxisRight={yAxisRight}
        yAxisLeft={yAxisLeft}
        loading={loading}
        counters={data}
        margin="normal"
        compare={false}
        animate={animate}
        colors={[primaryColor, secondaryColor]}
        options={ALL_OPTIONS}
        formatters={formatters}
        variant="minimal"
      />
      <ChartCardFooter>
        <ChartCardFooterBar>
          <ChartFooterBarItem>
            <ChartCardFooterSelect
              value={yAxisLeft}
              color={primaryColor}
              onChange={setYAxisLeft}
              options={OPTIONS.map((t) => ({
                label: t.label,
                value: t.value
              }))}
            />
            <Vs>vs.</Vs>
            <ChartCardFooterSelect
              color={secondaryColor}
              value={yAxisRight}
              onChange={setYAxisRight}
              options={OPTIONS.map((t) => ({ label: t.label, value: t.value }))}
            />
          </ChartFooterBarItem>

          {expandable && (
            <ChartFooterBarItem>
              <IconButton onClick={() => setShowList((s) => !s)}>
                {showList ? <IconExpand /> : <IconCollapse />}
              </IconButton>
            </ChartFooterBarItem>
          )}
        </ChartCardFooterBar>
        {expandable && showList && data && (
          <ChartCardFooterBody>{expandable() || null}</ChartCardFooterBody>
        )}
      </ChartCardFooter>
    </ChartCard>
  );
};
