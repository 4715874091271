export const required = (value: any) => (!!value ? undefined : 'Required');

export const beginsWithProtocol = (value: any) =>
  value && value.indexOf('https://') !== -1
    ? undefined
    : 'Your URL must start with https://';

export const isPositive = (n: number) =>
  !isNaN(n) && n > 0 ? undefined : 'Should be positive';

export const notEmpty = <T>(value: T[]) =>
  value.length > 0 ? undefined : 'Should not be empty';
