import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import React, { useState } from 'react';
import { withStoppedPropagation } from '../../../../../../helpers';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import { useCurrentUser } from '../../../../../../services/currentUser';
import { pluralize } from '../../../../../../services/pluralize';
import { deleteProducts } from '../../../../../../services/products';
import { flushPgProductsCacheForSpace } from '../../../../../../services/products/cache';
import * as tracking from '../../../../../../tracking';

export const ProductDeleteButton: React.FC<
  {
    productIds: string[];
    onSuccess?: () => void;
  } & ButtonProps
> = ({ productIds, onSuccess, children, ...other }) => {
  const { space } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const onConfirmedDelete = withStoppedPropagation(() => {
    tracking.sendEvent({
      category: tracking.toAppCategory(),
      action: 'Submit',
      label: 'Delete product form'
    });

    deleteProducts(space.id, productIds)
      .then(() => {
        setConfirmDialogOpen(false);
        enqueueSnackbar(`${pluralize('Product', productIds.length)} deleted`, {
          variant: 'success'
        });
        tracking.sendEvent({
          category: tracking.toAppCategory(),
          action: 'Delete products',
          label: productIds.join(', ')
        });
        flushPgProductsCacheForSpace(space.id);
        onSuccess && onSuccess();
      })
      .catch(() => {
        enqueueSnackbar(
          `${pluralize('Product', productIds.length)} could not be deleted`,
          { variant: 'error' }
        );
        tracking.sendEvent({
          category: tracking.toAppCategory(),
          action: 'Delete products (error)',
          label: productIds.join(', ')
        });
      });
  });

  const thisProduct = `${pluralize('this', productIds.length)} ${pluralize(
    'link',
    productIds.length
  )}`;
  return (
    <>
      <Button {...other} onClick={() => setConfirmDialogOpen(true)}>
        {children}
      </Button>
      <Dialog
        open={isConfirmDialogOpen}
        onClose={() => {
          setConfirmDialogOpen(false);
        }}
        maxWidth="sm"
      >
        <DialogTitle>
          Are you <em>sure</em> you want to delete {thisProduct}?
        </DialogTitle>
        <DialogContent>
          <Typography paragraph>
            You'll lose access to all historical data about {thisProduct}, and
            we won't track any views or clicks for{' '}
            {productIds.length === 1 ? 'it' : 'them'} anymore.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmDialogOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirmedDelete}
            variant="contained"
            color="secondary"
          >
            Yes, delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
