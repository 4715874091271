import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { ArrowRightCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import styled from '../../../../emotion/styled';
import { useRoutes } from '../../../../routes';

const Body = styled('div')`
  text-align: center;
  margin: ${(p) => p.theme.spacing(2)}px;
`;

const Img = styled('img')`
  width: 100%;
  display: block;
  margin: 0 auto ${(p) => p.theme.spacing(4)}px;
`;

export const IntegrationsStep = () => {
  const { ROUTES } = useRoutes();
  return (
    <Body>
      <Img src="/images/empty-states/integrations.png" />
      <Typography paragraph variant="h5">
        Import your affiliate commissions
      </Typography>
      <Typography paragraph>
        Last step! Clicks and views of your affiliate links are now tracking.
        Content analytics will appear by tomorrow.
        <br />
        <br />
        Finish setup by adding integrations and enabling revenue attribution.
      </Typography>
      <br />
      <Link to={ROUTES.performanceNew.transactions.url()}>
        <Button variant="contained" color="primary" size="large">
          Import your commissions &nbsp;{' '}
          <ArrowRightCircle
            size={18}
            style={{ position: 'relative', top: '1px' }}
          />
        </Button>
      </Link>
    </Body>
  );
};
