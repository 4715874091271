import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { HelpIcon } from '../../../../components/HelpIcon';
import { Loader } from '../../../../components/Loader';
import { NoPermissions } from '../../../../components/NoPermissions';
import { ScheduleFormSimple } from '../../../../components/Schedule/SimpleForm';
import { Doc } from '../../../../domainTypes/document';
import { Schedule } from '../../../../domainTypes/schedule';
import { CanvasBar } from '../../../../layout/Canvas';
import { LimitedWidth } from '../../../../layout/PageBody';
import { Section } from '../../../../layout/Section';
import { ARTICLES } from '../../../../services/beacon';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../services/currentUser';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { updateOrCreateSchedule } from '../../../../services/schedules/helper';
import { useLinkCheckSchedule } from '../../../../services/schedules/linkCheck';
import { AmazonApiSettings } from '../../components/AmazonApiSettings';
import { PageBodyLinks } from '../../components/PageBodyLinks';

export const PageLinksAmazonSettings = () => {
  const { space } = useCurrentUser();
  const [linkCheckScheduleDoc] = useLinkCheckSchedule(space.id);
  const scopes = useCurrentUserScopes();
  const canViewAmazonLinkSettings = scopes.has('link_checker.settings.view');
  const canEditLinkCheckSettings = scopes.has('link_checker.schedule.edit');

  useTrackMixpanelView('view_amazon_settings');

  const onChange = (schedule: Doc<Schedule>) =>
    updateOrCreateSchedule(schedule.id, schedule.data);

  return (
    <PageBodyLinks>
      <Helmet>
        <title>Amazon Link Settings | Affilimate</title>
      </Helmet>
      {canViewAmazonLinkSettings ? (
        <LimitedWidth width={800}>
          <Section>
            <CanvasBar>Amazon Link Check Settings</CanvasBar>
            <Card style={{ marginBottom: '24px' }}>
              <CardContent>
                <Typography
                  variant="body1"
                  component="p"
                  paragraph
                  style={{ fontWeight: 'bold' }}
                >
                  Set your link checking schedule &nbsp; &nbsp; &nbsp;
                  <HelpIcon
                    color="blue"
                    articleId={ARTICLES.links.amazonLinkChecker}
                  >
                    How it works
                  </HelpIcon>
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  color="textSecondary"
                  paragraph
                >
                  Choose which day of the week and when you want us to check
                  your Amazon links.
                </Typography>
                {linkCheckScheduleDoc ? (
                  <ScheduleFormSimple
                    label="Check for issues with Amazon links"
                    doc={linkCheckScheduleDoc}
                    onChange={onChange}
                    disabled={!canEditLinkCheckSettings}
                    readOnlyType
                  />
                ) : (
                  <Loader height={200} />
                )}
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <AmazonApiSettings space={space} />
              </CardContent>
            </Card>
          </Section>
        </LimitedWidth>
      ) : (
        <NoPermissions />
      )}
    </PageBodyLinks>
  );
};
