import { generateToDocFn, Doc } from '../../domainTypes/document';

import {
  IAutoLabelConfig,
  IAutoLabelConfigItem
} from '../../domainTypes/tracking';
import { store, useMappedLoadingValue } from '../db';
import { FS } from '../../versions';
import { useDocument } from 'react-firebase-hooks/firestore';

export const toAutoLabelConfigDoc = generateToDocFn<IAutoLabelConfig>(d => {
  if (d.v !== 2) {
    const items: IAutoLabelConfigItem[] = (d as any).items;
    d.partners = [...new Set(items.map(i => i.key))];
    d.v = 2;
  }
  return d;
});
export const emptyConfig = (spaceId: string): Doc<IAutoLabelConfig> => ({
  id: spaceId,
  collection: FS.autoLabelConfigs,
  data: {
    spaceId,
    partners: ['affilimate'],
    v: 2
  }
});

export const getBySpaceId = (spaceId: string) =>
  store()
    .collection(FS.autoLabelConfigs)
    .doc(spaceId)
    .get()
    .then(s => (s.exists ? toAutoLabelConfigDoc(s) : emptyConfig(spaceId)));

export const saveAutoLabelConfig = (doc: Doc<IAutoLabelConfig>) => {
  return store()
    .collection(FS.autoLabelConfigs)
    .doc(doc.id)
    .set(doc.data);
};

export const useAutoLabelConfig = (spaceId: string) => {
  return useMappedLoadingValue(
    useDocument(
      store()
        .collection(FS.autoLabelConfigs)
        .doc(spaceId)
    ),
    s => (s.exists ? toAutoLabelConfigDoc(s) : emptyConfig(spaceId))
  );
};
