import {
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  Tooltip
} from '@material-ui/core';
import { compact, some, sortBy } from 'lodash';
import pluralize from 'pluralize';
import React, { useEffect, useMemo, useState } from 'react';
import { AlertCircle as IconAlertCircle } from 'react-feather';
import { AlertBox } from '../../../components/AlertBox';
import { ButtonWithPromise } from '../../../components/ButtonWithPromise';
import { Loader } from '../../../components/Loader';
import { PartnerLogo, UnknownLogoChip } from '../../../components/PartnerLogo';
import { CurrencyCode } from '../../../domainTypes/currency';
import { IPartner } from '../../../domainTypes/partners';
import { IReportPreview } from '../../../domainTypes/performance';
import { css, styled } from '../../../emotion';
import { useBackgroundJobs } from '../../../hooks/useBackgroundJobs';
import { useCurrentUser } from '../../../services/currentUser';
import { Cio } from '../../../services/customerio';
import { getKnownPartnerForKey } from '../../../services/partner';
import { useTheme } from '../../../themes';
import { FileReportParseResult } from '../services/handlers/types';
import {
  saveParseResults,
  useTrackedSalesEventsForReports
} from '../services/report';
import { saveSalesFromReportsViaCf } from '../services/sale';
import { DEFAULT_SALESLIST_PAGE_SIZE, SalesList } from './SalesList';
import * as ENV from '../../../env.json';
import { ARTICLES } from '../../../services/beacon';
import { mixpanel } from '../../../services/mixpanel';
import { useFlushAnalyticsV2Cache } from '../../../services/analyticsV2/cache';

const ChipList = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > * {
    margin-right: ${(p) => p.theme.spacing(0.5)}px;
  }
`;

const ReportChip = ({
  label,
  onClick,
  partner,
  selected
}: {
  label: string;
  onClick: () => void;
  partner: IPartner | null;
  selected: boolean;
}) => {
  return (
    <Chip
      variant={selected ? 'default' : 'outlined'}
      color="primary"
      label={label}
      onClick={onClick}
      avatar={
        partner ? (
          <PartnerLogo partner={partner} chipStyle />
        ) : (
          <UnknownLogoChip label="?" chipStyle />
        )
      }
    />
  );
};

const NoReportChip = ({ label, error }: { label: string; error?: string }) => {
  const theme = useTheme();
  const msg =
    error === 'UNKNOWN_REPORT'
      ? 'File format was not recognized'
      : 'Unable to parse this file';
  return (
    <Tooltip title={msg}>
      <Chip
        label={label}
        avatar={<IconAlertCircle color={theme.palette.grey[500]} />}
        variant="outlined"
        classes={{
          root: css((t) => ({
            color: `${t.palette.grey[500]} !important`,
            backgroundColor: `${t.palette.common.white} !important`,
            borderColor: `${t.palette.grey[500]} !important`
          }))
        }}
      />
    </Tooltip>
  );
};

const ResultsList = ({
  results,
  selected,
  onReportClick
}: {
  results: FileReportParseResult[];
  selected: { [reportId: string]: boolean };
  onReportClick: (report: IReportPreview) => void;
}) => {
  return (
    <ChipList>
      {sortBy(results, (r) => r.report).map((r, i) => {
        const report = r.report;
        if (!report) {
          return (
            <NoReportChip key={i} label={r.file.name} error={r.errorType} />
          );
        }
        return (
          <ReportChip
            key={i}
            label={r.file.name}
            onClick={() => onReportClick(report)}
            selected={!!selected[report.reportId]}
            partner={getKnownPartnerForKey(report.partnerKey)}
          />
        );
      })}
    </ChipList>
  );
};

// Create an own saving step
// This step will allow to better track the progress of saving,
// chunking it up, reporting back on each individual chunk's progress

export const FileImportResults = ({
  results,
  onDone,
  onCancel,
  onError,
  currency,
  height
}: {
  results: FileReportParseResult[];
  onDone: () => void;
  onCancel: () => void;
  onError: (err: any) => void;
  currency: CurrencyCode;
  height: number;
}) => {
  const { space, id: userId } = useCurrentUser();
  const [page, setPage] = useState(1);
  const flushCache = useFlushAnalyticsV2Cache();
  const allReports = compact(results.map((r) => r.report));
  const [selected, setSelected] = useState(
    results.reduce<{ [key: string]: boolean }>((m, r) => {
      if (r.report) {
        m[r.report.reportId] = true;
      }
      return m;
    }, {})
  );

  const [sales, loading, error] = useTrackedSalesEventsForReports(
    space.id,
    userId,
    allReports,
    space.config.tz || 'UTC',
    currency
  );

  const { addJob } = useBackgroundJobs();

  useEffect(() => {
    if (error) {
      console.error(error);
      onError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  console.log(error);

  const filteredReports = allReports.filter((r) => !!selected[r.reportId]);
  const filteredSales = sales
    ? sales.filter((s) => !!selected[s.sale.reportId])
    : undefined;

  const hasError = useMemo(() => {
    return some(results.map((r) => r.errorType));
  }, [results]);

  if (loading || !filteredSales) {
    return (
      <DialogContent>
        <Loader height={height + 100} />
      </DialogContent>
    );
  }

  const onSave = () => {
    const selectedResults = results.filter(
      (r) => r.report && !!selected[r.report.reportId]
    );
    const request = saveParseResults(selectedResults, userId).then(
      async (res) => {
        const reportIds = res.map((r) => r.report.id);
        await saveSalesFromReportsViaCf(space.id, reportIds);
        setTimeout(() => {
          flushCache(space.id);
        }, 2000);
      }
    );
    addJob({
      job: () => request,
      onStart: () => {
        console.time('saving sales');
        return {
          message: (
            <>
              <CircularProgress color="inherit" size={16} /> &nbsp; Uploading
              your data...
            </>
          )
        };
      },
      onSuccess: () => {
        console.timeEnd('saving sales');
        const firstResult = results.length ? results[0] : null;
        const partnerKey = firstResult
          ? firstResult.report?.partnerKey || ''
          : '';

        Cio.track({
          name: 'upload_report',
          data: {
            value: partnerKey
          }
        });

        mixpanel().track('upload_report', {
          name: partnerKey,
          spaces: [space.id]
        });

        return {
          message: 'Your upload was successful!'
        };
      },
      onError: () => {
        console.timeEnd('saving sales');
        return {
          message: 'Upload failed, please try again or contact Support.'
        };
      }
    });
    // TODO show a dialog which tells people that they can do something else while this uploads
    onDone();
    return Promise.resolve();
  };

  return (
    <div>
      <DialogContent style={{ marginBottom: '48px' }}>
        {hasError && (
          <AlertBox variant="pending" style={{ marginBottom: '12px' }}>
            <strong>Sorry, we couldn't parse this report.</strong> Please
            double-check you've followed the report generation instructions
            precisely.{' '}
            <a
              href={`${ENV.domains.website.public}docs/${ARTICLES.transactions.formatNotRecognized}`}
              target="_blank"
              rel="noreferrer"
              style={{ borderBottom: '1px solid' }}
            >
              See common solutions
            </a>
          </AlertBox>
        )}
        <ResultsList
          results={results}
          selected={selected}
          onReportClick={(report) => {
            setSelected((s) => {
              const nextValue = !s[report.reportId];
              return { ...s, [report.reportId]: nextValue };
            });
          }}
        />
        <SalesList
          page={page}
          setPage={setPage}
          pageSize={DEFAULT_SALESLIST_PAGE_SIZE}
          sales={filteredSales}
          height={height}
          currency={currency}
        />
      </DialogContent>
      <DialogActions
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          width: '50%',
          backgroundColor: '#FFF',
          boxShadow: '-1px 0 10px rgb(0 0 0 / 10%)',
          zIndex: 1
        }}
      >
        <Button onClick={onCancel}>Cancel</Button>

        <ButtonWithPromise
          color="primary"
          variant="contained"
          pending="Uploading..."
          disabled={!filteredSales.length}
          onClick={onSave}
        >
          Upload {pluralize('sale', filteredSales.length, true)} from{' '}
          {pluralize('report', filteredReports.length, true)}
        </ButtonWithPromise>
      </DialogActions>
    </div>
  );
};
