import { styled } from '../../emotion';

export const Heading = styled('div')`
  font-size: ${(p) => p.theme.custom.fontSize.l}px;
  font-weight: 500;
`;

export const SubHeading = styled('div')`
  color: ${(p) => p.theme.palette.text.primary};
  font-size: ${(p) => p.theme.custom.fontSize.m}px;
  font-weight: 600;
`;

export const Hint = styled('div')`
  font-size: ${(p) => p.theme.custom.fontSize.s}px;
  color: gray;

  a {
    text-decoration: underline;
  }
`;

export const RAlign = styled('div')`
  text-align: right;
`;
