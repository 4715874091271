import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import { useState } from 'react';
import { X } from 'react-feather';
import { Dropzone } from '../../../../../../../components/Dropzone';
import { Loader } from '../../../../../../../components/Loader';

import { ImportedTag } from '../../../../../../../domainTypes/tags';
import { styled } from '../../../../../../../emotion';
import { Centered } from '../../../../../../../layout/Centered';
import { readCsvRows } from '../../../../../../../services/file';
import { BatchImportResults } from './BatchImportResults';

const Table = styled('table')`
  border: 1px solid ${(p) => p.theme.palette.grey[200]};
  margin: ${(p) => p.theme.spacing(4)}px 0;
  width: 100%;

  th,
  td {
    padding: ${(p) => p.theme.spacing(0.5)}px;
    border: 1px solid ${(p) => p.theme.palette.grey[400]};
  }
`;

export const BatchImportDialog = ({
  spaceId,
  open,
  onClose
}: {
  spaceId: string;
  open: boolean;
  onClose: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<ImportedTag[]>([]);

  const handleFiles = async (files: File[] | null) => {
    if (!files) {
      return;
    }

    setLoading(true);

    const importedTagAssociations = await Promise.all(
      files.map(async (file) => {
        const rows = await readCsvRows(file, {});
        return rows.slice(1, rows.length).map((row) => {
          const [url, name] = row;
          return { url, name };
        });
      })
    );

    setResults(importedTagAssociations.flat());
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} scroll="body">
      <DialogTitle
        disableTypography
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography
          variant="h6"
          style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
        >
          <div>Batch apply tags</div>
        </Typography>
        <IconButton onClick={onClose}>
          <X size={24} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!results.length && !loading && (
          <>
            <Typography
              variant="body1"
              component="p"
              color="textSecondary"
              paragraph
            >
              First, create any tags you want to apply to your content.
            </Typography>
            <Typography
              variant="body1"
              component="p"
              color="textSecondary"
              paragraph
            >
              Then, either batch apply tags directly through the Content report,
              or by uploading a CSV according to the format below:
            </Typography>
            <Table>
              <thead>
                <tr>
                  <th>Absolute URL</th>
                  <th>Tag</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>https://firstsite.com/best-review</td>
                  <td>Product review</td>
                </tr>
                <tr>
                  <td>https://secondsite.com/specific-page</td>
                  <td>Evergreen content</td>
                </tr>
              </tbody>
            </Table>
            <Dropzone
              onDrop={handleFiles}
              note="The CSV file should contain headers and the two columns listed above"
            />
          </>
        )}
        {loading && (
          <Centered height={200}>
            <Loader size={32} />
          </Centered>
        )}
        {results.length > 0 && (
          <BatchImportResults
            importedTags={results}
            spaceId={spaceId}
            onClose={onClose}
            setResults={setResults}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
