import { ButtonBase, Chip, Popover } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { isEqual } from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { TimeframeDefinition } from '../../domainTypes/timeframe';
import { styled } from '../../emotion';
import css from '../../emotion/css';
import { toTimeRanges } from '../../hooks/timeframe';
import { toMoments } from '../../hooks/timeframe/toMoments';
import { useCurrentUser } from '../../services/currentUser';
import { useMixpanel } from '../../services/mixpanel';
import { prettifyDateRange } from '../../services/time';
import { IComparisonOption, ITimeframeOption } from './service/options';
import { TimeframePickerMenu } from './TimeframePickerMenu';
import {
  findDefinitionLabels,
  timeframeComparisionDefinitionToTrackingProps,
  timeframeRangeDefinitionToTrackingProps
} from './useTimePickerMenuState';

const TimeframePickerContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const printDefinitionLabel = (
  definition: TimeframeDefinition,
  tz: string,
  timeframeOptions: ITimeframeOption[],
  comparisonOptions: IComparisonOption[]
) => {
  const { comparison, range } = definition;
  const { compare, start, end } = toMoments(definition, tz);

  const timeframeOption = timeframeOptions.find((option) =>
    isEqual(option.value, range)
  );
  const timeFrameLabel = timeframeOption
    ? timeframeOption.label
    : prettifyDateRange(start, end, tz);

  const comparisonLabel = (() => {
    if (!compare) return undefined;
    if (comparison.kind === 'disabled') return undefined;
    if (comparison.kind === 'timeshift') {
      const comparisonOption = comparisonOptions.find((option) =>
        isEqual(option.value, comparison)
      );
      if (comparisonOption) {
        return comparisonOption.label;
      }
    }
    return prettifyDateRange(compare.start, compare.end, tz);
  })();

  return (
    <Typography variant="caption">
      {timeFrameLabel}{' '}
      {comparisonLabel ? (
        <span className={css((t) => ({ color: t.palette.text.secondary }))}>
          vs. {comparisonLabel}
        </span>
      ) : (
        ''
      )}
    </Typography>
  );
};

export type TimeframePickerProps = {
  value: TimeframeDefinition;
  onChange: (nextValue: TimeframeDefinition) => void;
  timeframeOptions: ITimeframeOption[];
  comparisonOptions: IComparisonOption[];
};

export const TimeframePickerDense = ({
  value,
  onChange: onSelect,
  timeframeOptions,
  comparisonOptions
}: TimeframePickerProps) => {
  const { tz } = useCurrentUser();
  const mixpanel = useMixpanel();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  const closeMenu = useCallback(() => {
    setOpen(false);
    mixpanel.track('timeframe_picker_closed');
  }, [mixpanel]);
  const openMenu = useCallback(() => {
    setOpen(true);
    mixpanel.track('timeframe_picker_opened');
  }, [mixpanel]);

  const onSave = useCallback(
    (nextValue: TimeframeDefinition) => {
      onSelect(nextValue);
      const definitionLabels = findDefinitionLabels(
        nextValue,
        timeframeOptions,
        comparisonOptions,
        tz
      );
      mixpanel.track('timeframe_picker_applied', {
        _tf: toTimeRanges(nextValue, tz),
        ...timeframeRangeDefinitionToTrackingProps(nextValue.range),
        range_label: definitionLabels.timeframe,
        ...timeframeComparisionDefinitionToTrackingProps(nextValue.comparison),
        compare_range_label: definitionLabels.comparison || ''
      });
      closeMenu();
    },
    [closeMenu, mixpanel, onSelect, tz, timeframeOptions, comparisonOptions]
  );

  const label = useMemo(
    () => printDefinitionLabel(value, tz, timeframeOptions, comparisonOptions),
    [comparisonOptions, timeframeOptions, tz, value]
  );

  return (
    <TimeframePickerContainer>
      <Popover
        open={open}
        onClose={closeMenu}
        anchorEl={ref.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        style={{ marginTop: 4, zIndex: 99998 }}
      >
        <TimeframePickerMenu
          timeframeOptions={timeframeOptions}
          comparisonOptions={comparisonOptions}
          initialValue={value}
          onSave={onSave}
          onCancel={closeMenu}
        />
      </Popover>
      <ButtonBase ref={ref} onClick={openMenu}>
        <Chip
          label={
            <>
              {label}
              <ChevronDown
                size={16}
                className={css((t) => ({
                  marginLeft: t.spacing(1),
                  color: 'rgba(0, 0, 0, 0.54)'
                }))}
              />
            </>
          }
          classes={{
            label: css(() => ({
              display: 'inline-flex',
              alignItems: 'center'
            })),
            root: css(() => ({
              border: '1px solid rgb(0 0 0 / 6%)',
              '&:hover': {
                borderColor: 'rgba(0, 0, 0, 0.87) !important'
              }
            }))
          }}
        />
      </ButtonBase>
    </TimeframePickerContainer>
  );
};
