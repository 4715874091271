import React, { useMemo } from 'react';
import {
  ChartCard,
  ChartCardFooter
} from '../../../../../../components/Charts/ChartCard';
import { ReferrersChartFooter } from './ReferrersChartFooter';
import { COLORS } from '../services/groups';
import {
  toReferrerGroupTitle,
  useReferrerGroupTimeseries
} from '../services/referrer-groups';
import { Card } from '@material-ui/core';
import { Centered } from '../../../../../../layout/Centered';
import { ErrorMessage } from './ErrorMessage';
import { Loader } from '../../../../../../components/Loader';
import { useReportMetric } from '../services/report-metric';
import { ReferrerGroup } from '../../../../../../domainTypes/referrers';
import { TimeseriesChart } from '../../../../../../components/analytics_v2/Chart/TimeseriesChart';
import { xAxis } from '../../../../../../components/analytics_v2/Chart/xAxis';
import { yAxis } from '../../../../../../components/analytics_v2/Chart/yAxis';
import { tooltip } from '../../../../../../components/analytics_v2/Chart/tooltip';
import { areaSeries } from '../../../../../../components/analytics_v2/Chart/areaSeries';
import { useAnalyticsInterval } from '../../../../../../hooks/timeframe';
import {
  IntervalSelector,
  useIntervalSelectorState
} from '../../../../../../components/IntervalSelector';

interface ChartProps {
  group: ReferrerGroup;
}

export const OriginsTimeseriesChart: React.FC<ChartProps> = ({ group }) => {
  const [metric, setMetric] = useReportMetric();
  const metrics = useMemo(() => [metric], [metric]);
  const { interval, setInterval, options } = useIntervalSelectorState();
  const analyticsInterval = useAnalyticsInterval(interval);
  const [timeseries, loading, error] = useReferrerGroupTimeseries(
    metric,
    analyticsInterval
  );
  if (error) {
    return (
      <Card>
        <Centered height={350}>
          <ErrorMessage />
        </Centered>
      </Card>
    );
  }
  if (loading || !timeseries) {
    return (
      <Card>
        <Centered height={350}>
          <Loader size={32} />
        </Centered>
      </Card>
    );
  }

  return (
    <ChartCard
      noMaximize
      topRight={
        <IntervalSelector
          value={interval}
          onChange={setInterval}
          options={options}
        />
      }
    >
      <ChartCardFooter>
        <ReferrersChartFooter metric={metric} setMetric={setMetric} />
      </ChartCardFooter>
      <TimeseriesChart
        data={timeseries.rows}
        field="referrer_entry_origin"
        metrics={metrics}
        ResponsiveContainerProps={{ height: 300 }}
      >
        {({ context }) => {
          return [
            <defs>
              <linearGradient
                id={`${group}_gradient`}
                x1={0}
                x2={0}
                y1={0}
                y2={1}
              >
                <stop offset="5%" stopColor={COLORS[group]} stopOpacity={0.8} />
                <stop offset="95%" stopColor={COLORS[group]} stopOpacity={0} />
              </linearGradient>
            </defs>,
            xAxis(context, interval.unit, { textAnchor: 'middle' }),
            yAxis(metric, 'right', context),
            tooltip(context, interval.unit, { cursor: true }),
            areaSeries([group], metric, {
              name: toReferrerGroupTitle(group),
              fill: `url(#${group}_gradient)`,
              stroke: COLORS[group],
              fillOpacity: 1,
              strokeWidth: 1.5,
              strokeOpacity: 1
            })
          ];
        }}
      </TimeseriesChart>
    </ChartCard>
  );
};
