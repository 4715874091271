import { FormControlLabel, Switch } from '@material-ui/core';
import React from 'react';
import { css } from '../../emotion';

export const SwitchWithLabel = ({
  checked,
  onChange,
  label,
  fontSize
}: {
  checked: boolean;
  onChange: (nextChecked: boolean) => void;
  label: React.ReactNode;
  fontSize?: 'normal' | 'small';
}) => {
  const classes =
    fontSize === 'small'
      ? { label: css((t) => ({ fontSize: '0.8125rem' })) }
      : undefined;
  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          color="primary"
          onChange={(ev) => onChange(ev.target.checked)}
        />
      }
      classes={classes}
      label={label}
    />
  );
};
