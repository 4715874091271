import { styled } from '../emotion';

export const Badge = styled<'div', { color: string; bgColor: string }>('div')`
  background-color: ${(p) => p.bgColor};
  color: ${(p) => p.color};
  padding: 3px 12px;
  font-weight: 600;
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;

  svg {
    position: relative;
    top: 2px;
    left: -1px;
  }
`;
