import { Chip, CircularProgress } from '@material-ui/core';
import React from 'react';
import { css } from '../../emotion';
import { useTheme } from '../../themes';
import { useColors } from '../../services/color';

type IColor = {
  backgroundColor: string;
  color: string;
  isActive: boolean;
};

const DEFAULT_STEPS = [0, 0.01, 0.013, 0.02, 0.05, 0.1, 0.2];

const formatFn = (count: number) => {
  const label = count === 0 ? '0' : (count * 100).toFixed(1);
  return `${label}%`;
};

const CtrChip = ({
  color,
  backgroundColor,
  label,
  variant
}: {
  color: string;
  backgroundColor: string;
  label: React.ReactNode;
  variant?: 'filled' | 'outlined';
}) => (
  <Chip
    label={label}
    classes={{
      root: css((t) => ({
        color: variant === 'outlined' ? t.palette.text.primary : color,
        backgroundColor:
          variant === 'outlined' ? 'transparent' : backgroundColor,
        border:
          variant === 'outlined' ? `1px solid ${backgroundColor}` : 'none',
        fontWeight: 700,
        width: '60px'
      })),
      label: css((_) => ({
        textOverflow: 'inherit'
      }))
    }}
  />
);

export const CtrLoader = () => {
  const t = useTheme();
  return (
    <CtrChip
      label={<CircularProgress size={12} />}
      color={t.palette.text.primary}
      backgroundColor={t.custom.border.color}
      variant="outlined"
    />
  );
};

export const Ctr = ({
  rate,
  steps = DEFAULT_STEPS,
  format = formatFn
}: {
  rate: number;
  steps?: number[];
  format?: (count: number) => string;
}) => {
  const theme = useTheme();
  const COLORS = useColors();
  const [zero, lower, low, mid, high, higher, highest] = steps;

  const colors: IColor[] = [
    {
      backgroundColor: theme.palette.grey.A200,
      color: theme.palette.grey.A700,
      isActive: rate === zero
    },
    {
      backgroundColor: COLORS.red.red2,
      color: COLORS.red.red10,
      isActive: rate < lower
    },
    {
      backgroundColor: COLORS.orange.orange2,
      color: COLORS.orange.orange10,
      isActive: rate < low
    },
    {
      backgroundColor: COLORS.yellow.yellow2,
      color: COLORS.yellow.yellow10,
      isActive: rate < mid
    },
    {
      backgroundColor: COLORS.lime.lime3,
      color: COLORS.lime.lime10,
      isActive: rate < high
    },
    {
      backgroundColor: COLORS.green.green2,
      color: COLORS.green.green10,
      isActive: rate < higher
    },
    {
      backgroundColor: COLORS.green.green2,
      color: COLORS.green.green10,
      isActive: rate < highest
    },
    {
      backgroundColor: COLORS.green.green3,
      color: COLORS.green.green10,
      isActive: rate >= highest
    }
  ];

  const { color, backgroundColor } =
    colors.find((c) => c.isActive) || colors[0];
  const label = format(rate);

  return (
    <CtrChip label={label} color={color} backgroundColor={backgroundColor} />
  );
};
