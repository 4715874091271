import {
  AnalyticsField,
  AnalyticsFilter,
  AnalyticsInterval,
  AnalyticsOrderBy,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison,
  ISOTimeRange
} from '../../../../domainTypes/analytics_v2';
import { Metric } from '../../../../services/analyticsV2/metrics';
import { queryAnalyticsV2 } from '../../../../services/analyticsV2/query';

const TOP_N = 10;

export const timeseries = async (
  spaceId: string,
  range: ISOTimeRange,
  interval: AnalyticsInterval,
  metrics: Metric[],
  field: AnalyticsField,
  filters: AnalyticsFilter[]
): Promise<AnalyticsResponseRowWithComparison[]> => {
  const orderBy: AnalyticsOrderBy[] = metrics.map((metric) => ({
    field: metric,
    direction: 'DESC'
  }));
  const topQuery: AnalyticsQuery = {
    range,
    select: metrics,
    groupBy: [field],
    orderBy: orderBy,
    filters: [
      ...filters,
      {
        field: field,
        condition: 'not in',
        values: ['']
      }
    ],
    paginate: {
      page: 1,
      limit: TOP_N
    }
  };

  const topResults = await queryAnalyticsV2(spaceId, topQuery);
  const topValues = topResults.rows.map((row) => row.group[field]);

  const timeseriesFilters: AnalyticsFilter[] = [
    ...filters.filter((f) => f.field !== field),
    {
      field: field,
      condition: 'in',
      values: topValues
    }
  ];

  const timeseriesQuery: AnalyticsQuery = {
    range,
    select: metrics,
    groupBy: [field],
    orderBy: [
      {
        field: 'interval',
        direction: 'ASC'
      },
      ...orderBy
    ],
    interval: interval,
    filters: timeseriesFilters
  };

  const timeseries = await queryAnalyticsV2(spaceId, timeseriesQuery);
  return timeseries.rows;
};
