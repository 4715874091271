import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import React from 'react';
import { ButtonWithPromise } from '../../../components/ButtonWithPromise';
import { styled } from '../../../emotion';
import { useSnackbar } from '../../../hooks/useSnackbar';

const AlertImg = styled('img')`
  max-width: 80%;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  display: block;
  margin: 0 auto ${(props) => props.theme.spacing(4)}px;
`;

export const TransactionExportDialog = ({
  isOpen,
  onClose,
  onExport
}: {
  isOpen: boolean;
  onClose: () => void;
  onExport: () => Promise<void>;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Export Transactions to CSV</DialogTitle>
      <DialogContent>
        <Typography variant="body1" component="p" paragraph>
          The transaction export will export all commissions that match the
          currently selected filters and timeframe, along with most fields.
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          Exporting transactions can take anywhere from a few seconds to several
          minutes, depending on the number of commissions in the export. You
          will receive an <strong>Alert</strong> when the export is ready, like
          this:
        </Typography>
        <AlertImg src="/images/alert-example.png" alt="Alert Example" />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <ButtonWithPromise
          pending="Triggering export..."
          onClick={async () => {
            return onExport().then(() => {
              enqueueSnackbar(
                'Export triggered successfully. Watch for the download alert.',
                {
                  variant: 'success'
                }
              );
              onClose();
            });
          }}
          color="primary"
          variant="contained"
        >
          Trigger export
        </ButtonWithPromise>
      </DialogActions>
    </Dialog>
  );
};
