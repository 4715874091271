import { useDocument } from 'react-firebase-hooks/firestore';
import { IBillingData, isLockedOut } from '../../../domainTypes/billing';
import { ISpace } from '../../../domainTypes/space';
import {
  store,
  updateInTransaction,
  useMappedLoadingValue
} from '../../../services/db';
import { callFirebaseFunction } from '../../../services/firebaseFunctions';
import {
  CollectionListener,
  createSingleCollectionListenerStore,
  useCollectionListener
} from '../../../services/firecache/collectionListener';
import { toSpaceDoc } from '../../../services/space';
import { FS } from '../../../versions';

const spaceCollection = () => store().collection(FS.spaces);

export const TEST_SPACE_ID = '4oF26UAIvKcsv6YOxO7gtvrifrn2';

export const getTestSpace = () => {
  return spaceCollection().doc(TEST_SPACE_ID).get().then(toSpaceDoc);
};

export const useTestSpace = () => {
  return useMappedLoadingValue(
    useDocument(spaceCollection().doc(TEST_SPACE_ID)),
    toSpaceDoc
  );
};

export const getUserIdsInSpace = (space: ISpace) => {
  return Object.keys(space.permissions);
};

const getSpaceListener = createSingleCollectionListenerStore(
  () => new CollectionListener(spaceCollection(), toSpaceDoc)
);

export const getSpaces = () => {
  return getSpaceListener().get();
};

export const getSpaceIds = () => {
  return getSpaceListener()
    .get()
    .then((ds) => ds.map((s) => s.id));
};

export const getActiveSpaceIds = () => {
  return getSpaceListener()
    .get()
    .then((ds) => ds.filter((s) => s.data.active))
    .then((ds) => ds.map((s) => s.id));
};

export const updateSpace = (spaceId: string, updates: Partial<ISpace>) => {
  return spaceCollection().doc(spaceId).update(updates);
};

export const useSpaces = () => {
  return useCollectionListener(getSpaceListener());
};

export const useSpace = (spaceId: string) => {
  return useMappedLoadingValue(
    useDocument(spaceCollection().doc(spaceId)),
    (s) => (s.exists ? toSpaceDoc(s) : null)
  );
};

export const updateBillingData = (
  spaceId: string,
  data: Partial<IBillingData>
) => {
  const ref = spaceCollection().doc(spaceId);
  const n = Date.now();
  return updateInTransaction<ISpace>(ref, async (d) => {
    if (!d) {
      return null;
    }
    const nextD: ISpace = {
      ...d,
      billing: {
        ...d.billing,
        ...data
      }
    };
    return {
      ...nextD,
      active: !isLockedOut(nextD, n)
    };
  });
};

export const matchSpace = (space: ISpace, term: string) =>
  space.id.indexOf(term) !== -1 ||
  !!space.domains.find((d) => d.url.indexOf(term) !== -1) ||
  space.apiToken === term;

export const archiveSpace = async (spaceId: string, cleanup = false) => {
  return callFirebaseFunction('space-archiveSpace', { spaceId, cleanup });
};
