import { useCollection } from 'react-firebase-hooks/firestore';
import shortid from 'shortid';
import { Doc } from '../../domainTypes/document';
import { DailyEarningsReport2Schedule, Type } from '../../domainTypes/schedule';
import { FS } from '../../versions';
import { getCurrentUser } from '../currentUser';
import { useMappedLoadingValue } from '../db';
import { now } from '../time';
import { getSchedulesForSpaceByTypeQuery, toScheduleDoc } from './helper';

const TYPE: Type = 'DAILY_EARNINGS_REPORT2';

export const getDefaultDailyEarningsReportSchedule = (
  spaceId: string,
  createdBy: string,
  tz: string
): DailyEarningsReport2Schedule => ({
  spaceId,
  type: TYPE,
  frequency: {
    type: 'DAILY',
    hour: 10,
    tz
  },
  active: false,
  config: {},
  createdAt: now(),
  createdBy,
  lastRun: null,
  nextRun: null
});

export const useDailyEarningsReportSchedule = (spaceId: string) => {
  const extractDoc = (
    v: firebase.firestore.QuerySnapshot
  ): Doc<DailyEarningsReport2Schedule> => {
    if (v.empty) {
      return {
        id: shortid(),
        collection: FS.schedules,
        data: getDefaultDailyEarningsReportSchedule(
          spaceId,
          getCurrentUser().id,
          getCurrentUser().tz
        )
      };
    }
    if (v.size > 1) {
      console.warn(`Found more than 1 report schedule for ${spaceId}`);
    }
    return toScheduleDoc(v.docs[0]) as Doc<DailyEarningsReport2Schedule>;
  };

  return useMappedLoadingValue(
    useCollection(getSchedulesForSpaceByTypeQuery(spaceId, TYPE)),
    extractDoc
  );
};
