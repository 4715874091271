import { DimensionMenuComponent } from './Dimension';
import { TransactionStatusFilterDefinition } from '../../../../domainTypes/filters';
import { BASIC_MODES, FilterMenu } from './FilterMenu';
import { useCallback, useMemo, useState } from 'react';
import {
  createTransactionStatusFilterDefinition,
  useDimensionName,
  validateFilterDefinition
} from '../filters';
import { EnumSelectorMenu, useCollectionState } from './Selector';
import { isEqual } from 'lodash';
import {
  TRANSACTION_STATUSES,
  TRANSACTION_UI_CONFIG,
  TransactionStatus
} from '../../../../domainTypes/performance';
import pluralize from 'pluralize';
import { css } from '../../../../emotion';

// NOTE: to get rid of `readonly` modifier.
const options = [...TRANSACTION_STATUSES];

export const TransactionStatusMenu: DimensionMenuComponent<TransactionStatusFilterDefinition> = ({
  definition,
  onSave,
  range,
  filters
}) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const [value, toggleValue] = useCollectionState(definition.v);
  const newDefinition = useMemo<TransactionStatusFilterDefinition>(
    () => createTransactionStatusFilterDefinition(value),
    [value]
  );

  const enableSave =
    validateFilterDefinition(newDefinition) &&
    !isEqual(definition, newDefinition);

  const focusValue = (status: TransactionStatus) => {
    onSave(createTransactionStatusFilterDefinition([status]));
  };

  const toLabel = useCallback((status: TransactionStatus) => {
    const config = TRANSACTION_UI_CONFIG[status];
    return (
      <span
        className={css((t) => ({
          display: 'inline-block',
          fontSize: t.typography.caption.fontSize,
          borderRadius: '999px',
          padding: `${t.spacing(0.5)}px ${t.spacing(1.5)}px`,
          backgroundColor: config.color,
          color: config.text
        }))}
      >
        {config.label}
      </span>
    );
  }, []);

  return (
    <FilterMenu>
      <FilterMenu.Header dimension="transaction_status">
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <EnumSelectorMenu
          label={useDimensionName('transaction_status')}
          options={options}
          selectedValues={value}
          toggleValue={toggleValue}
          focusValue={focusValue}
          toLabel={toLabel}
          range={range}
          analyticsField={'sale_status'}
          queryFilters={filters}
        />
      </FilterMenu.Body>
      <FilterMenu.Footer definition={definition}>
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={`Filter by ${pluralize(
            'transaction status',
            value.length,
            true
          )}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
