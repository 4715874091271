import React from 'react';
import { Doc } from '../../../../../domainTypes/document';
import { ReleaseNotification } from '../../../../../domainTypes/notifications';
import { Frame } from '../../Item/Frame';

export const Release_ExtensionKlook = ({
  n
}: {
  n: Doc<ReleaseNotification>;
}) => {
  return (
    <Frame n={n}>Import your Klook reports via the Chrome Extension</Frame>
  );
};
