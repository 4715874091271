import {
  CampaignAdvertiserFilterDefinition,
  CampaignManagerFilterDefinition,
  CampaignStatusFilterDefinition
} from '../../../../domainTypes/filters';
import React from 'react';
import { BaseChip, formatList } from './index';
import { Dimension, useDimensionTitle } from '../filters';
import { useAdvertisersById } from '../Menus/Campaigns/AdvertiserMenu';
import { useCurrentUser } from '../../../../services/currentUser';
import {
  CampaignStatus,
  campaignStatusTitle
} from '../../../../features/Campaigns/service';
import { toUserLabel, useUsersInSpaceByIds } from '../../../../services/user';
import { useMappedLoadingValue } from '../../../../services/db';

export const CampaignStatusChip: React.FC<{
  definition: CampaignStatusFilterDefinition;
  onClick: () => void;
  onDelete: (dimension: Dimension) => void;
}> = ({ definition, onClick, onDelete }) => {
  const title = useDimensionTitle('campaign_status');
  return (
    <BaseChip
      title={`${title} is`}
      onClick={onClick}
      onDelete={() => onDelete('campaign_status')}
    >
      {formatList(
        definition.v.map((value) =>
          campaignStatusTitle(value as CampaignStatus)
        )
      )}
    </BaseChip>
  );
};

export const CampaignAdvertisersChip: React.FC<{
  definition: CampaignAdvertiserFilterDefinition;
  onClick: () => void;
  onDelete: (dimension: Dimension) => void;
}> = ({ definition, onClick, onDelete }) => {
  const title = useDimensionTitle('campaign_advertiser');
  const [advertisers = []] = useAdvertisersById(definition.v);
  return (
    <BaseChip
      title={`${title} is`}
      onClick={onClick}
      onDelete={() => onDelete('campaign_advertiser')}
    >
      {formatList(advertisers.map((a) => a.name))}
    </BaseChip>
  );
};

export const CampaignManagersChip: React.FC<{
  definition: CampaignManagerFilterDefinition;
  onClick: () => void;
  onDelete: (dimension: Dimension) => void;
}> = ({ definition, onClick, onDelete }) => {
  const title = useDimensionTitle('campaign_manager');
  const { space } = useCurrentUser();
  const [usersLabels = []] = useMappedLoadingValue(
    useUsersInSpaceByIds(space.id, definition.v),
    (usersPerSpace) => {
      if (!usersPerSpace) return [];
      return usersPerSpace.map((d) => toUserLabel(d));
    }
  );
  return (
    <BaseChip
      title={`${title} is`}
      onClick={onClick}
      onDelete={() => onDelete('campaign_manager')}
    >
      {formatList(usersLabels)}
    </BaseChip>
  );
};
