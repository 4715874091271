import React from 'react';

type Props = {
  width?: string | number;
};

const DEFAULT_WIDTH = '20px';

export const AffilimateLogo: React.FC<Props> = ({ width = DEFAULT_WIDTH }) => {
  return (
    <img
      src="https://storage.googleapis.com/affilimate-assets/logos/icon-white-circle.png"
      width={width}
      alt="Affilimate"
    />
  );
};

export const AffilimateLogoWide = ({
  style
}: {
  style?: React.CSSProperties;
}) => {
  return (
    <img
      style={style}
      height="18px"
      src="https://storage.googleapis.com/affilimate-assets/logos/logo-black-full.png"
      alt="Affilimate"
    />
  );
};
