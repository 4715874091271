import { useDocument, useCollection } from 'react-firebase-hooks/firestore';
import { Doc } from '../../domainTypes/document';
import { SalesApiFetchSchedule, Type } from '../../domainTypes/schedule';
import { useCurrentUser } from '../currentUser';
import { useMappedLoadingValue } from '../db';
import { now } from '../time';
import {
  getScheduleByIdQuery,
  toScheduleDoc,
  getSchedulesForSpaceByTypeQuery,
  updateOrCreateSchedule
} from './helper';
import { keyBy, compact } from 'lodash';

const TYPE: Type = 'SALES_API_FETCH';

const toId = (spaceId: string, handler: string) =>
  `salesApiFetchSchedule-${spaceId}-${handler}`;

const getDefaultSalesApiFetchSchedule = (
  spaceId: string,
  handler: string,
  createdBy: string,
  tz: string
): SalesApiFetchSchedule => ({
  spaceId,
  type: TYPE,
  frequency: {
    type: 'DAILY',
    hour: 0,
    tz
  },
  active: false,
  config: {
    handler
  },
  createdAt: now(),
  createdBy,
  lastRun: null,
  nextRun: null
});

const toDefaultDoc = (
  spaceId: string,
  handler: string,
  createdBy: string,
  tz: string
) => {
  const id = toId(spaceId, handler);
  return {
    id,
    data: getDefaultSalesApiFetchSchedule(spaceId, handler, createdBy, tz)
  };
};

export const useSalesApiFetchScheduleForHandlers = (
  spaceId: string,
  handlers: string[]
) => {
  const { id: userId, tz } = useCurrentUser();
  return useMappedLoadingValue(
    useCollection(getSchedulesForSpaceByTypeQuery(spaceId, TYPE)),
    s => {
      const docs = s.docs.map(
        d => toScheduleDoc(d) as Doc<SalesApiFetchSchedule>
      );
      const docsByHandler = keyBy(docs, d => d.data.config.handler);
      return compact(
        handlers.map(h => {
          const doc = docsByHandler[h];
          return doc || toDefaultDoc(spaceId, h, userId, tz);
        })
      );
    }
  );
};

export const useSalesApiFetchScheduleForHandlerWithoutDefault = (
  spaceId: string,
  handler: string
) => {
  const id = toId(spaceId, handler);
  return useMappedLoadingValue(useDocument(getScheduleByIdQuery(id)), s => {
    return s.exists ? (toScheduleDoc(s) as Doc<SalesApiFetchSchedule>) : null;
  });
};

export const useSalesApiFetchScheduleForHandler = (
  spaceId: string,
  handler: string
) => {
  const { id: userId, tz } = useCurrentUser();
  return useMappedLoadingValue(
    useSalesApiFetchScheduleForHandlerWithoutDefault(spaceId, handler),
    doc => doc || toDefaultDoc(spaceId, handler, userId, tz)
  );
};

export const setupSalesApiFetchScheduleForHandler = (
  spaceId: string,
  handler: string,
  userId: string,
  tz: string
) => {
  const doc = toDefaultDoc(spaceId, handler, userId, tz);
  return updateOrCreateSchedule(doc.id, {
    ...doc.data,
    active: true
  });
};
