import firebase from 'firebase/app';
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Typography
} from '@material-ui/core';
import { useState } from 'react';
import { ArrowRightCircle } from 'react-feather';
import { AlertBox } from '../../../../components/AlertBox';
import {
  AcceptInvitationResponse,
  ICurrentUser,
  MinimalInvitation
} from '../../../../domainTypes/user';
import { styled } from '../../../../emotion';
import { usePromise } from '../../../../hooks/usePromise';
import { useRoutes } from '../../../../routes';
import { signOutWithoutRedirect } from '../../../../services/auth';
import { callFirebaseFunction } from '../../../../services/firebaseFunctions';
import { pluralize } from '../../../../services/pluralize';
import { capitalize } from 'lodash';

const acceptInvitation = (invitationId: string, password: string) => {
  return callFirebaseFunction<AcceptInvitationResponse>(
    'user-acceptInvitation',
    { invitationId, password }
  );
};

const getInvitationData = (invitationId: string) => {
  return callFirebaseFunction<MinimalInvitation>(
    'user-getMinimalInvitationData',
    { invitationId }
  );
};

const Wrapper = styled('div')`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f9;

  > img {
    margin-bottom: ${(p) => p.theme.spacing()}px;
    margin-right: ${(p) => p.theme.spacing()}px;
  }
`;

const Loader = styled(CircularProgress)`
  width: 24px !important;
  height: 24px !important;
  margin-right: ${(p) => p.theme.spacing(1)}px;
  display: block;

  svg {
    color: ${(p) => p.theme.palette.primary.main};
  }
`;

const FormRowDouble = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-column-gap: 12px;
`;

const TermsAndConditions = styled('p')`
  color: ${(p) => p.theme.palette.grey[500]};
  font-size: 12px;
  text-align: left;
  line-height: 18px;

  a {
    border-bottom: 1px solid;
  }
`;

const SignupForm = ({
  invitationId,
  invitation
}: {
  invitationId: string;
  invitation: MinimalInvitation;
}) => {
  const [password, setPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [warning, setWarning] = useState('');
  const [loading, setLoading] = useState(false);
  const handleChangeTermsAccepted = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTermsAccepted(event.target.checked);
  };

  const createUser = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!termsAccepted) {
      setWarning(
        'You must accept our terms and privacy policy to use our service. Please check the box to continue.'
      );
      return;
    }

    if (password.length < 8) {
      setWarning(
        "Please provide a password that's at least 8 characters long."
      );
      return;
    }

    setLoading(true);
    let result: AcceptInvitationResponse | undefined = undefined;

    try {
      result = await acceptInvitation(invitationId, password);
      await firebase
        .auth()
        .signInWithEmailAndPassword(invitation.email, password);

      // Go to the home route, and the homePath
      // will be determined by the current user
      window.location.assign('/');
    } catch (err) {
      if (result && result.status === 'ERROR') {
        if (result.details.type === 'WEAK_PASSWORD') {
          const { requirements: reqs } = result.details;
          setWarning(
            `Your password is too weak. Please provide a password with at least ${
              reqs.minimumLength
            } characters, ${reqs.minimumLowerCaseChars} lowercase ${pluralize(
              'character',
              reqs.minimumLowerCaseChars
            )}, ${reqs.minimumUpperCaseChars} uppercase ${pluralize(
              'character',
              reqs.minimumUpperCaseChars
            )}, and at least ${reqs.minimumSymbols} ${pluralize(
              'symbol',
              reqs.minimumSymbols
            )}.`
          );
        } else if (result.details.type === 'INVITATION_EXPIRED') {
          setWarning(
            'This invitation has expired. Please ask your workspace owner to re-send the invite.'
          );
        } else if (result.details.type === 'INVITATION_NOT_FOUND') {
          setWarning('This invitation ID has not been found.');
        } else if (result.details.type === 'INVITATION_ALREADY_ACCEPTED') {
          setWarning('This invitation has already been accepted');
        } else {
          setWarning('An unknown error occurred, please contact Support');
        }
      } else {
        setWarning('An unknown error occurred, please contact Support');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Typography
        variant="h6"
        component="h1"
        style={{ fontWeight: 'bold' }}
        paragraph
      >
        Accept invitation to join
      </Typography>
      <Typography variant="body1" component="p" paragraph>
        Create your account to accept the invitation to join this workspace on
        Affilimate.
      </Typography>
      <form onSubmit={createUser}>
        <TextField
          variant="outlined"
          name="email"
          type="email"
          id="email"
          label="Email address"
          value={invitation.email}
          autoComplete="email"
          margin="normal"
          disabled
          fullWidth
        />
        <TextField
          variant="outlined"
          name="password"
          autoComplete="new-password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          type="password"
          placeholder="8 characters or more"
          label="Password"
          margin="normal"
          fullWidth
        />
        <FormRowDouble style={{ marginTop: '36px' }}>
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                checked={termsAccepted}
                onChange={handleChangeTermsAccepted}
              />
            }
            label={
              <TermsAndConditions>
                I agree to Affilimate's{' '}
                <a
                  href="https://affilimate.com/qs/terms-of-service/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  terms of service
                </a>{' '}
                and{' '}
                <a
                  href="https://affilimate.com/qs/privacy-policy/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  privacy policy
                </a>
                .
              </TermsAndConditions>
            }
          />
          <Button
            type="submit"
            size="large"
            variant="contained"
            disabled={loading}
            color="primary"
          >
            {loading ? (
              <>
                <CircularProgress size={20} /> Creating account
              </>
            ) : (
              <>
                <ArrowRightCircle size={18} /> &nbsp; Create account
              </>
            )}
          </Button>
        </FormRowDouble>
        {warning && <AlertBox variant="pending">{warning}</AlertBox>}
      </form>
    </div>
  );
};

const InviteExpired = () => {
  return (
    <div>
      <Typography
        variant="h6"
        component="h1"
        style={{ fontWeight: 'bold' }}
        paragraph
      >
        Invitation expired
      </Typography>
      <Typography variant="body1" component="p" paragraph>
        This invitation has expired. Please request a new invitation from your
        workspace owner.
      </Typography>
    </div>
  );
};

const LogInToAccept = () => {
  return (
    <div>
      <Typography
        variant="h6"
        component="h1"
        style={{ fontWeight: 'bold' }}
        paragraph
      >
        Log in to accept
      </Typography>
      <Typography variant="body1" component="p" paragraph>
        You've been invited to join an Affilimate workspace. Log in with your
        existing account, and then visit this link again to accept the
        invitation.
      </Typography>
      <Button href="/" variant="contained" size="large" color="primary">
        Log in
      </Button>
    </div>
  );
};

const AlreadyLoggedIn = ({ invitationId }: { invitationId: string }) => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const { ROUTES, goTo } = useRoutes();

  const handleAccept = async () => {
    try {
      setLoading(true);
      const result = await acceptInvitation(invitationId, '');
      if (result.status === 'OK') {
        goTo(ROUTES.dashboard.overview.url());
        return;
      }
      if (result.status === 'ERROR') {
        setErrorMsg(capitalize(result.details.type).replaceAll('_', ' '));
      }
    } catch (err) {
      setErrorMsg('An unknown error occurred, please contact Support');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Typography
        variant="h6"
        component="h1"
        style={{ fontWeight: 'bold' }}
        paragraph
      >
        Accept invitation
      </Typography>
      <Typography variant="body1" component="p" paragraph>
        You've been invited to join an Affilimate workspace. Click the button
        below to claim your invitation.
      </Typography>
      <Button
        size="large"
        variant="contained"
        color="primary"
        onClick={handleAccept}
        disabled={loading}
      >
        Accept invitation
      </Button>
      {errorMsg && <AlertBox variant="error">{errorMsg}</AlertBox>}
    </div>
  );
};

const NonMatchingEmail = ({
  currentUser,
  invitationId,
  invitation
}: {
  currentUser: ICurrentUser | null;
  invitationId: string;
  invitation: MinimalInvitation;
}) => {
  const onClick = () => {
    signOutWithoutRedirect();
    window.location.assign(`/?invitationId=${invitationId}`);
  };

  return (
    <div>
      <div>
        <Typography
          variant="h6"
          component="h1"
          style={{ fontWeight: 'bold' }}
          paragraph
        >
          Claim your invite
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          You've been invited to join a workspace with the email address{' '}
          <strong>{invitation.email}</strong>.
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          To accept the invitation, log out of your current account, and log in
          with the invited account's email and password and return to this link.
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          Or, ask the workspace owner to re-send the invitation to your current
          account's email address (<strong>{currentUser!.email}</strong>).
        </Typography>
      </div>
      <Button variant="contained" color="primary" onClick={onClick}>
        Log out and claim
      </Button>
    </div>
  );
};

export const PageAcceptInvitation = ({
  currentUser,
  invitationId
}: {
  currentUser: ICurrentUser | null;
  invitationId: string;
}) => {
  const [invitation, loading, error] = usePromise(
    () => getInvitationData(invitationId),
    [invitationId]
  );

  console.log(invitation, loading, error);

  if (loading) {
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }

  if (!invitation) {
    return (
      <Wrapper>
        <Typography variant="body1">No invitation found</Typography>
      </Wrapper>
    );
  }

  const showInviteExpired = invitation.isExpired;

  const showSignupForm = !invitation.isExpired && !invitation.userExistsAlready;

  const showNonMatchingEmails =
    !invitation.isExpired &&
    invitation.userExistsAlready &&
    currentUser &&
    invitation.email !== currentUser?.email;

  const showAlreadyLoggedIn =
    !invitation.isExpired &&
    invitation.userExistsAlready &&
    currentUser &&
    currentUser.email === invitation.email;

  const showLoginToAccept =
    !invitation.isExpired && invitation.userExistsAlready && !currentUser;

  return (
    <Wrapper>
      <div>
        <div style={{ textAlign: 'center' }}>
          <img
            src="https://storage.googleapis.com/affilimate-assets/logos/logo-black-full.png"
            width="120px"
            alt="Affilimate"
            style={{ display: 'block', margin: '48px auto' }}
          />
        </div>
        <Card
          style={{
            margin: '0 auto',
            minWidth: '500px',
            maxWidth: '50%',
            padding: '24px'
          }}
        >
          {showInviteExpired && <InviteExpired />}
          {showSignupForm && (
            <SignupForm invitationId={invitationId} invitation={invitation} />
          )}
          {showNonMatchingEmails && (
            <NonMatchingEmail
              invitationId={invitationId}
              currentUser={currentUser}
              invitation={invitation}
            />
          )}
          {showAlreadyLoggedIn && (
            <AlreadyLoggedIn invitationId={invitationId} />
          )}
          {showLoginToAccept && <LogInToAccept />}
        </Card>
      </div>
    </Wrapper>
  );
};
