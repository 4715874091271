import { styled } from '../../emotion';

/*

This e.g. works nicely in a dialog modal, using it like this:

<Dialog>
  <BlurredOutOverlay>
    <div>
      <p>Some Text</p>
      <p>More Text</p>
    </div>
  </BlurredOutOverlay>
  <DialogTitle>Your Title</DialogTitle>
  <DialogContent>Your Content</DialogContent>
  <DialogActions>Your Content</DialogActions>
</Dialog>

*/
export const BlurredOutOverlay = styled('div')((p) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(3px)',
  zIndex: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));
