import {
  SingleSelector,
  SingleSelectorChip
} from '../../../../../../components/SingleSelector';
import { metricTitle } from '../../../../../../services/analyticsV2/metrics';
import { reportMetrics, useReportMetric } from '../services/report-metric';

const options = reportMetrics.map((metric) => {
  const label = metricTitle(metric);
  return {
    value: metric,
    label,
    searchValue: label
  };
});

export const MetricSelector = () => {
  const [metric, setMetric] = useReportMetric();
  return (
    <SingleSelector
      legend="Highlighted metric"
      value={metric}
      onChange={setMetric}
      options={options}
    >
      <SingleSelectorChip
        isApplied={true}
        label={`By ${metricTitle(metric)}`}
        appliedLabel={`By ${metricTitle(metric)}`}
      />
    </SingleSelector>
  );
};
