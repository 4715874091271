import { MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';
import { css } from '../../emotion';

type Props<T> = {
  options: T[];
  selected: T;
  setSelected: (nextSelected: T) => void;
  toLabel: (option: T) => React.ReactNode;
  autoWidth?: boolean;
  size?: 'normal' | 'small';
  disabled?: boolean;
  disableUnderline?: boolean;
  variant?: 'filled' | 'outlined' | 'standard' | 'chiplike';
};

export const SelectSimple = <T extends {}>({
  options,
  selected,
  setSelected,
  toLabel,
  autoWidth = true,
  disabled,
  disableUnderline,
  variant,
  size
}: Props<T>) => {
  const [open, setOpen] = useState(false);
  const v = variant === 'chiplike' ? 'outlined' : variant;
  const classes =
    variant === 'chiplike'
      ? {
          select: css((t) => ({
            fontSize: 13,
            padding: '7px 32px 7px 12px'
          }))
        }
      : size === 'small'
      ? {
          select: css((t) => ({
            fontSize: t.custom.fontSize.m,
            padding: '7px 32px 7px 12px'
          }))
        }
      : undefined;

  return (
    <Select
      classes={classes}
      disabled={disabled}
      variant={v}
      autoWidth={autoWidth}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={options.indexOf(selected)}
      onChange={(ev) => setSelected(options[Number(ev.target.value)])}
    >
      {options.map((o, i) => (
        <MenuItem key={i} value={i}>
          {toLabel(o)}
        </MenuItem>
      ))}
    </Select>
  );
};
