import { Paper } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { ToolsPageBody } from '../../components/ToolsPageBody';
import { styled } from '../../../../emotion';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { useTrackMixpanelView } from '../../../../services/mixpanel';
import { NoPermissions } from '../../../../components/NoPermissions';
import { LinkGenerator } from './LinkGenerator';
import { sortBy } from 'lodash';
import { IPartner, PARTNERS } from '../../../../domainTypes/partners';
import { PlatformWithColor } from '../../../../components/PlatformWithColor';
import { CanvasBar } from '../../../../layout/Canvas';

const WIDTH = 500;

const PageWrapper = styled('div')`
  margin: 0 auto;
  max-width: 700px;
`;

const ColumnWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 150px;
  grid-column-gap: ${(p) => p.theme.spacing(2)}px;
`;

const ToolColumn = styled('div')`
  max-width: ${WIDTH}px;
`;

const SUPPORTED_PLATFORMS = sortBy(
  PARTNERS.filter(
    (p) =>
      [
        'admitad',
        'amazon',
        'avantlink',
        'awin',
        'belboon',
        'cj',
        'howl',
        'digidip',
        'impact',
        'levanta',
        'partnerize',
        'pepperjam',
        'rakuten',
        'refersion',
        'shareasale',
        'skimlinks',
        'sovrn'
      ].indexOf(p.key) !== -1
  ),
  'name'
);

const PLATFORMS_WITH_ADJUSTMENTS_NEEDED: Partial<Record<
  IPartner['key'],
  string
>> = {
  sovrn:
    'Ensure your API connection includes the "API Key" and not only "Secret key".',
  rakuten:
    'Ensure you have added the Rakuten Link Generator connection in addition to the transactions connection.',
  skimlinks:
    'Ensure you have provided a site configuration as part of the integration.'
};

const LinkGeneratorWrapper = () => {
  const currentUser = useCurrentUser();
  const { space } = currentUser;

  return (
    <ColumnWrapper>
      <ToolColumn>
        <Paper style={{ position: 'relative' }}>
          <div style={{ marginRight: '-16px' }}>
            <LinkGenerator prefilledUrl="" space={space} />
          </div>
        </Paper>
      </ToolColumn>
      <div>
        <CanvasBar>Supported platforms</CanvasBar>
        {SUPPORTED_PLATFORMS.map((p) => (
          <PlatformWithColor
            partner={p}
            key={p.key}
            info={PLATFORMS_WITH_ADJUSTMENTS_NEEDED[p.key]}
          />
        ))}
      </div>
    </ColumnWrapper>
  );
};

export const PageToolsLinkGenerator = () => {
  const [canUseLinkGenerator] = useHasCurrentUserRequiredScopes([
    'link_generator.use'
  ]);
  useTrackMixpanelView('view_link_generator');

  return (
    <ToolsPageBody>
      <Helmet>
        <title>Link Generator | Affilimate</title>
      </Helmet>
      <PageWrapper>
        {canUseLinkGenerator ? <LinkGeneratorWrapper /> : <NoPermissions />}
      </PageWrapper>
    </ToolsPageBody>
  );
};
