import { CampaignAdvertiserFilterDefinition } from '../../../../../domainTypes/filters';
import React, { useCallback, useMemo, useState } from 'react';
import { BASIC_MODES, FilterMenu } from '../FilterMenu';
import {
  createCampaignAdvertiserFilterDefinition,
  initFilterValue,
  validateFilterDefinition
} from '../../filters';
import {
  OptionsList,
  SelectorLoader,
  SelectorShell,
  toggle
} from '../Selector';
import { usePromise } from '../../../../../hooks/usePromise';
import { useCurrentUser } from '../../../../../services/currentUser';
import {
  getAdvertisersByIds,
  searchAdvertisers,
  toAdvertiserDoc
} from '../../../../../services/advertisers';
import { isEqual } from 'lodash';
import pluralize from 'pluralize';
import { AdvertiserLabel } from '../../../../../features/Campaigns/service/advertiser';
import { CachedAdvertiser } from '../../../../../domainTypes/advertiser';
import { CampaignAdvertiser } from '../../../../../domainTypes/campaigns';
import { Doc } from '../../../../../domainTypes/document';
import { useSecrets } from '../../../../../services/secret';
import { SECRET_CATEGORY } from '../../../../../domainTypes/reporting';
import { ISecretWithTs } from '../../../../../domainTypes/secret';

const toCampaignAdvertiser = (
  advertiser: Doc<CachedAdvertiser>,
  secrets: Doc<ISecretWithTs>[]
): CampaignAdvertiser & { nickname?: string } => {
  const { data } = advertiser;
  const secret = secrets.find((s) => s.data.instanceId === data.integrationId);
  return {
    advertiserId: data.advertiserId,
    name: data.name,
    partnerKey: data.partnerKey,
    integrationId: data.integrationId,
    nickname: secret?.data.nickname
  };
};

export const useAdvertisersById = (ids: string[]) => {
  const { space } = useCurrentUser();
  const [secrets = []] = useSecrets(space.id, SECRET_CATEGORY);
  return usePromise(async () => {
    if (!ids.length) return [];
    const snapshot = await getAdvertisersByIds(space.id, ids).get();
    return snapshot.docs.map((d) =>
      toCampaignAdvertiser(toAdvertiserDoc(d), secrets)
    );
  }, [ids]);
};

const useAdvertisersByName = (search: string) => {
  const { space } = useCurrentUser();
  const [secrets = []] = useSecrets(space.id, SECRET_CATEGORY);
  console.log(
    'DBG',
    secrets.map((s) => ({ id: s.data.instanceId, n: s.data.nickname }))
  );
  return usePromise(async () => {
    const snapshot = await searchAdvertisers(
      space.id,
      search.toLocaleLowerCase()
    ).get();

    return snapshot.docs.map((d) =>
      toCampaignAdvertiser(toAdvertiserDoc(d), secrets)
    );
  }, [search, secrets]);
};

export const CampaignAdvertiserMenu: React.FC<{
  definition: CampaignAdvertiserFilterDefinition;
  onSave: (definition: CampaignAdvertiserFilterDefinition) => void;
}> = ({ definition, onSave }) => {
  const [mode, setMode] = useState(BASIC_MODES[0].value);
  const [search, setSearch] = useState('');
  const [advertisers, loadingAdvertisers] = useAdvertisersByName(search);
  const [value, setValue] = useState(definition.v);
  const [selectedAdvertisers] = useAdvertisersById(value);

  const options = useMemo(() => {
    const selected = selectedAdvertisers ? selectedAdvertisers : [];
    if (!advertisers || loadingAdvertisers) {
      return null;
    }
    const promoted = selected.filter(
      (a) => !advertisers.find((b) => a.advertiserId === b.advertiserId)
    );
    return [...promoted, ...advertisers].map((advertiser) => ({
      value: advertiser.advertiserId,
      label: <AdvertiserLabel advertiser={advertiser} />,
      searchValue: advertiser.name
    }));
  }, [advertisers, loadingAdvertisers, selectedAdvertisers]);

  const _toggle = useCallback(
    (advertiser: string) => setValue(toggle(value, advertiser)),
    [setValue, value]
  );

  const focus = useCallback(
    (advertiser: string) =>
      onSave(createCampaignAdvertiserFilterDefinition([advertiser])),
    [onSave]
  );

  const newDefinition = useMemo<CampaignAdvertiserFilterDefinition>(
    () => createCampaignAdvertiserFilterDefinition(value),
    [value]
  );

  const enableSave =
    validateFilterDefinition(newDefinition) &&
    !isEqual(definition, newDefinition);

  return (
    <FilterMenu>
      <FilterMenu.Header dimension="campaign_advertiser">
        <FilterMenu.ModeSelector
          modes={BASIC_MODES}
          mode={mode}
          setMode={setMode}
        />
      </FilterMenu.Header>
      <FilterMenu.Body>
        <SelectorShell
          label="Advertisers"
          search={search}
          setSearch={setSearch}
        >
          {!options || loadingAdvertisers ? (
            <SelectorLoader />
          ) : (
            <OptionsList
              options={options}
              selectedValues={value}
              toggle={_toggle}
              focus={focus}
            />
          )}
        </SelectorShell>
      </FilterMenu.Body>
      <FilterMenu.Footer definition={initFilterValue('campaign_advertiser')}>
        <FilterMenu.SaveButton
          disabled={!enableSave}
          onSave={() => onSave(newDefinition)}
          label={`Filter by ${pluralize('Advertiser', value.length, true)}`}
        />
      </FilterMenu.Footer>
    </FilterMenu>
  );
};
