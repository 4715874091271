import moment from 'moment-timezone';
import { COUNTRY_NAMES } from '../../../../../domainTypes/country';
import { getTrackingId, ISale } from '../../../../../domainTypes/performance';
import { getKnownPartnerForKeyUnsafe } from '../../../../../services/partner';
import { fromMoment } from '../../../../../services/time';
import { addDevice, toCents, toPercent } from '../helpers';
import { IFileReportHandler } from '../types';

const toStatus = (status: string) => {
  if (
    [
      'Cancelled by guest',
      'Canceled by guest',
      'Cancelled by property',
      'Canceled by property'
    ].includes(status)
  ) {
    return 'Cancelled';
  }
  if (['Booked', 'All'].includes(status)) {
    return 'Pending';
  }
  if (['Stayed'].includes(status)) {
    return 'Final';
  }
  return 'Unknown';
};

const BOOKING_FORMAT = 'MM/DD/YYYY HH:mm:ss A';
const AIDS: { [k: string]: string } = {
  '386482': 'https://tokyocheapo.com',
  '889112': 'https://japancheapo.com',
  '1202058': 'https://londoncheapo.com',
  '1281313': 'https://hongkongcheapo.com',
  '1339022': 'https://www.penguinandpia.com',
  '1654658': 'https://www.ontarioaway.com'
};

const fromAid = (aid: string) => {
  return AIDS[aid] || null;
};

export const BOOKING: IFileReportHandler<string> = {
  type: 'CSV',
  partnerKey: 'booking',
  parser: {
    name: 'Individual Bookings',
    csvHeaders:
      'Booking date,Booking number,Check-in date,Check-out date,Length of stay,Booking window,Status,Your commission',
    matches: (text, expectedHeaders) => text.indexOf(expectedHeaders) === 0,
    processRows: async (
      rows,
      { space, partnerKey, reportId, integrationId }
    ) => {
      const hasConvertedCurrency =
        rows[0].indexOf('Your commission in your currency') !== -1;

      return rows.slice(1).map((row) => {
        let _saleDate = '';
        let _departure = '';
        let _status = '';
        let _commission = '';
        let __lengthOfStay = '';
        let saleId = '';
        let trackingLabel = '';
        let partnerProductName = '';
        let device = '';
        let metadata = {};
        let origin: null | string = null;
        let payoutId: null | string = null;
        let _revenue: null | string = null;

        if (row.length === 21) {
          _saleDate = row[0];
          saleId = row[1];
          _departure = row[3];
          __lengthOfStay = row[4];
          _status = row[6];
          _commission = row[7];

          // revenue has been removed
          // from the booking.com report
          // _revenue = row[9];
          payoutId = row[9];
          partnerProductName = row[10];
          origin = fromAid(row[15]);
          trackingLabel = row[16];
          device = row[19];
          metadata = {
            accommodationType: row[11],
            destinationCountry: row[12],
            destinationCity: row[13],
            affiliateId: row[15],
            customerCountry: COUNTRY_NAMES[row[17]] || '',
            customerLanguage: row[18],
            travelPurpose: row[20]
          };
        }

        // If the row length is still 22,
        // we need to know if the header includes the column
        // "revenue" or "your commission in your currency"
        if (row.length === 22 && !hasConvertedCurrency) {
          _saleDate = row[0];
          saleId = row[1];
          _departure = row[3];
          __lengthOfStay = row[4];
          _status = row[6];
          _commission = row[7];

          _revenue = row[9];
          payoutId = row[10];
          partnerProductName = row[11];
          origin = fromAid(row[16]);
          trackingLabel = row[17];
          device = row[20];
          metadata = {
            accommodationType: row[12],
            destinationCountry: row[13],
            destinationCity: row[14],
            affiliateId: row[16],
            customerCountry: COUNTRY_NAMES[row[18]] || '',
            customerLanguage: row[19],
            travelPurpose: row[21]
          };
        }

        if (row.length === 23 && hasConvertedCurrency) {
          _saleDate = row[0];
          saleId = row[1];
          _departure = row[3];
          __lengthOfStay = row[4];
          _status = row[6];
          _commission = row[7];

          payoutId = row[11];
          partnerProductName = row[12];
          origin = fromAid(row[17]);
          trackingLabel = row[18];
          device = row[21];
          metadata = {
            accommodationType: row[13],
            destinationCountry: row[14],
            destinationCity: row[15],
            affiliateId: row[17],
            customerCountry: COUNTRY_NAMES[row[19]] || '',
            customerLanguage: row[20],
            travelPurpose: row[22]
          };
        }

        const currency = 'EUR';
        const saleDate = moment.tz(_saleDate, BOOKING_FORMAT, 'UTC');
        const completionDate = moment.tz(_departure, BOOKING_FORMAT, 'UTC');
        const status = toStatus(_status);
        const commission = _commission !== '' ? toCents(_commission) : 0;
        const quantity = parseInt(__lengthOfStay, 10);
        const revenue = _revenue ? toCents(_revenue) : null;
        const payoutStatus = payoutId && status === 'Final' ? 'paid' : null;
        const commissionPercent =
          revenue !== null ? toPercent(commission, revenue) : null;

        const sale: ISale = {
          saleId,
          orderId: saleId,
          saleDate: fromMoment(saleDate),
          trackingId: getTrackingId(trackingLabel, space),
          trackingLabel,
          reportId,
          integrationId,
          completionDate: fromMoment(completionDate),
          status,
          origin,
          partnerKey,
          partnerProductName,
          partnerProductId: null, // TODO
          payoutId,
          payoutDate: null,
          lastModified: null,
          payoutStatus,
          coupon: '',
          saleType: 'unknown',
          amount: {
            currency,
            price: null,
            revenue,
            commission
          },
          commissionPercent,
          quantity,
          advertiserId: partnerKey,
          metadata,
          advertiserName: getKnownPartnerForKeyUnsafe(partnerKey).name
        };

        addDevice(sale, device, {
          Computer: 'desktop',
          Mobile: 'mobile',
          Tablet: 'tablet'
        });

        return sale;
      });
    }
  }
};
