import styled from '@emotion/styled';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import * as ReactCrop from 'react-image-crop';
import { readFileAsDataURL } from '../../services/file';
import { AlertBox } from '../AlertBox';
import { ButtonWithPromise } from '../ButtonWithPromise';
import { DialogActionsWithSlots } from '../DialogActionsWithSlots';
import { Dropzone } from '../Dropzone';
import { cropImage, ImageCrop } from '../ImageCrop';

export const CenteredBody = styled('div')((p) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));
const TYPES_TO_EXT: { [type: string]: string } = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/svg+xml': 'svg'
};

const ALLOWED_TYPES = Object.keys(TYPES_TO_EXT);

export const ImageUploadDialog = ({
  title,
  open,
  onClose,
  src,
  onUpload,
  children,
  circularCrop
}: {
  title: string;
  open: boolean;
  onClose: () => void;
  src?: string;
  onUpload: (nextImage: Blob, fileExtension: string) => Promise<any>;
  children?: (p: { onClose: () => void }) => React.ReactNode;
  circularCrop?: boolean;
}) => {
  const [img, setImg] = useState<string | null>(src || null);
  const [err, setErr] = useState<'INVALID_FILETYPE' | 'UPLOAD_FAILED' | null>(
    null
  );
  const [imgRef, setImgRef] = useState<HTMLImageElement | null>(null);
  const [crop, setCrop] = useState<ReactCrop.Crop | undefined>(undefined);
  const close = () => {
    onClose();
    setImg(src || null);
    setImgRef(null);
    setCrop(undefined);
  };
  return (
    <Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {err === 'INVALID_FILETYPE' && (
          <AlertBox variant="error">Please use a PNG, SVG or JPG.</AlertBox>
        )}
        {err === 'UPLOAD_FAILED' && (
          <AlertBox variant="error">Something went wrong.</AlertBox>
        )}
        <CenteredBody>
          {img ? (
            <ImageCrop
              aspect={1}
              crop={crop}
              onChange={setCrop}
              circularCrop={circularCrop}
            >
              <img
                src={img}
                alt={title}
                onLoad={(ev) => {
                  const image = ev.currentTarget;
                  setImgRef(image);
                  image.width = image.width || 200;
                  image.height = image.height || 100;
                  const nextCrop = ReactCrop.centerCrop(
                    ReactCrop.makeAspectCrop(
                      {
                        // You don't need to pass a complete crop into
                        // makeAspectCrop or centerCrop.
                        unit: 'px',
                        width: image.width
                      },
                      1,
                      image.width,
                      image.height
                    ),
                    image.width,
                    image.height
                  );
                  setCrop(nextCrop);
                  return false; // Return false when setting crop state in here.
                }}
              />
            </ImageCrop>
          ) : (
            <Dropzone
              label="Drag and drop your image here, or click to select it"
              height={'40vh'}
              onDrop={async (f) => {
                if (!ALLOWED_TYPES.includes(f[0].type)) {
                  setImg(null);
                  setErr('INVALID_FILETYPE');
                  return;
                }
                const nextImg = await readFileAsDataURL(f[0]);
                setImg(nextImg);
                setErr(null);
              }}
            />
          )}
        </CenteredBody>
      </DialogContent>
      <DialogActionsWithSlots
        right={
          <>
            <Button color="primary" onClick={close}>
              Cancel
            </Button>
            {children && children({ onClose: close })}
            <ButtonWithPromise
              variant="contained"
              color="primary"
              disabled={!img || !imgRef}
              onClick={async () => {
                if (!imgRef || !crop) {
                  return;
                }
                return cropImage(imgRef, crop)
                  .then((blob) => onUpload(blob, TYPES_TO_EXT[blob.type] || ''))
                  .then(close, (err) => {
                    console.log('image upload error', err);
                    return setErr('UPLOAD_FAILED');
                  });
              }}
              pending="Uploading..."
            >
              Upload
            </ButtonWithPromise>
          </>
        }
      />
    </Dialog>
  );
};
