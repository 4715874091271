import { Typography } from '@material-ui/core';
import React, { ComponentType, ReactNode, useCallback, useMemo } from 'react';
import { useSites } from '../../../../../../components/analytics_v2/SiteSelector';
import { useTimeframe } from '../../../../../../components/analytics_v2/Timeframe';
import {
  ChartCard,
  ChartCardFooter,
  ChartCardFooterBar
} from '../../../../../../components/Charts/ChartCard';
import {
  ILegendItem,
  Legend,
  WithShape
} from '../../../../../../components/Charts/Util';
import { Loader } from '../../../../../../components/Loader';
import {
  AnalyticsInterval,
  AnalyticsIntervalUnit,
  AnalyticsQuery,
  AnalyticsResponse,
  AnalyticsResponseRowWithComparison
} from '../../../../../../domainTypes/analytics_v2';
import { usePromise } from '../../../../../../hooks/usePromise';
import { Centered } from '../../../../../../layout/Centered';
import { queryAnalyticsV2 } from '../../../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../../../services/currentUser';
import { IPostgresTagsParent } from '../../../../../../services/tags';
import {
  useBarMetric,
  useLineMetric,
  useTopChartMetric
} from '../service/chart-metrics';
import { TagErrorMessage } from './TagErrorMessage';
import { useNonEmptyTagsFilter } from '../../../../../../components/analytics_v2/TagsSelector';
import { topTagsQuery } from '../service/top-tags-query';
import { useTagsById } from '../service/merge-with-tags';
import { IPostgresTags } from '../../../../../../domainTypes/tags';
import {
  COLOR_UNKNOWN,
  getStableRandomColor
} from '../../../../../../services/color';
import { Color, getColorDef } from '../../../../../../domainTypes/colors';

import { ReferenceLine } from 'recharts';
import { TimeseriesChart } from '../../../../../../components/analytics_v2/Chart/TimeseriesChart';
import { barSeries } from '../../../../../../components/analytics_v2/Chart/barSeries';
import { lineSeries } from '../../../../../../components/analytics_v2/Chart/lineSeries';
import { tooltip } from '../../../../../../components/analytics_v2/Chart/tooltip';
import { xAxis } from '../../../../../../components/analytics_v2/Chart/xAxis';
import { yAxis } from '../../../../../../components/analytics_v2/Chart/yAxis';
import { isNil } from 'lodash';
import { readDataKey } from '../../../../../../components/analytics_v2/Chart/service/keys';
import { formatMetric } from '../../../../../../services/analyticsV2/metrics';
import { useAnalyticsInterval } from '../../../../../../hooks/timeframe';
import {
  IntervalSelector,
  useIntervalSelectorState
} from '../../../../../../components/IntervalSelector';

const HEIGHT = 350;

const TagTrendLegend = () => {
  const [barMetric, setBarMetric] = useBarMetric();

  const [lineMetric, setLineMetric] = useLineMetric();

  const legendBarItems: ILegendItem[] = useMemo(
    () => [
      {
        color: '#DDD',
        shape: 'square',
        active: barMetric === 'commission_sum_net',
        onClick: () => setBarMetric('commission_sum_net'),
        label: 'Earnings'
      },
      {
        color: '#DDD',
        shape: 'square',
        active: barMetric === 'gmv_sum_net',
        onClick: () => setBarMetric('gmv_sum_net'),
        label: 'Sale volume'
      }
    ],
    [barMetric, setBarMetric]
  );

  const legendLineItems: ILegendItem[] = useMemo(
    () => [
      {
        color: '#444',
        shape: 'circle',
        active: lineMetric === 'p',
        onClick: () => setLineMetric('p'),
        label: 'Traffic'
      },
      {
        color: '#444',
        shape: 'circle',
        active: lineMetric === 'c',
        onClick: () => setLineMetric('c'),
        label: 'Clicks'
      },
      {
        color: '#444',
        shape: 'circle',
        active: lineMetric === 'v',
        onClick: () => setLineMetric('v'),
        label: 'Impressions'
      },
      {
        color: '#444',
        shape: 'circle',
        active: lineMetric === 'ctr',
        onClick: () => setLineMetric('ctr'),
        label: 'CTR'
      }
    ],
    [lineMetric, setLineMetric]
  );

  return (
    <ChartCardFooter>
      <ChartCardFooterBar>
        <Legend items={legendLineItems} />
        <Legend items={legendBarItems} />
      </ChartCardFooterBar>
    </ChartCardFooter>
  );
};

interface TagTrendChartCardProps {
  maximizedContent?: ComponentType;
  subheading?: string;
  topRight?: ReactNode;
}

const TagTrendChartCard: React.FC<TagTrendChartCardProps> = ({
  maximizedContent,
  children,
  subheading = 'Your activity by tag',
  topRight
}) => {
  return (
    <ChartCard
      heading="Tag activity"
      padding="dense"
      subheading={subheading}
      maximizedContent={maximizedContent}
      topRight={topRight}
    >
      {children}
      <TagTrendLegend />
    </ChartCard>
  );
};

const useTagsTrend = (
  tagGroup: IPostgresTagsParent,
  interval: AnalyticsInterval
) => {
  const tagGroupId = tagGroup.parent.id;
  const { space } = useCurrentUser();
  const { range } = useTimeframe();
  const sites = useSites();
  const tags = useNonEmptyTagsFilter(tagGroup);
  const [barMetric] = useBarMetric();
  const [lineMetric] = useLineMetric();
  const [sortMetric] = useTopChartMetric();

  return usePromise<AnalyticsResponse>(
    async () => {
      const topQuery = topTagsQuery(
        [sortMetric, lineMetric, barMetric],
        range,
        tagGroupId,
        tags,
        sites
      );
      const data = await queryAnalyticsV2(space.id, topQuery);
      const tagIds = data.rows.map((d) => d.group.tags);
      const timeseriesQuery: AnalyticsQuery = {
        range,
        select: [barMetric, lineMetric],
        groupBy: ['tags'],
        interval,
        orderBy: [{ field: 'interval', direction: 'ASC' }],
        filters: [
          sites,
          {
            field: 'tags',
            condition: 'in',
            values: tagIds
          }
        ]
      };
      return queryAnalyticsV2(space.id, timeseriesQuery, {
        logLabel: 'tags_trends'
      });
    },
    // NOTE: be careful and insert all dependencies of both queries
    [
      tags,
      tagGroupId,
      range,
      sites,
      sortMetric,
      barMetric,
      lineMetric,
      space.id,
      interval
    ]
  );
};

interface TagTrendChartProps {
  tagGroup: IPostgresTagsParent;
}

export const TagTrendChart: React.FC<TagTrendChartProps> = ({ tagGroup }) => {
  const { interval, setInterval, options } = useIntervalSelectorState();
  const analyticsInterval = useAnalyticsInterval(interval);
  const [timeseries, loading, error] = useTagsTrend(
    tagGroup,
    analyticsInterval
  );
  const [tags = {}] = useTagsById();

  if (error) {
    return (
      <TagTrendChartCard>
        <Centered height={HEIGHT}>
          <TagErrorMessage />
        </Centered>
      </TagTrendChartCard>
    );
  }

  if (!timeseries || loading) {
    return (
      <TagTrendChartCard>
        <Centered height={HEIGHT}>
          <Loader size={24} />
        </Centered>
      </TagTrendChartCard>
    );
  }
  if (timeseries && timeseries.rows.length === 0) {
    return (
      <TagTrendChartCard>
        <Centered height={HEIGHT}>
          <Typography variant="body2" component="p" paragraph>
            No data found. Please change your selection or adjust filters.
          </Typography>
        </Centered>
      </TagTrendChartCard>
    );
  }

  return (
    <TagTrendChartCard
      subheading="How much revenue and activity your tagged content drives per day"
      maximizedContent={() => {
        // NOTE: We're repeating Legend here because TagTrendChartCard passes maximizedContent props as is. Probably we could customise this behaviour further.
        return (
          <>
            <InnerChart
              height={'auto'}
              data={timeseries.rows}
              tags={tags}
              intervalUnit={interval.unit}
            />
            <TagTrendLegend />
          </>
        );
      }}
      topRight={
        <IntervalSelector
          value={interval}
          onChange={setInterval}
          options={options}
        />
      }
    >
      <InnerChart
        height={HEIGHT}
        data={timeseries.rows}
        size="small"
        tags={tags}
        intervalUnit={interval.unit}
      />
    </TagTrendChartCard>
  );
};

const niceBarDomain = [
  (min: number) => Math.min(min, 0),
  (max: number) => (max > 0 ? Math.ceil(max / 10) * 10 : max)
] as const;

const InnerChart = ({
  data,
  tags,
  height,
  size = 'normal',
  intervalUnit
}: {
  intervalUnit: AnalyticsIntervalUnit;
  data: AnalyticsResponseRowWithComparison[];
  tags: Record<string, IPostgresTags>;
  height: number | string;
  size?: 'small' | 'normal';
}) => {
  const [barMetric] = useBarMetric();
  const [lineMetric] = useLineMetric();
  const metrics = useMemo(() => [barMetric, lineMetric], [
    barMetric,
    lineMetric
  ]);

  const isSmall = size === 'small';
  const axisFontSize = isSmall ? 12 : 14;

  const tagName = useCallback((id) => tags[id]?.name ?? id, [tags]);
  const tagColor = useCallback(
    (id) => {
      const color = tags[id]?.color;
      return color
        ? getColorDef(color as Color).bgColor
        : getStableRandomColor(id);
    },
    [tags]
  );

  const existingTagSeries = data.filter((d) => !isNil(tags[d.group.tags]));

  return (
    <>
      <TimeseriesChart
        data={existingTagSeries}
        field="tags"
        metrics={metrics}
        ResponsiveContainerProps={{
          aspect: 1.8,
          height: height
        }}
        ComposedChartProps={{
          layout: 'horizontal',
          stackOffset: 'sign',
          maxBarSize: 12
        }}
      >
        {({ context, fieldValues: tagIds }) => {
          return [
            xAxis(context, intervalUnit, {
              tick: {
                fontSize: axisFontSize
              }
            }),
            yAxis(lineMetric, 'left', context, {
              width: isSmall ? 40 : undefined,
              tick: {
                fontSize: axisFontSize
              }
            }),
            yAxis(barMetric, 'right', context, {
              width: isSmall ? 40 : undefined,
              tick: {
                fontSize: axisFontSize
              },
              domain: niceBarDomain
            }),
            isSmall ? null : (
              <ReferenceLine y={0} yAxisId={barMetric} stroke="#bbb" />
            ),
            barSeries(tagIds, barMetric, {
              name: tagName,
              fill: '#EEE'
            }),
            lineSeries(tagIds, lineMetric, {
              name: tagName,
              stroke: tagColor
            }),
            tooltip(
              context,
              intervalUnit,
              {
                formatter: (value, name, { dataKey }) => {
                  const [metric, tagId] = readDataKey(dataKey);
                  return [
                    formatMetric(value as number, metric, context.currency),
                    <WithShape color={tagColor(tagId) ?? COLOR_UNKNOWN}>
                      {name}
                    </WithShape>
                  ];
                }
              },
              {
                size
              }
            )
          ];
        }}
      </TimeseriesChart>
    </>
  );
};
