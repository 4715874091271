import React from 'react';
import Typography from '@material-ui/core/Typography';
import { styled } from '../emotion';
import { COLORS } from '../domainTypes/colors';
import { hasOverdueInvoices, useInvoices } from '../features/Billing/service';
import { useCurrentUser } from '../services/currentUser';
import { AlertTriangle } from 'react-feather';
import { Dialog, DialogContent } from '@material-ui/core';
import { PageInvoicesOverdueOverlay } from '../features/Billing/components/PageInvoicesOverdueOverlay';

const AccountUpgradeBar = styled('div')`
  top: ${(p) => p.theme.spacing(7)}px;
  position: sticky;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  min-height: 32px;
  background-color: ${COLORS.volcano.volcano6};
  color: ${COLORS.volcano.volcano1};
  z-index: 3;

  strong {
    margin-right: 4px;
  }

  p {
    font-size: 13px;
    text-align: center;
    margin: 0 auto;
  }

  button {
    position: absolute;
    right: 0;
  }
`;

const BILLING_WARNING_CONFIGS = [
  {
    color: COLORS.red.red7,
    backgroundColor: COLORS.red.red2,
    overdueLimit: 50,
    text: 'Final warning',
    icon: AlertTriangle,
    message:
      'Your organization has invoices which are overdue by at least 50 days. Please pay them immediately to avoid system lockout.'
  },
  {
    color: COLORS.volcano.volcano7,
    backgroundColor: COLORS.volcano.volcano2,
    overdueLimit: 30,
    text: 'Immediate action required',
    icon: AlertTriangle,
    message:
      'Your organization has invoices which are overdue by at least 30 days. Please pay them to avoid system lockout.'
  },
  {
    color: COLORS.orange.orange7,
    backgroundColor: COLORS.orange.orange2,
    overdueLimit: 14,
    text: 'Action required',
    icon: AlertTriangle,
    message:
      'Your organization has invoices which are overdue by at least 14 days. Please pay them as soon as possible.'
  },
  {
    color: COLORS.yellow.yellow7,
    backgroundColor: COLORS.yellow.yellow2,
    overdueLimit: 7,
    text: 'Reminder',
    icon: AlertTriangle,
    message:
      'Your organization has invoices which are overdue by at least 7 days. Please pay them as soon as possible.'
  }
];

export const AccountUpgradePrompt = () => {
  const currentUser = useCurrentUser();
  const [invoices, loading, error] = useInvoices(currentUser.space.id);
  const [dialogDismissed, setDialogDismissed] = React.useState(false);

  if (!invoices || loading || error) return null;

  const showPrompt = hasOverdueInvoices(invoices, 14);
  const showPopup = hasOverdueInvoices(invoices, 30);

  if (showPrompt) {
    const config =
      BILLING_WARNING_CONFIGS.find((c) =>
        hasOverdueInvoices(invoices, c.overdueLimit)
      ) || BILLING_WARNING_CONFIGS[BILLING_WARNING_CONFIGS.length - 1];
    return (
      <>
        <AccountUpgradeBar
          style={{
            backgroundColor: config.backgroundColor,
            color: config.color
          }}
        >
          <Typography variant="body2" component="p">
            <config.icon
              size={16}
              style={{ position: 'relative', top: '3px', left: '-3px' }}
            />
            <strong>{config.text}</strong> &mdash; {config.message}
          </Typography>
        </AccountUpgradeBar>
        {showPopup && (
          <Dialog
            open={!dialogDismissed}
            maxWidth="xl"
            scroll="body"
            style={{ backdropFilter: 'blur(8px)' }}
          >
            <DialogContent>
              <PageInvoicesOverdueOverlay
                onIgnore={() => setDialogDismissed(true)}
                invoices={invoices}
              />
            </DialogContent>
          </Dialog>
        )}
      </>
    );
  }
  return null;
};
