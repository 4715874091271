import {
  Button,
  ButtonProps,
  Checkbox,
  FormControlLabel,
  IconButton,
  Popover,
  PopoverProps,
  Typography
} from '@material-ui/core';
import React, { createElement, useEffect, useRef, useState } from 'react';
import { ArrowRight, ChevronDown, ChevronUp } from 'react-feather';
import { Link } from 'react-router-dom';
import { styled } from '../../emotion';
import { Centered } from '../../layout/Centered';
import { FlexContainer } from '../../layout/Flex';
import { useRoutes } from '../../routes';
import { ButtonWithPromiseLoader } from '../ButtonWithPromise';

const NestedMultiSelectorContainer = styled('div')((p) => ({
  minWidth: 400,
  height: 350,
  overflowY: 'scroll'
}));

const NestedMultiSelectorItemContainer = styled('div')((p) => ({
  ':not(:last-child)': {
    borderBottom: p.theme.custom.border.standard
  }
}));

const NestedMultiSelectorItemHeader = styled('div')((p) => ({
  padding: `0 ${p.theme.spacing(2)}px`
}));

const NestedMultiSelectorItemBody = styled('div')((p) => ({
  borderTop: p.theme.custom.border.standard,
  paddingLeft: p.theme.spacing(5),
  background: p.theme.palette.grey[100]
}));

export type NestedMultiSelectorItem<T> = {
  key: string;
  label: (values: T) => React.ReactNode;
  checked: (values: T) => boolean | 'INDETERMINATE';
  onChangeAll?: (prevValues: T, checked: boolean) => T; // Triggered when clicking the checkbox - when this is not passed in, the checkbox will appear disabled

  body: React.ComponentType<{
    values: T;
    onChange: (fn: (prevValues: T) => T) => void;
  }>;
};

type NestedMultiSelectorState = {
  [key: string]: { checked: boolean | 'INDETERMINATE'; expanded: boolean };
};

const getInitialState = <T extends object>(
  values: T,
  items: NestedMultiSelectorItem<T>[],
  autoExpandCheckedItems?: boolean
): NestedMultiSelectorState => {
  return Object.fromEntries(
    items.map((item) => {
      const checked = item.checked(values);
      return [
        item.key,
        {
          checked,
          expanded: !!(autoExpandCheckedItems && checked) // this means that INDETERMINATE also gets auto expanded. If we don't want that, specifically check for `checked === true`
        }
      ];
    })
  );
};

export type NestedMultiSelectorProps<T extends object> = {
  values: T;
  onChange: (nextValues: T) => void;
  items: NestedMultiSelectorItem<T>[];
  autoExpandCheckedItems?: boolean;
};

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  color: ${(p) => p.theme.palette.primary.main};
`;

export const NestedMultiSelectorBody = <T extends object>({
  values,
  onChange,
  items,
  autoExpandCheckedItems
}: NestedMultiSelectorProps<T>) => {
  const { ROUTES } = useRoutes();
  useEffect(() => {
    const uniqueKeys = new Set(items.map((i) => i.key));
    if (uniqueKeys.size < items.length) {
      console.log('Warning - non unique items received', items);
    }
  }, [items]);

  const [state, setState] = useState<NestedMultiSelectorState>(
    getInitialState(values, items, autoExpandCheckedItems)
  );

  useEffect(() => {
    setState((x) =>
      Object.fromEntries(
        items.map((item) => {
          const checked = item.checked(values);
          return [
            item.key,
            {
              checked,
              expanded: !!x[item.key]?.expanded
            }
          ];
        })
      )
    );
  }, [values, items]); // mind that items might be changing a lot, as consumers are probably not making them stable. so this is going to run a lot, which is fine

  const setExpanded = (key: string, expanded?: boolean) => {
    setState((x) => ({
      ...x,
      [key]: {
        ...x[key],
        expanded: expanded ?? !x[key]?.expanded
      }
    }));
  };

  return (
    <NestedMultiSelectorContainer>
      {!items.length && (
        <Centered height={350}>
          <div style={{ textAlign: 'center', maxWidth: '300px' }}>
            <Typography variant="body1">
              <strong>Start by creating tags</strong>
            </Typography>
            <Typography variant="caption">
              Create and group your tags first, then come back here to apply
              them to your content.
              <br />
              <br />
              <StyledLink to={ROUTES.content.tags.manage.url()}>
                <div>Create your tags</div>{' '}
                <ArrowRight
                  style={{ position: 'relative', top: '2px' }}
                  size={13}
                />
              </StyledLink>
            </Typography>
          </div>
        </Centered>
      )}
      {!!items.length &&
        items.map((item) => {
          const itemState = state[item.key] || {};
          const indeterminate = itemState.checked === 'INDETERMINATE';
          return (
            <NestedMultiSelectorItemContainer key={item.key}>
              <NestedMultiSelectorItemHeader>
                <FlexContainer
                  role="button"
                  justifyContent="space-between"
                  onClick={() => setExpanded(item.key)}
                >
                  {item.onChangeAll ? (
                    <FormControlLabel
                      label={item.label(values)}
                      onClick={(ev) => ev.stopPropagation()}
                      onFocus={(ev) => ev.stopPropagation()}
                      control={
                        <Checkbox
                          color={indeterminate ? 'default' : 'primary'}
                          checked={!!itemState.checked}
                          indeterminate={indeterminate}
                          disabled={!item.onChangeAll}
                          onChange={(ev) => {
                            const { onChangeAll } = item;
                            if (!onChangeAll) {
                              return;
                            }
                            onChange(onChangeAll(values, ev.target.checked));
                          }}
                        />
                      }
                    />
                  ) : (
                    <Typography style={{ cursor: 'pointer' }}>
                      {item.label(values)}
                    </Typography>
                  )}
                  <IconButton>
                    {itemState.expanded ? (
                      <ChevronUp size={16} />
                    ) : (
                      <ChevronDown size={16} />
                    )}
                  </IconButton>
                </FlexContainer>
              </NestedMultiSelectorItemHeader>
              {itemState.expanded && (
                <NestedMultiSelectorItemBody>
                  {createElement(item.body, {
                    values,
                    onChange: (fn) => {
                      onChange(fn(values));
                    }
                  })}
                </NestedMultiSelectorItemBody>
              )}
            </NestedMultiSelectorItemContainer>
          );
        })}
    </NestedMultiSelectorContainer>
  );
};

export type NestedMultiSelectorPopoverBodyProps = {
  title: React.ReactNode;
  cta?: string;
  cancelCta?: string;
  onClose: () => void;
};

const NestedMultiSelectorPopoverHeader = styled(FlexContainer)((p) => ({
  justifyContent: 'space-between',
  padding: p.theme.spacing(1),
  background: p.theme.palette.common.white,
  color: p.theme.palette.common.black,
  boxShadow: `inset 0 0 1px ${p.theme.palette.grey[500]}`
}));

export const NestedMultiSelectorPopoverBody = <T extends object>({
  title,
  cta = 'Apply',
  cancelCta = 'Cancel',
  onClose,
  values,
  onChange,
  ...props
}: NestedMultiSelectorPopoverBodyProps & NestedMultiSelectorProps<T>) => {
  const [model, setModel] = useState(values);
  return (
    <div>
      <NestedMultiSelectorPopoverHeader>
        <Button size="small" variant="text" color="inherit" onClick={onClose}>
          {cancelCta}
        </Button>
        <strong>{title}</strong>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => {
            onChange(model);
            onClose();
          }}
        >
          {cta}
        </Button>
      </NestedMultiSelectorPopoverHeader>

      <NestedMultiSelectorBody {...props} values={model} onChange={setModel} />
    </div>
  );
};

export const toNestedMultiSelectorCheckboxGroup = <
  T extends object,
  O extends { id: string; name: string } = { id: string; name: string }
>({
  key,
  label,
  options,
  isOptionSelected,
  onChangeOption,
  onChangeAll
}: {
  key: string;
  label: string;
  options: O[];
  isOptionSelected: (values: T, option: O) => boolean | 'INDETERMINATE';
  onChangeOption: (prev: T, option: O, selected: boolean) => T;
  onChangeAll?: (prev: T, items: O[]) => T;
}): NestedMultiSelectorItem<T> => {
  return {
    key,
    label: () => label,
    checked: (values) => {
      const optionStates = options.map((o) => isOptionSelected(values, o));
      return optionStates.every((c) => c === true)
        ? true
        : optionStates.every((c) => c === false)
        ? false
        : ('INDETERMINATE' as const);
    },
    onChangeAll: onChangeAll
      ? (prev, checked) => {
          return onChangeAll(prev, checked ? options : []);
        }
      : undefined,
    body: ({ values, onChange }) => (
      <>
        {options.map((option) => {
          const selected = isOptionSelected(values, option);
          const indeterminate = selected === 'INDETERMINATE';
          return (
            <div key={option.id}>
              <FormControlLabel
                label={option.name}
                control={
                  <Checkbox
                    color={indeterminate ? 'default' : 'primary'}
                    checked={!!selected}
                    indeterminate={indeterminate}
                    onChange={(ev) => {
                      onChange((prev) =>
                        onChangeOption(prev, option, ev.target.checked)
                      );
                    }}
                  />
                }
              />
            </div>
          );
        })}
      </>
    )
  };
};

type NestedMultiSelectorButtonProps<T extends object> = Omit<
  NestedMultiSelectorPopoverBodyProps,
  'title' | 'onClose'
> &
  NestedMultiSelectorProps<T> & {
    variant?: ButtonProps['variant'];
    color?: ButtonProps['color'];
    size?: ButtonProps['size'];
    startIcon?: ButtonProps['startIcon'];
    endIcon?: ButtonProps['endIcon'];
    disabled?: ButtonProps['disabled'];
    anchorOrigin?: PopoverProps['anchorOrigin'];
    children: React.ReactNode;
    title?: React.ReactNode;
    // TODO a way to define the marker -> it's actice state
  };

export const NestedMultiSelectorButton = <T extends object>({
  children,
  variant,
  color,
  size,
  startIcon,
  endIcon,
  disabled,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
  },
  ...props
}: NestedMultiSelectorButtonProps<T>) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  return (
    <>
      <Button
        variant={variant}
        color={color}
        size={size}
        startIcon={startIcon}
        endIcon={endIcon}
        disabled={disabled}
        onClick={() => setOpen((x) => !x)}
        ref={ref}
      >
        {children}
      </Button>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        anchorOrigin={anchorOrigin}
      >
        <NestedMultiSelectorPopoverBody
          {...props}
          title={props.title || children}
          onClose={() => setOpen(false)}
        />
      </Popover>
    </>
  );
};

export const NestedMultiSelectorButtonAsync = <T extends object>({
  children,
  variant,
  color,
  size,
  startIcon,
  endIcon,
  disabled,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
  },
  pending,
  onChange,
  ...props
}: Omit<NestedMultiSelectorButtonProps<T>, 'onChange'> & {
  onChange: (nextValues: T) => Promise<void>;
  pending: React.ReactNode;
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  return (
    <>
      <Button
        variant={variant}
        color={color}
        style={{
          backgroundColor: color === 'default' ? 'white' : undefined,
          boxShadow:
            '0px 1px 1px -1px rgba(0,0,0,0.1), 2px 5px 4px -1px rgba(0,0,0,0.01)',
          height: '33px'
        }}
        size={size}
        startIcon={startIcon}
        endIcon={endIcon}
        disabled={disabled || loading}
        onClick={() => setOpen((x) => !x)}
        ref={ref}
      >
        {loading ? (
          <>
            <ButtonWithPromiseLoader /> {pending}
          </>
        ) : (
          children
        )}
      </Button>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        elevation={8}
        anchorEl={ref.current}
        anchorOrigin={anchorOrigin}
        style={{ overflow: 'hidden' }}
      >
        <NestedMultiSelectorPopoverBody
          {...props}
          title={props.title || children}
          onChange={async (nextValues) => {
            setLoading(true);
            try {
              await onChange(nextValues);
            } finally {
              setLoading(false);
            }
          }}
          onClose={() => setOpen(false)}
        />
      </Popover>
    </>
  );
};
