import React, { useMemo } from 'react';
import { IPartner } from '../../../domainTypes/partners';
import {
  PayoutStatus,
  PAYOUT_STATUSES,
  SaleStatus,
  SaleType,
  SALE_STATUSES,
  SALE_TYPES,
  SALE_TYPES_WITHOUT_BONUS
} from '../../../domainTypes/performance';
import { NestedFilterMenu } from '../../../components/NestedFilterMenu';
import { getAppliedLabel } from '../../../components/MultiSelector';
import { capitalize, compact, every } from 'lodash';
import { SelectorChip } from '../../../components/SelectorChip';
import { PlatformWithColor } from '../../../components/PlatformWithColor';
import { SalesStatus } from './SalesStatus';
import { useHasPayouts } from '../../../services/payouts';
import { EMPTY_SET } from '../../../domainTypes/emptyConstants';

export const SalesFilter = ({
  partners,
  statuses,
  advertisers,
  payoutStatuses,
  payoutIds,
  types,
  hiddenOptions,
  onReset
}: {
  partners: {
    partners: IPartner[];
    value: Set<string> | null;
    onChange: (nextValue: Set<string> | null) => void;
  };
  advertisers: {
    advertisers: string[];
    value: Set<string> | null;
    onChange: (nextValue: Set<string> | null) => void;
  };
  statuses: {
    value: Set<SaleStatus>;
    onChange: (nextValue: Set<SaleStatus>) => void;
  };
  payoutStatuses?: {
    value: Set<PayoutStatus>;
    onChange: (nextValue: Set<PayoutStatus>) => void;
  };
  payoutIds?: {
    payoutIds: string[];
    value: Set<string> | null;
    onChange: (nextValue: Set<string> | null) => void;
  };
  types: {
    value: Set<SaleType>;
    onChange: (nextValue: Set<SaleType>) => void;
  };
  hiddenOptions?: (
    | 'advertisers'
    | 'partners'
    | 'types'
    | 'statuses'
    | 'payoutIds'
    | 'payoutStatuses'
  )[];
  onReset: () => void;
}) => {
  const hasPayouts = useHasPayouts();
  const statusApplied = SALE_STATUSES.length !== statuses.value.size;
  const typeApplied = SALE_TYPES_WITHOUT_BONUS.length !== types.value.size;
  const partnersApplied = !!partners.value;
  const advertisersApplied = !!advertisers.value;
  const payoutIdsApplied = payoutIds ? !!payoutIds.value : false;
  const payoutStatusApplied = payoutStatuses
    ? PAYOUT_STATUSES.length !== payoutStatuses.value.size
    : false;
  const isApplied =
    statusApplied ||
    partnersApplied ||
    advertisersApplied ||
    typeApplied ||
    payoutIdsApplied ||
    payoutStatusApplied;

  const { value: v, partners: ps } = partners;

  const partnersWithDefaults = useMemo(
    () => v || new Set(ps.map((p) => p.key)),
    [v, ps]
  );

  const { value: avs, advertisers: ads } = advertisers;

  const advertisersWithDefaults = useMemo(
    () => avs || new Set(ads.map((p) => p)),
    [avs, ads]
  );

  const payoutIdsWithDefaults = useMemo(() => {
    if (!payoutIds) {
      return EMPTY_SET;
    }
    return payoutIds.value || new Set(payoutIds.payoutIds.map((p) => p));
  }, [payoutIds]);

  const advertisersLabel = useMemo(() => {
    if (!advertisers.value) {
      return null;
    }
    const visibleAdvertisers = [...advertisers.value];
    const VISIBLE_ADVERTISERS = 2;
    if (visibleAdvertisers.length <= VISIBLE_ADVERTISERS) {
      return visibleAdvertisers.join(', ');
    }
    const countRemaining = visibleAdvertisers.length - VISIBLE_ADVERTISERS;
    return `${visibleAdvertisers
      .slice(0, VISIBLE_ADVERTISERS)
      .join(', ')}, and ${countRemaining} ${
      countRemaining === 1 ? 'other' : 'others'
    }`;
  }, [advertisers.value]);

  const payoutIdsLabel = useMemo(() => {
    if (!payoutIds || !payoutIds.value) {
      return null;
    }
    const visiblePayoutIds = [...payoutIds.value];
    const VISIBLE_PAYOUT_IDS = 2;
    if (visiblePayoutIds.length <= VISIBLE_PAYOUT_IDS) {
      return visiblePayoutIds.join(', ');
    }
    const countRemaining = visiblePayoutIds.length - VISIBLE_PAYOUT_IDS;
    return `${visiblePayoutIds
      .slice(0, VISIBLE_PAYOUT_IDS)
      .join(', ')}, and ${countRemaining} ${
      countRemaining === 1 ? 'other' : 'others'
    }`;
  }, [payoutIds]);

  const showPayoutStatuses = useMemo(() => {
    if (!hiddenOptions) {
      return hasPayouts;
    }
    return !hiddenOptions.includes('payoutStatuses');
  }, [hasPayouts, hiddenOptions]);

  const showPayoutIds = useMemo(() => {
    if (!hiddenOptions) {
      return hasPayouts;
    }
    return !hiddenOptions.includes('payoutIds');
  }, [hasPayouts, hiddenOptions]);

  return (
    <NestedFilterMenu
      filters={compact([
        !hiddenOptions || (hiddenOptions && !hiddenOptions.includes('partners'))
          ? {
              key: 'partners',
              type: 'multiSelector',
              label: 'Platforms',
              appliedLabel: (
                <strong>
                  {getAppliedLabel(
                    'platforms',
                    partnersApplied
                      ? ps
                          .filter((p) => partnersWithDefaults.has(p.key))
                          .map((p) => p.name)
                      : []
                  )}
                </strong>
              ),
              isApplied: partnersApplied,
              legend: 'Platforms',
              allOption: <strong>All Platforms</strong>,
              allowFocusing: true,
              options: partners.partners
                .map((p) => ({
                  label: <PlatformWithColor partner={p} />,
                  value: p.key
                }))
                .sort((a, b) =>
                  a.value.toLowerCase().localeCompare(b.value.toLowerCase())
                ),
              value: partnersWithDefaults,
              onChange: (nextValue) => {
                /// when everything's selected, just remove the fitler
                const trueNextValue =
                  nextValue.size === ps.length &&
                  every(ps, (p) => nextValue.has(p.key))
                    ? null
                    : nextValue;
                partners.onChange(trueNextValue);
              }
            }
          : null,
        !hiddenOptions ||
        (hiddenOptions && !hiddenOptions.includes('advertisers'))
          ? {
              key: 'advertisers',
              type: 'multiSelector',
              label: 'Advertisers',
              appliedLabel: (
                <strong>
                  {advertisersApplied &&
                    advertisers.value &&
                    `Filtered by ${advertisersLabel}`}
                </strong>
              ),
              isApplied: advertisersApplied,
              legend: 'Advertisers',
              allOption: <strong>All Advertisers</strong>,
              allowFocusing: true,
              allowSearch: true,
              options: advertisers.advertisers
                .map((p) => ({
                  label: p,
                  value: p
                }))
                .sort((a, b) =>
                  a.label.toLowerCase().localeCompare(b.label.toLowerCase())
                ),
              value: advertisersWithDefaults,
              onChange: (nextValue) => {
                /// when everything's selected, just remove the fitler
                const trueNextValue =
                  nextValue.size === ads.length &&
                  every(ads, (a) => nextValue.has(a))
                    ? null
                    : nextValue;
                advertisers.onChange(trueNextValue);
              }
            }
          : null,
        {
          key: 'statuses',
          type: 'multiSelector',
          label: 'Transaction statuses',
          appliedLabel: <strong>Filtered by status</strong>,
          isApplied: statusApplied,
          allOption: <strong>All</strong>,
          allowFocusing: true,
          legend: 'Transaction statuses',
          options: SALE_STATUSES.map((s) => ({
            label: <SalesStatus status={s} />,
            value: s
          })),
          value: statuses.value,
          onChange: statuses.onChange
        },
        showPayoutStatuses && payoutStatuses
          ? {
              key: 'payoutStatuses',
              type: 'multiSelector',
              label: 'Payout statuses',
              appliedLabel: <strong>Filtered by payout status</strong>,
              isApplied: payoutStatusApplied,
              allOption: <strong>All payout statuses</strong>,
              allowFocusing: true,
              legend: 'Payout Statuses',
              options: PAYOUT_STATUSES.map((s) => ({
                label: capitalize(s),
                value: s
              })),
              value: payoutStatuses.value,
              onChange: payoutStatuses.onChange
            }
          : null,
        showPayoutIds && payoutIds
          ? {
              key: 'payoutIds',
              type: 'multiSelector',
              label: 'Payout IDs',
              appliedLabel: (
                <strong>
                  {payoutIdsApplied &&
                    payoutIds.value &&
                    `Filtered by ${payoutIdsLabel}`}
                </strong>
              ),
              isApplied: payoutIdsApplied,
              legend: 'Payout IDs',
              allOption: <strong>All Payout IDs</strong>,
              allowFocusing: true,
              allowSearch: true,
              options: payoutIds.payoutIds.map((p) => ({
                label: p,
                value: p
              })),
              value: payoutIdsWithDefaults,
              onChange: (nextValue) => {
                /// when everything's selected, just remove the fitler
                const trueNextValue =
                  nextValue.size === payoutIds.payoutIds.length &&
                  every(payoutIds.payoutIds, (a) => nextValue.has(a))
                    ? null
                    : nextValue;
                payoutIds.onChange(trueNextValue);
              }
            }
          : null,
        {
          key: 'types',
          type: 'multiSelector',
          label: 'Transaction types',
          appliedLabel: <strong>Filtered by type</strong>,
          isApplied: typeApplied,
          allOption: <strong>All</strong>,
          allowFocusing: true,
          legend: 'Transaction types',
          options: SALE_TYPES.map((s) => ({
            label: ['bonus', 'unknown'].includes(s)
              ? capitalize(s)
              : s.toUpperCase(),
            value: s
          })),
          value: types.value,
          onChange: types.onChange
        }
      ])}
    >
      <SelectorChip
        isApplied={isApplied}
        onDelete={onReset}
        label="Filters"
        appliedLabel="Filters"
      />
    </NestedFilterMenu>
  );
};
