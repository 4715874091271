import Typography from '@material-ui/core/Typography';
import React from 'react';
import {
  ILegendItem,
  Legend,
  WithShape
} from '../../../../../../components/Charts/Util';
import { HelpIcon } from '../../../../../../components/HelpIcon';
import { FlexContainer } from '../../../../../../layout/Flex';
import { metricTitle } from '../../../../../../services/analyticsV2/metrics';
import { ARTICLE_IDS, Beacon } from '../../../../../../services/beacon';
import { useMixpanel } from '../../../../../../services/mixpanel';
import { earningsMetrics } from '../../service';
import { EARNINGS_COLOR } from './index';
import { useChartEarningsMetric } from './metrics';

const EarningsMetricPicker = () => {
  const mixpanel = useMixpanel();
  const [earningsMetric, selectEarningsMetric] = useChartEarningsMetric();
  const items: ILegendItem[] = earningsMetrics.map((metric) => ({
    label: metricTitle(metric),
    color: '#000',
    shape: 'circle',
    active: earningsMetric === metric,
    onClick: () => {
      selectEarningsMetric(metric);
      mixpanel.track('realtime_report_chart_metric_change', {
        chart: 'earnings',
        metric
      });
    }
  }));

  return (
    <div style={{ marginLeft: '-6px' }}>
      <Legend items={items} />
    </div>
  );
};

export const RealtimeEarningsChartDetails: React.FC<{
  hasComparison: boolean;
}> = ({ hasComparison }) => {
  const [earningsMetric] = useChartEarningsMetric();
  return (
    <FlexContainer
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <div>
        <Typography variant="body1" component="p">
          <strong>Recent earnings</strong>
        </Typography>
        <Typography variant="body2" component="p" color="textSecondary">
          Many networks do not report same-day commissions. To pull today's
          earnings from those that do, use the guide below.
          <br />
        </Typography>
      </div>
      <div>
        <WithShape shape="square" color={EARNINGS_COLOR}>
          {metricTitle(earningsMetric)}
        </WithShape>
        {hasComparison ? (
          <WithShape
            color={EARNINGS_COLOR}
            shape="square"
            style={{
              opacity: 0.3
            }}
          >
            Previous {metricTitle(earningsMetric)}
          </WithShape>
        ) : null}
      </div>
      <div>
        <strong>Performance by</strong>
        <EarningsMetricPicker />
      </div>
      <HelpIcon
        color="blue"
        onClick={() => {
          Beacon('article', ARTICLE_IDS.performance.pullCommissions);
        }}
      >
        How to pull same-day commissions
      </HelpIcon>
    </FlexContainer>
  );
};
