import { Button, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { Zap } from 'react-feather';
import { AlertBox } from '../../../../components/AlertBox';
import { useTimeframe } from '../../../../components/analytics_v2/Timeframe';
import { AnalyticsQuery } from '../../../../domainTypes/analytics_v2';
import { styled } from '../../../../emotion';
import * as ENV from '../../../../env.json';
import { CanvasBar } from '../../../../layout/Canvas';
import { Section } from '../../../../layout/Section';
import { useAnalyticsQueryV2 } from '../../../../services/analyticsV2/query';
import { ARTICLES } from '../../../../services/beacon';
import { useCurrentUser } from '../../../../services/currentUser';
import {
  useCombineLoadingValues,
  useMappedLoadingValue
} from '../../../../services/db';
import { now } from '../../../../services/time';
import { useAllHandlers } from '../../services/handlers';
import { ReportHandler } from '../../services/handlers/types';
import { useReportingStats } from '../../services/reportingStats';
import { ArchivedHandlerListWrapper } from './ArchivedHandlerListWrapper';
import { HandlerList, HandlerListLoader } from './HandlerList';

type PartitionedHandlers = {
  yourApiHandlers: ReportHandler[];
  yourUploadHandlers: ReportHandler[];
  allHandlers: ReportHandler[];
};

const ZapWrapper = styled('div')`
  color: ${(p) => p.theme.palette.warning.main};
  margin-right: 10px;
  margin-top: 4px;
  margin-right: ${(p) => p.theme.spacing(2)}px;
`;

const SmartLabelAlert = () => (
  <AlertBox
    variant="pending"
    style={{
      marginTop: '18px',
      marginBottom: '24px',
      justifyContent: 'space-between',
      display: 'flex'
    }}
  >
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ZapWrapper>
        <Zap size={18} />
      </ZapWrapper>
      <Typography variant="body2" component="p">
        <strong>Smart Labels</strong> automatically attribute your affiliate
        revenue back to your links and content by overriding your links’
        existing SubIDs.
        <br />
        <strong>Link Cloakers</strong> (like Thirsty Affiliates or Pretty Links)
        will need you to adjust one setting to ensure Smart Labels work
        properly.
      </Typography>
    </div>
    <Button
      variant="outlined"
      style={{ whiteSpace: 'nowrap', minWidth: '100px', marginLeft: '6px' }}
      target="_blank"
      rel="noopener noreferrer"
      href={`${ENV.domains.website.public}docs/${ARTICLES.smartLabels.enable}`}
    >
      Show setup tips
    </Button>
  </AlertBox>
);

const HandlerCount = styled<'div', { active: boolean }>('div')`
  display: inline-block;
  align-items: center;
  justify-content: center;
  background-color: ${(p) =>
    p.active ? p.theme.palette.primary.main : p.theme.palette.grey[300]};
  color: ${(p) =>
    p.active ? p.theme.palette.common.white : p.theme.palette.grey[900]};
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  font-size: 12px;
  line-height: 12px;
  margin-left: ${(p) => p.theme.spacing(2)}px;
  padding: 4px 6px;
`;

const StyledTabLabel = styled.div`
  display: flex;
  alignitems: center;
`;

const usePartnerKeysFromClickhouse = (spaceId: string) => {
  const { range } = useTimeframe();
  const query = useMemo<AnalyticsQuery>(
    () => ({
      range,
      select: ['agg_uniq_pk'],
      filters: [
        {
          field: 'pk',
          condition: 'not in',
          values: ['']
        }
      ]
    }),
    [range]
  );
  return useMappedLoadingValue(
    useAnalyticsQueryV2(spaceId, query),
    (result) => result.rows[0]?.data.agg_uniq_pk?.curr ?? []
  );
};

const usePartnerKeys = (spaceId: string) => {
  return useMappedLoadingValue(
    useCombineLoadingValues(
      usePartnerKeysFromClickhouse(spaceId),
      useReportingStats(spaceId)
    ),
    ([pks, stats]) => new Set([...pks, ...stats.map((s) => s.data.partnerKey)])
  );
};

export const CompleteHandlerListCH = () => {
  const { space, id: userId } = useCurrentUser();
  const ts = useMemo(now, []);
  const handlers = useAllHandlers();
  const [selectedTab, setSelectedTab] = useState(0);
  const [partnerKeys] = usePartnerKeys(space.id);

  console.log('HANDLER LIST', partnerKeys);
  if (!partnerKeys) {
    return <HandlerListLoader />;
  }

  const emptyHandlers = {
    yourApiHandlers: [],
    yourUploadHandlers: [],
    allHandlers: []
  };

  const { yourApiHandlers, yourUploadHandlers, allHandlers } = handlers.reduce<
    PartitionedHandlers
  >((memo, h) => {
    if (partnerKeys.has(h.partnerKey)) {
      if (h.type === 'API' || h.type === 'AFFILIATE_PROGRAM') {
        memo.yourApiHandlers.push(h);
      } else {
        memo.yourUploadHandlers.push(h);
      }
    } else {
      memo.allHandlers.push(h);
    }
    return memo;
  }, emptyHandlers);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  if (yourApiHandlers.length) {
    return (
      <>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          style={{ marginBottom: '24px' }}
        >
          <Tab
            label={
              <StyledTabLabel>
                Your integrations
                <HandlerCount active={selectedTab === 0}>
                  {yourApiHandlers.length + yourUploadHandlers.length}
                </HandlerCount>
              </StyledTabLabel>
            }
          />
          <Tab
            label={
              <StyledTabLabel>
                More integrations
                <HandlerCount active={selectedTab === 1}>
                  {allHandlers.length}
                </HandlerCount>
              </StyledTabLabel>
            }
          />
        </Tabs>
        <SmartLabelAlert />
        {selectedTab === 0 && (
          <>
            <Section>
              <CanvasBar>API integrations</CanvasBar>
              <HandlerList
                space={space}
                userId={userId}
                handlers={yourApiHandlers}
                ts={ts}
              />
            </Section>
            <Section>
              <CanvasBar>Upload integrations</CanvasBar>
              <HandlerList
                space={space}
                userId={userId}
                handlers={yourUploadHandlers}
                ts={ts}
              />
            </Section>

            <Section>
              <ArchivedHandlerListWrapper
                space={space}
                userId={userId}
                handlers={yourApiHandlers}
                ts={ts}
              />
            </Section>
          </>
        )}
        {selectedTab === 1 && (
          <Section>
            <HandlerList
              space={space}
              userId={userId}
              handlers={allHandlers}
              ts={ts}
            />
          </Section>
        )}
      </>
    );
  }

  return (
    <>
      <SmartLabelAlert />
      <HandlerList space={space} userId={userId} handlers={handlers} ts={ts} />
    </>
  );
};
