import * as pako from 'pako';
import { Compressed } from '../../domainTypes/compression';

export const compress = (data: any): string => {
  return pako.deflate(JSON.stringify(data, null, ''), { to: 'string' });
};

export const compressType = <T>(data: T): Compressed<T> => ({
  data: compress(data)
});

export const decompress = <T = any>(str: string): T => {
  if (typeof str === 'object') {
    return str as T;
  }
  return JSON.parse(pako.inflate(str, { to: 'string' })) as T;
};

export const decompressType = <T>(d: Compressed<T>): T => {
  return decompress<T>(d.data);
};
