export const getMedian = (xs: number[]) =>
  [...xs].sort((a, b) => a - b)[Math.round(xs.length / 2)];

export const getAverage = (xs: number[]) =>
  xs.reduce((m, s) => m + s, 0) / xs.length;

export const getPercentile = (percentile: number, xs: number[]) => {
  const sorted = [...xs].sort((a, b) => a - b);
  if (percentile < 0 || percentile > 1) {
    console.error('Wrong usage of percentile! Provide a value between 0 and 1');
  }
  const i = Math.round(sorted.length * percentile);
  return sorted[Math.max(i - 1, 0)];
};

export const getRollingAverage = (xs: number[], windowSize: number) => {
  const windows = xs.map((x, i) => {
    const start = Math.max(0, i + 1 - windowSize);
    return xs.slice(start, i + 1);
  });
  return windows.map(getAverage);
};
