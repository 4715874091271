import { ICounter, ICounterWithTrend, ICountWithTrend } from './analytics';
import {
  AmazonAvailability,
  LinkCheckType,
  ProductDataStatus
} from './linkCheckV2';
import { IEarning } from './performance';
import { Timestamp } from './time';

interface IProductAliasAbstract {
  createdBy: string;
  createdAt: Timestamp;
  url: string;
}

export interface IProductAliasMerged extends IProductAliasAbstract {
  type: 'merged';
  formerProductId: string;
}

export interface IProductAliasAlternative extends IProductAliasAbstract {
  type: 'alternative';
}

export interface IProductAliasAffilimateRedirect extends IProductAliasAbstract {
  type: 'affilimateRedirect';
  redirectKey: string;
}

export type IProductAlias =
  | IProductAliasMerged
  | IProductAliasAlternative
  | IProductAliasAffilimateRedirect;

export interface IProductDestination {
  url: string;
  foundAt: Timestamp;
}

export interface IProduct {
  spaceId: string;
  name: string;
  url: string;
  createdBy: string;
  partnerKey: string | null;
  createdAt: Timestamp;
  aliases: IProductAlias[];
  destinations: IProductDestination[];
  issues: IProductIssue[];
}

export interface IProductWithCountsAndTrends extends IProduct {
  counts: ICounterWithTrend;
}

export interface IProductWithCounts extends IProduct {
  counts: ICounter;
}

export interface IProductWithCountsAndTrendsAndOccurrences
  extends IProductWithCountsAndTrends {
  byOccurrence: {
    [occurrenceKey: string]: {
      counts: ICounterWithTrend;
    };
  };
}

export interface IProductWithCountsAndTrendsAndOccurrencesAndSales
  extends IProductWithCountsAndTrends {
  sales: {
    prev: {
      earnings: IEarning;
    };
    curr: {
      earnings: IEarning;
    };
  };
  byOccurrence: {
    [occurrenceKey: string]: {
      counts: ICounterWithTrend;
      sales: {
        prev: {
          earnings: IEarning;
        };
        curr: {
          earnings: IEarning;
        };
      };
    };
  };
}

export interface IPostgresProduct {
  space_id: string;
  id: string;
  name: string;
  url: string;
  partner_key: string;
  created_at: Timestamp;
  issues: IProductIssue[];
}

export interface INarrowProductWithCountsAndTrends extends IPostgresProduct {
  counts: ICounterWithTrend;
  sales: ICountWithTrend;
  epc: ICountWithTrend;
  ctr: ICountWithTrend;
}

export const getLinksForProduct = (product: IProduct): string[] => {
  return [product.url, ...(product.aliases || []).map((a) => a.url)].map((u) =>
    u.replace(/\/$/, '')
  );
};

export const getDestinationUrlForProduct = (product: IProduct) => {
  const destination = product.destinations[product.destinations.length - 1];
  return destination ? destination.url : product.url;
};

export enum SmartLabelIssueType {
  NO_PASSTHROUGH_CONFIGURED = 'NO_PASSTHROUGH_CONFIGURED',
  NO_PASSTHROUGH_POSSIBLE_BECAUSE_OF_SOURCE = 'NO_PASSTHROUGH_POSSIBLE_BECAUSE_OF_SOURCE', // like tidd.ly
  NO_PASSTHROUGH_POSSIBLE_BECAUSE_OF_TARGET = 'NO_PASSTHROUGH_POSSIBLE_BECAUSE_OF_TARGET' // like www.anrdoezrs.net
}

export enum ProductIssueType {
  LINK_CHECK = 'LINK_CHECK',
  SMART_LABEL = 'SMART_LABEL'
}

export interface IProductIssueCommon {
  createdAt: Timestamp;
  updatedAt: Timestamp;
  mutedAt: Timestamp | null;
  resolved: boolean;
  type: ProductIssueType;
}

export interface IProductLinkCheckIssueCommon extends IProductIssueCommon {
  type: ProductIssueType.LINK_CHECK;
  status: ProductDataStatus;
  partner: LinkCheckType;
  url: string;
}

export interface IProductLinkCheckIssueAmazon
  extends IProductLinkCheckIssueCommon {
  partner: LinkCheckType.AMAZON;
  subType: AmazonAvailability;
}

// Not used, but adding right away, to make sure the types are correct
export interface IProductLinkCheckIssueBooking
  extends IProductLinkCheckIssueCommon {
  partner: LinkCheckType.BOOKING;
  subType: string;
}

export interface ISmartLabelIssue extends IProductIssueCommon {
  type: ProductIssueType.SMART_LABEL;
  status: SmartLabelIssueType;
}

export type IProductLinkCheckIssue =
  | IProductLinkCheckIssueAmazon
  | IProductLinkCheckIssueBooking;

export type IProductIssue = ISmartLabelIssue | IProductLinkCheckIssue;

export type CreateProductArgs = Pick<
  IProduct,
  'name' | 'url' | 'partnerKey'
> & {
  destinationUrl: string;
  pageUrls: string[];
};

export interface IPostgresProduct {
  space_id: string;
  id: string;
  name: string;
  url: string;
  //destination_url: string;
  partner_key: string;
  created_at: Timestamp;
  issues: IProductIssue[];
}

export interface PostgresProductQuery {
  id?: string[];
  partner_key?: string[];
  name?: string;
  // Currently only specific link check issue filtering
  // typed out, as this is the only thing we use.
  issues?: {
    type: ProductIssueType.LINK_CHECK;
    status?: ProductDataStatus[];
    subType?: AmazonAvailability[];
  }[];

  page?: number;
  limit?: number; // defaults to unlimited
  orderBy?: {
    column: ('name' | 'partner_key' | 'created_at')[];
    dir?: 'DESC' | 'ASC';
  };

  returnIdsOnly?: boolean;
}
