import { Typography } from '@material-ui/core';
import React from 'react';
import { ExternalLink } from 'react-feather';
import { SaleStatus, SALE_STATUSES } from '../../../../domainTypes/performance';
import { styled } from '../../../../emotion';
import { Centered } from '../../../../layout/Centered';
import {
  ColumnName,
  COLUMNS,
  DEFAULT_VISIBLE_COLUMNS,
  GROUPERS,
  SalesGrouper,
  SalesSorter,
  SORTERS
} from '../../components/SalesList';

const Link = styled('a')`
  border-bottom: 1px solid black;
`;

const HIGHEST_EARNING_LINKS_COLUMNS = COLUMNS.filter((c) =>
  [
    'status',
    'partnerProductName',
    'trackingLabel',
    'commissionPercent',
    'amount'
  ].includes(c.key)
).map((c) => c.key);

/*
*
const HIGHEST_EARNING_ADVERTISERS_COLUMNS = COLUMNS.filter(c =>
  [
    'status',
    'advertiser',
    'trackingLabel',
    'commissionPercent',
    'amount'
  ].includes(c.key)
).map(c => c.key);
*/

const EMPTY_STATES = {
  NO_REFUNDS: (
    <Centered height={400}>
      <div style={{ textAlign: 'center', maxWidth: '500px' }}>
        <Typography variant="h4" component="p" paragraph>
          We couldn't find any refunds!
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          Refunds are sales that were previously confirmed, and then later
          reversed. Try expanding your timeframe (we recommend 3-6 months) to
          find trends in refunded items.
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          <Link
            href="https://help.affilimate.com/articles/sales-statuses/?utm_source=app"
            target="_blank"
          >
            Learn more about refunded sales <ExternalLink size={16} />
          </Link>
        </Typography>
      </div>
    </Centered>
  ),
  NO_SALES: (
    <Centered height={400}>
      <div style={{ textAlign: 'center', maxWidth: '500px' }}>
        <Typography variant="h4" component="p" paragraph>
          We couldn't find any sales!
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          We couldn't find any sales at all during the timeframe you've
          selected.
        </Typography>
        <Typography variant="body1" component="p">
          Either adjust your timeframe, or double-check you've set up your
          integrations and uploaded your reports.
        </Typography>
      </div>
    </Centered>
  ),
  NO_CANCELLATIONS: (
    <Centered height={400}>
      <div style={{ textAlign: 'center', maxWidth: '550px' }}>
        <Typography variant="h4" component="p" paragraph>
          We couldn't find any cancelations!
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          Canceled sales are purchases that are canceled before being being
          delivered or used. Try expanding your timeframe (we recommend 3-6
          months) to find trends in cancelations.
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          <Link
            href="https://help.affilimate.com/articles/sales-statuses/?utm_source=app"
            target="_blank"
          >
            Learn more about canceled sales <ExternalLink size={16} />
          </Link>
        </Typography>
      </div>
    </Centered>
  ),
  NO_LABELED_SALES: (
    <Centered height={400}>
      <div style={{ textAlign: 'center', maxWidth: '700px' }}>
        <Typography variant="h4" component="p" paragraph>
          We couldn't find any smart labeled links!
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          Affilimate connects your individual links to sales via Smart Labels.
          <br />
          Make sure you have Smart Labels turned on in Integrations to get the
          most out of this preset.
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          <Link
            href="https://help.affilimate.com/articles/smart-labels/?utm_source=app"
            target="_blank"
          >
            Learn more about smart labels <ExternalLink size={16} />
          </Link>
        </Typography>
      </div>
    </Centered>
  )
};

export type IPreset = {
  label: string;
  groupBy: SalesGrouper;
  statuses: Set<SaleStatus>;
  sorter: SalesSorter;
  columns: Set<ColumnName>;
  collapsed: boolean;
  emptyState: React.ReactNode;
};

export const PRESETS: IPreset[] = [
  {
    label: 'Most refunded items',
    groupBy: GROUPERS.productSold,
    statuses: new Set(['Refunded']),
    sorter: SORTERS.itemsRefunded,
    columns: new Set(DEFAULT_VISIBLE_COLUMNS),
    collapsed: true,
    emptyState: EMPTY_STATES.NO_REFUNDS
  },
  {
    label: 'Most profitable items',
    groupBy: GROUPERS.productSold,
    statuses: new Set(SALE_STATUSES),
    sorter: SORTERS.amount,
    columns: new Set(DEFAULT_VISIBLE_COLUMNS),
    collapsed: true,
    emptyState: EMPTY_STATES.NO_SALES
  },
  {
    label: 'Most purchased items',
    groupBy: GROUPERS.productSold,
    statuses: new Set(SALE_STATUSES),
    sorter: SORTERS.itemsSold,
    columns: new Set(DEFAULT_VISIBLE_COLUMNS),
    collapsed: true,
    emptyState: EMPTY_STATES.NO_SALES
  },
  {
    label: 'Most cancelations',
    groupBy: GROUPERS.productSold,
    statuses: new Set(['Cancelled']),
    sorter: SORTERS.itemsCancelled,
    columns: new Set(DEFAULT_VISIBLE_COLUMNS),
    collapsed: true,
    emptyState: EMPTY_STATES.NO_CANCELLATIONS
  },
  {
    label: 'Pages with most refunds',
    groupBy: GROUPERS.page,
    statuses: new Set(['Refunded']),
    sorter: SORTERS.itemsRefunded,
    columns: new Set(DEFAULT_VISIBLE_COLUMNS),
    collapsed: true,
    emptyState: EMPTY_STATES.NO_REFUNDS
  },
  {
    label: 'Highest earning links',
    groupBy: GROUPERS.linkClicked,
    statuses: new Set(SALE_STATUSES),
    sorter: SORTERS.amount,
    columns: new Set(HIGHEST_EARNING_LINKS_COLUMNS),
    collapsed: true,
    emptyState: EMPTY_STATES.NO_LABELED_SALES
  }
  /*
  {
    label: 'Highest earning advertisers',
    groupBy: GROUPERS.advertiser,
    statuses: new Set(SALE_STATUSES),
    sorter: SORTERS.amount,
    columns: new Set(HIGHEST_EARNING_ADVERTISERS_COLUMNS),
    collapsed: true,
    emptyState: EMPTY_STATES.NO_LABELED_SALES
  }
*/
];
