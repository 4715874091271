import React from 'react';
import { Doc } from '../../../../../domainTypes/document';
import { ReleaseNotification } from '../../../../../domainTypes/notifications';
import { useRoutes } from '../../../../../routes';
import { Body, Frame, Header } from '../../Item/Frame';

export const Release_AffiliateProgram = ({
  n
}: {
  n: Doc<ReleaseNotification>;
}) => {
  const { ROUTES } = useRoutes();
  return (
    <Frame n={n} href={ROUTES.settings.affiliateProgram.url()}>
      <Header>Affilimate now has an affiliate program!</Header>
      <Body>
        Invite your friends and people in your communities to join Affilimate
        with your affiliate link. Click this notification to get your link and
        unique discount code.
      </Body>
    </Frame>
  );
};
