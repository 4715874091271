import { IApiReportHandler } from '../types';
import { HANDLER_CONFIGS } from '../../../../../domainTypes/reporting';
import { getSecretValue } from '../../../../../services/secret';

export const CAKE: IApiReportHandler = {
  type: 'API',
  partnerKey: 'cake',
  configName: 'cakeV1',
  configFields: HANDLER_CONFIGS.cakeV1,
  additionalName: (_, secret) => {
    if (!secret) {
      return null;
    }
    return getSecretValue(secret.data, 'advertiser', '') || null;
  }
};
