import { Dialog, DialogContent } from '@material-ui/core';
import React from 'react';

export const AutoTaggingDialog = ({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>hi</DialogContent>
    </Dialog>
  );
};
