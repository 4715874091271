import * as React from 'react';
import { CachedAdvertiser } from './advertiser';
import { AnalyticsField } from './analytics_v2';
import { IChannelType } from './channels';

export enum LinkGeneratorOptionGroup {
  Standard = 'Standard',
  Custom = 'Custom',
  Offsite = 'Offsite only'
}

export type ICreateLinkOptions = {
  ignoreCache?: boolean;
};

export type LinkGeneratorRequest = {
  url: string;
  subid: string;
  spaceId: string;
  options?: ICreateLinkOptions;
  source?: 'extension' | 'app' | 'api';
};

export type LinkGeneratorParameter =
  | 'channel'
  | 'subid'
  | 'page_url'
  | 'click_data_01'
  | 'click_data_02'
  | 'click_data_03'
  | 'click_data_04'
  | 'click_data_05'
  | 'click_data_06'
  | 'click_data_07'
  | 'click_data_08'
  | 'click_data_09'
  | 'click_data_10'
  | 'utm_source'
  | 'utm_medium'
  | 'utm_campaign'
  | 'utm_term'
  | 'utm_content';

export type LinkParameter = {
  key: LinkGeneratorParameter;
  value: string;
};

export type LinkGeneratorChannelOption = {
  value: string;
  label: React.ReactNode;
  group: 'sites' | IChannelType;
};

export type LinkGeneratorOption =
  | {
      value: LinkGeneratorParameter;
      label: string;
      group: LinkGeneratorOptionGroup;
      inputType: 'text';
    }
  | {
      value: LinkGeneratorParameter;
      label: string;
      group: LinkGeneratorOptionGroup;
      inputType: 'autocomplete';
      field: AnalyticsField;
    }
  | {
      value: LinkGeneratorParameter;
      label: string;
      group: LinkGeneratorOptionGroup;
      inputType: 'select';
      options: { value: string; label: string }[];
    }
  | {
      value: LinkGeneratorParameter;
      label: string;
      group: LinkGeneratorOptionGroup;
      inputType: 'channel';
      options: LinkGeneratorChannelOption[];
    };

export type GeneratedLink = {
  partnerKey: string;
  advertiser: string;
  advertiserId: string;
  // Not returned if user does not have access
  // to advertiser details (e.g. rates)
  advertiserDetails: CachedAdvertiser | null;
  connection: string;
  connectionId: string;
  connectionNickname: string;
  channel: string;
  channelId: string;
  link: string;
  wrappedLink?: string;
};

export enum LinkGeneratorWarning {
  NoMatchingAdvertisers = 'NoMatchingAdvertisers',
  LinkFormatError = 'LinkFormatError',
  ErrorFetchingAdvertisers = 'ErrorFetchingAdvertisers',
  ErrorCreatingLink = 'ErrorCreatingLink',
  Other = 'Other'
}

export type LinkGeneratorMessage = {
  type: LinkGeneratorWarning;
  message: string;
  integrationId?: string;
  partnerKey?: string;
};

export type LinkGeneratorResponse = {
  links: GeneratedLink[];
  messages: LinkGeneratorMessage[];
  usedCache: boolean;
};
