import {
  CalendarDate,
  toCalendarDate as dateTimeToCalendarDate,
  ZonedDateTime
} from '@internationalized/date';
import { isEqual, isNil } from 'lodash';
import moment from 'moment-timezone';
import { ComponentProps, useCallback, useMemo, useState } from 'react';
import {
  TimeframeComparisonDefinition,
  TimeframeDefinition,
  TimeframeIntervalDefinition,
  TimeframeRangeDefinition
} from '../../domainTypes/timeframe';
import {
  getCompareMoments,
  getTimeframeMoments,
  toMoments
} from '../../hooks/timeframe/toMoments';
import { useCurrentUser } from '../../services/currentUser';
import { useFeatureEnabled } from '../../services/features';
import { useMixpanel } from '../../services/mixpanel';
import { prettifyDateRange } from '../../services/time';
import { DateRangeInput } from './DateRangeInput';
import { DateRangePicker } from './DateRangePicker';
import { Granularity } from './GranularitySwitch';
import { useMaxDateTime, useMinDateTime } from './service/bounds';
import {
  endOfDay,
  rangeToISOStrings,
  rangeToMoments,
  toCalendarDate,
  toEndOfDay,
  toStartOfDay,
  toZonedDateTime
} from './service/conversion';
import { IComparisonOption, ITimeframeOption } from './service/options';
import {
  CalendarDateRange,
  clampDateRange,
  DateTimeRange
} from './service/range';
import { bestIntervalForTimeframe } from '../../hooks/timeframe';

const TIME_SETTINGS_LOCAL_STORAGE_KEY =
  'affilimate_timeframe_picker_granularity';

const timeframeToMoments = (state: TimeframeState, tz: string) => {
  if (state.kind === 'range') {
    return rangeToMoments(state, tz);
  }
  return getTimeframeMoments(state.preset, tz);
};

interface Labels {
  rangeName?: string;
  minDateName?: string;
  maxDateName?: string;
}

const validateRange = (
  range: DateTimeRange,
  min: ZonedDateTime,
  max: ZonedDateTime,
  labels: Labels = {}
): string | undefined => {
  const {
    rangeName = 'Range',
    minDateName = min.toDate().toLocaleString(),
    maxDateName = max.toDate().toLocaleString()
  } = labels;
  if (range.start.compare(range.end) > 0) {
    return `Start date must be before end date`;
  }
  if (range.start.compare(min) < 0) {
    return `${rangeName} can't start before ${minDateName}`;
  }
  if (range.end.compare(max) > 0) {
    return `${rangeName} can't end after ${maxDateName}`;
  }
  return undefined;
};

const validateTimeframe = (
  state: TimeframeState,
  min: ZonedDateTime,
  max: ZonedDateTime,
  labels?: Labels
): string | undefined => {
  if (state.kind !== 'range') return undefined;
  return validateRange(state, min, max, labels);
};

const validateComparison = (
  state: ComparisonState,
  min: ZonedDateTime,
  max: ZonedDateTime,
  labels?: Labels
): string | undefined => {
  if (state.kind !== 'range') return undefined;
  return validateRange(state, min, max, labels);
};

const timeframeToDateTimeRange = (
  timeframe: TimeframeState,
  tz: string
): DateTimeRange => {
  if (timeframe.kind === 'range') {
    return {
      start: timeframe.start,
      end: timeframe.end
    };
  }
  const { end, start } = getTimeframeMoments(timeframe.preset, tz);
  return {
    start: toZonedDateTime(start),
    end: toZonedDateTime(end)
  };
};

const comparisonToDateTimeRange = (
  state: TimeframePickerMenuInternalState,
  minDateTime: ZonedDateTime,
  maxDateTime: ZonedDateTime,
  tz: string
): DateTimeRange | null => {
  const { comparison, timeframe } = state;
  if (isNil(comparison)) return null;
  if (comparison.kind === 'range') {
    return {
      start: comparison.start,
      end: comparison.end
    };
  }
  if (!isNil(validateTimeframe(timeframe, minDateTime, maxDateTime))) {
    return null;
  }
  const compare = getCompareMoments(
    comparison.option,
    timeframeToMoments(timeframe, tz),
    tz
  );
  if (isNil(compare)) return null;
  return {
    start: toZonedDateTime(compare.start),
    end: toZonedDateTime(compare.end)
  };
};

type TimeframeState =
  | {
      kind: 'preset';
      preset: TimeframeRangeDefinition;
    }
  | {
      kind: 'range';
      start: ZonedDateTime;
      end: ZonedDateTime;
    };

type ComparisonState =
  | {
      kind: 'preset';
      option: TimeframeComparisonDefinition;
    }
  | {
      kind: 'range';
      start: ZonedDateTime;
      end: ZonedDateTime;
    };

type TimeframePickerMenuInternalState = {
  timeframe: TimeframeState;
  timeframeFocus: CalendarDate;
  comparison: ComparisonState;
  comparisonFocus?: CalendarDate;
  granularity: Granularity;
  interval?: TimeframeIntervalDefinition;
};

const toTimeframeDefinition = (
  state: TimeframePickerMenuInternalState,
  tz: string
): TimeframeDefinition => {
  const range: TimeframeRangeDefinition =
    state.timeframe.kind === 'preset'
      ? state.timeframe.preset
      : {
          kind: 'custom',
          ...rangeToISOStrings(state.timeframe, tz)
        };
  const comparison: TimeframeComparisonDefinition =
    state.comparison.kind === 'preset'
      ? state.comparison.option
      : {
          kind: 'custom',
          ...rangeToISOStrings(state.comparison, tz)
        };
  return {
    range,
    comparison
  };
};

const requiresMinuteGranularity = (
  definition: TimeframeRangeDefinition
): boolean =>
  definition.kind === 'period' && definition.value.type === 'latest';

export const timeframeRangeDefinitionToTrackingProps = (
  def: TimeframeRangeDefinition
) => {
  if (def.kind === 'custom') {
    return { range_kind: 'custom' };
  }
  return {
    range_kind: 'preset',
    range_type: def.value.type,
    range_duration:
      def.value.type === 'last' || def.value.type === 'latest'
        ? def.value.duration
        : def.value.unit
  };
};

export const timeframeComparisionDefinitionToTrackingProps = (
  def: TimeframeComparisonDefinition
) => {
  if (def.kind === 'custom') {
    return { compare_range_kind: 'custom' };
  }
  return {
    compare_range_kind: def.kind,
    compare_range_duration: def.kind === 'timeshift' ? def.duration : ''
  };
};

export interface TimeframePickerMenuState {
  timeframeInputProps: ComponentProps<typeof DateRangeInput>;
  timeframePickerProps: ComponentProps<typeof DateRangePicker>;
  timeframePresetSelectProps: {
    value?: TimeframeRangeDefinition;
    onChange: (option: TimeframeRangeDefinition) => void;
  };

  comparisonInputProps: ComponentProps<typeof DateRangeInput>;
  comparisonPickerProps: ComponentProps<typeof DateRangePicker>;
  comparisonPresetSelectProps: {
    value?: TimeframeComparisonDefinition;
    onChange: (option: TimeframeComparisonDefinition) => void;
  };

  isMenuValid: boolean;
  timeframeDefinition: TimeframeDefinition;
}

export const findDefinitionLabels = (
  value: TimeframeDefinition,
  timeframeOptions: ITimeframeOption[],
  comparisonOptions: IComparisonOption[],
  tz: string
): {
  timeframe: string;
  comparison?: string;
} => {
  const { comparison, range } = value;
  const timeframeOption = timeframeOptions.find((option) =>
    isEqual(option.value, range)
  );
  const comparisonOption = comparisonOptions.find((option) =>
    isEqual(option.value, comparison)
  );
  const timeframeLabel = timeframeOption
    ? timeframeOption.label
    : range.kind === 'custom'
    ? prettifyDateRange(
        moment(range.start).tz(tz),
        moment(range.end).tz(tz),
        tz
      )
    : 'Unknown';
  const comparisonLabel = comparisonOption
    ? comparisonOption.label
    : comparison.kind === 'custom'
    ? prettifyDateRange(
        moment(comparison.start).tz(tz),
        moment(comparison.end).tz(tz),
        tz
      )
    : 'Unknown';

  return {
    timeframe: timeframeLabel,
    comparison: comparisonLabel
  };
};

export const useTimePickerMenuState = (
  definition: TimeframeDefinition,
  timeframePresets: ITimeframeOption[],
  comparisonPresets: IComparisonOption[]
): TimeframePickerMenuState => {
  const mixpanel = useMixpanel();
  const { tz } = useCurrentUser();
  const hasRealtimeFeature = useFeatureEnabled('REFERRER_REPORTS_V1');
  const minDateTime = useMinDateTime();
  const maxDateTime = useMaxDateTime();
  const minDate = useMemo(() => dateTimeToCalendarDate(minDateTime), [
    minDateTime
  ]);
  const maxDate = useMemo(() => dateTimeToCalendarDate(maxDateTime), [
    maxDateTime
  ]);

  const definitionLabels = useMemo(
    () =>
      findDefinitionLabels(definition, timeframePresets, comparisonPresets, tz),
    [definition, timeframePresets, comparisonPresets, tz]
  );

  const [state, setState] = useState<TimeframePickerMenuInternalState>(() => {
    const { start, end, compare } = toMoments(definition, tz);
    const getGranularity = (): Granularity => {
      if (!hasRealtimeFeature) return 'day';
      if (requiresMinuteGranularity(definition.range)) {
        return 'minute';
      }
      const stored = localStorage.getItem(
        TIME_SETTINGS_LOCAL_STORAGE_KEY
      ) as Granularity;
      return stored ? (stored as Granularity) : 'day';
    };

    const getTimeframeState = (): TimeframeState => {
      if (definition.range.kind === 'period') {
        return {
          kind: 'preset',
          preset: definition.range
        };
      }
      if (
        timeframePresets.some((option) =>
          isEqual(option.value, definition.range)
        )
      ) {
        return {
          kind: 'preset',
          preset: definition.range
        };
      }
      return {
        kind: 'range',
        start: toZonedDateTime(start),
        end: toZonedDateTime(end)
      };
    };

    const getComparisonState = (): ComparisonState => {
      if (definition.comparison.kind === 'previous') {
        return {
          kind: 'preset',
          option: definition.comparison
        };
      }
      if (
        comparisonPresets.some((option) =>
          isEqual(option.value, definition.comparison)
        )
      ) {
        return {
          kind: 'preset',
          option: definition.comparison
        };
      }
      return {
        kind: 'range',
        start: toZonedDateTime(compare!.start),
        end: toZonedDateTime(compare!.end)
      };
    };

    const timeframeFocus = toCalendarDate(start);
    const comparisonFocus = toCalendarDate(compare?.start);
    return {
      timeframe: getTimeframeState(),
      comparison: getComparisonState(),
      timeframeFocus,
      comparisonFocus,
      granularity: getGranularity(),
      interval: definition.interval
    };
  });

  const adjustFocus = useCallback(
    (
      state: TimeframePickerMenuInternalState,
      skipTimeframeFocus = false
    ): TimeframePickerMenuInternalState => {
      const { end } = timeframeToDateTimeRange(state.timeframe, tz);
      const timeframeFocus = dateTimeToCalendarDate(end);
      const comparisonRange = comparisonToDateTimeRange(
        state,
        minDateTime,
        maxDateTime,
        tz
      );
      const comparisonFocus = comparisonRange
        ? dateTimeToCalendarDate(comparisonRange.end)
        : undefined;

      return {
        ...state,
        timeframeFocus: skipTimeframeFocus
          ? state.timeframeFocus
          : timeframeFocus,
        comparisonFocus
      };
    },
    [maxDateTime, minDateTime, tz]
  );

  const adjustInterval = useCallback(
    (
      state: TimeframePickerMenuInternalState
    ): TimeframePickerMenuInternalState => {
      if (isNil(state.interval)) return state;
      const { start, end } = timeframeToMoments(state.timeframe, tz);
      const newInterval = bestIntervalForTimeframe(start, end);
      return {
        ...state,
        interval: newInterval
      };
    },
    [tz]
  );

  return useMemo(() => {
    const selectedTimeframeOption: TimeframeRangeDefinition | undefined =
      state.timeframe.kind === 'preset' ? state.timeframe.preset : undefined;

    const selectTimeframeOption = (definition: TimeframeRangeDefinition) => {
      setState((state) => {
        const timeframe: TimeframeState = {
          kind: 'preset',
          preset: definition
        };

        mixpanel.track('timeframe_picker_timeframe_period_selected', {
          ...timeframeRangeDefinitionToTrackingProps(definition),
          range_label: definitionLabels.timeframe
        });

        return adjustInterval(
          adjustFocus({
            ...state,
            timeframe,
            granularity: requiresMinuteGranularity(definition)
              ? 'minute'
              : state.granularity
          })
        );
      });
    };

    const timeframeDateTimeRange = timeframeToDateTimeRange(
      state.timeframe,
      tz
    );
    const onTimeframeDateRangeChange = (range: CalendarDateRange | null) => {
      if (range !== null) {
        setState((state) => {
          const timeframe: TimeframeState = {
            kind: 'range',
            start: toStartOfDay(range.start, tz),
            end: toEndOfDay(range.end, tz)
          };

          mixpanel.track('timeframe_picker_timeframe_date_picker_changed', {
            start: timeframe.start.toAbsoluteString(),
            end: timeframe.end.toAbsoluteString(),
            tz
          });

          return adjustInterval({
            ...state,
            timeframe
          });
        });
      }
    };

    const onTimeframeDateTimeRangeChange = (range: DateTimeRange | null) => {
      if (range !== null) {
        setState((state) => {
          const end =
            state.granularity === 'minute' ? range.end : endOfDay(range.end);
          const timeframe: TimeframeState = {
            kind: 'range',
            start: range.start,
            end
          };
          mixpanel.track('timeframe_picker_timeframe_date_input_changed', {
            start: range.start.toAbsoluteString(),
            end: end.toAbsoluteString(),
            tz
          });

          return adjustInterval(
            adjustFocus({
              ...state,
              timeframe
            })
          );
        });
      }
    };

    const timeframeValidation = validateTimeframe(
      state.timeframe,
      minDateTime,
      maxDateTime,
      {
        rangeName: 'Timeframe'
      }
    );

    const isTimeframeValid = isNil(timeframeValidation);
    const timeframePickerValue = isTimeframeValid
      ? clampDateRange(
          {
            start: dateTimeToCalendarDate(timeframeDateTimeRange.start),
            end: dateTimeToCalendarDate(timeframeDateTimeRange.end)
          },
          minDate,
          maxDate
        )
      : null;

    const timeframeFocus = state.timeframeFocus;
    const onTimeframeFocusChange = (date: CalendarDate) => {
      setState((state) => ({ ...state, timeframeFocus: date }));
    };

    const selectedComparisonOption =
      state.comparison?.kind === 'preset' ? state.comparison.option : undefined;
    const selectComparisonOption = (
      definition: TimeframeComparisonDefinition
    ) => {
      setState((state) => {
        const comparison: ComparisonState = {
          kind: 'preset',
          option: definition
        };

        mixpanel.track('timeframe_picker_compare_period_selected', {
          ...timeframeComparisionDefinitionToTrackingProps(definition),
          compare_range_label: definitionLabels.comparison || ''
        });

        return adjustFocus(
          {
            ...state,
            comparison,
            comparisonFocus
          },
          true
        );
      });
    };

    const comparisonRangeValue = comparisonToDateTimeRange(
      state,
      minDateTime,
      maxDateTime,
      tz
    );
    const onComparisonDateRangeChange = (range: CalendarDateRange | null) => {
      if (range !== null) {
        setState((state) => {
          const comparison: ComparisonState = {
            kind: 'range',
            start: toStartOfDay(range.start, tz),
            end: toEndOfDay(range.end, tz)
          };

          mixpanel.track('timeframe_picker_compare_date_input_changed', {
            start: comparison.start.toAbsoluteString(),
            end: comparison.end.toAbsoluteString(),
            tz
          });

          return {
            ...state,
            comparison
          };
        });
      }
    };

    const onComparisonDateTimeRangeChange = (range: DateTimeRange | null) => {
      if (range !== null) {
        setState((state) => {
          const end =
            state.granularity === 'minute' ? range.end : endOfDay(range.end);
          const comparison: ComparisonState = {
            kind: 'range',
            start: range.start,
            end
          };

          mixpanel.track('timeframe_picker_compare_date_picker_changed', {
            start: comparison.start.toAbsoluteString(),
            end: comparison.end.toAbsoluteString(),
            tz
          });

          return adjustFocus(
            {
              ...state,
              comparison
            },
            true
          );
        });
      }
    };

    const comparisonValidation = validateComparison(
      state.comparison,
      minDateTime,
      maxDateTime,
      {
        rangeName: 'Comparison'
      }
    );
    const isComparisonValid = isNil(comparisonValidation);

    const comparisonFocus = state.comparisonFocus;
    const onComparisonFocusChange = (date: CalendarDate) => {
      setState((state) => ({ ...state, comparisonFocus: date }));
    };

    const setGranularity = (value: Granularity) => {
      setState((state) => ({ ...state, granularity: value }));
      localStorage.setItem(TIME_SETTINGS_LOCAL_STORAGE_KEY, value);
    };

    const timeframeDefinition = toTimeframeDefinition(state, tz);

    const timeframeInputProps: ComponentProps<typeof DateRangeInput> = {
      value: timeframeDateTimeRange,
      onChange: onTimeframeDateTimeRangeChange,
      minValue: minDateTime,
      maxValue: maxDateTime,
      errorMessage: timeframeValidation,
      granularity: state.granularity,
      onGranularityChange: setGranularity,
      hideGranularitySwitch: !hasRealtimeFeature
    };

    const timeframePickerProps: ComponentProps<typeof DateRangePicker> = {
      value: timeframePickerValue,
      onChange: onTimeframeDateRangeChange,
      focusedValue: timeframeFocus,
      onFocusChange: onTimeframeFocusChange,
      minValue: minDate,
      maxValue: maxDate
    };

    const comparisonInputProps: ComponentProps<typeof DateRangeInput> = {
      value: comparisonRangeValue,
      onChange: onComparisonDateTimeRangeChange,
      minValue: minDateTime,
      maxValue: maxDateTime,
      errorMessage: comparisonValidation,
      granularity: state.granularity,
      onGranularityChange: setGranularity,
      hideGranularitySwitch: !hasRealtimeFeature
    };

    const comparisonPickerProps: ComponentProps<typeof DateRangePicker> = {
      value:
        comparisonRangeValue &&
        clampDateRange(
          {
            start: dateTimeToCalendarDate(comparisonRangeValue.start),
            end: dateTimeToCalendarDate(comparisonRangeValue.end)
          },
          minDate,
          maxDate
        ),
      onChange: onComparisonDateRangeChange,
      focusedValue: comparisonFocus,
      onFocusChange: onComparisonFocusChange,
      minValue: minDate,
      maxValue: maxDate
    };

    return {
      timeframeInputProps: timeframeInputProps,
      timeframePickerProps: timeframePickerProps,
      timeframePresetSelectProps: {
        value: selectedTimeframeOption,
        onChange: selectTimeframeOption
      },
      comparisonInputProps: comparisonInputProps,
      comparisonPickerProps: comparisonPickerProps,
      comparisonPresetSelectProps: {
        value: selectedComparisonOption,
        onChange: selectComparisonOption
      },
      timeframeDefinition,
      isMenuValid: isTimeframeValid && isComparisonValid
    };
  }, [
    state,
    tz,
    minDateTime,
    maxDateTime,
    minDate,
    maxDate,
    hasRealtimeFeature,
    mixpanel,
    definitionLabels.timeframe,
    definitionLabels.comparison,
    adjustInterval,
    adjustFocus
  ]);
};
