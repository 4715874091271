import { capitalize, isNil, sortBy } from 'lodash';
import React, { useState } from 'react';
import moment from 'moment-timezone';

import {
  Bar,
  BarChart,
  Legend,
  RechartsFunction,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts';
import { IDailyCounter, Timeframe } from '../../../domainTypes/analytics';
import { IPartner } from '../../../domainTypes/partners';
import { formatTimeKey } from '../../../services/analytics';
import {
  useColorsSpectrumUnknown,
  useColorUnknown
} from '../../../services/color';
import { EmptyContent } from '../../Charts/ChartCard';
import { Loader } from '../../Loader';
import { CustomTooltip } from '../CustomTooltip';

type Props = {
  counters: void | { partner: IPartner; dailyCounters: IDailyCounter[] }[];
  loading: boolean;
  count: number;
  animate?: boolean;
  timeframe: Timeframe;
  height?: number;
};

const HEIGHT = 296;

type Margin = 'normal' | 'dense';

interface WithMargin {
  margin?: Margin;
}

type BarEl = {
  timeKey: string;
  partners: { [key: string]: number };
};

const TICK_STYLES: {
  [K in Margin]: {
    fontSize?: number;
  };
} = {
  normal: { fontSize: 12 },
  dense: { fontSize: 10 }
};

export const PartnerBarChart = React.memo(
  ({
    counters,
    loading,
    count = 10,
    animate,
    margin = 'normal',
    timeframe,
    height
  }: Props & WithMargin) => {
    const [selectedPartner, setSelectedPartner] = useState<IPartner | null>(
      null
    );
    const [hoveredPartner, setHoveredPartner] = useState<IPartner | null>(null);
    const colorsUnknown = useColorsSpectrumUnknown();
    const colorUnknown = useColorUnknown();

    if (loading || !counters) {
      return <Loader height={HEIGHT} />;
    }

    const hasAnyClicks = !!counters.find((p) =>
      Boolean(p.dailyCounters.find((d) => d.clicked > 0))
    );

    if (!hasAnyClicks) {
      return <EmptyContent />;
    }

    const sortedCounters = sortBy(counters, (c) => {
      return c.dailyCounters.reduce((m, d) => m - d.clicked, 0);
    });

    const partners = sortedCounters.map((counter) => counter.partner);

    let unknownI = 0;
    const data = sortedCounters.reduce<{ [timeKey: string]: BarEl }>(
      (result, el) => {
        const { partner, dailyCounters } = el;
        if (partner.color === colorUnknown) {
          partner.color = colorsUnknown[unknownI];
          unknownI += 1;
        }
        dailyCounters.forEach((c) => {
          const el: BarEl = (result[c.timeKey] = result[c.timeKey] || {
            timeKey: c.timeKey,
            partners: {}
          });
          if (selectedPartner === null || partner.key === selectedPartner.key) {
            el.partners[partner.key] = c.clicked;
          } else {
            el.partners[partner.key] = 0;
          }
        });
        return result;
      },
      {}
    );

    const handleMouseEnter: RechartsFunction = (entry) => {
      const { dataKey } = entry;
      const partner = partners.find((p) => `partners.${p.key}` === dataKey);

      if (partner) {
        setHoveredPartner(partner);
      }
    };

    const handleMouseLeave: RechartsFunction = () => {
      setHoveredPartner(null);
    };

    const handleClickPartner: RechartsFunction = (_, i) => {
      const partner = partners[i];
      if (selectedPartner === null) {
        setSelectedPartner(partner);
        return;
      }

      if (selectedPartner === partner) {
        setSelectedPartner(null);
        return;
      }

      setSelectedPartner(partner);
    };

    const getOpacity = (d: any) => {
      if (hoveredPartner === null) {
        return 1;
      }
      return d.partner.key === hoveredPartner.key ? 1 : 0.5;
    };

    const startsInCurrentYear =
      timeframe &&
      moment(timeframe.start).format('YYYY') === moment().format('YYYY');

    return (
      <ResponsiveContainer
        width="99%"
        height={height}
        aspect={isNil(height) ? 2 : undefined}
      >
        <BarChart
          data={Object.values(data)}
          layout="horizontal"
          maxBarSize={12}
        >
          <XAxis
            dataKey="timeKey"
            tickFormatter={(tk) =>
              formatTimeKey(
                tk || '',
                startsInCurrentYear ? 'MMM DD' : "MMM DD, 'YY"
              )
            }
            tick={TICK_STYLES[margin]}
          />
          <YAxis tick={TICK_STYLES[margin]} />
          {sortedCounters.slice(0, count).map((d) => (
            <Bar
              isAnimationActive={animate}
              key={d.partner.key}
              dataKey={`partners.${d.partner.key}`}
              stackId="a"
              opacity={getOpacity(d)}
              fill={d.partner.color}
            />
          ))}
          <Legend
            iconType="circle"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClickPartner}
            wrapperStyle={{ cursor: 'pointer' }}
            formatter={(_, __, index) => {
              const partner =
                index !== undefined ? partners[index].name : 'Unknown';
              return partner;
            }}
          />
          <Tooltip
            cursor={false}
            separator=": "
            content={<CustomTooltip />}
            labelFormatter={(label: TooltipProps['label']) => (
              <>
                {formatTimeKey(
                  label as string,
                  startsInCurrentYear ? 'ddd MMM DD' : "ddd MMM DD, 'YY"
                )}
              </>
            )}
            formatter={(value, name) => {
              const partnerKey = name.substr(
                'partners.'.length,
                name.length - 1
              );
              const partner = partners.find((p) => p.key === partnerKey);
              const partnerName = partner
                ? partner.name
                : capitalize(partnerKey);
              return [value, partnerName];
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
);
