import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useMemo } from 'react';
import { BarChart2, Copy, ExternalLink, Link as Link2, X } from 'react-feather';
import { AlertBox } from '../../../components/AlertBox';
import { Dash } from '../../../components/Table/CountCell';
import { styled } from '../../../emotion';
import { useDialogState } from '../../../hooks/useDialogState';
import { usePromise } from '../../../hooks/usePromise';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { getProductByIdPg, normalizeLinkUrl } from '../../../services/products';

// It should wrap links no matter what
const LinkWrapper = styled('div')`
  word-break: break-all;
  background-color: ${(p) => p.theme.palette.grey[100]};
  color: ${(p) => p.theme.palette.grey[800]};
  padding: ${(p) => p.theme.spacing(2)}px;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  margin: ${(p) => p.theme.spacing(2)}px 0;
  font-size: ${(p) => p.theme.typography.body1.fontSize};
`;

const StyledLink = styled('a')`
  color: ${(p) => p.theme.palette.primary.main};
  border-bottom: 1px solid;
  text-decoration: none;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: right;

  button {
    color: rgba(0, 0, 0, 0.54);

    &:hover {
      color: rgba(0, 0, 0, 0.87);
    }
  }
`;

export const LinkActionsMenu = ({
  linkId,
  spaceId
}: {
  linkId: string;
  spaceId: string;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [product, loading] = usePromise(() => {
    return getProductByIdPg(spaceId, linkId);
  }, [spaceId, linkId]);

  const { dialogOpen, setDialogOpen, closeDialog } = useDialogState();
  const { normalizedUrl, usingModifiedUrl } = useMemo(() => {
    if (!product) {
      return { normalizedUrl: '', usingModifiedUrl: false };
    }
    return normalizeLinkUrl(product.url, product.partner_key);
  }, [product]);

  if (loading) {
    const SIZE = 20;
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(2, ${SIZE}px)`,
          gridColumnGap: '4px',
          alignItems: 'center',
          justifyContent: 'right'
        }}
      >
        <Skeleton variant="circle" width={SIZE} height={SIZE} />
        <Skeleton variant="circle" width={SIZE} height={SIZE} />
      </div>
    );
  }

  if (!product) {
    return <Dash />;
  }

  return (
    <>
      <ButtonWrapper>
        <Tooltip title="View analytics" placement="top">
          <ButtonBase>
            <BarChart2 size={16} />
          </ButtonBase>
        </Tooltip>
        <Tooltip title="Copy link and view details" placement="top">
          <ButtonBase
            onClick={(e) => {
              e.stopPropagation();
              setDialogOpen(true);
            }}
          >
            <Link2 size={16} />
          </ButtonBase>
        </Tooltip>
      </ButtonWrapper>
      <Dialog
        open={dialogOpen}
        onClick={(e) => e.stopPropagation()}
        onClose={closeDialog}
      >
        <DialogContent>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '24px'
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              style={{
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                gap: '8px'
              }}
            >
              <Link2 size={18} />
              Copy or test link
            </Typography>
            <IconButton onClick={closeDialog}>
              <X size={18} />
            </IconButton>
          </div>
          <Typography component="p" variant="body1" paragraph>
            Copy the link below to share it with your audience or test it
            yourself. Be careful about re-using links, especially if they
            include existing SubIDs.
          </Typography>
          <LinkWrapper>
            <StyledLink
              href={normalizedUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {normalizedUrl} <ExternalLink size={16} />
            </StyledLink>
          </LinkWrapper>
          {usingModifiedUrl && (
            <AlertBox variant="pending">
              <strong>Head's up.</strong> This link has been modified from the
              analytics data so you can copy and test it. When in doubt,
              generate a new link from a clean product URL.
            </AlertBox>
          )}
          <Typography
            component="p"
            variant="body1"
            color="textSecondary"
            paragraph
          >
            <strong>Important</strong> &mdash; Never copy the clicked URL from
            your browser's address bar. It may contain click IDs that prevent
            you from getting credit for visitor clicks.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigator.clipboard.writeText(normalizedUrl);
              if (usingModifiedUrl) {
                enqueueSnackbar(
                  'Link copied! Note: This link has been modified from the analytics data.',
                  { variant: 'warning' }
                );
              } else {
                enqueueSnackbar('Link copied!', { variant: 'info' });
              }
            }}
          >
            <Copy size={18} /> &nbsp; Copy link
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
