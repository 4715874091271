import { toAnalyticsV2Search } from '../../../../services/analyticsV2/query';
import { useFilterClauses } from '../../../../components/analytics_v2/Filters/hooks';
import { AnalyticsFilter } from '../../../../domainTypes/analytics_v2';
import { useMemo } from 'react';
import { compact } from 'lodash';

export const toLinkSearch = (term: string) =>
  toAnalyticsV2Search(term, ['link_name', 'link_url', 'link_dest_url']);

export const useLinksFilters = (): AnalyticsFilter[] => {
  const filters = useFilterClauses();

  return useMemo(() => {
    const finalFilters: AnalyticsFilter[] = compact([
      ...filters,
      {
        field: 'link_id',
        condition: 'not in',
        values: ['']
      }
    ]);
    return finalFilters;
  }, [filters]);
};
