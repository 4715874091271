import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { styled } from '../../../emotion';
import { Centered } from '../../../layout/Centered';
import { UploadCloud } from 'react-feather';

const EmptyStateImage = styled('img')`
  display: block;
  max-width: 600px;
  margin: 36px auto 56px;
`;

export const EmptyState = ({ onImport }: { onImport: () => void }) => {
  return (
    <Centered height={500} style={{ margin: '48px' }}>
      <div>
        <EmptyStateImage
          src="/images/empty-states/integrations.png"
          alt="Performance"
        />
        <div
          style={{ textAlign: 'center', maxWidth: '500px', margin: '0 auto' }}
        >
          <Typography variant="h5" component="p" paragraph>
            <strong>Import your affiliate commissions</strong>
          </Typography>
          <Typography variant="body1" component="p" paragraph>
            Click the &nbsp;
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={onImport}
            >
              <UploadCloud size={18} /> &nbsp; Import
            </Button>{' '}
            &nbsp; button in the left menu to set up your affiliate network
            integrations and upload reports.
          </Typography>
        </div>
      </div>
    </Centered>
  );
};
