import { ReferenceDot, ReferenceLine } from 'recharts';
import { COLORS } from '../../../../domainTypes/colors';
import { Target } from 'react-feather';
import React from 'react';
import { Metric } from '../../../../services/analyticsV2/metrics';
import { isNil } from 'lodash';

export const timeseriesReferences = (
  metric: Metric,
  period: {
    start?: string;
    sum: number;
    end?: string;
    goal: number;
  }
) => {
  if (isNil(period.end)) return [];

  const dot = (
    <ReferenceDot
      x={period.end}
      y={period.goal}
      xAxisId="timestamp"
      yAxisId={metric}
      stroke="none"
      fill="none"
      isFront
      label={(p) => (
        <Target
          size={20}
          strokeWidth={1.7}
          fill="#fff"
          stroke={COLORS.blue.blue5}
          x={p.viewBox.x}
          y={p.viewBox.y}
        />
      )}
    />
  );

  if (isNil(period.start)) return [dot];

  return [
    <ReferenceLine
      stroke="#bdbdbd"
      strokeDasharray="5 5"
      xAxisId="timestamp"
      yAxisId={metric}
      segment={[
        {
          x: period.start,
          y: period.sum
        },
        { x: period.end, y: period.goal }
      ]}
    />,
    dot
  ];
};
