import React from 'react';
import { useInView } from 'react-intersection-observer';

export const LazyRenderWrapper = ({
  children,
  height
}: {
  children: React.ReactNode;
  height: number;
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <div ref={ref} style={{ height }}>
      {inView ? children : null}
    </div>
  );
};
