import React from 'react';
import { ArrowRight, Icon } from 'react-feather';
import { styled } from '../emotion';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  column-gap: ${(p) => p.theme.spacing(0.5)}px;

  svg {
    position: relative;
    display: none;
    visibility: hidden;
    opacity: 0;
    color: ${(p) => p.theme.palette.grey[400]};
    flex-shrink: 0;
  }

  &:hover svg {
    display: block;
    visibility: visible;
    opacity: 100;
    animation: fade 300ms;
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const WithHoverIndicator: React.FC<{ icon?: Icon }> = ({
  children,
  icon: IconComponent = ArrowRight
}) => {
  return (
    <Wrapper>
      {children}
      <IconComponent size={16} />
    </Wrapper>
  );
};
