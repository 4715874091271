import React, { ComponentProps, useCallback, useMemo } from 'react';
import moment, { Moment } from 'moment-timezone';
import { useDialogState } from '../../hooks/useDialogState';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { CustomDialog as TimeframePickerDialog } from '../TimeframePicker/CustomDialog';
import { DAY_FORMAT, Timeframe } from '../../domainTypes/analytics';
import { useCurrentUser } from '../../services/currentUser';
import { css } from '../../emotion';
import { X } from 'react-feather';

interface DateRange {
  start: Moment;
  end: Moment;
}

type LegacyDateRangePickerProps = {
  value: DateRange | null;
  onChange: (value: DateRange | null) => void;
} & Omit<ComponentProps<typeof TextField>, 'value' | 'onClick'>;

export const LegacyDateRangePicker: React.FC<LegacyDateRangePickerProps> = ({
  value,
  onChange,
  ...textFieldProps
}) => {
  const { space } = useCurrentUser();
  const { dialogOpen, closeDialog, openDialog } = useDialogState(false);

  const timeframeValue = useMemo<Timeframe>(
    () => ({
      tz: space.config.tz,
      start: (value?.start ?? moment()).format(DAY_FORMAT),
      end: (value?.end ?? moment().add(1, 'day')).format(DAY_FORMAT)
    }),
    [space.config.tz, value?.end, value?.start]
  );

  const onSelect = useCallback(
    (tf: Timeframe) => {
      onChange({
        start: moment(tf.start),
        end: moment(tf.end)
      });
      closeDialog();
    },
    [closeDialog, onChange]
  );

  const label = useMemo(() => {
    if (!value) {
      return 'Not set';
    }
    return `${value?.start.format('MMM DD')} - ${value?.end.format(
      'MMM D, YYYY'
    )}`;
  }, [value]);

  return (
    <>
      <TextField
        value={label}
        onClick={openDialog}
        variant="outlined"
        InputProps={{
          readOnly: true,
          className: css((t) => ({
            color: !value ? t.palette.text.secondary : t.palette.text.primary
          })),

          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                className={css((t) => ({
                  cursor: 'pointer',
                  color: t.palette.text.secondary
                }))}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onChange(null);
                }}
              >
                <X
                  size={16}
                  className={css((t) => ({
                    cursor: 'pointer',
                    color: t.palette.text.secondary
                  }))}
                />
              </IconButton>
            </InputAdornment>
          )
        }}
        {...textFieldProps}
      />
      <TimeframePickerDialog
        open={dialogOpen}
        onClose={closeDialog}
        value={timeframeValue}
        onSelect={onSelect}
      />
    </>
  );
};
