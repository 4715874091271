import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { useMemo } from 'react';
import { Search as IconDetails } from 'react-feather';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { Doc } from '../../../../domainTypes/document';
import {
  IReport,
  ITrackedSale,
  aggregateSales
} from '../../../../domainTypes/performance';
import { css, styled } from '../../../../emotion';
import { useDialogState } from '../../../../hooks/useDialogState';
import { decompressType } from '../../../../services/compression';
import { useTrackedSalesEventsForAsyncReports } from '../../services/report';
import { refreshSaleTimestamps } from '../../services/sale';
import { SalesList, DEFAULT_SALESLIST_PAGE_SIZE } from '../SalesList';
import { Loader } from '../../../../components/Loader';
import { pluralize } from '../../../../services/pluralize';
import { Currency } from '../../../../components/Number';
import { useErrorLogger } from '../../../../hooks/useErrorLogger';

const HEIGHT = 650;

const PreviewTitelContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Divider = styled('span')`
  display: 'inline-block';
  padding: 0 ${(p) => p.theme.spacing()}px;
`;

const PreviewTitleStats = styled('div')`
  font-size: ${(p) => p.theme.custom.fontSize.m}px;
`;

const PreviewTitle = ({
  title,
  sales,
  currency
}: {
  title: React.ReactNode;
  sales: void | ITrackedSale[];
  currency: CurrencyCode;
}) => {
  const count = (sales || []).length;
  const earnings = aggregateSales(
    (sales || []).map((s) => s.sale),
    currency
  );
  return (
    <PreviewTitelContainer>
      <div>{title}</div>
      {!!count && (
        <PreviewTitleStats>
          {pluralize('item', count, true)}
          <Divider>-</Divider>
          <Currency cents={earnings.total} currency={currency} />
        </PreviewTitleStats>
      )}
    </PreviewTitelContainer>
  );
};

export const PreviewButton = ({
  d,
  tz,
  currency
}: {
  d: Doc<IReport>;
  tz: string;
  currency: CurrencyCode;
}) => {
  const { dialogOpen, openDialog, closeDialog } = useDialogState();
  const [page, setPage] = useState(1);

  const reportPreview = useMemo(
    () => ({
      spaceId: d.data.spaceId,
      reportId: d.id,
      partnerKey: d.data.partnerKey,
      start: d.data.start,
      end: d.data.end,
      sales: d.data.sales
        ? decompressType(d.data.sales).map(refreshSaleTimestamps)
        : null,
      salesStoragePath: d.data.salesStoragePath
    }),
    [d]
  );

  const [sales, loading, error] = useTrackedSalesEventsForAsyncReports(
    d.data.spaceId,
    d.data.createdBy,
    dialogOpen ? [reportPreview] : [],
    tz,
    currency
  );

  useErrorLogger(error);

  return (
    <>
      <Tooltip title="Preview report" placement="top">
        <IconButton onClick={openDialog}>
          <IconDetails size={20} />
        </IconButton>
      </Tooltip>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        maxWidth="xl"
        classes={{
          paper: css(() => ({
            width: '80%',
            maxHeight: '90%'
          }))
        }}
      >
        <DialogTitle>
          <PreviewTitle title="Preview" currency={currency} sales={sales} />
        </DialogTitle>
        <DialogContent>
          {loading && <Loader height={HEIGHT} />}
          {!loading && (
            <SalesList
              sales={sales || []}
              height={HEIGHT}
              page={page}
              setPage={setPage}
              pageSize={DEFAULT_SALESLIST_PAGE_SIZE}
              products={[]}
              currency={currency}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
