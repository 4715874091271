import {
  Button,
  InputAdornment,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import React from 'react';
import { styled } from '../../../../emotion';

const ButtonSet = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: ${(p) => p.theme.spacing(2)}px;
`;

const Columns = styled('div')`
  display: grid;
  grid-template-columns: 1.3fr 2fr;
  align-items: center;
  grid-gap: ${(p) => p.theme.spacing(4)}px;
  row-gap: ${(p) => p.theme.spacing(4)}px;
  padding: ${(p) => p.theme.spacing(2)}px;
`;

export const HeatmapSettings = () => {
  return (
    <div>
      <Columns>
        <div>
          <Typography variant="body1" component="h3" paragraph>
            <strong>Pages to capture</strong>
          </Typography>
          <Typography variant="body2" component="p" color="textSecondary">
            Number of your top pages by clicks to capture for heatmaps, out of
            the limit in your plan.
          </Typography>
        </div>
        <div>
          <TextField
            name="captureLimit"
            type="number"
            variant="outlined"
            style={{ maxWidth: '150px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">pages</InputAdornment>
              )
            }}
          />
        </div>
        <div>
          <Typography variant="body1" component="h3" paragraph>
            <strong>URLs and patterns to ignore</strong>
          </Typography>
          <Typography variant="body2" component="p" color="textSecondary">
            For example, pages with lots of views but low commercial intent,
            like a homepage or forum posts.
          </Typography>
        </div>
        <div>Tags here</div>
        <div>
          <Typography variant="body1" component="h3" paragraph>
            <strong>Parallel requests</strong>
          </Typography>
          <Typography variant="body2" component="p" color="textSecondary">
            How many heatmaps can we generate in parallel for your site? Depends
            on your server speed.
          </Typography>
        </div>
        <div>
          <TextField
            name="parallelRequests"
            type="number"
            variant="outlined"
            style={{ maxWidth: '150px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">requests</InputAdornment>
              )
            }}
          />
        </div>
        <div>
          <Typography variant="body1" component="h3" paragraph>
            <strong>CSS selectors to remove</strong>
          </Typography>
          <Typography variant="body2" component="p" color="textSecondary">
            Which elements shold we delete when rendering your heatmaps?
            Includes selectors for popups, social embeds, etc.
          </Typography>
        </div>
        <div>Tagging ui</div>
        <div>
          <Typography variant="body1" component="h3" paragraph>
            <strong>Styles to apply</strong>
          </Typography>
          <Typography variant="body2" component="p" color="textSecondary">
            Apply inline CSS to specific elements to fixed stretched elements,
            scrolling issues, and more.
          </Typography>
        </div>
        <div>Selectors here</div>
        <div>
          <Typography variant="body1" component="h3" paragraph>
            <strong>Scroll pause duration</strong>
          </Typography>
          <Typography variant="body2" component="p" color="textSecondary">
            How long to wait between downward scrolling to allow for lazy loaded
            images to render, in milliseconds. Keep this as low as possible to
            increase speed of heatmap generation.
          </Typography>
        </div>
        <div>
          <TextField
            name="captureLimit"
            type="number"
            variant="outlined"
            style={{ maxWidth: '100px' }}
            InputProps={{
              endAdornment: <InputAdornment position="end">ms</InputAdornment>
            }}
          />
        </div>
        <div>
          <Typography variant="body1" component="h3" paragraph>
            <strong>Disable adblocker</strong>
          </Typography>
          <Typography variant="body2" component="p" color="textSecondary">
            Only disable the ad blocker if your website is not running any ads
            whatsoever. Otherwise, your heatmaps will fail to render.
          </Typography>
        </div>
        <div>
          <Switch color="primary" /> Disable adblocker (Not recommended)
        </div>
      </Columns>
      <ButtonSet>
        <Button color="primary">Test heatmap settings...</Button>
      </ButtonSet>
    </div>
  );
};
