import { SalesFilterArgs } from '../../../domainTypes/performance';
import { useSalesCacheContext } from '../../../services/sales/cache';
import { getDistinctValues } from '../../../services/sales/distinctValues';
import { getKnownPartnerForKey } from '../../../services/partner';
import { useMappedLoadingValue } from '../../../services/db';
import { usePromise } from '../../../hooks/usePromise';
import { compact, omit } from 'lodash';

export const useKnownPartners = (spaceId: string, q: SalesFilterArgs) => {
  const { version } = useSalesCacheContext();
  return useMappedLoadingValue(
    usePromise(
      () =>
        getDistinctValues(
          spaceId,
          'partner_key',
          omit(q, ['partner_key', 'advertiser_name'])
        ),
      [spaceId, q, version]
    ),
    (ps) => compact(ps.map(getKnownPartnerForKey))
  );
};

export const useKnownAdvertisers = (spaceId: string, q: SalesFilterArgs) => {
  const { version } = useSalesCacheContext();
  return useMappedLoadingValue(
    usePromise(
      () =>
        getDistinctValues(
          spaceId,
          'advertiser_name',
          omit(q, ['advertiser_name'])
        ),
      [spaceId, q, version]
    ),
    (ps) => compact(ps.map((p) => p))
  );
};

export const useKnownPayoutIds = (spaceId: string, q: SalesFilterArgs) => {
  const { version } = useSalesCacheContext();
  return useMappedLoadingValue(
    usePromise(
      () => getDistinctValues(spaceId, 'payout_id', omit(q, ['payout_id'])),
      [spaceId, q, version]
    ),
    compact
  );
};
