import { Paper } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { HelpIcon } from '../../../../components/HelpIcon';
import { CanvasBar } from '../../../../layout/Canvas';
import { LimitedWidth } from '../../../../layout/PageBody';
import { Section } from '../../../../layout/Section';
import { ARTICLES } from '../../../../services/beacon';
import {
  useCurrentUser,
  useCurrentUserScopes
} from '../../../../services/currentUser';
import { SettingsLayout } from '../../SettingsLayout';
import {
  CreatePublicApiTokenButton,
  PublicApiTokensTable
} from './PublicApiTokens';
import { useFeatureEnabled } from '../../../../services/features';
import { ApiNotAvailable } from '../../../../components/ApiNotAvailable';
import { Centered } from '../../../../layout/Centered';
import { NoPermissions } from '../../../../components/NoPermissions';

export const PageSettingsApi = () => {
  const currentUser = useCurrentUser();
  const scopes = useCurrentUserScopes();
  const canCreateApiTokens = scopes.has('api_tokens.create');
  const canViewApiTokens = scopes.has('api_tokens.view');
  const hasApi = useFeatureEnabled('API_V1');

  return (
    <SettingsLayout>
      <Helmet>
        <title>API Settings | Affilimate</title>
      </Helmet>
      <LimitedWidth width={900}>
        {canViewApiTokens ? (
          <Section>
            <CanvasBar>
              <div>
                Secret API Tokens &nbsp;{' '}
                <HelpIcon articleId={ARTICLES.api.overview} />
              </div>

              {canCreateApiTokens && (
                <CreatePublicApiTokenButton
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={!canCreateApiTokens || !hasApi}
                  spaceId={currentUser.space.id}
                >
                  Create new token
                </CreatePublicApiTokenButton>
              )}
            </CanvasBar>
            <Paper>
              {hasApi ? (
                <PublicApiTokensTable spaceId={currentUser.space.id} />
              ) : (
                <Centered style={{ paddingTop: 50, paddingBottom: 50 }}>
                  <ApiNotAvailable />
                </Centered>
              )}
            </Paper>
          </Section>
        ) : (
          <NoPermissions />
        )}
      </LimitedWidth>
    </SettingsLayout>
  );
};
