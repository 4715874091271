import fetch from 'unfetch';
import moment from 'moment-timezone';
import {
  CurrencyCode,
  HistoricalExchangeRates,
  CURRENCIES,
  COMMON_CURRENCY_SYMBOLS,
  ExchangeRates
} from '../../domainTypes/currency';

// Commented out all countries, which we don't support in our CurrencyCode type
const COUNTRY_CODE_TO_CURRENCY: { [key: string]: CurrencyCode } = {
  AD: 'EUR',
  // AE: 'AED',
  // AF: 'AFN',
  // AG: 'XCD',
  // AI: 'XCD',
  // AL: 'ALL',
  // AM: 'AMD',
  // AO: 'AOA',
  // AQ: null,
  // AR: 'ARS',
  AS: 'USD',
  AT: 'EUR',
  AU: 'AUD',
  // AW: 'AWG',
  AX: 'EUR',
  // AZ: 'AZN',
  // BA: 'BAM',
  // BB: 'BBD',
  // BD: 'BDT',
  BE: 'EUR',
  // BF: 'XOF',
  BG: 'BGN',
  // BH: 'BHD',
  // BI: 'BIF',
  // BJ: 'XOF',
  BL: 'EUR',
  // BM: 'BMD',
  // BN: 'BND',
  // BO: 'BOB',
  BQ: 'USD',
  BR: 'BRL',
  // BS: 'BSD',
  BT: 'INR',
  BV: 'NOK',
  // BW: 'BWP',
  // BY: 'BYN',
  // BZ: 'BZD',
  CA: 'CAD',
  CC: 'AUD',
  // CD: 'CDF',
  // CF: 'XAF',
  // CG: 'XAF',
  CH: 'CHF',
  // CI: 'XOF',
  CK: 'NZD',
  // CL: 'CLP',
  // CM: 'XAF',
  CN: 'CNY',
  // CO: 'COP',
  // CR: 'CRC',
  // CU: 'CUP',
  // CV: 'CVE',
  // CW: 'ANG',
  CX: 'AUD',
  CY: 'EUR',
  CZ: 'CZK',
  DE: 'EUR',
  // DJ: 'DJF',
  DK: 'DKK',
  // DM: 'XCD',
  // DO: 'DOP',
  // DZ: 'DZD',
  EC: 'USD',
  EE: 'EUR',
  // EG: 'EGP',
  // EH: 'MAD',
  // ER: 'ERN',
  ES: 'EUR',
  // ET: 'ETB',
  FI: 'EUR',
  // FJ: 'FJD',
  // FK: 'FKP',
  FM: 'USD',
  FO: 'DKK',
  FR: 'EUR',
  // GA: 'XAF',
  GB: 'GBP',
  // GD: 'XCD',
  // GE: 'GEL',
  GF: 'EUR',
  // GG: 'GGP',
  // GH: 'GHS',
  // GI: 'GIP',
  GL: 'DKK',
  // GM: 'GMD',
  // GN: 'GNF',
  GP: 'EUR',
  // GQ: 'XAF',
  GR: 'EUR',
  GS: 'GBP',
  // GT: 'GTQ',
  GU: 'USD',
  // GW: 'XOF',
  // GY: 'GYD',
  HK: 'HKD',
  HM: 'AUD',
  // HN: 'HNL',
  HR: 'HRK',
  //HT: 'HTG',
  HU: 'HUF',
  ID: 'IDR',
  IE: 'EUR',
  IL: 'ILS',
  IM: 'GBP',
  IN: 'INR',
  IO: 'USD',
  // IQ: 'IQD',
  // IR: 'IRR',
  IS: 'ISK',
  IT: 'EUR',
  JE: 'GBP',
  //JM: 'JMD',
  //JO: 'JOD',
  JP: 'JPY',
  //KE: 'KES',
  //KG: 'KGS',
  //KH: 'KHR',
  KI: 'AUD',
  //KM: 'KMF',
  //KN: 'XCD',
  //KP: 'KPW',
  KR: 'KRW',
  //KW: 'KWD',
  //KY: 'KYD',
  //KZ: 'KZT',
  //LA: 'LAK',
  //LB: 'LBP',
  //LC: 'XCD',
  LI: 'CHF',
  //LK: 'LKR',
  //LR: 'LRD',
  //LS: 'LSL',
  LT: 'EUR',
  LU: 'EUR',
  LV: 'EUR',
  //LY: 'LYD',
  //MA: 'MAD',
  MC: 'EUR',
  //MD: 'MDL',
  ME: 'EUR',
  MF: 'EUR',
  //MG: 'MGA',
  MH: 'USD',
  //MK: 'MKD',
  //ML: 'XOF',
  //MM: 'MMK',
  //MN: 'MNT',
  //MO: 'MOP',
  MP: 'USD',
  MQ: 'EUR',
  //MR: 'MRO',
  //MS: 'XCD',
  MT: 'EUR',
  //MU: 'MUR',
  //MV: 'MVR',
  //MW: 'MWK',
  MX: 'MXN',
  MY: 'MYR',
  //MZ: 'MZN',
  //NA: 'NAD',
  //NC: 'XPF',
  //NE: 'XOF',
  NF: 'AUD',
  //NG: 'NGN',
  //NI: 'NIO',
  NL: 'EUR',
  NO: 'NOK',
  //NP: 'NPR',
  NR: 'AUD',
  NU: 'NZD',
  NZ: 'NZD',
  //OM: 'OMR',
  //PA: 'PAB',
  //PE: 'PEN',
  //PF: 'XPF',
  //PG: 'PGK',
  PH: 'PHP',
  //PK: 'PKR',
  PL: 'PLN',
  PM: 'EUR',
  PN: 'NZD',
  PR: 'USD',
  // PS: 'JOD',
  PT: 'EUR',
  PW: 'USD',
  //PY: 'PYG',
  //QA: 'QAR',
  RE: 'EUR',
  RO: 'RON',
  //RS: 'RSD',
  RU: 'RUB',
  //RW: 'RWF',
  //SA: 'SAR',
  //SB: 'SBD',
  //SC: 'SCR',
  //SD: 'SDG',
  SE: 'SEK',
  SG: 'SGD',
  //SH: 'SHP',
  SI: 'EUR',
  SJ: 'NOK',
  SK: 'EUR',
  //SL: 'SLL',
  SM: 'EUR',
  //SN: 'XOF',
  //SO: 'SOS',
  //SR: 'SRD',
  //SS: 'SSP',
  //ST: 'STD',
  SV: 'USD',
  //SX: 'ANG',
  //SY: 'SYP',
  //SZ: 'SZL',
  TC: 'USD',
  //TD: 'XAF',
  TF: 'EUR',
  //TG: 'XOF',
  TH: 'THB',
  //TJ: 'TJS',
  TK: 'NZD',
  TL: 'USD',
  //TM: 'TMT',
  //TN: 'TND',
  //TO: 'TOP',
  TR: 'TRY',
  //TT: 'TTD',
  TV: 'AUD',
  //TW: 'TWD',
  //TZ: 'TZS',
  //UA: 'UAH',
  //UG: 'UGX',
  UM: 'USD',
  US: 'USD',
  //UY: 'UYU',
  //UZ: 'UZS',
  VA: 'EUR',
  //VC: 'XCD',
  //VE: 'VEF',
  VG: 'USD',
  VI: 'USD',
  //VN: 'VND',
  //VU: 'VUV',
  //WF: 'XPF',
  WS: 'EUR',
  //YE: 'YER',
  YT: 'EUR',
  ZA: 'ZAR',
  //ZM: 'ZMW',
  ZW: 'USD'
};

const ACCESS_KEY = 'a132d285c655dae7d8e2034808de40b1';
const LATEST_URL = `https://api.exchangeratesapi.io/latest?access_key=${ACCESS_KEY}`;

// The history API does not contain data for today
const YESTERDAY = moment().subtract(1, 'day').format('YYYY-MM-DD');

const cache: { [key: string]: Promise<HistoricalExchangeRates> } = {};
const latestCache: { [key: string]: Promise<ExchangeRates> } = {};

export const getExchangeRates = async (
  base: CurrencyCode,
  start: string = YESTERDAY,
  end: string = YESTERDAY
) => {
  const cacheKey = `${base}-${start}-${end}`;

  if (!cache[cacheKey]) {
    const url = `https://api.exchangeratesapi.io/v1/timeseries?base=${base}&start_date=${start}&end_date=${end}&access_key=${ACCESS_KEY}`;
    const promise = fetch(url).then((r) => r.json());
    cache[cacheKey] = promise;
  }

  return await cache[cacheKey];
};

export const getLatestExchangeRates = async (base: CurrencyCode) => {
  if (!latestCache[base]) {
    const promise = fetch(`${LATEST_URL}&base=${base}`).then((r) => r.json());
    latestCache[base] = promise;
  }
  return await latestCache[base];
};

export const getLatestExchangeRate = async (
  from: CurrencyCode,
  to: CurrencyCode
) => {
  if (from === to) {
    return 1;
  }
  const resp = await getLatestExchangeRates(to);
  return resp.rates[from];
};

export const getExchangeRate = async (
  from: CurrencyCode,
  to: CurrencyCode,
  date: string = YESTERDAY
) => {
  if (from === to) {
    return 1;
  }
  const resp = await getExchangeRates(to);
  return resp.rates[date][from];
};

export const applyConversionRate = (amount: number, rate: number) =>
  amount / rate;

export const convert = async (
  amount: number,
  from: CurrencyCode,
  to: CurrencyCode
) => {
  const rate = await getExchangeRate(from, to);
  return {
    original: amount,
    converted: applyConversionRate(amount, rate),
    rate
  };
};

export const getCurrencyFromLocale = (): CurrencyCode => {
  try {
    const locale = window.navigator.language;
    const country = locale.slice(3);
    return COUNTRY_CODE_TO_CURRENCY[country] || 'USD';
  } catch {
    return 'USD';
  }
};

export const detectCurrency = (currency: string): CurrencyCode | null => {
  if (CURRENCIES.indexOf(currency as CurrencyCode) !== -1) {
    return currency as CurrencyCode;
  }
  const fromSymbol = COMMON_CURRENCY_SYMBOLS[currency];
  return fromSymbol || null;
};

export const formatShortCurrency = (cents: number, currency: string) => {
  const converted = cents.toLocaleString('en-US', {
    style: 'currency',
    currency
  });
  return converted.substr(0, converted.length - 3); // Removes the .00 at the end
};
