import React from 'react';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../../services/currentUser';
import { Helmet } from 'react-helmet';
import { NoPermissions } from '../../../../../components/NoPermissions';
import { ContentPageBody } from '../../../components/ContentPageBody';
import { useTrackMixpanelView } from '../../../../../services/mixpanel';
import { useCustomDimensionsDoc } from '../../../../../services/customDimensions';
import { Centered } from '../../../../../layout/Centered';
import { Loader } from '../../../../../components/Loader';
import { CustomDimensionsManager } from './CustomDimensionsManager';
import { LimitedWidth } from '../../../../../layout/PageBody';

const ManagerBody = () => {
  const { space } = useCurrentUser();
  const [doc, loading] = useCustomDimensionsDoc(space.id);
  if (loading || !doc)
    return (
      <Centered height={300}>
        <Loader size={32} />
      </Centered>
    );
  return (
    <LimitedWidth width={800}>
      <CustomDimensionsManager doc={doc} />
    </LimitedWidth>
  );
};

export const PageCustomDimensionsManager = () => {
  useTrackMixpanelView('view_custom_dimensions');

  const [canView] = useHasCurrentUserRequiredScopes(['custom_dimensions.view']);

  return (
    <>
      <Helmet>
        <title>Manage custom dimensions | Affilimate</title>
      </Helmet>
      <ContentPageBody>
        {canView ? <ManagerBody /> : <NoPermissions />}
      </ContentPageBody>
    </>
  );
};
