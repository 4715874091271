import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { Button, TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { ISale, SalesFilterArgs } from '../../../../domainTypes/performance';
import { removeTrailingSlash, toDomain } from '../../../../services/url';
import { useKnownAdvertisers } from '../../services/hooks';
import { useCurrentUser } from '../../../../services/currentUser';
import { fromMoment, timeframeToMs } from '../../../../services/time';
import { lastDays } from '../../../../services/analytics';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import {
  CURRENCIES,
  CurrencyCode,
  symbolForCurrencyCode
} from '../../../../domainTypes/currency';
import { AutocompleteSingle } from '../../../../components/Autocomplete';
import moment from 'moment-timezone';
import { Autocomplete } from '@material-ui/lab';
import { importManualSales } from '../../services/sale';
import Typography from '@material-ui/core/Typography';
import { styled } from '../../../../emotion';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { MoneyInput } from '../../../../components/MoneyInput';
import { HelpIcon } from '../../../../components/HelpIcon';
import { ARTICLES } from '../../../../services/beacon';
import { Field, Form } from 'react-final-form';
import { isPositive, required } from '../../../../components/Form/validators';
import { ButtonWithPromiseLoader } from '../../../../components/ButtonWithPromise';
import { useFlushAnalyticsV2Cache } from '../../../../services/analyticsV2/cache';

interface ManualImportFormProps {
  closeForm: () => void;
}

type SaleFields = Pick<
  ISale,
  'partnerProductName' | 'advertiserName' | 'referrerUrl'
> & {
  saleDate: moment.Moment;
  currency: CurrencyCode;
  commission: number;
};

function createSale(sale: SaleFields): ISale {
  return {
    advertiserName: sale.advertiserName,
    referrerUrl: sale.referrerUrl && removeTrailingSlash(sale.referrerUrl),
    partnerProductName: sale.partnerProductName,
    saleDate: fromMoment(sale.saleDate),
    amount: {
      currency: sale.currency,
      commission: sale.commission * 100, // in cents
      price: null,
      revenue: null
    },
    partnerKey: 'direct',
    reportId: '',
    saleType: 'bonus',
    status: 'Final',
    origin: sale.referrerUrl ? toDomain(sale.referrerUrl) : null,
    advertiserId: '',
    saleId: 'to-be-generated',
    partnerProductId: null, // Empty for now
    // Required nulls
    commissionPercent: null,
    completionDate: null,
    lastModified: null,
    payoutDate: null,
    payoutId: null,
    payoutStatus: null,
    trackingId: null,
    trackingLabel: null
  };
}

const startsWithProtocol = (url: unknown) => {
  if (!url) return undefined;
  return typeof url === 'string' && url.startsWith('https://')
    ? undefined
    : 'Should start with protocol';
};

function useAdvertisersFromLastYear(spaceId: string): string[] {
  const q: SalesFilterArgs = useMemo(() => {
    return {
      dates: timeframeToMs(lastDays(365))
    };
  }, []);
  const [advertisers] = useKnownAdvertisers(spaceId, q);
  return advertisers || [];
}

function useSaleFormInitialValues(): SaleFields {
  const currency = useSpaceCurrency();
  return {
    advertiserName: '',
    partnerProductName: '',
    referrerUrl: '',
    saleDate: moment().subtract(1, 'day'),
    currency,
    commission: 0
  };
}

const CardContainer = styled('div')`
  padding: ${(p) => p.theme.spacing(4)}px ${(p) => p.theme.spacing(2)}px;
`;

const FormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing(4)}px;
`;

const AmountBox = styled('div')`
  display: flex;
  gap: ${(p) => p.theme.spacing(2)}px;
  & > .amount {
    flex-grow: 1;
  }

  & > .currency {
    flex-basis: 120px;
  }
`;

const ActionButtons = styled('div')`
  display: flex;
  gap: ${(p) => p.theme.spacing(2)}px;
  justify-content: flex-end;
`;

const SaleParams = styled('div')`
  display: flex;
  gap: ${(p) => p.theme.spacing(2)}px;
  justify-content: space-between;
`;

const Subtitle = styled(Typography)`
  font-weight: 700 !important;
  display: flex;
  gap: ${(p) => p.theme.spacing(1)}px;
  margin-bottom: ${(p) => p.theme.spacing(1)}px !important;
`;

const Description = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(2)}px !important;
`;

export const ManualImportForm: FunctionComponent<ManualImportFormProps> = ({
  closeForm
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { space } = useCurrentUser();
  const advertisers = useAdvertisersFromLastYear(space.id);
  const initialValues = useSaleFormInitialValues();
  const flushCache = useFlushAnalyticsV2Cache();

  const onSubmit = useCallback(
    async (fields: SaleFields) => {
      try {
        const sale = createSale(fields);
        await importManualSales([sale], space.id);
        flushCache(space.id);
        closeForm();
        enqueueSnackbar({
          variant: 'success',
          message: 'Your bonus was added successfully.'
        });
      } catch (e) {
        enqueueSnackbar({
          variant: 'error',
          message:
            'Bonus could not be added. Please try again or get in touch with support.'
        });
      }
    },
    [closeForm, enqueueSnackbar, space.id, flushCache]
  );

  return (
    <CardContainer>
      <Subtitle color="inherit" variant="body1">
        Add a manual bonus{' '}
        <HelpIcon color="blue" articleId={ARTICLES.transactions.manualImport}>
          How it works
        </HelpIcon>
      </Subtitle>

      <Description variant="body2" color="textSecondary">
        Add manual bonus you're paid by advertisers outside of the affiliate
        network, and optionally assign the revenue to a page.
      </Description>

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FormContainer>
                <Field name="advertiserName" validate={required}>
                  {({ input, meta }) => {
                    const hasError = meta.touched && meta.error;
                    return (
                      <Autocomplete<string>
                        fullWidth
                        value={input.value}
                        onChange={(_e, value) => {
                          input.onChange(value);
                        }}
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        options={advertisers}
                        renderInput={(params) => (
                          <TextField
                            error={hasError}
                            helperText={
                              hasError && 'Advertiser name is required'
                            }
                            name={input.name}
                            {...params}
                            variant="outlined"
                            label="Advertiser name *"
                          />
                        )}
                      />
                    );
                  }}
                </Field>

                <SaleParams>
                  <Field name="network">
                    {() => (
                      <TextField
                        fullWidth
                        label="Network"
                        variant="outlined"
                        disabled
                        value="Direct"
                      />
                    )}
                  </Field>

                  <Field name="saleType">
                    {() => (
                      <TextField
                        fullWidth
                        label="Commission type"
                        variant="outlined"
                        disabled
                        value="Bonus"
                      />
                    )}
                  </Field>
                </SaleParams>

                <AmountBox>
                  <Field
                    name="commission"
                    className="amount"
                    validate={isPositive}
                  >
                    {({ input, meta }) => {
                      const hasError = meta.touched && meta.error;
                      return (
                        <MoneyInput
                          className="amount"
                          onChange={input.onChange}
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          value={input.value}
                          name={input.name}
                          label="Commission amount *"
                          error={hasError}
                          helperText={
                            hasError && 'Commission should be positive'
                          }
                          variant="outlined"
                          numericFormatProps={{
                            prefix: symbolForCurrencyCode(values.currency)
                          }}
                        />
                      );
                    }}
                  </Field>

                  <Field name="currency" validate={required}>
                    {({ input, meta }) => {
                      const hasError = meta.touched && meta.error;

                      return (
                        <AutocompleteSingle
                          className="currency"
                          fullWidth
                          label="Currency *"
                          value={input.value}
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          error={hasError}
                          helperText={hasError && 'Currency is required'}
                          options={CURRENCIES}
                          onChange={(currency) => input.onChange(currency)}
                        />
                      );
                    }}
                  </Field>
                </AmountBox>

                <Field name="saleDate" validate={required}>
                  {({ input, meta }) => {
                    const hasError = meta.touched && meta.error;
                    return (
                      <DatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        value={input.value}
                        error={hasError}
                        helperText={hasError && 'Date is required'}
                        clearable={false}
                        format={moment.localeData().longDateFormat('LL')}
                        label="Date"
                        onChange={(e) =>
                          input.onChange(moment(e!.toISOString()))
                        }
                      />
                    );
                  }}
                </Field>

                <Field name="partnerProductName">
                  {({ input }) => (
                    <TextField
                      name={input.name}
                      placeholder="Description"
                      label="Description"
                      variant="outlined"
                      value={input.value}
                      onChange={input.onChange}
                    />
                  )}
                </Field>

                <Field name="referrerUrl" validate={startsWithProtocol}>
                  {({ input, meta }) => {
                    const hasError = meta.touched && meta.error;
                    return (
                      <TextField
                        name={input.name}
                        placeholder="Page URL"
                        label="Page URL"
                        variant="outlined"
                        value={input.value}
                        onChange={input.onChange}
                        error={hasError}
                        helperText={
                          hasError && 'Page URL must start with "https://"'
                        }
                        onBlur={input.onBlur}
                        onFocus={input.onFocus}
                      />
                    );
                  }}
                </Field>

                <ActionButtons>
                  <Button onClick={closeForm} variant="text">
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                  >
                    {submitting ? (
                      <>
                        <ButtonWithPromiseLoader />
                        Adding...
                      </>
                    ) : (
                      'Add bonus'
                    )}
                  </Button>
                </ActionButtons>
              </FormContainer>
            </form>
          );
        }}
      />
    </CardContainer>
  );
};
