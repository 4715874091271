import React, { useMemo } from 'react';
import { TableMetadata } from '../../../../../components/analytics_v2/Table';
import {
  createNameColumn,
  TopFooter,
  TopHeader,
  TopPaper,
  TopTable
} from '../../../../../components/analytics_v2/Table/TopTable/TopTable';
import { Truncated } from '../../../../../components/Truncated';
import {
  AnalyticsField,
  AnalyticsResponseRowWithComparison,
  ISOTimeRange
} from '../../../../../domainTypes/analytics_v2';
import { useRoutes } from '../../../../../routes';
import { useMixpanel } from '../../../../../services/mixpanel';
import {
  useRealtimeFilters,
  useRealtimeMetric,
  useSmallTableMetrics
} from '../service';
import { usePeriodRangeToTimeframe } from './PeriodSelector';

const GROUP_FIELD: AnalyticsField = 'referrer_entry_origin';

const cell = (p: AnalyticsResponseRowWithComparison, o: TableMetadata) => {
  const r = p.group[GROUP_FIELD];
  return <Truncated title={r}>{r}</Truncated>;
};

export const TopReferrerEntryOriginTable = ({
  range,
  color,
  compare
}: {
  range: ISOTimeRange;
  compare?: ISOTimeRange;
  color: string;
}) => {
  const mixpanel = useMixpanel();
  const [metric] = useRealtimeMetric();
  const metrics = useSmallTableMetrics();
  const { filters, columnTransformers } = useRealtimeFilters();
  const { ROUTES } = useRoutes();
  const timeframe = usePeriodRangeToTimeframe();
  const referrerColumn = useMemo(
    () => createNameColumn(cell, 'Referrer domain', color, metric),
    [color, metric]
  );
  return (
    <TopPaper>
      <TopHeader
        title="Top referrer domains"
        description="Domains that sent the most engagement or revenue to your site(s)."
      ></TopHeader>
      <TopTable
        metrics={metrics}
        range={range}
        compareRange={compare}
        filters={filters}
        columnTransformers={columnTransformers}
        metric={metric}
        groupField={GROUP_FIELD}
        nameColumn={referrerColumn}
      />
      <TopFooter
        route={ROUTES.content.referrers.overview.url({ timeframe })}
        onClick={() =>
          mixpanel.track('realtime_report_view_all', {
            widget: 'referrerDomains'
          })
        }
      />
    </TopPaper>
  );
};
