import React, { useCallback, useMemo, useRef, useState } from 'react';
import { MoneyInput } from '../../../../components/MoneyInput';
import { symbolForCurrencyCode } from '../../../../domainTypes/currency';
import { Header, Label, Stack } from './form-components';
import {
  Button,
  ButtonBase,
  Menu,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import { PlusCircle, XCircle } from 'react-feather';
import { FlexContainer } from '../../../../layout/Flex';
import {
  CampaignFlatSpendIncentive,
  CampaignIncentive,
  CampaignIncentiveType,
  CampaignRateIncreaseIncentive,
  defaultIncentive
} from '../../../../domainTypes/campaigns';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import { RateInput } from '../../../../components/RateInput';

const allIncentiveOptions: Array<{
  type: CampaignIncentiveType;
  label: string;
}> = [
  { type: 'flatSpend', label: 'Flat spend' },
  { type: 'rateIncrease', label: 'Rate increase' }
];

interface RateIncreaseInputProps {
  value: CampaignRateIncreaseIncentive;
  onChange: (value: CampaignIncentive) => void;
}

const RateIncreaseInput: React.FC<RateIncreaseInputProps> = ({
  value,
  onChange
}) => {
  const changeFrom = useCallback(
    (from: number) => {
      onChange({ ...value, from });
    },
    [onChange, value]
  );

  const changeTo = useCallback(
    (to: number) => {
      onChange({ ...value, to });
    },
    [onChange, value]
  );

  const changeDescription = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange({ ...value, description: event.target.value });
    },
    [onChange, value]
  );

  return (
    <FlexContainer>
      <RateInput
        label="From"
        value={value.from}
        variant="outlined"
        onChange={changeFrom}
      />
      <RateInput
        label="To"
        value={value.to}
        variant="outlined"
        onChange={changeTo}
      />
      <TextField
        label="Description"
        value={value.description}
        onChange={changeDescription}
        variant="outlined"
      />
    </FlexContainer>
  );
};

interface FlatSpendInputProps {
  value: CampaignFlatSpendIncentive;
  onChange: (value: CampaignIncentive) => void;
}

const FlatSpendInput: React.FC<FlatSpendInputProps> = ({ value, onChange }) => {
  const currency = useSpaceCurrency();
  const changeAmount = useCallback(
    (amount: number) => {
      onChange({ ...value, amount: amount * 100 });
    },
    [onChange, value]
  );

  const changeDescription = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange({ ...value, description: event.target.value });
    },
    [onChange, value]
  );
  return (
    <FlexContainer>
      <MoneyInput
        label="Amount"
        onChange={changeAmount}
        value={value.amount / 100}
        variant="outlined"
        numericFormatProps={{
          prefix: symbolForCurrencyCode(currency)
        }}
      />
      <TextField
        label="Description"
        value={value.description}
        onChange={changeDescription}
        variant="outlined"
      />
    </FlexContainer>
  );
};

interface CampaignIncentivesSelectorProps {
  value: CampaignIncentive[];
  onChange: (value: CampaignIncentive[]) => void;
}

const CampaignIncentiveInput = ({
  onChange,
  value
}: {
  onChange: (newGoal: CampaignIncentive) => void;
  value: CampaignIncentive;
}) => {
  switch (value.type) {
    case 'rateIncrease':
      return <RateIncreaseInput value={value} onChange={onChange} />;
    case 'flatSpend':
      return <FlatSpendInput value={value} onChange={onChange} />;
  }
};

const toLabel = (incentive: CampaignIncentive) => {
  switch (incentive.type) {
    case 'rateIncrease':
      return 'Rate increase';
    case 'flatSpend':
      return 'Flat spend';
  }
};

export const CampaignIncentivesSelector: React.FC<CampaignIncentivesSelectorProps> = ({
  value,
  onChange
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchor = useRef<HTMLButtonElement | null>(null);
  const options = useMemo(() => {
    return allIncentiveOptions.filter(
      (option) => !value.some((incentive) => incentive.type === option.type)
    );
  }, [value]);
  const addIncentive = useCallback(
    (type: CampaignIncentiveType) => {
      onChange([...value, defaultIncentive(type)]);
    },
    [onChange, value]
  );
  const changeIncentive = useCallback(
    (newGoal: CampaignIncentive) => {
      onChange(
        value.map((goal) => (goal.type === newGoal.type ? newGoal : goal))
      );
    },
    [onChange, value]
  );

  return (
    <Stack>
      <Header>
        INCENTIVES
        <Button
          ref={menuAnchor}
          color="primary"
          style={{ marginLeft: 12 }}
          onClick={() => setMenuOpen(true)}
          startIcon={<PlusCircle size={16} />}
          disabled={options.length === 0}
        >
          Add incentive
        </Button>
        <Menu
          open={menuOpen}
          anchorEl={menuAnchor.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          onClose={() => setMenuOpen(false)}
        >
          {options.map((option) => (
            <MenuItem
              key={option.type}
              onClick={() => {
                addIncentive(option.type);
                setMenuOpen(false);
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
        <FlexContainer wrap="wrap">
          {value.map((incentive) => (
            <Stack>
              <Label>{toLabel(incentive)}</Label>
              <FlexContainer>
                <CampaignIncentiveInput
                  key={incentive.type}
                  value={incentive}
                  onChange={changeIncentive}
                />
                <ButtonBase
                  onClick={() =>
                    onChange(value.filter((v) => v.type !== incentive.type))
                  }
                >
                  <XCircle size={16} />
                </ButtonBase>
              </FlexContainer>
            </Stack>
          ))}
          {value.length === 0 && (
            <Typography variant="body2" color="textSecondary">
              Define campaign incentives, like flat spend or a rate increase.
            </Typography>
          )}
        </FlexContainer>
      </Header>
    </Stack>
  );
};
