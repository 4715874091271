import { Tooltip } from '@material-ui/core';
import { capitalize, truncate } from 'lodash';
import React, { useMemo } from 'react';
import {
  AdvertiserWithColor,
  getAdvertiserColor
} from '../../components/AdvertiserWithColor';
import { WithShape } from '../../components/Charts/Util';
import { PartnerLogoWithTextContainer } from '../../components/PartnerLogo';
import { PlatformWithColor } from '../../components/PlatformWithColor';
import { SiteWithColor } from '../../components/SiteWithColor';
import { Truncated } from '../../components/Truncated';
import { WithHoverIndicator } from '../../components/WithHoverIndicator';
import {
  AnalyticsColumnTransformer,
  AnalyticsField,
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison
} from '../../domainTypes/analytics_v2';
import { COUNTRY_ABBREVIATIONS } from '../../domainTypes/country';
import { EMPTY_ARR } from '../../domainTypes/emptyConstants';
import {
  PAYOUT_UI_CONFIG,
  SALE_UI_CONFIG
} from '../../domainTypes/performance';
import { ISpace } from '../../domainTypes/space';
import { productNameFieldToEnglish } from '../../features/PerformanceNew/services/advertisers';
import { getCountryColor } from '../analytics/country';
import { ChannelIdLabel } from '../channels/ChannelLabel';
import { channelContainer, useSpaceChannels } from '../channels/channels';
import { getStableRandomColor } from '../color';
import { constructPartnerForKey, getKnownPartnerForKey } from '../partner';
import { withoutProtocolOrWww } from '../url';

export type AnalyticsGroupContainer = {
  key: string;
  label: string;
  color: string;
};

export type AnalyticsGroup = {
  groupBy: AnalyticsQuery['groupBy'];
  toKey: (row: AnalyticsResponseRowWithComparison) => string;
  toContainer: (
    row: AnalyticsResponseRowWithComparison
  ) => AnalyticsGroupContainer;
  toContainerFromKey: (key: string) => AnalyticsGroupContainer;
  toCell: (row: AnalyticsResponseRowWithComparison) => React.ReactNode;
  columnTransformers: (space: ISpace) => AnalyticsColumnTransformer[];
};

export type AnalyticsGroupType =
  | 'advertiser'
  | 'brand'
  | 'country'
  | 'channel_id'
  | 'device'
  | 'page_url_origin'
  | 'partner_product_category'
  | 'partner_product_id'
  | 'partner_product_name'
  | 'payout_status'
  | 'pk'
  | 'sale_status'
  | 'seller';

export const useChannelIdGrouper = (): AnalyticsGroup => {
  const [channels = EMPTY_ARR] = useSpaceChannels();
  return useMemo<AnalyticsGroup>(() => {
    return {
      groupBy: ['channel_id'],
      toKey: (row) => row.group.channel_id,
      toContainer: (row) => channelContainer(row.group.channel_id, channels),
      toContainerFromKey: (key) => channelContainer(key, channels),
      toCell: (row) => (
        <WithHoverIndicator>
          <ChannelIdLabel channelId={row.group.channel_id} />
        </WithHoverIndicator>
      ),
      columnTransformers: () => EMPTY_ARR
    };
  }, [channels]);
};

const toSimpleGroup = (field: AnalyticsField): AnalyticsGroup => {
  return {
    groupBy: [field],
    toKey: (row) => row.group[field],
    toContainer: (row) => {
      const key = row.group[field] || 'Unknown';
      return {
        key,
        color: getStableRandomColor(key),
        label: key
      };
    },
    toContainerFromKey: (k) => {
      const key = k || 'Unknown';
      return {
        key,
        color: getStableRandomColor(key),
        label: key
      };
    },
    toCell: (row) => {
      const label = row.group[field] || 'Unknown';
      return (
        <Tooltip title={label} placement="top">
          <div>
            <WithShape color={getStableRandomColor(label)}>
              {truncate(label, { length: 100 })}
            </WithShape>
          </div>
        </Tooltip>
      );
    },
    columnTransformers: () => EMPTY_ARR
  };
};

export const ANALYTICS_GROUPS: {
  [K in Exclude<AnalyticsGroupType, 'channel_id'>]: AnalyticsGroup;
} = {
  advertiser: {
    groupBy: ['advertiser_name', 'pk'],
    toKey: (row) =>
      JSON.stringify({
        adv: row.group['advertiser_name'],
        pk: row.group['pk']
      }),
    toContainerFromKey: (json) => {
      const { adv, pk } = JSON.parse(json) as { adv: string; pk: string };
      return {
        key: [adv, pk].join('---'),
        color: getAdvertiserColor(adv, pk),
        label: adv
      };
    },
    toContainer: (row) => {
      const adv = row.group['advertiser_name'] || '';
      const pk = row.group['pk'] || '';
      return {
        key: [adv, pk].join('---'),
        color: getAdvertiserColor(adv, pk),
        label: adv
      };
    },
    toCell: (row) => (
      <WithHoverIndicator>
        <Truncated title={row.group.advertiser_name}>
          <AdvertiserWithColor
            advertiserName={row.group.advertiser_name}
            partnerKey={row.group.pk}
          />
        </Truncated>
      </WithHoverIndicator>
    ),
    columnTransformers: () => EMPTY_ARR
  },
  country: {
    groupBy: ['country'],
    toKey: (row) => row.group['country'],
    toContainer: (row) => {
      const countryCode = row.group['country'] || 'Unknown';

      const countryName = COUNTRY_ABBREVIATIONS[countryCode] || countryCode;
      const label =
        countryName !== 'Unknown'
          ? `${countryCode} - ${countryName}`
          : 'Not available';

      return {
        key: countryCode,
        color: getCountryColor(countryCode),
        label
      };
    },
    toContainerFromKey: (key) => {
      const countryCode = key || 'Unknown';

      const countryName = COUNTRY_ABBREVIATIONS[countryCode] || countryCode;
      const label =
        countryName !== 'Unknown'
          ? `${countryCode} - ${countryName}`
          : 'Not available';

      return {
        key: countryCode,
        color: getCountryColor(countryCode),
        label
      };
    },
    toCell: (row) => null, // TODO
    columnTransformers: () => EMPTY_ARR
  },
  device: {
    groupBy: ['device'],
    toKey: (row) => row.group['device'],
    toContainer: (row) => {
      const device = row.group['device'] || 'Unknown';
      const deviceType = capitalize(device);
      return {
        key: deviceType,
        color: getStableRandomColor(deviceType),
        label: capitalize(deviceType)
      };
    },
    toContainerFromKey: (key) => {
      const device = key || 'Unknown';
      const deviceType = capitalize(device);
      return {
        key: deviceType,
        color: getStableRandomColor(deviceType),
        label: capitalize(deviceType)
      };
    },
    toCell: (row) => null, // TODO
    columnTransformers: () => EMPTY_ARR
  },
  page_url_origin: {
    groupBy: ['page_url_origin'],
    toKey: (row) => row.group['page_url_origin'],
    toContainer: (row) => {
      const origin = row.group['page_url_origin'] || 'Unknown';
      return {
        key: origin,
        color: getStableRandomColor(origin),
        label: origin
      };
    },
    toContainerFromKey: (key) => {
      const origin = key || 'Other';
      return {
        key: origin,
        color: getStableRandomColor(origin),
        label: origin
      };
    },
    toCell: (row) => {
      const origin = row.group['page_url_origin'] || 'Unknown';
      return <SiteWithColor origin={origin} />;
    },
    columnTransformers: (space) => {
      const individualDomains = space.domains
        .filter((d) => d.active)
        .map((d) => new URL(d.url).hostname);
      const individualDomainsAndTheirPermutations = individualDomains.map<
        [string, string[]]
      >((dom) => {
        const clean = withoutProtocolOrWww(dom);
        return [dom, [clean, `www.${clean}`]];
      });
      return [
        {
          field: 'page_url_origin',
          condition: 'ilike',
          pattern: '%youtube%',
          mapTo: 'youtube.com'
        },
        ...individualDomainsAndTheirPermutations.map<
          AnalyticsColumnTransformer
        >(([mapTo, values]) => ({
          field: 'page_url_origin',
          condition: 'in',
          values,
          mapTo
        })),
        {
          field: 'page_url_origin',
          condition: 'ilike',
          pattern: '%',
          mapTo: 'Other'
        }
      ];
    }
  },
  partner_product_name: {
    groupBy: ['partner_product_name'],
    toKey: (row) => row.group['partner_product_name'],
    toContainer: (row) => {
      const key = row.group['partner_product_name'] || 'Unknown';
      const label = productNameFieldToEnglish(key, 'partner_product_name');
      return {
        key,
        color: getStableRandomColor(key),
        label
      };
    },
    toContainerFromKey: (k) => {
      const key = k || 'Unknown';
      const label = productNameFieldToEnglish(key, 'partner_product_name');
      return {
        key,
        color: getStableRandomColor(key),
        label
      };
    },
    toCell: (row) => {
      const rawName = row.group.partner_product_name || 'Unknown';
      const nameLabel = productNameFieldToEnglish(
        rawName,
        'partner_product_name'
      );
      return (
        <Tooltip
          title={rawName.length > nameLabel.length ? rawName : nameLabel}
          placement="top"
        >
          <div>
            <WithShape color={getStableRandomColor(rawName)}>
              {truncate(nameLabel, { length: 100 })}
            </WithShape>
          </div>
        </Tooltip>
      );
    },
    columnTransformers: () => EMPTY_ARR
  },
  partner_product_id: toSimpleGroup('partner_product_id'),
  partner_product_category: toSimpleGroup('partner_product_category'),
  pk: {
    groupBy: ['pk'],
    toKey: (row) => row.group['pk'],
    toContainer: (row) => {
      const pk = row.group['pk'] || 'Unknown';
      const partner = getKnownPartnerForKey(pk) || constructPartnerForKey(pk);
      return {
        key: partner.key,
        color: partner.color,
        label: partner.name
      };
    },
    toContainerFromKey: (key) => {
      const pk = key || 'Unknown';
      const partner = getKnownPartnerForKey(pk) || constructPartnerForKey(pk);
      return {
        key: partner.key,
        color: partner.color,
        label: partner.name
      };
    },
    toCell: (row) => {
      const pk = row.group['pk'] || 'Unknown';
      const partner = getKnownPartnerForKey(pk) || constructPartnerForKey(pk);
      return (
        <div style={{ display: 'flex' }}>
          <PartnerLogoWithTextContainer>
            <PlatformWithColor partner={partner} />
          </PartnerLogoWithTextContainer>
          {/* {p.partner.portalUrl && (
          <Tooltip title="Open partner portal in new window" placement="top">
            <>
              <PartnerLink
                href={p.partner.portalUrl}
                target="_blank"
                rel="noopener noreferrer"
                title={p.partner.portalUrl}
                onClick={(ev) => {
                  ev.stopPropagation();
                  tracking.sendEvent({
                    category: tracking.toAppCategory(),
                    action: 'External click',
                    label: p.partner.name
                  });
                }}
              >
                <IconOpenNewWindow size={14} color="#AAA" />
              </PartnerLink>
            </>
          </Tooltip>
        )} */}
        </div>
      );
    },
    columnTransformers: () => EMPTY_ARR
  },
  payout_status: {
    groupBy: ['payout_status'],
    toKey: (row) => row.group['payout_status'],
    toContainerFromKey: (status) => {
      const config = status
        ? PAYOUT_UI_CONFIG[status] || PAYOUT_UI_CONFIG.Unknown
        : PAYOUT_UI_CONFIG.Unknown;
      return {
        key: status,
        color: config.color,
        label: capitalize(status)
      };
    },
    toContainer: (row) => {
      const status = row.group['payout_status'];
      const config = status
        ? PAYOUT_UI_CONFIG[status] || PAYOUT_UI_CONFIG.Unknown
        : PAYOUT_UI_CONFIG.Unknown;
      return {
        key: status,
        color: config.color,
        label: capitalize(status)
      };
    },

    toCell: (row) => {
      // TODO - not used anywhere atm
      return null;
    },
    columnTransformers: () => EMPTY_ARR
  },
  sale_status: {
    groupBy: ['sale_status'],
    toKey: (row) => row.group['sale_status'],
    toContainerFromKey: (status) => {
      const saleStatus = capitalize(status);
      const config = SALE_UI_CONFIG[saleStatus] || SALE_UI_CONFIG.Unknown;
      return {
        key: saleStatus,
        color: config.color,
        label: saleStatus
      };
    },
    toContainer: (row) => {
      const saleStatus = capitalize(row.group['sale_status'] || 'Unknown');
      const config = SALE_UI_CONFIG[saleStatus] || SALE_UI_CONFIG.Unknown;
      return {
        key: saleStatus,
        color: config.color,
        label: saleStatus
      };
    },
    toCell: (row) => {
      // TODO - not used anywhere atm
      return null;
    },
    columnTransformers: () => EMPTY_ARR
  },
  seller: toSimpleGroup('seller'),
  brand: toSimpleGroup('brand')
};
