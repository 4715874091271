import { snippetUrl, metaTagName } from '../../env.json';

export const getSnippet = () => {
  return `<script async src="${snippetUrl}"></script>`;
};

export const getMetaTag = (apiToken: string) => {
  return `<meta name="${metaTagName}" content="${apiToken}">`;
};

export const getCombinedSnippet = (apiToken: string) => {
  return `<script async src="${snippetUrl}?apiToken=${apiToken}"></script>`;
};
