import React, { useRef } from 'react';
import { Minus } from 'react-feather';
import { CurrencyCode } from '../../../domainTypes/currency';
import { styled } from '../../../emotion';
import { useHover } from '../../../hooks/useHover';
import { getTrend } from '../../../services/analytics';
import { Currency, Number } from '../../Number';
import { Popover } from '../../Popover';
import { bestDigits, formatTrend, getTrendRatio, Trend } from '../../Trend';

const ComparisonCell = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  > * {
    margin-left: ${(p) => p.theme.spacing() / 2}px;
  }
`;

const COMPARISON_TEXTS = [
  'Trending down',
  'Slightly down',
  'Stable',
  'Slightly up',
  'Trending up'
];

const CountComparisonWrapper = styled('div')`
  font-size: inherit;
  overflow: auto;
  min-width: 190px;
`;

const CountComparisonHeader = styled('div')`
  border-bottom: 1px solid ${(p) => p.theme.palette.text.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: ${(p) => p.theme.spacing(1)}px;
  font-weight: bold;

  > * {
    margin-right: ${(p) => p.theme.spacing() / 2}px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const CountComparisonTable = styled('table')`
  border-collapse: collapse;
  margin: ${(p) => p.theme.spacing() / 2}px;
  width: calc(100% - ${(p) => p.theme.spacing()}px);

  td {
    padding: ${(p) => p.theme.spacing() / 2}px;
  }

  tr:last-child td {
    margin-top: ${(p) => p.theme.spacing() / 2}px;
    border-top: 1px solid ${(p) => p.theme.palette.text.secondary};
  }
`;

const getPlusMinus = (n: number) => {
  if (n === 0) {
    return '±';
  }
  return n > 0 ? '+' : '-';
};

export const CountComparison: React.FC<{
  before: number;
  after: number;
  format?: 'percent' | 'decimal';
  digits?: number;
  currency?: CurrencyCode;
  invert?: boolean;
}> = ({ before, after, format, digits, invert, currency }) => {
  const trend = getTrend(before, after);
  const ratio = getTrendRatio(trend);
  const text = COMPARISON_TEXTS[ratio + 2];

  const diff = after - before;
  const plusMinus = getPlusMinus(diff);

  return (
    <CountComparisonWrapper>
      <CountComparisonHeader>
        <Trend invert={invert} values={[before, after]} />
        <div>{text}</div>
        <div>{plusMinus}</div>
        <Number n={Math.abs(trend)} format="percent" digits={1} />
      </CountComparisonHeader>
      <CountComparisonTable>
        <tbody>
          <tr>
            <td align="left">From</td>
            <td align="right">
              {currency ? (
                <Currency cents={before} currency={currency} />
              ) : (
                <Number n={before} format={format} digits={digits} />
              )}
            </td>
          </tr>
          <tr>
            <td align="left">To</td>
            <td align="right">
              {currency ? (
                <Currency cents={after} currency={currency} />
              ) : (
                <Number n={after} format={format} digits={digits} />
              )}
            </td>
          </tr>
          <tr>
            <td align="left">Difference</td>
            <td align="right" style={{ fontWeight: 'bold' }}>
              {plusMinus}{' '}
              {currency ? (
                <Currency cents={Math.abs(diff)} currency={currency} />
              ) : (
                <Number n={Math.abs(diff)} format={format} digits={digits} />
              )}
            </td>
          </tr>
        </tbody>
      </CountComparisonTable>
    </CountComparisonWrapper>
  );
};

type ICellProps = {
  before: number;
  after: number;
  compare: boolean;
  format?: 'percent' | 'decimal';
  digits?: number;
  invert?: boolean;
  currency?: CurrencyCode;
};

type IBaseProps = {
  children: React.ReactNode;
} & ICellProps;

export const Dash = styled(Minus)`
  color: ${(p) => p.theme.palette.grey[300]};
`;

export const BaseCountCell = ({
  before,
  after,
  compare,
  format,
  digits,
  currency,
  invert,
  children
}: IBaseProps) => {
  // prettier-ignore
  const anchor = useRef<HTMLDivElement | null>(null);
  const popoverOpen = useHover(anchor);
  return (
    <ComparisonCell ref={anchor}>
      {children}
      {compare && <Trend invert={invert} values={[before, after]} />}
      {compare && popoverOpen && (
        <Popover>
          <CountComparison
            before={before}
            after={after}
            format={format}
            digits={digits}
            currency={currency}
          />
        </Popover>
      )}
    </ComparisonCell>
  );
};

export type CountCellProps = ICellProps & {
  variant?: 'absolute-numbers' | 'absolute-growth' | 'relative-growth';
  dashWhenAllZero?: boolean;
};

export const CountCell = (props: CountCellProps) => {
  const {
    before,
    after,
    compare,
    format,
    digits,
    currency,
    variant = 'absolute-numbers',
    invert = false,
    dashWhenAllZero = false
  } = props;
  if (dashWhenAllZero && before === 0 && after === 0) {
    // NOTE: is it the right place to fix that?
    return <Dash size={12} />;
  }
  return (
    <BaseCountCell
      before={before}
      after={after}
      compare={compare}
      format={format}
      digits={digits}
      invert={invert}
      currency={currency}
    >
      {variant === 'absolute-numbers' &&
        (currency ? (
          <Currency cents={after} currency={currency} />
        ) : (
          <Number n={after} format={format} digits={digits} />
        ))}
      {variant === 'relative-growth' &&
        formatTrend(getTrend(before, after), true)}
      {variant === 'absolute-growth' &&
        (currency ? (
          <span>
            {getPlusMinus(after - before)}
            <Currency cents={Math.abs(after - before)} currency={currency} />
          </span>
        ) : (
          <Number
            n={after - before}
            format={format}
            digits={bestDigits(after - before)}
            plusMinus={true}
          />
        ))}
    </BaseCountCell>
  );
};
