import React from 'react';
import { styled } from '../../../../emotion';
import { Button, Typography } from '@material-ui/core';
import { ArrowRightCircle } from 'react-feather';
import { Beacon } from '../../../../services/beacon';
import { Centered } from '../../../../layout/Centered';

type Props = any;

const CheckoutHeader = styled('div')`
  margin: 32px 0;
  text-align: center;
  background-color: #000;
  padding: 24px 0 12px;
  color: #fff;
  margin: -20px -32px 6px -32px;

  & > div {
    position: relative;
    left: -24px;
  }
`;

const CheckoutFormWithStripe = () => {
  return (
    <div>
      <CheckoutHeader>
        <Typography
          variant="h5"
          component="h1"
          style={{
            textAlign: 'center',
            marginBottom: '12px'
          }}
        >
          The best business decision you'll make this year
        </Typography>
        <Typography
          variant="body2"
          component="p"
          style={{ textAlign: 'center', marginBottom: '18px' }}
        >
          All the data, features, and insights you need to scale your affiliate
          business
        </Typography>
      </CheckoutHeader>

      <Centered height={300}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h6" paragraph style={{ fontWeight: 'bold' }}>
            Request a plan quote
          </Typography>
          <Typography variant="body1" paragraph>
            If you haven't yet, reach out to your contact at Affilimate for a
            plan quote.
          </Typography>
          <br />
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={(e) => {
              e.preventDefault();
              Beacon('prefill', {
                subject: 'Request Plan Quote',
                text: ''
              });
              Beacon('open');
              Beacon('navigate', 'ask/message');
            }}
          >
            Talk to the team &nbsp;{' '}
            <ArrowRightCircle
              size={16}
              style={{ position: 'relative', top: '1px' }}
            />
          </Button>
        </div>
      </Centered>
    </div>
  );
};

export const CheckoutForm = (props: Props) => {
  return <CheckoutFormWithStripe {...props} />;
};

export const CHECKOUT_FORM_MIN_WIDTH = 650;
