import styled from '@emotion/styled';
import { Collapse, Tooltip } from '@material-ui/core';
import { useState } from 'react';
import { ChevronDown, ChevronUp, Info } from 'react-feather';
import { CanvasBar } from '../../../../layout/Canvas';
import { HandlerList, HandlerListTypes } from './HandlerList';

const HeadWithInfo = styled('span')`
  svg {
    margin-left: 4px;
    position: relative;
    top: 2px;
  }
`;

const StyledCanvasBar = styled(CanvasBar)`
  cursor: pointer;
`;
export const ArchivedHandlerListWrapper = ({
  space,
  userId,
  handlers,
  ts
}: HandlerListTypes) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <StyledCanvasBar onClick={() => setOpen(!open)}>
        <span>
          Archived integrations{' '}
          <Tooltip
            placement="top"
            title="Archived integrations are no longer active and will not be updated with new data."
          >
            <HeadWithInfo>
              <Info size={13} />
            </HeadWithInfo>
          </Tooltip>
        </span>
        {open ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
      </StyledCanvasBar>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <HandlerList
          space={space}
          userId={userId}
          handlers={handlers}
          ts={ts}
          archivedOnly
        />
      </Collapse>
    </div>
  );
};
