import { Campaign, isCampaignRunning } from '../../service';
import React, { useMemo } from 'react';
import { useCurrentUser } from '../../../../services/currentUser';
import { RowsRenderer } from '../../../../components/GroupableList';
import { Loader } from '../../../../components/Loader';
import { FlexContainer } from '../../../../layout/Flex';
import { useSpaceCurrency } from '../../../../services/useSpaceCurrency';
import { css } from '../../../../emotion';
import { CampaignListColumn, CAMPAIGN_COLUMNS } from '../../service/columns';
import {
  CampaignGroupBy,
  groupCampaigns,
  summarizeCampaigns
} from '../../service/groups';
import { CampaignData, useCampaignsData } from './useCampaignsData';
import {
  CampaignsGroupMetricsHeader,
  CampaignsGroupTitle
} from './GroupHeader';
import { useRoutes } from '../../../../routes';
import { Centered } from '../../../../layout/Centered';

const CampaignsListInner: React.FC<{
  data: Array<CampaignData>;
  group: CampaignGroupBy;
  columns: Set<CampaignListColumn>;
}> = ({ data, group, columns }) => {
  const { ROUTES } = useRoutes();
  const selectedColumnDefinitions = useMemo(
    () => CAMPAIGN_COLUMNS.filter((c) => columns.has(c.key)),
    [columns]
  );
  const { space } = useCurrentUser();
  const currency = useSpaceCurrency();
  const grouped = groupCampaigns(data, group);

  const otherProps = useMemo(
    () => ({
      spaceId: space.id,
      currency,
      tz: space.config.tz
    }),
    [currency, space.config.tz, space.id]
  );
  return (
    <>
      {Object.entries(grouped).map(([key, campaigns]) => {
        const groupData = summarizeCampaigns(campaigns);
        return (
          <React.Fragment key={key}>
            <FlexContainer
              alignItems="center"
              className={css((t) => ({
                padding: t.spacing(2),
                marginTop: t.spacing(2)
              }))}
            >
              <CampaignsGroupTitle group={group} value={key} />
              <CampaignsGroupMetricsHeader
                columns={selectedColumnDefinitions}
                metrics={groupData}
              />
            </FlexContainer>
            <RowsRenderer
              rows={campaigns}
              columns={selectedColumnDefinitions}
              renderHead
              sorter={{
                key: 'name',
                items: {
                  sort: (a) => a.campaign.name,
                  dir: 'asc'
                }
              }}
              otherProps={otherProps}
              rowToKey={(r) => r.campaign.id}
              rowToHref={(r) =>
                isCampaignRunning(r.campaign)
                  ? ROUTES.campaigns.report.url(r.campaign.id)
                  : ROUTES.campaigns.details.url(r.campaign.id, 'overview')
              }
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

export const CampaignsList: React.FC<{
  campaigns: Campaign[];
  group: CampaignGroupBy;
  columns: Set<CampaignListColumn>;
}> = ({ campaigns, group, columns }) => {
  const [data, loading] = useCampaignsData(campaigns);

  return (
    <>
      {loading || !data ? (
        <Centered height={500}>
          <Loader size={36} height={500} />
        </Centered>
      ) : (
        <CampaignsListInner data={data} group={group} columns={columns} />
      )}
    </>
  );
};
