import { groupBy } from 'lodash';

export type HelpScoutArticlePreview = {
  id: string;
  siteId: string;
  collectionId: string;
  categoryIds: string[];
  slug: string;
  name: string;
  url: string;
  docsUrl: string;
  number: number;
  status: 'published' | 'notpublished';
  visibility: 'public';
  updatedAt: string;
  hasDraft: boolean;
  lastPublishedAt: string;
  related: HelpScoutArticlePreview[];
  keywords: string[];
  text: string;
};

export const groupArticlesInCategory = (
  articles: HelpScoutArticlePreview[]
) => {
  const groups = groupBy(articles, (a) => {
    const keywords = a.keywords;
    if (!keywords || !keywords.length) {
      return 'articles';
    }
    return keywords[0].toLowerCase();
  });

  const groupNames = Object.keys(groups);
  const referenceOrder = [
    'featured',
    'overview',
    // getting started
    'the basics',
    'setting up your account',
    // affiliate dashboard
    'transaction reports',
    'other reports',
    'integration settings',
    'revenue attribution',
    // integrations
    'integrations',
    // link management
    'import your links',
    'working with links',
    'cloaked links',
    'amazon link checker',
    'export links',
    // content analytics
    'content trends',
    'heatmaps',
    'segmentation',
    'revisions',
    // api
    'endpoints',
    // misc
    'frequently asked questions',
    'tutorials',
    'best practices',
    'troubleshooting',
    'migrating',
    'other'
  ];

  groupNames.sort((a, b) => {
    return referenceOrder.indexOf(a) > referenceOrder.indexOf(b) ? 1 : -1;
  });

  return { groupNames, groups };
};
